import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchHint: {
		id: 'polaris-common.common.decoration.searchable-dropdown.search-hint',
		defaultMessage: 'Search',
		description: 'Search hint for the search bar in a generic filter dropdown',
	},
	noMatchesIndicator: {
		id: 'polaris-common.common.decoration.searchable-dropdown.no-matches-indicator',
		defaultMessage: 'No matches found',
		description: 'Indicator that no options are visible in the dropdown.',
	},
	createOption: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'polaris-common.field-config.add.create-option',
		defaultMessage: 'Create {value}',
		description: 'Option to create a new option',
	},
	creatableSelectHelperText: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'polaris-common.field-config.add.helper-text',
		defaultMessage: 'Select a field or create one',
		description: "Helper text for the select when it's creatable",
	},
});
