import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	disabledOnSiteTitle: {
		id: 'polaris-lib-control-sharing.disabled-screen.disabled-on-site-title',
		defaultMessage: 'Publishing is disabled for this site',
		description: 'Title for info message when publishing views is disabled for this site',
	},
	disabledOnSiteDescription: {
		id: 'polaris-lib-control-sharing.disabled-screen.disabled-on-site-description',
		defaultMessage:
			'When enabled, you can add anyone to this view without a full access to your project. No Jira license is required.',
		description: 'Description for info message when publishing views is disabled for this site',
	},
	contactSiteAdminMessage: {
		id: 'polaris-lib-control-sharing.disabled-screen.contact-site-admin-message',
		defaultMessage: 'Contact your site admin to enable publishing.',
		description: 'Text for info message when publishing views is disabled for this site',
	},
	enablePublishingButtonLabel: {
		id: 'polaris-lib-control-sharing.disabled-screen.enable-publishing-button-label',
		defaultMessage: 'Enable publishing',
		description: 'Link label to page where publishing can be enabled',
	},
});
