import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	emptyVal: {
		id: 'polaris-common.fields-grouped.empty-val',
		defaultMessage: '{val} empty',
		description: 'Label for the empty number of field values in a group',
	},
	filledVal: {
		id: 'polaris-common.fields-grouped.filled-val',
		defaultMessage: '{val} filled',
		description: 'Label for the filled number of field values in a group',
	},
	commentsCount: {
		id: 'polaris-common.fields-grouped.comments-count',
		defaultMessage: '{val, plural, one {# comment} other {# comments}}',
		description: 'Comments count label',
	},
	usersCount: {
		id: 'polaris-common.fields-grouped.users-count',
		defaultMessage: '{val, plural, one {# user} other {# users}}',
		description: 'Label for the count of users in a group',
	},
	optionsCount: {
		id: 'polaris-common.fields-grouped.options-count',
		defaultMessage: '{val, plural, one {# option} other {# options}}',
		description: 'Label for the count of options selected in a group',
	},
	labelsCount: {
		id: 'polaris-common.fields-grouped.labels-count',
		defaultMessage: '{val, plural, one {# label} other {# labels}}',
		description: 'Label for the count of labels selected in a group',
	},
	avgVal: {
		id: 'polaris-common.fields-grouped.avg-val',
		defaultMessage: 'Avg: {val}',
		description: 'Label for the average of field values in a group',
	},
	medianVal: {
		id: 'polaris-common.fields-grouped.median-val',
		defaultMessage: 'Median: {val}',
		description: 'Label for the median of field values in a group',
	},
	sumVal: {
		id: 'polaris-common.fields-grouped.sum-val',
		defaultMessage: 'Sum: {val}',
		description: 'Label for the sum of field values in a group',
	},
});
