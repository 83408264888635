import boardView from './board-view';
import collections from './collections';
import deliveryItems from './delivery-items';
import fieldsSidebar from './fields-sidebar';
import formulaField from './formula-field';
import globalFieldsAdministration from './global-fields-administration';
import headerView from './header-view';
import ideaView from './idea-view';
import ideaViewBentoSidebar from './idea-view-bento-sidebar';
import issues from './issues';
import listView from './list-view';
import matrixView from './matrix-view';
import navBar from './nav-bar';
import noViewsScreen from './no-views-screen';
import playSidepanel from './play-sidepanel';
import productAccess from './product-access';
import project from './project';
import sharing from './sharing';
import timelineView from './timeline-view';
import view from './view';
import viewPermissions from './view-permissions';

export const experience = {
	listView,
	formulaField,
	headerView,
	boardView,
	ideaView,
	ideaViewBentoSidebar,
	matrixView,
	navBar,
	project,
	timelineView,
	fieldsSidebar,
	issues,
	deliveryItems,
	sharing,
	productAccess,
	globalFieldsAdministration,
	playSidepanel,
	noViewsScreen,
	view,
	viewPermissions,
	collections,
};
