import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { noopJiraUpdateService } from '../common';
import type { JiraFieldMapping } from '../types';

export const jiraProjectMapping = (field: Field): JiraFieldMapping<ProjectFieldValue> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key] ?? undefined,
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	...noopJiraUpdateService,
});
