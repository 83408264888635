import { useEffect, useMemo } from 'react';
import {
	createStore,
	createStateHook,
	createActionsHook,
	createSelector,
} from '@atlassian/react-sweet-state';
import { getPolarisFieldsIdMapping } from '../services/polaris-api/field-id-mapping';
import type { FieldIdMap, State } from './types';

const initialState: State = {
	fieldMappings: {},
};

// visible for testing
export const Store = createStore({
	initialState,
	actions: {
		loadFieldMapping:
			(projectIdOrKey: number | string) =>
			async ({ getState, setState }): Promise<FieldIdMap> => {
				if (getState().fieldMappings[projectIdOrKey] !== undefined) {
					return getState().fieldMappings[projectIdOrKey].loader.then(({ fieldMap }) => fieldMap);
				}
				const loader = getPolarisFieldsIdMapping(projectIdOrKey);

				setState({
					fieldMappings: {
						...getState().fieldMappings,
						[projectIdOrKey]: { loader, isLoading: true, fieldIdMap: undefined },
					},
				});

				return loader.then((fieldIdMap) => {
					setState({
						fieldMappings: {
							...getState().fieldMappings,
							[projectIdOrKey]: {
								loader,
								fieldIdMap: fieldIdMap.fieldMap,
								isLoading: false,
							},
						},
					});
					return fieldIdMap.fieldMap;
				});
			},
	},
	name: 'PolarisFieldIdMapStore',
});

const useActions = createActionsHook(Store);

type SelectorProps = { projectIdOrKey: number | string | undefined };

const getFieldMappingConfigs = (state: State) => state.fieldMappings;
const getFieldMappingConfig = (state: State, { projectIdOrKey }: SelectorProps) =>
	projectIdOrKey === undefined ? undefined : getFieldMappingConfigs(state)[projectIdOrKey];

const getIsLoading = createSelector(
	getFieldMappingConfig,
	(config) => config === undefined || config.isLoading,
);
const getIsInitialized = createSelector(getFieldMappingConfig, (config) => config !== undefined);
const getFieldIdMap = createSelector(getFieldMappingConfig, (config) => config?.fieldIdMap);

const useIsLoading = createStateHook(Store, { selector: getIsLoading });
const useIsInitialized = createStateHook(Store, { selector: getIsInitialized });
const useFieldIdMap = createStateHook(Store, { selector: getFieldIdMap });

const useLoadFieldMappings = (projectIdOrKey: number | string | undefined) => {
	const { loadFieldMapping } = useActions();
	const loading = useIsLoading({ projectIdOrKey });
	const initialized = useIsInitialized({ projectIdOrKey });

	// trigger loading
	useEffect(() => {
		if (!initialized && !!projectIdOrKey) {
			loadFieldMapping(projectIdOrKey);
		}
	}, [initialized, projectIdOrKey, loadFieldMapping]);

	// return loading state
	return loading;
};

export const useFieldIdMapping = (
	projectIdOrKey: number | string | undefined,
): { loading: boolean; data: FieldIdMap | undefined } => {
	// trigger loading if not already loaded
	const loading = useLoadFieldMappings(projectIdOrKey);
	const data = useFieldIdMap({ projectIdOrKey });

	return useMemo(
		() => ({
			loading,
			data,
		}),
		[data, loading],
	);
};

export const useFieldIdMapAsync = (projectIdOrKey: number | string): Promise<FieldIdMap> => {
	const { loadFieldMapping } = useActions();
	return loadFieldMapping(projectIdOrKey);
};
