import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export class ExpressionError extends Error {
	msg: MessageDescriptor;

	args: string[];

	constructor(msg: MessageDescriptor, ...args: string[]) {
		super();
		this.msg = msg;
		this.args = args;
	}
}
