import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activeIssueLimitReachedBanner: {
		id: 'polaris-ideas.main-content.issue-limit-banner.active-issue-limit-reached-banner',
		defaultMessage:
			'Your project has reached the limit of ideas. We will display the last {limit, plural, one {# idea} other {# ideas}} for calculating the formula fields. <a>Also, you can check all the ideas here</a>. We advise that you archive or remove ideas that you don’t need anymore.',
		description: 'The heading displayed in the banner when active issue limit is reached.',
	},
});
