import { createSelector } from 'reselect';
import { SECURITY } from '@atlassian/jira-issue-view-configurations';
import {
	fieldSelector,
	isFieldEditableSelector,
} from '../../../common/state/selectors/field-selector';
import { isSubtaskSelector } from '../../../selectors/subtasks-selector';

const securitySelector = fieldSelector(SECURITY);

export type Value = {
	id: number;
	name: string;
	description: string;
};

type SecurityLevelField = {
	allowedValues: Value[];
	value: Value;
};

type AnalyticsData = {
	hasSecurityLevel: boolean;
	securityLevelAllowedValuesCount: number;
	hasSecurityLevelEditPermissions: boolean;
};

export const valueSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	securitySelector,
	(security: SecurityLevelField | null) => (security && security.value) || null,
);

export const allowedValuesSelector = createSelector(
	securitySelector,
	(security) => (security && security.allowedValues) || [],
);

/**
 * Check if security level is configured for the current issue.
 */
export const isEnabledForCurrentIssueSelector = createSelector(
	valueSelector,
	allowedValuesSelector,
	(value, allowedValues) => value || (allowedValues && allowedValues.length > 0),
);

export const isEditableSelector = createSelector(
	isFieldEditableSelector(SECURITY),
	isSubtaskSelector,
	(isEditable, isSubtask) => isEditable && !isSubtask,
);

export const analyticsSelector = createSelector(
	valueSelector,
	allowedValuesSelector,
	isEditableSelector,
	(value, allowedValues, isEditable): AnalyticsData => ({
		hasSecurityLevel: value != null,
		securityLevelAllowedValuesCount: allowedValues.length,
		hasSecurityLevelEditPermissions: isEditable,
	}),
);
