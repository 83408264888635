import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import {
	isValueDecorationRules,
	type FieldValueDecorations,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type CopyValues = {
	sourceField: Field | undefined;
	globalFields: Field[];
	canManageGlobalFields: boolean;
	fieldValueDecorations: FieldValueDecorations;
};

const CopyValuesContext = createContext<CopyValues>({
	sourceField: undefined,
	globalFields: [],
	canManageGlobalFields: false,
	fieldValueDecorations: {},
});

export const CopyValuesContextProvider = ({
	children,
	sourceField,
	globalFields,
	canManageGlobalFields,
	fieldValueDecorations,
}: PropsWithChildren<CopyValues>) => {
	const value = useMemo(
		() => ({
			sourceField,
			globalFields,
			fieldValueDecorations,
			canManageGlobalFields,
		}),
		[sourceField, globalFields, fieldValueDecorations, canManageGlobalFields],
	);

	return <CopyValuesContext.Provider value={value}>{children}</CopyValuesContext.Provider>;
};

export const useSourceField = () => {
	const { sourceField } = useContext(CopyValuesContext);
	return sourceField;
};

export const useGlobalFields = () => {
	const { globalFields } = useContext(CopyValuesContext);
	return globalFields;
};

export const useCanManageGlobalFields = () => {
	const { canManageGlobalFields } = useContext(CopyValuesContext);
	return canManageGlobalFields;
};

export const useFieldValueDecorationForField = (fieldKey: FieldKey) => {
	const { fieldValueDecorations } = useContext(CopyValuesContext);
	return fieldValueDecorations[fieldKey];
};

export const useFieldOptionDecoration = (fieldKey: FieldKey, optionId: string) => {
	const fieldValueDecorations = useFieldValueDecorationForField(fieldKey);

	const decorationsByOptionId = useMemo(() => {
		if (!fieldValueDecorations) {
			return {};
		}

		const optionDecorations: Record<
			string,
			{ emojiId: string | undefined; backgroundColor: string | undefined }
		> = {};

		for (const decoration of fieldValueDecorations) {
			if (isValueDecorationRules(decoration)) {
				const [{ value }] = decoration.rules;
				optionDecorations[value] = {
					emojiId: decoration.emoji,
					backgroundColor: decoration.backgroundColor,
				};
			}
		}

		return optionDecorations;
	}, [fieldValueDecorations]);

	return decorationsByOptionId[optionId] ?? {};
};
