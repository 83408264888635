import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hasTicketFilterLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.delivery-tickets.utils.has-ticket-filter-label',
		defaultMessage: 'has linked delivery tickets',
		description: 'radio button label for filtering the ideas with linked delivery tickets',
	},
	hasTicketFilterActiveLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.delivery-tickets.utils.has-ticket-filter-active-label',
		defaultMessage: 'is linked',
		description: 'button label for having linked delivery tickets',
	},
	hasNoTicketFilterLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.delivery-tickets.utils.has-no-ticket-filter-label',
		defaultMessage: 'has no linked delivery tickets',
		description: 'radio button label for filtering the ideas with mp linked delivery tickets',
	},
	hasNoTicketFilterActiveLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.delivery-tickets.utils.has-no-ticket-filter-active-label',
		defaultMessage: 'is not linked',
		description: 'button label for not having linked delivery tickets',
	},
});
