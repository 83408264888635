import { type ADF, isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content';
import type { Worklog } from '../../model';

export const isNotEmptyComment = (comment: string | ADF | null) =>
	typeof comment === 'string' ? comment.trim() !== '' : !isOnlyWhitespaceAdf(comment);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (worklog: Worklog & { comment: Worklog['comment'] | string }): boolean => {
	const { comment } = worklog;
	if (!comment) {
		return false;
	}

	return typeof comment === 'string' ? comment.trim() !== '' : !isOnlyWhitespaceAdf(comment);
};
