import React, { useCallback } from 'react';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useRouterActions } from '@atlassian/jira-router';
import messages from './messages';
import { NavigationPrompt } from './prompt';

type Props = {
	isDirty: boolean;
	withSaveButton?: boolean;
	title?: MessageDescriptor;
	prompt?: MessageDescriptor;
	onSave?: (arg1: () => void) => void;
	onDiscard?: () => void;
	isPromptOpen?: boolean;
	setIsPromptOpen?: (_: boolean) => void;
	shouldIgnoreQueryParams?: boolean;
};

export const PolarisNavigationBlocker = (props: Props) => {
	const { registerBlock } = useRouterActions();

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const onDiscardDefault = useCallback(() => {}, []);
	const onSaveDefault = useCallback((cb: () => void) => cb(), []);

	const {
		withSaveButton = false,
		prompt = messages.prompt,
		onDiscard = onDiscardDefault,
		onSave = onSaveDefault,
		...rest
	} = props;

	return (
		<NavigationPrompt
			withSaveButton={withSaveButton}
			onRegisterBlock={registerBlock}
			prompt={prompt}
			onDiscard={onDiscard}
			onSave={onSave}
			{...rest}
		/>
	);
};
