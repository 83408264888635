import { useIssueLinkTypes } from '@atlassian/jira-polaris-component-environment-tenant';
import type { SelectedIssueLinkType } from './types';

export const useFirstIssueLinkType = (): SelectedIssueLinkType | undefined => {
	const issueLinkTypes = useIssueLinkTypes();
	if (issueLinkTypes !== undefined && issueLinkTypes.length > 0) {
		const issueLinkType = issueLinkTypes[0];
		return {
			issueLinkTypeId: issueLinkType.id,
			direction: 'outward',
		};
	}
	return undefined;
};
