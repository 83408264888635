import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	descriptionPlaceholder: {
		id: 'polaris-ideas.idea-view.description.content.read.description-placeholder',
		defaultMessage: 'Add a description... ',
		description: 'Placeholder for description when no description is yet set.',
	},
	emptyDescriptionPlaceholder: {
		id: 'polaris-ideas.idea-view.description.content.read.empty-description-placeholder',
		defaultMessage: 'None',
		description: 'Placeholder for description in smartlink when no description is yet set.',
	},
	draftIndicator: {
		id: 'polaris-ideas.idea-view.description.content.read.draft-indicator',
		defaultMessage: 'Description has unsaved changes',
		description: 'Indicator message that unsaved changes exist for the description field.',
	},
	templatesButton: {
		id: 'polaris-ideas.idea-view.description.content.read.templates-button',
		defaultMessage: 'Templates',
		description: 'Button message for idea templates',
	},
	descriptionTemplatePlaceholder: {
		id: 'polaris-ideas.idea-view.description.content.read.description-template-placeholder',
		defaultMessage: 'Or start from a template',
		description: 'Button message for opening templates for idea description',
	},
});
