import type { ReactNode, MouseEvent, ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src';

type IssueContainerProps = {
	fixExtraDivider?: boolean;
	shouldTruncate?: boolean;
	['data-testid']: string;
	children: ReactNode;
	onMouseEnter?: (event: MouseEvent) => void;
	onMouseLeave?: (event: MouseEvent) => void;
	onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles,  @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const BreadcrumbsWrapper = styled.div({
	display: 'flex',
	flexGrow: 0,
	overflow: 'visible',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles,  @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
export const SmallBreadcrumbsItem = styled.a({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > span': {
		font: token('font.body.UNSAFE_small'),
		paddingBlock: token('space.025'),
	},
});

export const BREADCRUMBS_WRAPPER_SELECTOR_NAME = 'breadcrumbs-wrapper';
const BREADCRUMBS_WRAPPER_SELECTOR = `[data-component-selector="${BREADCRUMBS_WRAPPER_SELECTOR_NAME}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CopyLinkButton = styled(Button)({
	opacity: 0,
	transition: 'opacity 0.2s ease, transform 0.2s ease',
	transform: 'translate(-8px, 0)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${BREADCRUMBS_WRAPPER_SELECTOR}:hover &`]: {
		opacity: 1,
		transform: 'translate(0, 0)',
	},
	'&:focus': {
		opacity: 1,
		transform: 'translate(0,0)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IssueContainer = styled.div(
	{
		display: 'flex',
		margin: 0,
		alignItems: 'center',
		'&::after': {
			display: 'flex',
			content: '/',
			flexShrink: 0,

			padding: `0 ${token('space.050', '4px')}`,
			justifyContent: 'center',
			width: '8px',
			font: token('font.body'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > div > li::after': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			display: ({ fixExtraDivider }) => (fixExtraDivider ? 'none' : 'block'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > div > button': {
			font: token('font.body.UNSAFE_small'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:last-child::after': {
			content: '',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: IssueContainerProps) =>
		props.shouldTruncate && {
			'& > div:last-child': {
				maxWidth: '200px',
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconPaddingWrapper = styled.span({
	paddingRight: token('space.100', '8px'),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProjectIconWrapper = styled.span({
	paddingBlockStart: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ProjectContainer = styled.div<{ fixExtraDivider?: boolean }>({
	display: 'flex',
	maxWidth: '300px',
	paddingLeft: token('space.050', '4px'),
	alignItems: 'center',
	'&::after': {
		display: 'flex',
		content: '/',
		flexShrink: 0,

		padding: `0 ${token('space.050', '4px')}`,
		justifyContent: 'center',
		width: '8px',
		font: token('font.body'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div > li::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ fixExtraDivider }) => (fixExtraDivider ? 'none' : 'block'),
	},
});
