import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	delete: {
		id: 'polaris-common.config.fields.delete-field.delete',
		defaultMessage: 'Delete',
		description: 'Button label for the field deletion confirmation dialog',
	},
	cancel: {
		id: 'polaris-common.config.fields.delete-field.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for the cancelling the field deletion',
	},
	deleteHeader: {
		id: 'polaris-common.config.fields.delete-field.delete-header',
		defaultMessage: 'Do you want to delete the "{fieldLabel}" field?',
		description: 'Header caption in polaris delete field confirmation dialog',
	},
	description: {
		id: 'polaris-common.config.fields.delete-field.description',
		defaultMessage:
			"Once you delete it, you won't be able to retrieve the information stored in this field, and it will also be deleted in any view in the project affecting their configuration and the information they display.",
		description: 'Description in polaris delete field confirmation dialog',
	},
	descriptionForPlayField: {
		id: 'polaris-common.config.fields.delete-field.description-for-play-field',
		defaultMessage:
			"This will delete all user votes and comments that were made using this field, on all ideas. You can't undo this.",
		description: 'Description in polaris delete field confirmation dialog for play field',
	},
	confirmationMessage: {
		id: 'polaris-common.config.fields.delete-field.confirmation-message',
		defaultMessage: 'Type “{fieldName}“ to confirm the deletion:',
		description: 'Confirmation text in polaris delete field confirmation dialog',
	},

	descriptionNoValuesNoViews: {
		id: 'polaris-common.config.fields.delete-field.description-no-values-no-views',
		defaultMessage:
			"This field <b>isn’t used to configure views</b> and <b>doesn't store any value</b>. If you delete it, you won’t lose view configuration nor values.",
		description:
			'Description in polaris delete field confirmation dialog when field has no values and no views',
	},
	descriptionNoValuesHasViews: {
		id: 'polaris-common.config.fields.delete-field.description-no-values-has-views',
		defaultMessage:
			"This field is being used in <b>{viewsCount, plural, one {# view} other {# views}}</b> and <b>doesn't store any value</b>. If you delete it, the configuration of the following views will be affected:",
		description:
			'Description in polaris delete field confirmation dialog when field has no values and has views',
	},
	descriptionHasValuesHasViews: {
		id: 'polaris-common.config.fields.delete-field.description-has-values-has-views',
		defaultMessage:
			'This field is being used in <b>{viewsCount, plural, one {# view} other {# views}}</b> and <b>stores values</b>. If you delete it, <b>the values will be deleted</b> and configuration of the following views will be affected:',
		description:
			'Description in polaris delete field confirmation dialog when field has values and has views',
	},
	descriptionHasValuesNoViews: {
		id: 'polaris-common.config.fields.delete-field.description-has-values-no-views',
		defaultMessage:
			"This field <b>isn’t used to configure views</b> but <b>stores values</b>. If you delete the field, you won't lose view configuration, but the <b>values will be deleted</b>.",
		description:
			'Description in polaris delete field confirmation dialog when field has values and no views',
	},
	descriptionGlobalFieldInUse: {
		id: 'polaris-common.config.fields.delete-field.description-global-field-in-use',
		defaultMessage:
			'{viewsCount, plural, =1 {<b># view</b> is} other {<b># views</b> are}} using this field and its values. If you remove it, the setup of views will be affected in the project. Check the list with affected views:',
		description:
			'Description in polaris delete field confirmation dialog when field is a global field and is in use on some views',
	},
	descriptionGlobalFieldNotInUse: {
		id: 'polaris-common.config.fields.delete-field.description-global-field-not-in-use',
		defaultMessage:
			"This global field <b>isn't used to configure views</b>. Go ahead to remove it.",
		description:
			'Description in polaris delete field confirmation dialog when field is a global field and is not in use on views',
	},
	removeGlobalFieldHeader: {
		id: 'polaris-common.config.fields.delete-field.remove-global-field-header',
		defaultMessage: 'Remove "{fieldLabel}" field from a project',
		description: 'Header caption in polaris remove global field confirmation dialog',
	},
	remove: {
		id: 'polaris-common.config.fields.delete-field.remove',
		defaultMessage: 'Remove',
		description: 'Button label for the global field removal confirmation dialog',
	},
});
