import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import { PLAY_CONTRIBUTION_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/play/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { TransformablePolarisPlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/fetch/types.tsx';
import type {
	UpdatePlayContribution,
	UpdatePlayContributionVariables,
	UpdatePolarisPlayContribution,
} from './types';

const UPDATE_PLAY_CONTRIBUTION_MUTATION = gqlTagPolaris`
mutation jira_polaris_UpdatePlayContribution($id: ID!, $input: UpdatePolarisPlayContribution!, $skipComment: Boolean!) {
  updatePolarisPlayContribution(id: $id, input: $input) {
    success
    errors {
      message
    }
    node {
        ...PlayContribution
    }
  }
}
${PLAY_CONTRIBUTION_FRAGMENT}
`;

export const updatePlayContribution = (
	client: PolarisApolloClient,
	id: string,
	input: UpdatePolarisPlayContribution,
): Promise<TransformablePolarisPlayContribution> => {
	const variables: UpdatePlayContributionVariables = {
		id,
		input,
		skipComment: false,
	};
	return client
		.mutate<UpdatePlayContribution>({
			mutation: UPDATE_PLAY_CONTRIBUTION_MUTATION,
			variables,
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`project-config.update-play-contribution-error:${result.errors
						.map((e) => e.message)
						.join(', ')}`,
				);
			}

			if (!result.data?.updatePolarisPlayContribution?.node) {
				throw new Error('project-config.update-play-contribution-error: no data or no data node');
			}

			return {
				...result.data.updatePolarisPlayContribution.node,
				// FIXME: temporary workaround because the backend is returning null instead of the actual amount
				// ticket: POL-7106
				...(result.data.updatePolarisPlayContribution.node.amount !== input.amount
					? {
							amount: input.amount,
						}
					: {}),
			};
		});
};
