import React from 'react';
import JpdBoardBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-board-blank';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages';
import { Container, Wrapper } from '../../../common/ui/container/styled';
import { ViewEmptyState } from '../../../common/ui/view-empty-state';
import type { ViewKindEmptyStateProps } from '../../../types';
import { messages } from './messages';
import { BoardViewPlaceholder, ColumnPlaceholder } from './styled';

export const BoardViewEmptyState = ({
	onButtonClick,
	isHintHidden = false,
}: ViewKindEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			{!isHintHidden && (
				<Wrapper>
					<ViewEmptyState
						renderImage={JpdBoardBlankIllustrationWrapper}
						header={formatMessage(messages.header)}
						description={formatMessage(messages.description)}
						buttonText={formatMessage(commonMessages.buttonTextFields, {
							fieldsCount: 1,
						})}
						onButtonClick={onButtonClick}
					/>
				</Wrapper>
			)}
			<BoardViewPlaceholder>
				{[...Array.from({ length: 8 })].map((_, i) => (
					<ColumnPlaceholder key={i} />
				))}
			</BoardViewPlaceholder>
		</Container>
	);
};

const JpdBoardBlankIllustrationWrapper = () => <JpdBoardBlankIllustration alt="" width={306} />;
