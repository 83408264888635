import React, { useMemo, useCallback, useEffect, useState } from 'react';
import Button from '@atlaskit/button';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import EditorMediaWrapLeftIcon from '@atlaskit/icon/glyph/editor/media-wrap-left';
import { useIntl } from '@atlassian/jira-intl';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useSelectedIssueKey } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useOpenRightSidebarOnIdeaTemplates } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks';
import {
	useIsRightSidebarOpen,
	useRightSidebarShowing,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingIdeaTemplates } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import {
	usePolarisRouter,
	useIsIssueOpenInFullscreen,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import { useCurrentUserAccountId } from '@atlassian/jira-polaris-common/src/controllers/user';
import { useScrollableContainer } from '@atlassian/jira-polaris-common/src/ui/common/scrollable-container/main';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useConcurrentUpdate } from '@atlassian/jira-polaris-lib-concurrent-updates/src/controllers/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import type { EditorActions } from '@atlassian/jira-polaris-lib-editor/src/ui/editor/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import { ReadViewComponent } from '../read';
import messages from './messages';

const jsonTransformer: JSONTransformer = new JSONTransformer();

export type EditViewProps = {
	description: DocumentFieldValue;
	onConfirm: (value: DocumentFieldValue) => void;
	onCancel: () => void;
};

export const EditViewComponent = (props: EditViewProps) => {
	const { description, onCancel, onConfirm } = props;
	const onOpenRightSidebarOnIdeaTemplates = useOpenRightSidebarOnIdeaTemplates();
	const [sidebarShowing] = useRightSidebarShowing();
	const { saveDescriptionDraft } = useIdeaActions();
	const currentUserAccountId = useCurrentUserAccountId();
	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const { openIssueView } = usePolarisRouter();
	const isIssueOpenInFullscreen = useIsIssueOpenInFullscreen();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [editorActions, setEditorActions] = useState<EditorActions | undefined>();
	const [{ hasConcurrentUpdate, latestUpdate, isLoadingUpdate }] = useConcurrentUpdate();

	const { formatMessage } = useIntl();
	const isTemplatesSidebarOpen =
		isRightSidebarOpen && sidebarShowing.mode === RightSidebarShowingIdeaTemplates;
	const isEditorAiEnabled = useEditorAiEnabled();

	const handleSave = useCallback(
		(value: ADF) => {
			onConfirm(value);
			fireCompoundAnalyticsEvent.IdeaDescriptionUpdated(createAnalyticsEvent({}));
		},
		[createAnalyticsEvent, onConfirm],
	);

	const handleChange = useCallback(
		(value?: ADF) => {
			if (value === undefined) return;
			// @ts-expect-error - Argument of type 'DocNode' is not assignable to parameter of type 'Node'
			const adf = jsonTransformer.encode(value);
			// @ts-expect-error - Argument of type 'JSONDocNode' is not assignable to parameter of type 'DocNode'.
			saveDescriptionDraft(adf, currentUserAccountId);
		},
		[currentUserAccountId, saveDescriptionDraft],
	);

	const safeDescription = useMemo(() => prepareForRender(description), [description]);

	const issueKey = useSelectedIssueKey();

	const scrollableContainer = useScrollableContainer();

	const onOpenFullIdea = useCallback(() => {
		if (!issueKey || isIssueOpenInFullscreen) return;
		openIssueView(issueKey);
	}, [issueKey, openIssueView, isIssueOpenInFullscreen]);

	const openTemplates = useCallback(() => {
		onOpenFullIdea();
		onOpenRightSidebarOnIdeaTemplates((template) =>
			editorActions?.getValue().then((value) => {
				const adfDescription = jsonTransformer.encode(value);
				const descriptionWithTemplate = {
					...adfDescription,
					content: [
						...(adfDescription ? adfDescription.content : []),
						...(template ? template.content : []),
					],
				};
				editorActions?.replaceDocument(descriptionWithTemplate);
			}),
		);
	}, [onOpenRightSidebarOnIdeaTemplates, editorActions, onOpenFullIdea]);

	// Open idea in fullscreen and templates sidebar when templates button in read view is clicked
	useEffect(() => {
		if (isTemplatesSidebarOpen && editorActions) {
			openTemplates();
		}
	}, [openTemplates, editorActions, isTemplatesSidebarOpen]);

	const handleTemplatesButtonClick = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaDescriptionTemplateApplyClicked(createAnalyticsEvent({}));
		openTemplates();
	}, [openTemplates, createAnalyticsEvent]);

	return (
		<WaitForAdfConsumerProps forceLoadingView={false}>
			{({ akEditorProps }) => (
				<EditorBoundary fallback={<ReadViewComponent description={description} isEditingAllowed />}>
					<Editor
						testId="polaris-ideas.ui.idea-view.description.content.edit.editor"
						{...akEditorProps}
						onEditorReady={setEditorActions}
						defaultValue={safeDescription}
						shouldFocus
						placeholder={formatMessage(messages.descriptionPlaceholder)}
						popupsMountPoint={document?.body}
						popupsScrollableElement={scrollableContainer || undefined}
						allowFullEditorCapabilities
						useStickyToolbar
						onSave={handleSave}
						onChange={handleChange}
						onCancel={onCancel}
						extraButtons={
							<Button
								isSelected={isTemplatesSidebarOpen}
								// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
								iconBefore={<EditorMediaWrapLeftIcon label="Editor templates" />}
								onClick={handleTemplatesButtonClick}
							>
								{formatMessage(messages.templatesButton)}
							</Button>
						}
						isAiEnabled={isEditorAiEnabled}
						hasConcurrentUpdate={hasConcurrentUpdate}
						latestUpdate={latestUpdate}
						isLoadingUpdate={isLoadingUpdate}
					/>
				</EditorBoundary>
			)}
		</WaitForAdfConsumerProps>
	);
};
