import { createSelector } from 'reselect';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
	AVG_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { FieldRollupOperation } from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Props, PartialRecord } from '../types';
import { tryParseIntervalString } from '../utils/field-mapping/interval/utils';
import type { FieldMapping } from '../utils/field-mapping/types';
import { createGetFieldMapping, getFieldMappings, createGetIsWeightedField } from './fields';
import { createGetLocalIssueIdsByGroupIdentity } from './grouping';

const calculateAvg = (sum: number, count: number): number => {
	if (count === 0) {
		return 0;
	}

	return sum / count;
};

/**
 * Calculates the rollup values for a number field.
 */
const calcNumberRollupValues = (
	values: (number | undefined)[],
): PartialRecord<FieldRollupOperation, number> => {
	const rollupValues = {
		[AVG_ROLLUP]: 0,
		[MEDIAN_ROLLUP]: 0,
		[SUM_ROLLUP]: 0,
	};
	// count sum
	rollupValues[SUM_ROLLUP] = values.reduce<number>((sum, val) => sum + (val ?? 0), 0);

	// count average
	rollupValues[AVG_ROLLUP] = calculateAvg(rollupValues[SUM_ROLLUP], values.length);

	// count median
	const sortedValues = values.map((val) => val ?? 0).sort((a, b) => a - b);
	const half = Math.floor(sortedValues.length / 2);
	if (sortedValues.length === 0) {
		rollupValues[MEDIAN_ROLLUP] = 0;
	} else if (sortedValues.length % 2) {
		rollupValues[MEDIAN_ROLLUP] = sortedValues[half];
	} else {
		rollupValues[MEDIAN_ROLLUP] = (sortedValues[half - 1] + sortedValues[half]) / 2;
	}

	return rollupValues;
};

/**
 * Calculates the rollup values for a select field.
 */
const calcOptionsRollupValues = (
	values: (OptionFieldValue | OptionFieldValue[] | undefined)[],
): PartialRecord<FieldRollupOperation, number> => {
	const rollupValues = {
		[EMPTY_ROLLUP]: 0,
		[FILLED_ROLLUP]: 0,
		[COUNT_ROLLUP]: 0,
	};
	const options = new Set();

	values.forEach((value) => {
		if (Array.isArray(value)) {
			if (value.length) {
				value.forEach((v) => options.add(v.id));
				rollupValues[FILLED_ROLLUP] += 1;
			} else {
				rollupValues[EMPTY_ROLLUP] += 1;
			}
		} else if (value !== undefined) {
			options.add(value.id);
			rollupValues[FILLED_ROLLUP] += 1;
		} else {
			rollupValues[EMPTY_ROLLUP] += 1;
		}
	});
	rollupValues[COUNT_ROLLUP] = options.size;

	return rollupValues;
};

export const createGetFieldRollupOperations = (fieldKey?: FieldKey) => {
	const getFieldMapping = createGetFieldMapping(fieldKey);

	return createSelector(getFieldMapping, (fieldMapping) => {
		if (fieldMapping === undefined) {
			return [];
		}

		const rollupOperations = fieldMapping?.getRollupOperations?.();
		return rollupOperations ?? [];
	});
};

// COMMON ROLLUPS SELECTOR
export const createGetFieldsRollupValues = <FieldMappingType,>(
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
) => {
	const getLocalIssueIdsByGroupIdentity = createGetLocalIssueIdsByGroupIdentity(groupFieldKey);

	return createSelector(
		getFieldMappings,
		getLocalIssueIdsByGroupIdentity,
		(state) => state,
		(_, props: Props | undefined) => props,
		(fieldMappings, groupedIds, state, props) => {
			const values: (FieldMappingType | undefined)[] = [];

			const fieldMapping: FieldMapping<FieldMappingType> = fieldMappings[fieldKey];
			if (fieldMapping === undefined) {
				return values;
			}

			const ids = groupedIds.groups[groupId] || groupedIds.empty;
			ids?.forEach((id) => {
				const value = fieldMapping.valueAccessor(state, props, id);
				values.push(value);
			});

			return values;
		},
	);
};

// WEIGHTED SELECT ROLLUPS SELECTOR
export const createGetWeightedSelectRollupValues = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
) => {
	const getLocalIssueIdsByGroupIdentity = createGetLocalIssueIdsByGroupIdentity(groupFieldKey);

	return createSelector(
		getFieldMappings,
		getLocalIssueIdsByGroupIdentity,
		(state) => state,
		(_, props: Props | undefined) => props,
		(fieldMappings, groupedIds, state, props) => {
			const values: number[] = [];

			const fieldMapping: FieldMapping<OptionFieldValue | OptionFieldValue[]> =
				fieldMappings[fieldKey];
			if (fieldMapping === undefined) {
				return values;
			}

			const issueIds = groupedIds.groups[groupId] || groupedIds.empty;
			issueIds?.forEach((issueId) => {
				const value = fieldMapping.valueAccessor(state, props, issueId);
				if (Array.isArray(value)) {
					const weight = value.reduce(
						(acc, { id }) => acc + (fieldMapping.getWeight?.(id) ?? 0),
						0,
					);
					values.push(weight);
				} else if (value !== undefined) {
					values.push(fieldMapping.getWeight?.(value.id) ?? 0);
				} else {
					values.push(0);
				}
			});

			return values;
		},
	);
};

// NUMBER FIELDS ROLLUPS
export const createGetNumberFieldsRollup = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
) => {
	const getFieldsRollupValues = createGetFieldsRollupValues<number>(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	return createSelector(getFieldsRollupValues, (values) => calcNumberRollupValues(values));
};

// USER FIELDS ROLLUPS
export const createGetUserFieldsRollup = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
	rollupOperation: FieldRollupOperation,
) => {
	const getFieldsRollupValues = createGetFieldsRollupValues<UserFieldValue>(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	return createSelector(getFieldsRollupValues, (values) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const rollupValues = { [EMPTY_ROLLUP]: 0, [FILLED_ROLLUP]: 0, [COUNT_ROLLUP]: 0 } as Record<
			FieldRollupOperation,
			number
		>;
		const users = new Set();
		values.forEach((value) => {
			if (Array.isArray(value)) {
				value.forEach((v) => users.add(v.accountId));
				rollupValues[FILLED_ROLLUP] += 1;
			} else if (value !== undefined) {
				users.add(value.accountId);
				rollupValues[FILLED_ROLLUP] += 1;
			} else {
				rollupValues[EMPTY_ROLLUP] += 1;
			}
		});
		rollupValues[COUNT_ROLLUP] = users.size;

		return rollupValues[rollupOperation];
	});
};

// COMMENT FIELDS ROLLUPS
export const createGetCommentFieldsRollup = (
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
	rollupOperation: FieldRollupOperation,
) => {
	const getLocalIssueIdsByGroupIdentity = createGetLocalIssueIdsByGroupIdentity(groupFieldKey);

	return createSelector(
		getLocalIssueIdsByGroupIdentity,
		(state) => state.properties.issueMetadata,
		(groupedIds, issueMetadata) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const rollupValues = {
				[EMPTY_ROLLUP]: 0,
				[FILLED_ROLLUP]: 0,
				[COUNT_ROLLUP]: 0,
			} as Record<FieldRollupOperation, number>;
			const ids = groupedIds.groups[groupId] || groupedIds.empty;
			ids?.forEach((id) => {
				if (issueMetadata[id] && issueMetadata[id]?.comments?.count) {
					rollupValues[COUNT_ROLLUP] += issueMetadata[id].comments.count;
					rollupValues[FILLED_ROLLUP] += 1;
				} else {
					rollupValues[EMPTY_ROLLUP] += 1;
				}
			});
			return rollupValues[rollupOperation];
		},
	);
};

// SELECT FIELDS ROLLUPS
export const createGetSelectFieldsRollup = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
	rollupOperation: FieldRollupOperation,
) => {
	const getFieldsRollupValues = createGetFieldsRollupValues<OptionFieldValue | OptionFieldValue[]>(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	const getWeightedSelectRollupValues = createGetWeightedSelectRollupValues(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	const getIsWeightedField = createGetIsWeightedField(fieldKey);

	return createSelector(
		getFieldsRollupValues,
		getWeightedSelectRollupValues,
		getIsWeightedField,
		(values, weights, isWeightedField) => {
			const rollupValues = isWeightedField
				? calcNumberRollupValues(weights)
				: calcOptionsRollupValues(values);
			return rollupValues[rollupOperation];
		},
	);
};

// LABEL FIELDS ROLLUPS
export const createGetLabelsFieldsRollup = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
	rollupOperation: FieldRollupOperation,
) => {
	const getFieldsRollupValues = createGetFieldsRollupValues<string[]>(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	return createSelector(getFieldsRollupValues, (values) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const rollupValues = { [EMPTY_ROLLUP]: 0, [FILLED_ROLLUP]: 0, [COUNT_ROLLUP]: 0 } as Record<
			FieldRollupOperation,
			number
		>;
		const labels = new Set();
		values.forEach((value) => {
			if (Array.isArray(value)) {
				value.forEach((v) => labels.add(v));
				if (value.length) {
					rollupValues[FILLED_ROLLUP] += 1;
				} else {
					rollupValues[EMPTY_ROLLUP] += 1;
				}
			} else {
				rollupValues[EMPTY_ROLLUP] += 1;
			}
		});
		rollupValues[COUNT_ROLLUP] = labels.size;
		return rollupValues[rollupOperation];
	});
};

// STRING FIELDS ROLLUPS
export const createGetStringFieldsRollup = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
	rollupOperation: FieldRollupOperation,
) => {
	const getFieldsRollupValues = createGetFieldsRollupValues<string>(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	return createSelector(getFieldsRollupValues, (values) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const rollupValues = {
			[EMPTY_ROLLUP]: 0,
			[FILLED_ROLLUP]: 0,
		} as Record<FieldRollupOperation, number>;
		values.forEach((value) => {
			if (!value) {
				rollupValues[EMPTY_ROLLUP] += 1;
			} else {
				rollupValues[FILLED_ROLLUP] += 1;
			}
		});
		return rollupValues[rollupOperation];
	});
};

// INTERVAL FIELDS ROLLUPS
export const createGetIntervalFieldsRollup = (
	fieldKey: FieldKey,
	groupFieldKey: FieldKey,
	groupId: LocalIssueId,
) => {
	const getFieldsRollupValues = createGetFieldsRollupValues<string>(
		fieldKey,
		groupFieldKey,
		groupId,
	);

	return createSelector(getFieldsRollupValues, (values) => {
		const rollupValues: { min: string; max: string } = {
			min: '',
			max: '',
		};
		values.forEach((value) => {
			const interval = value && tryParseIntervalString(value);
			if (interval) {
				const { start, end } = interval;
				if (!rollupValues.min || end < rollupValues.min) {
					rollupValues.min = end;
				}
				if (!rollupValues.max || start > rollupValues.max) {
					rollupValues.max = start;
				}
			}
		});
		return rollupValues.min && rollupValues.max ? rollupValues : undefined;
	});
};
