import { TEMPO_ADDON_KEY } from './constants';
import {
	useEcosystemActionsStore,
	useEcosystemStoreContentPanels,
	useEcosystemStoreGlancePanels,
	useEcosystemStoreContextPanels,
	useEcosystemStoreKey,
} from './context';

export const useEcosystemGlances = () => {
	const [glances] = useEcosystemStoreGlancePanels();
	return [glances];
};

export const useEcosystemContextPanels = () => {
	const [contextPanels] = useEcosystemStoreContextPanels();
	return [contextPanels] as const;
};

export const useEcosystemContentPanel = (appKey: string, moduleKey: string) => {
	const [contentPanel] = useEcosystemStoreContentPanels();
	return contentPanel[appKey]?.[moduleKey];
};

export const useEcosystemGlancePanel = (appKey: string, moduleKey: string) => {
	const [contentPanel] = useEcosystemGlances();
	return contentPanel[appKey]?.[moduleKey];
};

export const useIsTempoInstalled = () => {
	const [contentPanel] = useEcosystemStoreContentPanels();

	return TEMPO_ADDON_KEY in contentPanel;
};

export const useEcosystemActions = () => {
	const [, { setEcosystem }] = useEcosystemActionsStore();
	return [undefined, { setEcosystem }] as const;
};

/**
 * @returns {true} if the information in the sweet-state
 * @param issueKey
 */
export const useIsEcosystemDataLoadedFor = (issueKey: string) =>
	useEcosystemStoreKey() === issueKey;
