import type { ContainersByType } from '@atlassian/jira-issue-layout-common-constants';
import type { IssueKey } from '@atlassian/jira-shared-types';
import type { ContextPanelItem, Glance, StoreApi } from '../types';

export const setIssueViewContainersLayout =
	(issueKey: IssueKey, containersByType: ContainersByType) =>
	({ getState, setState }: StoreApi) => {
		setState({
			[String(issueKey)]: {
				...getState()[String(issueKey)],
				containers: containersByType?.containers || [],
			},
		});
	};

export const setIssueViewLayoutGlance =
	(issueKey: IssueKey, glance?: Glance) =>
	({ getState, setState }: StoreApi) => {
		setState({
			[String(issueKey)]: {
				...getState()[String(issueKey)],
				glance,
			},
		});
	};

export const setIssueViewLayoutContextPanel =
	(issueKey: IssueKey, contextPanel?: ContextPanelItem) =>
	({ getState, setState }: StoreApi) => {
		setState({
			[String(issueKey)]: {
				...getState()[String(issueKey)],
				contextPanel,
			},
		});
	};
