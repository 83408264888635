import uuid from 'uuid';
import type { LocalViewId, ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import {
	modifyView,
	updateProjectState,
	transformRemoteView,
	createFakeId,
	findView,
	makeNewTitle,
} from '../utils';

export const cloneView =
	(viewId: LocalViewId, viewType: ViewKind): Action<State, Props> =>
	async (
		{ getState, setState },
		{ navigationRemote, cloudId, projectId, onFailure, onSuccess, onViewCreated },
	) => {
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view, parent } = findView(getState().projects[projectId], viewId);
		if (!view) {
			return;
		}

		if (viewType === 'SECTION' || viewType === 'TWOXTWO') {
			return;
		}

		const newViewId = createFakeId();
		const name = makeNewTitle(view.name);
		const newView = {
			id: newViewId,
			polarisId: 0,
			localId: uuid.v4(),
			name,
			viewType,
			views: undefined,
			isLocked: true,
			isManuallyCreated: true,
		};

		if (parent) {
			setState(
				updateProjectState(getState(), projectId, {
					views: getState().projects[projectId].views.map((innerView) =>
						innerView.localId === parent.localId
							? {
									...innerView,
									views: [...(innerView.views || []), newView],
								}
							: innerView,
					),
				}),
			);
		} else {
			setState(
				updateProjectState(getState(), projectId, {
					views: [...getState().projects[projectId].views, newView],
				}),
			);
		}

		try {
			const remoteView = await navigationRemote.cloneView({
				id: view.id,
				viewType,
				name,
			});

			setState(
				modifyView(getState(), projectId, (innerView) =>
					innerView.localId === newView.localId
						? {
								...transformRemoteView(remoteView),
								localId: newView.localId,
								isManuallyCreated: true,
								isLocked: false,
							}
						: innerView,
				),
			);
			onSuccess?.('cloneView');
			const navView = findView(getState().projects[projectId], newView.localId).view;
			if (navView) {
				onViewCreated?.(navView);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState(
				modifyView(getState(), projectId, (innerView) =>
					innerView.localId === newView.localId ? { ...innerView, isLocked: false } : innerView,
				),
			);

			onFailure?.(error, 'cloneView');
		}
	};
