import { useMemo } from 'react';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import {
	useCurrentMatrixFieldOptions,
	useCurrentMatrixOptionsHasReverseOrder,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import type { ExtendedOption } from '../../../../common/utils/board';
import { useExtendedOptionsInNaturalOrder, useRestrictedOptions } from './field-visibility-options';

/**
 * This calculates the options used for matrix axis.
 * */
export const useMatrixAxisFieldOptions = (
	field: Field | undefined,
	includeAllOptions = false,
	dimension: string,
): ExtendedOption<unknown>[] => {
	const currentMatrixAxisOptions = useCurrentMatrixFieldOptions(dimension);
	const isReverseOrder = useCurrentMatrixOptionsHasReverseOrder(dimension);

	// apply restrictions
	const restrictedOptions = useRestrictedOptions(field);
	const allOptions = useExtendedOptionsInNaturalOrder(field);
	const options = includeAllOptions ? allOptions : restrictedOptions;

	// apply sort order of view configuration, if present
	return useMemo(() => {
		const handledGroupIdentities: Array<string> = [];
		const sortOptions: Array<ExtendedOption<unknown>> = [];

		const optionsByGroupIdentity = keyBy(options, ({ groupIdentity }) => groupIdentity);
		currentMatrixAxisOptions.forEach((groupValue: GroupValue) => {
			if (groupValue.id !== undefined && has(optionsByGroupIdentity, groupValue.id)) {
				handledGroupIdentities.push(groupValue.id);
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				sortOptions.push(optionsByGroupIdentity[groupValue.id] as ExtendedOption<unknown>);
			}
		});
		options.forEach((option) => {
			if (
				option.groupIdentity !== undefined &&
				// @ts-expect-error - TS2367 - This condition will always return 'true' since the types 'string' and 'number' have no overlap.
				option.groupIdentity !== 0 &&
				!handledGroupIdentities.includes(option.groupIdentity)
			) {
				sortOptions.push(option);
			}
		});
		if (isReverseOrder) {
			return [...sortOptions].reverse();
		}
		return sortOptions;
	}, [currentMatrixAxisOptions, isReverseOrder, options]);
};
