import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	defaultTemplateTitle: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template.default-template-title',
		defaultMessage: 'Title',
		description: 'Default title for template',
	},
	defaultTemplateDescription: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template.default-template-description',
		defaultMessage: 'No description',
		description: 'Default description for template',
	},
	createTemplateTitle: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template.create-template-title',
		defaultMessage: 'Create a template',
		description: 'Title for creating custom template',
	},
	createTemplateDescription: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template.create-template-description',
		defaultMessage:
			'Create a template to standardize how ideas are defined by everyone on your team.',
		description: 'Description for creating custom template',
	},
});
