import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	SnippetProviderProperty,
	SnippetProvider,
} from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import { isSumFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/composition/index.tsx';
import type { FieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/field/types.tsx';
import type { DataPointCountFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/insight-count/types.tsx';
import type { MultiselectCountFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/multi-select-count/types.tsx';
import type { PropertyAggregationFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/property-aggregation/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import {
	isWeightedScoreFormula,
	getDecomposedTerms,
} from '@atlassian/jira-polaris-lib-formula/src/utils/formula/weighted-score/index.tsx';
import { POLARIS_OAUTH_CLIENT_ID } from '../../../../../../../common/types/snippet/constants';
import { useVisibleFieldsByKey } from '../../../../../../../controllers/field/selectors/field-hooks.tsx';
import { useSnippetProviders } from '../../../../../../../controllers/field/selectors/snippet-providers-hooks.tsx';
import {
	type FieldRollupField,
	type PropertyRollupField,
	type RollupField,
	FIELD_ROLLUP,
	PROPERTY_ROLLUP,
} from '../../types';

export type RollupFormulaState = {
	rollupFields: RollupField[];
	percentages: (number | undefined)[];
	weightedScore: boolean;
};

export const emptyState = (initAsWeightedScore: boolean): RollupFormulaState => ({
	rollupFields: [],
	percentages: [],
	weightedScore: initAsWeightedScore,
});

export const emptyRollupState = (): RollupFormulaState => emptyState(false);

const createPropertyRollupItem = (
	input: PropertyAggregationFormula,
	snippetProviders: SnippetProvider[],
): PropertyRollupField | undefined => {
	const provider = snippetProviders.find(
		(it) => it.app.oauthClientId === input.parameters.oauthClientId,
	);
	const property = provider?.properties.find(
		(it: SnippetProviderProperty) => it.key === input.parameters.key,
	);
	if (provider !== undefined && property !== undefined) {
		return {
			kind: PROPERTY_ROLLUP,
			value: {
				...property,
				appName: provider.app.name,
				avatarUrl: provider.app.avatarUrl,
				oauthClientId: provider.app.oauthClientId,
				filter: input.parameters.filter,
			},
		};
	}
	return undefined;
};

const createFieldRollupItem = (
	input: FieldFormula | MultiselectCountFormula,
	fieldsByKey: FieldMap,
): FieldRollupField | undefined => {
	const value = fieldsByKey[input.parameters.id];

	if (value) {
		return {
			kind: FIELD_ROLLUP,
			value,
		};
	}
	return undefined;
};

const createNumDataPointsItem = (
	formula: DataPointCountFormula,
	snippetProviders: SnippetProvider[],
): RollupField | undefined => {
	if (
		formula.parameters === undefined ||
		formula.parameters.oauthClientId === undefined ||
		formula.parameters.oauthClientId === 'polaris-num-data-points'
	) {
		return {
			kind: 'count',
			value: {
				id: 'polaris-num-data-points',
				appName: '',
				label: 'Number of insights',
				oauthClientId: POLARIS_OAUTH_CLIENT_ID,
				filter: formula.parameters?.filter,
			},
		};
	}
	const snippetProvider = snippetProviders.find(
		(provider) => provider.app.oauthClientId === formula.parameters?.oauthClientId,
	);
	if (snippetProvider !== undefined) {
		return {
			kind: 'count',
			value: {
				id: `${snippetProvider.app.oauthClientId}-num-data-points`,
				appName: snippetProvider.app.name,
				label: 'Number of insights',
				oauthClientId: snippetProvider.app.oauthClientId,
				avatarUrl: snippetProvider.app.avatarUrl,
				filter: formula.parameters?.filter,
			},
		};
	}
	return undefined;
};

const createRollupItem = (
	formula: DynamicFieldFormula,
	fieldsByKey: FieldMap,
	snippetProviders: Array<SnippetProvider>,
): RollupField | undefined => {
	if (formula.template === 'field') {
		return createFieldRollupItem(formula, fieldsByKey);
	}
	if (formula.template === 'property_agg') {
		return createPropertyRollupItem(formula, snippetProviders);
	}
	if (formula.template === 'play_agg') {
		// @ts-expect-error - TS2367 - This condition will always return 'false' since the types '"num_data_points" | "composition" | "norm" | "const" | "linked_issues" | "expr" | "discretization" | "multi-select-count"' and '"play_agg"' have no overlap.
		return createFieldRollupItem(formula, fieldsByKey);
	}
	if (formula.template === 'num_data_points') {
		return createNumDataPointsItem(formula, snippetProviders);
	}
	if (formula.template === 'multi-select-count') {
		return createFieldRollupItem(formula, fieldsByKey);
	}
	return undefined;
};

const parseRollupFormula = (
	rootFormula: DynamicFieldFormula,
	fieldsByKey: FieldMap,
	snippetProviders: SnippetProvider[],
): RollupFormulaState => {
	if (isWeightedScoreFormula(rootFormula)) {
		const getRollupFieldFactory =
			(negative: boolean) =>
			({ formula, factor }: { formula: DynamicFieldFormula; factor: number }) => {
				const rollupField = createRollupItem(formula, fieldsByKey, snippetProviders);

				return rollupField ? { rollupField: { ...rollupField, negative }, factor } : undefined;
			};

		const [positiveWeights, negativeWeights] = getDecomposedTerms(rootFormula);
		const weightedRollupFields = [
			...positiveWeights.map(getRollupFieldFactory(false)),
			...negativeWeights.map(getRollupFieldFactory(true)),
		].filter(Boolean);

		return {
			rollupFields: weightedRollupFields.map(
				(weightedRollupField) => weightedRollupField.rollupField,
			),
			percentages: weightedRollupFields.map((weightedRollupField) => weightedRollupField.factor),
			weightedScore: true,
		};
	}
	if (isSumFormula(rootFormula)) {
		const rollupFields = rootFormula.parameters.formulas
			.map((subFormula) => createRollupItem(subFormula, fieldsByKey, snippetProviders))
			.filter(Boolean);
		return { rollupFields, percentages: new Array(rollupFields.length), weightedScore: false };
	}
	return emptyRollupState();
};

export const useRollupFormulaParser = (): ((arg1: DynamicFieldFormula) => RollupFormulaState) => {
	const [snippetProviders] = useSnippetProviders();
	const [fieldsByKey] = useVisibleFieldsByKey();

	return (formula: DynamicFieldFormula) =>
		parseRollupFormula(formula, fieldsByKey, snippetProviders);
};
