import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mapTo';
import {
	ADD_WORKLOG_SUCCESS,
	EDIT_WORKLOG_SUCCESS,
	type AddWorklogSuccessAction,
	type EditWorklogSuccessAction,
} from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions';
import { fetchViewContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions';

const fetchViewContextOnWorklogSuccess = (
	action$: ActionsObservable<AddWorklogSuccessAction | EditWorklogSuccessAction>,
) =>
	action$
		.ofType(EDIT_WORKLOG_SUCCESS, ADD_WORKLOG_SUCCESS)
		.filter((action) => action.meta.shouldFetchViewContext)
		.mapTo(fetchViewContextRequest());

export default fetchViewContextOnWorklogSuccess;
