import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import authenticateUsingAppLinks from '@atlassian/jira-common-authenticate-using-app-links';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import {
	applinkAuthenticationSuccess,
	APPLINK_AUTHENTICATION_REQUEST,
	type ApplinkAuthenticationRequest,
} from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(APPLINK_AUTHENTICATION_REQUEST)
		.mergeMap((action: ApplinkAuthenticationRequest) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const href = action.payload;
			const { analyticsEvent } = action.meta;

			const promise = authenticateUsingAppLinks(`${baseUrl}${href}`);
			if (analyticsEvent) {
				fireTrackAnalytics(analyticsEvent, 'applink authenticate', { authStatus: 'start' });
			}
			if (promise) {
				return Observable.fromPromise(promise)
					.map(() => {
						if (analyticsEvent) {
							fireTrackAnalytics(analyticsEvent, 'applink authenticate', {
								authStatus: 'success',
							});
						}
						return applinkAuthenticationSuccess();
					})
					.catch((error) => {
						trackOrLogClientError(
							'issue-applink-authentication-epic',
							'Failed to authenticate with applinks',
							error,
						);
						return Observable.empty<never>();
					});
			}
			return Observable.empty<never>();
		});
