import difference from 'lodash/difference';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ParseNode } from '@atlassian/jira-polaris-lib-expressions';
import { parse } from '@atlassian/jira-polaris-lib-expressions/src/common/utils/parser/main.tsx';
import type { RecursiveFilter } from '@atlassian/jira-polaris-lib-formula/src/utils/filter/types.tsx';
import { SUPPORTED_FIELD_TYPES } from './constants';
import { ExpressionError } from './error';
import { messages } from './messages';
import type { CompilationResult, Filter, MentionedField, ParsedFormula } from './types';

const combineApp = (a?: string, b?: string): string | undefined => {
	if (a === undefined) {
		return b;
	}
	if (b === undefined) {
		return a;
	}
	throw new ExpressionError(messages.noMultipleAppsError);
};

const combineFilter = (
	mode: 'and' | 'or',
	a?: RecursiveFilter,
	b?: RecursiveFilter,
): RecursiveFilter | undefined => {
	if (a && b) {
		const both: RecursiveFilter[] = [a, b];

		if (mode === 'and') {
			return {
				template: 'conjunction',
				parameters: {
					filters: both,
				},
			};
		}
		return {
			template: 'disjunction',
			parameters: {
				filters: both,
			},
		};
	}
	return a || b;
};

const resolveApp = (given: string): string => {
	// TODO get this actually from the backend
	switch (given.toLowerCase()) {
		case 'chrome':
		case 'polaris chrome extension (stg)':
			return 'OpYAgsj5dkaSOsvhgdChAcMYEtmSKNwy';
		case 'polaris-zendesk-stg':
			return 'rDfB1Zp3cs8OXrq7TTEhllvy6rE3SScg';
		default:
			return given;
	}
};

const compileBoolFilter = (node: ParseNode): Filter => {
	if (node.type === 'EQ') {
		if (node.left.type === 'field' && node.right.type === 'literal-string') {
			if (node.left.name === 'app') {
				return {
					app: resolveApp(node.right.value),
				};
			}
		}
	}
	throw new Error(`cannot build filter using ${node.type}`);
};

const compileFilter = (node: ParseNode): Filter | null => {
	if (node.type === 'and') {
		return node.args.reduce<Filter | null>((a, b) => {
			const f2 = compileFilter(b);
			if (a === null) {
				return f2;
			}
			if (f2 === null) {
				return a;
			}
			return {
				app: combineApp(a.app, f2.app),
				insight: combineFilter('and', a.insight, f2.insight),
			};
		}, null);
	}

	if (node.type === 'or') {
		return node.args.reduce<Filter | null>((a, b) => {
			const f2 = compileFilter(b);
			if (a === null) {
				return f2;
			}
			if (f2 === null) {
				return a;
			}
			return {
				app: combineApp(a.app, f2.app),
				insight: combineFilter('or', a.insight, f2.insight),
			};
		}, null);
	}

	if (node.type === 'in') {
		if (node.left.type !== 'literal-string') {
			throw new Error('invalid use of in operator (LHS not a string literal)');
		}
		if (node.right.type === 'field') {
			// EXPR in {FIELD}
			return {
				insight: {
					template: 'includeInStringListProperty',
					parameters: {
						propertyKey: node.right.name,
						values: [node.left.value],
					},
				},
			};
		}
		throw new Error('invalid use of in operator (RHS not a field)');
	}

	// TODO we should support negation (NOT) as well; the evaluation engine
	// supports it.

	if (node.type === 'EQ') {
		return compileBoolFilter(node);
	}

	// our evaluation engine does not actually support comparison operators,
	// so we can't compile those, or anything else.

	throw new Error(`dont know how to compile filter from ${node.type}`);
};

class ParseWalker {
	fields: Field[] = [];

	restrictedFields: Field[] = [];

	cyclic: Record<Ari, boolean>;

	mentioned: MentionedField[];

	seen: Record<Ari, boolean>;

	constructor(fields: Field[], allowedFields: Field[]) {
		fields.forEach((field) => {
			const isHidden = field.configuration?.hidden;
			const isRestricted = field.hasRestrictedContext;
			if (!isHidden && !isRestricted) {
				this.fields.push(field);
			}
			if (isRestricted) {
				this.restrictedFields.push(field);
			}
		});
		this.cyclic = {};

		// mark every field in `fields` that is not in `allowed` as cycle-inducing
		// (currently, the only reason a field would be disallowed is due to cycles;
		// in the future if we have other carveouts, we'll want to refactor this)
		difference(
			fields.map((f) => f.key),
			allowedFields.map((f) => f.key),
		).forEach((fieldKey) => {
			this.cyclic[fieldKey] = true;
		});
		this.mentioned = [];
		this.seen = {};
	}

	checkFieldRestricted(name: string) {
		const nameLower = name.toLocaleLowerCase();
		return this.restrictedFields.some((field) => field.label.toLocaleLowerCase() === nameLower);
	}

	lookup(name: string) {
		const nameLower = name.toLowerCase();
		if (fg('polaris_duplicate_expression_input_error')) {
			const fieldsWithName = this.fields.filter((field) => field.label.toLowerCase() === nameLower);
			if (fieldsWithName.length > 1) {
				throw new ExpressionError(messages.multipleFieldsError, name);
			}
			if (fieldsWithName.length === 1) {
				return fieldsWithName[0];
			}
		} else {
			return this.fields.find((field) => field.label.toLowerCase() === nameLower);
		}
	}

	compile(item: ParseNode): ParsedFormula {
		const xbinop = (agg: 'prod' | 'sum' | 'diff' | 'quot', args: ParseNode[]): ParsedFormula => ({
			template: 'composition',
			parameters: {
				agg,
				formulas: args.map((node) => this.compile(node)),
			},
		});

		const funcallCount = (args: ParseNode[]): ParsedFormula => {
			if (args.length === 0) {
				return {
					template: 'num_data_points',
				};
			}
			const f = compileFilter(args[0]);
			if (f === null) {
				throw new Error('invalid filter argument to count()');
			}
			return {
				template: 'num_data_points',
				parameters: {
					filter: f.insight,
					oauthClientId: f.app,
				},
			};
		};

		const funcallSum = (args: ParseNode[]): ParsedFormula => {
			// TODO handle sum("property", filter)
			if (args.length < 1 || args.length > 2) {
				throw new ExpressionError(messages.sumArgumentCountError);
			}
			const key = args[0];
			if (key.type !== 'field') {
				throw new ExpressionError(messages.sumFieldRequiredError);
			}
			if (args.length >= 2) {
				const filter = compileFilter(args[1]);

				if (filter === null) {
					throw new Error('missing filter in sum()');
				}
				if (filter.app === undefined) {
					throw new ExpressionError(messages.appRequiredError);
				}
				return {
					template: 'property_agg',
					parameters: {
						agg: 'sum',
						key: key.name,
						oauthClientId: filter.app,
						filter: filter.insight,
					},
				};
			}
			return {
				template: 'property_agg',
				parameters: {
					agg: 'sum',
					key: key.name,
					oauthClientId: 'polaris',
				},
			};
		};

		const funcall = (head: string, args: ParseNode[]): ParsedFormula => {
			switch (head) {
				case 'count':
					return funcallCount(args);
				case 'sum':
					return funcallSum(args);
				default:
					throw new Error(`unknown function ${head}`);
			}
		};

		switch (item.type) {
			case 'field': {
				if (this.checkFieldRestricted(item.name)) {
					throw new ExpressionError(messages.fieldRestrictedError, item.name);
				}

				const field = this.lookup(item.name);
				if (field === undefined) {
					throw new ExpressionError(messages.noSuchFieldError, item.name);
				}

				const { type } = field;
				if (!SUPPORTED_FIELD_TYPES.includes(type)) {
					throw new ExpressionError(messages.unsupportedFieldError, item.name);
				}

				if (this.cyclic[field.key]) {
					throw new ExpressionError(messages.cyclicReferenceError, item.name);
				}

				if (!this.seen[field.key]) {
					this.mentioned.push({
						label: item.name,
						id: field.key,
					});
					this.seen[field.key] = true;
				}

				const template =
					type === FIELD_TYPES.SINGLE_SELECT ||
					type === FIELD_TYPES.MULTI_SELECT ||
					type === FIELD_TYPES.JSW_MULTI_SELECT
						? 'multi-select-count'
						: 'field';

				return {
					// @ts-expect-error - TS2322 - Type '"field" | "multi-select-count"' is not assignable to type '"num_data_points" | "composition" | "const" | "field" | "property_agg"'.
					template,
					parameters: {
						id: field.key,
					},
				};
			}

			case 'plus':
				return xbinop('sum', item.args);
			case 'minus':
				return xbinop('diff', item.args);
			case 'times':
				return xbinop('prod', item.args);
			case 'divide':
				return xbinop('quot', item.args);
			case 'literal-number':
				return {
					template: 'const',
					parameters: {
						value: item.value,
					},
				};
			case 'call':
				return funcall(item.func, item.args);
			default:
				throw new Error(`unexpected node type ${item.type}`);
		}
	}
}

export const compile = (
	expression: string,
	fields: Field[],
	allowedFields?: Field[],
): CompilationResult => {
	try {
		const parsedExpression = parse(expression);
		const walker = new ParseWalker(fields, allowedFields === undefined ? fields : allowedFields);
		try {
			const formula = walker.compile(parsedExpression);
			return {
				fields: walker.mentioned,
				formula: {
					template: 'expr',
					parameters: {
						expression,
						formula,
					},
				},
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof ExpressionError) {
				return {
					fields: walker.mentioned,
					error,
				};
			}
			return {
				fields: walker.mentioned,
				error: new ExpressionError(messages.internalExpressionError),
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return {
			fields: [],
			error: new ExpressionError(messages.invalidExpressionError),
		};
	}
};
