import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import differenceBy from 'lodash/differenceBy';
import isArray from 'lodash/isArray';
import deepEquals from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/use-debounce
import { useDebouncedCallback } from 'use-debounce';
import uuid from 'uuid';
import Select from '@atlaskit/select';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import logs from '@atlassian/jira-common-util-logging/src/log';
import { useIntl } from '@atlassian/jira-intl';
import {
	EXTERNAL_REFERENCE_PROVIDERS,
	type ExternalReferenceProvider,
} from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { sendPendoTrackEvent as sendTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals';
import { useStateWithRef } from '../../../../common/utils/react/hooks';
import { useAllExternalReferencesPropertiesForProvider } from '../../../../controllers/issue/selectors/properties/hooks';
import { WithCheckProductAvailability } from '../common/check-product-availability';
import { SignInRequired } from '../common/sign-in';
import { ExternalReferenceRenderer } from '../renderer';
import { useCustomComponents } from './components';
import messages from './messages';

type Props = {
	isMulti: boolean;
	fetchOptionsCloudId: string;
	signInRequired: boolean;
	isConnectionRequired: boolean;
	value: string[] | undefined;
	fieldKey: FieldKey;
	provider?: ExternalReferenceProvider;
	menuPortalTarget?: HTMLElement;
	onUpdate: (arg1: string[] | undefined, arg2: boolean) => void;
	onFetchOptions: (search: string) => Promise<SelectOption[]>;
	onConfiguration: () => void;
	onCloseRequested: () => void;
};

const EMPTY_STRING = '';

type SelectOption = { id: string; value: string };

export const ExternalReferenceEdit = memo<Props>(
	({
		signInRequired,
		isConnectionRequired,
		fetchOptionsCloudId,
		isMulti,
		value: externalValue = [],
		onUpdate,
		provider,
		onCloseRequested,
		onFetchOptions,
		onConfiguration,
		menuPortalTarget,
	}: Props) => {
		useEffect(() => {
			sendTrackEvent(`${provider}_field_edit mounted`);
		}, [provider]);

		const { formatMessage } = useIntl();

		const allExternalProperties = useAllExternalReferencesPropertiesForProvider(provider);

		const allExternalPropertiesToOptions = useMemo(
			() =>
				allExternalProperties.map((v) => ({
					id: v,
					value: v,
				})),
			[allExternalProperties],
		);

		const externalValueToOptions = useMemo(
			() =>
				externalValue.map((val) => ({
					id: val,
					value: val,
				})),
			[externalValue],
		);

		const [loadedOptions, setLoadedOptions] = useState<SelectOption[] | null>(null);
		const [selectMenuId] = useState(uuid());
		const containerRef = useRef<HTMLDivElement>(null);
		const searchBoxRef = useRef<unknown>();
		const [inputValue, setInputValue] = useState(EMPTY_STRING);
		const [isFocused, setIsFocused] = useState(false);

		const [isLoading, setIsloading] = useState(false);
		const loadingStartTimeRef = useRef(0);

		const [value, setValue, valueRef] = useStateWithRef(externalValueToOptions);

		// Group options
		const createOptionsGroups = useCallback(
			(
				isLoadingOptions: boolean,
				searchValue: string,
				selectedOptions: SelectOption[],
				allOptions: SelectOption[],
				asyncOptions: SelectOption[] | null,
			) => {
				let options = isLoadingOptions
					? []
					: differenceBy(
							asyncOptions?.length || searchValue ? asyncOptions : allOptions,
							selectedOptions,
							'id',
						) ?? [];

				if (isConnectionRequired) {
					options = [
						{
							id: 'connect-placeholder',
							value: 'connect-placeholder',
						},
					];
				} else if (
					asyncOptions != null &&
					isMulti &&
					options.length === 0 &&
					selectedOptions.length !== 0
				) {
					options = isLoadingOptions
						? [
								{
									id: 'is-loading-placeholder',
									value: 'is-loading-placeholder',
								},
							]
						: [
								{
									id: 'no-options-placeholder',
									value: 'no-options-placeholder',
								},
							];
				}
				return [
					...(isMulti
						? [
								{
									options: selectedOptions,
								},
							]
						: []),
					{
						options,
					},
				];
			},
			[isMulti, isConnectionRequired],
		);

		const [activeOptions, setActiveOptions] = useState(
			createOptionsGroups(
				isLoading,
				inputValue,
				value,
				allExternalPropertiesToOptions,
				loadedOptions,
			),
		);

		useEffect(() => {
			if (!isShallowEqual(value, externalValueToOptions)) {
				setValue(externalValueToOptions);
			}
		}, [externalValueToOptions, setValue, value]);

		useEffect(() => {
			setActiveOptions(
				createOptionsGroups(
					isLoading,
					inputValue,
					value,
					allExternalPropertiesToOptions,
					loadedOptions,
				),
			);
		}, [
			value,
			setValue,
			isLoading,
			inputValue,
			allExternalPropertiesToOptions,
			loadedOptions,
			createOptionsGroups,
		]);

		useEffect(() => {
			const handleClickAnywhere = (e: MouseEvent) => {
				const menuElement = document.getElementById(selectMenuId);
				const containerElement = containerRef.current;

				// @ts-expect-error - TS2345 - Argument of type 'EventTarget | null' is not assignable to parameter of type 'Node | null'. | TS2571 - Object is of type 'unknown'.
				if (!menuElement?.contains(e.target) && !containerElement?.contains(e.target)) {
					onCloseRequested();
				}
			};

			document.addEventListener('mousedown', handleClickAnywhere);
			return () => {
				// clean up
				document.removeEventListener('mousedown', handleClickAnywhere);
			};
		});

		const onChange = useCallback(
			(newValue?: SelectOption | SelectOption[], allowClose = true) => {
				if (newValue === null || newValue === undefined) {
					if (valueRef.current !== undefined) {
						// update to undefined if a value is currently set
						sendTrackEvent(`${provider}_field updated`);
						onUpdate(undefined, true);
					}
					return;
				}
				const newValueArray = isArray(newValue) ? newValue : [newValue];
				if (valueRef.current !== undefined) {
					const currentValueIdArray = isArray(valueRef.current)
						? valueRef.current
						: [valueRef.current];
					const valueForUpdateIdArray = newValueArray;
					if (deepEquals(currentValueIdArray, valueForUpdateIdArray)) {
						return;
					}
				}
				onUpdate(
					newValueArray.map(({ id }) => id),
					allowClose,
				);
				sendTrackEvent(`${provider}_field updated`);
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				defer(() => searchBoxRef.current?.focus());
			},
			[onUpdate, provider, valueRef],
		);

		useEffect(() => {
			if (isFocused) {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				searchBoxRef.current?.focus();
			}
		}, [isFocused, searchBoxRef]);

		const customComponents = useCustomComponents({
			isMulti,
			selectMenuId,
			renderer: useCallback(
				({ id }) => (
					<WithCheckProductAvailability aris={[id]}>
						{(availableAri: string[], unavailableCloudIds: string[]) => (
							<>
								<ExternalReferenceRenderer provider={provider} aris={availableAri} />
								{unavailableCloudIds.map((cloudId) => (
									<SignInRequired key={cloudId} cloudId={cloudId} />
								))}
							</>
						)}
					</WithCheckProductAvailability>
				),
				[provider],
			),
		});

		const [loadOptions] = useDebouncedCallback((search) => {
			setIsloading(true);
			const time = Date.now();
			loadingStartTimeRef.current = time;
			return onFetchOptions(search)
				.then((result) => {
					if (time === loadingStartTimeRef.current) {
						if (search) {
							sendTrackEvent(`${provider}_field searched`);
						}
						setLoadedOptions(result);
					}
				})
				.catch((error) => {
					logs.safeErrorWithoutCustomerData(
						'searchExternalReferences',
						'Error reading data from searchExternalReferences',
						error,
					);
				})
				.finally(() => {
					if (time === loadingStartTimeRef.current) {
						setIsloading(false);
					}
				});
		}, 200);

		const allExternalPropertiesCount = allExternalPropertiesToOptions.length;

		const prevSearchValue = useRef<string | null>(null);

		useEffect(() => {
			const newSearchValue = inputValue?.trim();
			if (newSearchValue && newSearchValue === prevSearchValue.current) {
				return;
			}
			if ((allExternalPropertiesCount === 0 && newSearchValue === '') || newSearchValue) {
				loadOptions(newSearchValue);
			} else {
				setLoadedOptions(null);
			}
			prevSearchValue.current = newSearchValue;
		}, [setLoadedOptions, loadOptions, allExternalPropertiesCount, inputValue]);

		let placeholder = null;
		switch (provider) {
			case EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL:
				placeholder = formatMessage(messages.searchGoalsHint);
				break;
			case EXTERNAL_REFERENCE_PROVIDERS.ATLAS_PROJECT:
				placeholder = formatMessage(messages.searchProjectsHint);
				break;
			default:
		}

		return (
			<Container ref={containerRef}>
				<Select
					menuPortalTarget={menuPortalTarget}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onKeyDown={(event: any) => {
						if (event.keyCode === 32 && !inputValue) {
							event.preventDefault();
							return;
						}
						switch (event.key) {
							case 'Esc': // IE/Edge specific value
							case 'Escape':
								onCloseRequested();
								break;
							default:
						}
					}}
					isLoading={isLoading}
					defaultMenuIsOpen
					onMenuInputFocus={() => setIsFocused(true)}
					menuIsOpen
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onChange={(newValue: any) => {
						onChange(newValue);
						if (!isMulti) {
							onCloseRequested();
						}
					}}
					isMulti={isMulti}
					hideSelectedOptions={false}
					filterOption={() => true}
					isSearchable
					isClearable
					backspaceRemovesValue={false}
					inputValue={inputValue}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onInputChange={(newValue: any, meta: any) => {
						if (meta.action !== 'input-change') {
							return;
						}
						setInputValue(newValue);
					}}
					searchBoxRef={searchBoxRef}
					onFocus={() => setIsFocused(true)}
					isFocused={isFocused}
					value={value}
					autoFocus
					options={activeOptions}
					components={customComponents}
					getOptionLabel={({ value: label }: OptionProperty) => label}
					getOptionValue={({ id }: OptionProperty) => id}
					enableAnimation={false}
					menuPlacement="auto"
					placeholder={placeholder}
					maxMenuHeight={320}
					signInRequired={signInRequired}
					onConfiguration={onConfiguration}
					fetchOptionsCloudId={fetchOptionsCloudId}
					styles={{
						menu: (styles) => ({
							...styles,
							width: 'max-content',
							minWidth: '240px',
							maxWidth: '400px',
							zIndex: 11,
						}),
						menuList: (styles) => ({
							...styles,
							padding: 0,
						}),
						groupHeading: (styles) => ({
							...styles,
							':empty': {
								display: 'none',
							},
						}),
						group: (styles) => ({
							...styles,
							padding: `${token('space.075', '6px')} 0 ${token('space.075', '6px')} 0`,
							':not(:first-of-type)': {
								borderTop: `1px solid ${token('color.border', N40)}`,
							},
						}),
						option: (styles) => ({
							...styles,
							padding: 0,
							paddingTop: 0,
							paddingBottom: 0,
						}),
					}}
				/>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	cursor: 'auto',
});
