import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type {
	ChildIssue,
	ServerChildIssue,
	ServerChildIssueField,
} from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import {
	STORY_POINT_ESTIMATE_CF_TYPE,
	STORY_POINTS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const toTopLevelChildrenIssues = (
	childrenIssues: ServerChildIssue,
	isResolved: boolean,
	isSimplifiedProject: boolean,
): ChildIssue => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const systemFields: Record<string, any> = {};

	const storyPointsType = isSimplifiedProject ? STORY_POINT_ESTIMATE_CF_TYPE : STORY_POINTS_TYPE;
	let storyPointsField: ServerChildIssueField | undefined;

	childrenIssues.fields.forEach((field) => {
		if (field.schema.system !== null) {
			systemFields[field.schema.system] = field.value;
		}

		if (field.schema.custom === storyPointsType) {
			storyPointsField = field;
		}
	});

	return {
		id: childrenIssues.id,
		issueLink: childrenIssues.self,
		issueTypeIconUrl: systemFields.issuetype?.iconUrl,
		issueTypeName: systemFields.issuetype?.name,
		issuePriorityUrl: systemFields.priority?.iconUrl ?? undefined,
		issuePriorityName: systemFields.priority?.name ?? undefined,
		issuePrioritySequence: systemFields.priority?.sequence ?? undefined,
		issueKey: childrenIssues.key,
		issueSummary: systemFields.summary,
		statusCategory: statusCategoryForId(systemFields.status?.statusCategory?.id),
		statusCategoryId: systemFields.status?.statusCategory?.id,
		statusId: systemFields.status?.id,
		statusName: systemFields.status?.name,
		isLoading: false,
		hasError: false,
		assigneeUrl: systemFields.assignee?.avatarUrls['48x48'] ?? null,
		assigneeDisplayName: systemFields.assignee?.displayName ?? null,
		issueTimeTracking: systemFields.timetracking,
		estimateFieldId: storyPointsField?.key ?? undefined,
		estimateValue: storyPointsField?.value ?? undefined,
		isResolved,
		...(systemFields.created && { created: new Date(systemFields.created) }),
		...(systemFields.updated && { updated: new Date(systemFields.updated) }),
	};
};
