import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	genericErrorTitle: {
		id: 'polaris-common.common.utils.errors.generic-error-title',
		defaultMessage: 'Something went wrong',
		description: 'The document title to show when there is generic error loading the data',
	},
	genericErrorDescription: {
		id: 'polaris-common.common.utils.errors.generic-error-description',
		defaultMessage: 'Refresh the page to try again.',
		description: 'Description of the error message when there is generic error loading the data',
	},
	projectLoadErrorTitle: {
		id: 'polaris-common.common.utils.errors.project-load-error-title',
		defaultMessage: 'We couldn’t load the project data',
		description: 'The document title to show when there is an error loading the project data',
	},
	projectLoadErrorDescription: {
		id: 'polaris-common.common.utils.errors.project-load-error-description',
		defaultMessage: 'Try to refresh again in a few minutes',
		description: 'Description of the error message when the project data cannott be loaded',
	},
	notFoundDocumentTitle: {
		id: 'polaris-common.common.utils.errors.not-found-document-title',
		defaultMessage: 'Project not found - Jira Product Discovery',
		description: 'The document title to show when there is an error',
	},
	notFoundTitle: {
		id: 'polaris-common.common.utils.errors.not-found-title',
		defaultMessage: "This project isn't available",
		description: "Title of the error message when the project can't be found",
	},
	notFoundDescription: {
		id: 'polaris-common.common.utils.errors.not-found-description',
		defaultMessage: 'It may have been deleted or your permissions may have changed',
		description: "Description of the error message when the project can't be found",
	},
	notOnboardedDocumentTitle: {
		id: 'polaris-common.common.utils.errors.not-onboarded-document-title',
		defaultMessage: 'Project not set up - Jira Product Discovery',
		description:
			'The document title to show when the project is not onboarded and the current user cannot onboard it',
	},
	notOnboardedTitle: {
		id: 'polaris-common.common.utils.errors.not-onboarded-title',
		defaultMessage: 'This project is currently empty',
		description: 'Title of the error message when the project is not onboarded yet',
	},
	notOnboardedDescription: {
		id: 'polaris-common.common.utils.errors.not-onboarded-description',
		defaultMessage:
			'The data will be created the next time a project administrator with access to Jira Product Discovery opens it.',
		description: 'Description of the error message when the project is not onboarded yet',
	},
	notOnboardedDescriptionForAdmins: {
		id: 'polaris-common.common.utils.errors.not-onboarded-description-for-admins',
		defaultMessage:
			'The data will be created the next time a project administrator with access to Jira Product Discovery opens it. You can open project settings.',
		description: 'Description of the error message when the project is not onboarded yet',
	},
	listAllProjectsAction: {
		id: 'polaris-common.common.utils.errors.list-all-projects-action',
		defaultMessage: 'View a list of all projects',
		description: 'Link caption to view the list of all JPD projects',
	},
	refreshButton: {
		id: 'polaris-common.common.utils.errors.refresh-button',
		defaultMessage: 'Refresh',
		description: 'Title of the refresh button',
	},
	genericErrorDocumentTitle: {
		id: 'polaris-common.common.utils.errors.generic-error-document-title',
		defaultMessage: 'Jira Product Discovery page error',
		description: 'The document title to show when there is a generic error',
	},
	viewUnavailableTitle: {
		id: 'polaris-common.common.utils.errors.view-unavailable-title',
		defaultMessage: "Sorry, we can't find that view",
		description: 'Title of the error message when the view is not available or not found',
	},
	viewUnavailableDescription: {
		id: 'polaris-common.common.utils.errors.view-unavailable-description',
		defaultMessage:
			"It seems you don't have access to this link, or it isn't available anymore. Make sure you are signed in with the right Atlassian account.",
		description: 'Description of the error message when the view is not available or not found',
	},
	rateLimitErrorTitle: {
		id: 'polaris-common.common.utils.errors.rate-limit-error-title',
		defaultMessage: 'We received too many requests',
		description: 'Title of the error message when the rate limit is exceeded',
	},
	rateLimitErrorDescription: {
		id: 'polaris-common.common.utils.errors.rate-limit-error-description',
		defaultMessage:
			'Please try again in a few minutes. If the problem persists, <a>contact support.</a>',
		description: 'Description of the error message when the rate limit is exceeded',
	},
});
