import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	groupedIdeas: {
		id: 'polaris-lib-matrix.dnd.tooltip.grouped-ideas',
		defaultMessage: '{count} {count, plural, one {idea} other {ideas}}',
		description:
			'Tooltip content to show how many ideas were grouped together to show only one bubble in matrix view',
	},
});
