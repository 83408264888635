import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noValue: {
		id: 'polaris-common.idea-card-v2.body.number.no-value',
		defaultMessage: 'N/A',
		description: 'Text displayed when a formula has an undefined resulting value',
	},
	noValueTooltip: {
		id: 'polaris-common.idea-card-v2.body.number.no-value-tooltip',
		defaultMessage: 'The result value of the formula is undefined',
		description: 'Tooltip displayed when hovering a formula that has an undefined resulting value',
	},
});
