import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editSummary: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.link-field.edit-summary',
		defaultMessage: 'Edit summary',
		description: 'Text displayed when edit icon has hovered',
	},
	previewIdea: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.link-field.preview-idea',
		defaultMessage: 'Preview idea',
		description: 'Text displayed when preview idea icon has hovered',
	},
	openIdea: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.link-field.open-idea',
		defaultMessage: 'Open idea',
		description: 'Text displayed when open idea icon has hovered',
	},
});
