import React from 'react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import type { TriggerProps } from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type SidebarHeaderTriggerProps = {
	triggerProps: TriggerProps;
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	label: string;
};

export const SidebarHeaderTrigger = ({
	triggerProps,
	isOpen,
	setIsOpen,
	label,
}: SidebarHeaderTriggerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Tooltip content={label} hideTooltipOnClick>
			<Button
				{...triggerProps}
				testId="polaris-lib-create-view-popup.ui.triggers.sidebar-header-trigger"
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'toggled',
							actionSubject: 'dropdown',
						}),
						'createView',
						{
							dropdownLocation: 'header',
						},
					);
					setIsOpen(!isOpen);
				}}
				appearance="subtle"
				iconAfter={<EditorAddIcon size="small" label="" />}
				isSelected={isOpen}
				aria-label={label}
			/>
		</Tooltip>
	);
};
