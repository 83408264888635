import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';

export const SUPPORTED_FIELD_TYPES: FieldType[] = [
	FIELD_TYPES.NUMBER,
	FIELD_TYPES.RATING,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.SLIDER,
	FIELD_TYPES.CHECKBOX,
	FIELD_TYPES.RATING,
	FIELD_TYPES.DELIVERY_PROGRESS,
	FIELD_TYPES.DELIVERY_STATUS,
	FIELD_TYPES.VOTES,
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
] as const;
