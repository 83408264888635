import React, { type ComponentPropsWithoutRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import AtlSectionMessage from '@atlaskit/section-message';

const sectionMessageContainerStyles = xcss({
	marginBottom: 'space.200',
});

export const SectionMessage = (props: ComponentPropsWithoutRef<typeof AtlSectionMessage>) => (
	<Box xcss={sectionMessageContainerStyles}>
		<AtlSectionMessage {...props} />
	</Box>
);
