import React, { useCallback, useState, useMemo } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { teamsIcon } from '@atlassian/jira-polaris-common/src/common/ui/apps';
import { TeamsHowToDialog } from '@atlassian/jira-polaris-common/src/common/ui/apps/modals/teams/index.tsx';
import type { AppPreset } from '../types';
import messages from './messages';

const TeamsInfoAction = () => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const close = useCallback(() => setIsOpen(false), []);

	return (
		<>
			<Button spacing="none" appearance="link" onClick={() => setIsOpen(true)}>
				{formatMessage(messages.title)}
			</Button>
			<TeamsHowToDialog isOpen={isOpen} onClose={close} />
		</>
	);
};

export const useTeamsPreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: 'teams',
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: teamsIcon,
			Action: TeamsInfoAction,
		}),
		[formatMessage],
	);
};
