import type { FieldValueDecorations } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	JIRA_API_FIELD_TYPES,
	FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { JiraApiFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { Field, FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { FetchFieldsResponse } from '../../../../services/jpd-views-service/fetch-fields/types';
import type { JiraField } from '../../../../services/types';
import type { FetchResponse } from '../../types';
import { getFieldType } from '../../utils';
import { getPresentationType, getOptions, transformDecorations } from './utils';

const requiredFakeFields: JiraField[] = [
	{
		key: 'issuekey',
		name: 'Key',
		description: '',
		type: JIRA_API_FIELD_TYPES.ISSUE_KEY,
		custom: false,
		global: false,
		clauseNames: ['id', 'issue', 'issuekey', 'key'],
	},
	{
		key: 'issueid',
		name: 'issueid',
		description: '',
		type: JIRA_API_FIELD_TYPES.ISSUE_ID,
		custom: false,
		clauseNames: ['issueid', 'id'],
		global: false,
	},
	{
		key: 'description',
		name: 'Description',
		description: '',
		type: JIRA_API_FIELD_TYPES.DESCRIPTION,
		custom: false,
		global: false,
		clauseNames: ['description'],
	},
	{
		key: 'issuetype',
		name: 'Issue Type',
		description: '',
		type: JIRA_API_FIELD_TYPES.ISSUE_TYPE,
		custom: false,
		global: false,
		clauseNames: ['issuetype', 'type'],
	},
];

export const transformResponse = ({ issueTypeFields }: FetchFieldsResponse): FetchResponse => {
	const issueTypeId = Object.keys(issueTypeFields)[0];
	const jiraFields = [...requiredFakeFields, ...Object.values(issueTypeFields)[0]];

	// Jira always returns these fields, we don't want them!
	const denyList = [
		'com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf',
		'com.pyxis.greenhopper.jira:gh-lexo-rank',
		'comment',
		'issuelinks',
		'priority',
		'issuerestriction',
		'attachment',
		'resolution',
		'fixVersions',
		'timetracking',
		'environment',
		'duedate',
		'project',
	];

	const filteredFilters = jiraFields.filter(
		({ key, type }) => !denyList.includes(key) && !denyList.includes(type),
	);

	const resolvedFieldMap: FieldMap = {};
	const resolvedFieldValueDecorations: FieldValueDecorations = {};

	/*
        TODO: Refactor to issuekey
        These fields need to be remapped because their key and type in polaris does not match the type in the Jira api,
        but we can't easily adapt to the new type, because many parts of the code rely on the old type.
     */
	const remapList: { [key: string]: JiraApiFieldType } = {
		issuekey: FIELD_TYPES.ISSUE_KEY,
	};
	const remappedFields = filteredFilters.map((field) =>
		remapList[field.key]
			? {
					...field,
					key: remapList[field.key],
					type: remapList[field.key],
				}
			: field,
	);

	remappedFields.forEach((field) => {
		const { key, name: label, global, custom } = field;

		const type = getFieldType(field);
		const presentation = getPresentationType(field);
		const options = getOptions(field);
		const description = field.description || '';
		const emoji = field.configuration?.emoji ?? undefined;
		const formula: DynamicFieldFormula | undefined =
			field.configuration?.polarisFormula != null
				? { ...field.configuration.polarisFormula }
				: undefined;

		/* A lot of this code was taken from:
             .../.../polaris-api/transform
           So that the transforming was consistent, but at these point we are not transforming the decoration etc
           directly so the code for that is left below, firstly as a reminder it needs doing and second to be
           explicit at these point that isn't handled.
        */

		const resolvedField: Field = {
			key,
			type,
			options,
			label,
			editable: false,
			presentation,
			description,
			emoji,
			global,
			custom,
			issueTypes: [issueTypeId],
		};

		if (field.configuration) {
			resolvedField.configuration = {
				...field.configuration,
			};
		}

		if (formula) {
			resolvedField.formula = formula;
		}

		resolvedFieldMap[key] = resolvedField;
		resolvedFieldValueDecorations[key] = transformDecorations(field);
	});

	return {
		fields: resolvedFieldMap,
		fieldValueDecorations: resolvedFieldValueDecorations,
	};
};
