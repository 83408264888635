export type ProjectAccessLevelType = 'OPEN' | 'LIMITED' | 'PRIVATE' | 'FREE' | 'UNKNOWN';
export type AccessLevel = {
	type: ProjectAccessLevelType;
	label: string;
	description: string;
	iconName: string;
};

export type RoleId = string;

export const VIEWER = 'viewer';
export const MEMBER = 'member';
export const ADMIN = 'admin';
export const CUSTOM = 'custom';
export type RoleType = typeof VIEWER | typeof MEMBER | typeof ADMIN | typeof CUSTOM;

export type Role = {
	id: RoleId;
	type: RoleType;
	label: string;
	description: string;
};

export type Actor = {
	displayName: string;
	avatarUrl: string | undefined;
	roles: RoleId[];
};

export const isFree = (accessLevel: AccessLevel): boolean => accessLevel.type === 'FREE';
export const isOpen = (accessLevel: AccessLevel): boolean => accessLevel.type === 'OPEN';
export const isLimited = (accessLevel: AccessLevel): boolean => accessLevel.type === 'LIMITED';
export const isPrivate = (accessLevel: AccessLevel): boolean => accessLevel.type === 'PRIVATE';

export const isAdmin = (role: Role) => role.type === ADMIN;
export const isMember = (role: Role) => role.type === MEMBER;
export const isViewer = (role: Role) => role.type === VIEWER;
