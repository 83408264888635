import { createSelector } from 'reselect';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import { getFields } from '../../fields';
import { getCurrentViewDraft, isCurrentViewAutosaveEnabled } from '../autosave';
import { getCurrentView } from '../current';

export const getCurrentViewTimelineModeOrDefault = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view : draft)?.timelineConfig?.mode || PolarisTimelineMode.QUARTERS,
);

export const getCurrentViewTimelineStartDateField = createSelector(
	getFields,
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(fields, view, draft, isAutosaveEnabled) => {
		const dateField = (isAutosaveEnabled ? view : draft)?.timelineConfig?.startDateField;

		if (dateField && fields[dateField.key]) {
			return dateField;
		}
	},
);

export const getCurrentViewTimelineEndDateField = createSelector(
	getFields,
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(fields, view, draft, isAutosaveEnabled) => {
		const dateField = (isAutosaveEnabled ? view : draft)?.timelineConfig?.dueDateField;

		if (dateField && fields[dateField.key]) {
			return dateField;
		}
	},
);

export const getCurrentViewTimelineIntervalStartDate = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view : draft)?.timelineConfig?.startTimestamp,
);

export const getCurrentViewTimelineIntervalEndDate = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view : draft)?.timelineConfig?.endTimestamp,
);

export const getCurrentViewArrangementInformation = createSelector(
	getCurrentView,
	(view) => view?.timelineConfig?.arrangementInformation,
);

export const getTimelineViewConfigured = createSelector(
	getCurrentView,
	getCurrentViewTimelineModeOrDefault,
	getCurrentViewTimelineStartDateField,
	getCurrentViewTimelineEndDateField,
	(view, mode, startDate, endDate) => Boolean(view?.id && startDate?.key && endDate?.key && mode),
);

export const getCurrentViewSummaryCardField = createSelector(
	getFields,
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(fields, view, draft, isAutosaveEnabled) => {
		const field = (isAutosaveEnabled ? view : draft)?.timelineConfig?.summaryCardField;

		if (!field || !fields?.[field.key]) {
			return null;
		}

		return field;
	},
);

export const getCurrentViewSummaryCardFieldKey = createSelector(
	getCurrentViewSummaryCardField,
	(field) => field?.key,
);

export const getCurrentViewTimelineMarkers = createSelector(
	getCurrentView,
	(view) => view?.markers || [],
);
