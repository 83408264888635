import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field';
import type { Props, State } from '../../types';

/**
 * @deprecated create a new action with an explicit name to update configuration
 */
export const updateFieldConfiguration =
	(
		fieldKey: FieldKey,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		oldConfiguration: Record<string, any> | undefined,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		configuration: Record<string, any>,
		localUpdate = false,
	) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, ideaTypes, createAnalyticsEvent }: Props,
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	): Promise<Error | void> => {
		try {
			const field = createGetFieldByKey(fieldKey)(getState());

			if (!field) {
				return undefined;
			}

			const issueType = head(ideaTypes);

			if (issueType === undefined) {
				return undefined;
			}

			if (!localUpdate) {
				await fieldRemote.updateFieldConfiguration({
					oldConfiguration: oldConfiguration ?? {},
					configuration,
					fieldKey: field.key,
					issueTypeId: issueType.jiraIssueTypeId,
				});
			}

			const fields = { ...getState().fields };
			fields[fieldKey] = {
				...fields[fieldKey],
				configuration,
			};
			setState({ fields });

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
				updatedItems: Object.keys(configuration).map((name) => ({ name })),
			});
			return undefined;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			onFieldUpdateFailed(err);
			return err;
		}
	};
