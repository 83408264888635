import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionHeaderPinned: {
		id: 'polaris-ideas.idea-view.fields.field.list.section-header-pinned',
		defaultMessage: 'Pinned fields',
		description: 'Section header for default project fields (text transform uppercase)',
	},
	sectionHeaderCurrent: {
		id: 'polaris-ideas.idea-view.fields.field.list.section-header-current',
		defaultMessage: 'Fields in this view',
		description: 'Section header for other fields in current view (text transform uppercase)',
	},
	sectionHeaderOther: {
		id: 'polaris-ideas.idea-view.fields.field.list.section-header-other',
		defaultMessage: 'Available fields',
		description: 'Section header all other fields (text transform uppercase)',
	},
	sectionHeaderOtherInRoadmap: {
		id: 'polaris-ideas.idea-view.fields.field.list.section-header-other-in-roadmap',
		defaultMessage: 'Global and system fields in roadmap',
		description: 'Section header all other fields in cross project view (text transform uppercase)',
	},
});
