import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlasProjectTargetDateOption: {
		id: 'polaris-component-field-configuration.configuration.interval-override.atlas-project-target-date-option',
		defaultMessage: 'Atlas project target date',
		description: 'Label for target date of Atlas project option',
	},
	deliveryDateOption: {
		id: 'polaris-component-field-configuration.configuration.interval-override.delivery-date-option',
		defaultMessage: 'Linked delivery tickets',
		description: 'Label for delivery date option',
	},
	sourceTitle: {
		id: 'polaris-component-field-configuration.configuration.interval-override.source-title',
		defaultMessage: 'Autofill dates',
		description: 'Header label for whether enable override',
	},
	sourceDescription: {
		id: 'polaris-component-field-configuration.configuration.interval-override.source-description',
		defaultMessage:
			'With this setting enabled, dates can be automatically filled from Atlas projects or linked delivery tickets.',
		description: 'Description for what overriding means',
	},
	sourceSelectLabel: {
		id: 'polaris-component-field-configuration.configuration.interval-override.source-select-label',
		defaultMessage: 'Source',
		description: 'Label for source select',
	},
});
