import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import generalOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { CREATED } from '@atlassian/jira-common-constants/src/http-status-codes';
import HttpError from '@atlassian/jira-fetch/src/utils';
import { OUTWARD_LINK_DIRECTION } from '@atlassian/jira-issue-shared-types';
import { getSaveIssueLinksUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import type { BaseUrl } from '@atlassian/jira-shared-types';

const postOptions = {
	...generalOptions,
	method: 'POST',
} as const;

const toIssueLinkObj = (
	issueLinkType: { direction: string; id: string },
	thisIssueKey: string,
	linkedIssueKey: string,
) => {
	if (issueLinkType.direction === OUTWARD_LINK_DIRECTION) {
		return {
			type: { id: issueLinkType.id },
			inwardIssue: { key: thisIssueKey },
			outwardIssue: { key: linkedIssueKey },
		};
	}

	return {
		type: { id: issueLinkType.id },
		inwardIssue: { key: linkedIssueKey },
		outwardIssue: { key: thisIssueKey },
	};
};

const processResponse = (response: Response) => {
	if (response.status === CREATED) {
		return Promise.resolve(null);
	}

	return Promise.reject(
		new HttpError(response.status, `Error server response: ${response.status}`),
	);
};

const post = (
	// @ts-expect-error - TS2304 - Cannot find name 'Href'.
	url: Href,
	options: {
		body: string;
	},
) => fetch(url, { ...postOptions, ...options }).then(processResponse);

export const saveIssueLink = (
	baseUrl: BaseUrl,
	issueLinkType: { direction: string; id: string },
	thisIssueKey: string,
	linkedIssueKey: string,
) =>
	Observable.fromPromise(
		post(getSaveIssueLinksUrl(baseUrl), {
			body: JSON.stringify(toIssueLinkObj(issueLinkType, thisIssueKey, linkedIssueKey)),
		}),
	);
