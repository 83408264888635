import React from 'react';
import {
	AVG_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { Props } from '../types';
import { NumberFieldGroupedAvg } from './avg';
import { NumberFieldGroupedMedian } from './median';
import { NumberFieldGroupedSum } from './sum';

export const NumberFieldGrouped = (props: Props) => {
	switch (props.rollupOperation) {
		case AVG_ROLLUP:
			return <NumberFieldGroupedAvg {...props} />;
		case MEDIAN_ROLLUP:
			return <NumberFieldGroupedMedian {...props} />;
		case SUM_ROLLUP:
			return <NumberFieldGroupedSum {...props} />;
		default:
			return null;
	}
};
