import { ff } from '@atlassian/jira-feature-flagging';
import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals';
import {
	AccessForbiddenError,
	RemoteFieldsNotInitializedError,
	SharedViewAuthenticationMissingError,
	SharedViewFieldsUnavailableError,
} from '@atlassian/jira-polaris-remote-field/src/controllers/utils';
import type { Action } from '@atlassian/react-sweet-state';
import { redirectToIdentity } from '../../../common/utils/identity';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_FIELDS_START,
	PAGE_LOAD_MARK_LOAD_FIELDS_END,
} from '../../../common/utils/metrics/project';
import type { Props, State } from '../types';

// Error message: [...] API-9998 permission check failed [...]
const isPermissionCheckError = (error: Error | unknown): boolean =>
	error instanceof Error ? error.message.search('API-9998') !== -1 : false;

const detectAndHandleCyclicFormulaDependency = (containerProps: Props, fields: FieldMap) => {
	const cyclicFieldLabels = Object.values(fields)
		.map((field) => (field.hasCyclicFormulaDependency ? field.label : undefined))
		.filter(Boolean);
	if (cyclicFieldLabels.length > 0) {
		containerProps.onCyclicFormulaDependencyDetected(cyclicFieldLabels);
	}
};

export const loadFields =
	(forceReload?: boolean): Action<State, Props> =>
	async ({ getState, setState }, containerProps) => {
		const { containerAri, isContainerInitialized, fieldRemote, isSiteAdmin } = containerProps;
		const loadingProps = { containerAri, isContainerInitialized };
		const {
			meta: { loadingProps: previousLoadingProps },
		} = getState();

		// Only load again when the props this function depends on have changed
		if (forceReload !== true && isShallowEqual(loadingProps, previousLoadingProps)) {
			return;
		}

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_FIELDS_START);

		setState({
			meta: {
				...getState().meta,
				initialized: false,
				loading: true,
				error: undefined,
				loadingProps,
			},
		});

		try {
			const fieldData = await fieldRemote.fetchAllFields();
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_FIELDS_END);
			setState({
				meta: {
					...getState().meta,
					initialized: true,
					loading: false,
					error: undefined,
					loadingProps,
				},
				...fieldData,
			});
			detectAndHandleCyclicFormulaDependency(containerProps, fieldData.fields);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_FIELDS_END);

			if (error instanceof RemoteFieldsNotInitializedError) {
				// we return early here,
				// we want to try loading again since the project is still loading
				return;
			}

			setState({
				meta: {
					...getState().meta,
					initialized: false,
					loading: false,
					error,
					loadingProps: undefined,
				},
				fields: {},
				fieldValueDecorations: {},
			});

			if (error instanceof AccessForbiddenError) {
				return;
			}

			if (
				error instanceof SharedViewAuthenticationMissingError &&
				ff('polaris.jsm-customer-redirect-on-shared-view')
			) {
				if (!ff('polaris-published-views-login-info-screen')) {
					// Some users are able to reach this point without being full authenticated (by design - JSM customers for instance)
					// In this instance we need to redirect them to the Atlassian account login screen.
					redirectToIdentity(containerProps.environment);
				}
				return;
			}

			if (error instanceof SharedViewFieldsUnavailableError) {
				return;
			}

			if (isPermissionCheckError(error) && isSiteAdmin) {
				// shallow perms error for site admins as they still need to access setting page
				// even without project permissions
				return;
			}

			containerProps.onFieldLoadingFailed(error);
		}
	};
