import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { token } from '@atlaskit/tokens';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FieldName } from '../../../../common/ui/field/styled';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks';
import { useExternalReferenceValue } from '../../../../controllers/issue/selectors/properties/hooks';
import { ExternalReferenceField as InnerField } from '../../../fields/external-reference';
import { EmptyField } from '../empty';
import { FieldLabel, FieldValue } from '../field/styled';

type ExternalReferenceFieldProps = {
	issueId: LocalIssueId;
	fieldKey: string;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
};

export const ExternalReferenceField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty,
}: ExternalReferenceFieldProps) => {
	const externalReferencesValue = useExternalReferenceValue(fieldKey, issueId);
	const label = useFieldLabel(fieldKey);

	const shouldRenderValue =
		externalReferencesValue !== undefined &&
		(!Array.isArray(externalReferencesValue) || externalReferencesValue.length > 0);

	if (!shouldRenderValue && hideEmpty) {
		return null;
	}

	return (
		<>
			{!isCompact && (
				<FieldLabel>
					<FieldName>{label}</FieldName>
				</FieldLabel>
			)}
			<FieldValue>
				<FieldValueWrapper noMargin={isCompact || isSummary}>
					{shouldRenderValue ? (
						<InnerField
							localIssueId={issueId}
							fieldKey={fieldKey}
							isEditable={false}
							isActive
							isCompact={isCompact}
							onUpdate={noop}
						/>
					) : (
						isSummary && <EmptyField />
					)}
				</FieldValueWrapper>
			</FieldValue>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldValueWrapper = styled.div<{ noMargin: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ noMargin }) => (noMargin ? 0 : token('space.050', '4px')),
});
