import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tryNow: {
		id: 'polaris-common.apps.unfurl-dialog.try-now',
		defaultMessage: 'Try it now',
		description: 'Button caption that opens the dialog to try a new app',
	},
	close: {
		id: 'polaris-common.apps.unfurl-dialog.close',
		defaultMessage: 'Close',
		description: 'Modal dialog close button caption',
	},
	unresolvableLink: {
		id: 'polaris-common.apps.unfurl-dialog.unresolvable-link',
		defaultMessage: "Sorry, that's not a link we understand",
		description: 'Modal dialog close button caption',
	},
	successMessagePrefix: {
		id: 'polaris-common.apps.unfurl-dialog.success-message-prefix',
		defaultMessage: 'Success!',
		description:
			'First sentence in the success message indicator. Second sentence will be app specific, third is successMessage',
	},
	successMessage: {
		id: 'polaris-common.apps.unfurl-dialog.success-message',
		defaultMessage: 'You can find these insights on your ideas in the Capture section.',
		description: 'Modal dialog close button caption',
	},
	capture: {
		id: 'polaris-common.apps.unfurl-dialog.capture',
		defaultMessage: 'Capture',
		description: 'Tab caption for capture content in Jira Product Discovery idea view.',
	},
});
