import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraIssueLinkTypeGetResponse, PolarisIssueLinkTypes } from './types';

export type { JiraIssueLinkTypeGetResponse };

export const getIssueLinkTypes = (): Promise<JiraIssueLinkTypeGetResponse> =>
	fetchJson('/rest/api/3/issueLinkType', {
		method: 'GET',
	});

export const getSpecialJpdIssueLinkTypes = (): Promise<PolarisIssueLinkTypes> =>
	fetchJson('/rest/polaris/issueLinkType', {
		method: 'GET',
	});
