import React, { useMemo } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIsGlobalCustomField } from '../../../../../controllers/field/selectors/field-hooks';
import { createDecoratableSelectCustomComponents } from '../../select';
import { DecoratedTag, DynamicOptionDecoratedTag } from '../tag';
import { ControlledFieldDecorator } from './field-decorator';

type DecoratableSelectProps = {
	isMulti: boolean;
	fieldKey: FieldKey;
	menuId?: string;
	configOnly?: boolean;
	onConfigRequested?: (optionId?: string) => void;
	onAddOption?: (arg1: string) => Promise<string | undefined>;
	onCloseRequested?: () => void;
	hideInput?: boolean;
};

export const useDecoratableSelectCustomComponentsForLabels = ({
	isMulti,
	fieldKey,
	onAddOption,
	onCloseRequested,
	menuId,
	configOnly,
	onConfigRequested,
}: DecoratableSelectProps) =>
	useMemo(
		() =>
			createDecoratableSelectCustomComponents({
				isMulti,
				onAddOption,
				FieldDecorator: ({ id }: { id: string }) => (
					<ControlledFieldDecorator
						id={id}
						fieldKey={fieldKey}
						onCloseRequested={onCloseRequested}
						autoFocusName
					/>
				),
				DecoratedTagComponent: ({ id, compact }: { id: string; compact?: boolean }) => (
					<DecoratedTag fieldKey={fieldKey} id={id} value={id} compact={compact} />
				),
				addMode: configOnly === true ? 'config-only' : 'search-first',
				menuId,
				onConfigRequested,
				hideInput: false,
			}),
		[configOnly, fieldKey, isMulti, menuId, onAddOption, onCloseRequested, onConfigRequested],
	);
export const useDecoratableSelectCustomComponentsForOptions = ({
	isMulti,
	fieldKey,
	onAddOption,
	onCloseRequested,
	menuId,
	configOnly,
	onConfigRequested,
	hideInput = true,
}: DecoratableSelectProps) => {
	const isGlobalField = useIsGlobalCustomField(fieldKey);

	return useMemo(
		() =>
			createDecoratableSelectCustomComponents({
				isMulti,
				onAddOption,
				FieldDecorator: ({ id }: { id: string }) => (
					<ControlledFieldDecorator
						id={id}
						fieldKey={fieldKey}
						onCloseRequested={onCloseRequested}
						autoFocusName
					/>
				),
				DecoratedTagComponent: ({ id, compact }: { id: string; compact?: boolean }) => (
					<DynamicOptionDecoratedTag fieldKey={fieldKey} id={id} compact={compact} />
				),
				addMode: configOnly === true ? 'config-only' : 'add-first',
				menuId,
				onConfigRequested,
				hideOptionEditButtons: isGlobalField,
				hideInput,
			}),
		[
			isMulti,
			onAddOption,
			configOnly,
			menuId,
			onConfigRequested,
			isGlobalField,
			hideInput,
			fieldKey,
			onCloseRequested,
		],
	);
};
