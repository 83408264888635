import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types';
import { setQuery } from '../set-query';

export const closeIssueView =
	() =>
	({ dispatch }: StoreActionApi<State>) => {
		dispatch(
			setQuery({
				selectedIssue: undefined,
				issueViewLayout: undefined,
				issueViewSection: undefined,
				viewCommentId: undefined,
			}),
		);
	};
