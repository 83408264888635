type Cache<T> = {
	value: Promise<T> | undefined;
};

type CachableFunc<T> = (noCache?: boolean) => Promise<T>;

export const cachedPromise = <T,>(func: () => Promise<T>, noCache = false): CachableFunc<T> => {
	const cache: Cache<T> = {
		value: undefined,
	};

	return () => {
		if (cache.value === undefined || noCache) {
			cache.value = func();
		}

		return cache.value;
	};
};
