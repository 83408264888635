import { stringify } from 'query-string';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { MentionDescription } from '@atlaskit/mention';
import { AbstractMentionProvider } from '@atlassian/jira-mention-provider/src/services/abstract-mention-provider/index.tsx';
import type { ServerUser } from '@atlassian/jira-mention-provider/src/services/abstract-mention-provider/utils';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ATLASSIAN_ACCOUNT_USER } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

export class ContainerlessMentionProvider extends AbstractMentionProvider {
	constructor(
		loggedInAccountId: AccountId | null,
		createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	) {
		super('', null, createAnalyticsEvent);
		this.loggedInAccountId = loggedInAccountId;
	}

	getQueryString = (query?: string) =>
		stringify({
			query,
			maxResults: 10,
		});

	getUrl = (queryString: string) => `/rest/api/2/user/search?${queryString}`;

	fetchMentions(url: string): Promise<ServerUser[]> {
		return super
			.fetchMentions(url)
			.then((users) =>
				users.filter((user) => user.active === true && user.accountType === ATLASSIAN_ACCOUNT_USER),
			);
	}

	loggedInAccountId: string | null;

	shouldHighlightMention = (mention: MentionDescription) => mention.id === this.loggedInAccountId;
}
