/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { type ValueContainerProps, components } from '@atlaskit/select';

export const ValueContainer = <Option, IsMulti extends boolean = false>({
	children,
	innerProps,
	...rest
}: ValueContainerProps<Option, IsMulti>) => (
	<components.ValueContainer
		innerProps={{
			...innerProps,
		}}
		{...rest}
		css={valueContainerStyles}
	>
		{children}
	</components.ValueContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
const valueContainerStyles = css({ flexWrap: 'nowrap', '&&': { paddingLeft: 0, paddingRight: 0 } });
