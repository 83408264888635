import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common';
import type { JiraFieldMapping } from '../types';

export const jiraIssueTypeMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<IssueTypeFieldValue> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key],
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
