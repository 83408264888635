import { fetchIssueIssueBatches } from '@atlassian/jira-polaris-remote-issue/src/services/jira/get-issues-rest-bulk/main.tsx';
import type { BatchLoadedFn } from '@atlassian/jira-polaris-remote-issue/src/services/jira/get-issues-rest-bulk/types.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export type IssuesFetcherProps = {
	projectId: string;
	fields?: string[];
	onBatchLoaded?: BatchLoadedFn;
};

export type JiraIssue = {
	key: IssueKey;
	id: IssueId;
	fields: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		issuelinks: any[];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		comment: { comments: any[] };
		project: {
			id: string;
			key: string;
		};
	};
};

export type IssuesFetcherResponse = {
	total: number;
	issues: JiraIssue[];
};

export type IssuesFetcher = (props: IssuesFetcherProps) => Promise<IssuesFetcherResponse>;

export const getAllIdeasForProject = ({
	projectId,
	onBatchLoaded,
	fields,
}: IssuesFetcherProps): Promise<IssuesFetcherResponse> =>
	fetchIssueIssueBatches({ projectId }, fields, [], onBatchLoaded);
