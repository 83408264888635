import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useActiveFiltersCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import noResults from './assets/no-results.svg';
import { messages } from './messages';

export const EmptyView = () => {
	const { formatMessage } = useIntl();
	const activeFiltersCount = useActiveFiltersCount();
	const { clearAllFilters } = useViewActions();
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const isSharedView = useIsSharedView();

	const isButtonEnabled =
		activeFiltersCount > 0 &&
		((ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews) ||
			isSharedView);

	return (
		<EmptyContainer>
			<img src={noResults} alt="empty list" />
			<EmptyHeader>{formatMessage(messages.header)}</EmptyHeader>
			<p>{formatMessage(activeFiltersCount > 0 ? messages.filters : messages.noFilters)}</p>
			{isButtonEnabled && (
				<Button appearance="link" onClick={() => clearAllFilters()}>
					{formatMessage(messages.clearFilter)}
				</Button>
			)}
		</EmptyContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	width: '100%',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		marginBottom: token('space.500', '40px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	p: {
		margin: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		marginTop: token('space.200', '16px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyHeader = styled.h5({
	margin: `0 0 ${token('space.075', '6px')} 0`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '16px',
});
