import React, { memo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { DefaultFields } from '../body/default-fields/index.tsx';
import {
	ASSIGNEE_SUMMARY_RIGHT_PADDING,
	ASSIGNEE_SUMMARY_CONTAINER_GAP,
	ASSIGNEE_SUMMARY_MIN_HEIGHT,
	FIELD_HEIGHT_COMPACT,
	HEADER_GAP,
	DISPLAYING_FIELDS_MIN_CARD_WIDTH,
} from '../constants';
import { useVisibleAssignee, useVisibleStatus } from '../utils';
import { KeyField } from './key-field';
import { Summary } from './summary';
import { useVisibleIssueKey } from './utils';

type HeaderProps = {
	id: string;
	isFullWidth?: boolean;
	isCompact?: boolean;
	isResizing?: boolean;
	hideEmptyFields?: boolean;
	hasMultilineSummary?: boolean;
};

export const Header = memo<HeaderProps>(
	({
		id,
		isFullWidth = false,
		isCompact = false,
		isResizing = false,
		hideEmptyFields = false,
		hasMultilineSummary = false,
	}) => {
		const visibleIssueKey = useVisibleIssueKey(id);
		const assignee = useVisibleAssignee(id);
		const status = useVisibleStatus(id);

		if (!visibleIssueKey && !status && isCompact) {
			return assignee ? (
				<Wrapper>
					<AssigneeSummaryContainer isFullWidth={isFullWidth} isMultiline={hasMultilineSummary}>
						<Summary id={id} isMultiline={hasMultilineSummary} isResizing={isResizing} />
						<AvatarWrapper data-component-selector="card-assignee_jh23">
							<Avatar size="small" src={assignee.avatarUrl} name={assignee.name} />
						</AvatarWrapper>
					</AssigneeSummaryContainer>
				</Wrapper>
			) : (
				<Container isFullWidth={isFullWidth}>
					<Summary id={id} isMultiline={hasMultilineSummary} isResizing={isResizing} />
				</Container>
			);
		}

		return (
			<>
				<Container isFullWidth={isFullWidth}>
					{visibleIssueKey && <KeyField issueKey={visibleIssueKey} />}
					{isCompact && <DefaultFields issueId={id} isCompact hideEmptyFields={hideEmptyFields} />}
				</Container>
				<SummaryContainer isFullWidth={isFullWidth}>
					<Summary id={id} isMultiline={hasMultilineSummary} isResizing={isResizing} />
				</SummaryContainer>
			</>
		);
	},
);

Header.displayName = 'CardHeader';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	zIndex: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isFullWidth?: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${HEADER_GAP}px`,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ isFullWidth }) => (isFullWidth ? 0 : '14px'),
	'&:empty': {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryContainer = styled.div<{ isFullWidth?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingRight: ({ isFullWidth }) => (isFullWidth ? 0 : `${ASSIGNEE_SUMMARY_RIGHT_PADDING}px`),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AssigneeSummaryContainer = styled.div<{
	isFullWidth: boolean;
	isMultiline: boolean;
}>({
	display: 'grid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${ASSIGNEE_SUMMARY_CONTAINER_GAP}px`,
	gridTemplateColumns: 'minmax(0, 1fr) 28px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ isMultiline }) => (isMultiline ? 'flex-start' : 'center'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridAutoRows: ({ isMultiline }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isMultiline ? `minmax(${FIELD_HEIGHT_COMPACT}px, auto)` : `${FIELD_HEIGHT_COMPACT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingRight: ({ isFullWidth }) => (isFullWidth ? 0 : `${ASSIGNEE_SUMMARY_RIGHT_PADDING}px`),
	zIndex: 21,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${ASSIGNEE_SUMMARY_MIN_HEIGHT}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		display: 'flex',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-component-selector="card-assignee_jh23"]': {
			width: 0,
			overflow: 'hidden',
			visibility: 'hidden',
		},
	},
});
