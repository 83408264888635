import type { UploadContext } from '@atlassian/jira-issue-gira-transformer-types';
import {
	FETCH_UPLOAD_CONTEXT_FAILURE,
	FETCH_UPLOAD_CONTEXT_SUCCESS,
	type FetchUploadContextSuccessAction,
	type FetchUploadContextFailureAction,
} from './upload-context-actions';

export const initialState = null;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: UploadContext | null = initialState,
	action: FetchUploadContextSuccessAction | FetchUploadContextFailureAction,
) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH_UPLOAD_CONTEXT_SUCCESS:
			return payload;
		case FETCH_UPLOAD_CONTEXT_FAILURE:
			return initialState;
		default:
			return state;
	}
};
