import isArray from 'lodash/isArray';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common';
import { USER_SELECT } from '../constants';
import type { JiraFieldMapping } from '../types';

export const jiraUserMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<UserFieldValue | undefined> => ({
	field,
	getValueFromJiraIssue: (issue) => {
		const jiraValue = issue.fields[field.key] ?? undefined;

		if (jiraValue === undefined) {
			return undefined;
		}

		// special handling because single user fields are sometimes handled by jira as collection fields
		// we don't know this unless we actually encounter a user array here. in that case, take the first
		// value
		if (isArray(jiraValue) && jiraValue.length > 0) {
			return jiraValue[0];
		}
		return jiraValue;
	},
	getFieldValueForJiraUpdate: (value) => {
		// custom fields can only be people fields, but if the value is restricted to a single user,
		// it shows up here as a User-field
		if (field.key.startsWith('customfield_')) {
			return value !== undefined ? [value] : [];
		}
		return value !== undefined ? value : null;
	},
	getFieldValueForJiraBulkUpdate: (value) => {
		const accountId = value?.accountId;
		return {
			[USER_SELECT]: {
				fieldId: field.key,
				user: accountId ? { accountId } : null,
			},
		};
	},
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
