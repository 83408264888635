export const createRaf = () => {
	let frameId: number | null = null;

	return (callback: () => void) => {
		if (frameId) {
			cancelAnimationFrame(frameId);
		}

		frameId = requestAnimationFrame(callback);
	};
};
