import React from 'react';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { JiraAttachment } from '../../../common/ui/attachment';
import { useMediaFile } from '../../../controllers/media';

type AttachmentProps = {
	id: string;
	mediaClientConfig: MediaClientConfig;
	preview: boolean;
	onClick?: (cardEvent: { mediaItemDetails?: unknown }) => void;
};

export const Attachment = ({ mediaClientConfig, id, preview, onClick }: AttachmentProps) => {
	const { loading, file: attachment } = useMediaFile(id);
	return !attachment && loading ? null : (
		<JiraAttachment
			onClick={onClick}
			attachment={attachment}
			mediaClientConfig={mediaClientConfig}
			preview={preview}
		/>
	);
};

Attachment.defaultProps = {
	preview: false,
};
