import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	type FetchConfluenceAppLinksSuccess,
	FETCH_CONFLUENCE_APP_LINKS_SUCCESS,
} from '../../actions/fetch-confluence-app-links-actions';

type State = ConfluenceAppLink[];

export const initialState: Array<ConfluenceAppLink> = [];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: FetchConfluenceAppLinksSuccess) => {
	switch (action.type) {
		case FETCH_CONFLUENCE_APP_LINKS_SUCCESS: {
			return action.payload;
		}
		default:
			return state;
	}
};
