import React, { useCallback, useState } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useDecorationsForField } from '../../../../controllers/field/selectors/decoration/hooks';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks';
import type { FieldComponentProps } from '../types';
import { NumberField } from './number-field';

type NumberFieldComponentProps = FieldComponentProps & {
	fieldKey: FieldKey;
};

export const NumberFieldComponent = ({
	fieldKey,
	onUpdate,
	predefinedValue,
	...fieldProps
}: NumberFieldComponentProps) => {
	const field = useField(fieldKey);
	const fieldValueDecorations = useDecorationsForField(fieldKey);

	const [value, setValue] = useState<number | undefined>(predefinedValue);

	const updateFieldValue = useCallback(
		(newValue?: number) => {
			setValue(newValue);
			onUpdate(newValue);
		},
		[onUpdate],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<NumberField
			{...fieldProps}
			value={value}
			decorations={fieldValueDecorations}
			fieldType={field.type}
			onUpdate={(newValue?: number) => updateFieldValue(newValue)}
		/>
	);
};
