import { FIELD_TYPES } from '../../field-types';
import type { Field, FieldConfiguration } from '../types';
import { INTERVAL_FIELD_SOURCES } from './index';

export const isIntervalFieldSourcesKey = (
	source: string,
): source is keyof typeof INTERVAL_FIELD_SOURCES => source in INTERVAL_FIELD_SOURCES;

const isValidDeliveryDateConfiguration = (configuration: FieldConfiguration | undefined): boolean =>
	!!(
		configuration &&
		configuration.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE &&
		configuration.customfieldKey &&
		configuration.aggregationType
	);

export const isIntervalFieldWithDeliveryDateConfig = (field: Field): boolean =>
	field.type === FIELD_TYPES.INTERVAL && isValidDeliveryDateConfiguration(field.configuration);
