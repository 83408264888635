import React, { memo, useCallback } from 'react';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { N0, G300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { getPaletteEntryForMainColor } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { Rating } from '@atlassian/jira-polaris-lib-rating/src/ui/index.tsx';
import { getAppliedNumberDecoration } from '../../../controllers/field/decoration';

interface DecoratedRatingProps {
	value: number | undefined;
	getColorForValue: (value: number | undefined) => string | undefined;
	mainColor?: string | undefined;
	onChange?: (arg1: number | undefined) => void;
}

export type Props = {
	decorations: ValueDecoration[] | undefined;
	value: number | undefined;
	onChange?: (arg1: number | undefined) => void;
};

export const DecoratedRatingComponent = memo<DecoratedRatingProps>(
	({ mainColor, value, getColorForValue, onChange }: DecoratedRatingProps) => (
		<Rating
			mainColor={mainColor}
			value={value}
			getColorForValue={getColorForValue}
			onChange={onChange}
		/>
	),
);

export const DecoratedRating = ({ decorations, value, onChange }: Props) => {
	const getColorForValue = useCallback<DecoratedRatingProps['getColorForValue']>(
		(dynamicValue) => {
			const numberDecoration = getAppliedNumberDecoration(decorations, dynamicValue);
			const { mainColor } = getPaletteEntryForMainColor(numberDecoration?.backgroundColor);
			// G300 is a default color for rating elements
			return mainColor === N0 ? G300 : mainColor;
		},
		[decorations],
	);
	return (
		<DecoratedRatingComponent
			mainColor={undefined}
			getColorForValue={getColorForValue}
			value={value}
			onChange={onChange}
		/>
	);
};
