import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	emptyField: {
		id: 'polaris-common.idea-card-v2.body.empty.empty-field',
		defaultMessage: 'None',
		description: 'Empty field value',
	},
	emptyUserField: {
		id: 'polaris-common.idea-card-v2.body.empty.empty-user-field',
		defaultMessage: 'Unassigned',
		description: 'Empty user field value',
	},
	emptyDeliveryProgressField: {
		id: 'polaris-common.idea-card-v2.body.empty.empty-delivery-progress-field',
		defaultMessage: 'No delivery tickets',
		description: 'Empty delivery progress field value',
	},
});
