import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	isLoadingSelector,
	errorSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { isWatchesPresentSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/watch-toggle-selector';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';
import { IssueWatchersLoading, IssueWatchersBoundary } from '../async';
import type IssueWatchersType from '../index';

type Props = {
	isWatchesPresent: boolean;
	isLoading: boolean;
	error: string | null;
};

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueWatchers = lazyForPaint<typeof IssueWatchersType>(
	() => import(/* webpackChunkName: "async-issue-watchers" */ '../index'),
);

const WatchersItem = ({ isWatchesPresent, isLoading, error }: Props) => {
	const loggedInAccountId = useAccountId();
	const shouldRenderWatchers = !error && !!loggedInAccountId;
	const isWatchersLoading = !isWatchesPresent || isLoading;

	if (!shouldRenderWatchers) {
		return null;
	}

	if (isWatchersLoading) {
		return <IssueWatchersLoading />;
	}

	return (
		<IssueWatchersBoundary packageName="issue" fallback={<IssueWatchersLoading />}>
			<IssueWatchers />
		</IssueWatchersBoundary>
	);
};

export const Watchers = connect(
	(state) => ({
		isWatchesPresent: isWatchesPresentSelector(state),
		isLoading: isLoadingSelector(state),
		error: errorSelector(state),
	}),
	{},
)(WatchersItem);
