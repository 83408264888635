import React, { type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
import EmptyState, { type EmptyStateProps } from '@atlaskit/empty-state';
import { Flex, xcss } from '@atlaskit/primitives';
import { ViewDescription } from './styled';

export type ViewEmptyStateProps = {
	renderImage: NonNullable<EmptyStateProps['renderImage']>;
	header: string;
	description: React.ReactNode;
	buttonText?: string;
	onButtonClick?: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	primaryAction?: React.ReactNode;
};

export const ViewEmptyState = ({
	renderImage,
	header,
	description,
	buttonText,
	onButtonClick,
	primaryAction,
}: ViewEmptyStateProps) => (
	<EmptyState
		header={header}
		description={<ViewDescription>{description}</ViewDescription>}
		headingLevel={3}
		primaryAction={
			primaryAction ||
			(onButtonClick ? (
				<Button appearance="primary" onClick={onButtonClick}>
					{buttonText}
				</Button>
			) : null)
		}
		renderImage={(props) => (
			<Flex xcss={imageWrapperStyles} justifyContent="center">
				{renderImage(props)}
			</Flex>
		)}
		width="wide"
	/>
);

const imageWrapperStyles = xcss({
	paddingBlockEnd: 'space.300',
});
