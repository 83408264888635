import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	expressionLabel: {
		id: 'polaris-common.config.fields.common.expression-formula-item.expression-label',
		defaultMessage: 'Expression',
		description: 'Header label for expression field',
	},
	expressionRequiredError: {
		id: 'polaris-common.config.fields.common.expression-formula-item.expression-required-error',
		defaultMessage: 'A non-empty expression is required',
		description: 'Error message representing an empty or missing expression',
	},
});
