import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	formulaHeader: {
		id: 'polaris-common.config.fields.field-item.formula-content.formula-header',
		defaultMessage: 'Formula type',
		description: 'Header label for the formula',
	},
	rollupFormula: {
		id: 'polaris-common.config.fields.field-item.formula-content.rollup-formula',
		defaultMessage: 'Roll-up',
		description: 'Message to describe Roll-up custom formula',
	},
	weightedScoreFormula: {
		id: 'polaris-common.config.fields.field-item.formula-content.weighted-score-formula',
		defaultMessage: 'Weighted score',
		description: 'Message to describe weighted score custom formula',
	},
});
