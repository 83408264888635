import type { ComponentType } from 'react';
import { DefaultCellRenderer, DefaultGroupCellRenderer } from '../../common/ui/renderer/cell';
import { DraggableHandle } from '../../common/ui/renderer/handle';
import { DefaultHeaderRenderer } from '../../common/ui/renderer/header';
import type {
	CellProps,
	GroupCellProps,
	HeaderProps,
	RowGroupRendererComponentProps,
	RowProps,
	RowRendererComponentProps,
	EmptyViewComponentProps,
	GoToRowProps,
	RowPinnedBottomRendererComponentProps,
} from '../../types';
import type { State } from '../types';

export const getCellComponent = (state: State): ComponentType<CellProps> =>
	state.components?.Cell || DefaultCellRenderer;

export const getGroupCellComponent = (state: State): ComponentType<GroupCellProps> =>
	state.components?.GroupCell || DefaultGroupCellRenderer;

export const getHeaderComponent = (state: State): ComponentType<HeaderProps> =>
	state.components?.Header || DefaultHeaderRenderer;

export const getDragHandleComponent = (state: State): ComponentType<RowProps> =>
	state.components?.DragHandle || DraggableHandle;

export const getRowComponent = (
	state: State,
): ComponentType<RowRendererComponentProps> | undefined => state.components?.Row;

export const getRowPinnedBottomComponent = (
	state: State,
): ComponentType<RowPinnedBottomRendererComponentProps> | undefined =>
	state.components?.RowPinnedBottom;

export const getRowGroupComponent = (
	state: State,
): ComponentType<RowGroupRendererComponentProps> | undefined => state.components?.RowGroup;

export const getEmptyViewComponent = (
	state: State,
): ComponentType<EmptyViewComponentProps> | undefined => state.components?.EmptyView;

export const getGoToRowLabelComponent = (state: State): ComponentType<GoToRowProps> | undefined =>
	state.components?.GoToRowLabel;
