import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export type RightSidebarFooterProps = {
	children: ReactNode;
};

export const RightSidebarFooter = ({ children }: RightSidebarFooterProps) => (
	<Box xcss={footerStyles}>{children}</Box>
);

const footerStyles = xcss({
	borderTopStyle: 'solid',
	borderTopWidth: '2px',
	borderTopColor: 'color.border',
	borderRadius: 'border.radius.100',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	paddingTop: 'space.250',
	paddingBottom: 'space.200',
});
