import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import {
	getCurrentViewComments,
	getCurrentViewCommentsLoaded,
	isCurrentViewHavingUnseenComments,
} from './comments';
import { createViewHook } from './utils';

export const useCurrentViewComments: () => PolarisComment[] | undefined =
	createViewHook(getCurrentViewComments);

export const useCurrentViewCommentCount = (): number => {
	const currentViewComments = useCurrentViewComments();
	return currentViewComments === undefined ? 0 : currentViewComments.length;
};

export const useCurrentViewUnseenCommentAvailable: () => boolean = createViewHook(
	isCurrentViewHavingUnseenComments,
);

export const useCurrentViewCommentsLoaded: () => boolean = createViewHook(
	getCurrentViewCommentsLoaded,
);

// Returns the limit type and value if reached, otherwise returns null
export const useHasReachedViewCommentsLimit = (): number | null => {
	const commentsCount = useCurrentViewCommentCount();
	const commentsLimit = useEntityLimitsByType(ENTITY_LIMIT_TYPE.COMMENTS_PER_VIEW);

	if (commentsLimit && commentsCount >= commentsLimit) {
		return commentsLimit;
	}

	return null;
};
