import type {
	Worklog,
	WorklogMap,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';

export const LOADING = 'LOADING' as const;
export const LOADING_NEWER_WORKLOGS = 'LOADING_NEWER_WORKLOGS' as const;
export const LOADING_OLDER_WORKLOGS = 'LOADING_OLDER_WORKLOGS' as const;
export const ERROR = 'ERROR' as const;
export const ERROR_ADDITIONAL = 'ERROR_ADDITIONAL' as const;
export const COMPLETE = 'COMPLETE' as const;

export type LoadingStage =
	| typeof LOADING
	| typeof ERROR
	| typeof ERROR_ADDITIONAL
	| typeof COMPLETE
	| typeof LOADING_NEWER_WORKLOGS
	| typeof LOADING_OLDER_WORKLOGS;

export type LoadingStageOld = typeof LOADING | typeof ERROR | typeof COMPLETE;

export const NO_MODAL = 'NO_MODAL' as const;
export const ADD_MODAL = 'ADD_MODAL' as const;
export const EDIT_MODAL = 'EDIT_MODAL' as const;
export const DELETE_MODAL = 'DELETE_MODAL' as const;

export type WorklogModal =
	| typeof NO_MODAL
	| typeof ADD_MODAL
	| typeof EDIT_MODAL
	| typeof DELETE_MODAL;

export type ModalState = {
	openModal: WorklogModal;
	isSaving: boolean;
	activeWorklogId: string | null;
};

export type PermalinkStatusState = {
	hasScrolledPermalink: boolean;
};

export type WorklogUiState = {
	modal: ModalState;
	loadingStage: LoadingStage;
	highlightedWorklogId: string | null;
	permalinkStatus: PermalinkStatusState;
};

export type TimeTrackingDeltas = {
	timeSpentSecondsDelta: number;
	newRemainingEstimateSeconds: number | null;
};

export type Worklogs = {
	worklogs: Worklog[];
	totalWorklogs: number;
};

export type FetchMoreWorklogs = {
	worklogs: WorklogMap;
	totalWorklogs: number;
	sortedWorklogIds: string[];
	startIndex: number;
};

export type {
	WorklogEntitiesState,
	User,
	Worklog,
	MaybeGroupedWorklog,
	WorklogMap,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
