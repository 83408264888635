import every from 'lodash/every';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import some from 'lodash/some';
import toLower from 'lodash/toLower';
import uniq from 'lodash/uniq';
import type { Filter, TextFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { MappingFilterFunction } from '../types';

export const getStringValueChunks = (filter: Filter): (string | undefined)[] => {
	const chunks: Array<undefined | Array<string>> = [];
	if (filter.type === 'TEXT' || filter.type === 'FIELD') {
		filter.values.forEach((value) => {
			if (value.stringValue !== undefined) {
				chunks.push(toLower(value.stringValue).trim().split(/\s+/));
			} else {
				chunks.push(undefined);
			}
		});
	}
	return uniq(flatten(chunks));
};

export const createDisjunctTextChunkFilter = (
	filter: Filter,
): MappingFilterFunction<string> | undefined => {
	if (filter.type !== 'FIELD' && filter.type !== 'TEXT') {
		return undefined;
	}
	const chunks = getStringValueChunks(filter);
	return (value?: string) => {
		if (value === undefined) {
			return chunks.includes(undefined);
		}
		return some(chunks, (chunk) => chunk !== undefined && toLower(value).includes(chunk));
	};
};

export const createConjunctTextChunkFilter = (
	filter: Filter,
): MappingFilterFunction<string> | undefined => {
	if (filter.type !== 'FIELD' && filter.type !== 'TEXT') {
		return undefined;
	}
	const chunks = getStringValueChunks(filter);
	return (value?: string) => {
		if (value === undefined) {
			return chunks.includes(undefined);
		}
		return every(chunks, (chunk) => chunk !== undefined && toLower(value).includes(chunk));
	};
};

export const createStringValueIntersectionFilter = (
	filter: Filter,
): MappingFilterFunction<string[]> | undefined => {
	if (filter.type !== 'FIELD' && filter.type !== 'TEXT') {
		return undefined;
	}
	if (filter.values.length === 0) {
		return undefined;
	}

	return (values?: string[]): boolean => {
		const stringValues = filter.values.map(({ stringValue }) => stringValue);

		if (values === undefined || values.length === 0) {
			return stringValues.includes(undefined);
		}

		return intersection(values, stringValues).length > 0;
	};
};

export const createStringValueContainsFilter = (
	filter: Filter,
): MappingFilterFunction<string> | undefined => {
	const intersectionFilter = createStringValueIntersectionFilter(filter);

	if (intersectionFilter === undefined) {
		return undefined;
	}

	return (value: string | undefined, state, props, localIssueId): boolean =>
		intersectionFilter(value !== undefined ? [value] : undefined, state, props, localIssueId);
};

export const createStringValuesContainsFilter = (
	filter: Filter,
): MappingFilterFunction<string[]> | undefined => {
	const intersectionFilter = createStringValueIntersectionFilter(filter);

	if (intersectionFilter === undefined) {
		return undefined;
	}

	return (values: string[] | undefined, state, props, localIssueId): boolean =>
		intersectionFilter(values, state, props, localIssueId);
};

export const createContainsSubstringTextFilter = (
	filter: TextFilter,
): MappingFilterFunction<string> | undefined => {
	if (filter.type !== 'TEXT' && filter.localId !== 'quick') {
		return undefined;
	}

	// quick filter only contains 1 entry
	const filterValue = filter.values[0]?.stringValue;
	if (filterValue === undefined) {
		return undefined;
	}

	return (value?: string) => {
		if (value === undefined) {
			return value === filterValue;
		}
		return value.toLowerCase().includes(filterValue.toLowerCase());
	};
};
