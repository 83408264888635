import {
	type LoadingStage,
	LOADING,
	COMPLETE,
	ERROR,
	ERROR_ADDITIONAL,
	LOADING_NEWER_WORKLOGS,
	LOADING_OLDER_WORKLOGS,
} from '@atlassian/jira-issue-view-common-types/src/worklog-type';
import {
	FETCH_ISSUE_SUCCESS,
	type FetchIssueSuccessAction,
} from '../../../common/actions/issue-fetch-actions';
import {
	FETCH_WORKLOGS_REQUEST_INITIAL,
	type FetchWorklogsRequestInitialAction,
	FETCH_WORKLOGS_SUCCESS,
	type FetchWorklogsSuccessAction,
	FETCH_WORKLOGS_FAILURE,
	type FetchWorklogsFailureAction,
	ADD_WORKLOG_SUCCESS,
	type AddWorklogSuccessAction,
	FETCH_WORKLOGS_BY_ID_SUCCESS,
	type FetchWorklogsByIdSuccessAction,
	FETCH_WORKLOGS_BY_ID_FAILURE,
	type FetchWorklogsByIdFailureAction,
	FETCH_SURROUNDING_WORKLOGS_BY_ID,
	type FetchSurroundingWorklogsByIdAction,
	FETCH_SORTED_WORKLOGS_REQUEST,
	type FetchSortedWorklogsRequestAction,
	FETCH_SORTED_WORKLOGS_SUCCESS,
	type FetchSortedWorklogsSuccessAction,
	FETCH_SORTED_WORKLOGS_FAILURE,
	type FetchSortedWorklogsFailureAction,
	FETCH_OLDER_SORTED_WORKLOGS_REQUEST,
	type FetchOlderSortedWorklogsRequestAction,
	FETCH_OLDER_SORTED_WORKLOGS_SUCCESS,
	type FetchOlderSortedWorklogsSuccessAction,
	FETCH_NEWER_SORTED_WORKLOGS_REQUEST,
	type FetchNewerSortedWorklogsRequestAction,
	FETCH_NEWER_SORTED_WORKLOGS_SUCCESS,
	type FetchNewerSortedWorklogsSuccessAction,
	FETCH_SORTED_WORKLOGS_RETRY,
	type FetchSortedWorklogsRetryAction,
} from '../../../common/actions/worklog-actions';

export const initialLoadingStage = LOADING;

type Action =
	| FetchWorklogsRequestInitialAction
	| FetchWorklogsSuccessAction
	| FetchWorklogsFailureAction
	| FetchIssueSuccessAction
	| FetchWorklogsByIdFailureAction
	| AddWorklogSuccessAction
	| FetchWorklogsByIdSuccessAction
	| FetchSurroundingWorklogsByIdAction
	| FetchSortedWorklogsRequestAction
	| FetchSortedWorklogsSuccessAction
	| FetchSortedWorklogsFailureAction
	| FetchOlderSortedWorklogsRequestAction
	| FetchOlderSortedWorklogsSuccessAction
	| FetchNewerSortedWorklogsRequestAction
	| FetchNewerSortedWorklogsSuccessAction
	| FetchSortedWorklogsRetryAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: LoadingStage = initialLoadingStage, action: Action): LoadingStage => {
	switch (action.type) {
		// Using ADD_WORKLOG_SUCCESS instead of ADD_WORKLOG_REQUEST as ADD_WORKLOG_REQUEST action is sent when we press
		// the SAVE button on the worklog form.
		case FETCH_WORKLOGS_REQUEST_INITIAL:
		case ADD_WORKLOG_SUCCESS:
		case FETCH_SURROUNDING_WORKLOGS_BY_ID:
		case FETCH_SORTED_WORKLOGS_REQUEST:
		case FETCH_SORTED_WORKLOGS_RETRY:
			return LOADING;
		case FETCH_NEWER_SORTED_WORKLOGS_REQUEST:
			return LOADING_NEWER_WORKLOGS;
		case FETCH_OLDER_SORTED_WORKLOGS_REQUEST:
			return LOADING_OLDER_WORKLOGS;
		case FETCH_WORKLOGS_SUCCESS:
		case FETCH_ISSUE_SUCCESS:
		case FETCH_WORKLOGS_BY_ID_SUCCESS:
		case FETCH_SORTED_WORKLOGS_SUCCESS:
		case FETCH_OLDER_SORTED_WORKLOGS_SUCCESS:
		case FETCH_NEWER_SORTED_WORKLOGS_SUCCESS:
			return COMPLETE;
		case FETCH_WORKLOGS_FAILURE:
		case FETCH_SORTED_WORKLOGS_FAILURE:
			return state === LOADING ? ERROR : ERROR_ADDITIONAL;
		case FETCH_WORKLOGS_BY_ID_FAILURE:
			return ERROR;
		default: {
			// prettier-ignore

			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
