import { useMemo } from 'react';
import {
	useLocalIssueIdsByCell,
	useLocalIssueIdsByGroupIdentity,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import {
	useCurrentViewCollapsedSwimlanes,
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewHideEmptyGroups,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { EMPTY_VALUE_ID } from '../../../../common/utils/board';
import { useGroupOptions } from '../../common/utils/group-options';
import { useExtendedVerticalGroupOptions } from '../../common/utils/vertical-group-options';
import { BOARD_CARD_GAP, BOARD_COLUMN_MIN_HEIGHT } from '../constants';
import { useBoardViewCardHeights } from '../use-board-view-card-heights';
import type { Group, Column } from '../use-board-virtualization/types.tsx';
import {
	filterOutGroupColumnsWithoutCards,
	filterOutEmptyGroups,
	getAllColumnsWithEmptyValues,
	getRankedGroups,
} from './utils';

// FIXME: we need tests for this, it's crucial to get the groups right before giving it to the virtualization layer

/**
 * TODO: this hook can be expensive (up to 500ms for 1000 issues), especially because of useLocalIssueIdsByCell,
 * it would be best to move it to a selector that memoizes the result once (using memoize-one) for each board view with a map.
 * Spike it in the next iteration of the board view virtualization
 */
export const useBoardViewWithGroups = (
	groupByField: Field,
	verticalGroupByField: Field,
): Group[] => {
	const cardHeights = useBoardViewCardHeights();
	const idsByCell = useLocalIssueIdsByCell(groupByField.key, verticalGroupByField.key);
	const visibleGroups = useExtendedVerticalGroupOptions(verticalGroupByField);
	const visibleGroupKeys: string[] = useMemo(
		() => visibleGroups.map((group) => group.groupIdentity || EMPTY_VALUE_ID),
		[visibleGroups],
	);
	const isEmptyGroupVisible = visibleGroupKeys.includes(EMPTY_VALUE_ID);

	const visibleColumns = useGroupOptions(groupByField);
	const visibleColumnKeys: string[] = useMemo(
		() => visibleColumns.map((column) => column.groupIdentity || EMPTY_VALUE_ID),
		[visibleColumns],
	);
	const isEmptyColumnVisible = visibleColumnKeys.includes(EMPTY_VALUE_ID);
	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();

	const groups = useMemo(() => {
		const groupsMap: Map<string, Column[]> = new Map();
		const allColumns = getAllColumnsWithEmptyValues(
			idsByCell,
			isEmptyColumnVisible,
			isEmptyGroupVisible,
		);

		for (const [columnKey, columnGroupMap] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const columnGroups = Object.entries(columnGroupMap || {});
			for (const [groupKey, localIssueIds] of columnGroups) {
				if (!visibleGroupKeys.includes(groupKey)) {
					// eslint-disable-next-line no-continue
					continue;
				}

				if (groupsMap.get(groupKey) === undefined) {
					groupsMap.set(groupKey, []);
				}

				const localIssueIdsSafe = localIssueIds || [];

				const cards = localIssueIdsSafe.map((localIssueId, index) => ({
					uid: localIssueId,
					height: (cardHeights.get(localIssueId) || BOARD_COLUMN_MIN_HEIGHT) + BOARD_CARD_GAP,
					offset: 0,
					index,
				}));

				groupsMap.get(groupKey)?.push({
					uid: `${groupKey}.${columnKey}`,
					columnUid: columnKey,
					cards,
					height: 0,
					contentHeight: 0,
				});
			}
		}

		const rankedGroups = getRankedGroups(
			groupsMap,
			visibleGroupKeys,
			visibleColumnKeys,
			collapsedSwimlanes,
		);

		const groupsWithFinalColumns = hideEmptyColumns
			? filterOutGroupColumnsWithoutCards(rankedGroups, visibleColumnKeys)
			: rankedGroups;

		const finalGroups = hideEmptyGroups
			? filterOutEmptyGroups(groupsWithFinalColumns)
			: groupsWithFinalColumns;

		return finalGroups;
	}, [
		idsByCell,
		visibleColumnKeys,
		visibleGroupKeys,
		isEmptyColumnVisible,
		isEmptyGroupVisible,
		cardHeights,
		collapsedSwimlanes,
		hideEmptyColumns,
		hideEmptyGroups,
	]);

	return groups;
};

/**
 * Used for the board view without groups. Fakes a group that contains all the columns.
 */
export const useBoardViewWithoutGroups = (groupByField: Field): Group[] => {
	const cardHeights = useBoardViewCardHeights();
	const visibleColumns = useGroupOptions(groupByField);
	const visibleColumnKeys: string[] = useMemo(
		() => visibleColumns.map((column) => column.groupIdentity || EMPTY_VALUE_ID),
		[visibleColumns],
	);
	const isEmptyColumnVisible = visibleColumnKeys.includes(EMPTY_VALUE_ID);
	const idsByColumn = useLocalIssueIdsByGroupIdentity(groupByField.key);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();

	const groups: Group[] = useMemo(() => {
		const columns: Column[] = [];
		const allColumns = Object.entries(idsByColumn.groups);

		if (isEmptyColumnVisible) {
			allColumns.unshift([EMPTY_VALUE_ID, idsByColumn.empty || []]);
		}

		for (const [columnKey, localIssueIds] of allColumns) {
			if (!visibleColumnKeys.includes(columnKey)) {
				// eslint-disable-next-line no-continue
				continue;
			}

			const cards = localIssueIds.map((localIssueId, index) => ({
				uid: localIssueId,
				height: (cardHeights.get(localIssueId) || BOARD_COLUMN_MIN_HEIGHT) + BOARD_CARD_GAP,
				offset: 0,
				index,
			}));

			columns.push({
				uid: columnKey,
				columnUid: columnKey,
				cards,
				height: 0,
				contentHeight: 0,
			});
		}

		const rankedColumns: Column[] = visibleColumnKeys.map((uid) => ({
			uid,
			columnUid: uid,
			cards: [],
			height: 0,
			contentHeight: 0,
		}));

		for (const column of columns) {
			rankedColumns[visibleColumnKeys.indexOf(column.uid)] = column;
		}

		const finalColumns = hideEmptyColumns
			? rankedColumns.filter((column) => column.cards.length > 0)
			: rankedColumns;

		return [
			{
				uid: 'MOCKED_GROUP',
				columns: finalColumns,
				height: 0,
				contentHeight: 0,
				offset: 0,
				maxColumnContentHeight: 0,
				isCollapsed: false,
			},
		];
	}, [isEmptyColumnVisible, cardHeights, idsByColumn, visibleColumnKeys, hideEmptyColumns]);

	return groups;
};
