import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { findView, modifyView } from '../utils';

export const addEmoji =
	(emojiId: string | undefined, localId: LocalViewId): Action<State, Props> =>
	async (
		{ setState, getState },
		{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
	) => {
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view } = findView(getState().projects[projectId], localId);

		if (!view || !view.viewType || view.viewType === 'TWOXTWO') {
			return;
		}

		setState(
			modifyView(getState(), projectId, (innerView) => {
				if (innerView.localId === localId) {
					return {
						...innerView,
						emoji: emojiId,
						isLocked: true,
					};
				}
				return innerView;
			}),
		);

		try {
			const updatedView = await navigationRemote.updateView({
				id: view.id,
				viewType: view.viewType,
				emoji: emojiId,
			});

			setState(
				modifyView(getState(), projectId, (innerView) => {
					if (innerView.localId === localId) {
						return {
							...innerView,
							emoji: updatedView.emoji,
							isLocked: false,
						};
					}
					return innerView;
				}),
			);

			onSuccess?.('addEmoji');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState(
				modifyView(getState(), projectId, (innerView) => {
					if (innerView.localId === localId) {
						return {
							...innerView,
							isLocked: false,
						};
					}
					return innerView;
				}),
			);

			onFailure?.(error, 'addEmoji');
		}
	};
