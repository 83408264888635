import React, { type PropsWithChildren, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useStoreActions } from './controllers';
import { useArjLicensed as useArjLicensedNew } from './controllers/arj';
import {
	InstanceContainer,
	useEditorAiEnabled as useEditorAiEnabledLegacy,
	useIssueLinkingEnabled as useIssueLinkingEnabledLegacy,
	useArjLicensed as useArjLicensedLegacy,
	useInstanceData as useInstanceDataLegacy,
	useHiddenPolarisIssueLinkTypes as useHiddenPolarisIssueLinkTypesLegacy,
	useInstanceActions as useInstanceActionsLegacy,
	useEditionMeta as useEditionMetaLegacy,
	useIssueLinkTypes as useIssueLinkTypesLegacy,
} from './controllers/legacy';
import type { Props } from './controllers/legacy/types';
import {
	useEditorAiEnabled as useEditorAiEnabledNew,
	useIssueLinkingEnabled as useIssueLinkingEnabledNew,
	useDeliveryIssueLinkType as useDeliveryIssueLinkTypeNew,
	useEpicNameFieldKey as useEpicNameFieldKeyNew,
	useMergeIssueLinkType as useMergeIssueLinkTypeNew,
	useDataPointIssueLinkType as useDataPointIssueLinkTypeNew,
	useHiddenPolarisIssueLinkTypes as useHiddenPolarisIssueLinkTypesNew,
	useIsBeta as useIsBetaNew,
	useIsBetaSelfServeEnabledOnSite as useIsBetaSelfServeEnabledOnSiteNew,
	useIsBetaEnforcePermissionChecksEnabledOnSite as useIsBetaEnforcePermissionChecksEnabledOnSiteNew,
	useIssueLinkTypes as useIssueLinkTypesNew,
} from './controllers/selectors';

export { useArjConfiguration, useStatusCategories } from './controllers/selectors';

// === temporary exports for site remote
export {
	SiteRemoteProvider,
	SharingSharingSiteRemoteProvider,
	useSiteRemote,
} from './controllers/legacy/site-remote/main.tsx';
export type { SiteRemote } from './controllers/legacy/site-remote/types.tsx';
// === temporary exports for remote call mocks

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { default as getEditionMetaMock } from './services/jira/edition-meta/mocks';

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { default as getEpicNameMock } from './services/jira/epic-name/mocks';

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { default as getIssueLinkTypesMock } from './services/jira/issue-link-types/mocks';

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { fetchIsEditorAiEnabledMock } from './services/jira/editor-ai/mocks';
// === end temporary exports

/**
 * @deprecated Use `EnvironmentTenantProvider` instead. This will be obsolete with the rollout of `polaris.environment-tenant-component`.
 */
export const EnvironmentTenantContainer = ({
	children,
	...rest
}: PropsWithChildren<
	Props & {
		scope: string; // scope for react-sweet-state container config
	}
>) => {
	if (ff('polaris.environment-tenant-component')) {
		return (
			<>
				<EnvironmentTenantProvider />
				{children}
			</>
		);
	}

	return <InstanceContainer {...rest}>{children}</InstanceContainer>;
};

export const EnvironmentTenantProvider = () => {
	const { load } = useStoreActions();
	const apolloClient = useApolloClient();
	const cloudId = useCloudId();
	const { generalDataLoadingFailedError } = useErrorHandlers();
	const container = useEnvironmentContainer();

	useEffect(() => {
		load({
			apolloClient,
			cloudId,
			onLoadingFailed: generalDataLoadingFailedError,
			container,
		});
	}, [apolloClient, cloudId, generalDataLoadingFailedError, load, container]);

	return null;
};

export const useEditorAiEnabled = functionWithFF(
	'polaris.environment-tenant-component',
	useEditorAiEnabledNew,
	useEditorAiEnabledLegacy,
);

export const useIssueLinkingEnabled = functionWithFF(
	'polaris.environment-tenant-component',
	useIssueLinkingEnabledNew,
	useIssueLinkingEnabledLegacy,
);

export const useArjLicensed = functionWithFF(
	'polaris.environment-tenant-component',
	useArjLicensedNew,
	useArjLicensedLegacy,
);

export const useDeliveryIssueLinkType = functionWithFF(
	'polaris.environment-tenant-component',
	useDeliveryIssueLinkTypeNew,
	() => {
		const [{ polarisDeliveryIssueLinkType }] = useInstanceDataLegacy();
		return polarisDeliveryIssueLinkType;
	},
);

export const useMergeIssueLinkType = functionWithFF(
	'polaris.environment-tenant-component',
	useMergeIssueLinkTypeNew,
	() => {
		const [{ polarisMergeIssueLinkType }] = useInstanceDataLegacy();
		return polarisMergeIssueLinkType;
	},
);

export const useDataPointIssueLinkType = functionWithFF(
	'polaris.environment-tenant-component',
	useDataPointIssueLinkTypeNew,
	() => {
		const [{ polarisDataPointLinkType }] = useInstanceDataLegacy();
		return polarisDataPointLinkType;
	},
);

export const useEpicNameFieldKey = functionWithFF(
	'polaris.environment-tenant-component',
	useEpicNameFieldKeyNew,
	() => {
		const [{ epicNameFieldKey }] = useInstanceDataLegacy();
		return epicNameFieldKey;
	},
);

export const useHiddenPolarisIssueLinkTypes = functionWithFF(
	'polaris.environment-tenant-component',
	useHiddenPolarisIssueLinkTypesNew,
	useHiddenPolarisIssueLinkTypesLegacy,
);

export const useIsBeta = functionWithFF(
	'polaris.environment-tenant-component',
	useIsBetaNew,
	() => {
		const { beta } = useEditionMetaLegacy();
		return beta;
	},
);

export const useIsBetaSelfServeEnabledOnSite = functionWithFF(
	'polaris.environment-tenant-component',
	useIsBetaSelfServeEnabledOnSiteNew,
	() => {
		const { isSelfServeEnabledOnSite } = useEditionMetaLegacy();
		return isSelfServeEnabledOnSite;
	},
);

export const useIsBetaEnforcePermissionChecksEnabledOnSite = functionWithFF(
	'polaris.environment-tenant-component',
	useIsBetaEnforcePermissionChecksEnabledOnSiteNew,
	() => {
		const { isEnforcePermissionChecksEnabledOnSite } = useEditionMetaLegacy();
		return isEnforcePermissionChecksEnabledOnSite;
	},
);

export const useIssueLinkTypes = functionWithFF(
	'polaris.environment-tenant-component',
	useIssueLinkTypesNew,
	useIssueLinkTypesLegacy,
);

export const useBetaActions = functionWithFF(
	'polaris.environment-tenant-component',
	() => {
		const apolloClient = useApolloClient();
		const cloudId = useCloudId();

		const {
			tryAddCurrentUserAsSiteCreator,
			toggleEnforcePermissionChecks,
			toggleSelfServeAddAsSiteCreator,
		} = useStoreActions();

		return {
			tryAddCurrentUserAsSiteCreator: () =>
				tryAddCurrentUserAsSiteCreator({ apolloClient, cloudId }),
			toggleEnforcePermissionChecks,
			toggleSelfServeAddAsSiteCreator,
		};
	},
	useInstanceActionsLegacy,
);
