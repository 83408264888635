import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import messages from './messages';

type Props = {
	limit: number;
};

export const CommentsLimitReachedBanner = ({ limit }: Props) => {
	const { formatMessage } = useIntl();
	const getEntityLimitMessage = useEntityLimitMessage();
	const title = getEntityLimitMessage(ENTITY_LIMIT_TYPE.COMMENTS_PER_VIEW, limit) ?? undefined;

	return (
		<SectionMessage appearance="information" title={title}>
			{formatMessage(messages.description)}
		</SectionMessage>
	);
};
