import { useMemo } from 'react';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { useFields } from '../../views/selectors/fields-hooks';
import { createHook, createHigherLevelHook } from '../main';
import {
	getCopyValuesTasks,
	getAreGlobalFieldsLoading,
	getGlobalField,
	getGlobalFieldData,
	getGlobalFieldFormula,
	getGlobalFields,
	getIsGlobalFieldsLoadingFailed,
} from './global-fields';

const useGlobalFields = createHook(getGlobalFields);

export const useAvailableGlobalFields = () => {
	const globalFields = useGlobalFields();
	const projectFields = useFields();

	return useMemo(
		() =>
			globalFields.filter((globalField) => {
				const isInUse = projectFields[globalField.key] !== undefined;
				const isContextRestricted = globalField.hasRestrictedContext;
				return !isInUse && !isContextRestricted;
			}),
		[globalFields, projectFields],
	);
};

export const useGlobalFieldKeysOfType = (type: FieldType) => {
	const globalFields = useGlobalFields();

	return useMemo(() => {
		const keys = globalFields
			.filter((globalField) => globalField.type === type)
			.map((globalField) => globalField.key);

		return new Set(keys);
	}, [globalFields, type]);
};

export const useGlobalField = createHigherLevelHook(getGlobalField);
export const useGlobalFieldData = createHigherLevelHook(getGlobalFieldData);

export const useAreGlobalFieldsLoading = createHook(getAreGlobalFieldsLoading);
export const useIsGlobalFieldsLoadingFailed = createHook(getIsGlobalFieldsLoadingFailed);

export const useAreAllGlobalFieldsAdded = () => {
	const globalFields = useGlobalFields();
	const availableGlobalFields = useAvailableGlobalFields();

	return availableGlobalFields.length === 0 && globalFields.length > 0;
};

export const useGlobalFieldFormula = createHigherLevelHook(getGlobalFieldFormula);

export const useCopyValuesTasks = createHook(getCopyValuesTasks);
