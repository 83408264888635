import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { token } from '@atlaskit/tokens';
import { ButtonSkeleton } from '@atlassian/jira-polaris-common/src/common/ui/skeleton';
import { useLocalIssueIdForJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import { useSelectedIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { IssueBreadcrumbs } from '../breadcrumbs';
import { COMPACT_LAYOUT_MAX_WIDTH } from '../constants';
import { FieldsSkeleton } from '../fields/field/skeleton';
import {
	KeyContainer,
	IdeaViewHeader,
	IdeaViewSidebar,
	HeaderControlsContainer,
	IdeaViewContainer,
	IdeaViewBody,
	IdeaViewContent,
	IdeaViewComponentContainer,
	TitleContainer,
} from '../idea-view/main.tsx';
import { SectionsSkeleton } from '../sections/skeleton';
import { Summary } from '../summary';

const IdeaViewSkeleton = () => {
	const [isCompact, setIsCompact] = useState<boolean | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const id = useSelectedIssueId();
	const issueId = id !== undefined ? toIssueId(id.toString()) : undefined;
	const localIssueId = useLocalIssueIdForJiraIssueId(issueId ?? '') || '';

	useEffect(() => {
		const containerWidth = containerRef.current?.clientWidth;
		setIsCompact(containerWidth !== undefined && containerWidth <= COMPACT_LAYOUT_MAX_WIDTH);
	}, []);

	if (id === undefined) {
		return null;
	}

	if (isCompact === null) {
		return <div ref={containerRef} />;
	}

	return (
		<IdeaViewContainer>
			<IdeaViewComponentContainer>
				<IdeaViewHeader isCompact={isCompact}>
					<KeyContainer isCompact={isCompact}>
						<IssueBreadcrumbs />
					</KeyContainer>
					<HeaderControlsContainer>
						{!isCompact && (
							<>
								<CollaborationButtonSkeleton width="66px" />
								<CollaborationButtonSkeleton width="74px" />
							</>
						)}
						<CollaborationButtonSkeleton width="32px" />
						<CollaborationButtonSkeleton width="32px" />
						<Button
							appearance="subtle-link"
							iconBefore={<CrossIcon label="close" size="medium" />}
							isDisabled
						/>
					</HeaderControlsContainer>
				</IdeaViewHeader>
				<TitleContainer>
					<Summary />
				</TitleContainer>
				<IdeaViewBody isCompact={isCompact}>
					<IdeaViewContent isCompact={isCompact}>
						<ControlsSkeletonContainer>
							<ControlButtonSkeleton width="32px" />
							<ControlButtonSkeleton width="32px" />
							<ControlButtonSkeleton width="32px" />
						</ControlsSkeletonContainer>
						<DescriptionSkeletonContainer>
							<AdfSkeleton />
						</DescriptionSkeletonContainer>
						{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any */}
						{(isCompact as any) && <FieldsSkeleton />}
						<SectionsSkeleton localIssueId={localIssueId} />
					</IdeaViewContent>
					{!isCompact && (
						<IdeaViewSidebar>
							<FieldsSkeleton />
						</IdeaViewSidebar>
					)}
				</IdeaViewBody>
			</IdeaViewComponentContainer>
		</IdeaViewContainer>
	);
};

export default IdeaViewSkeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionSkeletonContainer = styled.div({
	padding: `0 ${token('space.100', '8px')} ${token('space.100', '8px')}`,
	marginBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollaborationButtonSkeleton = styled(ButtonSkeleton)({
	marginRight: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsSkeletonContainer = styled.div({
	marginBottom: token('space.200', '16px'),
	marginLeft: token('space.100', '8px'),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlButtonSkeleton = styled(ButtonSkeleton)({
	marginRight: token('space.050', '4px'),
});
