import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.rating-decoration.item.edit-rule',
		defaultMessage: 'Edit rule',
		description: 'Button to edit the current rule.',
	},
	deleteRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.rating-decoration.item.delete-rule',
		defaultMessage: 'Delete rule',
		description: 'Button to delete the current rule.',
	},
	clearFormatting: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.rating-decoration.item.clear-formatting',
		defaultMessage: 'Clear formatting',
		description: 'Button to clear formatting for the current rule.',
	},
});
