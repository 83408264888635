import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	settings: {
		id: 'polaris-ideas.sidebar.settings.section.settings',
		defaultMessage: 'Project settings',
		description: 'Label for link to Jira project settings page.',
	},
	archive: {
		id: 'polaris-ideas.sidebar.settings.section.archive',
		defaultMessage: 'Archive',
		description: 'Label for archive view.',
	},
});
