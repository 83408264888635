import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	infoMessage: {
		id: 'polaris-common.field-info-marker.formula.info-message',
		defaultMessage:
			'We can’t refresh app data in some of your ideas. The output of your formula might need to be updated. Click here to authenticate again.',
		description:
			'Message shown to the customer when a formula uses data that cannot be fetched due to auth errors. In this case customer may need to refresh auth data',
	},
});
