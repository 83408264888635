import { resolveObject as resolveObjectCall } from '../../services/polaris-api/resolve-object';
import type { ProviderProps, RemoteObjectResolver, ResolveObjectRequest } from '../../types';

export const resolveObject =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteObjectResolver =>
	({ issueId, url, authToken }: ResolveObjectRequest) =>
		resolveObjectCall(apolloClient, cloudId, projectId, issueId, url, authToken).then(
			(response) => ({
				...response,
				externalAuth: response.externalAuth ? [...response.externalAuth] : null,
			}),
		);

export const multiProjectsResolveObject =
	({ apolloClient, cloudId }: Omit<ProviderProps, 'projectId'>): RemoteObjectResolver =>
	({ issueId, url, authToken, projectId }: ResolveObjectRequest) => {
		if (!projectId)
			throw new Error(
				'insightRemote.resolveObject(): ProjectId is undefined. Can not resolve object without it.',
			);

		return resolveObjectCall(apolloClient, cloudId, projectId, issueId, url, authToken).then(
			(response) => ({
				...response,
				externalAuth: response.externalAuth ? [...response.externalAuth] : null,
			}),
		);
	};
