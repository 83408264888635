import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ff } from '@atlassian/jira-feature-flagging';
import { FetchError, isHttpClientErrorResponse } from '@atlassian/jira-fetch';
import {
	type SaveRemoteIssueLinkPayload,
	type SaveRemoteIssueLinkResponse,
	RECIPROCAL_LINK_RESPONSE_STATUS_CREATED,
	REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID,
	REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID,
} from '@atlassian/jira-issue-shared-types';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { saveRemoteIssueLink } from '@atlassian/jira-issue-view-services/src/issue/remote-issue-links-add-server';
import {
	saveRemoteLinkedIssueSuccess,
	saveRemoteLinkedIssueFailure,
	type SaveRemoteLinkedIssueRequest,
	SAVE_REMOTE_LINKED_ISSUE_REQUEST,
	SAVE_REMOTE_LINKED_ISSUE_RETRY,
	type RemoteIssueLinksAction,
} from '@atlassian/jira-issue-view-store/src/actions/remote-issue-links-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { remoteIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector';

const saveRemoteLinkedIssue = (
	state: State,
	saveRemoteIssueLinkPayload: SaveRemoteIssueLinkPayload,
	requestAnalyticsEvent: UIAnalyticsEvent,
	onSuccess: (() => void) | undefined,
	onError: (() => void) | undefined,
) => {
	const baseUrl = baseUrlSelector(state);
	const thisIssueKey = issueKeySelector(state);

	// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<SaveRemoteIssueLinkResponse>'.
	return saveRemoteIssueLink(baseUrl, thisIssueKey, saveRemoteIssueLinkPayload)
		.map((response: SaveRemoteIssueLinkResponse) =>
			saveRemoteLinkedIssueSuccess(
				saveRemoteIssueLinkPayload.id,
				response,
				requestAnalyticsEvent.update({
					action: 'succeeded',
					isReciprocalLinkCreated:
						response.reciprocalLink?.status === RECIPROCAL_LINK_RESPONSE_STATUS_CREATED,
				}),
			),
		)
		.do(() => {
			onSuccess?.();
		})
		.catch((error) => {
			if (
				!(
					ff('ken-429-restrict-4xx-error_vi8v7') &&
					error instanceof FetchError &&
					isHttpClientErrorResponse(error)
				)
			) {
				onError?.();
			}
			return Observable.of(
				saveRemoteLinkedIssueFailure(
					saveRemoteIssueLinkPayload.id,
					saveRemoteIssueLinkPayload.issueKey,
					error,
					requestAnalyticsEvent.update({
						action: 'failed',
						isReciprocalLinkCreated: false,
					}),
				),
			);
		});
};

export const saveRemoteLinkedIssueRequestEpic = (
	action$: ActionsObservable<RemoteIssueLinksAction>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(SAVE_REMOTE_LINKED_ISSUE_REQUEST)
		.mergeMap((action: SaveRemoteLinkedIssueRequest) => {
			const state = store.getState();
			const {
				saveRemoteIssueLinkPayload,
				requestAnalyticsEvent,
				onSuccess = undefined,
				onError = undefined,
			} = action.payload;
			return saveRemoteLinkedIssue(
				state,
				saveRemoteIssueLinkPayload,
				requestAnalyticsEvent,
				onSuccess,
				onError,
			);
		});

const getAndValidateSaveRemoteIssueLinkPayload = (
	state: State,
	optimisticId: string,
): SaveRemoteIssueLinkPayload | undefined => {
	const optimisticRemoteIssueLink = remoteIssuesSelector(state).find(
		(link) => link.id === optimisticId,
	);
	if (
		!optimisticRemoteIssueLink ||
		optimisticRemoteIssueLink.applicationName === undefined ||
		optimisticRemoteIssueLink.applicationType === undefined ||
		optimisticRemoteIssueLink.iconUrl === undefined ||
		optimisticRemoteIssueLink.relationship === undefined ||
		optimisticRemoteIssueLink.summary === undefined
	) {
		return undefined;
	}
	const partialPayload = {
		id: optimisticRemoteIssueLink.id,
		applicationType: optimisticRemoteIssueLink.applicationType,
		applicationName: optimisticRemoteIssueLink.applicationName,
		issueLinkUrl: optimisticRemoteIssueLink.issueLinkUrl,
		relationship: optimisticRemoteIssueLink.relationship,
		summary: optimisticRemoteIssueLink.summary,
		iconUrl: optimisticRemoteIssueLink.iconUrl,
		issueKey: optimisticRemoteIssueLink.issueKey,
		createReciprocalLink: Boolean(optimisticRemoteIssueLink.createReciprocalLink),
	};
	if (optimisticRemoteIssueLink.globalId === undefined) {
		if (optimisticRemoteIssueLink.appLinkId === undefined) {
			return undefined;
		}
		return {
			...partialPayload,
			// @ts-expect-error - TS2322 - Type '{ appLinkId: string; payloadType: "WITHOUT_ISSUE_ID"; id: string; applicationType: string; applicationName: string; issueLinkUrl: string; relationship: string; summary: string; iconUrl: string; issueKey: string; createReciprocalLink: boolean; }' is not assignable to type 'RemoteIssueLinkPayloadWithIssueId'.
			appLinkId: optimisticRemoteIssueLink.appLinkId,
			payloadType: REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID,
		};
	}
	return {
		...partialPayload,
		globalId: optimisticRemoteIssueLink.globalId,
		remoteIssueId: optimisticRemoteIssueLink.remoteIssueId,
		payloadType: REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID,
	};
};

export const saveRemoteLinkedIssueRetryEpic = (
	action$: ActionsObservable<RemoteIssueLinksAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(SAVE_REMOTE_LINKED_ISSUE_RETRY).mergeMap((action) => {
		const state = store.getState();
		const {
			optimisticId,
			requestAnalyticsEvent,
			onSuccess = undefined,
			onError = undefined,
		} = action.payload;
		// optimistic remoteIssueLink is always supposed to be a "valid SaveRemoteIssueLinkPayload"
		// getAndValidateSaveRemoteIssueLinkPayload() is used for flow type conversion
		const saveRemoteIssueLinkPayload = getAndValidateSaveRemoteIssueLinkPayload(
			state,
			optimisticId,
		);
		if (saveRemoteIssueLinkPayload) {
			return saveRemoteLinkedIssue(
				state,
				saveRemoteIssueLinkPayload,
				requestAnalyticsEvent.update({
					withReciprocalLink: Boolean(saveRemoteIssueLinkPayload.createReciprocalLink),
				}),
				onSuccess,
				onError,
			);
		}
		return Observable.empty<never>();
	});
