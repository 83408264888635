import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'polaris-lib-option-utils.delete-option.delete-modal.delete',
		defaultMessage: 'Delete',
		description: 'Button label for the field option deletion confirmation dialog',
	},
	cancel: {
		id: 'polaris-lib-option-utils.delete-option.delete-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for the field option deletion confirmation dialog',
	},
	deleteHeader: {
		id: 'polaris-lib-option-utils.delete-option.delete-modal.delete-header',
		defaultMessage: 'Delete "{optionLabel}" ?',
		description: 'Header caption in polaris delete field option confirmation dialog',
	},
	deleteIndeterminateHeader: {
		id: 'polaris-lib-option-utils.delete-option.delete-modal.delete-indeterminate-header',
		defaultMessage: 'Delete option?',
		description: 'Header caption in polaris delete field option confirmation dialog',
	},
	description: {
		id: 'polaris-lib-option-utils.delete-option.delete-modal.description',
		defaultMessage:
			'This deletes your option. It will be removed from all Ideas. This cannot be undone.',
		description: 'Description in polaris delete field option confirmation dialog',
	},
});
