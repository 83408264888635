import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	performPutRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';

const getPropertyUrl = (projectIdOrKey: string | number, propertyKey: string): string =>
	`/rest/api/3/project/${projectIdOrKey}/properties/${propertyKey}`;

export const getPropertyValue = (
	projectIdOrKey: string | number,
	propertyKey: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> =>
	fetchJson(getPropertyUrl(projectIdOrKey, propertyKey)).then((response) => {
		try {
			return JSON.parse(response.value);
		} catch {
			// This needs to be here to handle string typed properties. even though they are
			// set below as JSON.stringify(value), they are just strings in this response.
			// Therefore JSON.parse fails for them.
			if (typeof response.value === 'string') {
				return response.value;
			}
			return null;
		}
	});

export const setPropertyValue = (
	projectIdOrKey: string | number,
	propertyKey: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
): Promise<void> =>
	performPutRequest(getPropertyUrl(projectIdOrKey, propertyKey), {
		body: JSON.stringify(value),
	});

export const deletePropertyValue = (projectIdOrKey: string, propertyKey: string): Promise<void> =>
	performDeleteRequest(getPropertyUrl(projectIdOrKey, propertyKey));
