import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeLinkTooltip: {
		id: 'polaris-ideas.common.related-issue.remove-link-tooltip',
		defaultMessage: 'Unlink issue',
		description: 'Tooltip for removing the issue-link.',
	},
	archivedLabel: {
		id: 'polaris-ideas.common.related-issue.archived-label',
		defaultMessage: 'Archived',
		description: 'Archived label',
	},
});
