import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_DeleteViewComment as DeleteViewComment,
	jira_polaris_DeleteViewCommentVariables as DeleteViewCommentVariables,
} from './__generated_apollo__/jira_polaris_DeleteViewComment';

const DELETE_VIEW_COMMENT_MUTATION = gqlTagPolaris`
mutation jira_polaris_DeleteViewComment($commentId: ID!) {
  updatePolarisComment(input: {
    id: $commentId
    delete: true
  }) {
    success
    errors {
        message
    }
  }
}
`;

export type ViewCommentDeleter = (arg1: { commentId: Ari }) => Promise<void>;

export const deleteViewComment = async (
	apolloClient: PolarisApolloClient,
	commentId: Ari,
): Promise<void> => {
	const result = await apolloClient.mutate<DeleteViewComment, DeleteViewCommentVariables>({
		mutation: DELETE_VIEW_COMMENT_MUTATION,
		variables: {
			commentId,
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`view-comment.delete-view-comment-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}
};
