import log from '@atlassian/jira-common-util-logging/src/log';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const loadDeliveryTicketsEstimate =
	(): Action<State, Props> =>
	async ({ setState, getState }, { issueKey, issuesRemote }) => {
		if (!issueKey || issuesRemote.fetchLinkedIssuesStoryPointField === undefined) {
			return;
		}

		const state = getState();
		const currentDeliveryTickets = state.deliveryTickets;
		const { issueLinks, storyPointsCustomFieldId } = state;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const issueKeys = issueLinks.reduce<Array<any>>((result, issueLink) => {
			const newResult = Array.from(result);
			if (issueLink.inwardIssue?.key !== undefined) {
				newResult.push(issueLink.inwardIssue.key);
			}
			if (issueLink.outwardIssue?.key !== undefined) {
				newResult.push(issueLink.outwardIssue.key);
			}
			return newResult;
		}, []);

		if (!currentDeliveryTickets.length || !storyPointsCustomFieldId) {
			return;
		}

		await issuesRemote
			.fetchLinkedIssuesStoryPointField({
				issueKeys,
				storyPointsCustomFieldId,
			})
			.then((linkedIssues) => {
				const deliveryTicketsWithEstimationField = currentDeliveryTickets.map((ticket) => {
					const externalIssue = linkedIssues.find(({ id }) => ticket.issueId === id);
					const estimationField = externalIssue?.fields
						? externalIssue.fields[storyPointsCustomFieldId]
						: undefined;
					return {
						...ticket,
						estimation: ticket.estimation || estimationField,
					};
				});
				setState({
					deliveryTickets: deliveryTicketsWithEstimationField,
				});
			})
			.catch((err) => {
				log.safeErrorWithoutCustomerData(
					'polaris.load-delivery-tickets-estimate',
					'Failed to fetch delivery tickets estimate',
					err,
				);
			});
	};
