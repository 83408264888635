import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTooltip: {
		id: 'polaris-common.common.field.text-field.error-tooltip',
		defaultMessage: 'Error',
		description: 'Tooltip error indicator',
	},
	defaultViewTitle: {
		id: 'polaris-common.common.field.text-field.default-view-title',
		defaultMessage: 'Untitled View',
		description: 'Header text to be used as a default when a view title changes to empty',
	},
	defaultSectionTitle: {
		id: 'polaris-common.common.field.text-field.default-section-title',
		defaultMessage: 'Untitled Section',
		description: 'Header text to be used as a default when a section title changes to empty',
	},
});
