import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-ideas.view-controls.config-layout.title',
		defaultMessage: 'Card layout',
		description: '',
	},
	optionSummaryNonFinal: {
		id: 'polaris-ideas.view-controls.config-layout.option-summary-non-final',
		defaultMessage: 'Summary',
		description: '',
	},
	optionCompact: {
		id: 'polaris-ideas.view-controls.config-layout.option-compact',
		defaultMessage: 'Compact',
		description: '',
	},
	optionDetailed: {
		id: 'polaris-ideas.view-controls.config-layout.option-detailed',
		defaultMessage: 'Detailed',
		description: '',
	},
});
