import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

export const GET_LIST_QUERY = gqlTagPolaris`
query jira_polaris_getList($listId: ID!) {
    polaris {
        ranking {
            list(listId: $listId) {
                rank
                id
            }
        }
    }
}`;

type GetRankingProps = {
	listId: Ari;
};

export const createGetRanking =
	(client: PolarisApolloClient): (({ listId }: GetRankingProps) => Promise<string[] | undefined>) =>
	({ listId }) =>
		client
			.query({
				query: GET_LIST_QUERY,
				fetchPolicy: 'network-only',
				variables: {
					listId,
				},
			})
			.then((result) => {
				if (result.errors !== undefined) {
					throw new Error(
						`ranking-service.get-list-error:${result.errors.map((e) => e.message).join(', ')}`,
					);
				}
				if (result.data.polaris.ranking.list) {
					return result.data.polaris.ranking.list.map(({ id }: { id: string }) => id);
				}
				return undefined;
			});
