import type { CollectionView, ViewResponse } from '@atlassian/jira-polaris-domain-collection';

export const transformView = (view: ViewResponse): CollectionView => ({
	uuid: view.uuid,
	ownerId: view.owner,
	kind: view.visualizationType,
	title: view.name,
	emoji: view.emoji,
	description: view.description,
	apiResponse: view,
});
