import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { log } from '@atlassian/jira-common-util-logging';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type {
	WebLink,
	ApplicationInput,
} from '@atlassian/jira-issue-view-common-types/src/web-links-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import {
	createWebLinkFailed,
	createWebLinkSuccess,
	deleteWebLinkSuccess,
	deleteWebLinkFailed,
	type DeleteWebLinkRequest,
	type CreateWebLinkRequest,
	type CreateWebLinkRetry,
	DELETE_WEB_LINK_REQUEST,
	CREATE_WEB_LINK_REQUEST,
	CREATE_WEB_LINK_RETRY,
} from '@atlassian/jira-issue-view-store/src/actions/web-links-actions';
import {
	baseUrlSelector,
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { webLinkSelector } from '@atlassian/jira-issue-view-store/src/selectors/web-links-selector';
import { toIssueId, toHref, type BaseUrl, type IssueId } from '@atlassian/jira-shared-types';
import { getDeleteRequest, createWebLinkRequest } from './web-links-server';

const LOG_LOCATION = 'issue.fetch.web-links-epic';

const logError = (error: Error | TypeError, logMessage: string, logLocation: string): void => {
	trackOrLogClientError(logLocation, logMessage, error);
};

export const makeCreateRequest = (
	action: CreateWebLinkRequest | CreateWebLinkRetry,
	baseUrl: BaseUrl,
	issueId: IssueId | null | undefined,
	optimisticWebLink: WebLink | null | undefined,
	actionType: typeof CREATE_WEB_LINK_RETRY | typeof CREATE_WEB_LINK_REQUEST,
) => {
	if (issueId === undefined || optimisticWebLink === undefined) {
		const errorMessage =
			issueId === undefined
				? 'No issueId when creating weblink'
				: 'Broken weblink state when creating weblink';
		log.safeErrorWithoutCustomerData(LOG_LOCATION, actionType, new Error(errorMessage));
		return Observable.empty<never>();
	}

	// @ts-expect-error - TS2339 - Property 'href' does not exist on type 'WebLink | null'. | TS2339 - Property 'linkText' does not exist on type 'WebLink | null'. | TS2339 - Property 'iconUrl' does not exist on type 'WebLink | null'.
	const { href, linkText, iconUrl, applicationType, applicationName } = optimisticWebLink;
	const iconUrlHref = iconUrl === null || iconUrl === undefined ? '' : iconUrl;
	const application: ApplicationInput | undefined =
		applicationType && applicationName
			? { type: applicationType, name: applicationName }
			: undefined;
	return (
		Observable.from(
			createWebLinkRequest({
				baseUrl,
				href,
				// TODO: Need to understand & update logic code to fix this issue, e.g. checking null and return a default value
				// @ts-expect-error - TS2531 - Object is possibly 'null'.
				issueId: toIssueId(issueId.toString()),
				linkText: linkText == null ? href : linkText,
				iconUrl: toHref(iconUrlHref),
				application,
			}),
		)
			// @ts-expect-error - TS2531 - Object is possibly 'null'.
			.map((webLink) => createWebLinkSuccess(optimisticWebLink.id, webLink))
			.catch((error) => {
				const logMessage = `${actionType}: Failed to create web link`;
				logError(error, logMessage, LOG_LOCATION);
				// @ts-expect-error - TS2531 - Object is possibly 'null'.
				return Observable.of(createWebLinkFailed(optimisticWebLink.id, error));
			})
	);
};

export const deleteWebLink = (
	action$: ActionsObservable<DeleteWebLinkRequest>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(DELETE_WEB_LINK_REQUEST).switchMap((action: DeleteWebLinkRequest) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);
		const { id } = action.payload;

		return Observable.fromPromise(getDeleteRequest(baseUrl, issueKey, id))
			.map(() => deleteWebLinkSuccess(id))
			.catch((error) => {
				const logMessage = `${DELETE_WEB_LINK_REQUEST}: Failed to delete web link`;
				logError(error, logMessage, LOG_LOCATION);
				return Observable.of(deleteWebLinkFailed(id));
			});
	});

export const createWebLink = (
	action$: ActionsObservable<CreateWebLinkRequest>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(CREATE_WEB_LINK_REQUEST).switchMap((action: CreateWebLinkRequest) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueId = issueIdSelector(state);
		const { optimisticId } = action.payload;
		const optimisticWebLink = webLinkSelector(optimisticId)(state);
		return makeCreateRequest(action, baseUrl, issueId, optimisticWebLink, CREATE_WEB_LINK_REQUEST);
	});

export const createWebLinkRetry = (
	action$: ActionsObservable<CreateWebLinkRetry>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(CREATE_WEB_LINK_RETRY).switchMap((action: CreateWebLinkRetry) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueId = issueIdSelector(state);
		const { optimisticId } = action.payload;
		const optimisticWebLink = webLinkSelector(optimisticId)(state);
		return makeCreateRequest(action, baseUrl, issueId, optimisticWebLink, CREATE_WEB_LINK_RETRY);
	});
