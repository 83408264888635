import type { IssueTypeUiState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	type ChangeIssueTypeActions,
	SHOW_ISSUE_TYPE_LIST,
} from '../actions/change-issue-type-actions';

export const initialState: IssueTypeUiState = {
	shouldShowIssueTypeList: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueTypeUiState = initialState, action: ChangeIssueTypeActions) => {
	const { type, payload } = action;
	switch (type) {
		case SHOW_ISSUE_TYPE_LIST:
			return {
				...state,
				shouldShowIssueTypeList: payload,
			};
		default:
			return state;
	}
};
