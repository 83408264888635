import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import difference from 'lodash/difference';
import head from 'lodash/head';
import { useApolloClient } from '@apollo/react-hooks';
import { token } from '@atlaskit/tokens';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { EXTERNAL_REFERENCE_PROVIDERS } from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useStateWithRef } from '../../../common/utils/react/hooks';
import { useCloudId } from '../../../common/utils/tenant-context';
import { useAtlasActions } from '../../../controllers/atlas';
import { useIsEmbedded } from '../../../controllers/environment';
import { useField } from '../../../controllers/issue/selectors/fields-hooks';
import { useProjectProperty } from '../../../controllers/project-properties';
import { ProjectProperties } from '../../../controllers/project-properties/types';
import { useOpenRightSidebarOnField } from '../../../controllers/right-sidebar/actions/hooks.tsx';
import { getAtlasGoalsSearch, getAtlasGoalByKey } from '../../../services/atlas/goals';
import { getAtlasProjectsSearch, getAtlasProjectByKey } from '../../../services/atlas/projects';
import { ProductType } from '../../../services/jira/available-products/types.tsx';
import { WithAvailableProduct } from '../../available-products';
import { Connect } from './common/connect/index.tsx';
import { ExternalReferenceEdit } from './edit';
import { ExternalReferenceView } from './view';

const ATLAS_GOAL_URL_REGEXP =
	/https:\/\/(?:home(?:\.stg)?|team(?:\.stg)?)\.atlassian\.com\/(?:.*\/)?goal\/([\d\w]+-\d+)\/?.*/;
const ATLAS_PROJECT_URL_REGEXP =
	/https:\/\/(?:home(?:\.stg)?|team(?:\.stg)?)\.atlassian\.com\/(?:.*\/)?project\/([\d\w]+-\d+)\/?.*/;

export type Props = {
	isActive?: boolean;
	isEditable: boolean;
	fieldKey: FieldKey;
	isMultiline?: boolean;
	placeholder?: string | undefined;
	menuPortalTarget?: HTMLElement;
	compactForBoard?: boolean;
	isCompact?: boolean;
	onUpdate: (arg1: string | string[] | undefined) => void;
	value: string[] | undefined;
};

export const ExternalReferenceField = ({
	isActive,
	fieldKey,
	isEditable,
	value: externalValue,
	menuPortalTarget,
	onUpdate,
	placeholder,
	isMultiline,
	compactForBoard = false,
	isCompact = false,
}: Props) => {
	const field = useField(fieldKey);
	const openFieldConfig = useOpenRightSidebarOnField();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [{ value: atlasSiteCloudId }] = useProjectProperty(ProjectProperties.ATLAS_SITE_CLOUD_ID);

	const isMulti = field?.configuration?.isMulti;
	const provider = field?.configuration?.provider;
	const configCloudId = (typeof atlasSiteCloudId === 'string' && atlasSiteCloudId) || undefined;

	const canEditFields = useCanEditFields();

	const [value, setValue, valueRef] = useStateWithRef<string[] | undefined>(externalValue);
	const [previousValue, setPreviousValue] = useState<string[] | undefined>(externalValue);
	const embedded = useIsEmbedded();
	const [isEditting, setIsEditting] = useState(false);
	const [signInRequired, setSignInRequired] = useState(false);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const isInEditView = useMemo(
		() => isEditable && isActive && isEditting,
		[isActive, isEditable, isEditting],
	);

	const client = useApolloClient();
	const cloudId = useCloudId();
	const [, { addGoals, addProjects }] = useAtlasActions();

	const fetchOptionsCloudId = useMemo(() => {
		if (typeof atlasSiteCloudId === 'string' && atlasSiteCloudId) {
			return atlasSiteCloudId;
		}
		return cloudId;
	}, [atlasSiteCloudId, cloudId]);

	const onFetchOptions = useCallback(
		(search: string) => {
			switch (provider) {
				case EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL: {
					setSignInRequired(false);

					const match = search.match(ATLAS_GOAL_URL_REGEXP);
					const key = match?.[1];

					if (key) {
						return getAtlasGoalByKey(client, fetchOptionsCloudId, key)
							.then((response) => {
								if (response === null) {
									return [];
								}
								addGoals([response]);
								return [response].map((item) => ({
									id: item.id,
									value: item.id,
								}));
							}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
							.catch(({ graphQLErrors }: any) => {
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								graphQLErrors?.forEach((graphQLError: any) => {
									if (graphQLError?.extensions?.statusCode === 401) {
										setSignInRequired(true);
									}
								});
								return [];
							});
					}
					return getAtlasGoalsSearch(client, fetchOptionsCloudId, search)
						.then((response) => {
							addGoals(response);
							return response.map((item) => ({
								id: item.id,
								value: item.id,
							}));
						}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
						.catch(({ graphQLErrors }: any) => {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							graphQLErrors?.forEach((graphQLError: any) => {
								if (graphQLError?.extensions?.statusCode === 401) {
									setSignInRequired(true);
								}
							});
							return [];
						});
				}
				case EXTERNAL_REFERENCE_PROVIDERS.ATLAS_PROJECT: {
					setSignInRequired(false);

					const match = search.match(ATLAS_PROJECT_URL_REGEXP);
					const key = match?.[1];

					if (key) {
						return getAtlasProjectByKey(client, fetchOptionsCloudId, key)
							.then((response) => {
								if (response === null) {
									return [];
								}
								addProjects([response]);
								return [response].map((item) => ({
									id: item.id,
									value: item.id,
								}));
							}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
							.catch(({ graphQLErrors }: any) => {
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								graphQLErrors?.forEach((graphQLError: any) => {
									if (graphQLError?.extensions?.statusCode === 401) {
										setSignInRequired(true);
									}
								});
								return [];
							});
					}

					return getAtlasProjectsSearch(client, fetchOptionsCloudId, search)
						.then((response) => {
							addProjects(response);
							return response.map((item) => ({
								id: item.id,
								value: item.id,
							}));
						}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
						.catch(({ graphQLErrors }: any) => {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							graphQLErrors?.forEach((graphQLError: any) => {
								if (graphQLError?.extensions?.statusCode === 401) {
									setSignInRequired(true);
								}
							});
							return [];
						});
				}
				default:
					return Promise.resolve([]);
			}
		},
		[fetchOptionsCloudId, client, provider, addGoals, addProjects],
	);

	// overwrite local state if the value changes from the outside (e.g. bento)
	useEffect(() => {
		if (!isShallowEqual(previousValue, externalValue)) {
			setValue(externalValue);
			setPreviousValue(externalValue);
		}
	}, [externalValue, previousValue, setValue]);

	const closeAndCommit = useCallback(
		(newValue: string[] | undefined) => {
			setIsEditting(false);
			const updateAsArray = newValue !== undefined ? newValue : undefined;

			if (isShallowEqual(previousValue, newValue)) {
				return;
			}

			if (isMulti) {
				onUpdate(updateAsArray);
			} else {
				onUpdate(updateAsArray !== undefined ? head(updateAsArray) : undefined);
			}

			let eventPrefix;

			switch (provider) {
				case EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL:
					eventPrefix = 'goal';
					break;
				case EXTERNAL_REFERENCE_PROVIDERS.ATLAS_PROJECT:
					eventPrefix = 'project';
					break;
				default:
					return;
			}

			if (previousValue === undefined || difference(updateAsArray, previousValue).length) {
				fireTrackAnalytics(createAnalyticsEvent({}), `${eventPrefix}Fusion created`, {
					product: 'jpd',
				});
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), `${eventPrefix}Fusion deleted`, {
					product: 'jpd',
				});
			}
		},
		[isMulti, setIsEditting, provider, onUpdate, createAnalyticsEvent, previousValue],
	);

	const onCloseRequested = useCallback(() => {
		closeAndCommit(valueRef.current);
	}, [closeAndCommit, valueRef]);

	const updateInternal = useCallback(
		(newValue?: string[], allowClose = true) => {
			setValue(newValue);
			if (!isMulti && allowClose) {
				closeAndCommit(newValue);
			}
		},
		[closeAndCommit, isMulti, setValue],
	);

	const listeners = isActive
		? {
				onClick: () => setIsEditting(true),
			}
		: {};

	const onConfiguration = useCallback(() => {
		openFieldConfig(fieldKey);
	}, [openFieldConfig, fieldKey]);

	return (
		<WithAvailableProduct productType={ProductType.ATLAS} cloudId={configCloudId || cloudId}>
			{(isProductAvailable) => {
				const isConnectionRequired = !isProductAvailable && canEditFields;
				if (isConnectionRequired && (!value || (Array.isArray(value) && value.length === 0))) {
					return <Connect onConfiguration={onConfiguration} />;
				}
				return (
					<>
						{!isInEditView && (
							<ReadViewContainer
								ref={containerRef}
								isMultiline={isMultiline}
								isEditable={isEditable}
								showHoverStyle={!embedded}
								compactForBoard={compactForBoard}
								{...listeners}
							>
								<ExternalReferenceView
									isActive={isActive}
									provider={provider}
									fieldKey={fieldKey}
									isEditable={isEditable}
									options={value || []}
									placeholder={placeholder}
									isMultiline={isMultiline}
									containerRef={containerRef}
									isCompact={isCompact}
								/>
							</ReadViewContainer>
						)}
						{isInEditView && (
							<ExternalReferenceEdit
								provider={provider}
								onConfiguration={onConfiguration}
								value={value}
								isMulti={!!isMulti}
								fieldKey={fieldKey}
								fetchOptionsCloudId={fetchOptionsCloudId}
								isConnectionRequired={isConnectionRequired}
								signInRequired={signInRequired}
								onFetchOptions={onFetchOptions}
								onUpdate={updateInternal}
								onCloseRequested={onCloseRequested}
								menuPortalTarget={menuPortalTarget}
							/>
						)}
					</>
				);
			}}
		</WithAvailableProduct>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div<{
	showHoverStyle: boolean;
	compactForBoard: boolean;
	isEditable: boolean;
	isMultiline?: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ isEditable }) => (isEditable ? '4px' : 'initial'),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ showHoverStyle, compactForBoard, isEditable }) =>
			showHoverStyle &&
			!compactForBoard &&
			isEditable &&
			token('color.background.neutral.subtle.hovered', '#ebecf0'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		padding: ({ compactForBoard }) => (compactForBoard ? 0 : undefined),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ isMultiline }) => (isMultiline ? '84px' : 'initial'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ isMultiline }) => (isMultiline ? 'hidden' : 'initial'),
});
