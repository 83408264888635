import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	quarterPickerButtonMessage: {
		id: 'polaris-common.date-picker.fuzzy-date-picker-content.quarter-picker-button-message',
		defaultMessage: 'Quarter',
		description: 'Button message for confirming selected date',
	},
	monthPickerButtonMessage: {
		id: 'polaris-common.date-picker.fuzzy-date-picker-content.month-picker-button-message',
		defaultMessage: 'Month',
		description: 'Button message for cancelling selection date',
	},
	dayPickerButtonMessage: {
		id: 'polaris-common.date-picker.fuzzy-date-picker-content.day-picker-button-message',
		defaultMessage: 'Day',
		description: 'Button message for cancelling selection date',
	},
});
