import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	optionsSearchInputPlaceholder: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.options-search-input-placeholder',
		defaultMessage: 'Search options...',
		description: 'Placeholder for the input to filter options',
	},
	options: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.options',
		defaultMessage: 'Options',
		description: 'Label for section that shows list of options',
	},
	labels: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.labels',
		defaultMessage: 'Labels',
		description: 'Label for section that shows list of labels',
	},
	noOptions: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.no-options',
		defaultMessage: 'No options are available for this field.',
		description: 'Message displayed when there are no options',
	},
	globalLabelsLoadingError: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.global-labels-loading-error',
		defaultMessage: "We can't load the labels for this field",
		description: 'Message displayed when loading of global labels failed',
	},
});
