import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

// Issue Transition SLA
export const transitionIssueExperienceDescription = (
	wasSuccessful: boolean,
	method: 'GET' | 'POST',
	analyticsSource: string,
	projectType: ProjectType | null,
	errorMessage?: string,
	traceId?: string,
) => {
	let application = null;
	let edition = null;

	if (projectType !== null) {
		try {
			const { appEditions } = getTenantContext_DEPRECATED_DO_NOT_USE();
			application = getApplicationForProject(projectType);
			edition = getEdition(application, appEditions);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// application and edition already default to null.
		}
	}

	// Flow sadly cannot do multiple spreads in one object, and additionalAttributes
	// cannot potentially contain fields as undefined.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const additionalAttributes: Record<string, any> = {};

	if (errorMessage != null) {
		additionalAttributes.errorMessage = errorMessage;
	}

	if (traceId != null) {
		additionalAttributes.traceId = traceId;
	}

	return {
		experience: 'transitionIssue',
		wasExperienceSuccesful: wasSuccessful,
		analyticsSource,
		application,
		edition,
		additionalAttributes: {
			...additionalAttributes,
			method,
		},
	};
};
