import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { type Props, type State, initialState } from '../../types';
import { copyAttachments } from '../copy-attachments';
import { copyDescription } from '../copy-description';
import { copyFieldValues } from '../copy-field-values';
import { copyInsights } from '../copy-insights';
import { copyIssueLinks } from '../copy-issue-links';
import { linkMergedIssues } from '../link-merged-issues';

export const mergeIdeas =
	() =>
	async (
		{ setState, getState, dispatch }: StoreActionApi<State>,
		{ onSuccess, onFailure, projectId, issuesRemote }: Props,
	) => {
		const { targetIssue, selectedIssues, successCallback } = getState();

		if (projectId === null || targetIssue === null) {
			return;
		}

		setState({
			isSaving: true,
		});

		const targetIssueResponse = await issuesRemote.fetchIssue({
			issueIdOrKey: targetIssue.key,
		});

		const isSameProject = targetIssueResponse.fields.project.id === String(projectId);

		try {
			await dispatch(copyAttachments());
			await dispatch(copyInsights(targetIssueResponse.fields.project.id));
			await dispatch(copyIssueLinks(isSameProject));
			await dispatch(copyDescription());
			await dispatch(copyFieldValues(isSameProject));
			await dispatch(linkMergedIssues(isSameProject));

			setState(initialState);
			const mergedTargetIssue = {
				...targetIssue,
				isSameProject,
				projectKey: targetIssueResponse.fields.project.key,
			};
			onSuccess(mergedTargetIssue, selectedIssues);
			successCallback?.(mergedTargetIssue, selectedIssues);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setState({
				error: err,
			});
			onFailure(err);
		} finally {
			setState({
				isSaving: false,
			});
		}
	};
