import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	selectedInsightsCount: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.selected-insights-count',
		defaultMessage: '{count, plural, =0 {no items} one {insight} other {insights}}',
		description: 'Number of selected insights',
	},
	copyButton: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.copy-button',
		defaultMessage: 'Copy',
		description: 'Copy button',
	},
	cancelButton: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button',
	},
	dismissButton: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button',
	},
	createdIdeaLinkHint: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.created-idea-link-hint',
		defaultMessage: 'Open idea',
		description: 'Created new idea link hint',
	},
	successMessage: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.success-message',
		defaultMessage: 'The selected insights were successfully copied',
		description: 'Copy operation success message',
	},
	issuePickerPlaceholder: {
		id: 'polaris-ideas.insights.insights.actions.copy-insights.issue-picker-placeholder',
		defaultMessage: 'Start typing to search for or create an idea',
		description: 'Placeholder text in issue picker select component.',
	},
});
