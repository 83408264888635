import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addDecorationRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.add-decoration-rule',
		defaultMessage: 'Add a rule',
		description: 'Label for button to add new decoration rule',
	},
	dateComingSoon: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.date-coming-soon',
		defaultMessage: 'Date is coming soon',
		description: 'Label for decoration rule for dates that are in the in the future',
	},
	dateOverdue: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.date-overdue',
		defaultMessage: 'Date is overdue',
		description: 'Label for decoration rule for dates that are in the past.',
	},
	noFormatting: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.no-formatting',
		defaultMessage: 'No formatting rules are applied to this field.',
		description: 'Message displayed when there are no formatting rules',
	},
});
