import React, { useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { HeaderProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { HeaderCell, HeaderCellSummary } from './cell';

export const Header = ({ columnId }: HeaderProps) => {
	const fieldType = useFieldType(columnId);
	const analyticsData = useMemo(
		() => ({
			issueFieldKey: columnId,
			issueFieldType: fieldType,
		}),
		[columnId, fieldType],
	);

	return (
		<ContextualAnalyticsData attributes={analyticsData}>
			{ff('polaris.list-view-creating-ideas-non-grouping') && columnId === SUMMARY_FIELDKEY ? (
				<HeaderCellSummary fieldKey={columnId} />
			) : (
				<HeaderCell fieldKey={columnId} />
			)}
		</ContextualAnalyticsData>
	);
};
