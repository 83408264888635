import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	previewModeCaption: {
		id: 'polaris-ideas.sidebar.preview-mode.preview-mode-caption',
		defaultMessage: 'You are in a preview experience for the Contributor project role.',
		description: 'Banner message for previewing contributor mode',
	},
	exitPreview: {
		id: 'polaris-ideas.sidebar.preview-mode.exit-preview',
		defaultMessage: 'Exit preview',
		description: 'Button message for exit preview',
	},
});
