import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldOfType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useExternalReferenceValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	useCurrentViewEndDateField,
	useCurrentViewStartDateField,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { ExternalReferenceRenderer } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference/renderer';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { ExternalDateActionDisabledHintProps } from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import messages from './messages';

export const ExternalDateDragDisabledHint = ({
	side,
	issueId,
}: ExternalDateActionDisabledHintProps) => {
	const startDateField = useCurrentViewStartDateField();
	const endDateField = useCurrentViewEndDateField();
	const { formatMessage } = useIntl();

	const field = side === 'start' ? startDateField : endDateField;

	const atlasProjectField = useFieldOfType(FIELD_TYPES.ATLAS_PROJECT);
	const externalReferenceValue = useExternalReferenceValue(atlasProjectField?.key, issueId);
	if (!atlasProjectField || !field) {
		return null;
	}

	return (
		<div>
			<Line>
				{iconForPolarisFieldType(FIELD_TYPES.ATLAS_PROJECT)}
				<Fieldlabel>{field?.label}</Fieldlabel>
				{formatMessage(messages.dateIsOverriddenByHint)}
			</Line>
			<Line>
				<ExternalReferenceRenderer
					provider={atlasProjectField.configuration?.provider}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					aris={[externalReferenceValue as string]}
					maxLines={1}
					isContainerClickable
				/>
			</Line>
			<Line>{formatMessage(messages.cantBeChangedHereHint)}</Line>
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Line = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Fieldlabel = styled.div({
	margin: `0 ${token('space.050', '4px')}`,
});
