import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'polaris-common.fields.labels.placeholder',
		defaultMessage: 'Type a label',
		description: '',
	},
	noOptions: {
		id: 'polaris-common.fields.labels.no-options',
		defaultMessage: 'No options',
		description: 'Indicator that there are no options available in the value selection dropdown',
	},
	globalLabelsLoadingError: {
		id: 'polaris-common.fields.labels.global-labels-loading-error',
		defaultMessage: "We couldn't load the labels for this field",
		description: 'Error notification when loading of global labels fail',
	},
});
