import type { AggregatedDeliveryProgressResponse, IdeaDateAggregationItem } from '../../jira/types';

type DirtyAggregatedDeliveryProgressResponse = {
	ideaToProgressAggregation?: Record<string, number[]> | null;
	aggregationTimestamp?: string;
	aggregationLimit?: number;
	ideaDateAggregation?: Record<string, IdeaDateAggregationItem[]> | null;
};
const isDirtyAggregatedDeliveryProgressResponse = (
	response: unknown,
): response is DirtyAggregatedDeliveryProgressResponse =>
	typeof response === 'object' && response !== null && !Array.isArray(response);

/**
 * This function performs a naive, shallow check of the response object
 * and prevents potential downstream failures in case when
 * some of the properties are missing.
 *
 * It allows to simplify the remaining data processing code and provides
 * clear error message in case something will go wrong.
 *
 * Rationale: Jira doesn't use any data-validation libraries and we need to
 * make sure that changes in the jpd-views-service won't cause accidental
 * failures in the UI.
 */
export const normalizeAggregatedDeliveryProgressResponse = (
	response: unknown,
): AggregatedDeliveryProgressResponse => {
	if (isDirtyAggregatedDeliveryProgressResponse(response) === false) {
		throw new Error('Unsupported AggregatedDeliveryProgressResponse shape');
	}

	return {
		ideaToProgressAggregation: response.ideaToProgressAggregation ?? {},
		aggregationTimestamp: response.aggregationTimestamp ?? '',
		aggregationLimit: response.aggregationLimit ?? undefined,
		ideaDateAggregation: response.ideaDateAggregation ?? {},
	};
};
