import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyColumnHeader: {
		id: 'polaris-ideas.view-content.idea-board.column.header.project.empty-column-header',
		defaultMessage: 'No project',
		description:
			'Header for columns in board that contain all issues that have no project value set on them.',
	},
});
