import { JIRA_API_FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { JiraApiFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';

export const NON_EDITABLE_FIELDS: JiraApiFieldType[] = [
	JIRA_API_FIELD_TYPES.FORMULA,
	JIRA_API_FIELD_TYPES.CREATED,
	JIRA_API_FIELD_TYPES.UPDATED,
	JIRA_API_FIELD_TYPES.CREATOR,
	JIRA_API_FIELD_TYPES.ISSUE_KEY,
	JIRA_API_FIELD_TYPES.VOTES,
	JIRA_API_FIELD_TYPES.ISSUE_ID,
];
