import type { IssueRemoteData } from '@atlassian/jira-issue-view-common-types/src/issue-remote-data-type';
import {
	type FetchIssueRemoteDataSuccess,
	type FetchIssueRemoteDataFailure,
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
	FETCH_ISSUE_REMOTE_DATA_FAILURE,
} from '../../actions/issue-remote-data-actions';

type Action = FetchIssueRemoteDataSuccess | FetchIssueRemoteDataFailure;

export const initialState = {
	isLoadingComplete: false,
} as const;

// reducer that contains information for issue-remote-data (web-links and confluence page links)
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueRemoteData = initialState, action: Action) => {
	switch (action.type) {
		case FETCH_ISSUE_REMOTE_DATA_SUCCESS:
		case FETCH_ISSUE_REMOTE_DATA_FAILURE: {
			return {
				...state,
				isLoadingComplete: true,
			};
		}

		default:
			return state;
	}
};
