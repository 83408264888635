import React from 'react';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ViewConfiguration } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types';
import { useLoadViewConfiguration } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/views';
import type { ViewType } from '@atlassian/jira-ui-modifications-types';
import type { ContainerComponent } from '@atlassian/react-sweet-state';
import { UiModificationContextContainer } from './adjustments-context';
import { LoadedUiModificationsContainer } from './issue-adjustments';

type Props = {
	scope: string;
	viewType: ViewType;
	viewConfiguration: new () => ViewConfiguration;
};

export const BaseUiModificationsContainer: ContainerComponent<Props> = ({
	children,
	scope,
	viewType,
	viewConfiguration,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useLoadViewConfiguration(viewType, viewConfiguration);

	return (
		<LoadedUiModificationsContainer
			scope={scope}
			viewType={viewType}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			<UiModificationContextContainer scope={scope}>{children}</UiModificationContextContainer>
		</LoadedUiModificationsContainer>
	);
};
