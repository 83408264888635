import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyLink: {
		id: 'polaris-component-view-access.access-screen.copy-link-button.copy-link',
		defaultMessage: 'Copy link',
		description: 'Title of copy link button to copy the view URL.',
	},
	copiedMessage: {
		id: 'polaris-component-view-access.access-screen.copy-link-button.copied-message',
		defaultMessage: 'URL copied to clipboard',
		description: 'Message for success after copying the view URL.',
	},
	copiedTitle: {
		id: 'polaris-component-view-access.access-screen.copy-link-button.copied-title',
		defaultMessage: 'Copied',
		description: 'Title for success after copying the view URL.',
	},
	copyFailMessage: {
		id: 'polaris-component-view-access.access-screen.copy-link-button.copy-fail-message',
		defaultMessage: 'Failed to copy URL to clipboard',
		description: 'Message for fail after copying the view URL.',
	},
	copyFailTitle: {
		id: 'polaris-component-view-access.access-screen.copy-link-button.copy-fail-title',
		defaultMessage: 'Error',
		description: 'Title for fail after copying the view URL.',
	},
});
