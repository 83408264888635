import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.heading',
		defaultMessage: 'Create delivery tickets',
		description: 'Dialog heading.',
	},
	close: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.close',
		defaultMessage: 'Close',
		description: 'Button label for closing the dialog.',
	},
	create: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.create',
		defaultMessage: 'Create',
		description: 'Button label for creation.',
	},
	cancel: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for cancellation.',
	},
	projectLabel: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.project-label',
		defaultMessage: 'Project',
		description: 'Label for the project select.',
	},
	issueTypeLabel: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.issue-type-label',
		defaultMessage: 'Issue type',
		description: 'Label for the issue type select',
	},
	successTitle: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.success-title',
		defaultMessage: 'Delivery tickets added.',
		description: 'Flag description to communicate a successful linking operation',
	},
	successDescription: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.success-description',
		defaultMessage:
			'We created delivery tickets in the selected project and added them to your ideas.',
		description: 'Flag description to communicate a general "sent to backlog" error.',
	},
	errorTitle: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.error-description',
		defaultMessage:
			'An error occurred while sending your ideas to the backlog. Our team has been notified. Please try again.',
		description: 'Flag description to communicate a general "sent to backlog" error.',
	},
	errorHasRequiredFields: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.error-has-required-fields',
		defaultMessage: 'We couldn’t create the delivery tickets.',
		description: 'Error when the issue type has required fields.',
	},
	errorHasRequiredFieldsDescription: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.error-has-required-fields-description',
		defaultMessage:
			'The issue type you’ve chosen has required fields that need to be filled. Create the delivery tickets separately for each individual idea. ',
		description: 'More information on the Error when the issue type has required fields.',
	},
	resultDescription: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.result-description',
		defaultMessage: 'We’ve created these issues in your backlog and linked them to the ideas.',
		description: 'Description for the operation status.',
	},
	pickDescription: {
		id: 'polaris-ideas.view-controls.send-to-backlog-modal.pick-description',
		defaultMessage:
			'Select your development project and we’ll create a corresponding delivery ticket for each idea. For more options, open each idea individually.',
		description: 'Instruction to pick the project and issue type to export to.',
	},
});
