/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { css, jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button/new';
import ErrorIcon from '@atlaskit/icon/core/error';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ACCESS_ROLE_EDITOR,
	ACCESS_ROLE_ERRORS,
	ACCESS_ROLE_VIEWER,
	PRINCIPAL_TYPES,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	AccessRoleError,
	Account,
	Group,
	PrincipalType,
	ViewAccessLevel,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useProcessedAdditionalAccessEntries } from './hooks';
import messages from './messages';

export type AccessUserListProps = {
	accessLevel: ViewAccessLevel;
	additionalAccess: {
		profiles: Account[];
		groups: Group[];
	};
	currentUserAccountId?: AccountId;
	skippedAccountFailures?: {
		name: string;
		accountId: string;
		errorCode: AccessRoleError;
		avatarUrl?: string;
		requestedRole: AccessRole;
	}[];
	isDisabled: AccessListItemProps['isDisabled'];
	onPrincipalRoleChange: AccessListItemProps['onPrincipalRoleChange'];
	onRemovePrincipals: AccessListItemProps['onRemovePrincipals'];
};

export const AccessUserList = ({
	accessLevel,
	additionalAccess,
	isDisabled,
	currentUserAccountId,
	skippedAccountFailures,
	onPrincipalRoleChange,
	onRemovePrincipals,
}: AccessUserListProps) => {
	const additionalAccessEntries = useProcessedAdditionalAccessEntries(
		additionalAccess,
		currentUserAccountId,
		skippedAccountFailures,
	);

	// if it's false it prevents removal of last editor in case view isn't open; a view should have at least one explicit editor
	const canDowngradeEditor = useMemo(
		() =>
			accessLevel === VIEW_ACCESS_LEVELS.OPEN ||
			additionalAccessEntries.filter((item) => item.role === ACCESS_ROLE_EDITOR).length > 1,
		[accessLevel, additionalAccessEntries],
	);

	return (
		<Stack
			space="space.150"
			testId="polaris-component-view-access.ui.access-screen.access-user-list"
		>
			{additionalAccessEntries.map((entry) => (
				<AccessListItem
					key={entry.id}
					isDisabled={isDisabled}
					onPrincipalRoleChange={onPrincipalRoleChange}
					onRemovePrincipals={onRemovePrincipals}
					canDowngradeRole={canDowngradeEditor || entry.role !== ACCESS_ROLE_EDITOR}
					{...entry}
				/>
			))}
		</Stack>
	);
};

type AccessListItemProps = {
	id: string;
	name: string;
	role: AccessRole;
	type: PrincipalType;
	canDowngradeRole: boolean;
	userType?: string;
	isCurrentUser?: boolean;
	avatarUrl?: string;
	isDisabled?: boolean;
	error?: AccessRoleError;
	onPrincipalRoleChange: ({
		name,
		id,
		role,
		type,
		avatarUrl,
	}: {
		name: string;
		id: string;
		role: AccessRole;
		type: PrincipalType;
		avatarUrl?: string;
	}) => Promise<void>;
	onRemovePrincipals: ({
		accounts,
		groups,
	}: {
		accounts: string[];
		groups: string[];
	}) => Promise<void>;
};

const AccessListItem = ({
	id,
	name,
	role,
	isCurrentUser = false,
	avatarUrl,
	userType,
	type,
	canDowngradeRole,
	isDisabled = false,
	error,
	onPrincipalRoleChange,
	onRemovePrincipals,
}: AccessListItemProps) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleRoleChange = useCallback(
		async (newRole: AccessRole) => {
			setIsOpen(false);

			if (role !== newRole) {
				setIsLoading(true);
				await onPrincipalRoleChange({
					name,
					id,
					role: newRole,
					type,
					avatarUrl,
				});
				setIsLoading(false);
			}
		},
		[role, name, id, type, avatarUrl, onPrincipalRoleChange],
	);

	const handleRemoveAccess = useCallback(async () => {
		setIsOpen(false);

		await onRemovePrincipals(
			type === PRINCIPAL_TYPES.PROFILE
				? {
						accounts: [id],
						groups: [],
					}
				: {
						accounts: [],
						groups: [id],
					},
		);
	}, [type, onRemovePrincipals, id]);

	return (
		<Flex
			gap="space.100"
			alignItems="center"
			testId="polaris-component-view-access.ui.access-screen.access-user-list.container"
		>
			<Avatar src={avatarUrl} />
			<Stack grow="fill">
				<Text weight="medium">
					{name}
					{isCurrentUser && ` (${formatMessage(messages.youLabel)})`}
				</Text>
				{userType && (
					<Text size="small" weight="medium" color="color.text.subtlest">
						{userType}
					</Text>
				)}
			</Stack>
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-end"
				content={() => (
					<MenuGroup>
						<Section>
							<ButtonItem
								isSelected={role === ACCESS_ROLE_EDITOR}
								onClick={() => handleRoleChange(ACCESS_ROLE_EDITOR)}
								testId="polaris-component-view-access.ui.access-screen.access-user-list.edit-role-button"
							>
								{formatMessage(messages.canEditLabel)}
							</ButtonItem>
							<Tooltip
								content={
									!canDowngradeRole ? formatMessage(messages.downgradeEditorTooltip) : undefined
								}
							>
								<ButtonItem
									isSelected={role === ACCESS_ROLE_VIEWER}
									isDisabled={!canDowngradeRole}
									onClick={() => handleRoleChange(ACCESS_ROLE_VIEWER)}
									testId="polaris-component-view-access.ui.access-screen.access-user-list.view-role-button"
								>
									{formatMessage(messages.canViewLabel)}
								</ButtonItem>
							</Tooltip>
						</Section>
						<Section hasSeparator>
							<Tooltip
								content={
									!canDowngradeRole ? formatMessage(messages.downgradeEditorTooltip) : undefined
								}
							>
								<ButtonItem
									onClick={handleRemoveAccess}
									isDisabled={!canDowngradeRole}
									testId="polaris-component-view-access.ui.access-screen.access-user-list.remove-access"
								>
									{formatMessage(messages.removeAccessLabel)}
								</ButtonItem>
							</Tooltip>
						</Section>
					</MenuGroup>
				)}
				zIndex={layers.modal + 1}
				trigger={(triggerProps) => (
					<Flex xcss={roleSelectWrapperStyles} alignItems="center" gap="space.050">
						{error && (
							<Tooltip
								content={
									error === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION
										? formatMessage(messages.errorTooltipMissingProjectAccess)
										: formatMessage(messages.errorTooltipMissingManageViewsPermission)
								}
							>
								<ErrorIcon
									testId="polaris-component-view-access.ui.access-screen.access-user-list.error-icon"
									label=""
									color="var(--ds-icon-accent-red)"
								/>
							</Tooltip>
						)}
						<div css={accessListItemButtonWrapperStyles}>
							<Button
								{...triggerProps}
								appearance="subtle"
								isSelected={isOpen}
								onClick={() => setIsOpen(!isOpen)}
								iconAfter={ChevronDownIcon}
								testId="polaris-component-view-access.ui.access-screen.access-user-list.role-button"
								isDisabled={
									isDisabled ||
									isLoading ||
									error === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION
								}
								isLoading={isLoading}
								data-component-selector="access-list-item-button-g39Sv"
							>
								{role === ACCESS_ROLE_EDITOR
									? formatMessage(messages.canEditLabel)
									: formatMessage(messages.canViewLabel)}
							</Button>
						</div>
					</Flex>
				)}
			/>
		</Flex>
	);
};

const roleSelectWrapperStyles = xcss({
	flexShrink: 0,
});

const accessListItemButtonWrapperStyles = css({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-component-selector="access-list-item-button-g39Sv"]:disabled': {
		background: 'transparent',
	},
});
