import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const setSummaryEditing =
	(id: string, value: boolean): Action<State, Props> =>
	({ setState }) => {
		setState({
			isEditingSummary: {
				id,
				value,
			},
		});
	};

export const closeSummaryEditor =
	(): Action<State, Props> =>
	({ setState }) => {
		setState({
			isEditingSummary: {
				id: '',
				value: false,
			},
		});
	};
