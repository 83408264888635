import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-view-empty-state.views.board-view.header',
		defaultMessage: 'Set up board columns',
		description: 'Header of the new board view hint',
	},
	description: {
		id: 'polaris-lib-view-empty-state.views.board-view.description',
		defaultMessage: 'Select a field to create your board columns.',
		description: 'New board view hint to start',
	},
});
