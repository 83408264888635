import { format as formatReal, isPast, parse as parseReal } from 'date-fns';

const DEFAULT_DATE_FNS_OPTIONS: {
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
} = {
	weekStartsOn: 1, // Start on monday. Week is monday -> sunday.
};

// Wrap a date-fns method with default options param
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mixinDefaultOptions = <p extends (...args: any[]) => any>(fn: p) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	function (this: any, ...theArgs: any[]) {
		const lastIndex = fn.length - 1;
		if (fn.length === theArgs.length) {
			Object.assign(theArgs, {
				lastIndex: {
					...DEFAULT_DATE_FNS_OPTIONS,
					...theArgs[lastIndex],
				},
			});
		} else {
			Object.assign(theArgs, { lastIndex: DEFAULT_DATE_FNS_OPTIONS });
		}
		return fn.apply(this, theArgs);
	} as p;

export const format = mixinDefaultOptions(formatReal);
export const parse = mixinDefaultOptions(parseReal);

export const isDateInThePast = (dateString?: string | null) =>
	dateString ? isPast(new Date(`${dateString.split('T')[0]}T23:59:59`)) : false;
