import log from '@atlassian/jira-common-util-logging/src/log';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const loadIssueLinkArchived =
	(): Action<State, Props> =>
	async ({ setState, getState }, { issueKey, issuesRemote }) => {
		if (
			!issueKey ||
			issueKey === getState().archivedMeta.loadingProps?.issueKey ||
			issuesRemote.fetchLinkedIssuesArchived === undefined
		) {
			return;
		}

		setState({
			archivedMeta: {
				isLoading: true,
				loadingProps: {
					issueKey,
				},
			},
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const issueIds = getState().issueLinks.reduce<Array<any>>((result, issueLink) => {
			const newResult = Array.from(result);
			if (issueLink.inwardIssue?.id !== undefined) {
				newResult.push(Number(issueLink.inwardIssue.id));
			}
			if (issueLink.outwardIssue?.id !== undefined) {
				newResult.push(Number(issueLink.outwardIssue.id));
			}
			return newResult;
		}, []);
		await issuesRemote
			.fetchLinkedIssuesArchived({ issueIds })
			.then((archivedLinkedIssues) => {
				setState({
					archivedLinkedIssues,
				});
			})
			.catch((err) => {
				log.safeErrorWithoutCustomerData(
					'polaris.load-issue-link-archived',
					'Failed to fetch issue links archived',
					err,
				);
			})
			.finally(() => {
				setState({
					archivedMeta: {
						isLoading: false,
						loadingProps: {
							issueKey,
						},
					},
				});
			});
	};
