import { AnyAri } from '@atlassian/ari/any-ari';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getViewARI = (cloudId: CloudId, viewUUID: string): string =>
	AnyAri.create({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'view',
		resourceId: viewUUID,
	}).toString();

export const getViewUUID = (viewId: string): string => AnyAri.parse(viewId).resourceId;
