import React from 'react';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	AVG_ROLLUP,
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import { FieldGroupedAvg } from './avg';
import { FieldGroupedCount } from './count';
import { FieldGroupedEmpty } from './empty';
import { FieldGroupedFilled } from './filled';
import { FieldGroupedMedian } from './median';
import { FieldGroupedSum } from './sum';

type CommonFieldGroupedProps = {
	val: number;
	rollupOperation?: string;
	message?: MessageDescriptor;
};

export const CommonFieldGrouped = ({ val, message, rollupOperation }: CommonFieldGroupedProps) => {
	switch (rollupOperation) {
		case AVG_ROLLUP:
			return <FieldGroupedAvg val={val} />;
		case COUNT_ROLLUP:
			return <FieldGroupedCount val={val} message={message} />;
		case EMPTY_ROLLUP:
			return <FieldGroupedEmpty val={val} />;
		case FILLED_ROLLUP:
			return <FieldGroupedFilled val={val} />;
		case MEDIAN_ROLLUP:
			return <FieldGroupedMedian val={val} />;
		case SUM_ROLLUP:
			return <FieldGroupedSum val={val} />;
		default:
			return null;
	}
};
