import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { GroupBySupportedFieldTypes } from './is-group-by-supported';

// This is done by design as we limit fields that can be used here inside
// the array only to fields that supports grouping
const CREATING_IDEAS_GROUP_BY_NOT_SUPPORTED_FIELD_TYPES: GroupBySupportedFieldTypes[] = [
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.CREATOR,
	FIELD_TYPES.REPORTER,
	FIELD_TYPES.REACTIONS,
];

export const isCreatingIdeasGroupBySupported = (fieldType?: FieldType) => {
	if (fieldType === undefined) {
		return true;
	}

	const notSupported = CREATING_IDEAS_GROUP_BY_NOT_SUPPORTED_FIELD_TYPES.some(
		(item) => item === fieldType,
	);

	return !notSupported;
};
