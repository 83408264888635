import type { ConfluenceWhiteboards } from '@atlassian/jira-issue-shared-types';
import {
	DELETE_CONFLUENCE_WHITEBOARD_LINK_SUCCESS,
	FETCH_CONFLUENCE_WHITEBOARD_SUCCESS,
	CONFLUENCE_WHITEBOARD_LINK_REQUEST,
	type ConfluenceWhiteboardsAction,
	CONFLUENCE_WHITEBOARD_LINK_REQUEST_FAILED,
} from '../../actions/confluence-whiteboards-actions';
import {
	type FetchIssueRemoteDataSuccess,
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
} from '../../actions/issue-remote-data-actions';

type State = ConfluenceWhiteboards;

type Action = FetchIssueRemoteDataSuccess | ConfluenceWhiteboardsAction;

export const initialState: State = {
	linkedWhiteboards: {
		whiteboards: [],
		whiteboardCount: 0,
	},
	createLinkedWhiteboard: {
		isLoading: false,
	},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case FETCH_ISSUE_REMOTE_DATA_SUCCESS: {
			const { remoteLinks } = action.payload;
			return {
				...state,
				...remoteLinks.confluenceWhiteboards,
			};
		}

		case DELETE_CONFLUENCE_WHITEBOARD_LINK_SUCCESS: {
			const { id } = action.payload;

			const filteredWhiteboards =
				state.linkedWhiteboards.whiteboards?.filter((whiteboard) => whiteboard.id !== id) ?? [];
			return {
				...state,
				linkedWhiteboards: {
					whiteboardCount: filteredWhiteboards.length,
					whiteboards: filteredWhiteboards,
				},
			};
		}
		case FETCH_CONFLUENCE_WHITEBOARD_SUCCESS: {
			const { whiteboard } = action.payload;
			const newWhiteboardsArray = [...(state.linkedWhiteboards?.whiteboards || []), whiteboard];

			return {
				...state,
				linkedWhiteboards: {
					whiteboardCount: newWhiteboardsArray.length,
					whiteboards: newWhiteboardsArray,
				},
				createLinkedWhiteboard: {
					...state.createLinkedWhiteboard,
					isLoading: false,
				},
			};
		}

		case CONFLUENCE_WHITEBOARD_LINK_REQUEST:
			return {
				...state,
				createLinkedWhiteboard: {
					...state.createLinkedWhiteboard,
					isLoading: true,
				},
			};

		case CONFLUENCE_WHITEBOARD_LINK_REQUEST_FAILED:
			return {
				...state,
				createLinkedWhiteboard: {
					...state.createLinkedWhiteboard,
					isLoading: false,
				},
			};

		default:
			return state;
	}
};
