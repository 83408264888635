import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIdeaIssueTypes } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import type { CreatePayload } from '@atlassian/jira-polaris-lib-create-issue-input/src/common/types.tsx';
import { CreateIssueInput } from '@atlassian/jira-polaris-lib-create-issue-input/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useOnCreatedOrMovedIssueFiltered } from '../../../view-content/common/on-created-filtered-issue';
import { messages } from './messages';

type FooterProps = {
	onIdeaCreated: (id: LocalIssueId) => void;
};

export const Footer = ({ onIdeaCreated }: FooterProps) => {
	const { formatMessage } = useIntl();
	const [ideaTypes] = useIdeaIssueTypes();
	const [isInputVisible, setIsInputVisible] = useState(false);
	const { createAndUpdate } = useIssueActions();
	const onCreatedIssueFiltered = useOnCreatedOrMovedIssueFiltered();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCreateIdea = useCallback(
		({ summary }: CreatePayload) => {
			if (!ideaTypes?.length) return;

			createAndUpdate(ideaTypes[0], summary, { summary }, onIdeaCreated, (id: LocalIssueId) =>
				onCreatedIssueFiltered(id),
			);

			setIsInputVisible(false);
		},
		[ideaTypes, createAndUpdate, onIdeaCreated, onCreatedIssueFiltered],
	);

	const handleCreateIdeaStart = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'createIdea',
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'createIdea',
		});
		setIsInputVisible(true);
	}, [createAnalyticsEvent]);

	const handleCreateIdeaCancel = useCallback(() => setIsInputVisible(false), []);

	return (
		<FooterContainer>
			{isInputVisible ? (
				<CreateIssueInput
					placeholder={formatMessage(messages.placeholder)}
					onCreate={handleCreateIdea}
					onCancel={handleCreateIdeaCancel}
				/>
			) : (
				<FooterButton
					id="pendo.ideas-bucket.create-idea-button"
					appearance="primary"
					shouldFitContainer
					onClick={handleCreateIdeaStart}
				>
					{formatMessage(messages.buttonLabel)}
				</FooterButton>
			)}
		</FooterContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.footer({
	padding: `${token('space.300', '24px')} ${token('space.200', '16px')}`,
	borderTop: `1px solid ${token('color.border', N40)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		height: '40px',
		lineHeight: '40px',
	},
});
