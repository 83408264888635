import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Marker } from '@atlassian/jira-polaris-domain-view/src/markers/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { updateViewState } from '../utils/state';

export const loadViewMarkers =
	(uuid: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote, onActionFailed }: Props) => {
		try {
			const markers = await viewRemote.fetchViewMarkers(uuid);

			const { changedView, viewSets } = updateViewState(
				getState().viewSets,
				(view: View): boolean => view.uuid === uuid,
				(view: View) => ({
					...view,
					markers,
				}),
			);
			if (changedView) {
				setState({ viewSets });
			}
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.controllers.view.actions.markers.load', error),
				);
				onActionFailed(error);
			}
		}
	};

type CreateMarker = Pick<Marker, 'label' | 'color' | 'date'>;

export const createViewMarker =
	(uuid: string, marker: CreateMarker) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote, onActionFailed }: Props) => {
		try {
			const createdMarker = await viewRemote.createViewMarker(uuid, marker);

			const { changedView, viewSets } = updateViewState(
				getState().viewSets,
				(view: View): boolean => view.uuid === uuid,
				(view: View) => ({
					...view,
					markers: [...(view.markers || []), createdMarker],
				}),
			);

			if (changedView) {
				setState({ viewSets });
			}
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.controllers.view.actions.markers.create', error),
				);
				onActionFailed(error);
			}
			throw error;
		}
	};

export const updateViewMarker =
	(uuid: string, updateMarker: Marker) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote, onActionFailed }: Props) => {
		try {
			await viewRemote.updateViewMarker(uuid, updateMarker);

			const { changedView, viewSets } = updateViewState(
				getState().viewSets,
				(view: View): boolean => view.uuid === uuid,
				(view: View) => ({
					...view,
					markers: (view.markers || []).map((marker) =>
						marker.id === updateMarker.id ? updateMarker : marker,
					),
				}),
			);
			if (changedView) {
				setState({ viewSets });
			}
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.controllers.view.actions.markers.update', error),
				);
				onActionFailed(error);
			}
			throw error;
		}
	};

export const deleteViewMarker =
	(uuid: string, markerId: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote, onActionFailed }: Props) => {
		try {
			await viewRemote.deleteViewMarker(uuid, markerId);

			const { changedView, viewSets } = updateViewState(
				getState().viewSets,
				(view: View): boolean => view.uuid === uuid,
				(view: View) => ({
					...view,
					markers: (view.markers || []).filter((marker) => marker.id !== markerId),
				}),
			);

			if (changedView) {
				setState({ viewSets });
			}
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.controllers.view.actions.markers.delete', error),
				);
				onActionFailed(error);
			}
			throw error;
		}
	};
