import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import { createAri } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ResolvePolarisObject, ResolvePolarisObjectResponse } from './types';

export const RESOLVE_OBJECT_MUTATION = gqlTagPolaris`
mutation jira_polaris_ResolvePolarisObject($input: ResolvePolarisObjectInput!) {
  resolvePolarisObject(input: $input) {
    success
    errors {
      message
    }
    response {
      statusCode
      body
        externalAuth {
          url
          key
          displayName
        }
       auth {
        type
        hint
      }
      oauthClientId
    }
  }
}
`;

export const resolveObject = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	issueId: string,
	resourceUrl: string,
	authToken?: string,
): Promise<ResolvePolarisObjectResponse> => {
	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId,
	});

	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

	return client
		.mutate<ResolvePolarisObject>({
			mutation: RESOLVE_OBJECT_MUTATION,
			variables: {
				input: {
					issue: issueAri.toString(),
					project: projectAri.toString(),
					resourceUrl,
					authToken,
				},
			},
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-insights.resolve-object-error:${result.errors.map((e) => e.message).join(', ')}`,
				);
			}

			if (
				!result.data ||
				!result.data.resolvePolarisObject ||
				!result.data.resolvePolarisObject.response
			) {
				throw new Error('polaris-insights.resolve-object-error: no data or no data node');
			}

			return result.data.resolvePolarisObject.response;
		});
};
