import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	inputHeader: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.input-header',
		defaultMessage: '{count, plural, one {Input} other {Inputs}}',
		description: 'Header label for formula input',
	},
	weightedScoreFormulaHelp: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.weighted-score-formula-help',
		defaultMessage: `This formula attributes a score between 0 and 100 to each idea based on the input provided.

The idea with the lowest score will get 0, the idea with the highest one will get 100, and all other ideas will get values in between.

The positive inputs change the score positively (e.g. impact), the negative inputs change the score negatively (e.g. effort).`,
		description: 'Explanation on how weighted score formula works',
	},
	positiveInputHeader: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.positive-input-header',
		defaultMessage: '{count, plural, one {Positive input} other {Positive inputs}}',
		description: 'Header label for formula input',
	},
	negativeInputHeader: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.negative-input-header',
		defaultMessage: '{count, plural, one {Negative input} other {Negative inputs}}',
		description: 'Header label for formula input',
	},
	fieldSectionHeader: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.field-section-header',
		defaultMessage: 'Number fields',
		description: 'Header label for field section',
	},
	selectSectionHeader: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.select-section-header',
		defaultMessage: 'Select fields',
		description: 'Header label for a select fields section',
	},
	dataSectionHeader: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.data-section-header',
		defaultMessage: 'Insights',
		description: 'Header label for insights section',
	},
	addInputButtonLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.add-input-button-label',
		defaultMessage: 'Add an input',
		description: 'Button label for adding input fields',
	},
	searchFieldsPlaceholder: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.search-fields-placeholder',
		defaultMessage: 'Search fields',
		description: 'Placeholder text for search input',
	},
});
