import React from 'react';
import { styled } from '@compiled/react';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { DecoratedRating } from '../../../decoration/rating/index.tsx';

type RatingNumberFieldProps = {
	isEditable: boolean;
	value: number | undefined;
	decorations: ValueDecoration[] | undefined;
	onUpdate?: ((inputValue: number | undefined) => void) | undefined;
};

export const RatingNumberField = ({
	value,
	decorations,
	isEditable,
	onUpdate,
}: RatingNumberFieldProps) => {
	const handleConfirm = (newValue: undefined | number) => {
		onUpdate && onUpdate(newValue);
	};

	return (
		<RatingContainer>
			<DecoratedRating
				decorations={decorations}
				value={value}
				onChange={isEditable ? handleConfirm : undefined}
			/>
		</RatingContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RatingContainer = styled.div({
	padding: 0,
});
