import startCase from 'lodash/startCase';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fetchSnippetProviders } from '../../../services/polaris-api/fetch-snippet-providers';
import type { ArchivedMode } from '../../../services/polaris-api/fetch-snippet-providers/types';

// @TODO: remove after fixing avatarUrl bug
const APPS_FAV_MAPPING = {
	amplitude: 'analytics.amplitude.com',
	intercom: 'intercom.com',
	jira: 'jira.atlassian.com',
	pendo: 'www.pendo.io',
	salesforce: 'force.com',
	slack: 'slack.com',
	zendesk: 'support.zendesk.com',
};

const generateFaviconUrl = (domain: string) =>
	`https://www.google.com/s2/favicons?domain=${domain}`;

const getAvatarUrl = (name: string, avatarUrl: string) => {
	for (const appName of Object.keys(APPS_FAV_MAPPING)) {
		if (name.toLowerCase().includes(appName)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return generateFaviconUrl(APPS_FAV_MAPPING[appName as keyof typeof APPS_FAV_MAPPING]);
		}
	}

	return avatarUrl;
};

export const fetchSnippetProvidersFromPolarisApi = (
	apolloClient: PolarisApolloClient,
	projectAri: Ari,
	archivedMode: ArchivedMode,
) =>
	fetchSnippetProviders(apolloClient, projectAri, archivedMode).then((response) => {
		const snippetProviders: SnippetProvider[] = [];
		response.forEach((item) => {
			if (item.app && item.properties) {
				const { id, name, avatarUrl, oauthClientId } = item.app;
				const tmpAvatarUrl = getAvatarUrl(name, avatarUrl);
				snippetProviders.push({
					id: item.id,
					app: {
						id,
						name,
						oauthClientId,
						avatarUrl: tmpAvatarUrl,
					},
					properties: (item.properties || []).map((p) => ({
						id: p.id,
						key: p.key,
						label: p.label === null || p.label === undefined ? startCase(p.key) : p.label,
						kind: p.kind === null || p.kind === undefined ? 'UnknownPropertyKind' : p.kind,
					})),
				});
			}
		});
		return snippetProviders;
	});
