import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import {
	useProjectProperty,
	useProjectPropertiesActions,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties';
import { ProjectProperties } from '@atlassian/jira-polaris-common/src/controllers/project-properties/types';
import { isGlobalExperienceWorkspaceForCloudId } from '@atlassian/jira-polaris-common/src/services/atlas/global-experience';
import {
	useIsAdmin,
	useIsProjectAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { BreadcrumbsProjectSettings } from '../breadcrumbs';
import { AtlasConfiguration } from './atlas';
import messages from './messages';

export const PolarisAdvancedConfig = () => {
	const cloudId = useCloudId();
	const [isProjectAdmin] = useIsProjectAdmin();
	const isAdmin = useIsAdmin();
	const [, { saveProperty }] = useProjectPropertiesActions();
	const { formatMessage } = useIntl();
	const [isGlobalAtlasExperience, setIsGlobalExperience] = useState(false);

	const [{ isLoading, value: isAtlasIntegrationEnabled }] = useProjectProperty(
		ProjectProperties.ATLAS_INTEGRATION_ENABLED,
	);

	useEffect(() => {
		isGlobalExperienceWorkspaceForCloudId(cloudId).then((isGlobalExperience) =>
			setIsGlobalExperience(isGlobalExperience),
		);
	}, [cloudId]);

	const isAtlasIntegrationOn = useMemo(() => {
		if (ff('polaris.turn-on-atlas-integration')) {
			if (isAtlasIntegrationEnabled === false) {
				return false;
			}
			return true;
		}
		if (
			isAtlasIntegrationEnabled ||
			(isGlobalAtlasExperience && isAtlasIntegrationEnabled === null && (isProjectAdmin || isAdmin))
		) {
			return true;
		}
		return false;
	}, [isAdmin, isAtlasIntegrationEnabled, isGlobalAtlasExperience, isProjectAdmin]);

	const handleAtlasIntegration = useCallback(
		(isEnabled: boolean) => {
			saveProperty(ProjectProperties.ATLAS_INTEGRATION_ENABLED, isEnabled);
		},
		[saveProperty],
	);

	const switchAtlasIntegration = useCallback(() => {
		if (ff('polaris.turn-on-atlas-integration') && isAtlasIntegrationEnabled === null) {
			handleAtlasIntegration(false);
		} else {
			handleAtlasIntegration(!isAtlasIntegrationEnabled);
		}
	}, [handleAtlasIntegration, isAtlasIntegrationEnabled]);

	return (
		<>
			<PageHeader breadcrumbs={<BreadcrumbsProjectSettings />}>
				<Heading size="large">{formatMessage(messages.header)}</Heading>
			</PageHeader>
			<Container>
				<CaptionContainer>
					<Title>{formatMessage(messages.atlasTitle)}</Title>
					<Description>
						<FormattedMessage
							{...messages.atlasDescription}
							values={{
								a: (chunks) => (
									<a
										href="https://intercom.help/atlas-by-atlassian/en/articles/6543595-the-relationship-between-projects-goals"
										target="_blank"
									>
										{chunks}
									</a>
								),
							}}
						/>
					</Description>
				</CaptionContainer>
				<Divider />
				<AtlasToggleContainer>
					<Title>{formatMessage(messages.enableAtlasIntegrationSetting)}</Title>
					<Value>
						{isLoading ? (
							<Spinner />
						) : (
							<Toggle
								isChecked={isAtlasIntegrationOn}
								isDisabled={isLoading}
								onChange={switchAtlasIntegration}
								name="atlas_integration"
								size="large"
							/>
						)}
					</Value>
				</AtlasToggleContainer>
				<Divider />
				<AtlasConfiguration />
			</Container>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	margin: '0 auto',
	padding: `${token('space.300', '24px')} 0`,
	display: 'flex',
	alignItems: 'flex-start',
	gap: token('space.150', '12px'),
	flexDirection: 'column',
	borderRadius: '8px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.border', colors.N40)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AtlasToggleContainer = styled.div({
	display: 'flex',
	padding: `0 ${token('space.250', '20px')}`,
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
	boxSizing: 'border-box',
	maxHeight: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Value = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CaptionContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	padding: `0 ${token('space.250', '20px')}`,
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.xsmall', fontFallback.heading.xsmall),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	fontWeight: 400,
	lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled.div({
	display: 'flex',
	margin: `${token('space.150', '12px')} ${token('space.250', '20px')}`,
	borderRadius: '1px',
	height: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.border', colors.N40),
	alignSelf: 'stretch',
});
