import React, { useState, useEffect, useCallback, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import Link from '@atlaskit/link';
import { N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';
import type { CopyToClipboardProps } from './types';

export const CopyToClipboard = ({ text }: CopyToClipboardProps) => {
	const { formatMessage } = useIntl();
	const [isCopied, setCopied] = useState(false);

	const onCopy = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();
			navigator.clipboard
				.writeText(text)
				.then(() => setCopied(true))
				.catch(() => setCopied(false));
		},
		[text],
	);

	useEffect(() => {
		if (!isCopied) return;
		const copyTimer = setTimeout(() => setCopied(false), 2500);
		return () => clearTimeout(copyTimer);
	}, [isCopied]);

	return (
		<Container>
			<Tooltip
				position="top"
				hideTooltipOnMouseDown
				delay={200}
				content={formatMessage(isCopied ? messages.copiedMessage : messages.copyToClipboard)}
			>
				{(tooltipProps) => (
					<Link
						{...tooltipProps}
						testId="polaris-lib-copy-to-clipboard.ui.copy-to-clipboard"
						onClick={onCopy}
						appearance="subtle"
						href="/"
						data-component-selector="copy-to-clipboard-jsT2"
					>
						{text}
					</Link>
				)}
			</Tooltip>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="copy-to-clipboard-jsT2"]': {
		fontWeight: 600,
		color: token('color.text', N900),
	},
});
