import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_CopyPolarisInsights as CopyPolarisInsights } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/copy-insights/__generated_apollo__/jira_polaris_CopyPolarisInsights.ts';
import type { CopyInsightsContainer } from './types';

const COPY_INSIGHTS_QUERY = gqlTagPolaris`
mutation jira_polaris_CopyPolarisInsights($input: CopyPolarisInsightsInput!) {
  copyPolarisInsights(input: $input) {
    success
    errors {
      message
    }
  }
}
`;

export const copyInsights = async (
	client: PolarisApolloClient,
	source: CopyInsightsContainer,
	destination: CopyInsightsContainer,
	insights?: string[],
) => {
	const result = await client.mutate<CopyPolarisInsights>({
		mutation: COPY_INSIGHTS_QUERY,
		variables: {
			input: {
				source,
				destination,
				insights: insights ?? [],
			},
		},
	});

	if (!result.data?.copyPolarisInsights?.success) {
		throw new Error('polaris-insights.copy-insights-error: no error but not successful either');
	}
};
