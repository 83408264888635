import React from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { CommentsField } from './comments';
import { DateField } from './date-field';
import { DateTimeField } from './datetime-field';
import { ExternalReferenceField } from './external-reference-field';
import { ExternalReferencePropertyField } from './external-reference-property-field';
import { FuzzyDateField } from './fuzzy-date-field';
import { InsightsField } from './insights-field';
import { IssueKeyField } from './key-field';
import { EditableLabelsField } from './labels-field';
import { EditableLinkField } from './link-field';
import { LinkedIssuesField } from './linked-issues-field';
import { EditableMultiSelectField } from './multi-select';
import { NumberField } from './number-field';
import { PlayField } from './play-field';
import { ProjectFieldCell } from './project-field';
import { ReactionsField } from './reactions-field';
import { EditableSingleSelectField } from './single-select';
import { StatusField } from './status-field';
import { StringField } from './string-field';
import { UserField } from './user-field';
import { UsersField } from './users-field';

type CellRendererProps = {
	isHovered: boolean;
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const RenderCell = ({ fieldKey, localIssueId, isHovered }: CellRendererProps) => {
	const fieldType = useFieldType(fieldKey);

	switch (fieldType) {
		case FIELD_TYPES.SUMMARY:
			return (
				<EditableLinkField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.ISSUE_KEY:
			return <IssueKeyField localIssueId={localIssueId} interactive={isHovered} />;
		case FIELD_TYPES.STATUS:
			return <StatusField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.SHORT_TEXT:
		case FIELD_TYPES.HYPERLINK:
			return <StringField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.INSIGHTS:
			return <InsightsField localIssueId={localIssueId} />;
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.CHECKBOX:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return (
				<NumberField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />
			);
		case FIELD_TYPES.ISSUE_COMMENTS:
			return <CommentsField localIssueId={localIssueId} />;
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
			return <EditableLabelsField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.CREATED:
			return <DateTimeField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.DATE:
			return <DateField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />;
		case FIELD_TYPES.INTERVAL:
			return (
				<FuzzyDateField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />
			);
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
			return <UserField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />;
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
			return <UsersField localIssueId={localIssueId} fieldKey={fieldKey} interactive={isHovered} />;

		case FIELD_TYPES.SINGLE_SELECT:
			return (
				<EditableSingleSelectField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
			return (
				<EditableMultiSelectField
					localIssueId={localIssueId}
					fieldKey={fieldKey}
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.DELIVERY_PROGRESS:
			return (
				<LinkedIssuesField
					type={FIELD_TYPES.DELIVERY_PROGRESS}
					issueId={localIssueId}
					appearance="list"
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.DELIVERY_STATUS:
			return (
				<LinkedIssuesField
					type={FIELD_TYPES.DELIVERY_STATUS}
					issueId={localIssueId}
					appearance="list"
					interactive={isHovered}
				/>
			);
		case FIELD_TYPES.VOTES:
			return <PlayField appearance="list" localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
			return (
				<ExternalReferenceField
					fieldKey={fieldKey}
					localIssueId={localIssueId}
					isHovered={isHovered}
				/>
			);
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
			return <ExternalReferencePropertyField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.REACTIONS:
			return <ReactionsField localIssueId={localIssueId} fieldKey={fieldKey} />;
		case FIELD_TYPES.PROJECT:
			return <ProjectFieldCell localIssueId={localIssueId} />;
		default:
			return <span>TBD Сustom ({fieldType})</span>;
	}
};
