import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { execWithIssueAnalyticsData } from './analytics';
import { archiveIssues, unarchiveIssues } from './archive-issues';
import { clonePolarisIssue } from './clone-issue';
import * as commentsActions from './comments';
import { rankIdeasWithCurrentSorting } from './commit-current-sorting';
import {
	updateDynamicFieldSelector,
	createDynamicFieldSelectors,
	scheduleDynamicFieldCalculation,
} from './create-dynamic-fields';
import {
	createIssue,
	saveIssue,
	cancelCreateIssue,
	cancelCreations,
	clearCreatedProperty,
	createAndUpdate,
	createIssueAfter,
	createIssueBefore,
	submitIdea,
	safelySwapRowInCreationWithCreated,
} from './create-issue';
import { deleteIssues, deleteExternalIssues } from './delete-issues';
import { exportStringValues } from './export-csv';
import { adaptExternalIssueRank } from './external-issue-rank';
import { updateExternalReferenceEntities } from './external-reference';
import { initialize, initializeArchived, addPropertiesForLoadedIssue } from './initialize';
import * as insightsActions from './insights';
import { invalidateFieldValue } from './invalidate-field-value';
import { linkIssue, linkDeliveryIssue } from './link-issue';
import {
	loadDeliveryProgress,
	refreshDeliveryProgressForSelectedIssue,
	refreshDeliveryProgressForSpecificIssue,
} from './load-delivery-data';
import { loadIssues, loadArchivedIssues } from './load-issues';
import { moveIssue, rankAfter, rankBefore } from './move-issue';
import { moveIssuesToTop } from './move-issues-to-top';
import playActions from './plays';
import { toggleReaction } from './reactions';
import { decrementOpenUpdateCounter, incrementOpenUpdateCounter } from './real-time';
import { refreshIssues } from './refresh-issues';
import { removeIssueFromState } from './remove-issue';
import { setError } from './set-error';
import { setIsSingleIssueLoaded } from './set-is-single-issue-loaded';
import { setIssueLinksForSelectedIssue } from './set-issue-links';
import { setIssues } from './set-issues';
import { setLoading } from './set-loading';
import { unlinkDeliveryIssue, unlinkIssueFromStore } from './unlink-issue';
import { updateCheckboxOrRatingNumberFieldMapping } from './update-checkbox-or-rating-number-field-mapping';
import { updateField } from './update-field';
import {
	updateFieldValue,
	updateFieldValues,
	updateFieldValueWithBulk,
	updateFieldValueForSelectedIssue,
	resetLastUpdatedIssueIds,
} from './update-field-value';
import {
	updateStatus,
	updateStatusInStateOnly,
	updateStatusInStateOnlyWithBulk,
} from './update-status';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	submitIdea,
	createAndUpdate,
	setLoading,
	setError,
	setIssues,
	setIssueLinksForSelectedIssue,
	moveIssue,
	rankBefore,
	archiveIssues,
	unarchiveIssues,
	rankAfter,
	loadIssues,
	loadArchivedIssues,
	initialize,
	initializeArchived,
	addPropertiesForLoadedIssue,
	loadDeliveryProgress,
	refreshDeliveryProgressForSelectedIssue,
	refreshDeliveryProgressForSpecificIssue,
	createIssue,
	createIssueAfter,
	createIssueBefore,
	safelySwapRowInCreationWithCreated,
	saveIssue,
	cancelCreateIssue,
	cancelCreations,
	updateCheckboxOrRatingNumberFieldMapping,
	updateFieldValue,
	updateFieldValues,
	updateFieldValueWithBulk,
	updateFieldValueForSelectedIssue,
	invalidateFieldValue,
	resetLastUpdatedIssueIds,
	updateStatus,
	updateField,
	refreshIssues,
	clearCreatedProperty,
	updateStatusInStateOnly,
	updateStatusInStateOnlyWithBulk,
	removeIssueFromState,
	deleteIssues,
	clonePolarisIssue,
	deleteExternalIssues,
	createDynamicFieldSelectors,
	updateDynamicFieldSelector,
	linkIssue,
	linkDeliveryIssue,
	unlinkIssueFromStore,
	unlinkDeliveryIssue,
	moveIssuesToTop,
	adaptExternalIssueRank,
	rankIdeasWithCurrentSorting,
	incrementOpenUpdateCounter,
	decrementOpenUpdateCounter,
	updateExternalReferenceEntities,
	toggleReaction,
	execWithIssueAnalyticsData,
	exportStringValues,
	scheduleDynamicFieldCalculation,
	setIsSingleIssueLoaded,
	...commentsActions,
	...insightsActions,
	...playActions,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
