import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from './controllers/types';

export {
	useEnvironmentContainer,
	useHasEnvironmentContainerError,
	useIsEnvironmentContainerAvailable,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';

export {
	PolarisJiraSpaProjectEnvironmentProvider,
	PolarisCustomProjectEnvironmentContainerProvider,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/project/index.tsx';
export { PolarisCustomEnvironmentContainerProvider } from '@atlassian/jira-polaris-component-environment-container/src/controllers/custom/index.tsx';
export {
	PolarisEnvironmentContainerTypes,
	type PolarisEnvironmentContainer,
	type ContainerId,
} from './controllers/types';

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectKey = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return container.projectKey;
	}

	return undefined;
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectKeyUnsafe = (): string => {
	const projectKey = useProjectKey();
	return projectKey || '';
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectId = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return container.projectId;
	}

	return undefined;
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectIdUnsafe = (): string => {
	const projectId = useProjectId();
	return projectId || '';
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectAri = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return container.projectAri.toString();
	}

	return undefined;
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectAriUnsafe = (fallbackAri = ''): string => {
	const projectAri = useProjectAri();
	return projectAri || fallbackAri;
};

export const useIsInCollectionContainer = () => {
	const container = useEnvironmentContainer();
	return container?.type === PolarisEnvironmentContainerTypes.COLLECTION;
};
