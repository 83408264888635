import { createSelector } from 'reselect';
import head from 'lodash/head';
import sum from 'lodash/sum';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	Filter,
	FieldValueFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type {
	ViewSetType,
	ViewSet,
} from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { LocalViewId, View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { wrapWithDeepEqualityCheck } from '../../../../../common/utils/reselect';
import type { Props, State } from '../../../types';
import { getFields } from '../../fields';
import { getCurrentView } from '../current';

export const getViewSets = (state: State) => state.viewSets;

export const getCurrentViewViewSetId = createSelector(getCurrentView, (view) => view?.viewSetId);

export const createGetViewSet = (viewSetType: ViewSetType) =>
	createSelector(getViewSets, (viewSets) =>
		viewSets.find((element) => element.type === viewSetType),
	);

export const createGetViewIdsInSet = (viewSetType: ViewSetType) =>
	wrapWithDeepEqualityCheck<State, Props | undefined, LocalViewId[]>(
		createSelector(createGetViewSet(viewSetType), (viewSet?: ViewSet) =>
			(viewSet?.views || []).map(({ id }) => id),
		),
	);

export const getViewCount = createSelector(getViewSets, (viewSets) => {
	const countViewsRecursive = (viewSet: ViewSet): number =>
		viewSet.views.length + sum((viewSet.viewSets || []).map((set) => countViewsRecursive(set)));

	return sum(viewSets.map((set) => countViewsRecursive(set)));
});

export const getPrioritizeViewCount = createSelector(
	createGetViewSet('PRIORITIZE'),
	(prioritizeViewSet) => {
		if (!prioritizeViewSet) {
			return 0;
		}

		const countViewsRecursive = (viewSet: ViewSet): number =>
			viewSet.views.length +
			(viewSet.viewSets || []).reduce((acc, set) => acc + countViewsRecursive(set), 0);

		return (
			prioritizeViewSet.views.length +
			(prioritizeViewSet.viewSets || []).reduce((acc, set) => acc + countViewsRecursive(set), 0)
		);
	},
);

export const getViewsetsCount = createSelector(getViewSets, (viewSets) => {
	const countViewSetsRecursive = (viewSet: ViewSet): number =>
		1 + sum((viewSet.viewSets || []).map((set) => countViewSetsRecursive(set)));

	return sum(viewSets.map((set) => countViewSetsRecursive(set)));
});

const getAllIdeasView = createSelector(createGetViewSet('PRIORITIZE'), (viewSet) =>
	head(viewSet?.views),
);

export const getAllIdeasViewId = createSelector(getAllIdeasView, (view) => view?.id);

export const getViewSlugByRank = (rank: number) =>
	createSelector(
		createGetViewSet('PRIORITIZE'),
		(viewSet) => viewSet?.views.find((view) => view.rank === rank)?.slug,
	);

const getPinnedView = createSelector(createGetViewSet('SINGLE'), (viewSet) => head(viewSet?.views));
const getPinnedViewFieldsStableReference = createSelector(
	getPinnedView,
	(view) => view?.fields || [],
);

export const getPinnedViewId = createSelector(getPinnedView, (view) => view?.id);

export const getPinnedViewFields = createSelector(
	getFields,
	getPinnedViewFieldsStableReference,
	(fields, viewFields) => viewFields.filter((pinnedField) => fields[pinnedField]),
);

export const getIsCurrentViewInCaptureSet = createSelector(
	createGetViewSet('CAPTURE'),
	getCurrentViewViewSetId,
	(captureSet, currentViewSetId) => {
		if (captureSet === undefined || currentViewSetId === undefined) {
			return false;
		}

		return captureSet.id === currentViewSetId;
	},
);

export const getSystemViewsContainingArchived = wrapWithDeepEqualityCheck<
	State,
	Props | undefined,
	LocalViewId[]
>(
	createSelector(createGetViewSet('SYSTEM'), (viewSet) =>
		(viewSet?.views || []).filter((view) => view.containsArchived).map(({ id }) => id),
	),
);

export const getSystemViewsNotContainingArchived = wrapWithDeepEqualityCheck<
	State,
	Props | undefined,
	LocalViewId[]
>(
	createSelector(createGetViewSet('SYSTEM'), (viewSet) =>
		(viewSet?.views || []).filter((view) => !view.containsArchived).map(({ id }) => id),
	),
);

const isFieldValueFilter = (filter: Filter): filter is FieldValueFilter => 'field' in filter;

export const getViewIdsConfiguredByField = (fieldKey: FieldKey) =>
	createSelector(createGetViewSet('PRIORITIZE'), (viewSet) => {
		const viewIds: Array<string> = [];
		const findUsage = ({
			filter,
			sortBy,
			verticalGroupBy,
			groupBy,
			id,
			timelineConfig,
			matrixConfig,
			fields,
		}: View) => {
			const inFields = fields?.find((f) => f === fieldKey);
			const inFilter = filter.find((f) => isFieldValueFilter(f) && f.field === fieldKey);
			const inSort = sortBy?.find((s) => s.fieldKey === fieldKey);
			const inGroup = verticalGroupBy === fieldKey || groupBy === fieldKey;
			const inTimelineConfig =
				timelineConfig?.startDateField?.key === fieldKey ||
				timelineConfig?.dueDateField?.key === fieldKey;
			const inMatrixConfig = matrixConfig?.axes.some((a) => a.field?.key === fieldKey);
			const isUsed =
				inFields || inFilter || inSort || inGroup || inTimelineConfig || inMatrixConfig;

			if (isUsed) {
				viewIds.push(id);
			}
		};

		viewSet?.views.forEach(findUsage);

		viewSet?.viewSets?.forEach((subSet) => {
			subSet.views.forEach(findUsage);
		});

		return viewIds;
	});

export const getHasViewSetsError = (state: State) => state.meta.error !== undefined;

export const getPrioritizeViews = createSelector(createGetViewSet('PRIORITIZE'), (viewSet) => {
	const views: View[] = [];

	viewSet?.views.forEach((view) => views.push(view));

	viewSet?.viewSets?.forEach((subSet) => {
		subSet.views.forEach((view) => views.push(view));
	});

	return views;
});
