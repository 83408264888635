import type { Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { TreeObject, ItemId, TreeItem } from '@atlassian/jira-polaris-lib-tree/src/types.tsx';

export type ItemType = 'view' | 'section' | 'placeholder';

export type TreeItemData = {
	title?: string;
	type?: ItemType;
	view?: SimplifiedView;
	section?: string;
	parentId?: ItemId;
	rank?: number;
};

export type SimplifiedView = {
	localId: LocalViewId;
	viewId: string | undefined;
	rank: number;
	uuid: string | undefined;
};

export interface JpdTreeItem extends TreeItem {
	data: TreeItemData;
}

export type SimplifiedViewSetTreeNode = {
	id: string;
	rank: number;
	views: SimplifiedView[];
	collapsed: boolean;
	children: SimplifiedViewSetTreeNode[];
};

export type JpdTreeObject = TreeObject<JpdTreeItem>;

export default class TreeBuilder {
	rootId: ItemId;

	items: Record<ItemId, TreeItem> = {};

	constructor(rootId: ItemId, data: TreeItemData, isExpanded?: boolean) {
		const rootItem = this.createItem(rootId, data, [], isExpanded);
		this.rootId = rootItem.id;
		this.items[rootItem.id] = rootItem;
	}

	// go/jfe-eslint
	withLeaf(
		id: ItemId,
		data: TreeItemData,
		blockedInstructions?: Instruction['type'][],
	): TreeBuilder {
		const leafItem = this.createItem(id, data, blockedInstructions);
		this.addItemToRoot(leafItem.id);
		this.items[leafItem.id] = leafItem;
		return this;
	}

	// go/jfe-eslint
	withSubTree(tree: TreeBuilder): TreeBuilder {
		const subTree = tree.build();
		this.addItemToRoot(subTree.rootId);
		this.items = { ...this.items, ...subTree.items };
		return this;
	}

	addItemToRoot(id: ItemId) {
		const rootItem = this.items[this.rootId];
		rootItem.children.push(id);
		rootItem.hasChildren = true;
	}

	createItem = (
		id: ItemId,
		data: TreeItemData,
		blockedInstructions?: Instruction['type'][],
		isExpanded = true,
	): TreeItem => ({
		id,
		data,
		isExpanded,
		children: [],
		hasChildren: false,
		blockedInstructions,
	});

	// Builds the tree object
	build(): JpdTreeObject {
		return {
			rootId: this.rootId,
			items: this.items,
		};
	}
}
