import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { loginSuccessful, LOGIN } from '@atlassian/jira-issue-view-store/src/actions/login-actions';
import { fetchIssueRequest } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import {
	baseUrlSelector,
	loginRedirectUrlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(LOGIN).switchMap(() => {
		const redirectUrl = loginRedirectUrlSelector(store.getState());
		if (redirectUrl) {
			const baseUrl = baseUrlSelector(store.getState());
			const encodedRedirectUrl = encodeURIComponent(redirectUrl);
			const logInUrl = `${baseUrl}/login.jsp?os_destination=${encodedRedirectUrl}`;
			const logInWindow = window.open(logInUrl, '_blank');
			/*
			 * Wait for the login window to post an event back to the app of the shape:
			 * {
			 *      accountId: accountid,
			 *      status: 'log-in-successful'
			 * }
			 * If the login was successful and there was a logged in user,
			 * request the issue data again now that we are authenticated.
			 */
			return (
				Observable.fromEvent(window, 'message')
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					.filter((e) => e.origin === window.location.origin)
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					.filter((e) => e.source === logInWindow)
					.filter(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						(e: any) => e.data && e.data.status === 'log-in-successful' && e.data.accountId,
					)
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					.do((e) => e.source.close())
					.mergeMap((e) =>
						Observable.of(
							loginSuccessful({
								// @ts-expect-error - TS2571 - Object is of type 'unknown'.
								accountId: e.data.accountId,
							}),
							// @ts-expect-error - TS2769 - No overload matches this call.
							fetchIssueRequest(),
						),
					)
			);
		}
		return Observable.empty<never>();
	});
