import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import type { RollupItemFieldIconComponentProps } from './types';

export const RollupItemFieldIconComponent = ({
	emoji,
	fieldTypeIcon,
}: RollupItemFieldIconComponentProps) => {
	if (emoji !== undefined) {
		return (
			<Flex xcss={containerStyles} alignItems="center">
				<Emoji emoji={emoji} fitToHeight={16} />
			</Flex>
		);
	}

	if (fieldTypeIcon !== null) {
		return <Box xcss={containerStyles}>{fieldTypeIcon}</Box>;
	}

	return null;
};

const containerStyles = xcss({
	marginRight: 'space.100',
});
