import {
	type IssueLoadingStage,
	LOADING,
	COMPLETE,
	PREVIEW,
} from '@atlassian/jira-issue-view-common-types/src/loading-stage-type';
import { type LoadNewIssueAction, LOAD_NEW_ISSUE } from '../actions/issue-navigation-actions';
import { type LoginSuccessfulAction, LOGIN_SUCCESSFUL } from '../actions/login-actions';
import {
	type FetchIssueSuccessAction,
	type FetchIssueFailureAction,
	type RefreshIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
	FETCH_ISSUE_FAILURE,
	REFRESH_ISSUE_SUCCESS,
} from '../common/actions/issue-fetch-actions';
import {
	PREVIEW_DATA_FETCHED,
	type PreviewDataFetchedAction,
} from '../issue-field/state/actions/field-preview-actions';

type Action =
	| LoadNewIssueAction
	| FetchIssueSuccessAction
	| FetchIssueFailureAction
	| RefreshIssueSuccessAction
	| LoginSuccessfulAction
	| PreviewDataFetchedAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueLoadingStage = LOADING, action: Action) => {
	const { type } = action;
	switch (type) {
		case FETCH_ISSUE_SUCCESS:
		case REFRESH_ISSUE_SUCCESS:
		case FETCH_ISSUE_FAILURE:
			return COMPLETE;

		case PREVIEW_DATA_FETCHED: {
			if (state === LOADING) {
				return PREVIEW;
			}
			return state;
		}

		case LOGIN_SUCCESSFUL:
		case LOAD_NEW_ISSUE:
			return LOADING;

		default:
			return state;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let resolve: (reason?: any) => void;
const promiseFunction = () =>
	new Promise((res) => {
		resolve = res;
	});

/**
 * We are moving to a suspense-based paradigm of rendering loading states for the issue-view.
 * In order to consolidate our loading-state rendering, our Redux-powered fetches now throw a
 * promise (to trigger suspense) which will trigger the same suspense boundaries that a Relay-powered fetch does.
 * @returns Promise or resolves it and returns null
 */
export const issueLoadingStagePromiseReducer = (
	state: null | undefined | Promise<unknown> = promiseFunction(),
	action: Action,
) => {
	const { type } = action;

	switch (type) {
		case FETCH_ISSUE_SUCCESS:
		case REFRESH_ISSUE_SUCCESS:
		case FETCH_ISSUE_FAILURE: {
			resolve?.();
			return null;
		}

		case LOAD_NEW_ISSUE:
			return promiseFunction();

		default:
			return state;
	}
};
