import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noValue: {
		id: 'polaris-ideas.idea-view.fields.field.label.no-value',
		defaultMessage: 'None',
		description: 'Field value if no actual value is available.',
	},
	nonEditableTooltip: {
		id: 'polaris-ideas.idea-view.fields.field.label.non-editable-tooltip',
		defaultMessage: 'This field requires all ideas to be loaded in order to be edited',
		description: 'Tooltip displayed on hover when a label field cannot be edited',
	},
});
