import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';

export const COMMENT_FRAGMENT = gqlTagPolaris`
  fragment PolarisComment on PolarisComment {
    id
    content
    account {
      accountId
      name
      picture
    }
    created
    updated
  }
`;

export const PLAY_CONTRIBUTION_COMMENT_FRAGMENT = gqlTagPolaris`
  fragment PolarisPlayContributionComment on PolarisComment {
    id
    content
    created
    updated
  }
`;
