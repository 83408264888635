import { evaluateNumericFilterValues } from '@atlassian/jira-polaris-domain-view/src/filter/index.tsx';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { MappingFilterFunction } from '../../types';

export const createFilterFunction =
	(values: NumericFieldFilterValue[]): MappingFilterFunction<number[]> =>
	(value: number[] | undefined) => {
		if (value === undefined) {
			return evaluateNumericFilterValues(values, 0);
		}
		const totalLinkedTickets = value.reduce((a, b) => a + b, 0);
		// if value exists but unestimated
		if (totalLinkedTickets === 0) {
			return evaluateNumericFilterValues(values, 1);
		}
		return evaluateNumericFilterValues(values, totalLinkedTickets);
	};
