import { B300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type { IdeaTemplate } from '../types';
import HYPOTHESIS_TEMPLATE from './hypothesis';
import PROBLEM_DEFINITION_TEMPLATE from './problem-definition';
import SOLUTION_DEFINITION_TEMPLATE from './solution-definition';
import SOLUTION_RETRO_TEMPLATE from './solution-retro';

const HYPOTHESIS: IdeaTemplate = {
	id: 'HypothesisTesting',
	title: 'Hypothesis testing',
	description: 'Define product hypothesis, validate them and capture key decisions.',
	template: HYPOTHESIS_TEMPLATE,
	color: B300,
	emoji: '1f4ca',
};

const PROBLEM_DEFINITION: IdeaTemplate = {
	id: 'ProblemDefinition',
	title: 'Problem definition',
	description: "Define what problem you're solving and for whom.",
	template: PROBLEM_DEFINITION_TEMPLATE,
	color: B300,
	emoji: '2601',
};

const SOLUTION_DEFINITION: IdeaTemplate = {
	id: 'SolutionDefinition',
	title: 'Solution definition',
	description: 'Define potential solutions and how to validate them.',
	template: SOLUTION_DEFINITION_TEMPLATE,
	color: B300,
	emoji: '1f5d2',
};

const SOLUTION_RETRO: IdeaTemplate = {
	id: 'SolutionRetro',
	title: 'Solution retrospective',
	description: 'Review the outcomes of a particular solution exploration.',
	template: SOLUTION_RETRO_TEMPLATE,
	color: B300,
	emoji: '2754',
};

export const DEFAULT_TEMPLATES = [
	HYPOTHESIS,
	PROBLEM_DEFINITION,
	SOLUTION_DEFINITION,
	SOLUTION_RETRO,
];
