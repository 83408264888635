import { createSelector } from 'reselect';
import compact from 'lodash/compact';
import groupBy from 'lodash/groupBy';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	RemoteIssueLink,
	RemoteLinkedIssueArchived,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/issue-link/types.tsx';
import type {
	Watcher,
	State,
	Props,
	PolarisIdeaVotes,
	ExtendedLinkedIssue,
	PolarisJiraIssueAttachment,
	FieldsSidebarConfig,
} from '../types';

const getIsLinkedIssueArchived = (
	field: RemoteLinkedIssueArchived['fields'][0],
	archivedField: Field,
) =>
	field.kind === 'select' &&
	field.title === archivedField?.label &&
	archivedField?.archivedOption?.value !== undefined &&
	field.selectedValue?.value === archivedField.archivedOption.value;

export const getProjectId = (state: State, props?: Props): string | undefined => props?.projectId;
// TODO: replace this selector with a global one when issues remote will be available globally
export const getIssuesRemote = (_: State, props?: Props) => props?.issuesRemote;

export const getIssueLinks = (state: State) => state.issueLinks;
export const getDeliveryTickets = (state: State) => state.deliveryTickets;
export const isLoadingLinkedIssues = (state: State) => state.meta.loadingLinkedIssues;
export const isLoading = (state: State) => state.meta.isLoading;
export const isLoadingArchived = (state: State) => state.archivedMeta.isLoading;
export const isIssueStateCallbackHandled = (state: State): boolean =>
	state.meta.issueStateCallbackHandled;
export const isSingleIssueLoaded = (state: State) => state.meta.isSingleIssueLoaded;
export const getIdeaNotFoundError = (state: State) => state.meta.error?.statusCode === 404;
export const getError = (state: State) => state.meta.error;

export const getCriticalDataLoadingError = (state: State) => {
	const error = getError(state);
	if (error && !getIdeaNotFoundError(state) && !isLoading(state) && !getPreloadedSummary(state)) {
		return error;
	}
	return undefined;
};

export const getIdeaVotes = (state: State): PolarisIdeaVotes | undefined => state.votes;

export const getFieldKeys = createSelector(
	(_: State, props: Props) => props.fields,
	(_: State, props: Props) => props.rankField,
	(fields, rankField) =>
		fields
			?.map((field) => field.key)
			.concat(rankField || '')
			.filter((str) => str.length > 0) || [],
);

export const getDeliveryTicketsGroupedByParent = createSelector(getDeliveryTickets, (tickets) => {
	const grouped = groupBy(tickets, (ticket) =>
		ticket.parentKey === undefined ? 'no-parent-key' : ticket.parentKey,
	);
	return grouped;
});

export const getIssueLinksMap = createSelector(getIssueLinks, (issueLinks) => {
	const map = new Map<string, RemoteIssueLink>();

	issueLinks.forEach((issue) => {
		const { inwardIssue, outwardIssue } = issue;
		const id = inwardIssue?.id ?? outwardIssue?.id;
		if (id) {
			map.set(id, issue);
		}
	});

	return map;
});

export const getGroupedIssueLinks = createSelector(
	getIssueLinks,
	(state: State) => state.archivedLinkedIssues,
	(_: State, props?: Props) => props?.polarisIssueLinkTypes,
	(_: State, props?: Props) => props?.archivedFieldsConfig?.archivedField,
	(
		issueLinks,
		archivedLinkedIssues,
		polarisIssueLinkTypes,
		archivedField,
	): Record<string, ExtendedLinkedIssue[]> => {
		if (!polarisIssueLinkTypes?.polarisDataPointLinkType) {
			return {};
		}

		const archivedLinkedIssuesMap: Record<string, boolean> = {};

		archivedLinkedIssues.forEach((link) => {
			link.fields.forEach((field) => {
				if (field.kind === 'project' && field.type !== PRODUCT_DISCOVERY_PROJECT) {
					archivedLinkedIssuesMap[link.issueId] = false;
				}
				if (
					archivedField &&
					getIsLinkedIssueArchived(field, archivedField) &&
					archivedLinkedIssuesMap[link.issueId] === undefined
				) {
					archivedLinkedIssuesMap[link.issueId] = true;
				}
			});
		});

		const linkedIssuesWithLinkGroup: ExtendedLinkedIssue[] = compact(
			issueLinks
				.filter((issueLink) => {
					if (polarisIssueLinkTypes?.polarisDeliveryIssueLinkType === issueLink.type.id) {
						return false;
					}

					if (
						polarisIssueLinkTypes?.polarisDataPointLinkType === issueLink.type.id &&
						issueLink.outwardIssue
					) {
						return false;
					}
					return true;
				})
				.map((issueLink) => {
					if (issueLink.outwardIssue !== undefined) {
						return {
							linkGroup: issueLink.type.outward,
							linkedIssue: {
								...issueLink.outwardIssue,
								isArchived: archivedLinkedIssuesMap[issueLink.outwardIssue.id],
							},
							issueLinkId: issueLink.id,
						};
					}
					if (issueLink.inwardIssue !== undefined) {
						return {
							linkGroup: issueLink.type.inward,
							linkedIssue: {
								...issueLink.inwardIssue,
								isArchived: archivedLinkedIssuesMap[issueLink.inwardIssue.id],
							},
							issueLinkId: issueLink.id,
						};
					}
					return undefined;
				}),
		);

		if (linkedIssuesWithLinkGroup !== undefined && linkedIssuesWithLinkGroup.length > 0) {
			return linkedIssuesWithLinkGroup.reduce<Record<string, ExtendedLinkedIssue[]>>(
				(object, item) => {
					if (object[item.linkGroup] !== undefined) {
						const linkedIssues = object[item.linkGroup];
						linkedIssues.push(item);
						return {
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...object,
							[item.linkGroup]: linkedIssues,
						};
					}
					return {
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...object,
						[item.linkGroup]: [item],
					};
				},
				{},
			);
		}
		return {};
	},
);

export const getAttachments = (state: State): PolarisJiraIssueAttachment[] =>
	state.attachments.attachments;

export const isAttachmentsLoading = (state: State) => state.attachments.isLoading;

export const getAttachmentsInUpload = (state: State): string[] => state.attachments.inUpload;

export const getFieldsSidebarConfig = (state: State): FieldsSidebarConfig => state.fieldsSidebar;

export const getPreloadedSummary = (state: State): string | undefined =>
	state.preloadedFields?.summary;

export const getPreloadedDescription = (state: State): DocumentFieldValue | undefined =>
	state.preloadedFields?.description;

export const getPreloadedIssueTypeIcon = (state: State): string | undefined =>
	state.preloadedFields?.issuetype?.iconUrl;

export const getWatchCount = (state: State): number => state.watchInfo?.watchersList.length ?? 0;

export const getIssueWatchersList = (state: State): Watcher[] =>
	state.watchInfo?.watchersList || [];

export const getIssueWatchersMap = createSelector(getIssueWatchersList, (watchers) => {
	const map = new Map<string, Watcher>();

	watchers.forEach((watcher) => {
		map.set(watcher.accountId, watcher);
	});

	return map;
});

export const isWatching = (userId?: string | null) =>
	createSelector(getIssueWatchersMap, (watchersMap) => userId && watchersMap.has(userId));

export const isEditingSummary =
	(fieldID: string) =>
	(state: State): boolean =>
		state.isEditingSummary.id === fieldID && state.isEditingSummary.value;
