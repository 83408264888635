import { createSelector } from 'reselect';
import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { noopJiraUpdateService } from '../../../../field/mapping/common';
import type { State, Props } from '../../../types';
import { numberComparator } from '../../comparators';
import type { FieldMapping } from '../types';

export const playMapping = (issuesRemote: IssuesRemote, field: Field): FieldMapping<number> => {
	const valueAccessor: FieldMapping<number>['valueAccessor'] = (state, props, issueId) => {
		const numberDynamicProperties = state.dynamicProperties.numberValue;
		if (numberDynamicProperties[field.key] !== undefined) {
			const val = numberDynamicProperties[field.key](issueId)?.(state, props);
			return val;
		}
		return undefined;
	};

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => `${valueAccessor(state, props, issueId) || ''}`;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const EMPTY: Record<string, any> = {};

	const getAllValuesWithoutUndefined = createSelector(
		(state: State, props: Props | undefined) =>
			state.dynamicProperties.numberValues[field.key](state, props),
		(values) => pickBy(values, (value) => value !== undefined),
	);

	return {
		field,
		getValueFromJiraIssue: () => undefined,
		getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
		setMutable: noop,
		setImmutable: (propertyMaps) => propertyMaps,
		remove: (propertyMaps) => propertyMaps,
		modifyImmutableIfMultiValueField: (propertyMaps) => propertyMaps,
		comparator: numberComparator,
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state, props) => {
			if (state.dynamicProperties.numberValues[field.key] === undefined) {
				return EMPTY;
			}
			return getAllValuesWithoutUndefined(state, props);
		},
		getGroupIdentitiesSelector: (_fieldKey, _issueIdsSelector) => () => ({}),
		getGroupIdentities: () => [],
		allowEmptyGroup: false,
		isMultiValueField: false,
		isSupportedByIssueUpdateApi: false,
		getLabel: () => undefined,
		getFilter: () => undefined,
		...noopJiraUpdateService,
	};
};
