import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter';
import { useListActions } from '../../../controllers';
import { useGoToRowLabelComponent } from '../../../controllers/selectors/components-hooks';
import { useUpdatedRowProps } from '../../../controllers/selectors/items-hooks';
import { useRowsRendered } from '../../../controllers/selectors/rows-hooks';
import { useBaseTableApi } from '../../../controllers/selectors/ui-hooks';
import { DEFAULT_HEADER_HEIGHT } from '../../constants';
import messages from './messages';

export type MovedRowOverlayProps = {
	isBulkEditDialogShown: boolean;
	testId?: string;
};

export const MovedRowOverlay = ({ isBulkEditDialogShown, testId }: MovedRowOverlayProps) => {
	const { startIndex, stopIndex } = useRowsRendered();
	const { id, idx, isMoved, isFiltered } = useUpdatedRowProps();
	const { showIdeaPreview, setUpdatedRows } = useListActions();
	const tableApi = useBaseTableApi();
	const GoToRowComponent = useGoToRowLabelComponent();

	const { formatMessage } = useIntl();
	const messageMoved = formatMessage(messages.ideaMoved);
	const messageFiltered = formatMessage(messages.ideaFiltered);

	const onClickMoved = () => {
		if (idx !== null) {
			tableApi?.scrollToRow(idx, 'center');
		}
	};

	const onClickFiltered = () => {
		id !== null && showIdeaPreview(id, []);
	};

	const handleOnClickOutside = () => {
		setUpdatedRows([]);
	};

	const direction = useMemo(() => {
		// Updated row is hidden
		if (idx === null) return 0;

		if (idx < startIndex) return -1;
		if (idx > stopIndex) return 1;

		return 0;
	}, [startIndex, stopIndex, idx]);

	if (direction === 0 || (!isFiltered && !isMoved)) {
		return null;
	}

	return (
		<OutsideClickAlerter onClickOutside={handleOnClickOutside}>
			{({ onMouseDownCapture, onTouchEndCapture }) => (
				<Box
					onMouseDownCapture={onMouseDownCapture}
					onTouchEndCapture={onTouchEndCapture}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({ pointerEvents: 'auto' })}
				>
					{isFiltered && (
						<FilteredHintButton
							iconAfter={<WatchIcon label={messageFiltered} size="medium" />}
							appearance="primary"
							onClick={onClickFiltered}
							testId={testId}
						>
							{messageFiltered}
						</FilteredHintButton>
					)}
					{!isFiltered && isMoved && (
						<MovedHintButton
							// eslint-disable-next-line jira/react/no-style-attribute
							style={{
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								...(direction === -1
									? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
										{ top: `calc(${DEFAULT_HEADER_HEIGHT}px + 20px)` }
									: { bottom: isBulkEditDialogShown ? '112px' : '20px' }),
							}}
							iconAfter={
								direction === -1 ? (
									<ArrowUpIcon label={messageMoved} size="medium" />
								) : (
									<ArrowDownIcon label={messageMoved} size="medium" />
								)
							}
							appearance="primary"
							onClick={onClickMoved}
							testId={testId}
						>
							{GoToRowComponent ? <GoToRowComponent /> : messageMoved}
						</MovedHintButton>
					)}
				</Box>
			)}
		</OutsideClickAlerter>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HintButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		position: 'absolute',
		left: '50%',
		transform: 'translate(-50%, 0)',
		boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
		borderRadius: '16px',
		paddingLeft: '16px',
		zIndex: 3,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilteredHintButton = styled(HintButton)({
	bottom: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MovedHintButton = styled(HintButton)({});
