import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.chrome.title',
		defaultMessage: 'Chrome Extension',
		description: 'Title of the polaris chrome extension app',
	},
	description: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.chrome.description',
		defaultMessage:
			'Capture quotes, images, and data from any page; add them to ideas without leaving your tab',
		description: 'Description of the polaris chrome extension app',
	},
	installItNow: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.chrome.install-it-now',
		defaultMessage: 'Install it now',
		description: 'Button caption that opens the dialog to explain how to install the slack app',
	},
});
