import { useState, useRef, useEffect, useCallback } from 'react';
import { useIsReducedMotion } from '@atlaskit/motion';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CommandInternal } from '../../../../../types/commands/index.tsx';
import { hasChildren } from '../../../../../utils';
import {
	TRANSITION_STEP,
	TRANSITION_DURATION_MS,
	TRANSITION_HOVER_DELAY_TIME_MS,
	TRANSITION_INITIAL_DELAY_TIME_MS,
} from './constants';

export const useHoverTransitionStep = (
	command: CommandInternal,
	isActive: boolean,
	isFirstCommand: boolean,
): TRANSITION_STEP => {
	const [hasTransitionedFirstCommand, setHasTransitionedFirstCommand] = useState(false);
	const [transitionStep, setTransitionStep] = useState<TRANSITION_STEP>(TRANSITION_STEP.INITIAL);
	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const isReducedMotion = useIsReducedMotion();
	const commandHasChildren = hasChildren(command);

	useEffect(() => {
		setTransitionStep(TRANSITION_STEP.INITIAL);
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
	}, [command]);

	const startTimerForNextStep = (nextStep: TRANSITION_STEP, durationMs: number) => {
		timerRef.current = setTimeout(() => {
			setTransitionStep(nextStep);
			timerRef.current = null;
		}, durationMs);
	};

	const handleReducedMotionTransition = useCallback(() => {
		if (isActive) {
			setTransitionStep(TRANSITION_STEP.FINISHED);
		} else {
			setTransitionStep(TRANSITION_STEP.INITIAL);
		}
	}, [isActive]);

	const handleWithMotionTransition = useCallback(() => {
		switch (transitionStep) {
			case TRANSITION_STEP.INITIAL:
				if (isActive) {
					setTransitionStep(TRANSITION_STEP.DELAYING);
					startTimerForNextStep(
						TRANSITION_STEP.TRANSITIONING,
						isFirstCommand && !!command.shortcut && !hasTransitionedFirstCommand
							? TRANSITION_INITIAL_DELAY_TIME_MS
							: TRANSITION_HOVER_DELAY_TIME_MS,
					);
				}
				break;
			case TRANSITION_STEP.DELAYING:
				if (!isActive) {
					timerRef.current && clearTimeout(timerRef.current);
					timerRef.current = null;
					setTransitionStep(TRANSITION_STEP.INITIAL);
				}
				break;
			case TRANSITION_STEP.TRANSITIONING:
				if (isActive) {
					if (isFirstCommand && !!command.shortcut && !hasTransitionedFirstCommand) {
						setHasTransitionedFirstCommand(true);
					}
					if (!timerRef.current) {
						startTimerForNextStep(TRANSITION_STEP.FINISHED, TRANSITION_DURATION_MS);
					}
				}
				break;
			case TRANSITION_STEP.FINISHED:
				if (!isActive) {
					setTransitionStep(TRANSITION_STEP.DELAYING_FADE_OUT);
				}
				break;
			case TRANSITION_STEP.DELAYING_FADE_OUT:
				if (!timerRef.current) {
					startTimerForNextStep(
						TRANSITION_STEP.TRANSITIONING_FADE_OUT,
						TRANSITION_HOVER_DELAY_TIME_MS,
					);
				}
				break;
			case TRANSITION_STEP.TRANSITIONING_FADE_OUT:
				if (!timerRef.current) {
					startTimerForNextStep(TRANSITION_STEP.INITIAL, TRANSITION_DURATION_MS);
				}
				break;
			default:
				fireErrorAnalytics({
					error: new Error('Invalid transition step for command'),
					meta: {
						id: 'useTransitionStep',
						packageName: 'jiraCommandPalette',
						teamName: 'deliveroo',
					},
				});
		}
	}, [command.shortcut, hasTransitionedFirstCommand, isActive, isFirstCommand, transitionStep]);

	useEffect(() => {
		if (commandHasChildren) {
			if (isReducedMotion) {
				handleReducedMotionTransition();
			} else {
				handleWithMotionTransition();
			}
		}
	}, [
		commandHasChildren,
		isReducedMotion,
		handleReducedMotionTransition,
		handleWithMotionTransition,
	]);

	useEffect(() => {
		if (!isFirstCommand) {
			setHasTransitionedFirstCommand(false);
		}
	}, [isFirstCommand]);

	useEffect(
		() => () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		},
		[],
	);

	return transitionStep;
};
