import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import messages from './messages';

type Props = {
	value?: string | undefined;
	onChange: (value: string) => void;
};

export const QuickSearchFilterComponent = ({ value, onChange }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<SearchInput
			id="pendo.view-controls.quick-search-input"
			testId="polaris-ideas.ui.view-controls.config-filters.quick-search.textfield"
			value={value}
			placeholder={formatMessage(messages.searchHint)}
			onChange={onChange}
		/>
	);
};
