import React, { useState, useRef, useMemo } from 'react';
import MediaServicesPresentationIcon from '@atlaskit/icon/glyph/media-services/presentation';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import { Section, ButtonItem } from '@atlaskit/side-navigation';
import Textfield from '@atlaskit/textfield';
import { N0, N20A, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { FIELD_ITEM_OUTER_SPACING } from '../../../../../common/constants';
import { RollupItemFieldIconComponent } from '../../../../../common/ui/rollup/field-icon';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../../../controllers/selectors/formula-hooks';
import { messages } from './messages';
import type { RollupItemComponentProps, RollupItemDropdownContentProps } from './types';

const buttonItemCss = () => ({
	backgroundColor: 'transparent',
});

const RollupItemDropdownContent = ({
	appName,
	label,
	appAvatarUrl,
	fieldKey,
	selectRef,
	filteredLabels,
	onFilterChange,
	sortedSnippetLabels,
}: RollupItemDropdownContentProps) => {
	const { formatMessage } = useIntl();

	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	const fieldTypeIcon = useFieldTypeIcon(fieldKey);

	return (
		<>
			{appName !== undefined && appName !== '' && (
				<Flex direction="column" xcss={decoratorContainerStyles}>
					<Box xcss={decoratorItemLabelStyles}>{formatMessage(messages.sourceLabel)}</Box>
					<Flex alignItems="start" direction="column">
						<Box xcss={iconLabelWrapperStyles}>
							{appAvatarUrl !== undefined && (
								<Box xcss={appAvatarContainerStyles}>
									<img src={appAvatarUrl} alt={appName} />
								</Box>
							)}
							{fieldKey !== undefined && (
								<RollupItemFieldIconComponent emoji={emoji} fieldTypeIcon={fieldTypeIcon} />
							)}
							<Box xcss={labelStyles}>{appName}</Box>
						</Box>
					</Flex>
				</Flex>
			)}
			<Flex direction="column" xcss={decoratorContainerStyles}>
				<Box xcss={decoratorItemLabelStyles}>{formatMessage(messages.propertyLabel)}</Box>
				<Flex alignItems="start" direction="column">
					<Box xcss={labelStyles}>{label}</Box>
				</Flex>
			</Flex>
			<Flex direction="column" xcss={decoratorContainerStyles}>
				<Box xcss={decoratorItemLabelStyles}>{formatMessage(messages.dialogLabel)}</Box>
				<Select
					// @ts-expect-error - TS2322 - Type '{ components: Partial<SelectComponents<unknown, boolean, GroupTypeBase<unknown>>>; select: StateManager<unknown, false, GroupTypeBase<unknown>, Select<unknown, false, GroupTypeBase<...>>> | null; ... 22 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; }' is not assignable to type '((string | ((instance: { components: Partial<SelectComponents<{ label: string; value: string; }, true, GroupTypeBase<{ label: string; value: string; }>>>; ... 23 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; } | null) => void) | RefObject<...>) & (...'.
					ref={selectRef}
					styles={{
						control: (base, { isFocused }) => ({
							...base,
							backgroundColor: isFocused ? N0 : N20A,
						}),
					}}
					options={sortedSnippetLabels.map((snippetLabel) => ({
						label: snippetLabel,
						value: snippetLabel,
					}))}
					value={
						filteredLabels
							? filteredLabels.map((snippetLabel) => ({
									label: snippetLabel,
									value: snippetLabel,
								}))
							: undefined
					}
					isMulti
					isSearchable
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onChange={(selectedOptions: any) => {
						onFilterChange &&
							onFilterChange(
								selectedOptions
									? // @ts-expect-error - TS7031 Parameter 'base' implicitly has an 'any' type.
										selectedOptions.map(({ value }) => value)
									: undefined,
							);
					}}
					placeholder={formatMessage(messages.selectionPlaceholder)}
				/>
			</Flex>
		</>
	);
};

export const RollupItemComponent = ({
	isPolarisApp = false,
	label,
	fieldKey,
	appAvatarUrl,
	appName,
	filteredLabels,
	onDelete,
	percentage,
	onFilterChange,
	isUnbalanced,
	readonly,
	defaultValue,
	sortedSnippetLabels,
}: RollupItemComponentProps) => {
	const { formatMessage } = useIntl();
	const [percentageValue, setPercentageValue] = useState<unknown>(undefined);
	const formatPercentage = (value: number) => value.toFixed(0);

	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);

	const fieldTypeIcon = useFieldTypeIcon(fieldKey);

	const selectRef = useRef<unknown>(null);
	const focusOnSelect = () => {
		if (selectRef && selectRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			selectRef.current.focus();
		}
	};

	const [isOpen, setIsOpen] = useState(false);

	const { placeholder, value, readonlyValue } = useMemo(() => {
		if (percentage?.value !== undefined) {
			return {
				readonlyValue: `${formatPercentage(percentage.value)}%`,
				value: formatPercentage(percentage.value),
				placeholder: undefined,
			};
		}
		return {
			readonlyValue: `${formatPercentage(defaultValue)}%`,
			value: undefined,
			placeholder: formatPercentage(defaultValue),
		};
	}, [defaultValue, percentage?.value]);

	const rollupItem = (
		<>
			{percentage && (
				<Flex alignItems="center" xcss={percentageFieldStyles}>
					{readonly ? (
						readonlyValue
					) : (
						<Textfield
							isReadOnly={readonly}
							appearance={readonly ? 'none' : 'standard'}
							data-interactive
							value={value}
							placeholder={placeholder}
							onChange={(event) => {
								if (!event || !event.target) {
									return;
								}
								// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
								setPercentageValue(event.target.value);
								if (
									// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
									event.target.value === undefined ||
									// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
									event.target.value === null ||
									// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
									event.target.value === ''
								) {
									percentage.onChangePercentage(undefined);
								} else {
									// @ts-expect-error - TS2339 - Property 'strValue' does not exist on type 'EventTarget'.
									const strValue = Number(event.target.value);
									const safeValue = Number.isSafeInteger(strValue) ? strValue : undefined;
									percentage.onChangePercentage(safeValue);
								}
							}}
							isInvalid={Number.isNaN(Number(percentageValue || 0)) || isUnbalanced}
							width={58}
							isCompact
							elemAfterInput={<Box paddingInlineEnd="space.050">%</Box>}
						/>
					)}
				</Flex>
			)}
			<Flex
				xcss={rollupItemStyles}
				justifyContent="space-between"
				alignItems="center"
				testId="polaris-component-field-configuration.ui.configuration.formula.rollup.item.rollup-item"
			>
				{!isOpen && (
					<Flex alignItems="start" direction="column">
						<Box xcss={iconLabelWrapperStyles}>
							<Box xcss={iconLabelStyles}>
								{!isPolarisApp ? (
									<>
										{appAvatarUrl !== undefined && (
											<Box xcss={appAvatarContainerStyles}>
												<img src={appAvatarUrl} alt={appName} />
											</Box>
										)}
										{fieldKey !== undefined && (
											<RollupItemFieldIconComponent emoji={emoji} fieldTypeIcon={fieldTypeIcon} />
										)}
									</>
								) : (
									<Box xcss={customIconStyles}>
										{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
										<MediaServicesPresentationIcon label="" size="small" primaryColor={N200} />
									</Box>
								)}
							</Box>
							<Box xcss={labelStyles}>{label}</Box>
						</Box>
					</Flex>
				)}
			</Flex>
		</>
	);

	return (
		<Flex direction="column">
			{readonly ? (
				<Flex xcss={readonlyRollupItemContainerStyles}>{rollupItem}</Flex>
			) : (
				<MenuOption
					menuComponent={
						<Section>
							{onFilterChange && (
								<>
									<ButtonItem
										// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
										cssFn={buttonItemCss}
										onClick={() => setIsOpen(true)}
									>
										{formatMessage(messages.detailsMenuLabel)}
									</ButtonItem>
									<ButtonItem
										// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
										cssFn={buttonItemCss}
										onClick={() => {
											setIsOpen(true);
											setTimeout(() => focusOnSelect(), 25);
										}}
									>
										{formatMessage(messages.filterMenuLabel)}
									</ButtonItem>
								</>
							)}
							{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
							<ButtonItem cssFn={buttonItemCss} onClick={onDelete}>
								{formatMessage(messages.deleteMenuLabel)}
							</ButtonItem>
						</Section>
					}
					isActive={isOpen}
					isDragEnabled={false}
					setOpenOption={() => setIsOpen(false)}
					outerSpacing={FIELD_ITEM_OUTER_SPACING}
				>
					{rollupItem}
				</MenuOption>
			)}
			{!readonly && isOpen && (
				<RollupItemDropdownContent
					appAvatarUrl={appAvatarUrl}
					appName={appName}
					fieldKey={fieldKey}
					filteredLabels={filteredLabels}
					selectRef={selectRef}
					label={label}
					onFilterChange={onFilterChange}
					sortedSnippetLabels={sortedSnippetLabels}
				/>
			)}
		</Flex>
	);
};

const readonlyRollupItemContainerStyles = xcss({
	marginLeft: 'space.200',
	height: '28px',
});

const rollupItemStyles = xcss({
	boxSizing: 'border-box',
	flex: '1',
});

const percentageFieldStyles = xcss({
	maxWidth: '60px',
	paddingRight: 'space.100',
	boxSizing: 'unset', // Flex defaults to 'border-box', which prevents the field from taking up the space it needs
});

const iconLabelStyles = xcss({
	gridArea: 'icon',
});

const labelStyles = xcss({
	gridArea: 'title',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	marginRight: 'space.050',
});

const appAvatarContainerStyles = xcss({
	marginRight: 'space.100',
	height: 'size.100',
	width: 'size.100',
	overflow: 'hidden',
});

const decoratorContainerStyles = xcss({
	paddingRight: 'space.200',
	paddingBottom: 'space.150',
	paddingLeft: 'space.200',
	boxSizing: 'border-box',
	background: N20A,
});

const decoratorItemLabelStyles = xcss({
	font: token('font.body.small'),
	color: 'color.text.subtlest',
	paddingBottom: 'space.050',
	maxWidth: '100%',
	overflow: 'hidden',
	lineHeight: '14px',
});

const iconLabelWrapperStyles = xcss({
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: '1fr',
	gap: 'space.0',
	gridTemplateAreas: '"icon title"',
	lineHeight: token('space.200'),
});

const customIconStyles = xcss({
	transform: 'scale(1.5)',
	marginRight: 'space.100',
});
