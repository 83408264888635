import React, { useCallback, useEffect, useMemo } from 'react';
import difference from 'lodash/difference';
import head from 'lodash/head';
import some from 'lodash/some';
import { IconButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsRightSidebarOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { useIsIssueOpenInSidebar } from '@atlassian/jira-polaris-common/src/controllers/route';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useActivePermanentFiltersFields,
	useActiveTemporaryFiltersFields,
	useAvailableFiltersFields,
	useQuickSearchFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import {
	useCanManageCurrentView,
	useCanManagePermanentFilters,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AddFilterComponent } from './add';
import { ClearFilterComponent } from './clear';
import { FilterComponent } from './filter-component';
import { useTmpFiltersKeysState } from './hooks';
import { messages } from './messages';
import { QuickSearchFilterComponent } from './quick-search';

export const PolarisFilters = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { clearAllFilters } = useViewActions();
	const { clearFieldOrNumericFilter } = useViewActions();

	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();

	const canCreatePermanentFilters = useCanManagePermanentFilters();
	const isDisabled = !canCreatePermanentFilters;

	// Fields used in filters (permanent)
	const activePermanentFiltersFields = useActivePermanentFiltersFields();
	// Fields used in filters (temporary)
	const activeTemporaryFiltersFields = useActiveTemporaryFiltersFields();
	// Available fields to use in filters
	const availableFiltersFields = useAvailableFiltersFields();
	// Temporary view filters
	const { tmpFiltersKeys, setTmpFiltersKeys, tmpFieldKey } = useTmpFiltersKeysState(
		canCreatePermanentFilters,
		activeTemporaryFiltersFields,
	);

	// Alter labels and sort them alpabetically
	const sortedAvailableFiltersFields = useMemo(() => {
		// Alter filters names mapping
		const filterLabelsMap = {
			issueid: formatMessage(messages.excludeIdeas),
		};

		return availableFiltersFields
			.map((field: Field) => {
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ issueid: string; }'.
				if (filterLabelsMap[field.label]) {
					const newField = { ...field };
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ issueid: string; }'.
					newField.label = filterLabelsMap[field.label];
					return newField;
				}
				return field;
			})
			.sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
	}, [availableFiltersFields, formatMessage]);

	const allFieldsMap = useMemo(
		() =>
			[...activePermanentFiltersFields, ...sortedAvailableFiltersFields].reduce<
				Record<string, Field>
			>((acc, field) => {
				acc[field.key] = field;
				return acc;
			}, {}),
		[activePermanentFiltersFields, sortedAvailableFiltersFields],
	);

	// Combine active and temp filters fields
	const allActivePermanentFiltersFields = useMemo(() => {
		const tmpFiltersSet = new Set(tmpFiltersKeys);

		return [
			...activePermanentFiltersFields.filter((field) => !tmpFiltersSet.has(field.key)),
			...tmpFiltersKeys.map((fieldKey) => allFieldsMap[fieldKey]),
		];
	}, [tmpFiltersKeys, activePermanentFiltersFields, allFieldsMap]);

	const helpMessage = (ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews)
		? messages.helpText
		: messages.sharedViewHelpText;

	// New filter listener
	const onAddFilter = (fieldKey?: FieldKey) => {
		const newFilter: Field | undefined = sortedAvailableFiltersFields.find(
			({ key }) => key === fieldKey,
		);

		if (newFilter) {
			setTmpFiltersKeys((oldFiltersKeys) => [...oldFiltersKeys, newFilter.key]);
		}
	};

	// Clear filter by FieldKey
	const onClearFilter = (key: FieldKey) => {
		const attributes = {
			filterFieldKey: key,
		};
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'clearFilter', attributes);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'clearFilter',
			attributes,
		});

		if (some(activePermanentFiltersFields, { key })) {
			experience.headerView.viewFilter.start();
			clearFieldOrNumericFilter(
				key,
				false,
				() => {
					experience.headerView.viewFilter.success();
				},
				(error?: Error) => {
					experience.headerView.viewFilter.failure(error);
				},
			);
		}

		setTmpFiltersKeys((prevTmpFiltersKeys) =>
			prevTmpFiltersKeys.filter((filterKey) => filterKey !== key),
		);
	};

	const onClearFilters = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'clearAllFilters');
		sendPendoTrackEvent({
			actionSubjectAndAction: 'button clicked',
			actionSubjectId: 'clearAllFilters',
		});

		experience.headerView.viewFilter.start();
		clearAllFilters(
			() => {
				experience.headerView.viewFilter.success();
			},
			(error?: Error) => {
				experience.headerView.viewFilter.failure(error);
			},
		);
		setTmpFiltersKeys([]);
	};

	return (
		<Stack xcss={filterContainerStyles}>
			<Box paddingBlock="space.200">
				<Box paddingBlockEnd="space.150">
					{isDisabled ? (
						<Box xcss={sectionStyles}>{formatMessage(messages.filtersDisabledText)}</Box>
					) : (
						formatMessage(helpMessage)
					)}
				</Box>
				{allActivePermanentFiltersFields.map((field, idx, { length }) => (
					<Flex justifyContent="space-between" alignItems="center" key={field.key} gap="space.050">
						<FilterComponent
							field={field}
							isLastFilter={length - 1 === idx}
							isDisabled={isDisabled}
							defaultOpen={
								ff('polaris.list-view-header-cell-options-redesign_0gogh') && !isDisabled
									? tmpFieldKey === field.key
									: undefined
							}
						/>
						{!isDisabled && (
							<IconButton
								id="pendo.config-filters.clear-filter-button"
								appearance="subtle"
								onClick={() => onClearFilter(field.key)}
								label={formatMessage(messages.closeButton)}
								icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
							/>
						)}
					</Flex>
				))}
				{!isDisabled && (
					<Flex gap="space.050" xcss={actionsStyles}>
						<AddFilterComponent
							onAddFilter={onAddFilter}
							options={difference(sortedAvailableFiltersFields, allActivePermanentFiltersFields)}
						/>
						{allActivePermanentFiltersFields.length > 0 && (
							<ClearFilterComponent onClear={onClearFilters} />
						)}
					</Flex>
				)}
			</Box>
			{fg('polaris_just-for-you') && (
				<>
					<Box xcss={dividerStyles} />
					<TemporaryFiltersSection
						allFieldsMap={allFieldsMap}
						sortedAvailableFiltersFields={sortedAvailableFiltersFields}
						allActivePermanentFiltersFields={allActivePermanentFiltersFields}
					/>
				</>
			)}
		</Stack>
	);
};

type TemporaryFiltersSectionProps = {
	allFieldsMap: Record<string, Field>;
	sortedAvailableFiltersFields: Field[];
	allActivePermanentFiltersFields: Field[];
};

const TemporaryFiltersSection = ({
	allFieldsMap,
	sortedAvailableFiltersFields,
	allActivePermanentFiltersFields,
}: TemporaryFiltersSectionProps) => {
	const { formatMessage } = useIntl();
	const { clearAllTemporaryFilters, clearFieldOrNumericFilter } = useViewActions();
	const activeTemporaryFiltersFields = useActiveTemporaryFiltersFields();
	const canManagePermanentFilters = useCanManagePermanentFilters();

	const { tmpFieldKey, tmpFiltersKeys, setTmpFiltersKeys } =
		useTmpFiltersKeysState(!canManagePermanentFilters);

	useEffect(() => {
		const updatedTmpTemporaryFiltersKeys = tmpFiltersKeys.filter(
			(tmpFilterKey) =>
				!allActivePermanentFiltersFields.some(
					(activeFilterField) => activeFilterField.key === tmpFilterKey,
				),
		);
		if (updatedTmpTemporaryFiltersKeys.length !== tmpFiltersKeys.length) {
			setTmpFiltersKeys(updatedTmpTemporaryFiltersKeys);
		}
	}, [allActivePermanentFiltersFields, setTmpFiltersKeys, tmpFiltersKeys]);

	// Combine active and temp filters fields
	const allActiveTemporaryFiltersFields = useMemo(() => {
		const tmpFiltersSet = new Set(tmpFiltersKeys);

		return [
			...activeTemporaryFiltersFields.filter((field) => !tmpFiltersSet.has(field.key)),
			...tmpFiltersKeys.map((fieldKey) => allFieldsMap[fieldKey]),
		];
	}, [tmpFiltersKeys, activeTemporaryFiltersFields, allFieldsMap]);

	const onAddFilter = useCallback(
		(fieldKey?: FieldKey) => {
			const newFilter: Field | undefined = sortedAvailableFiltersFields.find(
				({ key }) => key === fieldKey,
			);

			if (newFilter) {
				setTmpFiltersKeys((oldFiltersKeys) => [...oldFiltersKeys, newFilter.key]);
			}
		},
		[setTmpFiltersKeys, sortedAvailableFiltersFields],
	);

	const onClearFilters = useCallback(() => {
		clearAllTemporaryFilters();
		setTmpFiltersKeys([]);
	}, [clearAllTemporaryFilters, setTmpFiltersKeys]);

	const onClearFilter = useCallback(
		(key: FieldKey) => {
			clearFieldOrNumericFilter(key, true);
			setTmpFiltersKeys((prevTmpFiltersKeys) =>
				prevTmpFiltersKeys.filter((filterKey) => filterKey !== key),
			);
		},
		[clearFieldOrNumericFilter, setTmpFiltersKeys],
	);

	return (
		<Box paddingBlock="space.200">
			<Stack space="space.100">
				<Heading size="xsmall">{formatMessage(messages.justForYouHeading)}</Heading>
				<Box paddingBlockEnd="space.050">
					<Text size="small" color="color.text.subtlest">
						{formatMessage(messages.description)}
					</Text>
				</Box>
				<Box>
					{allActiveTemporaryFiltersFields.map((field, idx, { length }) => (
						<Flex
							justifyContent="space-between"
							alignItems="center"
							key={field.key}
							gap="space.050"
						>
							<FilterComponent
								field={field}
								isLastFilter={length - 1 === idx}
								isTemporary
								defaultOpen={
									ff('polaris.list-view-header-cell-options-redesign_0gogh')
										? tmpFieldKey === field.key
										: undefined
								}
							/>
							<IconButton
								id="pendo.config-filters.clear-filter-button"
								appearance="subtle"
								onClick={() => onClearFilter(field.key)}
								label={formatMessage(messages.clearButton)}
								icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
							/>
						</Flex>
					))}
					<Flex gap="space.050" xcss={actionsStyles}>
						<AddFilterComponent
							onAddFilter={onAddFilter}
							options={difference(
								sortedAvailableFiltersFields,
								allActivePermanentFiltersFields,
								allActiveTemporaryFiltersFields,
							)}
						/>
						{allActiveTemporaryFiltersFields.length > 0 && (
							<ClearFilterComponent onClear={onClearFilters} />
						)}
					</Flex>
				</Box>
			</Stack>
		</Box>
	);
};

export const PolarisQuickSearch = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const qsFilter = useQuickSearchFilter();
	const { updateQuickSearchFilter } = useViewActions();

	const [isRightSidebarOpen] = useIsRightSidebarOpen();
	const isOpenInSidebar = useIsIssueOpenInSidebar();

	const onUpdateFilterValue = (stringValue: string) => {
		fireUIAnalytics(createAnalyticsEvent({}), 'quickSearchFilter updated');

		updateQuickSearchFilter([{ stringValue }]);
	};

	const qsFilterVal = head(qsFilter.values.map(({ stringValue }) => stringValue)) ?? '';

	return isRightSidebarOpen || isOpenInSidebar ? null : (
		<Box xcss={quickSearchFilterComponentWrapperStyles}>
			<QuickSearchFilterComponent value={qsFilterVal} onChange={onUpdateFilterValue} />
		</Box>
	);
};

const actionsStyles = xcss({
	paddingBlock: 'space.050',
});

const filterContainerStyles = xcss({
	paddingInline: 'space.200',
});

const quickSearchFilterComponentWrapperStyles = xcss({
	width: '240px',
	marginInlineStart: 'auto',
});

const sectionStyles = xcss({
	padding: 'space.150',
	backgroundColor: 'color.background.information',
});

const dividerStyles = xcss({
	height: '1px',
	marginBlock: 'space.100',
	background: token('color.border'),
	flexShrink: 0,
});
