import React from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort,
	useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort,
} from '@atlassian/jira-polaris-common/src/controllers/idea-ranking';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	useCanEditIssues,
	useCanManageViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import messages from './messages';

const ConvertToViewRankItem = () => {
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const setSortModeOfCurrentViewToViewRankAndAddCurrentSort =
		useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort();

	const { formatMessage } = useIntl();
	return (
		<DropdownItem
			testId="polaris-ideas.ui.right-sidebar.sort.advanced.field-sort.convert-to-view-rank"
			description={
				<Box xcss={descriptionStyles}>{formatMessage(messages.convertToViewRankDescription)}</Box>
			}
			isDisabled={ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews}
			onClick={setSortModeOfCurrentViewToViewRankAndAddCurrentSort}
		>
			{formatMessage(messages.convertToViewRank)}
		</DropdownItem>
	);
};

const ConvertToProjectRankItem = () => {
	const [canEditIssues] = useCanEditIssues();
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort =
		useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort();

	const { formatMessage } = useIntl();
	return (
		<DropdownItem
			testId="polaris-ideas.ui.right-sidebar.sort.advanced.field-sort.convert-to-project-rank"
			description={
				<Box xcss={descriptionStyles}>
					{formatMessage(messages.convertToProjectRankDescription)}
				</Box>
			}
			isDisabled={
				!(
					(ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews) &&
					canEditIssues
				)
			}
			onClick={setSortModeOfCurrentViewToProjectRankAndCommitCurrentSort}
		>
			{formatMessage(messages.convertToProjectRank)}
		</DropdownItem>
	);
};

export const FieldSortContent = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlockEnd="space.300">
			<Box paddingBlockStart="space.075" paddingBlockEnd="space.150">
				<Text>{formatMessage(messages.fieldSortDescription)}</Text>
			</Box>
			<Flex alignItems="center">
				<DropdownMenu
					testId="polaris-ideas.ui.right-sidebar.sort.advanced.field-sort.change-to-ranked-mode-trigger"
					trigger={formatMessage(messages.changeToRankedModeTrigger)}
				>
					<Box xcss={dropdownMenuWrapperStyles}>
						<DropdownItemGroup>
							<ConvertToViewRankItem />
							<ConvertToProjectRankItem />
						</DropdownItemGroup>
					</Box>
				</DropdownMenu>
				<Box paddingInlineStart="space.050">
					<Tooltip content={formatMessage(messages.changeToRankedModeTooltip)}>
						<EditorInfoIcon label="info" />
					</Tooltip>
				</Box>
			</Flex>
		</Box>
	);
};

const descriptionStyles = xcss({
	whiteSpace: 'normal',
});

const dropdownMenuWrapperStyles = xcss({
	maxWidth: '320px',
});
