import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.apps.apps.intercom.title',
		defaultMessage: 'Intercom',
		description: 'Title of the polaris intercom app',
	},
	description: {
		id: 'polaris-common.apps.apps.intercom.description',
		defaultMessage:
			'Add messages and conversation to ideas to keep track of important problems to fix',
		description: 'Description of the polaris intercom app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.intercom.unfurl-dialog-title',
		defaultMessage: 'Connect to Intercom',
		description: 'Title of the Intercom try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.intercom.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to a Intercom conversation or message here',
		description: 'Placeholder of the Intercom try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.intercom.unfurl-dialog-success',
		defaultMessage:
			'This is what it looks like when you add a Intercom conversation or message to an idea.',
		description: 'Success message of Intercom the try-it-now unfurl dialog',
	},
});
