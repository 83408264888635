// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md
// brandfolder asset id = gg9cjt4rjv6mhqjhkm7h7r9
// brandfolder url = https://brandfolder.com/atlassian-brand-creative-library/atlassian-public-asset-library/#!asset/gg9cjt4rjv6mhqjhkm7h7r9
import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/file-cabinet-open-darkmode.svg';
import srcLight from '../../assets/file-cabinet-open.svg';

const Illustration = createIllustration({
	srcLight,
	srcDark,
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 203, 214];
export { srcLight, srcDark, viewBox };
