import React from 'react';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	useNumberValue,
	useSelectedIssue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { NumberField } from '@atlassian/jira-polaris-common/src/ui/fields/number/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useUpdateFieldValue } from '../utils';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
	isIdeaView?: boolean;
};

export const Number = ({ isIdeaView, fieldKey }: Props) => {
	const field = useField(fieldKey);
	const { formatMessage } = useIntl();
	const localIssueId = useSelectedIssue();
	const { value, initialized } = useNumberValue(fieldKey, localIssueId || '');
	const fieldValueDecorations = useDecorationsForField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (field === undefined) {
		return null;
	}

	if (!initialized) {
		return <Skeleton height="16px" />;
	}

	return (
		<NumberField
			value={value}
			isIdeaView={isIdeaView}
			decorations={fieldValueDecorations}
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			fieldType={field.type}
			onUpdate={updateFieldValue}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
