import { traverse } from '@atlaskit/adf-utils/traverse';
import { convertWikiToAdfAsync } from '@atlassian/jira-platform-convert-wiki-to-adf';
import { fireIAErrorAnalytics } from '@atlassian/jira-ui-modifications-analytics';
import { ExternalImageValidationError } from '../../errors';
import type { CommonFieldPropertyValidators } from '../../types';
import type { FieldInternalMetadata } from '../../types/field-internal-metadata';
import type { OptionsVisibility } from '../../types/options-visibility';
import { isNotEmptyString } from '../is-not-empty-string';
import { isObject } from '../is-object';

export const getCommonFieldValidators = (): CommonFieldPropertyValidators => ({
	fieldName: (value: unknown) => typeof value === 'string',
	isVisible: (value: unknown) => typeof value === 'boolean',
	description: validateDescription,
	isReadOnly: (value: unknown, { isReadOnlyBySystem }: FieldInternalMetadata) =>
		typeof value === 'boolean' && (value || (!value && !isReadOnlyBySystem)),
	isRequired: (value: unknown, { isRequiredBySystem }: FieldInternalMetadata) =>
		typeof value === 'boolean' && (value || (!value && !isRequiredBySystem)),
});

export const validateDescription = async (value: unknown) => {
	if (typeof value !== 'string') {
		return false;
	}

	if (value === '') {
		return true;
	}

	// turn into ADF
	try {
		const adfValue = await convertWikiToAdfAsync(value);
		if (!adfValue) {
			return false;
		}
		// find image nodes that contain external URLs.
		traverse(adfValue, {
			media: (node) => {
				if (node.attrs?.type === 'external' && node.attrs.url !== undefined) {
					// ADF cannot contain external urls due to the possibility of data egress.
					throw new ExternalImageValidationError('Cannot contain external images');
				}
			},
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		// ExternalImageValidationError will be thrown if there is an external image
		if (!(err instanceof ExternalImageValidationError)) {
			// TODO: use valid value of issueType instead of undefined in fireIAErrorAnalytics call. There is a task for it - https://ecosystem-platform.atlassian.net/browse/UIM-1651;
			fireIAErrorAnalytics({
				error: err,
				id: 'descriptionWikiValidationException',
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
				viewType: undefined as any,
				attributes: {
					errorCause: err?.cause,
					message: err.message,
				},
			});
		}
		return false;
	}
	return true;
};

export const validateSetOptionsVisibilityRule = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optionsVisibility: any,
): optionsVisibility is OptionsVisibility =>
	isObject(optionsVisibility) &&
	typeof optionsVisibility.isVisible === 'boolean' &&
	Array.isArray(optionsVisibility.options) &&
	optionsVisibility.options.every(isNotEmptyString);
