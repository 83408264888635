import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import keyBy from 'lodash/keyBy';
import Button from '@atlaskit/button';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import { DecoratedRating } from '@atlassian/jira-polaris-common/src/ui/decoration/rating/index.tsx';
import { CheckboxField } from '@atlassian/jira-polaris-common/src/ui/fields/checkbox';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { Draggable, useDroppableEvents } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { type ExtendedOption, EMPTY_VALUE_ID } from '../../../../common/utils/board';
import {
	useRestrictedOptions,
	useExtendedOptionsInNaturalOrder,
} from '../../../view-content/common/utils/field-visibility-options';
import {
	getNewFieldValueFilterOnHide,
	getNewFieldValueFilterOnShow,
} from '../../../view-content/idea-board/column/header/utils';
import hiddenIcon from './assets/hidden.svg';
import visibleIcon from './assets/visible.svg';
import { dateFieldValueConfig } from './dates';
import { EmptyFieldValue, emptyFieldValueConfig } from './empty';
import { externalReferenceFieldValueConfig } from './external-reference';
import { externalReferencePropertyFieldValueConfig } from './external-reference-property';
import { labelsFieldValueConfig } from './labels';
import { messages } from './messages';
import { numberFieldValueConfig } from './numbers';
import { optionsFieldValueConfig } from './options';
import { projectFieldValueConfig } from './project';
import { reactionsFieldValueConfig } from './reactions';
import { statusFieldValueConfig } from './status';
import type { FieldValueConfig } from './types';
import { userFieldValueConfig } from './users';

const fieldValues: FieldValueConfig = {
	config: {
		[FIELD_TYPES.SINGLE_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.MULTI_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.JSW_MULTI_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.STATUS]: statusFieldValueConfig,
		[FIELD_TYPES.ASSIGNEE]: userFieldValueConfig,
		[FIELD_TYPES.CREATOR]: userFieldValueConfig,
		[FIELD_TYPES.REPORTER]: userFieldValueConfig,
		[FIELD_TYPES.PEOPLE]: userFieldValueConfig,
		[FIELD_TYPES.LABELS]: labelsFieldValueConfig,
		[FIELD_TYPES.CUSTOM_LABELS]: labelsFieldValueConfig,
		[FIELD_TYPES.NUMBER]: numberFieldValueConfig,
		[FIELD_TYPES.FORMULA]: numberFieldValueConfig,
		[FIELD_TYPES.SLIDER]: numberFieldValueConfig,
		[FIELD_TYPES.RATING]: numberFieldValueConfig,
		[FIELD_TYPES.CHECKBOX]: numberFieldValueConfig,
		[FIELD_TYPES.INSIGHTS]: numberFieldValueConfig,
		[FIELD_TYPES.DATE]: dateFieldValueConfig,
		[FIELD_TYPES.ATLAS_GOAL]: externalReferenceFieldValueConfig,
		[FIELD_TYPES.ATLAS_PROJECT]: externalReferenceFieldValueConfig,
		[FIELD_TYPES.ATLAS_PROJECT_STATUS]: externalReferencePropertyFieldValueConfig,
		[FIELD_TYPES.REACTIONS]: reactionsFieldValueConfig,
		[FIELD_TYPES.PROJECT]: projectFieldValueConfig,
	},
	default: emptyFieldValueConfig,
};

type FieldValueContentProps = {
	field: Field;
	fieldKey: FieldKey;
	groupIdentity?: string;
};

export const FieldValueContent = memo<FieldValueContentProps>(
	({ field, fieldKey, groupIdentity }: FieldValueContentProps) => {
		const decorations = useDecorationsForField(fieldKey);

		const { Component, EmptyComponent } =
			(field.type && fieldValues.config[field.type]) || fieldValues.default;

		if (groupIdentity === undefined) {
			const SafeEmptyComponent = EmptyComponent || EmptyFieldValue;
			return <SafeEmptyComponent fieldKey={fieldKey} />;
		}
		if (field.type === FIELD_TYPES.CHECKBOX) {
			return <CheckboxField value={Number(groupIdentity)} />;
		}
		if (field.type === FIELD_TYPES.RATING) {
			return <DecoratedRating value={Number(groupIdentity)} decorations={decorations} />;
		}
		return <Component fieldKey={fieldKey} groupIdentity={groupIdentity} />;
	},
);

export const Values = ({
	field,
	options,
	setOptions,
	isDisabledDnD = false,
}: {
	field: Field;
	options: ExtendedOption<unknown>[];
	setOptions: (options: GroupValue[]) => void;
	isDisabledDnD?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const restrictions = useRestrictedOptions(field);
	const restrictionsMap = useMemo(
		() => keyBy(restrictions, ({ groupIdentity }) => groupIdentity),
		[restrictions],
	);

	const sortedGroupOptions = useSortedGroupOptions(field.key);
	const statusOptions = useExtendedOptionsInNaturalOrder(field);
	const { updateFieldFilter } = useViewActions();
	const fieldFilter = useFieldFilter(field.key);
	const [localOptions, setLocalOptions] = useState(options);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		setLocalOptions(options);
	}, [options]);

	const onShowGroupIdentity = (groupIdentity?: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
			'showInView',
			{
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'icon clicked',
			actionSubjectId: 'showInView',
			attributes: { fieldValueId: groupIdentity || '' },
		});
		updateFieldFilter(
			getNewFieldValueFilterOnShow(
				field,
				sortedGroupOptions,
				statusOptions,
				fieldFilter,
				groupIdentity,
			),
		);
	};

	const onHideGroupIdentity = (groupIdentity?: string) => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
			'hideInView',
			{
				fieldValueId: groupIdentity,
			},
		);
		sendPendoTrackEvent({
			actionSubjectAndAction: 'icon clicked',
			actionSubjectId: 'hideInView',
			attributes: { fieldValueId: groupIdentity || '' },
		});
		updateFieldFilter(
			getNewFieldValueFilterOnHide(
				field,
				sortedGroupOptions,
				statusOptions,
				fieldFilter,
				groupIdentity,
			),
		);
	};

	const { isHideable } =
		(field?.type !== undefined ? fieldValues.config[field.type] : undefined) || fieldValues.default;

	const onSort = useCallback(
		({ srcId, dstId, edge }: { srcId: number; dstId: number; edge: Edge }) => {
			let dstAdj = srcId < dstId && edge === 'top' ? -1 : 0;
			dstAdj = srcId > dstId && edge === 'bottom' ? 1 : dstAdj;

			const newOrder: ExtendedOption<unknown>[] = [...localOptions];
			const [removed] = newOrder.splice(srcId, 1);
			newOrder.splice(dstId + dstAdj, 0, removed);

			const newSortedGroupOptions = newOrder.map((option: ExtendedOption<unknown>) => ({
				id: option.groupIdentity ?? EMPTY_VALUE_ID,
			}));

			setLocalOptions(newOrder);
			setOptions(newSortedGroupOptions);
		},
		[localOptions, setOptions],
	);

	useDroppableEvents({
		onSort,
	});

	const isDragDisabled = isDisabledDnD || localOptions.length === 1;

	if (!field) {
		return <ValuesContainer />;
	}

	return (
		<ValuesContainer>
			{localOptions.map((option, index) => (
				<Draggable
					isDragDisabled={isDragDisabled}
					key={`${field.key}-${index}`}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					id={index as number}
				>
					<ValueContainer
						data-component-selector="value-container-87Cp"
						key={`${field.key}-${index}`}
						isHidden={
							// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
							!restrictionsMap[option.groupIdentity]
						}
					>
						<DragHandle
							isDragDisabled={isDragDisabled}
							// @ts-expect-error - TS2322 - Type '{ children: Element; isDragDisabled: boolean; label: string; type: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
							label="view-dragHandle"
							type="drag-handle"
						>
							<DragHandlerIcon label="" />
						</DragHandle>
						<FieldValueContent
							fieldKey={field.key}
							field={field}
							groupIdentity={option.groupIdentity}
						/>
						<ValueContainerSpacer />
						{isHideable ? (
							<div data-component-selector="visibility-image-container-3K4q">
								<Tooltip
									content={
										restrictionsMap[
											// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
											option.groupIdentity
										]
											? formatMessage(messages.filterGroupOption)
											: formatMessage(messages.showGroupOption)
									}
								>
									<Button
										id="pendo.group-identity.button"
										onClick={() =>
											restrictionsMap[
												// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
												option.groupIdentity
											]
												? onHideGroupIdentity(option.groupIdentity)
												: onShowGroupIdentity(option.groupIdentity)
										}
										appearance="subtle-link"
										iconAfter={
											<>
												<HiddenImage data-component-selector="hidden-image-sE58" src={hiddenIcon} />
												<VisibleImage
													data-component-selector="visible-image-bS93"
													src={visibleIcon}
												/>
											</>
										}
									/>
								</Tooltip>
							</div>
						) : null}
					</ValueContainer>
				</Draggable>
			))}
		</ValuesContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HiddenImage = styled.img({
	width: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisibleImage = styled.img({
	width: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValuesContainer = styled.div({
	maxHeight: 'calc(100vh - var(--topNavigationHeight) - 325px)',
	overflowY: 'auto',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '38px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueContainer = styled.div<{ isHidden: boolean }>({
	display: 'flex',
	alignItems: 'center',
	height: '32px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '30px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="visible-image-bS93"]': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="hidden-image-sE58"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ isHidden }) => (isHidden ? 'block' : 'none'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ isHidden }) =>
			isHidden
				? 'invert(69%) sepia(12%) saturate(360%) hue-rotate(178deg) brightness(91%) contrast(85%)'
				: 'none',
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20A),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="visible-image-bS93"]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			display: ({ isHidden }) => (isHidden ? 'none' : 'block'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			filter: ({ isHidden }) =>
				isHidden
					? 'none'
					: 'invert(69%) sepia(12%) saturate(360%) hue-rotate(178deg) brightness(91%) contrast(85%)',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="visibility-image-container-3K4q"]': {
			'&:hover': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'[data-component-selector="hidden-image-sE58"]': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
					display: ({ isHidden }) => (isHidden ? 'none' : 'block'),
					filter: 'none',
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'[data-component-selector="visible-image-bS93"]': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
					display: ({ isHidden }) => (isHidden ? 'block' : 'none'),
				},
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueContainerSpacer = styled.div({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandle = styled.span<{ isDragDisabled: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isDragDisabled }) => (isDragDisabled ? 'hidden!important' : 'hidden'),
	cursor: 'grab',
	width: '18px',
	minWidth: '18px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="value-container-87Cp"]:hover &': {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		visibility: (props: any) => (props.isDragDisabled ? 'hidden' : 'visible'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		lineHeight: 'unset',
	},
});
