import { useMemo } from 'react';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldMapping } from '../../issue/selectors/fields-hooks';
import { getAllStatusesById, getAllStatusesAsList, getAreStatusesInitialized } from './statuses';
import { createWorkflowHook } from './utils';

export const useAllStatusesById = createWorkflowHook(getAllStatusesById);
export const useAllStatusesAsList = createWorkflowHook(getAllStatusesAsList);

export const useAreStatusesInitialized = createWorkflowHook(getAreStatusesInitialized);

export const useSortedStatusesAsList = (field?: Field) => {
	const allStatuses = useAllStatusesAsList();
	const fieldMapping = useFieldMapping(field?.key);

	return useMemo(() => {
		if (fieldMapping === undefined || field?.type !== FIELD_TYPES.STATUS) {
			return allStatuses;
		}
		const retVal = [...allStatuses];

		retVal.sort((a, b) => fieldMapping.comparator(a, b, 'ASC'));

		return retVal;
	}, [allStatuses, field, fieldMapping]);
};
