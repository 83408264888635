import ExtensibleError from '@atlassian/jira-extensible-error';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

export class IssueViewFetchError extends ExtensibleError {
	statusCode: number | undefined;

	constructor(
		private error: Error,
		public endpoint: string,
		public traceId?: string,
		private isRelayError?: boolean,
		public userExperience?: string,
	) {
		super(error.message);

		this.endpoint = endpoint;
		this.error = error;
		this.message = error.message;

		if (error instanceof FetchError) {
			if (error.traceId != null) {
				this.traceId = error.traceId;
			}

			this.statusCode = error.statusCode;
		}

		if (error instanceof IssueViewFetchError) {
			this.error = error.error;
			this.statusCode = error.statusCode;
			this.traceId = error.traceId;
		}

		// set traceId from AGG, only if not set already
		if (!this.traceId && traceId && traceId !== '') {
			this.traceId = traceId;
		}
	}

	public getIsRelayError() {
		return this.isRelayError === true;
	}
}
