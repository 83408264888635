import differenceBy from 'lodash/differenceBy';

type SelectFieldValue = {
	id: string;
};

export const createLabelsGroups = (
	selected: SelectFieldValue[],
	all: SelectFieldValue[],
	isMulti: boolean,
) => [
	...(isMulti
		? [
				{
					options: selected,
				},
			]
		: []),
	{
		options: differenceBy<SelectFieldValue, SelectFieldValue>(all, selected, 'id') ?? [],
	},
];
