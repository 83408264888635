import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareDefaultTitle: {
		id: 'polaris-ideas.idea-view.collaboration-controls.share-default-title',
		defaultMessage: 'Idea',
		description: 'Default title that gets shared when there is no issue summary.',
	},
	follow: {
		id: 'polaris-ideas.idea-view.collaboration-controls.follow',
		defaultMessage: 'Follow',
		description: 'Button label for the follow button',
	},
	delete: {
		id: 'polaris-ideas.idea-view.collaboration-controls.delete',
		defaultMessage: 'Delete',
		description: 'Button label to permanently delete the selected idea',
	},
});
