import React from 'react';
import type { User as UserType } from '@atlassian/jira-polaris-common/src/common/types/user';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	useSelectedIssueKey,
	useSelectedIssueUserValue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { UserField } from '@atlassian/jira-polaris-common/src/ui/fields/user';
import {
	useCanEditIssues,
	useCanModifyReporter,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useUpdateFieldValue } from '../utils';

type Props = {
	fieldKey: FieldKey;
	portalElement?: HTMLElement;
};

export const User = ({ fieldKey, portalElement }: Props) => {
	const value = useSelectedIssueUserValue(fieldKey);
	const field = useField(fieldKey);
	const ideaKey = useSelectedIssueKey();
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const [hasModifyReportPermission] = useCanModifyReporter();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (field === undefined) {
		return null;
	}

	let isEditable = canEditIssues && field.editable && !isIdeaArchived;
	if (fieldKey === REPORTER_FIELDKEY) {
		isEditable = hasModifyReportPermission && isEditable;
	}

	return (
		<UserField
			user={value}
			issueKey={ideaKey}
			fieldKey={fieldKey}
			isEditable={isEditable}
			portalElement={portalElement}
			onUpdate={(user?: UserType) => {
				const displayUser: UserFieldValue | undefined = user
					? {
							accountId: user.id,
							displayName: user.name || '',
							avatarUrls: {
								'16x16': user.avatarUrl ?? '',
								'24x24': user.avatarUrl ?? '',
								'32x32': user.avatarUrl ?? '',
								'48x48': user.avatarUrl ?? '',
							},
						}
					: undefined;
				updateFieldValue(displayUser);
			}}
		/>
	);
};
