import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editLink: {
		id: 'polaris-common.fields.string.edit-link',
		defaultMessage: 'Edit link',
		description: 'Button label to edit link',
	},
	shortTextFieldTypeError: {
		id: 'polaris-common.fields.string.short-text-field-type-error',
		defaultMessage: 'Short text fields are limited to 255 characters',
		description: 'Error message for too long short text field values',
	},
	invalidLink: {
		id: 'polaris-common.fields.string.invalid-link',
		defaultMessage: 'Invalid link',
		description: 'Tooltip indicating that the provided link does not have a valid format',
	},
});
