import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	backToFieldValues: {
		id: 'polaris-ideas.idea-view.fields.back-to-field-values',
		defaultMessage: 'Back to field values',
		description: 'Label to navigate out of the field config mode back to the field value list',
	},
	backToIdeaDetails: {
		id: 'polaris-ideas.idea-view.fields.back-to-idea-details',
		defaultMessage: 'Back to idea details',
		description: 'Label to navigate out of the field config mode back to the idea view',
	},
	globalFieldWarning: {
		id: 'polaris-ideas.idea-view.fields.global-field-warning',
		defaultMessage: '<b>Global field: </b>Jira admins can edit this field on Global fields page',
		description: 'Warning message for when the user opens a global field from a project view',
	},
});
