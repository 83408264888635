import {
	performPostRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';

const getAttachmentsUploadServiceUrl = (issueKey: string) =>
	`/rest/api/2/attachment/upload/issue/${issueKey}`;

const getAttachmentsDeleteServiceUrl = (attachmentId: number | string) =>
	`/rest/api/2/attachment/${attachmentId}`;

export const addMediaToIssueAttachments = (issueKey: string, fileId: string): Promise<number> =>
	performPostRequest(getAttachmentsUploadServiceUrl(issueKey), {
		body: JSON.stringify({
			fileId,
		}),
	}).then(({ attachmentId }) => +attachmentId);

export const deleteIssueAttachment = (attachmentId: number) =>
	performDeleteRequest(getAttachmentsDeleteServiceUrl(attachmentId));
