import React, { type ReactNode } from 'react';
import type { JsonLd } from 'json-ld-types';
import { Provider as SmartCardProvider } from '@atlaskit/smart-card';
import {
	createContainer,
	createSubscriber,
	createStore,
	createHook,
} from '@atlassian/react-sweet-state';
import * as actions from './actions';
import { initSmartCardClient } from './actions/init-smart-card-client';
import type { State } from './types';

type Actions = typeof actions;

const initialState: State = {
	client: null,
	states: {},
};

const SmartCardStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisSmartCardStore',
});

const useSmartCard = createHook(SmartCardStore);

export const useSmartCardClient = () => {
	const [{ client }] = useSmartCard();
	return client;
};

export const useIsSmartCard = (url: string): boolean => {
	const [{ states }] = useSmartCard();
	return states[url]?.data?.name;
};

export const useSmartCardName = (url: string): string | null => {
	const [{ states }] = useSmartCard();
	return states[url]?.data?.name || null;
};

export const useSmartCardGrantedResponse = (url: string): JsonLd.Data.BaseData | null => {
	const [{ states }] = useSmartCard();
	const response = states[url];
	return response?.meta?.access === 'granted' ? response.data : null;
};

const useSmartCardActionsHook = createHook(SmartCardStore, {
	selector: null,
});

export const useSmartCardActions = () => {
	const [, storeActions] = useSmartCardActionsHook();
	return storeActions;
};

const InternalContainer = createContainer<State, Actions>(SmartCardStore, {
	onInit:
		() =>
		({ dispatch }) =>
			dispatch(initSmartCardClient()),
});

const InternalSubscriber = createSubscriber(SmartCardStore);

type SmartCardContainerProps = {
	children: ReactNode;
};

export const SmartCardContainer = ({ children }: SmartCardContainerProps) => (
	<InternalContainer>
		<InternalSubscriber>
			{({ client }) =>
				client !== null ? <SmartCardProvider client={client}>{children}</SmartCardProvider> : null
			}
		</InternalSubscriber>
	</InternalContainer>
);
