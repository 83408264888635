import type { Issue } from '@atlassian/jira-providers-issue/src/model';

// PREVIEW_DATA_FETCHED is no longer triggered. will be cleaned up in https://jdog.jira-dev.com/browse/BENTO-10072
export const PREVIEW_DATA_FETCHED = 'PREVIEW_DATA_FETCHED' as const;

export type PreviewDataFetchedAction = {
	type: typeof PREVIEW_DATA_FETCHED;
	payload: Issue;
};

export const previewDataFetched = (issue: Issue): PreviewDataFetchedAction => ({
	type: PREVIEW_DATA_FETCHED,
	payload: issue,
});

export type PreviewDataFetched = ReturnType<typeof previewDataFetched>;

export type FieldPreviewAction = PreviewDataFetched;
