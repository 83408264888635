import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { DynamicFieldResolver } from '../../types';

const FORMULA_TEMPLATE = 'const';

export const constantResolver: DynamicFieldResolver<number> = (formula: DynamicFieldFormula) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}
	return () => () => formula.parameters.value;
};
