import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'polaris-lib-control-sharing.error-screen.error-title',
		defaultMessage: 'We’re unable to publish views now',
		description: 'Error message title',
	},
	errorDescription: {
		id: 'polaris-lib-control-sharing.error-screen.error-description',
		defaultMessage: 'Try refreshing the page. If this keeps happening, <a>contact support.</a>',
		description: 'Error message description',
	},
});
