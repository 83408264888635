import React, { useEffect, useState } from 'react';
import { useIsExporting } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { CommentsField as CommonCommentsField } from '@atlassian/jira-polaris-common/src/ui/fields/comments';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { callWhenIdle } from '@atlassian/jira-polaris-lib-idle-callback';

type Props = {
	localIssueId: LocalIssueId;
};

export const CommentsFieldLazy = ({ localIssueId }: Props) => {
	const [render, setRender] = useState(false);
	const isExporting = useIsExporting();

	useEffect(() => callWhenIdle(() => setRender(true)), []);

	return render || isExporting ? (
		<CommonCommentsField appearance="list" localIssueId={localIssueId} />
	) : null;
};

export const CommentsField = CommentsFieldLazy;
