import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	mapOptions: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.map-options',
		defaultMessage: 'Map options',
		description: 'Header of the Map options section',
	},
	reviewOptions: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.review-options',
		defaultMessage: 'Review automatically mapped options',
		description: 'Header of the Review options section',
	},
	noAutomappedOptionsNonFinal: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.no-automapped-options-non-final',
		defaultMessage: 'No automatically mapped options available',
		description: 'Message when there are no automapped options',
	},
});
