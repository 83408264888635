import {
	createNotImplementedJiraGlobalFieldCopyValuesRemote,
	createJiraGlobalFieldCopyValuesRemote,
} from './copy-values';
import {
	createJiraGlobalFieldCrudRemote,
	createNotImplementedJiraGlobalFieldCrudRemote,
} from './crud';
import {
	createJiraGlobalFieldOptionRemote,
	createNotImplementedJiraGlobalFieldOptionRemote,
} from './option';
import type { GlobalFieldRemote } from './types';
import {
	createJiraGlobalFieldUsageRemote,
	createNotImplementedJiraGlobalFieldUsageRemote,
} from './usage';

export const createGlobalFieldRemote = (): GlobalFieldRemote => ({
	...createJiraGlobalFieldCrudRemote(),
	...createJiraGlobalFieldOptionRemote(),
	...createJiraGlobalFieldUsageRemote(),
	...createJiraGlobalFieldCopyValuesRemote(),
});

export const createSharingGlobalFieldRemote = (): GlobalFieldRemote => ({
	...createNotImplementedJiraGlobalFieldCrudRemote(),
	...createNotImplementedJiraGlobalFieldOptionRemote(),
	...createNotImplementedJiraGlobalFieldUsageRemote(),
	...createNotImplementedJiraGlobalFieldCopyValuesRemote(),
});
