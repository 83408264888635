import { createSelector } from 'reselect';
import isFunction from 'lodash/isFunction';
import some from 'lodash/some';
import {
	SUMMARY_FIELDKEY,
	KEY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { TextFilter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { FieldMapping } from '../utils/field-mapping/types';
import { getFieldMappingsAsList } from './fields';
import type { IssueFilterFunction } from './filters';
import { getSortedIssueIds } from './sort';

const createGetBucketFilterFunction = (stringValue: string) =>
	createSelector(
		getFieldMappingsAsList,
		(fieldMappings: FieldMapping<string>[]): IssueFilterFunction => {
			const textFilter: TextFilter = {
				type: 'TEXT',
				localId: 'text',
				values: [
					{
						stringValue,
					},
				],
			};

			const filterFunctions = fieldMappings.reduce<IssueFilterFunction[]>((filtersAcc, mapping) => {
				const filterValueFunc = mapping.getFilter(textFilter);

				if (
					(mapping.field?.key === SUMMARY_FIELDKEY || mapping.field?.key === KEY_FIELDKEY) &&
					!isFunction(filterValueFunc) &&
					filterValueFunc?.disjunctionIdentifier !== undefined
				) {
					const filterFunc: IssueFilterFunction = (state, props, id) => {
						const value = mapping.valueAccessor(state, props, id);
						return filterValueFunc.filterFunction(value, state, props, id);
					};
					filtersAcc.push(filterFunc);
				}

				return filtersAcc;
			}, []);

			return (state, props, id) => some(filterFunctions, (func) => func(state, props, id));
		},
	);

export const createGetBucketFilteredIssueIds = (stringValue: string) => {
	const getFilterFunction = createGetBucketFilterFunction(stringValue);

	return createSelector(
		getSortedIssueIds,
		getFilterFunction,
		(state, props) => ({ state, props }),
		(issueIds, filter, { state, props }) => issueIds.filter((id) => filter(state, props, id)),
	);
};
