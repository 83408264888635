import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import uniq from 'lodash/uniq';
import { Observable } from 'rxjs/Observable';
import type {
	AssociatedIssuesContextActions,
	LocalAssociatedIssueContextType,
} from '@atlassian/jira-associated-issues-context-service';
import type { IssueLink, NormalizedLinkedIssues } from '@atlassian/jira-issue-shared-types';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import { fetchIssueLinks } from '@atlassian/jira-issue-view-services/src/issue/issue-fetch-server';
import { fetchBulkIssues } from '@atlassian/jira-issue-view-services/src/issue/issues-bulk-fetch-server';
import { transformUser } from '@atlassian/jira-issue-view-services/src/issue/user-transformer';
import {
	fetchLinkedIssuesDataSuccess,
	fetchLinkedIssuesDataFailure,
	SAVE_LINKED_ISSUE_SUCCESS,
	type SaveLinkedIssueSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { optimisticLinksDataSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types';

export const asyncFieldsOld = ['assignee', 'project'] as const;
export const asyncFields = ['assignee', 'project', 'resolution'] as const;

export const LOG_LOCATION = 'issue.issuelinks.fetch.linked.issues.epic';

// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type. | TS7031 - Binding element 'key' implicitly has an 'any' type. | TS7031 - Binding element 'fields' implicitly has an 'any' type.
const transformIssueAsyncFields = ({ id, key, fields }) => ({
	id,
	assigneeUrl: fields.assignee && transformUser(fields.assignee).avatarUrl,
	assigneeDisplayName: fields.assignee && transformUser(fields.assignee).displayName,
	projectType: fields.project && fields.project.projectTypeKey,
	issueKey: key,
	isResolved: !!fields.resolution,
});

const fetchLinkedIssues = (
	baseUrl: BaseUrl,
	issueKeys: IssueKey[],
	fields: Readonly<string[]>,
): Observable<NormalizedLinkedIssues> =>
	fetchBulkIssues(baseUrl, issueKeys, fields).map((issues) =>
		issues.map(transformIssueAsyncFields).reduce<
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			Record<string, any>
		>((issuesMap, issue) => Object.assign(issuesMap, { [issue.issueKey]: issue }), {}),
	);

const saveLinkedIssueSuccessEpic =
	(associatedIssuesContextActions?: AssociatedIssuesContextActions) =>
	(action$: ActionsObservable<SaveLinkedIssueSuccess>, store: MiddlewareAPI<State>) =>
		action$.ofType(SAVE_LINKED_ISSUE_SUCCESS).switchMap(() => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);
			const optimisticLinks = optimisticLinksDataSelector(state);
			const uniqueLinkedIssueKeys = uniq(
				Object.values(optimisticLinks).map((link) => link.linkedIssueKey),
			);
			const savedOptimisticIds = Object.values(optimisticLinks)
				.filter((link: IssueLink) => link.isSaved)
				.map((link) => link.id);

			return Observable.zip(
				/**
				 * fetchIssueLinks returns:
				 * - issueLinks: The actual link itself (link type, direction, etc)
				 * - linkedIssues: The issue which is linked (issue id/key, field info etc)
				 */
				fetchIssueLinks(baseUrl, issueKey),
				/**
				 * fetchLinkedIssues returns, for each newly linked (optimistic) issue:
				 * - id
				 * - assigneeUrl
				 * - assigneeDisplayName
				 * - projectType
				 * - issueKey
				 * - isResolved
				 */
				fetchLinkedIssues(baseUrl, uniqueLinkedIssueKeys, asyncFields),
				(issueLinks, linkedIssues) => {
					// Update the associated issue context store with the isResolved state of the new linked issues
					const newLinkedIssuesContext: LocalAssociatedIssueContextType = {};
					Object.entries(linkedIssues).forEach(([key, newLinkedIssue]) => {
						newLinkedIssuesContext[key] = { isResolved: !!newLinkedIssue.isResolved };
					});
					associatedIssuesContextActions?.mergeLocalAssociatedIssuesContext(newLinkedIssuesContext);
					return fetchLinkedIssuesDataSuccess(
						issueLinks,
						linkedIssues,
						savedOptimisticIds,
						optimisticLinks,
					);
				},
			).catch((error: Error) => {
				trackOrLogClientError(
					LOG_LOCATION,
					'Error while fetching new issue links and their details',
					error,
				);
				return Observable.of(fetchLinkedIssuesDataFailure(savedOptimisticIds, error));
			});
		});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (associatedIssuesContextActions?: AssociatedIssuesContextActions) =>
	saveLinkedIssueSuccessEpic(associatedIssuesContextActions);
