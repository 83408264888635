import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startWatching: {
		id: 'polaris-ideas.idea-view.watchers.start-watching',
		defaultMessage: 'Start watching',
		description: 'Clicking in this element will make the user start watching the issue',
	},
	stopWatching: {
		id: 'polaris-ideas.idea-view.watchers.stop-watching',
		defaultMessage: 'Stop watching',
		description: 'Clicking in this element will make the user stop watching the issue',
	},
	tooltipWatchOptions: {
		id: 'polaris-ideas.idea-view.watchers.tooltip-watch-options',
		defaultMessage: 'Watch options',
		description: 'Tooltip for watch options',
	},
});
