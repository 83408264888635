import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { Stack, Text } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { FieldNameAndEmojiEditor } from '@atlassian/jira-polaris-component-field-configuration/src/common/ui/field-name-and-emoji-editor/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { eligibleCopyTargetFilterPredicate } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { useGlobalFields, useSourceField } from '../../../controllers';
import { OptionsMappingContextProvider } from '../../../controllers/options-mapping';
import { GlobalFieldOption } from './global-field-option';
import { GlobalFieldPrompt } from './global-field-prompt';
import messages from './messages';
import { OptionsMapping } from './options-mapping';
import { convertOptionValueMappingToPlainMap } from './options-mapping/options-mapping-modal';
import type { OptionsValueMapping } from './options-mapping/options-mapping-modal/field-options-mapper';
import type { FieldOptionData } from './options-mapping/options-mapping-modal/field-options-mapper/field-option-selector';

export const COPY_VALUES_FORM_SUBMIT_BUTTON_PORTAL_ID = 'jpd-copy-values-form-submit-button';

const useFieldOptionsValuesMapping = (
	fromSelectField: Field | undefined,
	toSelectField: Field | undefined,
) => {
	const [userProvidedOptionsValuesMapping, setUserProvidedOptionsValuesMapping] = useState<
		Record<string, string | undefined>
	>({});

	const onSetUserProvidedOptionValueMapping = useCallback(
		(mapping: Record<string, string | undefined>) => {
			setUserProvidedOptionsValuesMapping((userProvidedMapping) => ({
				...userProvidedMapping,
				...mapping,
			}));
		},
		[setUserProvidedOptionsValuesMapping],
	);

	useEffect(() => {
		setUserProvidedOptionsValuesMapping({});
	}, [fromSelectField, toSelectField, setUserProvidedOptionsValuesMapping]);

	return useMemo(() => {
		if (!fromSelectField || !toSelectField) {
			return {
				unmappedOptionsCount: 0,
				automappedOptionsCount: 0,
				mapping: {},
				setOptionValueMapping: onSetUserProvidedOptionValueMapping,
			};
		}

		const mapping: OptionsValueMapping = {};

		const toOptionsByValue: Record<string, FieldOptionData> = {};

		for (const toOption of toSelectField.options ?? []) {
			toOptionsByValue[toOption.value] = toOption;
		}

		let unmappedOptionsCount = 0;
		let automappedOptionsCount = 0;

		for (const fromOption of fromSelectField.options ?? []) {
			let mappedOption;

			if (userProvidedOptionsValuesMapping[fromOption.id]) {
				mappedOption = {
					optionId: userProvidedOptionsValuesMapping[fromOption.id],
					automapped: false,
				};
			} else if (toOptionsByValue[fromOption.value]?.id) {
				mappedOption = {
					optionId: toOptionsByValue[fromOption.value].id,
					automapped: true,
				};
			}

			mapping[fromOption.id] = mappedOption;

			if (!mappedOption) {
				unmappedOptionsCount++;
			} else if (mappedOption.automapped) {
				automappedOptionsCount++;
			}
		}

		return {
			mapping,
			unmappedOptionsCount,
			automappedOptionsCount,
			setOptionValueMapping: onSetUserProvidedOptionValueMapping,
		};
	}, [
		fromSelectField,
		toSelectField,
		onSetUserProvidedOptionValueMapping,
		userProvidedOptionsValuesMapping,
	]);
};

type CopyValuesFormProps = {
	onCopyValues: (
		fromFieldKey: FieldKey,
		toFieldKey: FieldKey,
		optionsValueMapping: Record<string, string | undefined>,
	) => Promise<void>;
};

export const CopyValuesForm = ({ onCopyValues }: CopyValuesFormProps) => {
	const { formatMessage } = useIntl();
	const field = useSourceField();
	const globalFields = useGlobalFields();

	const [targetFieldKey, setTargetFieldKey] = useState<string | undefined>();

	const selectedTargetField = useMemo(
		() => globalFields.find(({ key }) => key === targetFieldKey),
		[globalFields, targetFieldKey],
	);

	const { mapping, unmappedOptionsCount, automappedOptionsCount, setOptionValueMapping } =
		useFieldOptionsValuesMapping(field, selectedTargetField);

	const sourceFieldType = field?.type ?? undefined;

	const targetFieldOptions = useMemo(
		() =>
			globalFields
				.filter(({ type }) =>
					eligibleCopyTargetFilterPredicate({ from: sourceFieldType, to: type }),
				)
				.map((globalField) => ({
					value: globalField.key,
					field: globalField,
				})),
		[sourceFieldType, globalFields],
	);

	const handleCopyValuesClick = useCallback(() => {
		if (field && targetFieldKey) {
			onCopyValues(field.key, targetFieldKey, convertOptionValueMappingToPlainMap(mapping));
		}
	}, [onCopyValues, field, targetFieldKey, mapping]);

	const [portalIsMounted, setPortalIsMounted] = useState(false);

	useEffect(() => {
		setPortalIsMounted(true);
	}, [setPortalIsMounted]);

	if (!field) {
		return null;
	}

	const isSelectField =
		field.type === FIELD_TYPES.SINGLE_SELECT ||
		field.type === FIELD_TYPES.MULTI_SELECT ||
		field.type === FIELD_TYPES.JSW_MULTI_SELECT;

	const submitButtonPortalEl = document.getElementById(COPY_VALUES_FORM_SUBMIT_BUTTON_PORTAL_ID);

	return (
		<Stack space="space.300">
			<Text>{formatMessage(messages.explanation)}</Text>
			<Stack space="space.150">
				<Heading size="xsmall">{formatMessage(messages.copyFrom)}</Heading>
				<FieldNameAndEmojiEditor isReadOnly emojiId={field.emoji} value={field.label} />
			</Stack>
			<Stack space="space.150">
				<Heading size="xsmall">{formatMessage(messages.copyTo)}</Heading>
				{targetFieldOptions.length ? (
					<>
						<Select
							placeholder={formatMessage(messages.selectField)}
							options={targetFieldOptions}
							hideSelectedOptions
							onChange={(globalField) => {
								if (globalField) {
									setTargetFieldKey(globalField.value);
								}
							}}
							// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
							styles={{
								option: (styles) => ({
									...styles,
									// options' inline padding closes field preview when the cursor is near the edges
									// we render the padding inside GlobalFieldOption to fix this
									paddingBlock: '0',
								}),
							}}
							formatOptionLabel={(option) => <GlobalFieldOption field={option.field} />}
						/>
						{isSelectField && selectedTargetField && (
							<OptionsMappingContextProvider
								toField={selectedTargetField}
								optionsValueMapping={mapping}
								unmappedOptionsCount={unmappedOptionsCount}
								automappedOptionsCount={automappedOptionsCount}
								onSetOptionValueMapping={setOptionValueMapping}
							>
								<OptionsMapping />
							</OptionsMappingContextProvider>
						)}
					</>
				) : (
					<GlobalFieldPrompt fieldType={field.type} />
				)}
			</Stack>
			{portalIsMounted &&
				submitButtonPortalEl &&
				createPortal(
					<Button
						shouldFitContainer
						appearance="primary"
						onClick={handleCopyValuesClick}
						isDisabled={!targetFieldKey || !isFieldOptionsValueMappingValid(mapping)}
					>
						{formatMessage(messages.copyValues)}
					</Button>,
					submitButtonPortalEl,
				)}
		</Stack>
	);
};

const isFieldOptionsValueMappingValid = (fieldOptionsValueMapping: OptionsValueMapping = {}) =>
	Object.values(fieldOptionsValueMapping).every((value) => value?.optionId !== undefined);
