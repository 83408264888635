import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getAtlasGoalsByAri } from '../../../../services/atlas/goals';
import type { Props, State } from '../../types';

export const loadGoals =
	(withQueue = false, retry = 1): Action<State, Props> =>
	(
		{ setState, getState, dispatch },
		{ goalAris, onUpdate, apolloClient, initialized, isSingleIssueLoaded },
	) => {
		if (!initialized && !isSingleIssueLoaded) {
			return;
		}

		if ((goalAris === undefined || goalAris.length === 0) && !withQueue) {
			setState({
				goals: {
					...getState().goals,
					loaded: true,
				},
			});
			return;
		}

		if (retry > 3) {
			return;
		}

		if (getState().goals.isLoading === true) {
			!withQueue &&
				setState({
					goals: {
						...getState().goals,
						queue: Array.from(new Set(getState().goals.queue.concat(goalAris))),
					},
				});
			return;
		}

		if (!withQueue && goalAris.every((goalAri) => getState().goals.items[goalAri] !== undefined)) {
			return;
		}

		const goalArisToLoad = (withQueue ? getState().goals.queue : goalAris).filter(
			(goalAri) => getState().goals.items[goalAri] === undefined,
		);

		if (goalArisToLoad.length === 0) {
			return;
		}

		setState({
			goals: {
				...getState().goals,
				queue: [],
				items: getState().goals.items,
				isLoading: true,
				error: null,
			},
		});

		getAtlasGoalsByAri(apolloClient, goalArisToLoad)
			.then((response) => {
				const newGoals = response.reduce(
					(result, goal) =>
						Object.assign(result, {
							[goal.id]: {
								...goal,
							},
						}),
					goalArisToLoad.reduce(
						(result, ari) =>
							Object.assign(result, {
								[ari]: null,
							}),
						{},
					),
				);

				const newItems = {
					...getState().goals.items,
					...newGoals,
				};

				setState({
					goals: {
						queue: getState().goals.queue,
						items: newItems,
						isLoading: false,
						loaded: true,
						error: null,
					},
				});
				onUpdate(newItems);
				const { queue } = getState().goals;
				if (queue.length) {
					dispatch(loadGoals(true, retry + 1));
				}
			})
			.catch((error) => {
				let isAuthError = false;

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				error?.graphQLErrors?.forEach((graphQLError: any) => {
					if (graphQLError?.extensions?.statusCode === 401) {
						isAuthError = true;
					}
				});

				if (!isAuthError) {
					log.safeErrorWithoutCustomerData(
						'polaris.load-atlas-goals-by-ari',
						'Failed to load Atlas goals by ari',
						error,
					);
				}

				setState({
					goals: {
						...getState().goals,
						items: {
							...getState().goals.items,
							...goalArisToLoad.reduce(
								(result, ari) =>
									Object.assign(result, {
										[ari]: null,
									}),
								{},
							),
						},
						isLoading: false,
						loaded: true,
						error,
					},
				});
			});
	};
