import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	DeletePlayContribution,
	DeletePlayContributionVariables,
	DeletePlayContributionReturn,
} from './types';

const DELETE_PLAY_CONTRIBUTION_MUTATION = gqlTagPolaris`
mutation jira_polaris_DeletePlayContribution($id: ID!) {
  deletePolarisPlayContribution(id: $id) {
    success
    errors {
      message
    }
  }
}
`;

export const deletePlayContribution = (
	client: PolarisApolloClient,
	id: string,
): Promise<DeletePlayContributionReturn> => {
	const variables: DeletePlayContributionVariables = {
		id,
	};
	return client
		.mutate<DeletePlayContribution>({
			mutation: DELETE_PLAY_CONTRIBUTION_MUTATION,
			variables,
		})
		.then((result) => {
			if (result.errors) {
				throw new Error(
					`project-config.delete-play-contribution-error:${result.errors
						.map((e) => e.message)
						.join(', ')}`,
				);
			}

			if (!result.data?.deletePolarisPlayContribution?.success) {
				throw new Error(
					'project-config.delete-play-contribution-error: no error but not successful either',
				);
			}

			return {};
		});
};
