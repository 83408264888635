import React from 'react';
import { InsightsField as CommonInsightsField } from '@atlassian/jira-polaris-common/src/ui/fields/insights';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type Props = {
	localIssueId: LocalIssueId;
};

export const InsightsField = ({ localIssueId }: Props) => (
	<CommonInsightsField appearance="list" localIssueId={localIssueId} />
);
