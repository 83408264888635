// GALILEO-721
export const titleToSupportedQuickstartViewKey: Record<string, string> = {
	'Všechny nápady': 'ideas',
	'Produktový plán': 'roadmap',
	'Stav dodávání': 'delivery',
	'Dopad vs. úsilí': 'impact_vs_effort',
	'Časová osa produktu': 'timeline',
	'Alle idéer': 'ideas',
	Produktoversigt: 'roadmap',
	Leveringsoversigt: 'delivery',
	'Indvirkning vs. indsats': 'impact_vs_effort',
	'Tidslinje for produkt': 'timeline',
	'Alle Ideen': 'ideas',
	'Produkt-Roadmap': 'roadmap',
	Umsetzungsstatus: 'delivery',
	'Auswirkung im Vergleich zum Aufwand': 'impact_vs_effort',
	Produktzeitleiste: 'timeline',
	'All ideas': 'ideas',
	'Product roadmap': 'roadmap',
	'Delivery status': 'delivery',
	'Impact vs Effort': 'impact_vs_effort',
	'Product timeline': 'timeline',
	'Todas las ideas': 'ideas',
	'Hoja de ruta del producto': 'roadmap',
	'Estado de la entrega': 'delivery',
	'Impacto frente a esfuerzo': 'impact_vs_effort',
	'Cronograma del producto': 'timeline',
	'Kaikki ideat': 'ideas',
	'Tuotteen etenemissuunnitelma': 'roadmap',
	'Toimituksen tila': 'delivery',
	'Vaikutus vs. työpanos': 'impact_vs_effort',
	'Tuotteen aikajana': 'timeline',
	'Toutes les idées': 'ideas',
	'Feuille de route produit': 'roadmap',
	'État de la livraison': 'delivery',
	'Impact et effort': 'impact_vs_effort',
	'Chronologie produit': 'timeline',
	'Minden ötlet': 'ideas',
	Termékútvonalterv: 'roadmap',
	'Teljesítés állapota': 'delivery',
	'Hatás és szükséges erőfeszítések': 'impact_vs_effort',
	Termékidővonal: 'timeline',
	'Tutte le idee': 'ideas',
	'Roadmap del prodotto': 'roadmap',
	'Stato consegna': 'delivery',
	'Impatto vs impegno': 'impact_vs_effort',
	'Timeline del prodotto': 'timeline',
	すべてのアイデア: 'ideas',
	製品ロードマップ: 'roadmap',
	デリバリーのステータス: 'delivery',
	影響と労力の比較: 'impact_vs_effort',
	製品タイムライン: 'timeline',
	'모든 아이디어': 'ideas',
	'제품 로드맵': 'roadmap',
	'제공 상태': 'delivery',
	'영향 및 노력 비교': 'impact_vs_effort',
	'제품 타임라인': 'timeline',
	'Alle ideer': 'ideas',
	Produktveikart: 'roadmap',
	Leveringsstatus: 'delivery',
	'Innvirkning kontra innsats': 'impact_vs_effort',
	Produkttidslinje: 'timeline',
	'Alle ideeën': 'ideas',
	Productroadmap: 'roadmap',
	'Impact versus inspanning': 'impact_vs_effort',
	Producttijdlijn: 'timeline',
	'Wszystkie pomysły': 'ideas',
	'Harmonogram produktu': 'roadmap',
	'Status dostarczania': 'delivery',
	'Wpływ a nakład pracy': 'impact_vs_effort',
	'Oś czasu produktu': 'timeline',
	'Todas as ideias': 'ideas',
	'Roteiro do produto': 'roadmap',
	'Status da entrega': 'delivery',
	'Impacto versus esforço': 'impact_vs_effort',
	'Cronograma do produto': 'timeline',
	'Все идеи': 'ideas',
	'Дорожная карта продукта': 'roadmap',
	'Статус поставки': 'delivery',
	'Влияние и трудозатраты': 'impact_vs_effort',
	'Хронология продукта': 'timeline',
	'Alla idéer': 'ideas',
	Produktöversikt: 'roadmap',
	Leveransstatus: 'delivery',
	'Påverkan kontra ansträngning': 'impact_vs_effort',
	'Tüm fikirler': 'ideas',
	'Ürün yol haritası': 'roadmap',
	'Teslimat durumu': 'delivery',
	'Çaba - Etki Karşılaştırması': 'impact_vs_effort',
	'Ürün zaman çizelgesi': 'timeline',
	所有想法: 'ideas',
	产品路线图: 'roadmap',
	交付状态: 'delivery',
	影响与所需工作量: 'impact_vs_effort',
	产品时间线: 'timeline',
	所有構想: 'ideas',
	產品藍圖: 'roadmap',
	交付狀態: 'delivery',
	影響與投入: 'impact_vs_effort',
	產品時間軸: 'timeline',
};
