import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';

export const createStateWithoutExternLinkedIssue = (state: State, issueLinkId: string): State => {
	const { issueLinks, deliveryTickets } = state;
	const newIssueLinks = issueLinks.filter((issueLink) => issueLink.id !== issueLinkId);
	const deliveryTicketRemoved = deliveryTickets.find(
		(ticket) => ticket.issueLinkId === issueLinkId,
	);
	const newDeliveryTickets = deliveryTickets
		.filter((ticket) => ticket.issueLinkId !== issueLinkId)
		.map((data) =>
			data.parentKey === deliveryTicketRemoved?.issueKey && data.isTopLevelTicket
				? {
						...data,
						parentKey: undefined,
					}
				: data,
		);

	return {
		...state,
		issueLinks: newIssueLinks,
		deliveryTickets: newDeliveryTickets,
	};
};

export const unlinkIssue =
	(issueLinkId: string) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ issuesRemote, onDeliveryIssuesUpdated }: Props,
	) =>
		issuesRemote.deleteIssueLink({ issueLinkId }).then(() => {
			setState(createStateWithoutExternLinkedIssue(getState(), issueLinkId));
			onDeliveryIssuesUpdated();
		});

export const removeIssueFromIssueLinks =
	(issueLinkId: string) =>
	async ({ getState, setState }: StoreActionApi<State>) =>
		setState(createStateWithoutExternLinkedIssue(getState(), issueLinkId));
