import React from 'react';
import Button from '@atlaskit/button';
import type { TriggerProps } from '@atlaskit/popup';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type ButtonTriggerProps = {
	triggerProps: TriggerProps;
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	label: string;
};

export const ButtonTrigger = ({ triggerProps, isOpen, setIsOpen, label }: ButtonTriggerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Button
			{...triggerProps}
			testId="polaris-lib-create-view-popup.ui.triggers.button-trigger"
			onClick={() => {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'toggled',
						actionSubject: 'dropdown',
					}),
					'createView',
					{
						dropdownLocation: 'noViewsScreen',
					},
				);
				setIsOpen(!isOpen);
			}}
			appearance="primary"
			isSelected={isOpen}
		>
			{label}
		</Button>
	);
};
