import { useCallback } from 'react';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIssueActions } from '../main';

export const useUpdateDynamicField = () => {
	const { updateDynamicFieldSelector } = useIssueActions();

	return useCallback(
		(field?: Field) => {
			if (field !== undefined) {
				updateDynamicFieldSelector(field);
			}
		},
		[updateDynamicFieldSelector],
	);
};
