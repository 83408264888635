import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	columnsLabel: {
		id: 'polaris-ideas.view-controls.config-group-by.columns-label',
		defaultMessage: 'Columns',
		description: '',
	},
	groupByLabel: {
		id: 'polaris-ideas.view-controls.config-group-by.group-by-label',
		defaultMessage: 'Group by (optional)',
		description: '',
	},
	notSelectedColumn: {
		id: 'polaris-ideas.view-controls.config-group-by.not-selected-column',
		defaultMessage: 'Not selected',
		description: '',
	},
	clearSelectionButton: {
		id: 'polaris-ideas.view-controls.config-group-by.clear-selection-button',
		defaultMessage: 'Clear selection',
		description: '',
	},
	editButton: {
		id: 'polaris-ideas.view-controls.config-group-by.edit-button',
		defaultMessage: 'Edit',
		description: '',
	},
	editField: {
		id: 'polaris-ideas.view-controls.config-group-by.edit-field',
		defaultMessage: 'Edit field',
		description: 'Button to edit the field',
	},
	fieldSettings: {
		id: 'polaris-ideas.view-controls.config-group-by.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button to open settings for the currently open field',
	},
	columnsHelpText: {
		id: 'polaris-ideas.view-controls.config-group-by.columns-help-text',
		defaultMessage:
			'Select a field. The different values of that field will be the columns of your board.',
		description: '',
	},
	groupByHelpText: {
		id: 'polaris-ideas.view-controls.config-group-by.group-by-help-text',
		defaultMessage:
			'Select a field. The different values of that field will be swimlanes in your board.',
		description: '',
	},
	groupByHelpTextForListView: {
		id: 'polaris-ideas.view-controls.config-group-by.group-by-help-text-for-list-view',
		defaultMessage:
			'Select a field. The different values of that field will be swimlanes in your list.',
		description: '',
	},
	hideEmptyGroupsToggle: {
		id: 'polaris-ideas.view-controls.config-group-by.hide-empty-groups-toggle',
		defaultMessage: 'Hide empty groups',
		description: '',
	},
	hideEmptyColumnsToggle: {
		id: 'polaris-ideas.view-controls.config-group-by.hide-empty-columns-toggle',
		defaultMessage: 'Hide empty columns',
		description: '',
	},
	groupByDisabledText: {
		id: 'polaris-ideas.view-controls.config-group-by.group-by-disabled-text',
		defaultMessage: 'You need the "can edit" permission to make changes to this view.',
		description: "Message displayed when user doesn't have manage view permission",
	},
});
