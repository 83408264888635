import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dontCopy: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.field-option-selector.dont-copy',
		defaultMessage: "Don't copy",
		description: 'Label for the "Don\'t copy" option',
	},
	selectOption: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.field-option-selector.select-option',
		defaultMessage: 'Select option',
		description: 'Placeholder for Select option input',
	},
	noOptionsMessageNoPermissions: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.field-option-selector.no-options-message-no-permissions',
		defaultMessage: 'No options. Ask your Jira admin to create an option for this global field',
		description: 'Message when there are no options to map to',
	},
	noOptionsMessage: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.field-option-selector.no-options-message',
		defaultMessage: 'No options',
		description: 'Message for Jira admins when there are no options to map to',
	},
	editGlobalFieldOptions: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.field-options-mapper.field-option-selector.edit-global-field-options',
		defaultMessage: 'Edit global field options',
		description: 'Edit Global Field Options link',
	},
});
