import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	deliver: {
		id: 'polaris-ideas.idea-view.sections.deliver',
		defaultMessage: 'Delivery',
		description: 'Tab caption for delivery content in Jira Product Discovery idea view.',
	},
	insights: {
		id: 'polaris-ideas.idea-view.sections.insights',
		defaultMessage: 'Insights',
		description: 'Tab caption for insights content in Jira Product Discovery idea view.',
	},
	comments: {
		id: 'polaris-ideas.idea-view.sections.comments',
		defaultMessage: 'Comments',
		description: 'Tab caption for comments content in Jira Product Discovery idea view.',
	},
	history: {
		id: 'polaris-ideas.idea-view.sections.history',
		defaultMessage: 'History',
		description: 'Tab caption for issue history in Jira Product Discovery idea view.',
	},
});
