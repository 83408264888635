import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	opCreatedWithLastDays: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.num-data-points.content.op-created-with-last-days',
		defaultMessage: 'insights created within the last days',
		description: 'Numeric of last $ days operator',
	},
	opGreaterThan: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.num-data-points.content.op-greater-than',
		defaultMessage: 'number of insights greater than',
		description: 'Numeric > operator',
	},
	opLessThan: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.num-data-points.content.op-less-than',
		defaultMessage: 'number of insights less than',
		description: 'Numeric < operator',
	},
	opBetween: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.num-data-points.content.op-between',
		defaultMessage: 'number of insights between',
		description: 'Numeric range between two values operator',
	},
	opEmpty: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.num-data-points.content.op-empty',
		defaultMessage: 'without insights',
		description: 'Numeric empty value operator',
	},
	opAll: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.num-data-points.content.op-all',
		defaultMessage: 'No filter',
		description: 'Indicator that no filter is configured',
	},
});
