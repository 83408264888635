import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	weightType: {
		id: 'polaris-component-field-configuration.common.rollup.field-help-text.weight-type',
		defaultMessage: 'Weight',
		description: 'Weight formula type',
	},
	weightSelectTypeHelpText: {
		id: 'polaris-component-field-configuration.common.rollup.field-help-text.weight-select-type-help-text',
		defaultMessage: 'The weight of selected options',
		description: 'Help message for the weighted single select options',
	},
	weightMultiSelectTypeHelpText: {
		id: 'polaris-component-field-configuration.common.rollup.field-help-text.weight-multi-select-type-help-text',
		defaultMessage: 'The weight of selected options',
		description: 'Help message for the weighted multi select options',
	},
});
