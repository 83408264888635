import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks';
import { useLinkedDeliveryIssuesExist } from '../../../../controllers/issue/selectors/properties/hooks';
import { DeliveryDataRestrictedMarker } from '../../../field-info-marker/delivery-data-restricted';
import { LinkedStatus } from '../../../linked-issue-status';
import { EmptyField } from '../empty';
import { FieldLabel, FieldValue } from '../field/styled';

type Props = {
	issueId: string;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
};

export const LinkedIssuesStatus = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty,
}: Props) => {
	const label = useFieldLabel(fieldKey);
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(issueId);

	if (hideEmpty && !deliveryIssuesExist) {
		return null;
	}

	return (
		<>
			{!isCompact && (
				<FieldLabel>
					<DeliveryDataRestrictedMarker fieldKey={fieldKey} marginRight={6} isInline />
					{label}
				</FieldLabel>
			)}
			<FieldValue>
				{deliveryIssuesExist ? (
					<Box xcss={containerStyles}>
						<LinkedStatus localIssueId={issueId} />
					</Box>
				) : (
					isSummary && <EmptyField />
				)}
			</FieldValue>
		</>
	);
};

const containerStyles = xcss({
	paddingLeft: 'space.050',
});
