import { addGoals } from './add-goals';
import { addProjects } from './add-projects';
import { loadGoals } from './load-goals';
import { loadProjects } from './load-projects';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadGoals,
	loadProjects,
	addGoals,
	addProjects,
};
