import React, { type ReactNode, useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { User } from '@atlassian/jira-polaris-common/src/common/types/user';
import {
	useIsIssueArchived,
	usePeopleTransformed,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { UsersField as CommonField } from '@atlassian/jira-polaris-common/src/ui/fields/users';
import type {
	UserValue,
	LocalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal';
import { PopperElement } from '../../../utils/popper-element';
import { useFieldUpdate, useIsEditable } from '../../utils';

type UsersFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
	interactive: boolean;
};

const UsersFieldEditViewContainer = ({ children }: { children?: ReactNode }) => (
	<PopperElement>
		<Box xcss={inlineEditableUsersContainerStyles}>{children}</Box>
	</PopperElement>
);

export const UsersField = ({ localIssueId, fieldKey, interactive }: UsersFieldProps) => {
	const people = usePeopleTransformed(fieldKey, localIssueId);
	const portalElement = useListPortalElement();
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	const onUpdateForCustomField = useCallback(
		(users: User[]) => {
			const displayUsers: UserValue[] = users.map((user) => ({
				accountId: user.id,
				displayName: user.name || '',
				avatarUrls: {
					'48x48': user.avatarUrl || '',
				},
			}));
			onUpdate(displayUsers);
		},
		[onUpdate],
	);

	return (
		<CommonField
			interactive={interactive}
			fieldKey={fieldKey}
			isEditable={isEditable && !isIdeaArchived}
			onUpdate={onUpdateForCustomField}
			value={people}
			portalElement={portalElement}
			editViewContainer={UsersFieldEditViewContainer}
		/>
	);
};

const inlineEditableUsersContainerStyles = xcss({
	width: '100%',
});
