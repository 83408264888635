import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const OptionWeightTypeRatingTooltipImage = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="276"
		height="128"
		viewBox="0 0 276 128"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_201_22761)">
			<rect width="276" height="128" rx="3" fill="white" />
			<path
				d="M7.33333 27.5C7.79357 27.5 8.16667 27.1269 8.16667 26.6667C8.16667 26.2065 7.79357 25.8334 7.33333 25.8334C6.8731 25.8334 6.5 26.2065 6.5 26.6667C6.5 27.1269 6.8731 27.5 7.33333 27.5Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 27.5C11.1269 27.5 11.5 27.1269 11.5 26.6667C11.5 26.2065 11.1269 25.8334 10.6667 25.8334C10.2064 25.8334 9.83333 26.2065 9.83333 26.6667C9.83333 27.1269 10.2064 27.5 10.6667 27.5Z"
				fill="#44546F"
			/>
			<path
				d="M7.33333 34.1667C7.79357 34.1667 8.16667 33.7936 8.16667 33.3334C8.16667 32.8731 7.79357 32.5 7.33333 32.5C6.8731 32.5 6.5 32.8731 6.5 33.3334C6.5 33.7936 6.8731 34.1667 7.33333 34.1667Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 34.1667C11.1269 34.1667 11.5 33.7936 11.5 33.3334C11.5 32.8731 11.1269 32.5 10.6667 32.5C10.2064 32.5 9.83333 32.8731 9.83333 33.3334C9.83333 33.7936 10.2064 34.1667 10.6667 34.1667Z"
				fill="#44546F"
			/>
			<path
				d="M7.33333 30.8334C7.79357 30.8334 8.16667 30.4603 8.16667 30C8.16667 29.5398 7.79357 29.1667 7.33333 29.1667C6.8731 29.1667 6.5 29.5398 6.5 30C6.5 30.4603 6.8731 30.8334 7.33333 30.8334Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 30.8334C11.1269 30.8334 11.5 30.4603 11.5 30C11.5 29.5398 11.1269 29.1667 10.6667 29.1667C10.2064 29.1667 9.83333 29.5398 9.83333 30C9.83333 30.4603 10.2064 30.8334 10.6667 30.8334Z"
				fill="#44546F"
			/>
			<rect x="17" y="19" width="116" height="22" rx="3" fill="#DCFFF1" />
			<rect x="25" y="27" width="100" height="6" rx="3" fill="#7EE2B8" />
			<path
				d="M7.33333 61.4999C7.79357 61.4999 8.16667 61.1268 8.16667 60.6666C8.16667 60.2063 7.79357 59.8333 7.33333 59.8333C6.8731 59.8333 6.5 60.2063 6.5 60.6666C6.5 61.1268 6.8731 61.4999 7.33333 61.4999Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 61.4999C11.1269 61.4999 11.5 61.1268 11.5 60.6666C11.5 60.2063 11.1269 59.8333 10.6667 59.8333C10.2064 59.8333 9.83333 60.2063 9.83333 60.6666C9.83333 61.1268 10.2064 61.4999 10.6667 61.4999Z"
				fill="#44546F"
			/>
			<path
				d="M7.33333 68.1666C7.79357 68.1666 8.16667 67.7935 8.16667 67.3333C8.16667 66.873 7.79357 66.4999 7.33333 66.4999C6.8731 66.4999 6.5 66.873 6.5 67.3333C6.5 67.7935 6.8731 68.1666 7.33333 68.1666Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 68.1666C11.1269 68.1666 11.5 67.7935 11.5 67.3333C11.5 66.873 11.1269 66.4999 10.6667 66.4999C10.2064 66.4999 9.83333 66.873 9.83333 67.3333C9.83333 67.7935 10.2064 68.1666 10.6667 68.1666Z"
				fill="#44546F"
			/>
			<path
				d="M7.33333 64.8333C7.79357 64.8333 8.16667 64.4602 8.16667 63.9999C8.16667 63.5397 7.79357 63.1666 7.33333 63.1666C6.8731 63.1666 6.5 63.5397 6.5 63.9999C6.5 64.4602 6.8731 64.8333 7.33333 64.8333Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 64.8333C11.1269 64.8333 11.5 64.4602 11.5 63.9999C11.5 63.5397 11.1269 63.1666 10.6667 63.1666C10.2064 63.1666 9.83333 63.5397 9.83333 63.9999C9.83333 64.4602 10.2064 64.8333 10.6667 64.8333Z"
				fill="#44546F"
			/>
			<rect x="17" y="53" width="82" height="22" rx="3" fill="#E9F2FF" />
			<rect x="25" y="61" width="66" height="6" rx="3" fill="#85B8FF" />
			<path
				d="M7.33333 95.4999C7.79357 95.4999 8.16667 95.1268 8.16667 94.6666C8.16667 94.2063 7.79357 93.8333 7.33333 93.8333C6.8731 93.8333 6.5 94.2063 6.5 94.6666C6.5 95.1268 6.8731 95.4999 7.33333 95.4999Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 95.4999C11.1269 95.4999 11.5 95.1268 11.5 94.6666C11.5 94.2063 11.1269 93.8333 10.6667 93.8333C10.2064 93.8333 9.83333 94.2063 9.83333 94.6666C9.83333 95.1268 10.2064 95.4999 10.6667 95.4999Z"
				fill="#44546F"
			/>
			<path
				d="M7.33333 102.167C7.79357 102.167 8.16667 101.793 8.16667 101.333C8.16667 100.873 7.79357 100.5 7.33333 100.5C6.8731 100.5 6.5 100.873 6.5 101.333C6.5 101.793 6.8731 102.167 7.33333 102.167Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 102.167C11.1269 102.167 11.5 101.793 11.5 101.333C11.5 100.873 11.1269 100.5 10.6667 100.5C10.2064 100.5 9.83333 100.873 9.83333 101.333C9.83333 101.793 10.2064 102.167 10.6667 102.167Z"
				fill="#44546F"
			/>
			<path
				d="M7.33333 98.8333C7.79357 98.8333 8.16667 98.4602 8.16667 97.9999C8.16667 97.5397 7.79357 97.1666 7.33333 97.1666C6.8731 97.1666 6.5 97.5397 6.5 97.9999C6.5 98.4602 6.8731 98.8333 7.33333 98.8333Z"
				fill="#44546F"
			/>
			<path
				d="M10.6667 98.8333C11.1269 98.8333 11.5 98.4602 11.5 97.9999C11.5 97.5397 11.1269 97.1666 10.6667 97.1666C10.2064 97.1666 9.83333 97.5397 9.83333 97.9999C9.83333 98.4602 10.2064 98.8333 10.6667 98.8333Z"
				fill="#44546F"
			/>
			<rect x="17" y="87" width="101" height="22" rx="3" fill="#F3F0FF" />
			<rect x="25" y="95" width="85" height="6" rx="3" fill="#B8ACF6" />
			<rect width="12" height="12" transform="translate(194 24)" fill="white" fillOpacity="0.01" />
			<rect x="194" y="24" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(210 24)" fill="white" fillOpacity="0.01" />
			<rect x="210" y="24" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(226 24)" fill="white" fillOpacity="0.01" />
			<rect x="226" y="24" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(242 24)" fill="white" fillOpacity="0.01" />
			<rect x="242" y="24" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(258 24)" fill="white" fillOpacity="0.01" />
			<rect x="258" y="24" width="12" height="12" rx="6" fill="#579DFF" />
			<g clipPath="url(#clip1_201_22761)">
				<path
					d="M173.744 32.188C173.65 32.4151 173.389 32.5217 173.162 32.4275C172.936 32.3329 172.829 32.0729 172.923 31.8462L174.975 26.2738C175.069 26.0471 175.33 25.94 175.556 26.0342C175.782 26.1289 175.89 26.3889 175.795 26.6155L173.744 32.188Z"
					fill="#44546F"
				/>
				<path
					d="M178.188 31.8462C178.283 32.0729 178.176 32.3329 177.949 32.4275C177.722 32.5217 177.462 32.4151 177.368 32.188L175.316 26.6155C175.222 26.3889 175.329 26.1289 175.556 26.0342C175.783 25.94 176.043 26.0471 176.137 26.2738L178.188 31.8462ZM182.633 32.188C182.539 32.4151 182.278 32.5217 182.052 32.4275C181.825 32.3329 181.718 32.0729 181.812 31.8462L183.864 26.2733C183.958 26.0471 184.218 25.94 184.445 26.0342C184.672 26.1289 184.779 26.3889 184.684 26.6155L182.633 32.188Z"
					fill="#44546F"
				/>
				<path
					d="M187.077 31.8462C187.172 32.0729 187.065 32.3329 186.838 32.4275C186.612 32.5217 186.351 32.4151 186.256 32.188L184.205 26.6155C184.111 26.3889 184.218 26.1289 184.445 26.0342C184.672 25.94 184.932 26.0471 185.026 26.2738L187.077 31.8462Z"
					fill="#44546F"
				/>
				<path
					d="M178.603 31.7778H172.508C172.228 31.7778 172 32.005 172 32.2858C172 33.4076 173.592 34.3174 175.556 34.3174C177.519 34.3174 179.111 33.4076 179.111 32.2858C179.111 32.005 178.884 31.7778 178.603 31.7778ZM182.667 37.1112H177.333C177.088 37.1112 176.889 36.9125 176.889 36.6668C176.889 35.685 178.282 34.889 180 34.889C181.718 34.889 183.111 35.685 183.111 36.6668C183.111 36.9125 182.912 37.1112 182.667 37.1112ZM187.492 31.7778H181.397C181.116 31.7778 180.889 32.005 180.889 32.2858C180.889 33.4076 182.48 34.3174 184.444 34.3174C186.408 34.3174 188 33.4076 188 32.2858C188 32.005 187.773 31.7778 187.492 31.7778Z"
					fill="#44546F"
				/>
				<path
					d="M180.445 23.3332C180.445 23.0879 180.246 22.8888 180 22.8888C179.755 22.8888 179.556 23.0879 179.556 23.3332V35.3332C179.556 35.579 179.755 35.7777 180 35.7777C180.246 35.7777 180.445 35.579 180.445 35.3332V23.3332Z"
					fill="#44546F"
				/>
				<path
					d="M179.999 24.6666C180.49 24.6666 180.888 24.2686 180.888 23.7777C180.888 23.2868 180.49 22.8888 179.999 22.8888C179.508 22.8888 179.11 23.2868 179.11 23.7777C179.11 24.2686 179.508 24.6666 179.999 24.6666Z"
					fill="#44546F"
				/>
				<path
					d="M175.555 27.3333C176.046 27.3333 176.444 26.9353 176.444 26.4444C176.444 25.9535 176.046 25.5555 175.555 25.5555C175.064 25.5555 174.666 25.9535 174.666 26.4444C174.666 26.9353 175.064 27.3333 175.555 27.3333Z"
					fill="#44546F"
				/>
				<path
					d="M184.445 27.3333C184.935 27.3333 185.333 26.9353 185.333 26.4444C185.333 25.9535 184.935 25.5555 184.445 25.5555C183.954 25.5555 183.556 25.9535 183.556 26.4444C183.556 26.9353 183.954 27.3333 184.445 27.3333Z"
					fill="#44546F"
				/>
				<path
					d="M184.445 26.4444C184.445 26.9351 184.445 26.4444 180 26.4444C175.556 26.4444 175.556 26.9351 175.556 26.4444C175.556 25.9537 176.889 24.6666 180 24.6666C183.111 24.6666 184.445 25.9537 184.445 26.4444Z"
					fill="#44546F"
				/>
			</g>
			<rect width="12" height="12" transform="translate(194 58)" fill="white" fillOpacity="0.01" />
			<rect x="194" y="58" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(210 58)" fill="white" fillOpacity="0.01" />
			<rect x="210" y="58" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(226 58)" fill="white" fillOpacity="0.01" />
			<rect x="226" y="58" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(242 58)" fill="white" fillOpacity="0.01" />
			<rect x="246" y="62" width="4" height="4" rx="2" fill="#091E42" fillOpacity="0.06" />
			<rect width="12" height="12" transform="translate(258 58)" fill="white" fillOpacity="0.01" />
			<rect x="262" y="62" width="4" height="4" rx="2" fill="#091E42" fillOpacity="0.06" />
			<g clipPath="url(#clip2_201_22761)">
				<path
					d="M173.744 66.188C173.65 66.4151 173.389 66.5217 173.162 66.4275C172.936 66.3329 172.829 66.0729 172.923 65.8462L174.975 60.2738C175.069 60.0471 175.33 59.94 175.556 60.0342C175.782 60.1289 175.89 60.3889 175.795 60.6155L173.744 66.188Z"
					fill="#44546F"
				/>
				<path
					d="M178.188 65.8462C178.283 66.0729 178.176 66.3329 177.949 66.4275C177.722 66.5217 177.462 66.4151 177.368 66.188L175.316 60.6155C175.222 60.3889 175.329 60.1289 175.556 60.0342C175.783 59.94 176.043 60.0471 176.137 60.2738L178.188 65.8462ZM182.633 66.188C182.539 66.4151 182.278 66.5217 182.052 66.4275C181.825 66.3329 181.718 66.0729 181.812 65.8462L183.864 60.2733C183.958 60.0471 184.218 59.94 184.445 60.0342C184.672 60.1289 184.779 60.3889 184.684 60.6155L182.633 66.188Z"
					fill="#44546F"
				/>
				<path
					d="M187.077 65.8462C187.172 66.0729 187.065 66.3329 186.838 66.4275C186.612 66.5217 186.351 66.4151 186.256 66.188L184.205 60.6155C184.111 60.3889 184.218 60.1289 184.445 60.0342C184.672 59.94 184.932 60.0471 185.026 60.2738L187.077 65.8462Z"
					fill="#44546F"
				/>
				<path
					d="M178.603 65.7778H172.508C172.228 65.7778 172 66.005 172 66.2858C172 67.4076 173.592 68.3174 175.556 68.3174C177.519 68.3174 179.111 67.4076 179.111 66.2858C179.111 66.005 178.884 65.7778 178.603 65.7778ZM182.667 71.1112H177.333C177.088 71.1112 176.889 70.9125 176.889 70.6668C176.889 69.685 178.282 68.889 180 68.889C181.718 68.889 183.111 69.685 183.111 70.6668C183.111 70.9125 182.912 71.1112 182.667 71.1112ZM187.492 65.7778H181.397C181.116 65.7778 180.889 66.005 180.889 66.2858C180.889 67.4076 182.48 68.3174 184.444 68.3174C186.408 68.3174 188 67.4076 188 66.2858C188 66.005 187.773 65.7778 187.492 65.7778Z"
					fill="#44546F"
				/>
				<path
					d="M180.445 57.3334C180.445 57.088 180.246 56.8889 180 56.8889C179.755 56.8889 179.556 57.088 179.556 57.3334V69.3334C179.556 69.5792 179.755 69.7778 180 69.7778C180.246 69.7778 180.445 69.5792 180.445 69.3334V57.3334Z"
					fill="#44546F"
				/>
				<path
					d="M179.999 58.6667C180.49 58.6667 180.888 58.2687 180.888 57.7778C180.888 57.2869 180.49 56.8889 179.999 56.8889C179.508 56.8889 179.11 57.2869 179.11 57.7778C179.11 58.2687 179.508 58.6667 179.999 58.6667Z"
					fill="#44546F"
				/>
				<path
					d="M175.555 61.3332C176.046 61.3332 176.444 60.9352 176.444 60.4443C176.444 59.9534 176.046 59.5554 175.555 59.5554C175.064 59.5554 174.666 59.9534 174.666 60.4443C174.666 60.9352 175.064 61.3332 175.555 61.3332Z"
					fill="#44546F"
				/>
				<path
					d="M184.445 61.3332C184.935 61.3332 185.333 60.9352 185.333 60.4443C185.333 59.9534 184.935 59.5554 184.445 59.5554C183.954 59.5554 183.556 59.9534 183.556 60.4443C183.556 60.9352 183.954 61.3332 184.445 61.3332Z"
					fill="#44546F"
				/>
				<path
					d="M184.445 60.4445C184.445 60.9352 184.445 60.4445 180 60.4445C175.556 60.4445 175.556 60.9352 175.556 60.4445C175.556 59.9539 176.889 58.6667 180 58.6667C183.111 58.6667 184.445 59.9539 184.445 60.4445Z"
					fill="#44546F"
				/>
			</g>
			<rect width="12" height="12" transform="translate(194 92)" fill="white" fillOpacity="0.01" />
			<rect x="194" y="92" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(210 92)" fill="white" fillOpacity="0.01" />
			<rect x="210" y="92" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(226 92)" fill="white" fillOpacity="0.01" />
			<rect x="226" y="92" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(242 92)" fill="white" fillOpacity="0.01" />
			<rect x="242" y="92" width="12" height="12" rx="6" fill="#579DFF" />
			<rect width="12" height="12" transform="translate(258 92)" fill="white" fillOpacity="0.01" />
			<rect x="262" y="96" width="4" height="4" rx="2" fill="#091E42" fillOpacity="0.06" />
			<g clipPath="url(#clip3_201_22761)">
				<path
					d="M173.744 100.188C173.65 100.415 173.389 100.522 173.162 100.428C172.936 100.333 172.829 100.073 172.923 99.8462L174.975 94.2738C175.069 94.0471 175.33 93.94 175.556 94.0342C175.782 94.1289 175.89 94.3889 175.795 94.6155L173.744 100.188Z"
					fill="#44546F"
				/>
				<path
					d="M178.188 99.8462C178.283 100.073 178.176 100.333 177.949 100.428C177.722 100.522 177.462 100.415 177.368 100.188L175.316 94.6155C175.222 94.3889 175.329 94.1289 175.556 94.0342C175.783 93.94 176.043 94.0471 176.137 94.2738L178.188 99.8462ZM182.633 100.188C182.539 100.415 182.278 100.522 182.052 100.428C181.825 100.333 181.718 100.073 181.812 99.8462L183.864 94.2733C183.958 94.0471 184.218 93.94 184.445 94.0342C184.672 94.1289 184.779 94.3889 184.684 94.6155L182.633 100.188Z"
					fill="#44546F"
				/>
				<path
					d="M187.077 99.8462C187.172 100.073 187.065 100.333 186.838 100.428C186.612 100.522 186.351 100.415 186.256 100.188L184.205 94.6155C184.111 94.3889 184.218 94.1289 184.445 94.0342C184.672 93.94 184.932 94.0471 185.026 94.2738L187.077 99.8462Z"
					fill="#44546F"
				/>
				<path
					d="M178.603 99.7778H172.508C172.228 99.7778 172 100.005 172 100.286C172 101.408 173.592 102.317 175.556 102.317C177.519 102.317 179.111 101.408 179.111 100.286C179.111 100.005 178.884 99.7778 178.603 99.7778ZM182.667 105.111H177.333C177.088 105.111 176.889 104.912 176.889 104.667C176.889 103.685 178.282 102.889 180 102.889C181.718 102.889 183.111 103.685 183.111 104.667C183.111 104.912 182.912 105.111 182.667 105.111ZM187.492 99.7778H181.397C181.116 99.7778 180.889 100.005 180.889 100.286C180.889 101.408 182.48 102.317 184.444 102.317C186.408 102.317 188 101.408 188 100.286C188 100.005 187.773 99.7778 187.492 99.7778Z"
					fill="#44546F"
				/>
				<path
					d="M180.445 91.3334C180.445 91.088 180.246 90.8889 180 90.8889C179.755 90.8889 179.556 91.088 179.556 91.3334V103.333C179.556 103.579 179.755 103.778 180 103.778C180.246 103.778 180.445 103.579 180.445 103.333V91.3334Z"
					fill="#44546F"
				/>
				<path
					d="M179.999 92.6667C180.49 92.6667 180.888 92.2687 180.888 91.7778C180.888 91.2869 180.49 90.8889 179.999 90.8889C179.508 90.8889 179.11 91.2869 179.11 91.7778C179.11 92.2687 179.508 92.6667 179.999 92.6667Z"
					fill="#44546F"
				/>
				<path
					d="M175.555 95.3332C176.046 95.3332 176.444 94.9352 176.444 94.4443C176.444 93.9534 176.046 93.5554 175.555 93.5554C175.064 93.5554 174.666 93.9534 174.666 94.4443C174.666 94.9352 175.064 95.3332 175.555 95.3332Z"
					fill="#44546F"
				/>
				<path
					d="M184.445 95.3332C184.935 95.3332 185.333 94.9352 185.333 94.4443C185.333 93.9534 184.935 93.5554 184.445 93.5554C183.954 93.5554 183.556 93.9534 183.556 94.4443C183.556 94.9352 183.954 95.3332 184.445 95.3332Z"
					fill="#44546F"
				/>
				<path
					d="M184.445 94.4445C184.445 94.9352 184.445 94.4445 180 94.4445C175.556 94.4445 175.556 94.9352 175.556 94.4445C175.556 93.9539 176.889 92.6667 180 92.6667C183.111 92.6667 184.445 93.9539 184.445 94.4445Z"
					fill="#44546F"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_201_22761">
				<rect width="276" height="128" rx="3" fill="white" />
			</clipPath>
			<clipPath id="clip1_201_22761">
				<rect width="16" height="16" fill="white" transform="translate(172 22)" />
			</clipPath>
			<clipPath id="clip2_201_22761">
				<rect width="16" height="16" fill="white" transform="translate(172 56)" />
			</clipPath>
			<clipPath id="clip3_201_22761">
				<rect width="16" height="16" fill="white" transform="translate(172 90)" />
			</clipPath>
		</defs>
	</svg>
);
