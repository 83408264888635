export const configureResizeObserver = (
	element: HTMLElement | null,
	callback: (dimension: { height: number | undefined }) => void,
): ResizeObserver | undefined => {
	if (element && window.ResizeObserver) {
		/*
		 * it's more common to have one observer to track multiple elements using single Observer
		 * using multiple ones here to simplify logic
		 * plus there just a few headings to track
		 */
		const observer = new ResizeObserver((entries) => {
			const { borderBoxSize } = entries[0];
			// JIV-13952: borderBoxSize might have length of 0, it also might not array at all for old browsers (Firefox < 69 )
			// in this case fallback to `undefined` which will trigger `clientHeight`-based calculations at findTallestHeader
			// borderBoxSize might not be present as well
			const height = borderBoxSize?.[0]?.blockSize;
			callback({
				height,
			});
		});
		observer.observe(element);

		return observer;
	}
	// in case of ResizeObserver not supported - reactivate `clientHeight fallback`
	callback({ height: undefined });

	return undefined;
};
