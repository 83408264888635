import { stringify } from 'query-string';
import { performPostRequest } from '@atlassian/jira-fetch';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token';
import type { BaseUrl } from '@atlassian/jira-shared-types';
import type { ServerWorklog } from '../model';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueId: any,
	remainingEstimate: string,
): Promise<ServerWorklog> =>
	performPostRequest(`${baseUrl}/secure/AjaxIssueAction.jspa?decorator=none`, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: stringify({
			issueId,
			timetracking_remainingestimate: remainingEstimate,
			timetracking_targetsubfield: 'timetracking_remainingestimate',
			fieldsToForcePresent: 'timetracking',
			singleFieldEdit: true,
			skipScreenCheck: true,
			atl_token: getXsrfToken(),
		}),
	});
