'use strict';
// Generated from ../Expr.g4 by ANTLR 4.9.0-SNAPSHOT
Object.defineProperty(exports, '__esModule', { value: true });
exports.SomeExprListContext =
	exports.NoExprListContext =
	exports.ExprsContext =
	exports.NamedFieldContext =
	exports.NamedIdentContext =
	exports.NamedContext =
	exports.ParenContext =
	exports.StringContext =
	exports.IntegerContext =
	exports.FloatContext =
	exports.FieldRefContext =
	exports.CallContext =
	exports.IdentifierContext =
	exports.PrimitiveContext =
	exports.DivideContext =
	exports.TimesContext =
	exports.IdentityFactorContext =
	exports.FactorContext =
	exports.MinusContext =
	exports.PlusContext =
	exports.IdentityTermContext =
	exports.TermContext =
	exports.InContext =
	exports.OrContext =
	exports.AndContext =
	exports.NotEqualContext =
	exports.EqualContext =
	exports.GreaterOrEqualContext =
	exports.GreaterThanContext =
	exports.LessOrEqualContext =
	exports.LessThanContext =
	exports.IdentityLogicalContext =
	exports.LogicalContext =
	exports.SubexprContext =
	exports.CompleteExprContext =
	exports.ExprContext =
	exports.ExprParser =
		void 0;
const ATN_1 = require('antlr4ts/atn/ATN');
const ATNDeserializer_1 = require('antlr4ts/atn/ATNDeserializer');
const FailedPredicateException_1 = require('antlr4ts/FailedPredicateException');
const NoViableAltException_1 = require('antlr4ts/NoViableAltException');
const Parser_1 = require('antlr4ts/Parser');
const ParserRuleContext_1 = require('antlr4ts/ParserRuleContext');
const ParserATNSimulator_1 = require('antlr4ts/atn/ParserATNSimulator');
const RecognitionException_1 = require('antlr4ts/RecognitionException');
const VocabularyImpl_1 = require('antlr4ts/VocabularyImpl');
const Utils = require('antlr4ts/misc/Utils');
class ExprParser extends Parser_1.Parser {
	constructor(input) {
		super(input);
		this._interp = new ParserATNSimulator_1.ParserATNSimulator(ExprParser._ATN, this);
	}
	// @Override
	// @NotNull
	get vocabulary() {
		return ExprParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace
	// @Override
	get grammarFileName() {
		return 'Expr.g4';
	}
	// @Override
	get ruleNames() {
		return ExprParser.ruleNames;
	}
	// @Override
	get serializedATN() {
		return ExprParser._serializedATN;
	}
	createFailedPredicateException(predicate, message) {
		return new FailedPredicateException_1.FailedPredicateException(this, predicate, message);
	}
	// @RuleVersion(0)
	expr() {
		let _localctx = new ExprContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, ExprParser.RULE_expr);
		try {
			_localctx = new CompleteExprContext(_localctx);
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 16;
				this.subexpr();
				this.state = 17;
				this.match(ExprParser.EOF);
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	subexpr() {
		let _localctx = new SubexprContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, ExprParser.RULE_subexpr);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 19;
				this.logical(0);
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	logical(_p) {
		if (_p === undefined) {
			_p = 0;
		}
		let _parentctx = this._ctx;
		let _parentState = this.state;
		let _localctx = new LogicalContext(this._ctx, _parentState);
		let _prevctx = _localctx;
		let _startState = 4;
		this.enterRecursionRule(_localctx, 4, ExprParser.RULE_logical, _p);
		try {
			let _alt;
			this.enterOuterAlt(_localctx, 1);
			{
				this.state = 50;
				this._errHandler.sync(this);
				switch (this.interpreter.adaptivePredict(this._input, 0, this._ctx)) {
					case 1:
						{
							_localctx = new IdentityLogicalContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 22;
							this.term(0);
						}
						break;
					case 2:
						{
							_localctx = new LessThanContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 23;
							this.term(0);
							this.state = 24;
							this.match(ExprParser.T__0);
							this.state = 25;
							this.term(0);
						}
						break;
					case 3:
						{
							_localctx = new LessOrEqualContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 27;
							this.term(0);
							this.state = 28;
							this.match(ExprParser.T__1);
							this.state = 29;
							this.term(0);
						}
						break;
					case 4:
						{
							_localctx = new GreaterThanContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 31;
							this.term(0);
							this.state = 32;
							this.match(ExprParser.T__2);
							this.state = 33;
							this.term(0);
						}
						break;
					case 5:
						{
							_localctx = new GreaterOrEqualContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 35;
							this.term(0);
							this.state = 36;
							this.match(ExprParser.T__3);
							this.state = 37;
							this.term(0);
						}
						break;
					case 6:
						{
							_localctx = new EqualContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 39;
							this.term(0);
							this.state = 40;
							this.match(ExprParser.T__4);
							this.state = 41;
							this.term(0);
						}
						break;
					case 7:
						{
							_localctx = new NotEqualContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 43;
							this.term(0);
							this.state = 44;
							this.match(ExprParser.T__5);
							this.state = 45;
							this.term(0);
						}
						break;
					case 8:
						{
							_localctx = new InContext(_localctx);
							this._ctx = _localctx;
							_prevctx = _localctx;
							this.state = 47;
							this.match(ExprParser.STRING);
							this.state = 48;
							this.match(ExprParser.T__8);
							this.state = 49;
							this.named();
						}
						break;
				}
				this._ctx._stop = this._input.tryLT(-1);
				this.state = 60;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
				while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						if (this._parseListeners != null) {
							this.triggerExitRuleEvent();
						}
						_prevctx = _localctx;
						{
							this.state = 58;
							this._errHandler.sync(this);
							switch (this.interpreter.adaptivePredict(this._input, 1, this._ctx)) {
								case 1:
									{
										_localctx = new AndContext(new LogicalContext(_parentctx, _parentState));
										this.pushNewRecursionContext(_localctx, _startState, ExprParser.RULE_logical);
										this.state = 52;
										if (!this.precpred(this._ctx, 3)) {
											throw this.createFailedPredicateException('this.precpred(this._ctx, 3)');
										}
										this.state = 53;
										this.match(ExprParser.T__6);
										this.state = 54;
										this.logical(4);
									}
									break;
								case 2:
									{
										_localctx = new OrContext(new LogicalContext(_parentctx, _parentState));
										this.pushNewRecursionContext(_localctx, _startState, ExprParser.RULE_logical);
										this.state = 55;
										if (!this.precpred(this._ctx, 2)) {
											throw this.createFailedPredicateException('this.precpred(this._ctx, 2)');
										}
										this.state = 56;
										this.match(ExprParser.T__7);
										this.state = 57;
										this.logical(3);
									}
									break;
							}
						}
					}
					this.state = 62;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	term(_p) {
		if (_p === undefined) {
			_p = 0;
		}
		let _parentctx = this._ctx;
		let _parentState = this.state;
		let _localctx = new TermContext(this._ctx, _parentState);
		let _prevctx = _localctx;
		let _startState = 6;
		this.enterRecursionRule(_localctx, 6, ExprParser.RULE_term, _p);
		try {
			let _alt;
			this.enterOuterAlt(_localctx, 1);
			{
				{
					_localctx = new IdentityTermContext(_localctx);
					this._ctx = _localctx;
					_prevctx = _localctx;
					this.state = 64;
					this.factor(0);
				}
				this._ctx._stop = this._input.tryLT(-1);
				this.state = 74;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
				while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						if (this._parseListeners != null) {
							this.triggerExitRuleEvent();
						}
						_prevctx = _localctx;
						{
							this.state = 72;
							this._errHandler.sync(this);
							switch (this.interpreter.adaptivePredict(this._input, 3, this._ctx)) {
								case 1:
									{
										_localctx = new PlusContext(new TermContext(_parentctx, _parentState));
										this.pushNewRecursionContext(_localctx, _startState, ExprParser.RULE_term);
										this.state = 66;
										if (!this.precpred(this._ctx, 2)) {
											throw this.createFailedPredicateException('this.precpred(this._ctx, 2)');
										}
										this.state = 67;
										this.match(ExprParser.T__9);
										this.state = 68;
										this.factor(0);
									}
									break;
								case 2:
									{
										_localctx = new MinusContext(new TermContext(_parentctx, _parentState));
										this.pushNewRecursionContext(_localctx, _startState, ExprParser.RULE_term);
										this.state = 69;
										if (!this.precpred(this._ctx, 1)) {
											throw this.createFailedPredicateException('this.precpred(this._ctx, 1)');
										}
										this.state = 70;
										this.match(ExprParser.T__10);
										this.state = 71;
										this.factor(0);
									}
									break;
							}
						}
					}
					this.state = 76;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 4, this._ctx);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	factor(_p) {
		if (_p === undefined) {
			_p = 0;
		}
		let _parentctx = this._ctx;
		let _parentState = this.state;
		let _localctx = new FactorContext(this._ctx, _parentState);
		let _prevctx = _localctx;
		let _startState = 8;
		this.enterRecursionRule(_localctx, 8, ExprParser.RULE_factor, _p);
		try {
			let _alt;
			this.enterOuterAlt(_localctx, 1);
			{
				{
					_localctx = new IdentityFactorContext(_localctx);
					this._ctx = _localctx;
					_prevctx = _localctx;
					this.state = 78;
					this.primitive();
				}
				this._ctx._stop = this._input.tryLT(-1);
				this.state = 88;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 6, this._ctx);
				while (_alt !== 2 && _alt !== ATN_1.ATN.INVALID_ALT_NUMBER) {
					if (_alt === 1) {
						if (this._parseListeners != null) {
							this.triggerExitRuleEvent();
						}
						_prevctx = _localctx;
						{
							this.state = 86;
							this._errHandler.sync(this);
							switch (this.interpreter.adaptivePredict(this._input, 5, this._ctx)) {
								case 1:
									{
										_localctx = new TimesContext(new FactorContext(_parentctx, _parentState));
										this.pushNewRecursionContext(_localctx, _startState, ExprParser.RULE_factor);
										this.state = 80;
										if (!this.precpred(this._ctx, 2)) {
											throw this.createFailedPredicateException('this.precpred(this._ctx, 2)');
										}
										this.state = 81;
										this.match(ExprParser.T__11);
										this.state = 82;
										this.primitive();
									}
									break;
								case 2:
									{
										_localctx = new DivideContext(new FactorContext(_parentctx, _parentState));
										this.pushNewRecursionContext(_localctx, _startState, ExprParser.RULE_factor);
										this.state = 83;
										if (!this.precpred(this._ctx, 1)) {
											throw this.createFailedPredicateException('this.precpred(this._ctx, 1)');
										}
										this.state = 84;
										this.match(ExprParser.T__12);
										this.state = 85;
										this.primitive();
									}
									break;
							}
						}
					}
					this.state = 90;
					this._errHandler.sync(this);
					_alt = this.interpreter.adaptivePredict(this._input, 6, this._ctx);
				}
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	primitive() {
		let _localctx = new PrimitiveContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, ExprParser.RULE_primitive);
		try {
			this.state = 105;
			this._errHandler.sync(this);
			switch (this.interpreter.adaptivePredict(this._input, 7, this._ctx)) {
				case 1:
					_localctx = new IdentifierContext(_localctx);
					this.enterOuterAlt(_localctx, 1);
					{
						this.state = 91;
						this.match(ExprParser.IDENT);
					}
					break;
				case 2:
					_localctx = new CallContext(_localctx);
					this.enterOuterAlt(_localctx, 2);
					{
						this.state = 92;
						this.match(ExprParser.IDENT);
						this.state = 93;
						this.match(ExprParser.T__13);
						this.state = 94;
						this.exprs();
						this.state = 95;
						this.match(ExprParser.T__14);
					}
					break;
				case 3:
					_localctx = new FieldRefContext(_localctx);
					this.enterOuterAlt(_localctx, 3);
					{
						this.state = 97;
						this.match(ExprParser.FIELD);
					}
					break;
				case 4:
					_localctx = new FloatContext(_localctx);
					this.enterOuterAlt(_localctx, 4);
					{
						this.state = 98;
						this.match(ExprParser.FLOAT);
					}
					break;
				case 5:
					_localctx = new IntegerContext(_localctx);
					this.enterOuterAlt(_localctx, 5);
					{
						this.state = 99;
						this.match(ExprParser.INTEGER);
					}
					break;
				case 6:
					_localctx = new StringContext(_localctx);
					this.enterOuterAlt(_localctx, 6);
					{
						this.state = 100;
						this.match(ExprParser.STRING);
					}
					break;
				case 7:
					_localctx = new ParenContext(_localctx);
					this.enterOuterAlt(_localctx, 7);
					{
						this.state = 101;
						this.match(ExprParser.T__13);
						this.state = 102;
						this.subexpr();
						this.state = 103;
						this.match(ExprParser.T__14);
					}
					break;
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	named() {
		let _localctx = new NamedContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, ExprParser.RULE_named);
		try {
			this.state = 109;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
				case ExprParser.IDENT:
					_localctx = new NamedIdentContext(_localctx);
					this.enterOuterAlt(_localctx, 1);
					{
						this.state = 107;
						this.match(ExprParser.IDENT);
					}
					break;
				case ExprParser.FIELD:
					_localctx = new NamedFieldContext(_localctx);
					this.enterOuterAlt(_localctx, 2);
					{
						this.state = 108;
						this.match(ExprParser.FIELD);
					}
					break;
				default:
					throw new NoViableAltException_1.NoViableAltException(this);
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	exprs() {
		let _localctx = new ExprsContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, ExprParser.RULE_exprs);
		let _la;
		try {
			this.state = 120;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
				case ExprParser.T__14:
					_localctx = new NoExprListContext(_localctx);
					this.enterOuterAlt(_localctx, 1);
					// tslint:disable-next-line:no-empty
					{
					}
					break;
				case ExprParser.T__13:
				case ExprParser.FIELD:
				case ExprParser.IDENT:
				case ExprParser.FLOAT:
				case ExprParser.INTEGER:
				case ExprParser.STRING:
					_localctx = new SomeExprListContext(_localctx);
					this.enterOuterAlt(_localctx, 2);
					{
						this.state = 112;
						this.subexpr();
						this.state = 117;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
						while (_la === ExprParser.T__15) {
							{
								{
									this.state = 113;
									this.match(ExprParser.T__15);
									this.state = 114;
									this.subexpr();
								}
							}
							this.state = 119;
							this._errHandler.sync(this);
							_la = this._input.LA(1);
						}
					}
					break;
				default:
					throw new NoViableAltException_1.NoViableAltException(this);
			}
		} catch (re) {
			if (re instanceof RecognitionException_1.RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		} finally {
			this.exitRule();
		}
		return _localctx;
	}
	sempred(_localctx, ruleIndex, predIndex) {
		switch (ruleIndex) {
			case 2:
				return this.logical_sempred(_localctx, predIndex);
			case 3:
				return this.term_sempred(_localctx, predIndex);
			case 4:
				return this.factor_sempred(_localctx, predIndex);
		}
		return true;
	}
	logical_sempred(_localctx, predIndex) {
		switch (predIndex) {
			case 0:
				return this.precpred(this._ctx, 3);
			case 1:
				return this.precpred(this._ctx, 2);
		}
		return true;
	}
	term_sempred(_localctx, predIndex) {
		switch (predIndex) {
			case 2:
				return this.precpred(this._ctx, 2);
			case 3:
				return this.precpred(this._ctx, 1);
		}
		return true;
	}
	factor_sempred(_localctx, predIndex) {
		switch (predIndex) {
			case 4:
				return this.precpred(this._ctx, 2);
			case 5:
				return this.precpred(this._ctx, 1);
		}
		return true;
	}
	static get _ATN() {
		if (!ExprParser.__ATN) {
			ExprParser.__ATN = new ATNDeserializer_1.ATNDeserializer().deserialize(
				Utils.toCharArray(ExprParser._serializedATN),
			);
		}
		return ExprParser.__ATN;
	}
}
exports.ExprParser = ExprParser;
ExprParser.T__0 = 1;
ExprParser.T__1 = 2;
ExprParser.T__2 = 3;
ExprParser.T__3 = 4;
ExprParser.T__4 = 5;
ExprParser.T__5 = 6;
ExprParser.T__6 = 7;
ExprParser.T__7 = 8;
ExprParser.T__8 = 9;
ExprParser.T__9 = 10;
ExprParser.T__10 = 11;
ExprParser.T__11 = 12;
ExprParser.T__12 = 13;
ExprParser.T__13 = 14;
ExprParser.T__14 = 15;
ExprParser.T__15 = 16;
ExprParser.FIELD = 17;
ExprParser.IDENT = 18;
ExprParser.WS = 19;
ExprParser.FLOAT = 20;
ExprParser.INTEGER = 21;
ExprParser.STRING = 22;
ExprParser.RULE_expr = 0;
ExprParser.RULE_subexpr = 1;
ExprParser.RULE_logical = 2;
ExprParser.RULE_term = 3;
ExprParser.RULE_factor = 4;
ExprParser.RULE_primitive = 5;
ExprParser.RULE_named = 6;
ExprParser.RULE_exprs = 7;
// tslint:disable:no-trailing-whitespace
ExprParser.ruleNames = [
	'expr',
	'subexpr',
	'logical',
	'term',
	'factor',
	'primitive',
	'named',
	'exprs',
];
ExprParser._LITERAL_NAMES = [
	undefined,
	"'<'",
	"'<='",
	"'>'",
	"'>='",
	"'='",
	"'<>'",
	"'and'",
	"'or'",
	"'in'",
	"'+'",
	"'-'",
	"'*'",
	"'/'",
	"'('",
	"')'",
	"','",
];
ExprParser._SYMBOLIC_NAMES = [
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	'FIELD',
	'IDENT',
	'WS',
	'FLOAT',
	'INTEGER',
	'STRING',
];
ExprParser.VOCABULARY = new VocabularyImpl_1.VocabularyImpl(
	ExprParser._LITERAL_NAMES,
	ExprParser._SYMBOLIC_NAMES,
	[],
);
ExprParser._serializedATN =
	'\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\x18}\x04\x02' +
	'\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07' +
	'\t\x07\x04\b\t\b\x04\t\t\t\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03' +
	'\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03' +
	'\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03' +
	'\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03' +
	'\x04\x03\x04\x05\x045\n\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03' +
	'\x04\x07\x04=\n\x04\f\x04\x0E\x04@\v\x04\x03\x05\x03\x05\x03\x05\x03\x05' +
	'\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x07\x05K\n\x05\f\x05\x0E\x05' +
	'N\v\x05\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06' +
	'\x03\x06\x07\x06Y\n\x06\f\x06\x0E\x06\\\v\x06\x03\x07\x03\x07\x03\x07' +
	'\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07' +
	'\x03\x07\x03\x07\x05\x07l\n\x07\x03\b\x03\b\x05\bp\n\b\x03\t\x03\t\x03' +
	'\t\x03\t\x07\tv\n\t\f\t\x0E\ty\v\t\x05\t{\n\t\x03\t\x02\x02\x05\x06\b' +
	'\n\n\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x02\x02' +
	'\x02\x8A\x02\x12\x03\x02\x02\x02\x04\x15\x03\x02\x02\x02\x064\x03\x02' +
	'\x02\x02\bA\x03\x02\x02\x02\nO\x03\x02\x02\x02\fk\x03\x02\x02\x02\x0E' +
	'o\x03\x02\x02\x02\x10z\x03\x02\x02\x02\x12\x13\x05\x04\x03\x02\x13\x14' +
	'\x07\x02\x02\x03\x14\x03\x03\x02\x02\x02\x15\x16\x05\x06\x04\x02\x16\x05' +
	'\x03\x02\x02\x02\x17\x18\b\x04\x01\x02\x185\x05\b\x05\x02\x19\x1A\x05' +
	'\b\x05\x02\x1A\x1B\x07\x03\x02\x02\x1B\x1C\x05\b\x05\x02\x1C5\x03\x02' +
	'\x02\x02\x1D\x1E\x05\b\x05\x02\x1E\x1F\x07\x04\x02\x02\x1F \x05\b\x05' +
	'\x02 5\x03\x02\x02\x02!"\x05\b\x05\x02"#\x07\x05\x02\x02#$\x05\b\x05' +
	"\x02$5\x03\x02\x02\x02%&\x05\b\x05\x02&'\x07\x06\x02\x02'(\x05\b\x05" +
	'\x02(5\x03\x02\x02\x02)*\x05\b\x05\x02*+\x07\x07\x02\x02+,\x05\b\x05\x02' +
	',5\x03\x02\x02\x02-.\x05\b\x05\x02./\x07\b\x02\x02/0\x05\b\x05\x0205\x03' +
	'\x02\x02\x0212\x07\x18\x02\x0223\x07\v\x02\x0235\x05\x0E\b\x024\x17\x03' +
	'\x02\x02\x024\x19\x03\x02\x02\x024\x1D\x03\x02\x02\x024!\x03\x02\x02\x02' +
	'4%\x03\x02\x02\x024)\x03\x02\x02\x024-\x03\x02\x02\x0241\x03\x02\x02\x02' +
	'5>\x03\x02\x02\x0267\f\x05\x02\x0278\x07\t\x02\x028=\x05\x06\x04\x069' +
	':\f\x04\x02\x02:;\x07\n\x02\x02;=\x05\x06\x04\x05<6\x03\x02\x02\x02<9' +
	'\x03\x02\x02\x02=@\x03\x02\x02\x02><\x03\x02\x02\x02>?\x03\x02\x02\x02' +
	'?\x07\x03\x02\x02\x02@>\x03\x02\x02\x02AB\b\x05\x01\x02BC\x05\n\x06\x02' +
	'CL\x03\x02\x02\x02DE\f\x04\x02\x02EF\x07\f\x02\x02FK\x05\n\x06\x02GH\f' +
	'\x03\x02\x02HI\x07\r\x02\x02IK\x05\n\x06\x02JD\x03\x02\x02\x02JG\x03\x02' +
	'\x02\x02KN\x03\x02\x02\x02LJ\x03\x02\x02\x02LM\x03\x02\x02\x02M\t\x03' +
	'\x02\x02\x02NL\x03\x02\x02\x02OP\b\x06\x01\x02PQ\x05\f\x07\x02QZ\x03\x02' +
	'\x02\x02RS\f\x04\x02\x02ST\x07\x0E\x02\x02TY\x05\f\x07\x02UV\f\x03\x02' +
	'\x02VW\x07\x0F\x02\x02WY\x05\f\x07\x02XR\x03\x02\x02\x02XU\x03\x02\x02' +
	'\x02Y\\\x03\x02\x02\x02ZX\x03\x02\x02\x02Z[\x03\x02\x02\x02[\v\x03\x02' +
	'\x02\x02\\Z\x03\x02\x02\x02]l\x07\x14\x02\x02^_\x07\x14\x02\x02_`\x07' +
	'\x10\x02\x02`a\x05\x10\t\x02ab\x07\x11\x02\x02bl\x03\x02\x02\x02cl\x07' +
	'\x13\x02\x02dl\x07\x16\x02\x02el\x07\x17\x02\x02fl\x07\x18\x02\x02gh\x07' +
	'\x10\x02\x02hi\x05\x04\x03\x02ij\x07\x11\x02\x02jl\x03\x02\x02\x02k]\x03' +
	'\x02\x02\x02k^\x03\x02\x02\x02kc\x03\x02\x02\x02kd\x03\x02\x02\x02ke\x03' +
	'\x02\x02\x02kf\x03\x02\x02\x02kg\x03\x02\x02\x02l\r\x03\x02\x02\x02mp' +
	'\x07\x14\x02\x02np\x07\x13\x02\x02om\x03\x02\x02\x02on\x03\x02\x02\x02' +
	'p\x0F\x03\x02\x02\x02q{\x03\x02\x02\x02rw\x05\x04\x03\x02st\x07\x12\x02' +
	'\x02tv\x05\x04\x03\x02us\x03\x02\x02\x02vy\x03\x02\x02\x02wu\x03\x02\x02' +
	'\x02wx\x03\x02\x02\x02x{\x03\x02\x02\x02yw\x03\x02\x02\x02zq\x03\x02\x02' +
	'\x02zr\x03\x02\x02\x02{\x11\x03\x02\x02\x02\r4<>JLXZkowz';
class ExprContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_expr;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.ExprContext = ExprContext;
class CompleteExprContext extends ExprContext {
	subexpr() {
		return this.getRuleContext(0, SubexprContext);
	}
	EOF() {
		return this.getToken(ExprParser.EOF, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitCompleteExpr) {
			return visitor.visitCompleteExpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.CompleteExprContext = CompleteExprContext;
class SubexprContext extends ParserRuleContext_1.ParserRuleContext {
	logical() {
		return this.getRuleContext(0, LogicalContext);
	}
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_subexpr;
	}
	// @Override
	accept(visitor) {
		if (visitor.visitSubexpr) {
			return visitor.visitSubexpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.SubexprContext = SubexprContext;
class LogicalContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_logical;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.LogicalContext = LogicalContext;
class IdentityLogicalContext extends LogicalContext {
	term() {
		return this.getRuleContext(0, TermContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitIdentityLogical) {
			return visitor.visitIdentityLogical(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.IdentityLogicalContext = IdentityLogicalContext;
class LessThanContext extends LogicalContext {
	term(i) {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitLessThan) {
			return visitor.visitLessThan(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.LessThanContext = LessThanContext;
class LessOrEqualContext extends LogicalContext {
	term(i) {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitLessOrEqual) {
			return visitor.visitLessOrEqual(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.LessOrEqualContext = LessOrEqualContext;
class GreaterThanContext extends LogicalContext {
	term(i) {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitGreaterThan) {
			return visitor.visitGreaterThan(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.GreaterThanContext = GreaterThanContext;
class GreaterOrEqualContext extends LogicalContext {
	term(i) {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitGreaterOrEqual) {
			return visitor.visitGreaterOrEqual(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.GreaterOrEqualContext = GreaterOrEqualContext;
class EqualContext extends LogicalContext {
	term(i) {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitEqual) {
			return visitor.visitEqual(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.EqualContext = EqualContext;
class NotEqualContext extends LogicalContext {
	term(i) {
		if (i === undefined) {
			return this.getRuleContexts(TermContext);
		} else {
			return this.getRuleContext(i, TermContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitNotEqual) {
			return visitor.visitNotEqual(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.NotEqualContext = NotEqualContext;
class AndContext extends LogicalContext {
	logical(i) {
		if (i === undefined) {
			return this.getRuleContexts(LogicalContext);
		} else {
			return this.getRuleContext(i, LogicalContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitAnd) {
			return visitor.visitAnd(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.AndContext = AndContext;
class OrContext extends LogicalContext {
	logical(i) {
		if (i === undefined) {
			return this.getRuleContexts(LogicalContext);
		} else {
			return this.getRuleContext(i, LogicalContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitOr) {
			return visitor.visitOr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.OrContext = OrContext;
class InContext extends LogicalContext {
	STRING() {
		return this.getToken(ExprParser.STRING, 0);
	}
	named() {
		return this.getRuleContext(0, NamedContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitIn) {
			return visitor.visitIn(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.InContext = InContext;
class TermContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_term;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.TermContext = TermContext;
class IdentityTermContext extends TermContext {
	factor() {
		return this.getRuleContext(0, FactorContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitIdentityTerm) {
			return visitor.visitIdentityTerm(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.IdentityTermContext = IdentityTermContext;
class PlusContext extends TermContext {
	term() {
		return this.getRuleContext(0, TermContext);
	}
	factor() {
		return this.getRuleContext(0, FactorContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitPlus) {
			return visitor.visitPlus(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.PlusContext = PlusContext;
class MinusContext extends TermContext {
	term() {
		return this.getRuleContext(0, TermContext);
	}
	factor() {
		return this.getRuleContext(0, FactorContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitMinus) {
			return visitor.visitMinus(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.MinusContext = MinusContext;
class FactorContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_factor;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.FactorContext = FactorContext;
class IdentityFactorContext extends FactorContext {
	primitive() {
		return this.getRuleContext(0, PrimitiveContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitIdentityFactor) {
			return visitor.visitIdentityFactor(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.IdentityFactorContext = IdentityFactorContext;
class TimesContext extends FactorContext {
	factor() {
		return this.getRuleContext(0, FactorContext);
	}
	primitive() {
		return this.getRuleContext(0, PrimitiveContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitTimes) {
			return visitor.visitTimes(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.TimesContext = TimesContext;
class DivideContext extends FactorContext {
	factor() {
		return this.getRuleContext(0, FactorContext);
	}
	primitive() {
		return this.getRuleContext(0, PrimitiveContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitDivide) {
			return visitor.visitDivide(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.DivideContext = DivideContext;
class PrimitiveContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_primitive;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.PrimitiveContext = PrimitiveContext;
class IdentifierContext extends PrimitiveContext {
	IDENT() {
		return this.getToken(ExprParser.IDENT, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitIdentifier) {
			return visitor.visitIdentifier(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.IdentifierContext = IdentifierContext;
class CallContext extends PrimitiveContext {
	IDENT() {
		return this.getToken(ExprParser.IDENT, 0);
	}
	exprs() {
		return this.getRuleContext(0, ExprsContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitCall) {
			return visitor.visitCall(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.CallContext = CallContext;
class FieldRefContext extends PrimitiveContext {
	FIELD() {
		return this.getToken(ExprParser.FIELD, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitFieldRef) {
			return visitor.visitFieldRef(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.FieldRefContext = FieldRefContext;
class FloatContext extends PrimitiveContext {
	FLOAT() {
		return this.getToken(ExprParser.FLOAT, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitFloat) {
			return visitor.visitFloat(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.FloatContext = FloatContext;
class IntegerContext extends PrimitiveContext {
	INTEGER() {
		return this.getToken(ExprParser.INTEGER, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitInteger) {
			return visitor.visitInteger(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.IntegerContext = IntegerContext;
class StringContext extends PrimitiveContext {
	STRING() {
		return this.getToken(ExprParser.STRING, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitString) {
			return visitor.visitString(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.StringContext = StringContext;
class ParenContext extends PrimitiveContext {
	subexpr() {
		return this.getRuleContext(0, SubexprContext);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitParen) {
			return visitor.visitParen(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.ParenContext = ParenContext;
class NamedContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_named;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.NamedContext = NamedContext;
class NamedIdentContext extends NamedContext {
	IDENT() {
		return this.getToken(ExprParser.IDENT, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitNamedIdent) {
			return visitor.visitNamedIdent(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.NamedIdentContext = NamedIdentContext;
class NamedFieldContext extends NamedContext {
	FIELD() {
		return this.getToken(ExprParser.FIELD, 0);
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitNamedField) {
			return visitor.visitNamedField(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.NamedFieldContext = NamedFieldContext;
class ExprsContext extends ParserRuleContext_1.ParserRuleContext {
	constructor(parent, invokingState) {
		super(parent, invokingState);
	}
	// @Override
	get ruleIndex() {
		return ExprParser.RULE_exprs;
	}
	copyFrom(ctx) {
		super.copyFrom(ctx);
	}
}
exports.ExprsContext = ExprsContext;
class NoExprListContext extends ExprsContext {
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitNoExprList) {
			return visitor.visitNoExprList(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.NoExprListContext = NoExprListContext;
class SomeExprListContext extends ExprsContext {
	subexpr(i) {
		if (i === undefined) {
			return this.getRuleContexts(SubexprContext);
		} else {
			return this.getRuleContext(i, SubexprContext);
		}
	}
	constructor(ctx) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	accept(visitor) {
		if (visitor.visitSomeExprList) {
			return visitor.visitSomeExprList(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
exports.SomeExprListContext = SomeExprListContext;
