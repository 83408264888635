import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldValueComponentProps } from '../types';
import messages from './messages';

const DefaultFieldValue = ({ groupIdentity }: FieldValueComponentProps) => (
	<Box paddingInlineStart="space.050">{groupIdentity}</Box>
);

export const EmptyFieldValue = () => {
	const { formatMessage } = useIntl();
	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyFieldValue)}</Box>;
};

export const emptyFieldValueConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: DefaultFieldValue,
	EmptyComponent: EmptyFieldValue,
} as const;
