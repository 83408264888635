import React from 'react';
import type { BasicIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/types';
import { ShortLabel } from '../../../../../../../common/ui/issue/short-label';

type Props = {
	basicIssue: BasicIssue;
};

export const BasicIssueOptionRenderer = ({ basicIssue }: Props) => (
	<ShortLabel issueKey={basicIssue.key} summary={basicIssue.summary} />
);
