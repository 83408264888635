import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapPromiseWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { getJpdIssues } from '../../services/jira/get-jpd-issues';
import { rankIdeaRest } from '../../services/jira/rank-idea-rest';
import type { IssueRankRemote } from './types';

export const createNotImplementedIssueRankRemote = (): IssueRankRemote => ({
	rankIssues: notImplementedError('rankIssues'),
	fetchRanks: notImplementedError('fetchRanks'),
});

export const createIssueRankRemote = (projectId: ProjectId): IssueRankRemote => ({
	rankIssues: ({ issueKeys, rankBeforeIssueKey, rankAfterIssueKey, rankCustomFieldId }) =>
		wrapPromiseWithExperience(
			rankIdeaRest(issueKeys, rankBeforeIssueKey, rankAfterIssueKey, rankCustomFieldId),
			experience.issues.rankIssue,
		),
	fetchRanks: ({ rankField, issueIdsOrKeys, archivedFilter, startAt, maxResults }) =>
		wrapPromiseWithExperience(
			getJpdIssues({
				projectId,
				issueIdsOrKeys,
				fields: [rankField],
				archivedFilter,
				startAt,
				maxResults,
			}),
			experience.issues.loadIssuesRanksJpdApi,
			'fetchRanks',
		),
});
