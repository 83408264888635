import { createSelector } from 'reselect';
import find from 'lodash/find';
import has from 'lodash/has';
import head from 'lodash/head';
import some from 'lodash/some';
import trim from 'lodash/trim';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	FieldValueFilter,
	Filter,
	NumericFieldFilter,
	TextFilter,
	IntervalFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { Props } from '../types';
import { getFields } from './fields';
import { getCurrentViewGroupBy, getCurrentViewVerticalGroupBy } from './view';
import { getCurrentViewDraft, isCurrentViewAutosaveEnabled } from './view/autosave/index.tsx';
import { getCurrentView } from './view/current/index.tsx';

const doesFilterValueIdExistsInFieldOptions = (
	field: Field,
	{ stringValue: optionId }: { stringValue?: string },
) =>
	// undefined (=no value) OR check optionId in field options
	optionId === undefined ||
	!field?.options ||
	field?.options?.some(({ jiraOptionId }) => jiraOptionId === optionId);

const getCurrentViewPermanentFilterProperty = createSelector(
	getCurrentView,
	(currentView) => currentView?.filter,
);

const getCurrentViewTemporaryFilterProperty = createSelector(
	getCurrentView,
	(currentView) => currentView?.temporaryData?.filter,
);

const getCurrentViewPermanentFilterObj = createSelector(
	getCurrentViewPermanentFilterProperty,
	(filter) => (filter || []).filter((f) => f.type !== 'TEXT' || f.localId === 'quick'),
);

const getCurrentViewTemporaryFilterObj = createSelector(
	getCurrentViewTemporaryFilterProperty,
	(filter) => (filter || []).filter((f) => f.type !== 'TEXT' || f.localId === 'quick'),
);

const getCurrentViewDraftFilterProperty = createSelector(
	getCurrentViewDraft,
	(draft) => draft?.filter || [],
);

export const getCurrentViewFilter = createSelector(
	getCurrentViewPermanentFilterObj,
	getCurrentViewTemporaryFilterObj,
	getCurrentViewDraftFilterProperty,
	getFields,
	isCurrentViewAutosaveEnabled,
	(viewFilterObj, temporaryFilterObj, draftFilter, fieldsByKey, isAutosaveEnabled) => {
		let filterData: Filter[] = [];

		if (fg('polaris_just-for-you')) {
			filterData = viewFilterObj.concat(temporaryFilterObj);
		} else {
			filterData = isAutosaveEnabled ? viewFilterObj : draftFilter;
		}

		return filterData.map((filter) =>
			filter.type === 'FIELD'
				? {
						...filter,
						values: filter.values.filter((value) =>
							doesFilterValueIdExistsInFieldOptions(fieldsByKey[filter.field], value),
						),
					}
				: filter,
		);
	},
);

export const getCurrentViewPermanentFilter = createSelector(
	getCurrentViewPermanentFilterObj,
	getCurrentViewDraftFilterProperty,
	getFields,
	isCurrentViewAutosaveEnabled,
	(viewFilterObj, draftFilter, fieldsByKey, isAutosaveEnabled) => {
		let filterData: Filter[] = [];

		if (fg('polaris_just-for-you')) {
			filterData = viewFilterObj;
		} else {
			filterData = isAutosaveEnabled ? viewFilterObj : draftFilter;
		}

		return filterData.map((filter) =>
			filter.type === 'FIELD'
				? {
						...filter,
						values: filter.values.filter((value) =>
							doesFilterValueIdExistsInFieldOptions(fieldsByKey[filter.field], value),
						),
					}
				: filter,
		);
	},
);

export const getCurrentViewTemporaryFilter = createSelector(
	getCurrentViewTemporaryFilterObj,
	getFields,
	(temporaryFilterObj, fieldsByKey) => {
		return temporaryFilterObj.map((filter) =>
			filter.type === 'FIELD'
				? {
						...filter,
						values: filter.values.filter((value) =>
							doesFilterValueIdExistsInFieldOptions(fieldsByKey[filter.field], value),
						),
					}
				: filter,
		);
	},
);

export const getCurrentViewInitialFilter = createSelector(
	getCurrentView,
	(view) => view?.initialFilter,
);

export const hasSharedViewNoValueFilter = (field: Field) =>
	createSelector(
		getCurrentViewInitialFilter,
		(_, props: Props | undefined) => props,
		(initialFilter, props) => {
			if (!props?.isSharedView) return true;

			const fieldFilter = initialFilter?.find((f) => 'field' in f && f.field === field.key);

			if (!fieldFilter || fieldFilter.type !== 'FIELD') {
				return true;
			}

			return !!fieldFilter?.values?.find(({ stringValue }) => !stringValue);
		},
	);

export const getCurrentViewFilterForShared = createSelector(
	getCurrentViewFilter,
	getCurrentViewInitialFilter,
	getCurrentViewGroupBy,
	getCurrentViewVerticalGroupBy,
	(filters, initialFilters, groupBy, verticalGroupBy) => {
		const getGroupFilter = (f: Filter) => 'field' in f && f.field === groupBy?.key;
		const getVerticalGroupFilter = (f: Filter) => 'field' in f && f.field === verticalGroupBy?.key;

		const groupFilter = initialFilters?.find(getGroupFilter);
		const verticalGroupByFilter = initialFilters?.find(getVerticalGroupFilter);
		const localGroupFilter = filters.find(getGroupFilter);
		const localVerticalGroupFilter = filters.find(getVerticalGroupFilter);
		const mergedFilters = [...filters];

		// use group filter configured in normal view until user overrides this locally in shared view
		if (groupFilter && !localGroupFilter) {
			mergedFilters.push(groupFilter);
		}

		if (verticalGroupByFilter && !localVerticalGroupFilter) {
			mergedFilters.push(verticalGroupByFilter);
		}

		return mergedFilters;
	},
);

type FilterOptions = { isTemporary?: boolean };

export const createGetFieldFilter = (field: FieldKey, options?: FilterOptions) =>
	createSelector(getCurrentViewFilter, (filters) => {
		const retVal = find(
			filters,
			(f): f is FieldValueFilter =>
				!!f.isTemporary === !!options?.isTemporary && f.type === 'FIELD' && f.field === field,
		);

		return retVal;
	});

export const createGetNumericFilter = (field: FieldKey, options?: FilterOptions) =>
	createSelector(getCurrentViewFilter, (filters) => {
		const retVal = find(
			filters,
			(f): f is NumericFieldFilter =>
				!!f.isTemporary === !!options?.isTemporary && f.type === 'NUMBER' && f.field === field,
		);
		return retVal;
	});

export const createGetIntervalFilter = (field: FieldKey, options?: FilterOptions) =>
	createSelector(getCurrentViewFilter, (filters) =>
		find(
			filters,
			(filter): filter is IntervalFieldFilter =>
				!!filter.isTemporary === !!options?.isTemporary &&
				filter.type === 'INTERVAL' &&
				filter.field === field,
		),
	);

export const createGetFieldFilterOrEmptyFilter = (fieldKey: FieldKey, options?: FilterOptions) => {
	const getFieldFilter = createGetFieldFilter(fieldKey, { isTemporary: options?.isTemporary });
	return createSelector(
		getFieldFilter,
		getFields,
		(fieldFilter, fields): FieldValueFilter =>
			fieldFilter || {
				type: 'FIELD',
				field: fieldKey,
				values: [],
				fieldType: fields[fieldKey]?.type,
				isTemporary: options?.isTemporary,
			},
	);
};

export const createGetNumericFilterOrEmptyFilter = (
	fieldKey: FieldKey,
	options?: FilterOptions,
) => {
	const getFieldFilter = createGetNumericFilter(fieldKey, { isTemporary: options?.isTemporary });
	return createSelector(
		getFieldFilter,
		getFields,
		(fieldFilter, fields): NumericFieldFilter =>
			fieldFilter || {
				type: 'NUMBER',
				field: fieldKey,
				fieldType: fields[fieldKey].type,
				values: [],
				isTemporary: options?.isTemporary,
			},
	);
};

export const createGeIntervalFilterOrEmptyFilter = (
	fieldKey: FieldKey,
	options?: FilterOptions,
) => {
	const getFieldFilter = createGetIntervalFilter(fieldKey, { isTemporary: options?.isTemporary });
	const emptyIntervalFilter: IntervalFieldFilter = {
		type: 'INTERVAL',
		field: fieldKey,
		values: [],
		isTemporary: options?.isTemporary,
	};
	return createSelector(
		getFieldFilter,
		(fieldFilter: IntervalFieldFilter | undefined): IntervalFieldFilter =>
			fieldFilter || emptyIntervalFilter,
	);
};

export const getQuickSearchFilter = createSelector(
	getCurrentViewFilter,
	(filters): TextFilter =>
		head(filters.filter((f): f is TextFilter => f.type === 'TEXT' && f.localId === 'quick')) || {
			type: 'TEXT',
			localId: 'quick',
			values: [],
		},
);

/**
 * Filters as represented in the state might be empty or referencing fields
 * that are no longer available on the prroject. this filter ensures that only
 * filters that actually can modify the issue set are used for counting active filters.
 */
const isApplicableFilter = (filter: Filter, fields: FieldsByKey): boolean => {
	if (filter.type === 'TEXT') {
		return filter.values.length > 0 && trim(filter.values[0].stringValue) !== '';
	}
	return filter.values.length > 0 && has(fields, filter.field);
};

export const isQuickSearchFilterDefined = createSelector(getQuickSearchFilter, (textFilter) =>
	isApplicableFilter(textFilter, {}),
);

export const getActiveFiltersCount = createSelector(
	getCurrentViewFilter,
	getFields,
	(filters, fields) => filters.filter((filter) => isApplicableFilter(filter, fields)).length,
);

// Fields for active filters
export const getActivePermanentFiltersFields = createSelector(
	getCurrentViewFilter,
	getFields,
	(filters: Filter[], fields: FieldsByKey = {}): Field[] => {
		const activeFiltersFields = filters
			.filter((filter) => !filter.isTemporary)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, filter) => {
				if (filter.type === 'FIELD' || filter.type === 'NUMBER' || filter.type === 'INTERVAL') {
					if (filter.values.length > 0 && has(fields, filter.field)) {
						acc[filter.field] = fields[filter.field];
					}
				}
				return acc;
			}, {});

		return Object.values(activeFiltersFields);
	},
);

// Fields for active temporary filters
export const getActiveTemporaryFiltersFields = createSelector(
	getCurrentViewTemporaryFilter,
	getFields,
	(filters: Filter[], fields: FieldsByKey = {}): Field[] => {
		const activeFiltersFields = filters
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, filter) => {
				if (filter.type === 'FIELD' || filter.type === 'NUMBER' || filter.type === 'INTERVAL') {
					if (filter.values.length > 0 && has(fields, filter.field)) {
						acc[filter.field] = fields[filter.field];
					}
				}
				return acc;
			}, {});

		return Object.values(activeFiltersFields);
	},
);

export const getAllowedFiltersFields = createSelector(
	getFields,
	(fields: FieldsByKey = {}): Field[] => {
		// Allowed field types to filter on
		const allowedFieldTypes = [
			FIELD_TYPES.NUMBER,
			FIELD_TYPES.FORMULA,
			FIELD_TYPES.LINKED_ISSUES,
			FIELD_TYPES.RATING,
			FIELD_TYPES.CHECKBOX,
			FIELD_TYPES.SLIDER,
			FIELD_TYPES.INSIGHTS,
			FIELD_TYPES.ASSIGNEE,
			FIELD_TYPES.REPORTER,
			FIELD_TYPES.CREATOR,
			FIELD_TYPES.PEOPLE,
			FIELD_TYPES.JSW_PEOPLE,
			FIELD_TYPES.SINGLE_SELECT,
			FIELD_TYPES.MULTI_SELECT,
			FIELD_TYPES.JSW_MULTI_SELECT,
			FIELD_TYPES.LABELS,
			FIELD_TYPES.CUSTOM_LABELS,
			FIELD_TYPES.STATUS,
			FIELD_TYPES.ISSUE_ID,
			FIELD_TYPES.ATLAS_GOAL,
			FIELD_TYPES.ATLAS_PROJECT,
			FIELD_TYPES.ATLAS_PROJECT_STATUS,
			FIELD_TYPES.INTERVAL,
			FIELD_TYPES.DELIVERY_STATUS,
			FIELD_TYPES.DELIVERY_PROGRESS,
			FIELD_TYPES.REACTIONS,
			FIELD_TYPES.PROJECT,
		];

		return Object.keys(fields)
			.map((key) => fields[key])
			.filter(({ type }) => allowedFieldTypes.some((t) => t === type));
	},
);

// Available fields for filters
export const getAvailableFiltersFields = createSelector(
	getAllowedFiltersFields,
	getActivePermanentFiltersFields,
	(allowedFields: Field[], activeFiltersFields: Field[]): Field[] =>
		allowedFields.filter((key) => !some(activeFiltersFields, { key })),
);
