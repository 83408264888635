import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types';
import { copyInsights, enableCopyInsightsMode, disableCopyInsightsMode } from './copy-insights';
import { createInsight } from './create-insight';
import { deleteInsight } from './delete-insight';
import { loadInsights, updateInsights } from './load-insights';
import { refreshInsight, refreshInsights } from './refresh-insights';
import { refreshSnippet } from './refresh-snippet';
import { toogleInsightSeletion, clearInsightSeletion, deleteSelectedInsights } from './selection';
import { updateInsight } from './update-insight';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	createInsight,
	updateInsight,
	deleteInsight,
	refreshInsight,
	refreshInsights,
	refreshSnippet,
	copyInsights,
	loadInsights,
	deleteSelectedInsights,
	clearInsightSeletion,
	toogleInsightSeletion,
	enableCopyInsightsMode,
	disableCopyInsightsMode,
	updateInsights,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
