import type { StoreApi, ScrollTarget, ScrollTargetType } from '../types';

export const setScrollTarget =
	(scrollTarget: ScrollTarget) =>
	({ setState }: StoreApi) => {
		setState({
			scrollTarget,
		});
	};

export const getScrollTargetId =
	(targetType: ScrollTargetType) =>
	({ getState }: StoreApi) => {
		const state = getState();
		return (
			(state.scrollTarget && state.scrollTarget.type === targetType && state.scrollTarget.id) ||
			null
		);
	};
