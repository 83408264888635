import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { DatePickerValue } from '../../../../common/types/date-picker';
import { FuzzyDatePicker } from '../../../date-picker';
import { FuzzyDate } from '../../../date-picker/fuzzy-date';
import type { FieldComponentProps } from '../types';
import { messages } from './messages';

export const IntervalDateField = ({ onUpdate }: FieldComponentProps) => {
	const { formatMessage } = useIntl();

	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState<string | undefined>();
	const parsedDate = value ? JSON.parse(value) : value;

	const onChangeValue = useCallback(
		(newValue: DatePickerValue | undefined) => {
			const newStringValue = JSON.stringify(newValue);
			if (newStringValue !== value) {
				setValue(newStringValue);
				onUpdate(newStringValue);
			}
			setIsEditing(false);
		},
		[onUpdate, value],
	);

	return (
		<FuzzyDateFieldWrapper isFocused={isEditing}>
			{isEditing ? (
				<FuzzyDatePicker
					value={parsedDate}
					onChange={onChangeValue}
					onClose={() => setIsEditing(false)}
					placeholder={formatMessage(messages.placeholder)}
				/>
			) : (
				<FuzzyDate
					value={parsedDate}
					placeholder={formatMessage(messages.placeholder)}
					onClick={() => setIsEditing(true)}
					onClearDate={() => onChangeValue(undefined)}
				/>
			)}
		</FuzzyDateFieldWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FuzzyDateFieldWrapper = styled.div<{ isFocused: boolean }>({
	boxSizing: 'border-box',
	border: '2px solid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ isFocused }) =>
		isFocused
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.focused', colors.B200)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.border.input', colors.N40A),
	borderRadius: '3px',
	width: '350px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtlest', colors.N200),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		background: 'none !important',
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.input.hovered', colors.N20),
	},
});
