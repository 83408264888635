import React, { memo } from 'react';
import { styled } from '@compiled/react';
import type { RowGroupId, RowId } from '../../common/types';
import type { CellRendererProps } from '../../common/types/react-base-table';
import { PRESENTATIONAL_FIELDKEYS } from '../../constants';
import { useIsCellHovered } from '../../controllers/selectors/cells-hooks';
import {
	useCellComponent,
	useDragHandleComponent,
	useGroupCellComponent,
} from '../../controllers/selectors/components-hooks';
import { useIsRowHovered } from '../../controllers/selectors/rows-hooks';
import { SelectionCheckBox } from './selection-checkbox';

const DraggableHandleRenderer = ({ rowId }: { rowId: RowId | RowGroupId }) => {
	const DraggableHandle = useDragHandleComponent();
	const isRowHovered = useIsRowHovered(rowId);

	return (
		<DragHandleWrapper>
			<DraggableHandle rowId={rowId} isRowHovered={isRowHovered} />
		</DragHandleWrapper>
	);
};

const CellComponentRendererRest = memo<CellRendererProps>((props: CellRendererProps) => {
	const { column, rowData } = props;
	const Cell = useCellComponent();

	const columnId = String(column.key);
	const isCellHovered = useIsCellHovered(rowData.id, columnId);

	return (
		<Cell
			columnId={columnId}
			isHovered={isCellHovered}
			rowId={rowData.id}
			isFirstRow={rowData.index === 0}
		/>
	);
});

const CellComponentRenderer = memo<CellRendererProps>((props: CellRendererProps) => {
	const { column, rowData } = props;

	if (column.key === PRESENTATIONAL_FIELDKEYS.DND) {
		return <DraggableHandleRenderer rowId={rowData.id} />;
	}

	if (column.key === PRESENTATIONAL_FIELDKEYS.SELECT) {
		return <SelectionCheckBox rowId={rowData.id} />;
	}

	if (column.key === PRESENTATIONAL_FIELDKEYS.ADD) {
		return null;
	}

	return <CellComponentRendererRest {...props} />;
});

CellComponentRenderer.displayName = 'CellComponentRenderer';

const GroupCellComponentRenderer = memo<CellRendererProps>((props: CellRendererProps) => {
	const { column, rowData } = props;

	const GroupCell = useGroupCellComponent();

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const isCellHovered = useIsCellHovered(rowData.id, column.key as string);

	return (
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		<GroupCell isHovered={isCellHovered} columnId={column.key as string} groupId={rowData.id} />
	);
});

export const cellRenderer = (props: CellRendererProps) => {
	const isGroupRow = props.rowData.type === 'GROUP';
	const isEmptyRow = props.rowData.type === 'EMPTY';

	if (isEmptyRow) {
		return null;
	}

	if (isGroupRow) {
		return (
			<GroupCellComponentRenderer
				column={props.column}
				columnIndex={props.columnIndex}
				rowData={props.rowData}
				rowIndex={props.rowIndex}
			/>
		);
	}

	return (
		<CellComponentRenderer
			column={props.column}
			columnIndex={props.columnIndex}
			rowData={props.rowData}
			rowIndex={props.rowIndex}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandleWrapper = styled.div({
	width: '100%',
	height: '100%',
	cursor: 'grab',
});
