// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, useCallback } from 'react';
import defer from 'lodash/defer';
import { toAriOrDefault } from '@atlassian/jira-platform-ari';
import { ISSUE_EVENTS_BUNDLED } from '@atlassian/jira-polaris-common/src/common/types/realtime/constants';
import {
	ISSUE_COMMENTED_EVENT,
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import { useFieldActions } from '../../field/main.tsx';
import { useProjectActions } from '../../project/main.tsx';
import { PolarisRealtime } from '../../realtime/index.tsx';
import { useIssueActions } from '../main';
import { useLocalIssueIdsByJiraIssueId } from '../selectors/issue-ids-hooks';
import { useIsInitialized } from '../selectors/meta-hooks';
import { useIsSingleIssueLoaded } from '../selectors/single-issue-hooks';
import { JIRA_SUBSCRIPTION_EVENTS } from './constants';
import { useJiraRealtimeChannels, useJiraRealtimeEventHandler } from './main';

// Batch size for bundled issue events
const ISSUE_EVENTS_BUNDLED_BATCH_SIZE = 100;

const config = {
	bundleEvents: {
		[ISSUE_UPDATED_EVENT]: {
			bundledEventName: ISSUE_EVENTS_BUNDLED,
		},
		[ISSUE_CREATED_EVENT]: {
			bundledEventName: ISSUE_EVENTS_BUNDLED,
		},
		[ISSUE_COMMENTED_EVENT]: {
			bundledEventName: ISSUE_EVENTS_BUNDLED,
		},
	},
	batchSizes: {
		[ISSUE_EVENTS_BUNDLED]: ISSUE_EVENTS_BUNDLED_BATCH_SIZE,
		// no network requests for these events
		[ISSUE_DELETED_EVENT]: Number.MAX_VALUE,
	},
};

type Props = {
	projectId: string | number | undefined;
	onContainerReady: () => void;
};

export const PolarisIssueRealtimeHandler = ({ projectId, onContainerReady }: Props) => {
	const jiraChannels = useJiraRealtimeChannels(projectId);
	const handleJiraEvent = useJiraRealtimeEventHandler(projectId);
	const isSingleIssueLoaded = useIsSingleIssueLoaded();
	const idMap = useLocalIssueIdsByJiraIssueId();
	const isInitialized = useIsInitialized();
	const {
		deleteInsightFromRemote,
		updateInsightFromRemote,
		deletePlayContributionFromRemote,
		upsertPlayContributionFromRemote,
	} = useIssueActions();

	const { updatePlayParams } = useProjectActions();
	const { updatePlayFieldParameters } = useFieldActions();

	const onJoin = useCallback(() => {
		defer(() => onContainerReady());
	}, [onContainerReady]);

	const onInsightCreated = useCallback<ComponentProps<typeof PolarisRealtime>['onInsightCreated']>(
		(event) =>
			updateInsightFromRemote({
				insightId: event.payload.insightId,
				insight: event.payload.insight,
				jiraIssueId: event.payload.issueId,
				lastExternalUpdate: event.payload.updatedTime,
			}),
		[updateInsightFromRemote],
	);

	const onInsightDeleted = useCallback<ComponentProps<typeof PolarisRealtime>['onInsightDeleted']>(
		(event) => deleteInsightFromRemote(event.payload.insightId, event.payload.issueId),
		[deleteInsightFromRemote],
	);

	const onInsightUpdated = useCallback<ComponentProps<typeof PolarisRealtime>['onInsightUpdated']>(
		(event) =>
			updateInsightFromRemote({
				insightId: event.payload.insightId,
				insight: event.payload.insight,
				jiraIssueId: event.payload.issueId,
				lastExternalUpdate: event.payload.updatedTime,
			}),
		[updateInsightFromRemote],
	);

	const onPlayUpdated = useCallback<
		NonNullable<ComponentProps<typeof PolarisRealtime>['onPlayUpdated']>
	>(
		(event) => {
			updatePlayParams(toAriOrDefault(event.payload.playAri, ''), event.payload.parameters);
			updatePlayFieldParameters(
				toAriOrDefault(event.payload.playAri, ''),
				event.payload.parameters,
			);
		},
		[updatePlayParams, updatePlayFieldParameters],
	);

	const onPlayContributionDeleted = useCallback<
		NonNullable<ComponentProps<typeof PolarisRealtime>['onPlayContributionDeleted']>
	>(
		(event) =>
			deletePlayContributionFromRemote({
				playAri: toAriOrDefault(event.payload.playAri, ''),
				contribAri: toAriOrDefault(event.payload.contribAri, ''),
				subjectId: event.payload.subjectId,
			}),
		[deletePlayContributionFromRemote],
	);

	const onPlayContributionUpserted = useCallback<
		NonNullable<ComponentProps<typeof PolarisRealtime>['onPlayContributionUpdated']>
	>(
		(event) =>
			upsertPlayContributionFromRemote({
				projectId: event.payload.projectId,
				playId: event.payload.playId,
				playAri: toAriOrDefault(event.payload.playAri, ''),
				contribId: event.payload.contribId,
				contribAri: toAriOrDefault(event.payload.contribAri, ''),
				subjectId: event.payload.subjectId,
				updated: event.payload.updatedTime,
				contribution: event.payload.contribution,
			}),
		[upsertPlayContributionFromRemote],
	);

	const onPlayContributionСreated = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
		(event) => onPlayContributionUpserted(event),
		[onPlayContributionUpserted],
	);

	/**
	 * An event should be skipped if it relates to an idea that is not
	 * yet loaded. This can happen when an idea is opened in a new tab
	 * and only this idea is loaded. In this case, we want to skip all
	 * events related to other ideas.
	 */
	const shouldSkipEvent = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(event: any) => {
			if (
				event.payload.issueId !== undefined &&
				idMap[event.payload.issueId] === undefined &&
				isSingleIssueLoaded &&
				!isInitialized
			) {
				return true;
			}

			return false;
		},
		[idMap, isInitialized, isSingleIssueLoaded],
	);

	if (!isInitialized && !isSingleIssueLoaded) {
		return null;
	}

	return (
		<PolarisRealtime
			appId="ideas"
			config={config}
			containerType="PROJECT"
			containerId={projectId}
			shouldSkipEvent={shouldSkipEvent}
			additionalChannels={jiraChannels}
			additionalEvents={JIRA_SUBSCRIPTION_EVENTS}
			onJoin={onJoin}
			onInsightCreated={onInsightCreated}
			onInsightDeleted={onInsightDeleted}
			onInsightUpdated={onInsightUpdated}
			onPlayContributionCreated={onPlayContributionСreated}
			onPlayContributionDeleted={onPlayContributionDeleted}
			onPlayContributionUpdated={onPlayContributionUpserted}
			onPlayUpdated={onPlayUpdated}
			onNonPolarisEvent={handleJiraEvent}
		/>
	);
};
