import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	systemField: {
		id: 'polaris-component-field-configuration.common.field-title-with-icon.system-field',
		defaultMessage: 'System',
		description: 'Helper text for label of built in fields',
	},
	systemFieldTooltip: {
		id: 'polaris-component-field-configuration.common.field-title-with-icon.system-field-tooltip',
		defaultMessage: "Jira created this field. You can't edit its name or delete it.",
		description: 'Tooltip text to explain why system field label is not editable or deletable',
	},
});
