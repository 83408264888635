import mapKeys from 'lodash/mapKeys';
import { AnyAri } from '@atlassian/ari/any-ari';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import { asyncSwitchAction } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/actions/index.tsx';
import type { PolarisApiInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/get-insight/types.tsx';
import { transformInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/transform/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	getLocalIssueIdsByJiraIssueId,
	getLocalIssueIdForJiraIssueId,
} from '../../../selectors/issue-ids';
import { createGetInsight } from '../../../selectors/properties/insights';
import type { Props, State } from '../../../types';
import { refreshIssues } from '../../refresh-issues';
import { getInsightTransformed } from '../utils';

export const updateInsights =
	(jiraIssueId: string, insights: Insight[]) =>
	({ dispatch, getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIssueId = getLocalIssueIdForJiraIssueId(jiraIssueId)(state, props);
		// If the issue is not loaded yet, refresh it first and then update the insights
		if (!localIssueId) {
			dispatch(
				refreshIssues({
					jiraIssueIds: [jiraIssueId],
					callback: () => {
						dispatch(updateInsights(jiraIssueId, insights));
					},
				}),
			);
			return;
		}

		setState({
			properties: {
				...state.properties,
				insights: {
					...state.properties.insights,
					[localIssueId]: [
						// Add new insights
						...insights.filter(
							(insight) =>
								!(state.properties.insights[localIssueId] || []).some(
									(existingInsight) => existingInsight.id === insight.id,
								),
						),
						// Update existing insights
						...(state.properties.insights[localIssueId] || []).map((existingInsight) => {
							const newInsight = insights.find((insight) => insight.id === existingInsight.id);
							return newInsight || existingInsight;
						}),
					],
				},
			},
		});
	};

type UpdateInsightFromRemoteArguments = {
	insightId: number;
	jiraIssueId: number;
	lastExternalUpdate: string;
	insight?: PolarisApiInsight;
};

export const updateInsightFromRemote = asyncSwitchAction<
	State,
	Props,
	UpdateInsightFromRemoteArguments,
	Insight | undefined
>(
	({ insightId, insight, jiraIssueId, lastExternalUpdate }, { getState }, props) => {
		const { cloudId, insightsRemote } = props;

		const insightAri = AnyAri.create({
			resourceOwner: 'jira',
			cloudId,
			resourceType: 'polaris-insight',
			resourceId: String(insightId),
		}).toString();

		const state = getState();

		const localInsight = createGetInsight(insightAri)(state, props);
		if (localInsight?.updated === lastExternalUpdate) {
			// already on the newest data, bail and don't refresh
			return Promise.resolve(undefined);
		}

		if (insight) {
			return Promise.resolve(transformInsight(insight));
		}

		return getInsightTransformed(insightsRemote, insightId, jiraIssueId, state, props);
	},
	(insight, { jiraIssueId }, { dispatch }) => {
		if (insight) {
			dispatch(updateInsights(String(jiraIssueId), [insight]));
		}
	},
);

export const updateInsightsFromProps =
	() =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		setState({
			properties: {
				...state.properties,
				insights: mapKeys(props.insights ?? {}, (_, issueId) => localIdMap[issueId]),
			},
		});
	};
