import { useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { MemoryReactionsStore, ReactionServiceClient } from '@atlaskit/reactions';
import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import getMeta from '@atlassian/jira-get-meta';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { jira_polaris_GetReactions_polarisGetReactions as ReactionsKeyValueMap } from './__generated_apollo__/jira_polaris_GetReactions.ts';
import type { PolarisReactionSummary } from './types';

const GET_REACTIONS_QUERY = gqlTagPolaris`
query jira_polaris_GetReactions ($input: PolarisGetReactionsInput!){
    polarisGetReactions(input: $input) @optIn(to: ["polaris-v0"]) {
        key 
        value {  
            ari
            containerAri
            emojiId
            count
            reacted
            users {
                id
                displayName
            }
        }
    }
}
`;

const GET_DETAILED_REACTION_QUERY = gqlTagPolaris`
query jira_polaris_GetDetailedReaction ($input: PolarisGetDetailedReactionInput!){
    polarisGetDetailedReaction(input: $input) @optIn(to: ["polaris-v0"]) {
        ari
        containerAri
        emojiId
        count
        reacted
        users {
            id
            displayName
        }
    }
}
`;

const ADD_REACTION_MUTATION = gqlTagPolaris`
mutation jira_polaris_AddReaction ($input: PolarisAddReactionInput!){
    polarisAddReaction(input: $input) @optIn(to: ["polaris-v0"]) {
        success
        errors {
          message
        }
        node {
            ari
            containerAri
            emojiId
            count
            reacted
            users {
                id
                displayName
            }
        }
    }
}
`;

const DELETE_REACTION_MUTATION = gqlTagPolaris`
mutation jira_polaris_DeleteReaction ($input: PolarisDeleteReactionInput!){
    polarisDeleteReaction(input: $input) @optIn(to: ["polaris-v0"]) {
        success
        errors {
          message
        }
        node {
            ari
            containerAri
            emojiId
            count
            reacted
            users {
                id
                displayName
            }
        }
    }
}
`;

export const getReactions =
	(apolloClient: PolarisApolloClient, reactClient: ReactionServiceClient) =>
	(containerAri: string, aris: string[]) =>
		aris.some((ari) => ari.includes('polaris-comment'))
			? apolloClient
					.query({
						query: GET_REACTIONS_QUERY,
						variables: {
							input: {
								containerAri,
								aris,
							},
						},
					})
					.then((result) => {
						if (result.errors !== undefined) {
							throw new Error(
								`polaris-ideas.get-reactions:${result.errors.map((e) => e.message).join(', ')}`,
							);
						}
						const reactionsMap =
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(result.data?.polarisGetReactions as ReactionsKeyValueMap[]) ?? [];
						const reactions: Record<string, PolarisReactionSummary[]> = {};
						reactionsMap.forEach(({ key, value }) => {
							// deconstructing the value array here since TS doesn't like assigning readonly to non-readonly
							reactions[key] = value.map((reaction) => ({
								...reaction,
								users: reaction.users == null ? undefined : [...reaction.users],
							}));
						});
						return reactions;
					})
			: reactClient.getReactions(containerAri, aris);

export const getDetailedReaction =
	(apolloClient: PolarisApolloClient, reactClient: ReactionServiceClient) =>
	(containerAri: string, ari: string, emojiId: string) =>
		ari.includes('polaris-comment')
			? apolloClient
					.query({
						query: GET_DETAILED_REACTION_QUERY,
						variables: {
							input: {
								containerAri,
								ari,
								emojiId,
							},
						},
					})
					.then((result) => {
						if (result.errors !== undefined) {
							throw new Error(
								`polaris-ideas.get-detailed-reaction:${result.errors
									.map((e) => e.message)
									.join(', ')}`,
							);
						}
						return result.data?.polarisGetDetailedReaction;
					})
			: reactClient.getDetailedReaction(containerAri, ari, emojiId);

export const addReaction =
	(apolloClient: PolarisApolloClient, reactClient: ReactionServiceClient) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(containerAri: string, ari: string, emojiId: string, metadata?: Record<string, any>) =>
		ari.includes('polaris-comment')
			? apolloClient
					.mutate({
						mutation: ADD_REACTION_MUTATION,
						variables: {
							input: {
								containerAri,
								ari,
								emojiId,
								metadata,
							},
						},
					})
					.then((result) => {
						if (result.errors !== undefined) {
							throw new Error(
								`polaris-ideas.add-reaction:${result.errors.map((e) => e.message).join(', ')}`,
							);
						}
						return result.data?.polarisAddReaction?.node;
					})
			: reactClient.addReaction(containerAri, ari, emojiId, metadata);

export const deleteReaction =
	(apolloClient: PolarisApolloClient, reactClient: ReactionServiceClient) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(containerAri: string, ari: string, emojiId: string, metadata?: Record<string, any>) =>
		ari.includes('polaris-comment')
			? apolloClient
					.mutate({
						mutation: DELETE_REACTION_MUTATION,
						variables: {
							input: {
								containerAri,
								ari,
								emojiId,
								metadata,
							},
						},
					})
					.then((result) => {
						if (result.errors !== undefined) {
							throw new Error(
								`polaris-ideas.delete-reaction:${result.errors.map((e) => e.message).join(', ')}`,
							);
						}
						return result.data?.polarisDeleteReaction?.node;
					})
			: reactClient.deleteReaction(containerAri, ari, emojiId, metadata);

export const useReactionClient = () => {
	const apolloClient = useApolloClient();

	return useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const reactClient = new ReactionServiceClient(getMeta('ajs-fabric-reactions-url')!);

		return {
			getReactions: getReactions(apolloClient, reactClient),
			getDetailedReaction: getDetailedReaction(apolloClient, reactClient),
			addReaction: addReaction(apolloClient, reactClient),
			deleteReaction: deleteReaction(apolloClient, reactClient),
		};
	}, [apolloClient]);
};

export const useReactionStore = () => {
	const reactionsClient = useReactionClient();
	return useMemo(() => new MemoryReactionsStore(reactionsClient), [reactionsClient]);
};
