import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisComment } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/comment/__generated_apollo__/PolarisComment.ts';
import { COMMENT_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/comment/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_CreateViewComment as CreateViewComment,
	jira_polaris_CreateViewCommentVariables as CreateViewCommentVariables,
} from './__generated_apollo__/jira_polaris_CreateViewComment';
import type { CreateViewCommentProps } from './types';

const CREATE_VIEW_COMMENT_MUTATION = gqlTagPolaris`
mutation jira_polaris_CreateViewComment(
    $viewId: ID!
    $content: JSON!
  ) {
      createPolarisComment(
          input: {
              kind: VIEW
              subject: $viewId
              content: $content
          }
      ) {
          success
          errors {
              message
          }
          node {
              ...PolarisComment
          }
        }
    }

${COMMENT_FRAGMENT}`;

export const createViewComment = async (
	apolloClient: PolarisApolloClient,
	{ viewId, content }: CreateViewCommentProps,
): Promise<PolarisComment> => {
	const result = await apolloClient.mutate<CreateViewComment, CreateViewCommentVariables>({
		mutation: CREATE_VIEW_COMMENT_MUTATION,
		variables: {
			viewId,
			content,
		},
	});

	const node = result.data?.createPolarisComment?.node;
	if (!node) {
		throw new Error('view-comment.create-view-comment-error: no data or no data node');
	}

	return node;
};
