import { createHigherLevelHook, createHook } from '../index';
import {
	getDecorationForValue,
	getDecorationForValues,
	getDecorations,
	getDecorationsComponents,
} from './decoration';

export const useDecorationForValue = createHigherLevelHook(getDecorationForValue);
export const useDecorationForValues = createHigherLevelHook(getDecorationForValues);
export const useDecorations = createHook(getDecorations);
export const useDecorationsComponents = createHook(getDecorationsComponents);
