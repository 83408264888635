import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter';
import type { FieldComponentProps } from '../types';
import { SelectEdit } from './select-component';

type SelectFieldComponentProps = FieldComponentProps & {
	fieldKey: FieldKey;
	isMulti?: boolean;
};

export const SelectFieldComponent = ({
	fieldKey,
	isMulti = false,
	onUpdate,
	predefinedValue = [],
}: SelectFieldComponentProps) => {
	const [value, setValue] = useState<OptionProperty[]>(predefinedValue);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const closeAndCommit = useCallback(
		(newValue: OptionProperty[]) => {
			if (!newValue.length) {
				onUpdate(undefined);
			} else {
				const newValuePolimorphic = isMulti ? newValue : newValue[0];
				onUpdate(newValuePolimorphic);
			}
			setIsMenuOpen(false);
		},
		[isMulti, onUpdate],
	);

	const onCloseRequested = useCallback(() => {
		closeAndCommit(value);
	}, [closeAndCommit, value]);

	const updateInternal = useCallback(
		(newValue: OptionProperty[], allowClose = true) => {
			setValue(newValue);
			if (!isMulti && allowClose) {
				closeAndCommit(newValue);
			}
		},
		[closeAndCommit, isMulti, setValue],
	);

	return (
		<OutsideClickAlerter onClickOutside={onCloseRequested}>
			{(outsideClickAlerterProps) => (
				<Container {...outsideClickAlerterProps}>
					<SelectEdit
						isMenuOpen={isMenuOpen}
						onOpenMenu={() => setIsMenuOpen(true)}
						selectedOptions={value}
						isMulti={isMulti}
						fieldKey={fieldKey}
						onUpdate={updateInternal}
						onCloseRequested={onCloseRequested}
					/>
				</Container>
			)}
		</OutsideClickAlerter>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: 'fit-content',
});
