import { getSnippetPropertiesConfig } from '../../services/polaris-api/get-snippet-properties-config';
import { setSnippetPropertiesConfig } from '../../services/polaris-api/set-snippet-properties-config';
import type {
	FetchSnippetPropertiesConfigRequest,
	ProviderProps,
	RemoteSnippetPropertiesConfigFetcher,
	RemoteSnippetPropertiesConfigUpdater,
	UpdateSnippetPropertiesConfigRequest,
} from '../../types';

export const fetchSnippetPropertiesConfig =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteSnippetPropertiesConfigFetcher =>
	({ oauthClientId, groupId }: FetchSnippetPropertiesConfigRequest) =>
		getSnippetPropertiesConfig(apolloClient, cloudId, projectId, oauthClientId, groupId);

export const updateSnippetPropertiesConfig =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteSnippetPropertiesConfigUpdater =>
	({ oauthClientId, groupId, config }: UpdateSnippetPropertiesConfigRequest) =>
		setSnippetPropertiesConfig(apolloClient, cloudId, projectId, oauthClientId, groupId, config);
