import React, { type SyntheticEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueLinks } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import type { ClonedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/clone-issue';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useSelectedIssue,
	useSummary,
	useIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';
import { useCloneNotifications } from './notifications';

type CloneButtonProps = {
	isDropdownItem: boolean;
};

export const CloneIdeaButton = ({ isDropdownItem }: CloneButtonProps) => {
	const { formatMessage } = useIntl();
	const localIssueId = useSelectedIssue();
	const summary = useSummary(localIssueId ?? '');
	const issueLinks = useIssueLinks();
	const { clonePolarisIssue } = useIssueActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { onSuccess, onFailure, onStart } = useCloneNotifications();
	const issueKey = useIssueKey(localIssueId ?? '');

	const handleClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent?: UIAnalyticsEvent) => {
			if (localIssueId === undefined) return;

			if (isDropdownItem) {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
					'issueOption',
					{
						selectedActionItem: 'clone',
					},
				);
			} else {
				// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
				fireUIAnalytics(analyticsEvent, 'clone');
			}

			const dismiss = onStart(issueKey);

			clonePolarisIssue(
				localIssueId,
				formatMessage(messages.copiedIdeaSummary, { summary }),
				issueLinks,
				(clonedIssue: ClonedIssue) => {
					dismiss !== undefined && dismiss();
					onSuccess(clonedIssue);
				},
				(error: Error) => {
					dismiss !== undefined && dismiss();
					onFailure(error);
				},
			);
		},
		[
			localIssueId,
			createAnalyticsEvent,
			clonePolarisIssue,
			formatMessage,
			summary,
			issueLinks,
			onSuccess,
			onFailure,
			onStart,
			issueKey,
			isDropdownItem,
		],
	);

	const Component = isDropdownItem ? DropdownItem : Button;
	const testId = isDropdownItem
		? 'polaris-ideas.ui.idea-view.collaboration-controls.clone.dropdown-item'
		: 'polaris-ideas.ui.idea-view.collaboration-controls.clone.button';

	return (
		<Component
			id="polaris-ideas.ui.view-controls.clone-button"
			testId={testId}
			onClick={handleClick}
		>
			{formatMessage(messages.buttonLabel)}
		</Component>
	);
};
