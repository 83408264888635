import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	rename: {
		id: 'polaris-ideas.sidebar.sections.view-section.actions.rename',
		defaultMessage: 'Rename',
		description: 'Label for renaming a view section',
	},
	delete: {
		id: 'polaris-ideas.sidebar.sections.view-section.actions.delete',
		defaultMessage: 'Delete',
		description: 'Label for deleting a view section.',
	},
	onDeleteTooltip: {
		id: 'polaris-ideas.sidebar.sections.view-section.actions.on-delete-tooltip',
		defaultMessage: 'Remove views from this section to delete it',
		description: 'Label for deleting a view section.',
	},
});
