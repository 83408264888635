import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '@atlassian/jira-polaris-domain-collection';
import type { BulkUserResponse } from './types';

export const fetchUsers = async (accountIds: string[]): Promise<User[]> => {
	const query = new URLSearchParams(accountIds.map((accountId) => ['accountId', accountId]));

	return fetchJson<BulkUserResponse>(`/rest/api/3/user/bulk?${query}`).then(
		(response) => response.values,
	);
};
