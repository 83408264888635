import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	save: {
		id: 'polaris-ideas.view-controls.config-extra.save-view-button.save',
		defaultMessage: 'Save for everyone',
		description: '',
	},
	saveAsNew: {
		id: 'polaris-ideas.view-controls.config-extra.save-view-button.save-as-new',
		defaultMessage: 'Save as new view',
		description: '',
	},
	configSaved: {
		id: 'polaris-ideas.view-controls.config-extra.save-view-button.config-saved',
		defaultMessage: 'Configuration saved',
		description: '',
	},
});
