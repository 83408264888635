import { createSelector } from 'reselect';
import forEach from 'lodash/forEach';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import keyBy from 'lodash/keyBy';
import { isAfter } from 'date-fns';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	ISSUEID_FIELDKEY,
	ISSUETYPE_FIELDKEY,
	KEY_FIELDKEY,
	REPORTER_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { toIssueKey, type IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueForMerge, Props, State } from '../types';
import { fieldMapping } from '../utils/field-mapping/index.tsx';
import { stringMapping } from '../utils/field-mapping/string';
import { userMapping } from '../utils/field-mapping/user';
import {
	getCreatedField,
	getCreatorField,
	getDescriptionField,
	getIssueIdField,
} from './field-utils';
import { getFields } from './fields';
import {
	createSpecificallyMemoizedDataSelector,
	getIssueIdsInCreation,
	getJiraIssueIdProperties,
	getNumberProperties,
	getStringProperties,
	getSingleOptionProperties,
} from './properties';
import {
	createGetLinkedIssueData,
	getExternalIssueData,
} from './properties/linked-issues/index.tsx';

export const getRankedIssueIds = (state: State): LocalIssueId[] => state.ids;
export const getRankedIssueCount = (state: State): number => state.ids.length;

export const getLastUpdatedIssueIds = (state: State): LocalIssueId[] => state.lastUpdatedIssueIds;

const getIssueIdRelevantFieldMappings = createSelector(
	getFields,
	getIssueIdField,
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
	({ containerProps }) => containerProps?.issuesRemote!,
	(fields, id, issuesRemote) => [fieldMapping(issuesRemote, fields, id)],
);

const getIssueIdRelevantProperties = createSpecificallyMemoizedDataSelector(
	getIssueIdRelevantFieldMappings,
);

export const getIssuesWithBasicProperties = createSelector(
	getRankedIssueIds,
	getNumberProperties,
	getStringProperties,
	getSingleOptionProperties,
	(_: State, props: Props | undefined) => props?.archivedFieldsConfig,
	(
		localIssueIds,
		numberProperties,
		stringProperties,
		singleOptionProperties,
		archivedFieldsConfig,
	) => {
		const archivedFieldKey = archivedFieldsConfig?.archivedField?.key;
		const isArchivedOption = archivedFieldsConfig?.archivedField?.archivedOption;
		const result = localIssueIds.map((localIssueId) => ({
			localIssueId,
			id: numberProperties[ISSUEID_FIELDKEY]?.[localIssueId],
			key: stringProperties[KEY_FIELDKEY]?.[localIssueId],
			summary: stringProperties[SUMMARY_FIELDKEY]?.[localIssueId],
			isArchived:
				archivedFieldKey !== undefined &&
				isArchivedOption !== undefined &&
				singleOptionProperties[archivedFieldKey]?.[localIssueId]?.id ===
					isArchivedOption.jiraOptionId,
		}));
		return result;
	},
);

const getArchivedSingleOptionProperties = createSelector(
	getSingleOptionProperties,
	(_: State, props: Props | undefined) => props?.archivedFieldsConfig?.archivedField?.key,
	(_: State, props: Props | undefined) =>
		props?.archivedFieldsConfig?.archivedField?.archivedOption?.jiraOptionId,
	(singleOptionProperties, archivedFieldKey, archivedFieldOptionId) =>
		(archivedFieldKey !== undefined &&
			archivedFieldOptionId !== undefined &&
			singleOptionProperties[archivedFieldKey]) ||
		undefined,
);

const getIssueIdsWithArchivedProperty = createSelector(
	getRankedIssueIds,
	getArchivedSingleOptionProperties,
	(_: State, props: Props | undefined) =>
		props?.archivedFieldsConfig?.archivedField?.archivedOption?.jiraOptionId,
	(localIssueIds, archivedSingleOptionProperties, archivedFieldOptionId) => {
		const isArchivedOption = archivedFieldOptionId;
		const result = localIssueIds.map((localIssueId) => ({
			localIssueId,
			isArchived:
				isArchivedOption !== undefined &&
				archivedSingleOptionProperties?.[localIssueId]?.id === archivedFieldOptionId,
		}));
		return result;
	},
);

export const getActiveIssueCount = createSelector(
	getIssuesWithBasicProperties,
	(issuesWithBasicProperties) =>
		issuesWithBasicProperties.filter(({ isArchived }) => !isArchived).length,
);

export const getArchivedIssueCount = createSelector(
	getIssuesWithBasicProperties,
	(issuesWithBasicProperties) =>
		issuesWithBasicProperties.filter(({ isArchived }) => isArchived).length,
);

export const getIssuesWithBasicPropertiesMap = createSelector(
	getIssuesWithBasicProperties,
	(issues) => keyBy(issues, (issue) => issue.localIssueId),
);

export const getJiraIdToLocalIssueId = createSelector(
	getRankedIssueIds,
	getJiraIssueIdProperties,
	(localIssueIds, jiraIssueIds) => {
		const mapping: Record<number, LocalIssueId> = {};
		localIssueIds.forEach((localIssueId) => {
			if (jiraIssueIds[localIssueId] !== undefined) {
				mapping[jiraIssueIds[localIssueId]] = localIssueId;
			}
		});
		return mapping;
	},
);

export const createGetIssuesForMerge = (localIssueIds: LocalIssueId[]) =>
	createSelector(
		getFields,
		getDescriptionField,
		(state: State, props: Props | undefined) => [state, props] as const,
		(fields, descriptionField, [state, props]): IssueForMerge[] => {
			const { properties } = state;
			const result = localIssueIds.map((localIssueId) => ({
				localIssueId,
				id: properties.number[ISSUEID_FIELDKEY]?.[localIssueId],
				key: properties.string[KEY_FIELDKEY]?.[localIssueId],
				summary: properties.string[SUMMARY_FIELDKEY]?.[localIssueId],
				description:
					descriptionField !== undefined
						? fieldMapping(
								// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
								props?.issuesRemote!,
								fields,
								descriptionField,
							).valueAccessor(state, props, localIssueId)
						: undefined,
				issuetype: properties.issueType[ISSUETYPE_FIELDKEY]?.[localIssueId],
				// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
				externalIssueData: createGetLinkedIssueData(localIssueId)(state, props),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				fieldsForUpdate: fields.reduce<Record<string, any>>((fieldsResult, field) => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
					const mapping = fieldMapping(props?.issuesRemote!, fields, field);

					if (
						!mapping ||
						!mapping.isSupportedByIssueUpdateApi ||
						!field.editable ||
						field.key === REPORTER_FIELDKEY ||
						field.key === SUMMARY_FIELDKEY
					) {
						return fieldsResult;
					}

					if (
						field.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE &&
						mapping.valueAccessorRealValues
					) {
						return {
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...fieldsResult,
							[field.key]:
								mapping.getFieldValueForJiraUpdate(
									mapping.valueAccessorRealValues(state, props, localIssueId),
								) || null,
						};
					}

					return {
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...fieldsResult,
						[field.key]:
							mapping.getFieldValueForJiraUpdate(
								mapping.valueAccessor(state, props, localIssueId),
							) || null,
					};
				}, {}),
			}));
			return result;
		},
	);

export const createGetIssuesForIssueSelect = createSelector(
	getRankedIssueIds,
	getFields,
	getDescriptionField,
	(state: State, props: Props | undefined) => [state, props] as const,
	(localIssueIds, fields, descriptionField, [state, props]) => {
		const { properties } = state;
		const archivedFieldKey = props?.archivedFieldsConfig?.archivedField?.key;
		const isArchivedOption = props?.archivedFieldsConfig?.archivedField?.archivedOption;
		return localIssueIds.map((localIssueId) => ({
			localIssueId,
			id: properties.number[ISSUEID_FIELDKEY]?.[localIssueId],
			key: properties.string[KEY_FIELDKEY]?.[localIssueId],
			summary: properties.string[SUMMARY_FIELDKEY]?.[localIssueId],
			issuetype: properties.issueType[ISSUETYPE_FIELDKEY]?.[localIssueId],
			isArchived:
				archivedFieldKey !== undefined &&
				isArchivedOption !== undefined &&
				properties.singleSelect[archivedFieldKey]?.[localIssueId]?.id ===
					isArchivedOption.jiraOptionId,
		}));
	},
);

export const createGetUnarchivedIssuesForIssueSelect = createSelector(
	createGetIssuesForIssueSelect,
	(issues) => issues.filter(({ isArchived }) => !isArchived),
);

export const getIssueIdsForDynamicFormula = createSelector(
	getIssueIdsWithArchivedProperty,
	(_, props) => props?.containsArchived || false,
	getIssueIdsInCreation,
	(issueIdsWithArchivedProperty, containsArchived, inCreation) =>
		issueIdsWithArchivedProperty
			.filter(
				(issue) =>
					((!containsArchived && !issue.isArchived) || (containsArchived && issue.isArchived)) &&
					!inCreation.includes(issue.localIssueId),
			)
			.map((issue) => issue.localIssueId),
);

export const getLocalIssueIdsByJiraIssueId = createSelector(
	getRankedIssueIds,
	getIssueIdRelevantProperties,
	(localIssueIds, [{ properties }]) => {
		const map: Record<IssueId, LocalIssueId> = {};
		if (has(properties.number, ISSUEID_FIELDKEY)) {
			localIssueIds.forEach((localIssueId) => {
				const jiraId = properties.number[ISSUEID_FIELDKEY][localIssueId];
				if (jiraId !== undefined) {
					map[jiraId] = localIssueId;
				}
			});
		}
		return map;
	},
);

const EMPTY_MAP = Object.freeze({});
export const getLocalIssueIdToJiraId = createSelector<
	State,
	Props | undefined,
	LocalIssueId[],
	ReturnType<typeof getIssueIdRelevantProperties>,
	Record<LocalIssueId, IssueId>
>(getRankedIssueIds, getIssueIdRelevantProperties, (localIssueIds, [{ properties }]) => {
	if (!has(properties.number, ISSUEID_FIELDKEY)) {
		return EMPTY_MAP;
	}
	const map: Record<LocalIssueId, IssueId> = {};
	localIssueIds.forEach((localIssueId) => {
		const id = properties.number[ISSUEID_FIELDKEY][localIssueId];
		if (id !== undefined) {
			map[localIssueId] = String(id);
		}
	});
	return map;
});

export const getLocalIssueIdToJiraKey = createSelector(
	getRankedIssueIds,
	getIssueIdRelevantProperties,
	(localIssueIds, [{ properties }]) => {
		if (!has(properties.string, KEY_FIELDKEY) || localIssueIds.length === 0) {
			return EMPTY_MAP;
		}
		const map: Record<string, string> = {};
		localIssueIds.forEach((localIssueId) => {
			const jiraKey = toIssueKey(String(properties.string[KEY_FIELDKEY][localIssueId]));
			if (jiraKey !== undefined) {
				map[localIssueId] = jiraKey;
			}
		});
		return map;
	},
);

export const getLocalIssueIdForJiraIssueId = (jiraIssueId: IssueId) =>
	createSelector(getLocalIssueIdsByJiraIssueId, (idMap) => idMap[+jiraIssueId]);

export const getExternalIssueIdsByJiraIssueId = createSelector(
	getExternalIssueData,
	(externalIssues) => {
		const map: Record<string, string> = {};
		forEach(externalIssues, (issue, key) => {
			map[issue.issueId] = key;
		});
		return map;
	},
);

export const getCurrentUserCreatedIssueIds = createSelector(
	getRankedIssueIds,
	getCreatorField,
	(state: State, props: Props | undefined) => ({ state, props }),
	(localIssueIds, creatorField, { state, props }): LocalIssueId[] => {
		if (!creatorField || !props?.currentUser || !state.containerProps?.issuesRemote) {
			return [];
		}
		const creatorFieldMapping = userMapping(state.containerProps.issuesRemote, creatorField);

		const filterFunction = creatorFieldMapping.getFilter({
			type: 'FIELD',
			field: creatorField?.key,
			fieldType: FIELD_TYPES.CREATOR,
			values: [
				{
					stringValue: props.currentUser,
				},
			],
		});

		if (!isFunction(filterFunction)) {
			return [];
		}

		return localIssueIds.filter((id) =>
			filterFunction(creatorFieldMapping.valueAccessor(state, props, id), state, props, id),
		);
	},
);

// This selector is different from getCurrentUserCreatedIssueIds because it filters out ids of issues that were generated from the template
export const getCurrentUserManuallyCreatedIssueIds = createSelector(
	getCurrentUserCreatedIssueIds,
	getCreatedField,
	(state: State, props: Props | undefined) => ({ state, props }),
	(issueIds, createdField, { state, props }): string[] => {
		if (!createdField || !state.containerProps?.issuesRemote || !props?.projectOnboardedAt) {
			return [];
		}

		const createdFieldMapping = stringMapping(state.containerProps.issuesRemote, createdField);

		return issueIds.filter((id) => {
			const createdAt = createdFieldMapping.valueAccessor(state, undefined, id);

			return (
				createdAt !== undefined &&
				props.projectOnboardedAt &&
				isAfter(new Date(createdAt), new Date(props.projectOnboardedAt))
			);
		});
	},
);
