import React from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '../../common/ui/modal-transition';
import { RefreshErrorDialogContent } from './content';
import messages from './messages';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

export const RefreshErrorsDialog = ({ isOpen, onClose }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ModalTransitionUniversal>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog onClose={onClose} width="medium">
						<ModalHeader>
							<ModalTitle testId="polaris-common.ui.refresh-errors-dialog.data-refresh-errors-title">
								{formatMessage(messages.dialogHeader)}
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<RefreshErrorDialogContent />
						</ModalBody>
						<ModalFooter>
							<Button onClick={onClose}>{formatMessage(messages.dialogConfirm)}</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransitionUniversal>
	);
};
