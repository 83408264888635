import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ApplicationEdition, Application } from '@atlassian/jira-shared-types';

// OPEN_DELETE_ISSUE_MODAL
export const OPEN_DELETE_ISSUE_MODAL = 'OPEN_DELETE_ISSUE_MODAL' as const;

export const openDeleteIssueModal = () => ({
	type: OPEN_DELETE_ISSUE_MODAL,
});

export type OpenDeleteIssueModal = ReturnType<typeof openDeleteIssueModal>;

// CLOSE_DELETE_ISSUE_MODAL
export const CLOSE_DELETE_ISSUE_MODAL = 'CLOSE_DELETE_ISSUE_MODAL' as const;

export const closeDeleteIssueModal = () => ({
	type: CLOSE_DELETE_ISSUE_MODAL,
});

export type CloseDeleteIssueModal = ReturnType<typeof closeDeleteIssueModal>;

// DELETE_ISSUE_REQUEST
export const DELETE_ISSUE_REQUEST = 'DELETE_ISSUE_REQUEST' as const;

export const deleteIssueRequest = (
	issueKey: string,
	analyticsEvent: UIAnalyticsEvent,
	application: Application | undefined = undefined,
	edition: ApplicationEdition | undefined = undefined,
) => ({
	type: DELETE_ISSUE_REQUEST,
	payload: { issueKey, analyticsEvent, application, edition },
});

export type DeleteIssueRequest = ReturnType<typeof deleteIssueRequest>;

// DELETE_ISSUE_SUCCESS
export const DELETE_ISSUE_SUCCESS = 'DELETE_ISSUE_SUCCESS' as const;

export const deleteIssueSuccess = (issueKey: string) => ({
	type: DELETE_ISSUE_SUCCESS,
	payload: issueKey,
});

export type DeleteIssueSuccess = ReturnType<typeof deleteIssueSuccess>;

// DELETE_ISSUE_FAILURE
export const DELETE_ISSUE_FAILURE = 'DELETE_ISSUE_FAILURE' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteIssueFailure = (issueKey: string, error: any) => ({
	type: DELETE_ISSUE_FAILURE,
	payload: {
		issueKey,
		error,
	},
});

export type DeleteIssueFailure = ReturnType<typeof deleteIssueFailure>;

export type IssueDeleteAction =
	| OpenDeleteIssueModal
	| CloseDeleteIssueModal
	| DeleteIssueRequest
	| DeleteIssueSuccess
	| DeleteIssueFailure;
