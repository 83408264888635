import { createHigherLevelHook, createHook } from '../index';
import {
	getAreFiltersActive,
	getFieldScopeFilter,
	getFilteredFieldsKeys,
	getSearchQueryFilter,
	getSorting,
} from './fields';

export const useFilteredFieldsKeys = createHigherLevelHook(getFilteredFieldsKeys);

export const useFieldsScopeFilter = createHook(getFieldScopeFilter);
export const useSearchQueryFilter = createHook(getSearchQueryFilter);
export const useAreFiltersActive = createHook(getAreFiltersActive);

export const useSorting = createHook(getSorting);
