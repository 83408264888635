import { JiraPriorityAri } from '@atlassian/ari/jira/priority';
import {
	type PriorityPublicSetValue,
	priorityScopes,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/priority';
import type { PublicToInternalPropertyTransformerContext } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types';
import type {
	FieldOptionsFilter,
	OptionsVisibility,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types/options-visibility';
import { commonIssueViewGranularScopes } from './fields-scopes';

type PriorityPublicGetValue = {
	id: string;
	name: string;
	iconUrl?: string;
} | null;

type PriorityInternalIdShapeValue = { id: string };

type PriorityInternalFullShapeValue = {
	id: string;
	name: string;
	iconUrl?: string;
} | null;

export const transformPrioritySetValue = (
	value: PriorityPublicSetValue,
): PriorityInternalIdShapeValue => ({ id: value });

export const transformPriorityGetValue = (
	priority: PriorityInternalFullShapeValue,
): PriorityPublicGetValue => {
	if (!priority) {
		return null;
	}

	const { id, name, iconUrl } = priority;
	return {
		id,
		name,
		iconUrl,
	};
};

export const priorityIssueViewScopes = {
	...priorityScopes,
	granular: [...priorityScopes.granular, ...commonIssueViewGranularScopes],
};

export const transformSetOptionsVisibilityAriPriority = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	{ tenantContext }: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: options.map((optionId) =>
		JiraPriorityAri.create({
			priorityId: optionId,
			siteId: tenantContext.cloudId,
			activationId: tenantContext.activationId,
		}).toString(),
	),
});
