import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { ff } from '@atlassian/jira-feature-flagging';
import { FetchError, isHttpClientErrorResponse } from '@atlassian/jira-fetch';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import { deleteRemoteIssueLink } from '@atlassian/jira-issue-view-services/src/issue/remote-issue-link-delete-server';
import {
	deleteRemoteLinkedIssueSuccess,
	deleteRemoteLinkedIssueFailure,
	DELETE_REMOTE_LINKED_ISSUE_REQUEST,
	type DeleteRemoteLinkedIssueRequest,
} from '@atlassian/jira-issue-view-store/src/actions/remote-issue-links-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	EXPERIENCE_DELETE_ISSUE_LINK_FAILED,
	EXPERIENCE_DELETE_ISSUE_LINK_SUCCEEDED,
	SOURCE_JIRA_LINKED_ISSUES,
} from './constants';

export const deleteRemoteLinkedIssuesEpic = (
	action$: ActionsObservable<DeleteRemoteLinkedIssueRequest>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(DELETE_REMOTE_LINKED_ISSUE_REQUEST).mergeMap((action) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);
		const { globalId, analyticsEvent } = action.payload;
		const { application, edition } = action.payload;

		return deleteRemoteIssueLink(baseUrl, issueKey, globalId)
			.map(() => {
				analyticsEvent && fireOperationalAnalytics(analyticsEvent, 'remoteLinkedIssue deleted');
				return deleteRemoteLinkedIssueSuccess(globalId);
			})
			.do(() => {
				sendExperienceAnalytics({
					experience: EXPERIENCE_DELETE_ISSUE_LINK_SUCCEEDED,
					wasExperienceSuccesful: true,
					analyticsSource: SOURCE_JIRA_LINKED_ISSUES,
					application: application ?? null,
					edition: edition ?? null,
				});
			})
			.catch((error) => {
				if (
					!(
						ff('ken-429-restrict-4xx-error_vi8v7') &&
						error instanceof FetchError &&
						isHttpClientErrorResponse(error)
					)
				) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'issue.issue-links.delete-issue-link-error',
							teamName: 'bento',
						},
						attributes: {
							experience: EXPERIENCE_DELETE_ISSUE_LINK_FAILED,
							analyticsSource: SOURCE_JIRA_LINKED_ISSUES,
							application: application ?? null,
							edition: edition ?? null,
						},
					});
				}
				analyticsEvent &&
					fireOperationalAnalytics(analyticsEvent, 'remoteLinkedIssue deleteFailed');
				trackOrLogClientError('issue.issue-links.delete-issue-link-error', 'remote', error);
				return Observable.of(deleteRemoteLinkedIssueFailure(globalId));
			});
	});
