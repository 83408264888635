import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisViewLastViewed } from '@atlassian/jira-polaris-domain-view/src/last-viewed/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
import type { ViewRemote } from '@atlassian/jira-polaris-remote-view/src/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { updateViewState } from '../utils/state';

const LAST_VIEWED_TIMESTAMP_THRESHOLD = 5 * 60 * 1000; // 5 minutes

export const updateLastViewedState =
	(viewId: Ari, lastViewed: PolarisViewLastViewed[] = []) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			(view: View): boolean => view.viewId === viewId,
			(view: View) => ({
				...view,
				lastViewed,
				hasUserVisited: true,
			}),
		);

		if (changedView) {
			setState({ viewSets });
		}
		return changedView;
	};

const setCurrentUserInLastViewed = (
	lastViewed: Array<never> | Array<PolarisViewLastViewed>,
	currentUser: undefined | AccountId,
) => {
	const isCurrentUser = (lv: PolarisViewLastViewed) =>
		lv.aaid !== undefined && lv.aaid === currentUser;

	return lastViewed.map((lv) => ({
		...lv,
		timestamp: isCurrentUser(lv) ? new Date() : new Date(lv.timestamp),
		isCurrentUser: isCurrentUser(lv),
	}));
};

const getCurrentUserViewLastViewedTimestamp = (view: View) => {
	if (!view.viewId || !view.lastViewed) return undefined;

	return view.lastViewed.find((lv) => lv.isCurrentUser)?.timestamp;
};

const getOrFetchLastViewed = async (
	view: View,
	viewRemote: ViewRemote,
	currentUser: undefined | AccountId,
) => {
	if (!view.viewId) return [];

	if (view.lastViewed && currentUser) {
		return [
			...view.lastViewed.filter((lv) => !lv.isCurrentUser),
			{ aaid: currentUser, isCurrentUser: true, timestamp: new Date() },
		];
	}

	const { lastViewed = [] } = await viewRemote.fetchViewLastViewed(view.viewId);

	return setCurrentUserInLastViewed(lastViewed, currentUser);
};

export const loadViewLastViewed =
	(view: View) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ currentUser, viewRemote, onActionFailed }: Props,
	) => {
		try {
			if (
				!view?.viewId ||
				view.containsArchived ||
				!viewRemote.fetchViewLastViewed ||
				!viewRemote.updateViewLastViewed
			) {
				return;
			}

			const currentUserViewLastViewedTimestamp = getCurrentUserViewLastViewedTimestamp(view);
			const lastViewed = await getOrFetchLastViewed(view, viewRemote, currentUser);

			if (
				currentUserViewLastViewedTimestamp !== undefined &&
				// if last viewed is older than LAST_VIEWED_TIMESTAMP_THRESHOLD, update it
				currentUserViewLastViewedTimestamp.getTime() + LAST_VIEWED_TIMESTAMP_THRESHOLD > Date.now()
			) {
				return;
			}

			// last viewed updater should run only after awaited getLastViewed query to avoid race conditions between them
			// e.g. if updater finishes first than query returns updated result and we would have incorrect UI state
			// like About section won't open for the first time user visited the view
			viewRemote
				.updateViewLastViewed({
					viewId: view.viewId,
					lastViewed: new Date().toISOString(),
				})
				.catch(onActionFailed);

			const { changedView, viewSets } = updateViewState(
				getState().viewSets,
				(v: View): boolean => v.viewId === view.viewId,
				(v: View) => ({
					...v,
					lastViewed,
					hasUserVisited: lastViewed.some((lv) => lv.isCurrentUser),
				}),
			);

			if (changedView) {
				setState({ viewSets });
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.views.actions.last-viewed.load-view-last-viewed',
					error,
				),
			);

			if (!(error instanceof ViewNotFoundError)) {
				throw error;
			}
		}
	};
