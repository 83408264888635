// eslint-disable-next-line jira/restricted/react-component-props
import React, { useCallback, type ComponentProps } from 'react';
import type { Dispatch } from 'redux';
import { lazy } from 'react-loosely-lazy';
import { ModalTransition } from '@atlaskit/modal-dialog';
import type AssignIssueParentModalType from '@atlassian/jira-assign-issue-parent-modal';
import type { Issue } from '@atlassian/jira-assign-issue-parent-modal';
import { AssignIssueParentModalBoundary } from '@atlassian/jira-assign-issue-parent-modal/async';
import { useIssueKey } from '@atlassian/jira-issue-context-service';
import { useParentFieldSuggestionsCache } from '@atlassian/jira-issue-fields-suggestions-cache';
import type { IssueParent } from '@atlassian/jira-issue-parent-services';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { epicModalOpenInteraction } from '@atlassian/jira-issue-view-common-utils/src/utils/performance-analytics';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	type Action,
	hideAssignIssueParentModal,
	setParentOptimistic,
} from '@atlassian/jira-issue-view-store/src/actions/assign-issue-parent-modal-actions';
import {
	analyticsSourceSelector,
	baseUrlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	parentLevelIssuesSelector,
	projectIdSelector,
	isSimplifiedProjectSelector,
	projectIdsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	hierarchyLevelSelector,
	parentLevelIssueTypeIdsSelector,
	parentLevelIssueTypeNamesSelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector';
import {
	assignIssueParentModalIssuesSelector,
	isAssignIssueParentModalOpen,
	shouldHideUnassignOptionSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/assign-issue-parent-modal-selector';
import type { BaseUrl, IssueId } from '@atlassian/jira-shared-types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AssignIssueParentModal = lazy<typeof AssignIssueParentModalType>(
	() =>
		import(
			/* webpackChunkName: "async-assign-issue-parent-modal" */ '@atlassian/jira-assign-issue-parent-modal'
		),
);

type StateProps = {
	isOpen: boolean;
	issues: Issue[];
	issueParents: IssueParent[];
	issueHierarchyLevel: number | null;
	isSimplifiedProject: boolean;
	issueParentTypeIds: string[];
	shouldHideUnassignOption: boolean;
	parentIssueTypeName: string | undefined;
	baseUrl: BaseUrl;
	currentProjectId: number;
	projectIds: number[];
	analyticsSource: string;
};

export const mapStateToProps = (state: State): StateProps => {
	const analyticsSource = analyticsSourceSelector(state);
	const projectId = projectIdSelector(state);
	const projectIds = projectIdsSelector(state);

	return {
		isOpen: isAssignIssueParentModalOpen(state),
		issues: assignIssueParentModalIssuesSelector(state),
		issueParents: parentLevelIssuesSelector(state),
		issueHierarchyLevel: hierarchyLevelSelector(state),
		isSimplifiedProject: isSimplifiedProjectSelector(state),
		issueParentTypeIds: parentLevelIssueTypeIdsSelector(state),
		shouldHideUnassignOption: shouldHideUnassignOptionSelector(state),
		parentIssueTypeName: parentLevelIssueTypeNamesSelector(state)[0],
		baseUrl: baseUrlSelector(state),
		// @ts-expect-error - TS2322 - Type 'number | null' is not assignable to type 'number'.
		currentProjectId: projectId,
		projectIds,
		analyticsSource: `issue-view (${String(analyticsSource)})`,
	};
};

type DispatchProps = {
	onClose: () => void;
	onSubmit: JSX.LibraryManagedAttributes<
		typeof AssignIssueParentModalType,
		ComponentProps<typeof AssignIssueParentModalType>
	>['onSubmit'];
};

export const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
	onClose: () => {
		dispatch(hideAssignIssueParentModal());
	},
	onSubmit: (
		issues: Issue[],
		issueParentId: IssueId | null,
		issueParent: IssueParent | null,
		requestPromise: Promise<undefined>,
	) => {
		// @ts-expect-error - Argument of type '{ type: "SET_PARENT_OPTIMISTIC"; payload: { issues: Issue[]; parentIssue: IssueParent | null; requestPromise: Promise<unknown>; }; }' is not assignable to parameter of type 'Action'.
		dispatch(setParentOptimistic(issues, issueParent, requestPromise));
	},
});

type Props = StateProps & DispatchProps;

const ModalWithTransition = ({ isOpen, onSubmit, ...props }: Props) => {
	const onReady = useCallback(() => {
		epicModalOpenInteraction.stop();
	}, []);

	const issueKey = useIssueKey();
	const { setRecentlySelectedParent } = useParentFieldSuggestionsCache({ issueKey });

	const handleSubmit = (
		issues: Issue[],
		issueParentId: IssueId | null,
		issueParent: IssueParent | null,
		requestPromise: Promise<undefined>,
	) => {
		issueParent && setRecentlySelectedParent(issueParent);

		onSubmit(issues, issueParentId, issueParent, requestPromise);
	};

	return (
		<ModalTransition>
			{isOpen && (
				<AssignIssueParentModalBoundary packageName="issue">
					<AssignIssueParentModal {...props} onReady={onReady} onSubmit={handleSubmit} />
				</AssignIssueParentModalBoundary>
			)}
		</ModalTransition>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWithTransition);
