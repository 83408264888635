import React, { Component, type ReactNode } from 'react';
import { styled as styledNew } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { keyframes } from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { StatusValue } from '../../../../common/types';

type Props = {
	status: StatusValue;
	children: ReactNode;
};

type State = {
	shouldShow: boolean;
};

// TODO consolidate status id's
const DONE_ID = 3;

// eslint-disable-next-line jira/react/no-class-components
export default class Pulse extends Component<Props, State> {
	state = {
		shouldShow: false,
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.status.statusCategory.id !== this.props.status.statusCategory.id) {
			this.setState({
				shouldShow: this.props.status.statusCategory.id === DONE_ID,
			});
		}
	}

	render() {
		return (
			<PulseContainer shouldShow={this.state.shouldShow}>{this.props.children}</PulseContainer>
		);
	}
}

const PulseAnimation = keyframes({
	to: {
		boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const PulseContainer = styledNew.div<{ shouldShow?: boolean }>({
	display: 'inline-flex',
	width: '100%',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: `0 0 0 0 ${token('color.border.success', colors.G400)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	animation: ({ shouldShow }) =>
		shouldShow ? `${PulseAnimation} 1.45s cubic-bezier(0.5, 0, 0, 1)` : 'none',
});
