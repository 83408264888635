import type { RemoteNavigationView } from '../../../../common/types/remote';
import type { NavigationView } from '../../types';
import { transformRemoteView } from '../utils';

const copyInternalProps = (currentView: NavigationView, newView: NavigationView) => ({
	...newView,
	localId: currentView.localId,
	isRenaming: currentView.isRenaming,
	isLocked: currentView.isLocked,
	isExpanded: currentView.isExpanded,
});

export const mergeViews = (currentViews: NavigationView[], remoteViews: RemoteNavigationView[]) => {
	const currentViewsMap = new Map<string, NavigationView>();
	currentViews.forEach((view) => {
		currentViewsMap.set(view.id, view);
		view.views?.forEach((innerView) => {
			currentViewsMap.set(innerView.id, innerView);
		});
	});

	return remoteViews?.map(transformRemoteView).map((view) => {
		if (currentViewsMap.has(view.id)) {
			const currentView = currentViewsMap.get(view.id);
			const newView = {
				...view,
				views: view.views?.map((innerView) => {
					const innerCurrentView = currentViewsMap.get(innerView.id);
					if (innerCurrentView) {
						return copyInternalProps(innerCurrentView, innerView);
					}
					return innerView;
				}),
			};
			return currentView ? copyInternalProps(currentView, newView) : newView;
		}
		return view;
	});
};
