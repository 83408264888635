import React, { useState } from 'react';
import Select from '@atlaskit/select';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { Input, Option, SingleValue, type OptionType } from './select-components';
import { useAccessLevelOptions } from './utils';

type Props = {
	accessLevel: ViewAccessLevel;
	isDisabled?: boolean;
	onUpdateAccessLevel: (accessLevel: ViewAccessLevel) => Promise<void>;
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
};

export const AccessLevelSelect = ({
	accessLevel,
	isDisabled = false,
	onMenuOpen,
	onMenuClose,
	onUpdateAccessLevel,
}: Props) => {
	const accessLevelOptions = useAccessLevelOptions();
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Select<OptionType, false>
			options={accessLevelOptions}
			onChange={async (option) => {
				if (option && option.value !== accessLevel) {
					setIsLoading(true);
					await onUpdateAccessLevel(option.value);
					setIsLoading(false);
				}
			}}
			components={{
				Option,
				SingleValue,
				Input,
			}}
			menuPosition="fixed"
			isLoading={isLoading}
			isDisabled={isDisabled}
			onMenuOpen={onMenuOpen}
			onMenuClose={onMenuClose}
			value={accessLevelOptions.find((option) => option.value === accessLevel)}
		/>
	);
};
