import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	filtersButton: {
		id: 'polaris-ideas.view-controls.filters-button',
		defaultMessage: 'Filter',
		description: '',
	},
	fieldsButton: {
		id: 'polaris-ideas.view-controls.fields-button',
		defaultMessage: 'Fields',
		description: '',
	},
	closeConfig: {
		id: 'polaris-ideas.view-controls.close-config',
		defaultMessage: 'Close',
		description: '',
	},
});
