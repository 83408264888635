import React, { useState, type MouseEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import AkTooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service';
import { useSummaryField } from '@atlassian/jira-issue-field-summary';
import PermalinkButton from '@atlassian/jira-issue-view-common/src/component/permalink-button';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types';
import IssueTypeChanger from '../../change-issue-type';
import NoneditableIssueType from '../../noneditable-issue-type';
import messages from '../messages';
import { IssueContainer, SmallBreadcrumbsItem } from '../styled';

export type Props = {
	isSimplifiedProject: boolean;
	isIssueTypeFieldEditable: boolean;
	issueKey: IssueKey;
	issueTypeName: string;
	issueTypeIconUrl: string;
	relativeIssueUrl: string;
	fullIssueUrl: string;
	onClick: (event: MouseEvent) => void;
	onCopyClick: (event: UIAnalyticsEvent) => void;
};

export const CopyLink = ({
	isVisible,
	fullIssueUrl,
	onCopyClick,
}: {
	isVisible: boolean;
	fullIssueUrl: string;
	onCopyClick: (event: UIAnalyticsEvent) => void;
}) => {
	const { formatMessage } = useIntl();

	const label = formatMessage(
		expVal('issue-terminology-refresh-m1-fade', 'isIssueTermFaded', false)
			? messages.copyLink
			: messages.copy,
	);

	return (
		<PermalinkButton
			id="BreadcrumbCurrentIssue"
			label={label}
			fullIssueUrl={fullIssueUrl}
			// @ts-expect-error the type definition expects (AnalyticsEvent) but instead receives (SyntheticEvent, AnalyticsEvent)
			onCopyClick={onCopyClick}
			isVisible={isVisible}
		/>
	);
};

export const BreadcrumbsWithTooltip = ({
	isSimplifiedProject,
	issueKey,
	relativeIssueUrl,
	onClick,
}: {
	isSimplifiedProject: boolean;
	issueKey: IssueKey;
	relativeIssueUrl: string;
	onClick: (event: MouseEvent) => void;
}) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = ff('relay-migration-issue-fields-summary_eog1v') ? useIssueId() : undefined;
	const [{ value: summaryValue }] = useSummaryField({ issueKey, issueId });
	const breadCrumbsTooltip = summaryValue || '';

	return isSimplifiedProject ? (
		<AkTooltip
			content={`${issueKey}: ${breadCrumbsTooltip}`}
			position="top"
			delay={0}
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.foundation.breadcrumbs.current-issue.tooltip"
		>
			<BreadcrumbsItem
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.current-issue.item"
				key="issueKeyBreadcrumbItem"
				href={relativeIssueUrl}
				onClick={onClick}
				text={issueKey}
				target="_blank"
				component={SmallBreadcrumbsItem}
			/>
		</AkTooltip>
	) : (
		<div>
			<BreadcrumbsItem
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.current-issue.item"
				key="issueKeyBreadcrumbItem"
				href={relativeIssueUrl}
				onClick={onClick}
				text={issueKey}
				target="_blank"
				component={SmallBreadcrumbsItem}
			/>
		</div>
	);
};

export const BreadcrumbCurrentIssue = ({
	isSimplifiedProject = false,
	isIssueTypeFieldEditable = false,
	issueKey = toIssueKey(''),
	issueTypeName = '',
	issueTypeIconUrl = '',
	relativeIssueUrl = '',
	fullIssueUrl = '',
	onClick = noop,
	onCopyClick = noop,
}: Props) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const show = () => setIsVisible(true);
	const hide = () => setIsVisible(false);

	return (
		<IssueContainer
			onMouseLeave={hide}
			onMouseEnter={show}
			onFocus={show}
			onBlur={hide}
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.breadcrumbs.breadcrumb-current-issue-container"
			fixExtraDivider
		>
			{isIssueTypeFieldEditable ? (
				<IssueTypeChanger
					key="issueTypeChanger"
					icon={<AsyncIcon url={issueTypeIconUrl} alt={issueTypeName} />}
				/>
			) : (
				<NoneditableIssueType issueTypeName={issueTypeName} issueTypeIconUrl={issueTypeIconUrl} />
			)}
			<BreadcrumbsWithTooltip
				isSimplifiedProject={isSimplifiedProject}
				issueKey={issueKey}
				relativeIssueUrl={relativeIssueUrl}
				onClick={onClick}
			/>
			<CopyLink isVisible={isVisible} fullIssueUrl={fullIssueUrl} onCopyClick={onCopyClick} />
		</IssueContainer>
	);
};

export default BreadcrumbCurrentIssue;
