import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manualRankDescription: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.manual-rank-description',
		defaultMessage: 'Ideas are ranked manually',
		description: 'Indicator that ideas can be ranked by the user',
	},
	viewRankHeader: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.view-rank-header',
		defaultMessage: 'View rank',
		description: 'Header of the view rank description',
	},
	viewRankDescription: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.view-rank-description',
		defaultMessage:
			'The rank of ideas is specific to this view and isn’t shared with other views in this project',
		description: 'the view rank description',
	},
	changeToProjectRankModeTrigger: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.change-to-project-rank-mode-trigger',
		defaultMessage: 'Change to project rank',
		description: 'caption on trigger button to change sort mode to project rank',
	},
	changeToProjectRankModeTooltip: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.change-to-project-rank-mode-tooltip',
		defaultMessage: 'The rank of ideas will be shared across views in this project',
		description: 'tooltip on button to change sort mode to project rank',
	},
	backToProjectRank: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.back-to-project-rank',
		defaultMessage: 'Go back to project rank',
		description: 'caption to go back to project rank sort mode',
	},
	backToProjectRankDescription: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.back-to-project-rank-description',
		defaultMessage: 'The order of ideas in this view will be replaced by the project rank',
		description: 'description to go back to project rank sort mode',
	},
	backToProjectRankWithUpdate: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.back-to-project-rank-with-update',
		defaultMessage: 'Update project rank',
		description:
			'caption to go back to project rank sort mode and update it with the current view rank',
	},
	backToProjectRankWithUpdateDescription: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.back-to-project-rank-with-update-description',
		defaultMessage:
			'The order of ideas in this view will be used as the new project rank, shared across views',
		description:
			'description to go back to project rank sort mode and update it with the current view rank',
	},
	switchedSortModeHeader: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.switched-sort-mode-header',
		defaultMessage: 'Rank was updated',
		description: 'header shown for flag indicating that the view sort mode was switched',
	},
	switchedSortModeToProjectRank: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank.switched-sort-mode-to-project-rank',
		defaultMessage: 'This view has a project rank now',
		description:
			'message indicating that the sort mode of the view has been switched to project rank.',
	},
});
