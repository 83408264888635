import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	appsViewHeader: {
		id: 'polaris-common.apps.header.apps-view-header',
		defaultMessage: 'Connect your apps',
		description: 'Header text for the connect your apps page',
	},
	appsViewSubHeader: {
		id: 'polaris-common.apps.header.apps-view-sub-header',
		defaultMessage:
			'Quickly capture ideas, user feedback and supporting data from anywhere, then use it to help you prioritize.',
		description: 'Explanation / subtitle text for the connect your apps page',
	},
});
