import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useFieldFormula } from '../../../controllers/field/selectors/field-hooks';
import { useRefreshErrorSnippetsIds } from '../../../controllers/issue/selectors/properties/insights/hooks';
import { useRollupFormulaParser } from '../../config/fields/common/rollup-formula-item/utils/formula-parser/index.tsx';
import { RefreshErrorsDialog } from '../../refresh-errors-dialog';
import { InfoMarker } from '../info-marker';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
};

export const FormulaFieldMarker = ({ fieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const formula = useFieldFormula(fieldKey);
	const refreshErrorSnippetsIds = useRefreshErrorSnippetsIds();
	const rollupFormulaParser = useRollupFormulaParser();
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const formulaInputError = useMemo(() => {
		if (!formula) return null;

		const { rollupFields } = rollupFormulaParser(formula);

		return rollupFields.some(
			({ value }) => 'oauthClientId' in value && refreshErrorSnippetsIds.has(value.oauthClientId),
		);
	}, [formula, refreshErrorSnippetsIds, rollupFormulaParser]);

	const closeDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	if (!formulaInputError) return null;

	return (
		<>
			<InfoMarker
				onClick={() => setIsDialogOpen(true)}
				tooltipContent={formatMessage(messages.infoMessage)}
				marginRight={3}
			/>
			<RefreshErrorsDialog onClose={closeDialog} isOpen={isDialogOpen} />
		</>
	);
};
