import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { CONTINUOUS_PADDING_PIXELS } from '../../../common/constants';
import { removeSelectionFromStorage, setSelectionToStorage } from '../../../common/utils/storage';
import type { ItemId } from '../../../types';
import { createGetRelativeItemCenterPosition } from '../../selectors/positions';
import { getDragX, getDragY } from '../../selectors/ui';
import type { HoveredItem, RectangleArea, State } from '../../types';

export const setCurrentViewId =
	(viewId?: string) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			viewId,
		});
	};

export const setHoveredItems =
	(hoveredItems?: HoveredItem) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			hoveredItems,
		});
	};

export const setHighlightedItem =
	(highlightedItem?: ItemId) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			highlightedItem,
		});
	};

export const setSelectedItems =
	(selectedItems?: ItemId[]) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const currentViewId = getState().viewId;
		if (currentViewId !== undefined && selectedItems) {
			if (selectedItems.length > 0) {
				setSelectionToStorage(currentViewId, selectedItems);
			} else {
				removeSelectionFromStorage(currentViewId);
			}
		}
		setState({
			selectedItems,
		});
	};

export const setSelectedItemsWithCollision =
	({ x1, x2, y1, y2 }: RectangleArea, isXAxisContinuous: boolean, isYAxisContinuous: boolean) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const state = getState();
		// selected area rectangle has to contain the center position of an item
		const selectedItems = state.items.filter((itemId) => {
			const getRelativeCenterPosition = createGetRelativeItemCenterPosition(itemId);

			const { left, top } = getRelativeCenterPosition(state);

			if (state.matrixContainer !== undefined) {
				const containerWidth = state.matrixContainer.width;
				const containerHeight = state.matrixContainer.height;

				// Left and top denotes relative (%) positioning in inner container
				// which starts immediately in discrete and after a padding in continuous axes.
				// for this, we need to calculate the correct pixel position on the parent container
				// by first calculating the correct position in inner container and adding padding amount
				let leftPx: number;
				if (isXAxisContinuous) {
					leftPx =
						CONTINUOUS_PADDING_PIXELS +
						(left * (containerWidth - CONTINUOUS_PADDING_PIXELS * 2)) / 100;
				} else {
					leftPx = (left * containerWidth) / 100;
				}

				let topPx: number;
				if (isYAxisContinuous) {
					topPx =
						CONTINUOUS_PADDING_PIXELS +
						(top * (containerHeight - CONTINUOUS_PADDING_PIXELS * 2)) / 100;
				} else {
					topPx = (top * containerHeight) / 100;
				}

				return x1 <= leftPx && leftPx <= x2 && y1 <= topPx && topPx <= y2;
			}
			return false;
		});
		setState({
			selectedItems,
		});
	};

export const setDragValue =
	(x: number, y: number) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const state = getState();
		if (getDragX(state) === x && getDragY(state) === y) {
			return;
		}
		setState({
			dragValue: {
				x,
				y,
			},
		});
	};

export const clearDragValue =
	() =>
	({ setState, getState }: StoreActionApi<State>) => {
		if (getState().dragValue === undefined) {
			return;
		}
		setState({
			dragValue: undefined,
		});
	};
