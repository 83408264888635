import type { IssueId, RemoteLinkGlobalId } from '@atlassian/jira-shared-types/src';
import type { RemoteIssueLinkPreview } from './remote-issue-link-type';

export const REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID = 'WITH_ISSUE_ID' as const;
export const REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID = 'WITHOUT_ISSUE_ID' as const;

type RemoteIssueLinkPayloadType =
	| typeof REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID
	| typeof REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID;

type BaseRemoteIssueLinkPayload = RemoteIssueLinkPreview & {
	createReciprocalLink?: boolean;
	payloadType: RemoteIssueLinkPayloadType;
};

// `globalId` cannot be constructed without issue ID
type RemoteIssueLinkPayloadWithIssueId = BaseRemoteIssueLinkPayload & {
	globalId: RemoteLinkGlobalId;
	remoteIssueId: IssueId;
	payloadType: typeof REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID;
};

type RemoteIssueLinkPayloadWithoutIssueId = BaseRemoteIssueLinkPayload & {
	remoteIssueId?: undefined; // Override to make `remoteIssueId` undefined,
	appLinkId: string;
	payloadType: typeof REMOTE_ISSUE_LINK_PAYLOAD_WITHOUT_ISSUE_ID;
};

/**
 * Shape of the payload used when creating remote issue links in Bento.
 */
export type SaveRemoteIssueLinkPayload =
	| RemoteIssueLinkPayloadWithIssueId
	| RemoteIssueLinkPayloadWithoutIssueId;

export const RECIPROCAL_LINK_RESPONSE_STATUS_CREATED = 'CREATED' as const;
export const RECIPROCAL_LINK_RESPONSE_STATUS_SERVER_ERROR = 'SERVER_ERROR' as const;
export const RECIPROCAL_LINK_RESPONSE_STATUS_VALIDATION_ERROR = 'VALIDATION_ERROR' as const;

type ReciprocalLinkResponseStatus =
	| typeof RECIPROCAL_LINK_RESPONSE_STATUS_CREATED
	| typeof RECIPROCAL_LINK_RESPONSE_STATUS_SERVER_ERROR
	| typeof RECIPROCAL_LINK_RESPONSE_STATUS_VALIDATION_ERROR;

/**
 * Shape of the JSON response after creating remote issue links in Bento.
 */
export type SaveRemoteIssueLinkResponse = {
	id: number;
	globalId: string;
	issueId?: number;
	reciprocalLink?: {
		status: ReciprocalLinkResponseStatus;
		error?: string;
	};
};
