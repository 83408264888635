import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	applyTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.menu.apply-template',
		defaultMessage: 'Apply template',
		description: 'Description for applying template',
	},
	resetTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.menu.reset-template',
		defaultMessage: 'Reset template',
		description: 'Description for reset template',
	},
	editTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.menu.edit-template',
		defaultMessage: 'Edit template',
		description: 'Description for editing template',
	},
	deleteTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.menu.delete-template',
		defaultMessage: 'Delete template',
		description: 'Description for deleting template',
	},
	noManagePermissions: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.menu.no-manage-permissions',
		defaultMessage: "You don't have permissions to manage templates",
		description: 'Information message for missing idea templates manage permissions.',
	},
});
