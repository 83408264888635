export const CLASS_PREFIX = 'table';

export const CLASS_HEADER_CONTAINER = `${CLASS_PREFIX}__header-container`;

export const CLASS_ROW = `${CLASS_PREFIX}__row`;
export const CLASS_HEADER_ROW = `${CLASS_PREFIX}__header-row`;

export const CLASS_ROW_CELL = `${CLASS_PREFIX}__row-cell`;
export const CLASS_HEADER_CELL = `${CLASS_PREFIX}__header-cell`;

export const CLASS_SUMMARY_CELL = `${CLASS_ROW_CELL}--summary`;

export const CLASS_PRESENTATIONAL_ROW_CELL = `${CLASS_ROW_CELL}--presentational`;
export const CLASS_PRESENTATIONAL_HEADER_CELL = `${CLASS_HEADER_CELL}--presentational`;

export const CLASS_EXPORTING_ROW_CELL = `${CLASS_ROW_CELL}--exporting`;
export const CLASS_EXPORTING_HEADER_CELL = `${CLASS_HEADER_CELL}--exporting`;

export const CLASS_FIXED_HEADER_CELL = `${CLASS_HEADER_CELL}--sticky`;
export const CLASS_FIXED_ROW_CELL = `${CLASS_ROW_CELL}--sticky`;

export const CLASS_CHANGING_ROW_CELL = `${CLASS_ROW_CELL}--selected`;

export const MIN_COLUMN_WIDTH = 60;
