import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types';

export const getWorklogUrl = (baseUrl: BaseUrl, issueKey: IssueKey) =>
	`${baseUrl}/rest/internal/3/issue/${issueKey}/worklog`;

export const getSortedWorklogsGiraQuery = ({
	startAt = 0,
	orderBy,
	worklogId,
	maxResults,
	issueKey,
	worklogAmount,
}: {
	issueKey: IssueKey;
	maxResults?: number;
	startAt?: number;
	orderBy?: string;
	worklogId?: string;
	worklogAmount?: number;
}) => {
	const startAtParameter = `startAt: ${startAt}`;
	const orderByParameter =
		orderBy !== null && orderBy !== undefined ? `, orderBy: "${orderBy}"` : '';
	const worklogParameter =
		worklogId !== null && worklogId !== undefined ? `, worklogId: "${worklogId}"` : '';
	const worklogAmountParameter =
		worklogAmount !== null && worklogAmount !== undefined
			? `, worklogAmount: ${worklogAmount}`
			: '';
	const maxResultsParameter =
		worklogParameter === '' && maxResults !== null && maxResults !== undefined
			? `, maxResults: ${maxResults}`
			: '';

	return `query sortedWorklogsQuery {
        viewIssue(issueKey: "${issueKey}") {
            worklogs (${startAtParameter}${maxResultsParameter}${orderByParameter}${worklogParameter}${worklogAmountParameter}) {
                nodes {
                id
                author {
                    accountId
                    displayName
                    avatarUrl
                }
                timeSpent
                started
                updated
                created
                updateAuthor {
                    accountId
                    displayName
                    avatarUrl
                }
                comment
            }
            totalCount
            startIndex
            }
        }
    }`;
};
