import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	createTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.create-template',
		defaultMessage: 'Create a template',
		description: 'Title for template creation form',
	},
	updateTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.update-template',
		defaultMessage: 'Edit template',
		description: 'Title for template edition form',
	},
	templateTitle: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.template-title',
		defaultMessage: 'Template title',
		description: 'Label for template title text field',
	},
	templatePlaceholder: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.template-placeholder',
		defaultMessage: 'This is your template...',
		description: 'Placeholder for template editor',
	},
	templateDescription: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.template-description',
		defaultMessage: 'Template description',
		description: 'Label for template description text field',
	},
	saveTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.save-template',
		defaultMessage: 'Save',
		description: 'Button message for saving template',
	},
	cancelTemplateModal: {
		id: 'polaris-ideas.right-sidebar.idea-templates.template-form.cancel-template-modal',
		defaultMessage: 'Cancel',
		description: 'Button message for canceling template form',
	},
});
