import { createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'issue-scroll',
	initialState: {
		scrollTarget: null,
	},
	actions,
});

export const IssueScrollContainer = createContainer(store);
