import { type Ari, createAri } from '@atlassian/jira-platform-ari';
import type { CommentUser } from '@atlassian/jira-polaris-domain-comment';
import type {
	PolarisPlayContributionComment,
	PolarisPlayContribution,
} from '@atlassian/jira-polaris-domain-field/src/play/types';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { DEFAULT_VOTES_PER_PLAY } from './constants';

type LocalContributionProps = {
	amount: number;
	comment: ADF;
	cloudId: string;
	subject: Ari;
	user: {
		id: AccountId;
		name?: string;
		avatarUrl?: string;
	};
};

export const calcVotesAvailable = (
	votesLeft: number,
	val: number,
	maxSpendPerPlay: number,
	totaContributionsByUser: number,
) => {
	if (totaContributionsByUser > maxSpendPerPlay) {
		return totaContributionsByUser - val >= maxSpendPerPlay
			? 0
			: Math.min(maxSpendPerPlay - (totaContributionsByUser - val), DEFAULT_VOTES_PER_PLAY);
	}
	if (val > maxSpendPerPlay) {
		return Math.min(votesLeft, DEFAULT_VOTES_PER_PLAY);
	}
	return Math.min(votesLeft + val, DEFAULT_VOTES_PER_PLAY);
};

export const generateLocalContribution = ({
	amount,
	comment,
	cloudId,
	subject,
	user: { id, name = '', avatarUrl = '' },
}: LocalContributionProps): PolarisPlayContribution => {
	const fakeContributionAri = createAri({
		resourceOwner: 'jira-product-discovery',
		cloudId,
		resourceType: 'contribution',
		resourceId: 'fake-contribution-id',
	});
	const fakeCommentAri = createAri({
		resourceOwner: 'jira-product-discovery',
		cloudId,
		resourceType: 'comment',
		resourceId: 'fake-comment-id',
	});

	const dateNow = new Date().toISOString();
	const curUser: CommentUser = {
		accountId: id ?? '',
		displayName: name,
		avatarUrls: {
			'16x16': avatarUrl,
			'24x24': avatarUrl,
			'32x32': avatarUrl,
			'48x48': avatarUrl,
		},
		active: true,
	};
	const localComment: PolarisPlayContributionComment = {
		id: fakeCommentAri,
		body: comment,
		created: dateNow,
		updated: dateNow,
		updateAuthor: undefined,
	};
	return {
		id: fakeContributionAri,
		aaid: id,
		amount,
		subject,
		created: dateNow,
		updated: dateNow,
		comment: localComment,
		author: curUser,
	};
};

export const getVoteAmountInRange = (voteAmount: number, maxVotes: number) => {
	if (voteAmount < 0 || Number.isNaN(voteAmount)) {
		return 0;
	}

	if (voteAmount > maxVotes) {
		return maxVotes;
	}

	return voteAmount;
};
