import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import noResults from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useFieldsTableActions } from '../../../../../../../../controllers/fields-table';
import messages from './messages';

export const EmptySearchScreen = () => {
	const { formatMessage } = useIntl();
	const { clearFilters } = useFieldsTableActions();

	const handleClearFiltersClick = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'clearFilters');
			clearFilters();
		},
		[clearFilters],
	);

	return (
		<EmptyState
			header={formatMessage(messages.emptySearchHeader)}
			renderImage={getAkEmptyStateRenderImageFn(noResults)}
			maxImageWidth={120}
			description={formatMessage(messages.emptySearchDescription)}
			primaryAction={
				<Button appearance="link" onClick={handleClearFiltersClick}>
					{formatMessage(messages.clearFilters)}
				</Button>
			}
		/>
	);
};
