import React from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import { Header } from './header';
import { ViewDescription } from './view-description';

type Props = {
	onClose: () => void;
};

export const ViewInfoPanel = ({ onClose }: Props) => (
	<Flex
		direction="column"
		gap="space.300"
		xcss={containerStyles}
		testId="polaris-ideas.ui.right-sidebar.view-info"
	>
		<Header onClose={onClose} />
		<ViewDescription />
	</Flex>
);

const containerStyles = xcss({
	paddingTop: 'space.250',
	paddingRight: 'space.300',
	paddingBottom: 'space.250',
	paddingLeft: 'space.300',
});
