import React from 'react';
import { styled } from '@compiled/react';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { SliderField } from '../../slider';
import { NumericNumberFieldReadView } from '../numeric';

type SliderNumberFieldProps = {
	value: number | undefined;
	decorations: ValueDecoration[] | undefined;
	placeholder?: string | undefined;
	onUpdate?: ((inputValue: number | undefined) => void) | undefined;
};

export const SliderNumberFieldEmptyView = () => <SliderField value={undefined} />;

export const SliderNumberField = ({
	value,
	decorations,
	placeholder,
	onUpdate,
}: SliderNumberFieldProps) => {
	const handleConfirm = (newValue: number) => {
		onUpdate && onUpdate(newValue);
	};

	return (
		<SliderHoverContainer isEmpty={value === undefined}>
			<div data-component-selector="slider-edit-view-wE44">
				<SliderField value={value} onChange={handleConfirm} />
			</div>
			<div data-component-selector="slider-read-view-45Tj">
				<NumericNumberFieldReadView
					value={value}
					decorations={decorations}
					placeholder={placeholder}
				/>
			</div>
		</SliderHoverContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SliderHoverContainer = styled.div<{ isEmpty: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="slider-read-view-45Tj"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ isEmpty }) => (isEmpty ? 0 : 'auto'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isEmpty }) => (isEmpty ? 0 : 1),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		overflow: ({ isEmpty }) => (isEmpty ? 'hidden' : 'initial'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="slider-edit-view-wE44"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ isEmpty }) => (isEmpty ? 'auto' : 0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isEmpty }) => (isEmpty ? 1 : 0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		overflow: ({ isEmpty }) => (isEmpty ? 'initial' : 'hidden'),
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="slider-read-view-45Tj"]': {
			height: 0,
			opacity: 0,
			overflow: 'hidden',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="slider-edit-view-wE44"]': {
			height: 'auto',
			transition: 'opacity 0.5s',
			opacity: 1,
			overflow: 'initial',
		},
	},
});
