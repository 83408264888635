import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { LinkedStatus } from '@atlassian/jira-polaris-common/src/ui/linked-issue-status';

export const LinkedIssueStatus = () => {
	const ideaId = useSelectedIssue();

	if (ideaId === undefined) {
		return null;
	}

	return (
		<Box xcss={containerStyles}>
			<LinkedStatus localIssueId={ideaId} />
		</Box>
	);
};

const containerStyles = xcss({
	paddingLeft: 'space.100',
});
