import log from '@atlassian/jira-common-util-logging/src/log';

const emitEventToLegacyApp = (
	event: string,
	data?:
		| boolean
		| {
				sprintIds: number[];
		  },
) => {
	const container = document.getElementById('ghx-issue-fragment');
	if (window.AJS && container) {
		window.AJS.$(container).trigger(event, data);
	} else {
		log.safeErrorWithoutCustomerData(
			'issue.rapid-board',
			'Could not find container to send event for rapid board',
		);
	}
};

const BOARD_EVENT = {
	REFRESH_SPRINT_ON_BOARD: 'refresh-sprints-on-board',
	HIDE: 'hide',
	VIEW_ISSUE_MODAL: 'issue.details.view.switch-to-modal',
	VIEW_ISSUE_SIDEBAR: 'issue.details.view.switch-to-sidebar',
	SET_IS_GLOBAL_ISSUE_CREATE_OPEN: 'issue.details.view.set-is-global-issue-create-open',
} as const;

const refreshSprintOnBoard = (sprintId: number) =>
	emitEventToLegacyApp(BOARD_EVENT.REFRESH_SPRINT_ON_BOARD, { sprintIds: [sprintId] });

const hideIssueView = () => emitEventToLegacyApp(BOARD_EVENT.HIDE);

// Handling for view mode switching resides in the Legacy JS code
// We are currently supporting view mode switching from Bento
// See https://extranet.atlassian.com/display/JPLAT/Bento+Sidebar+on+Boards
const switchIssueViewToModal = () => emitEventToLegacyApp(BOARD_EVENT.VIEW_ISSUE_MODAL);
const switchIssueViewToSidebar = () => emitEventToLegacyApp(BOARD_EVENT.VIEW_ISSUE_SIDEBAR);
const setIsGlobalIssueCreateOpen = (newValue: boolean) =>
	emitEventToLegacyApp(BOARD_EVENT.SET_IS_GLOBAL_ISSUE_CREATE_OPEN, newValue);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	refreshSprintOnBoard,
	hideIssueView,
	switchIssueViewToModal,
	switchIssueViewToSidebar,
	setIsGlobalIssueCreateOpen,
};
