import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manageAccessButton: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.manage-access-button',
		defaultMessage: 'Add team members',
		description: 'Button label to manage access.',
	},
	manageAccessTooltip: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.manage-access-tooltip',
		defaultMessage: 'Only project admins can change access settings',
		description: 'Button tooltip to manage access.',
	},
	openAccess: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.open-access',
		defaultMessage: 'Open access',
		description: 'Label for open access text.',
	},
	openAccessHint: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.open-access-hint',
		defaultMessage: 'all logged Jira users on {hostname} can open this view',
		description: 'Hint for open access text.',
	},
	privateAccess: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.private-access',
		defaultMessage: 'Private access',
		description: 'Label for private access text.',
	},
	limitedAccess: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.limited-access',
		defaultMessage: 'Limited access',
		description: 'Label for limited access text.',
	},
	privateAccessHint: {
		id: 'polaris-ideas.view-header.share-dialog.share-dialog-content.access-banner.private-access-hint',
		defaultMessage:
			'only Jira users who have been explicitly added to the project can open this view',
		description: 'Label for private access text.',
	},
});
