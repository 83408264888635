import React, { memo, useCallback, useMemo } from 'react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useDroppableEvents } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIsCollectionView } from '../../controllers/environment';
import { useCanManageCurrentView } from '../../controllers/views/selectors/view-hooks';
import { LISTS, TOGGLE_PERMISSIONS, type TogglePermissionType } from './constants';
import { List } from './list';
import { messages } from './messages';
import { handleDragDropFieldsSort } from './utils';

type HandleSortProps = {
	srcId: string;
	dstId: string;
	edge: Edge;
};

type Props = {
	availableFields?: Field[];
	selectedFields: Field[];
	unselectedFieldsAvailable?: boolean;
	hiddenFieldKeys?: FieldKey[];
	togglePermissionType: TogglePermissionType;
	onFieldClick: (field: FieldKey) => void;
	onFieldHide?: (field: FieldKey) => void;
	onFieldsSort?: (field: FieldKey, movedToKey: FieldKey) => void;
	onFieldToggle?: (field: FieldKey, isSelected: boolean) => void;
};

export const FieldsList = memo<Props>(
	({
		availableFields = [],
		selectedFields,
		unselectedFieldsAvailable,
		hiddenFieldKeys = [],
		togglePermissionType,
		onFieldClick,
		onFieldHide,
		onFieldToggle,
		onFieldsSort,
	}: Props) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const canManageCurrentView = useCanManageCurrentView();
		const canManageViews = useCanManageViews();
		const isCollectionView = useIsCollectionView();

		const isSelectedFieldsListDragDisabled = useMemo(() => {
			if (ff('polaris.view-permissions_plaoi')) {
				return !canManageCurrentView;
			}

			return !canManageViews;
		}, [canManageCurrentView, canManageViews]);

		const selectedFieldKeys = useMemo(() => selectedFields.map(({ key }) => key), [selectedFields]);

		const onFieldToggleLocal = useCallback(
			(fieldKey: FieldKey, isChecked: boolean) => {
				onFieldToggle && onFieldToggle(fieldKey, isChecked);
			},
			[onFieldToggle],
		);

		const handleSort = useCallback(
			({ srcId, dstId, edge }: HandleSortProps) => {
				handleDragDropFieldsSort({
					srcId,
					dstId,
					edge,
					selectedFields,
					onFieldsSort,
				});

				fireUIAnalytics(
					createAnalyticsEvent({ action: 'dragged', actionSubject: 'listItem' }),
					'issueField',
				);
			},
			[createAnalyticsEvent, onFieldsSort, selectedFields],
		);

		useDroppableEvents({
			onSort: handleSort,
		});

		return (
			<Stack xcss={configFieldsContainerStyles}>
				<List
					listId={LISTS.SELECTED}
					isDragDisabled={isSelectedFieldsListDragDisabled}
					fields={selectedFields}
					hiddenFieldKeys={hiddenFieldKeys}
					selectedFieldKeys={selectedFieldKeys}
					title={
						togglePermissionType === TOGGLE_PERMISSIONS.PIN_FIELDS
							? formatMessage(messages.pinnedFields)
							: formatMessage(messages.viewFields)
					}
					togglePermissionType={togglePermissionType}
					onFieldClick={onFieldClick}
					onFieldToggle={onFieldToggleLocal}
					onFieldHide={onFieldHide}
				/>
				<Box xcss={dividerStyles} />
				<List
					isExpandable
					isDragDisabled
					listId={LISTS.AVAILABLE}
					fields={availableFields}
					unselectedFieldsAvailable={unselectedFieldsAvailable}
					title={
						isCollectionView
							? formatMessage(messages.otherFieldsRoadmaps)
							: formatMessage(messages.otherFields)
					}
					helpText={
						togglePermissionType === TOGGLE_PERMISSIONS.PIN_FIELDS
							? formatMessage(messages.pinnedOtherFieldsHelpText)
							: formatMessage(messages.otherFieldsHelpText)
					}
					togglePermissionType={togglePermissionType}
					onFieldClick={onFieldClick}
					onFieldToggle={onFieldToggleLocal}
					onFieldHide={onFieldHide}
				/>
			</Stack>
		);
	},
);

const configFieldsContainerStyles = xcss({
	height: '100%',
});

const dividerStyles = xcss({
	height: '1px',
	marginBlockStart: 'space.075',
	marginInline: 'space.200',
	background: token('color.border'),
	flexShrink: 0,
});
