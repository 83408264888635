import { log } from '@atlassian/jira-common-util-logging';
import type { ViewIssueData as IssueServiceViewIssueData } from '@atlassian/jira-issue-fetch-services/src/types';
import type {
	CommentsTransformResult,
	TransformedGiraData,
	NormalizedUser,
	NormalizedComment,
} from '@atlassian/jira-issue-gira-transformer-types';
import { NUM_INITIAL_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed';
import type { ViewIssueData } from '@atlassian/jira-issue-view-common-types/src/gira';
import {
	transformComments,
	normalizeComments,
	normalizeGiraComments,
} from '../../comment-transformer';
import { getCommentsQuery } from './graphql';

const CREATED_ORDER_DESCENDING = '-created';

export const getQuery = () =>
	getCommentsQuery(NUM_INITIAL_ITEMS_TO_LOAD, 0, CREATED_ORDER_DESCENDING);

export const transformData = (
	issue: ViewIssueData | IssueServiceViewIssueData,
): CommentsTransformResult => {
	const { comments } = issue;

	if (!comments) {
		return {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			users: {} as any,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			comments: {} as any,
			totalComments: 0,
			commentsStartIndex: 0,
			loadedComments: 0,
		};
	}

	const { totalCount: totalComments, startIndex: commentsStartIndex } = comments;

	const response = {
		data: {
			issueComments: comments,
		},
	};

	return {
		...normalizeComments(transformComments(response), 0, commentsStartIndex),
		totalComments,
		commentsStartIndex,
	};
};

export const transformGiraData = (
	issue: ViewIssueData | null,
	loadedComments: number,
	commentsStartIndex: number,
	type?: string,
	orderBy?: string,
): CommentsTransformResult => {
	const emptyComments = {
		users: {},
		comments: {},
		totalComments: 0,
		commentsStartIndex: 0,
		loadedComments: 0,
	};

	if (issue?.comments == null) {
		return emptyComments;
	}

	const { comments } = issue;
	const { totalCount: totalComments } = comments;
	const response = {
		data: {
			issueComments: comments,
		},
	};

	return {
		...normalizeGiraComments(
			transformComments(response),
			loadedComments,
			commentsStartIndex,
			type,
			orderBy,
		),
		totalComments,
	};
};

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.comments', msg);

const logUserMismatch = (userId: string) => (attribute: string) =>
	logError(`user id ${userId} mismatch for ${attribute}`);
const logCommentMismatch =
	(commentId: string) => (attribute: string, giraString?: string, aggString?: string) =>
		logError(
			`comment id ${commentId} mismatch for ${attribute}: gira: ${giraString} vs. agg: ${aggString}`,
		);

const userAttributes = ['displayName'];

const commentAttributes = [
	'authorId',
	'edited',
	'id',
	'isInternal',
	'updateAuthorId',
	'visibility',
	'eventOccurredAt',
	'jsdIncidentActivityViewHidden',
];

const THIRTY_SECONDS = 30 * 10000;
export const compareComment = (
	giraCommentResult: TransformedGiraData,
	aggCommentResult: CommentsTransformResult,
) => {
	try {
		const { users: aggUsers, comments: aggComments } = aggCommentResult;
		const { users: giraUsers, comments: giraComments } = giraCommentResult;

		if (!giraUsers || !giraComments || !aggUsers || !aggComments) {
			return;
		}

		const aggCommentIds = Object.keys(aggComments);
		const giraCommentIds = Object.keys(giraComments);

		if (JSON.stringify(aggCommentIds) !== JSON.stringify(giraCommentIds)) {
			const difference = aggCommentIds.filter((commentId) => giraCommentIds.includes(commentId));
			const hasActualMismatch = difference.filter(
				(commentId) =>
					new Date().getTime() - new Date(aggComments[commentId].createdDate).getTime() >
					THIRTY_SECONDS,
			);

			if (hasActualMismatch) {
				logError(`comments id mismatch ${aggCommentIds} vs. ${giraCommentIds}`);
				return;
			}
			if (!difference.length) {
				logError(`comments id order mismatch ${aggCommentIds} vs. ${giraCommentIds}`);
				return;
			}
		}

		Object.entries(giraComments).forEach(([commentId, giraCommentValue]) => {
			const aggCommentValue = aggComments[commentId];
			attributeComparison(
				commentAttributes,
				giraCommentValue,
				aggCommentValue,
				logCommentMismatch(commentId),
			);
			// JSM comment only
			if (aggCommentValue.jsdAuthorCanSeeRequest !== undefined) {
				attributeComparison(
					['jsdAuthorCanSeeRequest'],
					giraCommentValue,
					aggCommentValue,
					logCommentMismatch(commentId),
				);
			}
		});

		if (
			JSON.stringify(Object.keys(aggUsers).sort()) !== JSON.stringify(Object.keys(giraUsers).sort())
		) {
			logError(`users id mismatch ${Object.keys(aggUsers)} vs. ${Object.keys(giraUsers)}`);
			return;
		}

		Object.entries(giraUsers).forEach(([userId, giraUserValue]) => {
			const aggUserValue = aggUsers[userId];
			attributeComparison(userAttributes, giraUserValue, aggUserValue, logUserMismatch(userId));
		});
	} catch (error) {
		if (error instanceof Error) {
			logError(error.message);
		}
	}
};

type LogFun = (attribute: string, giraString?: string, aggString?: string) => void;

const attributeComparison = (
	attributes: string[],
	giraResponse: NormalizedComment | NormalizedUser,
	aggResponse: NormalizedComment | NormalizedUser,
	logFunc: LogFun,
) => {
	attributes.forEach((attribute: string) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		const giraValue = giraResponse[attribute as keyof typeof giraResponse];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		const aggValue = aggResponse[attribute as keyof typeof aggResponse];
		if (
			(giraValue !== aggValue && typeof giraValue !== 'object') ||
			(typeof giraValue === 'object' && JSON.stringify(giraValue) !== JSON.stringify(aggValue))
		) {
			logFunc(attribute, JSON.stringify(giraValue), JSON.stringify(aggValue));
		}
	});
};
