import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewTimelineMode } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const ModeToggle = () => {
	const currentMode = useCurrentViewTimelineMode();
	const { setTimelineMode } = useViewActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const AVAILABLE_MODES: PolarisTimelineMode[] = [
		PolarisTimelineMode.MONTHS,
		PolarisTimelineMode.QUARTERS,
	];

	return (
		<ModeToggleContainer>
			{AVAILABLE_MODES.map((mode) => (
				<ModeBtnContainer key={mode}>
					<Button
						id={`pendo.config-timeline.${mode.toLowerCase()}-button`}
						shouldFitContainer
						isSelected={mode === currentMode}
						onClick={() => {
							if (mode !== currentMode) {
								fireCompoundAnalyticsEvent.TimelineGranularityChanged(createAnalyticsEvent({}), {
									timelineGranularity: mode.toLocaleLowerCase(),
								});
								setTimelineMode(mode);
							}
						}}
					>
						{mode.toLowerCase()}
					</Button>
				</ModeBtnContainer>
			))}
		</ModeToggleContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModeToggleContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	background: token('color.background.inverse.subtle', N0),
	borderRadius: '3px',
	alignSelf: 'center',
	width: '288px',
	boxShadow: token(
		'elevation.shadow.overlay',
		'0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)',
	),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModeBtnContainer = styled.div({
	width: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		borderRadius: 0,
		fontWeight: 300,
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '14px',
		textTransform: 'capitalize',
	},
});
