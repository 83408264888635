import { useEffect } from 'react';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useSelectedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';

export const TrackSelectedIdeasVisibility = () => {
	const sortedIssueIds = useSortedIssueIds();
	const selectedIds = useSelectedIssueIds();
	const { clearSelection } = useViewActions();

	useEffect(() => {
		const copy = { ...selectedIds };
		sortedIssueIds.forEach((item) => {
			delete copy[item];
		});
		const hiddenSelectedIds = Object.keys(copy);
		if (hiddenSelectedIds.length === 0) {
			return;
		}
		clearSelection(hiddenSelectedIds);
	}, [sortedIssueIds, selectedIds, clearSelection]);

	return null;
};
