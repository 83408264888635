import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.apps.apps.vivun.title',
		defaultMessage: 'Vivun',
		description: 'Title of the polaris vivun app',
	},
	description: {
		id: 'polaris-common.apps.apps.vivun.description',
		defaultMessage:
			'Add Vivun product gaps to ideas to keep track of customer and prospect requests from the field',
		description: 'Description of the polaris vivun app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.vivun.unfurl-dialog-title',
		defaultMessage: 'Connect to Vivun',
		description: 'Title of the Vivun try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.vivun.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to a Vivun product gap here',
		description: 'Placeholder of the Vivun try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.vivun.unfurl-dialog-success',
		defaultMessage: 'This is what it looks like when you add a Vivun product gap to an idea.',
		description: 'Success message of Vivun the try-it-now unfurl dialog',
	},
});
