import type { DynamicFieldFormula, FieldVisitor, FormulaVisitor } from '../types';
import { EXPRESSION_TEMPLATE } from './types';

export { EXPRESSION_TEMPLATE };

export const expressionVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
	visitFormula: FormulaVisitor,
): boolean => {
	if (formula.template !== EXPRESSION_TEMPLATE) {
		return false;
	}
	visitFormula(formula.parameters.formula);
	return true;
};
