import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	greaterThan: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.tag.greater-than',
		defaultMessage: 'Values greater than',
		description: 'Input label for a number field',
	},
	lessThan: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.tag.less-than',
		defaultMessage: 'Values less than',
		description: 'Input label for a number field',
	},
	between: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.tag.between',
		defaultMessage: 'Values between',
		description: 'Input label for a number field',
	},
	and: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.item.tag.and',
		defaultMessage: 'and',
		description: 'Input label for a number field',
	},
});
