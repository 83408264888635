import { useEffect, useRef, useState } from 'react';

type StateWithRef<TValue> = [
	TValue,
	(arg1: ((arg1: TValue) => TValue) | TValue) => void,
	{
		current: TValue;
	},
];
/**
 * Util to create an immutable ref object for the current state value. This
 * can be used to memoize on createCallbacks to avoid recreating new callback
 * functions everytime the state value changes.
 */
export const useStateWithRef = <TValue,>(value: TValue): StateWithRef<TValue> => {
	const [state, setState] = useState(value);
	const ref = useRef(value);

	useEffect(() => {
		ref.current = state;
	}, [state]);

	return [state, setState, ref];
};

export const useEventListener = (
	eventName: string,
	handler: (event: Event) => string | undefined,
	element?: HTMLElement | Window | undefined,
	options?: boolean | AddEventListenerOptions,
) => {
	// Create a ref that stores handler
	const savedHandler = useRef(handler);

	// Create event listener that calls handler function stored in ref
	const eventListener: typeof handler = (event) => savedHandler.current(event);

	// Update ref.current value if handler changes.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		// Make sure element supports addEventListener
		const isSupported = element && element.addEventListener;
		if (!isSupported) return () => undefined;

		// Add event listener
		element && element.addEventListener(eventName, eventListener, options);

		// Remove event listener on cleanup
		return () => {
			element && element.removeEventListener(eventName, eventListener, options);
		};
	}, [eventName, element, options]);
};
