import type { LinkedIssuesProgress } from '@atlassian/jira-polaris-domain-field/src/field-types/progress/types.tsx';
import { evaluateNumericFilterValues } from '@atlassian/jira-polaris-domain-view/src/filter/index.tsx';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { MappingFilterFunction } from '../../types';

export const createFilterFunction =
	(values: NumericFieldFilterValue[]): MappingFilterFunction<LinkedIssuesProgress> =>
	(value: LinkedIssuesProgress | undefined) => {
		if (value === undefined) {
			return evaluateNumericFilterValues(values, 0);
		}
		// if total progress exists but the value is unestimated
		if (value.total === 0) {
			return evaluateNumericFilterValues(values, 1);
		}
		return evaluateNumericFilterValues(values, value.total);
	};
