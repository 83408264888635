import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mapTo';
import { fetchViewContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions';
import {
	FIELD_SAVE_SUCCESS,
	type FieldSaveSuccessAction,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';

const fetchViewContextOnFieldSaveSuccess = (action$: ActionsObservable<FieldSaveSuccessAction>) =>
	action$
		.ofType(FIELD_SAVE_SUCCESS)
		.filter(
			(action) =>
				action.payload.fieldOptions.canContainMediaContent &&
				// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				action.meta.shouldFetchViewContext,
		)
		.mapTo(fetchViewContextRequest());

export default fetchViewContextOnFieldSaveSuccess;
