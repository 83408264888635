import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { log } from '@atlassian/jira-common-util-logging';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics';
import { NUM_INITIAL_ITEMS_TO_LOAD_FOR_POST_LOAD_ACTIONS } from '@atlassian/jira-issue-view-common-constants/src/activity-feed';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector';
import { worklogExperienceDescription } from '../../common/experience-description';
import fetchWorklogs from '../../services/fetch-worklogs-sorted';
import {
	FETCH_SORTED_WORKLOGS_REQUEST,
	FETCH_SORTED_WORKLOGS_RETRY,
	fetchSortedWorklogsSuccess,
	fetchSortedWorklogsFailure,
	type FetchSortedWorklogsSuccessAction,
	type FetchSortedWorklogsFailureAction,
} from '../../state';

export const fetchSortedWorklogsRequest = (
	action$: ActionsObservable<FetchSortedWorklogsSuccessAction | FetchSortedWorklogsFailureAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_SORTED_WORKLOGS_REQUEST, FETCH_SORTED_WORKLOGS_RETRY).switchMap(() => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);
		const selectedSortOrder = getSelectedActivitySortOrder(state);
		const projectType = projectTypeSelector(state);

		return fetchWorklogs(
			baseUrl,
			issueKey,
			NUM_INITIAL_ITEMS_TO_LOAD_FOR_POST_LOAD_ACTIONS,
			selectedSortOrder,
		)
			.map(({ worklogs, totalWorklogs, startIndex, sortedWorklogIds }) => {
				sendExperienceAnalytics({
					getExperienceDescription: () =>
						worklogExperienceDescription({
							wasSuccessful: true,
							action: 'FETCH_SORTED',
							analyticsSource: 'fetchSortedWorklogsRequest',
							projectType,
						}),
				});
				return fetchSortedWorklogsSuccess({
					worklogs,
					totalWorklogs,
					startIndex,
					sortedWorklogIds,
				});
			})
			.catch((error) => {
				sendExperienceAnalytics({
					getExperienceDescription: () =>
						worklogExperienceDescription({
							wasSuccessful: false,
							action: 'FETCH_SORTED',
							analyticsSource: 'fetchSortedWorklogsRequest',
							projectType,
							errorMessage: error.message,
						}),
					error,
				});
				log.safeErrorWithoutCustomerData(
					'issue.activity.worklog.fetch-sorted-worklogs',
					'Could not fetch sorted worklogs:',
					error,
				);

				return Observable.of(fetchSortedWorklogsFailure());
			});
	});
