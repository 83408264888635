import { SET_VALUE_ARRAY_SIZE_LIMIT } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/constants';
import { labelsRequiredScopes as labelsRequiredScopesBase } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/labels';
import { isNotEmptyString } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/utils/is-not-empty-string';
import { commonIssueViewGranularScopes } from './fields-scopes';

export type LabelsPublicValue = string[];

export const validateLabelsSetValue = (value: unknown): value is LabelsPublicValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const labelsRequiredScopes = {
	...labelsRequiredScopesBase,
	granular: [...labelsRequiredScopesBase.granular, ...commonIssueViewGranularScopes],
};
