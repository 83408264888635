import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import {
	VIEW_COMMENT_CREATED_EVENT,
	VIEW_COMMENT_DELETED_EVENT,
	VIEW_COMMENT_UPDATED_EVENT,
	VIEW_CREATED_EVENT,
	VIEW_DELETED_EVENT,
	VIEW_UPDATED_EVENT,
	VIEWSET_CREATED_EVENT,
	VIEWSET_DELETED_EVENT,
	VIEWSET_UPDATED_EVENT,
} from './constants';
import type {
	PolarisViewCommentEvent,
	PolarisViewEvent,
	PolarisViewSetEvent,
	ViewRealtimeEvent,
} from './types';

export function isViewCommentEvent(
	event: ViewRealtimeEvent | RealtimeEvent,
): event is PolarisViewCommentEvent {
	return (
		event.type === VIEW_COMMENT_CREATED_EVENT ||
		event.type === VIEW_COMMENT_UPDATED_EVENT ||
		event.type === VIEW_COMMENT_DELETED_EVENT
	);
}

export function isViewEvent(event: ViewRealtimeEvent | RealtimeEvent): event is PolarisViewEvent {
	return (
		event.type === VIEW_CREATED_EVENT ||
		event.type === VIEW_UPDATED_EVENT ||
		event.type === VIEW_DELETED_EVENT
	);
}

export function isViewSetEvent(
	event: ViewRealtimeEvent | RealtimeEvent,
): event is PolarisViewSetEvent {
	return (
		event.type === VIEWSET_CREATED_EVENT ||
		event.type === VIEWSET_DELETED_EVENT ||
		event.type === VIEWSET_UPDATED_EVENT
	);
}
