import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	more: {
		id: 'polaris-common.fields.reactions.more-tag.more',
		defaultMessage: 'More',
		description: 'More button message',
	},
	moreTooltip: {
		id: 'polaris-common.fields.reactions.more-tag.more-tooltip',
		defaultMessage: 'Show more reactions',
		description: 'Message for tooltip for more button',
	},
});
