import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	impact1: {
		id: 'polaris-lib-rating.impact-1',
		defaultMessage: 'Very Low',
		description: 'Label for the impact selector, value 1 on a scale of 1 to 5',
	},
	impact2: {
		id: 'polaris-lib-rating.impact-2',
		defaultMessage: 'Low',
		description: 'Label for the impact selector, value 2 on a scale of 1 to 5',
	},
	impact3: {
		id: 'polaris-lib-rating.impact-3',
		defaultMessage: 'Medium',
		description: 'Label for the impact selector, value 3 on a scale of 1 to 5',
	},
	impact4: {
		id: 'polaris-lib-rating.impact-4',
		defaultMessage: 'High',
		description: 'Label for the impact selector, value 4 on a scale of 1 to 5',
	},
	impact5: {
		id: 'polaris-lib-rating.impact-5',
		defaultMessage: 'Very High',
		description: 'Label for the impact selector, value 5 on a scale of 1 to 5',
	},
	clickToReset: {
		id: 'polaris-lib-rating.click-to-reset',
		defaultMessage: 'click to reset the value',
		description: 'Label describing that clicking on the element will reset the value',
	},
});
