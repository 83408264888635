import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	overview: {
		id: 'polaris-ideas.idea-view.tab-view.overview',
		defaultMessage: 'Overview',
		description: 'Tab caption for overview',
	},
	delivery: {
		id: 'polaris-ideas.idea-view.tab-view.delivery',
		defaultMessage: 'Delivery',
		description: 'Tab caption for delivery',
	},
	insights: {
		id: 'polaris-ideas.idea-view.tab-view.insights',
		defaultMessage: 'Insights',
		description: 'Tab caption for insights',
	},
	comments: {
		id: 'polaris-ideas.idea-view.tab-view.comments',
		defaultMessage: 'Comments',
		description: 'Tab caption for comments',
	},
	history: {
		id: 'polaris-ideas.idea-view.tab-view.history',
		defaultMessage: 'History',
		description: 'Tab caption for history',
	},
	more: {
		id: 'polaris-ideas.idea-view.tab-view.more',
		defaultMessage: 'More',
		description: 'Dropdown label in idea view',
	},
});
