import React, { type ComponentPropsWithoutRef, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { COMMAND_BODY_TEST_ID, COMPAL_LISTBOX_ID } from '../constants';
import { messages } from './messages';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CommandPaletteSearchResultListWrapper = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof Wrapper> & {
		noResultsFallback?: string | JSX.Element | null | undefined;
	}
>(({ noResultsFallback, ...props }, ref) => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper
			data-testid={COMMAND_BODY_TEST_ID}
			id={COMPAL_LISTBOX_ID}
			role={noResultsFallback && fg('a11y_violations_fixes') ? 'group' : 'listbox'}
			ref={ref}
			aria-label={formatMessage(messages.commandPaletteSearchResultListBoxLabel)}
			{...props}
		/>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	maxHeight: '376px',
	overflowY: 'auto',
	boxSizing: 'border-box',
	padding: `${token('space.100', '8px')} 0`,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.050', '4px'),
	minHeight: '56px',
});
