import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'polaris-common.fields.user.placeholder',
		defaultMessage: 'Select user',
		description: 'Prompt to select a user in a user picker.',
	},
	unassigned: {
		id: 'polaris-common.fields.user.unassigned',
		defaultMessage: 'Unassigned',
		description: 'Default user in a user picker.',
	},
});
