import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreThan: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.more-than',
		defaultMessage: 'Values more than',
		description: 'Input label for formatting options for values greater than a certain number',
	},
	lessThan: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.less-than',
		defaultMessage: 'Values less than',
		description: 'Input label for formatting options for values less than a certain number',
	},
	between: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.between',
		defaultMessage: 'Values between',
		description: 'Input label for formatting options for values between two numbers',
	},
	addDecorationRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.add-decoration-rule',
		defaultMessage: 'Add a rule',
		description: 'Label for button to add new decoration rule',
	},
	moreDecorationsInPreview: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.more-decorations-in-preview',
		defaultMessage:
			'+ {number, plural, one {1 more formatting rule} other {{number} more formatting rules}}',
		description: 'The number of formatting rules that are not visible in the preview list',
	},
	noFormatting: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.number-decoration.no-formatting',
		defaultMessage: 'No formatting rules are applied to this field.',
		description: 'Message displayed when there are no formatting rules',
	},
});
