import React, { useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import Blanket from '@atlaskit/blanket';
import Spinner from '@atlaskit/spinner';
import { B75 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Placeholder from '@atlassian/jira-placeholder';
import { logPolarisError } from '@atlassian/jira-polaris-common/src/common/utils/errors';
import { useProjectActions } from '@atlassian/jira-polaris-common/src/controllers/project/main';
import { useProjectName } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import { useProjectAriUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useShouldShowOnboardingExperience } from '@atlassian/jira-polaris-lib-onboarding/src/utils.tsx';
import LazyPageSkeleton from '@atlassian/jira-polaris-lib-page-skeleton/src/async';
import LazyProjectTemplateApplier from '@atlassian/jira-polaris-lib-project-template-applier/src/async';

type TemplateApplierProps = {
	onComplete: () => void;
};

export const TemplateApplier = ({ onComplete }: TemplateApplierProps) => {
	const {
		setProjectError,
		loadProject,
		applyProjectTemplate,
		checkProjectOnboarded,
		loadInsights,
		loadPlays,
	} = useProjectActions();
	const [projectName = ''] = useProjectName();
	const projectAri = useProjectAriUnsafe();
	const shouldShowOnboardingExperience = useShouldShowOnboardingExperience(projectAri);

	useEffect(() => {
		experience.project.applyTemplate.start();
	}, []);

	const handleError = useCallback(
		(error: Error) => {
			experience.project.pageLoad.abort('OnboardingError');
			experience.project.applyTemplate.failure(error);
			logPolarisError('project.applyTemplate', error);
			setProjectError(error);
		},
		[setProjectError],
	);

	const handleComplete = useCallback(() => {
		// Load project, insights and plays after template is applied
		loadProject(true, true);
		loadInsights({ fireAnalyticsEvent: true });
		loadPlays(true);
		experience.project.applyTemplate.mark(
			experience.project.applyTemplate.marks.APPLY_TEMPLATE_POLLING_STOP_MARK,
		);
		experience.project.applyTemplate.success();
		onComplete();
	}, [loadProject, loadInsights, loadPlays, onComplete]);

	const handleStart = useCallback(async () => {
		const shouldPoll = await applyProjectTemplate(shouldShowOnboardingExperience === true);
		return shouldPoll;
	}, [applyProjectTemplate, shouldShowOnboardingExperience]);

	return (
		<Placeholder
			name="template-applier"
			fallback={
				<Blanket isTinted>
					<InnerContainer>
						<Spinner size="large" />
					</InnerContainer>
				</Blanket>
			}
		>
			<Blanket isTinted>
				<InnerContainer>
					<ModalWrapper>
						<LazyProjectTemplateApplier
							isStatic={shouldShowOnboardingExperience === null}
							projectName={projectName}
							onStart={handleStart}
							onCheck={checkProjectOnboarded}
							onComplete={handleComplete}
							onError={handleError}
						/>
					</ModalWrapper>
				</InnerContainer>
			</Blanket>
			<PageContainer>
				<LazyPageSkeleton />
			</PageContainer>
		</Placeholder>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageContainer = styled.div({
	height: 'calc(100vh - 56px)',
	width: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InnerContainer = styled.div({
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '60px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalWrapper = styled.div({
	height: '600px',
	width: '100%',
	maxWidth: '1040px',
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: '3px',
	overflow: 'hidden',
	backgroundColor: token('color.background.accent.blue.subtler', B75),
});
