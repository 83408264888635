import { tryAddCurrentUserAsSiteCreator } from './beta/add-as-site-creator';
import { toggleEnforcePermissionChecks } from './beta/toggle-enforce-permission-checks';
import { toggleSelfServeAddAsSiteCreator } from './beta/toggle-self-serve';
import { load } from './load';

export const actions = {
	load,
	// beta actions
	tryAddCurrentUserAsSiteCreator,
	toggleEnforcePermissionChecks,
	toggleSelfServeAddAsSiteCreator,
};
