import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewNotConfiguredForPublishing: {
		id: 'polaris-ideas.view-header.publish.view-not-configured-for-publishing',
		defaultMessage: 'You should set up this view before publishing.',
		description: 'Error message when view is not configured',
	},
	noPermissionsToPublish: {
		id: 'polaris-ideas.view-header.publish.no-permissions-to-publish',
		defaultMessage:
			'You don’t have permission to publish a view and manage its other settings. Ask your project admin to change your role or permission.',
		description: 'Error message when user does not have permissions to publish',
	},
	noPermissionsToUnpublish: {
		id: 'polaris-ideas.view-header.publish.no-permissions-to-unpublish',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'You don’t have permission to unpublish a view and manage its other settings. Ask your project admin to change your role or permission.',
		description: 'Error message when user does not have permissions to unpublish',
	},
	misconfiguredUnpublishedViewTitle: {
		id: 'polaris-ideas.view-header.publish.misconfigured-unpublished-view-title',
		defaultMessage: 'This view can’t be published yet',
		description: 'Misconfigured view error title when view is not published',
	},
	misconfiguredPublishedViewTitle: {
		id: 'polaris-ideas.view-header.publish.misconfigured-published-view-title',

		defaultMessage: 'The published view can’t be displayed',
		description: 'Misconfigured view error title when view is published',
	},
	reviewConfigurationLink: {
		id: 'polaris-ideas.view-header.publish.review-configuration-link',
		defaultMessage: 'Learn about unsupported fields',
		description: 'Misconfigured view link button description',
	},
	noPermissionsToPublishPublicDiscoveryView: {
		id: 'polaris-ideas.view-header.publish.no-permissions-to-publish-public-discovery-view',
		defaultMessage:
			"You can't publish this view because the 'Anyone with the link' option is set to 'Can access' and you don't have permissions to publish views to anyone with the link.",
		description:
			'Error message when user does not have permissions to publish because of the current set of options picked in the dialog',
	},
});
