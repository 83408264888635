import { createSelector } from 'reselect';
import type { ThemeIds } from '@atlaskit/tokens';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import type {
	ValueDecoration,
	FieldValueDecorations,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	getPaletteEntryForMainColor,
	type PolarisPaletteEntry,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import type { DatePickerValue } from '../../../../common/types/date-picker';
import {
	getAppliedDecoration,
	getAppliedIntervalDecoration,
	getAppliedNumberDecoration,
} from '../../decoration';
import type { State } from '../../types';
import { createGetNewFieldType } from '../field';
import { getSortedDecorations } from './sort';

export const getFieldValueDecorations = (state: State): FieldValueDecorations =>
	state.fieldValueDecorations;

export const createGetValueDecorations = (fieldkey: FieldKey) => {
	const getNewFieldType = createGetNewFieldType(fieldkey);
	return createSelector(getFieldValueDecorations, getNewFieldType, (decorationsMap, type) => {
		if (
			type === FIELD_TYPES.INSIGHTS ||
			type === FIELD_TYPES.CREATED ||
			type === FIELD_TYPES.UPDATED
		) {
			return [];
		}

		return decorationsMap[fieldkey];
	});
};

export const createGetDecorationForValue = (fieldKey: FieldKey, value?: string) =>
	createSelector(getFieldValueDecorations, (decorationsMap) => {
		const decorations = decorationsMap[fieldKey];
		return getAppliedDecoration(decorations, value);
	});

export const createGetSortedValueDecorations = cacheSelectorCreator((fieldKey: FieldKey) => {
	const getValueDecorations = createGetValueDecorations(fieldKey);
	return createSelector(getValueDecorations, getSortedDecorations);
});

export const createGetDecorationForNumberValue = (fieldKey: FieldKey, value?: number) => {
	const getSortedDecorationsSelector = createGetSortedValueDecorations(fieldKey);
	return createSelector(getSortedDecorationsSelector, (decorations) =>
		getAppliedNumberDecoration(decorations, value),
	);
};

export const createGetDecorationForIntervalDateValue = (
	fieldKey: FieldKey,
	value: DatePickerValue | undefined,
	locale: Locale,
) => {
	const getSortedDecorationsSelector = createGetSortedValueDecorations(fieldKey);
	return createSelector(getSortedDecorationsSelector, (decorations) =>
		getAppliedIntervalDecoration(decorations, value, locale),
	);
};

export const createGetValueDecorationPalette = (
	fieldKey: FieldKey,
	value?: string,
	theme?: ThemeIds | null,
) => {
	const getDecoration = createGetDecorationForValue(fieldKey, value);
	const getDecorationForNumberValue = createGetDecorationForNumberValue(fieldKey, Number(value));
	return createSelector(
		getDecoration,
		getDecorationForNumberValue,
		(
			decoration?: ValueDecoration,
			decorationForNumberValue?: ValueDecoration,
		): PolarisPaletteEntry =>
			getPaletteEntryForMainColor(
				decoration?.backgroundColor || decorationForNumberValue?.backgroundColor,
				theme,
			),
	);
};
