import { useMemo } from 'react';
import { useInsights } from '../main';
import type { Props, State } from '../types';

type Selector<TValue> = (arg1: State, arg2: Props) => TValue;

/**
 * helper to wrap all selector hooks into the correct types for the issue state
 */
export const createInsightsHook =
	<TValue,>(selector: Selector<TValue>): (() => TValue) =>
	() => {
		const [state, { getContainerProps }] = useInsights();
		return selector(state, getContainerProps());
	};

/**
 * helper to wrap higher-order selectors into state types. Takes a
 * "selector creator" which creates disctinct selectors and hooks for specific arguments.
 * NOTE that these are memoized on the arguments, creating per-component selectors.
 * use this only with component props as arguments
 */
export const createHigherLevelInsightsHook =
	<TArg1, TValue>(selectorCreator: (arg1: TArg1) => Selector<TValue>): ((arg1: TArg1) => TValue) =>
	(arg1) => {
		const [state, { getContainerProps }] = useInsights();
		const selector = useMemo(() => selectorCreator(arg1), [arg1]);
		return selector(state, getContainerProps());
	};

export const createHigherLevelInsightsHook2 =
	<TArg1, TArg2, TValue>(
		selectorCreator: (arg1: TArg1, arg2: TArg2) => Selector<TValue>,
	): ((arg1: TArg1, arg2: TArg2) => TValue) =>
	(arg1, arg2) => {
		const [state, { getContainerProps }] = useInsights();
		const selector = useMemo(() => selectorCreator(arg1, arg2), [arg1, arg2]);
		return selector(state, getContainerProps());
	};
