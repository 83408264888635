import { useCallback } from 'react';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import messages from './messages';

export const useSwitchedToViewRankFlag = () => {
	const { showFlag } = useNotifications();
	return useCallback(
		() =>
			showFlag({
				id: 'polaris.sort-mode-switched-view-rank.success',
				type: 'success',
				title: messages.switchedSortModeHeader,
				description: messages.switchedSortModeToViewRank,
			}),
		[showFlag],
	);
};
