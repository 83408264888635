import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types';
import { setFieldCache } from '../set-field-cache';
import type { SetRecentlySelectedItemProps } from './types';
import { getRecentlySelectedWithoutNewSelected } from './utils';

export const setRecentlySelectedItem =
	({ fieldKey, projectKey, selected, maxLength }: SetRecentlySelectedItemProps): Action<State> =>
	({ getState, dispatch }) => {
		const recentlySelected = getState()[fieldKey][projectKey]?.recentlySelected || [];

		const recentlySelectedWithoutNewSelected = getRecentlySelectedWithoutNewSelected({
			selections: recentlySelected,
			newlySelected: selected,
		});

		const newRecentlySelected = [selected, ...recentlySelectedWithoutNewSelected];
		const newRecentlySelectedOfMaxLength =
			newRecentlySelected.length <= maxLength
				? newRecentlySelected
				: newRecentlySelected.slice(0, maxLength);

		dispatch(
			setFieldCache({
				fieldKey,
				projectKey,
				recentlySelected: newRecentlySelectedOfMaxLength,
			}),
		);
	};
