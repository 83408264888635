import keyBy from 'lodash/keyBy';
import { ff } from '@atlassian/jira-feature-flagging';
import { createAri } from '@atlassian/jira-platform-ari';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import { asyncSwitchAction } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/actions/index.tsx';
import type { PolarisApiInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/get-insight/types.tsx';
import { transformInsight } from '@atlassian/jira-polaris-remote-insight/src/services/polaris-api/transform/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetInsightUpdatedTimestamp } from '../selectors/insights';
import type { Props, State } from '../types';
import { enrichPayload } from '../utils/enrich';
import { isOptimisticInsight } from '../utils/optimistic-updates';

export const refreshInsights =
	() =>
	async ({ setState }: StoreActionApi<State>, { insightsRemote, issueId, projectId }: Props) => {
		if (!issueId || !insightsRemote.fetch) {
			return;
		}
		insightsRemote.fetch({ issueId, projectId }).then((data) => {
			setState({
				insights: keyBy(data.insights, ({ id }) => id),
			});
		});
	};

export type RefreshInsightArguments = {
	insightId: string;
	lastExternalUpdate: string;
	insight?: PolarisApiInsight;
};

export const refreshInsight = asyncSwitchAction<
	State,
	Props,
	RefreshInsightArguments,
	Insight | undefined
>(
	({ insightId, insight, lastExternalUpdate }, { getState }, props) => {
		const { cloudId, insightsRemote } = props;

		if (isOptimisticInsight(insightId) || !insightsRemote.fetchInsight) {
			return Promise.resolve(undefined);
		}

		const insightAri = createAri({
			resourceOwner: 'jira',
			cloudId,
			resourceType: 'polaris-insight',
			resourceId: insightId,
		});

		const getUpdatedSelector = createGetInsightUpdatedTimestamp(insightAri);
		const updated = getUpdatedSelector(getState());

		if (updated === lastExternalUpdate) {
			// already on the newest data, bail and don't refresh
			return Promise.resolve(undefined);
		}

		if (insight) {
			const enrichedInsight = enrichPayload(insight, getState().insights);
			if (enrichedInsight) {
				return Promise.resolve(transformInsight(enrichedInsight));
			}
		}

		return insightsRemote.fetchInsight({
			id: insightId,
		});
	},
	(freshInsight, _, { getState, setState }) => {
		if (freshInsight) {
			if (ff('polaris.insights.fix-refresh')) {
				const { insights } = getState();

				// Can happen eg. when user switches to a different issue and insights are cleared, while refresh is not yet complete.
				// During refresh we don't want to insert new insight into the state but only update the existing ones.
				const thereIsNoInsightToRefresh = insights[freshInsight.id] === undefined;

				if (thereIsNoInsightToRefresh) {
					return;
				}

				setState({
					insights: {
						...insights,
						[freshInsight.id]: freshInsight,
					},
				});
			} else {
				setState({
					insights: {
						...getState().insights,
						[freshInsight.id]: freshInsight,
					},
				});
			}
		}
	},
);
