import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addContributionPlaceholder: {
		id: 'polaris-common.plays.add-contribution.input-editor.add-contribution-placeholder',
		defaultMessage: 'Why did you vote for this idea?',
		description: 'Placeholder for the add vote editor',
	},
	save: {
		id: 'polaris-common.plays.add-contribution.input-editor.save',
		defaultMessage: 'Save',
		description: 'Button caption to save the vote',
	},
	discard: {
		id: 'polaris-common.plays.add-contribution.input-editor.discard',
		defaultMessage: 'Cancel',
		description: 'Button caption to discard the vote that is currently being edited',
	},
	unsavedChanges: {
		id: 'polaris-common.plays.add-contribution.input-editor.unsaved-changes',
		defaultMessage: 'You have some unsaved changes',
		description: 'Help text for unsaved changes scenario',
	},
	label: {
		id: 'polaris-common.plays.add-contribution.input-editor.label',
		defaultMessage: 'Summary',
		description: 'Label above the text field where the user enters why they voted for the idea',
	},
	emptyEditorErrorMessage: {
		id: 'polaris-common.plays.add-contribution.input-editor.empty-editor-error-message',
		defaultMessage: 'A summary is required',
		description:
			'Error message that is shown when the user did not enter why they voted for an idea. ' +
			'Wording should match with the editor label.',
	},
});
