import React, { useState, useEffect, useRef, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalTransition,
	ModalFooter as ModalFooterAk,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { type HistoryBlocker, useRouter } from '@atlassian/jira-router';
import commonMessages from '../../../common/messages';
import { useEventListener } from '../../../common/utils/react/hooks';
import messages from './messages';

type Props = {
	isDirty: boolean;
	prompt: MessageDescriptor;
	title?: MessageDescriptor;
	withSaveButton: boolean;
	isPromptOpen?: boolean;
	setIsPromptOpen?: (_: boolean) => void;
	onRegisterBlock: (arg1: HistoryBlocker) => () => void;
	onSave: (arg1: () => void) => void;
	onDiscard: () => void;
	onConfirmNavigation: () => void;
	onRejectNavigation: () => void;
	shouldIgnoreQueryParams?: boolean;
};

export const usePromptControl = (
	props: Pick<Props, 'isPromptOpen' | 'setIsPromptOpen'>,
): [boolean, (_: boolean) => void] => {
	const { isPromptOpen: isOpenExternally, setIsPromptOpen: setIsOpenExternally } = props;
	const [isOpen, setIsOpen] = useState(false);

	if (typeof isOpenExternally === 'boolean' && typeof setIsOpenExternally === 'function') {
		return [isOpenExternally, setIsOpenExternally];
	}

	return [isOpen, setIsOpen];
};

export const NavigationPrompt = ({
	isDirty,
	prompt,
	title,
	withSaveButton,
	onRegisterBlock,
	onSave,
	onDiscard,
	onConfirmNavigation,
	onRejectNavigation,
	shouldIgnoreQueryParams = false,
	...props
}: Props) => {
	const [isPromptOpen, setIsPromptOpen] = usePromptControl(props);
	const [{ location }] = useRouter();
	const { formatMessage } = useIntl();
	const navigationResolver = useRef<(a: boolean) => void>((cb) => cb);

	// Create event listener that calls handler function stored in ref
	const navigationResolverHandler = useCallback(
		(cb: boolean) => navigationResolver.current(cb),
		[],
	);

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let unregisterBlock = () => {};

	const onUnloadHandler = (e: Event) => {
		if (!isDirty) {
			return;
		}

		e.preventDefault(); // Firefox
		// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'boolean'.
		e.returnValue = formatMessage(prompt); // Chrome
		return formatMessage(prompt); // IE
	};

	useEventListener('beforeunload', onUnloadHandler, window);

	const blocker: HistoryBlocker = ({ pathname }) => {
		if (!isDirty || (shouldIgnoreQueryParams && location.pathname === pathname)) {
			return Promise.resolve(true);
		}
		return new Promise((resolve) => {
			setIsPromptOpen(true);
			navigationResolver.current = resolve;
		});
	};

	const registerNavigationHandler = () => {
		unregisterBlock = onRegisterBlock(blocker);
	};

	useEffect(() => {
		registerNavigationHandler();
		return () => {
			unregisterBlock();
		};
	});

	const continueNavigation = () => {
		unregisterBlock();
		onConfirmNavigation();
		navigationResolverHandler(true);
	};

	const onSaveCb = () => {
		setIsPromptOpen(false);
		onSave(continueNavigation);
	};

	const onCloseCb = () => {
		setIsPromptOpen(false);
		navigationResolverHandler(false);
		onRejectNavigation();
	};

	const onDiscardCb = () => {
		setIsPromptOpen(false);
		continueNavigation();
		// If we discard too quickly, the blocker remounts and discards again before the re-render.
		setTimeout(() => onDiscard());
	};

	const renderFooter = () => (
		<>
			{withSaveButton ? (
				<>
					<Button appearance="primary" onClick={onSaveCb}>
						{formatMessage(commonMessages.save)}
					</Button>
					<Button appearance="subtle-link" onClick={onDiscardCb}>
						{formatMessage(commonMessages.discard)}
					</Button>
				</>
			) : (
				<Button appearance="primary" onClick={onDiscardCb}>
					{formatMessage(messages.leave)}
				</Button>
			)}
			<Button appearance="link" onClick={onCloseCb}>
				{formatMessage(commonMessages.cancel)}
			</Button>
		</>
	);

	if (!isPromptOpen) {
		return null;
	}

	return (
		<ModalTransition>
			<ShortcutScope>
				<ModalDialog shouldCloseOnOverlayClick={false} onClose={onCloseCb}>
					<ModalHeader>
						<ModalTitle>{formatMessage(title ?? messages.unsavedChanges)}</ModalTitle>
					</ModalHeader>
					<ModalBody>{formatMessage(prompt)}</ModalBody>
					<ModalFooter>{renderFooter()}</ModalFooter>
				</ModalDialog>
			</ShortcutScope>
		</ModalTransition>
	);
};

// Set default props
NavigationPrompt.defaultProps = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onRejectNavigation: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onConfirmNavigation: () => {},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalFooter = styled(ModalFooterAk)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&&': {
		justifyContent: 'end',
	},
});
