import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleInsights: {
		id: 'polaris-common.apps.apps.amplitude.title-insights',
		defaultMessage: 'Amplitude',
		description: 'Title of the Amplitude app',
	},
	descriptionInsights: {
		id: 'polaris-common.apps.apps.amplitude.description-insights',
		defaultMessage: 'Add product analytics to ideas to understand user behavior',
		description: 'Description of the Amplitude app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.amplitude.unfurl-dialog-title',
		defaultMessage: 'Connect to Amplitude',
		description: 'Title of the Amplitude try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.amplitude.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to Amplitude here',
		description: 'Placeholder of the Amplitude try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.amplitude.unfurl-dialog-success',
		defaultMessage: 'This is what it looks like when you add a Amplitude link to an idea.',
		description: 'Success message of Amplitude the try-it-now unfurl dialog',
	},
});
