import React, { useCallback } from 'react';
import {
	GenericErrorWithRefresh,
	RateLimitError,
} from '@atlassian/jira-polaris-common/src/common/utils/errors/main.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';

type Props = {
	error: Error;
	isRateLimitError: boolean;
};

export const ErrorState = ({ error, isRateLimitError }: Props) => {
	experience.ideaView.insightsSegmentLoad.failure(error?.message);

	const onPageRefresh = useCallback(() => {
		window.location.reload();
	}, []);

	return isRateLimitError ? (
		<RateLimitError />
	) : (
		<GenericErrorWithRefresh error={error} onRefresh={onPageRefresh} />
	);
};
