import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraCheckBrowseProjectPermissionResponse } from './types';

export type { JiraCheckBrowseProjectPermissionResponse };

export const getLinkIssuesProjectPermissions = (
	id: string,
): Promise<JiraCheckBrowseProjectPermissionResponse> =>
	fetchJson(`/rest/api/3/mypermissions?projectId=${id}&permissions=BROWSE_PROJECTS, LINK_ISSUES`, {
		method: 'GET',
	});
