import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	authenticateButton: {
		id: 'polaris-common.controllers.unfurl.utils.authentication.apikey.authenticate-button',
		defaultMessage: 'Authenticate',
		description: 'Authenticate button placeholder',
	},
	saveButton: {
		id: 'polaris-common.controllers.unfurl.utils.authentication.apikey.save-button',
		defaultMessage: 'Save',
		description: 'Save button placeholder',
	},
	authHint: {
		id: 'polaris-common.controllers.unfurl.utils.authentication.apikey.auth-hint',
		defaultMessage: 'Please enter an API KEY',
		description: 'Auth hint text',
	},
});
