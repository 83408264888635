import type { ADF } from '@atlassian/jira-rich-content';

// DELETE_DRAFT_NEWER_CONTENT_FOUND
export const DELETE_DRAFT_NEWER_CONTENT_FOUND = 'DELETE_DRAFT_NEWER_CONTENT_FOUND' as const;

export const deleteDraftNewerContentFound = (fieldId: string) => ({
	type: DELETE_DRAFT_NEWER_CONTENT_FOUND,
	payload: {
		fieldId,
	},
});

export type DeleteDraftNewerContentFound = ReturnType<typeof deleteDraftNewerContentFound>;

// SAVE_DRAFT_REQUEST
export const SAVE_DRAFT_REQUEST = 'SAVE_DRAFT_REQUEST' as const;

export const saveDraftRequest = (fieldId: string, value: ADF, serverValue: string) => ({
	type: SAVE_DRAFT_REQUEST,
	payload: {
		fieldId,
		value,
		serverValue,
	},
});

export type SaveDraftRequest = ReturnType<typeof saveDraftRequest>;

// SAVE_DRAFT_SUCCESS
export const SAVE_DRAFT_SUCCESS = 'SAVE_DRAFT_SUCCESS' as const;

export const saveDraftSuccess = (draftKey: string, value: ADF) => ({
	type: SAVE_DRAFT_SUCCESS,
	payload: {
		draftKey,
		value,
	},
});

export type SaveDraftSuccess = ReturnType<typeof saveDraftSuccess>;

// SAVE_DRAFT_FAILURE
export const SAVE_DRAFT_FAILURE = 'SAVE_DRAFT_FAILURE' as const;

export const saveDraftError = (error: string) => ({
	type: SAVE_DRAFT_FAILURE,
	payload: error,
});

export type SaveDraftError = ReturnType<typeof saveDraftError>;

// LOAD_DRAFT_REQUEST
export const LOAD_DRAFT_REQUEST = 'LOAD_DRAFT_REQUEST' as const;

export const loadDraftRequest = (
	fieldId: string,
	draftKey: string,
	/*
	 * draftHandler should have type `EditCommentUpdateAction | FieldEditUpdate` but results in a
	 * cyclical dependency.
	 * Ticket to track fixing this - https://jdog.jira-dev.com/browse/BENTO-3191
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	draftHandler: any,
	serverValue?: string,
) => ({
	type: LOAD_DRAFT_REQUEST,
	payload: {
		fieldId,
		draftKey,
		draftHandler,
		serverValue,
	},
});

export type LoadDraftRequest = ReturnType<typeof loadDraftRequest>;

// LOAD_DRAFT_SUCCESS
export const LOAD_DRAFT_SUCCESS = 'LOAD_DRAFT_SUCCESS' as const;

export const loadDraftSuccess = (fieldId: string, value: ADF) => ({
	type: LOAD_DRAFT_SUCCESS,
	payload: { fieldId, value },
});

export type LoadDraftSuccess = ReturnType<typeof loadDraftSuccess>;

// LOAD_DRAFT_FAILURE
export const LOAD_DRAFT_FAILURE = 'LOAD_DRAFT_FAILURE' as const;

export const loadDraftFailure = (fieldId: string | null, error: string) => ({
	type: LOAD_DRAFT_FAILURE,
	payload: {
		fieldId,
		error,
	},
});

export type LoadDraftFailure = ReturnType<typeof loadDraftFailure>;

// DELETE_DRAFT_REQUEST
export const DELETE_DRAFT_REQUEST = 'DELETE_DRAFT_REQUEST' as const;

export const deleteDraftRequest = ({
	fieldId,
	draftKey,
}: {
	fieldId: string;
	draftKey?: string;
}) => ({
	type: DELETE_DRAFT_REQUEST,
	payload: {
		fieldId,
		draftKey,
	},
});

export type DeleteDraftRequest = ReturnType<typeof deleteDraftRequest>;

// DELETE_DRAFT_SUCCESS
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS' as const;

export const deleteDraftSuccess = (fieldId: string) => ({
	type: DELETE_DRAFT_SUCCESS,
	payload: {
		fieldId,
	},
});

export type DeleteDraftSuccess = ReturnType<typeof deleteDraftSuccess>;

// DELETE_DRAFT_FAILURE
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE' as const;

export const deleteDraftError = (fieldId: string | null, error: string) => ({
	type: DELETE_DRAFT_FAILURE,
	payload: {
		fieldId,
		error,
	},
});

export type DeleteDraftFailure = ReturnType<typeof deleteDraftError>;

export type DeleteDraftAction =
	| DeleteDraftRequest
	| DeleteDraftSuccess
	| DeleteDraftFailure
	| DeleteDraftNewerContentFound;

// @ts-expect-error - TS2456 - Type alias 'DraftsAction' circularly references itself.
export type DraftsAction =
	| SaveDraftRequest
	| SaveDraftSuccess
	| SaveDraftError
	| LoadDraftRequest
	| LoadDraftSuccess
	| LoadDraftFailure
	| DeleteDraftRequest
	| DeleteDraftSuccess
	| DraftsAction;
