import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-lib-onboarding.create-insight.title',
		defaultMessage: 'Capture insights',
		description: 'Spotlight title for "create insight" button',
	},
	description: {
		id: 'polaris-lib-onboarding.create-insight.description',
		defaultMessage:
			'Use insights to capture user needs: snippets from customer interviews, support tickets, and sales opportunities.',
		description: 'Spotlight description for "create insight" button',
	},
	learnMoreText: {
		id: 'polaris-lib-onboarding.create-insight.learn-more-text',
		defaultMessage: 'Understand what insights are and how to use them',
		description: 'Accessible text fot "Learn more" button',
	},
});
