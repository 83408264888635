import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Action } from '@atlassian/react-sweet-state';
import {
	getAvailableProducts,
	getAvailableProductsIncludingGlobalExperienceForOrg,
} from '../../../services/jira/available-products';
import type { Props, State } from '../types';

export const loadAvailableProducts =
	(): Action<State, Props> =>
	async ({ getState, setState }, { cloudId }: Props) => {
		if (getState().loading || getState().data) {
			return;
		}

		setState({
			loading: true,
			error: null,
			data: null,
		});

		try {
			const response = ff('polaris-atlas-check-global-experience')
				? await getAvailableProductsIncludingGlobalExperienceForOrg(cloudId)
				: await getAvailableProducts();

			setState({
				loading: false,
				error: null,
				data: response,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				'polaris.load-available-products',
				'Failed to load available products',
				error,
			);
			setState({
				loading: false,
				error,
				data: null,
			});
		}
	};
