import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { State, Props } from '../../types';
import { createGetField } from '../fields';
import { createGetCommentsCounts } from './comments';
import { createGetPlayContributionsAmountIssuesCounts } from './plays';
import {
	createGetStatusesForIssues,
	createGetFieldNumberValuesSelector,
	createGetFieldMultiOptionsSelector,
	createGetFieldSingleOptionSelector,
	createGetFieldUserSelector,
} from './index';

// TODO reselect

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_RESULT: Record<string, any> = {};
export const createGetFieldItemValuesSelector = (
	fieldKey: FieldKey | undefined,
	ids: LocalIssueId[],
	useWeight = false,
) => {
	const getFieldSelector = createGetField(fieldKey);
	const getNumberValuesSelector = createGetFieldNumberValuesSelector(fieldKey, ids);
	const getSingleOptionValuesSelector = createGetFieldSingleOptionSelector(
		fieldKey,
		ids,
		useWeight,
	);
	const getMultiOptionsValuesSelector = createGetFieldMultiOptionsSelector(fieldKey, ids);
	const getStatusValuesSelector = createGetStatusesForIssues(ids);
	const getUserValuesSelector = createGetFieldUserSelector(fieldKey, ids);
	const getCommentsCountSelector = createGetCommentsCounts(ids);

	return (state: State, props?: Props): Record<LocalIssueId, string | number> => {
		const field = getFieldSelector(state, props);
		const playId = field?.play?.id;
		if (field === undefined) {
			return EMPTY_RESULT;
		}
		if (
			field.type === FIELD_TYPES.NUMBER ||
			field.type === FIELD_TYPES.RATING ||
			field.type === FIELD_TYPES.SLIDER ||
			field.type === FIELD_TYPES.CHECKBOX ||
			field.type === FIELD_TYPES.FORMULA ||
			field.type === FIELD_TYPES.INSIGHTS ||
			field.type === FIELD_TYPES.LINKED_ISSUES
		) {
			return getNumberValuesSelector(state, props);
		}
		if (field.type === FIELD_TYPES.STATUS) {
			return getStatusValuesSelector(state, props);
		}
		if (
			field.type === FIELD_TYPES.REPORTER ||
			field.type === FIELD_TYPES.CREATOR ||
			field.type === FIELD_TYPES.ASSIGNEE
		) {
			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
			return getUserValuesSelector(state, props);
		}
		if (field.type === FIELD_TYPES.SINGLE_SELECT) {
			return getSingleOptionValuesSelector(state, props);
		}
		if (field.type === FIELD_TYPES.MULTI_SELECT || field.type === FIELD_TYPES.JSW_MULTI_SELECT) {
			return getMultiOptionsValuesSelector(state, props);
		}
		if (field.type === FIELD_TYPES.ISSUE_COMMENTS) {
			return getCommentsCountSelector(state);
		}
		if (field.type === FIELD_TYPES.VOTES && playId !== undefined) {
			const getPlayCountSelector = createGetPlayContributionsAmountIssuesCounts(playId, ids);
			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
			return getPlayCountSelector(state, props);
		}

		return EMPTY_RESULT;
	};
};
