import type { MiddlewareAPI } from 'redux';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';

// SAVE_SERVER_TIME
export const SAVE_SERVER_TIME = 'SAVE_SERVER_TIME' as const;

const saveServerTime = (key: string, serverTime: number) => ({
	type: SAVE_SERVER_TIME,
	payload: { key, serverTime },
});

export type SaveServerTimeAction = ReturnType<typeof saveServerTime>;

export const reportServerTimeViaState =
	(store: MiddlewareAPI<State>) => (key: string) => (headers: Headers) => {
		// @ts-expect-error - Argument of type '{ type: "SAVE_SERVER_TIME"; payload: { key: string; serverTime: number; }; }' is not assignable to parameter of type 'Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: MyPreferences | undefined; }>; ... 5 more ...; validators: Validators; }>'.
		store.dispatch(saveServerTime(key, Number(headers.get('atl-vtm-backend-time'))));
	};
