import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

export type NotificationEmailResponse = {
	emailAddress: EmailAddress;
	emailAddressStatus?: string[];
};

export type EmailAddress = string;
export const toEmailAddress = (value: string): EmailAddress => value;

export type NotificationEmailCustomDomainAllowListResponse = {
	emailAddresses: EmailAddress[];
};

export type NotificationEmailOptionsResponse = {
	customDomainEmails: EmailAddress[];
	defaultEmail: EmailAddress;
};

export type NotificationEmailServiceState = {
	data: {
		currentEmail: EmailAddress | undefined;
		currentEmailStatus?: string[];
		allowList: EmailAddress[];
		defaultAtlassianEmail: string;
	};
	error: FetchError | ValidationError | undefined;
	loadingEmail: boolean;
	loadingAllowList: boolean;
	loadingDefaultEmail: boolean;
	hasCustomDomainEmail: boolean;
};

export type NotificationEmailServiceActions = {
	fetchCurrentEmail: () => Promise<void>;
	fetchAllowList: () => Promise<void>;
	updateEmail: (updatedEmail: EmailAddress) => Promise<void>;
	clearErrors: () => void;
	defaultAddress: () => EmailAddress;
	fetchDefaultEmailAndAllowList: (isFirstLoad?: boolean) => Promise<void>;
};

export type NotificationEmailService = (
	arg1: ProjectId,
) => [NotificationEmailServiceState, NotificationEmailServiceActions];
