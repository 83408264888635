import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { toProjectAri } from '@atlassian/jira-polaris-domain-project/src/utils.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { PlayContributionRemote } from '@atlassian/jira-polaris-remote-play-contribution/src/controllers/play-contribution/types.tsx';
import type { CloudId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createPolarisApiPlayContributionRemote,
	createNotImplementedPlayContributionRemote,
} from './play-contribution';

export const createPlayContributionRemote = (
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	cloudId: CloudId,
	projectId: ProjectId,
): PlayContributionRemote => {
	const projectAri = toProjectAri(projectId, cloudId);

	return {
		...createPolarisApiPlayContributionRemote(apolloClient, projectAri),
	};
};

export const createPlayContributionSharingRemote = (): PlayContributionRemote => ({
	...createNotImplementedPlayContributionRemote(),
});

export const createPlayContributionCollectionRemote = (): PlayContributionRemote => ({
	...createNotImplementedPlayContributionRemote(),
});
