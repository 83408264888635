import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { JiraIssuefieldvalueAri } from '@atlassian/ari/jira';
import { ValidationError } from '@atlassian/jira-fetch';
import type {
	singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation as SingleLineTextHeadingMutation,
	singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$data as SingleLineTextHeadingMutationData,
} from '@atlassian/jira-relay/src/__generated__/singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import type { StringValue } from '../types';
import type { SaveField } from './types';

export const useSaveSummaryField = (issueId: string | undefined): SaveField => {
	const cloudId = useCloudId();

	const [commit] = useMutation<SingleLineTextHeadingMutation>(graphql`
		mutation useSaveSummaryFieldMutation($input: JiraUpdateSingleLineTextFieldInput!)
		@raw_response_type {
			jira {
				updateSingleLineTextField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						...singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView
					}
				}
			}
		}
	`);

	return useCallback(
		async (_issueKey: IssueKey, fieldId: string, newValue: StringValue) =>
			new Promise((resolve, reject) => {
				commit({
					variables: {
						input: {
							id: JiraIssuefieldvalueAri.create({
								siteId: cloudId,
								issueId: issueId || '',
								fieldId,
							}).toString(),
							operation: {
								operation: 'SET',
								text: newValue,
							},
						},
					},
					onCompleted(response: SingleLineTextHeadingMutationData) {
						if (response.jira?.updateSingleLineTextField) {
							const { success, errors: responseErrors } = response.jira?.updateSingleLineTextField;
							if (success) {
								resolve();
								return;
							}
							if (responseErrors?.length) {
								reject(
									responseErrors[0].message
										? new ValidationError(responseErrors[0].message)
										: new Error('Unknown error when mutating summary'),
								);
							}
						} else {
							reject(new Error('Unknown error when mutating summary'));
						}
					},
					onError(error: Error) {
						reject(error);
					},
				});
			}),
		[cloudId, commit, issueId],
	);
};
