import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { DeleteFieldOptionRequest } from './types';

export const deleteFieldOption = ({
	projectId,
	issueTypeId,
	fieldKey,
	optionId,
}: DeleteFieldOptionRequest): Promise<void> =>
	performDeleteRequest(
		`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options/${optionId}`,
		{
			body: JSON.stringify({
				issueTypeId,
			}),
		},
	);
