import React, { useCallback, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Inline } from '@atlaskit/primitives';
import type { OptionData } from '@atlaskit/user-picker';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { AccessScreen, type AccessScreenProps } from './access-screen';
import { ErrorScreen } from './error-screen';
import { LoadingScreen } from './loading-screen';
import messages from './messages';

export type ViewAccessModalProps = {
	isOpen: boolean;
	isLoading: boolean;
	hasError: boolean;
	onClose: () => void;
} & Omit<AccessScreenProps, 'userPickerOptions' | 'setUserPickerOptions'>;

export const ViewAccessModal = (props: ViewAccessModalProps) => {
	const { isOpen, isLoading, hasError, onClose, ...accessScreenProps } = props;
	const [userPickerOptions, setUserPickerOptions] = useState<OptionData[]>([]);
	const { formatMessage } = useIntl();

	const [shouldCloseOnEscapePress, setShouldCloseOnEscapePress] = useState(true);

	const handleCloseModal = useCallback(() => {
		onClose();
		setUserPickerOptions([]);

		if (fg('polaris_access_view_modal_esc_handling')) {
			setShouldCloseOnEscapePress(true);
		}
	}, [onClose]);

	const handleMenuOpen = useCallback(() => {
		setShouldCloseOnEscapePress(false);
	}, []);

	const handleMenuClose = useCallback(() => {
		setShouldCloseOnEscapePress(true);
	}, []);

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<Modal
						onClose={handleCloseModal}
						autoFocus={false}
						shouldCloseOnEscapePress={shouldCloseOnEscapePress}
					>
						<ModalHeader>
							<Inline space="space.050">
								{userPickerOptions.length > 0 && (
									<IconButton
										icon={ArrowLeftIcon}
										spacing="compact"
										appearance="subtle"
										onClick={() => setUserPickerOptions([])}
										label={formatMessage(messages.cancelAddingPrincipalsLabel)}
									/>
								)}
								<ModalTitle>{formatMessage(messages.modalHeader)}</ModalTitle>
							</Inline>
							<IconButton
								appearance="subtle"
								onClick={handleCloseModal}
								label={formatMessage(messages.modalCloseButtonLabel)}
								icon={CrossIcon}
							/>
						</ModalHeader>
						<ModalBody>
							<Box paddingBlockEnd="space.300">
								{isLoading && <LoadingScreen />}
								{hasError && !isLoading && <ErrorScreen />}
								{!isLoading && !hasError && (
									<AccessScreen
										{...accessScreenProps}
										userPickerOptions={userPickerOptions}
										setUserPickerOptions={setUserPickerOptions}
										onMenuOpen={
											fg('polaris_access_view_modal_esc_handling') ? handleMenuOpen : undefined
										}
										onMenuClose={
											fg('polaris_access_view_modal_esc_handling') ? handleMenuClose : undefined
										}
									/>
								)}
							</Box>
						</ModalBody>
					</Modal>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};
