import React, {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
	type ChangeEvent,
} from 'react';
import debounce from 'lodash/debounce';
import type { PopupComponentProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield, { type TextFieldProps } from '@atlaskit/textfield';
import { EmojiPicker } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/index.tsx';
import type { EmojiPickerProps } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/types.tsx';

type InputWithEmojiPickerProps = {
	emojiId: string | undefined;
	placeholder?: string;
	testId?: string;
	value?: string;
	emojiPickerPlacement?: PopupComponentProps['data-placement'];
	emojiPickerOffset?: EmojiPickerProps['offset'];
	onChange: (value: string) => void;
	onUpdateEmoji?: EmojiPickerProps['onEmojiSelected'];
	isEmojiReadOnly?: boolean;
	isInputReadOnly?: boolean;
	isEmojiPickerVisible?: boolean;
	disableDebounce?: boolean;
	renderEmojiPickerToParent?: boolean;
} & Omit<TextFieldProps, 'onChange' | 'isReadOnly'>;

export const InputWithEmojiPicker = forwardRef<HTMLElement, InputWithEmojiPickerProps>(
	(
		{
			emojiId,
			placeholder = '',
			testId,
			value,
			emojiPickerPlacement = 'bottom-start',
			emojiPickerOffset = [-3, 9],
			isEmojiReadOnly = false,
			isInputReadOnly = false,
			isEmojiPickerVisible = true,
			disableDebounce = false,
			renderEmojiPickerToParent = false,
			onChange,
			onUpdateEmoji,
			...textFieldProps
		},
		ref,
	) => {
		const [localValue, setValue] = useState(value);

		const debouncedChange = useMemo(() => debounce(onChange, 200), [onChange]);

		const handleChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				const target = event.target;
				setValue(target.value);
				if (disableDebounce) {
					onChange(target.value);
				} else {
					debouncedChange(target.value);
				}
			},
			[debouncedChange, disableDebounce, onChange],
		);

		useEffect(() => {
			setValue(value);
		}, [value]);

		return (
			<>
				<Textfield
					{...textFieldProps}
					ref={ref}
					testId={testId}
					value={localValue}
					placeholder={placeholder}
					onChange={handleChange}
					isReadOnly={isInputReadOnly}
					elemBeforeInput={
						isEmojiPickerVisible ? (
							<Box xcss={emojiPickerWrapper}>
								<EmojiPicker
									onEmojiSelected={onUpdateEmoji}
									readonly={isEmojiReadOnly}
									selectedEmojiId={emojiId}
									placement={emojiPickerPlacement}
									offset={emojiPickerOffset}
									shouldRenderToParent={renderEmojiPickerToParent}
								/>
							</Box>
						) : undefined
					}
				/>
			</>
		);
	},
);

const emojiPickerWrapper = xcss({
	marginLeft: 'space.025',
});
