import React, { useMemo } from 'react';
import {
	useFieldLabel,
	useFieldType,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import {
	useField,
	useFieldOptionsWithAriResolved,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { useCurrentMatrixOptionsHasReverseOrder } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	type Axis,
	CONTINUOUS,
	DISCRETE,
	type LabelComponentProps,
} from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';
import { useMatrixAxisFieldOptions } from '../../common/utils/matrix-axis-field-options';
import { AxisLabel } from '../axis-label';
import { RatingNumberFieldLabel } from '../number-field';
import { OptionFieldLabel } from '../option-field';
import { StatusFieldLabel } from '../status-field';
import { UserFieldValue } from '../user-field';

export const NO_WEIGHT_Z_AXIS: Axis = {
	type: DISCRETE,
	range: {
		values: [25, 50, 75, 100],
	},
};

const ratingOptions = [1, 2, 3, 4, 5];
const reverseRatingOptions = [...ratingOptions].reverse();

const createRatingAxis = (fieldKey: FieldKey, isReverseOrder?: boolean, label?: string): Axis => ({
	type: DISCRETE,
	range: { values: isReverseOrder ? reverseRatingOptions : ratingOptions },
	Label: () => <AxisLabel text={label} />,
	components: {
		Label: ({ value }: LabelComponentProps) => (
			<RatingNumberFieldLabel value={value} fieldKey={fieldKey} />
		),
	},
});

const createSliderAxis = (fieldKey: FieldKey, isReverseOrder?: boolean, label?: string): Axis => ({
	type: CONTINUOUS,
	range: {
		min: 0,
		max: 100,
		isReverseOrder,
		stepConfig: {
			maxStepCount: 10,
		},
	},
	Label: () => <AxisLabel text={label} />,
});

export const calculateAxisMax = (itemValues: number[]): number => {
	const MIN_AXIS_VALUE = 5;
	const max = Math.max(MIN_AXIS_VALUE, Math.max(...itemValues));
	const magnitude = Math.pow(10, Math.floor(Math.log10(max)));
	return Math.ceil(max / magnitude) * magnitude;
};

export const useAxisConfiguration = (
	fieldKey: FieldKey | undefined,
	itemValues: (string | number | undefined)[],
	useWeights = false,
	dimension: string,
): Axis | undefined => {
	const fieldType = useFieldType(fieldKey);
	const field = useField(fieldKey);
	const label = useFieldLabel(fieldKey);
	const options = useMatrixAxisFieldOptions(field, false, dimension);
	const isReverseOrder = useCurrentMatrixOptionsHasReverseOrder(dimension);
	const weightOptions = useFieldOptionsWithAriResolved(fieldKey);
	return useMemo(() => {
		if (fieldKey === undefined) {
			return undefined;
		}

		if (fieldType === FIELD_TYPES.SLIDER) {
			return createSliderAxis(fieldKey, isReverseOrder, label);
		}

		if (fieldType === FIELD_TYPES.RATING) {
			return createRatingAxis(fieldKey, isReverseOrder, label);
		}

		if (
			fieldType === FIELD_TYPES.NUMBER ||
			fieldType === FIELD_TYPES.FORMULA ||
			fieldType === FIELD_TYPES.INSIGHTS ||
			fieldType === FIELD_TYPES.LINKED_ISSUES ||
			fieldType === FIELD_TYPES.ISSUE_COMMENTS ||
			fieldType === FIELD_TYPES.VOTES
		) {
			const scaleMax = calculateAxisMax(
				itemValues.filter((it): it is number => typeof it === 'number'),
			);
			return {
				type: CONTINUOUS,
				range: {
					min: 0,
					max: scaleMax,
					isReverseOrder,
					stepConfig: {
						maxStepCount: scaleMax >= 10 ? 10 : scaleMax,
					},
				},
				Label: () => <AxisLabel text={label} />,
			};
		}
		if (
			fieldType === FIELD_TYPES.SINGLE_SELECT ||
			fieldType === FIELD_TYPES.MULTI_SELECT ||
			fieldType === FIELD_TYPES.JSW_MULTI_SELECT
		) {
			if (useWeights) {
				const range =
					fieldType === FIELD_TYPES.SINGLE_SELECT
						? (weightOptions || []).map(({ weight }) => weight ?? 0)
						: (itemValues || []).map((weight) => (weight !== undefined ? Number(weight) : 0));

				return {
					type: CONTINUOUS,
					range: {
						min: 0,
						max: Math.max(...range),
					},
					Label: () => <AxisLabel text={label} />,
					components: {
						Label: ({ value }: LabelComponentProps) => (
							<OptionFieldLabel groupIdentity={String(value)} fieldKey={fieldKey} />
						),
					},
				};
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const rangeValues = options.reduce<Array<any>>((result, { groupIdentity }) => {
				if (groupIdentity !== undefined) {
					result.push(groupIdentity);
				}
				return result;
			}, []);
			return {
				type: DISCRETE,
				range: { values: rangeValues },
				Label: () => <AxisLabel text={label} />,
				components: {
					Label: ({ value }: LabelComponentProps) => (
						<OptionFieldLabel groupIdentity={String(value)} fieldKey={fieldKey} />
					),
				},
			};
		}
		if (fieldType === FIELD_TYPES.STATUS) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const statuses = options.reduce<Array<any>>((result, { groupIdentity }) => {
				if (groupIdentity !== undefined) {
					result.push(groupIdentity);
				}
				return result;
			}, []);
			return {
				type: DISCRETE,
				range: { values: statuses },
				Label: () => <AxisLabel text={label} />,
				components: {
					Label: ({ value }: LabelComponentProps) => (
						<StatusFieldLabel groupIdentity={String(value)} />
					),
				},
			};
		}

		if (
			fieldType === FIELD_TYPES.ASSIGNEE ||
			fieldType === FIELD_TYPES.REPORTER ||
			fieldType === FIELD_TYPES.CREATOR
		) {
			const users = (options || []).map(({ groupIdentity }) => groupIdentity || '');
			return {
				type: DISCRETE,
				range: { values: users },
				Label: () => <AxisLabel text={label} />,
				components: {
					Label: ({ value, mode }: LabelComponentProps) => (
						<UserFieldValue
							accountId={String(value)}
							fieldKey={fieldKey}
							rotate={mode === 'VERTICAL'}
						/>
					),
				},
			};
		}
		return undefined;
	}, [fieldKey, fieldType, itemValues, isReverseOrder, label, useWeights, options, weightOptions]);
};
