import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { linkIssue, linkIssueInJira } from '../../services/jira/create-and-link-issues';
import { getLinkedIssuesArchived } from '../../services/jira/linked-issue-archived';
import { getLinkedIssuesStoryPoints } from '../../services/jira/linked-issues-story-points';
import { deleteIssueLink } from '../../services/jira/unlink-issue';
import type { IssueLinkRemote } from './types';
import { transformArchived } from './utils';

export const createNotImplementedIssueLinkRemote = (): IssueLinkRemote => ({
	createIssueLink: notImplementedError('createIssueLink'),
	deleteIssueLink: notImplementedError('deleteIssueLink'),
	fetchLinkedIssuesArchived: notImplementedError('fetchLinkedIssuesArchived'),
	fetchLinkedIssuesStoryPointField: notImplementedError('fetchLinkedIssuesStoryPointField'),
	linkIssueInJira: notImplementedError('linkIssueInJira'),
});

export const createSharingIssueLinkRemote = (): IssueLinkRemote => ({
	...createNotImplementedIssueLinkRemote(),
	fetchLinkedIssuesArchived: () => Promise.resolve([]),
	fetchLinkedIssuesStoryPointField: () => Promise.resolve([]),
});

export const createIssueLinkRemote = (cloudId: CloudId): IssueLinkRemote => ({
	createIssueLink: ({ issueLinkTypeId, outwardIssueKey, inwardIssueKey }) =>
		linkIssue({ issueLinkTypeId, outwardIssueKey, inwardIssueKey }),
	deleteIssueLink: ({ issueLinkId }) => deleteIssueLink({ issueLinkId }),
	fetchLinkedIssuesArchived: ({ issueIds }) =>
		getLinkedIssuesArchived(issueIds, cloudId).then(transformArchived),
	fetchLinkedIssuesStoryPointField: ({ issueKeys, storyPointsCustomFieldId }) =>
		getLinkedIssuesStoryPoints(issueKeys, storyPointsCustomFieldId),
	linkIssueInJira,
});
