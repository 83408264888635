import type { ContextState } from '@atlassian/jira-issue-view-common-types/src/context-type';

// SET_CONTEXT
export const SET_CONTEXT = 'SET_CONTEXT' as const;

export const setContext = (context: Partial<ContextState>) => ({
	type: SET_CONTEXT,
	payload: context,
});

export type SetContext = ReturnType<typeof setContext>;

export type ContextAction = SetContext;
