import React from 'react';
import type { StatusTransition } from '../../../../common/types';
import { StatusLozenge } from '../../../../common/ui/status-lozenge';

type Props = {
	isDisabled?: boolean;
	transition: StatusTransition;
};

export const TransitionWithoutLabel = (props: Props) => (
	<StatusLozenge status={props.transition.to} isDisabled={props.isDisabled} />
);
