import { invokeObject as invokeObjectCall } from '../../services/polaris-api/invoke-object';
import type { InvokeObjectRequest, ProviderProps, RemoteObjectInvoker } from '../../types';

export const invokeObject =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteObjectInvoker =>
	({ issueId, url, oauthClientId, snippetData, invokeAction, authToken }: InvokeObjectRequest) =>
		invokeObjectCall(
			apolloClient,
			cloudId,
			projectId,
			issueId,
			url,
			oauthClientId,
			snippetData,
			invokeAction,
			authToken,
		).then((response) => ({
			...response,
			externalAuth: response.externalAuth ? [...response.externalAuth] : null,
		}));
