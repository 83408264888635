import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';

const template: ADF = {
	version: 1,
	type: 'doc',

	content: [
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Shipping is just the beginning: use this template to review the outcomes of a particular solution exploration. Remember: the first version of anything is generally bad, and it will take many iterations to get it right!',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#403294',
							},
						},
					],
				},
			],
		},
		{
			type: 'table',
			attrs: {
				isNumberColumnEnabled: false,
				layout: 'default',
				localId: '7b5278a4-9816-4f8b-8217-55a0bb8c05be',
			},
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Driver',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who’s the driver for this review?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Contributors',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who are contributors helping with the review?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Informed',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who should be informed of the findings?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':thought_balloon:',
						id: '1f4ad',
						text: '💭',
					},
				},
				{
					type: 'text',
					text: ' Problem definition',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Recap the problem you’re trying to solve.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':notepad_spiral:',
						id: '1f5d2',
						text: '🗒',
					},
				},
				{
					type: 'text',
					text: ' Solution definition',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Recap the solution you’ve tested.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':test_tube:',
						id: '1f9ea',
						text: '🧪',
					},
				},
				{
					type: 'text',
					text: ' Outcomes and learnings',
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'Was the solution successful?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Yes/no/inconclusive.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Does the solution achieve the stated goals? Provide as much data as you can, both qualitative and qualitative.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'What should we do next with the solution?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Based on the above, do you recommend:',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'bulletList',
			content: [
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Iterating on the solution',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Putting the solution on maintenance (it did its job)',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Retiring the solution and/or trying another one',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'What worked well?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Describe what worked well when implementing, testing and validating the solution - that you’d like to see happen again.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'What should we improve next time?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Describe what the team should do differently the next time around.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'text',
					text: 'Resources',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Here are plays from the Atlassian team playbook that are relevant to this: ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/retrospective',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/4-ls-retrospective-technique',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
	],
};

export default template;
