import { performGetRequest } from '@atlassian/jira-fetch';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types';
import type { ServerWorklog } from '../model';
import { getWorklogUrl } from './common';

export type FetchWorklogResponse = {
	startAt: number;
	maxResults: number;
	total: number;
	worklogs: ServerWorklog[];
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	startAt: number,
	maxResults?: number,
	reverseSort?: boolean,
): Promise<FetchWorklogResponse> =>
	performGetRequest(
		`${getWorklogUrl(baseUrl, issueKey)}?startAt=${startAt}&reverseSortOrder=${
			reverseSort ? 'true' : 'false'
		}${maxResults ? `&maxResults=${maxResults}` : ''}`,
	);
