import React, { useState, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import Popup from '@atlaskit/popup';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { isGroupBySupportedFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/utils/is-group-by-supported';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { IssueCreateGroupTypeNoGroup } from '@atlassian/jira-polaris-common/src/controllers/issue/types';
import {
	useOpenRightSidebarOnField,
	useOpenRightSidebarOnFilter,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useAllowedFiltersFields,
	useActivePermanentFiltersFields,
	useActiveTemporaryFiltersFields,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewSortBy,
	useCurrentViewSortByField,
	useCurrentViewVerticalGroupBy,
	useIsCurrentViewInCaptureSet,
	useIsExporting,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	useCanEditFields,
	useCanManageViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { VIEW_KIND_BOARD } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { callWhenIdle } from '@atlassian/jira-polaris-lib-idle-callback';
import { PRESENTATIONAL_FIELDKEYS } from '@atlassian/jira-polaris-lib-list/src/constants.tsx';
import {
	ContextualAnalyticsData,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsRankingAllowedDuringCreation } from '../../../../utils';
import { useCanCreateIdeasWhenNoGrouping } from '../../utils/can-create-ideas';
import { CellDropdownMenu } from './cell-dropdown-menu';
import { ColumnAdd } from './column-add';
import { MIN_VISIBLE_LABEL_WIDTH } from './constants';
import { DropdownMenuItem } from './dropdown-menu-item';
import messages from './messages';
import { SummaryColumnAddIdea } from './summary-column-add-idea';
import { HeaderTrigger } from './trigger';
import type { HeaderCellProps, HeaderCellSummaryProps } from './types';

export const HeaderCell = ({ children, fieldKey }: HeaderCellProps) => {
	const { formatMessage } = useIntl();
	const fieldType = useFieldType(fieldKey);
	const canEditFields = useCanEditFields();
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const sortField = useCurrentViewSortByField(fieldKey);
	const sortFields = useCurrentViewSortBy();
	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const openRightSidebarOnFilter = useOpenRightSidebarOnFilter();
	const allowedFiltersFields = useAllowedFiltersFields();
	const activePermanentFiltersFields = useActivePermanentFiltersFields();
	const activeTemporaryFiltersFields = useActiveTemporaryFiltersFields();
	const isCaptureSetView = useIsCurrentViewInCaptureSet();
	const isFieldRemovable = fieldKey !== SUMMARY_FIELDKEY;
	const isSharedView = useIsSharedView();

	const {
		clearFieldOrNumericFilter,
		setSortFieldsOfCurrentView,
		cloneCurrentView,
		removeFieldFromView,
	} = useViewActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [width, setWidth] = useState<number>(MIN_VISIBLE_LABEL_WIDTH);
	const throttledSetWidth = throttle(setWidth, 50);
	const isLabelHidden = width < MIN_VISIBLE_LABEL_WIDTH;

	const canCloneAsBoard = !isCaptureSetView;

	const [isDropdownOpen, setDropdownOpen] = useState(false);

	const safelyCloseDropdown = useCallback(() => {
		// opening the side panel and closing the Header's dropdown menu at the same time sometimes causes weird layout shifts.
		// to avoid that we schedule the closing of the dropdown menu to the next iteration of the event loop
		callWhenIdle(() => setDropdownOpen(false), 50);
	}, []);

	const onToggleDropdown = useCallback(() => {
		setDropdownOpen(!isDropdownOpen);

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'viewFieldActions',
		);
	}, [setDropdownOpen, isDropdownOpen, createAnalyticsEvent]);

	const onManageField = useCallback(() => {
		if (canEditFields) {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: 'editField',
			});
		} else {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: 'viewFieldSettings',
			});
		}
		openRightSidebarOnField(fieldKey);
		safelyCloseDropdown();
	}, [canEditFields, openRightSidebarOnField, fieldKey, safelyCloseDropdown, createAnalyticsEvent]);

	const sortFieldsWithoutThisField = useMemo(
		() => sortFields?.filter((sf: SortField) => sf.fieldKey !== fieldKey) || [],
		[fieldKey, sortFields],
	);

	const onSetAsSort = useCallback(
		(asc: boolean) => {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: `setFieldSort ${asc ? 'ASC' : 'DESC'}`,
			});
			setDropdownOpen(false);
			setSortFieldsOfCurrentView([{ fieldKey, asc }]);
		},
		[setSortFieldsOfCurrentView, fieldKey, createAnalyticsEvent],
	);

	const onRemoveSort = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'removeFieldSort',
		});

		setDropdownOpen(false);
		setSortFieldsOfCurrentView(
			sortFieldsWithoutThisField.length > 0 ? sortFieldsWithoutThisField : undefined,
		);
	}, [setSortFieldsOfCurrentView, sortFieldsWithoutThisField, createAnalyticsEvent]);

	const onRemoveField = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'removeField',
		});

		setDropdownOpen(false);
		removeFieldFromView(fieldKey);
	}, [createAnalyticsEvent, removeFieldFromView, fieldKey]);

	const onViewAsBoard = useCallback(() => {
		if (canCloneAsBoard) {
			fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
				selectedActionItem: 'cloneAsBoard',
			});

			setDropdownOpen(false);
			cloneCurrentView(VIEW_KIND_BOARD, fieldKey);
		}
	}, [canCloneAsBoard, cloneCurrentView, createAnalyticsEvent, fieldKey]);

	const onFilter = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'filter',
		});

		openRightSidebarOnFilter(fieldKey);
		safelyCloseDropdown();
	}, [createAnalyticsEvent, fieldKey, openRightSidebarOnFilter, safelyCloseDropdown]);

	const onClearFilter = useCallback(() => {
		fireCompoundAnalyticsEvent.ListView.columnActionItemClicked(createAnalyticsEvent({}), {
			selectedActionItem: 'clearFilter',
		});

		clearFieldOrNumericFilter(fieldKey);
		clearFieldOrNumericFilter(fieldKey, true);
	}, [clearFieldOrNumericFilter, createAnalyticsEvent, fieldKey]);

	const shouldFilterBeVisible = useMemo(
		() => allowedFiltersFields.some((field) => field.key === fieldKey),
		[allowedFiltersFields, fieldKey],
	);

	const shouldClearFilterBeVisible = useMemo(() => {
		if (fg('polaris_just-for-you')) {
			const canManageView = ff('polaris.view-permissions_plaoi')
				? canManageCurrentView
				: canManageViews;

			if (canManageView) {
				return (
					activePermanentFiltersFields.some((field) => field.key === fieldKey) ||
					activeTemporaryFiltersFields.some((field) => field.key === fieldKey)
				);
			}

			return activeTemporaryFiltersFields.some((field) => field.key === fieldKey);
		}

		return activePermanentFiltersFields.some((field) => field.key === fieldKey);
	}, [
		activePermanentFiltersFields,
		activeTemporaryFiltersFields,
		canManageCurrentView,
		canManageViews,
		fieldKey,
	]);

	const getRemoveFieldDisabledTooltip = () => {
		if (!isFieldRemovable) {
			return formatMessage(messages.nonRemovableFromView);
		}

		return (ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews)
			? undefined
			: formatMessage(messages.noManageViewPermissions);
	};

	if (fieldKey === PRESENTATIONAL_FIELDKEYS.ADD) {
		return (
			<ColumnAdd
				isDisabled={ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews}
			/>
		);
	}

	const mainContent = (
		<>
			{ff('polaris.list-view-header-cell-options-redesign_0gogh') ? (
				<CellDropdownMenu
					trigger={({ triggerRef, ...triggerProps }) => (
						<HeaderTrigger
							{...triggerProps}
							ref={triggerRef}
							onClick={onToggleDropdown}
							isLabelHidden={isLabelHidden}
							fieldKey={fieldKey}
							disabled={false}
							disableTooltip={isDropdownOpen}
						/>
					)}
					canCloneAsBoard={canCloneAsBoard}
					canEditFields={canEditFields}
					canManageCurrentView={canManageCurrentView}
					canManageViews={canManageViews}
					fieldType={fieldType}
					isClearFilterVisible={shouldClearFilterBeVisible}
					isFieldRemovable={isFieldRemovable}
					isFilterVisible={shouldFilterBeVisible}
					isOpen={isDropdownOpen}
					isSharedView={isSharedView}
					sortField={sortField}
					onClearFilter={onClearFilter}
					onFilter={onFilter}
					onManageField={onManageField}
					onRemoveField={onRemoveField}
					onRemoveSort={onRemoveSort}
					onSetAsSort={onSetAsSort}
					onViewAsBoard={onViewAsBoard}
					setIsOpen={setDropdownOpen}
				/>
			) : (
				<Popup
					isOpen={isDropdownOpen}
					onClose={() => setDropdownOpen(false)}
					placement="bottom-end"
					content={() => (
						<DropdownMenuContainer>
							{!isSharedView && (
								<DropdownMenuItem onClick={onManageField}>
									{!canEditFields
										? formatMessage(messages.fieldSettings)
										: formatMessage(messages.editField)}
								</DropdownMenuItem>
							)}
							{(!sortField || !sortField.asc) && (
								<DropdownMenuItem onClick={() => onSetAsSort(true)}>
									{formatMessage(messages.sortAscending)}
								</DropdownMenuItem>
							)}
							{(!sortField || sortField.asc) && (
								<DropdownMenuItem onClick={() => onSetAsSort(false)}>
									{formatMessage(messages.sortDescending)}
								</DropdownMenuItem>
							)}
							{sortField && (
								<DropdownMenuItem onClick={onRemoveSort}>
									{formatMessage(messages.removeSort)}
								</DropdownMenuItem>
							)}
							{!isSharedView && (
								<DropdownMenuItem
									isDisabled={
										!isFieldRemovable ||
										(ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews)
									}
									onClick={onRemoveField}
									tooltipProps={{
										content: getRemoveFieldDisabledTooltip(),
									}}
								>
									{formatMessage(messages.removeFromView)}
								</DropdownMenuItem>
							)}

							{!isSharedView && isGroupBySupportedFieldType(fieldType) && (
								<DropdownMenuItem
									isDisabled={
										!canCloneAsBoard ||
										(ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews)
									}
									onClick={onViewAsBoard}
									tooltipProps={{
										content: canCloneAsBoard
											? undefined
											: formatMessage(messages.viewAsBoardDisabled),
									}}
								>
									{formatMessage(messages.viewAsBoard)}
								</DropdownMenuItem>
							)}
						</DropdownMenuContainer>
					)}
					trigger={(triggerProps) => (
						<HeaderTrigger
							{...triggerProps}
							onClick={onToggleDropdown}
							isLabelHidden={isLabelHidden}
							fieldKey={fieldKey}
							disabled={false}
							disableTooltip={isDropdownOpen}
						/>
					)}
				/>
			)}
		</>
	);

	return (
		<ContextualAnalyticsData attributes={{ fieldType }}>
			{ff('polaris.list-view-creating-ideas-non-grouping') && children ? (
				<Inline grow="fill" space="space.050" alignBlock="center">
					<Box xcss={mainContentStyles}>
						<WidthObserver setWidth={throttledSetWidth} />
						{mainContent}
					</Box>
					<Inline xcss={extraContentStyles}>{children}</Inline>
				</Inline>
			) : (
				<Box xcss={headerCellContainerStyles}>
					<WidthObserver setWidth={throttledSetWidth} />
					{mainContent}
				</Box>
			)}
		</ContextualAnalyticsData>
	);
};

// This is intentionally a separate component to avoid
// calling multiple times hooks used below for each column / HeaderCell
// as customers may define "infinite" number of columns for their List View
export const HeaderCellSummary = ({ fieldKey }: HeaderCellSummaryProps) => {
	const isExporting = useIsExporting();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const canCreateIdeasWhenNoGrouping = useCanCreateIdeasWhenNoGrouping(isExporting);
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();
	const { createIssue } = useIssueActions();

	const handleSummaryAddIdeaClick = useCallback(() => {
		createIssue(0, undefined, {
			groupType: IssueCreateGroupTypeNoGroup,
			rankingAllowed: isRankingAllowedDuringCreation,
		});
	}, [createIssue, isRankingAllowedDuringCreation]);

	return (
		<HeaderCell fieldKey={fieldKey}>
			{!verticalGroupByField && canCreateIdeasWhenNoGrouping && (
				<SummaryColumnAddIdea onClick={handleSummaryAddIdeaClick} />
			)}
		</HeaderCell>
	);
};

const headerCellContainerStyles = xcss({
	width: '100%',
});

const mainContentStyles = xcss({
	flex: 1,
});

const extraContentStyles = xcss({
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuContainer = styled.div({
	paddingTop: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.xxsmall', fontFallback.heading.xxsmall),
});
