import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	buttonLabel: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.footer.button-label',
		defaultMessage: 'Create a new idea',
		description: 'Button label for creating new idea from ideas bucket',
	},
	placeholder: {
		id: 'polaris-ideas.right-sidebar.ideas-bucket.footer.placeholder',
		defaultMessage: 'Add a quick summary of your idea and press enter',
		description: 'Placeholder message visible when adding an idea',
	},
});
