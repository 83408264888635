import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.heading',
		defaultMessage: 'Add global field',
		description: 'Heading of the right sidebar for global fields panel',
	},
	addToProject: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.add-to-project',
		defaultMessage: 'Add',
		description: 'Label of Add global fields button',
	},
	cancel: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.cancel',
		defaultMessage: 'Cancel',
		description: 'Label of Cancel button',
	},
	associationErrorTitle: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.association-error-title',
		defaultMessage: "We couldn't add fields to the project",
		description: 'Notification title for failed fields adding to the project',
	},
	associationErrorDescription: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.association-error-description',
		defaultMessage: 'Try to add fields to the project again.',
		description: 'Notification description for failed fields adding to the project',
	},
	associationHardLimitErrorTitle: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.association-hard-limit-error-title',
		defaultMessage:
			'We couldn’t add this <item>field</item> because this <item>project</item> has reached its limit for <item>fields</item>.',
		description: 'Notification title for failed projects adding to the global field',
	},
	associationHardLimitErrorDescription: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.association-hard-limit-error-description',
		defaultMessage: 'Try to add fields to the project again.',
		description: 'Notification description for failed fields adding to the project',
	},
	selectFieldTooltip: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.select-field-tooltip',
		defaultMessage: 'Select a field to add',
		description: 'Tooltip of the Add button when no fields are selected',
	},
	errorScreenHeader: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.error-screen-header',
		defaultMessage: "We couldn't load any global field",
		description: 'Header of the error global fields screen',
	},
});
