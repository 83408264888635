import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	rangeVal: {
		id: 'polaris-common.fields-grouped.interval.range-val',
		defaultMessage: '{val}',
		description: 'Label for the count of options selected in a group',
	},
	minVal: {
		id: 'polaris-common.fields-grouped.interval.min-val',
		defaultMessage: 'Earliest: {val}',
		description: 'Label for the earliest date in a group',
	},
	maxVal: {
		id: 'polaris-common.fields-grouped.interval.max-val',
		defaultMessage: 'Latest: {val}',
		description: 'Label for the latest date in a group',
	},
	empty: {
		id: 'polaris-common.fields-grouped.interval.empty',
		defaultMessage: 'Empty',
		description: 'Label for the empty date in a group',
	},
});
