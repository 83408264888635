import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '../../../../../../../controllers/field/selectors/field-hooks';
import { EmojiWrapper } from '../../../../../../field-config/item/emoji-wrapper';
import { IconWrapper } from '../../styled';

type RollupItemFieldIconComponentProps = {
	fieldKey: FieldKey;
};

export const RollupItemFieldIconComponent = ({ fieldKey }: RollupItemFieldIconComponentProps) => {
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	const fieldTypeIcon = useFieldTypeIcon(fieldKey, undefined);

	if (emoji && emojiId !== undefined) {
		return (
			<EmojiWrapper>
				<Emoji emoji={emoji} fitToHeight={16} />
			</EmojiWrapper>
		);
	}

	if (fieldTypeIcon !== null) {
		return <IconWrapper>{fieldTypeIcon}</IconWrapper>;
	}

	return null;
};
