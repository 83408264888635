import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ViewConfigFetcher } from '../../services/jpd-views-service/view-config/types';
import { fetchCollectionViewDescription } from '../../services/jpd-views-service/view-description';
import { fetchViewDescription } from '../../services/polaris-api/fetch-view-description';
import type { ViewDescriptionRemote } from './types';

export const createPolarisApiViewDescriptionRemote = (
	apolloClient: PolarisApolloClient,
): ViewDescriptionRemote => ({
	fetchViewDescription: (viewId) => fetchViewDescription(apolloClient, viewId),
});

export const createSharingViewDescriptionRemote = (
	viewConfigFetcher: ViewConfigFetcher,
): ViewDescriptionRemote => ({
	fetchViewDescription: () => viewConfigFetcher().then(({ description }) => description),
});

export const createCollectionViewDescriptionRemote = (
	collectionUUID: string,
): ViewDescriptionRemote => ({
	fetchViewDescription: (viewId: string) => fetchCollectionViewDescription(collectionUUID, viewId),
});
