import type { FieldIdMap } from '@atlassian/jira-polaris-component-field-id-mapping/src/controllers/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { notImplementedError } from '../../common/utils';
import { updateCollectionViewArrangementInfo } from '../../services/jpd-views-service/update-arrangement-info';
import type { ViewConfigFetcher } from '../../services/jpd-views-service/view-config/types';
import { fetchArrangementInfo } from '../../services/polaris-api/fetch-arrangement-info';
import { updateArrangementInfo } from '../../services/polaris-api/update-arrangement-info';
import type { ViewArrangementInfoRemote } from './types';

export const createPolarisApiViewArrangementInfoRemote = (
	apolloClient: PolarisApolloClient,
	fieldIdMappingPromise: Promise<FieldIdMap>,
): ViewArrangementInfoRemote => ({
	fetchArrangementInfo: (viewId, fields) =>
		fetchArrangementInfo(apolloClient, viewId, fields, fieldIdMappingPromise),
	updateArrangementInfo: (props) => updateArrangementInfo(apolloClient, props),
});

export const createNotImplementedViewArrangementInfoRemote = (): ViewArrangementInfoRemote => ({
	fetchArrangementInfo: notImplementedError('fetchArrangementInfo'),
	updateArrangementInfo: notImplementedError('updateArrangementInfo'),
});

export const createSharingViewArrangementInfoRemote = (
	viewConfigFetcher: ViewConfigFetcher,
): ViewArrangementInfoRemote => ({
	...createNotImplementedViewArrangementInfoRemote(),
	fetchArrangementInfo: () =>
		viewConfigFetcher().then(({ timelineConfig }) =>
			timelineConfig?.arrangementInfo ? JSON.stringify(timelineConfig.arrangementInfo) : undefined,
		),
});

export const createCollectionViewArrangementInfoRemote = (
	collectionUUID: string,
): ViewArrangementInfoRemote => ({
	updateArrangementInfo: ({ viewId, arrangement }) =>
		updateCollectionViewArrangementInfo({
			collectionUUID,
			viewUUID: viewId,
			arrangementInfo: arrangement,
		}),
	fetchArrangementInfo: () => Promise.resolve(''),
});
