import { createSelector } from 'reselect';
import {
	SharedViewFieldsUnavailableError,
	SharedViewAuthenticationMissingError,
} from '@atlassian/jira-polaris-remote-field/src/controllers/utils.tsx';
import type { State } from '../types';

export const isLoading = (state: State) => state.meta.loading;
export const isInitialized = (state: State) => state.meta.initialized;

const getFieldsLoadingError = (state: State) => state.meta.error;

export const isLoadingError = createSelector(getFieldsLoadingError, (err) => err !== undefined);

export const isSharedViewUnavailable = createSelector(
	getFieldsLoadingError,
	(error) => error instanceof SharedViewFieldsUnavailableError,
);

export const isSharedViewAuthenticationMissing = createSelector(
	getFieldsLoadingError,
	(error) => error instanceof SharedViewAuthenticationMissingError,
);
