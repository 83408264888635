import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warning: {
		id: 'polaris-lib-concurrent-updates.update-modal.warning',
		defaultMessage: 'Last updated by {user} • {time}',
		description: 'Warning message for concurrent updates',
	},
	warningLoading: {
		id: 'polaris-lib-concurrent-updates.update-modal.warning-loading',
		defaultMessage: 'Last updated by {user}',
		description: 'Warning message for concurrent updates',
	},
	closeButtonLabel: {
		id: 'polaris-lib-concurrent-updates.update-modal.close-button-label',
		defaultMessage: 'Close',
		description: 'Label for the close button',
	},
});
