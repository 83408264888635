import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cancel: {
		id: 'polaris-common.common.cancel',
		defaultMessage: 'Cancel',
		description: '',
	},
	discard: {
		id: 'polaris-common.common.discard',
		defaultMessage: 'Discard',
		description: 'Button caption to discard changes',
	},
	save: {
		id: 'polaris-common.common.save',
		defaultMessage: 'Save',
		description: 'Button caption to save changes',
	},
	unsavedChanges: {
		id: 'polaris-common.common.unsaved-changes',
		defaultMessage: 'You have some unsaved changes',
		description: 'Help text for unsaved changes scenario',
	},
});
