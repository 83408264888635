import type { ApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { ApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum Role {
	CREATOR = 'CREATOR',
	CONTRIBUTOR = 'CONTRIBUTOR',
	STAKEHOLDER = 'STAKEHOLDER',
}

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum Edition {
	UNKNOWN = 'UNKNOWN',
	BETA_EDITION = 'BETA_EDITION',
	LICENSED = 'LICENSED',
}

export type JpdEdition = Edition.BETA_EDITION | Edition.UNKNOWN | Edition.LICENSED;

export type PendoWidgetSettings = {
	atlassianAccountId: string;
	userFullname: string;
	emailAddress: string;
	isJiraAdmin: boolean;
	isSiteAdmin: boolean;
	appPermissions: ApplicationPermissions | undefined;
	appEditions: ApplicationEditions | undefined;
	jpdEdition: JpdEdition;
	hasProjectPermissions: boolean;
};

export type InlineScriptsProps = {
	embedded?: boolean;
};
