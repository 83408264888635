import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type { WeightType } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DEFAULT_OPTION_WEIGHT } from '../types';
import {
	createGetOptionWeightById,
	createGetOptionCount,
	createGetFieldOptionTypeById,
	createGetOptions,
	createGetOption,
	createAreGlobalLabelsLoading,
	createIsGlobalLabelsLoadingFailed,
} from './options';
import { createHigherLevelFieldHook, createHigherLevelFieldHook2 } from './utils';

export const useFieldOptions = createHigherLevelFieldHook<FieldKey, FieldOption[] | undefined>(
	createGetOptions,
);

export const useFieldOption = createHigherLevelFieldHook2<
	FieldKey,
	string,
	FieldOption | undefined
>(createGetOption);

export const useFieldOptionsCount = createHigherLevelFieldHook<FieldKey, number>(
	createGetOptionCount,
);

export const useFieldOptionWeight = createHigherLevelFieldHook2<
	FieldKey,
	string,
	number | undefined
>(createGetOptionWeightById);

export const useFieldOptionWeightType = createHigherLevelFieldHook<
	FieldKey,
	WeightType | undefined
>(createGetFieldOptionTypeById);

export const useHasDefaultFieldOptionWeights = (fieldKey: FieldKey) => {
	const options = useFieldOptions(fieldKey) || [];
	return !options.some((option) => option.weight !== DEFAULT_OPTION_WEIGHT);
};

export const useAreGlobalLabelsLoading = createHigherLevelFieldHook<FieldKey, boolean>(
	createAreGlobalLabelsLoading,
);

export const useIsGlobalLabelsLoadingFailed = createHigherLevelFieldHook<FieldKey, boolean>(
	createIsGlobalLabelsLoadingFailed,
);
