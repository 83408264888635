import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const linkMergedIssues =
	(isSameProject: boolean): Action<State, Props> =>
	async ({ getState }, { onIssueLinked, projectId, polarisMergeIssueLinkType, issuesRemote }) => {
		const { targetIssue, selectedIssues } = getState();
		if (projectId === null || targetIssue === null) {
			return;
		}
		if (polarisMergeIssueLinkType === undefined) {
			return;
		}
		try {
			await runInBatch(
				selectedIssues.map(
					(selectedIssue) => () =>
						issuesRemote
							.createIssueLink({
								issueLinkTypeId: polarisMergeIssueLinkType,
								outwardIssueKey: selectedIssue.key,
								inwardIssueKey: targetIssue.key,
							})
							.then(() => {
								onIssueLinked && onIssueLinked(selectedIssue.key, targetIssue.key, isSameProject);
							}),
				),
				5,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			log.safeErrorWithoutCustomerData(
				'polaris.merge-ideas-error',
				'Failed to link merged ideas to target idea',
				err,
			);
			throw err;
		}
	};
