import { useReducer } from 'react';
import { TOGGLE_DELETE_MODAL, TOGGLE_EDIT_MODAL, TOGGLE_ADD_MODAL } from './constants';
import type { ControllerState, NotificationsSettingsPageController } from './types';

const initialState: ControllerState = {
	isDeleteModalOpen: false,
	isEditModalOpen: false,
	isAddModalOpen: false,
	selectedConfiguration: undefined,
};

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'action' implicitly has an 'any' type.
const notificationSettingsPageReducer = (state, action) => {
	switch (action.type) {
		case TOGGLE_DELETE_MODAL: {
			const { isOpen, selectedConfiguration } = action.payload;
			return {
				...initialState,
				isDeleteModalOpen: isOpen,
				selectedConfiguration,
			};
		}

		case TOGGLE_EDIT_MODAL: {
			const { isOpen, selectedConfiguration } = action.payload;
			return {
				...initialState,
				isEditModalOpen: isOpen,
				selectedConfiguration,
			};
		}

		case TOGGLE_ADD_MODAL: {
			const { isOpen } = action.payload;
			return {
				...initialState,
				isAddModalOpen: isOpen,
			};
		}

		default:
			return state;
	}
};

export const useNotificationSettingsPageController: NotificationsSettingsPageController = () => {
	const [notificationSettingsPageState, notificationSettingsPageDispatch] = useReducer(
		notificationSettingsPageReducer,
		initialState,
	);

	const setIsDeleteNotificationModalOpen = (
		// @ts-expect-error - TS2304 - Cannot find name 'ToggleDeleteOrEditModal'.
		toggleDeleteModalPayload: ToggleDeleteOrEditModal,
	) => {
		notificationSettingsPageDispatch({
			type: TOGGLE_DELETE_MODAL,
			payload: toggleDeleteModalPayload,
		});
	};

	// @ts-expect-error - TS2304 - Cannot find name 'ToggleDeleteOrEditModal'.
	const setIsEditNotificationModalOpen = (toggleEditModalPayload: ToggleDeleteOrEditModal) => {
		notificationSettingsPageDispatch({
			type: TOGGLE_EDIT_MODAL,
			payload: toggleEditModalPayload,
		});
	};

	const setIsAddNotificationModalOpen = (isOpen: boolean) => {
		notificationSettingsPageDispatch({
			type: TOGGLE_ADD_MODAL,
			payload: { isOpen },
		});
	};

	return [
		notificationSettingsPageState,
		{
			setIsDeleteNotificationModalOpen,
			setIsEditNotificationModalOpen,
			setIsAddNotificationModalOpen,
		},
	];
};
