import React, { useState, useCallback } from 'react';
import { styled, css } from '@compiled/react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Emoji } from '@atlaskit/emoji/element';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsCollectionView,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	useFieldEmoji,
	useFieldType,
	useFieldLabel,
	useIsDeletable,
	useIsGlobalCustomField,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { DeleteFieldModal } from '@atlassian/jira-polaris-common/src/ui/config/fields/delete-field';
import { DeliveryDataRestrictedMarker } from '@atlassian/jira-polaris-common/src/ui/field-info-marker/delivery-data-restricted';
import { FieldTooltip } from '@atlassian/jira-polaris-common/src/ui/field-tooltip';
import {
	useCanEditFields,
	useCanPinIssueViewFields,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { isSystemFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import fieldMessages from '../messages';
import messages from './messages';

type Props = {
	isPinned: boolean;
	fieldKey: FieldKey;
	onManageField: (arg1: FieldKey) => void;
	onPinField: () => void;
};

export const FieldDropdownMenu = ({ fieldKey, onManageField, isPinned, onPinField }: Props) => {
	const { formatMessage } = useIntl();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const canEditFields = useCanEditFields();
	const canPinIssueViewFields = useCanPinIssueViewFields();
	const label = useFieldLabel(fieldKey);
	const isDeletable = useIsDeletable(fieldKey);
	const fieldType = useFieldType(fieldKey);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	const [fieldKeyForDelete, setFieldKeyForDelete] = useState<FieldKey | undefined>();
	const isSharedView = useIsSharedView();
	const isGlobalField = useIsGlobalCustomField(fieldKey);
	const isCollectionView = useIsCollectionView();

	const manage = useCallback(() => {
		onManageField(fieldKey);
	}, [fieldKey, onManageField]);

	const onCloseModal = useCallback(() => {
		setFieldKeyForDelete(undefined);
	}, [setFieldKeyForDelete]);

	const onOpenModal = useCallback(() => {
		setFieldKeyForDelete(fieldKey);
	}, [setFieldKeyForDelete, fieldKey]);

	const getPinButtonTooltipContent = () => {
		if (canPinIssueViewFields) {
			return undefined;
		}

		return isPinned
			? formatMessage(messages.noUnpinPermissions)
			: formatMessage(messages.noPinPermissions);
	};

	const getDeleteButtonTooltip = () => {
		if (!canEditFields) {
			return formatMessage(messages.noDeletePersmissions);
		}

		if (isSystemFieldType(fieldType)) {
			return formatMessage(messages.systemField);
		}

		return undefined;
	};

	if (isSharedView) {
		return (
			<FieldTitle>
				{emoji && (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</EmojiWrapper>
				)}
				{label}
			</FieldTitle>
		);
	}

	return (
		<>
			{fieldKeyForDelete !== undefined && (
				<DeleteFieldModal onClose={onCloseModal} fieldKey={fieldKeyForDelete} />
			)}
			<DeliveryDataRestrictedMarker fieldKey={fieldKey} marginRight={6} />
			<FieldTooltip
				fieldKey={fieldKey}
				strategy="hover"
				position="bottom-start"
				disabled={isDropdownOpen}
			>
				<DropdownMenu
					onOpenChange={({ isOpen }) => {
						setIsDropdownOpen(isOpen);
					}}
					trigger={({ triggerRef, ...props }) => (
						<FieldTitleButton {...props} ref={triggerRef}>
							{emoji && (
								<EmojiWrapper>
									<Emoji emoji={emoji} fitToHeight={16} showTooltip />
								</EmojiWrapper>
							)}
							{label}
						</FieldTitleButton>
					)}
				>
					<DropdownItemGroup>
						<DropdownItem
							testId="polaris-ideas.ui.idea-view.fields.field.menu.field-settings-button"
							onClick={manage}
						>
							{!canEditFields
								? formatMessage(messages.fieldSettings)
								: formatMessage(messages.editField)}
						</DropdownItem>
						{!isCollectionView && (
							<>
								<Tooltip truncate content={getPinButtonTooltipContent()}>
									<DropdownItem
										testId="polaris-ideas.ui.idea-view.fields.field.menu.pin-button"
										isDisabled={!canPinIssueViewFields}
										onClick={onPinField}
									>
										{formatMessage(isPinned ? fieldMessages.unpinField : fieldMessages.pinField)}
									</DropdownItem>
								</Tooltip>
								<Tooltip truncate content={getDeleteButtonTooltip()}>
									<DropdownItem
										testId="polaris-ideas.ui.idea-view.fields.field.menu.delete-button"
										isDisabled={!isDeletable || !canEditFields}
										onClick={onOpenModal}
									>
										{formatMessage(isGlobalField ? messages.removeField : messages.deleteField)}
									</DropdownItem>
								</Tooltip>
							</>
						)}
					</DropdownItemGroup>
				</DropdownMenu>
			</FieldTooltip>
		</>
	);
};

const baseFieldTitleStyles = css({
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	display: 'inline-flex',
	justifyContent: 'left',
	border: 'none',
	backgroundColor: 'transparent',
	textAlign: 'left',
	wordBreak: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldTitleButton = styled.button({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...baseFieldTitleStyles,
	paddingTop: token('space.050', '4px'),
	paddingBottom: token('space.050', '4px'),
	cursor: 'pointer',
	'&:hover, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
	},
	'&:focus-within': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldTitle = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...baseFieldTitleStyles,
	padding: `${token('space.050', '4px')} ${token('space.075', '6px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '5px',
});
