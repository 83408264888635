import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	deleteTemplateTitle: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.delete-modal.delete-template-title',
		defaultMessage: 'Delete {title} template',
		description: 'Title for deletion template modal dialog',
	},
	deleteTemplateInfoMessage: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.delete-modal.delete-template-info-message',
		defaultMessage: 'If you delete the {title} template, you won’t be able to retrieve it later.',
		description: 'Info message for deletion template modal dialog',
	},
	deleteButton: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.delete-modal.delete-button',
		defaultMessage: 'Delete',
		description: 'Button message for confirm deletion in modal dialog',
	},
	cancelButton: {
		id: 'polaris-ideas.right-sidebar.idea-templates.common.delete-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button message for cancel deletion in modal dialog',
	},
});
