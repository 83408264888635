import React from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldValueChangeReporter } from '@atlassian/jira-polaris-use-field-value-change-reporter';
import { useExternalReferencePropertyValue } from '../../../controllers/issue/selectors/properties/hooks';
import { ExternalReferencePropertyField as InnerField } from './main';

export type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	onValueChange?: () => void;
};

export const ExternalReferencePropertyField = (props: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const { localIssueId, fieldKey, onValueChange = () => {} } = props;
	const value = useExternalReferencePropertyValue(fieldKey, localIssueId);

	useFieldValueChangeReporter(value, onValueChange);

	return <InnerField value={value} {...props} />;
};
