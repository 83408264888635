import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'polaris-ideas.view-controls.manage-bar.delete-ideas.modal.delete',
		defaultMessage: 'Delete',
		description: 'Button label for deleting a polaris view',
	},
	cancel: {
		id: 'polaris-ideas.view-controls.manage-bar.delete-ideas.modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Button label for cancel the delete-operation',
	},
	deleteHeader: {
		id: 'polaris-ideas.view-controls.manage-bar.delete-ideas.modal.delete-header',
		defaultMessage: 'Delete idea?',
		description: 'Header caption in polaris delete ideas confirmation dialog',
	},
	description: {
		id: 'polaris-ideas.view-controls.manage-bar.delete-ideas.modal.description',
		defaultMessage:
			'You are about to permanently delete {count, plural, one {an idea} other {# ideas}}. Note that this includes comments, attachments and all associated data.',
		description: 'Description in polaris delete view confirmation dialog',
	},
});
