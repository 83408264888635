import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const setNewlyAddedGlobalFields =
	(fieldKeys: string[]): Action<State, Props> =>
	async ({ setState }) => {
		setState({
			newlyAddedGlobalFields: fieldKeys,
		});
	};

export const resetNewlyAddedGlobalFields =
	(): Action<State, Props> =>
	async ({ setState }) => {
		setState({
			newlyAddedGlobalFields: [],
		});
	};
