import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { useCanImportIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	useProjectAvatarUrls,
	useProjectKey,
	useProjectName,
} from '../../../../controllers/project/selectors/project-hooks';
import messages from './messages';

export const IMPORT_ISSUES_URL =
	'/secure/BulkCreateSetupPage!default.jspa?externalSystem=com.atlassian.jira.plugins.jim-plugin%3AbulkCreateCsv';

type Props = {
	onProjectSwitch: () => void;
};

export const ProjectInfo = ({ onProjectSwitch }: Props) => {
	const [projectName] = useProjectName();
	const [projectKey] = useProjectKey();
	const [projectAvatarUrls] = useProjectAvatarUrls();
	const { formatMessage } = useIntl();

	const canImportIssues = useCanImportIssues();

	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();

	const onGlobalIssueCreate = useCallback(() => {
		onProjectSwitch();

		openIssueCreateModal({
			triggerPointKey: POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER,
		});
	}, [onProjectSwitch, openIssueCreateModal]);

	return (
		<ProjectPickerWithActionWrapper>
			<ProjectInfoContainer>
				<ProjectLabel>{formatMessage(messages.projectLabel)}</ProjectLabel>
				<ProjectDetailsWrapper>
					<Avatar appearance="square" size="xsmall" src={projectAvatarUrls?.['16x16']} />
					<div>{`${projectName} (${projectKey})`}</div>
					<Button
						testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.project-info.button"
						appearance="link"
						onClick={onGlobalIssueCreate}
					>
						{formatMessage(messages.switchProjectBtn)}
					</Button>
				</ProjectDetailsWrapper>
			</ProjectInfoContainer>
			{canImportIssues && (
				<Button
					href={IMPORT_ISSUES_URL}
					testId="polaris-common.ui.create-idea-dialog.form-modal-dialog.project-info.import-issues-button"
				>
					{formatMessage(messages.importIssues)}
				</Button>
			)}
		</ProjectPickerWithActionWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectInfoContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '44px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectLabel = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	fontWeight: '600',
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectDetailsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectPickerWithActionWrapper = styled.div({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
});
