import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { GlobalFieldAddOptionsResponse } from '../types';

export const addGlobalFieldOption = async (
	fieldKey: string,
	contextId: number,
	values: string[],
): Promise<Array<{ id: number; value: string }>> => {
	const result = await performPostRequest<GlobalFieldAddOptionsResponse>(
		`/rest/api/3/field/${fieldKey}/context/${contextId}/option`,
		{
			body: JSON.stringify({ options: values.map((value) => ({ value })) }),
		},
	);

	return result.options.map((option) => ({
		id: Number(option.id),
		value: option.value,
	}));
};
