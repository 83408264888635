import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useIssuesStatusesById } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { useAllStatusesById } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks';

type Props = {
	groupIdentity: string | undefined;
};

const StatusLozenge = ({
	categoryName,
	statusName,
}: {
	categoryName: string;
	statusName: string;
}) => {
	switch (categoryName) {
		case 'In Progress':
			return <Lozenge appearance="inprogress">{statusName}</Lozenge>;
		case 'Done':
			return <Lozenge appearance="success">{statusName}</Lozenge>;
		case 'To Do':
		default:
			return <Lozenge>{statusName}</Lozenge>;
	}
};

export const StatusOptionRenderer = ({ groupIdentity }: Props) => {
	const statiById = useAllStatusesById();
	const issuesStatusesById = useIssuesStatusesById();
	const isCollectionView = useIsCollectionView();

	if (groupIdentity === undefined) {
		// no empty option allowed for status
		return null;
	}
	const status = isCollectionView ? issuesStatusesById[groupIdentity] : statiById[groupIdentity];

	if (!status) return null;

	return <StatusLozenge categoryName={status.statusCategory.name} statusName={status.name} />;
};
