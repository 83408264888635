import { NUM_PAGED_ITEMS_TO_LOAD } from '@atlassian/jira-issue-view-common-constants/src/activity-feed';

export const calculateNumberOfCommentsToLoad = (
	totalComments: number,
	loadedComments: number,
	startIndex: number,
) => {
	let numPrevCommentsToLoad = 0;
	const numPagedItemsToLoad = NUM_PAGED_ITEMS_TO_LOAD;
	const endIndex = loadedComments + startIndex;
	const numNextCommentsToLoad = totalComments - endIndex;

	if (startIndex > 0) {
		numPrevCommentsToLoad = startIndex > numPagedItemsToLoad ? numPagedItemsToLoad : startIndex;
	}

	return {
		numNextCommentsToLoad:
			numNextCommentsToLoad > numPagedItemsToLoad ? numPagedItemsToLoad : numNextCommentsToLoad,
		numPrevCommentsToLoad,
	};
};
