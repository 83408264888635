import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.edit-rule',
		defaultMessage: 'Edit rule',
		description: 'Button to edit the current rule.',
	},
	deleteRule: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.delete-rule',
		defaultMessage: 'Delete rule',
		description: 'Button to delete the current rule.',
	},
	clearFormatting: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.clear-formatting',
		defaultMessage: 'Clear formatting',
		description: 'Button to clear formatting for the current rule.',
	},
	dateIsOverdue: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.date-is-overdue',
		defaultMessage: 'This formatting will apply to any ideas that are past the due date.',
		description: 'Label for overdue dates.',
	},
	dateIsWithin: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.date-is-within',
		defaultMessage: 'Days:',
		description: 'Label for dates which are not overdue.',
	},
	notIntegerError: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.not-integer-error',
		defaultMessage: 'The value must be an integer',
		description: 'Error message displayed when the value of field is not integer number',
	},
	negativeNumbersError: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.negative-numbers-error',
		defaultMessage: 'The field value must be greater or equal to 0',
		description: 'Error message displayed when the value of field is negative number',
	},
});
