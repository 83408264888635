import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { isSystemFieldType, FIELD_TYPES } from '../field-types';
import type { FieldKey } from './types';

export const isFieldKey = (fieldKey: unknown): fieldKey is FieldKey => typeof fieldKey === 'string';

export const toFieldKey = (key: string): FieldKey => key;

export const getFieldId = (fieldKey: string): string => {
	const customFieldPrefix = 'customfield_';

	if (!fieldKey || !fieldKey.startsWith(customFieldPrefix)) {
		return '';
	}

	return fieldKey.replace(customFieldPrefix, '');
};

const FIELDS_CANNOT_BE_HIDDEN: Set<FieldType> = new Set([
	FIELD_TYPES.SUMMARY,
	FIELD_TYPES.ISSUE_KEY,
	FIELD_TYPES.CREATED,
]);

/** We only allow hiding jpd system fields, and even then there are a few exceptions. */
export const fieldCanBeHidden = (fieldType?: FieldType) => {
	if (!fieldType || !isSystemFieldType(fieldType)) {
		return false;
	}

	return !FIELDS_CANNOT_BE_HIDDEN.has(fieldType);
};

const FIELDS_CANNOT_BE_MAPPED_TO_GLOBAL: Set<FieldType> = new Set([
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.REACTIONS,
]);

export const canHaveValuesCopiedToGlobalField = (fieldType?: FieldType) =>
	fieldType && !isSystemFieldType(fieldType) && !FIELDS_CANNOT_BE_MAPPED_TO_GLOBAL.has(fieldType);

/** Predicate for filtering global fields to determine which are eligible to copy values to */
export const eligibleCopyTargetFilterPredicate = ({
	from: sourceFieldType,
	to: targetFieldType,
}: {
	from: FieldType | undefined;
	to: FieldType;
}) => {
	if (targetFieldType === sourceFieldType) {
		return true;
	}

	return (
		(sourceFieldType === FIELD_TYPES.MULTI_SELECT &&
			targetFieldType === FIELD_TYPES.JSW_MULTI_SELECT) ||
		(sourceFieldType === FIELD_TYPES.PEOPLE && targetFieldType === FIELD_TYPES.JSW_PEOPLE)
	);
};
