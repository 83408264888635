import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { ModalTransitionUniversal } from '../../common/ui/modal-transition';
import { GenericError } from '../../common/utils/errors/main.tsx';
import {
	isConfigurePropertiesAction,
	validSnippetActions,
	getSnippetPropertyName,
} from '../../common/utils/snippet';
import {
	useConfigurePropertiesDialogState,
	useConfigurePropertiesDialogProps,
	useConfigurePropertiesDialogActions,
} from '../../controllers/configure-properties-dialog';
import { useUnfurlState, InvokeContainer } from '../../controllers/unfurl';
import { UnfurlStatus } from '../../controllers/unfurl/utils';
import { useAuthentication } from '../../controllers/unfurl/utils/authentication';
import { messages } from './messages';

const ConfigurePropertiesDialogContent = () => {
	const { isResolving, error: resolveError, resolvedObject, tryAgain } = useUnfurlState();
	const { requiresAuthentication } = useAuthentication();
	const {
		error: loadError,
		saveError,
		isLoading,
		isSaving,
		snippetData,
		config,
	} = useConfigurePropertiesDialogState();
	const { checkProperty } = useConfigurePropertiesDialogActions();
	const { formatMessage } = useIntl();
	const { closeDialog, saveConfig } = useConfigurePropertiesDialogActions();
	const handleClose = useCallback(() => {
		if (isLoading || isSaving) {
			return;
		}
		closeDialog();
	}, [closeDialog, isLoading, isSaving]);
	const properties = useMemo(() => {
		if (isResolving || !resolvedObject || !resolvedObject.properties) {
			return [];
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return Object.keys(resolvedObject.properties).reduce<Array<any>>((result, key) => {
			if (isResolving || !resolvedObject) {
				return result;
			}
			if (!resolvedObject.properties) {
				return result;
			}
			if (
				typeof resolvedObject.properties[key] === 'object' &&
				!Array.isArray(resolvedObject.properties[key])
			) {
				return [
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...result,
					{
						key,
						name: getSnippetPropertyName(resolvedObject.properties, key),
						isChecked: config && !!config[key],
					},
				];
			}
			return result;
		}, []);
	}, [config, isResolving, resolvedObject]);

	const error = resolveError || loadError || saveError;

	let content = null;
	const groupName = snippetData?.group?.name || null;

	if (error !== null || groupName === null) {
		content = <GenericError error={error || new Error('Group name is not defined')} />;
	} else if (isResolving || isLoading || snippetData === null) {
		content = (
			<CenteredWrapper>
				<Spinner />
			</CenteredWrapper>
		);
	} else {
		content = (
			<>
				{properties.map((property) => (
					<Checkbox
						key={property.key}
						defaultChecked={property.isChecked || false}
						isChecked={property.isChecked || false}
						onChange={(event) => checkProperty(property, event.target.checked)}
						label={property.name}
						name={property.key}
					/>
				))}
			</>
		);
	}

	return (
		<ModalTransitionUniversal>
			{groupName && (
				<ShortcutScope>
					<ModalDialog autoFocus={false} onClose={handleClose}>
						<ModalHeader>
							<ModalTitle>
								{formatMessage(messages.configurePropertiesDialogHeader, {
									name: groupName,
								})}
							</ModalTitle>
						</ModalHeader>
						<ModalBody>
							{(tryAgain && !isResolving) || requiresAuthentication ? (
								<CenteredWrapper>
									<UnfurlStatus />
								</CenteredWrapper>
							) : (
								content
							)}
						</ModalBody>
						<ModalFooter>
							<Button
								onClick={saveConfig}
								appearance="primary"
								isDisabled={
									isLoading ||
									isSaving ||
									isResolving ||
									!!error ||
									requiresAuthentication ||
									tryAgain
								}
							>
								{isSaving ? <Spinner /> : formatMessage(messages.saveAction)}
							</Button>
							<Button onClick={handleClose} isDisabled={isSaving}>
								{formatMessage(messages.cancelAction)}
							</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransitionUniversal>
	);
};

export const ConfigurePropertiesDialog = () => {
	const { snippetData, oauthClientId, url } = useConfigurePropertiesDialogState();
	const { cloudId, projectId, issueId } = useConfigurePropertiesDialogProps();
	const insightsRemote = useInsightRemote();
	const configurePropertiesAction = validSnippetActions(snippetData?.actions).filter(
		isConfigurePropertiesAction,
	)[0];
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const handleObjectResolved = useCallback(() => {}, []);
	return configurePropertiesAction &&
		url !== null &&
		oauthClientId !== null &&
		snippetData !== null ? (
		<InvokeContainer
			insightsRemote={insightsRemote}
			url={url}
			cloudId={cloudId}
			issueId={issueId}
			projectId={projectId}
			action={configurePropertiesAction}
			oauthClientId={oauthClientId}
			snippetData={snippetData}
			onObjectResolved={handleObjectResolved}
		>
			<ConfigurePropertiesDialogContent />
		</InvokeContainer>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenteredWrapper = styled.div({
	margin: '100px auto',
	display: 'flex',
	justifyContent: 'center',
});
