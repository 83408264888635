import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { FetchResponse, ViewConfigFetcher } from './types';

const getConfigUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v0'
	}/views/${viewId}/config`;

type Cache = {
	response: Promise<FetchResponse> | undefined;
};

export const createCachedViewConfigFetcher = (viewId: string): ViewConfigFetcher => {
	const cache: Cache = {
		response: undefined,
	};

	return () => {
		if (cache.response === undefined) {
			cache.response = fetchJson(getConfigUrl(viewId));
		}

		return cache.response;
	};
};
