import { useCallback } from 'react';
import get from 'lodash/get';
import {
	performPostRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { SaveField } from '@atlassian/jira-issue-field-base/src/common/types.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { Vote as VoteValue } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { FIELD_KEY } from '../../common/types';
import messages from '../../messages';
import { useVotersList } from '../voters-list-service/main.tsx';
import type { VoterResponse } from '../voters-list-service/types';
import type { SaveFieldParams } from './types';
import { calculateVote } from './utils';

interface Props {
	onFieldUpdated?: (value: VoteValue) => void;
}

const saveField = ({ issueKey, newValue }: SaveFieldParams) => {
	if (newValue.hasVoted) {
		return performPostRequest(`/rest/api/3/issue/${issueKey}/votes`);
	}

	return performDeleteRequest(`/rest/api/3/issue/${issueKey}/votes`);
};

export const useVoteField = ({ onFieldUpdated }: Props) => {
	const issueKey = useIssueKey();
	const [, { setLoggedInUserActionVote, setLoggedInUserActionUnvote }] = useVotersList();
	const [fieldConfig] = useFieldConfig(issueKey, FIELD_KEY);
	const fieldType = get(fieldConfig.value, 'schema.type', FIELD_KEY);
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onFailure = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7006 - Parameter 'failedValue' implicitly has an 'any' type.
		(_, failedValue) => {
			showFlag({
				type: 'error',
				title: messages.updateVoteFailedTitle,
				description: messages.updateVoteFailedDescription,
			});
			failedValue.hasVoted ? setLoggedInUserActionUnvote() : setLoggedInUserActionVote();
		},
		[showFlag, setLoggedInUserActionUnvote, setLoggedInUserActionVote],
	);

	const saveVoteField: SaveField<VoteValue, VoterResponse> = (
		_issueKey,
		_fieldKey,
		newValue,
		baseUrl,
	) => saveField({ issueKey, newValue, baseUrl: toBaseUrl(baseUrl) });

	const [{ value, error }, { saveValue, resetError }] = useEditField<
		VoteValue,
		null,
		VoterResponse
	>({
		fieldKey: FIELD_KEY,
		issueKey,
		fieldType,
		initialValue: {
			hasVoted: false,
			votes: 0,
		},
		saveField: saveVoteField,
		onFailure,
		onSuccess: onFieldUpdated,
	});

	const toggleValue = useCallback(
		(isDropdownItem = false) => {
			const didToggleOn = !value.hasVoted;
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, didToggleOn ? 'voteAdded' : 'voteRemoved', {
				viewMode: isDropdownItem ? 'showVotersList' : 'hideVotersList',
			});
			// optimistic sync the voters store
			didToggleOn ? setLoggedInUserActionVote() : setLoggedInUserActionUnvote();
			saveValue(calculateVote(didToggleOn, value), null, analyticsEvent);
		},
		[
			value,
			createAnalyticsEvent,
			setLoggedInUserActionVote,
			setLoggedInUserActionUnvote,
			saveValue,
		],
	);

	return [
		{ value, error, fieldConfig },
		{ toggleValue, resetError },
	] as const;
};
