import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	helpText: {
		id: 'polaris-ideas.view-controls.config-filters.help-text',
		defaultMessage: 'Add a filter to control which ideas show in this view.',
		description: 'Help text',
	},
	sharedViewHelpText: {
		id: 'polaris-ideas.view-controls.config-filters.shared-view-help-text',
		defaultMessage:
			'Use filters to find specific ideas in this view. Filters will reset when you reload the view.',
		description: 'Help text for shared view',
	},
	closeButton: {
		id: 'polaris-ideas.view-controls.config-filters.close-button',
		defaultMessage: 'Close',
		description: '',
	},
	excludeIdeas: {
		id: 'polaris-ideas.view-controls.config-filters.exclude-ideas',
		defaultMessage: 'Exclude ideas',
		description: 'Label for the exclude ideas filter button',
	},
	filtersDisabledText: {
		id: 'polaris-ideas.view-controls.config-filters.filters-disabled-text',
		defaultMessage: 'You need the "can edit" permission to make changes to this view.',
		description: "Message displayed when user doesn't have manage view permission",
	},
	justForYouHeading: {
		id: 'polaris-ideas.view-controls.config-filters.just-for-you-heading',
		defaultMessage: 'Just for you',
		description: 'Just for you section header',
	},
	description: {
		id: 'polaris-ideas.view-controls.config-filters.description',
		defaultMessage:
			'Use ephemeral filters to find specific ideas in this view. Filters will reset when you reload the view.',
		description: 'Description of just for you functionality',
	},
	clearButton: {
		id: 'polaris-ideas.view-controls.config-filters.clear-button',
		defaultMessage: 'Clear',
		description: 'Clear filter rule',
	},
});
