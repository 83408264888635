import React from 'react';
import { LinkedProgress } from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type LinkedIssuesProgressFieldProps = {
	issueId: LocalIssueId;
	hideTooltip?: boolean;
};

export const LinkedIssuesProgress = ({ issueId, hideTooltip }: LinkedIssuesProgressFieldProps) => (
	<LinkedProgress issueId={issueId} hideTooltip={hideTooltip} />
);
