import type { Ari } from '@atlassian/jira-platform-ari';
import { makeNewTitle } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/actions/utils.tsx';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import {
	type View,
	type ViewKind,
	ViewLayoutType,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { extractViewAnalyticsData } from '../../selectors/view';
import { type Props, type State, ViewSectionTypeViews } from '../../types';
import { saveView } from '../save';
import { getAutosaveAffectedViewConfigurations } from '../utils/autosave';
import { createNewViewEntity, findViewSet } from '../utils/views';

const viewsetRankList = (viewSet: ViewSet) => {
	const viewRanks = viewSet.views.map((view) => view.rank);
	if (viewSet.viewSets === undefined) {
		return viewRanks;
	}

	return [...viewRanks, ...viewSet.viewSets.map((viewset) => viewset.rank)] as const;
};

const nextRankInViewset = (viewSet: ViewSet) => {
	const rankList = viewsetRankList(viewSet);
	return 1 + rankList.reduce((a, b) => Math.max(a, b), rankList.length);
};

export const createNewView =
	(
		kind: ViewKind,
		parentId: Ari | undefined,
		onSuccess?: () => void,
		onError?: (err?: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const { viewSets } = getState();
		const { router, isCollectionView } = props;

		// currently we can only create views within the PRIORITIZE viewSet - in the future the action should take the viewset id as a parameter
		const prioritizeViewSet = findViewSet(viewSets, (v) => v.type === 'PRIORITIZE');
		const containerViewSet =
			parentId === undefined
				? prioritizeViewSet
				: findViewSet(viewSets, (vs) => vs.id === parentId);

		if (prioritizeViewSet && containerViewSet) {
			const viewSetId = parentId ?? prioritizeViewSet.id;
			const view: View = createNewViewEntity(
				viewSetId,
				kind,
				makeNewTitle(),
				nextRankInViewset(containerViewSet),
				isCollectionView || false,
			);

			if (kind === VIEW_KIND_TIMELINE) {
				view.layoutType = ViewLayoutType.SUMMARY;

				view.timelineConfig = {
					startDateField: undefined,
					dueDateField: undefined,
					mode: PolarisTimelineMode.QUARTERS,
					startTimestamp: undefined,
					endTimestamp: undefined,
					arrangementInformation: {},
					summaryCardField: undefined,
				};
			}

			view.draft = getAutosaveAffectedViewConfigurations(view);

			const updatedViewSet = viewSets.map((v) => {
				if (v.id === prioritizeViewSet.id) {
					return {
						...v,
						views:
							viewSetId === prioritizeViewSet.id
								? [...(prioritizeViewSet.views || []), view]
								: prioritizeViewSet.views,
						viewSets: prioritizeViewSet.viewSets?.map((vSet) => {
							if (viewSetId === vSet.id) {
								return {
									...vSet,
									views: [...(vSet.views || []), view],
								};
							}
							return vSet;
						}),
					};
				}
				return v;
			});
			setState({
				viewSets: updatedViewSet,
			});
			// Save it... and always make the cloned view the current view
			dispatch(
				saveView(view.id, (savedView) => {
					if (savedView !== undefined) {
						if (savedView.saveError) {
							onError?.(savedView.saveError);
						} else {
							onSuccess?.();

							const analyticsData = extractViewAnalyticsData(savedView);
							fireTrackAnalytics(
								props.createAnalyticsEvent({
									containers: analyticsData?.containers,
								}),
								'view created',
								analyticsData?.attributes || {},
							);
							sendPendoTrackEvent({
								actionSubjectAndAction: 'view created',
								attributes: { viewId: savedView.slug || '' },
							});
						}

						router.routeTo({
							section: ViewSectionTypeViews,
							resource: savedView.slug,
						});
					}
				}),
			);
		}
	};
