import React from 'react';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const FormulaDependencyFooterNotice = () => {
	const { formatMessage } = useIntl();

	if (!ff('polaris.global-formulas-dependencies')) {
		return null;
	}

	return (
		<Box xcss={footerTextContainerStyles}>
			<Text size="small">
				{formatMessage(messages.noticeText, {
					b: (text: React.ReactNode) => <b>{text}</b>,
				})}
			</Text>
		</Box>
	);
};

const footerTextContainerStyles = xcss({
	// the sidebar footer has greater padding than we want when the notice is displayed
	marginTop: 'space.negative.100',
	lineHeight: '14px',
});
