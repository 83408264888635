import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ReadAttachmentsToken } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import { transformData as transformMediaContext } from '@atlassian/jira-issue-view-services/src/issue/gira/media-context';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export interface SharingMediaContextResponse {
	readToken?: ReadAttachmentsToken;
}

const getMediaContextUrl = (viewId: string, issueKey: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v1'
	}/views/${viewId}/idea/${issueKey}/${
		ff('polaris.media-context-new-endpoint', false) ? 'media-with-filter' : 'media'
	}`;

export const mediaContextCache = new Map<IssueKey, Promise<SharingMediaContextResponse>>();

export const fetchViewsServiceMediaContextBase = async (
	viewId: string,
	issueKey: IssueKey,
	useCache?: boolean,
): Promise<SharingMediaContextResponse> => {
	if (!ff('polaris.publish.improve-media-loading')) {
		return fetchJson(getMediaContextUrl(viewId, issueKey));
	}

	const cachedPromise = mediaContextCache.get(issueKey);
	if (useCache && cachedPromise) {
		return cachedPromise;
	}

	const fetchPromise = fetchJson(getMediaContextUrl(viewId, issueKey));
	mediaContextCache.set(issueKey, fetchPromise);

	return fetchPromise;
};

export const fetchViewsServiceMediaContext = async (
	viewId: string,
	issueKey: IssueKey,
	useCache?: boolean,
) => {
	const mediaContext = await fetchViewsServiceMediaContextBase(viewId, issueKey, useCache);

	// All files returned in this context have the same issue id, as the context is only generated per issue
	const issueId = mediaContext.readToken?.tokensWithFiles?.[0]?.files?.[0]?.issueId ?? '';

	return {
		mediaContext: transformMediaContext(mediaContext),
		issueId,
	};
};
