import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	clearFilters: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.clear-filters',
		defaultMessage: 'Clear filters',
		description: 'Notification action to allow the user to clear all filters.',
	},
	editFiltersAndValues: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.edit-filters-and-values',
		defaultMessage: 'Edit idea to match filters',
		description:
			'Notification action to allow the user to edit the filters and/or the filtered field values.',
	},
	createSuccess: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.create-success',
		defaultMessage: 'Idea successfully created',
		description:
			'Notification header that an idea has been successfully created, but might not be visible. See notification description message for more detail.',
	},
	createSuccessInvisibleDescription: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.create-success-invisible-description',
		defaultMessage: 'Your idea was created, but filters prevent it from showing in this view',
		description:
			'Notification footer for polaris-ideas.view-content.idea-board.column.footer.create-success',
	},
	moveSuccessSpecific: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.move-success-specific',
		defaultMessage: 'Idea successfully added to {columnLabel}',
		description:
			'Notification header that an idea has been successfully moved to another column, but might not be visible. See notification description message for more detail.',
	},
	moveSuccessGeneric: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.move-success-generic',
		defaultMessage: 'Idea added successfully.',
		description:
			'Same as polaris-ideas.view-content.idea-board.column.footer.move-success-specific but for generic columns (no label available)',
	},
	moveSuccessInvisibleDescription: {
		id: 'polaris-ideas.view-content.common.on-created-filtered-issue.move-success-invisible-description',
		defaultMessage: "But you can't see it here, it's hidden because of filters",
		description:
			'Notifican footer for polaris-ideas.view-content.idea-board.column.footer.move-success-specific and polaris-ideas.view-content.idea-board.column.footer.move-success-generic',
	},
});
