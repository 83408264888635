import React, {
	type ReactElement,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	type ReactNode,
} from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueViewSection } from '@atlassian/jira-polaris-common/src/common/types/issue/index.tsx';
import { markIdeaViewTTI } from '@atlassian/jira-polaris-common/src/common/utils/metrics/idea-view';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useFieldsSidebarConfig } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import {
	useIssueCommentCount,
	useUnseenCommentsAvailableForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/comments/hooks';
import {
	useSelectedIssue,
	useLinkedDeliveryIssuesCount,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	useInsightsCountForIssue,
	useUnseenInsightsAvailableForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/insights/hooks';
import {
	usePolarisRouter,
	useIssueViewLayout,
	useIssueViewSection,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import { useCurrentViewSharingSettings } from '@atlassian/jira-polaris-common/src/controllers/sharing/selectors/hooks';
import { ScrollableContainerContextProvider } from '@atlassian/jira-polaris-common/src/ui/common/scrollable-container/main';
import { useCanAddComments } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { CreateDeliveryTicketSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-delivery-ticket/index.tsx';
import { CreateInsightSpotlight } from '@atlassian/jira-polaris-lib-onboarding/src/ui/create-insight/index.tsx';
import { FireUiAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useInsightsCount } from '../../../controllers/insights/selectors/insights-hooks';
import { InsightsUI, InsightsActionsWrapper } from '../../insights/insights/index.tsx';
import { IdeaCommentStream } from '../comments';
import { Fields } from '../fields';
import { History } from '../history';
import { Deliver } from '../sections/deliver';
import { messages } from './messages';
import { OverviewTab } from './overview';

type TabData = {
	name: IssueViewSection;
	label: string;
	content: ReactNode;
	count: number;
	hasUnseen: boolean;
	testId?: string;
};

const useTabs: (
	isSharedView: boolean,
	issueId: IssueId,
	issueKey: IssueKey,
	localIssueId: LocalIssueId,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	scrollableRef: any,
	sharingOptions?: SharingSettings,
) => TabData[] = (isSharedView, issueId, issueKey, localIssueId, scrollableRef, sharingOptions) => {
	const { formatMessage } = useIntl();
	const isCollectionView = useIsCollectionView();
	const commentCount = useIssueCommentCount(localIssueId);
	// disabling for collection view for now: https://pi-dev-sandbox.atlassian.net/browse/POL-10557?focusedCommentId=729350
	const hasUnseenComments = useUnseenCommentsAvailableForIssue(localIssueId) && !isCollectionView;
	const [canAddComments] = useCanAddComments();
	// used on project view
	const insightsCountForIssue = useInsightsCountForIssue(localIssueId);
	// disabling for collection view for now: https://pi-dev-sandbox.atlassian.net/browse/POL-10863?focusedCommentId=729349
	const hasUnseenInsights = useUnseenInsightsAvailableForIssue(localIssueId) && !isCollectionView;
	const deliveryIssuesCount = useLinkedDeliveryIssuesCount(localIssueId);
	// used on collection view
	const insightsCount = useInsightsCount() || insightsCountForIssue;

	const { mode, fieldKey } = useFieldsSidebarConfig();
	const fieldType = useFieldType(fieldKey);
	const isDeliveryFieldsSidebarOpen =
		mode === 'FIELD' &&
		(fieldType === FIELD_TYPES.DELIVERY_STATUS || fieldType === FIELD_TYPES.DELIVERY_PROGRESS);

	const overviewContentElement = useMemo(
		() => (
			<>
				<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaOverview" />
				<OverviewTab isSharedView={isSharedView} />
			</>
		),
		[isSharedView],
	);

	const commentsContentElement = useMemo(
		() => (
			<TabContent>
				<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaComments" />
				<IdeaCommentStream scrollableRef={scrollableRef} />
			</TabContent>
		),
		[scrollableRef],
	);

	const captureContentElement = useMemo(
		() => (
			<TabContent>
				<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaInsights" />
				<InsightsActionsContainer>
					<InsightsActionsWrapper />
				</InsightsActionsContainer>
				<InsightsUI
					isCompact
					scrollableRef={scrollableRef}
					issueId={issueId}
					localIssueId={localIssueId}
				/>
			</TabContent>
		),
		[issueId, localIssueId, scrollableRef],
	);

	const deliverContentElement = useMemo(
		() => (
			<TabContent>
				<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaDelivery" />
				<Deliver />
				{isDeliveryFieldsSidebarOpen && <Fields isCompact />}
			</TabContent>
		),
		[isDeliveryFieldsSidebarOpen],
	);

	const historyContentElement = useMemo(
		() => (
			<TabContent>
				<FireUiAnalytics eventName="tab viewed" actionSubjectId="ideaHistory" />
				<History issueKey={issueKey} />
			</TabContent>
		),
		[issueKey],
	);

	return useMemo(
		() =>
			[
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'overview' as IssueViewSection,
					label: formatMessage(messages.overview),
					count: 0,
					hasUnseen: false,
					content: overviewContentElement,
					testId: 'polaris.idea.src.ui.tab-view.overview',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'comments' as IssueViewSection,
					label: formatMessage(messages.comments),
					count: commentCount,
					hasUnseen: hasUnseenComments,
					content: commentsContentElement,
					testId: 'polaris.idea.src.ui.tab-view.comments',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'capture' as IssueViewSection,
					label: formatMessage(messages.insights),
					count: insightsCount,
					hasUnseen: hasUnseenInsights,
					content: captureContentElement,
					testId: 'polaris.idea.src.ui.tab-view.capture',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'deliver' as IssueViewSection,
					label: formatMessage(messages.delivery),
					count: deliveryIssuesCount,
					hasUnseen: false,
					content: deliverContentElement,
					testId: 'polaris.idea.src.ui.tab-view.deliver',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'history' as IssueViewSection,
					label: formatMessage(messages.history),
					count: 0,
					hasUnseen: false,
					content: historyContentElement,
					testId: 'polaris.idea.src.ui.tab-view.history',
				},
			].filter((tab) => {
				if (isSharedView) {
					if (tab.name === 'overview') {
						return true;
					}
					if (
						tab.name === 'comments' &&
						sharingOptions?.showIdeaComments &&
						(commentCount || canAddComments)
					) {
						return true;
					}
					if (tab.name === 'capture' && sharingOptions?.showIdeaInsights) {
						return true;
					}
					return false;
				}
				return true;
			}),
		[
			formatMessage,
			overviewContentElement,
			commentCount,
			hasUnseenComments,
			commentsContentElement,
			insightsCount,
			hasUnseenInsights,
			captureContentElement,
			deliveryIssuesCount,
			deliverContentElement,
			historyContentElement,
			isSharedView,
			sharingOptions?.showIdeaComments,
			sharingOptions?.showIdeaInsights,
			canAddComments,
		],
	);
};

const TabSpotlightWrapper = ({ name, children }: { name: string; children: ReactElement }) => {
	switch (name) {
		case 'capture':
			return <CreateInsightSpotlight>{children}</CreateInsightSpotlight>;
		case 'deliver':
			return (
				<CreateDeliveryTicketSpotlight placement="bottom-end">
					{children}
				</CreateDeliveryTicketSpotlight>
			);
		default:
			return children;
	}
};

type TabViewProps = {
	isSharedView: boolean;
	issueId: IssueId;
	issueKey: IssueKey;
};

const TabView = memo<TabViewProps>((props: TabViewProps) => {
	const { isSharedView, issueId, issueKey } = props;

	const localIssueId = useSelectedIssue() ?? '';
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { openIssueView } = usePolarisRouter();
	const issueViewSection = useIssueViewSection();
	const issueViewLayout = useIssueViewLayout();
	let selectedTab: IssueViewSection = issueViewSection ?? 'overview';
	const sharingOptions = useCurrentViewSharingSettings();
	const tabsContentContainerRef = useRef<HTMLDivElement | null>(null);
	const tabs = useTabs(
		isSharedView,
		issueId,
		issueKey,
		localIssueId,
		tabsContentContainerRef,
		sharingOptions,
	);
	const [canAddComments] = useCanAddComments();
	const commentsCount = useIssueCommentCount(localIssueId);

	if (isSharedView && issueViewSection === 'comments' && !canAddComments && commentsCount === 0) {
		selectedTab = 'overview';
	}

	const onSelect = useCallback(
		(tabName: IssueViewSection) => {
			fireCompoundAnalyticsEvent.IdeaView.tabClicked(createAnalyticsEvent({}), tabName);
			tabsContentContainerRef.current?.scrollTo(0, 0);
			openIssueView(issueKey, { layout: issueViewLayout, section: tabName });

			if (tabName === 'capture') {
				experience.ideaView.insightsSegmentLoad.abort();
				experience.ideaView.insightsSegmentLoad.start();
			}
		},
		[createAnalyticsEvent, issueKey, issueViewLayout, openIssueView],
	);

	useEffect(() => {
		experience.ideaView.pageSegmentLoad.success({
			metadata: {
				layout: issueViewLayout,
				section: issueViewSection,
			},
		});

		experience.ideaView.directPageSegmentLoad.success({
			metadata: {
				layout: issueViewLayout,
				section: issueViewSection,
			},
		});
		markIdeaViewTTI();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{tabs.length > 1 && (
				<TabList role="tablist">
					{tabs.map(({ name, label, count, hasUnseen }) => (
						<TabSpotlightWrapper key={name} name={name}>
							<TabButton
								role="tab"
								id={`tab-${name}`}
								aria-selected={name === selectedTab}
								aria-controls={`tabpanel-${name}`}
								isSelected={name === selectedTab}
								onClick={() => onSelect(name)}
							>
								{label}
								{count > 0 && (
									<UnseenCounterContainer>
										<Badge appearance={hasUnseen ? 'primary' : 'default'}>{count}</Badge>
									</UnseenCounterContainer>
								)}
							</TabButton>
						</TabSpotlightWrapper>
					))}
				</TabList>
			)}
			<TabsContentContainer ref={tabsContentContainerRef}>
				<ScrollableContainerContextProvider scrollableContainerRef={tabsContentContainerRef}>
					{tabs
						.filter(({ name }) => name === selectedTab)
						.map(({ name, content, testId }) => (
							<div
								key={`tabpanel-${name}`}
								id={`tabpanel-${name}`}
								role="tabpanel"
								tabIndex={0}
								aria-labelledby={`tab-${name}`}
								data-testid={testId}
							>
								{content}
							</div>
						))}
				</ScrollableContainerContextProvider>
			</TabsContentContainer>
		</>
	);
});

export default TabView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabsContentContainer = styled.div({
	overflow: 'auto',
	flex: '1 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabContent = styled.div({
	padding: `0 ${token('space.100', '8px')} ${token('space.400', '32px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabList = styled.div({
	display: 'flex',
	position: 'relative',
	margin: `0 ${token('space.075', '6px')} 10px`,
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabButton = styled(Button)({
	margin: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InsightsActionsContainer = styled.div({
	position: 'sticky',
	top: 0,
	zIndex: 10,
	paddingBottom: token('space.050', '4px'),
	backgroundColor: token('elevation.surface', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UnseenCounterContainer = styled.span({
	marginLeft: token('space.075', '6px'),
});
