import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	ideaTemplates: {
		id: 'polaris-ideas.right-sidebar.idea-templates.idea-templates',
		defaultMessage: 'Description templates',
		description: 'Header title for idea description templates sidebar',
	},
	projectTemplates: {
		id: 'polaris-ideas.right-sidebar.idea-templates.project-templates',
		defaultMessage: 'Your templates',
		description: 'Header title for custom templates section',
	},
	defaultTemplates: {
		id: 'polaris-ideas.right-sidebar.idea-templates.default-templates',
		defaultMessage: 'Templates library',
		description: 'Header title for default templates section',
	},
	manageTemplates: {
		id: 'polaris-ideas.right-sidebar.idea-templates.manage-templates',
		defaultMessage: 'Manage templates',
		description: 'Button message for managing existing templates',
	},
	newTemplate: {
		id: 'polaris-ideas.right-sidebar.idea-templates.new-template',
		defaultMessage: 'Create a template',
		description: 'Button message for creating new template',
	},
	noManagePermissions: {
		id: 'polaris-ideas.right-sidebar.idea-templates.no-manage-permissions',
		defaultMessage: "You don't have permissions to manage templates",
		description: 'Tooltip message if the template management buttons are disabled',
	},
});
