import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { NotificationEmailModalProps } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyNotificationEmailModalComponent = lazy(
	() => import(/* webpackChunkName: "async-notification-custom-email-modal" */ './index'),
);

export const AsyncNotificationEmailModal = (props: NotificationEmailModalProps) => (
	<Placeholder name="notification-custom-email-modal" fallback={null}>
		<LazyNotificationEmailModalComponent {...props} />
	</Placeholder>
);
