import { getAriConfig } from '@atlassian/jira-platform-ari';
import { getCollectionViewLastViewed } from '../../../services/jpd-views-service/get-collection-view-last-viewed';
import { updateCollectionViewLastViewed } from '../../../services/jpd-views-service/update-collection-view-last-viewed';
import { updateViewLastViewed } from '../../../services/jpd-views-service/update-view-last-viewed';
import type { ViewConfigFetcher } from '../../../services/jpd-views-service/view-config/types';
import type { ViewLastViewedRemote } from '../types';

export const createSharingViewLastViewedRemote = (
	fetcher: ViewConfigFetcher,
	atlassianAccountId: string | null,
): ViewLastViewedRemote => ({
	fetchViewLastViewed: (_: string) => {
		if (atlassianAccountId === null) {
			return Promise.resolve({ lastViewed: [] });
		}
		return fetcher().then((response) => ({
			lastViewed: response.lastViewedTimestamp
				? [
						{
							aaid: atlassianAccountId,
							timestamp: new Date(response.lastViewedTimestamp),
							isCurrentUser: true,
						},
					]
				: [],
		}));
	},
	updateViewLastViewed: async ({ viewId }) => {
		const ari = getAriConfig(viewId);
		return updateViewLastViewed(ari.resourceId);
	},
});

export const createCollectionViewLastViewedRemote = (
	collectionUUID: string,
): ViewLastViewedRemote => ({
	fetchViewLastViewed: (viewId: string) =>
		getCollectionViewLastViewed(collectionUUID, viewId).then((res) => ({
			lastViewed: res.map(({ aaid, timestamp }) => ({ aaid, timestamp: new Date(timestamp) })),
		})),
	updateViewLastViewed: ({ viewId, lastViewed }) =>
		updateCollectionViewLastViewed(collectionUUID, viewId, lastViewed),
});
