import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { updateProjectState } from '../utils';

export const renameViewSet =
	(localId: LocalViewId): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationRemote, cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		setState(
			updateProjectState(getState(), projectId, {
				views: [
					...getState().projects[projectId].views.map((view) =>
						view.localId === localId ? { ...view, isRenaming: true } : view,
					),
				],
			}),
		);
	};

export const cancelViewSetRenaming =
	(localId: LocalViewId): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationRemote, cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		setState(
			updateProjectState(getState(), projectId, {
				views: [
					...getState().projects[projectId].views.map((view) =>
						view.localId === localId ? { ...view, isRenaming: false } : view,
					),
				],
			}),
		);
	};
