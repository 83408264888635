import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const LoadingScreen = () => (
	<Inline
		testId="polaris-component-view-access.ui.loading-screen.loading-screen"
		alignInline="center"
		alignBlock="center"
		space="space.100"
		xcss={containerStyles}
	>
		<Spinner size="large" />
	</Inline>
);

const containerStyles = xcss({
	minHeight: '200px',
});
