import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'polaris-lib-inline-delete.delete',
		defaultMessage: 'Delete',
		description: 'Submit deletion',
	},
	deleteWarning: {
		id: 'polaris-lib-inline-delete.delete-warning',
		defaultMessage: "Once you delete it, it's gone for good",
		description: 'Content for inline delete dialog',
	},
	cancel: {
		id: 'polaris-lib-inline-delete.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel inline delete',
	},
});
