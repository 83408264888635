import { useCallback } from 'react';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	useFieldEditable,
	useFieldType,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useCanEditIssues,
	useCanModifyReporter,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { REPORTER_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Value, LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { JPDExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import {
	type CustomError,
	isBadRequest,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';

export type EditableFieldProps = {
	isEditable: boolean;
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	onUpdate: (inputValue: Value) => void;
};

export type FieldUpdater = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
	newValue: Value,
) => void;

export type EditableFieldContainerProps = {
	isEditable: boolean;
	localIssueId: LocalIssueId;
	fieldKey: string;
	onFieldUpdate: FieldUpdater;
};

const useFieldUpdateHandler = () => {
	const { updateFieldValueWithBulk } = useIssueActions();

	return useCallback(
		(
			localIssueId: LocalIssueId,
			fieldKey: FieldKey,
			newValue: Value,
			onSuccess?: () => void,
			onError?: (err?: CustomError) => void,
		) => {
			updateFieldValueWithBulk(localIssueId, fieldKey, newValue, onSuccess, onError);
		},
		[updateFieldValueWithBulk],
	);
};

export const useFieldUpdate = (localIssueId: LocalIssueId, fieldKey: FieldKey) => {
	const onFieldUpdate = useFieldUpdateHandler();
	const fieldType = useFieldType(fieldKey);

	return useCallback(
		(newValue: Value, jpdExperience?: JPDExperience) => {
			const updateFieldExperience = jpdExperience || experience.listView.makeFieldUpdate();

			const onExperienceFailure = (err?: CustomError) => {
				if (!err || !isBadRequest(err) || !isClientFetchError(err)) {
					updateFieldExperience.failure(err);
				}
			};

			updateFieldExperience.start();
			onFieldUpdate(
				localIssueId,
				fieldKey,
				newValue,
				updateFieldExperience.success,
				onExperienceFailure,
			);
			fireCompoundAnalyticsEvent.ListView.fieldValueUpdatedByInlineEdit(fieldType);
		},
		[fieldKey, fieldType, localIssueId, onFieldUpdate],
	);
};

export const useIsEditable = (fieldKey: FieldKey): boolean => {
	const [canEditIssues] = useCanEditIssues();
	const fieldEditable = useFieldEditable(fieldKey);
	const [hasModifyReportPermission] = useCanModifyReporter();

	let isEditable = canEditIssues && fieldEditable;
	if (fieldKey === REPORTER_FIELDKEY) {
		isEditable = hasModifyReportPermission && isEditable;
	}

	return isEditable;
};
