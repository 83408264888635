// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, memo } from 'react';
import Icon, { type CustomGlyphProps } from '@atlaskit/icon';
import { ButtonItem, type CSSFn } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type {
	SnippetProvider,
	SnippetProviderProperty,
} from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import { POLARIS_OAUTH_CLIENT_ID } from '../../../../../../common/types/snippet/constants';
import { messages } from '../messages';
import { MenuInlineDialogContainer, FieldRollupTypeWrapper, IconWrapper } from '../styled';
import { TooltipComponent } from '../tooltip';
import {
	COUNT_ROLLUP,
	PROPERTY_ROLLUP,
	type PropertyRollupField,
	type RollupField,
	type CountRollupField,
} from '../types';
import { getAppName } from '../utils/app-name';

const cssFn: CSSFn = (currentStyles) => ({
	...currentStyles,
	padding: `${token('space.050', '4px')} ${token('space.200', '16px')}`,
	'& [data-item-elem-before]': {
		span: {
			transform: 'scale(1.5)',
		},
	},
});

type PropertyItemProps = {
	snippetProvider: SnippetProvider;
	isFiltered: (x: string) => boolean;
	onPropertySelect: (arg1: RollupField) => void;
};

const datapointGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.9667 14.2686C13.1609 14.4627 13.4242 14.5717 13.6988 14.5717C13.9733 14.5717 14.2367 14.4627 14.4308 14.2686L18.5726 10.1268C18.6715 10.0313 18.7503 9.91706 18.8046 9.79073C18.8589 9.6644 18.8874 9.52853 18.8886 9.39105C18.8898 9.25356 18.8636 9.11722 18.8116 8.98997C18.7595 8.86272 18.6826 8.74711 18.5854 8.64989C18.4882 8.55267 18.3726 8.47579 18.2453 8.42372C18.1181 8.37166 17.9817 8.34546 17.8442 8.34666C17.7067 8.34785 17.5709 8.37642 17.4446 8.43068C17.3182 8.48495 17.204 8.56383 17.1085 8.66272L13.6988 12.0724L11.3245 9.69816C11.1304 9.50404 10.867 9.395 10.5925 9.395C10.3179 9.395 10.0546 9.50404 9.86043 9.69816L5.7187 13.8399C5.53009 14.0352 5.42572 14.2967 5.42808 14.5682C5.43044 14.8397 5.53933 15.0994 5.73131 15.2914C5.92329 15.4834 6.18299 15.5922 6.45447 15.5946C6.72596 15.597 6.98751 15.4926 7.1828 15.304L10.5925 11.8943L12.9667 14.2686Z"
			fill="currentColor"
		/>
		<circle cx="18.1896" cy="8.90176" r="1.81042" fill="currentColor" />
		<circle cx="13.6505" cy="13.2697" r="1.81042" fill="currentColor" />
		<circle cx="10.5582" cy="10.6119" r="1.81042" fill="currentColor" />
		<circle cx="5.81042" cy="15.0983" r="1.81042" fill="currentColor" />
	</svg>
);

export const toPropertyRollupField = (
	property: SnippetProviderProperty,
	appName: string,
	oauthClientId: string,
	avatarUrl: string,
): PropertyRollupField => ({
	kind: PROPERTY_ROLLUP,
	value: {
		...property,
		appName,
		oauthClientId,
		avatarUrl,
	},
});

export const toNumDataPointsRollupField = (
	numDataPointsLabel: string,
	appName: string,
	oauthClientId: string,
	avatarUrl: string,
): CountRollupField => ({
	kind: COUNT_ROLLUP,
	value: {
		id: `${oauthClientId}-num-data-points`,
		label: numDataPointsLabel,
		appName,
		avatarUrl,
		oauthClientId,
	},
});

export const FieldRollupType = ({ kind }: { kind: string }) => {
	const { formatMessage } = useIntl();
	let label;
	switch (kind.toLowerCase()) {
		case 'num_data_points':
			label = formatMessage(messages.sumType);
			break;
		case 'rating':
			label = formatMessage(messages.sumType);
			break;
		case 'number':
			label = formatMessage(messages.valueType);
			break;
		default:
			label = formatMessage(messages.valueType);
			break;
	}
	return <FieldRollupTypeWrapper>{label}</FieldRollupTypeWrapper>;
};

export const PropertyItemIcon = ({
	label,
	appName,
	avatarUrl,
}: {
	label: string;
	appName: string;
	avatarUrl: string;
}) => (
	<IconWrapper>
		{appName !== '' ? (
			<img src={avatarUrl} alt={appName} height="16" width="16" />
		) : (
			<Icon glyph={datapointGlyph} size="small" label={label} />
		)}
	</IconWrapper>
);

const PropertyItem = ({ snippetProvider, onPropertySelect, isFiltered }: PropertyItemProps) => {
	const { formatMessage } = useIntl();
	if (!Array.isArray(snippetProvider.properties)) {
		return null;
	}
	const {
		app: { avatarUrl, oauthClientId, name },
	} = snippetProvider;
	const appName = getAppName(oauthClientId, name);

	const numDataPointsLabel = formatMessage(messages.numInsightsLabel);

	return (
		<MenuInlineDialogContainer>
			{isFiltered(numDataPointsLabel) && (
				<TooltipComponent
					key={`${oauthClientId}-num-data-points`}
					content={
						appName !== ''
							? formatMessage(messages.appsNumInsightsHelpText, { appName })
							: formatMessage(messages.numInsightsHelpText)
					}
					header={
						<>
							<PropertyItemIcon
								appName={appName}
								avatarUrl={avatarUrl}
								label={numDataPointsLabel}
							/>{' '}
							{numDataPointsLabel}
						</>
					}
				>
					<ButtonItem
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
						cssFn={cssFn}
						testId="polaris-common.ui.config.fields.common.rollup-formula-item.data-options.option"
						key={`${oauthClientId}-num-data-points`}
						onClick={() =>
							onPropertySelect(
								toNumDataPointsRollupField(numDataPointsLabel, appName, oauthClientId, avatarUrl),
							)
						}
						iconBefore={
							<PropertyItemIcon
								appName={appName}
								avatarUrl={avatarUrl}
								label={numDataPointsLabel}
							/>
						}
						iconAfter={<FieldRollupType kind="num_data_points" />}
					>
						{numDataPointsLabel}
					</ButtonItem>
				</TooltipComponent>
			)}
			{snippetProvider.properties
				.filter(
					(property) =>
						['rating'].includes(property.kind.toLowerCase()) && isFiltered(property.label),
				)
				.map((property) => (
					<TooltipComponent
						key={property.id}
						header={
							<>
								<PropertyItemIcon appName={appName} avatarUrl={avatarUrl} label={property.label} />{' '}
								{property.label}
							</>
						}
						content={formatMessage(messages.impactTypeHelpText)}
					>
						<ButtonItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							testId="polaris-common.ui.config.fields.common.rollup-formula-item.data-options.option"
							onClick={() =>
								onPropertySelect(toPropertyRollupField(property, appName, oauthClientId, avatarUrl))
							}
							iconBefore={
								<PropertyItemIcon appName={appName} avatarUrl={avatarUrl} label={property.label} />
							}
							iconAfter={<FieldRollupType kind={property.kind} />}
						>
							{property.label}
						</ButtonItem>
					</TooltipComponent>
				))}
			{snippetProvider.properties
				.filter(
					(property) =>
						['number'].includes(property.kind.toLowerCase()) && isFiltered(property.label),
				)
				.map((property) => (
					<TooltipComponent
						key={property.id}
						header={
							<>
								<PropertyItemIcon appName={appName} avatarUrl={avatarUrl} label={property.label} />{' '}
								{property.label}
							</>
						}
						content={formatMessage(messages.insightValueTypeHelpText)}
					>
						<ButtonItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							testId="polaris-common.ui.config.fields.common.rollup-formula-item.data-options.option"
							onClick={() =>
								onPropertySelect(toPropertyRollupField(property, appName, oauthClientId, avatarUrl))
							}
							iconBefore={
								<PropertyItemIcon appName={appName} avatarUrl={avatarUrl} label={property.label} />
							}
							iconAfter={<FieldRollupType kind={property.kind} />}
						>
							{property.label}
						</ButtonItem>
					</TooltipComponent>
				))}
		</MenuInlineDialogContainer>
	);
};

type DataOptionsProps = {
	snippetProviders: SnippetProvider[];
	isFiltered: (x: string) => boolean;
	onClick: (arg1: RollupField) => void;
};

export const DataOptions = memo<DataOptionsProps>(
	({ snippetProviders, onClick, isFiltered }: DataOptionsProps) => {
		if (snippetProviders.length === 0) {
			return <></>;
		}
		const polarisProviderIdx = snippetProviders.findIndex(
			(sp) => sp.app.oauthClientId === POLARIS_OAUTH_CLIENT_ID,
		);
		// show polaris properties first, and then the other apps
		let sortedSnippetProviders = snippetProviders;
		if (polarisProviderIdx > 0) {
			sortedSnippetProviders = [...snippetProviders];
			sortedSnippetProviders.splice(polarisProviderIdx, 1);
			sortedSnippetProviders = [snippetProviders[polarisProviderIdx], ...sortedSnippetProviders];
		}

		return (
			<>
				{sortedSnippetProviders.map((sp) => (
					<PropertyItem
						key={sp.id}
						isFiltered={isFiltered}
						snippetProvider={sp}
						onPropertySelect={(rf) => onClick(rf)}
					/>
				))}
			</>
		);
	},
);
