import React, { type SyntheticEvent, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FormattedDate } from '@atlassian/jira-polaris-common/src/common/ui/date';
import {
	getCopyUrl,
	PermalinkType,
} from '@atlassian/jira-polaris-common/src/common/utils/permalink';
import { CopyLink } from '@atlassian/jira-polaris-common/src/ui/copy-link';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { InlineDelete } from '@atlassian/jira-polaris-lib-inline-delete/src/ui/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { ProfileCard } from '@atlassian/jira-profilecard-next/src/main.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useInsight } from '../../../../../controllers/insights/selectors/insights-hooks';
import { messages } from './messages';

type HeaderProps = {
	isEditing: boolean;
	isOptimistic: boolean;
	canEdit: boolean;
	canDelete: boolean;
	insightId: string;
	insightResourceId: string;
	onEdit: () => void;
	onDelete: () => void;
};

export const Header = ({
	isEditing,
	isOptimistic,
	insightId,
	insightResourceId,
	canEdit,
	canDelete,
	onEdit,
	onDelete,
}: HeaderProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const insight = useInsight(insightId);

	const copyInsightUrl = useMemo(
		() => getCopyUrl(PermalinkType.INSIGHTS, insightResourceId, window.location.search),
		[insightResourceId],
	);

	const onCopyLink = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaView.copyIdeaInsightLinkButtonClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent]);

	const onEditIconClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent.update({ actionSubject: 'icon' }), 'editInsight');
			onEdit();
		},
		[onEdit],
	);

	if (insight === undefined) {
		return null;
	}

	return (
		<Wrapper data-testid="polaris-ideas.ui.insights.insights.insight.header.wrapper">
			<div data-testid="polaris-ideas.ui.insights.insights.insight.header.profilecard">
				{insight.account?.accountId && (
					<ProfileCard
						actions={[VIEW_PROFILE_ACTION]}
						accountId={insight.account.accountId}
						TenantContextSubscriber={TenantContextSubscriber}
					>
						<UserName>{insight.account.name}</UserName>
					</ProfileCard>
				)}
			</div>
			<DateWrapper>
				<FormattedDate date={insight.created} />
			</DateWrapper>
			<Box xcss={marginLeftStyles} />
			{canEdit && !isEditing && !isOptimistic && (
				<ActionIconWrapper>
					<Tooltip content={formatMessage(messages.editTooltip)}>
						<Button
							testId="polaris-ideas.ui.insights.insights.insight.header.edit"
							iconAfter={<EditIcon size="small" label="Edit description" />}
							appearance="subtle-link"
							spacing="compact"
							onClick={onEditIconClick}
						/>
					</Tooltip>
				</ActionIconWrapper>
			)}
			{canDelete && !isEditing && !isOptimistic && (
				<InlineDelete
					title={formatMessage(messages.deleteInsightWarningHeader)}
					onSubmit={onDelete}
				>
					{({ onOpen }) => (
						<ActionIconWrapper>
							<Tooltip content={formatMessage(messages.deleteTooltip)}>
								<Button
									testId="polaris-ideas.ui.insights.insights.insight.header.delete"
									iconAfter={
										<TrashIcon size="small" label={formatMessage(messages.deleteInsight)} />
									}
									appearance="subtle-link"
									spacing="compact"
									onClick={(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
										fireUIAnalytics(
											analyticsEvent.update({ actionSubject: 'icon' }),
											'deleteInsight',
										);
										onOpen();
									}}
								/>
							</Tooltip>
						</ActionIconWrapper>
					)}
				</InlineDelete>
			)}
			{!isEditing && !isOptimistic && (
				<CopyLinkIconWrapper>
					<CopyLink
						size="small"
						appearance="subtle-link"
						onCopy={onCopyLink}
						url={copyInsightUrl}
						label={formatMessage(messages.copyTooltip)}
						position="bottom"
					/>
				</CopyLinkIconWrapper>
			)}
			{isOptimistic ? (
				<Spinner testId="polaris-ideas.ui.insights.insights.insight.header.spinner" size="xsmall" />
			) : null}
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	backgroundColor: 'transparent',
	border: 0,
	borderRadius: '3px',
	width: '100%',
	lineHeight: '16px',
	overflowX: 'hidden',
	marginBottom: token('space.050', '4px'),
	color: 'inherit',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: 'inherit',
	fontStyle: 'normal',
	fontWeight: 'normal',
	outline: 'none',
	minHeight: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UserName = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	fontWeight: 600,
});

const marginLeftStyles = xcss({
	marginLeft: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionIconWrapper = styled.div({
	visibility: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="content-container-q72J"]:hover &': {
		visibility: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CopyLinkIconWrapper = styled.div({
	marginTop: token('space.050', '4px'),
	marginLeft: token('space.025', '2px'),
	visibility: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"[data-component-selector='content-container-q72J']:hover &": {
		visibility: 'visible',
	},
});
