import { useMemo } from 'react';
import { useAmplitudePreset } from './amplitude';
import { useChromePreset } from './chrome';
import { useIntercomPreset } from './intercom';
import { useJsmPreset } from './jsm';
import { usePendoPresets } from './pendo';
import { useSalesforcePreset } from './salesforce';
import { useSlackPreset } from './slack';
import { useTeamsPreset } from './teams';
import type { AppPreset } from './types';
import { useUserTestingPreset } from './user-testing';
import { useVivunPreset } from './vivun';
import { useZendeskPreset } from './zendesk';

export type { AppPreset } from './types';

export const usePresets = (): AppPreset[] => {
	const slack = useSlackPreset();
	const chrome = useChromePreset();
	const teams = useTeamsPreset();
	const zendesk = useZendeskPreset();
	const salesforce = useSalesforcePreset();
	const pendo = usePendoPresets();
	const intercom = useIntercomPreset();
	const vivun = useVivunPreset();
	const amplitude = useAmplitudePreset();
	const userTesting = useUserTestingPreset();
	const jsm = useJsmPreset();

	return useMemo(
		() => [
			chrome,
			slack,
			teams,
			zendesk,
			salesforce,
			...pendo,
			intercom,
			vivun,
			jsm,
			amplitude,
			userTesting,
		],
		[
			chrome,
			slack,
			teams,
			zendesk,
			salesforce,
			pendo,
			intercom,
			vivun,
			jsm,
			amplitude,
			userTesting,
		],
	);
};
