import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editButton: {
		id: 'project-settings-apps-notifications-custom-email-card.edit-button',
		defaultMessage: 'Edit',
		description: 'Label for edit button used to edit the current email address configuration',
	},
	errorDescription: {
		id: 'project-settings-apps-notifications-custom-email-card.error-description',
		defaultMessage:
			"We can't use this email address due to configuration issues. Click Edit to see your options",
		description: 'Label for edit button used to edit the current email address configuration',
	},
});
