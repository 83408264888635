import { fetchJson$ } from '@atlassian/jira-fetch';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types';
import type { ServerWorklog } from '../model';
import { getSortedWorklogsGiraQuery } from './common';

export type FetchWorklogResponse = {
	startAt: number;
	maxResults: number;
	total: number;
	worklogs: ServerWorklog[];
};

const fetchSurroundingSortedWorklogsFromGira = ({
	orderBy,
	baseUrl,
	issueKey,
	worklogAmount,
	worklogId,
}: {
	orderBy: string;
	baseUrl: BaseUrl;
	issueKey: IssueKey;
	worklogAmount: number;
	worklogId: string;
}) =>
	fetchJson$(`${baseUrl}/rest/gira/1/`, {
		method: 'POST',
		body: JSON.stringify({
			query: getSortedWorklogsGiraQuery({
				orderBy,
				worklogAmount,
				issueKey,
				worklogId,
			}),
		}),
	});

export default fetchSurroundingSortedWorklogsFromGira;
