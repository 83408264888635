import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SerializableType = any;

/**
 * Monitors the value of a field and calls the valueChangeCallback when the value changed.
 * /!\ Be mindful of making any changes in this hook, because it triggers pretty heavy upstream
 * workflows like recalculating card heights in the board view. /!\
 *
 * @param values A value of any type, however it MUST be serializable using JSON.stringify
 */
export const useFieldValueChangeReporter = (
	value: SerializableType,
	valueChangeCallback: () => void,
) => {
	const hasChangedSinceInitialValue = useRef(false);
	const serializedValue = JSON.stringify(value);
	const initialValue = useRef(serializedValue);

	useEffect(() => {
		if (hasChangedSinceInitialValue.current || serializedValue !== initialValue.current) {
			valueChangeCallback();
		}
		hasChangedSinceInitialValue.current = true;
	}, [serializedValue, valueChangeCallback]);
};
