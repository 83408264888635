import type { MessageDescriptor } from '@atlassian/jira-intl';

export type FeedbackModalProps = {
	onCancel: () => void;
	defaultCategoryName?: TemplateKey;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSuccess: (_: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onError: (_: any) => void;
	testId?: string;
};

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export const enum FeedbackCategory {
	CustomerFeedback = 'CF',
	GetSupportAtlassianCom = 'GSAC',
}

export type TemplateDescriptor = {
	key: TemplateKey;
	title: MessageDescriptor;
	selectLabel: MessageDescriptor;
	target: FeedbackCategory;
	template: (msg: string) => string;
	sectionMessage?: MessageDescriptor;
};

export type TemplateOptions = {
	feedback: TemplateDescriptor;
	help: TemplateDescriptor;
};

export type TemplateKey = keyof TemplateOptions;
