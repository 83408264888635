import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import log from '@atlassian/jira-common-util-logging/src/log';
import {
	type CommentAction,
	ADD_COMMENT_FORM_CANCEL,
	EDIT_COMMENT_CANCEL,
	SAVE_COMMENT_SUCCESS,
	DELETE_COMMENT_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { deleteDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions';
import { createCommentDraftKey } from '@atlassian/jira-issue-view-store/src/drafts/draft-utils';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants';

const deleteDraftActions = [
	ADD_COMMENT_FORM_CANCEL,
	EDIT_COMMENT_CANCEL,
	SAVE_COMMENT_SUCCESS,
	DELETE_COMMENT_SUCCESS,
];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCommentIdFromPayload = (payload: any) => {
	const commentId = (payload.id || payload.optimisticId).toString();
	if (commentId.indexOf(NEW_COMMENT_ID) !== -1) {
		return NEW_COMMENT_ID;
	}

	return commentId;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendDeleteDraftRequest = (action: any) => {
	if (action.type === ADD_COMMENT_FORM_CANCEL || action.payload.isNewComment) {
		return Observable.of(deleteDraftRequest({ fieldId: createCommentDraftKey(NEW_COMMENT_ID) }));
	}

	const commentId = getCommentIdFromPayload(action.payload);
	const id = createCommentDraftKey(commentId);

	return Observable.of(deleteDraftRequest({ fieldId: id }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	log.safeErrorWithoutCustomerData('issue.comment-delete-draft', error.message, error);
	return Observable.empty<never>();
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<CommentAction>) =>
	action$
		.ofType(...deleteDraftActions)
		.mergeMap((action) => sendDeleteDraftRequest(action))
		.catch((error) => errorHandler(error));
