import { createHook, createHigherLevelHook } from '../main';
import type { Props, State } from '../types';

export const createWorkflowHook = createHook;

export const createHigherLevelWorkflowHook2 = <TArg1, TArg2, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2) => TReturnValue) =>
	createHigherLevelHook<TReturnValue, [TArg1, TArg2]>(selectorCreator);

export const createHigherLevelWorkflowHook3 = <TArg1, TArg2, TArg3, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
		arg3: TArg3,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2, arg3: TArg3) => TReturnValue) =>
	createHigherLevelHook<TReturnValue, [TArg1, TArg2, TArg3]>(selectorCreator);
