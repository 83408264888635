import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	label: {
		id: 'polaris-ideas.view-controls.manage-bar.label',
		defaultMessage: '{count} {count, plural, one {idea} other {ideas}} selected',
		description: 'Plural counter label.',
	},
	closeButtonLabel: {
		id: 'polaris-ideas.view-controls.manage-bar.close-button-label',
		defaultMessage: 'Close',
		description: 'Close button label',
	},
});
