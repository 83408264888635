import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import { getViewUUID } from '../utils';
import type { FetchViewLastViewedResponse } from './type';

export const getCollectionViewLastViewed = (
	collectionUUID: string,
	viewId: string,
): Promise<FetchViewLastViewedResponse> => {
	const viewUUID = getViewUUID(viewId);
	return fetchJson<FetchViewLastViewedResponse>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/last-viewed`,
	);
};
