import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { log } from '@atlassian/jira-common-util-logging';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-analytics';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time';
import { NEWEST_FIRST } from '@atlassian/jira-issue-shared-types';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { TimeTrackingDeltas } from '@atlassian/jira-issue-view-common-types/src/worklog-type';
import { timeStringToSeconds as timeStringToSecondsUtil } from '@atlassian/jira-issue-view-common-utils';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	worklogsSelector,
	timeTrackingConfigurationSelector,
	totalWorklogsSelector,
	sortedWorklogsSelector,
	worklogsStartIndexSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector';
import { hasMediaFileNodes } from '@atlassian/jira-rich-content';
import { toIssueKey } from '@atlassian/jira-shared-types';
import { worklogExperienceDescription } from '../../common';
import deleteWorklog from '../../services/delete-worklog';
import {
	type Action,
	type DeleteWorklogRequestAction,
	type DeleteWorklogSuccessAction,
	type Worklog,
	deleteWorklogSuccess,
	deleteWorklogFailure,
	DELETE_WORKLOG_REQUEST,
	DELETE_WORKLOG_SUCCESS,
	fetchNewerSortedWorklogsRequest,
	fetchOlderSortedWorklogsRequest,
} from '../../state';

export const getTimeTrackingDeltas = (
	state: State,
	deletedWorklog: Worklog,
	timeRemaining: string,
): TimeTrackingDeltas => {
	const config = timeTrackingConfigurationSelector(state);
	const timeStringToSeconds = timeStringToSecondsUtil(config.daysPerWeek, config.hoursPerDay);

	return {
		timeSpentSecondsDelta: -(timeStringToSeconds(deletedWorklog.timeSpent) || 0),
		newRemainingEstimateSeconds: timeStringToSeconds(timeRemaining),
	};
};

// If all the visible worklogs are deleted, and there are more unloaded worklogs, fetch and display them.
// Prefer to fetch newer worklogs if available, otherwise older worklogs if they exist.
export const fetchWorklogsWhenDeletedEpic = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(action$.ofType(DELETE_WORKLOG_SUCCESS) as Observable<DeleteWorklogSuccessAction>).switchMap(
		// @ts-expect-error - Types of property 'type' are incompatible.
		() => {
			const state = store.getState();

			const loadedWorklogs = sortedWorklogsSelector(state).length;
			if (loadedWorklogs > 0) {
				return Observable.empty<never>();
			}

			const startIndex = worklogsStartIndexSelector(state);
			const totalWorklogs = totalWorklogsSelector(state);
			const selectedSortOrder = getSelectedActivitySortOrder(state);
			const hasWorklogsAboveToLoad = startIndex > 0;

			if (hasWorklogsAboveToLoad) {
				return selectedSortOrder === NEWEST_FIRST
					? Observable.of(fetchNewerSortedWorklogsRequest())
					: Observable.of(fetchOlderSortedWorklogsRequest());
			}

			const hasWorklogsBelowToLoad = totalWorklogs > startIndex;
			if (hasWorklogsBelowToLoad) {
				return selectedSortOrder === NEWEST_FIRST
					? Observable.of(fetchOlderSortedWorklogsRequest())
					: Observable.of(fetchNewerSortedWorklogsRequest());
			}
			return Observable.empty<never>();
		},
	);

export const deleteWorklogEpic =
	(attachmentActions: AttachmentServiceActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		(action$.ofType(DELETE_WORKLOG_REQUEST) as Observable<DeleteWorklogRequestAction>) // eslint-disable-line @typescript-eslint/consistent-type-assertions
			.switchMap(
				({
					payload: { worklogId, timeRemaining, isAdjustTimeRemainingChecked },
					meta: { analyticsEvent },
				}) => {
					const state = store.getState();
					const baseUrl = baseUrlSelector(state);
					const issueKey = toIssueKey(issueKeySelector(state));
					const projectType = projectTypeSelector(state);

					const worklog = worklogsSelector(state)[worklogId];
					const bodyAdf = worklog && worklog.comment;
					const shouldRefetchAttachment = bodyAdf !== null && hasMediaFileNodes(bodyAdf);

					const { daysPerWeek, hoursPerDay } = timeTrackingConfigurationSelector(state);
					const parseTime = timeStringToSecondsUtil(daysPerWeek, hoursPerDay);
					// Convert the timeRemaining from time-string to minutes because the
					// backend doesn't support weeks/days/hours with decimal places other than
					// 0.25, 0.5 or 0.75
					// we have to convert the final value to a whole number as passing decimal
					// values in minutes errors.
					const newTimeRemaining = secondsToWholeMinuteTimeString(parseTime(timeRemaining) || 0);

					return Observable.from(
						deleteWorklog(
							baseUrl,
							issueKey,
							worklogId,
							newTimeRemaining,
							isAdjustTimeRemainingChecked,
						),
					)
						.map(() => {
							const worklogs = worklogsSelector(state);
							const deletedWorklog = worklogs[worklogId];
							shouldRefetchAttachment && attachmentActions.refreshAttachments(toIssueKey(issueKey));
							return deleteWorklogSuccess(
								worklogId,
								getTimeTrackingDeltas(state, deletedWorklog, newTimeRemaining),
							);
						})
						.do(() => {
							sendExperienceAnalytics({
								getExperienceDescription: () =>
									worklogExperienceDescription({
										wasSuccessful: true,
										action: 'DELETE',
										analyticsSource: 'deleteWorklogEpic',
										projectType,
									}),
							});
							fireTrackAnalytics(analyticsEvent, {
								action: 'deleted',
								actionSubjectId: worklogId,
								attributes: { timeAdjusted: isAdjustTimeRemainingChecked },
							});
						})
						.catch((error) => {
							sendExperienceAnalytics({
								getExperienceDescription: ({ wasSuccessful }) => {
									if (!wasSuccessful) {
										log.safeErrorWithoutCustomerData(
											'issue.activity.worklog.delete-worklog',
											`Could not delete worklog '${worklogId}'`,
											error,
										);
									}

									return worklogExperienceDescription({
										wasSuccessful,
										action: 'DELETE',
										analyticsSource: 'deleteWorklogEpic',
										projectType,
										errorMessage: wasSuccessful ? undefined : error.message,
									});
								},
								error,
							});
							return Observable.of(deleteWorklogFailure());
						});
				},
			);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (attachmentActions: AttachmentServiceActions) =>
	combineEpics<Action, State>(deleteWorklogEpic(attachmentActions), fetchWorklogsWhenDeletedEpic);
