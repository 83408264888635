// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentType, ReactElement, ComponentProps } from 'react';
import type { ApplicationKey } from '@atlassian/jira-common-constants/src/application-keys';

export type TopBannerRendererProps = {
	isProjectSimplified: boolean;
	applicationKey: ApplicationKey | undefined;
};

export type TopBannerRenderer = (
	applicationKey: ApplicationKey | undefined,
	isProjectSimplified: boolean,
) => null | ReactElement<ComponentProps<ComponentType<TopBannerRendererProps>>>;

export type OwnProps = {
	renderTopBanner?: TopBannerRenderer;
};

export type Props = TopBannerRendererProps & OwnProps;

const Banner = ({ renderTopBanner, applicationKey, isProjectSimplified }: Props) =>
	renderTopBanner ? renderTopBanner(applicationKey, isProjectSimplified) : null;

export default Banner;
