import { useMemo } from 'react';
import {
	useJiraSettingStoreIssueLinkType,
	useJiraSettingStoreNewIssueViewTransition,
	useJiraSettingStoreSubtaskConfiguration,
	useJiraSettingStoreTimeTracking,
	useSettingsActions,
} from './context';

export const useTimeTrackingConfiguration = () => {
	const [timeTrackingConfiguration] = useJiraSettingStoreTimeTracking();

	return [timeTrackingConfiguration] as const;
};

export const useSubtaskConfiguration = () => {
	const [subTaskConfiguration] = useJiraSettingStoreSubtaskConfiguration();

	return [subTaskConfiguration] as const;
};

export const useIssueLinkTypes = () => {
	const [issueLinkTypes] = useJiraSettingStoreIssueLinkType();

	return [issueLinkTypes] as const;
};

export const useNewIssueViewTransitionSetting = () => {
	const [newIssueViewTransitionSetting] = useJiraSettingStoreNewIssueViewTransition();

	return [newIssueViewTransitionSetting] as const;
};

export const useJiraSettingsActions = () => {
	const [, { setJiraSettings }] = useSettingsActions();

	const actions = useMemo(() => ({ setJiraSettings }), [setJiraSettings]);

	return [null, actions] as const;
};
