import React, { useCallback } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { Box } from '@atlaskit/primitives';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getNextgenSettingsWorkflowUrl } from '@atlassian/jira-project-settings-apps-common/src/urls';
import { useRouterActions } from '@atlassian/jira-router';
import {
	useCanEditField,
	useProjectKey,
	useReadonly,
} from '../../../../controllers/selectors/config-hooks';
import messages from './messages';

export const StatusDecorationItem = () => {
	const projectKey = useProjectKey();
	const isReadonly = useReadonly();
	const canEditField = useCanEditField();
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();

	const onClick = useCallback<NonNullable<ButtonProps['onClick']>>(
		(_, analyticsEvent) => {
			if (!projectKey) {
				return;
			}

			fireUIAnalytics(analyticsEvent, 'editWorkflowButton clicked', 'config-fields');

			// Third argument is issueTypeId which is not used for JPD, so it's
			// ok to pass an empty string
			push(getNextgenSettingsWorkflowUrl(PRODUCT_DISCOVERY_PROJECT, projectKey, ''));
		},
		[projectKey, push],
	);

	return (
		<Box>
			<Button appearance="default" isDisabled={isReadonly || !canEditField} onClick={onClick}>
				{formatMessage(messages.editWorkflow)}
			</Button>
		</Box>
	);
};
