import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-ideas.view-controls.config-extra.autosave-view-config.title',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Autosave view configuration',
		description: '',
	},
	description: {
		id: 'polaris-ideas.view-controls.config-extra.autosave-view-config.description',
		defaultMessage: 'Changes to groups, filters, sorting and fields are saved automatically.',
		description: '',
	},
});
