import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export type RightSidebarBodyProps = {
	children: ReactNode;
	isScrollable?: boolean;
};

export const RightSidebarBody = ({ children, isScrollable = true }: RightSidebarBodyProps) => (
	<Box paddingInline="space.300" xcss={[rightSidebarBodyStyles, isScrollable && scrollableStyles]}>
		{children}
	</Box>
);

const rightSidebarBodyStyles = xcss({
	// This ensures the body fills the whole space between header and footer.
	flex: '1 1 auto',
	minHeight: '0',
});

const scrollableStyles = xcss({
	overflowY: 'auto',
});
