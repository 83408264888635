import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { MAX_FIELD_OPTION_NAME_LENGTH } from './constants';
import messages from './messages';
import type { FieldOptionNameValidationResult } from './types';

type Props = {
	entityLimitMessage?: string | null;
	existingOptionNames: string[];
	newValue?: string;
	formatMessage: FormatMessage;
};

export const validateFieldOptionName = ({
	entityLimitMessage,
	existingOptionNames,
	newValue,
	formatMessage,
}: Props): FieldOptionNameValidationResult => {
	if (entityLimitMessage) {
		return {
			isValid: false,
			error: entityLimitMessage,
		};
	}

	if (newValue === undefined || newValue.trim().length === 0) {
		return {
			isValid: false,
			error: formatMessage(messages.valueEmptyError),
		};
	}

	if (existingOptionNames.includes(newValue)) {
		return {
			isValid: false,
			error: formatMessage(messages.valueDuplicateError),
		};
	}

	if (newValue.length > MAX_FIELD_OPTION_NAME_LENGTH) {
		return {
			isValid: false,
			error: formatMessage(messages.valueTooLongError),
		};
	}

	return {
		isValid: true,
	};
};
