import React, { memo, type SyntheticEvent, useCallback, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type Props = {
	issueKey: string;
	issueTypeIconUrl: string;
	issueTypeName: string;
	estimation?: number;
	isUnlinking: boolean;
	canUnlink: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AdditionalComponent: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ChildIssuesComponent?: ComponentType<Record<any, any>>;
	summary: string;
	allowChildren: boolean;
	showChildren?: boolean;
	onRemoveIssueLink?: () => void;
	onToggleChildren?: () => void;
};

export const LinkedDeliveryIssueItem = memo<Props>((props: Props) => {
	const {
		AdditionalComponent,
		ChildIssuesComponent,
		allowChildren,
		isUnlinking,
		issueKey,
		summary,
		issueTypeIconUrl,
		issueTypeName,
		showChildren,
		estimation,
		onRemoveIssueLink,
		onToggleChildren,
		canUnlink,
	} = props;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const issueLink = `/browse/${issueKey}`;

	const handleRemoveIssueLink = useCallback(
		(e: SyntheticEvent) => {
			e.stopPropagation();
			onRemoveIssueLink && onRemoveIssueLink();
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
				'removeDeliveryTicket',
			);
		},
		[onRemoveIssueLink, createAnalyticsEvent],
	);

	const stopPropagation = useCallback((e: SyntheticEvent) => {
		e.stopPropagation();
	}, []);

	return (
		<>
			<ItemContainer
				data-testid="polaris-ideas.common.ui.linked-delivery-issue-item.item-container"
				onClick={onToggleChildren}
				data-component-selector="item-container-74Sg"
			>
				<Expand>
					{allowChildren &&
						showChildren !== undefined &&
						onToggleChildren !== undefined &&
						showChildren && <ChevronDownIcon label="expand" size="medium" />}
					{allowChildren &&
						showChildren !== undefined &&
						onToggleChildren !== undefined &&
						!showChildren && <ChevronRightIcon label="expand" size="medium" />}
				</Expand>

				<LinkedDeliveryIssueType title={issueTypeName} src={issueTypeIconUrl} alt={issueTypeName} />
				<IssueKey title={issueKey} href={issueLink} target="_blank" onClick={stopPropagation}>
					{issueKey}
				</IssueKey>

				<Summary title={summary}>{summary}</Summary>

				{estimation !== undefined && (
					<EstimateWrapper>
						<Badge>{estimation}</Badge>
					</EstimateWrapper>
				)}
				<AdditionalComponent />

				{onRemoveIssueLink && canUnlink && !isUnlinking && (
					<RemoveLink
						onClick={handleRemoveIssueLink}
						date-testid="polaris-ideas.common.ui.linked-delivery-issue-item.remove-link"
					>
						<Tooltip content={formatMessage(messages.removeLinkTooltip)}>
							<EditorCloseIcon label="remove link" size="medium" />
						</Tooltip>
					</RemoveLink>
				)}
				{onRemoveIssueLink && isUnlinking && (
					<LoadingWrapper>
						<Spinner
							size="xsmall"
							testId="polaris-ideas.common.ui.linked-delivery-issue-item.spinner"
						/>
					</LoadingWrapper>
				)}
				{!canUnlink && !isUnlinking && <RemovePlaceholder />}
			</ItemContainer>
			{allowChildren && showChildren === true && ChildIssuesComponent !== undefined && (
				<ChildIssuesComponent />
			)}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '40px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border', colors.N40A)}`,
	transition: '0.3s',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border', colors.N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Expand = styled.div({
	height: '24px',
	width: '24px',
	marginRight: token('space.100', '8px'),
	cursor: 'pointer',
	borderRadius: '3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueKey = styled.a({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N100),
	width: '80px',
	flexShrink: 0,
	flexGrow: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	display: 'block',
	marginRight: token('space.050', '4px'),
	'&:hover, &:active, &:visited, &:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N100),
		boxShadow: 0,
		border: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	flexGrow: 1,
	flexShrink: 1000,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LinkedDeliveryIssueType = styled.img({
	width: '16px',
	height: '16px',
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoveLink = styled.div({
	visibility: 'hidden',
	marginRight: token('space.100', '8px'),
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="item-container-74Sg"]:hover &': {
		visibility: 'visible',
	},
	width: '24px',
	lineHeight: 1,
	marginTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemovePlaceholder = styled.div({
	width: '31px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '24px',
	marginRight: token('space.100', '8px'),
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EstimateWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	margin: `0 ${token('space.050', '4px')}`,
	justifyContent: 'center',
});
