import React, {
	useRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
	type PropsWithChildren,
	type ReactNode,
} from 'react';
import noop from 'lodash/noop';
import { useApolloClient } from '@apollo/react-hooks';
import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithFF } from '@atlassian/jira-feature-flagging-utils';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import {
	jpdProjectPageLoadPermissionsStart,
	jpdProjectPageLoadPermissionsEnd,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project';
import {
	useCloudId,
	useCurrentUser,
} from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { AtlasContainer } from '@atlassian/jira-polaris-common/src/controllers/atlas';
import { AvailableProductsContainer } from '@atlassian/jira-polaris-common/src/controllers/available-products/main.tsx';
import { DynamicFieldContainer } from '@atlassian/jira-polaris-common/src/controllers/dynamic-field';
import {
	useIsCollectionView,
	useIsEmbedded,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	FieldContainer,
	useFieldActions,
} from '@atlassian/jira-polaris-common/src/controllers/field/main.tsx';
import { useFieldValueDecorations } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useArchivedFieldsConfig,
	useFieldsArray,
	useAllFieldsArray,
	useFieldsByKey,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useIsInitialized as useIsFieldsInitialized } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/meta-hooks';
import { GlobalFieldsContainer } from '@atlassian/jira-polaris-common/src/controllers/global-fields/main.tsx';
import { useIsSingleIssueLoaded } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import { IssuesContainer } from '@atlassian/jira-polaris-common/src/controllers/issue';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import {
	useIsInitialized as useIsIssuesInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { useAllExternalReferencesPropertiesForProvider } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { LicenceInformationContainer } from '@atlassian/jira-polaris-common/src/controllers/licence-information/main.tsx';
import { MergeIdeasDialogContainer } from '@atlassian/jira-polaris-common/src/controllers/merge-ideas-dialog';
import {
	PRELOAD_PROJECT_PROPERTIES,
	ProjectPropertiesContainer,
	useProjectPropertiesActions,
	useProjectProperty,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties';
import { ProjectProperties } from '@atlassian/jira-polaris-common/src/controllers/project-properties/types';
import {
	useProjectActions,
	ProjectContainer,
} from '@atlassian/jira-polaris-common/src/controllers/project/main';
import { useInsightsByIssueId } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/insights-hooks.tsx';
import { useGetPlayContributionsByIssueId } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/plays-hooks.tsx';
import {
	useIsProjectLoading,
	useIsProjectOnboarded,
	useProjectArjConfiguration,
	useProjectArjHierarchyConfiguration,
	useProjectMetadata,
	useProjectOnboardedAtTimestamp,
	useProjectTemplateVersion,
	useIdeaIssueTypes,
	useIssueLinkType,
	useJiraRankFieldKey,
	useStatusCategories as useStatusCategoriesOld,
} from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import {
	useCurrentPolarisRoute,
	useIsIssueOpenInSidebar,
	usePolarisRouter,
	useSelectedIssueQueryParameter,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import { SharingContainer } from '@atlassian/jira-polaris-common/src/controllers/sharing';
import { useCurrentUserTimezone } from '@atlassian/jira-polaris-common/src/controllers/user';
import { UserPropertiesContainer } from '@atlassian/jira-polaris-common/src/controllers/user-properties';
import { ViewAccessContainer } from '@atlassian/jira-polaris-common/src/controllers/view-access';
import {
	ViewContainer,
	useViewActions,
} from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCurrentViewFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks.tsx';
import {
	useCurrentViewIssueRanking,
	useCurrentViewSelectedIssueIdsAsList,
	useCurrentViewSortMode,
	useCurrentViewUUID,
	useCanManageView,
	useCurrentViewId,
	useRemoveViewIfNoAccess,
	useViewAccessLevel,
	useCurrentViewContainsArchived,
	useCurrentViewSortBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { WorkflowContainer } from '@atlassian/jira-polaris-common/src/controllers/workflow/main.tsx';
import { useTransitionsMap } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/transitions-hooks';
import { isGlobalExperienceWorkspaceForCloudId } from '@atlassian/jira-polaris-common/src/services/atlas/global-experience';
import { useReactionClient } from '@atlassian/jira-polaris-common/src/services/polaris-api/reactions';
import { useMergeNotifications } from '@atlassian/jira-polaris-common/src/ui/merge-ideas-dialog/notifications/index.tsx';
import { EntityLimitsContainer } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/main.tsx';
import {
	useProjectId,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container';
import {
	EnvironmentTenantContainer,
	useArjLicensed,
	useBetaActions,
	useDataPointIssueLinkType,
	useDeliveryIssueLinkType,
	useHiddenPolarisIssueLinkTypes,
	useMergeIssueLinkType,
} from '@atlassian/jira-polaris-component-environment-tenant';
import { useSiteRemote } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/legacy/site-remote/main.tsx';
import {
	useArjConfiguration,
	useStatusCategories as useStatusCategoriesNew,
} from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/selectors/index.tsx';
import { FieldAriProvider } from '@atlassian/jira-polaris-component-field-ari';
import {
	NavigationMetadataContainer,
	useActions as useNavigationMetadataActions,
} from '@atlassian/jira-polaris-component-navigation-metadata-store/src/controllers';
import {
	NavigationContainer,
	useNavigationActions,
} from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/index.tsx';
import { useNavigationViews } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/selectors/views-hooks.tsx';
import {
	PermissionsContainer,
	usePermissionActions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useCanMakeBulkChanges,
	useCanManageViews,
	useCanRankIdeas,
	useHasNoProjectPermissions,
	useIsAdmin,
	useIsLoadedPermissions,
	useIsProjectAdmin,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { getProjectContainerScope } from '@atlassian/jira-polaris-component-permissions-store/src/utils.tsx';
import { ProjectMetadataProvider } from '@atlassian/jira-polaris-component-project-metadata';
import { ViewAccessPermissionStoreInitializer } from '@atlassian/jira-polaris-component-view-access/src/controllers/store-initializer/index.tsx';
import { EXTERNAL_REFERENCE_PROVIDERS } from '@atlassian/jira-polaris-domain-field/src/field/external-reference/types.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { EmojiContainer } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import {
	useOnCyclicFormulaFieldError,
	useErrorHandlers,
} from '@atlassian/jira-polaris-lib-errors/src/controllers';
import { useFieldRemote } from '@atlassian/jira-polaris-remote-field/src/main.tsx';
import { useGlobalFieldRemote } from '@atlassian/jira-polaris-remote-global-field';
import { useInsightRemote } from '@atlassian/jira-polaris-remote-insight/src/controllers/index.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { useProjectConfig } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/main.tsx';
import { usePlayContributionRemote } from '@atlassian/jira-polaris-remote-play-contribution/src/main.tsx';
import { useProjectRemote } from '@atlassian/jira-polaris-remote-project/src/main.tsx';
import { useRankingRemote } from '@atlassian/jira-polaris-remote-ranking/src/controllers/index.tsx';
import { useViewRemote } from '@atlassian/jira-polaris-remote-view/src/main.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { TrackSelectedIdeasVisibility } from '../common/utils/track-selected-ideas-visibility';
import { PolarisIdeaViewDataContainer } from './idea/main.tsx';
import type { MergeIdeasDataContainerProps, PolarisDataContainerProps } from './types';
import { FireRoleAnalytics } from './utils';

const useStatusCategories = functionWithFF(
	'polaris.extract-status-categories',
	useStatusCategoriesNew,
	() => {
		const [statusCategories] = useStatusCategoriesOld();
		return statusCategories;
	},
);

export const FieldDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const { generalActionFailedError, generalDataLoadingFailedError, generalDataUpdateFailedError } =
		useErrorHandlers();
	const onCyclicFormulaDependencyDetected = useOnCyclicFormulaFieldError();

	const projectId = useProjectId();
	const [ideaTypes] = useIdeaIssueTypes();
	const { cloudId, environment } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [isProjectOnboarded] = useIsProjectOnboarded();
	const isSiteAdmin = useIsSiteAdmin();
	const [isProjectAdmin] = useIsProjectAdmin();
	const isAdmin = useIsAdmin();
	const [isGlobalAtlasExperience, setIsGlobalExperience] = useState(false);

	const { deletePlay } = useProjectActions();
	const [, { saveProperty }] = useProjectPropertiesActions();

	const [{ isLoading, value: isAtlasIntegrationEnabled }] = useProjectProperty(
		ProjectProperties.ATLAS_INTEGRATION_ENABLED,
	);

	useEffect(() => {
		isGlobalExperienceWorkspaceForCloudId(cloudId).then((isGlobalExperience) =>
			setIsGlobalExperience(isGlobalExperience),
		);
	}, [cloudId]);

	const fieldRemote = useFieldRemote();
	const globalFieldRemote = useGlobalFieldRemote();

	const isAtlasIntegrationOn = useMemo(() => {
		if (ff('polaris.turn-on-atlas-integration')) {
			if (isAtlasIntegrationEnabled === false) {
				return false;
			}
			return true;
		}
		if (
			isAtlasIntegrationEnabled ||
			(isGlobalAtlasExperience && isAtlasIntegrationEnabled === null && (isProjectAdmin || isAdmin))
		) {
			return true;
		}
		return false;
	}, [isAdmin, isAtlasIntegrationEnabled, isGlobalAtlasExperience, isProjectAdmin]);

	const handleAtlasIntegration = useCallback(() => {
		saveProperty(ProjectProperties.ATLAS_INTEGRATION_ENABLED, true);
	}, [saveProperty]);

	useEffect(() => {
		if (isAtlasIntegrationEnabled || isLoading) {
			return;
		}
		if (isAtlasIntegrationOn && (isProjectAdmin || isAdmin)) {
			handleAtlasIntegration();
		}
	}, [
		isLoading,
		isAtlasIntegrationOn,
		handleAtlasIntegration,
		isAtlasIntegrationEnabled,
		isProjectAdmin,
		isAdmin,
	]);

	return (
		<FieldContainer
			scope="field-singleton"
			containerAri={containerAri}
			fieldRemote={fieldRemote}
			globalFieldRemote={globalFieldRemote}
			hasNoProjectPermissions={hasNoProjectPermissions}
			isContainerInitialized={isProjectOnboarded}
			deletePlay={deletePlay}
			onFieldLoadingFailed={generalDataLoadingFailedError}
			onDecorationUpdateFailed={generalDataUpdateFailedError}
			onFieldUpdateFailed={generalDataUpdateFailedError}
			onActionFailed={generalActionFailedError}
			onCyclicFormulaDependencyDetected={onCyclicFormulaDependencyDetected}
			createAnalyticsEvent={createAnalyticsEvent}
			isAtlasIntegrationEnabled={isAtlasIntegrationOn}
			projectId={projectId}
			ideaTypes={ideaTypes}
			cloudId={cloudId}
			isSiteAdmin={isSiteAdmin}
			environment={environment}
		>
			{children}
		</FieldContainer>
	);
};

export const ViewDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const [fieldsByKey] = useFieldsByKey();
	const { generalActionFailedError, generalDataLoadingFailedError, generalDataUpdateFailedError } =
		useErrorHandlers();
	const currentRoute = useCurrentPolarisRoute();
	const router = usePolarisRouter();
	const currentUser = useCurrentUser();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { tryAddCurrentUserAsSiteCreator } = useBetaActions();

	const cloudId = useCloudId();

	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const canManageViews = useCanManageViews();

	const viewRemote = useViewRemote();
	const rankingRemote = useRankingRemote();

	const projectOnboardedAt = useProjectOnboardedAtTimestamp();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();

	const isCollectionView = useIsCollectionView();

	const { syncViewName, syncViewEmoji } = useNavigationActions();

	return (
		<>
			{ff('polaris.view-permissions_plaoi') && (
				<ViewAccessPermissionStoreInitializer hasManageViewsPermission={canManageViews} />
			)}
			<ViewContainer
				scope="view-singleton"
				containerAri={containerAri}
				currentViewSection={currentRoute?.section}
				currentViewSlug={currentRoute?.resource}
				isCollectionView={isCollectionView}
				fields={fieldsByKey}
				currentUser={currentUser}
				createAnalyticsEvent={createAnalyticsEvent}
				onViewLoadingFailed={generalDataLoadingFailedError}
				onViewUpdateFailed={generalDataUpdateFailedError}
				onActionFailed={generalActionFailedError}
				router={router}
				cloudId={cloudId}
				hasNoProjectPermissions={hasNoProjectPermissions}
				viewRemote={viewRemote}
				rankingRemote={rankingRemote}
				onTryAddCurrentUserAsSiteCreator={tryAddCurrentUserAsSiteCreator}
				projectOnboardedAt={projectOnboardedAt}
				hasManageViewsPermission={canManageViews}
				onDeliveryDataFetchFailed={generalDataLoadingFailedError}
				delayDeliveryDataFetchFailedNotification={!isIssueOpenInSidebar}
				onSyncViewName={getWillShowNav4() ? syncViewName : noop}
				onSyncViewEmoji={getWillShowNav4() ? syncViewEmoji : noop}
			>
				{ff('polaris.view-permissions_plaoi') && <ViewAccessCurrentViewDataLoader />}
				{getWillShowNav4() && <NavigationViewsSynchronizer />}
				{getWillShowNav4() && <NavigationMetadataSynchronizer />}
				{children}
			</ViewContainer>
		</>
	);
};

const NavigationMetadataSynchronizer = () => {
	const { setIsProjectOnboarded } = useNavigationMetadataActions();
	const [isProjectOnboarded] = useIsProjectOnboarded();
	const projectId = useProjectIdUnsafe();

	useEffect(() => {
		isProjectOnboarded && setIsProjectOnboarded(projectId, isProjectOnboarded);
	}, [isProjectOnboarded, projectId, setIsProjectOnboarded]);

	return null;
};

const NavigationViewsSynchronizer = () => {
	const { syncViewsWithNavigation } = useViewActions();
	const projectId = useProjectIdUnsafe();
	const [navigationViews] = useNavigationViews({ projectId });

	useEffect(() => {
		syncViewsWithNavigation(navigationViews);
	}, [navigationViews, syncViewsWithNavigation]);

	return null;
};

const ViewAccessCurrentViewDataLoader = () => {
	const { setHasManageCurrentViewPermission, setCurrentViewAccessLevel } = useViewActions();
	const viewId = useCurrentViewId();
	const prevCanManageCurrentViewRef = useRef<boolean | undefined>(false);
	const canManageCurrentView = useCanManageView(viewId);
	const prevViewAccessLevel = useRef<ViewAccessLevel | undefined>(undefined);
	const viewAccessLevel = useViewAccessLevel(viewId);
	useRemoveViewIfNoAccess(viewId);

	if (viewAccessLevel !== prevViewAccessLevel.current) {
		prevViewAccessLevel.current = viewAccessLevel;
		setCurrentViewAccessLevel(viewAccessLevel);
	}

	if (canManageCurrentView !== prevCanManageCurrentViewRef.current) {
		prevCanManageCurrentViewRef.current = canManageCurrentView;
		setHasManageCurrentViewPermission(canManageCurrentView);
	}

	return null;
};

export const WorkflowDataContainer = ({ children }: { children: ReactNode }) => {
	const projectId = useProjectId();
	const [ideaTypes] = useIdeaIssueTypes();
	const [isProjectLoading] = useIsProjectLoading();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError } = useErrorHandlers();

	const statusCategories = useStatusCategories();
	const issuesRemote = useIssueRemote();

	return (
		<WorkflowContainer
			issuesRemote={issuesRemote}
			statusCategories={statusCategories}
			projectId={projectId}
			ideaTypes={ideaTypes}
			createAnalyticsEvent={createAnalyticsEvent}
			onActionFailed={generalActionFailedError}
			isProjectLoading={isProjectLoading}
		>
			{children}
		</WorkflowContainer>
	);
};

export const IssueDataContainer = ({ children }: { children: ReactNode }) => {
	const { locale } = useTenantContext();
	const timezone = useCurrentUserTimezone();
	const projectId = useProjectId();
	const [ideaTypes] = useIdeaIssueTypes();
	const [fields] = useAllFieldsArray();
	const insights = useInsightsByIssueId();
	const plays = useGetPlayContributionsByIssueId();
	const [fieldValueDecorations] = useFieldValueDecorations();
	const [jiraRankFieldKey] = useJiraRankFieldKey();
	const [archivedFieldsConfig] = useArchivedFieldsConfig();
	const sortBy = useCurrentViewSortBy();
	const filter = useCurrentViewFilter();
	const containsArchived = useCurrentViewContainsArchived();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalActionFailedError } = useErrorHandlers();
	const apolloClient = useApolloClient();
	const reactionsClient = useReactionClient();
	const cloudId = useCloudId();
	const { refreshSnippets } = useInsightRemote();
	const [selectedIssue] = useQueryParam('selectedIssue');
	const embedded = useIsEmbedded();
	const currentRoute = useCurrentPolarisRoute();

	const onContainerReady = useCallback(() => refreshSnippets?.(), [refreshSnippets]);
	const currentUser = useCurrentUser();
	const [arjConfigurationOld] = useProjectArjConfiguration();
	const arjConfigurationNew = useArjConfiguration();

	const arjConfiguration = ff('polaris.environment-tenant-component')
		? arjConfigurationNew
		: arjConfigurationOld;

	const [arjHierarchyConfiguration] = useProjectArjHierarchyConfiguration();
	const arjLicensed = useArjLicensed();
	const hiddenIssueLinkTypes = useHiddenPolarisIssueLinkTypes();
	const transitions = useTransitionsMap();
	const selectedIssueIds = useCurrentViewSelectedIssueIdsAsList();
	const sortMode = useCurrentViewSortMode();
	const externalRanks = useCurrentViewIssueRanking();
	const canRankIdeas = useCanRankIdeas();
	const issuesRemote = useIssueRemote();
	const insightsRemote = useInsightRemote();
	const playContributionRemote = usePlayContributionRemote();
	const [hasBulkChangePermission] = useCanMakeBulkChanges();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const isCollectionView = useIsCollectionView();

	const projectOnboardedAt = useProjectOnboardedAtTimestamp();
	const projectTemplateVersion = useProjectTemplateVersion();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();
	const isSelectedIssueSet = useSelectedIssueQueryParameter() !== undefined;
	const singleIdeaVisible = isSelectedIssueSet && !isIssueOpenInSidebar;
	const { handleDeliveryDataFetchError } = useViewActions();
	const { loadInsights } = useProjectActions();
	const { fetchArchivedSnippetProviders } = useFieldActions();
	const handleLoadArchivedIssues = useCallback(() => {
		loadInsights({
			archivedOnly: true,
		});
		fetchArchivedSnippetProviders();
	}, [loadInsights, fetchArchivedSnippetProviders]);

	return (
		<IssuesContainer
			insightsRemote={insightsRemote}
			issuesRemote={issuesRemote}
			playContributionRemote={playContributionRemote}
			hasBulkChangePermissions={hasBulkChangePermission}
			hasNoProjectPermissions={hasNoProjectPermissions}
			arjLicensed={arjLicensed}
			arjConfiguration={arjConfiguration}
			arjHierarchyConfiguration={arjHierarchyConfiguration}
			currentUser={currentUser}
			locale={locale}
			timezone={timezone}
			projectId={projectId}
			ideaTypes={ideaTypes}
			fields={fields}
			insights={insights}
			plays={plays}
			rankField={jiraRankFieldKey}
			archivedFieldsConfig={archivedFieldsConfig}
			sortMode={sortMode}
			sortBy={sortBy}
			externalIssueRanking={externalRanks}
			filter={filter}
			containsArchived={containsArchived}
			decorations={fieldValueDecorations}
			polarisIssueLinkType={polarisDeliveryIssueLinkType}
			hiddenIssueLinkTypes={hiddenIssueLinkTypes}
			createAnalyticsEvent={createAnalyticsEvent}
			onActionFailed={generalActionFailedError}
			apolloClient={apolloClient}
			reactionsClient={reactionsClient}
			cloudId={cloudId}
			onContainerReady={onContainerReady}
			embedded={embedded}
			onLoadArchivedIssues={handleLoadArchivedIssues}
			currentViewSelectedIssueId={selectedIssueIds}
			selectedIssue={selectedIssue !== undefined ? toIssueKey(selectedIssue) : selectedIssue}
			workflowTransitions={transitions}
			isRankingEnabled={canRankIdeas}
			projectOnboardedAt={projectOnboardedAt}
			projectTemplateVersion={projectTemplateVersion}
			permissionsLoaded={permissionsLoaded}
			singleIdeaVisible={singleIdeaVisible}
			onDeliveryDataFetchFailed={handleDeliveryDataFetchError}
			selectedViewId={currentRoute?.resource}
			isCollectionView={isCollectionView}
		>
			{children}
		</IssuesContainer>
	);
};

export const ProjectDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const [projectConfigFetcher] = useProjectConfig();
	const { generalActionFailedError, generalDataLoadingFailedError } = useErrorHandlers();
	const apolloClient = useApolloClient();
	const cloudId = useCloudId();
	const insightsRemote = useInsightRemote();
	const projectRemote = useProjectRemote();
	const playContributionRemote = usePlayContributionRemote();
	const { isSiteAdmin, isAdmin } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ProjectContainer
			insightsRemote={insightsRemote}
			projectRemote={projectRemote}
			playContributionRemote={playContributionRemote}
			isSiteAdmin={isSiteAdmin || isAdmin}
			scope="project-singleton"
			containerAri={containerAri}
			createAnalyticsEvent={createAnalyticsEvent}
			projectFetcher={projectConfigFetcher}
			onProjectLoadingError={generalDataLoadingFailedError}
			onActionFailed={generalActionFailedError}
			apolloClient={apolloClient}
			cloudId={cloudId}
		>
			{children}
		</ProjectContainer>
	);
};

export const InstanceDataContainer = ({ children }: { children: ReactNode }) => {
	const { generalActionFailedError, generalDataLoadingFailedError } = useErrorHandlers();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const siteRemote = useSiteRemote();
	const [issueLinkType] = useIssueLinkType();

	return (
		<EnvironmentTenantContainer
			polarisIssueLinkType={issueLinkType}
			scope="instance-singleton"
			createAnalyticsEvent={createAnalyticsEvent}
			onLoadingFailed={generalDataLoadingFailedError}
			onActionFailed={generalActionFailedError}
			siteRemote={siteRemote}
		>
			{children}
		</EnvironmentTenantContainer>
	);
};

export const AtlasDataContainer = ({ children }: { children: ReactNode }) => {
	const goalAris = useAllExternalReferencesPropertiesForProvider(
		EXTERNAL_REFERENCE_PROVIDERS.ATLAS_GOAL,
	);
	const projectAris = useAllExternalReferencesPropertiesForProvider(
		EXTERNAL_REFERENCE_PROVIDERS.ATLAS_PROJECT,
	);
	const apolloClient = useApolloClient();
	const { updateExternalReferenceEntities } = useIssueActions();
	const isIssuesLoading = useIsIssuesLoading();
	const isIssuesInitialized = useIsIssuesInitialized();
	const isFieldsInitialized = useIsFieldsInitialized();
	const isSingleIssueLoaded = useIsSingleIssueLoaded();

	return (
		<AtlasContainer
			scope="atlas-singleton"
			goalAris={goalAris}
			projectAris={projectAris}
			apolloClient={apolloClient}
			isSingleIssueLoaded={isSingleIssueLoaded && isFieldsInitialized}
			initialized={isIssuesInitialized && !isIssuesLoading}
			onUpdate={updateExternalReferenceEntities}
		>
			{children}
		</AtlasContainer>
	);
};

const ProjectStateMetadataProvider = () => {
	const metadata = useProjectMetadata();

	return <ProjectMetadataProvider metadata={metadata} />;
};

const EntityLimitsDataContainer = ({ children }: { children: ReactNode }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<EntityLimitsContainer isGlobal createAnalyticsEvent={createAnalyticsEvent}>
			{children}
		</EntityLimitsContainer>
	);
};

export const PolarisRootDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const isCollectionView = useIsCollectionView();
	const isEmbeddedView = useIsEmbedded();
	const isSharedView = useIsSharedView();
	const projectId = useProjectIdUnsafe();
	return (
		<PermissionsContainer
			isCollectionView={isCollectionView}
			isEmbeddedView={isEmbeddedView}
			isSharedView={isSharedView}
			scope={getWillShowNav4() ? getProjectContainerScope(projectId) : 'permissions-singleton'}
			containerAri={containerAri}
			onLoadStart={jpdProjectPageLoadPermissionsStart}
			onLoadEnd={jpdProjectPageLoadPermissionsEnd}
		>
			<FireRoleAnalytics />
			<EntityLimitsDataContainer>
				<ProjectDataContainer containerAri={containerAri}>
					<ProjectStateMetadataProvider />
					<InstanceDataContainer>{children}</InstanceDataContainer>
				</ProjectDataContainer>
			</EntityLimitsDataContainer>
		</PermissionsContainer>
	);
};

export const MergeIdeasDataContainer = ({ children }: MergeIdeasDataContainerProps) => {
	const cloudId = useCloudId();
	const projectId = useProjectIdUnsafe();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const polarisMergeIssueLinkType = useMergeIssueLinkType();
	const polarisDataPointLinkType = useDataPointIssueLinkType();
	const { archiveIssues, execWithIssueAnalyticsData } = useIssueActions();

	const { onSuccess, onFailure } = useMergeNotifications();
	const apolloClient = useApolloClient();
	const { openIssueView } = usePolarisRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const idMap = useLocalIssueIdsByJiraIssueId();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();

	const insightsRemote = useInsightRemote();
	const issuesRemote = useIssueRemote();

	return (
		<MergeIdeasDialogContainer
			insightsRemote={insightsRemote}
			issuesRemote={issuesRemote}
			hasNoProjectPermissions={hasNoProjectPermissions}
			apolloClient={apolloClient}
			cloudId={cloudId}
			projectId={projectId}
			polarisDeliveryIssueLinkType={polarisDeliveryIssueLinkType}
			polarisMergeIssueLinkType={polarisMergeIssueLinkType}
			polarisDataPointIssueLinkType={polarisDataPointLinkType}
			onSuccess={(targetIssue, mergedIssues) => {
				archiveIssues(mergedIssues.map(({ localIssueId }) => localIssueId));
				onSuccess(targetIssue);
				execWithIssueAnalyticsData(idMap[targetIssue.id], (issueAnalyticsData) => {
					fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), targetIssue.id.toString(), {
						...issueAnalyticsData,
						issueId: targetIssue.id.toString(),
						mergedIssueIds: mergedIssues.map((i) => i.id.toString()),
					});
				});
				if (targetIssue.isSameProject === true) {
					openIssueView(targetIssue.key);
				}
			}}
			onFailure={onFailure}
		>
			{children}
		</MergeIdeasDialogContainer>
	);
};

const SharingDataContainer = ({ children }: PropsWithChildren) => {
	const { appEditions } = useTenantContext();
	const projectId = useProjectId();
	const viewUUID = useCurrentViewUUID();
	const viewRemote = useViewRemote();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [fields] = useFieldsArray();

	const { generalActionFailedError, generalDataLoadingFailedError } = useErrorHandlers();

	return (
		<SharingContainer
			fields={fields}
			edition={appEditions.productDiscovery}
			projectId={projectId}
			viewUUID={viewUUID}
			onActionFailed={generalActionFailedError}
			onLoadFailed={generalDataLoadingFailedError}
			viewRemote={viewRemote}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			{children}
		</SharingContainer>
	);
};

const ViewAccessDataContainer = ({ children }: PropsWithChildren) => {
	const viewRemote = useViewRemote();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { generalDataLoadingFailedError, generalDataUpdateFailedError } = useErrorHandlers();

	return (
		<ViewAccessContainer
			onActionFailed={generalDataUpdateFailedError}
			onLoadFailed={generalDataLoadingFailedError}
			viewRemote={viewRemote}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			{children}
		</ViewAccessContainer>
	);
};

const GlobalFieldsDataContainer = ({ children }: PropsWithChildren) => {
	const projectId = useProjectIdUnsafe();
	const globalFieldRemote = useGlobalFieldRemote();

	return (
		<GlobalFieldsContainer globalFieldRemote={globalFieldRemote} projectId={projectId}>
			{children}
		</GlobalFieldsContainer>
	);
};

const EmojiDataContainer = ({ children }: PropsWithChildren) => {
	const { cloudId } = useTenantContext();
	const currentUser = useCurrentUser();
	return (
		<EmojiContainer cloudId={cloudId} currentUser={currentUser}>
			{children}
		</EmojiContainer>
	);
};

const WithNavigationContainer = ({ children }: { children: React.ReactNode }) => {
	const projectId = useProjectIdUnsafe();
	const { cloudId } = useTenantContext();
	if (getWillShowNav4()) {
		return (
			<NavigationMetadataContainer isGlobal cloudId={cloudId} projectId={projectId}>
				<NavigationContainer isGlobal cloudId={cloudId} projectId={projectId}>
					{children}
				</NavigationContainer>
			</NavigationMetadataContainer>
		);
	}
	return <>{children}</>;
};

export const PolarisDataContainer = ({ children, containerAri }: PolarisDataContainerProps) => {
	const { atlassianAccountId, cloudId } = useTenantContext();
	const projectId = useProjectIdUnsafe();
	const [selectedIssue] = useQueryParam('selectedIssue');
	const { setContributorCreateIssuePermission } = usePermissionActions();
	const onIssueCreateFormEnabled = useCallback(
		(enabled: boolean) => setContributorCreateIssuePermission(enabled),
		[setContributorCreateIssuePermission],
	);
	const isCollectionView = useIsCollectionView();

	return (
		<ProjectPropertiesContainer
			projectIdOrKey={projectId}
			preloadProperties={PRELOAD_PROJECT_PROPERTIES}
			onSubmitIdeaFormEnabled={onIssueCreateFormEnabled}
		>
			<WithNavigationContainer>
				<UserPropertiesContainer accountId={atlassianAccountId}>
					<FieldDataContainer containerAri={containerAri}>
						<GlobalFieldsDataContainer>
							<DynamicFieldContainer>
								<FieldAriProvider onlyFieldKeys={isCollectionView} />
								<ViewDataContainer containerAri={containerAri}>
									<WorkflowDataContainer>
										<IssueDataContainer>
											<PolarisIdeaViewDataContainer issueKey={selectedIssue}>
												<TrackSelectedIdeasVisibility />
												<MergeIdeasDataContainer>
													<AvailableProductsContainer cloudId={cloudId}>
														<LicenceInformationContainer>
															<AtlasDataContainer>
																<SharingDataContainer>
																	<ViewAccessDataContainer>
																		<EmojiDataContainer>{children}</EmojiDataContainer>
																	</ViewAccessDataContainer>
																</SharingDataContainer>
															</AtlasDataContainer>
														</LicenceInformationContainer>
													</AvailableProductsContainer>
												</MergeIdeasDataContainer>
											</PolarisIdeaViewDataContainer>
										</IssueDataContainer>
									</WorkflowDataContainer>
								</ViewDataContainer>
							</DynamicFieldContainer>
						</GlobalFieldsDataContainer>
					</FieldDataContainer>
				</UserPropertiesContainer>
			</WithNavigationContainer>
		</ProjectPropertiesContainer>
	);
};
