import React, { useEffect, useState, useCallback, type SyntheticEvent } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ShareButton from '@atlaskit/icon/glyph/share';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import type { AccessLevel } from '@atlassian/jira-polaris-common/src/common/types/access';
import { fetchProjectAccessLevel } from '@atlassian/jira-polaris-common/src/services/jira/project/access';
import {
	useProjectKeyUnsafe,
	useProjectIdUnsafe,
} from '@atlassian/jira-polaris-component-environment-container';
import { useIsProjectAdmin } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import {
	OutsideClickAlerter,
	isFocusOutsideJFE,
} from '@atlassian/jira-polaris-lib-outside-click-alerter';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	MODAL,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import { ShareDialogContent } from './share-dialog-content';

export const ShareDialog = () => {
	const [accessLevel, setAccessLevel] = useState<AccessLevel>();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [isProjectAdmin] = useIsProjectAdmin();
	const { formatMessage } = useIntl();
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();

	useEffect(() => {
		if (dialogOpen) {
			fetchProjectAccessLevel(projectId).then(setAccessLevel);
		}
	}, [dialogOpen, projectId, projectKey, isProjectAdmin]);

	const handleTogglePopup = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		if (!dialogOpen) {
			fireUIAnalytics(analyticsEvent, 'shareModal');
		}
		setDialogOpen(!dialogOpen);
	};

	const handleClickOutside = useCallback(
		(event: Event) => {
			if (isFocusOutsideJFE(event?.target) || !dialogOpen) {
				return;
			}
			setDialogOpen(false);
		},
		[setDialogOpen, dialogOpen],
	);

	const getTriggerComponent = (triggerProps: TriggerProps) => (
		<IconButton
			id="pendo.share-modal.open-button"
			testId="polaris-ideas.ui.view-header.share-dialog.button"
			{...triggerProps}
			label={formatMessage(messages.shareButtonLabel)}
			appearance="subtle"
			isSelected={dialogOpen}
			onClick={handleTogglePopup}
			icon={ShareButton}
			isTooltipDisabled={false}
		/>
	);

	return (
		<OutsideClickAlerter onClickOutside={handleClickOutside}>
			{(outsideClickAlerterProps) => (
				<div {...outsideClickAlerterProps}>
					<Popup
						isOpen={dialogOpen}
						placement="bottom-end"
						content={() => (
							<ContextualAnalyticsData sourceName="share" sourceType={MODAL}>
								<ShareDialogContent accessLevel={accessLevel} />
							</ContextualAnalyticsData>
						)}
						trigger={getTriggerComponent}
					/>
				</div>
			)}
		</OutsideClickAlerter>
	);
};
