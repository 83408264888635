import React from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type ConversionWarningModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const ConversionWarningModal = (props: ConversionWarningModalProps) => {
	const { isOpen, onClose } = props;
	const { formatMessage } = useIntl();

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<Modal onClose={onClose}>
						<ModalHeader>
							<ModalTitle>{formatMessage(messages.title)}</ModalTitle>
						</ModalHeader>
						<ModalBody>{formatMessage(messages.description)}</ModalBody>
						<ModalFooter>
							<Button onClick={onClose} appearance="subtle">
								{formatMessage(messages.closeButton)}
							</Button>
						</ModalFooter>
					</Modal>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};
