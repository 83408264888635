import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'polaris-ideas.view-content.idea-board.column.footer.search-and-create.placeholder',
		defaultMessage: 'Search or create an idea',
		description: 'Placeholder string for dropdown for adding or creating ideas',
	},
	maxLengthError: {
		id: 'polaris-ideas.view-content.idea-board.column.footer.search-and-create.max-length-error',
		defaultMessage: "Summary can't exceed 255 characters.",
		description: 'Error message for creatable select when value is too long',
	},
});
