import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import * as actions from './actions';
import type { State, Props } from './types';

type Actions = typeof actions;

export const initialState = Object.freeze<State>({
	config: {
		isAddingPrincipals: false,
		error: undefined,
		skippedAccountFailures: [],
	},
	accessLevel: VIEW_ACCESS_LEVELS.LIMITED,
	additionalAccess: {
		profiles: [],
		groups: [],
	},
	containerProps: undefined,
});

const ViewAccessStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'ViewAccessStore',
});

const {
	Container: ViewAccessContainer,
	createHook,
	useActions,
} = createJpdContainer<Props, State, Actions>(ViewAccessStore);

export const createViewAccessHook = createHook;
export const useViewAccessActions = useActions;
export { ViewAccessContainer };
