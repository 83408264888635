import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchHint: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.select.content.search-hint',
		defaultMessage: 'Search',
		description: 'Search hint for the search bar in a generic filter dropdown',
	},
	noMatchesIndicator: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.select.content.no-matches-indicator',
		defaultMessage: 'No matches found',
		description: 'Indicator that no options are visible in the dropdown.',
	},
	clearSelectedItems: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.select.content.clear-selected-items',
		defaultMessage: 'Clear selected items',
		description: 'Button caption to clear all items of an individual filter',
	},
});
