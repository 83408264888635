import { useCallback } from 'react';
import { useSharingActions } from '@atlassian/jira-polaris-common/src/controllers/sharing/main.tsx';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	useViewEmojiSpotlightApi,
	emojis,
} from '@atlassian/jira-polaris-lib-control-sharing/src/controllers/view-emoji-spotlight-provider/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const useUpdateSharingSettings = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { updateSharingSettings } = useSharingActions();
	const { addCurrentViewEmoji } = useViewActions();
	const { activateSpotlight } = useViewEmojiSpotlightApi();
	const canManageViews = useCanManageViews();

	return useCallback(
		async (viewUUID: string, newSettings: SharingSettings) => {
			const result = await updateSharingSettings(viewUUID, newSettings);

			if (canManageViews === false) {
				return;
			}

			if (result === undefined) {
				return;
			}

			switch (result.publicSharingStatus) {
				case 'PUBLIC_SHARING_ENABLED': {
					addCurrentViewEmoji(emojis.publicSharingOn);

					const eventName = 'viewPublicIcon added';
					fireTrackAnalytics(createAnalyticsEvent({}), eventName);
					sendPendoTrackEvent({ actionSubjectAndAction: eventName });

					activateSpotlight({ variant: 'publishing' });
					break;
				}
				case 'PUBLIC_SHARING_DISABLED': {
					addCurrentViewEmoji(emojis.publicSharingOff);

					const eventName = 'viewPublicIcon removed';
					fireTrackAnalytics(createAnalyticsEvent({}), eventName);
					sendPendoTrackEvent({ actionSubjectAndAction: eventName });

					activateSpotlight({ variant: 'unpublishing' });
					break;
				}
				case 'PUBLIC_SHARING_UNCHANGED':
				default:
				// Do nothing
			}
		},
		[
			updateSharingSettings,
			canManageViews,
			addCurrentViewEmoji,
			createAnalyticsEvent,
			activateSpotlight,
		],
	);
};
