import { baseScopesRequiredPerField } from '../fields-scopes';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid';
import { isNotEmptyString } from '../utils/is-not-empty-string';

export type RadioButtonsPublicSetValue = null | string;

export type RadioButtonsPublicGetValue = {
	id: string;
	value: string;
} | null;

export const radioButtonsSetValueValidator = (
	value: unknown,
): value is RadioButtonsPublicSetValue => value === null || isNotEmptyString(value);

export const validateRadioButtonsWithAriSetValue = (
	value: unknown,
): value is RadioButtonsPublicSetValue =>
	value === null || (isNotEmptyString(value) && isAriResourceIdValid(value));

export const radioButtonsScopes = baseScopesRequiredPerField;
