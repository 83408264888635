// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	eventType: string,
	eventConstructor = 'Event',
	bubbles = true,
	cancelable = true,
) => {
	const event: Event = document.createEvent(eventConstructor);
	event.initEvent(eventType, bubbles, cancelable);
	return event;
};
