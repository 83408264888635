import React from 'react';
import ArrowUpRight from '@atlaskit/icon/core/migration/arrow-up-right--open';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { useCanManageGlobalFields } from '../../../../controllers';
import messages from './messages';

const GLOBAL_FIELDS_PAGE = '/jira/settings/products/jira-product-discovery/global-fields';

type GlobalFieldPromptProps = {
	fieldType: FieldType;
};

export const GlobalFieldPrompt = ({ fieldType }: GlobalFieldPromptProps) => {
	const { formatMessage } = useIntl();
	const canManageGlobalFields = useCanManageGlobalFields();

	const specifyNewFieldType = fg('polaris_create_global_field_of_specific_type');

	const queryParams = new URLSearchParams();
	queryParams.set('openNewFieldPanel', 'true');
	if (specifyNewFieldType) {
		queryParams.set('fieldType', fieldType);
	}
	const href = `${GLOBAL_FIELDS_PAGE}?${queryParams.toString()}`;

	const actions = canManageGlobalFields
		? [
				<SectionMessageAction key="create-global-field" href={href}>
					<Inline space="space.050">
						<Box>{formatMessage(messages.createAGlobalField)}</Box>
						<Box xcss={linkIconStyles}>
							<ArrowUpRight label="" color={token('color.link')} />
						</Box>
					</Inline>
				</SectionMessageAction>,
			]
		: [];

	return (
		<SectionMessage actions={actions}>
			<Text>
				{canManageGlobalFields
					? formatMessage(messages.noGlobalFieldsOfTypeAdmin)
					: formatMessage(messages.noGlobalFieldsOfType)}
			</Text>
		</SectionMessage>
	);
};

const linkIconStyles = xcss({
	marginTop: 'space.negative.025',
});
