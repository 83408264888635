/** @jsx jsx */
import { keyframes, css, jsx } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenRightSidebarOnIdeasBucket } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks';
import { useIsIdeasBucketOpen } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks';
import { messages } from './messages';

type IdeasBucketButtonProps = {
	isHighlighted?: boolean;
};

export const IdeasBucketButton = ({ isHighlighted = false }: IdeasBucketButtonProps) => {
	const openRightSidebarOnIdeasBucket = useOpenRightSidebarOnIdeasBucket();
	const [isIdeasBucketOpen] = useIsIdeasBucketOpen();
	const { formatMessage } = useIntl();

	if (isIdeasBucketOpen) {
		return null;
	}

	return (
		<div
			css={[
				ideasBucketButtonContainerStyles,
				isHighlighted && ideasBucketButtonIsHighlightedStyles,
			]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
			className="hide-from-export"
		>
			<IconButton
				shape="circle"
				// eslint-disable-next-line @atlaskit/design-system/ensure-icon-color
				icon={(props) => <AddIcon {...props} spacing="spacious" />}
				id="polaris-ideas.ui.ideas-bucket-button"
				label={formatMessage(messages.label)}
				onClick={openRightSidebarOnIdeasBucket}
				appearance="primary"
			/>
		</div>
	);
};

const ideasBucketButtonContainerStyles = css({
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		width: '48px',
		height: '48px',
		boxShadow: token('elevation.shadow.overlay'),
		'&:hover': {
			boxShadow: token('elevation.shadow.overlay'),
			animation: 'none',
			border: 'none',
		},
	},
});

const pulseAnimation = keyframes({
	'0%': {
		boxShadow: '0 0 0 0 rgba(0, 82, 204, 0.7)',
	},
	'70%': {
		boxShadow: '0 0 0 10px rgba(0, 82, 204, 0)',
	},
	'100%': {
		boxShadow: '0 0 0 0 rgba(0, 82, 204, 0)',
	},
});

const ideasBucketButtonIsHighlightedStyles = css({
	animation: `${pulseAnimation} 1.5s infinite`,
	border: `${token('border.width')} solid ${token('color.border.brand')}`,
});
