import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { saveViewWithAutoSave } from '../save';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';
import {
	addFieldToMatrixHiddenViewFields,
	addFieldToMatrixViewFieldsOrUnhide,
	changeFieldForMatrixAxis,
} from './utils';

export { switchMatrixXAndYAxis } from './switch-axes';
export { setFieldValues, setFieldValuesReverseOrder } from './field-options';

const setMatrixAxisInternal = (
	oldView: View,
	allFields: FieldsByKey,
	fieldKey: FieldKey | undefined,
	dimension: string,
): View => {
	const { view, previousField } = changeFieldForMatrixAxis(oldView, dimension, fieldKey, allFields);
	const changedView = addFieldToMatrixViewFieldsOrUnhide(view, fieldKey);
	return addFieldToMatrixHiddenViewFields(changedView, previousField);
};

export const setMatrixXAxis =
	(xAxis?: FieldKey): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, fields, createAnalyticsEvent }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => setMatrixAxisInternal(view, fields, xAxis, 'x'),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'matrixConfig.axes.x' }],
			});
		}
	};

export const setMatrixYAxis =
	(yAxis?: FieldKey): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, fields, createAnalyticsEvent }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => setMatrixAxisInternal(view, fields, yAxis, 'y'),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'matrixConfig.axes.y' }],
			});
		}
	};

export const setMatrixZAxis =
	(zAxis?: FieldKey): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, fields, createAnalyticsEvent }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => setMatrixAxisInternal(view, fields, zAxis, 'z'),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'matrixConfig.axes.z' }],
			});
		}
	};
