import type { MutableRefObject } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { createEpicMiddleware, type Epic } from 'redux-observable';
import noop from 'lodash/noop';
import type { History } from 'history';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types';
import { disposableEpic } from '@atlassian/jira-common-redux-disposable';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service';
import type {
	FieldValueServiceActions,
	FieldConfigServiceActions,
	IssueRefreshLoadingActions,
} from '@atlassian/jira-issue-field-base';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service';
import type { IssueViewNonCriticalDataActions } from '@atlassian/jira-issue-non-critical-gira-service';
import type { IssueRefreshServiceActions } from '@atlassian/jira-issue-refresh-service';
import type { IssueScrollActions } from '@atlassian/jira-issue-scroll/src';
import type { OnErrorCallback } from '@atlassian/jira-issue-shared-types';
import type { UserPreferenceActions } from '@atlassian/jira-issue-user-preference-services';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { errorLoggingMiddleware } from '@atlassian/jira-issue-view-common-utils/src/utils/error-logging-middleware';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources';
import type { EcosystemActions } from '@atlassian/jira-issue-view-ecosystem-service';
import type { IssueViewFieldUpdateActions } from '@atlassian/jira-issue-view-field-update-events';
import type { ForgeServiceActions } from '@atlassian/jira-issue-view-forge-service';
import type { IssueViewLayoutActions } from '@atlassian/jira-issue-view-layout';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type';
import type { RecentIssuesActions } from '@atlassian/jira-issue-view-services/src/recent-issues-service';
import rootReducer from '@atlassian/jira-issue-view-store/src/reducers';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services';
import type { ProjectContextServiceActions } from '@atlassian/jira-project-context-service';
import type { ProjectPermissionActions } from '@atlassian/jira-project-permissions-service';
import type { DataProvider } from '@atlassian/jira-providers-issue/src';
import type { JiraSettingsActions } from '@atlassian/jira-settings-service';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types';
import createRootEpic from './epics';

type ConfigType = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initialState?: any;
	isSSR?: boolean;
	onChange?: OnChangeCallback;
	onError?: OnErrorCallback;
	dataProvider: DataProvider;
	history: History;
	issueKey: IssueKey;
	name?: string;
	subProduct: SubProduct | null;
	issueRefreshServiceActions: IssueRefreshServiceActions;
	fieldsValueActions: FieldValueServiceActions;
	fieldsConfigActions: FieldConfigServiceActions;
	issueViewLayoutActions: IssueViewLayoutActions;
	issueViewFieldUpdateActions: IssueViewFieldUpdateActions;
	forgeActions: ForgeServiceActions;
	ecosystemActions: EcosystemActions;
	attachmentActions: AttachmentServiceActions;
	recentIssuesActions: RecentIssuesActions;
	prefetchedResourceManager?: ResourceManager;
	userPreferenceActions: UserPreferenceActions;
	issueScrollActions: IssueScrollActions;
	permissionActions: ProjectPermissionActions;
	projectContextActions: ProjectContextServiceActions;
	jiraSettingsActions: JiraSettingsActions;
	issueContextActions: IssueContextServiceActions;
	workflowTransitionsActions: WorkflowTransitionsActions;
	mediaContextActions: MediaContextServiceActions;
	issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>;
	associatedIssuesContextActions?: AssociatedIssuesContextActions;
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions;
};

const createMiddleware = (
	onChange: OnChangeCallback,
	onError: OnErrorCallback,
	dataProvider: DataProvider,
	history: History,
	subProduct: SubProduct | null,
	issueRefreshServiceActions: IssueRefreshServiceActions,
	fieldsValueActions: FieldValueServiceActions,
	fieldsConfigActions: FieldConfigServiceActions,
	issueViewLayoutActions: IssueViewLayoutActions,
	issueViewFieldUpdateActions: IssueViewFieldUpdateActions,
	forgeActions: ForgeServiceActions,
	ecosystemActions: EcosystemActions,
	attachmentActions: AttachmentServiceActions,
	recentIssuesActions: RecentIssuesActions,
	prefetchedResourceManager: ResourceManager | undefined,
	userPreferenceActions: UserPreferenceActions,
	issueScrollActions: IssueScrollActions,
	permissionActions: ProjectPermissionActions,
	jiraSettingsActions: JiraSettingsActions,
	projectContextActions: ProjectContextServiceActions,
	issueContextActions: IssueContextServiceActions,
	workflowTransitionsActions: WorkflowTransitionsActions,
	mediaContextActions: MediaContextServiceActions,
	issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rootEpic: Epic<any, State> = disposableEpic(
		createRootEpic(
			onChange,
			onError,
			dataProvider,
			history,
			subProduct,
			issueRefreshServiceActions,
			fieldsValueActions,
			fieldsConfigActions,
			issueViewLayoutActions,
			issueViewFieldUpdateActions,
			forgeActions,
			ecosystemActions,
			attachmentActions,
			recentIssuesActions,
			userPreferenceActions,
			prefetchedResourceManager,
			issueScrollActions,
			permissionActions,
			jiraSettingsActions,
			projectContextActions,
			issueContextActions,
			workflowTransitionsActions,
			mediaContextActions,
			issueRefreshLoadingActionsRef,
			associatedIssuesContextActions,
			issueViewNonCriticalDataActions,
		),
	);
	const epicMiddleware = createEpicMiddleware(rootEpic);

	return [errorLoggingMiddleware, epicMiddleware] as const;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	initialState,
	onChange = noop,
	onError = noop,
	dataProvider,
	history,
	issueKey = toIssueKey(''),
	subProduct,
	issueRefreshServiceActions,
	fieldsValueActions,
	fieldsConfigActions,
	issueViewLayoutActions,
	issueViewFieldUpdateActions,
	forgeActions,
	ecosystemActions,
	attachmentActions,
	recentIssuesActions,
	prefetchedResourceManager,
	userPreferenceActions,
	issueScrollActions,
	permissionActions,
	jiraSettingsActions,
	projectContextActions,
	issueContextActions,
	workflowTransitionsActions,
	mediaContextActions,
	associatedIssuesContextActions,
	issueRefreshLoadingActionsRef,
	issueViewNonCriticalDataActions,
}: ConfigType) =>
	createStore(
		enableBatching(rootReducer),
		initialState,
		composeWithDevtools({ name: `issue-${issueKey}` })(
			applyMiddleware(
				// @ts-expect-error - TS2345 - Argument of type '() => (next: (arg1: Action) => Action) => (action: Action) => any' is not assignable to parameter of type 'Middleware'.
				...createMiddleware(
					onChange,
					onError,
					dataProvider,
					history,
					subProduct,
					issueRefreshServiceActions,
					fieldsValueActions,
					fieldsConfigActions,
					issueViewLayoutActions,
					issueViewFieldUpdateActions,
					forgeActions,
					ecosystemActions,
					attachmentActions,
					recentIssuesActions,
					prefetchedResourceManager,
					userPreferenceActions,
					issueScrollActions,
					permissionActions,
					jiraSettingsActions,
					projectContextActions,
					issueContextActions,
					workflowTransitionsActions,
					mediaContextActions,
					issueRefreshLoadingActionsRef,
					associatedIssuesContextActions,
					issueViewNonCriticalDataActions,
				),
			),
		),
	);
