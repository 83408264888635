import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	restoreButton: {
		id: 'polaris-ideas.idea-view.archived.restore-button',
		defaultMessage: 'Restore',
		description: 'Restore button title',
	},
	title: {
		id: 'polaris-ideas.idea-view.archived.title',
		defaultMessage: `This idea is archived. It’s still available to view, but you’ll need to
                        restore it to edit or connect it to other ideas.`,
		description: 'Issue was archived notification',
	},
});
