/*
 * These epics allow external sources to make modifications to fields.
 *
 * With connected-fields, fields contain within themselves information that
 * control how they behave when modified.
 *
 * FIELD_INIT_OPTIONS is dispatched by the field when it mounts to make this
 * information accessible by external requests to modify the field.
 */

import { combineEpics } from 'redux-observable';
import fieldExternalEditRequests from './field-external-edit-epic';
import fieldExternalSaveRequests from './field-external-save-epic';

// @ts-expect-error - TS2769 - No overload matches this call.
export default combineEpics(fieldExternalEditRequests, fieldExternalSaveRequests);
