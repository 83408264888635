import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

const PROXY_URL = '/gateway/api/jpd/proxy/v1/feedback';

export const raiseTicket = (message: string, target: string) =>
	performPostRequest(PROXY_URL, {
		body: JSON.stringify({
			message,
			target,
		}),
	});
