import React from 'react';
import { styled } from '@compiled/react';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { KEY_FIELD_HEIGHT } from '../../constants';

type Props = {
	issueKey: string;
};

export const KeyField = ({ issueKey }: Props) => {
	if (issueKey === undefined) {
		return null;
	}

	return (
		<KeyFieldContainer data-testid="polaris-common.ui.idea-card-v2.header.key-field.key-field-value">
			<KeyFieldValue>{issueKey}</KeyFieldValue>
		</KeyFieldContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyFieldContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${KEY_FIELD_HEIGHT}px`,
	alignItems: 'center',
	maxWidth: '75px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeyFieldValue = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	fontWeight: '700',
	color: token('color.text.subtlest', N200),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
