import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { validateFieldOptionName } from '../../../utils/validate-field-option-name';
import type { FieldOptionNameValidationResult } from '../../../utils/validate-field-option-name/types';
import messages from './messages';

type Props = {
	placeholder: string;
	entityLimitMessage?: string | null;
	existingOptionNames: string[];
	isOptionCreationDisabled?: boolean;
	isCompact?: boolean;
	onOptionCreation: (optionName: string) => Promise<void>;
	onSearch: (query: string) => void;
};

export const SearchAndCreateInput = forwardRef<HTMLElement, Props>(
	(
		{
			placeholder,
			entityLimitMessage,
			existingOptionNames,
			isOptionCreationDisabled,
			isCompact = true,
			onOptionCreation,
			onSearch,
		},
		ref,
	) => {
		const { formatMessage } = useIntl();
		const [localValue, setLocalValue] = useState<string>('');
		const localValueRef = useRef<string>('');
		const isCreatingOption = useRef(false);
		const [validation, setValidation] = useState<FieldOptionNameValidationResult>({
			isValid: true,
		});

		const debouncedSearch = useMemo(
			() =>
				debounce((query: string) => {
					if (!isCreatingOption.current) {
						onSearch(query);
					}
				}, 250),
			[onSearch],
		);

		useEffect(
			() => () => {
				debouncedSearch.cancel();
			},
			[debouncedSearch],
		);

		const handleKeyDown = useCallback(
			async (e: React.KeyboardEvent<HTMLInputElement>) => {
				if (e.key === 'Enter' && !isOptionCreationDisabled) {
					const trimmedLocalValue = localValue.trim();

					if (trimmedLocalValue !== localValue) {
						setLocalValue(trimmedLocalValue);
						localValueRef.current = trimmedLocalValue;
					}

					const validationResult = validateFieldOptionName({
						entityLimitMessage,
						existingOptionNames,
						newValue: trimmedLocalValue,
						formatMessage,
					});

					setValidation(validationResult);

					if (validationResult.isValid) {
						isCreatingOption.current = true;

						setLocalValue('');
						localValueRef.current = '';
						onSearch('');

						await onOptionCreation(trimmedLocalValue);

						sendPendoTrackEvent({
							actionSubjectAndAction: 'option created',
							actionSubjectId: 'createOption',
							source: 'fieldOptionList',
						});

						debouncedSearch.cancel();

						isCreatingOption.current = false;

						if (localValueRef.current !== '') {
							onSearch(localValueRef.current);
						}
					}
				}
			},
			[
				debouncedSearch,
				entityLimitMessage,
				existingOptionNames,
				formatMessage,
				isOptionCreationDisabled,
				localValue,
				onOptionCreation,
				onSearch,
			],
		);

		return (
			<>
				<Textfield
					ref={ref}
					testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.search-and-create-input.textfield"
					isCompact={isCompact}
					value={localValue}
					placeholder={placeholder}
					onChange={(event) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const target = event.target as HTMLInputElement;
						setLocalValue(target.value);
						localValueRef.current = target.value;

						if (!isOptionCreationDisabled && !validation.isValid) {
							setValidation({ isValid: true });
						}

						debouncedSearch(target.value);
					}}
					onKeyDown={handleKeyDown}
				/>
				{!isOptionCreationDisabled && localValue && validation.isValid && (
					<Box paddingBlockStart="space.050" xcss={createMessageContainerStyles}>
						{formatMessage(messages.createOptionDescriptionText, {
							optionName: localValue,
							b: (text: React.ReactNode) => <b>{text}</b>,
						})}
					</Box>
				)}
				{!isOptionCreationDisabled && !validation.isValid && validation.error !== undefined && (
					<Box
						paddingBlockEnd="space.050"
						xcss={errorContainerStyles}
						testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.search-and-create-input.error"
					>
						<ErrorMessage>{validation.error}</ErrorMessage>
					</Box>
				)}
			</>
		);
	},
);

const createMessageContainerStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const errorContainerStyles = xcss({
	whiteSpace: 'break-spaces',
});
