import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { PropertyMaps, State } from '../../types';

export const setIssues =
	(ids: LocalIssueId[], properties: PropertyMaps): Action<State> =>
	({ setState }) => {
		setState({
			ids,
			properties,
		});
	};
