import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { Action } from '@atlassian/jira-issue-view-actions';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import { updateActivitySortOrder } from '@atlassian/jira-issue-view-services/src/issue/activity-sort-server';
import {
	UPDATE_ACTIVITY_SORT_ORDER_REQUEST,
	type UpdateActivitySortOrderRequest,
} from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';

const LOG_LOCATION = 'issue.activity-feed.sort.save.epic';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(UPDATE_ACTIVITY_SORT_ORDER_REQUEST)
		.switchMap((action: UpdateActivitySortOrderRequest) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			return updateActivitySortOrder(baseUrl, action.payload).ignoreElements();
		})
		.catch((error) => {
			trackOrLogClientError(LOG_LOCATION, 'Could not save sort order in jira issue view', error);

			return Observable.empty<never>();
		});
