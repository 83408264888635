export const WEIGHT_TYPE_RATING = 'RATING';
export const WEIGHT_TYPE_NUMBER = 'NUMBER';

type WeightType = typeof WEIGHT_TYPE_RATING | typeof WEIGHT_TYPE_NUMBER;
type Configuration = {
	optionWeightType?: WeightType;
	optionWeights?: {
		weight: number;
	}[];
};

export const mapOptionWeightType = (configuration?: Configuration): WeightType | undefined => {
	const optionWeights = configuration?.optionWeights;

	let optionWeightType: WeightType | undefined = WEIGHT_TYPE_RATING;
	// if there aren't any weights or all weights === 1, option weight type should be undefined
	if (!optionWeights?.length || optionWeights?.every(({ weight }) => weight === 1)) {
		optionWeightType = undefined;
	} else if (optionWeights?.some(({ weight }) => weight < 1 || weight > 5)) {
		optionWeightType = WEIGHT_TYPE_NUMBER;
	}

	// if the optionWeightType is defined in the configuration, use that value instead
	if (
		configuration?.optionWeightType !== undefined &&
		(configuration?.optionWeightType === WEIGHT_TYPE_NUMBER ||
			configuration?.optionWeightType === WEIGHT_TYPE_RATING)
	) {
		optionWeightType = configuration?.optionWeightType;
	}

	return optionWeightType;
};
