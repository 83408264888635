import type { ReactNode } from 'react';
import type { IconProps } from '@atlaskit/icon';
import type { Ari } from '@atlassian/jira-platform-ari';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import {
	isDeliveryFieldType,
	FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import {
	DESCRIPTION_FIELDKEY,
	ISSUETYPE_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type {
	FieldDescription,
	Field,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisPlay } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { FieldPresentation } from '@atlassian/jira-polaris-domain-field/src/presentation/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { createHook } from '@atlassian/react-sweet-state';
import { FieldStore } from '../main';
import { isCreatingIdeasGroupBySupported } from '../utils/is-creating-ideas-group-by-supported';
import {
	getAllFields,
	getAllVisibleFields,
	createGetEditable,
	createGetFieldOfType,
	createGetFieldByPlayId,
	createGetFieldDescription,
	createGetFieldEmoji,
	createGetFieldFormula,
	createGetFieldKeysOfType,
	createGetFieldLabel,
	createGetFieldPlay,
	createGetFieldPresentation,
	createGetNewFieldType,
	createGetIsDeletable,
	createHasFieldFormula,
	getAllFieldArray,
	getArchivedFieldsConfig,
	getFieldArray,
	getFieldCount,
	getFieldKeys,
	getFieldLabels,
	getFieldTypeIcon,
	getNewFieldTypes,
	getIsArchivingEnabled,
	getAllEditableFields,
	getAtlasFieldsEnabled,
	createGetFieldByKey,
	getAllStringFields,
	createIsGlobalCustomField,
	getGlobalFieldCount,
	getHasFieldsError,
	getHighlightedFields,
	getNewlyAddedGlobalFields,
	createGetFieldHidden,
	createGetFieldCountByType,
	createGetFieldRestricted,
	getVisibleFieldArray,
	getGlobalFieldsInUseArray,
} from './field';
import { createFieldHook, createHigherLevelFieldHook, createHigherLevelFieldHook2 } from './utils';

/**
 * Expose fields including hidden as an array.
 */
export const useAllFieldsArray = createHook(FieldStore, {
	selector: getAllFieldArray,
});

/**
 * Find `Field` quickly by FieldKey.
 */
export const useField = createHook(FieldStore, {
	selector: (state, key: string) => getAllFields(state)[key],
});

/**
 * Expose fields for easy look up by `FieldKey` aka Polaris Field Key.
 */
export const useFieldsByKey = createHook(FieldStore, {
	selector: getAllFields,
});

/**
 * Expose visible fields for easy look up by `FieldKey` aka Polaris Field Key.
 * i.e. does not include system fields that are hidden
 */
export const useVisibleFieldsByKey = createHook(FieldStore, {
	selector: getAllVisibleFields,
});

/**
 * Expose fields as an array.
 */
export const useFieldsArray = createHook(FieldStore, {
	selector: getFieldArray,
});

/**
 * Expose visible fields as an array.
 */
export const useVisibleFieldsArray = createHook(FieldStore, {
	selector: getVisibleFieldArray,
});

/**
 * Expose global fields that are in use in the project.
 */
export const useGlobalFieldsInUseArray = createHook(FieldStore, {
	selector: getGlobalFieldsInUseArray,
});

/**
 * Expose fields-keys as an array.
 */
export const useFieldKeys = createHook(FieldStore, {
	selector: getFieldKeys,
});

export const useDescriptionField = createHook(FieldStore, {
	selector: (state) => getFieldArray(state).find((field) => field.key === DESCRIPTION_FIELDKEY),
});

export const useFieldOfType = createHigherLevelFieldHook<FieldType, Field | undefined>(
	createGetFieldOfType,
);

export const useAtlasFieldsEnabled = createHook(FieldStore, {
	selector: getAtlasFieldsEnabled,
});

/**
 * Return the field that should be used to reference the issue 'issuetype'.
 */
export const useIssueTypeField = createHook(FieldStore, {
	selector: (state) => getFieldArray(state).find((field) => field.key === ISSUETYPE_FIELDKEY),
});

export const useAllDeliveryFields = createHook(FieldStore, {
	selector: (state) =>
		getFieldArray(state)
			.filter((field) => isDeliveryFieldType(field.type))
			.map(({ key }) => key),
});

export const useFieldType = createHigherLevelFieldHook<FieldKey | undefined, FieldType | undefined>(
	createGetNewFieldType,
);

export const useIsDeliveryFieldType = (fieldKey: FieldKey | undefined): boolean => {
	const fieldType = useFieldType(fieldKey);
	return !fieldKey || !fieldType ? false : isDeliveryFieldType(fieldType);
};

export const useFieldLabel = createHigherLevelFieldHook<FieldKey | undefined, string | undefined>(
	createGetFieldLabel,
);

export const useIsGlobalCustomField = createHigherLevelFieldHook<FieldKey | undefined, boolean>(
	createIsGlobalCustomField,
);
export const useFieldPlay = createHigherLevelFieldHook<
	FieldKey | undefined,
	PolarisPlay | undefined
>(createGetFieldPlay);

export const useIsReadOnlyFieldForGrouping = (fieldKey: FieldKey | undefined): boolean => {
	const fieldType = useFieldType(fieldKey);

	return fieldType === FIELD_TYPES.REACTIONS || fieldType === FIELD_TYPES.ATLAS_PROJECT_STATUS;
};

export const useIsCreatingIdeasForGroupingSupported = (fieldKey: FieldKey | undefined): boolean => {
	const fieldType = useFieldType(fieldKey);
	return isCreatingIdeasGroupBySupported(fieldType);
};

export const useIsDeletable = createHigherLevelFieldHook<FieldKey | undefined, boolean>(
	createGetIsDeletable,
);

export const useFieldEditable = createHigherLevelFieldHook<FieldKey | undefined, boolean>(
	createGetEditable,
);

export const useAllEditableFields = createFieldHook(getAllEditableFields);

export const useFieldFormula = createHigherLevelFieldHook<
	FieldKey,
	DynamicFieldFormula | undefined
>(createGetFieldFormula);
export const useFieldPresentation = createHigherLevelFieldHook<
	FieldKey | undefined,
	FieldPresentation | undefined
>(createGetFieldPresentation);

export const useHasFieldFormula = createHigherLevelFieldHook<FieldKey, boolean>(
	createHasFieldFormula,
);

export const useFieldTypeIcon = createHigherLevelFieldHook2<
	FieldKey,
	IconProps | undefined,
	ReactNode | null
>(getFieldTypeIcon);

export const useFieldLabels = createHook(FieldStore, {
	selector: getFieldLabels,
});

export const useFieldTypes = createHook(FieldStore, {
	selector: getNewFieldTypes,
});

export const useNewlyAddedGlobalFields = createHook(FieldStore, {
	selector: getNewlyAddedGlobalFields,
});

export const useFieldDescription = createHigherLevelFieldHook<FieldKey, FieldDescription>(
	createGetFieldDescription,
);

export const useFieldEmoji = createHigherLevelFieldHook<FieldKey, string | undefined>(
	createGetFieldEmoji,
);

export const useIsHidden = createHigherLevelFieldHook<FieldKey, boolean>(createGetFieldHidden);

export const useIsRestricted = createHigherLevelFieldHook<FieldKey, boolean>(
	createGetFieldRestricted,
);

export const useArchivedFieldsConfig = createHook(FieldStore, {
	selector: getArchivedFieldsConfig,
});

export const useIsArchivingEnabled = createHook(FieldStore, {
	selector: getIsArchivingEnabled,
});

export const useFieldByPlayId = createHigherLevelFieldHook<Ari, Field | undefined>(
	createGetFieldByPlayId,
);

export const useFieldKeysOfType = createHigherLevelFieldHook<FieldType, FieldKey[]>(
	createGetFieldKeysOfType,
);

export const useFieldCount = createFieldHook(getFieldCount);
export const useGlobalFieldCount = createFieldHook(getGlobalFieldCount);

export const useFieldByKey = createHigherLevelFieldHook<FieldKey | undefined, Field | undefined>(
	createGetFieldByKey,
);

export const useStringFields = createFieldHook(getAllStringFields);

export const useHasFieldsError = createFieldHook(getHasFieldsError);

export const useHighlightedFields = createFieldHook(getHighlightedFields);

export const useFieldCountByType = createFieldHook(createGetFieldCountByType);

type LimitValues = {
	[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT]: string | null;
	[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT]: string | null;
};

// Returns the limit type and value if reached, otherwise returns null
export const useHasReachedFieldsLimit = (): LimitValues => {
	const entityLimitMessage = useEntityLimitMessage();

	const limits: LimitValues = {
		[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT]: null,
		[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT]: null,
	};

	const { standardCount, votesCount } = useFieldCountByType();

	const fieldsLimitStandard = useEntityLimitsByType(ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT);
	const fieldsLimitVotes = useEntityLimitsByType(ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT);

	if (fieldsLimitStandard && standardCount >= fieldsLimitStandard) {
		limits[ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT] = entityLimitMessage(
			ENTITY_LIMIT_TYPE.FIELDS_PER_PROJECT,
			fieldsLimitStandard,
		);
	}

	if (fieldsLimitVotes && votesCount >= fieldsLimitVotes) {
		limits[ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT] = entityLimitMessage(
			ENTITY_LIMIT_TYPE.PLAYS_PER_PROJECT,
			fieldsLimitVotes,
		);
	}

	return limits;
};
