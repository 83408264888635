export const MAX_FIELD_NAME_LENGTH = 255;
export const FIELD_ITEM_OUTER_SPACING = '16px';

export const POLARIS_OAUTH_CLIENT_ID = 'polaris';

export const dotStartingDecimalRegex = /(\D\.[0-9]+)|(^\.[0-9]+)/;

export const PROPERTY_ROLLUP = 'property';
export const FIELD_ROLLUP = 'field';
export const COUNT_ROLLUP = 'count';
