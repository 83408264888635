import React from 'react';
import { ButtonItem, Section } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useCanManageIdeaTemplates } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import { messages } from './messages';

export type MenuOptionsProps = {
	onEdit?: (() => void) | undefined;
	onApply: (template: ADF | undefined) => void;
	onDelete?: (() => void) | undefined;
	isAppliedTemplate?: boolean;
};

export const MenuOptions = ({ onEdit, onDelete, onApply, isAppliedTemplate }: MenuOptionsProps) => {
	const canManageIdeaTemplates = useCanManageIdeaTemplates();
	const { formatMessage } = useIntl();

	const applyButtonTestId = `polaris-ideas.ui.right-sidebar.idea-templates.common.menu.${
		isAppliedTemplate ? 'reset' : 'apply'
	}`;

	return (
		<Section>
			<ButtonItem
				testId={applyButtonTestId}
				// @ts-expect-error - TS2322 - Type '(template: DocNode | undefined) => void' is not assignable to type '(event: MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>) => void'.
				onClick={onApply}
			>
				{isAppliedTemplate
					? formatMessage(messages.resetTemplate)
					: formatMessage(messages.applyTemplate)}
			</ButtonItem>
			{onEdit && (
				<Tooltip
					content={canManageIdeaTemplates ? null : formatMessage(messages.noManagePermissions)}
				>
					<ButtonItem
						testId="polaris-ideas.ui.right-sidebar.idea-templates.common.menu.edit"
						onClick={onEdit}
						isDisabled={!canManageIdeaTemplates}
					>
						{formatMessage(messages.editTemplate)}
					</ButtonItem>
				</Tooltip>
			)}
			{onDelete && (
				<Tooltip
					content={canManageIdeaTemplates ? null : formatMessage(messages.noManagePermissions)}
				>
					<ButtonItem
						testId="polaris-ideas.ui.right-sidebar.idea-templates.common.menu.delete"
						onClick={onDelete}
						isDisabled={!canManageIdeaTemplates}
					>
						{formatMessage(messages.deleteTemplate)}
					</ButtonItem>
				</Tooltip>
			)}
		</Section>
	);
};
