import React, { type ReactNode, type SyntheticEvent, useMemo, type ReactChild } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export type ActionButtonProps = {
	isSelected?: boolean;
	isEmpty: boolean;
	isAccentuated?: boolean | undefined;
	id: string;
	children?: ReactNode;
	elemAfter?: ReactNode | null;
	hasUnsavedChanges?: boolean;
	iconBefore?: ReactChild;
	onClick: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	testId?: string;
};

export const ActionButton = ({
	id,
	children = null,
	elemAfter,
	isEmpty,
	isSelected = false,
	isAccentuated,
	onClick,
	hasUnsavedChanges,
	iconBefore,
	testId,
}: ActionButtonProps) => {
	const iconAfter = useMemo(() => {
		if (iconBefore) {
			return undefined;
		}

		if (elemAfter !== null && elemAfter !== undefined) {
			return (
				<ElementAfterContainer
					hasUnsavedChanges={hasUnsavedChanges && !isSelected}
					isSelected={isSelected}
					isAccentuated={isAccentuated && !isSelected}
				>
					{elemAfter}
				</ElementAfterContainer>
			);
		}

		return isEmpty ? (
			<AddIconContainer isSelected={isSelected}>
				<AddIcon
					size="small"
					label=""
					testId="polaris-ideas.ui.view-controls.action-button.add-icon"
				/>
			</AddIconContainer>
		) : undefined;
	}, [elemAfter, isEmpty, iconBefore, isSelected, hasUnsavedChanges, isAccentuated]);

	return (
		<ControlButtonWrapper
			isAccentuated={isAccentuated && !isSelected}
			hasUnsavedChanges={hasUnsavedChanges && !isSelected}
			hasChanges={isEmpty === false && !isSelected}
		>
			<Button
				id={id}
				testId={testId}
				isSelected={isSelected}
				onClick={onClick}
				iconAfter={iconAfter}
				iconBefore={iconBefore}
			>
				{children}
			</Button>
		</ControlButtonWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ElementAfterContainer = styled.span<{
	isAccentuated?: boolean;
	isSelected?: boolean;
	hasUnsavedChanges?: boolean;
}>({
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isSelected, hasUnsavedChanges, isAccentuated }) =>
		isSelected
			? 'inherit'
			: hasUnsavedChanges
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.warning.hovered', colors.Y75)
				: isAccentuated
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.discovery.hovered', colors.P75)
					: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.selected.pressed', colors.B75),
	/* eslint-enable no-nested-ternary */
	borderRadius: '2px',
	marginLeft: token('space.050', '4px'),
	minWidth: '16px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	fontWeight: 500,
	lineHeight: '16px',
	padding: '0 3px',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddIconContainer = styled.span<{ isSelected: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isSelected }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isSelected ? 'inherit' : token('color.background.neutral.subtle.hovered', colors.N20A),
	borderRadius: '2px',
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlButtonWrapper = styled.div<{
	isSharedView?: boolean;
	isAccentuated?: boolean;
	hasChanges?: boolean;
	hasUnsavedChanges?: boolean;
}>({
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ isAccentuated, hasUnsavedChanges, hasChanges }) =>
			hasUnsavedChanges
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.warning', colors.Y50)
				: isAccentuated
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.discovery', colors.P50)
					: hasChanges
						? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							token('color.background.accent.blue.subtler', colors.B50)
						: undefined,
		/* eslint-enable no-nested-ternary */
	},
});
