import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraIssueKeyMapping } from '../../../../field/mapping/issue-key';
import { numberComparator } from '../../comparators';
import { createDisjunctTextChunkFilter } from '../common/filter-utils';
import { stringMapping } from '../string';
import type { FieldMapping } from '../types';

export const issueKeyMapping = (issuesRemote: IssuesRemote, field: Field): FieldMapping<string> => {
	const basicStringMapping = stringMapping(issuesRemote, field);
	return {
		...jiraIssueKeyMapping(issuesRemote, field),
		setMutable: basicStringMapping.setMutable,
		setImmutable: basicStringMapping.setImmutable,
		remove: basicStringMapping.remove,
		modifyImmutableIfMultiValueField: basicStringMapping.modifyImmutableIfMultiValueField,
		comparator: (a, b, direction) => {
			const extractKeyNumPart = (key: undefined | string) =>
				key !== undefined ? +key.substring(key.indexOf('-') + 1) : undefined;
			return numberComparator(extractKeyNumPart(a), extractKeyNumPart(b), direction);
		},
		valueAccessor: basicStringMapping.valueAccessor,
		valueAccessorToExport: basicStringMapping.valueAccessorToExport,
		getAllValues: basicStringMapping.getAllValues,
		getGroupIdentities: basicStringMapping.getGroupIdentities,
		getGroupIdentitiesSelector: basicStringMapping.getGroupIdentitiesSelector,
		allowEmptyGroup: false,
		getLabel: (groupIdentity, value) => value,
		getFilter: (filter: Filter) => {
			if (filter.type === 'TEXT') {
				const filterFunction = createDisjunctTextChunkFilter(filter);
				if (filterFunction !== undefined) {
					return {
						filterId: filter.localId,
						filterFunction,
						disjunctionIdentifier: 'TEXT',
					};
				}
			}
			return undefined;
		},
	};
};
