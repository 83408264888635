import React, { useMemo, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { components as componentsNext, type SingleValueProps } from '@atlaskit/select';
import { Connect } from '../../common/connect';
import { SignInRequired } from '../../common/sign-in';
import { Control } from './control';
import { MenuListComponent } from './menu-list';
import { Option } from './option';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MenuComponentProps = any;

const createMenuComponent =
	(id: string) =>
	({ children, ...rest }: MenuComponentProps) => (
		<div id={id}>
			<componentsNext.Menu {...rest}>{children}</componentsNext.Menu>
		</div>
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NoOptionsMessage = (props: any) => {
	if (props.selectProps.signInRequired) {
		return (
			<componentsNext.NoOptionsMessage {...props}>
				<SignInRequiredContainer>
					<SignInRequired cloudId={props.selectProps.fetchOptionsCloudId} />
				</SignInRequiredContainer>
			</componentsNext.NoOptionsMessage>
		);
	}
	return <componentsNext.NoOptionsMessage {...props} />;
};

type CustomComponentsProps = {
	isMulti: boolean;
	selectMenuId: string;
	renderer: ({ id }: { id: string }) => ReactElement;
};

export const useCustomComponents = ({
	isMulti,
	selectMenuId,
	renderer: Renderer,
}: CustomComponentsProps) =>
	useMemo(
		() =>
			// eslint-ignore-next-line
			({
				MultiValueContainer: () => null,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				SingleValue: ({ innerProps, ...rest }: SingleValueProps<any>) => (
					<componentsNext.SingleValue
						innerProps={{
							...innerProps,
							style: {
								minHeight: '16px',
								width: 'fit-content',
								maxWidth: '95%',
							},
						}}
						{...rest}
					>
						<Renderer id={rest.data.id} />
					</componentsNext.SingleValue>
				),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				MultiValueLabel: ({ innerRef, innerProps, data }: any) => (
					<MultiValueDecorationItemContainer ref={innerRef} {...innerProps}>
						<Renderer id={data.id} />
					</MultiValueDecorationItemContainer>
				),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				Control: (props: any) => <Control {...props} isMulti={isMulti} />,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				Option: (props: any) => {
					const { children, ...rest } = props;
					if (props.data.id === 'connect-placeholder') {
						return (
							<componentsNext.NoOptionsMessage {...props}>
								<Connect onConfiguration={props.selectProps.onConfiguration} />
							</componentsNext.NoOptionsMessage>
						);
					}
					if (props.data.id === 'is-loading-placeholder') {
						return <componentsNext.LoadingMessage {...rest} label={undefined} value={undefined} />;
					}
					if (props.data.id === 'no-options-placeholder') {
						return <NoOptionsMessage {...rest} label={undefined} value={undefined} />;
					}
					return <Option {...props} isMulti={isMulti} renderer={Renderer} />;
				},
				Menu: createMenuComponent(selectMenuId),
				MenuList: MenuListComponent,
				NoOptionsMessage,
			}),
		[selectMenuId, isMulti, Renderer],
	);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SignInRequiredContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueDecorationItemContainer = styled.div({
	lineHeight: '16px',
	minHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	margin: '0px !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '0px !important',
	display: 'inline-block',
});
