import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

const JIRA_SEARCH_URL = '/rest/api/3/search';

const createInitialSearchBody = (jql: string, storyPointsCustomFieldId?: string): string =>
	JSON.stringify({
		jql,
		fields: storyPointsCustomFieldId ? [storyPointsCustomFieldId] : [],
	});

export const fetchLinkedIssuesStoryPointField = (
	jql: string,
	storyPointsEstimateFieldId?: string,
) =>
	fetchJson(JIRA_SEARCH_URL, {
		method: 'POST',
		body: createInitialSearchBody(jql, storyPointsEstimateFieldId),
	}).then((response) => response.issues);
