import { useEffect, useCallback, useRef, useMemo } from 'react';
import {
	isLoading,
	isInitialized,
	isSharedViewUnavailable,
	isSharedViewAuthenticationMissing,
	isLoadingError,
} from './meta';
import { createFieldHook } from './utils';

export const useIsLoading = createFieldHook(isLoading);
export const useIsLoadingFailed = createFieldHook(isLoadingError);
export const useIsInitialized = createFieldHook(isInitialized);
export const useIsSharedViewUnavailable = createFieldHook(isSharedViewUnavailable);
export const useIsSharedViewAuthenticationMissing = createFieldHook(
	isSharedViewAuthenticationMissing,
);

export const useIsInitializedPromise = (): (() => Promise<boolean>) => {
	const initialized = useIsInitialized();
	const resolverRef = useRef<((v: boolean) => void) | null>(null);

	useEffect(() => {
		if (!initialized) {
			return;
		}

		if (resolverRef.current) {
			resolverRef.current(true);
		}
	}, [initialized]);

	/**
	 * resolve the promise either
	 * - immediately if initialized is already true on first hook access
	 *   if the callback function gets recreated due to `initialized` changeing
	 *   this is fine, as any new listeners on the new promise will resolve immediately,
	 *   while the old listeners on the old promise still get served by the resolverRef effect
	 * - delayed, if initialized is false and switches from false to true
	 */

	const promise = useMemo(
		() =>
			initialized
				? Promise.resolve(true)
				: new Promise((resolve: (result: Promise<boolean> | boolean) => void) => {
						resolverRef.current = resolve;
					}),
		[initialized],
	);

	return useCallback(() => promise, [promise]);
};
