import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createHigherLevelHook } from '../index';
import { DEFAULT_OPTION_WEIGHT } from '../types';
import { createGetFieldType, createGetOptions, getFieldEmoji, getFieldTypeIcon } from './formula';

export const useFieldOptions = createHigherLevelHook(createGetOptions);

export const useHasDefaultFieldOptionWeights = (fieldKey: FieldKey) => {
	const options = useFieldOptions(fieldKey) || [];
	return !options.some((option) => option.weight !== DEFAULT_OPTION_WEIGHT);
};

export const useFieldType = createHigherLevelHook(createGetFieldType);

export const useFieldTypeIcon = createHigherLevelHook(getFieldTypeIcon);

export const useFieldEmoji = createHigherLevelHook(getFieldEmoji);
