import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	descriptionPlaceholder: {
		id: 'polaris-ideas.idea-view.description.content.edit.description-placeholder',
		defaultMessage: 'Add a description... ',
		description: 'Placeholder for description when no description is yet set.',
	},
	templatesButton: {
		id: 'polaris-ideas.idea-view.description.content.edit.templates-button',
		defaultMessage: 'Templates',
		description: 'Button message for idea templates',
	},
	saveButton: {
		id: 'polaris-ideas.idea-view.description.content.edit.save-button',
		defaultMessage: 'Save',
		description: 'Button message for save idea description',
	},
	cancelButton: {
		id: 'polaris-ideas.idea-view.description.content.edit.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button message for cancel idea description changes',
	},
});
