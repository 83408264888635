import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { RefreshSpecificIssuesArgs } from '../../actions/refresh-issues/types';
import { useIssueActions } from '../../main';
import { EVENT_DEBOUNCE_WAIT_MS } from './constants';

type DebouncedRefreshIssues = (args: RefreshSpecificIssuesArgs) => void;

export const useDebouncedRefreshIssues = () => {
	const { refreshIssues } = useIssueActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useMemo<DebouncedRefreshIssues>(() => {
		const funcCache: Record<string, () => void> = {};

		return ({ jiraIssueIds }) => {
			const jiraIssueIdsStr = jiraIssueIds.sort().join(',');
			if (!has(funcCache, jiraIssueIdsStr)) {
				funcCache[jiraIssueIdsStr] = debounce(() => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'JpdJiraRealtimeEvent refreshIssue');
					refreshIssues({ jiraIssueIds });
				}, EVENT_DEBOUNCE_WAIT_MS);
			}

			funcCache[jiraIssueIdsStr]();
		};
	}, [createAnalyticsEvent, refreshIssues]);
};
