import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useProjectsCountForContainer } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import messages from './messages';

export const ViewProjectsCount = () => {
	const container = useEnvironmentContainer();
	const projectsCount = useProjectsCountForContainer({ containerId: container?.id });
	const { formatMessage } = useIntl();

	return <>{formatMessage(messages.projectsCount, { projectsCount })}</>;
};
