import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';

// TODO: Decide on how we store this information in public views
const getUpdateLastViewedUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/v0/views/${viewId}/last-viewed`;

export const updateViewLastViewed = async (viewId: string): Promise<void> => {
	performPutRequest(getUpdateLastViewedUrl(viewId));
};
