import { ff } from '@atlassian/jira-feature-flagging';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { publishFeatureDisabled } from '../../common/utils/publish';
import * as actions from './actions';
import { privateActions, publicActions, type Actions } from './actions';
import type { Props, State } from './types';

export const initialState = Object.freeze<State>({
	sharingSettings: {},
	sharingStakeholders: {},
	siteConfig: {
		isEnabled: false,
		isLoading: false,
		error: undefined,
	},
	sitePublicSharingConfig: {
		isEnabled: false,
		isLoading: false,
		error: undefined,
	},
	projectAccessLevel: undefined,
	containerProps: undefined,
	prevContainerProps: undefined,
});

const SharingStore = createPolarisStore<State, Actions>({
	initialState,
	actions: publicActions,
	name: 'SharingStore',
});

const onInit =
	(): Action<State, Props> =>
	({ dispatch, setState }, { edition, permissions }) => {
		if (!ff('polaris.sharing-enabled')) {
			return;
		}

		if (ff('polaris.publish.standard-edition') && publishFeatureDisabled(edition)) {
			return;
		}

		dispatch(actions.loadSharingSiteConfig());
		if (ff('polaris.published-view-public-links_dj5gi')) {
			setState({ permissions });
			dispatch(privateActions.loadSitePublicSharingConfig());
		}
		dispatch(actions.loadProjectAccessLevel());
	};

const onUpdate =
	(): Action<State, Props> =>
	({ setState }, { permissions }) => {
		if (ff('polaris.published-view-public-links_dj5gi')) {
			// In case permissions are not yet loaded onInit
			setState({ permissions });
		}
	};

const { Container, createHook, useActions } = createJpdContainer<Props, State, Actions>(
	SharingStore,
	{ onInit, onUpdate },
);

export const createSharingHook = createHook;
export const useSharingActions = useActions;
export { Container };
