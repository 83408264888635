import React, { useMemo, useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { entryPoint } from '@atlassian/jira-workflow-readonly-modal-v2/src/entrypoint';
import ViewWorkflowButton from '../../../common/ui/view-workflow/button';
import commonMessages from '../../../common/ui/view-workflow/messages';
import type { Props } from './types';

export const ViewWorkflowModalDropDownTrigger = ({
	hasPermission,
	isServiceLoading,
	isServiceRefetching,
	...selfProps
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSimplifiedProject = selfProps.isSimplifiedProject;
	const onMount = useCallback(() => {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'viewWorkflowTrigger mounted', {
			hasViewWorkflowPermission: hasPermission,
			isSimplifiedProject,
		});
	}, [createAnalyticsEvent, hasPermission, isSimplifiedProject]);

	const onClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'viewWorkflowTrigger',
				action: 'clicked',
			}),
			{ isSimplifiedProject },
		);
	}, [createAnalyticsEvent, isSimplifiedProject]);

	const { tooltip, isDisabled, isLoading } = useMemo(() => {
		if (isServiceLoading || isServiceRefetching) {
			return {
				tooltip: formatMessage(commonMessages.loading),
				isDisabled: true,
				isLoading: isServiceRefetching,
			};
		}

		if (hasPermission !== true) {
			return {
				tooltip: formatMessage(commonMessages.noPermission),
				isDisabled: true,
				isLoading: false,
			};
		}

		// Empty tooltip means no tooltip
		return { tooltip: '', isDisabled: false };
	}, [isServiceLoading, isServiceRefetching, hasPermission, formatMessage]);

	const modalProps = useMemo(
		() => ({
			width: '100%',
			testId: 'issue-line-card.ui.remove-button.confirmation-modal.delete',
		}),
		[],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={entryPoint}
			entryPointProps={selfProps}
			interactionName="open-read-view-workflow-modal"
			modalProps={modalProps}
			useInternalModal
		>
			{({ ref }) => (
				<ViewWorkflowButton
					forwardRef={ref}
					isDisabled={isDisabled}
					isLoading={isLoading}
					isInitialLoading={!!isServiceLoading}
					onMount={onMount}
					onClick={onClick}
					tooltipContent={tooltip}
				/>
			)}
		</ModalEntryPointPressableTrigger>
	);
};
