import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

type FieldResponse = {
	required: boolean;
	hasDefaultValue: boolean;
	key: string;
};

type IssueTypeResponse = {
	fields: {
		[key: string]: FieldResponse;
	};
};

type ProjectResponse = {
	issuetypes: IssueTypeResponse[];
};

type MetaDataResponse = {
	projects: ProjectResponse[];
};

export const fetchRequiredFieldKeys = (projectId: string, issueTypeId: string): Promise<string[]> =>
	fetchJson(
		`/rest/api/3/issue/createmeta?issuetypeIds=${issueTypeId}&projectIds=${projectId}&expand=projects.issuetypes.fields`,
	).then((response: MetaDataResponse) =>
		response.projects[0] && response.projects[0].issuetypes[0]
			? Object.values(response.projects[0].issuetypes[0].fields)
					.filter((field: FieldResponse) => field.required && !field.hasDefaultValue)
					.map((field) => field.key)
			: [],
	);
