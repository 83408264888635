import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyScreenHeader: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.empty-screen-header',
		defaultMessage: "You've got no fields to add",
		description: 'Header of the empty global fields screen',
	},
	emptyScreenDescription: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.empty-screen-description',
		defaultMessage:
			"You can't add global fields to the project because your Jira administrator hasn't created any global field yet.",
		description: 'Description of the empty global fields screen',
	},
	createGlobalField: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.create-global-field',
		defaultMessage: 'Create global field',
		description: 'Label of Create global field button',
	},
	contactAdminToCreateGlobalField: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.contact-admin-to-create-global-field',
		defaultMessage: 'Contact your Jira administrator to create a global field.',
		description: 'A hint for Project admin about how to create a global field',
	},
	allFieldsAddedScreenHeader: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.all-fields-added-screen-header',
		defaultMessage: 'All global fields are in use',
		description: 'Header of the Add global fields added screen',
	},
	allFieldsAddedDescription: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.all-fields-added-description',
		defaultMessage:
			'You can’t add global fields to this project because all fields have already been added.',
		description: 'Description of the Add global fields added screen',
	},
	allFieldsAddedHint: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.empty-screen.all-fields-added-hint',
		defaultMessage: 'Contact your Jira administrator to create a global field.',
		description: 'A hint for Project admin about how to create a global field',
	},
});
