import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noValueOption: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.options.renderers.user.no-value-option',
		defaultMessage: 'Unassigned',
		description: 'Special option to filter for ideas that have no user set',
	},
	unknownUser: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.options.renderers.user.unknown-user',
		defaultMessage: 'Unknown',
		description: 'Default text for an unknown user entity (error/edge case)',
	},
});
