import type { ReactNode } from 'react';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { UpdateInsightHandler } from '@atlassian/jira-polaris-common/src/controllers/context/types';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { Label } from '@atlassian/jira-polaris-domain-insight/src/label/types.tsx';
import type { InsightsRemote } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type {
	CloudId,
	ProjectId,
	IssueId,
	IssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';

type Meta = {
	issueId?: IssueId;
	issueKey: IssueKey;
	projectId: ProjectId;
	cloudId: CloudId;
};

type SharedProps = Meta & {
	isVisible: boolean;
	isCollectionView: boolean;
	insights: Insight[];
	snippetProviders: SnippetProvider[];
	playIdsToAris: Record<string, string>;
	playContributions: {
		[key: string]: PolarisPlayContribution[];
	};
	insightsRemote: InsightsRemote;
	issuesRemote: IssuesRemote;
	onCreateFailed: (arg1: Error) => void;
	onUpdateFailed: (arg1: Error) => void;
	onDeleteFailed: (arg1: Error) => void;
	onCopyInsightsFailed: (arg1: Error) => void;
	onOpenIdeaView?: (issueKey: IssueKey) => void;
	hasNoProjectPermissions: boolean;
};

export type Props = SharedProps & {
	onUpdateInsight?: UpdateInsightHandler;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

export type PropsExternal = SharedProps & {
	isSharedView: boolean;
	children: ReactNode;
};

export const InsightsActionModes = {
	COPY: 'copy',
	COPY_IN_PROGRESS: 'copy_in_progress',
	COPY_SUCCESS: 'copy_success',
} as const;

export type InsightsAction =
	| {
			mode: typeof InsightsActionModes.COPY;
	  }
	| {
			mode: typeof InsightsActionModes.COPY_IN_PROGRESS;
	  }
	| {
			mode: typeof InsightsActionModes.COPY_SUCCESS;
			meta: {
				projectKey: string;
				issueKey: string;
			};
	  }
	| undefined;

export type InsightMap = {
	[key: string]: Insight;
};

export type PlayContributionMap = {
	[key: string]: PolarisPlayContribution[];
};

export type State = {
	meta: Meta | undefined;
	loading: boolean;
	error: Error | undefined;
	isRateLimitError: boolean;
	insights: InsightMap;
	playIdsToAris: Record<string, string>;
	playContributions: PlayContributionMap;
	labels: Label[];
	selectedInsights: {
		[insight: string]: boolean;
	};
	activeAction: InsightsAction;
};

export const initialState: State = {
	loading: false,
	error: undefined,
	isRateLimitError: false,
	insights: {},
	labels: [],
	meta: undefined,
	selectedInsights: {},
	playIdsToAris: {},
	playContributions: {},
	activeAction: undefined,
};
