import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { log } from '@atlassian/jira-common-util-logging';
import { ff } from '@atlassian/jira-feature-flagging';
import { FetchError, isHttpClientErrorResponse } from '@atlassian/jira-fetch';
import { useFlagsService } from '@atlassian/jira-flags';
import { commentActivityItem } from '@atlassian/jira-issue-activity-feed/src/constants';
import { type IssueScrollActions, SCROLL_TARGET_TYPES } from '@atlassian/jira-issue-scroll/src';
import type { Action } from '@atlassian/jira-issue-view-actions';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics';
import {
	NUM_PAGED_ITEMS_TO_LOAD,
	NUM_INITIAL_ITEMS_TO_LOAD,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import { commentExperienceDescription } from '@atlassian/jira-issue-view-common-utils/src/experience-tracking/comment-experience-tracking';
import flagMessages from '@atlassian/jira-issue-view-common/src/messages/flags';
import {
	fetchMoreCommentsFromGira,
	fetchSortedCommentsFromGira,
	fetchFocusedCommentFromGira,
} from '@atlassian/jira-issue-view-services/src/issue/comment-fetch-server';
import { setInitialSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions';
import {
	fetchMoreCommentsSuccess,
	fetchMoreCommentsFailure,
	FETCH_OLDER_COMMENTS_REQUEST,
	FETCH_NEWER_COMMENTS_REQUEST,
	setCommentScrollStatus,
	FETCH_SORTED_COMMENTS_REQUEST,
	type FetchSortedCommentsRequestAction,
	fetchSortedCommentsSuccess,
	fetchSortedCommentsFailure,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { SCROLL_TO_ATTACHMENT_COMMENT } from '@atlassian/jira-issue-view-store/src/actions/issue-scroll-actions';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	totalCommentsSelector,
	loadedCommentsSelector,
	startIndexCommentsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector';
import { PermalinkType, getUrlPermalinkParameter } from '@atlassian/jira-platform-issue-permalinks';

const LOG_LOCATION = 'issue.comment.fetch.epic';
const LOG_MESSAGE = 'Failed to fetch comments';
const filter4xxError = (error: Error | FetchError) =>
	!(
		ff('ken-429-restrict-4xx-error_vi8v7') &&
		error instanceof FetchError &&
		isHttpClientErrorResponse(error)
	);
export const getSortedCommentMaxResultsAndStartAt = (
	type: string,
	commentsStartIndex: number,
	totalComments: number,
	loadedComments: number,
): {
	maxResults: number;
	startAt: number;
} => {
	let maxResults = 0;
	let startFetchingFrom = 0;

	if (type === FETCH_OLDER_COMMENTS_REQUEST) {
		startFetchingFrom = commentsStartIndex + loadedComments;
		return {
			maxResults: Math.min(totalComments - startFetchingFrom, NUM_PAGED_ITEMS_TO_LOAD),
			startAt: startFetchingFrom,
		};
	}

	startFetchingFrom = Math.max(0, commentsStartIndex - NUM_PAGED_ITEMS_TO_LOAD);
	maxResults = commentsStartIndex - startFetchingFrom;

	return { maxResults, startAt: startFetchingFrom };
};

export const fetchCommentsRequest = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(FETCH_OLDER_COMMENTS_REQUEST, FETCH_NEWER_COMMENTS_REQUEST)
		.switchMap(({ type }) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);
			const totalComments = totalCommentsSelector(state);
			const loadedComments = loadedCommentsSelector(state);
			const commentsStartIndex = startIndexCommentsSelector(state);

			const { startAt, maxResults } = getSortedCommentMaxResultsAndStartAt(
				type,
				commentsStartIndex,
				totalComments,
				loadedComments,
			);
			return fetchMoreCommentsFromGira({
				baseUrl,
				issueKey,
				maxResults,
				startAt,
				loadedComments,
				commentsStartIndex,
				type,
			})
				.map((comments) => fetchMoreCommentsSuccess(comments))
				.do(() => {
					sendExperienceAnalytics(
						commentExperienceDescription({
							wasSuccessful: true,
							action: 'FETCH_MORE',
							analyticsSource: 'commentFetchEpic',
							projectType: state.entities.project?.projectType,
						}),
					);
				})
				.catch((error: Error | FetchError) => {
					trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
					log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
					if (filter4xxError(error)) {
						sendExperienceAnalytics(
							commentExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_MORE',
								analyticsSource: 'commentFetchEpic',
								projectType: state.entities.project?.projectType,
							}),
						);
					}
					return Observable.of(fetchMoreCommentsFailure());
				});
		});

export const fetchSortedCommentsRequest = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(action$.ofType(FETCH_SORTED_COMMENTS_REQUEST) as Observable<FetchSortedCommentsRequestAction>)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.switchMap(({ payload: commentId }) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);

			if (commentId !== undefined) {
				return fetchFocusedCommentFromGira({
					baseUrl,
					issueKey,
					commentAmount: Math.floor(NUM_INITIAL_ITEMS_TO_LOAD),
					commentId,
				})
					.map((comments) => fetchSortedCommentsSuccess(comments))
					.do(() => {
						sendExperienceAnalytics(
							commentExperienceDescription({
								wasSuccessful: true,
								action: 'FETCH_SORTED',
								analyticsSource: 'commentFetchEpic',
								projectType: state.entities.project?.projectType,
							}),
						);
					})
					.catch((error: Error | FetchError) => {
						trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
						log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
						if (filter4xxError(error)) {
							sendExperienceAnalytics(
								commentExperienceDescription({
									wasSuccessful: false,
									action: 'FETCH_SORTED',
									analyticsSource: 'commentFetchEpic',
									projectType: state.entities.project?.projectType,
								}),
							);
						}
						return Observable.of(fetchSortedCommentsFailure());
					});
			}

			return fetchSortedCommentsFromGira({
				baseUrl,
				issueKey,
				maxResults: NUM_INITIAL_ITEMS_TO_LOAD,
			})
				.map((comments) => fetchSortedCommentsSuccess(comments))
				.do(() => {
					sendExperienceAnalytics(
						commentExperienceDescription({
							wasSuccessful: true,
							action: 'FETCH_SORTED',
							analyticsSource: 'commentFetchEpic',
							projectType: state.entities.project?.projectType,
						}),
					);
				})
				.catch((error: Error | FetchError) => {
					trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
					log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
					if (filter4xxError(error)) {
						sendExperienceAnalytics(
							commentExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_SORTED',
								analyticsSource: 'commentFetchEpic',
								projectType: state.entities.project?.projectType,
							}),
						);
					}
					return Observable.of(fetchSortedCommentsFailure());
				});
		});

export const fetchAttachmentCommentRequest =
	(issueScrollAction: IssueScrollActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$.ofType(SCROLL_TO_ATTACHMENT_COMMENT).switchMap(() => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const issueKey = issueKeySelector(state);
			const commentId = issueScrollAction.getScrollTargetId(SCROLL_TARGET_TYPES.COMMENT);

			// the store does not contain the right scroll target. Abort fetch call to backend
			if (commentId === null) {
				return Observable.of();
			}

			return fetchFocusedCommentFromGira({
				baseUrl,
				issueKey,
				commentAmount: Math.floor(NUM_INITIAL_ITEMS_TO_LOAD),
				commentId,
			})
				.flatMap((comments) => {
					// modify the url, so that specific commentId can scroll within comment-view.js
					window.history.replaceState(
						{},
						// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.
						null,
						getUrlPermalinkParameter(commentId, PermalinkType.COMMENTS),
					);
					issueScrollAction.setScrollTarget(null);

					sendExperienceAnalytics(
						commentExperienceDescription({
							wasSuccessful: true,
							action: 'FETCH_SURROUNDING',
							analyticsSource: 'commentFetchEpic',
							projectType: state.entities.project?.projectType,
						}),
					);

					return Observable.of(
						fetchSortedCommentsSuccess(comments),
						// @ts-expect-error - TS2769 - No overload matches this call.
						setInitialSelectedActivityItem(commentActivityItem),
						setCommentScrollStatus(false),
					);
				})
				.catch((error: Error | FetchError) => {
					trackOrLogClientError(LOG_LOCATION, LOG_MESSAGE, error);
					log.safeWarnWithoutCustomerData(LOG_LOCATION, LOG_MESSAGE);
					const { showFlag } = useFlagsService();
					const field = 'comment';
					showFlag({
						type: 'error',
						title: [flagMessages.commentWorklogAttachmentFetchFailedTitle, { field }],
						description: flagMessages.commentWorklogAttachmentFetchFailedDescription,
						isAutoDismiss: true,
					});
					if (filter4xxError(error)) {
						sendExperienceAnalytics(
							commentExperienceDescription({
								wasSuccessful: false,
								action: 'FETCH_SURROUNDING',
								analyticsSource: 'commentFetchEpic',
								projectType: state.entities.project?.projectType,
							}),
						);
					}
					return Observable.of();
				});
		});
