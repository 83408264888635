import React, { useState } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import { LoadingButton } from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useNextValidAddValue } from '../../common/utils/add-value-generator';
import messages from './messages';

type CreateOptionEntryProps = {
	existingOptionValues: string[];
	setCreatedId: (arg1: string | undefined) => void;
	inputValue: string | undefined;
	isDisabled?: boolean;
	onAddOption?: (arg1: string) => Promise<string | undefined>;
	onOptionAdded: () => void;
};

export const CreateOptionEntry = ({
	existingOptionValues,
	setCreatedId,
	inputValue,
	isDisabled,
	onAddOption,
	onOptionAdded,
}: CreateOptionEntryProps) => {
	const { formatMessage } = useIntl();
	const [inCreation, setInCreation] = useState(false);
	const { value, isCustom } = useNextValidAddValue(inputValue, existingOptionValues);

	return (
		<CreateEntryWrapper>
			<LoadingButton
				testId="polaris-lib-option-utils.ui.option-entry"
				onClick={() => {
					if (onAddOption !== undefined) {
						setInCreation(true);
						onAddOption(value).then((newId) => {
							setInCreation(false);
							setCreatedId(newId);
							// needs to be deferred otherwise the input focus handling
							// on the popup will "steal" the scroll operation
							defer(onOptionAdded);
						});
					}
				}}
				iconBefore={<EditorAddIcon label="add" />}
				appearance="subtle"
				isDisabled={isDisabled}
				isLoading={inCreation}
			>
				{isCustom
					? formatMessage(messages.addValueBasedOnSearch, { value })
					: formatMessage(messages.addValue)}
			</LoadingButton>
		</CreateEntryWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateEntryWrapper = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		paddingLeft: 0,
		paddingRight: token('space.100', '8px'),
		'&:hover': {
			backgroundColor: token('color.background.neutral.subtle.hovered', N30),
		},
	},
});
