import React from 'react';
import { BaseUiModificationsContainer } from '@atlassian/jira-issue-adjustments';
import type { ContainerComponent } from '@atlassian/react-sweet-state';
import { ISSUE_VIEW_VIEW_TYPE } from '../../common/constants';
import { IssueViewViewConfiguration } from '../../common/utils/configuration';

export const UiModificationsContainer: ContainerComponent<unknown> = ({ children, scope }) => (
	<BaseUiModificationsContainer
		scope={`${ISSUE_VIEW_VIEW_TYPE}-${scope}`}
		viewType={ISSUE_VIEW_VIEW_TYPE}
		viewConfiguration={IssueViewViewConfiguration}
	>
		{children}
	</BaseUiModificationsContainer>
);
