import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';

const GROUP_BY_SUPPORTED_FIELD_TYPES = [
	FIELD_TYPES.NUMBER,
	FIELD_TYPES.SLIDER,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.RATING,
	FIELD_TYPES.CHECKBOX,
	FIELD_TYPES.LABELS,
	FIELD_TYPES.CUSTOM_LABELS,
	FIELD_TYPES.ASSIGNEE,
	FIELD_TYPES.CREATOR,
	FIELD_TYPES.REPORTER,
	FIELD_TYPES.PEOPLE,
	FIELD_TYPES.JSW_PEOPLE,
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,
	FIELD_TYPES.STATUS,
	FIELD_TYPES.ISSUE_TYPE,
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,
	FIELD_TYPES.REACTIONS,
	FIELD_TYPES.PROJECT,
] as const satisfies FieldType[];

export type GroupBySupportedFieldTypes = (typeof GROUP_BY_SUPPORTED_FIELD_TYPES)[number];

export const isGroupBySupportedFieldType = (fieldType?: FieldType) =>
	fieldType !== undefined && GROUP_BY_SUPPORTED_FIELD_TYPES.some((item) => item === fieldType);
