import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { ExternalReferenceField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldProps } from '../select';
import messages from './messages';

export const ExternalReference = ({ fieldKey, localIssueId, menuPortalTarget }: FieldProps) => {
	const { formatMessage } = useIntl();
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	const onUpdate = useCallback(
		(newValue: string | string[] | undefined) => {
			updateFieldValue({
				fieldKey,
				localIssueIds: [localIssueId],
				newValue,
			});
		},
		[fieldKey, localIssueId, updateFieldValue],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<ExternalReferenceField
			isActive
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			menuPortalTarget={menuPortalTarget}
			isEditable={canEditIssues && field.editable}
			onUpdate={onUpdate}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
