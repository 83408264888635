import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AddFieldOptionRequest } from './types';

export const addFieldOption = ({
	projectId,
	issueTypeId,
	fieldKey,
	newOption,
}: AddFieldOptionRequest): Promise<number> =>
	performPostRequest(`/rest/polaris/v2/projects/${projectId}/fields/${fieldKey}/options`, {
		body: JSON.stringify({
			issueTypeId: +issueTypeId,
			value: newOption,
		}),
	});
