import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export const SelectionRowSkeleton = () => <Skeleton height="20px" />;

export const GlobalFieldsSkeleton = () => (
	<>
		{Array.from({ length: 6 }).map((_, index) => (
			<Box xcss={skeletorRowStyles} key={index}>
				<SelectionRowSkeleton />
			</Box>
		))}
	</>
);

const skeletorRowStyles = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.150',
});
