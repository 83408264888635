import React, { useCallback, useRef, useState } from 'react';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/glyph/add';
import { useIntl } from '@atlassian/jira-intl';
import { SearchableDropdown } from '@atlassian/jira-polaris-common/src/ui/common/decoration/searchable-dropdown';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import messages from './messages';

type Props = {
	options: Field[];
	onAddFilter: (value: FieldKey | undefined) => void;
};

export const AddFilterComponent = ({ options, onAddFilter }: Props) => {
	const { formatMessage } = useIntl();
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={(target) => {
				if (buttonRef.current && !buttonRef.current.contains(target)) {
					setDialogOpen(false);
				}
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={
				<SearchableDropdown
					options={options.map(({ key, label }) => ({ key, label }))}
					onSelect={(fieldKey) => {
						onAddFilter(fieldKey);
						setDialogOpen(false);
					}}
					onClose={() => setDialogOpen(false)}
				/>
			}
		>
			<Button
				testId="polaris-ideas.ui.view-controls.config-filters.add.button"
				ref={buttonRef}
				isSelected={dialogOpen}
				onClick={toggleDialog}
				iconBefore={(iconProps) => <AddIcon size="small" {...iconProps} />}
			>
				{formatMessage(messages.addFilter)}
			</Button>
		</PolarisInlineDialog>
	);
};
