import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Components } from '../../../types';
import type { State } from '../../types';

export const updateComponentsState =
	(components?: Components) =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			components,
		});
	};
