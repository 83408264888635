import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../../pendo';

export const IdeaView = {
	copyIdeaLinkButtonClicked: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47650
		fireUIAnalytics(event, 'button clicked', 'copyIdeaLinkButton');
	},
	copyIdeaCommentLinkButtonClicked: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/48687
		fireUIAnalytics(event, 'button clicked', 'copyIdeaCommentLinkButton');
	},
	copyIdeaInsightLinkButtonClicked: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/50374
		fireUIAnalytics(event, 'icon clicked', 'copyIdeaInsightLink');
	},
	viewed: (event: UIAnalyticsEvent) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/n5034S7jQww7n-LViD6ZpHEP3xM
		sendPendoTrackEvent('idea_opened');

		// Fullscreen: https://data-portal.internal.atlassian.com/analytics/registry/893
		// Sidebar: https://data-portal.internal.atlassian.com/analytics/registry/51086
		fireScreenAnalytics(event);
	},
	tabClicked: (event: UIAnalyticsEvent, issueViewSection: string) => {
		let actionSubjectId: string = issueViewSection;

		if (issueViewSection === 'capture') {
			actionSubjectId = 'insights';
		} else if (issueViewSection === 'deliver') {
			actionSubjectId = 'delivery';
		}

		fireUIAnalytics(event, 'tab clicked', actionSubjectId);
	},
	DeliveryTab: {
		createDeliveryTicketButtonClicked: (event: UIAnalyticsEvent, isFromHint = false) => {
			sendPendoTrackEvent('ideaViewDeliveryCreateDeliveryTicketButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/50485
			fireUIAnalytics(event, 'button clicked', 'createDeliveryTicket', { isFromHint });
		},
		linkDeliveryTicketButtonClicked: (event: UIAnalyticsEvent, isFromHint = false) => {
			sendPendoTrackEvent('ideaViewDeliveryLinkDeliveryTicketButton clicked');

			// https://data-portal.internal.atlassian.com/analytics/registry/50507
			fireUIAnalytics(event, 'button clicked', 'addDeliveryTicket', { isFromHint });
		},
		deliveryTicketsHintClosed: (event: UIAnalyticsEvent) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50733
			fireUIAnalytics(event, 'button clicked', 'closeDeliveryTicketsHint');
		},
		learnMoreButtonClicked: (event: UIAnalyticsEvent, isFromHint = false) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50732
			fireUIAnalytics(event, 'button clicked', 'learnMoreAboutDeliveryTickets', {
				isFromHint,
			});
		},
	},
	InsightsTab: {
		createInsightButtonClicked: (event: UIAnalyticsEvent, isFromHint = false) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50336
			fireUIAnalytics(event, 'button clicked', 'createInsight', { isFromHint });
		},
		createInsightHintClosed: (event: UIAnalyticsEvent) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50739
			fireUIAnalytics(event, 'button clicked', 'closeCreateInsightsHint');
		},
		createInsightLearnMoreButtonClicked: (event: UIAnalyticsEvent, isFromHint = false) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50738
			fireUIAnalytics(event, 'button clicked', 'learnMoreCreateInsightsTickets', {
				isFromHint,
			});
		},
		addLinkToInsightsHintClosed: (event: UIAnalyticsEvent) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50741
			fireUIAnalytics(event, 'button clicked', 'closeAddLinkToInsightsHint');
		},
		addLinkToInsightsLearnMoreButtonClicked: (event: UIAnalyticsEvent, isFromHint = false) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/50740
			fireUIAnalytics(event, 'button clicked', 'learnMoreAddLinkToInsights', {
				isFromHint,
			});
		},
	},
};
