import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	open: {
		id: 'polaris-component-view-access.access-screen.access-level-select.open',
		defaultMessage: 'Open',
		description: '"Open" option label for setting a specific view permission level',
	},
	limited: {
		id: 'polaris-component-view-access.access-screen.access-level-select.limited',
		defaultMessage: 'Limited',
		description: '"Limited" option label for setting a specific view permission level',
	},
	restricted: {
		id: 'polaris-component-view-access.access-screen.access-level-select.restricted',
		defaultMessage: 'Restricted',
		description: '"Restricted" option label for setting a specific view permission level',
	},
	openOptionDescription: {
		id: 'polaris-component-view-access.access-screen.access-level-select.open-option-description',
		defaultMessage: 'Everyone in the project can view. Creators can edit.',
		description: 'Open option description that appears below it',
	},
	limitedOptionDescription: {
		id: 'polaris-component-view-access.access-screen.access-level-select.limited-option-description',
		defaultMessage: 'Everyone in the project can view. Select users can edit.',
		description: 'Limited option description that appears below it',
	},
	restrictedOptionDescription: {
		id: 'polaris-component-view-access.access-screen.access-level-select.restricted-option-description',
		defaultMessage: 'Only select users can view or edit.',
		description: 'Restricted option description that appears below it',
	},
});
