import type { Action } from '@atlassian/react-sweet-state';
import { setEnforcePermissionChecksOnSiteEnabled } from '../../../services/jira/toggle-enforce-permission-checks';
import type { Props, State } from '../types';

export const toggleEnforcePermissionChecks =
	(): Action<State, Props> =>
	async ({ getState, setState }) => {
		const { editionMeta } = getState();
		const { isEnforcePermissionChecksEnabledOnSite } = editionMeta;

		setState({
			editionMeta: {
				...getState().editionMeta,
				isEnforcePermissionChecksEnabledOnSite: !isEnforcePermissionChecksEnabledOnSite,
			},
		});

		setEnforcePermissionChecksOnSiteEnabled(!isEnforcePermissionChecksEnabledOnSite).catch(() => {
			setState({
				editionMeta: {
					...getState().editionMeta,
					isEnforcePermissionChecksEnabledOnSite,
				},
			});
		});
	};
