import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addValue: {
		id: 'polaris-common.common.decoration.select.menu-list.add-value',
		defaultMessage: 'Add option',
		description: 'Label for option to add a new value in the value selection dropdown',
	},
	enterKey: {
		id: 'polaris-common.common.decoration.select.menu-list.enter-key',
		defaultMessage: 'Enter',
		description: 'Enter keyboard key label',
	},
	addValueBasedOnSearch: {
		id: 'polaris-common.common.decoration.select.menu-list.add-value-based-on-search',
		defaultMessage: 'Create "{value}"',
		description: 'Label for option to add a new value for the current search string',
	},
	newValue: {
		id: 'polaris-common.common.decoration.select.menu-list.new-value',
		defaultMessage: 'New option',
		description:
			'New value prefix to be used to create additional new values, e.g. New Value, New Value 2, New Value 3, etc.',
	},
	newValueNonFinal: {
		id: 'polaris-common.common.decoration.select.menu-list.new-value-non-final',
		defaultMessage: 'A new value',
		description:
			'New value prefix to be used to create additional new values, e.g. A new value, A new value 2, etc.',
	},
	editField: {
		id: 'polaris-common.common.decoration.select.menu-list.edit-field',
		defaultMessage: 'Edit field',
		description: 'Button to edit the currently open field',
	},
	fieldSettings: {
		id: 'polaris-common.common.decoration.select.menu-list.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button to open settings for the currently open field',
	},
	searchHint: {
		id: 'polaris-common.common.decoration.select.menu-list.search-hint',
		defaultMessage: 'Adjust the search to display more options',
		description: 'Search hint displayed for users to see more global labels',
	},
});
