import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { JiraFieldMapping } from '../types';

export const jiraDocumentMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<DocumentFieldValue> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key] ?? undefined,
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	getJiraUpdateService: (issueKey, value) =>
		issuesRemote.updateIssueDescription({
			issueKey,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			description: value as DocumentFieldValue,
		}),
});
