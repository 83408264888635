import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	copyToClipboard: {
		id: 'polaris-lib-copy-to-clipboard.copy-to-clipboard',
		defaultMessage: 'Copy to clipboard',
		description:
			'Tooltip text when hovering field name in polaris delete field confirmation dialog to copy the field name into the clipboard',
	},
	copiedMessage: {
		id: 'polaris-lib-copy-to-clipboard.copied-message',
		defaultMessage: 'Copied',
		description:
			'Tooltip text when clicked on field name in polaris delete field confirmation dialog to copy the field label into the clipboard',
	},
});
