import {
	performDeleteRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { CollectionView, ViewResponse } from '@atlassian/jira-polaris-domain-collection';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { ViewToCreate } from './types';
import { transformView } from './utils';

export const createView = (
	collectionUUID: string,
	viewToCreate: ViewToCreate,
): Promise<CollectionView> =>
	performPostRequest<ViewResponse>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views`,
		{
			body: JSON.stringify(viewToCreate),
		},
	).then(transformView);

export const deleteView = async (collectionUUID: string, viewUUID: string): Promise<void> => {
	await performDeleteRequest<ViewResponse>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}`,
	);
};
