import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addNotification: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add-notification',
		defaultMessage: 'Add notification',
		description: 'Add notification header',
	},
	addNotificationPrompt: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add-notification-prompt',
		defaultMessage: 'Users will be notified the next time this event occurs.',
		description: 'Add notification prompt message',
	},
	add: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add',
		defaultMessage: 'Add',
		description: 'Delete button text',
	},
	cancel: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.cancel',
		defaultMessage: 'Cancel',
		description: 'cancel button text',
	},
	addSuccessFlagTitle: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add-success-flag-title',
		defaultMessage: 'Notification added',
		description: 'Notification added flag success message',
	},
	addSuccessFlagDescription: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add-success-flag-description',
		defaultMessage: 'Your notification has been added.',
		description: 'Notification added flag success message description',
	},
	addFailureFlagTitle: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add-failure-flag-title',
		defaultMessage: 'Unable to add notification',
		description: 'Notification added flag failure message',
	},
	addFailureFlagDescription: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.add-failure-flag-description',
		defaultMessage:
			'We were unable to add your notification. Please wait a moment and refresh to try again.',
		description: 'Notification added flag failure message description',
	},
	selectEventType: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.select-event-type',
		defaultMessage: 'Select Event',
		description: 'event selection placeholder',
	},
	selectEventTypeLabel: {
		id: 'spa-apps.project-settings.notifications-common.common.add-notification.select-event-type-label',
		defaultMessage: 'When this happens',
		description: 'event selection select field label',
	},
});
