/** @jsx jsx */
import React, { useCallback, useState, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import find from 'lodash/find';
import Button from '@atlaskit/button/new';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { EmojiWrapper } from '@atlassian/jira-polaris-common/src/ui/field-config/item/emoji-wrapper';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { SelectFilterContentComponent, type SelectOption } from './content';
import messages from './messages';
import { ReactionsFirstFilterLabel } from './reactions-label';

type Props = {
	isLastFilter: boolean;
	label: string;
	fieldType?: FieldType;
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
	options: SelectOption[];
	selected: (string | undefined)[];
	defaultOpen?: boolean;
	onChange: (value: (string | undefined)[]) => void;
	isDisabled?: boolean;
	isTemporary?: boolean;
};

const FilterButtonLabel = ({
	label,
	fieldType,
	fieldTypeIcon,
	emoji,
	options,
	selected,
}: {
	label: string;
	fieldType?: FieldType;
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
	options: SelectOption[];
	selected: (string | undefined)[];
}) => {
	const { formatMessage } = useIntl();

	const firstSelection =
		selected.length > 0 ? find(options, ({ id }) => id === selected[0]) : undefined;

	if (firstSelection === undefined) {
		return (
			<Flex alignItems="center">
				{emoji ? (
					<EmojiWrapper>
						<Emoji emoji={emoji} fitToHeight={16} showTooltip />
					</EmojiWrapper>
				) : (
					<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
				)}
				{label}
			</Flex>
		);
	}

	const more = selected.length - 1;

	return (
		<Flex alignItems="center">
			{emoji ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
			)}
			<div css={labelStyles}>{label}</div>
			{fieldType === FIELD_TYPES.REACTIONS ? (
				<ReactionsFirstFilterLabel labelId={firstSelection.id} />
			) : (
				<Box xcss={filterLabelFirstItemStyles}>
					{firstSelection.previewLabel !== undefined
						? firstSelection.previewLabel
						: firstSelection.label}
				</Box>
			)}
			{more !== 0 && (
				<Box paddingInlineStart="space.050">
					{formatMessage(messages.moreOptionsSelectedIndicator, { number: more })}
				</Box>
			)}
		</Flex>
	);
};

export const SelectFilterComponent = ({
	isLastFilter,
	defaultOpen,
	label,
	fieldTypeIcon,
	emoji,
	options,
	selected,
	onChange,
	fieldType,
	isDisabled = false,
	isTemporary = false,
}: Props) => {
	const [dialogOpen, setDialogOpen] = useState(defaultOpen || (isLastFilter && !selected.length));
	const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

	return (
		<PolarisInlineDialog
			noPadding
			onClose={() => {
				setDialogOpen(false);
			}}
			isOpen={dialogOpen}
			placement="bottom-start"
			content={
				<SelectFilterContentComponent options={options} selected={selected} onChange={onChange} />
			}
		>
			<div css={[isTemporary && buttonWrapperStyles]}>
				<Button
					isSelected={dialogOpen}
					onClick={toggleDialog}
					iconAfter={(iconProps) => <ChevronDownIcon {...iconProps} />}
					isDisabled={isDisabled}
				>
					<FilterButtonLabel
						selected={selected}
						options={options}
						label={label}
						fieldType={fieldType}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
					/>
				</Button>
			</div>
		</PolarisInlineDialog>
	);
};

const buttonWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	button: {
		background: token('color.background.warning'),
		color: token('color.text.accent.yellow.bolder'),
	},
});

const iconContainerStyles = xcss({
	paddingInlineEnd: 'space.100',
	alignItems: 'center',
});

const filterLabelFirstItemStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const labelStyles = css({
	fontWeight: token('font.weight.bold'),
});
