import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ProjectField } from '@atlassian/jira-polaris-common/src/ui/fields/project';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { NoValueContainer } from '../../../../common/board-header-column/styled';
import type { ColumnHeaderComponentProps } from '../types';
import messages from './messages';

const ProjectColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const projectMeta = useProjectMetadata({ projectId: groupIdentity });

	return <ProjectField project={projectMeta} />;
};

const EmptyProjectColumnHeader = () => {
	const { formatMessage } = useIntl();
	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};
export const projectColumnHeaderConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: ProjectColumnHeader,
	EmptyComponent: EmptyProjectColumnHeader,
} as const;
