import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.title',
		defaultMessage: 'Capture insights',
		description: 'Hint title',
	},
	description: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.description',
		defaultMessage:
			'You can bring qualitative and quantitative data points such as quotes, images, and conversations into an idea from multiple apps or manually as an insight.',
		description: 'Hint description',
	},
	learnMoreLink: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.learn-more-link',
		defaultMessage: 'Learn more about insights',
		description: 'Link capture to open help panel with article about insights',
	},
	addInsightButton: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.add-insight-button',
		defaultMessage: 'Create an insight',
		description: 'Button caption for create an insight button',
	},
	addInsightHint: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.add-insight-hint',
		defaultMessage: 'or use the {chrome}, {slack} or {teams}',
		description: 'Button caption for create an insight button',
	},
	chrome: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.chrome',
		defaultMessage: 'Chrome extension',
		description: 'Chrome extension link',
	},
	slack: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.slack',
		defaultMessage: 'Slack app',
		description: 'Slack link',
	},
	teams: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.teams',
		defaultMessage: 'Microsoft Teams app',
		description: 'Teams link',
	},
});
