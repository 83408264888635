import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { noopJiraUpdateService } from '../common';
import { jiraNumberMapping } from '../number';
import type { JiraFieldMapping } from '../types';

export const jiraIssueIdMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<number> => {
	const baseMapping = jiraNumberMapping(issuesRemote, field);
	return {
		field,
		getValueFromJiraIssue: (issue) => Number(issue.id),
		getFieldValueForJiraUpdate: baseMapping.getFieldValueForJiraUpdate,
		isMultiValueField: false,
		isSupportedByIssueUpdateApi: false,
		...noopJiraUpdateService,
	};
};
