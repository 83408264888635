import type { AccountId } from '@atlassian/jira-shared-types';

export const ATTACHMENT_PARENT = {
	comment: 'comment',
	worklog: 'worklog',
	description: 'description',
	environment: 'environment',
	customfield: 'customfield',
	issue: 'issue',
	form: 'form',
} as const;

export type AttachmentParent = keyof typeof ATTACHMENT_PARENT;

export type Attachment = {
	id: string;
	filename: string;
	mimetype?: string;
	mediaApiFileId: string;
	createdDate: number;
	filesize: number;
	parentName: AttachmentParent;
	parentId: string;
	author: {
		accountId: AccountId;
	} | null;
	isParentCommentJsdInternal: boolean;
	hasRestrictedParent: boolean;
};
