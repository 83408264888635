import { archiveInsights as archiveInsightsCall } from '../../services/polaris-api/archive-insights';
import { unarchiveInsights as unarchiveInsightsCall } from '../../services/polaris-api/unarchive-insights';
import type {
	ArchiveInsightsRequest,
	ProviderProps,
	RemoteInsightsArchiver,
	RemoteInsightsUnarchiver,
	UnarchiveInsightsRequest,
} from '../../types';

export const archiveInsights =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteInsightsArchiver =>
	({ issueIds }: ArchiveInsightsRequest) =>
		archiveInsightsCall(apolloClient, cloudId, projectId, issueIds);

export const unarchiveInsights =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteInsightsUnarchiver =>
	({ issueIds }: UnarchiveInsightsRequest) =>
		unarchiveInsightsCall(apolloClient, cloudId, projectId, issueIds);
