import type { EmailValidator } from '@atlaskit/user-picker';

// Email Validation
// Extends default validation found in
// https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/elements/user-picker/src/components/emailValidation.ts
// by checking that the domain does not end in a symbol

const validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]*[^\s@!#$%&'*+-/=?^_`{|}~"().,:;<>\\[\]]$/i;
const potentialRegex = /^[^\s@]+@[^\s@]*$/i;

export const isValidEmail: EmailValidator = (inputText: string) => {
	if (inputText.match(validRegex)) {
		return 'VALID';
	}
	if (inputText.match(potentialRegex)) {
		return 'POTENTIAL';
	}

	return 'INVALID';
};
