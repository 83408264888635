import React, { useCallback } from 'react';
import { ButtonItem, Section } from '@atlaskit/menu';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type ActionsProps = {
	onRename: () => void;
	onDelete?: () => void;
};

export const Actions = (props: ActionsProps) => {
	const { onRename, onDelete } = props;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onRenameHandler = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
			'viewSectionActionItem',
			{
				selectedActionItem: 'rename',
			},
		);
		onRename();
	}, [onRename, createAnalyticsEvent]);

	const onDeleteHandler = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
			'viewSectionActionItem',
			{
				selectedActionItem: 'delete',
			},
		);
		onDelete?.();
	}, [onDelete, createAnalyticsEvent]);

	return (
		<Section>
			<ButtonItem onClick={onRenameHandler}>{formatMessage(messages.rename)}</ButtonItem>
			<Tooltip content={onDelete ? '' : formatMessage(messages.onDeleteTooltip)}>
				<ButtonItem onClick={onDeleteHandler} isDisabled={!onDelete}>
					{formatMessage(messages.delete)}
				</ButtonItem>
			</Tooltip>
		</Section>
	);
};
