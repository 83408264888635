import type { IssueKey } from '@atlassian/jira-shared-types';

// UPDATE_ASSOCIATED_ISSUE_REQUEST
export const UPDATE_ASSOCIATED_ISSUE_REQUEST = 'UPDATE_ASSOCIATED_ISSUE_REQUEST';

export const updateAssociatedIssueRequest = (issueKey?: IssueKey) => ({
	type: UPDATE_ASSOCIATED_ISSUE_REQUEST,
	payload: {
		issueKey,
	},
});

export type UpdateAssociatedIssueRequest = ReturnType<typeof updateAssociatedIssueRequest>;
