import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fetchIssueIssueBatches } from '@atlassian/jira-polaris-remote-issue/src/services/jira/get-issues-rest-bulk/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraSearchApiResponse } from '../get-issues-rest-bulk/types';
import type { JiraSearchApiIssue } from '../types';
import { createStableParentJql } from '../util';
import type { Response, ChildIssueStub } from './types';
import { getFields } from './utils';

const transformIssues = (
	issues: JiraSearchApiIssue[],
	parentKey: IssueKey,
	storyPointsCustomFieldId?: string,
): ChildIssueStub[] =>
	issues.map((issue) => ({
		issueId: issue.id,
		issueKey: issue.key,
		summary: issue.fields.summary,
		status: issue.fields.status,
		issueType: issue.fields.issuetype,
		estimation: storyPointsCustomFieldId ? issue.fields[storyPointsCustomFieldId] : undefined,
		parentKey,
	}));

export const createResponse = (
	result: JiraSearchApiResponse,
	issueKey: IssueKey,
	storyPointsCustomFieldId?: string,
) => ({
	issues: transformIssues(result.issues, issueKey, storyPointsCustomFieldId),
	hitLimit: result.issues.length < result.total,
});

export const EMPTY: Response = {
	issues: [],
	hitLimit: false,
};

export const getChildIssues = async (
	issueKey: IssueKey,
	storyPointsCustomFieldId?: string,
	parentLinkFields?: FieldKey[] | undefined,
): Promise<Response> => {
	const jql = createStableParentJql([issueKey], parentLinkFields || []);
	if (!jql) {
		return Promise.resolve(EMPTY);
	}
	const response = await fetchIssueIssueBatches(
		{ jql },
		getFields(...(parentLinkFields || []), storyPointsCustomFieldId),
		[],
		undefined,
		getFeatureFlagValue<number>('polaris.delivery-child-issues-loading-limit', 500),
	);
	if (response.issues.length < response.total) {
		sendPendoTrackEvent({
			actionSubjectAndAction: 'childIssuesLimit hit',
			attributes: { total: response.total },
		});
	}
	return createResponse(response, issueKey, storyPointsCustomFieldId);
};
