import type { AggJiraIssueTypeHierarchyLevel } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/fields-type.tsx';
import type {
	AggIssue,
	IssueTypesForHierarchy,
} from '@atlassian/jira-issue-agg-field-transformers/src/common/types/issue-type.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	GRAPHQL_HIERARCHY_LEVEL_ABOVE,
	GRAPHQL_HIERARCHY_LEVEL_BELOW,
	GRAPHQL_HIERARCHY_LEVEL_SAME_LEVEL,
	HIERARCHY_LEVEL_ABOVE,
	HIERARCHY_LEVEL_BELOW,
	HIERARCHY_LEVEL_SAME_LEVEL,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { transformAggConnection } from './default-node-transformer';
import {
	getSystemAggJiraIssueType,
	transformJiraIssueTypeToLegacyIssueType,
} from './issue-type-field-transformer';

const getHierarchyLevelFieldTitle = (key: string): string => {
	switch (key) {
		case HIERARCHY_LEVEL_ABOVE:
			return 'graphql.hierarchy.level.above';
		case HIERARCHY_LEVEL_BELOW:
			return 'graphql.hierarchy.level.below';
		case HIERARCHY_LEVEL_SAME_LEVEL:
			return 'graphql.hierarchy.level.same';
		default:
			return '';
	}
};

const transformAggHierarchyLevelToLegacyField = (
	key: string,
	type: string,
	hierarchyLevel?: AggJiraIssueTypeHierarchyLevel | null,
	issueTypesForHierarchyLevel?: IssueTypesForHierarchy | null,
): FieldsState => {
	const transformedIssueTypes = transformAggConnection(
		issueTypesForHierarchyLevel,
		transformJiraIssueTypeToLegacyIssueType,
	);

	if (transformedIssueTypes.length === 0) return {};

	// Check `hierarchyLevelAbove`, `hierarchyLevelBelow`, or `JiraIssueTypeField.issueType.hierarchy`
	if (hierarchyLevel == null) {
		throw new Error(
			`Failed to transform hierarchy level fields: top-level hierarchy info for ${type} is missing.`,
		);
	}

	const issueTypeIds = transformedIssueTypes.map((issueType) => Number(issueType.id));

	return {
		[key]: {
			key,
			title: getHierarchyLevelFieldTitle(key),
			editable: false,
			required: false,
			autoCompleteUrl: null,
			allowedValues: [],
			value: {
				...hierarchyLevel,
				issueTypeIds,
				issueTypes: transformedIssueTypes,
			},
			schema: {
				configuration: null,
				items: null,
				renderer: null,
				type: key,
				system: null,
				custom: type,
			},
		},
	};
};

export const transformAggIssueToHierarchyLegacyFields = (issue: AggIssue): FieldsState => {
	// Hierarchy level info for `HIERARCHY_LEVEL_SAME_LEVEL` lives in `JiraIssueTypeField`
	const issueType = getSystemAggJiraIssueType(issue?.fields?.edges);

	return {
		...transformAggHierarchyLevelToLegacyField(
			HIERARCHY_LEVEL_ABOVE,
			GRAPHQL_HIERARCHY_LEVEL_ABOVE,
			issue?.hierarchyLevelAbove,
			issue?.issueTypesForHierarchyAbove,
		),
		...transformAggHierarchyLevelToLegacyField(
			HIERARCHY_LEVEL_BELOW,
			GRAPHQL_HIERARCHY_LEVEL_BELOW,
			issue?.hierarchyLevelBelow,
			issue?.issueTypesForHierarchyBelow,
		),
		...transformAggHierarchyLevelToLegacyField(
			HIERARCHY_LEVEL_SAME_LEVEL,
			GRAPHQL_HIERARCHY_LEVEL_SAME_LEVEL,
			issueType.hierarchy,
			issue?.issueTypesForHierarchySame,
		),
	};
};
