import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByGroupIdentity } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import { useIdeasInCreation } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	IssueCreateGroupFromLocationTop,
	IssueCreateGroupTypeEmpty,
	IssueCreateGroupTypeSpecified,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types';
import {
	useCanManageCurrentView,
	useCurrentViewVerticalGroupBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { RowGroupRendererComponentProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
// TODO make ColumnHeader a common component (not bound to board)
import { useIsRankingAllowedDuringCreation } from '../../../utils';
import { useExtendedVerticalGroupOptions } from '../../common/utils/vertical-group-options';
import { ColumnHeader, type ColumnHeaderProps } from '../../idea-board/column/header';
import { useCanCreateIdeasWhenGrouping } from '../utils/can-create-ideas';
import { messages } from './messages';

const useIdeaCountOfGroup = (groupId: string | undefined, fieldKey: string | undefined): number => {
	const groupedIds = useLocalIssueIdsByGroupIdentity(fieldKey);
	const ideasInCreation = useIdeasInCreation();

	return useMemo(() => {
		if (ff('polaris.idea-list-row-group-column-header-add-idea')) {
			const ids = groupId !== undefined ? groupedIds.groups[groupId] : groupedIds.empty;
			return ids?.filter((id) => !ideasInCreation.includes(id)).length || 0;
		}

		if (groupId !== undefined) {
			return groupedIds.groups[groupId]?.length || 0;
		}
		return groupedIds.empty?.filter((id: string) => !ideasInCreation.includes(id)).length || 0; // filter idea-ids that are in-creation from no-value groups
	}, [groupId, groupedIds.empty, groupedIds.groups, ideasInCreation]);
};

export const RowGroup = ({
	groupId,
	isExporting,
	onExpandGroup,
}: RowGroupRendererComponentProps) => {
	const { formatMessage } = useIntl();
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const extendedOptions = useExtendedVerticalGroupOptions(verticalGroupByField);
	const canCreateIdeasWhenGrouping = useCanCreateIdeasWhenGrouping({
		isExporting,
		fieldKey: verticalGroupByField?.key,
	});
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();
	const ideaCountOfGroup = useIdeaCountOfGroup(groupId, verticalGroupByField?.key);
	const { createIssue } = useIssueActions();

	const handleAddClick: NonNullable<ColumnHeaderProps['onAddClick']> = useCallback(
		({ groupIdentity, fieldKey }) => {
			onExpandGroup?.();

			const extendedOption = extendedOptions.find(
				(option) => option.groupIdentity === groupIdentity,
			);

			const isEmptyGroup = groupIdentity === '' && fieldKey.length > 0;
			createIssue(
				0,
				undefined,
				isEmptyGroup
					? {
							groupType: IssueCreateGroupTypeEmpty,
							fromLocation: IssueCreateGroupFromLocationTop,
							rankingAllowed: isRankingAllowedDuringCreation,
						}
					: {
							groupType: IssueCreateGroupTypeSpecified,
							groupIdentity,
							fieldKey,
							fieldValue: extendedOption?.value,
							fromLocation: IssueCreateGroupFromLocationTop,
							rankingAllowed: isRankingAllowedDuringCreation,
						},
			);
		},
		[createIssue, extendedOptions, isRankingAllowedDuringCreation, onExpandGroup],
	);

	if (!verticalGroupByField) {
		return null;
	}

	return (
		<ColumnHeader
			isAddVisible={canCreateIdeasWhenGrouping}
			onAddClick={handleAddClick}
			isRow
			/* eslint-disable no-nested-ternary */
			isReadOnly={
				fg('polaris_refactor_table_headers_hover_cursor')
					? true
					: ff('polaris.view-permissions_plaoi')
						? !canManageCurrentView
						: !canManageViews
			}
			/* eslint-enable no-nested-ternary */
			elemAfter={
				ideaCountOfGroup > 0 ? (
					<IssueCountContainer>
						{formatMessage(messages.issuesCountLabel, {
							issuesCount: ideaCountOfGroup,
						})}
					</IssueCountContainer>
				) : null
			}
			fieldKey={verticalGroupByField.key}
			groupIdentity={groupId}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
});
