import {
	createStartAfterNowValueOperator,
	createStartBeforeNowValueOperator,
	createEndBeforeNowValueOperator,
	createEndAfterNowValueOperator,
} from '@atlassian/jira-polaris-domain-view/src/filter/index.tsx';
import {
	type IntervalFieldFilterOperator,
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import messages from './messages';
import {
	type UiFilter,
	INTERVAL_ENDED_VISUALIZATION,
	INTERVAL_ENDS_VISUALIZATION,
	INTERVAL_STARTED_VISUALIZATION,
	INTERVAL_STARTS_VISUALIZATION,
} from './types';

const intervalEndedRelativeFilter: UiFilter = {
	id: INTERVAL_ENDED_VISUALIZATION,
	isChecked: (values: IntervalFieldFilterOperator[]): boolean =>
		values.length === 1 && values[0].operator === END_BEFORE_NOW,
	getValues: (parameter: number | undefined): IntervalFieldFilterOperator[] | undefined => [
		createEndBeforeNowValueOperator(parameter || 0),
	],
	hasParameter: true,
	label: messages.intervalEndedRelativeFilterLabel,
	activeLabel: messages.intervalEndedRelativeFilterActiveLabel,
};

const intervalWillStartRelativeFilter: UiFilter = {
	id: INTERVAL_STARTS_VISUALIZATION,
	isChecked: (values: IntervalFieldFilterOperator[]): boolean =>
		values.length === 1 && values[0].operator === START_AFTER_NOW,
	getValues: (parameter: number | undefined): IntervalFieldFilterOperator[] | undefined => [
		createStartAfterNowValueOperator(parameter || 0),
	],
	hasParameter: true,
	label: messages.intervalWillStartRelativeFilterLabel,
	activeLabel: messages.intervalWillStartRelativeFilterActiveLabel,
};

const intervalStartedRelativeFilter: UiFilter = {
	id: INTERVAL_STARTED_VISUALIZATION,
	isChecked: (values: IntervalFieldFilterOperator[]): boolean =>
		values.length === 1 && values[0].operator === START_BEFORE_NOW,
	getValues: (parameter: number | undefined): IntervalFieldFilterOperator[] | undefined => [
		createStartBeforeNowValueOperator(parameter || 0),
	],
	hasParameter: true,
	label: messages.intervalStartedRelativeFilterLabel,
	activeLabel: messages.intervalStartedRelativeFilterActiveLabel,
};

const intervalWillEndRelativeFilter: UiFilter = {
	id: INTERVAL_ENDS_VISUALIZATION,
	isChecked: (values: IntervalFieldFilterOperator[]): boolean =>
		values.length === 1 && values[0].operator === END_AFTER_NOW,
	getValues: (parameter: number | undefined): IntervalFieldFilterOperator[] | undefined => [
		createEndAfterNowValueOperator(parameter || 0),
	],
	hasParameter: true,
	label: messages.intervalWillEndRelativeFilterLabel,
	activeLabel: messages.intervalWillEndRelativeFilterActiveLabel,
};

export const filters: UiFilter[] = [
	intervalStartedRelativeFilter,
	intervalEndedRelativeFilter,
	intervalWillStartRelativeFilter,
	intervalWillEndRelativeFilter,
];
