import React from 'react';
import { IconButton } from '@atlaskit/button/new';
import { Mention } from '@atlaskit/editor-common/mention';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import TimestampView from '@atlassian/jira-issue-timestamp/src/main.tsx';
import { MentionPlaceholder } from '../../common/ui/placeholder';
import messages from './messages';
import type { UpdateModalProps } from './types';

const HeaderMessage = ({
	latestUpdate,
	isLoadingUpdate,
}: Pick<UpdateModalProps, 'latestUpdate' | 'isLoadingUpdate'>) => {
	const { formatMessage } = useIntl();

	let message: React.ReactNode;

	if (isLoadingUpdate) {
		message = formatMessage(messages.warningLoading, {
			user: <MentionPlaceholder />,
		});
	} else if (!latestUpdate) {
		return null;
	} else {
		message = formatMessage(messages.warning, {
			user: <Mention id={latestUpdate.authorId} text={`@${latestUpdate.authorName}`} />,
			time: <TimestampView value={latestUpdate.date} />,
		});
	}

	return (
		<Flex gap="space.050" alignItems="center">
			{message}
		</Flex>
	);
};

export const UpdateModal = ({
	isOpen = false,
	summary,
	description,
	onClose,
	latestUpdate,
	isLoadingUpdate,
}: UpdateModalProps) => {
	const { formatMessage } = useIntl();

	return (
		<ModalTransition>
			{!!isOpen && (
				<Modal onClose={onClose}>
					<ModalHeader>
						<Flex direction="column" gap="space.100">
							<ModalTitle>{summary}</ModalTitle>
							<HeaderMessage latestUpdate={latestUpdate} isLoadingUpdate={isLoadingUpdate} />
						</Flex>
						<IconButton
							appearance="subtle"
							onClick={onClose}
							label={formatMessage(messages.closeButtonLabel)}
							icon={CrossIcon}
						/>
					</ModalHeader>
					<ModalBody>{description}</ModalBody>
				</Modal>
			)}
		</ModalTransition>
	);
};
