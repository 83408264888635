import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mapTo';
import {
	SAVE_COMMENT_SUCCESS,
	type SaveCommentSuccessAction,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { fetchViewContextRequest } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions';

const fetchViewContextOnSaveCommentSuccess = (
	action$: ActionsObservable<SaveCommentSuccessAction>,
) =>
	action$
		.ofType(SAVE_COMMENT_SUCCESS)
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		.filter((action) => action.meta.shouldFetchViewContext)
		.mapTo(fetchViewContextRequest());

export default fetchViewContextOnSaveCommentSuccess;
