import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editContribution: {
		id: 'polaris-common.plays.add-contribution.footer.edit-contribution',
		defaultMessage: 'Edit',
		description: 'Vote contribution action: edit this contribution',
	},
	deleteContribution: {
		id: 'polaris-common.plays.add-contribution.footer.delete-contribution',
		defaultMessage: 'Delete',
		description: 'Vote contribution action: delete this contribution',
	},
	deleteContributionWarningHeader: {
		id: 'polaris-common.plays.add-contribution.footer.delete-contribution-warning-header',
		defaultMessage: 'Are your sure you want to delete your vote?',
		description: 'Header for delete contribution confirmation dialog',
	},
	deleteContributionWarning: {
		id: 'polaris-common.plays.add-contribution.footer.delete-contribution-warning',
		defaultMessage: 'This will remove your vote and your comment. You cannot undo this.',
		description: 'Content for delete contribution confirmation dialog',
	},
	cancel: {
		id: 'polaris-common.plays.add-contribution.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button caption',
	},
});
