import { FORGE_ENTITY_TYPE, CONNECT_ENTITY_TYPE } from './ecosystem-constants';

export const ALL_ACTIVITY = 'AllActivity' as const;
// Jira Service Management Approvals
export const APPROVALS = 'Approvals' as const;
export const COMMENTS = 'Comments' as const;
export const HISTORY = 'History' as const;
export const WORKLOG = 'Worklog' as const;
export const FORGE = FORGE_ENTITY_TYPE;
export const CONNECT = CONNECT_ENTITY_TYPE;
