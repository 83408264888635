import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	fieldNamePlaceholder: {
		id: 'polaris-lib-create-field-form.field-name-placeholder',
		defaultMessage: 'Give it a name',
		description: 'Placeholder for name of the field to be created',
	},
	fieldNameLabel: {
		id: 'polaris-lib-create-field-form.field-name-label',
		defaultMessage: 'Name',
		description: 'Label for the name field',
	},
	fieldTypePlaceholder: {
		id: 'polaris-lib-create-field-form.field-type-placeholder',
		defaultMessage: 'Select a type',
		description: 'Placeholder for dropdown to select type of field to be created',
	},
	fieldTypeLabel: {
		id: 'polaris-lib-create-field-form.field-type-label',
		defaultMessage: 'Type',
		description: 'Label for the field type field',
	},
});
