import React from 'react';
import { FieldConfigurationContainer } from './controllers';
import type { Props } from './controllers/types';
import { FieldConfigurationInternal } from './ui';

export const FieldConfiguration = (props: Props) => {
	const fieldKey = props.fieldData?.field.key;
	return (
		<FieldConfigurationContainer key={fieldKey} {...props}>
			<FieldConfigurationInternal />
		</FieldConfigurationContainer>
	);
};
