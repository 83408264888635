import type { UploadContext } from '@atlassian/jira-issue-gira-transformer-types';

// FETCH_UPLOAD_CONTEXT_REQUEST
export const FETCH_UPLOAD_CONTEXT_REQUEST = 'FETCH_UPLOAD_CONTEXT_REQUEST' as const;

export type FetchUploadContextRequestAction = {
	type: typeof FETCH_UPLOAD_CONTEXT_REQUEST;
};

export const fetchUploadContextRequest = (): FetchUploadContextRequestAction => ({
	type: FETCH_UPLOAD_CONTEXT_REQUEST,
});

// FETCH_UPLOAD_CONTEXT_SUCCESS
export const FETCH_UPLOAD_CONTEXT_SUCCESS = 'FETCH_UPLOAD_CONTEXT_SUCCESS' as const;

export type FetchUploadContextSuccessAction = {
	type: typeof FETCH_UPLOAD_CONTEXT_SUCCESS;
	payload: UploadContext;
};

export const fetchUploadContextSuccess = (
	payload: UploadContext,
): FetchUploadContextSuccessAction => ({
	type: FETCH_UPLOAD_CONTEXT_SUCCESS,
	payload,
});

// FETCH_UPLOAD_CONTEXT_FAILURE
export const FETCH_UPLOAD_CONTEXT_FAILURE = 'FETCH_UPLOAD_CONTEXT_FAILURE' as const;

export type FetchUploadContextFailureActionPayload = {
	error:
		| Error
		| {
				statusCode: number;
		  };
};

export type FetchUploadContextFailureAction = {
	type: typeof FETCH_UPLOAD_CONTEXT_FAILURE;
	payload: FetchUploadContextFailureActionPayload;
};

export const fetchUploadContextFailure = (
	error:
		| Error
		| {
				statusCode: number;
		  },
): FetchUploadContextFailureAction => ({
	type: FETCH_UPLOAD_CONTEXT_FAILURE,
	payload: { error },
});

// CHECK_UPLOAD_CONTEXT
export const CHECK_UPLOAD_CONTEXT = 'CHECK_UPLOAD_CONTEXT' as const;

export type CheckUploadContextAction = {
	type: typeof CHECK_UPLOAD_CONTEXT;
};

export const checkUploadContext = (): CheckUploadContextAction => ({
	type: CHECK_UPLOAD_CONTEXT,
});

export type UploadContextAction =
	| FetchUploadContextRequestAction
	| FetchUploadContextSuccessAction
	| FetchUploadContextFailureAction
	| CheckUploadContextAction;
