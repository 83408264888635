import React, { useEffect, useRef, useMemo, useState, memo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { B400 } from '@atlaskit/theme/colors';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useValueDecorationPalette } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useLocalIssueIdsForGroupIdentity } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useAreCurrentViewAllSwimlanesCollapsed,
	useCurrentViewCollapsedSwimlanes,
	useCurrentViewHideEmptyBoardColumns,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	N100,
	N400,
	DN100,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { DEFAULT } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { preserveOffsetFromPointer } from '../../../../../../common/ui/dnd-v2/utils';
import { ColumnHeader } from '../../../column/header';
import { BOARD_COLUMN_GAP, BOARD_COLUMN_WIDTH } from '../../../constants';
import {
	type DragState,
	type DraggableColumnHeaderData,
	isDraggableColumnHeaderData,
} from '../../../utils/draggable.types';
import { HEADER_HEIGHT } from '../../constants';

type DraggableColumnHeaderProps = {
	isReadOnly: boolean;
	index: number;
	fieldKey: FieldKey;
	draggableId: string;
	groupIdentity: string | undefined;
	isLastHeader: boolean;
	onDragStatusChange: (dragStatus: DragState) => void;
	showCollapseAllButton?: boolean;
};

const CollapseAllGroupsButton = () => {
	const { toggleAllSwimlanes } = useViewActions();
	const areAllSwimlanesCollapsed = useAreCurrentViewAllSwimlanesCollapsed();
	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();

	return (
		<IconButton
			label="toggle groups"
			onClick={toggleAllSwimlanes}
			appearance="subtle"
			spacing="compact"
			icon={
				areAllSwimlanesCollapsed && !isEmpty(collapsedSwimlanes)
					? ChevronRightIcon
					: ChevronDownIcon
			}
		/>
	);
};

/**
 * Draggable column header to be used in a sticky board header (when using swimlanes/groups)
 */
export const DraggableColumnStickyHeader = memo((props: DraggableColumnHeaderProps) => {
	const {
		isReadOnly,
		index,
		fieldKey,
		draggableId,
		groupIdentity,
		isLastHeader,
		onDragStatusChange,
		showCollapseAllButton,
	} = props;
	const { colorMode } = useThemeObserver();
	const theme = colorMode === 'dark' ? 'dark' : 'light';
	const columnValueDecorationPalette = useValueDecorationPalette(fieldKey, groupIdentity, theme);
	const isDragDisabled = isReadOnly || groupIdentity === undefined;
	const ideaIds = useLocalIssueIdsForGroupIdentity(fieldKey, groupIdentity);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();
	const isEmptyColumn = useMemo(() => ideaIds.length === 0, [ideaIds.length]);
	const columnHeaderRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, _setDragStatus] = useState<DragState>('idle');
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	const setDragStatus = useCallback(
		(status: DragState) => {
			_setDragStatus(status);
			onDragStatusChange(status);
		},
		[onDragStatusChange],
	);

	useEffect(() => {
		if (!columnHeaderRef.current || isDragDisabled) {
			return undefined;
		}

		const cleanupDragAndDrop = combine(
			draggable({
				element: columnHeaderRef.current,
				onGenerateDragPreview: ({ nativeSetDragImage, source, location }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: preserveOffsetFromPointer({
							sourceElement: source.element,
							input: location.current.input,
						}),
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					const data: DraggableColumnHeaderData = {
						draggableId,
						index,
						type: 'STICKY_COLUMN_HEADER',
					};
					return data;
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					// the setTimeout prevents a flickering before column header card is moved to another location
					setTimeout(() => setDragStatus('idle'));
				},
			}),
			dropTargetForElements({
				element: columnHeaderRef.current,
				canDrop({ source }) {
					return isDraggableColumnHeaderData(source.data);
				},
				getData({ input, element }) {
					return attachClosestEdge(
						{ draggableId, index, type: 'COLUMN_HEADER' },
						{
							input,
							element,
							allowedEdges: ['left', 'right'],
						},
					);
				},
				onDrag({ source, self }) {
					if (!isDraggableColumnHeaderData(source.data)) {
						return;
					}
					if (source.data.draggableId !== draggableId) {
						const dropEdge = extractClosestEdge(self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [fieldKey, index, draggableId, closestEdge, isDragDisabled, setDragStatus]);

	return (
		<>
			<DraggableWrapper
				data-testid="polaris-ideas.ui.view-content.idea-board.swimlanes.header.draggable-column-sticky-header"
				ref={columnHeaderRef}
				isHidden={hideEmptyColumns && isEmptyColumn}
				closestEdge={closestEdge}
				dragStatus={dragStatus}
				isFirstHeader={index === 0}
				isLastHeader={isLastHeader}
			>
				<ColumnHeaderContainer
					backgroundColor={columnValueDecorationPalette.highlightColor}
					isNoValueColumn={groupIdentity === undefined}
					closestEdge={closestEdge}
					isFirstHeader={index === 0}
					showCollapseAllButton={
						ff('polaris.collapse-all-board-groups') ? showCollapseAllButton : undefined
					}
					isLastHeader={isLastHeader}
				>
					{ff('polaris.collapse-all-board-groups')
						? showCollapseAllButton && <CollapseAllGroupsButton />
						: null}
					<ColumnHeader
						isReadOnly={isReadOnly}
						fieldKey={fieldKey}
						groupIdentity={groupIdentity}
						elemAfter={ideaIds ? <IssueCountContainer>{ideaIds.length}</IssueCountContainer> : null}
					/>
				</ColumnHeaderContainer>
			</DraggableWrapper>
			{previewContainer
				? ReactDOM.createPortal(
						<ColumnPreviewWrapper isDarkTheme={theme === 'dark'}>
							<ColumnPreview
								backgroundColor={columnValueDecorationPalette.highlightColor}
								isNoValueColumn={groupIdentity === undefined}
							>
								<ColumnHeaderContainer
									backgroundColor={columnValueDecorationPalette.highlightColor}
									isNoValueColumn={groupIdentity === undefined}
								>
									<ColumnHeader
										isReadOnly
										fieldKey={fieldKey}
										groupIdentity={groupIdentity}
										elemAfter={
											ideaIds ? <IssueCountContainer>{ideaIds.length}</IssueCountContainer> : null
										}
									/>
								</ColumnHeaderContainer>
							</ColumnPreview>
						</ColumnPreviewWrapper>,
						previewContainer,
					)
				: null}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: N400,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnHeaderContainer = styled.div<{
	backgroundColor: string;
	isNoValueColumn: boolean;
	closestEdge?: Edge | null;
	isFirstHeader?: boolean;
	showCollapseAllButton?: boolean;
	isLastHeader?: boolean;
}>(
	{
		display: 'flex',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${BOARD_COLUMN_WIDTH}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${HEADER_HEIGHT}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		padding: ({ showCollapseAllButton }) =>
			`${token('space.100', '8px')} 10px ${token('space.100', '8px')} ${showCollapseAllButton ? '0' : '10px'}`,
		alignItems: 'center',
		boxSizing: 'border-box',
		justifyContent: 'flex-start',
		borderRadius: '5px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ backgroundColor, isNoValueColumn }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isNoValueColumn || backgroundColor === DEFAULT.highlightColor
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.neutral', N100)
				: backgroundColor,
		flex: '0 0 auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ showCollapseAllButton }) =>
		showCollapseAllButton && {
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			gap: '10px',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DraggableWrapper = styled.div<{
	isHidden: boolean;
	closestEdge: Edge | null;
	dragStatus: string;
	isFirstHeader: boolean;
	isLastHeader: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => isHidden && 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-child)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingRight: `${BOARD_COLUMN_GAP}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ dragStatus }) => dragStatus === 'dragging' && 0.3,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ dragStatus }) => dragStatus === 'dragging' && 'saturate(0)',
	},
	// we use a pseudo element to set the background color because while dragging
	// we want the height to fill the parent height, but the background height should
	// remain the same.
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:before': {
		content: '',
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${HEADER_HEIGHT}px`,
		background: token('elevation.surface', 'white'),
		zIndex: '-1',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		height: '100%',
		width: '2px',
		position: 'absolute',
		top: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		left: ({ closestEdge, isFirstHeader }) =>
			closestEdge === 'left'
				? `${isFirstHeader ? 0 : token('space.negative.075', '-6px')}`
				: 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		right: ({ closestEdge, isLastHeader }) =>
			closestEdge === 'right' ? `${isLastHeader ? 0 : token('space.050', '4px')}` : 'auto',
		background: token('color.border.brand', B400),
		zIndex: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnPreviewWrapper = styled.div<{ isDarkTheme: boolean }>({
	height: '80vh',
	boxSizing: 'border-box',
	borderRadius: '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ isDarkTheme }) => (isDarkTheme ? DN100 : 'white'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnPreview = styled.div<{
	backgroundColor: string;
	isNoValueColumn: boolean;
}>({
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor, isNoValueColumn }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isNoValueColumn || backgroundColor === DEFAULT.highlightColor
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.neutral', N100)
			: backgroundColor,
});
