import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteLabelOptionDisabled: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.option-list.field-decorator.delete-label-option-disabled',
		defaultMessage: 'Labels that are not in use will automatically be removed from this list.',
		description: 'Tooltip text to explain user why label option cannot be deleted',
	},
	deleteSelectOptionDisabled: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.option-list.field-decorator.delete-select-option-disabled',
		defaultMessage: 'This field type requires at least one added option.',
		description: 'Tooltip text to explain user why select / multi-select option cannot be deleted',
	},
});
