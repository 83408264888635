import { SUMMARY_CARD_GAP } from './constants';

export const calculateSummaryFieldSpace = (containerWidth = 0, summaryWidth = 0) => {
	const fieldRemainingSpace = containerWidth - summaryWidth - SUMMARY_CARD_GAP;
	const fieldReservedSpace = containerWidth / 3 - SUMMARY_CARD_GAP;
	const summaryReservedSpace = (containerWidth * 2) / 3;
	const isSummaryOverflowing = summaryWidth > summaryReservedSpace;
	// allow to take field more space if summary is fully visible
	// otherwise distribute space in 2:1 ratio
	const fieldMaxWidth = isSummaryOverflowing ? fieldReservedSpace : fieldRemainingSpace;

	return {
		reserved: fieldReservedSpace,
		maxWidth: fieldMaxWidth,
	};
};
