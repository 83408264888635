import type { GraphQlResponse } from '@atlassian/jira-issue-view-common-types/src/gira';

/** @internal Exported for tests only, please do not use it outside this file. */
export const issueViewGiraDataCache = new Map<string, Promise<GraphQlResponse>>();

export const setCacheEntry = (issueKey: string, promise: Promise<GraphQlResponse>) => {
	if (issueViewGiraDataCache.has(issueKey)) {
		return;
	}

	issueViewGiraDataCache.set(issueKey, promise);
};

export const getCacheEntry = (issueKey: string) => issueViewGiraDataCache.get(issueKey);

export const hasCacheEntry = (issueKey: string) => issueViewGiraDataCache.has(issueKey);

export const clearCache = () => issueViewGiraDataCache.clear();
