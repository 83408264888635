import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsXAxisLocked,
	useIsYAxisLocked,
	useXAxis,
	useYAxis,
} from '../../controllers/selectors/axis-hooks';
import type { AxisComponentProps } from '../container/types';
import { AxisComponent } from './main';
import { messages } from './messages';

export const XAxisComponent = ({ onSizing, currentWidth }: AxisComponentProps<number>) => {
	const { formatMessage } = useIntl();
	const axis = useXAxis();
	const axisLocked = useIsXAxisLocked() || false;
	if (axis === undefined) {
		return null;
	}

	return (
		<AxisComponent
			dimension="x"
			axis={axis}
			mode="HORIZONTAL"
			onSizing={onSizing}
			currentWidth={currentWidth}
			locked={axisLocked}
			lockedMessage={formatMessage(messages.axisLockedMessage)}
		/>
	);
};

export const YAxisComponent = ({ onSizing, currentWidth }: AxisComponentProps<number>) => {
	const { formatMessage } = useIntl();
	const axis = useYAxis();
	const axisLocked = useIsYAxisLocked() || false;
	if (axis === undefined) {
		return null;
	}

	return (
		<AxisComponent
			dimension="y"
			axis={axis}
			mode="VERTICAL"
			onSizing={onSizing}
			currentWidth={currentWidth}
			locked={axisLocked}
			lockedMessage={formatMessage(messages.axisLockedMessage)}
		/>
	);
};
