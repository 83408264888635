import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';

export const getTimelineMinColumnWidth = (mode: PolarisTimelineMode) =>
	mode === PolarisTimelineMode.MONTHS ? 110 : 55;
export const getTimelineMaxColumnWidth = (mode: PolarisTimelineMode) =>
	mode === PolarisTimelineMode.MONTHS ? 200 : 100;

// Returns the middle of the month date for the given date in the format: [startOfIdea, endOfIdea]
export const getMiddleOfTheMonthDate = (date: Date): number[] => {
	const utcMonth = date.getUTCMonth();
	const utcYear = date.getUTCFullYear();
	const utcDate = new Date(Date.UTC(utcYear, utcMonth + 1, 0));
	const daysInMonth = utcDate.getUTCDate();

	switch (daysInMonth) {
		case 28:
			return [14, 13];
		case 29:
			return [15, 14];
		default:
			return [16, 15];
	}
};
