import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	accessLevelHeading: {
		id: 'polaris-component-view-access.access-screen.access-level-heading',
		defaultMessage: 'ACCESS LEVEL',
		description: 'Heading of the access level section in the access modal',
	},
	additionalAccessHeading: {
		id: 'polaris-component-view-access.access-screen.additional-access-heading',
		defaultMessage: 'ADDITIONAL ACCESS',
		description: 'Heading of the additional access section in the access modal',
	},
	copyLinkButton: {
		id: 'polaris-component-view-access.access-screen.copy-link-button',
		defaultMessage: 'Copy link',
		description: 'Label of the copy link button',
	},
	addButtonLabel: {
		id: 'polaris-component-view-access.access-screen.add-button-label',
		defaultMessage: 'Add',
		description:
			'Button that will add selected principals (users or groups) with selected view permission',
	},
	cancelButtonLabel: {
		id: 'polaris-component-view-access.access-screen.cancel-button-label',
		defaultMessage: 'Cancel',
		description:
			'Button that will clear all selected principals (users or groups) from the user picker input',
	},
	canEditLabel: {
		id: 'polaris-component-view-access.access-screen.can-edit-label',
		defaultMessage: 'Can edit',
		description: 'Option label for specifying viewer level permissions',
	},
	canViewLabel: {
		id: 'polaris-component-view-access.access-screen.can-view-label',
		defaultMessage: 'Can view',
		description: 'Option label for specifying editor level permissions',
	},
	canEditOptionMessage: {
		id: 'polaris-component-view-access.access-screen.can-edit-option-message',
		defaultMessage:
			'Only users with the "Manage views" permission in their role can edit the view; others will only be able to view it.',
		description:
			'Message that\'s displayed below a select if the user has chosen the "Can edit" permission, should inform the user that "Manage views" permission is required for it to work properly',
	},
	infoIconLabel: {
		id: 'polaris-component-view-access.access-screen.info-icon-label',
		defaultMessage: 'info',
		description: 'Info icon label',
	},
	addPrincipalsFailureMessage: {
		id: 'polaris-component-view-access.access-screen.add-principals-failure-message',
		defaultMessage: 'We were unable to add people',
		description: 'Error message that appears when adding principals fails',
	},
	addPrincipalsRetryAction: {
		id: 'polaris-component-view-access.access-screen.add-principals-retry-action',
		defaultMessage: 'Retry',
		description: 'Retry label to try to add principals again after a failure',
	},
	addPrincipalsCancelAction: {
		id: 'polaris-component-view-access.access-screen.add-principals-cancel-action',
		defaultMessage: 'Cancel',
		description: 'Cancel label to remove an error message about a failure to add principals',
	},
});
