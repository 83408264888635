import React, { useCallback, useState } from 'react';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import {
	useNumberValue,
	useIsIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { FieldTooltip } from '@atlassian/jira-polaris-common/src/ui/field-tooltip';
import {
	NumberField as CommonNumberField,
	NumberFieldReadView,
} from '@atlassian/jira-polaris-common/src/ui/fields/number/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils';

type InnerNumberFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
};

type InteractiveNumberFieldProps = InnerNumberFieldProps & {
	onModeChanged: (arg1: { isEditing: boolean }) => void;
};

type NumberFieldProps = {
	interactive: boolean;
} & InnerNumberFieldProps;

const InteractiveNumberField = ({
	localIssueId,
	fieldKey,
	onModeChanged,
}: InteractiveNumberFieldProps) => {
	const { value } = useNumberValue(fieldKey, localIssueId);
	const fieldType = useFieldType(fieldKey);
	const decorations = useDecorationsForField(fieldKey);
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	if (fieldType === undefined) return <></>;

	// note: CommonNumberField switches out it's content, so we need the additional fixed div layer
	// to make the tooltip/inline dialog work. without this div, the ak/inline-dialog will get it's
	// anchor element unmounted by the optimisations in CommonNumberField and would render at 0/0
	return (
		<FieldTooltip strategy="click" disabled={isEditable} fieldKey={fieldKey}>
			<div>
				<CommonNumberField
					isEditable={isEditable && !isIdeaArchived}
					value={value}
					decorations={decorations}
					fieldType={fieldType}
					onUpdate={onUpdate}
					onModeChanged={onModeChanged}
				/>
			</div>
		</FieldTooltip>
	);
};

const PreviewNumberField = ({ localIssueId, fieldKey }: InnerNumberFieldProps) => {
	const { value, initialized } = useNumberValue(fieldKey, localIssueId);
	const decorations = useDecorationsForField(fieldKey);
	const fieldType = useFieldType(fieldKey);
	const isEditable = useIsEditable(fieldKey);

	if (!initialized || !fieldType) {
		return <Skeleton height="16px" />;
	}

	return (
		<NumberFieldReadView
			value={value}
			decorations={decorations}
			fieldType={fieldType}
			isEditable={isEditable}
		/>
	);
};

export const NumberField = ({ localIssueId, fieldKey, interactive }: NumberFieldProps) => {
	const [isInEditMode, setInEditMode] = useState(false);

	const handleModeChanged = useCallback(({ isEditing }: { isEditing: boolean }) => {
		setInEditMode(isEditing);
	}, []);

	if (interactive || isInEditMode) {
		return (
			<InteractiveNumberField
				localIssueId={localIssueId}
				fieldKey={fieldKey}
				onModeChanged={handleModeChanged}
			/>
		);
	}

	return <PreviewNumberField localIssueId={localIssueId} fieldKey={fieldKey} />;
};
