import { layers } from '@atlassian/jira-common-styles/src';

const baseLayer = layers.card;

export const issueViewLayers = {
	...layers,
	baseLayer,
	stickyHeaderBreadcrumbs: baseLayer - 1, // jira issue header
	// the group header needs to sit below the jira issue header (stickyHeaderBreadcrumbs),
	// be higher than the editor header and be lower than
	// the modal layer (used for field menus that show on top of the group header)
	groupStickyHeader: baseLayer - 2,
	// editor popup should be portaled to a node "above" Issue Dialog
	editorTooltip: layers.modal + 1,
} as const;
