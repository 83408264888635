import { createSelector } from 'reselect';
import type {
	ViewSetType,
	ViewSet,
} from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { TreeObject } from '@atlassian/jira-polaris-lib-tree/src/types.tsx';
import { wrapWithDeepEqualityCheck } from '../../../common/utils/reselect';
import type { State, Props } from '../types';
import TreeBuilder, {
	type SimplifiedViewSetTreeNode,
	type SimplifiedView,
	type JpdTreeItem,
} from './sections-tree';
import { createGetViewSet } from './view/view-sets/index.tsx';

/**
 * create a simplified tree structure only with the things we need for building the sections tree
 * remove all unused properties so we can do a deep equality check on the selector without too much
 * overhead. we need that to properly memoize the input for the sidebar
 */
const createSimplifiedTree = (viewSet?: ViewSet): SimplifiedViewSetTreeNode | undefined => {
	if (viewSet === undefined) {
		return undefined;
	}

	return {
		id: viewSet.id,
		rank: viewSet.rank,
		views: viewSet.views.map(({ id, viewId, rank, uuid }) => ({
			viewId,
			localId: id,
			rank,
			uuid,
		})),
		collapsed: viewSet.collapsed,
		children: (viewSet.viewSets || []).map(createSimplifiedTree).filter(Boolean),
	};
};

export const createGetSectionSimplifiedViewSetTree = (viewSetType: ViewSetType) =>
	wrapWithDeepEqualityCheck<State, Props | undefined, SimplifiedViewSetTreeNode | undefined>(
		createSelector(createGetViewSet(viewSetType), createSimplifiedTree),
	);

const createTree = (node?: SimplifiedViewSetTreeNode): TreeObject<JpdTreeItem> | undefined => {
	if (node === undefined) {
		return undefined;
	}

	const viewsTree = new TreeBuilder(node.id, {});

	const createSectionNode = ({ collapsed = true, id, views, rank }: SimplifiedViewSetTreeNode) => {
		const subTree = new TreeBuilder(
			id,
			{
				type: 'section',
				section: id,
				rank,
			},
			collapsed,
		);
		views.forEach((view: SimplifiedView) => {
			subTree.withLeaf(
				view.viewId || view.localId,
				{
					type: 'view',
					view,
				},
				['make-child'],
			);
		});
		subTree.withLeaf(
			`${id}-placeholder`,
			{
				type: 'placeholder',
				parentId: id,
			},
			['reorder-below', 'make-child'],
		);
		viewsTree.withSubTree(subTree);
	};

	const createViewNode = (view: SimplifiedView) => {
		viewsTree.withLeaf(view.viewId || view.localId, { type: 'view', view }, ['make-child']);
	};

	// Sort views and viewsets
	const treeNodes: Array<{
		type: string;
		rank: number;
		data: { view: SimplifiedView | null; node: SimplifiedViewSetTreeNode | null };
	}> = [];
	node.children.forEach((childNode) => {
		treeNodes.push({
			type: 'section',
			rank: childNode.rank !== undefined ? childNode.rank : Infinity,
			data: {
				node: childNode,
				view: null,
			},
		});
	});

	node.views.forEach((view: SimplifiedView) => {
		treeNodes.push({
			type: 'view',
			rank: view.rank,
			data: { view, node: null },
		});
	});
	treeNodes.sort((a, b) => a.rank - b.rank);

	// Generate all tree nodes
	treeNodes.forEach(({ type, data }) => {
		if (type === 'section' && data.node) {
			createSectionNode(data.node);
		}
		if (type === 'view' && data.view) {
			createViewNode(data.view);
		}
	});

	return viewsTree.build();
};

export const createGetSectionsTree = (viewSetType: ViewSetType) =>
	createSelector(createGetSectionSimplifiedViewSetTree(viewSetType), createTree);
