import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { RestUser, UsersResponse } from './types';

export const fetchUsersUrl = (query: string, avatarSize: string, excludeConnectUsers: boolean) =>
	`/rest/api/2/user/picker?showAvatar=true&query=${query}&avatarSize=${avatarSize}&excludeConnectUsers=${String(
		excludeConnectUsers,
	)}`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	query: string,
	avatarSize: string,
	excludeConnectUsers: boolean,
): Promise<RestUser[]> =>
	fetchJson(fetchUsersUrl(query, avatarSize, excludeConnectUsers)).then(
		(response: UsersResponse) => response.users,
	);
