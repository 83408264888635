import type { ComponentType } from 'react';
import flow from 'lodash/flow';
import { safeComponent, type Fallback } from '../safe-component';

type FlowResult<T, Pn> = (ComponentToWrap: T, FallbackComponent?: Fallback) => Pn;
type FlowStep<In, Out> = (ComponentToWrap: In) => Out;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyComponent = ComponentType<any>;

export function flowWithSafeComponent<T extends AnyComponent, P1 extends AnyComponent>(
	fn1: FlowStep<T, P1>,
): FlowResult<T, P1>;
export function flowWithSafeComponent<
	T extends AnyComponent,
	P1 extends AnyComponent,
	P2 extends AnyComponent,
>(f1: FlowStep<T, P1>, f2: FlowStep<P1, P2>): FlowResult<T, P2>;
export function flowWithSafeComponent<
	T extends AnyComponent,
	P1 extends AnyComponent,
	P2 extends AnyComponent,
	P3 extends AnyComponent,
>(f1: FlowStep<T, P1>, f2: FlowStep<P1, P2>, f3: FlowStep<P2, P3>): FlowResult<T, P3>;
export function flowWithSafeComponent<
	T extends AnyComponent,
	P1 extends AnyComponent,
	P2 extends AnyComponent,
	P3 extends AnyComponent,
	P4 extends AnyComponent,
>(
	f1: FlowStep<T, P1>,
	f2: FlowStep<P1, P2>,
	f3: FlowStep<P2, P3>,
	f4: FlowStep<P3, P4>,
): FlowResult<T, P4>;

export function flowWithSafeComponent<
	T extends AnyComponent,
	P1 extends AnyComponent,
	P2 extends AnyComponent,
	P3 extends AnyComponent,
	P4 extends AnyComponent,
	P5 extends AnyComponent,
>(
	f1: FlowStep<T, P1>,
	f2: FlowStep<P1, P2>,
	f3: FlowStep<P2, P3>,
	f4: FlowStep<P3, P4>,
	f5: FlowStep<P4, P5>,
): FlowResult<T, P5>;

export function flowWithSafeComponent<
	T extends AnyComponent,
	P1 extends AnyComponent,
	P2 extends AnyComponent,
	P3 extends AnyComponent,
	P4 extends AnyComponent,
	P5 extends AnyComponent,
	P6 extends AnyComponent,
>(
	f1: FlowStep<T, P1>,
	f2: FlowStep<P1, P2>,
	f3: FlowStep<P2, P3>,
	f4: FlowStep<P3, P4>,
	f5: FlowStep<P4, P5>,
	f6: FlowStep<P5, P6>,
): FlowResult<T, P6>;

export function flowWithSafeComponent<
	T extends AnyComponent,
	P1 extends AnyComponent,
	P2 extends AnyComponent,
	P3 extends AnyComponent,
	P4 extends AnyComponent,
	P5 extends AnyComponent,
	P6 extends AnyComponent,
	P7 extends AnyComponent,
>(
	f1: FlowStep<T, P1>,
	f2: FlowStep<P1, P2>,
	f3: FlowStep<P2, P3>,
	f4: FlowStep<P3, P4>,
	f5: FlowStep<P4, P5>,
	f6: FlowStep<P5, P6>,
	f7: FlowStep<P6, P7>,
): FlowResult<T, P7>;

/**
 * @deprecated This function bypasses the type checker. Prefer using hooks or render props.
 */
export function flowWithSafeComponent(
	...functions: Array<FlowStep<AnyComponent, AnyComponent>>
): FlowResult<AnyComponent, AnyComponent> {
	return (ComponentToWrap, FallbackComponent?: Fallback) =>
		flow(...functions, safeComponent({ FallbackComponent }))(ComponentToWrap);
}
