'use strict';
// Generated from ../Expr.g4 by ANTLR 4.9.0-SNAPSHOT
Object.defineProperty(exports, '__esModule', { value: true });
exports.ExprLexer = void 0;
const ATNDeserializer_1 = require('antlr4ts/atn/ATNDeserializer');
const Lexer_1 = require('antlr4ts/Lexer');
const LexerATNSimulator_1 = require('antlr4ts/atn/LexerATNSimulator');
const VocabularyImpl_1 = require('antlr4ts/VocabularyImpl');
const Utils = require('antlr4ts/misc/Utils');
class ExprLexer extends Lexer_1.Lexer {
	// tslint:enable:no-trailing-whitespace
	constructor(input) {
		super(input);
		this._interp = new LexerATNSimulator_1.LexerATNSimulator(ExprLexer._ATN, this);
	}
	// @Override
	// @NotNull
	get vocabulary() {
		return ExprLexer.VOCABULARY;
	}
	// @Override
	get grammarFileName() {
		return 'Expr.g4';
	}
	// @Override
	get ruleNames() {
		return ExprLexer.ruleNames;
	}
	// @Override
	get serializedATN() {
		return ExprLexer._serializedATN;
	}
	// @Override
	get channelNames() {
		return ExprLexer.channelNames;
	}
	// @Override
	get modeNames() {
		return ExprLexer.modeNames;
	}
	static get _ATN() {
		if (!ExprLexer.__ATN) {
			ExprLexer.__ATN = new ATNDeserializer_1.ATNDeserializer().deserialize(
				Utils.toCharArray(ExprLexer._serializedATN),
			);
		}
		return ExprLexer.__ATN;
	}
}
exports.ExprLexer = ExprLexer;
ExprLexer.T__0 = 1;
ExprLexer.T__1 = 2;
ExprLexer.T__2 = 3;
ExprLexer.T__3 = 4;
ExprLexer.T__4 = 5;
ExprLexer.T__5 = 6;
ExprLexer.T__6 = 7;
ExprLexer.T__7 = 8;
ExprLexer.T__8 = 9;
ExprLexer.T__9 = 10;
ExprLexer.T__10 = 11;
ExprLexer.T__11 = 12;
ExprLexer.T__12 = 13;
ExprLexer.T__13 = 14;
ExprLexer.T__14 = 15;
ExprLexer.T__15 = 16;
ExprLexer.FIELD = 17;
ExprLexer.IDENT = 18;
ExprLexer.WS = 19;
ExprLexer.FLOAT = 20;
ExprLexer.INTEGER = 21;
ExprLexer.STRING = 22;
// tslint:disable:no-trailing-whitespace
ExprLexer.channelNames = ['DEFAULT_TOKEN_CHANNEL', 'HIDDEN'];
// tslint:disable:no-trailing-whitespace
ExprLexer.modeNames = ['DEFAULT_MODE'];
ExprLexer.ruleNames = [
	'T__0',
	'T__1',
	'T__2',
	'T__3',
	'T__4',
	'T__5',
	'T__6',
	'T__7',
	'T__8',
	'T__9',
	'T__10',
	'T__11',
	'T__12',
	'T__13',
	'T__14',
	'T__15',
	'FIELD',
	'IDENT',
	'WS',
	'FLOAT',
	'INTEGER',
	'STRING',
	'STRING_CHAR',
];
ExprLexer._LITERAL_NAMES = [
	undefined,
	"'<'",
	"'<='",
	"'>'",
	"'>='",
	"'='",
	"'<>'",
	"'and'",
	"'or'",
	"'in'",
	"'+'",
	"'-'",
	"'*'",
	"'/'",
	"'('",
	"')'",
	"','",
];
ExprLexer._SYMBOLIC_NAMES = [
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	undefined,
	'FIELD',
	'IDENT',
	'WS',
	'FLOAT',
	'INTEGER',
	'STRING',
];
ExprLexer.VOCABULARY = new VocabularyImpl_1.VocabularyImpl(
	ExprLexer._LITERAL_NAMES,
	ExprLexer._SYMBOLIC_NAMES,
	[],
);
ExprLexer._serializedATN =
	'\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x18\x88\b\x01' +
	'\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06' +
	'\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r' +
	'\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t' +
	'\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t' +
	'\x17\x04\x18\t\x18\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03\x04\x03' +
	'\x04\x03\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03' +
	'\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\n\x03\n\x03\n\x03\v\x03\v\x03' +
	'\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x03' +
	'\x11\x03\x11\x03\x12\x03\x12\x06\x12[\n\x12\r\x12\x0E\x12\\\x03\x12\x03' +
	'\x12\x03\x13\x03\x13\x07\x13c\n\x13\f\x13\x0E\x13f\v\x13\x03\x14\x06\x14' +
	'i\n\x14\r\x14\x0E\x14j\x03\x14\x03\x14\x03\x15\x03\x15\x03\x15\x06\x15' +
	'r\n\x15\r\x15\x0E\x15s\x03\x16\x06\x16w\n\x16\r\x16\x0E\x16x\x03\x17\x03' +
	'\x17\x07\x17}\n\x17\f\x17\x0E\x17\x80\v\x17\x03\x17\x03\x17\x03\x18\x03' +
	'\x18\x03\x18\x05\x18\x87\n\x18\x02\x02\x02\x19\x03\x02\x03\x05\x02\x04' +
	'\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\v' +
	'\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02\x11!' +
	"\x02\x12#\x02\x13%\x02\x14'\x02\x15)\x02\x16+\x02\x17-\x02\x18/\x02\x02" +
	'\x03\x02\b\x03\x02\x7F\x7F\x05\x02C\\aac|\x06\x022;C\\aac|\x05\x02\v\f' +
	'\x0F\x0F""\x03\x022;\x04\x02$$^^\x02\x8D\x02\x03\x03\x02\x02\x02\x02' +
	'\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02' +
	'\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11' +
	'\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17' +
	'\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D' +
	'\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03' +
	"\x02\x02\x02\x02%\x03\x02\x02\x02\x02'\x03\x02\x02\x02\x02)\x03\x02\x02" +
	'\x02\x02+\x03\x02\x02\x02\x02-\x03\x02\x02\x02\x031\x03\x02\x02\x02\x05' +
	'3\x03\x02\x02\x02\x076\x03\x02\x02\x02\t8\x03\x02\x02\x02\v;\x03\x02\x02' +
	'\x02\r=\x03\x02\x02\x02\x0F@\x03\x02\x02\x02\x11D\x03\x02\x02\x02\x13' +
	'G\x03\x02\x02\x02\x15J\x03\x02\x02\x02\x17L\x03\x02\x02\x02\x19N\x03\x02' +
	'\x02\x02\x1BP\x03\x02\x02\x02\x1DR\x03\x02\x02\x02\x1FT\x03\x02\x02\x02' +
	"!V\x03\x02\x02\x02#X\x03\x02\x02\x02%`\x03\x02\x02\x02'h\x03\x02\x02" +
	'\x02)n\x03\x02\x02\x02+v\x03\x02\x02\x02-z\x03\x02\x02\x02/\x86\x03\x02' +
	'\x02\x0212\x07>\x02\x022\x04\x03\x02\x02\x0234\x07>\x02\x0245\x07?\x02' +
	'\x025\x06\x03\x02\x02\x0267\x07@\x02\x027\b\x03\x02\x02\x0289\x07@\x02' +
	'\x029:\x07?\x02\x02:\n\x03\x02\x02\x02;<\x07?\x02\x02<\f\x03\x02\x02\x02' +
	'=>\x07>\x02\x02>?\x07@\x02\x02?\x0E\x03\x02\x02\x02@A\x07c\x02\x02AB\x07' +
	'p\x02\x02BC\x07f\x02\x02C\x10\x03\x02\x02\x02DE\x07q\x02\x02EF\x07t\x02' +
	'\x02F\x12\x03\x02\x02\x02GH\x07k\x02\x02HI\x07p\x02\x02I\x14\x03\x02\x02' +
	'\x02JK\x07-\x02\x02K\x16\x03\x02\x02\x02LM\x07/\x02\x02M\x18\x03\x02\x02' +
	'\x02NO\x07,\x02\x02O\x1A\x03\x02\x02\x02PQ\x071\x02\x02Q\x1C\x03\x02\x02' +
	'\x02RS\x07*\x02\x02S\x1E\x03\x02\x02\x02TU\x07+\x02\x02U \x03\x02\x02' +
	'\x02VW\x07.\x02\x02W"\x03\x02\x02\x02XZ\x07}\x02\x02Y[\n\x02\x02\x02' +
	'ZY\x03\x02\x02\x02[\\\x03\x02\x02\x02\\Z\x03\x02\x02\x02\\]\x03\x02\x02' +
	'\x02]^\x03\x02\x02\x02^_\x07\x7F\x02\x02_$\x03\x02\x02\x02`d\t\x03\x02' +
	'\x02ac\t\x04\x02\x02ba\x03\x02\x02\x02cf\x03\x02\x02\x02db\x03\x02\x02' +
	'\x02de\x03\x02\x02\x02e&\x03\x02\x02\x02fd\x03\x02\x02\x02gi\t\x05\x02' +
	'\x02hg\x03\x02\x02\x02ij\x03\x02\x02\x02jh\x03\x02\x02\x02jk\x03\x02\x02' +
	'\x02kl\x03\x02\x02\x02lm\b\x14\x02\x02m(\x03\x02\x02\x02no\x05+\x16\x02' +
	'oq\x070\x02\x02pr\t\x06\x02\x02qp\x03\x02\x02\x02rs\x03\x02\x02\x02sq' +
	'\x03\x02\x02\x02st\x03\x02\x02\x02t*\x03\x02\x02\x02uw\t\x06\x02\x02v' +
	'u\x03\x02\x02\x02wx\x03\x02\x02\x02xv\x03\x02\x02\x02xy\x03\x02\x02\x02' +
	'y,\x03\x02\x02\x02z~\x07$\x02\x02{}\x05/\x18\x02|{\x03\x02\x02\x02}\x80' +
	'\x03\x02\x02\x02~|\x03\x02\x02\x02~\x7F\x03\x02\x02\x02\x7F\x81\x03\x02' +
	'\x02\x02\x80~\x03\x02\x02\x02\x81\x82\x07$\x02\x02\x82.\x03\x02\x02\x02' +
	'\x83\x87\n\x07\x02\x02\x84\x85\x07^\x02\x02\x85\x87\t\x07\x02\x02\x86' +
	'\x83\x03\x02\x02\x02\x86\x84\x03\x02\x02\x02\x870\x03\x02\x02\x02\n\x02' +
	'\\djsx~\x86\x03\b\x02\x02';
