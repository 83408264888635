import type { ChildPanelUiState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	type SubtaskQuickAddClickedAction,
	type IssueInEpicQuickAddClickedAction,
	type ChildIssueQuickAddClickedAction,
	type ResetQuickAddClickedCount,
	RESET_QUICK_ADD_CLICKED_COUNT,
	SUBTASK_QUICK_ADD_CLICKED,
	ISSUE_IN_EPIC_QUICK_ADD_CLICKED,
	CHILD_ISSUE_QUICK_ADD_CLICKED,
} from '../actions/child-panel-actions';

type Action =
	| SubtaskQuickAddClickedAction
	| IssueInEpicQuickAddClickedAction
	| ChildIssueQuickAddClickedAction
	| ResetQuickAddClickedCount;

const initialState = {
	subtaskQuickAddClickCount: 0,
	issueInEpicQuickAddClickCount: 0,
	childIssueQuickAddClickCount: 0,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ChildPanelUiState = initialState, action: Action) => {
	switch (action.type) {
		case SUBTASK_QUICK_ADD_CLICKED:
			return { ...state, subtaskQuickAddClickCount: state.subtaskQuickAddClickCount + 1 };
		case ISSUE_IN_EPIC_QUICK_ADD_CLICKED:
			return {
				...state,
				issueInEpicQuickAddClickCount: state.issueInEpicQuickAddClickCount + 1,
			};
		case CHILD_ISSUE_QUICK_ADD_CLICKED:
			return {
				...state,
				childIssueQuickAddClickCount: state.childIssueQuickAddClickCount + 1,
			};
		case RESET_QUICK_ADD_CLICKED_COUNT:
			return {
				...initialState,
			};
		default:
			return state;
	}
};
