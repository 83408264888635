import type { CardContent } from '@atlassian/jira-polaris-domain-insight/src/snippet/card/types.tsx';

export const isContentLessCard = (content?: CardContent) =>
	content?.description === undefined && content?.preview === undefined;

export const createCardWithUrlSnippetData = (url: string) => ({
	type: 'card' as const,
	context: {
		url,
		title: url,
	},
});
