import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { JiraFieldDict, FetchFieldsWithJQLResponse } from '../types';
import { getFieldsSearchServiceUrl, getFieldsServiceUrl } from '../utils';

export const getPolarisFields = (projectIdOrKey: number | string): Promise<JiraFieldDict> =>
	fetchJson(getFieldsServiceUrl(projectIdOrKey));

export const getSelectedPolarisFields = (
	projectIdOrKey: number | string,
	fieldKeys: string[],
): Promise<JiraFieldDict> =>
	performPostRequest(getFieldsSearchServiceUrl(projectIdOrKey), {
		body: JSON.stringify({
			fieldKeys,
		}),
	});

const JQL_FIELDS_URL = '/rest/polaris/fields/associations/jql';

export const getFieldsWithJql = (jql: string): Promise<FetchFieldsWithJQLResponse> =>
	performPostRequest(JQL_FIELDS_URL, {
		body: JSON.stringify({ jql, globalOnly: true }),
	});
