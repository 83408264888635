import React, { type PropsWithChildren, memo, useEffect, useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import {
	useCanPublishPublicDiscoveryViews,
	useCanPublishDiscoveryViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { publishFeatureDisabled } from '../../common/utils/publish';
import { Container, useSharingActions } from './main';
import { useIsSharingOnSiteEnabled } from './selectors/hooks';

type SharingContainerInnerProps = PropsWithChildren<{
	viewUUID: string | undefined;
	edition?: ApplicationEdition;
	isSharedView: boolean;
}>;

const SharingContainerInner = memo<SharingContainerInnerProps>(
	({ children, edition, viewUUID }) => {
		const { loadSharingSettings } = useSharingActions();
		const isSharingSiteConfigEnabled = useIsSharingOnSiteEnabled();

		useEffect(() => {
			if (ff('polaris.publish.standard-edition') && publishFeatureDisabled(edition)) {
				return;
			}

			if (!ff('polaris.sharing-enabled') || !isSharingSiteConfigEnabled) {
				return;
			}

			if (viewUUID) {
				loadSharingSettings(viewUUID);
			}
		}, [viewUUID, loadSharingSettings, isSharingSiteConfigEnabled, edition]);

		return <>{children}</>;
	},
);

const SharingContainerOld: typeof Container = ({ children, ...props }) => (
	<Container {...props}>
		<SharingContainerInner
			edition={props.edition}
			viewUUID={props.viewUUID}
			isSharedView={!!props.isSharedView}
		>
			{children}
		</SharingContainerInner>
	</Container>
);

export const SharingContainer: typeof Container = functionWithCondition(
	() => ff('polaris.published-view-public-links_dj5gi'),
	({ children, ...props }) => {
		const canPublishDiscoveryViews = useCanPublishDiscoveryViews();
		const canPublishPublicDiscoveryViews = useCanPublishPublicDiscoveryViews();
		const permissions = useMemo(
			() => ({
				canPublishDiscoveryViews,
				canPublishPublicDiscoveryViews,
			}),
			[canPublishPublicDiscoveryViews, canPublishDiscoveryViews],
		);

		return (
			<Container {...props} permissions={permissions}>
				<SharingContainerInner
					edition={props.edition}
					viewUUID={props.viewUUID}
					isSharedView={!!props.isSharedView}
				>
					{children}
				</SharingContainerInner>
			</Container>
		);
	},
	SharingContainerOld,
);

export { useSharingActions };
