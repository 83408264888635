import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	deleteField: {
		id: 'polaris-common.common.manage-field-buttons.delete-field',
		defaultMessage: 'Delete field',
		description: 'Label on button that opens delete field dialog',
	},
	removeField: {
		id: 'polaris-common.common.manage-field-buttons.remove-field',
		defaultMessage: 'Remove from project',
		description: 'Label on button that opens remove the global field from project dialog',
	},
	noPermissions: {
		id: 'polaris-common.common.manage-field-buttons.no-permissions',
		defaultMessage: 'You do not have the permission to delete this field',
		description: 'Tooltip text for missing delete permissions.',
	},
	fieldNotDeletable: {
		id: 'polaris-common.common.manage-field-buttons.field-not-deletable',
		defaultMessage: 'This field cannot be deleted',
		description: 'Tooltip text for missing delete permissions.',
	},
	editGlobalField: {
		id: 'polaris-common.common.manage-field-buttons.edit-global-field',
		defaultMessage: 'Edit',
		description: 'Label of the Edit global field link',
	},
});
