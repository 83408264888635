import filter from 'lodash/filter';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { getMetaFromJiraSearchIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getFieldKeys } from '../../selectors/fields';
import { createGetKeySelector } from '../../selectors/properties';
import type { State, Props } from '../../types';

export const createStateWithoutExternLinkedIssue = (
	state: State,
	props: Props,
	issueId: LocalIssueId,
	issueLinkId: string,
	issueResponse: RemoteIssue | null,
	isDeliveryIssue?: boolean,
): State => {
	const { externalIssueData } = state;
	const externalIssueIdsToDelete = keys(
		pickBy(externalIssueData, (data) => data.issueLinkId === issueLinkId),
	);
	const newExternalIssueData = omitBy(
		externalIssueData,
		(data) => data.issueLinkId === issueLinkId,
	);
	const linkedDeliveryIssue = state.properties.linkedDeliveryIssues[issueId];
	const newLinkedDeliveryIssues = filter(
		linkedDeliveryIssue,
		(id) => externalIssueIdsToDelete.indexOf(id) === -1,
	);

	if (isDeliveryIssue === true) {
		return {
			...state,
			lastUpdatedIssueIds: [issueId],
			properties: {
				...state.properties,
				linkedDeliveryIssues: {
					...state.properties.linkedDeliveryIssues,
					[issueId]: newLinkedDeliveryIssues,
				},
			},
			externalIssueData: newExternalIssueData,
		};
	}

	if (issueResponse) {
		return {
			...state,
			lastUpdatedIssueIds: [issueId],
			properties: {
				...state.properties,
				issueMetadata: {
					...state.properties.issueMetadata,
					[issueId]: getMetaFromJiraSearchIssue(
						issueResponse,
						props.polarisIssueLinkType,
						props.hiddenIssueLinkTypes,
					),
				},
			},
			externalIssueData: newExternalIssueData,
		};
	}

	return state;
};

export const unlinkDeliveryIssue =
	(issueId: LocalIssueId, issueLinkId: string) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) =>
		props.issuesRemote
			.deleteIssueLink({ issueLinkId })
			.then(() =>
				setState(
					createStateWithoutExternLinkedIssue(getState(), props, issueId, issueLinkId, null, true),
				),
			);

export const unlinkIssueFromStore =
	(issueId: LocalIssueId, issueLinkId: string) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		props.issuesRemote
			.fetchIssue?.({
				issueIdOrKey: createGetKeySelector(issueId)(getState()),
				fields: getFieldKeys(getState(), props),
			})
			.then((response) => {
				setState(
					createStateWithoutExternLinkedIssue(getState(), props, issueId, issueLinkId, response),
				);
			});
	};
