import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noProjectsMessage: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'polaris-component-collection-details.projects-list.no-projects-message',
		defaultMessage: 'This roadmap has no projects.',
		description: 'Message displayed when no projects in the roadmap',
	},
});
