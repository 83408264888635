import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	misconfiguredUnpublishedViewDescription: {
		id: 'polaris-ideas.view-header.publish.unsupported-fields.misconfigured-unpublished-view-description',
		defaultMessage:
			'The following fields are preventing the publishing of your view because they are displayed or used for grouping, sorting, filtering.',
		description: 'Misconfigured view error description when view is not published',
	},
	misconfiguredPublishedViewDescription: {
		id: 'polaris-ideas.view-header.publish.unsupported-fields.misconfigured-published-view-description',
		defaultMessage: 'The view configuration now uses the following unsupported fields.',
		description: 'Misconfigured view error description when view is published',
	},
});
