import type React from 'react';
import { useCallback, useEffect } from 'react';
import { isFirefox } from '@atlassian/jira-common-util-browser';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { BaseTableApi } from '../../common/types/react-base-table';
import { useScrollPositionLeft, useScrollPositionTop } from '../../controllers/selectors/ui-hooks';

type ScrollRestorationProps = {
	innerRef: React.MutableRefObject<BaseTableApi | undefined>;
	isHidden: boolean;
};

export const useScrollRestoration = ({ innerRef, isHidden }: ScrollRestorationProps) => {
	const enabled = isFirefox();

	const wasHidden = usePrevious(isHidden) && !isHidden;
	const tableBody = innerRef.current?.getDOMNode()?.querySelector('.table__body');
	const scrollPositionLeft = useScrollPositionLeft();
	const scrollPositionTop = useScrollPositionTop();

	const restoreScrollPosition = useCallback(() => {
		if (!tableBody) {
			return;
		}

		const firstRow = tableBody.querySelector('.table__row');
		if (!firstRow) {
			return;
		}

		const tableTop = tableBody.getBoundingClientRect().top;
		const firstRowTop = firstRow.getBoundingClientRect().top;
		const scrollPositionLost = firstRowTop - tableTop >= 0;

		if (scrollPositionLost) {
			tableBody.scrollBy(scrollPositionLeft, scrollPositionTop);
		}
	}, [tableBody, scrollPositionLeft, scrollPositionTop]);

	useEffect(() => {
		if (!enabled) {
			return;
		}

		if (wasHidden) {
			restoreScrollPosition();
		}
	}, [enabled, wasHidden, restoreScrollPosition]);
};
