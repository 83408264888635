import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	refreshInProgress: {
		id: 'polaris-common.snippet.refresh.refresh-in-progress',
		defaultMessage: 'This data is currently queued to be refreshed.',
		description: 'Tooltip indicator that the data is in queue to be refreshed',
	},
	refreshError: {
		id: 'polaris-common.snippet.refresh.refresh-error',
		defaultMessage: 'The last attempt to refresh this data failed.',
		description: 'Tooltip indicator that the last refresh returned an error state',
	},
	refreshLastSuccess: {
		id: 'polaris-common.snippet.refresh.refresh-last-success',
		defaultMessage: 'Last updated {date}',
		description:
			'Tooltip indicator when the last successful refresh has happened. date string is internationalised absolute or relative.',
	},
	refreshLastAttempt: {
		id: 'polaris-common.snippet.refresh.refresh-last-attempt',
		defaultMessage: 'Last refresh attempt {date}',
		description:
			'Tooltip indicator when the last refresh was atteempted. date string is internationalised absolute or relative.',
	},
	refreshNowMenuEntry: {
		id: 'polaris-common.snippet.refresh.refresh-now-menu-entry',
		defaultMessage: 'Refresh',
		description: 'Dropdown menu entry for manual refreshing',
	},
});
