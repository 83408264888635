import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-lib-onboarding.join-community.title',
		defaultMessage: 'Find help, and learn pro tips by joining the community!',
		description: '"Join the community" flag title',
	},
	description: {
		id: 'polaris-lib-onboarding.join-community.description',
		defaultMessage:
			'Read articles on <link1>automation for delivery</link1>, <link2>FAQ about the product</link2> and more, stay up-to-date with the product announcements.',
		description: '"Join the community" flag description',
	},
	imageAlt: {
		id: 'polaris-lib-onboarding.join-community.image-alt',
		defaultMessage: 'Community logo',
		description: 'Alternative text for the community logo',
	},
	acceptButton: {
		id: 'polaris-lib-onboarding.join-community.accept-button',
		defaultMessage: 'Join community',
		description: 'Label for accept button in "Join the community" flag',
	},
	dismissButton: {
		id: 'polaris-lib-onboarding.join-community.dismiss-button',
		defaultMessage: 'No, thanks',
		description: 'Label for dismiss button in "Join the community" flag',
	},
});
