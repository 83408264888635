import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, MergeFromOption, TargetIssue, SelectedIssue } from '../../types';

export const setMergeFromOption =
	(mergeFromOption: MergeFromOption, selectedIssue: SelectedIssue | TargetIssue | null) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const { mergeFromOptions: newMergeFromOptions } = getState();
		if (selectedIssue === null) {
			delete newMergeFromOptions[mergeFromOption];
		} else {
			newMergeFromOptions[mergeFromOption] = selectedIssue;
		}
		setState({
			mergeFromOptions: newMergeFromOptions,
		});
	};
