import { ff } from '@atlassian/jira-feature-flagging';
import type {
	ContentPanelConnection,
	LegacyRightWebPanelConnection,
} from '@atlassian/jira-issue-fetch-services/src/types';
import type {
	ActivityPanel as ServerActivityPanel,
	EcosystemContentPanels,
	ServerEcosystemBackgroundScript,
	ServerEcosystemOperation,
} from '@atlassian/jira-issue-gira-transformer-types';
import type { ContextPanelStatus } from '@atlassian/jira-issue-shared-types';
import type {
	EcosystemActivityPanels,
	EcosystemBackgroundScript,
	EcosystemExtensions,
	EcosystemOperation,
} from '@atlassian/jira-issue-view-common-types/src/ecosystem-types';
import type { TransformedEcosystemContextPanel } from '@atlassian/jira-issue-view-common-types/src/gira';
import type { GlanceStatus } from '@atlassian/jira-issue-view-common-types/src/glance-type';

export const convertIconUrlToAbsolute = (addonBaseUrl: string, iconUrl: string): string =>
	!iconUrl.includes('http') ? addonBaseUrl + iconUrl : iconUrl;

/**
 * Attempts to convert the status icon url to an absolute url icon. It has a lot of guarding as the GlanceStatus
 * comes from jira entity properties, and they have no validated object so anything is possible.
 *
 * @param addonBaseUrl The addon base url of the app
 * @param status the status of the app, comes from jira entity properties and is updated by the app
 */
const convertStatusIconUrlToAbsolute = (
	addonBaseUrl: string,
	status: GlanceStatus | ContextPanelStatus | null,
): GlanceStatus | null => {
	if (status && status.type === 'icon' && status.value && status.value.label) {
		const convertedStatus = { ...status };
		if (convertedStatus.value !== undefined) {
			convertedStatus.value.label = convertIconUrlToAbsolute(addonBaseUrl, status.value.label);
		}
		return convertedStatus;
	}

	return status;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const arrayToTreeReducer = (extensions: Record<any, any>, currentValue: Record<any, any>) => {
	const addonKey = Object.keys(currentValue)[0];
	const aggregated = {
		...extensions,
		[addonKey]: { ...currentValue[addonKey], ...extensions[addonKey] },
	};
	return aggregated;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformIFrameOptions = (serverOptions: string | any) =>
	typeof serverOptions === 'string' ? JSON.parse(serverOptions) : serverOptions;

const transformLegacyRightWebPanelsAsGlances = (
	serverLegacyRightWebPanels?: LegacyRightWebPanelConnection,
) =>
	(serverLegacyRightWebPanels?.edges || [])
		.map((edge: NonNullable<LegacyRightWebPanelConnection['edges']>[number]) => {
			if (!edge?.node?.addonKey || !edge?.node?.moduleKey) {
				return {};
			}
			return {
				[edge.node.addonKey]: {
					[edge.node.moduleKey]: {
						name: edge?.node?.name,
						appKey: edge.node.addonKey,
						moduleKey: edge.node.moduleKey,
						options: transformIFrameOptions(edge?.node?.options),
						icon: edge?.node?.iconUrl && { url: edge?.node?.iconUrl },
						label: {
							type: 'text',
							value: edge?.node?.content || edge?.node?.name,
						},
						status: convertStatusIconUrlToAbsolute(
							transformIFrameOptions(edge?.node?.options).origin,
							edge.node.status ? JSON.parse(edge.node.status) : null,
						),
						type: 'WEB_PANEL',
					},
				},
			};
		})
		.reduce(arrayToTreeReducer, {});

const transformContextPanels = (serverContextPanels: TransformedEcosystemContextPanel[] = []) =>
	serverContextPanels
		.map((serverContextPanel) => ({
			[serverContextPanel.iframe.addonKey]: {
				[serverContextPanel.iframe.moduleKey]: {
					name: serverContextPanel.name,
					appKey: serverContextPanel.iframe.addonKey,
					moduleKey: serverContextPanel.iframe.moduleKey,
					options: transformIFrameOptions(serverContextPanel.iframe.options),
					icon: serverContextPanel.icon && { url: serverContextPanel.icon.url },
					label: serverContextPanel.content || serverContextPanel.name,
					status: convertStatusIconUrlToAbsolute(
						transformIFrameOptions(serverContextPanel.iframe.options).origin,
						serverContextPanel.status,
					),
					type: serverContextPanel.type,
				},
			},
		}))
		.reduce(arrayToTreeReducer, {});

const transformContentPanels = (
	contentPanels: ContentPanelConnection,
	legacyContentPanels: ContentPanelConnection,
) => {
	const moduleByModuleKeyByAddonKey: EcosystemContentPanels = {};

	const add = (edge: NonNullable<ContentPanelConnection['edges']>[number]) => {
		if (!edge?.node?.addonKey || !edge?.node?.moduleKey) {
			return;
		}

		if (!moduleByModuleKeyByAddonKey[edge.node.addonKey]) {
			moduleByModuleKeyByAddonKey[edge.node.addonKey] = {};
		}

		moduleByModuleKeyByAddonKey[edge.node.addonKey][edge.node.moduleKey] = {
			name: edge.node.name ?? '',
			appKey: edge.node.addonKey,
			moduleKey: edge.node.moduleKey,
			options: transformIFrameOptions(edge.node.options),
			iconUrl: edge.node.iconUrl ?? undefined,
			tooltip: edge.node.tooltip ?? undefined,
			type: edge.node.type ?? 'ISSUE_MODULE',
			showByDefault: edge.node.isShownByDefault ?? false,
		};
	};
	contentPanels?.edges?.forEach(add);
	legacyContentPanels?.edges?.forEach(add);

	return moduleByModuleKeyByAddonKey;
};

// JIV-15751: Remove when cleaning up issue-view-remove-backgroundscripts-from-critical-fetch_6f6on
const transformBackgroundScripts = (
	backgroundScripts: ServerEcosystemBackgroundScript[] = [],
): EcosystemBackgroundScript[] =>
	backgroundScripts.map(({ iframe, shouldReloadOnRefresh }) => ({
		appKey: iframe.addonKey,
		moduleKey: iframe.moduleKey,
		options: transformIFrameOptions(iframe.options),
		shouldReloadOnRefresh,
	}));

const transformActivityPanels = (
	activityPanels: ServerActivityPanel[] = [],
): EcosystemActivityPanels =>
	activityPanels
		.map((panel) => ({
			[panel.iframe.addonKey]: {
				[panel.iframe.moduleKey]: {
					name: panel.name,
					appKey: panel.iframe.addonKey,
					moduleKey: panel.iframe.moduleKey,
					options: transformIFrameOptions(panel.iframe.options),
				},
			},
		})) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Record<string, any>>(arrayToTreeReducer, {});

// JIV-16397: Remove when cleaning up issue-view-remove-connect-operations-from-critical-fetch_vtk4w
const transformOperations = (
	serverOperations: ServerEcosystemOperation[] = [],
): EcosystemOperation[] =>
	serverOperations.map((serverOperation) => ({
		name: serverOperation.name,
		url: serverOperation.url,
		tooltip: serverOperation.tooltip ?? undefined,
		styleClass: serverOperation.styleClass ?? undefined,
	}));

export const transformEcosystemExtensions = (serverResponse: {
	legacyRightWebPanels?: LegacyRightWebPanelConnection;
	contextPanels: TransformedEcosystemContextPanel[];
	contentPanels: ContentPanelConnection;
	legacyContentPanels: ContentPanelConnection;
	backgroundScripts?: ServerEcosystemBackgroundScript[]; // JIV-15751: Remove when cleaning up issue-view-remove-backgroundscripts-from-critical-fetch_6f6on
	operations?: ServerEcosystemOperation[]; // JIV-16397: Remove when cleaning up issue-view-remove-connect-operations-from-critical-fetch_vtk4w
	activityPanels: ServerActivityPanel[];
	ecoSystemOnDate: number;
	canInstallAddons: boolean | undefined;
}): EcosystemExtensions => ({
	glances: transformLegacyRightWebPanelsAsGlances(serverResponse.legacyRightWebPanels),
	contextPanels: transformContextPanels(serverResponse.contextPanels),
	contentPanels: transformContentPanels(
		serverResponse.contentPanels,
		serverResponse.legacyContentPanels,
	),
	...(!ff('issue-view-remove-backgroundscripts-from-critical-fetch_6f6on')
		? { backgroundScripts: transformBackgroundScripts(serverResponse.backgroundScripts) }
		: null),
	...(!ff('issue-view-remove-connect-operations-from-critical-fetch_vtk4w')
		? { operations: transformOperations(serverResponse.operations) }
		: null),
	activityPanels: transformActivityPanels(serverResponse.activityPanels),
	ecosystemOnDate: serverResponse.ecoSystemOnDate,
	canInstallAddons: serverResponse.canInstallAddons || false,
});
