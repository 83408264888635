import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	rename: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.rename',
		defaultMessage: 'Rename',
		description: 'Label for renaming a Jira Product Discovery view',
	},
	removeEmoji: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.remove-emoji',
		defaultMessage: 'Remove emoji',
		description: 'Label for removing emoji from selected view title',
	},
	saveAsNewList: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.save-as-new-list',
		defaultMessage: 'Save as new list',
		description: 'Label for creating a new list view from the selected view.',
	},
	saveAsNewBoard: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.save-as-new-board',
		defaultMessage: 'Save as new board',
		description: 'Label for creating a new board view from the selected view.',
	},
	saveAsNewMatrix: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.save-as-new-matrix',
		defaultMessage: 'Save as new matrix',
		description: 'Label for creating a new matrix view from the selected view.',
	},
	saveAsNewTimeline: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.save-as-new-timeline',
		defaultMessage: 'Save as new timeline',
		description: 'Label for creating a new timeline view from the selected view.',
	},
	delete: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.delete',
		defaultMessage: 'Delete',
		description: 'Label for deleting a Jira Product Discovery view.',
	},
	lastViewCantBeDeleted: {
		id: 'polaris-ideas.sidebar.sections.view-item.actions.last-view-cant-be-deleted',
		defaultMessage: "This is your only remaining view in the project and can't be deleted.",
		description:
			"Tooltip on the delete Jira Product Discovery view action when it is diabled because it's the last view",
	},
});
