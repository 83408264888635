import type { DraggableItem, ExternalDraggableItem } from '../../types';

export function isExternal(item: DraggableItem): item is ExternalDraggableItem {
	return 'isExternal' in item && item.isExternal === true;
}

export const getItemIds = (item: DraggableItem | undefined) => {
	if (!item) {
		return [];
	}

	return isExternal(item) ? [item.id] : item.ids;
};
