import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	placeholder: {
		id: 'polaris-ideas.insights.insights.add-insight.unfurl-form.placeholder',
		defaultMessage: 'Attach a link',
		description: 'Add data placeholder',
	},
	compactPlaceholder: {
		id: 'polaris-ideas.insights.insights.add-insight.unfurl-form.compact-placeholder',
		defaultMessage: 'Paste a link',
		description: 'Compact add data placeholder',
	},
});
