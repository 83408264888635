import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraIssueTypeMapping } from '../../../../field/mapping/issue-type';
import type { PropertyMaps, State } from '../../../types';
import { nullSafeComparator, stringComparator } from '../../comparators';
import { removePropertyValue } from '../common/remove-utils';
import type { FieldMapping } from '../types';

export const issueTypeMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<IssueTypeFieldValue> => ({
	...jiraIssueTypeMapping(issuesRemote, field),
	setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: IssueTypeFieldValue) =>
		set(maps.issueType, [field.key, issueId], value),
	setImmutable: (
		maps: PropertyMaps,
		issueId: LocalIssueId,
		value?: IssueTypeFieldValue,
	): PropertyMaps => {
		if (maps.issueType[field.key] && isEqual(maps.issueType[field.key][issueId], value)) {
			return maps;
		}
		return {
			...maps,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issueType: {
				...maps.issueType,
				[field.key]: {
					...maps.issueType[field.key],
					[issueId]: value,
				},
			} as PropertyMaps['issueType'],
		};
	},
	remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
		removePropertyValue(field.key, maps, issueId, 'issueType'),
	modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
	comparator: nullSafeComparator<IssueTypeFieldValue>((a, b, direction) =>
		stringComparator(a.name, b.name, direction),
	),
	valueAccessor: (state, props, issueId) =>
		state.properties.issueType[field.key] !== undefined
			? state.properties.issueType[field.key][issueId]
			: undefined,
	valueAccessorToExport: (state, props, issueId) =>
		state.properties.issueType[field.key] !== undefined
			? `${state.properties.issueType[field.key][issueId]}`
			: '',
	getAllValues: (state) =>
		state.properties.issueType[field.key] ? state.properties.issueType[field.key] : {},
	getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
		createSelector(
			issueIdsSelector,
			(state: State) => state.properties.issueType[fieldKey],
			(ids, issueType) =>
				ids.reduce(
					(result, issueId) =>
						Object.assign(result, {
							[issueId]:
								issueType !== undefined && issueType[issueId] !== undefined
									? [
											{
												groupIdentity: issueType[issueId].name,
												value: issueType[issueId],
											},
										]
									: [],
						}),
					{},
				),
		),
	getGroupIdentities: (state, props, issueId) =>
		state.properties.issueType[field.key] !== undefined &&
		state.properties.issueType[field.key][issueId] !== undefined
			? [
					{
						groupIdentity: state.properties.issueType[field.key][issueId].name,
						value: state.properties.issueType[field.key][issueId],
					},
				]
			: [],
	allowEmptyGroup: false,
	getLabel: (groupIdentity, value) => value?.name,
	getFilter: () => undefined,
});
