import {
	createContainer,
	createStore,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { getSelectionFromStorage } from '../common/utils/storage';
import actions from './actions';
import type { State, Props } from './types';

export const initialState: State = {
	matrixContainer: undefined,
	viewId: undefined,
	items: [],
	axisValues: {
		x: {},
		y: {},
		z: {},
	},
	xAxis: undefined,
	yAxis: undefined,
	zAxis: undefined,
	xAxisLocked: undefined,
	yAxisLocked: undefined,
	isAxesDragDisabled: true,
	emptyState: 'NONE',
	components: undefined,
	hoveredItems: undefined,
	selectedItems: [],
	dragValue: undefined,
};

export type Actions = typeof actions;

export const MatrixStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisMatrixStore',
});

const updateStateFromProps =
	() =>
	(
		{ dispatch, getState }: StoreActionApi<State>,
		{
			items,
			xAxis,
			yAxis,
			zAxis,
			highlightedItem,
			components,
			emptyState,
			viewId,
			xAxisLocked,
			yAxisLocked,
			isAxesDragDisabled,
		}: Props,
	) => {
		let selectedItems = [];
		const lastViewId = getState().viewId;
		if (viewId !== undefined && viewId !== lastViewId) {
			selectedItems = getSelectionFromStorage(viewId);
		}
		dispatch(actions.setCurrentViewId(viewId));
		dispatch(actions.setSelectedItems(selectedItems));
		dispatch(actions.setHighlightedItem(highlightedItem));
		dispatch(
			actions.updateAxisState(
				xAxis,
				yAxis,
				zAxis,
				isAxesDragDisabled,
				xAxisLocked === true,
				yAxisLocked === true,
			),
		);
		dispatch(actions.updateItemState(items, emptyState));
		dispatch(actions.updateComponentsState(components));
	};

export const MatrixContainer = createContainer<State, Actions, Props>(MatrixStore, {
	onInit: updateStateFromProps,
	onUpdate: updateStateFromProps,
});

export const useMatrixActions = createHook(MatrixStore, {
	selector: null,
});
