import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createNewSection: {
		id: 'polaris-lib-create-view-popup.actions.create-new-section',
		defaultMessage: 'Create a new section',
		description: 'Action dropdown option for creating a new section',
	},
	createNewList: {
		id: 'polaris-lib-create-view-popup.actions.create-new-list',
		defaultMessage: 'Create a new list',
		description: 'Action dropdown option for creating a new Jira Product Discovery list view',
	},
	createNewBoard: {
		id: 'polaris-lib-create-view-popup.actions.create-new-board',
		defaultMessage: 'Create a new board',
		description: 'Action dropdown option for creating a new Jira Product Discovery board view',
	},
	createNewMatrix: {
		id: 'polaris-lib-create-view-popup.actions.create-new-matrix',
		defaultMessage: 'Create a new matrix',
		description: 'Action dropdown option for creating a new Jira Product Discovery matrix view',
	},
	createNewTimeline: {
		id: 'polaris-lib-create-view-popup.actions.create-new-timeline',
		defaultMessage: 'Create a new timeline',
		description: 'Action dropdown option for creating a new Jira Product Discovery timeline view',
	},
});
