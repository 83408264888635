import React, { useMemo, type ReactNode, useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { isMatrixSupportedField } from '@atlassian/jira-polaris-common/src/controllers/field/utils/is-matrix-supported';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	useOpenRightSidebarMatrixXAxisOptions,
	useOpenRightSidebarMatrixYAxisOptions,
	useOpenRightSidebarOnField,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import type { Showing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentMatrixOptionsHasReverseOrder,
	useCurrentViewXAxisField,
	useCurrentViewYAxisField,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { FieldSelect } from '@atlassian/jira-polaris-common/src/ui/common/field-select';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MatrixSelectDropdown } from '../../matrix-field-select';
import { messages } from './messages';

type BaseFieldConfigProps = {
	isClearable: boolean;
	fields: {
		[key: string]: Field;
	};
	selectedField?: FieldKey;
	helpText: string;
	children?: ReactNode;
	backTo?: Showing;
	dimension: 'x' | 'y' | 'z';
	onChange: (fieldKey?: FieldKey) => void;
};

export const BaseFieldConfig = ({
	selectedField,
	fields,
	onChange,
	children,
	helpText,
	isClearable,
	backTo,
	dimension,
}: BaseFieldConfigProps) => {
	const { formatMessage } = useIntl();
	const openRightSidebarOnField = useOpenRightSidebarOnField();
	const canEditFields = useCanEditFields();
	const field = useField(selectedField);
	const hasReverseOrder = useCurrentMatrixOptionsHasReverseOrder(dimension);
	const onFilterField = useMemo(() => isMatrixSupportedField(dimension), [dimension]);

	const xAxis = useCurrentViewXAxisField();
	const yAxis = useCurrentViewYAxisField();
	const openXAxisOptions = useOpenRightSidebarMatrixXAxisOptions();
	const openYAxisOptions = useOpenRightSidebarMatrixYAxisOptions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOpenXAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixXAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
		);
		openXAxisOptions();
	}, [createAnalyticsEvent, openXAxisOptions]);
	const handleOpenYAxisOptions = useCallback(() => {
		fireCompoundAnalyticsEvent.ViewControls.openMatrixYAxisSidebarButtonClicked(
			createAnalyticsEvent({}),
		);
		openYAxisOptions();
	}, [createAnalyticsEvent, openYAxisOptions]);

	const { setFieldValuesReverseOrder } = useViewActions();

	const fieldList = useMemo(() => {
		const list = Object.keys(fields)
			.map((key) => fields[key])
			.filter((fieldItem) => fieldItem.key !== selectedField)
			.sort((a, b) => a.label.localeCompare(b.label));
		return list;
	}, [fields, selectedField]);

	const fieldOptions = fieldList.map((option) => ({
		key: option.key,
		label: option.label,
		disabled: onFilterField && !onFilterField(option),
	}));

	const supportedOptions = {
		label: 'supported',
		options: fieldOptions.filter((option) => !option.disabled),
	};
	const unsupportedOptions = {
		label: 'unsupported',
		options: fieldOptions.filter((option) => !!option.disabled),
	};

	const options = [supportedOptions, unsupportedOptions];

	const onChangeOptionsOrder = useCallback(() => {
		setFieldValuesReverseOrder(!hasReverseOrder, dimension);
	}, [dimension, hasReverseOrder, setFieldValuesReverseOrder]);

	return (
		<Container id="polaris-ideas.ui.view-control.config-columns.matrix-field-dropdown">
			<HelpText>{helpText}</HelpText>
			<FieldGroup>
				<FieldSelect
					isClearable={isClearable}
					selectedField={field}
					onChange={onChange}
					fieldOptions={options}
					SearchableComponent={MatrixSelectDropdown}
				/>
				{field && dimension !== 'z' && (
					<Tooltip content={formatMessage(messages.reverseAxisOrderTooltip)}>
						<ReverseOrderButton
							onClick={onChangeOptionsOrder}
							iconBefore={
								hasReverseOrder ? (
									<PolarisIcon name={PolarisIconType.SortMatrixDesc} label="Desc" />
								) : (
									<PolarisIcon name={PolarisIconType.SortMatrixAsc} label="Asc" />
								)
							}
						/>
					</Tooltip>
				)}
			</FieldGroup>
			{children !== undefined ? children : <p />}
			{field && field.editable ? (
				<Box xcss={editFieldContainerStyles}>
					<Button onClick={() => openRightSidebarOnField(field.key, backTo)}>
						{!canEditFields
							? formatMessage(messages.fieldSettings)
							: formatMessage(messages.editField)}
					</Button>
				</Box>
			) : null}
			{dimension === 'x' && xAxis !== undefined && yAxis === undefined && (
				<SetUpNextAxisContainer>
					<Button
						onClick={handleOpenYAxisOptions}
						iconBefore={<PolarisIcon name={PolarisIconType.SetUpYAxis} label="" />}
					>
						{formatMessage(messages.setUpYAxis)}
					</Button>
				</SetUpNextAxisContainer>
			)}
			{dimension === 'y' && yAxis !== undefined && xAxis === undefined && (
				<SetUpNextAxisContainer>
					<Button
						onClick={handleOpenXAxisOptions}
						iconBefore={<PolarisIcon name={PolarisIconType.SetUpXAxis} label="" />}
					>
						{formatMessage(messages.setUpXAxis)}
					</Button>
				</SetUpNextAxisContainer>
			)}
		</Container>
	);
};

BaseFieldConfig.defaultProps = {
	isClearable: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldGroup = styled.div({
	margin: `${token('space.200', '16px')} 0`,
	padding: `0 ${token('space.200', '16px')}`,
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		textAlign: 'left',
		'&:hover': {
			textAlign: 'left',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	padding: `${token('space.200', '16px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.p({
	padding: `0 ${token('space.200', '16px')} 10px`,
});

const editFieldContainerStyles = xcss({
	paddingLeft: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SetUpNextAxisContainer = styled.div({
	flexGrow: 1,
	paddingTop: `${token('space.200', '16px')}`,
	paddingLeft: token('space.200', '16px'),
	display: 'flex',
	alignItems: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReverseOrderButton = styled(Button)({
	marginLeft: token('space.150', '12px'),
});
