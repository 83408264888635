import React, { useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { PRODUCT_DISCOVERY_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useProjectActions } from '@atlassian/jira-polaris-common/src/controllers/project/main';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import ProjectDetailsApp from '@atlassian/jira-project-details/src/async';
import type { UpdateableDetails } from '@atlassian/jira-project-details/src/model/types';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { BreadcrumbsProjectSettings } from '../breadcrumbs';

export const PolarisProjectDetails = () => {
	const { locale, appEditions, isAdmin } = useTenantContext();
	const projectId = useProjectIdUnsafe();
	const { updateProjectDetails } = useProjectActions();

	const onDetailsUpdated = useCallback(
		({ key, name, avatarId }: UpdateableDetails) => {
			updateProjectDetails(key, name, avatarId);
		},
		[updateProjectDetails],
	);

	return (
		<>
			<Box paddingBlockStart="space.300" xcss={breadcrumbsContainerStyles}>
				<BreadcrumbsProjectSettings />
			</Box>
			<ProjectDetailsApp
				isAdmin={isAdmin}
				projectId={+projectId}
				baseUrl=""
				locale={locale}
				simplified
				projectType={PRODUCT_DISCOVERY_PROJECT}
				onDetailsUpdated={onDetailsUpdated}
				appEditions={appEditions}
			/>
		</>
	);
};

const breadcrumbsContainerStyles = xcss({
	marginBottom: 'space.negative.300',
});
