import findIndex from 'lodash/fp/findIndex';
import getOr from 'lodash/fp/getOr';
import set from 'lodash/fp/set';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalViewId, View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { saveView } from '../save';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

export const resizeFieldColumn =
	(id: LocalViewId | null | undefined, fieldKey: FieldKey, size: number) =>
	({ getState, setState, dispatch }: StoreActionApi<State>) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			(view) => view.id === id,
			(view: View) => {
				const fieldColIdx = findIndex(['fieldKey', fieldKey], view.tableColumnSizes);
				const tableColumnSizes = getOr([], 'tableColumnSizes', view);
				const fieldSizeObj = { fieldKey, size };
				let newTableColumnSizes = [];
				if (fieldColIdx !== -1) {
					newTableColumnSizes = set(fieldColIdx, fieldSizeObj, tableColumnSizes);
				} else {
					newTableColumnSizes = [...tableColumnSizes, fieldSizeObj];
				}
				return {
					...view,
					tableColumnSizes: newTableColumnSizes,
					modified: true,
				};
			},
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
		}
	};

export const setHoveredFieldKeyForBulkEdit =
	(bulkEditHoveredFieldKey?: FieldKey): Action<State, Props> =>
	({ getState, setState }: StoreActionApi<State>, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				bulkEditHoveredFieldKey,
			}),
		);
		if (changedView) {
			setState({ viewSets });
		}
	};
