import { createSelector } from 'reselect';
import type { Field } from '@atlassian/jira-issue-shared-types';
import { SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config';
import { fieldSelector } from '../common/state/selectors/field-selector';

export const practicesSelector = createSelector(
	fieldSelector(SERVICEDESK_PRACTICES_TYPE),
	(practices?: Field) => {
		// Practices will always be an array of only one practice
		if (practices && practices.value && practices.value.length > 0) {
			return practices.value[0];
		}

		return undefined;
	},
);
