import type { MediaProvider } from '@atlaskit/editor-common/provider-factory';
import 'rxjs/add/operator/map';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { fetchIssueMediaReadPermission$ } from '@atlassian/jira-issue-fetch-services/src';
import type { MediaContext } from '../../common/types';
import { createAuthProvider } from '../create-auth-provider';
import type { TokenProvider } from '../token-provider';
import { viewContextTransformer } from './utils';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	mediaContext: MediaContext,
	tokenProvider: TokenProvider,
	baseUrl: string,
): MediaProvider => {
	const { viewContext, uploadContext } = mediaContext;

	// In practice this shouldn't happen, but our types don't accurately
	// reflect our assumptions. We should migrate to use the MediaAuth sub-apps
	// to manage this: https://jdog.jira-dev.com/browse/BENTO-1997
	if (!viewContext) {
		throw new TypeError(
			'mediaContext.viewContext was null. Check that the server response has been processed.',
		);
	}

	const viewMediaClientConfig: MediaClientConfig = {
		authProvider: createAuthProvider(tokenProvider, viewContext.clientId, viewContext.serviceHost),
		useSha256ForUploads: true,
	};

	let mediaProvider: MediaProvider = {
		viewMediaClientConfig,
	};

	if (uploadContext) {
		mediaProvider = {
			...mediaProvider,
			uploadParams: {
				collection: uploadContext.collection,
			},
			uploadMediaClientConfig: {
				authProvider: createAuthProvider(
					tokenProvider,
					uploadContext.clientId,
					uploadContext.serviceHost,
				),
				useSha256ForUploads: true,
				/**
				 * contextId === origin issue id (from where the file was copied)
				 * In this provider we return a view token for the origin file
				 */
				getAuthFromContext: async (contextId: string) => {
					const maxTokenLength = 65536;

					const authContext = await fetchIssueMediaReadPermission$(
						baseUrl,
						contextId,
						maxTokenLength,
					)
						.map(viewContextTransformer)
						.toPromise();

					return {
						baseUrl: authContext.serviceHost,
						clientId: authContext.clientId,
						token: authContext.token || '',
					};
				},
			},
		};
	}

	return mediaProvider;
};
