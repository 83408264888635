import isEqual from 'lodash/isEqual';
import type { PatchOperation } from './types';

export const createJsonPatch = (oldObject: object, newObject: object): PatchOperation[] => {
	// undefined value for undefined is not a valid value in JSON schema
	// stringify and parse to deep clone the objects to get rid of
	// undefined values are removed when stringified, or replaced with null when makes sense
	const oldObj = JSON.parse(JSON.stringify(oldObject));
	const newObj = JSON.parse(JSON.stringify(newObject));

	const patch: PatchOperation[] = [];

	for (const [oldKey, value] of Object.entries(oldObj)) {
		if (!(oldKey in newObj)) {
			patch.push({
				op: 'remove',
				path: `/${oldKey}`,
			});
		} else if (!isEqual(value, newObj[oldKey])) {
			patch.push({
				op: 'replace',
				value: newObj[oldKey],
				path: `/${oldKey}`,
			});
		}
	}

	for (const [key, value] of Object.entries(newObj)) {
		if (!(key in oldObj)) {
			patch.push({
				op: 'add',
				path: `/${key}`,
				value,
			});
		}
	}

	return patch;
};

export { type PatchOperation } from './types';
