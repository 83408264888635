import React, { Component } from 'react';
import { setupConnectJs } from '@atlassian/jira-connect';
import type { App } from '../model/connect-apps/types';
import ConnectApps from './view';

type Props = App;

type State = {
	connectHost:
		| undefined
		| {
				createExtension: (arg1: {
					addon_key: string;
					key: string;
					url: string;
					options: {
						productContext: unknown;
						structuredContext: unknown;
						user: {
							uid: string;
							ukey: string;
							timeZone: string;
						};
					};
				}) => {
					iframeAttributes: unknown;
				};
		  };
};

// eslint-disable-next-line jira/react/no-class-components
export class AppsWrapper extends Component<Props, State> {
	state = {
		connectHost: undefined,
	};

	componentDidMount() {
		setupConnectJs().then(() => this.setState({ connectHost: window.connectHost }));
	}

	render() {
		const { id, connectIframeProps } = this.props;
		const { connectHost } = this.state;
		if (!connectIframeProps || !connectHost) {
			return null;
		}

		// `createExtension` is not pure function and does change whatever is passed in.
		// We know that it breaks Redux devtools because it adds React elements and other things from inside the iframe to it
		// and it may potentially break something else. Make sure to create new object here to avoid polluting our app state.
		// @ts-expect-error - TS2339 - Property 'createExtension' does not exist on type 'never'.
		const { iframeAttributes } = connectHost.createExtension({
			...connectIframeProps,
			options: {
				...connectIframeProps.options,
				// Documented in https://bitbucket.org/atlassian/simple-xdm/
				autoresize: true,
			},
		});

		return <ConnectApps key={id} id={id} iframeAttributes={iframeAttributes} />;
	}
}

export default AppsWrapper;
