import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { getMetaFromJiraSearchIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids';
import { getSelectedIssueLocalIssueId } from '../../selectors/properties';
import type { State, Props } from '../../types';
import { transformIssueLinkData } from '../common/issue-links';
import { refreshDeliveryProgressForSelectedIssue } from '../load-delivery-data';

export const setIssueLinksForSelectedIssue =
	(issue: RemoteIssue): Action<State, Props> =>
	({ getState, setState, dispatch }, props: Props) => {
		const { polarisIssueLinkType } = props;
		const state = getState();
		const selectedIssueLocalIssueId = getSelectedIssueLocalIssueId(state);
		const localIssueIdJiraIdMap = getLocalIssueIdToJiraId(state, props);
		const selectedIssueJiraId = selectedIssueLocalIssueId
			? localIssueIdJiraIdMap[selectedIssueLocalIssueId]
			: undefined;

		if (selectedIssueLocalIssueId !== undefined && selectedIssueJiraId === issue.id) {
			const issueLinkData = transformIssueLinkData(
				polarisIssueLinkType,
				props.hiddenIssueLinkTypes,
				issue,
			);

			setState({
				lastUpdatedIssueIds: [selectedIssueLocalIssueId],
				properties: {
					...state.properties,
					linkedDeliveryIssues: {
						...state.properties.linkedDeliveryIssues,
						[selectedIssueLocalIssueId]: issueLinkData.deliveryIssueIds,
					},
					issueMetadata: {
						...state.properties.issueMetadata,
						[selectedIssueLocalIssueId]: getMetaFromJiraSearchIssue(
							issue,
							props.polarisIssueLinkType,
							props.hiddenIssueLinkTypes,
						),
					},
				},
				externalIssueData: {
					...state.externalIssueData,
					...issueLinkData.externalIssueData,
				},
			});
			dispatch(refreshDeliveryProgressForSelectedIssue());
		}
	};
