import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	selectFieldsButton: {
		id: 'polaris-common.field-config.select-fields-button',
		defaultMessage: 'Select fields',
		description: 'Label on button which opens dropdown to select fields',
	},
	manageFields: {
		id: 'polaris-common.field-config.manage-fields',
		defaultMessage: 'Manage fields',
		description: 'Link to Jira fields configurations page',
	},
	otherFields: {
		id: 'polaris-common.field-config.other-fields',
		defaultMessage: 'Available fields',
		description: 'Label on section showing non-selected fields',
	},
	otherFieldsRoadmaps: {
		id: 'polaris-common.field-config.other-fields-roadmaps',
		defaultMessage: 'Available global and system fields',
		description:
			'Label on section showing non-selected fields in the context of a roadmap (cross project view)',
	},

	pinnedFields: {
		id: 'polaris-common.field-config.pinned-fields',
		defaultMessage: 'Pinned fields',
		description: 'Label on section showing pinned fields',
	},
	viewFields: {
		id: 'polaris-common.field-config.view-fields',
		defaultMessage: 'Fields in this view',
		description: 'Label on section showing view fields',
	},
	pinnedOtherFieldsHelpText: {
		id: 'polaris-common.field-config.pinned-other-fields-help-text',
		defaultMessage: 'Toggle a field to pin it.',
		description: 'Help text for other fields section when managing pinned fields',
	},
	otherFieldsHelpText: {
		id: 'polaris-common.field-config.other-fields-help-text',
		defaultMessage: 'Toggle a field to add it to this view.',
		description: 'Help text for other fields section',
	},
	dragRemoveHelpText: {
		id: 'polaris-common.field-config.drag-remove-help-text',
		defaultMessage: 'Drag here to remove the field from view.',
		description: 'Help text for field list removal area',
	},
});
