import { log } from '@atlassian/jira-common-util-logging';
import { ff } from '@atlassian/jira-feature-flagging';
import { retryOnError } from '@atlassian/jira-fetch';
import {
	fetchIssueGiraNonCriticalData,
	nonCriticalGiraQueryExperienceTracking,
	type GiraViewIssue,
} from '@atlassian/jira-issue-fetch-services';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources';
import { makeErrorObject } from '@atlassian/jira-issue-view-services/src/issue/error-utils';
import type { IssueKey } from '@atlassian/jira-shared-types';
import {
	transformEcosystemBackgroundScripts,
	transformEcosystemOperations,
} from './utils/ecosystem';

const isNonCriticalGiraQueryExperienceTrackingEnabled = () =>
	ff('issue.details.non-critical-gira-query-experience-tracking', false);

const consumePrefetchedData = async (prefetchedResourceManager: ResourceManager) => {
	const prefetchedDataPromise = prefetchedResourceManager.issueGiraNonCriticalData;
	prefetchedResourceManager.clearGiraNonCriticalData();
	const response = await prefetchedDataPromise;
	return response;
};

const fetchData = async (issueKey: IssueKey) => {
	const response = await fetchIssueGiraNonCriticalData(issueKey);
	return response;
};

const transformData = (viewIssue: GiraViewIssue) => {
	const result = {
		operations: transformEcosystemOperations(viewIssue.ecosystem?.operations),
		...(ff('issue-view-remove-backgroundscripts-from-critical-fetch_6f6on')
			? {
					backgroundScripts: transformEcosystemBackgroundScripts(
						viewIssue.ecosystem?.backgroundScripts,
					),
				}
			: {}),
		...(ff('issue.details.gira-agg-migration-attachments_ecebe')
			? {
					deletableAttachmentsCount: viewIssue.deletableAttachmentsCount || 0,
				}
			: {}),
	};

	return result;
};

// Fetches the gira data (from either the existing prefetched promise or a fresh query) and transforms it.
// In practice this should always come from the prefetchedResourceManager except if invoked outside of
// initial load or transition.
export const fetchNonCriticalGiraDataWithRetries = async (
	issueKey: IssueKey,
	prefetchedResourceManager?: ResourceManager,
) => {
	const hasPrefetchPromise =
		prefetchedResourceManager?.issueKey === issueKey &&
		!!prefetchedResourceManager?.issueGiraNonCriticalData;
	const getData = () =>
		hasPrefetchPromise ? consumePrefetchedData(prefetchedResourceManager) : fetchData(issueKey);
	const doFetch = () => fetchData(issueKey);
	const response = await retryOnError(getData, {
		retryFunc: doFetch,
	});

	const { errors } = response || {};
	if (!response?.data?.viewIssue) {
		// If the response is completely missing the data, throw an error
		const message = errors
			? JSON.stringify(makeErrorObject(errors))
			: 'No data returned for non-critical Gira query';
		throw new Error(message);
	} else if (errors?.length) {
		// Log an error for partial failure and try to continue
		log.safeErrorWithoutCustomerData(
			'issue.fetch.gira-non-critical.partial',
			'Partial failure when fetching non-critical Gira data',
			makeErrorObject(errors),
		);
	}

	try {
		const transformedData = transformData(response.data.viewIssue);

		if (isNonCriticalGiraQueryExperienceTrackingEnabled() && hasPrefetchPromise) {
			nonCriticalGiraQueryExperienceTracking({
				wasExperienceSuccessful: true,
				isEarlyFetch: true,
			});
		}

		return transformedData;
	} catch (error) {
		throw new Error(`Failed to transform non-critical Gira data: ${error}`);
	}
};
