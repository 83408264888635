import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { User as UserType } from '@atlassian/jira-polaris-common/src/common/types/user';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	usePeopleTransformed,
	useSelectedIssue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { UsersField } from '@atlassian/jira-polaris-common/src/ui/fields/users';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useUpdateFieldValue } from '../utils';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
	portalElement?: HTMLElement;
};

export const Users = ({ fieldKey, portalElement }: Props) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const selectedIssueId = useSelectedIssue()!;
	const value = usePeopleTransformed(fieldKey, selectedIssueId);
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (field === undefined) {
		return null;
	}
	return (
		<UsersField
			key={selectedIssueId}
			interactive
			value={value}
			verticalLayout
			fieldKey={fieldKey}
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			portalElement={portalElement}
			onUpdate={(users: UserType[]) => {
				const displayUsers: UserFieldValue[] = users.map((user) => ({
					accountId: user.id,
					displayName: user.name || '',
					avatarUrls: {
						'16x16': user.avatarUrl ?? '',
						'24x24': user.avatarUrl ?? '',
						'32x32': user.avatarUrl ?? '',
						'48x48': user.avatarUrl ?? '',
					},
				}));
				updateFieldValue(displayUsers);
			}}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
