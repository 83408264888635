import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	logout: {
		defaultMessage: 'Log out',
		id: 'polaris-ideas.view-header.avatar.logout',
		description: 'Menu item link to trigger logout',
	},
	login: {
		defaultMessage: 'Log in',
		id: 'polaris-ideas.view-header.avatar.login',
		description: 'Login button label for public view',
	},
});
