import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addNotification: {
		id: 'spa-apps.project-settings.notifications-common.notification-page-header.add-notification',
		defaultMessage: 'Add notification',
		description: 'Add notification button text',
	},
	disabledButtonTooltip: {
		id: 'spa-apps.project-settings.notifications-common.notification-page-header.disabled-button-tooltip',
		defaultMessage: 'All notifications have been added',
		description: 'Tool tip text for when add button is disabled',
	},
	fetchEventTypesGenericErrorTitle: {
		id: 'spa-apps.project-settings.notifications-common.notification-page-header.fetch-event-types-generic-error-title',
		defaultMessage: 'An unexpected error occurred',
		description: 'Tool tip text for when add button is disabled',
	},
});
