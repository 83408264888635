import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	saveOption: {
		id: 'polaris-lib-decoration.menu-option.save-option',
		defaultMessage: 'Done',
		description: 'Button to save the currently open option',
	},
	dragHandleLabel: {
		id: 'polaris-lib-decoration.menu-option.drag-handle-label',
		defaultMessage: 'Drag handle',
		description: 'Drag handle icon label',
	},
});
