import { useCallback } from 'react';
import { ACCESS_ROLE_EDITOR_ARI } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { useIsViewPermissionsEnabled } from '@atlassian/jira-polaris-lib-entitlement-utils';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { transformRoleAriToRole } from '../../utils';
import { useActions } from '../main';
import { useHasManageViewsPermission, useViewAccessMap } from '../selectors';

const useLoadViewAccess = (viewUUID: string | undefined, viewId: string | undefined) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { loadCurrentUserViewAccess } = useActions();

	return useCallback(async () => {
		if (!viewUUID) {
			return;
		}

		const result = await loadCurrentUserViewAccess(viewUUID);

		if (result.role) {
			// https://data-portal.internal.atlassian.com/analytics/registry/69714
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData currentUserRoleLoaded', {
				viewUUID,
				viewId,
				role: transformRoleAriToRole(result.role),
			});
		}

		return result;
	}, [createAnalyticsEvent, loadCurrentUserViewAccess, viewId, viewUUID]);
};

export const useCanManageViewBasedOnViewPermissions = (
	viewUUID: string | undefined,
	viewId: string | undefined,
	forceLoading = true,
): { canEdit: boolean; canView: boolean } => {
	const viewAccessMap = useViewAccessMap();
	const canManageViews = useHasManageViewsPermission();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();

	const loadViewAccess = useLoadViewAccess(viewUUID, viewId);

	if (viewUUID === undefined) {
		return { canEdit: false, canView: false };
	}

	if (!isViewPermissionsEnabled) {
		return { canEdit: canManageViews, canView: true };
	}

	const viewAccess = viewAccessMap[viewUUID];

	if (!viewAccess && forceLoading) {
		loadViewAccess();

		return { canEdit: false, canView: true };
	}

	return {
		canEdit:
			canManageViews &&
			viewAccess?.state === 'succeeded' &&
			viewAccess.role === ACCESS_ROLE_EDITOR_ARI,
		canView: viewAccess?.state !== 'no-access',
	};
};

export const useViewAccessLevelBasedOnViewPermissions = (
	viewUUID: string | undefined,
	viewId: string | undefined,
	forceLoading = true,
): ViewAccessLevel | undefined => {
	const viewAccessMap = useViewAccessMap();
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();

	const loadViewAccess = useLoadViewAccess(viewUUID, viewId);

	if (viewUUID === undefined || !isViewPermissionsEnabled) {
		return undefined;
	}

	const viewAccess = viewAccessMap[viewUUID];

	if (!viewAccess && forceLoading) {
		loadViewAccess();

		return undefined;
	}

	if (viewAccess?.state === 'succeeded') {
		return viewAccess.accessLevel;
	}

	return undefined;
};

export const useLoadViewAccessAction = () => {
	const isViewPermissionsEnabled = useIsViewPermissionsEnabled();
	const { loadCurrentUserViewAccess } = useActions();

	if (isViewPermissionsEnabled) {
		return loadCurrentUserViewAccess;
	}

	return undefined;
};
