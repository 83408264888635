import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import type { State } from '../types';
import * as actions from './actions';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'issue-view-realtime-service',
	initialState: {
		events: {},
	},
	actions,
});

export const IssueRefreshServiceStoreContainer = createContainer(store);
export const useIssueRefreshServiceStore = createHook(store);
