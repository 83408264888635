import { defineMessages } from '@atlassian/jira-intl';
import { ENTITY_LIMIT_ERROR_TYPE } from './types';

export default defineMessages({
	/* eslint-disable jira/i18n/id-named-by-package */
	[ENTITY_LIMIT_ERROR_TYPE.COMMENTS_PER_VIEW]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.comments-per-view',
		defaultMessage:
			'We couldn’t create a new <item>comment</item> because this <item>view</item> has reached its limit for <item>comments</item>. Remove <item>comment</item> to create a new <item>comment</item>',
		description: 'Flag description for entity limit error for comments per view',
	},
	[ENTITY_LIMIT_ERROR_TYPE.FIELDS_PER_VIEW]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.fields-per-view',
		defaultMessage:
			'We couldn’t create a new <item>field</item> because this <item>view</item> has reached its limit for <item>fields</item>. Remove <item>field</item> to create a new <item>field</item>',
		description: 'Flag description for entity limit error for fields per view',
	},
	[ENTITY_LIMIT_ERROR_TYPE.FIELDS_PER_PROJECT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.fields-per-project',
		defaultMessage:
			'We couldn’t create a new <item>field</item> because this <item>project</item> has reached its limit for <item>fields</item>. Remove <item>field</item> to create a new <item>field</item>',
		description: 'Flag description for entity limit error for fields per project',
	},
	[ENTITY_LIMIT_ERROR_TYPE.INSIGHTS_PER_IDEA]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.insights-per-idea',
		defaultMessage:
			'We couldn’t create a new <item>insight</item> because this <item>idea</item> has reached its limit for <item>insights</item>. Remove <item>insight</item> to create a new <item>insight</item>',
		description: 'Flag description for entity limit error for insights per idea',
	},
	[ENTITY_LIMIT_ERROR_TYPE.INSIGHTS_PER_PROJECT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.insights-per-project',
		defaultMessage:
			'We couldn’t create a new <item>insight</item> because this <item>project</item> has reached its limit for <item>insights</item>. Remove <item>insight</item> to create a new <item>insight</item>',
		description: 'Flag description for entity limit error for insights per project',
	},
	[ENTITY_LIMIT_ERROR_TYPE.LABELS_PER_SNIPPET]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.labels-per-snippet',
		defaultMessage:
			'We couldn’t create a new <item>label</item> because this <item>snippet</item> has reached its limit for <item>labels</item>. Remove <item>label</item> to create a new <item>label</item>',
		description: 'Flag description for entity limit error for labels per snippet',
	},
	[ENTITY_LIMIT_ERROR_TYPE.OPTIONS_PER_FIELD]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.options-per-field',
		defaultMessage:
			'We couldn’t create a new <item>option</item> because this <item>field</item> has reached its limit for <item>options</item>. Remove <item>option</item> to create a new <item>option</item>',
		description: 'Flag description for entity limit error for options per field',
	},
	[ENTITY_LIMIT_ERROR_TYPE.PLAY_CONTRIBUTIONS_PER_PLAY]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.play-contributions-per-play',
		defaultMessage:
			'We couldn’t create a new <item>vote contribution</item> because this <item>vote</item> has reached its limit for <item>vote contributions</item>. Remove <item>vote contribution</item> to create a new <item>vote contribution</item>',
		description: 'Flag description for entity limit error for play contributions per play',
	},
	[ENTITY_LIMIT_ERROR_TYPE.PLAY_CONTRIBUTIONS_PER_PROJECT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.play-contributions-per-project',
		defaultMessage:
			'We couldn’t create a new <item>vote contribution</item> because this <item>project</item> has reached its limit for <item>vote contributions</item>. Remove <item>vote contribution</item> to create a new <item>vote contribution</item>',
		description: 'Flag description for entity limit error for play contributions per project',
	},
	[ENTITY_LIMIT_ERROR_TYPE.PLAYS_PER_PROJECT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.plays-per-project',
		defaultMessage:
			'We couldn’t create a new <item>vote</item> because this <item>project</item> has reached its limit for <item>votes</item>. Remove <item>vote</item> to create a new <item>vote</item>',
		description: 'Flag description for entity limit error for plays per project',
	},
	[ENTITY_LIMIT_ERROR_TYPE.SNIPPETS_PER_INSIGHT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.snippets-per-insight',
		defaultMessage:
			'We couldn’t create a new <item>snippet</item> because this <item>insight</item> has reached its limit for <item>snippets</item>. Remove <item>snippet</item> to create a new <item>snippet</item>',
		description: 'Flag description for entity limit error for snippets per insight',
	},
	[ENTITY_LIMIT_ERROR_TYPE.VIEWS_PER_PROJECT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.views-per-project',
		defaultMessage:
			'We couldn’t create a new <item>view</item> because this <item>project</item> has reached its limit for <item>views</item>. Remove <item>view</item> to create a new <item>view</item>',
		description: 'Flag description for entity limit error for views per project',
	},
	[ENTITY_LIMIT_ERROR_TYPE.VIEWSETS_PER_PROJECT]: {
		id: 'polaris-lib-errors.controllers.entity-limit-error.viewsets-per-project',
		defaultMessage:
			'We couldn’t create a new <item>section</item> because this <item>project</item> has reached its limit for <item>sections</item>. Remove <item>section</item> to create a new <item>section</item>',
		description: 'Flag description for entity limit error for viewsets per project',
	},
	/* eslint-enable jira/i18n/id-named-by-package */
});
