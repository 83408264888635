import log from '@atlassian/jira-common-util-logging/src/log';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

export const logError = (errorSubject: string, error: Error) => {
	const ERROR_ID = 'polaris-error';

	fireErrorAnalytics({
		meta: {
			id: ERROR_ID,
			teamName: 'polaris',
		},
		attributes: {
			subject: errorSubject,
		},
		error,
	});
	log.safeErrorWithoutCustomerData(`${ERROR_ID}.${errorSubject}`, error.message, error);
};

export const isPendoReady = (): boolean =>
	window?.pendo?.isReady && typeof window.pendo.isReady === 'function' && window.pendo.isReady();
