import every from 'lodash/every';
import isFunction from 'lodash/isFunction';
import some from 'lodash/some';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DAY } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { createDateFilterFunction } from '../interval/filter';
import { numberMapping } from '../number';
import type { FieldMapping } from '../types';

export const numDataPointsMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<number> => {
	const numberMappings = numberMapping(issuesRemote, field);
	return {
		...numberMappings,
		getFilter: (filter) => {
			if (filter.type === 'INTERVAL' && filter.field === field.key) {
				const filterFunctions = filter.values.map(createDateFilterFunction(DAY));
				return (value, state, props, localIssueId) => {
					if (!filterFunctions.length) {
						return true;
					}
					const dates = state.dynamicProperties.dateValue[field.key](localIssueId)?.(state, props);
					return every(filterFunctions, (filterFunction) =>
						some(dates, (date) => filterFunction(date, state, props, localIssueId)),
					);
				};
			}
			return (value, state, props, localIssueId) => {
				const filterFunc = numberMappings.getFilter(filter);
				if (isFunction(filterFunc)) {
					let valueInternal = value;
					if (
						value === 0 &&
						filter.type === 'NUMBER' &&
						filter.values?.length &&
						filter.values[0].operator === 'EQ'
					) {
						valueInternal = undefined; // POL-5290 Keep behavior for empty filter
					}
					return filterFunc(valueInternal, state, props, localIssueId);
				}
				return true;
			};
		},
	};
};
