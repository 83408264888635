import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ViewDescription } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import { getViewUUID } from '../utils';

export type FetchViewDescriptionResponse = {
	description: ViewDescription;
};

export const fetchCollectionViewDescription = async (
	collectionUUID: string,
	viewId: string,
): Promise<{ description: ViewDescription }> => {
	const description = await fetchJson<ViewDescription>(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${getViewUUID(viewId)}/description`,
	);

	return { description };
};
