import { POLARIS_OAUTH_CLIENT_ID } from '../../../../../../../common/types/snippet/constants';
import { type RollupField, COUNT_ROLLUP, PROPERTY_ROLLUP } from '../../types';

export const getAppName = (oauthClientId: string, appName: string): string =>
	oauthClientId === POLARIS_OAUTH_CLIENT_ID ? '' : appName;

export const getAppNameForRollupField = (field: RollupField): string | undefined =>
	field.kind === COUNT_ROLLUP || field.kind === PROPERTY_ROLLUP
		? getAppName(field.value.oauthClientId, field.value.appName)
		: undefined;
