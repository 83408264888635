import React, { memo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { CellProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { FieldDescription } from './description';
import { FieldName } from './name';
import { FieldSettings } from './settings';
import { FieldType } from './type';

export const Cell = memo(({ columnId, rowId, isFirstRow }: CellProps) => {
	const key = `${columnId}-${rowId}`;

	switch (columnId) {
		case 'label':
			return (
				<Box key={key} xcss={cellStyles}>
					<FieldName fieldKey={rowId} />
				</Box>
			);
		case 'fieldType':
			return (
				<Box key={key} xcss={cellStyles}>
					<FieldType fieldKey={rowId} />
				</Box>
			);
		case 'description':
			return (
				<Box key={key} xcss={cellStyles}>
					<FieldDescription fieldKey={rowId} />
				</Box>
			);
		case 'fieldSettings':
			return (
				<Box key={key} xcss={[cellStyles, settingsCellStyles]}>
					<FieldSettings fieldKey={rowId} isFirstRow={isFirstRow} />
				</Box>
			);
		default:
			return null;
	}
});

const cellStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
});

const settingsCellStyles = xcss({
	textAlign: 'center',
	// Makes the active button state visible and prevents it from being cut
	padding: 'space.025',
});

Cell.displayName = 'Cell';
