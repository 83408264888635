import {
	add,
	endOfMonth,
	isBefore,
	isSameDay,
	startOfDay,
	endOfDay,
	parseISO,
	startOfMonth,
	sub,
} from 'date-fns';
import {
	type IntervalFieldFilterOperator,
	type INTERVAL_PERIOD,
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
	DAY,
	MONTH,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { MappingFilterFunction } from '../../types';

type IntervalFilter = (startDate: Date, endDate: Date, now?: Date) => boolean;
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
type IntervalStringFilter = (value: string | void) => boolean;

export const createIntervalStartBeforeNowFilterFunction =
	(interval: number, pediod: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (pediod) {
			case MONTH:
				horizon = startOfMonth(sub(now, { months: interval }));
				break;
			case DAY:
				horizon = startOfDay(sub(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return (
			isSameDay(startDate, now) ||
			isSameDay(startDate, horizon) ||
			(isBefore(horizon, startDate) && isBefore(startDate, now))
		);
	};

export const createIntervalStartAfterNowFilterFunction =
	(interval: number, pediod: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (pediod) {
			case MONTH:
				horizon = endOfMonth(add(now, { months: interval }));
				break;
			case DAY:
				horizon = endOfDay(add(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return (
			isSameDay(startDate, now) ||
			isSameDay(startDate, horizon) ||
			(isBefore(now, startDate) && isBefore(startDate, horizon))
		);
	};

export const createIntervalEndBeforeNowFilterFunction =
	(interval: number, pediod: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (pediod) {
			case MONTH:
				horizon = startOfMonth(sub(now, { months: interval }));
				break;
			case DAY:
				horizon = startOfDay(sub(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return (
			isSameDay(endDate, now) ||
			isSameDay(endDate, horizon) ||
			(isBefore(horizon, endDate) && isBefore(endDate, now))
		);
	};

export const createIntervalEndAfterNowFilterFunction =
	(interval: number, pediod: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (pediod) {
			case MONTH:
				horizon = endOfMonth(add(now, { months: interval }));
				break;
			case DAY:
				horizon = endOfDay(add(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return (
			isSameDay(endDate, now) ||
			isSameDay(endDate, horizon) ||
			(isBefore(now, endDate) && isBefore(endDate, horizon))
		);
	};

const createStringParsingIntervalFilter =
	(wrappedIntervalFilter: IntervalFilter): IntervalStringFilter =>
	(value) => {
		try {
			if (value === undefined) {
				return false;
			}
			const interval = JSON.parse(value);
			return wrappedIntervalFilter(parseISO(interval.start), parseISO(interval.end));
		} catch (err) {
			return false;
		}
	};

const createStringParsingDateFilter =
	(wrappedIntervalFilter: IntervalFilter): IntervalStringFilter =>
	(value) => {
		try {
			if (value === undefined) {
				return false;
			}
			return wrappedIntervalFilter(parseISO(value), parseISO(value));
		} catch (err) {
			return false;
		}
	};

const getWrappedFilterFunction = (
	filterValueOperator: IntervalFieldFilterOperator,
	pediod: INTERVAL_PERIOD,
) => {
	switch (filterValueOperator.operator) {
		case START_BEFORE_NOW:
			return createIntervalStartBeforeNowFilterFunction(filterValueOperator.numericValue, pediod);
		case START_AFTER_NOW:
			return createIntervalStartAfterNowFilterFunction(filterValueOperator.numericValue, pediod);
		case END_BEFORE_NOW:
			return createIntervalEndBeforeNowFilterFunction(filterValueOperator.numericValue, pediod);
		case END_AFTER_NOW:
			return createIntervalEndAfterNowFilterFunction(filterValueOperator.numericValue, pediod);
		default:
			return () => true;
	}
};

export const createFilterFunction =
	(pediod: INTERVAL_PERIOD) =>
	(filterValueOperator: IntervalFieldFilterOperator): MappingFilterFunction<string> =>
		createStringParsingIntervalFilter(getWrappedFilterFunction(filterValueOperator, pediod));

export const createDateFilterFunction =
	(pediod: INTERVAL_PERIOD) =>
	(filterValueOperator: IntervalFieldFilterOperator): MappingFilterFunction<string> =>
		createStringParsingDateFilter(getWrappedFilterFunction(filterValueOperator, pediod));
