export const getCounterDisplay = (count: number, maxValue = 99): string => {
	if (count <= 0) {
		return '';
	}

	if (count > maxValue) {
		return `${maxValue}+`;
	}

	return `${count}`;
};
