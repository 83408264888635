import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { FetchFieldsResponse } from './types';

const getFieldsUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v1'
	}/views/${viewId}/fields`;

export const getFields = (viewId: string): Promise<FetchFieldsResponse> => {
	if (viewId === undefined) {
		return Promise.reject(new Error('viewId must be supplied, cannot load fields'));
	}
	return fetchJson(getFieldsUrl(viewId));
};
