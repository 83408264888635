import { createHook } from '@atlassian/react-sweet-state';
import type { EntityLimitType, EntityLimits } from '../../../common/types';
import { EntityLimitsStore, type Actions } from '../main';
import type { State } from '../types';
import { getEntityLimits } from './entity-limits';

export const useEntityLimits = createHook<State, Actions, EntityLimits | null>(EntityLimitsStore, {
	selector: getEntityLimits,
});

// Get the limit for a specific entity type
export const useEntityLimitsByType = (limitType: EntityLimitType) => {
	const [entityLimits] = useEntityLimits();
	return entityLimits?.[limitType] ?? null;
};
