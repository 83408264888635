import { createSelector } from 'reselect';
import differenceWith from 'lodash/differenceWith';
import flatten from 'lodash/flatten';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { isBefore } from 'date-fns';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import { hasFieldKeyDependingOnDeliveryData } from '@atlassian/jira-polaris-domain-field/src/delivery/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import {
	type Filter,
	type TextFilter,
	GENERIC_FIELD_FILTER,
	NUMERIC_FIELD_FILTER,
	INTERVAL_FIELD_FILTER,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type {
	ViewSetType,
	ViewSet,
} from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import {
	VIEW_KIND_TABLE,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_BOARD,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import {
	type LocalViewId,
	type ViewKind,
	type View,
	ViewLayoutType,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { NUMBER_COMMENT_FORMULA_KEY } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StatusCategoryMap } from '../../../../common/types/status-category';
import { findView } from '../../actions/utils/views';
import type { Props, State } from '../../types';
import { getFields } from '../fields';
import {
	hasVerticalGroupByUnsavedChanges,
	hasGroupByUnsavedChanges,
	hasFiltersUnsavedChanges,
	hasSortUnsavedChanges,
	isCurrentViewAutosaveEnabled,
	hasFieldRollupUnsavedChanges,
	hasFieldsUnsavedChanges,
	hasMatrixXAxisUnsavedChanges,
	hasMatrixYAxisUnsavedChanges,
	hasMatrixZAxisUnsavedChanges,
	hasTimelineConfigUnsavedChanges,
	hasViewUnsavedChanges,
	getCurrentViewDraft,
	hasLayoutTypeUnsavedChanges,
} from './autosave';
import { getCurrentView } from './current';
import {
	getMatrixViewConfigured,
	getCurrentViewXAxisField,
	getCurrentViewYAxisField,
	getCurrentViewZAxisField,
	getCurrentViewXAxis,
	getCurrentViewYAxis,
	getCurrentViewZAxis,
	getMatrixFieldOptions,
	getMatrixOptionsHasReverseOrder,
} from './matrix';
import { getTimelineViewConfigured } from './timeline';
import { getPinnedViewFields, getPinnedViewId } from './view-sets';

export { hasSharedViewConfigError } from './current';
export {
	getCurrentViewTableColumnSizes,
	getCurrentViewTableColumnSizesByFieldKey,
	getCurrentViewTableColumnSize,
} from './list';
export {
	getCurrentViewXAxisField,
	getCurrentViewYAxisField,
	getCurrentViewZAxisField,
	getCurrentViewXAxis,
	getCurrentViewYAxis,
	getCurrentViewZAxis,
	getMatrixFieldOptions,
	getMatrixOptionsHasReverseOrder,
};
export {
	getCurrentViewArrangementInformation,
	getCurrentViewTimelineEndDateField,
	getCurrentViewTimelineIntervalStartDate,
	getCurrentViewTimelineIntervalEndDate,
	getCurrentViewTimelineModeOrDefault,
	getCurrentViewTimelineStartDateField,
	getCurrentViewSummaryCardFieldKey,
	getCurrentViewSummaryCardField,
	getCurrentViewTimelineMarkers,
} from './timeline';
export {
	getIsCurrentViewInCaptureSet,
	createGetViewIdsInSet,
	createGetViewSet,
	getSystemViewsContainingArchived,
	getSystemViewsNotContainingArchived,
	getCurrentViewViewSetId,
	getAllIdeasViewId,
	getViewSlugByRank,
	getPinnedViewFields,
	getPinnedViewId,
	getViewIdsConfiguredByField,
	getHasViewSetsError,
	getPrioritizeViews,
} from './view-sets';
export {
	isManualIdeaRankingPossibleInCurrentView,
	getCurrentViewIssueRanking,
	isCurrentViewSortModeViewRank,
	getCurrentViewSortMode,
	isViewInViewRankSortMode,
} from './view-rank';
export { getCurrentView };
export { getCanManageCurrentView } from './permissions';
export {
	hasVerticalGroupByUnsavedChanges,
	hasGroupByUnsavedChanges,
	hasFiltersUnsavedChanges,
	hasSortUnsavedChanges,
	isCurrentViewAutosaveEnabled,
	hasFieldRollupUnsavedChanges,
	hasFieldsUnsavedChanges,
	hasMatrixXAxisUnsavedChanges,
	hasMatrixYAxisUnsavedChanges,
	hasMatrixZAxisUnsavedChanges,
	hasTimelineConfigUnsavedChanges,
	hasViewUnsavedChanges,
	getCurrentViewDraft,
	hasLayoutTypeUnsavedChanges,
};

const EMPTY: Array<Field> = [];
const EMPTY_FIELD_KEYS: Array<FieldKey> = [];

export const getHasCurrentView = createSelector(getCurrentView, (view) => view !== undefined);

export const getCurrentUser = (state: State, props?: Props): AccountId | undefined =>
	props?.currentUser;

export const getViewById = (state: State, localViewIdOrUuid: LocalViewId | string) =>
	findView(
		state.viewSets,
		({ id, uuid }) => id === localViewIdOrUuid || uuid === localViewIdOrUuid,
	);

export const getViewBySlug = (state: State, viewSlug: string) =>
	findView(state.viewSets, ({ slug }) => slug === viewSlug);

export const createGetViewByAri = (ari?: Ari) =>
	createSelector(
		(state: State) => state.viewSets,
		(viewSets) => findView(viewSets, ({ viewId }) => viewId === ari),
	);

const createGetViewById = (viewId?: LocalViewId) =>
	createSelector(
		(state: State) => state.viewSets,
		(viewSets) => findView(viewSets, ({ id }) => id === viewId),
	);

export const createGetViewUUID = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.uuid);

export const createGetViewAri = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.viewId);

export const createGetViewEmoji = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.emoji);

export const createGetViewSlug = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.slug);

export const createGetViewKind = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.kind);

export const createGetViewTitle = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.title);

export const createGetViewContainsArchived = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) =>
		view?.containsArchived !== undefined ? view.containsArchived : false,
	);

export const createGetViewFilter = (viewAri?: string) =>
	createSelector(createGetViewByAri(viewAri), (view) => view?.filter);

export const createGetViewFilterLength = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.filter.length);

export const createGetViewFilterTypes = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), getFields, (view, allFields) =>
		view?.filter
			.filter((f: Filter) => f.type === 'FIELD')
			.map((f) => {
				if ('field' in f && f.field !== undefined && allFields[f.field] !== undefined) {
					return allFields[f.field].type;
				}
				return f.type;
			}),
	);

const createGetViewFieldKeysRaw = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.fields);

const createGetViewFieldKeysRawByViewAri = (viewAri?: string) =>
	createSelector(createGetViewByAri(viewAri), (view) => view?.fields);

const createGetViewFields = (viewId?: LocalViewId) =>
	createSelector(createGetViewFieldKeysRaw(viewId), getFields, (fieldKeys, fields) => {
		if (fieldKeys === undefined) {
			return EMPTY;
		}
		return fieldKeys
			.filter((fieldKey) => has(fields, fieldKey))
			.map((fieldKey) => fields[fieldKey]);
	});

const createGetViewFieldsByAri = (viewAri?: string) =>
	createSelector(createGetViewFieldKeysRawByViewAri(viewAri), getFields, (fieldKeys, fields) => {
		if (fieldKeys === undefined) {
			return EMPTY;
		}
		return fieldKeys
			.filter((fieldKey) => has(fields, fieldKey))
			.map((fieldKey) => fields[fieldKey]);
	});

export const createGetViewFieldKeysByAri = (viewAri?: string) =>
	createSelector(createGetViewFieldsByAri(viewAri), (viewFields) => viewFields.map((f) => f.key));
export const createGetViewFieldTypes = (viewId?: LocalViewId) =>
	createSelector(createGetViewFields(viewId), (viewFields) => viewFields.map((f) => f.type));
export const createGetViewFieldKeys = (viewId?: LocalViewId) =>
	createSelector(createGetViewFields(viewId), (viewFields) => viewFields.map((f) => f.key));

export const createGetViewSort = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.sortBy);

export const createGetViewGroupBy = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), getFields, (view, fields) =>
		view?.groupBy !== undefined ? fields[view.groupBy] : undefined,
	);

export const createGetViewGroupValues = (viewId?: LocalViewId) =>
	createSelector(createGetViewById(viewId), (view) => view?.groupValues);

export const createGetViewSortFieldTypes = (viewId?: LocalViewId) =>
	createSelector(createGetViewSort(viewId), getFields, (sortBy, allFields) =>
		sortBy !== undefined
			? sortBy
					.filter((sf) => allFields[sf.fieldKey])
					.map((sf) => allFields[sf.fieldKey])
					.map((sf) => sf.type)
			: ['rank'],
	);

export const isActionField = ({ type }: Field) =>
	[FIELD_TYPES.ISSUE_COMMENTS, FIELD_TYPES.VOTES, FIELD_TYPES.REACTIONS, FIELD_TYPES.INSIGHTS].some(
		(t) => t === type,
	);

const isIdeaSharingUnshareableFields = (field: Field) => {
	const { type } = field;
	return (
		isActionField(field) ||
		type === FIELD_TYPES.STATUS ||
		type === FIELD_TYPES.ASSIGNEE ||
		type === FIELD_TYPES.ATLAS_GOAL ||
		type === FIELD_TYPES.ATLAS_PROJECT ||
		type === FIELD_TYPES.ATLAS_PROJECT_STATUS ||
		type === FIELD_TYPES.CREATOR
	);
};

const isValidFieldForIssueSubmissionForm = (field: Field) =>
	!isIdeaSharingUnshareableFields(field) && field.formula === undefined;

export const createGetValidIssueSubmissionFormFieldKeys = (viewAri?: string) =>
	createSelector(createGetViewFieldsByAri(viewAri), (fields) =>
		fields.filter((field) => isValidFieldForIssueSubmissionForm(field)).map((field) => field.key),
	);

export const createGetInvalidIssueSubmissionFormFieldLabels = (viewAri?: string) =>
	createSelector(createGetViewFieldsByAri(viewAri), (fields) =>
		fields
			.filter((field) => !isValidFieldForIssueSubmissionForm(field))
			.map((field) => field.label),
	);

export const getCurrentViewFieldKeys = createSelector(
	getFields,
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(fields, view, draft, isAutosaveEnabled) => {
		if (isAutosaveEnabled) {
			const viewFields = view?.fields?.filter((viewField) => fields[viewField]);
			return viewFields ?? EMPTY_FIELD_KEYS;
		}
		const draftFields = draft?.fields?.filter((viewField) => fields[viewField]);
		return draftFields ?? EMPTY_FIELD_KEYS;
	},
);

export const isDeliveryFieldVisibleInCurrentView = createSelector(
	getCurrentViewFieldKeys,
	getFields,
	hasFieldKeyDependingOnDeliveryData,
);

export const getCurrentViewHiddenFieldKeys = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view?.hidden : draft?.hidden) ?? EMPTY_FIELD_KEYS,
);

export const getCurrentViewLastCommentsViewedTimestamp = createSelector(
	getCurrentView,
	(view) => view?.lastCommentsViewedTimestamp,
);

export const getCurrentViewCollapsedSwimlanes = createSelector(
	getCurrentView,
	(view) => view?.collapsedSwimlanes || {},
);

export const areCurrentViewAllSwimlanesCollapsed = createSelector(
	getCurrentViewCollapsedSwimlanes,
	(collapsedSwimlanes) => Object.values(collapsedSwimlanes).every((isCollapsed) => isCollapsed),
);

export const getAllViewFields = createSelector(
	(state: State) => state.viewSets,
	(viewSets) => {
		const viewSet = viewSets.find((set) => set.type === 'PRIORITIZE');
		if (!viewSet) {
			return [];
		}
		const fieldsUsedInViews = viewSet.views.map((view) => view.fields).flat();
		return uniq(fieldsUsedInViews);
	},
);

export const getCurrentViewFields = createSelector(
	getCurrentViewFieldKeys,
	getFields,
	(fieldKeys, fields) => {
		if (fieldKeys === undefined) {
			return EMPTY;
		}
		return fieldKeys
			.filter((fieldKey) => has(fields, fieldKey))
			.map((fieldKey) => fields[fieldKey]);
	},
);

export const getCurrentViewHiddenFields = createSelector(
	getCurrentViewHiddenFieldKeys,
	getFields,
	(fieldKeys, fields) => {
		if (fieldKeys === undefined) {
			return EMPTY;
		}
		return fieldKeys
			.filter((fieldKey) => has(fields, fieldKey))
			.map((fieldKey) => fields[fieldKey]);
	},
);

export const getCurrentViewVisibleFields = createSelector(
	getCurrentViewHiddenFields,
	getCurrentViewFields,
	(hiddenFields, fields) => differenceWith(fields, hiddenFields, (a, b) => a.key === b.key),
);

export const getCurrentViewVisibleFieldKeys = createSelector(
	getCurrentViewVisibleFields,
	(fields) => fields.map(({ key }) => key),
);

export const getCurrentViewVisibleIssueActionFields = createSelector(
	getCurrentViewVisibleFields,
	(visibleFields) => visibleFields.filter(isActionField),
);

export const getCurrentViewVisibleIssueActionFieldKeys = createSelector(
	getCurrentViewVisibleIssueActionFields,
	(actionFields) => actionFields.map(({ key }) => key),
);

export const getCurrentViewFieldCount = createSelector(
	getCurrentViewFields,
	(fields) => fields.length,
);

export const getCurrentViewId = createSelector(getCurrentView, (view) => view?.id);

export const getCurrentViewSlug = createSelector(getCurrentView, (view) => view?.slug);

export const getCurrentViewContainsArchived = createSelector(
	getCurrentView,
	(view) => view?.containsArchived || false,
);

export const getCurrentViewProjectId = createSelector(getCurrentView, (view) => view?.projectId);

export const getCurrentViewAri = createSelector(getCurrentView, (view) => view?.viewId);

export const getCurrentViewKind = createSelector(getCurrentView, (view) => view?.kind);

export const getCurrentViewSortBy = createSelector(
	getFields,
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(fields, view, draft, isAutosaveEnabled) => {
		if (isAutosaveEnabled) {
			return view?.sortBy?.filter((sortField) => fields[sortField.fieldKey]);
		}
		return draft?.sortBy?.filter((sortField) => fields[sortField.fieldKey]);
	},
);

export const getCurrentViewSortFields = createSelector(
	getCurrentViewSortBy,
	getFields,
	(currentViewSortBy = [], fieldsByKey) =>
		currentViewSortBy.map(({ fieldKey }) => fieldsByKey[fieldKey]),
);

export const getCurrentViewSortByField = (fieldKey?: FieldKey) =>
	createSelector(getCurrentViewSortBy, (sortBy) =>
		fieldKey === undefined
			? undefined
			: sortBy?.find((sortItem: SortField) => sortItem.fieldKey === fieldKey),
	);

export const getCurrentViewSortByFieldCount = (state: State, props?: Props): number =>
	getCurrentViewSortBy(state, props)?.length || 0;

export const getPinnedViewActionFieldKeys = createSelector(
	getFields,
	getPinnedViewFields,
	(fields, pinnedFieldKeys) =>
		pinnedFieldKeys.filter((fieldKey) => isActionField(fields[fieldKey])),
);

export const isLastView = createSelector(
	(state: State) => state.viewSets,
	(viewSets) => {
		let viewCount = 0;
		viewSets.forEach((viewSet) => {
			viewCount += viewSet.views.length;
		});
		return viewCount < 2;
	},
);

export const getArchiveView = createSelector(
	(state: State) => state.viewSets,
	(viewSets) => findView(viewSets, (v) => v.containsArchived),
);

const getFilterFields = (view: View): string[] => {
	const filterFields = view?.filter.filter(
		(f): f is Exclude<Filter, TextFilter> =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(f as Exclude<Filter, TextFilter>).field !== undefined,
	);

	return filterFields.map((f) => f.field);
};

// <type>(:<source>)(.<configuration>)
const fieldToCompositeFieldType = (field: Field): string | undefined => {
	if (!field) {
		return;
	}
	if (field.type === FIELD_TYPES.INTERVAL) {
		const sourceOrEmpty = field?.configuration?.source ? `:${field.configuration?.source}` : '';
		const aggregationOrEmpty = field?.configuration?.aggregationType
			? `.${field.configuration?.aggregationType}`
			: '';

		return `${field.type}${sourceOrEmpty}${aggregationOrEmpty}`;
	}

	return field.type;
};

const getGlobalFieldsFromViewFields = (
	viewFields: {
		viewSortFields: SortField[] | undefined;
		viewGroupByField: string | undefined;
		viewShownFields: string[];
		viewFilterFields: string[];
		viewColumnField: string | undefined;
		viewXAxisField: string | undefined;
		viewYAxisField: string | undefined;
		viewZAxisField: string | undefined;
		viewTimelineStartDateField: string | undefined;
		viewTimelineTargetDateField: string | undefined;
	},
	allFields: FieldsByKey,
) =>
	uniq(
		[
			...(viewFields.viewSortFields || []).map((f) => f.fieldKey),
			viewFields.viewGroupByField,
			...viewFields.viewShownFields,
			...viewFields.viewFilterFields,
			viewFields.viewColumnField,
			viewFields.viewXAxisField,
			viewFields.viewYAxisField,
			viewFields.viewZAxisField,
			viewFields.viewTimelineStartDateField,
			viewFields.viewTimelineTargetDateField,
		]
			.filter(Boolean)
			.filter((fieldKey) => allFields[fieldKey]?.global && allFields[fieldKey]?.custom),
	);

export const getIsViewGeneratedByTemplate = (view: View, projectOnboardedAt?: string) =>
	view.createdAtTimestamp && projectOnboardedAt
		? isBefore(new Date(view.createdAtTimestamp), new Date(projectOnboardedAt))
		: undefined;

export const extractViewAnalyticsData = (
	view: View | undefined,
	isShared = false,
	projectOnboardedAt?: string,
	fields: FieldsByKey = {},
) => {
	if (!view) {
		return undefined;
	}

	const fieldTypes = view.fields.map((fieldKey) => fieldToCompositeFieldType(fields[fieldKey]));

	const viewFields = {
		viewId: view.slug,
		viewSortFields: view.sortBy,
		viewGroupByField: view.verticalGroupBy,
		viewShownFields: view.fields,
		viewFilterFields: getFilterFields(view),
		viewColumnField: view.groupBy,
		viewXAxisField: view.matrixConfig?.axes?.find((axis) => axis.dimension === 'x')?.field?.key,
		viewYAxisField: view.matrixConfig?.axes?.find((axis) => axis.dimension === 'y')?.field?.key,
		viewZAxisField: view.matrixConfig?.axes?.find((axis) => axis.dimension === 'z')?.field?.key,
		viewTimelineStartDateField: view.timelineConfig?.startDateField?.key,
		viewTimelineTargetDateField: view.timelineConfig?.dueDateField?.key,
	};

	const viewGlobalFields = getGlobalFieldsFromViewFields(viewFields, fields);

	return {
		containers: {
			view: {
				id: view.viewId ?? '',
				type: view.kind.toLowerCase(),
			},
		},
		attributes: {
			...viewFields,
			viewGlobalFields,
			viewHasDescription: !!view.description,
			viewShownFieldTypes: fieldTypes,
			viewTimelineGranularity: view.timelineConfig?.mode,
			viewIsTemplateGenerated: getIsViewGeneratedByTemplate(view, projectOnboardedAt),
			viewOpenedAsPublished: isShared,
			cardLayout: view.layoutType,
		},
	};
};

export const getViewAnalyticsData = createSelector(
	getCurrentView,
	(state: State, props?: Props) => props?.isSharedView,
	(state: State, props?: Props) => props?.projectOnboardedAt,
	getFields,
	extractViewAnalyticsData,
);

export const getCurrentViewGroupBy = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	getFields,
	isCurrentViewAutosaveEnabled,
	(view, draft, fields, isAutosaveEnabled) => {
		const groupByFieldKey = isAutosaveEnabled ? view?.groupBy : draft?.groupBy;

		return groupByFieldKey !== undefined ? fields[groupByFieldKey] : undefined;
	},
);

export const getCurrentHideEmptyGroups = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) => (isAutosaveEnabled ? view : draft)?.hideEmptyGroups || false,
);

export const getCurrentHideEmptyBoardColumns = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) => (isAutosaveEnabled ? view : draft)?.hideEmptyColumns || false,
);

export const getCurrentViewVerticalGroupBy = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	getFields,
	isCurrentViewAutosaveEnabled,
	(view, draft, fields, isAutosaveEnabled) => {
		const verticalGroupByFieldKey = isAutosaveEnabled
			? view?.verticalGroupBy
			: draft?.verticalGroupBy;

		return verticalGroupByFieldKey !== undefined ? fields[verticalGroupByFieldKey] : undefined;
	},
);

const EMPTY_GROUPS: Array<GroupValue> = [];
export const getCurrentViewGroupValues = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view?.groupValues : draft?.groupValues) || EMPTY_GROUPS,
);

export const getCurrentViewVerticalGroupValues = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view?.verticalGroupValues : draft?.verticalGroupValues) || EMPTY_GROUPS,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY_COLLECTION: Record<string, any> = {};
export const getCurrentViewSelectedIssueIds = createSelector(
	getCurrentView,
	(view) => view?.selectedIssues || EMPTY_COLLECTION,
);

export const getCurrentViewBulkEditHoveredFieldKey = createSelector(
	getCurrentView,
	(view) => view?.bulkEditHoveredFieldKey,
);

export const getCurrentViewSelectedIssueIdsAsList = createSelector(getCurrentView, (view) =>
	keys(view?.selectedIssues || EMPTY_COLLECTION),
);

export const getCurrentViewTitle = createSelector(getCurrentView, (view) => view?.title);
export const getCurrentViewUUID = createSelector(getCurrentView, (view) => view?.uuid);

export const getCurrentViewEmoji = createSelector(getCurrentView, (view) => view?.emoji);

export const getCurrentViewDescription = createSelector(
	getCurrentView,
	(view) => view?.description,
);

export const getIsCurrentViewDescriptionEmpty = createSelector(
	getCurrentView,
	(view) => !view?.description?.content?.length,
);

export const getCurrentViewLastViewed = createSelector(getCurrentView, (view) => view?.lastViewed);

export const getUserHasVisitedCurrentView = createSelector(
	getCurrentView,
	(view) => view?.hasUserVisited ?? true,
);

export const getAutoOpenSidebarCondition = createSelector(
	getIsCurrentViewDescriptionEmpty,
	getUserHasVisitedCurrentView,
	(isDescriptionEmpty, hasUserVisited) => !isDescriptionEmpty && !hasUserVisited,
);

export const getCurrentViewIsEditingTitle = createSelector(
	getCurrentView,
	(view) => view?.isEditingTitle || false,
);

export const getArchiveViewId = createSelector(getArchiveView, (view) => view?.id);

const extractViewsFromSet = (viewSet: ViewSet): View[] =>
	flatten([...viewSet.views, ...(viewSet.viewSets || []).map(extractViewsFromSet)]);

const extractViewSetsFromSet = (viewSet: ViewSet): ViewSet[] =>
	flatten([viewSet, ...(viewSet.viewSets || []).map(extractViewSetsFromSet)]);

const getAllViewsFlat = createSelector(
	(state: State) => state.viewSets,
	(sets) => flatten([...sets.map(extractViewsFromSet)]),
);

const getAllViewSetsFlat = createSelector(
	(state: State) => state.viewSets,
	(sets) => flatten(sets.map(extractViewSetsFromSet)),
);

const createGetViewSetById = (id: Ari) =>
	createSelector(getAllViewSetsFlat, (viewSets) => viewSets.find((element) => element.id === id));

const getAllViewsFromViewSetTypes = (types: ViewSetType[]) =>
	createSelector(
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		(state) => state.viewSets,
		(sets) =>
			flatten([
				...sets.filter((set: ViewSet) => types.includes(set.type)).map(extractViewsFromSet),
			]),
	);

export const getViewsCountFromViewSetTypes = (types: ViewSetType[]) =>
	createSelector(getAllViewsFromViewSetTypes(types), (views: View[]) => views.length);

export const createGetViewSetName = (id: Ari) =>
	createSelector(createGetViewSetById(id), (set) => set?.name);

export const getViewTypeCount = (kind: ViewKind) =>
	createSelector(getAllViewsFlat, (all) => all.filter((view) => view.kind === kind).length);

export const isCurrentViewGroupedList = createSelector(
	getCurrentView,
	getCurrentViewVerticalGroupBy,
	(view, verticalGroupBy) => view?.kind === VIEW_KIND_TABLE && verticalGroupBy !== undefined,
);

export const getIsExporting = (state: State): boolean => state.isExporting;

export const getIsReadyToExport = (state: State): boolean => state.isReadyToExport;

export const getHasUnsavedChanges = (state: State): boolean => state.hasUnsavedChanges;

export const getCurrentViewLayoutType = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		(isAutosaveEnabled ? view?.layoutType : draft?.layoutType) ??
		(view?.kind === VIEW_KIND_TIMELINE ? ViewLayoutType.SUMMARY : ViewLayoutType.DETAILED),
);

export const getAllViewsNameAndIds = createSelector(
	getAllViewsFlat,
	getPinnedViewId,
	(views, pinnedViewId) =>
		views
			.filter(({ viewId, id }) => !!viewId && id !== pinnedViewId)
			.filter(({ containsArchived }) => !containsArchived)
			.map(({ viewId, title }) => ({ id: viewId || '', title })),
);

export const getBoardViewConfigured = createSelector(getCurrentViewGroupBy, (groupByField) =>
	Boolean(groupByField),
);

export const getCurrentViewConfigured = createSelector(
	getCurrentViewKind,
	getMatrixViewConfigured,
	getTimelineViewConfigured,
	getBoardViewConfigured,
	(viewKind, isMatrixViewConfigured, isTimelineViewConfigured, isBoardViewConfigured): boolean => {
		switch (viewKind) {
			case VIEW_KIND_MATRIX:
				return isMatrixViewConfigured;
			case VIEW_KIND_TIMELINE:
				return isTimelineViewConfigured;
			case VIEW_KIND_BOARD:
				return isBoardViewConfigured;
			default:
				return true;
		}
	},
);

export const getCurrentViewRankFieldKey = createSelector(
	getCurrentView,
	(view) => view?.rankFieldKey,
);

const mapKeysToFields = (fields: FieldsByKey, fieldKeys: (FieldKey | undefined)[]) =>
	fieldKeys.reduce<Field[]>((acc, fieldKey) => {
		if (!fieldKey) {
			return acc;
		}
		const field = fields[fieldKey];
		if (field) {
			acc.push(field);
		}
		return acc;
	}, []);

const getUnsupportedFields = (fields: Field[]) => {
	const unsupportedFields = [
		FIELD_TYPES.ATLAS_GOAL,
		FIELD_TYPES.ATLAS_PROJECT,
		FIELD_TYPES.ATLAS_PROJECT_STATUS,
		FIELD_TYPES.VOTES,
		FIELD_TYPES.REACTIONS,
	];
	const supportedFormulaFields = [NUMBER_COMMENT_FORMULA_KEY];

	return fields.filter(({ type, formula, configuration }) => {
		const isDeliveryField =
			type === FIELD_TYPES.DELIVERY_PROGRESS || type === FIELD_TYPES.DELIVERY_STATUS;
		const isUnsupportedFormula =
			!!formula && !supportedFormulaFields.includes(formula?.template) && !isDeliveryField;
		const isAtlasBasedDate =
			type === FIELD_TYPES.INTERVAL &&
			(configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_START_DATE ||
				configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE);
		// Check if field is an unsupported formula field or a field of the given type
		return isUnsupportedFormula || unsupportedFields.some((t) => t === type) || isAtlasBasedDate;
	});
};

// Below selector is not using existing selectors that rely on isAutosaveEnabled property
// because it is used in the sharing dialog and there we show real view data, not draft
export const getCurrentViewFieldsUnsupportedForSharing = createSelector(
	getCurrentView,
	getFields,
	(currentView, allFields) => {
		const currentViewFieldKeys = currentView?.fields || [];
		const currentViewHiddenFieldKeys = currentView?.hidden || [];
		const currentViewVisibleFieldKeys = currentViewFieldKeys.filter(
			(key) => !currentViewHiddenFieldKeys.includes(key),
		);
		const currentViewVisibleFields = mapKeysToFields(allFields, currentViewVisibleFieldKeys);
		const groupByField = currentView?.groupBy ? allFields[currentView?.groupBy] : undefined;
		const verticalGroupByField = currentView?.verticalGroupBy
			? allFields[currentView?.verticalGroupBy]
			: undefined;
		const matrixAxes = (currentView?.matrixConfig?.axes || []).map(({ field }) => field);
		const currentViewFilterFields = mapKeysToFields(
			allFields,
			(currentView?.filter || []).map((filter) =>
				filter.type === GENERIC_FIELD_FILTER ||
				filter.type === NUMERIC_FIELD_FILTER ||
				filter.type === INTERVAL_FIELD_FILTER
					? filter.field
					: undefined,
			),
		);
		const currentViewSortFields = mapKeysToFields(
			allFields,
			(currentView?.sortBy || []).map(({ fieldKey }) => fieldKey),
		);

		const usedFields = [
			groupByField,
			verticalGroupByField,
			...matrixAxes,
			...currentViewFilterFields,
			...currentViewSortFields,
			currentView?.timelineConfig?.startDateField,
			currentView?.timelineConfig?.dueDateField,
		].filter(Boolean);
		const usedUniqueMisconfiguredViewFields = uniqBy(usedFields, 'key');
		const usedViewFields = [...currentViewVisibleFields].filter(Boolean);

		return {
			viewUnsupportedFields: getUnsupportedFields(usedViewFields),
			misconfiguredViewUnsupportedFields: getUnsupportedFields(usedUniqueMisconfiguredViewFields),
		};
	},
);

export const getStatusCategoriesForPublishedView = createSelector(
	getCurrentView,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(view) => keyBy(view?.statusCategories, ({ key }) => key) as StatusCategoryMap,
);

export const getCurrentViewAccessLevel = (state: State) => state.accessLevel;
