/*
This service tracks sticky headers and keeps a ref to the tallest one.
*/

import React, { type FC, type PropsWithChildren } from 'react';
import {
	createStore,
	createSubscriber,
	createHook,
	createStateHook,
	createActionsHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { configureResizeObserver } from './utils';

export type StickyRef = {
	current: HTMLElement | null;
};

type StickyOptions = Partial<{
	marginBottom: number;
}>;

export type StickyRecord = {
	ref: StickyRef;
	height: number | undefined;
	options: StickyOptions;
	observer: ResizeObserver | undefined;
};

type StickyId = string | Symbol;

type StickiesMap = Map<StickyId, StickyRecord>;

type State = {
	/**
	 * configuration setting - offset to parent
	 */
	offset: number;
	/**
	 * Currently tracked elements
	 */
	stickies: StickiesMap | null;
	/**
	 * reference of the tallest element
	 */
	headerRef: StickyRef | null;
	/**
	 * height of the tallest element
	 */
	headerHeight: number | undefined;
};

const initialState: State = {
	offset: 0,
	stickies: null, // fake initial state to cause containerization
	headerRef: null,
	headerHeight: undefined,
};

/**
 * Of all the stickies which are currently registered (i.e. stuck to the top)
 * which one is the tallest? We can dock other things to that.
 */
const findTallestHeader = (stickies: Map<StickyId, StickyRecord>): StickyRecord | undefined =>
	[...stickies.values()].reduce(
		(tallest, current) => {
			// note the side effect - without ResizeObserver not yet reported result or disabled we will read height directly
			// eslint-disable-next-line no-param-reassign
			current.height = (current.height ?? current.ref.current?.clientHeight) || 0;

			return current.height >= (tallest?.height || 0) ? current : tallest;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		undefined as StickyRecord | undefined,
	);

const Store = createStore({
	initialState,
	actions: {
		/**
		 * recalculates current header information
		 */
		invalidate:
			() =>
			({ setState, getState }) => {
				const { stickies } = getState();
				if (!stickies) {
					return;
				}
				const tallest = findTallestHeader(stickies);

				setState(
					tallest
						? {
								headerRef: tallest.ref,
								headerHeight: (tallest.height || 0) + (tallest.options.marginBottom || 0),
							}
						: {
								headerRef: null,
								headerHeight: undefined,
							},
				);
			},
		registerSticky:
			(name: StickyId, stickyRef: StickyRef, options: StickyOptions = {}) =>
			({ setState, getState, dispatch }) => {
				// initial value of Store not wrapped in a Container is "null"
				const stickies = getState().stickies || new Map();
				// set stickies back as they might be re-initialized.
				// Setting back the same value causes no effect
				setState({ stickies });

				if (stickies.has(name)) {
					return;
				}

				const stick: StickyRecord = {
					ref: stickyRef,
					options,
					height: undefined,
					observer: undefined,
				};
				stickies.set(name, stick);

				// set to "non-undefined" to prune "clientHeight" branch and speedup rendering
				// resize observer will trigger after initial setup and measure node in the "best time"(for Browser)
				// configureResizeObserver will "undo" this if ResizeObserver is not supported
				stick.height = 0;
				stick.observer = configureResizeObserver(stickyRef.current, ({ height }) => {
					stick.height = height;
					dispatch(Store.actions.invalidate());
				});
			},
		deregisterSticky:
			(name: StickyId) =>
			({ getState, dispatch }) => {
				const { stickies } = getState();
				const record = stickies?.get(name);
				if (!record || !stickies) return;

				record.observer?.disconnect();
				stickies.delete(name);

				dispatch(Store.actions.invalidate());
			},
	},
	name: 'sticky-header',
});

export const StickyEditorRefSubscriber = createSubscriber(Store);

const StickyHeaderTrackerStateContainer = createContainer(Store, {
	onInit:
		() =>
		(
			{ setState },
			options: Partial<{
				/**
				 * offset for the all elements in the containers
				 */
				offset: number;
			}>,
		) =>
			setState({ stickies: new Map(), offset: options.offset || 0 }),
	onCleanup:
		() =>
		({ getState }) => {
			getState().stickies?.forEach(({ observer }) => {
				if (observer) {
					observer.disconnect();
				}
			});
		},
	onUpdate:
		() =>
		({ setState, dispatch }, options) => {
			setState(options);
			dispatch(Store.actions.invalidate());
		},
});

/**
 * @deprecated low level component. Use {@link useStickyHeaderOffset} or {@link useStickyHeaderRegistration}
 */
export const useStickyHeaderRef = createHook(Store);

/**
 * @returns an offset consumed by the current sticky header
 */
export const useStickyHeaderOffset = createStateHook(Store, {
	selector: (state) => (state.headerHeight || 0) + state.offset,
});

export const useStickyHeaderRegistration = createActionsHook(Store);

/**
 * Creates a new Tracker Context automatically tracking offset accumulated in the parent scope
 * @param scope
 * @param children
 * @constructor
 */
export const StickyHeaderTrackerContainer: FC<PropsWithChildren<{ scope: string }>> = ({
	scope,
	children,
}) => (
	<StickyHeaderTrackerStateContainer scope={scope} offset={useStickyHeaderOffset()}>
		{children}
	</StickyHeaderTrackerStateContainer>
);

/**
 * @returns is sticky header is enabled and the offset to it
 */
export const useStickyHeader = (): [number | undefined, boolean] => [useStickyHeaderOffset(), true];
