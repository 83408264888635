import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { sum } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/composition/index.tsx';
import { field as fieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/field/index.tsx';
import { count } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/insight-count/index.tsx';
import { multiselectCount } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/multi-select-count/index.tsx';
import { propertySum } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/property-aggregation/index.tsx';
import type {
	CompositionFormula,
	DynamicFieldFormula,
} from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import {
	weightedScore,
	weightedScoreWithPositiveAndNegativeWeights,
} from '@atlassian/jira-polaris-lib-formula/src/utils/formula/weighted-score/index.tsx';
import {
	COUNT_ROLLUP,
	FIELD_ROLLUP,
	POLARIS_OAUTH_CLIENT_ID,
	PROPERTY_ROLLUP,
} from '../../../constants';
import type { RollupField } from '../../../types';
import { getSplitPercentageValue } from '../percentage';

const toFormula = (field: RollupField): DynamicFieldFormula => {
	switch (field.kind) {
		case PROPERTY_ROLLUP:
			return propertySum(field.value.key, field.value.oauthClientId, field.value.filter);
		case FIELD_ROLLUP: {
			const { key, type } = field.value;
			return type === FIELD_TYPES.SINGLE_SELECT ||
				type === FIELD_TYPES.MULTI_SELECT ||
				type === FIELD_TYPES.JSW_MULTI_SELECT
				? multiselectCount(key)
				: fieldFormula(key);
		}
		case COUNT_ROLLUP:
			return count(field.value.oauthClientId, [POLARIS_OAUTH_CLIENT_ID], field.value.filter);
		default:
			// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'never'.
			throw new Error(`polaris-field.bad-formula-input: ${field.value.key}`);
	}
};

const createWeightedScoreFormula = (
	rollupFields: RollupField[],
	percentages: (number | undefined)[],
): DynamicFieldFormula => {
	const defaultPositivePercentage = getSplitPercentageValue(
		percentages.filter((_, index) => !rollupFields[index].negative),
	);
	const defaultNegativePercentage = getSplitPercentageValue(
		percentages.filter((_, index) => rollupFields[index].negative),
	);
	const percentagesWithDefaultValue = percentages.map((percentage, index) => {
		// check the reason of changing negative 0 in constant file
		if (percentage !== undefined) return percentage;
		return rollupFields[index].negative ? defaultNegativePercentage : defaultPositivePercentage;
	});

	const positiveWeights: number[] = [];
	const negativeWeights: number[] = [];
	const positiveRollupFields: RollupField[] = [];
	const negativeRollupFields: RollupField[] = [];

	percentagesWithDefaultValue.forEach((percentage, index) => {
		if (rollupFields[index].negative) {
			negativeWeights.push(percentage);
			negativeRollupFields.push(rollupFields[index]);
		} else {
			positiveWeights.push(percentage);
			positiveRollupFields.push(rollupFields[index]);
		}
	});

	if (negativeWeights.length === 0) {
		return weightedScore(positiveRollupFields.map(toFormula), positiveWeights);
	}

	return weightedScoreWithPositiveAndNegativeWeights(
		positiveRollupFields.map(toFormula),
		positiveWeights,
		negativeRollupFields.map(toFormula),
		negativeWeights,
	);
};

const createRollupFormula = (rollupFields: RollupField[]): CompositionFormula =>
	sum(rollupFields.map(toFormula));

export const createFormula = (fields: RollupField[], percentages?: (number | undefined)[]) =>
	percentages ? createWeightedScoreFormula(fields, percentages) : createRollupFormula(fields);
