import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueAttachmentReachingLimitFlagHeading: {
		id: 'issue-attachments-limit.issue-attachment-reaching-limit-flag-heading',
		defaultMessage: 'This issue has a lot of attachments',
		description:
			"Displays as a flag heading, after user uploads an attachment and it's reaching the limit.",
	},
	issueAttachmentReachingLimitFlagBody: {
		id: 'issue-attachments-limit.issue-attachment-reaching-limit-flag-body',
		defaultMessage:
			'When an issue has over 150 attachments, it may not display previews and thumbnails.',
		description:
			"Displays as a flag body, after user uploads an attachment and it's reaching the limit.",
	},
	issueAttachmentOverLimitFlagHeading: {
		id: 'issue-attachments-limit.issue-attachment-over-limit-flag-heading',
		defaultMessage: 'Too many attachments on this issue',
		description:
			"Displays as a flag heading, after user uploads an attachment and it's over the limit.",
	},
	issueAttachmentOverLimitFlagBody: {
		id: 'issue-attachments-limit.issue-attachment-over-limit-flag-body',
		defaultMessage:
			"Thumbnails and preview can't load. Remove some attachments to enable thumbnails and previews.",
		description:
			"Displays as a flag heading, after user uploads an attachment and it's over the limit.",
	},
	issueAttachmentLimitLink: {
		id: 'issue-attachments-limit.issue-attachment-limit-link',
		defaultMessage: 'Learn about attachments',
		description:
			"Displays in a flag body, after user uploads an attachment and it's over or reaching the limit.",
	},
});
