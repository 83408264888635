import React, { type ChangeEvent, useEffect, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_NAME_MAX_LENGTH } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/actions/utils.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type EditableTitleProps = {
	isEditing: boolean;
	title: string;
	isLoading?: boolean;
	isReadOnly?: boolean;
	onEdit: () => void;
	onCancel: () => void;
	onConfirm: (value: string) => void;
};

type ReadViewTitleProps = {
	title: string;
	isReadOnly: boolean;
	onEdit: () => void;
};

type EditViewTitleProps = {
	title: string;
	onConfirm: (value: string) => void;
	onCancel: () => void;
};

const ReadViewTitle = ({ title, isReadOnly, onEdit }: ReadViewTitleProps) => (
	<ReadViewContainer
		data-testid="polaris-lib-editable-view-title.ui.view-title.read-view-container"
		onClick={isReadOnly ? undefined : onEdit}
		title={title}
	>
		{title}
	</ReadViewContainer>
);

const EditViewTitle = ({ title, onConfirm, onCancel }: EditViewTitleProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [valueInternal, setValueInternal] = useState(title);

	useEffect(() => {
		setValueInternal(title);
	}, [title]);

	const handleChange = useCallback((newValue: string) => {
		setValueInternal(newValue);
	}, []);

	const confirm = useCallback(() => {
		if (valueInternal !== title) {
			onConfirm(valueInternal || formatMessage(messages.defaultViewTitle));
			fireCompoundAnalyticsEvent.View.nameChanged(createAnalyticsEvent({}));
		} else {
			onCancel();
		}
	}, [createAnalyticsEvent, formatMessage, onCancel, onConfirm, title, valueInternal]);

	return (
		<TextField
			testId="polaris-lib-editable-view-title.ui.view-title.text-field"
			onBlur={confirm}
			maxLength={VIEW_NAME_MAX_LENGTH}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'pressed',
							actionSubject: 'keyboardShortcut',
						}),
						'confirmViewName',
					);
					confirm();
				}
				if (event.key === 'Escape') {
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'pressed',
							actionSubject: 'keyboardShortcut',
						}),
						'cancelViewNameChange',
					);
					onCancel();
				}
			}}
			value={valueInternal}
			onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
			autoFocus
			isCompact
		/>
	);
};

export const EditableTitle = ({
	title,
	isEditing,
	isLoading = false,
	isReadOnly = false,
	onEdit,
	onCancel,
	onConfirm,
}: EditableTitleProps) => {
	if (isLoading) {
		return null;
	}

	return (
		<Container>
			{(!isEditing || isReadOnly) && (
				<ReadViewTitle title={title} isReadOnly={isReadOnly} onEdit={onEdit} />
			)}
			{isEditing && !isReadOnly && (
				<EditViewTitle title={title} onConfirm={onConfirm} onCancel={onCancel} />
			)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.div({
	height: '32px',
	width: '100%',
	overflow: 'hidden',
	display: 'flex',
	alignItems: 'center',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-ds--text-field--container="true"]': {
		border: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		':focus-within:not([data-disabled])': {
			boxShadow: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		'&&': () =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
			fg('jpd-visual-refresh_typography_horizon')
				? { font: token('font.heading.large'), fontWeight: token('font.weight.bold') }
				: {
						// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
						fontSize: '24px',
						lineHeight: '28px',
					},

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: '28px !important',
		margin: '0px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		padding: '0px !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ReadViewContainer = styled.div({
	overflow: 'hidden',
	wordBreak: 'break-word',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
	display: 'inline-block',
	width: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	'&&': () =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('jpd-visual-refresh_typography_horizon')
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
				{ font: token('font.heading.large'), fontWeight: `${token('font.weight.bold')} !important` }
			: {
					// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
					fontSize: '24px',
					lineHeight: '28px',
				},
});
