import isEqual from 'lodash/isEqual';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { ColumnId, RowGroupId, RowId, RowType } from '../../../common/types';
import { NO_VALUE_GROUP_ID } from '../../../constants';
import type { Edge } from '../../../types';
import { getRowGroups } from '../../selectors/items';
import type { UpdatedRowProps, RowGrouping, State, Props } from '../../types';
import { isRowMovedInsideGroups } from './utils';

export const updateItemState =
	(
		newRows: RowId[] | undefined,
		newUpdatedRows: RowId[],
		newRowGroups?: (RowGroupId | undefined)[],
		newGroupedIds?: RowGrouping,
		newColumns?: ColumnId[],
		newFixedColumns?: ColumnId[],
		newHoverableColumns?: ColumnId[],
		hideEmptyGroups = false,
	): Action<State, Props> =>
	({ setState, getState }, { alwaysHighlightUpdatedRows }) => {
		const {
			rows,
			updatedRows,
			updatedRowsProps,
			rowGroups,
			groupedIds,
			columns,
			fixedColumns,
			hoverableColumns,
			collapsedGroups,
			prevDraggingRowId,
		} = getState();

		const newUpdatedRowsProps: Array<UpdatedRowProps> = [];
		const isRowsChanged = !isEqual(rows, newRows);

		// @TODO: make it work for multiple updated rows
		const updatedRow = newUpdatedRows?.[0];
		let isUpdatedRowsChanged =
			!isEqual(updatedRows, newUpdatedRows) && prevDraggingRowId !== updatedRow;

		if (isUpdatedRowsChanged) {
			const updatedRowProp: UpdatedRowProps = {
				id: null,
				idx: null,
				isMoved: false,
				isFiltered: false,
			};
			const oldIdx = rows.indexOf(updatedRow);
			const newIdx = newRows?.indexOf(updatedRow) ?? -1;
			if (newIdx === -1 && oldIdx !== -1) {
				updatedRowProp.isFiltered = true;
			}
			if (rowGroups === undefined && newIdx !== -1 && newIdx !== oldIdx) {
				updatedRowProp.isMoved = true;
			} else if (rowGroups !== undefined && newIdx !== -1) {
				updatedRowProp.isMoved = isRowMovedInsideGroups(
					rowGroups,
					groupedIds,
					newRowGroups,
					newGroupedIds,
					collapsedGroups,
					updatedRow,
				);
			}
			updatedRowProp.id = updatedRow;
			newUpdatedRowsProps.push(updatedRowProp);
		} else if (updatedRow && alwaysHighlightUpdatedRows) {
			isUpdatedRowsChanged = true;
			const updatedRowProp: UpdatedRowProps = {
				id: updatedRow,
				idx: null,
				isMoved: true,
				isFiltered: false,
			};
			newUpdatedRowsProps.push(updatedRowProp);
		}

		const newRowGroupsFiltered = newRowGroups?.filter((groupId) => {
			const isEmptyGroup =
				groupId === undefined
					? !newGroupedIds?.empty?.length
					: !newGroupedIds?.groups[groupId]?.length;
			return !(hideEmptyGroups && isEmptyGroup);
		});

		setState({
			rows: isRowsChanged ? newRows : rows,
			updatedRows: isUpdatedRowsChanged ? newUpdatedRows : updatedRows,
			updatedRowsProps: isUpdatedRowsChanged ? newUpdatedRowsProps : updatedRowsProps,
			prevDraggingRowId: undefined,
			rowGroups: isEqual(rowGroups, newRowGroupsFiltered) ? rowGroups : newRowGroupsFiltered,
			groupedIds: isEqual(groupedIds, newGroupedIds) ? groupedIds : newGroupedIds,
			columns: newColumns === undefined || isEqual(columns, newColumns) ? columns : newColumns,
			fixedColumns:
				newFixedColumns === undefined || isEqual(fixedColumns, newFixedColumns)
					? fixedColumns
					: newFixedColumns,
			hoverableColumns:
				newHoverableColumns === undefined || isEqual(hoverableColumns, newHoverableColumns)
					? hoverableColumns
					: newHoverableColumns,
		});
	};

type RankItemsProps = {
	sourceId: RowId;
	sourceGroupId?: RowGroupId;
	targetId: RowId;
	targetGroupId?: RowGroupId;
	targetType: RowType;
	dropEdge: Edge;
};

export const rankItems =
	({ sourceId, sourceGroupId, targetId, targetGroupId, targetType, dropEdge }: RankItemsProps) =>
	({ getState }: StoreActionApi<State>, { onItemRanked, onItemGroupChanged }: Props) => {
		const state = getState();
		const { groupedIds } = state;
		const rowGroups = getRowGroups(state);

		if (rowGroups !== undefined && groupedIds !== undefined) {
			if (sourceGroupId === undefined || targetGroupId === undefined) {
				return;
			}

			if (sourceGroupId !== targetGroupId) {
				// Change issue group
				onItemGroupChanged?.({
					id: sourceId,
					sourceGroupId: sourceGroupId !== NO_VALUE_GROUP_ID ? sourceGroupId : undefined,
					targetGroupId: targetGroupId !== NO_VALUE_GROUP_ID ? targetGroupId : undefined,
				});
			}

			if (targetType !== 'GROUP') {
				// Change issue rank
				onItemRanked?.({ sourceId, targetId, dropEdge });
			} else {
				const targetGroup = groupedIds.groups[targetId];
				// get first rowId in target group
				const targetRowIdInGroup = targetGroup?.[0];
				if (targetRowIdInGroup && targetRowIdInGroup !== sourceId) {
					// Change issue rank, for drag into group always put issue on it's top
					onItemRanked?.({ sourceId, targetId: targetRowIdInGroup, dropEdge: 'top' });
				}
			}
		} else {
			// non-grouped re-ranking
			onItemRanked?.({ sourceId, targetId, dropEdge });
		}
	};
