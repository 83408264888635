import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { IntervalFieldFilterOperator } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';

export const INTERVAL_ENDED_VISUALIZATION = 'INTERVAL_ENDED';
export const INTERVAL_STARTS_VISUALIZATION = 'INTERVAL_STARTS';
export const INTERVAL_ENDS_VISUALIZATION = 'INTERVAL_ENDS';
export const INTERVAL_STARTED_VISUALIZATION = 'INTERVAL_STARTED';

export type UiFilter = {
	id: string;
	isChecked: (values: IntervalFieldFilterOperator[]) => boolean;
	getValues: (parameter: number | undefined) => IntervalFieldFilterOperator[] | undefined;
	hasParameter?: boolean;
	label: MessageDescriptor;
	activeLabel?: MessageDescriptor;
};
