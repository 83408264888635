import generalOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import HttpError from '@atlassian/jira-fetch/src/utils/errors';
import { applyObservabilityHeaders } from '@atlassian/jira-fetch/src/utils/observability-headers.tsx';
import { getTraceId } from '@atlassian/jira-fetch/src/utils/trace-id.tsx';
import type { FieldConfiguration } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createJsonPatch } from '@atlassian/jira-polaris-lib-json-patch';

const postOptions = {
	...generalOptions,
	method: 'POST',
} as const;

type ErrorResponseMessage = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors?: any;
	errorMessages?: String[];
	httpStatusCode?: {
		empty: boolean;
		present: boolean;
	};
};

export const updatePolarisFieldConfiguration = async (
	fieldKey: string,
	oldConfiguration: FieldConfiguration,
	newConfiguration: FieldConfiguration,
	projectIdOrKey: number | string,
	issueTypeId: string,
): Promise<void> => {
	const patch = createJsonPatch(oldConfiguration, newConfiguration);
	if (patch.length === 0) {
		return Promise.resolve();
	}

	const url = `/rest/polaris/v2/projects/${projectIdOrKey}/fields/${fieldKey}/configuration/patch`;
	const options = {
		headers: {
			'Content-Type': 'application/json-patch+json',
		},
		body: JSON.stringify({
			issueTypeId,
			patch,
		}),
	};

	const response = await fetch(url, applyObservabilityHeaders(url, { ...postOptions, ...options }));
	if (!response.ok) {
		const message: ErrorResponseMessage = await response.json();
		const errorServerResponse =
			message.errorMessages?.join(' - ') ?? `Error server response: ${response.status}`;

		const traceId = getTraceId(response);
		throw new HttpError(response.status, errorServerResponse, traceId);
	}
};
