import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import {
	getPaletteEntryForMainColor,
	convertMainColorHexToRgb,
} from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import type { HighlightedField } from '../../../controllers/issue/types';

type Props = {
	valueDecorations: {
		backgroundColor: string | undefined;
		localDecorationId: string;
	}[];
	compact?: boolean;
};
const HighlightingComponent = memo<Props>(
	({ valueDecorations, compact }: Props) => (
		<HighlightingContainer compact={compact}>
			{valueDecorations.map((valueDecoration) => {
				if (valueDecoration.backgroundColor !== undefined) {
					const palette = getPaletteEntryForMainColor(valueDecoration.backgroundColor);
					return (
						<Highlight
							key={valueDecoration.localDecorationId}
							backgroundColor={convertMainColorHexToRgb(palette.mainColor)}
						/>
					);
				}
				return null;
			})}
		</HighlightingContainer>
	),
	isEqual,
);

type SimplifiedProps = {
	fields: HighlightedField[];
	compact?: boolean;
};

const HighlightingInternal = ({ fields, compact }: SimplifiedProps) => {
	const valueDecorations = useMemo(
		() =>
			fields.map((field) => ({
				backgroundColor: field.appliedDecoration.backgroundColor,
				localDecorationId: field.appliedDecoration.localDecorationId,
			})),
		[fields],
	);
	return <HighlightingComponent valueDecorations={valueDecorations} compact={compact} />;
};

export const Highlighting = ({ fields, compact }: SimplifiedProps) =>
	fields?.length ? (
		<HighlightingInternal fields={fields} compact={compact} />
	) : (
		<EmptyHighlightingContainer />
	);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyHighlightingContainer = styled.div({
	width: '8px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HighlightingContainer = styled.div<{ compact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ compact }) => `${compact ? 8 : 14}px`,
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Highlight = styled.div<{ backgroundColor: string }>({
	flex: 1,
	height: 'auto',
	width: '8px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	transition: '0.3s',
});
