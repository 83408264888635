import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warning: {
		id: 'polaris-lib-concurrent-updates.banner.warning',
		defaultMessage: 'The description has been updated by {user} at {time}',
		description: 'Warning message for concurrent updates',
	},
	warningLoading: {
		id: 'polaris-lib-concurrent-updates.banner.warning-loading',
		defaultMessage: 'The description has been updated by {user}',
		description: 'Warning message for concurrent updates when loading the author',
	},
	warningGeneric: {
		id: 'polaris-lib-concurrent-updates.banner.warning-generic',
		defaultMessage: 'The description has been updated by another user',
		description: 'Warning message for concurrent updates if the changelog fetch fails',
	},
	warningIconLabel: {
		id: 'polaris-lib-concurrent-updates.banner.warning-icon-label',
		defaultMessage: 'Concurrent update warning',
		description: 'Label for the warning icon',
	},
	openModalButton: {
		id: 'polaris-lib-concurrent-updates.banner.open-modal-button',
		defaultMessage: 'Read the updated description',
		description: 'Button to open the modal with the updated description',
	},
});
