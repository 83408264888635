import React, { useState, useEffect } from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition';
import { useCloudId } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { useIsBeta } from '@atlassian/jira-polaris-component-environment-tenant';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { useFetchConsent, useUpdateConsent } from '../../services/consent';
import messages from './messages';

const ToSConsentCheckerInternal = () => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const [consentFetcher] = useFetchConsent();
	const [consentUpdater] = useUpdateConsent();
	const [isConsented, setIsConsented] = useState(true);
	const [consentLocale, setConsentLocale] = useState('UNKNOWN');
	const { generalDataLoadingFailedError } = useErrorHandlers();

	const handleAccept = () => {
		setIsConsented(true);
		consentUpdater({
			cloudID: cloudId,
			url: window.location.href,
			displayedText: formatMessage(messages.termsText),
			locale: consentLocale,
			marketingConsent: false,
		});
	};

	useEffect(() => {
		consentFetcher(cloudId)
			.then((result) => {
				setIsConsented(result.consented);
				// https://pi-dev-sandbox.atlassian.net/browse/POL-1792 Locale must be a 2-character country code or 'UNKNOWN'
				if (result.locale?.length === 2) {
					setConsentLocale(result.locale);
				}
			})
			.catch((error) => {
				generalDataLoadingFailedError(error);
				log.safeErrorWithoutCustomerData(
					'polaris-consent-fetch.failure',
					'Failed to fetch consent data',
					error,
				);
			});
	}, [cloudId, consentFetcher, generalDataLoadingFailedError]);

	return (
		<ModalTransitionUniversal>
			{!isConsented && (
				<ShortcutScope>
					<ModalDialog>
						<ModalHeader>
							<ModalTitle>{formatMessage(messages.heading)}</ModalTitle>
						</ModalHeader>
						<ModalBody>
							<div
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: formatMessage(messages.termsText),
								}}
							/>
						</ModalBody>
						<ModalFooter>
							<Button
								id="polaris.consent-dialog.accept-button"
								onClick={handleAccept}
								appearance="primary"
							>
								{formatMessage(messages.accept)}
							</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransitionUniversal>
	);
};

const ToSConsentCheckerEditionGuard = () => {
	const beta = useIsBeta();

	// only show consent checker if verified beta edition
	if (beta === true) {
		return <ToSConsentCheckerInternal />;
	}

	return null;
};

export const ToSConsentChecker = ToSConsentCheckerEditionGuard;
