import React, {
	useCallback,
	useRef,
	useState,
	useLayoutEffect,
	type MouseEvent,
	type ComponentPropsWithoutRef,
	type RefObject,
} from 'react';
import { styled } from '@compiled/react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { sized } from '@atlassian/jira-polaris-lib-resize-observer';
import { sized as sizedLegacy } from '../../common/ui/sizing';
import type { onSizeChangedCallback } from '../../common/ui/sizing/types';
import { useMatrixActions } from '../../controllers';
import { useYAxis } from '../../controllers/selectors/axis-hooks';
import { CONTINUOUS } from '../../types';
import type { ChartContainerProps } from './types';

export const ChartContainer = <TAxisSizeProps,>({
	ChartContentComponent,
	XAxisComponent,
	YAxisComponent,
	CornerComponent,
	isItemsDragDisabled,
}: ChartContainerProps<TAxisSizeProps>) => {
	const [, { setSelectedItems }] = useMatrixActions();

	const yAxis = useYAxis();
	const isContinuousYAxis = yAxis?.type === CONTINUOUS;
	const contentRef = useRef<HTMLDivElement>(null);
	const xAxisRef = useRef<HTMLDivElement>(null);
	const yAxisRef = useRef<HTMLDivElement>(null);
	const cornerRef = useRef<HTMLDivElement>(null);

	const [outerDimensions, setOuterDimensions] = useState({ width: 0, height: 0 });
	const [xAxisDimensions, setXAxisDimensions] = useState({ width: 0, height: 0 });
	const [yAxisDimensions, setYAxisDimensions] = useState({ width: 0, height: 0 });
	const [contentDimensions, setContentDimensions] = useState({ width: 0, height: 0 });

	const [xAxisInternalSizing, setXAxisInternalSizing] = useState<unknown>(undefined);
	const [yAxisInternalSizing, setYAxisInternalSizing] = useState<unknown>(undefined);

	const onOuterChartAreaSizeChanged = useCallback<onSizeChangedCallback>((width, height) => {
		setOuterDimensions({ width, height });
	}, []);

	const onYAxisSizeChanged = useCallback<onSizeChangedCallback>((width, height) => {
		setYAxisDimensions({ width, height });
	}, []);

	const onXAxisSizeChanged = useCallback<onSizeChangedCallback>((width, height) => {
		setXAxisDimensions({ width, height });
	}, []);

	const onContentSizeChanged = useCallback<onSizeChangedCallback>((width, height) => {
		setContentDimensions({ width, height });
	}, []);

	const onXAxisInternalSizing = useCallback((props: TAxisSizeProps) => {
		setXAxisInternalSizing(props);
	}, []);

	const onYAxisInternalSizing = useCallback((props: TAxisSizeProps) => {
		setYAxisInternalSizing(props);
	}, []);

	const onClickEmptyChartArea = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			if (e.shiftKey || e.metaKey || e.ctrlKey) return;
			setSelectedItems([]);
		},
		[setSelectedItems],
	);

	const yAxisSize = isContinuousYAxis ? yAxisDimensions.height : yAxisDimensions.width;
	const SizedYAxisComponent = isContinuousYAxis ? SizedContinuousYAxis : SizedYAxis;

	useLayoutEffect(() => {
		if (yAxisRef.current) {
			const size = outerDimensions.height - xAxisDimensions.height;
			if (isContinuousYAxis) {
				yAxisRef.current.style.width = `${size}px`;
			} else {
				yAxisRef.current.style.height = `${size}px`;
			}
		}
	}, [isContinuousYAxis, outerDimensions.height, xAxisDimensions.height]);

	useLayoutEffect(() => {
		if (xAxisRef.current) {
			xAxisRef.current.style.marginLeft = `${yAxisSize}px`;
			xAxisRef.current.style.width = `calc(100% - ${yAxisSize}px)`;
		}
	}, [yAxisSize]);

	useLayoutEffect(() => {
		if (contentRef.current) {
			contentRef.current.style.marginLeft = `${yAxisSize}px`;
			contentRef.current.style.width = `calc(100% - ${yAxisSize}px)`;
			contentRef.current.style.height = `calc(100% - ${xAxisDimensions.height}px)`;
		}
	}, [xAxisDimensions.height, yAxisSize]);

	useLayoutEffect(() => {
		if (cornerRef.current) {
			cornerRef.current.style.width = `${yAxisSize}px`;
			cornerRef.current.style.height = `${xAxisDimensions.height}px`;
		}
	}, [xAxisDimensions.height, yAxisSize]);

	// The legacy `sized` hoc is using `innerRef` prop.
	// The new one is using regular `ref`.
	const applyRef = useCallback((ref: RefObject<HTMLDivElement>) => {
		if (fg('polaris_right_sidebar_resize_observer')) {
			return { ref };
		}

		return {
			innerRef: ref,
		};
	}, []);

	return (
		<SizedOuterChartArea onSizeChanged={onOuterChartAreaSizeChanged}>
			<SizedXAxis {...applyRef(xAxisRef)} onSizeChanged={onXAxisSizeChanged}>
				{XAxisComponent && (
					<XAxisComponent currentWidth={xAxisDimensions.width} onSizing={onXAxisInternalSizing} />
				)}
			</SizedXAxis>
			<SizedYAxisComponent
				data-component-selector="matrix-y-axis"
				{...applyRef(yAxisRef)}
				onSizeChanged={onYAxisSizeChanged}
			>
				{YAxisComponent && (
					<YAxisComponent currentWidth={yAxisDimensions.width} onSizing={onYAxisInternalSizing} />
				)}
			</SizedYAxisComponent>
			<SizedContent
				{...applyRef(contentRef)}
				onSizeChanged={onContentSizeChanged}
				onClick={onClickEmptyChartArea}
			>
				<ChartContentComponent
					width={contentDimensions.width}
					height={contentDimensions.height}
					isItemsDragDisabled={isItemsDragDisabled}
				/>
			</SizedContent>
			<CornerContainer ref={cornerRef}>
				{CornerComponent && (
					<CornerComponent
						// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'TAxisSizeProps | undefined'.
						xAxisSizeProps={xAxisInternalSizing}
						// @ts-expect-error - TS2322 - Type 'unknown' is not assignable to type 'TAxisSizeProps | undefined'.
						yAxisSizeProps={yAxisInternalSizing}
					/>
				)}
			</CornerContainer>
		</SizedOuterChartArea>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OuterChartAreaContainer = styled.div({
	width: '100%',
	height: '100%',
	minWidth: '450px',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const XAxisContainer = styled.div({
	maxHeight: '100%',
	overflow: 'hidden',
	position: 'absolute',
	left: 0,
	bottom: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const YAxisContainer = styled.div({
	maxWidth: '68px',
	position: 'absolute',
	left: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContinuousYAxisContainer = styled.div({
	maxHeight: '100%',
	overflow: 'hidden',
	position: 'absolute',
	left: 0,
	transformOrigin: '0 0',
	transform: 'rotate(-90deg) translate(-100%, 0%)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CornerContainer = styled.div({
	position: 'absolute',
	left: 0,
	bottom: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	height: '100%',
});

/* eslint-disable @typescript-eslint/no-explicit-any */
const SizedOuterChartAreaLegacy = sizedLegacy<any>(OuterChartAreaContainer);
const SizedContentLegacy = sizedLegacy<any>(ContentContainer);
const SizedYAxisLegacy = sizedLegacy<any>(YAxisContainer);
const SizedContinuousYAxisLegacy = sizedLegacy<any>(ContinuousYAxisContainer);
const SizedXAxisLegacy = sizedLegacy<any>(XAxisContainer);
/* eslint-enable @typescript-eslint/no-explicit-any */

// We need to opt out from using requestAnimationFrame
// to avoid blink during the first render
const SizedOuterChartAreaNext = sized<ComponentPropsWithoutRef<typeof OuterChartAreaContainer>>(
	OuterChartAreaContainer,
	{ shouldUseRequestAnimationFrame: false },
);
const SizedContentNext = sized<ComponentPropsWithoutRef<typeof ContentContainer>>(
	ContentContainer,
	{
		shouldUseRequestAnimationFrame: false,
	},
);
const SizedYAxisNext = sized<ComponentPropsWithoutRef<typeof YAxisContainer>>(YAxisContainer, {
	shouldUseRequestAnimationFrame: false,
});
const SizedContinuousYAxisNext = sized<ComponentPropsWithoutRef<typeof ContinuousYAxisContainer>>(
	ContinuousYAxisContainer,
	{ shouldUseRequestAnimationFrame: false },
);
const SizedXAxisNext = sized<ComponentPropsWithoutRef<typeof XAxisContainer>>(XAxisContainer, {
	shouldUseRequestAnimationFrame: false,
});

const SizedOuterChartArea = componentWithFG(
	'polaris_right_sidebar_resize_observer',
	SizedOuterChartAreaNext,
	SizedOuterChartAreaLegacy,
);
const SizedContent = componentWithFG(
	'polaris_right_sidebar_resize_observer',
	SizedContentNext,
	SizedContentLegacy,
);
const SizedYAxis = componentWithFG(
	'polaris_right_sidebar_resize_observer',
	SizedYAxisNext,
	SizedYAxisLegacy,
);
const SizedContinuousYAxis = componentWithFG(
	'polaris_right_sidebar_resize_observer',
	SizedContinuousYAxisNext,
	SizedContinuousYAxisLegacy,
);
const SizedXAxis = componentWithFG(
	'polaris_right_sidebar_resize_observer',
	SizedXAxisNext,
	SizedXAxisLegacy,
);
