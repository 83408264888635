import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { loadDeliveryTicketsEstimate } from '../load-delivery-tickets-estimate';
import { addDeliveryTickets } from '../load-issue';
import { loadIssueLinkArchived } from '../load-issue-link-archived';

export const refreshIssueLinks =
	(onRefreshed: () => void, shouldRefreshAllDeliveryTickets = false) =>
	async (
		{ getState, setState, dispatch }: StoreActionApi<State>,
		{ issueKey, issuesRemote, onDeliveryIssuesUpdated }: Props,
	) => {
		if (!issueKey) {
			return;
		}
		issuesRemote.fetchIssue({ issueIdOrKey: issueKey }).then((response) => {
			addDeliveryTickets(getState, setState, response, shouldRefreshAllDeliveryTickets);
			onDeliveryIssuesUpdated(response);
			setState({
				issueLinks: response.fields.issuelinks,
			});

			dispatch(loadIssueLinkArchived());
			dispatch(loadDeliveryTicketsEstimate());
			onRefreshed();
		});
	};
