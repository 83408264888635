import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recipientsLabel: {
		id: 'spa-apps.project-settings.notifications-common.common.recipient-selection.recipients-label',
		defaultMessage: 'Send a notification to',
		description: 'recipient list heading',
	},
	selectRolePlaceholder: {
		id: 'spa-apps.project-settings.notifications-common.common.recipient-selection.select-role-placeholder',
		defaultMessage: 'Select project role',
		description: 'role selection placeholder',
	},
});
