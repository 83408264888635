import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types';

export const setFieldScopeFilter =
	(fieldScope: State['filters']['scope']): Action<State> =>
	({ getState, setState }) => {
		setState({
			filters: {
				...getState().filters,
				scope: fieldScope,
			},
		});
	};

export const setSearchQueryFilter =
	(search: State['filters']['search']): Action<State> =>
	({ getState, setState }) => {
		setState({
			filters: {
				...getState().filters,
				search,
			},
		});
	};

export const clearFilters =
	(): Action<State> =>
	({ setState }) => {
		setState({
			filters: {
				scope: undefined,
				search: '',
			},
		});
	};
