import { notImplementedError } from '../../common/utils';
import {
	fetchViewAccess,
	updateViewAccessLevel,
	addViewAccessPrincipals,
	removeViewAccessPrincipals,
} from '../../services/jpd-views-service/view-access';
import type { ViewAccessRemote } from './types';

export const createNotImplementedViewAccessRemote = (): ViewAccessRemote => ({
	fetchViewAccess: notImplementedError('fetchViewAccess'),
	updateViewAccessLevel: notImplementedError('updateViewAccessLevel'),
	addViewAccessPrincipals: notImplementedError('addViewAccessPrincipals'),
	removeViewAccessPrincipals: notImplementedError('removeViewAccessPrincipals'),
});

export const createViewAccessRemote = (): ViewAccessRemote => ({
	fetchViewAccess,
	updateViewAccessLevel,
	addViewAccessPrincipals,
	removeViewAccessPrincipals,
});

export const createSharedViewAccessRemote = createNotImplementedViewAccessRemote;
