import {
	isGroupReorderingEnabled,
	isColumnReorderingEnabled,
	isColumnResizingEnabled,
	isItemReorderingEnabled,
	isItemSelectionEnabled,
} from './operations';
import { createSelectorHook } from './utils';

export const useIsGroupReorderingEnabled = createSelectorHook(isGroupReorderingEnabled);
export const useIsColumnReorderingEnabled = createSelectorHook(isColumnReorderingEnabled);
export const useIsColumnResizingEnabled = createSelectorHook(isColumnResizingEnabled);
export const useIsItemReorderingEnabled = createSelectorHook(isItemReorderingEnabled);
export const useIsItemSelectionEnabled = createSelectorHook(isItemSelectionEnabled);
