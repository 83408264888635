import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	newValue: {
		id: 'polaris-lib-option-utils.common.utils.add-value-generator.new-value',
		defaultMessage: 'A new value',
		description:
			'New value prefix to be used to create additional new values, e.g. A new value, A new value 2, etc.',
	},
});
