import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	selectionPlaceholder: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.selection-placeholder',
		defaultMessage: 'Select one or more labels',
		description: 'Placeholder message for the select element',
	},
	dialogLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.dialog-label',
		defaultMessage: 'Include only insights labeled',
		description: 'Instruction for the label filter',
	},
	sourceLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.source-label',
		defaultMessage: 'Source',
		description: 'Source label',
	},
	propertyLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.property-label',
		defaultMessage: 'Property',
		description: 'Property label',
	},
	detailsMenuLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.details-menu-label',
		defaultMessage: 'Show details',
		description: 'Menu option to show details for input',
	},
	filterMenuLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.filter-menu-label',
		defaultMessage: 'Add a filter',
		description: 'Menu option to filter input',
	},
	deleteMenuLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.item.delete-menu-label',
		defaultMessage: 'Remove input',
		description: 'Menu option to remove input',
	},
});
