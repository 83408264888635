import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	preInstruction: {
		id: 'polaris-common.common.apps.modals.teams.pre-instruction',
		defaultMessage:
			'The same Jira for Microsoft Teams app that your team uses for software projects will also help you capture messages on your ideas.',
		description: 'First instruction on how to install the teams app',
	},
	postInstruction: {
		id: 'polaris-common.common.apps.modals.teams.post-instruction',
		defaultMessage:
			'To capture a message, select the “Add comment” option on a message, then select your Product Discovery project and idea.',
		description: 'Second instruction on how to use the teams app',
	},
	installCaption: {
		id: 'polaris-common.common.apps.modals.teams.install-caption',
		defaultMessage: 'Install the app',
		description: 'Install button caption',
	},
	close: {
		id: 'polaris-common.common.apps.modals.teams.close',
		defaultMessage: 'Close',
		description: 'Modal dialog close button caption',
	},
});
