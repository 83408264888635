import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import { Box, xcss } from '@atlaskit/primitives';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { getPaletteEntryForMainColor } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';

type CommonDecoratedTagProps = {
	mainColor?: string;
	emoji?: EmojiDescription;
	value?: string;
	compact?: boolean;
	iconOnly?: boolean;
};

export const CommonDecoratedTag = memo<CommonDecoratedTagProps>(
	({ mainColor, emoji, value, compact = false, iconOnly }: CommonDecoratedTagProps) => {
		const { colorMode } = useThemeObserver();
		const theme = colorMode === 'dark' ? 'dark' : 'light';
		const paletteEntry = useMemo(
			() => getPaletteEntryForMainColor(mainColor, theme),
			[mainColor, theme],
		);
		let label = value;

		if (iconOnly && value) {
			label = emoji ? '' : value[0];
		}

		return (
			<DecoratedTagContent
				title={value}
				color={paletteEntry.mainColor}
				textColor={paletteEntry.textColor}
				compact={compact}
				iconOnly={iconOnly}
				data-component-selector="decorated-tag-content-c3R"
			>
				{emoji && (
					<EmojiWrapper
						standalone={!label}
						color={paletteEntry.emojiOverlay}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="hide-from-export"
					>
						<Emoji emoji={emoji} fitToHeight={16} />
					</EmojiWrapper>
				)}
				{label && (
					<ValueWrapper
						data-testid="polaris-lib-decoration.ui.decoration.common.decorated-tag-content"
						color={paletteEntry.mainColor}
						standalone={!emoji}
						iconOnly={iconOnly}
					>
						{fg('polaris_option_tag_ellipsis') ? <Box xcss={labelStyles}>{label}</Box> : label}
					</ValueWrapper>
				)}
			</DecoratedTagContent>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DecoratedTagContent = styled.div<{
	compact: boolean;
	textColor: string;
	iconOnly?: boolean;
}>(
	{
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		gridTemplateRows: '1fr',
		gap: '0px 0px',
		gridTemplateAreas: '"icon label"',
		justifyContent: 'left',
		alignItems: 'center',
		height: '22px',
		transition: '0.3s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		margin: ({ compact }) =>
			compact
				? '0px'
				: `${token('space.050', '4px')} ${token('space.050', '4px')} ${token(
						'space.050',
						'4px',
					)} 5px`,
		maxWidth: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ textColor }) => textColor,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ iconOnly }) =>
		iconOnly && {
			display: 'flex',
			minWidth: '22px',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmojiWrapper = styled.div<{
	standalone?: boolean;
	color?: string;
}>({
	overflow: 'hidden',
	gridArea: 'icon',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => color,
	width: '24px',

	height: '22px',
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ standalone }) => (standalone ? '3px' : '3px 0 0 3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ValueWrapper = styled.div<{ standalone: boolean; color: string; iconOnly?: boolean }>(
	{
		gridArea: 'label',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		borderRadius: ({ standalone }) => (standalone ? '3px' : '0 3px 3px 0'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ color }) => color,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		maxWidth: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		'&&': () =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
			fg('jpd-visual-refresh_typography_horizon')
				? {
						font: token('font.body.UNSAFE_small'),
						fontWeight: token('font.weight.semibold'),
						height: '22px',
						display: 'flex',
						alignItems: 'center',
						padding: `0 ${token('space.050')}`,
					}
				: {
						// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
						fontSize: '12px',
						fontWeight: 600,
						lineHeight: '14px',
						padding: token('space.050', '4px'),
					},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ iconOnly }) =>
		iconOnly && {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
		},
);

const labelStyles = xcss({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
});
