import React, { useCallback } from 'react';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types';
import type { StatusUpdateFunction } from '@atlassian/jira-polaris-common/src/controllers/issue/actions/update-status';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useIsIssueArchived,
	useSafeIssueKey,
	useStatus,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route';
import { useStatusCategories } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/status-categories-hook';
import { StatusField as CommonField } from '@atlassian/jira-polaris-common/src/ui/fields/status/index.tsx';
import type { StatusCategory } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { useIsEditable } from '../../utils';
import { messages } from './messages';

export type StatusFieldUpdater = (
	LocalIssueId: LocalIssueId,
	FieldKey: FieldKey,
	oldStatus: StatusValue,
	newStatus: StatusValue,
	updateField: StatusUpdateFunction<StatusValue>,
) => void;

export type StatusFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const useStatusSubmitHandler = () => {
	const { updateStatusInStateOnlyWithBulk } = useIssueActions();

	const { showFlag } = useNotifications();
	const { openIssueView } = usePolarisRouter();

	const openIssue = useCallback(
		(e: React.SyntheticEvent, key: string) => {
			e.preventDefault();
			openIssueView(key, { layout: 'sidebar' });
		},
		[openIssueView],
	);

	const onNotAllowedTransitions = useCallback(
		(keys: string[]) => {
			const notAllowedIssueKeys = keys.map((key) => (
				// eslint-disable-next-line react/jsx-key
				<>
					<a href="/" onClick={(e) => openIssue(e, key)}>
						{key}
					</a>
					,&nbsp;
				</>
			));

			showFlag({
				type: 'info',
				title: messages.notAllowedTransitionsTitle,
				description: [
					messages.notAllowedTransitionsDescription,
					{
						// @ts-expect-error Type 'Element[]' is not assignable to type 'string | number | boolean | ((arg0: string) => ReactNode) | null | undefined'.
						notAllowedIssueKeys,
						issuesCount: keys.length,
					},
				],
			});
		},
		[openIssue, showFlag],
	);

	return useCallback(
		(
			localIssueId: LocalIssueId,
			fieldKey: FieldKey,
			oldStatus: StatusValue,
			newStatus: StatusValue,
			updateField: StatusUpdateFunction<StatusValue>,
		) => {
			updateStatusInStateOnlyWithBulk({
				localIssueId,
				fieldKey,
				oldStatus,
				newStatus,
				updateField,
				onNotAllowedTransitions,
			});
		},
		[updateStatusInStateOnlyWithBulk, onNotAllowedTransitions],
	);
};

export const StatusField = ({ localIssueId, fieldKey }: StatusFieldProps) => {
	const issueStatusProperty = useStatus(localIssueId);
	const issueKey = useSafeIssueKey(localIssueId);
	const { updateField } = useIssueActions();
	const onFieldUpdate = useStatusSubmitHandler();
	const isEditable = useIsEditable(fieldKey);
	const statusCategoriesMap = useStatusCategories();
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	const submitHandler = useCallback(
		(oldStatus: StatusValue, newValue: StatusValue) => {
			const statusCategories: StatusCategory[] = Object.values(statusCategoriesMap || {});
			const { id, colorName, key, name } =
				statusCategories.find(
					(statusCategory) => statusCategory.id === newValue.statusCategory.id,
				) ?? newValue.statusCategory;
			const newStatus: StatusValue = {
				...newValue,
				statusCategory: { id, colorName, key, name },
			};
			onFieldUpdate(localIssueId, fieldKey, oldStatus, newStatus, updateField);
		},
		[onFieldUpdate, localIssueId, fieldKey, updateField, statusCategoriesMap],
	);

	return (
		<CommonField
			isEditable={isEditable && !isIdeaArchived}
			issueKey={issueKey}
			issueId={localIssueId}
			value={issueStatusProperty}
			onSubmit={submitHandler}
		/>
	);
};
