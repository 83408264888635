import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	timelineFieldsHelpText: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-fields-help-text',
		defaultMessage: 'Select the date fields you want to use for start and end dates in this view.',
		description: 'Help text for timeline field configuration',
	},
	timelineStartField: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-start-field',
		defaultMessage: 'Start date field',
		description: '',
	},
	timelineEndField: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-end-field',
		defaultMessage: 'Target date field',
		description: '',
	},
	timelineRangeLabel: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-range-label',
		defaultMessage: 'View Settings',
		description: '',
	},
	timelineRangeHelpText: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-range-help-text',
		defaultMessage:
			'Select when this timeline starts and ends. Ideas that have start and end dates outside the range will not be displayed.',
		description: '',
	},
	timelineRangeStart: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-range-start',
		defaultMessage: 'Timeline start',
		description: '',
	},
	timelineRangeEnd: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-range-end',
		defaultMessage: 'Timeline end',
		description: '',
	},
	timelineScaleHelpText: {
		id: 'polaris-ideas.view-controls.config-timeline.timeline-configuration.timeline-scale-help-text',
		defaultMessage: 'Select the time scale you want to use for the columns in this timeline',
		description: '',
	},
});
