import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types';

export const setHighlightedFields =
	(fieldKeys: string[]): Action<State> =>
	async ({ setState }) => {
		setState({
			highlightedFields: [...fieldKeys],
		});
	};

export const resetHighlightedFields =
	(): Action<State> =>
	({ getState, setState }) => {
		if (getState().highlightedFields.length > 0) {
			setState({
				highlightedFields: [],
			});
		}
	};
