import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	selectPlaceholder: {
		id: 'polaris-common.field-config.add.select-placeholder',
		defaultMessage: 'Search for a field',
		description: 'Placeholder text for the field select component',
	},
	addMoreButton: {
		id: 'polaris-common.field-config.add.add-more-button',
		defaultMessage: 'Add a field',
		description: 'Button label to add more fields to the view',
	},
	noManageViewFieldsPermissions: {
		id: 'polaris-common.field-config.add.no-manage-view-fields-permissions',
		defaultMessage: "You don't have permissions to manage fields in views",
		description: 'Information message for missing manage fields in views permissions.',
	},
});
