import type { Attributes, PendoEvent } from './types';
import { isPendoReady, logError } from './utils';

const QUEUE_LIMIT = 50;
let queue: Array<[string, Attributes | undefined]> = [];

const interval = setInterval(() => {
	if (isPendoReady()) {
		queue.forEach(([event, attributes]) => window?.pendo?.track(event, attributes));
		queue = [];
		clearInterval(interval);
	}
}, 1000);

/**
 * @deprecated passing event as string is deprecated, use `sendPendoTrackEvent(event: PendoEvent)` call instead
 */
export function sendPendoTrackEvent(event: string, properties?: Attributes): void;
export function sendPendoTrackEvent(event: PendoEvent): void;
export function sendPendoTrackEvent(event: string | PendoEvent, properties?: Attributes) {
	let pendoEvent;
	let attributes;

	if (typeof event === 'string') {
		pendoEvent = event;
		attributes = properties;
	} else {
		const fullEvent = [event.source, event.actionSubjectAndAction, event.actionSubjectId]
			.filter((part) => part !== undefined)
			.join(' - ');

		pendoEvent = fullEvent;
		attributes = event.attributes;
	}

	try {
		if (isPendoReady()) {
			window.pendo.track(pendoEvent, attributes);
		} else {
			queue.length < QUEUE_LIMIT && queue.push([pendoEvent, attributes]);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		logError('pendoTrackEvent', err);
	}
}

export const updatePendoVisitorOptions = (metadata: {}) => {
	try {
		if (isPendoReady()) {
			window.pendo.updateOptions(metadata);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		logError('pendoUpdateOptions', err);
	}
};
