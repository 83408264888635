import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-lib-onboarding-flows.project-fields.explore-section-message.title',
		defaultMessage: 'Explore field management enhancements',
		description: 'Title of the section message for project fields onboarding',
	},
	body: {
		id: 'polaris-lib-onboarding-flows.project-fields.explore-section-message.body',
		defaultMessage:
			'Manage all project fields in one place and create global fields to add them to your project.',
		description: 'Body of the section message for project fields onboarding',
	},
	secondaryText: {
		id: 'polaris-lib-onboarding-flows.project-fields.explore-section-message.secondary-text',
		defaultMessage: 'Jira admins can create global fields on Global fields page.',
		description: 'Text for secondary link in the section message for project fields onboarding',
	},
	exploreNowButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.explore-section-message.explore-now-button',
		defaultMessage: 'Explore now',
		description: 'Text for "Explore now" button',
	},
	dismissButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.explore-section-message.dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Text for "Dismiss" button',
	},
});
