import React from 'react';
import flow from 'lodash/flow';
import {
	AnalyticsSource,
	ComponentWithAnalytics,
	ReduxAnalyticsData,
	withFireUiAnalytics,
} from '@atlassian/jira-analytics-web-react/src';
import { ErrorBoundary } from '@atlassian/jira-error-boundary';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl';
import { useIssueKey, useOnIssueKeyChange } from '@atlassian/jira-issue-context-service';
import { useFieldConfig } from '@atlassian/jira-issue-field-base';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { SUMMARY } from '@atlassian/jira-issue-view-configurations';
import { withViewExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { getProjectUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls';
import {
	navigateToNewIssue,
	navigateToProject,
} from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions';
import {
	analyticsSourceSelector,
	baseUrlSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import { fieldPersistedValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import {
	errorSelector,
	isCompletedLoadingSelector,
	isSimplifiedProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import {
	canLinkToParentSelector,
	hierarchyLevelSelector,
	parentLevelIssueTypeNamesSelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector';
import {
	fullIssueUrlSelector,
	getParentIssuesSelector,
	isIssueTypeFieldEditableSelector,
	issueTypeIconUrlSelector,
	issueTypeNameSelector,
	issueTypeHierarchyLevelSelector,
	issueUrlSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import {
	projectAvatarUrlSelector,
	projectNameSelector,
	projectSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/project-selector';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/jira-router';
import type { DispatchProps, Props, StateProps } from './types';
import { IssueBreadcrumbs } from './view';

const IssueBreadcrumbsWithHooks = ({ projectName, projectAvatarUrl, ...props }: Props) => {
	const { push } = useRouterActions();

	const onIssueKeyChange = useOnIssueKeyChange();
	const issueKey = useIssueKey();
	const intl = useIntl();

	const [fieldConfig] = useFieldConfig(issueKey, PARENT_TYPE);

	return (
		<ErrorBoundary id="issue.issue-view.foundation.breadcrumbs">
			<IssueBreadcrumbs
				pushAction={push}
				onIssueKeyChange={onIssueKeyChange}
				issueKey={issueKey}
				projectName={projectName || ''}
				projectAvatarUrl={projectAvatarUrl || ''}
				intl={intl}
				isEditable={fieldConfig?.value?.isEditable}
				{...props}
			/>
		</ErrorBoundary>
	);
};

const IssueBreadcrumbsWithStore = flow(
	connect(
		(state: State): StateProps => {
			const isSimplifiedProject = isSimplifiedProjectSelector(state);
			const project = projectSelector(state);

			return {
				error: errorSelector(state),
				isSimplifiedProject,
				isLoadingComplete: isCompletedLoadingSelector(state),
				issueSummary: fieldPersistedValueSelector(SUMMARY)(state) || '',
				relativeIssueUrl: issueUrlSelector(state),
				fullIssueUrl: fullIssueUrlSelector(state),
				issueTypeName: issueTypeNameSelector(state),
				issueTypeIconUrl: issueTypeIconUrlSelector(state),
				parentIssues: getParentIssuesSelector(state),
				projectName: projectNameSelector(state),
				projectUrl: getProjectUrl(
					baseUrlSelector(state),
					projectKeySelector(state),
					project?.projectType,
					project?.boardId,
					isSimplifiedProject,
				),
				projectAvatarUrl: projectAvatarUrlSelector(state),
				// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'. | TS2345 - Argument of type '{}' is not assignable to parameter of type 'Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>'.
				isIssueTypeFieldEditable: isIssueTypeFieldEditableSelector(state),
				canLinkToParent: canLinkToParentSelector(state),
				issueHierarchyLevel: !isSimplifiedProject
					? issueTypeHierarchyLevelSelector(state)
					: hierarchyLevelSelector(state),
				parentLevelIssueTypeNames: parentLevelIssueTypeNamesSelector(state),
			};
		},
		(dispatch): DispatchProps => ({
			onParentClick: ({ childIssueKey, parentIssueKey, onIssueKeyChange }) => {
				onIssueKeyChange &&
					onIssueKeyChange({
						fromIssueKey: childIssueKey,
						toIssueKey: parentIssueKey,
						meta: { location: 'breadcrumbs' },
					});
				if (!__SPA__) {
					dispatch(
						navigateToNewIssue({
							fromIssueKey: childIssueKey,
							toIssueKey: parentIssueKey,
						}),
					);
				}
			},
			onProjectClick: (projectUrl, pushAction) => {
				dispatch(navigateToProject(projectUrl, pushAction));
			},
		}),
	),
	ReduxAnalyticsData((state: State) => {
		const analyticsSource = analyticsSourceSelector(state);
		const isSimplifiedProject = isSimplifiedProjectSelector(state);
		const project = projectSelector(state);
		return {
			attributes: {
				issueHierarchyLevel: hierarchyLevelSelector(state),
				analyticsSource: `issue-view (${String(analyticsSource)})`,
				nextGenProject: isSimplifiedProject,
				projectConfig: isSimplifiedProject ? 'next-gen' : 'classic',
			},
			containerType: 'project',
			containerId: project ? `${project.projectId}` : '',
		};
	}),
)(IssueBreadcrumbsWithHooks);

export default flow(
	AnalyticsSource('breadcrumbs', SCREEN),
	withViewExperienceTracker('breadcrumbs'),
	ComponentWithAnalytics('breadcrumbsItem', {
		onRootBreadcrumbClick: 'clicked',
		onProjectClick: 'clicked',
		onParentClick: 'clicked',
		onClick: 'clicked',
	}),
	withFireUiAnalytics({
		onCopyClick: 'copyUrlLink',
		onRootBreadcrumbClick: 'navigateToProjectsList',
		onProjectClick: 'navigateToProject',
		onParentClick: 'navigateToParentIssue',
		onClick: 'navigateToCurrentIssue',
	}),
)(IssueBreadcrumbsWithStore);
