import {
	REQUEST_CHANNEL_ANONYMOUS_PORTAL,
	REQUEST_CHANNEL_API,
	REQUEST_CHANNEL_EMAIL,
	REQUEST_CHANNEL_JIRA,
	REQUEST_CHANNEL_DEPLOYMENT,
	REQUEST_CHANNEL_PORTAL,
	REQUEST_CHANNEL_UNKNOWN,
} from '@atlassian/jira-issue-view-common-types/src/request-channel-type';
import type { RequestPanelMetadata } from '@atlassian/jira-issue-view-common-types/src/request-panel-metadata-type';

// FETCH_REQUEST_PANEL_METADATA
export const FETCH_REQUEST_PANEL_METADATA = 'FETCH_REQUEST_PANEL_METADATA' as const;

export const fetchRequestPanelMetadata = () => ({
	type: FETCH_REQUEST_PANEL_METADATA,
});

export type FetchRequestPanelMetadata = ReturnType<typeof fetchRequestPanelMetadata>;

// REQUEST_PANEL_METADATA_FETCH_SUCCESS
export const REQUEST_PANEL_METADATA_FETCH_SUCCESS = 'REQUEST_PANEL_METADATA_FETCH_SUCCESS' as const;

const getRequestChannelType = (channelType?: string) => {
	switch (channelType) {
		case 'portal':
			return REQUEST_CHANNEL_PORTAL;
		case 'email':
			return REQUEST_CHANNEL_EMAIL;
		case 'api':
			return REQUEST_CHANNEL_API;
		case 'jira':
			return REQUEST_CHANNEL_JIRA;
		case 'deployment':
			return REQUEST_CHANNEL_DEPLOYMENT;
		case 'anonymousportal':
			return REQUEST_CHANNEL_ANONYMOUS_PORTAL;
		default:
			return REQUEST_CHANNEL_UNKNOWN;
	}
};

export const requestPanelMetadataFetchSuccess = (metadata: RequestPanelMetadata) => ({
	type: REQUEST_PANEL_METADATA_FETCH_SUCCESS,
	payload: {
		channel: getRequestChannelType(metadata.channel),
		requestUrl: metadata.requestUrl,
		csatComment: metadata.csatComment,
	},
});

export type RequestPanelMetadataFetchSuccess = ReturnType<typeof requestPanelMetadataFetchSuccess>;

// REQUEST_PANEL_METADATA_FETCH_FAILURE
export const REQUEST_PANEL_METADATA_FETCH_FAILURE = 'REQUEST_PANEL_METADATA_FETCH_FAILURE' as const;

export const requestPanelMetadataFetchFailure = () => ({
	type: REQUEST_PANEL_METADATA_FETCH_FAILURE,
});

export type RequestChannelFetchFailure = ReturnType<typeof requestPanelMetadataFetchFailure>;

export type RequestPanelMetadataAction =
	| FetchRequestPanelMetadata
	| RequestPanelMetadataFetchSuccess
	| RequestChannelFetchFailure;
