import type { AgileState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	type FetchIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
} from '../common/actions/issue-fetch-actions';

type Action = FetchIssueSuccessAction;

const initialState: AgileState = {
	epic: undefined,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AgileState = initialState, action: Action) => {
	switch (action.type) {
		case FETCH_ISSUE_SUCCESS:
			return {
				...action.payload.issue.agile,
			};

		default:
			return state;
	}
};
