import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'polaris-ideas.insights.insights.shared-view-warning.description',
		defaultMessage:
			'Insights are unavailable in the shared view during early access. We are working to make this feature available soon.',
		description: 'Warning section message for unavailable insights in early access shared view',
	},
	learnMore: {
		id: 'polaris-ideas.insights.insights.shared-view-warning.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more link action',
	},
});
