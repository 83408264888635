import { useMemo } from 'react';
import { getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';
import type { ConsistencyCheckFeatureFlag } from '../../types';

export const useConsistencyFeatureFlag = (flag: string): ConsistencyCheckFeatureFlag =>
	useMemo(
		() => ({
			featureFlagName: flag,
			// eslint-disable-next-line jira/ff/static-feature-flags
			value: getMultivariateFeatureFlag(flag, 'OLD', ['NEW', 'CHECK_RETURN_NEW', 'CHECK', 'OLD']),
		}),
		[flag],
	);

export const getIsNewValueReturned = (flag: string) => {
	// eslint-disable-next-line jira/ff/static-feature-flags
	const featureFlagValue = getMultivariateFeatureFlag(flag, 'OLD', [
		'NEW',
		'CHECK_RETURN_NEW',
		'CHECK',
		'OLD',
	]);

	return featureFlagValue === 'NEW' || featureFlagValue === 'CHECK_RETURN_NEW';
};

export const getIsConsistencyCheckEnabled = (flag: string) => {
	// eslint-disable-next-line jira/ff/static-feature-flags
	const featureFlagValue = getMultivariateFeatureFlag(flag, 'OLD', [
		'NEW',
		'CHECK_RETURN_NEW',
		'CHECK',
		'OLD',
	]);

	return featureFlagValue === 'CHECK' || featureFlagValue === 'CHECK_RETURN_NEW';
};

export const getIsNewValueRequested = (flag: string) => {
	// eslint-disable-next-line jira/ff/static-feature-flags
	const featureFlagValue = getMultivariateFeatureFlag(flag, 'OLD', [
		'NEW',
		'CHECK_RETURN_NEW',
		'CHECK',
		'OLD',
	]);

	return (
		featureFlagValue === 'NEW' ||
		featureFlagValue === 'CHECK_RETURN_NEW' ||
		featureFlagValue === 'CHECK'
	);
};

export const getIsOldValueRequested = (flag: string) => {
	// eslint-disable-next-line jira/ff/static-feature-flags
	const featureFlagValue = getMultivariateFeatureFlag(flag, 'OLD', [
		'NEW',
		'CHECK_RETURN_NEW',
		'CHECK',
		'OLD',
	]);

	return (
		featureFlagValue === 'OLD' ||
		featureFlagValue === 'CHECK_RETURN_NEW' ||
		featureFlagValue === 'CHECK'
	);
};
