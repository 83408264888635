import { fetchJson$, fetchJson } from '@atlassian/jira-fetch';
import { getUserAuthUrl } from '@atlassian/jira-issue-fetch-services-common';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors';

export { getUserAuthUrl };

export const fetchUserAuth$ = (_baseUrl?: string) =>
	fetchJson$(getUserAuthUrl()).catch((error) => {
		throw new IssueViewFetchError(error, getUserAuthUrl());
	});

export const fetchUserAuth = (_baseUrl?: string) => fetchJson(getUserAuthUrl());
