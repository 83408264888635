import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyColumnIndicator: {
		id: 'polaris-ideas.view-content.idea-board.card-list.filtered-ideas-text.empty-column-indicator',
		defaultMessage: 'There are no ideas here (yet).',
		description: 'Placeholder text for columns without any ideas in them.',
	},
	filteredColumnIndicator: {
		id: 'polaris-ideas.view-content.idea-board.card-list.filtered-ideas-text.filtered-column-indicator',
		defaultMessage: 'Some ideas are not visible due to filters.',
		description: 'Placeholder text for columns without any ideas in them due to active filters.',
	},
});
