import React, { type ReactNode, type ReactElement } from 'react';
import EmptyState from '@atlaskit/empty-state';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ContactSupportLink } from '@atlassian/jira-polaris-common/src/common/utils/errors/contact-support-link';
import GenericError from './assets/generic-error.svg';
import messages from './messages';

type ViewMainContentErrorBoundaryProps = {
	children: ReactElement;
};

export const ViewMainContentErrorBoundary = (props: ViewMainContentErrorBoundaryProps) => {
	const { children } = props;
	const { formatMessage } = useIntl();

	return (
		<JSErrorBoundary
			id="ViewMainContentErrorBoundary"
			packageName="jiraPolarisIdeas"
			render={() => (
				<EmptyState
					testId="polaris-ideas.ui.main-content.view-main-content-error-boundary.empty-state"
					imageUrl={GenericError}
					header={formatMessage(messages.header)}
					description={formatMessage(messages.description, {
						a: (chunks: ReactNode[]) => <ContactSupportLink>{chunks}</ContactSupportLink>,
					})}
				/>
			)}
		>
			{children}
		</JSErrorBoundary>
	);
};
