import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	emojiPickerHeader: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.emoji-picker-header',
		defaultMessage: 'Emoji',
		description: 'Header text for the polaris emoji picker',
	},
	fieldNameRequiredError: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.field-name-required-error',
		defaultMessage: 'Field name is required',
		description: 'Message to describe error that field name is required',
	},
	fieldNameInUseError: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.field-name-in-use-error',
		defaultMessage: 'This name is already in use, try another.',
		description: 'Message to describe error that field name is in use already',
	},
	fieldNameTooLongError: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.field-name-too-long-error',
		defaultMessage: 'Field names are limited to 255 characters',
		description: 'Error message for too long field name',
	},
	lockedFieldHelperText: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.locked-field-helper-text',
		defaultMessage: 'Custom field is locked',
		description: 'Label showing that a custom field is in locked state.',
	},
	lockedFieldHelperTextTooltip: {
		id: 'polaris-component-field-configuration.field-main-properties-editor.locked-field-helper-text-tooltip',
		defaultMessage:
			'This field is locked by Jira or a Jira app; you can only edit its color, formatting, and emojis.',
		description:
			'Description displayed for locked custom field, describing that it is locked by Jira or Jira app.',
	},
});
