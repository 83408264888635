import { useMemo, useRef } from 'react';
import find from 'lodash/find';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { ff } from '@atlassian/jira-feature-flagging';
import { useLocalIssueIdsByGroupIdentity } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import {
	useIdeasInCreation,
	useIdeasInCreationGrouped,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	IssueCreateGroupTypeNoGroup,
	IssueCreateGroupTypeUnknown,
	IssueCreateGroupTypeSpecified,
	IssueCreateGroupTypeEmpty,
	type IssueCreatedProperty,
} from '@atlassian/jira-polaris-common/src/controllers/issue/types';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewGroupValues,
	useCurrentViewVerticalGroupBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { type ExtendedOption, EMPTY_VALUE_ID } from '../../../../common/utils/board';
import { useExtendedOptionsInNaturalOrder, useRestrictedOptions } from './field-visibility-options';
import { useExtendedVerticalGroupOptions } from './vertical-group-options';

/**
 * local helper hook to collect the available field options and enrich them with a
 * droppable id, which will be used to identify the source and destination columns
 * on DND events
 */
export const useGroupOptions = (
	field?: Field,
	includeAllOptions = false,
): ExtendedOption<unknown>[] => {
	const currentViewGroupValues = useCurrentViewGroupValues();

	// apply restrictions
	const restrictedOptions = useRestrictedOptions(field);
	const allOptions = useExtendedOptionsInNaturalOrder(field);
	const options = includeAllOptions ? allOptions : restrictedOptions;
	const previousSortOptions = useRef<ExtendedOption<unknown>[]>([]);

	// apply sort order of view configuration, if present
	return useMemo(() => {
		const handledGroupIdentities: Array<string> = [];
		const sortOptions: Array<ExtendedOption<unknown>> = [];

		const optionsByGroupIdentity = keyBy(options, ({ groupIdentity }) => groupIdentity);

		// always put the "no value" column first
		const noValueOption = find(options, ({ groupIdentity }) => groupIdentity === undefined);
		if (noValueOption !== undefined) {
			sortOptions.push(noValueOption);
		}

		currentViewGroupValues.forEach((groupValue: GroupValue) => {
			if (groupValue.id !== undefined && has(optionsByGroupIdentity, groupValue.id)) {
				handledGroupIdentities.push(groupValue.id);
				// @ts-expect-error - TS2345 - Argument of type 'number | ExtendedOption<unknown> | (() => string) | (() => string) | (() => ExtendedOption<unknown> | undefined) | ((...items: ExtendedOption<unknown>[]) => number) | ... 29 more ... | ((index: number) => ExtendedOption<...> | undefined)' is not assignable to parameter of type 'ExtendedOption<unknown>'.
				sortOptions.push(optionsByGroupIdentity[groupValue.id]);
			}
		});
		options.forEach((option) => {
			if (
				option.groupIdentity !== undefined &&
				!handledGroupIdentities.includes(option.groupIdentity)
			) {
				sortOptions.push(option);
			}
		});

		if (JSON.stringify(previousSortOptions.current) === JSON.stringify(sortOptions)) {
			// allows to keep the same array reference to prevent upstream rerenders
			return previousSortOptions.current;
		}

		previousSortOptions.current = sortOptions;

		return sortOptions;
	}, [currentViewGroupValues, options]);
};

export const useGroupDropHandler = () => {
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const extendedOptions = useExtendedVerticalGroupOptions(verticalGroupByField);
	const { setVerticalGroupValues } = useViewActions();

	return useMemo(
		() =>
			({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
				const newOrder: ExtendedOption<unknown>[] = [...extendedOptions];
				const sourceItem = newOrder[oldIndex];
				newOrder.splice(oldIndex, 1);
				newOrder.splice(newIndex, 0, sourceItem);

				const newSortedGroupOptions = newOrder.map((option: ExtendedOption<unknown>) => ({
					id: option.groupIdentity ?? EMPTY_VALUE_ID,
				}));
				setVerticalGroupValues(newSortedGroupOptions);
			},
		[extendedOptions, setVerticalGroupValues],
	);
};

function assertUnreachable(value: never, label?: string): never {
	throw new Error(label || `Unhandled value: ${value}`);
}

type GroupingWithIdeasInCreation = {
	noGroup: LocalIssueId[] | undefined;
	empty: LocalIssueId[] | undefined;
	groups: {
		[key: string]: LocalIssueId[];
	};
};

const createNextGroupingWithIdeasInCreation = ({
	ideasInCreationGrouped,
	empty,
	groups,
}: {
	ideasInCreationGrouped: IssueCreatedProperty;
	empty: string[] | undefined;
	groups: Record<string, string[]>;
}): GroupingWithIdeasInCreation => {
	const createdIdeasInNoGroup: string[] = [];
	const createdIdeasInEmptyGroup: string[] = [];
	const createdIdeasInGroups: string[] = [];

	Object.keys(ideasInCreationGrouped).forEach((ideaId) => {
		const ideaInCreation = ideasInCreationGrouped[ideaId];
		const { groupType } = ideaInCreation;

		switch (groupType) {
			case IssueCreateGroupTypeSpecified:
				createdIdeasInGroups.push(ideaId);
				break;
			case IssueCreateGroupTypeUnknown:
			case IssueCreateGroupTypeNoGroup:
				createdIdeasInNoGroup.push(ideaId);
				break;
			case IssueCreateGroupTypeEmpty:
				createdIdeasInEmptyGroup.push(ideaId);
				break;
			default:
				assertUnreachable(groupType);
		}
	});

	const nextGroups = mapValues(groups, (ids) =>
		ids.filter(
			(id) => !createdIdeasInNoGroup.includes(id) && !createdIdeasInEmptyGroup.includes(id),
		),
	);

	createdIdeasInGroups.forEach((id) => {
		const ideaInCreation = ideasInCreationGrouped[id];

		if (ideaInCreation.groupType !== IssueCreateGroupTypeSpecified) {
			return;
		}

		if (!nextGroups[ideaInCreation.groupIdentity]) {
			nextGroups[ideaInCreation.groupIdentity] = [id];
		}
	});

	return {
		noGroup: createdIdeasInNoGroup,
		empty: empty?.filter(
			(id: LocalIssueId) =>
				!createdIdeasInNoGroup.includes(id) && !createdIdeasInGroups.includes(id),
		),
		groups: nextGroups,
	};
};

export const useRowGrouping = (): [
	Field | undefined,
	ExtendedOption<unknown>[] | undefined,
	(string | undefined)[] | undefined,
	GroupingWithIdeasInCreation | undefined,
] => {
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const extendedOptions: ExtendedOption<unknown>[] =
		useExtendedVerticalGroupOptions(verticalGroupByField);
	const groupedIds = useLocalIssueIdsByGroupIdentity(verticalGroupByField?.key);
	const ideasInCreation = useIdeasInCreation();
	const ideasInCreationGrouped = useIdeasInCreationGrouped();

	return useMemo(() => {
		if (verticalGroupByField === undefined) {
			return [undefined, undefined, undefined, undefined];
		}

		const rowGroups = extendedOptions.map((option) => option.groupIdentity);
		// filter out ideas in creation from any group and place it in no-groups

		if (ff('polaris.idea-list-row-group-column-header-add-idea')) {
			const groupingWithIdeasInCreation = createNextGroupingWithIdeasInCreation({
				ideasInCreationGrouped,
				empty: groupedIds.empty,
				groups: groupedIds.groups,
			});

			return [verticalGroupByField, extendedOptions, rowGroups, groupingWithIdeasInCreation];
		}

		const groupingWithIdeasInCreation = {
			noGroup: ideasInCreation,
			empty: groupedIds.empty?.filter((id: LocalIssueId) => !ideasInCreation.includes(id)),
			groups: mapValues(groupedIds.groups, (ids) =>
				ids.filter((id) => !ideasInCreation.includes(id)),
			),
		};

		return [verticalGroupByField, extendedOptions, rowGroups, groupingWithIdeasInCreation];
	}, [
		extendedOptions,
		groupedIds.empty,
		groupedIds.groups,
		ideasInCreation,
		ideasInCreationGrouped,
		verticalGroupByField,
	]);
};

export const useNonEmptyGroupOptions = (field?: Field): ExtendedOption<unknown>[] => {
	const groupedIds = useLocalIssueIdsByGroupIdentity(field?.key);
	const extendedOptions = useGroupOptions(field);

	return useMemo(() => {
		const nonEmptyGroupOptions = extendedOptions.filter(({ groupIdentity }) => {
			if (groupIdentity === undefined) {
				return !!groupedIds.empty?.length;
			}
			return !!groupedIds.groups[groupIdentity]?.length;
		});
		return nonEmptyGroupOptions;
	}, [extendedOptions, groupedIds.empty?.length, groupedIds.groups]);
};
