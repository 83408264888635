import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterHeading: {
		id: 'polaris-ideas.view-content.filtered-issue-modal.active-filters.filter-heading',
		defaultMessage: 'Filter',
		description: 'Heading caption for Filter column',
	},
	valueHeading: {
		id: 'polaris-ideas.view-content.filtered-issue-modal.active-filters.value-heading',
		defaultMessage: 'Value',
		description: 'Heading caption for Value column',
	},
});
