import forEach from 'lodash/forEach';
import fpSet from 'lodash/fp/set';
import isEqual from 'lodash/isEqual';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import set from 'lodash/set';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { FieldKeyToAriMap } from '@atlassian/jira-polaris-component-field-ari/src/controllers/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createIssueAri } from '../../../../common/utils/ari';
import { getFieldMappings } from '../../selectors/fields';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids';
import { createGetIssueAnalyticsAttributes } from '../../selectors/properties';
import type { Props, State } from '../../types';
import type { ReactionSummary } from './types';

export const loadReactions =
	(ideasIds: IssueId[], fieldKeyToAriMapPromise: Promise<FieldKeyToAriMap>) =>
	async ({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const { cloudId, reactionsClient } = props;
		const state = getState();
		const fieldMappings = getFieldMappings(state, props);

		const ariToLocalIssueId = mapKeys(
			getLocalIssueIdsByJiraIssueId(state, props),
			(value, issueId) => createIssueAri(props.cloudId, issueId),
		);
		const allIssuesAris = ideasIds.map((id) => createIssueAri(cloudId, id));

		const fieldKeyToAriMap = await fieldKeyToAriMapPromise;

		forEach(fieldMappings, async (mapping) => {
			const { field } = mapping;
			if (field?.type === FIELD_TYPES.REACTIONS) {
				const fieldAri = fieldKeyToAriMap[field.key];

				try {
					if (fieldAri === undefined) {
						throw new Error('Field ARI is not defined');
					}
					const result = await reactionsClient.getReactions(fieldAri, allIssuesAris);
					const reactionsListByLocalIssueId = mapKeys(
						result,
						(v, issueAri) => ariToLocalIssueId[issueAri],
					);
					const reactionIdsByLocalIssueId = mapValues(
						reactionsListByLocalIssueId,
						(reactionsList: ReactionSummary[]) =>
							reactionsList.map((reaction: ReactionSummary) => ({
								id: reaction.emojiId,
							})),
					);
					const newState = set(state.properties.reactions, [field.key], reactionIdsByLocalIssueId);
					setState(newState);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					log.safeErrorWithoutCustomerData(
						'polaris.load-reactions-by-issue-aris',
						'Failed to get reactions',
						err,
					);
				}
			}
		});
	};

export const toggleReaction =
	(fieldKey: FieldKey, localIssueId: LocalIssueId, reactions: ReactionSummary[]) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);
		const reactionsIds = reactions.map((reaction: ReactionSummary) => ({
			id: reaction.emojiId,
		}));
		const currentReactions = state.properties.reactions[fieldKey]
			? state.properties.reactions[fieldKey][localIssueId]
			: [];
		if (isEqual(currentReactions, reactionsIds)) return;
		const newState = fpSet(
			['properties', 'reactions', fieldKey, localIssueId],
			reactionsIds,
			state,
		);
		setState({ ...newState, lastUpdatedIssueIds: [localIssueId] });

		fireAnalyticsEventForIssueUpdate(props.createAnalyticsEvent({}), localIdMap[localIssueId], {
			updatedItems: [{ name: fieldKey }],
			...createGetIssueAnalyticsAttributes(localIssueId)(getState()),
		});
	};
