import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';

export const getAddStakeholdersUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/sharing/stakeholders/add`;

export const getRemoveStakeholdersUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/sharing/stakeholders/remove`;

export const getFetchStakeholdersUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/sharing/stakeholders`;
