import React, { type ComponentType, useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box } from '@atlaskit/primitives';
import { ButtonItem, type ButtonItemProps, Section } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueDecoration,
	ValueOperator,
	ValueRule,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	convertLogicToRules,
	isDecorationWithLogic,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { N100, N1000 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import type { FieldDecoratorProps } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/types.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../../controllers/selectors/callback-hooks';
import { DateDecorationLabel } from './label';
import { messages } from './messages';

const { LT, LTE, GT, GTE } = ValueRuleOperator;

type BaseDecoratorItemProps = {
	decoration: ValueDecoration;
	FieldDecorator: ComponentType<FieldDecoratorProps>;
	readonly?: boolean;
	outerSpacing: string;
};

type DecoratorItemProps = {
	isOpen: boolean;
} & BaseDecoratorItemProps & {
		onOpenChanged: (open: boolean) => void;
	};

const generatePreviewValueForRules = (rules: ValueRule[]): number => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const ltValue = rules.find(({ operator }) => ([LT, LTE] as ValueOperator[]).includes(operator));
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const gtValue = rules.find(({ operator }) => ([GT, GTE] as ValueOperator[]).includes(operator));

	if (gtValue === undefined && ltValue !== undefined) {
		return -1;
	}
	return Number.parseInt(ltValue?.value || '0', 10);
};

const formatRules = (oldDecoration: ValueDecoration, newRules: ValueRule[]) => {
	const ltValue = newRules.find(
		(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
	)?.value;
	const gteValue = newRules.find(
		(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
	)?.value;

	const currentGteRule = isDecorationWithLogic(oldDecoration)
		? undefined // TODO: Handle Decoration Logic
		: oldDecoration.rules.find(
				(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
			);
	const currentLtRule = isDecorationWithLogic(oldDecoration)
		? undefined // TODO: Handle Decoration Logic
		: oldDecoration.rules.find(
				(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
			);

	const rules: Array<ValueRule> = [];

	if (currentGteRule !== undefined) {
		if (gteValue !== undefined) {
			rules.push({ ...currentGteRule, value: gteValue });
		} else {
			rules.push(currentGteRule);
		}
	}

	if (currentLtRule !== undefined) {
		if (ltValue !== undefined) {
			rules.push({ ...currentLtRule, value: ltValue });
		} else {
			rules.push(currentLtRule);
		}
	}

	return rules;
};

export const DecoratorDateTimeItem = (props: DecoratorItemProps) => {
	const {
		onOpenChanged,
		isOpen,
		decoration,
		FieldDecorator,
		readonly = false,
		outerSpacing,
	} = props;
	const rules = useMemo(
		() =>
			isDecorationWithLogic(decoration) ? convertLogicToRules(decoration.logic) : decoration.rules,
		[decoration],
	);

	const { formatMessage } = useIntl();
	const { onValueDecorationUpdated, onValueDecorationDeleted } = useCallbacks();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [currentColor, setCurrentColor] = useState(decoration.backgroundColor);
	const [currentHighlighted, setCurrentHighlighted] = useState(!!decoration.highlightContainer);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration.emoji);
	const [currentRules, setCurrentRules] = useState(rules);

	const onValueDecorationChanged = useCallback(
		({
			backgroundColor,
			emoji,
			highlightContainer,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: 'update',
				backgroundColor,
				emoji,
				highlightContainer,
			});

			onValueDecorationUpdated?.({
				localDecorationId: decoration.localDecorationId,
				backgroundColor,
				emoji,
				highlightContainer,
				rules,
			});
		},
		[createAnalyticsEvent, decoration.localDecorationId, rules, onValueDecorationUpdated],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentEmojiId, currentHighlighted, onValueDecorationChanged],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentColor, currentHighlighted, onValueDecorationChanged],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged],
	);

	const onClearFormatting = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'clearFormatting clicked', 'config-fields');

		setCurrentColor(undefined);
		setCurrentEmojiId(undefined);
		setCurrentHighlighted(false);

		onValueDecorationChanged({
			backgroundColor: undefined,
			emoji: undefined,
			highlightContainer: false,
		});
	}, [createAnalyticsEvent, onValueDecorationChanged]);

	const isClearFormattingDisabled =
		!currentHighlighted && currentColor === undefined && !currentEmojiId;

	const onValueChange = useMemo(
		() =>
			debounce((value) => {
				const gtValue = rules.find(({ operator }) => operator === GTE);

				if (gtValue) {
					const newRules = formatRules(decoration, [
						{ value: value.toString(), operator: LT },
						{ value: gtValue.value, operator: GTE },
					]);

					setCurrentRules(newRules);

					onValueDecorationUpdated?.({
						localDecorationId: decoration.localDecorationId,
						backgroundColor: decoration.backgroundColor,
						emoji: decoration.emoji,
						highlightContainer: Boolean(decoration.highlightContainer),
						rules: newRules,
					});
				}
			}, 350),
		[rules, decoration, onValueDecorationUpdated],
	);

	const handleValidateValue = (
		newValue: string,
	): {
		isValid: boolean;
		error?: string;
	} => {
		if (newValue === '') {
			return {
				isValid: false,
			};
		}

		const newNumberValue = Number(newValue);

		if (!Number.isInteger(newNumberValue)) {
			return {
				isValid: false,
				error: formatMessage(messages.notIntegerError),
			};
		}

		if (newNumberValue < 0) {
			return {
				isValid: false,
				error: formatMessage(messages.negativeNumbersError),
			};
		}

		return {
			isValid: true,
		};
	};

	const daysValue = generatePreviewValueForRules(currentRules);
	const isOverdue = daysValue < 0;

	const content = (
		<>
			<FireUiAnalytics
				actionSubject="fieldDecorationMenu"
				action="viewed"
				actionSubjectId="viewControls"
			/>
			<FieldDecorator
				isNameEditable={!isOverdue}
				isHighlighted={currentHighlighted}
				color={currentColor}
				emojiId={currentEmojiId}
				input={{
					width: 60,
					label: formatMessage(messages[isOverdue ? 'dateIsOverdue' : 'dateIsWithin']),
					type: 'number',
					min: 0,
					labelPosition: 'left',
					useSeparateInputForEmoji: true,
				}}
				value={`${daysValue}`}
				onNameChange={onValueChange}
				onColorChanged={(hexCode) => {
					setCurrentColor(hexCode);
					onColorChanged(hexCode);
				}}
				onHighlightingChanged={(highlighted) => {
					setCurrentHighlighted(highlighted);
					onHighlightContainerChanged(highlighted);
				}}
				onEmojiSelected={(selectedEmoji) => {
					setCurrentEmojiId(selectedEmoji);
					onEmojiChanged(selectedEmoji);
				}}
				onNameValidate={handleValidateValue}
				outerSpacing={outerSpacing}
			/>
		</>
	);

	// Styles for section items
	const cssFn: ButtonItemProps['cssFn'] = () => ({
		padding: `${token('space.075', '6px')} ${token('space.200', '16px')}`,
		backgroundColor: 'transparent',
		color: token('color.text', N1000),
		'&:hover': {
			color: token('color.text', N1000),
			backgroundColor: token('color.background.neutral.hovered', N100),
		},
	});

	return (
		<Box>
			<MenuOption
				menuComponent={
					<Section>
						<ButtonItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={() => {
								onOpenChanged(!isOpen);
							}}
						>
							{formatMessage(messages.editRule)}
						</ButtonItem>
						<ButtonItem
							isDisabled={isClearFormattingDisabled}
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={onClearFormatting}
						>
							{formatMessage(messages.clearFormatting)}
						</ButtonItem>
						<ButtonItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							onClick={async () => {
								const { localDecorationId } = decoration;
								onValueDecorationDeleted && onValueDecorationDeleted({ localDecorationId });
							}}
						>
							{formatMessage(messages.deleteRule)}
						</ButtonItem>
					</Section>
				}
				isActive={isOpen}
				isDragEnabled={false}
				setOpenOption={() => onOpenChanged(false)}
				hideActionMenu={readonly}
				outerSpacing={outerSpacing}
			>
				<DateDecorationLabel
					rules={rules}
					color={currentColor}
					emoji={currentEmojiId}
					value={daysValue}
				/>
			</MenuOption>
			{isOpen && content}
		</Box>
	);
};
