import flattenDeep from 'lodash/flattenDeep';
import type { JiraAttachment } from '@atlassian/jira-polaris-domain-insight/src/snippet/attachment/types.tsx';
import { getMediaIds } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';

export const getInsightsAttachments = (
	insights: {
		snippets: {
			data: { content: Array<unknown> | { preview: unknown } } | null | undefined;
		}[];
	}[],
): JiraAttachment[] =>
	// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
	flattenDeep(
		insights.map((insight) =>
			insight.snippets?.map(
				(snippet: {
					data: { content: Array<unknown> | { preview: unknown } } | null | undefined;
				}) => {
					if (snippet.data && Array.isArray(snippet.data?.content)) {
						return snippet.data.content;
					}
					if (
						snippet.data?.content &&
						'preview' in snippet.data?.content &&
						snippet.data?.content?.preview !== undefined
					) {
						return [snippet.data?.content?.preview];
					}
					return [];
				},
			),
		),
	) // eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
		.filter(Boolean) // @ts-expect-error([Part of upgrade 4.9-5.4]) - 'contentItem' is of type 'unknown'.
		.filter((contentItem) => contentItem.type === 'attachment');

export const getInsightsMediaIds = (
	insights: { description: ADF | undefined | null }[],
): string[] => flattenDeep(insights.map((insight) => getMediaIds(insight.description)));
