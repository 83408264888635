import React, { type ComponentType } from 'react';
import SpaStateTransitionStateSubscriber from '@atlassian/jira-spa-state-controller/src/components/transition-state';

type InjectedProps = {
	isInitialRender: boolean;
};

export default function WithInitialSpaRender<P>(
	WrappedComponent: ComponentType<P>,
): ComponentType<P & InjectedProps> {
	return (props: P & InjectedProps) => (
		<SpaStateTransitionStateSubscriber>
			{(spaTransitionsState) => (
				<WrappedComponent {...props} isInitialRender={spaTransitionsState.isInitialRender} />
			)}
		</SpaStateTransitionStateSubscriber>
	);
}
