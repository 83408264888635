import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async';
import { AdfSkeleton } from '@atlassian/jira-polaris-lib-editor/src/common/ui/adf-skeleton/index.tsx';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import {
	WaitForAdfConsumerProps,
	IdeaCreateAdfController,
} from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import type { EditorActions } from '@atlassian/jira-polaris-lib-editor/src/ui/editor/types.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueModalCreateConfig } from '../../../../../services/gira/issue-modal-create-config';
import messages from './messages';

type AdfComponentProps = {
	isDisabled: boolean;
	isLoadingTemplate: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onUpdate: (value: any) => void;
	description: ADF | undefined;
};

const IDEA_CREATION_MODAL =
	'[data-testid="polaris-common.ui.create-idea-dialog.form-modal-dialog.issue-creation-form-modal"]';

export const AdfComponent = (props: AdfComponentProps) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const { formatMessage } = useIntl();
	const isEditorAiEnabled = useEditorAiEnabled();

	const { isDisabled, onUpdate, description, isLoadingTemplate } = props;
	const safeDescription = useMemo(() => prepareForRender(description), [description]);
	const [modalElement, setModalElement] = useState<HTMLElement | null>(null);

	useEffect(() => {
		const foundElement = document.querySelector<HTMLElement>(IDEA_CREATION_MODAL);
		if (foundElement) {
			setModalElement(foundElement);
		}
	}, []);

	const getMediaContext = useCallback(
		async ({ projectId: targetProjectId }: { projectId: ProjectId }) =>
			getIssueModalCreateConfig(targetProjectId).then((config) => {
				const uploadToken = config?.data?.jiraIssueCreateFields?.fields?.edges?.find(
					(f: { node: { fieldId: string } }) => f.node.fieldId === 'description',
				)?.node?.mediaContext?.uploadToken;

				const mediaUploadContext = {
					clientId: uploadToken.clientId,
					serviceHost: uploadToken.endpointUrl,
					collection: uploadToken.targetCollection,
					token: uploadToken.token,
					tokenLifespanInMs: uploadToken.tokenDurationInMin * 60 * 1000,
					tokenIssueTimestamp: Date.now() - 10000, // added 10s buffer to cover network calls
				};

				return {
					userAuth: null,
					viewContext: {
						...mediaUploadContext,
						areTokenPermissionsUpToDate: true,
					},
					uploadContext: mediaUploadContext,
				};
			}),
		[],
	);

	const onChange = useCallback(
		(value?: ADF) => {
			if (value === undefined) return;
			onUpdate(value);
		},
		[onUpdate],
	);

	const onEditorReady = useCallback(
		(actions: EditorActions) => {
			actions.getValue().then((value) => {
				if (!description) return;
				onUpdate(value);
			});
		},
		[description, onUpdate],
	);

	return (
		<IdeaCreateAdfController
			projectId={projectId}
			projectKey={projectKey}
			getMediaContext={getMediaContext}
		>
			<EditorWrapper ref={containerRef}>
				<WaitForAdfConsumerProps forceLoadingView={isLoadingTemplate}>
					{({ akEditorProps }) => (
						<EditorBoundary fallback={<AdfSkeleton />}>
							<Editor
								{...akEditorProps}
								disabled={isDisabled}
								withActionButtons={false}
								useStickyToolbar
								defaultValue={safeDescription}
								placeholder={formatMessage(messages.descriptionPlaceholder)}
								popupsMountPoint={modalElement || document.body || undefined}
								allowFullEditorCapabilities
								onChange={onChange}
								onEditorReady={onEditorReady}
								popupsBoundariesElement={containerRef.current || undefined}
								isAiEnabled={isEditorAiEnabled}
							/>
						</EditorBoundary>
					)}
				</WaitForAdfConsumerProps>
			</EditorWrapper>
		</IdeaCreateAdfController>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditorWrapper = styled.div({
	position: 'relative',
});
