import { useEffect, useMemo } from 'react';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { usePresence } from '@atlassian/jira-polaris-common/src/controllers/collab/selectors/hooks';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/controllers/user';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewAri,
	useCurrentViewLastViewed,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';

export type Visitor = {
	name?: string;
	accountId?: string;
	avatar?: string;
	timestamp?: Date;
	presence?: string;
};

export type Avatar = {
	href: string;
	src?: string;
	presence?: string;
};

export const useVisitors = (): [Visitor[], Avatar[]] => {
	const presence = usePresence();
	const previousPresence = usePrevious(presence);
	const lastViewed = useCurrentViewLastViewed();
	const { updateLastViewedState } = useViewActions();
	const currentViewAri = useCurrentViewAri();
	const previousViewAri = usePrevious(currentViewAri);
	const { data: user } = useCurrentUser();

	useEffect(() => {
		if (!lastViewed) return;
		if (!currentViewAri) return;
		if (previousViewAri !== currentViewAri) return;
		if (previousPresence === undefined) return;

		const wentOffline = previousPresence
			.filter((collabUser) => !presence.some((u) => collabUser.clientId === u.clientId))
			.map((collabUser) =>
				collabUser.clientId == null
					? null
					: {
							timestamp: new Date(),
							aaid: collabUser.clientId,
							account: {
								picture: collabUser.avatar,
								accountId: collabUser.clientId,
								name: collabUser.name,
							},
						},
			)
			.filter(Boolean);

		if (wentOffline.length > 0) {
			const lastViewedFiltered = (lastViewed ?? []).filter(
				(viewer) => !wentOffline.some((off) => off?.aaid === viewer.aaid),
			);
			const newLastViewed = [...lastViewedFiltered, ...wentOffline];
			updateLastViewedState(currentViewAri, newLastViewed);
		}
	}, [
		presence,
		previousPresence,
		previousViewAri,
		currentViewAri,
		lastViewed,
		updateLastViewedState,
	]);

	return useMemo(() => {
		if (!user) return [[], []];

		const previousVisitors: Visitor[] =
			lastViewed
				?.filter(
					(visitor) =>
						!presence.some((c) => c.clientId === visitor.aaid) && visitor.aaid !== user.accountId,
				)
				.map((visitor) => ({
					name: visitor.account?.name,
					accountId: visitor.aaid,
					avatar: visitor.account?.picture,
					timestamp: visitor.timestamp,
				})) ?? [];

		const allVisitors: Visitor[] = [
			...previousVisitors,
			...presence.map((p) => ({
				name: p.name,
				avatar: p.avatar,
				accountId: p.clientId,
				presence: 'online',
			})),
		];
		allVisitors.sort((a, b) => {
			if (!b.timestamp) return 1;
			if (!a.timestamp) return -1;
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			return b.timestamp - a.timestamp;
		});

		const avatarData = allVisitors.map((visitor) => ({
			presence: visitor.presence,
			src: visitor.avatar,
			href: '#',
		}));

		return [allVisitors, avatarData];
	}, [lastViewed, presence, user]);
};
