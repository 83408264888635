// THIS FILE WAS AUTO-GENERATED. MODIFY WITH CAUTION. See README.md

import { createIllustration, type SVGViewBox } from '@atlassian/jira-illustration';
import srcDark from '../../assets/icecream-accident.svg'; // eslint-disable-line import/no-duplicates, jira/import/no-duplicates
import srcLight from '../../assets/icecream-accident.svg'; // eslint-disable-line import/no-duplicates

const Illustration = createIllustration({
	srcLight,
	srcDark,
	autoDark: false, // the current illustration does not display well in dark mode - when eventually redrawn this should be removed
});

export default Illustration;

// for advanced use only
const viewBox: SVGViewBox = [0, 0, 277, 263];
export { srcLight, srcDark, viewBox };
