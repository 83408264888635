import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';

const localStorage = createLocalStorageProvider('polaris');

const SIDEBAR_WIDTH_STORAGE_KEY = 'sidebar-width';

export const setSidebarWidthToStorage = (width: number) => {
	try {
		localStorage.set(SIDEBAR_WIDTH_STORAGE_KEY, width);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'polaris.sidebar-resizer-error',
			'Failed to save sidebar size to localStorage',
			error,
		);
	}
};

export const getSidebarWidthFromStorage = (): number | null =>
	parseFloat(localStorage.get(SIDEBAR_WIDTH_STORAGE_KEY)) || null;
