import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-view-empty-state.views.archive-view.header',
		defaultMessage: 'No archived ideas',
		description: 'Header of the empty archive view',
	},
	description: {
		id: 'polaris-lib-view-empty-state.views.archive-view.description',
		defaultMessage: 'Archive unused ideas and restore them when needed.',
		description: 'Empty archive view hint',
	},
});
