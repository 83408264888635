import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/filter';
import { merge } from 'rxjs/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/takeLast';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/mergeMap';
import { FIELD_EDIT_REQUEST_EXTERNAL } from '@atlassian/jira-issue-view-store/src/actions/external-actions';
import {
	fieldEditBegin,
	FIELD_INIT_OPTIONS,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editRequestFromExternalSource = (action$: ActionsObservable<any>) =>
	action$
		.ofType(FIELD_INIT_OPTIONS, FIELD_EDIT_REQUEST_EXTERNAL)
		.groupBy((action) => action.payload.fieldId)
		.mergeMap((actionsForField$) => {
			const editActions$ = actionsForField$.filter(
				(action) => action.type === FIELD_EDIT_REQUEST_EXTERNAL,
			);
			const fieldOptions$ = actionsForField$.filter((action) => action.type === FIELD_INIT_OPTIONS);

			const earlyEditAction$ = editActions$.takeUntil(fieldOptions$).takeLast(1);
			const actionsBeforeFieldVisible = merge(earlyEditAction$, fieldOptions$.take(1)).pairwise();
			const actionsAfterFieldVisible = editActions$.withLatestFrom(fieldOptions$);

			return merge(actionsBeforeFieldVisible, actionsAfterFieldVisible).map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				([_editAction, fieldData]: [any, any]) => {
					const { fieldId, fieldOptions } = fieldData.payload;
					// @ts-expect-error - TS2554 - Expected 4 arguments, but got 2.
					return fieldEditBegin(fieldId, fieldOptions);
				},
			);
		});

export default editRequestFromExternalSource;
