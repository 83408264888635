import React, { type ComponentType, type ReactElement } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Popper, type Placement } from '@atlaskit/popper';
import { getPlacement } from './utils';

export { useHideProfileCardDelayed, useEventListener } from './utils';

type ProfileCardLegacyWrapperProps = {
	children: (fn: () => void) => ReactElement;
	referenceElement: HTMLElement;
	placement?: Placement;
	offset: string | null;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
};

// Updated version of popper requires tuple but provided value may be a string
const parsePopperOffsetValue = (
	offset: string | number[] | null,
): [number | null | undefined, number | null | undefined] => {
	let offsetArray: [number | null | undefined, number | null | undefined] = [0, 0];
	if (isEmpty(offset)) {
		return offsetArray;
	}

	if (typeof offset === 'string') {
		// @ts-expect-error - TS2322 - Type 'number[]' is not assignable to type '[number | null | undefined, number | null | undefined]'.
		offsetArray = offset.split(',').map((pxValue) => parseInt(pxValue.replace('px', ''), 10));
	} else if (Array.isArray(offset) && offset.length === 2) {
		// @ts-expect-error - TS2322 - Type 'number[]' is not assignable to type '[number | null | undefined, number | null | undefined]'.
		offsetArray = offset;
	}

	return offsetArray.some(Number.isNaN) ? [0, 0] : offsetArray;
};

export const ProfileCardLegacyWrapper: ComponentType<ProfileCardLegacyWrapperProps> = ({
	onMouseEnter,
	onMouseLeave,
	referenceElement,
	offset,
	children,
	placement,
}: ProfileCardLegacyWrapperProps) => {
	const offsetTuple: [number | null | undefined, number | null | undefined] =
		parsePopperOffsetValue(offset);
	const modifiers = [
		{
			name: 'offset',
			options: {
				offset: offsetTuple,
			},
		},
		{
			name: 'preventOverflow',
			options: {
				tether: true,
			},
		},
	];

	return (
		<Popper
			referenceElement={referenceElement}
			placement={placement || getPlacement(referenceElement)}
			modifiers={modifiers}
		>
			{({ ref, style, forceUpdate }) => (
				<div
					data-testid="profilecard-next.common.ui.legacy-profile-card.div"
					ref={ref}
					// eslint-disable-next-line jira/react/no-style-attribute
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					onMouseLeave={onMouseLeave}
					onMouseEnter={onMouseEnter}
				>
					{children(forceUpdate)}
				</div>
			)}
		</Popper>
	);
};
