import React, { useCallback, memo, useMemo, cloneElement } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { N20 } from '@atlaskit/theme/colors';
import { useThemeObserver, token } from '@atlaskit/tokens';
import type { RowGroupRendererProps } from '../../common/types/react-base-table';
import { NO_VALUE_GROUP_ID } from '../../constants';
import { useListActions } from '../../controllers';
import { useRowGroupComponent } from '../../controllers/selectors/components-hooks';
import {
	useFixedColumnIndices,
	useVisibleColumnIndices,
	useInvisibleColumnPlaceholderWidthLeft,
	useIsExporting,
} from '../../controllers/selectors/ui-hooks';
import { SelectAllInGroupCheckBox } from '../cell/selection-checkbox';
import { borderColor } from '../constants';
import { RowContainer } from '../row/main';

const DefaultRowGroupContainer = memo<RowGroupRendererProps>(
	({ cells, children, rowData }: RowGroupRendererProps) => {
		const fixedColumnIndices = useFixedColumnIndices();
		const visibleColumnIndices = useVisibleColumnIndices();
		const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();
		const { colorMode } = useThemeObserver();
		const isDarkMode = colorMode === 'dark';

		const visibleCells = useMemo(
			() =>
				cells.filter(
					(_, index) => visibleColumnIndices.includes(index) && !fixedColumnIndices.includes(index),
				),
			[cells, fixedColumnIndices, visibleColumnIndices],
		);

		const fixedColumnsWidth = useMemo(
			() =>
				cells
					.filter((_, index) => fixedColumnIndices.includes(index))
					.reduce(
						// @ts-expect-error expected
						(acc, cell) => acc + cell?.props?.style?.width || 0,
						0,
					),
			[cells, fixedColumnIndices],
		);

		const { isExpanded, key } = rowData;
		const groupId = key === undefined ? NO_VALUE_GROUP_ID : key;

		const { toggleRowGroupExpansion } = useListActions();

		const onChangeExpansion = useCallback(() => {
			toggleRowGroupExpansion(groupId);
		}, [groupId, toggleRowGroupExpansion]);

		const fixedPart = useMemo(
			() => (
				<FixedPartWrapper>
					<ActionContainer>
						<CollapseButtonContainer>
							<Button
								onClick={onChangeExpansion}
								appearance="subtle"
								spacing="none"
								iconBefore={
									isExpanded ? <ChevronDownIcon label="" /> : <ChevronRightIcon label="" />
								}
							/>
						</CollapseButtonContainer>
						<SelectAllInGroupCheckBox groupId={groupId} />
					</ActionContainer>
					{children}
				</FixedPartWrapper>
			),
			[children, isExpanded, onChangeExpansion, groupId],
		);

		const lastFixedColumnIndex = fixedColumnIndices[fixedColumnIndices.length - 1];

		const fixedCell = useMemo(
			() =>
				cloneElement(
					// @ts-expect-error expected
					cells[lastFixedColumnIndex],
					{
						style: {
							// @ts-expect-error expected
							...(cells[lastFixedColumnIndex]?.props?.style ?? {}),
							width: fixedColumnsWidth,
							left: 0,
						},
					},
					fixedPart,
				),
			[cells, fixedColumnsWidth, fixedPart, lastFixedColumnIndex],
		);

		return (
			<RowGroupContainer
				data-testid="polaris-lib-list.ui.group-row.row"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={isExpanded ? 'expanded' : 'collapsed'}
			>
				<ContentContainer>
					{fixedCell}
					{placeholderWidthLeft > 0 && (
						<PlaceholderLeft
							isDarkMode={isDarkMode}
							// eslint-disable-next-line jira/react/no-style-attribute
							style={{
								width: `${placeholderWidthLeft}px`,
							}}
						/>
					)}
					{visibleCells}
					<PlaceholderRight isDarkMode={isDarkMode} />
				</ContentContainer>
			</RowGroupContainer>
		);
	},
);

export const LegacyRowGroupComponent = memo<RowGroupRendererProps>(
	({ ...props }: RowGroupRendererProps) => {
		const { key: groupId } = props.rowData;

		const isExporting = useIsExporting();

		const { toggleRowGroupExpansion, triggerRowGroupExpansion } = useListActions();
		const onChangeExpansion = useCallback(() => {
			toggleRowGroupExpansion(groupId);
		}, [groupId, toggleRowGroupExpansion]);

		const onExpandGroup = useCallback(() => {
			triggerRowGroupExpansion(groupId);
		}, [groupId, triggerRowGroupExpansion]);

		const ConsumerComponent = useRowGroupComponent();

		if (ConsumerComponent !== undefined) {
			return (
				<DefaultRowGroupContainer {...props} onChangeExpansion={onChangeExpansion}>
					<ConsumerComponent
						groupId={groupId === NO_VALUE_GROUP_ID ? undefined : groupId}
						isExporting={isExporting}
						onExpandGroup={onExpandGroup}
					/>
				</DefaultRowGroupContainer>
			);
		}

		return (
			<DefaultRowGroupContainer {...props} onChangeExpansion={onChangeExpansion}>
				<RowContentContainer>{groupId}</RowContentContainer>
			</DefaultRowGroupContainer>
		);
	},
);

export { StickyRowGroupComponent } from './sticky';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapseButtonContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: '32px',
	minHeight: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContentContainer = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowGroupContainer = styled(RowContainer)({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div<{ isDarkMode: boolean }>({
	boxSizing: 'border-box',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isDarkMode }) => (isDarkMode ? '#22272B' : N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${borderColor}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLeft = styled(Placeholder)({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderRight = styled(Placeholder)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FixedPartWrapper = styled.div({
	display: 'flex',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	marginLeft: token('space.negative.100', '-8px'),
});
