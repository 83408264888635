import React, { useState, useRef } from 'react';
import { styled } from '@compiled/react';
import findLast from 'lodash/findLast';
import Button from '@atlaskit/button';
import { Emoji } from '@atlaskit/emoji';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { SearchableDropdown } from '@atlassian/jira-polaris-common/src/ui/common/decoration/searchable-dropdown';
import { EmojiWrapper } from '@atlassian/jira-polaris-common/src/ui/field-config/item/emoji-wrapper';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';

const OptionComponent = ({ field }: { field: Field }) => {
	const icon = useFieldTypeIcon(field.key, {
		label: field.label,
	});
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<OptionContainer>
			{emoji ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Box xcss={iconContainerStyles}>{icon}</Box>
			)}
			<OptionText>{field.label}</OptionText>
		</OptionContainer>
	);
};

type SortFieldComponentProps = {
	selectedField?: Field;
	asc: boolean;
	fields: Field[];
	label?: string;
	isDisabled?: boolean;
	isHoverable?: boolean;
	onClearSort?: () => void;
	onClickDirection: () => void;
	onChangeField: (field: Field) => void;
};

export const SortFieldComponent = ({
	selectedField,
	asc,
	fields,
	label,
	onClearSort,
	onClickDirection,
	onChangeField,
}: SortFieldComponentProps) => {
	const { formatMessage } = useIntl();

	const buttonRef = useRef<HTMLElement | null>(null);

	const [dialogOpen, setDialogOpen] = useState(false);
	const toggleDialog = () => setDialogOpen(!dialogOpen);

	const toggleIcon = asc ? (
		<ArrowUpIcon size="small" label={formatMessage(messages.ascSortButton)} />
	) : (
		<ArrowDownIcon size="small" label={formatMessage(messages.descSortButton)} />
	);

	const renderTrigger = () =>
		selectedField ? (
			<OptionComponent field={selectedField} />
		) : (
			formatMessage(messages.fieldSelectPlaceholder)
		);

	return (
		<SortFieldContainer data-component-selector="sort-field-container-a7G5">
			<DragHandle
				// @ts-expect-error - TS2322 - Type '{ children: Element; isDragDisabled: boolean; label: string; type: string; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'.
				label="view-dragHandle"
				type="drag-handle"
				data-component-selector="drag-handle-68Hh"
			>
				<DragHandlerIcon label="" />
			</DragHandle>
			<SortFieldLabel>{label}</SortFieldLabel>
			<SortFieldGroup>
				<SortFieldInput>
					<PolarisInlineDialog
						noPadding
						onClose={(target) => {
							if (buttonRef.current && !buttonRef.current.contains(target)) {
								setDialogOpen(false);
							}
						}}
						isOpen={dialogOpen}
						placement="bottom"
						content={
							<>
								<FireUiAnalytics
									actionSubject="sortFieldMenu"
									action="viewed"
									actionSubjectId="view-controls"
								/>
								<SearchableDropdown
									options={fields.map(({ key, label: fieldLabel }) => ({
										key,
										label: fieldLabel,
									}))}
									onSelect={(key) => {
										const field = findLast(fields, { key });
										field !== undefined && onChangeField(field);
										setDialogOpen(false);
									}}
									onClose={() => setDialogOpen(false)}
								/>
							</>
						}
					>
						<Button
							testId="polaris-ideas.ui.view-controls.config-sort.field.button"
							ref={buttonRef}
							isSelected={dialogOpen}
							onClick={toggleDialog}
							iconAfter={<ChevronDownIcon size="medium" label="" />}
						>
							{renderTrigger()}
						</Button>
					</PolarisInlineDialog>
					{selectedField && <Button onClick={onClickDirection} iconBefore={toggleIcon} />}
				</SortFieldInput>
				{onClearSort && (
					<Button
						appearance="subtle-link"
						onClick={onClearSort}
						iconBefore={<TrashIcon size="small" label={formatMessage(messages.clearButton)} />}
					/>
				)}
			</SortFieldGroup>
		</SortFieldContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortFieldContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	padding: '5px 0',
	'&:hover, &:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N20A),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragHandle = styled.span({
	display: 'flex',
	alignItems: 'center',
	width: '16px',
	transition: 'opacity 150ms',
	opacity: 0,
	justifyContent: 'center',
	cursor: 'grab',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="sort-field-container-a7G5"]:hover &, [data-component-selector="sort-field-container-a7G5"]:active &':
		{
			opacity: 1,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		lineHeight: 'unset',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		maxWidth: 'unset',
		maxHeight: 'unset',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortFieldGroup = styled.div({
	display: 'flex',
	flex: 1,
	justifyContent: 'space-between',
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		marginLeft: token('space.025', '2px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortFieldLabel = styled.div({
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SortFieldInput = styled.div({
	display: 'flex',
});

const iconContainerStyles = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	maxWidth: '120px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionText = styled.div({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
});
