import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noViewsToShowMessage: {
		id: 'polaris-lib-view-empty-state.no-views-empty-state.no-views-to-show-message',
		defaultMessage: 'No views to show',
		description:
			"A message that's displayed on the screen when the user doesn't have access to any views",
	},
	noViewsToShowMessageDescription: {
		id: 'polaris-lib-view-empty-state.no-views-empty-state.no-views-to-show-message-description',
		defaultMessage: 'You don’t have access to any view in this project.',
		description:
			"A message description that's displayed on the screen when the user doesn't have access to any views",
	},
});
