import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { type Ari, getAriConfig } from '@atlassian/jira-platform-ari';

const getPolarisApiServiceHost = () =>
	getFeatureFlagValue<string>('polaris.polaris-api-service-host', 'default');

const getUri = (playId: string): string => {
	let ffHost = getPolarisApiServiceHost();
	if (!ffHost || ffHost === 'default') {
		ffHost = '/gateway/api/polaris';
	}

	return `${ffHost}/rest/plays/${playId}`;
};

export const deletePlay = (playId: Ari): Promise<void> => {
	// extract the playResourceId from the Ari
	const { resourceId } = getAriConfig(playId);

	return performDeleteRequest(getUri(resourceId));
};
