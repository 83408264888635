import React, { type ReactNode, useState, useEffect, useRef } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import type { IssueError } from '@atlassian/jira-issue-shared-types';
import { getIssueErrorAttributes } from '@atlassian/jira-issue-view-errors/src/common/utils';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';

import { IssueErrorViewWithSuccessTracking } from '../issue-error-view-with-success-tracking';

type Props = {
	errorBoundaryId: string;
	children: ReactNode;
	renderFeedback?: () => ReactNode;
	// eslint-disable-next-line jira/react/handler-naming
	fallback?: (errorComponent: ReactNode, errorType: IssueError) => ReactNode;
	issueKey?: IssueKey;
	loginRedirectUrl: string | undefined;
};

export const IssueViewErrorBoundary = ({
	errorBoundaryId,
	children,
	issueKey,
	loginRedirectUrl,
	fallback,
}: Props) => {
	const accountIdLoggedInUser = useAccountId();
	const [caughtError, setCaughtError] = useState<Error | undefined>();
	const [errorKey, setErrorKey] = useState<number>(0);
	// We are purposely not using the usePrevious/usePreviousWithInitial hook as it has its own useEffect internally meaning
	// because of asynchronicity when we are checking it to update the error key it is not up to date so we need to manage this state ourselves
	const previousIssueKeyRef = useRef<IssueKey | undefined>(issueKey);

	useEffect(() => {
		if (issueKey !== previousIssueKeyRef.current && caughtError) {
			// When we error we are incrementing the key so that we completely remount the issue view + AppProvider as the error will otherwise be cached
			setErrorKey((key) => key + 1);
			setCaughtError(undefined);
		}
		previousIssueKeyRef.current = issueKey;
	}, [issueKey, caughtError, previousIssueKeyRef]);

	return (
		<JSErrorBoundary
			key={errorKey}
			id={errorBoundaryId}
			/* eslint-disable-next-line jira/js/package-name-enforcer-for-error-boundary */
			packageName="issue"
			onError={(_, error) => {
				setCaughtError(error);
			}}
			fallback={(errorProps) => {
				const { errorType, errorAttributes } = getIssueErrorAttributes(
					errorProps.error,
					accountIdLoggedInUser,
				);

				const isIssueKeyFresh = issueKey === previousIssueKeyRef.current;

				// We should avoid tracking the error if the issue key has changed as this means we are remounting the issue view
				const errorComponent = isIssueKeyFresh ? (
					<IssueErrorViewWithSuccessTracking
						errorType={errorType}
						loginRedirectUrl={loginRedirectUrl}
						errorAttributes={errorAttributes}
					/>
				) : (
					<>{null}</>
				);

				if (fallback) {
					return <>{fallback(errorComponent, errorType)}</>;
				}
				return errorComponent;
			}}
		>
			{children}
		</JSErrorBoundary>
	);
};
