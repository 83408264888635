import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addValue: {
		id: 'polaris-lib-option-utils.option-entry.add-value',
		defaultMessage: 'Add option',
		description: 'Label for option to add a new value in the value selection dropdown',
	},
	addValueBasedOnSearch: {
		id: 'polaris-lib-option-utils.option-entry.add-value-based-on-search',
		defaultMessage: 'Create "{value}"',
		description: 'Label for option to add a new value for the current search string',
	},
});
