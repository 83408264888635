import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';

export const switchPreviewContributorMode =
	(isPreviewOn: boolean): Action<State, Props> =>
	({ setState }) => {
		setState({
			isPreviewContributorMode: isPreviewOn,
		});
	};
