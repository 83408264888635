import { useState, useMemo } from 'react';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { conjunctiveLabelFilter } from '../../../../../../../controllers/project/insight/filter';
import { type RollupField, PROPERTY_ROLLUP, COUNT_ROLLUP } from '../../types';
import { useRollupFormulaParser, type RollupFormulaState, emptyState } from '../formula-parser';

type RollupFormulaStateExt = RollupFormulaState & {
	// this is set to false when formula is parsed initially, true after any future change
	isUpdated: boolean;
};

type RollupFormulaOperations = {
	addField: (arg1: RollupField) => void;
	removeField: (arg1: number) => void;
	setPercentageValue: (arg1: number, arg2: number | undefined) => void;
	setLabelFilter: (arg1: number, arg2: string[] | undefined) => void;
	setWeightedScore: (arg1: boolean) => void;
};

export const useFormulaState = (
	formula?: DynamicFieldFormula,
	initAsWeightedScore?: boolean,
): [RollupFormulaStateExt, RollupFormulaOperations] => {
	const rollupFormulaParser = useRollupFormulaParser();
	const [state, setState] = useState(
		formula
			? { ...rollupFormulaParser(formula), isUpdated: false }
			: { ...emptyState(initAsWeightedScore === true), isUpdated: false },
	);
	return useMemo(
		() => [
			state,
			{
				addField: (rollupField) =>
					setState({
						rollupFields: [...state.rollupFields, rollupField],
						percentages: [...state.percentages, undefined],
						weightedScore: state.weightedScore,
						isUpdated: true,
					}),
				removeField: (index) =>
					setState({
						rollupFields: state.rollupFields.filter((_, i) => index !== i),
						percentages: state.percentages.filter((_, i) => index !== i),
						weightedScore: state.weightedScore,
						isUpdated: true,
					}),
				setPercentageValue: (index, value) => {
					state.percentages.splice(index, 1, value);
					setState({
						...state,
						percentages: [...state.percentages],
						isUpdated: true,
					});
				},
				setLabelFilter: (index, values) => {
					const rollupField = state.rollupFields[index];
					if (
						rollupField !== undefined &&
						(rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP)
					) {
						const newRollupFields = [...state.rollupFields];
						// @ts-expect-error - TS2345 - Argument of type '{ value: { filter: Conjunction; id: string; key: string; label: string; kind: string; appName: string; oauthClientId: string; avatarUrl: string; } | { filter: Conjunction; ... 4 more ...; avatarUrl?: string | undefined; }; kind: "property"; } | { ...; }' is not assignable to parameter of type 'RollupField'.
						newRollupFields.splice(index, 1, {
							...rollupField,
							value: {
								...rollupField.value,
								filter: conjunctiveLabelFilter(values || []),
							},
						});
						setState({
							...state,
							rollupFields: newRollupFields,
							isUpdated: true,
						});
					}
				},
				setWeightedScore: (weightedScore) =>
					setState({
						...state,
						weightedScore,
						isUpdated: true,
					}),
			},
		],
		[state],
	);
};
