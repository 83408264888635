import React, { useCallback, useState } from 'react';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSummary } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { EditableSummary } from './main';
import { handleError } from './utils';

type Props = {
	localIssueId: LocalIssueId;
};

export const Summary = ({ localIssueId }: Props) => {
	const [isEditing, setEditing] = useState(false);
	const value = useSummary(localIssueId);
	const initialValue = value || '';
	const [isEditingAllowed] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	const handleEdit = useCallback(() => {
		if (isEditingAllowed) {
			setEditing(true);
			experience.ideaView.summaryUpdate.start();
		}
	}, [isEditingAllowed]);

	const handleConfirm = useCallback(
		(newValue: string) => {
			if (isEditingAllowed) {
				updateFieldValue(
					{
						fieldKey: 'summary',
						localIssueIds: [localIssueId],
						newValue,
					},
					() => {
						experience.ideaView.summaryUpdate.success();
					},
					(error?: Error) => handleError(experience.ideaView.summaryUpdate, error),
				);
			}
			setEditing(false);
		},
		[isEditingAllowed, localIssueId, updateFieldValue],
	);

	const handleCancel = useCallback(() => {
		setEditing(false);
		experience.ideaView.summaryUpdate.abort();
	}, []);

	return (
		<EditableSummary
			summary={initialValue}
			isEditingAllowed={isEditingAllowed}
			isEditing={isEditing}
			onEdit={handleEdit}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
		/>
	);
};
