import { useCallback } from 'react';
import noop from 'lodash/noop';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	VIEW_RANK,
	PROJECT_RANK,
} from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIssueActions } from '../issue/main.tsx';
import { useLocalIssueIdToJiraIssueIdMapper } from '../issue/selectors/issue-ids-hooks';
import { useSortedUnfilteredIssueIds as useSortedUnfilteredLocalIssueIds } from '../issue/selectors/sort-hooks';
import type { IssueCreatedProperty, IssueCreatedPropertyItem } from '../issue/types';
import type { IssueType } from '../project/types.tsx';
import { useViewActions } from '../views/main.tsx';
import { useCurrentViewSortMode } from '../views/selectors/view-hooks';

const useSortedUnfilteredJiraIssueIds = (): IssueId[] => {
	const sortedUnfilteredIssueIds = useSortedUnfilteredLocalIssueIds();
	const idMapper = useLocalIssueIdToJiraIssueIdMapper();
	return sortedUnfilteredIssueIds.map(idMapper).filter(Boolean);
};

const useViewScopedIssueRankingAction = () => {
	const { adaptExternalIssueRank: adaptExternalIssueRankInIssueStore } = useIssueActions();
	const { moveIssueInCurrentView } = useViewActions();
	const sortedUnfilteredIssueIds = useSortedUnfilteredJiraIssueIds();
	const idMapper = useLocalIssueIdToJiraIssueIdMapper();

	return useCallback(
		(id: LocalIssueId, destinationBeforeId?: LocalIssueId, destinationAfterId?: LocalIssueId) => {
			const issueId = idMapper(id);
			if (issueId === undefined) {
				return;
			}
			fireCompoundAnalyticsEvent.IdeaRankedInView();
			adaptExternalIssueRankInIssueStore(
				issueId,
				destinationBeforeId ? idMapper(destinationBeforeId) : undefined,
				destinationAfterId ? idMapper(destinationAfterId) : undefined,
				sortedUnfilteredIssueIds,
			);
			moveIssueInCurrentView(
				issueId,
				destinationBeforeId ? idMapper(destinationBeforeId) : undefined,
				destinationAfterId ? idMapper(destinationAfterId) : undefined,
				sortedUnfilteredIssueIds,
			);
		},
		[
			idMapper,
			adaptExternalIssueRankInIssueStore,
			sortedUnfilteredIssueIds,
			moveIssueInCurrentView,
		],
	);
};

const useProjectScopedIssueRankingAction = () => {
	const { moveIssue } = useIssueActions();
	return useCallback(
		(id: LocalIssueId, destinationBeforeId?: LocalIssueId, destinationAfterId?: LocalIssueId) => {
			fireCompoundAnalyticsEvent.IdeaRankedInView();
			moveIssue(id, destinationBeforeId, destinationAfterId);
		},
		[moveIssue],
	);
};

/**
 * Dependent on the sort mode of the current view this hook returns the action needed to
 * manipulate the correctly scoped ranking data.
 */
export const useSortingAwareIssueRankingAction = (): ((
	arg1: LocalIssueId,
	arg2?: LocalIssueId,
	arg3?: LocalIssueId,
) => void) => {
	const projectScopedAction = useProjectScopedIssueRankingAction();
	const viewScopedAction = useViewScopedIssueRankingAction();
	const sortMode = useCurrentViewSortMode();

	if (sortMode === VIEW_RANK) {
		return viewScopedAction;
	}
	if (sortMode === undefined || sortMode === PROJECT_RANK) {
		return projectScopedAction;
	}
	return noop;
};

export const useSortingAwareIssueRankingActions = () => {
	const moveIssue = useSortingAwareIssueRankingAction();
	return {
		rankBefore: useCallback(
			(id: LocalIssueId, destinationBeforeId: LocalIssueId) => moveIssue(id, destinationBeforeId),
			[moveIssue],
		),
		rankAfter: useCallback(
			(id: LocalIssueId, destinationAfterId: LocalIssueId) =>
				moveIssue(id, undefined, destinationAfterId),
			[moveIssue],
		),
	};
};

/**
 * Dependent on the sort mode of the current view this hook returns the action needed to
 * create an issue and rank it with the correctly scoped ranking data.
 */
export const useSortingAwareIssueCreationAction = () => {
	const { saveIssue } = useIssueActions();
	const { insertIssueAtIndexInCurrentView } = useViewActions();
	const sortMode = useCurrentViewSortMode();
	const sortedUnfilteredIssueIds = useSortedUnfilteredJiraIssueIds();

	return useCallback(
		(
			id: LocalIssueId,
			issueType: IssueType,
			summary: string,
			clonedCreatedProperty: IssueCreatedPropertyItem,
			clonedIds: LocalIssueId[],
			onCreatedIssueFiltered: (arg1: LocalIssueId) => void,
			positionIndex: number,
			onIssueSaved?: (options: {
				issueId: IssueId;
				localIssueId: LocalIssueId;
				createdProperty: IssueCreatedProperty[string];
			}) => void,
			onIssueSaveError?: (arg1: Error) => void,
		) => {
			if (sortMode === VIEW_RANK) {
				return saveIssue(
					id,
					issueType,
					summary,
					clonedCreatedProperty,
					clonedIds,
					onCreatedIssueFiltered,
					({ issueId, localIssueId, createdProperty }) => {
						insertIssueAtIndexInCurrentView(positionIndex, issueId, sortedUnfilteredIssueIds);
						onIssueSaved &&
							onIssueSaved({
								issueId,
								localIssueId,
								createdProperty,
							});
					},
					onIssueSaveError,
				);
			}
			return saveIssue(
				id,
				issueType,
				summary,
				clonedCreatedProperty,
				clonedIds,
				onCreatedIssueFiltered,
				onIssueSaved,
				onIssueSaveError,
			);
		},
		[saveIssue, sortMode, insertIssueAtIndexInCurrentView, sortedUnfilteredIssueIds],
	);
};

/**
 * Dependent on the sort mode of the current view this hook returns the action needed to
 * rank issue at the top with respect to the correctly scoped ranking data.
 */
export const useSortingAwareMoveIssuesToTopAction = (): ((arg1: LocalIssueId[]) => void) => {
	const sortedUnfilteredIssueIds = useSortedUnfilteredJiraIssueIds();
	const { moveIssuesToTop: moveIssuesToTopProjectScoped } = useIssueActions();
	const { clearSelection, moveIssuesToTopOfCurrentView } = useViewActions();
	const sortMode = useCurrentViewSortMode();
	const idMapper = useLocalIssueIdToJiraIssueIdMapper();
	const viewScopedAction = useCallback(
		(visibleSelectedLocalIssueIds: LocalIssueId[]) => {
			moveIssuesToTopOfCurrentView(
				visibleSelectedLocalIssueIds.map(idMapper).filter(Boolean),
				sortedUnfilteredIssueIds,
			);
			clearSelection(visibleSelectedLocalIssueIds);
		},
		[moveIssuesToTopOfCurrentView, idMapper, sortedUnfilteredIssueIds, clearSelection],
	);
	const projectScopedAction = useCallback(
		(visibleSelectedIssueIds: LocalIssueId[]) => {
			moveIssuesToTopProjectScoped(visibleSelectedIssueIds);
			clearSelection(visibleSelectedIssueIds);
		},
		[clearSelection, moveIssuesToTopProjectScoped],
	);
	if (sortMode === VIEW_RANK) {
		return viewScopedAction;
	}
	if (sortMode === undefined || sortMode === PROJECT_RANK) {
		return projectScopedAction;
	}
	return noop;
};

export const useSetSortModeOfCurrentViewToProjectRankAndCommitViewRank = () => {
	const { setSortModeOfCurrentViewToProjectRank } = useViewActions();
	const { moveIssuesToTop: moveIssuesToTopProjectScoped } = useIssueActions();
	const sortedUnfilteredLocalIssueIds = useSortedUnfilteredLocalIssueIds();

	return useCallback(() => {
		moveIssuesToTopProjectScoped(sortedUnfilteredLocalIssueIds);
		setSortModeOfCurrentViewToProjectRank(true);
	}, [
		setSortModeOfCurrentViewToProjectRank,
		moveIssuesToTopProjectScoped,
		sortedUnfilteredLocalIssueIds,
	]);
};

export const useSetSortModeOfCurrentViewToViewRankAndAddCurrentSort = () => {
	const { setSortModeOfCurrentViewToViewRank } = useViewActions();
	const sortedUnfilteredLocalIssueIds = useSortedUnfilteredJiraIssueIds();

	return useCallback(() => {
		setSortModeOfCurrentViewToViewRank(sortedUnfilteredLocalIssueIds, true);
	}, [setSortModeOfCurrentViewToViewRank, sortedUnfilteredLocalIssueIds]);
};

export const useSetSortModeOfCurrentViewToProjectRankAndCommitCurrentSort = () => {
	const { setSortModeOfCurrentViewToProjectRank } = useViewActions();
	const { rankIdeasWithCurrentSorting } = useIssueActions();

	return useCallback(() => {
		rankIdeasWithCurrentSorting();
		setSortModeOfCurrentViewToProjectRank();
	}, [rankIdeasWithCurrentSorting, setSortModeOfCurrentViewToProjectRank]);
};
