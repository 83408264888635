import React, { useCallback, memo, useEffect } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { ErrorMessage, Field } from '@atlaskit/form';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useIsSharedView } from '../../../controllers/environment';
import { useFieldActions } from '../../../controllers/field/main.tsx';
import { useFieldDescription } from '../../../controllers/field/selectors/field-hooks';
import { useField } from '../../../controllers/issue/selectors/fields-hooks';
import { FieldTooltip } from '../../field-tooltip';
import { useGetFieldConfig } from '../field-config';

const LabelWithTooltip = ({
	fieldKey,
	label,
	isRequired,
	isPreview,
}: {
	fieldKey: FieldKey;
	label: string;
	isRequired: boolean;
	isPreview?: boolean;
}) => {
	const description = useFieldDescription(fieldKey);
	return (
		<TooltipContainer>
			<div>{label}</div>
			{isRequired && <RequiredAsterisk>*</RequiredAsterisk>}
			{!isEmpty(description.value) && !isPreview && (
				<FieldTooltip fieldKey={fieldKey} strategy="hover" position="right">
					<EditorInfoIcon
						label="info"
						primaryColor={token('color.icon.information', colors.B500)}
					/>
				</FieldTooltip>
			)}
		</TooltipContainer>
	);
};

type FieldRendererProps = {
	isPreview?: boolean;
	fieldKey: FieldKey;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue?: (name: string, value: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	predefinedValue?: any;
	shouldUpdateTemplate?: boolean;
};

export const FieldRenderer = memo(
	({
		isPreview,
		fieldKey,
		setFieldValue,
		predefinedValue,
		shouldUpdateTemplate,
	}: FieldRendererProps) => {
		const field = useField(fieldKey);
		const fieldConfig = useGetFieldConfig(fieldKey);
		const { loadGlobalLabels } = useFieldActions();

		const isSharedView = useIsSharedView();

		const onUpdate = useCallback(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(newValue?: any) => {
				if (setFieldValue) {
					setFieldValue(fieldKey, newValue);
				}
			},
			[fieldKey, setFieldValue],
		);

		useEffect(() => {
			if (field?.type === FIELD_TYPES.CUSTOM_LABELS) {
				loadGlobalLabels(field.key);
			}
		}, [field, loadGlobalLabels]);

		if (!field || !fieldConfig) {
			return null;
		}

		const { label, editable, key } = field;
		const { Component, isRequired, validateFieldValue } = fieldConfig;

		return (
			<FieldWrapper isPreview={isPreview}>
				<Field
					defaultValue={predefinedValue}
					label={
						<LabelWithTooltip
							label={label}
							fieldKey={fieldKey}
							isRequired={isRequired}
							isPreview={isPreview}
						/>
					}
					id={key}
					name={fieldKey}
					validate={validateFieldValue}
				>
					{({ fieldProps, error }) => (
						<>
							<Component
								predefinedValue={predefinedValue}
								isEditable={editable || isSharedView}
								onUpdate={onUpdate}
								isPreview={isPreview}
								shouldUpdateTemplate={shouldUpdateTemplate}
								{...fieldProps}
							/>
							{error !== undefined && <ErrorMessage>{error}</ErrorMessage>}
						</>
					)}
				</Field>
			</FieldWrapper>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldWrapper = styled.div<{ isPreview?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':not(:nth-of-type(1)) > div': {
		marginTop: token('space.150', '12px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-testid="ak-editor-main-toolbar"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		zIndex: ({ isPreview }) => (isPreview ? 3 : undefined),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContainer = styled.div({
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequiredAsterisk = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.danger', colors.R400),
});
