import React from 'react';
import { styled } from '@compiled/react';
import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { ColumnShape } from '../../common/types/react-base-table';
import { PRESENTATIONAL_FIELDKEYS } from '../../constants';
import { useHighlightedRow } from '../../controllers/selectors/ui-hooks';
import type { TableRowProps } from '../../types';
import { cellRenderer } from '../cell';
import { ROW_BORDER_WIDTH, activeBorderColor } from '../constants';
import { RowComponent } from '../row/main';
import { useFullColumnConfig } from '../utils/columns';

const RowPreview = ({ id, ...rest }: TableRowProps) => {
	const cols = useFullColumnConfig();
	const frozenCols = cols.filter((col: ColumnShape) => col.frozen);

	const highlightedRow = useHighlightedRow();
	const isHighlighted = id === highlightedRow;

	const cells = frozenCols.map(({ key, width }: ColumnShape, index) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const isPresentational = Object.values(PRESENTATIONAL_FIELDKEYS).includes(key as string);
		return (
			// we need to apply table specific styles to the cells
			<ClonedCellWrapper
				key={key}
				width={width}
				noPadding={isPresentational}
				isFirstCell={index === 0}
				isHighlighted={isHighlighted}
			>
				{cellRenderer({
					column: { key, width },
					columnIndex: 0,
					rowData: { id, ...rest },
					rowIndex: rest.index,
				})}
			</ClonedCellWrapper>
		);
	});

	const columns = frozenCols.map(({ key, width }: ColumnShape) => ({
		key,
		width,
	}));

	return (
		<RowComponent rowIndex={rest.index} rowData={{ id, ...rest }} cells={cells} columns={columns} />
	);
};

export { RowPreview };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ClonedCellWrapper = styled.div<{
	noPadding: boolean;
	width: string | number;
	isFirstCell: boolean;
	isHighlighted: boolean;
}>({
	flex: '0 0 auto',
	height: '100%',
	overflow: 'hidden',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ noPadding }) => (noPadding ? '0' : '0 7.5px'),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	minWidth: '0px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRight: ({ noPadding }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		noPadding ? 'none' : `${ROW_BORDER_WIDTH}px solid ${token('color.border', N40)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: '100% !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderTop: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isHighlighted ? `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}` : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderBottom: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isHighlighted ? `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}` : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	borderLeft: ({ isHighlighted, isFirstCell }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		isHighlighted && isFirstCell ? `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}` : undefined,
});
