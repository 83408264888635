import React from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DecoratorOptionsList } from './options';

export type DecoratorItemsProps = {
	showOptionCreateButton: boolean;
	onOpenOption: (id: string | null, field?: FieldKey) => void;
	decorationConfigHeader?: React.JSX.Element;
	readonly?: boolean;
};

export const DecoratorOptionItems = ({
	showOptionCreateButton,
	onOpenOption,
	decorationConfigHeader,
	readonly = false,
}: DecoratorItemsProps) => (
	<DecoratorOptionsList
		showOptionCreateButton={showOptionCreateButton}
		onOpenOption={onOpenOption}
		decorationConfigHeader={decorationConfigHeader}
		readonly={readonly}
	/>
);
