import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { TIMELINE_DATES_CONFIG_SECTION_MESSAGE_DISMISSED } from '../../../constants';
import type { Props, State } from '../../../types';

export const loadTimelineSectionMessageDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().timeline.sectionMessageDismissed === 'boolean') {
			return;
		}

		try {
			const timelineSectionMessageDismissed = await getUserProperty<boolean>(
				accountId,
				TIMELINE_DATES_CONFIG_SECTION_MESSAGE_DISMISSED,
			);

			const state = getState();

			setState({
				...state,
				timeline: {
					sectionMessageDismissed: timelineSectionMessageDismissed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && error.statusCode === 404) {
				const state = getState();

				setState({
					...state,
					timeline: {
						sectionMessageDismissed: false,
					},
				});

				return;
			}

			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.timeline.spotlight',
				`Failed to get user property with key: ${TIMELINE_DATES_CONFIG_SECTION_MESSAGE_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};

export const setTimelineSectionMessageDismissed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			timeline: {
				sectionMessageDismissed: true,
			},
		});

		try {
			await setUserProperties(accountId, TIMELINE_DATES_CONFIG_SECTION_MESSAGE_DISMISSED, 'true');
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.timeline.spotlight',
				`Failed to set user property with key: ${TIMELINE_DATES_CONFIG_SECTION_MESSAGE_DISMISSED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};
