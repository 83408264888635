import { UserProfileClient, type ProfileCardClientData } from '@atlaskit/profilecard';
import { fetchJson } from '@atlassian/jira-fetch';
import { isCustomerAccount, restResponseToUserData } from '../../../utils';
import type { OnLoadError } from '../types';

export class CustomProfileClient extends UserProfileClient {
	onLoadError: OnLoadError | undefined;

	baseUrl: string;

	// this is a hack to workaround issue with Babel when using "modern" bundles - it doesn't
	// like the `await super.makeRequest()` that would get used later, and throws an
	// "Unable to handle nested super.prop usage" error
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	parentMakeRequest: (cloudId: string, userId: string) => Promise<any>;

	constructor(fabricPfDirUrl: string, baseUrl: string, onLoadError?: OnLoadError) {
		super({ url: fabricPfDirUrl, cacheSize: 20, cacheMaxAge: 1000 * 60 * 3 });
		this.baseUrl = baseUrl;
		this.onLoadError = onLoadError;
		this.parentMakeRequest = super.makeRequest;
	}

	makeRequest: (cloudId: string, userId: string) => Promise<ProfileCardClientData> = async (
		cloudId: string,
		userId: string,
	) => {
		// call old profilerestendpoint
		try {
			if (isCustomerAccount(userId)) {
				const response = await fetchJson(`${this.baseUrl}/rest/api/3/user?accountId=${userId}`);

				return restResponseToUserData(response);
			}

			return await this.parentMakeRequest(cloudId, userId);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			const error = new Error(`Unable to fetch user: ${err.message}`, { cause: err });
			this.onLoadError?.(err);
			throw error;
		}
	};
}
