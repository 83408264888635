import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.issue-linking.title',
		defaultMessage: 'The issue linking is disabled.',
		description: 'Title of the message about disabled Issue Linking feature',
	},
	description: {
		id: 'polaris-common.issue-linking.description',
		defaultMessage:
			'You can enable it under {linkStart}settings{linkEnd}. If you don’t have permission, contact your admin.',
		description: 'Description of the message about disabled Issue Linking feature',
	},
});
