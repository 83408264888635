import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { findViewBy, updateProjectState } from '../utils';

export const deleteViewFromState =
	(viewUUID: string): Action<State, Props> =>
	async ({ setState, getState }, { navigationRemote, cloudId, projectId, onSuccess }) => {
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		if (!viewUUID) {
			return;
		}

		const { view } = findViewBy(
			getState().projects[projectId],
			(innerView) => innerView.id === viewUUID,
		);

		if (!view) {
			return;
		}

		setState(
			updateProjectState(getState(), projectId, {
				views: getState()
					.projects[projectId].views.filter((innerView) => innerView.id !== viewUUID)
					.map((childView) => {
						if (childView.views) {
							return {
								...childView,
								views: childView.views?.filter((innerView) => innerView.id !== viewUUID),
							};
						}
						return childView;
					}),
			}),
		);

		onSuccess?.('realtime:deleteView');
	};
