import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

export const MOVE_LAST_MUTATION = gqlTagPolaris`
mutation jira_polaris_moveLast(
    $listId: ID!,
    $items: [ID!],
 ) {
  polaris {
    ranking {
      makeLast(
        listId: $listId
        items: $items
      ) {
        changed {
          id
          rank
        }
        added {
          id
          rank
        }
        deleted {
          id
          rank
        }
      }
    }
  }
}`;

type MoveLastArgs = {
	listId: string;
	itemId: string;
};

export const createMoveLast =
	(client: PolarisApolloClient): (({ listId, itemId }: MoveLastArgs) => Promise<void>) =>
	({ listId, itemId }) =>
		client
			.mutate({
				mutation: MOVE_LAST_MUTATION,
				variables: { listId, items: [itemId] },
				errorPolicy: 'all',
			})
			.then((result) => {
				if (result.errors !== undefined) {
					throw new Error(
						`ranking-service.move-last-error:${result.errors.map((e) => e.message).join(', ')}`,
					);
				}
			});
