import isArray from 'lodash/isArray';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { createGetTransitionForStatus } from '../../../workflow/selectors/transitions';
import type { IssueTypeTransitionConfig, Status } from '../../../workflow/types';
import { createGetFieldMapping } from '../../selectors/fields';
import { createGetIssueType, createGetStatus } from '../../selectors/properties';
import type { PropertyMaps, Props, State } from '../../types';
import type { FieldMapping } from '../../utils/field-mapping/types';

export const getNewPropertiesMapping = <TFieldValue,>(
	fieldMapping: FieldMapping<unknown>,
	oldPropertyMap: PropertyMaps,
	localIssueIds: LocalIssueId | LocalIssueId[],
	newValue: TFieldValue | undefined,
	removeValue: TFieldValue | undefined,
	appendMultiValues: boolean,
) => {
	if (isArray(localIssueIds)) {
		let newMapping = oldPropertyMap;
		localIssueIds.forEach((localIssueId) => {
			newMapping = getNewPropertiesMapping(
				fieldMapping,
				newMapping,
				localIssueId,
				newValue,
				removeValue,
				appendMultiValues,
			);
		});
		return newMapping;
	}
	return fieldMapping.isMultiValueField && appendMultiValues
		? fieldMapping.modifyImmutableIfMultiValueField(
				oldPropertyMap,
				localIssueIds,
				newValue,
				removeValue,
			)
		: fieldMapping.setImmutable(oldPropertyMap, localIssueIds, newValue);
};

export const getTransitionsForTargetStatusByLocalIssueIds = (
	localIssueIds: LocalIssueId[],
	toStatus: Status,
	state: State,
	props: Props,
): Record<LocalIssueId, IssueTypeTransitionConfig | undefined> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	localIssueIds.reduce<Record<string, any>>((acc, issueId) => {
		const issueType = createGetIssueType(issueId)(state, props);
		const status = createGetStatus(issueId)(state, props);

		if (issueType === undefined || status === undefined) {
			acc[issueId] = undefined;
			return acc;
		}

		const getTransitionForStatus = createGetTransitionForStatus(issueType.id, status, toStatus);

		const transition = getTransitionForStatus({
			transitions: props.workflowTransitions,
		});

		acc[issueId] = transition;
		return acc;
	}, {});

export const isBulkOperationSupported = (fieldKeys: FieldKey[], state: State, props: Props) =>
	fieldKeys.every((fieldKey) => {
		const fieldMapping = createGetFieldMapping(fieldKey)(state, props);
		return fieldMapping?.getFieldValueForJiraBulkUpdate;
	});
