import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { messages } from '../messages';

type Props = {
	searchValue: string;
	onSearchInputChange: (value: string) => void;
};

export const Filters = ({ searchValue, onSearchInputChange }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<FiltersContainer>
			<SearchInput
				testId="polaris-ideas.ui.right-sidebar.fields.filters.search-input"
				value={searchValue}
				placeholder={formatMessage(messages.searchHint)}
				onChange={onSearchInputChange}
			/>
		</FiltersContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FiltersContainer = styled.section({
	display: 'flex',
	flex: 0,
	margin: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
});
