/** @jsx jsx */
import React, { type ReactNode, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { type EmojiDescription, Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { EmojiWrapper } from '@atlassian/jira-polaris-common/src/ui/field-config/item/emoji-wrapper';
import type { IntervalFieldFilterOperator } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { filters } from '../utils';

export const SelectionLabel = ({ values }: { values: IntervalFieldFilterOperator[] }) => {
	const { formatMessage } = useIntl();
	const activeFilter = useMemo(() => filters.find((filter) => filter.isChecked(values)), [values]);

	if (activeFilter?.activeLabel === undefined) {
		return null;
	}

	return (
		<>
			{formatMessage(activeFilter.activeLabel, {
				value: values[0].numericValue !== undefined ? Math.abs(values[0].numericValue) : undefined,
			})}
		</>
	);
};

export const FilterButtonLabel = ({
	label,
	values,
	fieldTypeIcon,
	emoji,
}: {
	label: string;
	values: IntervalFieldFilterOperator[];
	fieldTypeIcon: ReactNode;
	emoji?: EmojiDescription;
}) => (
	<Flex alignItems="center">
		{emoji ? (
			<EmojiWrapper>
				<Emoji emoji={emoji} fitToHeight={16} showTooltip />
			</EmojiWrapper>
		) : (
			<Flex xcss={iconContainerStyles}>{fieldTypeIcon}</Flex>
		)}
		<div css={labelStyles}>{label}</div>
		{values.length > 0 && (
			<Box xcss={filterLabelValueStyles}>
				<SelectionLabel values={values} />
			</Box>
		)}
	</Flex>
);

const iconContainerStyles = xcss({
	paddingInlineEnd: 'space.100',
	alignItems: 'center',
});

const filterLabelValueStyles = xcss({
	marginInlineStart: 'space.050',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '260px',
});

const labelStyles = css({
	fontWeight: token('font.weight.bold'),
});
