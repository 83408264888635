import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SnippetProviderProperty } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { RecursiveFilter } from '@atlassian/jira-polaris-lib-formula/src/utils/filter/types.tsx';

type SnippetPropertyItem = SnippetProviderProperty & {
	appName: string;
	oauthClientId: string;
	avatarUrl: string;
	filter?: RecursiveFilter;
};

export const PROPERTY_ROLLUP = 'property';
export const FIELD_ROLLUP = 'field';
export const COUNT_ROLLUP = 'count';

export type PropertyRollupField = {
	kind: typeof PROPERTY_ROLLUP;
	value: SnippetPropertyItem;
	negative?: boolean;
};

export type FieldRollupField = {
	kind: typeof FIELD_ROLLUP;
	value: Field;
	negative?: boolean;
};

export type CountRollupField = {
	kind: typeof COUNT_ROLLUP;
	value: {
		id: string;
		label: string;
		appName: string;
		oauthClientId: string;
		avatarUrl?: string;
		filter?: RecursiveFilter;
	};
	negative?: boolean;
};

export type RollupField = PropertyRollupField | FieldRollupField | CountRollupField;
