import React, { useState, useEffect, useMemo, useCallback } from 'react';
import merge from 'lodash/merge';
import Badge from '@atlaskit/badge';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { Section, HeadingItem, type CSSFn } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fieldsReferencableFrom } from '@atlassian/jira-polaris-domain-field/src/formula/index.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { SearchInput } from '@atlassian/jira-polaris-lib-search-input/src/ui/index.tsx';
import { POLARIS_OAUTH_CLIENT_ID } from '../../../../../common/types/snippet/constants';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import { useVisibleFieldsArray } from '../../../../../controllers/field/selectors/field-hooks.tsx';
import { useSnippetProviders } from '../../../../../controllers/field/selectors/snippet-providers-hooks.tsx';
import { parseConjunctiveLabelFilter } from '../../../../../controllers/project/insight/filter';
import { DataOptions } from './data-options';
import { FieldOptions } from './field-options';
import { RollupItemComponent, type PercentageProps } from './item';
import { messages } from './messages';
import { MultiselectOptions } from './multi-select-options';
import {
	RollupContainer,
	AddButton,
	AddInputContainer,
	RollupContainerHeader,
	RollupFieldsContainer,
	RollupOptionsContainer,
	MenuInlineDialogContainer,
	AddInputInnerContainer,
	AddInputSearchContainer,
	HelpText,
} from './styled';
import { type RollupField, PROPERTY_ROLLUP, COUNT_ROLLUP, FIELD_ROLLUP } from './types';
import { getAppNameForRollupField } from './utils/app-name/index.tsx';
import { createFormula } from './utils/formula-create/index.tsx';
import { useFormulaState } from './utils/formula-state/index.tsx';
import {
	getSplitPercentageValue,
	isInvalidPercentageDistribution,
} from './utils/percentage/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cssFn: CSSFn<any> = (currentStyles) => {
	const newStyle = {
		padding: `0px ${token('space.200', '16px')}`,
		marginTop: 0,
	};
	return merge(currentStyles, newStyle);
};

type RollupProps = {
	thisFieldKey?: FieldKey;
	initFormula?: DynamicFieldFormula;
	initAsWeightedScore?: boolean;
	readonly: boolean;
	onChange: (formula: DynamicFieldFormula, numInputs: number) => void;
};

type RollupInput = {
	rollupField: RollupField;
	percentage?: PercentageProps;
	index: number;
};

type RollupInputListProps = {
	hasSeparator: boolean;
	title: string;
	rollupInputs: RollupInput[];
	readonly: boolean;
	negative?: boolean;
	nonCircularFields: Field[];
	onAddField: (arg1: RollupField) => void;
	onRemoveField: (arg1: number) => void;
	setLabelFilter: (arg1: number, arg2: string[] | undefined) => void;
	isWeightedScore: boolean;
};

const useWeightCheck = (
	rollupInputs: RollupInput[],
	isWeightedScore: boolean,
): [boolean, number] => {
	const percentages = useMemo(
		() => (isWeightedScore ? rollupInputs.map(({ percentage }) => percentage?.value) : []),
		[rollupInputs, isWeightedScore],
	);

	const isUnbalanced = useMemo(
		() => !isWeightedScore || isInvalidPercentageDistribution(percentages),
		[percentages, isWeightedScore],
	);

	const defaultValue = useMemo(
		() => (isWeightedScore ? getSplitPercentageValue(percentages) : 0),
		[isWeightedScore, percentages],
	);

	return [isUnbalanced, defaultValue];
};

const RollupInputList = ({
	title,
	rollupInputs,
	readonly,
	onRemoveField,
	onAddField,
	negative,
	setLabelFilter,
	nonCircularFields,
	hasSeparator,
	isWeightedScore,
}: RollupInputListProps) => {
	const { formatMessage } = useIntl();

	const [isAddInputDialogOpen, setIsAddInputDialogOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [snippetProviders] = useSnippetProviders();

	const addFieldWithNegative = (selectedField: RollupField) =>
		onAddField({ ...selectedField, negative });

	const closeDialog = () => {
		setSearch('');
		setIsAddInputDialogOpen(false);
	};

	const isFiltered = (x: string) => x.toLowerCase().indexOf(search.toLowerCase()) > -1;

	const [isUnbalanced, defaultValue] = useWeightCheck(rollupInputs, isWeightedScore);

	return (
		<Section
			hasSeparator={hasSeparator}
			testId={`polaris-common.ui.config.fields.common.rollup-${negative ? 'negative' : 'positive'}`}
		>
			<RollupContainerHeader>
				<Badge>{rollupInputs.length}</Badge> {title}
			</RollupContainerHeader>
			{rollupInputs.length > 0 && (
				<RollupFieldsContainer>
					{rollupInputs.map(({ rollupField, percentage, index }) => (
						<RollupItemComponent
							readonly={readonly}
							key={`${rollupField.kind === 'field' ? rollupField.value.key : rollupField.value.id}-${index}`}
							fieldKey={rollupField.kind === 'field' ? rollupField.value.key : undefined}
							label={rollupField.value.label}
							appName={getAppNameForRollupField(rollupField)}
							appAvatarUrl={
								rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP
									? rollupField.value.avatarUrl
									: undefined
							}
							isPolarisApp={
								rollupField.kind !== FIELD_ROLLUP &&
								rollupField.value?.oauthClientId === POLARIS_OAUTH_CLIENT_ID
							}
							onDelete={() => onRemoveField(index)}
							filteredLabels={
								// @ts-expect-error - TS2339 - Property 'filter' does not exist on type 'Field | SnippetPropertyItem | { id: string; label: string; appName: string; oauthClientId: string; avatarUrl?: string | undefined; filter?: InsightFilter | undefined; }'.
								rollupField.value.filter
									? // @ts-expect-error - TS2339 - Property 'filter' does not exist on type 'Field | SnippetPropertyItem | { id: string; label: string; appName: string; oauthClientId: string; avatarUrl?: string | undefined; filter?: InsightFilter | undefined; }'.
										parseConjunctiveLabelFilter(rollupField.value.filter)
									: undefined
							}
							percentage={percentage}
							defaultValue={defaultValue}
							isUnbalanced={isUnbalanced}
							onFilterChange={
								rollupField.kind === PROPERTY_ROLLUP || rollupField.kind === COUNT_ROLLUP
									? (labels: undefined | Array<string>) => setLabelFilter(index, labels)
									: undefined
							}
						/>
					))}
				</RollupFieldsContainer>
			)}
			{!readonly && (
				<MenuInlineDialogContainer>
					<AddInputContainer>
						<PolarisInlineDialog
							noPadding
							placement="bottom-start"
							isOpen={isAddInputDialogOpen}
							onClose={closeDialog}
							content={
								<AddInputInnerContainer>
									<AddInputSearchContainer>
										<SearchInput
											autoFocus
											value={search}
											onChange={setSearch}
											placeholder="Search fields"
										/>
									</AddInputSearchContainer>
									<RollupOptionsContainer>
										<Section>
											{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
											<HeadingItem cssFn={cssFn}>
												{formatMessage(messages.fieldSectionHeader)}
											</HeadingItem>
											<FieldOptions
												fields={nonCircularFields}
												isFiltered={isFiltered}
												onClick={(selectedField) => {
													addFieldWithNegative(selectedField);
													closeDialog();
												}}
											/>
										</Section>
										<Section>
											{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
											<HeadingItem cssFn={cssFn}>
												{formatMessage(messages.selectSectionHeader)}
											</HeadingItem>
											<MultiselectOptions
												fields={nonCircularFields}
												isFiltered={isFiltered}
												onClick={(selectedField) => {
													addFieldWithNegative(selectedField);
													closeDialog();
												}}
											/>
										</Section>
										<Section testId="polaris-common.ui.config.fields.common.rollup-formula-item.insights-section">
											{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
											<HeadingItem cssFn={cssFn}>
												{formatMessage(messages.dataSectionHeader)}
											</HeadingItem>
											<DataOptions
												snippetProviders={snippetProviders}
												isFiltered={isFiltered}
												onClick={(selectedField) => {
													addFieldWithNegative(selectedField);
													closeDialog();
												}}
											/>
										</Section>
									</RollupOptionsContainer>
								</AddInputInnerContainer>
							}
						>
							<AddButton
								id="polaris.common.src.ui.config.fields.common.rollup-formula.item.add-input-button"
								iconBefore={
									<EditorAddIcon
										label={formatMessage(messages.addInputButtonLabel)}
										size="medium"
									/>
								}
								onClick={() => setIsAddInputDialogOpen(true)}
								isSelected={isAddInputDialogOpen}
							>
								{formatMessage(messages.addInputButtonLabel)}
							</AddButton>
						</PolarisInlineDialog>
					</AddInputContainer>
				</MenuInlineDialogContainer>
			)}
		</Section>
	);
};

export const RollupLegacy = ({
	initFormula,
	thisFieldKey,
	initAsWeightedScore,
	readonly,
	onChange,
}: RollupProps) => {
	const { formatMessage } = useIntl();

	const { throttledFetchSnippetProviders } = useFieldActions();
	const [fields] = useVisibleFieldsArray();
	const [
		{ rollupFields, percentages, weightedScore, isUpdated },
		{ addField, removeField, setPercentageValue, setWeightedScore, setLabelFilter },
	] = useFormulaState(initFormula, initAsWeightedScore);

	// figure out which fields we are allowed to mention; if we are a new Rollup, then it's
	// basically anything.  If we are editing an existing Rollup, then it's anything that doesn't
	// reference this field directly or indirectly.
	const nonCircularFields = useMemo(
		() => fieldsReferencableFrom(thisFieldKey, fields),
		[fields, thisFieldKey],
	);

	useEffect(() => {
		if (readonly) {
			return;
		}
		// re-fetch the snippet providers when we mount this component, which covers
		// both the EDIT and the CREATE case
		throttledFetchSnippetProviders(true);
	}, [throttledFetchSnippetProviders, readonly]);

	useEffect(() => {
		const formula = createFormula(rollupFields, weightedScore ? percentages : undefined);
		if (isUpdated) {
			onChange(formula, rollupFields.length);
		}
	}, [onChange, percentages, rollupFields, isUpdated, weightedScore]);

	useEffect(() => {
		if (initAsWeightedScore !== undefined && initAsWeightedScore !== weightedScore) {
			setWeightedScore(initAsWeightedScore);
		}
	}, [initAsWeightedScore, percentages, setWeightedScore, weightedScore]);

	const getPercentageProps = useCallback(
		(index: number): PercentageProps | undefined => {
			if (!weightedScore) {
				return undefined;
			}
			return {
				value: percentages[index],
				onChangePercentage: (percentage) => {
					setPercentageValue(index, percentage);
				},
			};
		},
		[percentages, setPercentageValue, weightedScore],
	);
	const rollupInputs = useMemo(
		() =>
			rollupFields.map((rollupField, index) => ({
				rollupField,
				index,
				percentage: getPercentageProps(index),
			})),
		[rollupFields, getPercentageProps],
	);

	const [positiveRollupInputs, negativeRollupInputs] = useMemo(() => {
		const posInputs: RollupInput[] = [];
		const negInputs: RollupInput[] = [];
		rollupInputs.forEach((rollupInput: RollupInput) => {
			if (rollupInput.rollupField.negative) {
				negInputs.push(rollupInput);
			} else {
				posInputs.push(rollupInput);
			}
		});
		return [posInputs, negInputs];
	}, [rollupInputs]);

	const sharedRollupInputListProps = {
		isWeightedScore: weightedScore,
		readonly,
		onRemoveField: removeField,
		onAddField: addField,
		setPercentageValue,
		setLabelFilter,
		nonCircularFields,
	};

	return (
		<RollupContainer readonly={readonly}>
			{!readonly && weightedScore && (
				<HelpText>{formatMessage(messages.weightedScoreFormulaHelp)}</HelpText>
			)}
			<RollupInputList
				{...sharedRollupInputListProps}
				data-testid="polaris-common.ui.config.fields.common.rollup-formula-item.positive-input"
				rollupInputs={weightedScore ? positiveRollupInputs : rollupInputs}
				hasSeparator={!readonly && weightedScore}
				title={
					weightedScore
						? formatMessage(messages.positiveInputHeader, {
								count: positiveRollupInputs.length,
							})
						: formatMessage(messages.inputHeader, {
								count: rollupInputs.length,
							})
				}
			/>
			{weightedScore && (
				<>
					<RollupInputList
						{...sharedRollupInputListProps}
						data-testid="polaris-common.ui.config.fields.common.rollup-formula-item.negative-input"
						rollupInputs={negativeRollupInputs}
						negative
						hasSeparator={!readonly}
						title={formatMessage(messages.negativeInputHeader, {
							count: negativeRollupInputs.length,
						})}
					/>
					{!readonly && <Section hasSeparator> </Section>}
				</>
			)}
		</RollupContainer>
	);
};
