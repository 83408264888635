import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	opEquals: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.content.op-equals',
		defaultMessage: 'is',
		description: 'Numeric equals operator',
	},
	opNotEquals: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.content.op-not-equals',
		defaultMessage: 'is not',
		description: 'Numeric not equals operator',
	},
	opGreaterThan: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.content.op-greater-than',
		defaultMessage: 'greater than',
		description: 'Numeric > operator',
	},
	opLessThan: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.content.op-less-than',
		defaultMessage: 'less than',
		description: 'Numeric < operator',
	},
	opBetween: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.content.op-between',
		defaultMessage: 'between',
		description: 'Numeric range between two values operator',
	},
	opEmpty: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.content.op-empty',
		defaultMessage: 'is empty',
		description: 'Numeric empty value operator',
	},
});
