import React from 'react';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { ProjectFieldsScreen } from './project-fields-screen/ui/index.tsx';

export const PolarisProjectFields = () => (
	<ContextualAnalyticsData sourceType={SCREEN} sourceName="fieldsPage">
		<FireScreenAnalytics />
		<ProjectFieldsScreen />
	</ContextualAnalyticsData>
);
