import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { addFieldOption } from '../../services/jira/create-field-option';
import { deleteFieldOption } from '../../services/jira/delete-field-option';
import { rankFieldOptions } from '../../services/jira/rank-field-options';
import { renameFieldOption } from '../../services/jira/update-field-option-name';
import type { FieldOptionRemote } from './types';

export const createNotImplementedFieldOptionRemote = (): FieldOptionRemote => ({
	addFieldOption: notImplementedError('addFieldOption'),
	deleteFieldOption: notImplementedError('deleteFieldOption'),
	renameFieldOption: notImplementedError('renameFieldOption'),
	updateFieldOptionRank: notImplementedError('updateFieldOptionRank'),
});

export const createJiraFieldOptionRemote = (projectId: ProjectId): FieldOptionRemote => ({
	addFieldOption: (request) =>
		addFieldOption({
			...request,
			projectId,
		}),
	deleteFieldOption: (request) =>
		deleteFieldOption({
			...request,
			projectId,
		}),
	renameFieldOption: (request) => renameFieldOption({ ...request, projectId }),
	updateFieldOptionRank: (request) => rankFieldOptions({ ...request, projectId }),
});
