import React, { useCallback } from 'react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type LinkProps = {
	fieldId: string;
	text: React.ReactNode;
	textSize: 'medium' | 'small';
};

/**
 * Link that can be rendered multiline and take in a custom click handler
 */
export const ContextLink = ({ fieldId, text, textSize }: LinkProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const openJiraFieldContext = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'fieldContext',
		);
		window.open(
			`/secure/admin/ConfigureCustomField!default.jspa?customFieldId=${fieldId}`,
			'_blank',
		);
	}, [createAnalyticsEvent, fieldId]);

	return (
		<Box
			as="span"
			xcss={shortcutStyles}
			tabIndex={0}
			role="link"
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					openJiraFieldContext();
				}
			}}
			onClick={() => openJiraFieldContext()}
		>
			<Text size={textSize}>{text}</Text>
			<Box
				as="span"
				xcss={[
					shortcutIconContainerStyles,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					textSize === 'small' && xcss({ verticalAlign: 'bottom' }),
				]}
			>
				<ShortcutIcon size="small" label="" />
			</Box>
		</Box>
	);
};

const shortcutStyles = xcss({
	color: 'color.link',
	cursor: 'pointer',
	':hover': {
		textDecoration: 'underline',
	},
});

const shortcutIconContainerStyles = xcss({
	marginLeft: 'space.025',
	display: 'inline-flex',
	verticalAlign: 'text-bottom',
});
