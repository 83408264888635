import { updateInsight as updateInsightCall } from '../../services/polaris-api/update-insight';
import type { ProviderProps, UpdateInsightRequest, RemoteInsightUpdater } from '../../types';

export const updateInsight =
	({ apolloClient }: Omit<ProviderProps, 'projectId'>): RemoteInsightUpdater =>
	({ id, description, snippets }: UpdateInsightRequest) =>
		updateInsightCall(apolloClient, id, description, snippets).then((insight) => ({
			...insight,
			snippets: [
				...insight.snippets.map((snippet) => ({
					...snippet,
					properties: {
						...snippet.properties,
					},
				})),
			],
		}));
