import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleInsights: {
		id: 'polaris-common.apps.apps.jsm.title-insights',
		defaultMessage: 'Jira Service Management',
		description: 'Title of the JSM app',
	},
	descriptionInsights: {
		id: 'polaris-common.apps.apps.jsm.description-insights',
		defaultMessage: 'Add support tickets to provide more context about your users problems',
		description: 'Description of the JSM app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.jsm.unfurl-dialog-title',
		defaultMessage: 'Connect to JSM',
		description: 'Title of the Jsm try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.jsm.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to JSM here',
		description: 'Placeholder of the JSM try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.jsm.unfurl-dialog-success',
		defaultMessage: 'This is what it looks like when you add a JSM link to an idea.',
		description: 'Success message of JSM the try-it-now unfurl dialog',
	},
});
