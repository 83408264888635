import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	type useAnalyticsEvents,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { extractViewAnalyticsData } from '../../selectors/view';

type CreateAnalyticsEvent = ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'];
type UpdatedItems = Array<{ name: string; oldVal?: unknown; newVal?: unknown }>;
type ViewUpdatedAttributes = {
	updatedItems?: UpdatedItems;
} & Record<string, unknown>;

const getUpdatedItems = (items?: UpdatedItems) =>
	items
		?.map(({ name, oldVal, newVal }) => `name: ${name}, oldVal: ${oldVal}, newVal: ${newVal}`)
		.join(', ') ?? '';

export const fireViewUpdatedEvent = (
	createAnalyticsEvent: CreateAnalyticsEvent,
	view: View,
	attributes: ViewUpdatedAttributes,
) => {
	const analyticsData = extractViewAnalyticsData(view);

	fireTrackAnalytics(
		createAnalyticsEvent({
			containers: analyticsData?.containers,
		}),
		'view updated',
		{
			...analyticsData?.attributes,
			...attributes,
		},
	);

	sendPendoTrackEvent({
		actionSubjectAndAction: 'view updated',
		attributes: { updatedItems: getUpdatedItems(attributes.updatedItems) },
	});
};
