import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	preInstruction: {
		id: 'polaris-common.common.apps.modals.slack.pre-instruction',
		defaultMessage:
			'The same Jira for Slack app that your team uses for software projects will also help you capture messages on your ideas.',
		description: 'First instruction on how to install the slack app',
	},
	postInstruction: {
		id: 'polaris-common.common.apps.modals.slack.post-instruction',
		defaultMessage:
			'To capture a message, select the “Add to issue” option on a message, then select your Product Discovery project and idea.',
		description: 'Second instruction on how to use the slack app',
	},
	installCaption: {
		id: 'polaris-common.common.apps.modals.slack.install-caption',
		defaultMessage: 'Install the app',
		description: 'Install button caption',
	},
	close: {
		id: 'polaris-common.common.apps.modals.slack.close',
		defaultMessage: 'Close',
		description: 'Modal dialog close button caption',
	},
});
