import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addInsightButton: {
		id: 'polaris-ideas.insights.insights.add-insight.buttons.add-insight-button',
		defaultMessage: 'Create an insight',
		description: 'Button caption for create an insight button',
	},
	createButton: {
		id: 'polaris-ideas.insights.insights.add-insight.buttons.create-button',
		defaultMessage: 'Create',
		description: 'Create a note button text',
	},
	saveButton: {
		id: 'polaris-ideas.insights.insights.add-insight.buttons.save-button',
		defaultMessage: 'Save',
		description: 'Save a note button text',
	},
	cancelButton: {
		id: 'polaris-ideas.insights.insights.add-insight.buttons.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel creating note button text',
	},
});
