import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ChangelogEntry, ChangelogResponse } from './types';

const getUpdatesForField = async (
	issueKeyOrId: string,
	field: string,
	startAt = 0,
): Promise<ChangelogEntry[]> => {
	const response: ChangelogResponse = await fetchJson(
		`/rest/api/3/issue/${issueKeyOrId}/changelog?startAt=${startAt}`,
	);

	const updates = response.values.filter((update) =>
		update.items.some((item) => item.field === field),
	);

	if (response.startAt + response.maxResults < response.total) {
		const moreUpdates = await getUpdatesForField(
			issueKeyOrId,
			field,
			response.startAt + response.maxResults,
		);
		return updates.concat(moreUpdates);
	}
	return updates;
};

export const getLatestUpdateForField = async (
	issueKeyOrId: string,
	field: string,
	startAt = 0,
): Promise<ChangelogEntry> => {
	const updates = await getUpdatesForField(issueKeyOrId, field, startAt);
	// updates are sorted by date, ascending
	return updates[updates.length - 1];
};
