import {
	type VersionValue,
	type VersionsPublicSetValue,
	versionsRequiredScopes as versionsRequiredScopesBase,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/versions';
import { commonIssueViewGranularScopes } from './fields-scopes';

type VersionInternalIdShapeValue = { id: string }[];

export const transformVersionToInternalValue = (
	internalValue: VersionsPublicSetValue,
): VersionInternalIdShapeValue => {
	if (!internalValue.length) {
		return [];
	}

	return internalValue.map((id) => ({ id }));
};

export const transformVersionToPublicValue = (internalValue: VersionValue[]): VersionValue[] => {
	if (!internalValue) return [];

	return internalValue.map(({ id, name }) => ({
		id,
		name,
	}));
};

export const versionsRequiredScopes = {
	...versionsRequiredScopesBase,
	granular: [...versionsRequiredScopesBase.granular, ...commonIssueViewGranularScopes],
};
