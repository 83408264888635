import React, { useEffect } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useProjectOnboardedAtTimestamp } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import { useOpenRightSidebarOnViewInfo } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks';
import { getIsViewGeneratedByTemplate } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view';
import {
	useAutoOpenSidebarCondition,
	useCurrentViewTitle,
	useGetCurrentView,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { AsyncPolarisQuickStartViewVideo } from '@atlassian/jira-polaris-lib-quick-start-view-video';
import {
	MountEvent,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { titleToSupportedQuickstartViewKey } from './titleToSupportedQuickstartViewKey';

const useGetQuickstartViewKeyForCurrentView = () => {
	const viewTitle = useCurrentViewTitle();

	if (!viewTitle) return undefined;
	return titleToSupportedQuickstartViewKey[viewTitle.trim()];
};

export const useIsCurrentViewGeneratedByTemplate = () => {
	const currentView = useGetCurrentView();
	const projectOnboardedAt = useProjectOnboardedAtTimestamp();

	if (!currentView) return false;

	if (!getIsViewGeneratedByTemplate(currentView, projectOnboardedAt)) return false;

	return true;
};

const useCheckOnboardingExperimentEnabledWithAaExposure = (
	quickstartViewKey: string | undefined,
) => {
	const isQuickstartSupportedForCurrentView = useIsCurrentViewGeneratedByTemplate();
	if (!isQuickstartSupportedForCurrentView || quickstartViewKey === undefined) {
		return false;
	}

	return expVal('jpd_onboarding_video', 'onboardingVideoEnabled', false);
};

// GALILEO-721
export const QuickstartViewVideoContainer = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const quickstartViewKey = useGetQuickstartViewKeyForCurrentView();
	const shouldShowExperimentalVideo =
		useCheckOnboardingExperimentEnabledWithAaExposure(quickstartViewKey);

	const openSidebarOnViewInfo = useOpenRightSidebarOnViewInfo();
	const shouldAutoOpenSidebar = useAutoOpenSidebarCondition();

	const { atlassianAccountId } = useTenantContext();

	useEffect(() => {
		if (!shouldShowExperimentalVideo && shouldAutoOpenSidebar) {
			openSidebarOnViewInfo();
		}
	}, [shouldShowExperimentalVideo, openSidebarOnViewInfo, shouldAutoOpenSidebar]);

	if (shouldShowExperimentalVideo && quickstartViewKey) {
		return (
			<>
				<MountEvent
					onMount={() => {
						fireTrackAnalytics(createAnalyticsEvent({}), 'QuickstartViewVideoContainer viewed');
					}}
				/>
				<AsyncPolarisQuickStartViewVideo
					data-testid="polaris-ideas.ui.main-content.quick-start-view-video.async-polaris-quickstart"
					viewKey={quickstartViewKey}
					accountId={atlassianAccountId}
				/>
			</>
		);
	}
	return null;
};
