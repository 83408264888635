import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageHeader: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.page-header',
		defaultMessage: 'Fields',
		description: 'Header of the fields settings page',
	},
	createField: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.create-field',
		defaultMessage: 'Create field',
		description: 'Label for the create field button',
	},
	addGlobalField: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.add-global-field',
		defaultMessage: 'Add global field',
		description: 'Label for the add global field button',
	},
	globalFields: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.global-fields',
		defaultMessage: 'Global fields',
		description: 'Label for the global field link button',
	},
	pageDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.page-description',
		defaultMessage:
			'Manage your project fields and reuse global fields from other projects on your site.',
		description: 'Description for the global fields settings page',
	},
	learnMore: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.learn-more',
		defaultMessage: 'Learn how to manage fields',
		description: 'Label for the learn more link',
	},
	showFieldsTour: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.show-fields-tour',
		defaultMessage: 'Show fields tour',
		description: 'Label for the show fields tour button',
	},
});
