import { ff } from '@atlassian/jira-feature-flagging';
import { DESCRIPTION_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { SharingSettingsServiceType } from './types';

export const getSharingSettingsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/sharing/settings`;

export const getSharingSettingsPublicUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/public/views/${viewUUID}/sharing/settings`;

export const putSharingSettingsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/sharing/settings`;

export const putPublicSharingSettingsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/public-sharing`;

export const getSharingUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v1'
	}/views/${viewUUID}/sharing`;

export const mapSharingSettings = (settings: SharingSettingsServiceType): SharingSettings => ({
	...settings,
	ideaDescription: {
		fieldKey: settings.ideaDescription.fieldKey || DESCRIPTION_FIELDKEY,
	},
});
