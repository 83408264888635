import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.conversion-warning-modal.title',
		defaultMessage: 'Change number values to ratings',
		description: 'The title of the warning modal',
	},
	description: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.conversion-warning-modal.description',
		defaultMessage:
			'Changing the weighting type to rating only works when all values are between 1 and 5. Change the values and try again.',
		description: 'The description of the warning modal',
	},
	closeButton: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.option-decoration.options.conversion-warning-modal.close-button',
		defaultMessage: 'Close',
		description: 'The close button of the warning modal',
	},
});
