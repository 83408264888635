import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { UpdateCollectionViewArrangementInfoRequest } from './types';

export const updateCollectionViewArrangementInfo = ({
	collectionUUID,
	viewUUID,
	arrangementInfo,
}: UpdateCollectionViewArrangementInfoRequest) =>
	performPutRequest(
		`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/arrangement-info`,
		{
			body: JSON.stringify({ arrangementInfo }),
		},
	);
