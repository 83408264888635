import React, { useState, useMemo, useCallback, type ReactElement } from 'react';
import { DeleteOptionModal } from './delete-modal';

type CallbackPayload = { groupIdentity?: string; optionLabel?: string };
type RequestDeleteCallback = (callback: CallbackPayload) => void;

type ChildRenderProps = {
	onOptionDeleteRequested: RequestDeleteCallback;
};

type DeleteOptionHandlerProps = {
	fieldKey?: string;
	children: (arg1: ChildRenderProps) => ReactElement;
	onDeleteConfirmed: (groupIdentity: string) => Promise<void>;
};

type RequestedDeleteOptionHandlerProps = {
	children: (arg1: ChildRenderProps) => ReactElement;
	childRenderProps: ChildRenderProps;
	fieldKey: string;
	groupIdentity: string;
	optionLabel: string | undefined;
	onClearHandlerConfiguration: () => void;
	onDeleteConfirmed: (groupIdentity: string) => Promise<void>;
};

export const RequestedDeleteOptionHandler = ({
	groupIdentity,
	optionLabel,
	children,
	childRenderProps,
	onClearHandlerConfiguration,
	onDeleteConfirmed,
}: RequestedDeleteOptionHandlerProps) => {
	const onCancelDelete = useCallback(() => {
		onClearHandlerConfiguration();
	}, [onClearHandlerConfiguration]);

	const onConfirmDelete = useCallback(() => {
		if (groupIdentity !== undefined) {
			onDeleteConfirmed(groupIdentity).then(() => onClearHandlerConfiguration());
		}
		return Promise.resolve(undefined).then(() => {
			onClearHandlerConfiguration();
		});
	}, [groupIdentity, onDeleteConfirmed, onClearHandlerConfiguration]);

	return (
		<>
			<DeleteOptionModal
				isOpen={groupIdentity !== undefined}
				optionLabel={optionLabel}
				onCancel={onCancelDelete}
				onConfirm={onConfirmDelete}
			/>

			{children(childRenderProps)}
		</>
	);
};

export const DeleteOptionHandler = ({
	children,
	fieldKey: fieldKeyToDelete,
	onDeleteConfirmed,
}: DeleteOptionHandlerProps) => {
	const [{ fieldKey, groupIdentity, optionLabel }, setDeleteOptions] = useState<{
		fieldKey?: string;
		groupIdentity?: string;
		optionLabel?: string;
	}>({
		fieldKey: undefined,
		groupIdentity: undefined,
		optionLabel: undefined,
	});

	const onOptionDeleteRequested = useCallback(
		(callback: CallbackPayload) => {
			setDeleteOptions({
				fieldKey: fieldKeyToDelete,
				groupIdentity: callback.groupIdentity,
				optionLabel: callback.optionLabel,
			});
		},
		[fieldKeyToDelete],
	);

	const childRenderProps = useMemo(
		() => ({
			onOptionDeleteRequested,
		}),
		[onOptionDeleteRequested],
	);

	const onClearHandlerConfiguration = useCallback(() => {
		setDeleteOptions({
			fieldKey: undefined,
			groupIdentity: undefined,
			optionLabel: undefined,
		});
	}, []);

	if (fieldKey === undefined || groupIdentity === undefined) {
		return <>{children(childRenderProps)}</>;
	}

	return (
		<RequestedDeleteOptionHandler
			fieldKey={fieldKey}
			groupIdentity={groupIdentity}
			optionLabel={optionLabel}
			childRenderProps={childRenderProps}
			onClearHandlerConfiguration={onClearHandlerConfiguration}
			onDeleteConfirmed={onDeleteConfirmed}
		>
			{children}
		</RequestedDeleteOptionHandler>
	);
};
