import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	autosaveEnabledTooltip: {
		id: 'polaris-ideas.view-controls.config-extra.autosave-enabled-tooltip',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Autosave is enabled',
		description: '',
	},
	autosaveDisabledTooltip: {
		id: 'polaris-ideas.view-controls.config-extra.autosave-disabled-tooltip',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Autosave is disabled',
		description: '',
	},
	unsavedChangesTitle: {
		id: 'polaris-ideas.view-controls.config-extra.unsaved-changes-title',
		defaultMessage: 'Unsaved view changes',
		description:
			'Header displayed in a modal when user attempts to navigate away view with changes (filters, sorting etc.)',
	},
	unsavedChangesPrompt: {
		id: 'polaris-ideas.view-controls.config-extra.unsaved-changes-prompt',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			"Autosave is currently disabled. To continue, save or discard the changes you've made to the view.",
		description:
			'Prompt displayed in a modal when the user navigates away with unsaved view changes (filters, sorting etc.)',
	},
	resetButton: {
		id: 'polaris-ideas.view-controls.config-extra.reset-button',
		defaultMessage: 'Reset',
		description: '',
	},
});
