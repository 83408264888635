import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

type PartialJiraField = {
	key: string;
	name: string;
};

export const searchSystemFields = async () => {
	const fields = await fetchJson<{ values: PartialJiraField[] }>(
		'/rest/api/3/field/search?type=system&expand=key',
		{
			method: 'GET',
		},
	);

	return fields.values ?? [];
};
