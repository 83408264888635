let incId = 0;

const optimisticInsightIdPrefix = 'fake-insight-id';

export const optimisticInsightId = () => {
	incId += 1;

	return `${optimisticInsightIdPrefix}-${incId}`;
};

export const isOptimisticInsight = (id: string) => id.indexOf(optimisticInsightIdPrefix) === 0;
