import { POLARIS_SUB_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/constants.tsx';
import {
	FIELD_TYPES,
	JIRA_API_FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { JiraField } from '../../services/types';

export const getFieldType = ({
	type,
	configuration,
}: Pick<JiraField, 'type' | 'configuration'>): FieldType => {
	// Frontend only: the jira type is not specific enough
	switch (configuration?.polarisSubType) {
		case POLARIS_SUB_TYPES.ARCHIVED:
			return FIELD_TYPES.ARCHIVED;
		case POLARIS_SUB_TYPES.ARCHIVED_BY:
			return FIELD_TYPES.ARCHIVED_BY;
		case POLARIS_SUB_TYPES.ARCHIVED_ON:
			return FIELD_TYPES.ARCHIVED_ON;
		case POLARIS_SUB_TYPES.ATLAS_GOAL:
			return FIELD_TYPES.ATLAS_GOAL;
		case POLARIS_SUB_TYPES.ATLAS_PROJECT:
			return FIELD_TYPES.ATLAS_PROJECT;
		case POLARIS_SUB_TYPES.ATLAS_PROJECT_STATUS:
			return FIELD_TYPES.ATLAS_PROJECT_STATUS;
		case POLARIS_SUB_TYPES.DELIVERY_STATUS:
			return FIELD_TYPES.DELIVERY_STATUS;
		case POLARIS_SUB_TYPES.DELIVERY_PROGRESS:
			return FIELD_TYPES.DELIVERY_PROGRESS;
		case POLARIS_SUB_TYPES.ISSUE_COMMENTS:
			return FIELD_TYPES.ISSUE_COMMENTS;
		case POLARIS_SUB_TYPES.LINKED_ISSUES:
			return FIELD_TYPES.LINKED_ISSUES;
		case POLARIS_SUB_TYPES.NUM_DATA_POINTS:
			return FIELD_TYPES.INSIGHTS;
		default:
			break;
	}

	// useless as it's already covered with polarisSubType check above
	// but typescript can't get it
	switch (type) {
		case JIRA_API_FIELD_TYPES.EXTERNAL_REFERENCE:
			return FIELD_TYPES.ATLAS_PROJECT;
		case JIRA_API_FIELD_TYPES.DATE_TIME:
			return FIELD_TYPES.ARCHIVED_ON;
		default:
			break;
	}

	return type;
};
