import React, { useState, useCallback, useRef } from 'react';
import defer from 'lodash/defer';
import { HelperMessage } from '@atlaskit/form';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Box, Flex } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import type { EmojiPickerProps } from '@atlassian/jira-polaris-lib-emoji-picker/src/ui/types.tsx';
import { InputWithEmojiPicker } from '@atlassian/jira-polaris-lib-input-with-emoji-picker/src/ui/index.tsx';
import { ErrorContainer } from '@atlassian/jira-polaris-lib-inputs-error/src/ui/styled.tsx';

export type FieldNameAndEmojiEditorProps = {
	isReadOnly: boolean;
	emojiId: string | undefined;
	value?: string;
	fieldNameInUseError?: string;
	reasonNotEditableLabel?: string;
	reasonNotEditableTooltip?: string;
	isEmojiPickerVisible?: boolean;
	onUpdateEmoji?: EmojiPickerProps['onEmojiSelected'];
	onUpdateFieldName?: (value?: string) => Promise<boolean | Error>;
	onFieldNameValidation?: (val?: string) => string | undefined;
};

export const FieldNameAndEmojiEditor = ({
	isReadOnly,
	value,
	emojiId,
	isEmojiPickerVisible,
	fieldNameInUseError,
	reasonNotEditableLabel,
	reasonNotEditableTooltip,
	onFieldNameValidation,
	onUpdateFieldName,
	onUpdateEmoji,
}: FieldNameAndEmojiEditorProps) => {
	const [inputValue, setInputValue] = useState<string | undefined>(value);
	const [error, setError] = useState<string>();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const inputRef = useRef<HTMLElement | null>(null);

	const handleSubmit = useCallback(async () => {
		if (!inputValue || inputValue === value) {
			return;
		}

		const validationError = onFieldNameValidation?.(inputValue);
		if (validationError) {
			return;
		}

		setIsSubmitLoading(true);

		try {
			const result = await onUpdateFieldName?.(inputValue);
			if (result instanceof Error) {
				setError(fieldNameInUseError);
				return;
			}
			defer(() => inputRef.current?.blur());
		} finally {
			defer(() => setIsSubmitLoading(false));
		}
	}, [fieldNameInUseError, inputValue, onFieldNameValidation, onUpdateFieldName, value]);

	const handleInputChange = useCallback(
		(inputChangeValue: string) => {
			setInputValue(inputChangeValue);
			const validationError = onFieldNameValidation?.(inputChangeValue);
			setError(validationError);
		},
		[onFieldNameValidation],
	);

	const handleOnKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				handleSubmit();
			}
			if (e.key === 'Escape') {
				setInputValue(value);
				defer(() => inputRef.current?.blur());
			}
		},
		[handleSubmit, value],
	);

	return (
		<>
			<Box testId="polaris-component-field-configuration.common.ui.field-name-and-emoji-editor.label-container">
				<InputWithEmojiPicker
					ref={inputRef}
					isInputReadOnly={isReadOnly || isSubmitLoading}
					isEmojiReadOnly={isReadOnly || isSubmitLoading}
					isDisabled={isReadOnly || isSubmitLoading}
					isInvalid={!!error}
					emojiPickerPlacement="left-start"
					emojiPickerOffset={[-4, 12]}
					value={inputValue}
					onChange={handleInputChange}
					emojiId={emojiId}
					onUpdateEmoji={onUpdateEmoji}
					onBlur={handleSubmit}
					onKeyDown={handleOnKeyDown}
					isEmojiPickerVisible={isEmojiPickerVisible}
					disableDebounce
				/>
				{error !== undefined && (
					<ErrorContainer data-testid="polaris-component-field-configuration.common.ui.field-name-and-emoji-editor.error-container">
						{error}
					</ErrorContainer>
				)}
			</Box>
			{isReadOnly && reasonNotEditableLabel ? (
				<FieldLabel label={reasonNotEditableLabel} tooltip={reasonNotEditableTooltip} />
			) : null}
		</>
	);
};

const FieldLabel = ({ label, tooltip }: { label: string; tooltip?: string }) => (
	<Flex>
		<HelperMessage>{label}</HelperMessage>
		{tooltip ? (
			<Tooltip content={tooltip}>
				<Box paddingBlockStart="space.050" paddingInlineStart="space.050">
					<InfoIcon size="small" label={label} />
				</Box>
			</Tooltip>
		) : null}
	</Flex>
);
