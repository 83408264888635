import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderTitleComponent = styled.div({
	marginTop: 0,
});

export const HeaderTitle = (props: ComponentPropsWithoutRef<typeof HeaderTitleComponent>) => (
	<Heading size="xsmall">
		<HeaderTitleComponent {...props} />
	</Heading>
);
