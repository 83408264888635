import { useCallback, useMemo, useEffect, useState } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { User as Voter } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import { FIELD_KEY } from '../../common/types';
import messages from '../../messages';
import { useVotersListStoreVoters, useVotersListStoreLoggedInUserAction } from './context';
import { type VoterResponse, LOGGED_IN_USER_ACTION } from './types';
import {
	addLoggedInUserToVoters,
	removeLoggedInUserFromVoters,
	transformServerUserToVoter,
} from './utils';

export const useVotersList = () => {
	const [{ data: loggedInUserData }] = useUserSubscriber();
	const [voters, { setVoters }] = useVotersListStoreVoters();
	const [loggedInUserAction, { setLoggedInUserAction }] = useVotersListStoreLoggedInUserAction();
	const issueKey = useIssueKey();
	const { showFlag } = useFlagsService();
	const [voteFieldValue, { setFieldValue }] = useFieldValue({
		issueKey,
		fieldKey: FIELD_KEY,
	});
	const [serviceDidFetch, setServiceDidFetch] = useState<boolean>(false);

	const request = useCallback(
		() => performGetRequest(`/rest/api/3/issue/${issueKey}/votes`),
		[issueKey],
	);

	const { loading, error, data, fetch } = useService<VoterResponse>(request);

	const transformedVoters = useMemo<Voter[]>(() => {
		if (data && data.voters) {
			return data.voters.map(transformServerUserToVoter);
		}
		return voters;
		// Only run this hook when data changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (data && data.voters) {
			setVoters(transformedVoters);
			setServiceDidFetch(true);
		}
	}, [data, setVoters, transformedVoters]);

	const visibleVoters = useMemo<Voter[]>(() => {
		const loggedInUser = loggedInUserData ? transformServerUserToVoter(loggedInUserData) : null;
		switch (loggedInUserAction) {
			case LOGGED_IN_USER_ACTION.ADDED:
				return addLoggedInUserToVoters(transformedVoters, loggedInUser);
			case LOGGED_IN_USER_ACTION.REMOVED:
				return removeLoggedInUserFromVoters(transformedVoters, loggedInUser);
			default:
				return transformedVoters;
		}
	}, [loggedInUserData, loggedInUserAction, transformedVoters]);

	useEffect(() => {
		if (error) {
			showFlag({
				type: 'error',
				title: messages.fetchVotersListFailedTitle,
				description: messages.fetchVotersListFailedDescription,
			});
		}
	}, [error, showFlag]);

	useEffect(() => {
		// re-sync the votes field store
		if (serviceDidFetch && voteFieldValue.votes !== visibleVoters.length) {
			const noLoggedInUserAction = loggedInUserAction === undefined;

			setFieldValue(issueKey, FIELD_KEY, {
				...voteFieldValue,
				votes: visibleVoters.length,
				hasVoted: noLoggedInUserAction
					? voteFieldValue.hasVoted
					: loggedInUserAction === LOGGED_IN_USER_ACTION.ADDED,
			});
		}
		setServiceDidFetch(false);
		// Only run this hook when we receieve response from the server
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceDidFetch]);

	const setLoggedInUserActionVote = useCallback(() => {
		setLoggedInUserAction(LOGGED_IN_USER_ACTION.ADDED);
	}, [setLoggedInUserAction]);

	const setLoggedInUserActionUnvote = useCallback(() => {
		setLoggedInUserAction(LOGGED_IN_USER_ACTION.REMOVED);
	}, [setLoggedInUserAction]);

	const refreshVoters = useCallback(() => {
		if (!loading) {
			fetch();
		}
	}, [fetch, loading]);

	const actions = useMemo(
		() => ({
			setLoggedInUserActionVote,
			setLoggedInUserActionUnvote,
			refreshVoters,
		}),
		[setLoggedInUserActionVote, refreshVoters, setLoggedInUserActionUnvote],
	);

	return [{ voters: visibleVoters, isFetching: loading, error }, actions] as const;
};
