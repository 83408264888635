import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions';
import type { State } from './types';

export const store = createStore<State, Actions>({
	name: 'workflow-transitions',
	initialState: {
		data: null,
		loading: false,
		error: null,
		ui: {
			transitionInProgress: false,
		},
	},
	actions,
});

export const useWorkflowTransitionsStoreValue = createHook(store, {
	selector: (state) => state,
});

export const useWorkflowTransitionsStoreTransitionInProgress = createHook(store, {
	selector: (state) => state.ui.transitionInProgress,
});

export const useWorkflowTransitionsStoreActions = createHook(store, {
	selector: null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WorkflowTransitionsStoreContainer = createContainer<State, Actions, Record<any, any>>(
	store,
);
