import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari';
import { toProjectAri } from '@atlassian/jira-polaris-domain-project/src/utils.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FieldRemote } from '../types';
import {
	createJiraFieldCrudRemote,
	createSharingFieldCrudRemote,
	createJqlFieldCrudRemote,
} from './crud';
import { createNotImplementedFieldOptionRemote, createJiraFieldOptionRemote } from './option';
import {
	createNotImplementedFieldSnippetRemote,
	createMultiProjectPolarisApiFieldSnippetRemote,
	createPolarisApiFieldSnippetRemote,
} from './snippet';

export const createFieldRemote = (
	apolloClient: PolarisApolloClient,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	cloudId: CloudId,
	projectId: ProjectId,
): FieldRemote => {
	const projectAri = toProjectAri(projectId, cloudId);

	return {
		...createPolarisApiFieldSnippetRemote(apolloClient, projectAri),
		...createJiraFieldOptionRemote(projectId),
		...createJiraFieldCrudRemote(apolloClient, createAnalyticsEvent, projectId, projectAri),
	};
};

export const createSharingFieldRemote = (viewId: Ari): FieldRemote => ({
	...createNotImplementedFieldSnippetRemote(),
	...createNotImplementedFieldOptionRemote(),
	...createSharingFieldCrudRemote(viewId),
});

export const createJqlFieldRemote = (
	jql: string,
	cloudId: CloudId,
	apolloClient: PolarisApolloClient,
): FieldRemote => ({
	...createMultiProjectPolarisApiFieldSnippetRemote(cloudId, apolloClient),
	...createNotImplementedFieldOptionRemote(),
	...createJqlFieldCrudRemote(jql),
});
