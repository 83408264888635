import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	intervalEndedRelativeFilterLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-ended-relative-filter-label',
		defaultMessage: 'Date ended within the past months',
		description: 'radio button label for filtering the interval ending in the past few months',
	},
	intervalEndedRelativeFilterActiveLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-ended-relative-filter-active-label',
		defaultMessage: 'ended within {value} months',
		description:
			'button label for having the interval ending in the last few months as active filter option',
	},
	intervalWillStartRelativeFilterLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-will-start-relative-filter-label',
		defaultMessage: 'Date starts within the next months',
		description: 'radio button label for filtering the interval starting in the next few months',
	},
	intervalWillStartRelativeFilterActiveLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-will-start-relative-filter-active-label',
		defaultMessage: 'starts within {value} months',
		description:
			'button label for having the interval starting in the next few months as active filter option',
	},
	intervalStartedRelativeFilterLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-started-relative-filter-label',
		defaultMessage: 'Date started within the previous months',
		description: 'radio button label for filtering the interval started in the previous few months',
	},
	intervalStartedRelativeFilterActiveLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-started-relative-filter-active-label',
		defaultMessage: 'started within {value} months',
		description:
			'button label for having the interval started in the previous few months as active filter option',
	},
	intervalWillEndRelativeFilterLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-will-end-relative-filter-label',
		defaultMessage: 'Date ends within the next months',
		description: 'radio button label for filtering the interval ending in the next few months',
	},
	intervalWillEndRelativeFilterActiveLabel: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.interval.utils.interval-will-end-relative-filter-active-label',
		defaultMessage: 'ends within {value} months',
		description:
			'button label for having the interval ending in the next few months as active filter option',
	},
});
