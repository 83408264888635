import type { State } from './types';

export const initialUpdatedRowsProps = [{ id: null, idx: null, isMoved: false, isFiltered: false }];

export const initialState: State = {
	rows: [],
	rowGroups: undefined,
	groupedIds: undefined,
	updatedRows: [],
	updatedRowsProps: initialUpdatedRowsProps,
	columns: [],
	fixedColumns: [],
	hoverableColumns: [],
	columnSizes: {},
	columnMinSizes: {},
	components: undefined,
	draggingRowId: undefined,
	prevDraggingRowId: undefined,
	columnDraggingInfo: {
		columnId: null,
		initialOffsetLeft: null,
		delta: null,
	},
	resizingCol: undefined,
	selectedRows: undefined,
	hoveredRow: undefined,
	lastSelectedRow: undefined,
	hoveredColumn: undefined,
	collapsedGroups: [],
	activeInlineRowCreation: undefined,
	scrollPosition: {
		left: 0,
		top: 0,
	},
	rowsRendered: {
		startIndex: 0,
		stopIndex: 0,
	},
	dimensions: {
		width: 0,
		height: 0,
		clientWidth: () => 0,
	},

	baseTableApi: undefined,

	isItemSelectionEnabled: false,
	isGroupReorderingEnabled: false,
	isColumnReorderingEnabled: false,
	isColumnResizingEnabled: false,
	isItemReorderingEnabled: false,
	isExporting: false,
	hideEmptyGroups: false,
};
