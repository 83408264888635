import { createSelector } from 'reselect';
import sum from 'lodash/sum';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { getSnippetPropertyValue } from '../../../../../../common/utils/snippet';
import { createGetSnippets } from '../../../../selectors/properties/insights';
import type { DynamicFieldResolver } from '../../types';

const FORMULA_TEMPLATE = 'property_agg';
const AGGREGATION = 'sum';

export const propertyAggregationSumResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}

	if (formula.parameters.agg !== AGGREGATION) {
		return undefined;
	}

	const propertyKeyForAggregation = formula.parameters.key;

	return (localIssueId: LocalIssueId) => {
		const getIdeaSnippets = createGetSnippets(
			localIssueId,
			formula.parameters.filter,
			formula.parameters.oauthClientId,
		);

		return createSelector(getIdeaSnippets, (snippets: Snippet[]) => {
			const propValues: number[] = snippets
				.map(({ properties }) => {
					const value = getSnippetPropertyValue(properties, propertyKeyForAggregation);
					if (typeof value === 'number') {
						return value;
					}
					return undefined;
				})
				.filter(Boolean);

			return sum(propValues);
		});
	};
};
