import {
	type ParentGetPublicValue,
	type ParentSetPublicValue,
	parentScopes,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/parent';
import { commonIssueViewGranularScopes } from './fields-scopes';

type ParentInternalIdShapeValue = null | { id: string };

type ParentInternalFullShapeValue = null | {
	id: string;
	key: string;
};

export function transformParentSetValue(value: ParentSetPublicValue): ParentInternalIdShapeValue {
	if (value === null) {
		return null;
	}

	return { id: value };
}

export function transformParentGetValue(
	internalValue: ParentInternalFullShapeValue,
): ParentGetPublicValue {
	if (!internalValue) {
		return null;
	}

	return {
		id: internalValue.id,
		key: internalValue.key,
	};
}

export const parentIssueViewScopes = {
	...parentScopes,
	granular: [...parentScopes.granular, ...commonIssueViewGranularScopes],
};
