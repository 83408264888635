import React, { type SyntheticEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useSelectedIssue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import { useMoveIssue } from './utils';

type MoveButtonProps = {
	isDropdownItem?: boolean;
};

export const MoveButton = ({ isDropdownItem = false }: MoveButtonProps) => {
	const { formatMessage } = useIntl();
	const localIssueId = useSelectedIssue();
	const onMoveClick = useMoveIssue(localIssueId !== undefined ? localIssueId : '');

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent?: UIAnalyticsEvent) => {
			if (isDropdownItem) {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
					'issueOption',
					{
						selectedActionItem: 'move',
					},
				);
			} else {
				// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
				fireUIAnalytics(analyticsEvent, 'move');
			}
			onMoveClick();
		},
		[createAnalyticsEvent, onMoveClick, isDropdownItem],
	);

	if (localIssueId === undefined) {
		return null;
	}

	const Component = isDropdownItem ? DropdownItem : Button;
	const testId = isDropdownItem
		? 'polaris-ideas.ui.idea-view.collaboration-controls.move.dropdown-item'
		: 'polaris-ideas.ui.idea-view.collaboration-controls.move.button';

	return (
		<Component testId={testId} onClick={handleClick}>
			{formatMessage(messages.moveButton)}
		</Component>
	);
};
