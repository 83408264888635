export const LISTS = {
	SELECTED: 'selected-fields-list',
	AVAILABLE: 'available-fields-list',
};

export const TOGGLE_PERMISSIONS = {
	PIN_FIELDS: 'pin_fields',
	MANAGE_VIEWS: 'manage_views',
};

export type TogglePermissionType = (typeof TOGGLE_PERMISSIONS)[keyof typeof TOGGLE_PERMISSIONS];
