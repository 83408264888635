import { differenceInDays, parseISO, isValid } from 'date-fns';
import type { CalendarProps, CalendarState, DatePickerValue } from '../../types/date-picker';
import { formatNiceDate, formatNiceMonthDate, formatNiceQuarterDate } from './date-format';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum FuzzyScale {
	DAY = 'day',
	MONTH = 'month',
	QUARTER = 'quarter',
}

const MONTH_THRESHOLD = 31;
const DAY_THRESHOLD = 1;

export const getFuzzyScale = (dateObj?: DatePickerValue) => {
	if (dateObj) {
		const dateDiff = differenceInDays(new Date(dateObj.end), new Date(dateObj.start));
		if (dateDiff < DAY_THRESHOLD) {
			return FuzzyScale.DAY;
		}
		if (dateDiff <= MONTH_THRESHOLD) {
			return FuzzyScale.MONTH;
		}
		return FuzzyScale.QUARTER;
	}
	// If date is somehow undefined, we'll show quarters picker by default
	return FuzzyScale.QUARTER;
};

export const renderDateString = (dateString?: DatePickerValue, placeholder?: string) => {
	if (!dateString) {
		return placeholder;
	}
	const endDate = parseISO(dateString.end);
	if (!isValid(endDate)) {
		return placeholder;
	}
	switch (getFuzzyScale(dateString)) {
		case FuzzyScale.DAY:
			return formatNiceDate(endDate);
		case FuzzyScale.QUARTER:
			return formatNiceQuarterDate(endDate);
		default:
		case FuzzyScale.MONTH:
			return formatNiceMonthDate(endDate);
	}
};

export const getInitialMonthPickerDate = (props: CalendarProps): CalendarState => {
	const parsedDate = props.date ? parseISO(props.date) : new Date();
	return {
		initialYear: parsedDate.getFullYear(),
		year: parsedDate.getFullYear(),
		month: parsedDate.getMonth(),
	};
};
