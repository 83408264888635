import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment';
import { createIdeaHook, createHigherLevelIdeaHook } from '../utils';
import { getSortedCommentIds, createGetComment, getAllMediaIds } from './index';

export const useSortedCommentIds: () => readonly string[] = createIdeaHook(getSortedCommentIds);

export const useComment: (arg1: string) => PolarisComment | undefined =
	createHigherLevelIdeaHook(createGetComment);

export const useCommentMediaIds: () => string[] = createIdeaHook(getAllMediaIds);
