import { useEnvironment } from '../environment';

export const useTeamCentralUrl = (): string => '/gateway/api/watermelon/graphql';

export const useTeamCentralBaseUrl = (): string => {
	const env = useEnvironment();
	if (env === 'staging' || env === 'dev' || env === 'local') {
		return 'https://team.stg.atlassian.com';
	}
	return 'https://team.atlassian.com';
};
