import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import { fieldPendingValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import { saveDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions';
import {
	type FieldEditUpdateAction,
	FIELD_EDIT_UPDATE,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions';
import { fieldSaveDraftError } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-draft-actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processEditForSaving = (action: any, state: State) => {
	const {
		payload: { fieldId, fieldOptions, value },
	} = action;
	const shouldSaveDraft = fieldOptions ? fieldOptions.shouldSaveDraft : false;

	if (!shouldSaveDraft) {
		return Observable.empty<never>();
	}

	const currentFieldValue = fieldPendingValueSelector(fieldId)(state);
	return Observable.of(saveDraftRequest(fieldId, value, currentFieldValue));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError(
		'issue.save-draft',
		'Could not send request to save field draft to local storage',
		error,
	);
	return Observable.of(fieldSaveDraftError(error));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<FieldEditUpdateAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_EDIT_UPDATE)
		.mergeMap((action) => processEditForSaving(action, store.getState()))
		.catch((error) => errorHandler(error));
