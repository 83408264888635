import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { initialized } from './actions/initialized';
import { markDynamicFieldAsInitialized } from './actions/mark-as-initialized';
import type { State, Props } from './types';

const actions = {
	markDynamicFieldAsInitialized,
	initialized,
};

type Actions = typeof actions;

const initialState: State = {
	dynamicPropertiesInitialized: {},
	initialized: false,
	containerProps: undefined,
};

const DynamicFieldStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolariDynamicFieldStore',
});

const { Container, useActions, createHigherLevelHook } = createJpdContainer<Props, State, Actions>(
	DynamicFieldStore,
);

export { Container as DynamicFieldContainer, useActions };

export const useIsDynamicFieldIsInitialized = createHigherLevelHook(
	(fieldKey: FieldKey) => (state: State) =>
		state.initialized === true && state.dynamicPropertiesInitialized[fieldKey] === true,
);
