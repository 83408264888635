import { NEWEST_FIRST } from '@atlassian/jira-issue-shared-types';
import type { ActivityFeedUiState } from '@atlassian/jira-issue-view-common-types/src/activity-item-type';
import {
	type Action as ActivityFeedAction,
	SELECT_ACTIVITY_ITEM,
	SELECT_INITIAL_ACTIVITY_ITEM,
	SELECT_ACTIVITY_SORT_ORDER,
} from '../actions/activity-feed-actions';
import {
	type FetchIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
} from '../common/actions/issue-fetch-actions';

const initialState: ActivityFeedUiState = {
	selectedActivityItem: null,
	selectedActivitySortOrder: NEWEST_FIRST,
};

type Action = FetchIssueSuccessAction | ActivityFeedAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ActivityFeedUiState = initialState, action: Action) => {
	switch (action.type) {
		case SELECT_INITIAL_ACTIVITY_ITEM:
		case SELECT_ACTIVITY_ITEM:
			return {
				...state,
				selectedActivityItem: action.payload,
			};
		case SELECT_ACTIVITY_SORT_ORDER:
			return {
				...state,
				selectedActivitySortOrder: action.payload,
			};
		case FETCH_ISSUE_SUCCESS:
			return {
				...state,
				selectedActivitySortOrder: action.payload.activitySortOrder,
			};
		default:
			return state;
	}
};
