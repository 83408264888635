import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	performGetRequest,
	performDeleteRequest,
	performPutRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type {
	NotificationConfiguration,
	NotificationEventTypeId,
	NotificationRecipient,
	NotificationSettingsServiceState,
	NotificationSettingsResponse,
	NotificationsContainerProps,
	EventTypesResponse,
	NotificationTypesResponse,
	ProjectRoleResponse,
} from '../types';
import {
	buildDeleteUrl,
	buildNotificationSettingsUrl,
	buildRecipientsUpdateUrl,
	buildSupportedEventTypesUrl,
	buildSupportedNotificationRecipientTypesUrl,
	buildProjectRolesUrl,
	getTranslationOverrides,
} from './utils';

type OnFailureCallback = {
	onFailure: () => void;
};

const getNotificationSettings = (
	// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'.
	baseUrl: BaseUrl,
	// @ts-expect-error - TS2304 - Cannot find name 'ProjectUuid'.
	projectUuid: ProjectUuid,
): Promise<NotificationSettingsResponse> =>
	performGetRequest(buildNotificationSettingsUrl(baseUrl, projectUuid));

const getNotificationPutPayload = (notificationRecipients: NotificationRecipient[]) =>
	notificationRecipients.map((recipient) => {
		const parameter =
			recipient.parameter !== undefined ? { parameter: recipient.parameter } : undefined;
		return { key: recipient.key, ...parameter };
	});

const actions = {
	fetchNotificationSettings:
		(): Action<NotificationSettingsServiceState, NotificationsContainerProps> =>
		async ({ setState, getState }, { baseUrl, projectUuid }) => {
			setState({ loading: true });
			try {
				const response = await getNotificationSettings(baseUrl, projectUuid);
				const withTranslationOverrides = getTranslationOverrides(response);
				setState({
					data: { ...getState().data, configuration: withTranslationOverrides },
					loading: false,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({ error, loading: false });
				trackFetchErrors({
					error,
					id: 'fetchNotificationSettings',
					packageName: 'jiraProjectSettingsAppsNotificationsServices',
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},

	fetchSupportedEventTypes:
		// @ts-expect-error - TS2741 - Property 'onFailure' is missing in type '{}' but required in type 'OnFailureCallback'.


			({ onFailure }: OnFailureCallback = {}): Action<
				NotificationSettingsServiceState,
				NotificationsContainerProps
			> =>
			async ({ setState, getState }, { baseUrl, projectUuid }) => {
				try {
					const eventTypes: EventTypesResponse = await performGetRequest(
						buildSupportedEventTypesUrl(baseUrl, projectUuid),
					);
					const withTranslationOverrides = eventTypes.map((eventType) => ({
						...eventType,
						name: eventType.name,
					}));
					setState({
						data: { ...getState().data, eventTypes: withTranslationOverrides },
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					trackFetchErrors({
						error,
						id: 'fetchSupportedEventTypes',
						packageName: 'jiraProjectSettingsAppsNotificationsServices',
						sendToPrivacyUnsafeSplunk: true,
					});
					onFailure && onFailure();
				}
			},

	deleteNotification:
		(
			eventTypeId: NotificationEventTypeId,
			onSuccess: () => void,
			onFailure: () => void,
		): Action<NotificationSettingsServiceState, NotificationsContainerProps> =>
		async ({ setState, getState, dispatch }, { baseUrl, projectUuid }) => {
			setState({ loading: true });
			try {
				await performDeleteRequest(buildDeleteUrl({ baseUrl, projectUuid, eventTypeId }));
				const {
					data: { configuration },
				} = getState();
				if (configuration) {
					setState({
						data: {
							...getState().data,
							configuration: configuration.filter(
								(config) => config.eventType.eventTypeId !== eventTypeId,
							),
						},
					});
				}
				dispatch(actions.fetchSupportedEventTypes());
				onSuccess();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				trackFetchErrors({
					error,
					id: 'deleteNotification',
					packageName: 'jiraProjectSettingsAppsNotificationsServices',
					sendToPrivacyUnsafeSplunk: true,
				});
				onFailure();
			} finally {
				setState({ loading: false });
			}
		},

	putNotification:
		(
			notificationConfig: NotificationConfiguration,
			onSuccess: (eventType: NotificationEventTypeId) => void,
			onFailure: () => void,
		): Action<NotificationSettingsServiceState, NotificationsContainerProps> =>
		async ({ setState, getState, dispatch }, { baseUrl, projectUuid }) => {
			setState({ loading: true });
			try {
				const {
					data: { configuration: existingConfig },
				} = getState();

				const payload = getNotificationPutPayload(notificationConfig.notifications);

				await performPutRequest(
					buildRecipientsUpdateUrl(baseUrl, projectUuid, notificationConfig.eventType.eventTypeId),
					{
						body: JSON.stringify({ notifications: payload }),
					},
				);
				dispatch(actions.fetchSupportedEventTypes());

				const isEditingExistingNotification = (existingConfig || []).find(
					(c) => c.eventType.key === notificationConfig.eventType.key,
				);

				if (existingConfig && isEditingExistingNotification) {
					setState({
						data: {
							...getState().data,
							configuration: existingConfig.map((config) =>
								config.eventType.key === notificationConfig.eventType.key
									? notificationConfig
									: config,
							),
						},
					});
				} else {
					const updatedConfiguration = await getNotificationSettings(baseUrl, projectUuid);
					const withTranslationOverrides = getTranslationOverrides(updatedConfiguration);
					setState({
						data: { ...getState().data, configuration: withTranslationOverrides },
					});
				}

				onSuccess(notificationConfig.eventType.eventTypeId);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				trackFetchErrors({
					error,
					id: 'putNotification',
					packageName: 'jiraProjectSettingsAppsNotificationsServices',
					sendToPrivacyUnsafeSplunk: true,
				});
				onFailure();
			} finally {
				setState({ loading: false });
			}
		},

	fetchNotificationTypes:
		// @ts-expect-error - TS2741 - Property 'onFailure' is missing in type '{}' but required in type 'OnFailureCallback'.


			({ onFailure }: OnFailureCallback = {}): Action<
				NotificationSettingsServiceState,
				NotificationsContainerProps
			> =>
			async ({ setState, getState }, { baseUrl, projectUuid }) => {
				try {
					const [eventTypes, notificationTypes]: [EventTypesResponse, NotificationTypesResponse] =
						await Promise.all([
							performGetRequest(buildSupportedEventTypesUrl(baseUrl, projectUuid)),
							performGetRequest(buildSupportedNotificationRecipientTypesUrl(baseUrl)),
						]);
					const withTranslationOverrides = eventTypes.map((eventType) => ({
						...eventType,
						name: eventType.name,
					}));
					setState({
						data: {
							...getState().data,
							eventTypes: withTranslationOverrides,
							notificationTypes,
						},
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					trackFetchErrors({
						error,
						id: 'fetchNotificationTypes',
						packageName: 'jiraProjectSettingsAppsNotificationsServices',
						sendToPrivacyUnsafeSplunk: true,
					});
					onFailure && onFailure();
				}
			},

	fetchProjectRoles:
		// @ts-expect-error - TS2741 - Property 'onFailure' is missing in type '{}' but required in type 'OnFailureCallback'.


			({ onFailure }: OnFailureCallback = {}): Action<
				NotificationSettingsServiceState,
				NotificationsContainerProps
			> =>
			async ({ setState, getState }, { baseUrl, projectKey }) => {
				try {
					const response: ProjectRoleResponse = await performGetRequest(
						buildProjectRolesUrl(baseUrl, projectKey),
					);
					const projectRoles = response.map((role) => ({
						key: String(role.id),
						name: role.name,
					}));
					setState({
						data: { ...getState().data, projectRoles },
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					trackFetchErrors({
						error,
						id: 'fetchProjectRoles',
						packageName: 'jiraProjectSettingsAppsNotificationsServices',
						sendToPrivacyUnsafeSplunk: true,
					});
					onFailure && onFailure();
				}
			},
} as const;

export default actions;
