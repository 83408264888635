import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import Select, { components } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type {
	GroupType,
	OptionComponentProps,
	SelectProps,
} from '@atlassian/jira-polaris-common/src/common/types/select';
import { ESCAPE } from '@atlassian/jira-polaris-common/src/common/utils/key-code';
import messages from './messages';
import { SelectOptionComponent } from './select-option';

const OptionComponent = ({ data: { key }, ...rest }: OptionComponentProps) => (
	// @ts-expect-error - TS2740 - Type '{ children: Element; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
	<components.Option {...rest}>
		<SelectOptionComponent fieldKey={key} />
	</components.Option>
);

const GroupComponent = (props: GroupType) => {
	const [visibleFields, setVisibleFields] = useState(false);
	const onShow = () => setVisibleFields(!visibleFields);

	const { formatMessage } = useIntl();

	if (props.label === 'supported') {
		return (
			// @ts-expect-error - TS2740 - Type '{ label: string; options: OptionsType; data: { options: OptionsType; }; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 7 more.
			<components.Group {...props} />
		);
	}

	const optionsLength = props.options.length;
	const isFoundOptions = props.data.options.length !== optionsLength;

	const btnMessage = visibleFields
		? formatMessage(messages.hideDisableFields)
		: formatMessage(messages.showDisableFields);

	const groupHeaderMessage = isFoundOptions
		? messages.foundDisableFields
		: messages.disableFieldsHeader;

	const componentsGroupItems = (
		// @ts-expect-error - TS2740 - Type '{ label: string; options: OptionsType; data: { options: OptionsType; }; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 7 more.
		<components.Group {...props} />
	);

	return (
		<>
			<GroupTitle>{formatMessage(groupHeaderMessage, { count: optionsLength })}</GroupTitle>
			{visibleFields ? componentsGroupItems : null}
			<Button appearance="link" onClick={onShow}>
				{btnMessage}
			</Button>
		</>
	);
};

const DropdownIndicator = () => (
	<EditorSearchIcon primaryColor={token('color.text', colors.N100)} label="" />
);

export const MatrixSelectDropdown = ({ options, onSelect, onClose }: SelectProps) => {
	const { formatMessage } = useIntl();

	const noOptionsMessage = () => formatMessage(messages.noMatchesIndicator);

	return (
		<Select
			autoFocus
			backspaceRemovesValue={false}
			components={{
				DropdownIndicator,
				IndicatorSeparator: null,
				Option: OptionComponent,
				// @ts-expect-error - TS2322 - Type '(props: GroupType) => JSX.Element' is not assignable to type 'ComponentType<GroupProps<OptionType, false, GroupTypeBase<OptionType>>> | undefined'.
				Group: GroupComponent,
			}}
			controlShouldRenderValue={false}
			hideSelectedOptions={false}
			noOptionsMessage={noOptionsMessage}
			isClearable={false}
			escapeClearsValue
			menuIsOpen
			onKeyDown={(e) => {
				if (e.keyCode === ESCAPE) {
					onClose && onClose();
				}
			}}
			isOptionDisabled={(option) => !!option.disabled}
			// @ts-expect-error - TS2339 - Property 'key' does not exist on type 'OptionType | null'.
			onChange={({ key }) => onSelect(key)}
			options={options}
			placeholder={formatMessage(messages.searchHint)}
			styles={{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				control: (styles: any) => ({
					...styles,
					width: 224,
					margin: token('space.100', '8px'),
					lineHeight: '20px',
					':hover': {
						cursor: 'text',
					},
				}),
				menu: () => ({
					width: 240,
					boxShadow: token('elevation.shadow.overflow', 'inset 0 1px 0 rgba(0, 0, 0, 0.1)'),
				}),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				menuList: (styles: any) => ({
					...styles,
					height: 'calc(50vh - var(--topNavigationHeight) - 80px)',
				}),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				group: (styles: any) => ({
					...styles,
					':not(:last-of-type)': {
						borderBottom: `1px solid ${token('color.border', N40A)}`,
					},
				}),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				groupHeading: (styles: any) => ({
					...styles,
					display: 'none',
				}),
			}}
			tabSelectsValue={false}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupTitle = styled.div({
	padding: `${token('space.150', '12px')} ${token('space.150', '12px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '20px',
});
