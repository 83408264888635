import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { HintMessage as HintMessageType } from './ui';
import type { Props } from './ui/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyHintMessage = lazyForPaint<typeof HintMessageType>(
	() =>
		import(/* webpackChunkName: "async-polaris-lib-hint-message" */ './ui').then(
			({ HintMessage }) => HintMessage,
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<Placeholder name="polaris-lib-hint-message">
		<LazyHintMessage {...props} />
	</Placeholder>
);
