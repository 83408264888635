import React, { type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Select, { PopupSelect } from '@atlaskit/select';
import Blanket from '@atlassian/jira-common-blanket/src/view.tsx';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StatusTransition, StatusTransitionOption } from '../../common/types';
import { WrappedStatusLozenge } from '../../common/ui/status-lozenge';
import {
	getFilteredOrderedTransitions,
	isOnlyGlobalTransitions,
	transformOptions,
} from '../../common/utils';
import { CommandPaletteStatusView } from './command-palette';
import messages from './messages';
import { LoadingSpinner } from './spinner';
import { StatusButton } from './status-button';
import { Transition } from './transition';
import type { Props, SelectTarget, SelectTargetRender } from './types';

const defaultPopperProps = {
	modifiers: [
		{
			name: 'offset',
			options: {
				offset: [0, 8],
			},
		},
		{
			name: 'preventOverflow',
			options: {
				padding: 5,
				rootBoundary: 'document',
			},
		},
	],
	// makes it work inside modal-dialog
	strategy: 'fixed',
};

export const PopupSelectWithAnalytics = AnalyticsEventToProps('statusField', {
	onOpen: 'openDropdown',
	onChange: 'transitionSelected',
})(PopupSelect);

export const SelectWithAnalytics = AnalyticsEventToProps('statusField', {
	onOpen: 'openDropdown',
	onChange: 'transitionSelected',
})(Select);

const isOptionDisabled = (option: StatusTransitionOption) => option.transition.isDisabled;

export const StatusView = ({
	forceNoLabels = false,
	isEditable = true,
	isSaving = false,
	isLoading,
	error,
	value,
	transitions,
	appearance,
	footer,
	isDefaultSelect = false,
	selectedTransition,
	onChange = noop,
	onOpen = noop,
	onClose = noop,
	onSuccess,
	customNoOptionsMessage,
	statusButtonRef,
	popperProps,
	registerInCommandPalette,
	onFetch,
	fieldId,
	// for testing
	defaultOpen = false,
	shouldFitContainer = true,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const childWrapperRef = useRef<unknown>(null);
	const wasChanged = useRef<boolean>(false);

	const { formatMessage } = useIntl();

	const previousIsSaving = usePrevious(isSaving);
	const previousTransitions = usePrevious(transitions);
	const previousIsOpen = usePrevious(isOpen);

	const wasFetchSuccessful = transitions != null;
	const filteredAndOrderedTransitions = getFilteredOrderedTransitions(transitions, value.id);
	const isGlobalOnly = isOnlyGlobalTransitions(filteredAndOrderedTransitions || []);
	const showLabel = !(forceNoLabels || isGlobalOnly);

	const formatTransitionOptionLabel = useCallback(
		(
			option: Pick<StatusTransitionOption, 'transition'>,
			transitionProps?: {
				addTransitionArrowSpacing?: boolean;
			},
		) => (
			<div data-testid="issue-field-status.ui.status-view.transition">
				<Transition
					transition={option.transition}
					hasLabel={showLabel}
					addTransitionArrowSpacing={transitionProps?.addTransitionArrowSpacing}
				/>
			</div>
		),
		[showLabel],
	);

	// specifically for screen readers
	const getTransitionOptionLabel = (option: StatusTransitionOption) =>
		option.transition.isGlobal
			? formatMessage(messages.transitionTo, { statusName: option.label })
			: `${option.transition.name} → ${option.transition.to.name}`;

	const mergedPopperProps = useMemo(() => merge(defaultPopperProps, popperProps), [popperProps]);

	// componentWillUpdate
	useEffect(() => {
		if (previousIsSaving === true && isSaving === false) {
			childWrapperRef.current &&
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				childWrapperRef.current.children.length > 0 &&
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				childWrapperRef.current.children[0].focus();
		}
	}, [isOpen, isSaving, previousIsOpen, previousIsSaving, previousTransitions, transitions]);

	const handleChangeStatus = useCallback(
		(transition: StatusTransition, analyticsEvent: UIAnalyticsEvent) => {
			const { hasScreen } = transition;
			wasChanged.current = true;

			fireUIAnalytics(analyticsEvent, 'transitionSelectedStatusField', {
				hasScreen,
			});

			onChange?.(transition, analyticsEvent);
		},
		[onChange],
	);

	const handleChange = useCallback(
		(
			option: StatusTransitionOption,
			action: ActionMeta<StatusTransitionOption>,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			handleChangeStatus(option.transition, analyticsEvent);
		},
		[handleChangeStatus],
	);

	const handleOpen = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'openDropdownStatusField');

			onOpen?.(analyticsEvent);
			setIsOpen(true);
		},
		[onOpen],
	);

	const handleClose = useCallback(() => {
		wasChanged.current = false;
		onClose?.();
		setIsOpen(false);
	}, [onClose]);

	const renderLozenge = useCallback(
		({ isEditable: editable, isSaving: saving, error: err, targetRef }: SelectTargetRender) => {
			if (saving) {
				return (
					<Blanket border={false}>
						<WrappedStatusLozenge targetRef={targetRef} status={value} error={error} />
					</Blanket>
				);
			}

			return (
				<WrappedStatusLozenge
					ariaLabel={`${value.name} - ${formatMessage(messages.changeStatus)}`}
					error={err}
					hasHoverState
					isEditable={editable}
					isOpen={isOpen}
					status={value}
					targetRef={targetRef}
				/>
			);
		},
		[error, formatMessage, isOpen, value],
	);

	const renderButton = useCallback(
		({ isEditable: editable, isSaving: saving, targetRef }: SelectTargetRender) => {
			const statusButton = (
				<StatusButton
					ariaLabel={`${value.name} - ${formatMessage(messages.changeStatus)}`}
					isEditable={editable}
					isOpen={isOpen}
					status={value}
					targetRef={targetRef}
					statusButtonRef={statusButtonRef}
					shouldFitContainer={shouldFitContainer}
				/>
			);
			return saving ? <Blanket>{statusButton}</Blanket> : statusButton;
		},
		[formatMessage, isOpen, shouldFitContainer, statusButtonRef, value],
	);

	const renderCommandPalette = useCallback(
		() => (
			<CommandPaletteStatusView
				transitions={filteredAndOrderedTransitions}
				currentStatus={value}
				onSuccess={onSuccess}
				wasFetchSuccessful={wasFetchSuccessful}
				renderTransition={formatTransitionOptionLabel}
				onFetch={onFetch}
				isLoading={isLoading}
			/>
		),
		[
			filteredAndOrderedTransitions,
			value,
			onSuccess,
			wasFetchSuccessful,
			formatTransitionOptionLabel,
			onFetch,
			isLoading,
		],
	);

	const renderWithFocusWrapper = useCallback(
		// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
		(children: ReactNode) => <div ref={childWrapperRef}>{children}</div>,
		[],
	);

	const renderTarget = useCallback(
		({ isEditable: editable, isSaving: saving, error: err, targetRef }: SelectTargetRender) => {
			if (value) {
				if (appearance === 'lozenge') {
					return renderWithFocusWrapper(
						renderLozenge({
							isEditable: editable,
							isSaving: saving,
							error: err,
							targetRef,
						}),
					);
				}
				if (appearance === 'button') {
					return (
						<>
							{renderWithFocusWrapper(
								renderButton({ isEditable: editable, isSaving: saving, targetRef }),
							)}
							{registerInCommandPalette && renderCommandPalette()}
						</>
					);
				}
			}
			return <div />;
		},
		[
			appearance,
			registerInCommandPalette,
			renderButton,
			renderCommandPalette,
			renderLozenge,
			renderWithFocusWrapper,
			value,
		],
	);

	/* This prevents the dropdown from being accessible while saving/not editable,
	 * because for the underlying PopupSelect, the `isDisabled` prop only prevents
	 * the actual dropdown items from being selectable - the dropdown itself still
	 * pops up/down.
	 */
	if (isSaving || !isEditable) {
		return renderTarget({ isSaving, isEditable, error });
	}

	const getNoOptionsMessage = () => {
		if (customNoOptionsMessage) {
			return customNoOptionsMessage;
		}
		return formatMessage(
			wasFetchSuccessful ? messages.noPermission : messages.cannotLoadTransitions,
		);
	};

	const statusPopup =
		isDefaultSelect && selectedTransition ? (
			<SelectWithAnalytics
				inputId={fieldId}
				noOptionsMessage={getNoOptionsMessage}
				formatOptionLabel={formatTransitionOptionLabel}
				getOptionLabel={getTransitionOptionLabel}
				options={transformOptions(filteredAndOrderedTransitions || [])}
				isLoading={isLoading}
				isDisabled={!isEditable}
				isOptionDisabled={isOptionDisabled}
				onChange={handleChange}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				value={transformOptions([selectedTransition!])[0]}
				isSearchable={false}
				onOpen={handleOpen}
				onClose={handleClose}
				closeMenuOnSelect
				components={{
					LoadingMessage: () => <LoadingSpinner />,
				}}
				popperProps={mergedPopperProps}
				footer={footer}
			/>
		) : (
			<PopupSelectWithAnalytics
				inputId={fieldId}
				noOptionsMessage={getNoOptionsMessage}
				formatOptionLabel={formatTransitionOptionLabel}
				getOptionLabel={getTransitionOptionLabel}
				options={transformOptions(filteredAndOrderedTransitions || [])}
				target={({ ref }: SelectTarget) =>
					renderTarget({ isEditable, isSaving, error, targetRef: ref })
				}
				isLoading={isLoading}
				isDisabled={!isEditable}
				isOptionDisabled={isOptionDisabled}
				searchThreshold={99999}
				onChange={handleChange}
				onOpen={handleOpen}
				onClose={handleClose}
				closeMenuOnSelect
				components={{
					LoadingMessage: () => <LoadingSpinner />,
				}}
				popperProps={mergedPopperProps}
				footer={footer}
				defaultIsOpen={defaultOpen}
			/>
		);

	return <EnterEscapeHandler onEscape={noop}>{statusPopup}</EnterEscapeHandler>;
};

export default StatusView;
