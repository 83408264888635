import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	valueEmptyError: {
		id: 'polaris-lib-field-option-configuration.utils.validate-field-option-name.value-empty-error',
		defaultMessage: 'Name must not be empty',
		description: 'Error message for trying to create an option with an empty name',
	},
	valueDuplicateError: {
		id: 'polaris-lib-field-option-configuration.utils.validate-field-option-name.value-duplicate-error',
		defaultMessage: 'No duplicate names are allowed',
		description: 'Error message for trying to create an option a name that already exists',
	},
	valueTooLongError: {
		id: 'polaris-lib-field-option-configuration.utils.validate-field-option-name.value-too-long-error',
		defaultMessage: "You've reached the 255 character limit",
		description: 'Error message for too long option field value',
	},
});
