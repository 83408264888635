import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';

/**
 * Returns the current jpd-views-service hostname to use, or undefined to use staging
 * Use this to centrally override the jpd-views-service hostname.
 * Example: 'https://asokolov-be.public.atlastunnel.com'
 *
 * https://app.launchdarkly.com/jira/staging/features/polaris.jpd-views-service-host/variations
 */
const getJpdViewsServiceHost = () =>
	getFeatureFlagValue<string>('polaris.jpd-views-service-host', 'default');

export const getJpdViewsServicePathPrefix = () => {
	const ffHost = getJpdViewsServiceHost();
	if (!ffHost || ffHost === 'default') {
		return '/gateway/api/jpd';
	}
	return ffHost;
};
