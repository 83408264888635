import { useMemo, useRef } from 'react';
import find from 'lodash/find';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import {
	useCurrentViewVerticalGroupValues,
	useCurrentViewSortBy,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import { type ExtendedOption, EMPTY_VALUE_ID } from '../../../../common/utils/board';
import { useExtendedOptionsInNaturalOrder, useRestrictedOptions } from './field-visibility-options';

export const useExtendedVerticalGroupOptions = (
	field?: Field,
	includeAllOptions = false,
): ExtendedOption<unknown>[] => {
	const sortBy = useCurrentViewSortBy();
	const currentViewVerticalGroupValues = useCurrentViewVerticalGroupValues();

	// apply restrictions
	const restrictedOptions = useRestrictedOptions(field);
	const allOptions = useExtendedOptionsInNaturalOrder(field);
	const options = includeAllOptions ? allOptions : restrictedOptions;
	const previousSortedOptions = useRef<ExtendedOption<unknown>[]>([]);

	// apply sort order of view configuration, if present
	return useMemo(() => {
		const handledGroupIdentities: Array<string> = [];
		let sortedOptions: Array<ExtendedOption<unknown>> = [];

		const optionsByGroupIdentity = keyBy(options, ({ groupIdentity }) => groupIdentity);

		// always put the "no value" column first
		const noValueOption = find(options, ({ groupIdentity }) => groupIdentity === undefined);

		currentViewVerticalGroupValues.forEach((groupValue: GroupValue) => {
			if (groupValue.id !== undefined && has(optionsByGroupIdentity, groupValue.id)) {
				handledGroupIdentities.push(groupValue.id);
				sortedOptions.push(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					optionsByGroupIdentity[groupValue.id] as ExtendedOption<unknown>,
				);
			}

			if (groupValue.id === EMPTY_VALUE_ID && noValueOption) {
				sortedOptions.push(noValueOption);
			}
		});

		options.forEach((option) => {
			if (
				option.groupIdentity !== undefined &&
				!handledGroupIdentities.includes(option.groupIdentity)
			) {
				sortedOptions.push(option);
			}
		});
		const sortField = sortBy?.find((value) => value.fieldKey === field?.key);

		if (currentViewVerticalGroupValues.length === 0 && sortField?.asc === false) {
			sortedOptions = sortedOptions.reverse();
		}

		const hasNoValueOption = sortedOptions.some((o) => !o.groupIdentity);

		if (noValueOption !== undefined && !hasNoValueOption) {
			sortedOptions.push(noValueOption);
		}

		if (JSON.stringify(previousSortedOptions.current) === JSON.stringify(sortedOptions)) {
			// allows to keep the same array reference to prevent upstream rerenders
			return previousSortedOptions.current;
		}

		previousSortedOptions.current = sortedOptions;

		return sortedOptions;
	}, [currentViewVerticalGroupValues, field?.key, options, sortBy]);
};
