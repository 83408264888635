import React from 'react';
import { token } from '@atlaskit/tokens';
import { useStringValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { whyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

export const ReadOnlySummaryField = ({ fieldKey, localIssueId }: Props) => {
	const value = useStringValue(fieldKey, localIssueId);

	return (
		<>
			{whyDidYouRenderPlaceholder('ReadOnlySummaryField')}
			<div
				data-testid="polaris-ideas.ui.view-content.idea-list.cell.cells.link-field.read-only.container"
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					overflow: 'hidden',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					textOverflow: 'ellipsis',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					whiteSpace: 'nowrap',
					padding: `${token('space.100', '8px')} ${token('space.075', '6px')} ${token(
						'space.075',
						'6px',
					)}`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					border: '2px solid transparent',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					boxSizing: 'border-box',
				}}
			>
				{value}
			</div>
		</>
	);
};
