import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import keyBy from 'lodash/keyBy';
import noop from 'lodash/noop';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useFieldLabel,
	useFieldType,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useFieldOptionsWithAriResolved } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { useLocalIssueIdToJiraIssueIdMapper } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import {
	useSummary,
	useIssueAnalitycsAttributes,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	useCurrentViewXAxis,
	useCurrentViewYAxis,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { DecoratedTag } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/tag/index.tsx';
import { NumberField } from '@atlassian/jira-polaris-common/src/ui/fields/number';
import { StringField } from '@atlassian/jira-polaris-common/src/ui/fields/string';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { AxisValue, DnDTooltipProps } from '@atlassian/jira-polaris-lib-matrix/src/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { StatusFieldLabel } from '../status-field';
import { UserFieldValue } from '../user-field';

type ValueProps = {
	value: AxisValue;
	fieldKey: FieldKey;
};

const Value = ({ value, fieldKey }: ValueProps) => {
	const fieldType = useFieldType(fieldKey);
	const decorations = useDecorationsForField(fieldKey);
	const options = useFieldOptionsWithAriResolved(fieldKey);

	if (
		(fieldType === FIELD_TYPES.NUMBER ||
			fieldType === FIELD_TYPES.SLIDER ||
			fieldType === FIELD_TYPES.CHECKBOX ||
			fieldType === FIELD_TYPES.RATING ||
			fieldType === FIELD_TYPES.FORMULA ||
			fieldType === FIELD_TYPES.INSIGHTS ||
			fieldType === FIELD_TYPES.LINKED_ISSUES) &&
		typeof value === 'number'
	) {
		return (
			<NumberField
				decorations={decorations}
				value={value}
				fieldType={fieldType}
				isEditable={false}
				onUpdate={noop}
			/>
		);
	}
	if (fieldType === FIELD_TYPES.SINGLE_SELECT) {
		const optionsByKey = keyBy(options, ({ id }) => id);
		const option = optionsByKey[value];
		return option ? <DecoratedTag fieldKey={fieldKey} id={option.id} value={option.value} /> : null;
	}
	if (fieldType === FIELD_TYPES.STATUS && typeof value === 'string') {
		return <StatusFieldLabel groupIdentity={value} />;
	}
	if (
		(fieldType === FIELD_TYPES.REPORTER ||
			fieldType === FIELD_TYPES.CREATOR ||
			fieldType === FIELD_TYPES.ASSIGNEE) &&
		typeof value === 'string'
	) {
		return <UserFieldValue fieldKey={fieldKey} accountId={value} rotate={false} />;
	}
	return (
		<StringField value={String(value)} fieldType={fieldType} isEditable={false} onUpdate={noop} />
	);
};

const Row = ({ value, fieldKey }: ValueProps) => {
	const fieldName = useFieldLabel(fieldKey);
	return (
		<RowContainer>
			<FieldName>{fieldName}:</FieldName>
			<Value fieldKey={fieldKey} value={value} />
		</RowContainer>
	);
};

export const BubbleTooltip = ({ itemIds, xValue, yValue, tooltipTitle }: DnDTooltipProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const itemSummary = useSummary(itemIds[0]);
	const selectedXAxisField = useCurrentViewXAxis();
	const selectedYAxisField = useCurrentViewYAxis();
	const analyticsData = useIssueAnalitycsAttributes(itemIds[0]);
	const localIssueIdToJiraIssueIdMapper = useLocalIssueIdToJiraIssueIdMapper();

	useEffect(() => {
		fireCompoundAnalyticsEvent.MatrixBubbleTooltipDisplayed(
			createAnalyticsEvent({}),
			itemIds.length > 1
				? {
						bubbleType: 'clustered',
						clusteredIssueIds: itemIds
							.map(localIssueIdToJiraIssueIdMapper)
							.filter((id: string | undefined): id is string => !!id),
					}
				: {
						bubbleType: 'single',
						...analyticsData,
					},
		);
	}, [createAnalyticsEvent, analyticsData, itemIds, localIssueIdToJiraIssueIdMapper]);

	return (
		<div>
			<Summary>{tooltipTitle !== undefined ? tooltipTitle : itemSummary}</Summary>
			{selectedYAxisField !== undefined && <Row value={yValue} fieldKey={selectedYAxisField} />}
			{selectedXAxisField !== undefined && <Row value={xValue} fieldKey={selectedXAxisField} />}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	overflow: 'hidden',
	maxHeight: '38px',
	boxSizing: 'border-box',
	maxWidth: '240px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	fontWeight: token('font.weight.medium', '500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldName = styled.div({
	width: '120px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.UNSAFE_small', fontFallback.body.UNSAFE_small),
	fontWeight: token('font.weight.medium', '500'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});
