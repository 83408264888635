import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetTransitionForStatus } from '../../../workflow/selectors/transitions';
import type { IssueTypeTransitionConfig } from '../../../workflow/types';
import { getFilteredIssueIds } from '../../selectors/filters';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids';
import {
	createGetIssueType,
	createGetStatus,
	createGetKeySelector,
	createGetIssueAnalyticsAttributes,
} from '../../selectors/properties';
import { getSortedIssueIds } from '../../selectors/sort';
import { getCurrentViewSelectedIssueIds } from '../../selectors/view';
import type { State, Props } from '../../types';
import { incrementOpenUpdateCounter } from '../real-time';

export type StatusUpdateFunction<TFieldValueType> = (
	fieldKey: FieldKey,
	appendMultiValues: boolean,
	localIssueId: LocalIssueId,
	newValue: TFieldValueType | undefined,
	removeValue: TFieldValueType | undefined,
	// Note: the purpose of this parameter is to allow this function to be called in callbacks with the actual state.
	// If not provided
	overrideState: State | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSuccess?: (arg1?: any) => void,
	onError?: (arg1: Error) => void,
) => {
	filtered: boolean | undefined;
};

const mutateStatusState = (
	{ getState, setState }: StoreActionApi<State>,
	fieldKey: FieldKey,
	localIssueId: LocalIssueId,
	status: StatusFieldValue,
) => {
	const newState = {
		...getState(),
		lastUpdatedIssueIds: [localIssueId],
		properties: {
			...getState().properties,
			status: {
				...getState().properties.status,
				[fieldKey]: {
					...getState().properties.status[fieldKey],
					[localIssueId]: status,
				},
			},
		},
	};
	setState(newState);
	return newState;
};

const isTransitionForStatusAllowed = (
	id: LocalIssueId,
	newStatus: StatusValue,
	state: State,
	props: Props,
) => {
	const issueType = createGetIssueType(id)(state, props);
	const oldStatus = createGetStatus(id)(state, props);

	if (issueType === undefined || oldStatus === undefined) {
		return false;
	}

	const getTransitionForStatus = createGetTransitionForStatus(
		issueType.id,
		oldStatus,
		// @ts-expect-error - TS2345 - Argument of type 'Status' is not assignable to parameter of type 'IssueStatusProperty'.
		newStatus,
	);
	const transition = getTransitionForStatus({
		transitions: props.workflowTransitions,
	});

	return oldStatus.id === newStatus.id || !!transition;
};

export const updateStatusInStateOnly =
	(fieldKey: FieldKey, localIssueId: LocalIssueId, status: StatusFieldValue) =>
	(storeActionApi: StoreActionApi<State>) =>
		mutateStatusState(storeActionApi, fieldKey, localIssueId, status);

export const updateStatus =
	(
		fieldKey: FieldKey,
		localIssueId: LocalIssueId,
		transition: IssueTypeTransitionConfig,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSuccess?: (arg1?: any) => void,
		onError?: (arg1: Error) => void,
	) =>
	(storeActionApi: StoreActionApi<State>, props: Props) => {
		const state = { ...storeActionApi.getState() };
		const issueKey = createGetKeySelector(localIssueId)(state);
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		const newState = mutateStatusState(storeActionApi, fieldKey, localIssueId, transition.to);

		storeActionApi.dispatch(incrementOpenUpdateCounter([localIssueId]));
		props.issuesRemote
			.transitionIssue({ issueKey, transition })
			.then(() => {
				onSuccess && onSuccess();
				fireAnalyticsEventForIssueUpdate(props.createAnalyticsEvent({}), localIdMap[localIssueId], {
					updatedItems: [{ name: fieldKey }],
					...createGetIssueAnalyticsAttributes(localIssueId)(storeActionApi.getState()),
				});
			})
			.catch((e) => onError && onError(e));

		const filteredIdsAfterStateChange = getFilteredIssueIds(newState, props);
		return {
			filtered: !filteredIdsAfterStateChange.includes(localIssueId),
		};
	};

type UpdateStatusInStateOnlyWithBulkProps = {
	localIssueId: LocalIssueId;
	fieldKey: FieldKey;
	oldStatus: StatusValue;
	newStatus: StatusValue;
	updateField: StatusUpdateFunction<StatusValue>;
	onNotAllowedTransitions: (issueKeys: string[]) => void;
};

export const updateStatusInStateOnlyWithBulk =
	({
		localIssueId,
		fieldKey,
		oldStatus,
		newStatus,
		updateField,
		onNotAllowedTransitions,
	}: UpdateStatusInStateOnlyWithBulkProps) =>
	({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { createAnalyticsEvent } = props;

		const selectedIssueIds = getCurrentViewSelectedIssueIds(state);
		const sortedIssueIds = getSortedIssueIds(state, props);
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		const visibleSelectedIssueIds = sortedIssueIds.filter((id) => selectedIssueIds.includes(id));

		// the status value type we get from the field is incomplete even though it contains
		// all the field that we need. that is why we have to resort to a type check
		// failure ignore annotations
		// @ts-expect-error - TS2345 - Argument of type 'Status' is not assignable to parameter of type 'IssueStatusProperty'.
		dispatch(updateStatusInStateOnly(fieldKey, localIssueId, newStatus));

		const isBulkEditingOperation = visibleSelectedIssueIds.indexOf(localIssueId) !== -1;

		// if bulk editing is enabled, update all other selected ideas and try to updated the status of each idea
		if (isBulkEditingOperation) {
			const notAllowedIssueKeys: string[] = [];
			const otherLocalIssueIds = visibleSelectedIssueIds.filter((id) => {
				if (localIssueId === id) return false;

				const isStatusTransitionAllowed = isTransitionForStatusAllowed(id, newStatus, state, props);

				if (!isStatusTransitionAllowed) {
					const key = createGetKeySelector(id)(state);
					notAllowedIssueKeys.push(key);
				}

				return isStatusTransitionAllowed;
			});

			otherLocalIssueIds.forEach((id) => {
				const oldOtherIssueStatus = state.properties.status?.status?.[id];
				// @ts-expect-error - TS2345 - Argument of type 'Status' is not assignable to parameter of type 'IssueStatusProperty'.
				dispatch(updateStatusInStateOnly(fieldKey, id, newStatus));

				fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), localIdMap[id], {
					updatedItems: [
						{
							name: 'status',
							oldValue: {
								id: oldOtherIssueStatus?.id,
								name: oldOtherIssueStatus?.name,
							},
							newValue: {
								id: newStatus?.id,
								name: newStatus?.name,
							},
						},
					],
					action: 'bulk',
					...createGetIssueAnalyticsAttributes(id)(state),
				});
			});

			const updateOperations = otherLocalIssueIds.map(
				(id) => () =>
					new Promise((resolve, reject) => {
						updateField(
							fieldKey,
							true,
							id,
							newStatus,
							undefined,
							state,
							() => resolve(undefined),
							(e) => reject(e),
						);
					}),
			);
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			runInBatch(updateOperations, 5).then(() => {});

			if (notAllowedIssueKeys.length) {
				onNotAllowedTransitions(notAllowedIssueKeys);
			}
		}

		fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), localIdMap[localIssueId], {
			updatedItems: [
				{
					name: 'status',
					oldValue: {
						id: oldStatus?.id,
						name: oldStatus?.name,
					},
					newValue: {
						id: newStatus?.id,
						name: newStatus?.name,
					},
				},
			],
			action: isBulkEditingOperation ? 'bulk' : 'single',
			...createGetIssueAnalyticsAttributes(localIssueId)(state),
		});
	};
