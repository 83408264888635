import React, { type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { Radio } from '@atlaskit/radio';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { IntervalFieldFilterOperator } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { filters } from '../utils';
import type { UiFilter } from '../utils/types';

const NumberPicker = ({
	value,
	onChange,
}: {
	value: number | undefined;
	onChange: (arg1: number | undefined) => void;
}) => (
	<TextField
		type="number"
		value={value}
		onChange={(event: ChangeEvent<HTMLInputElement>) => {
			onChange(Number(event.target.value));
		}}
	/>
);

type Props = {
	values: IntervalFieldFilterOperator[];
	onChange: (values: IntervalFieldFilterOperator[]) => void;
};
export const IntervalFilterContentContainer = ({ values, onChange }: Props) => {
	const { formatMessage } = useIntl();
	const value = Math.max((values.length > 0 ? values[0].numericValue : 0) || 0, 0);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ContentContainer>
			{filters.map((filter: UiFilter) => (
				<React.Fragment key={filter.id}>
					<Radio
						id={filter.id}
						name={filter.id}
						value={filter.id}
						label={formatMessage(filter.label)}
						isChecked={filter.isChecked(values)}
						onChange={() => {
							fireCompoundAnalyticsEvent.NavSidebarArchiveViewClicked(
								createAnalyticsEvent({ filter: filter.id }),
							);
							onChange(filter.getValues(value) || []);
						}}
					/>
					{filter.isChecked(values) && filter.hasParameter && (
						<NumberPicker
							value={value}
							onChange={(nv) => onChange(filter.getValues(Math.max(nv || 0, 0)) || [])}
						/>
					)}
				</React.Fragment>
			))}
		</ContentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '180px',
	maxWidth: '180px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		marginBottom: token('space.025', '2px'),
	},
});
