import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getCurrentRouteSelector, getPolarisQuerySelector } from '../../selectors';
import type { PolarisRoute, RouteToParams, State, Props } from '../../types';
import debouncedRouterActions from '../../utils/debounced-router-actions';
import { createQueryStringPostfix } from '../../utils/query.tsx';
import { setStorageRouteSlug } from '../../utils/storage.tsx';

export const routeTo =
	(route: PolarisRoute, params?: RouteToParams) =>
	(
		{ getState }: StoreActionApi<State>,
		{ getAppUrl, storageKey, useNativeReplaceMethod }: Props,
	) => {
		const routeParams = {
			clearParameters: true,
			replaceInHistory: false,
			saveStorageRouteSlug: true,
			...params,
		};
		const { clearParameters, replaceInHistory, saveStorageRouteSlug } = routeParams;
		const currentRoute = getCurrentRouteSelector(getState());
		const currentQuery = getPolarisQuerySelector(getState());
		if (route.section === currentRoute.section && route.resource === currentRoute.resource) {
			// same route, early exit
			return;
		}
		const url = `${getAppUrl(route)}${
			clearParameters ? '' : createQueryStringPostfix(currentQuery)
		}`;
		if (replaceInHistory) {
			useNativeReplaceMethod(url);
		} else {
			debouncedRouterActions.push(getAppUrl(route), clearParameters ? {} : undefined);
		}
		if (saveStorageRouteSlug && storageKey !== undefined) {
			setStorageRouteSlug({ ...route, storageKey });
		}
	};
