import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type { ChainableContextProvider } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { notImplementedError } from '../common/utils';
import type { InsightsRemote, ProviderProps } from '../types';
import { archiveInsights, unarchiveInsights } from './archive-insights';
import { copyInsights } from './copy-insights';
import { createInsight } from './create';
import { deleteInsight } from './delete';
import { fetch, fetchInsight } from './fetch';
import { fetchInsightsForIssue } from './fetch-insights-for-issue';
import { fetchInsightsWithErrors } from './fetch-insights-with-errors';
import { fetchInsightsWithSnippetData } from './fetch-insights-with-snippet-data';
import { fetchProjectConfigInsights } from './fetch-project-config-insights';
import { invokeObject } from './invoke-object';
import { refreshSnippet, refreshSnippets } from './refresh-snippets';
import { multiProjectsResolveObject, resolveObject } from './resolve-object';
import {
	fetchSnippetPropertiesConfig,
	updateSnippetPropertiesConfig,
} from './snippet-properties-config';
import { updateInsight } from './update';

const { RemoteContextProvider, useRemoteFromContext } =
	createRemoteContext<InsightsRemote>('insight');

export const createNotImplementedInsightsRemote = (): InsightsRemote => ({
	fetch: notImplementedError('fetch'),
	fetchInsight: notImplementedError('fetchInsight'),
	createInsight: notImplementedError('createInsight'),
	deleteInsight: notImplementedError('deleteInsight'),
	updateInsight: notImplementedError('updateInsight'),
	refreshSnippet: notImplementedError('refreshSnippet'),
	refreshSnippets: notImplementedError('refreshSnippets'),
	copyInsights: notImplementedError('copyInsights'),
	archiveInsights: notImplementedError('archiveInsights'),
	unarchiveInsights: notImplementedError('unarchiveInsights'),
	fetchSnippetPropertiesConfig: notImplementedError('fetchSnippetPropertiesConfig'),
	updateSnippetPropertiesConfig: notImplementedError('updateSnippetPropertiesConfig'),
	resolveObject: notImplementedError('resolveObject'),
	invokeObject: notImplementedError('invokeObject'),
	fetchInsightsWithErrors: notImplementedError('fetchInsightsWithErrors'),
	fetchInsightsWithSnippetData: notImplementedError('fetchInsightsWithSnippetData'),
	fetchInsightsForIssue: notImplementedError('fetchInsightsForIssue'),
	fetchProjectConfigInsights: notImplementedError('fetchProjectConfigInsights'),
});

const createNoopInsightsRemote = (): InsightsRemote => ({
	fetch: () => Promise.resolve({ insights: [], labels: [], providers: [] }),
	fetchInsight: undefined,
	createInsight: undefined,
	deleteInsight: undefined,
	updateInsight: undefined,

	refreshSnippet: undefined,
	refreshSnippets: undefined,

	copyInsights: undefined,
	archiveInsights: undefined,
	unarchiveInsights: undefined,

	fetchSnippetPropertiesConfig: undefined,
	updateSnippetPropertiesConfig: undefined,

	resolveObject: undefined,
	invokeObject: undefined,

	fetchInsightsWithErrors: undefined,

	fetchInsightsWithSnippetData: undefined,
	fetchInsightsForIssue: undefined,

	fetchProjectConfigInsights: undefined,
});

export const NoopInsightRemoteProvider: ChainableContextProvider = ({ children }) => {
	const remote = createNoopInsightsRemote();

	return <RemoteContextProvider remote={remote}>{children}</RemoteContextProvider>;
};

const createInsightRemote = (props: ProviderProps): InsightsRemote => ({
	fetch: fetch(props),

	fetchInsight: fetchInsight(props),

	copyInsights: copyInsights(props),

	refreshSnippet: refreshSnippet(props),
	refreshSnippets: refreshSnippets(props),

	fetchInsightsWithErrors: fetchInsightsWithErrors(props),

	fetchInsightsWithSnippetData: fetchInsightsWithSnippetData(props),

	createInsight: createInsight(props),
	updateInsight: updateInsight(props),
	deleteInsight: deleteInsight(props),

	fetchInsightsForIssue: fetchInsightsForIssue(props),
	resolveObject: resolveObject(props),
	invokeObject: invokeObject(props),

	fetchSnippetPropertiesConfig: fetchSnippetPropertiesConfig(props),
	updateSnippetPropertiesConfig: updateSnippetPropertiesConfig(props),

	archiveInsights: archiveInsights(props),
	unarchiveInsights: unarchiveInsights(props),

	fetchProjectConfigInsights: fetchProjectConfigInsights(props),
});

export const createInsightRemoteProvider =
	(projectId: ProjectId): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();
		const insightRemote = useMemo(
			() =>
				projectId !== undefined ? createInsightRemote({ apolloClient, cloudId, projectId }) : null,
			[apolloClient, cloudId],
		);

		if (!insightRemote) {
			return <>{children}</>;
		}

		return <RemoteContextProvider remote={insightRemote}>{children}</RemoteContextProvider>;
	};

const createMulitProjectsInsightRemote = (
	props: Omit<ProviderProps, 'projectId'>,
): InsightsRemote => ({
	...createNotImplementedInsightsRemote(),
	fetch: fetch(props),

	copyInsights: copyInsights(props),

	// is this necessary? https://pi-dev-sandbox.atlassian.net/browse/POL-10843
	refreshSnippets: undefined,

	createInsight: createInsight(props),
	updateInsight: updateInsight(props),
	deleteInsight: deleteInsight(props),

	resolveObject: multiProjectsResolveObject(props),
});

export const createMultiProjectsInsightRemoteProvider =
	(): ChainableContextProvider =>
	({ children, apolloClient }) => {
		const { cloudId } = useTenantContext();
		const insightRemote = useMemo(
			() => createMulitProjectsInsightRemote({ apolloClient, cloudId }),
			[apolloClient, cloudId],
		);

		if (!insightRemote) {
			return <>{children}</>;
		}

		return <RemoteContextProvider remote={insightRemote}>{children}</RemoteContextProvider>;
	};

export const useInsightRemote = useRemoteFromContext;
