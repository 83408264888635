import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useDecorationForNumberValue } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { DecoratedNumber } from '@atlassian/jira-polaris-common/src/ui/decoration/number/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const NumberFieldValue = ({ fieldKey, groupIdentity }: FieldValueComponentProps) => {
	const value = Number(groupIdentity);
	const decoration = useDecorationForNumberValue(fieldKey, value);
	return (
		<Box paddingBlock="space.025">
			<DecoratedNumber value={value} decoration={decoration} />
		</Box>
	);
};

const EmptyNumberFieldValue = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingBlock="space.025" paddingInlineStart="space.050">
			{formatMessage(messages.emptyFieldValue)}
		</Box>
	);
};

export const numberFieldValueConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: NumberFieldValue,
	EmptyComponent: EmptyNumberFieldValue,
} as const;
