import React, { useState } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { SecurityLevel } from '../../common/types';
import DetailsPopup from './details-popup/main.tsx';
import { Dropdown } from './dropdown';
import { MenuItem } from './menu-item';
import type { Props } from './types';

const actionSubjectId = 'securityLevelField';

export const SecurityLevelView = ({ allowedValues, onChange, value, isEditable }: Props) => {
	const [isOpen, setOpen] = useState(false);

	const fireEvent = (analyticsEvent: UIAnalyticsEvent) => {
		if (analyticsEvent) {
			fireUIAnalytics(analyticsEvent, actionSubjectId, {
				allowedValuesLength: allowedValues.length,
				hasEditPermission: isEditable,
			});
		}
	};
	const onChangeOpenState = (open: boolean, analyticsEvent: UIAnalyticsEvent) => {
		setOpen(open);
		fireEvent(analyticsEvent);
	};
	const onChangeCallback = (
		newValue: SecurityLevel,
		meta: null,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		onChange(newValue, null, analyticsEvent);
		fireEvent(analyticsEvent);
	};

	if (!isEditable) {
		const toggleOpen = () => setOpen(!isOpen);

		return (
			value && (
				<DetailsPopup
					trigger={
						<MenuItem isOpen={isOpen} value={value} onClick={toggleOpen} onHover={fireEvent} />
					}
					isOpen={isOpen}
					onChangeOpen={setOpen}
					securityLevel={value}
				/>
			)
		);
	}

	return (
		<Dropdown
			value={value}
			items={allowedValues}
			onChange={onChangeCallback}
			trigger={<MenuItem isOpen={isOpen} value={value} onHover={fireEvent} />}
			onChangeOpenState={onChangeOpenState}
		/>
	);
};
