import { createSelector, createStructuredSelector } from 'reselect';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import { getIssueIdsForDynamicFormula } from '../../selectors/issue-ids';
import type { DynamicPropertyMaps, State, Props, DynamicFieldValuesSelector } from '../../types';
import { getNumberSelectorForFormula, getDateSelectorForFormula } from './resolvers';
import type { DynamicFieldSelectorCreator } from './types';

export const createFullPropertyValuesSelectorInternal = <TPropertyValue,>(
	fieldSelectorCreator: DynamicFieldSelectorCreator<TPropertyValue>,
) => {
	const perIssueSelectorsSelector = createSelector(getIssueIdsForDynamicFormula, (issueIds) =>
		createStructuredSelector<
			State,
			Props | undefined,
			{
				[key: string]: TPropertyValue;
			}
		>(
			issueIds.reduce(
				(result, issueId) =>
					Object.assign(result, {
						[issueId]: fieldSelectorCreator(issueId),
					}),
				{},
			),
		),
	);
	return (state: State, props: undefined | Props) => {
		const perIssueSelectors = perIssueSelectorsSelector(state, props);
		return perIssueSelectors(state, props);
	};
};

export const createFullPropertyValuesSelector = cacheSelectorCreator(
	createFullPropertyValuesSelectorInternal,
);

const EMPTY_SELECTOR = () => undefined;

const EMPTY_SELECTOR_CREATOR = () => EMPTY_SELECTOR;
/**
 * Attempts to apply a resolver to a field. If the resolver returns a selector creator function,
 * it is assumed that that resolver can handle the current dynamic field. That function will be used
 * as the cache filler function for that dynamic field handler.
 */
const applyResolver = (
	maps: DynamicPropertyMaps<State>,
	field: Field,
): DynamicPropertyMaps<State> => {
	if (field.formula === undefined) {
		return maps;
	}
	const selectorCreator = field.hasCyclicFormulaDependency
		? EMPTY_SELECTOR_CREATOR
		: getNumberSelectorForFormula(field.formula, field.play?.id);
	if (selectorCreator === undefined) {
		return maps;
	}
	return {
		...maps,
		numberValue: {
			...maps.numberValue,
			[field.key]: selectorCreator,
		},
		numberValues: {
			...maps.numberValues,
			[field.key]: createFullPropertyValuesSelector(selectorCreator),
		},
	};
};

const applyDatesResolver = (
	maps: DynamicPropertyMaps<State>,
	field: Field,
): DynamicPropertyMaps<State> => {
	if (field.formula === undefined) {
		return maps;
	}
	const selectorCreator = getDateSelectorForFormula(field.formula, field.play?.id);
	if (selectorCreator === undefined) {
		return maps;
	}
	return {
		...maps,
		dateValue: {
			...maps.dateValue,
			[field.key]: selectorCreator,
		},
		dateValues: {
			...maps.dateValues,
			[field.key]: createFullPropertyValuesSelector(selectorCreator),
		},
	};
};

/**
 * Main entry point to initialize dynamic fields.
 * This will create an entry in dynamic property maps for every dynamic field for which a resolver can be
 * found.
 */
export const resolveDynamicFields = (
	fields: Field[],
	maps: DynamicPropertyMaps<State>,
): DynamicPropertyMaps<State> =>
	fields.reduce((merged, field) => applyDatesResolver(applyResolver(merged, field), field), maps);

export const createValueSelectorForFormula = (
	formula: DynamicFieldFormula,
): DynamicFieldValuesSelector<State, number> | undefined => {
	const selectorCreator = getNumberSelectorForFormula(formula);
	if (selectorCreator === undefined) {
		return undefined;
	}
	return createFullPropertyValuesSelector(selectorCreator);
};
