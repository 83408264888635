import type { RecursiveFilter } from '../../filter/types';

export const PROPERTY_AGGREGATION_TEMPLATE = 'property_agg';
export const PROPERTY_SUM = 'sum';

/**
 * Formula for aggregating a given property over the set of data points.
 */
export type PropertyAggregationFormula = {
	template: typeof PROPERTY_AGGREGATION_TEMPLATE;
	parameters: {
		agg: typeof PROPERTY_SUM;
		key: string;
		oauthClientId: string;
		filter?: RecursiveFilter;
	};
};
