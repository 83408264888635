import { styled } from '@compiled/react';
import { N0, N20, N30, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { TIMELINE_COLUMN_WIDTH, TIMELINE_HEADER_HEIGHT } from './constants';

export const backgroundLines = (backgroundColor: string, lineColor: string) => ({
	backgroundColor,
	backgroundImage: `linear-gradient(to bottom, ${backgroundColor} 0 50%, transparent 50% 100%),
        linear-gradient(to right, ${lineColor} 1px 0, transparent 0 100%)`,
	backgroundSize: `${TIMELINE_COLUMN_WIDTH}px 8px`,
	backgroundPosition: '-1px 4px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TimelineViewPlaceholder = styled.div({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '0',
	boxSizing: 'border-box',
	top: '0',
	left: '0',
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
	borderRadius: '8px 8px 0 0',
	overflow: 'hidden',

	'&::before': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface', N0),
		opacity: '0.6',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnPlaceholder = styled.div({
	height: 'auto',
	minHeight: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${TIMELINE_COLUMN_WIDTH}px`,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...backgroundLines(token('color.background.input.hovered', N20), token('color.border', N40)),

	'&::before': {
		content: '',
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${TIMELINE_HEADER_HEIGHT}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...backgroundLines(
			token('color.background.accent.gray.subtlest', N30),
			token('color.border', N40),
		),
	},
});
