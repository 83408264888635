import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	overdue: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.label.overdue',
		defaultMessage: 'Overdue',
		description:
			'Decoration rule for date fields, meaning the actual date is > than the date value of the field.',
	},
	comingSoon: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-content.datetime-decoration.item.label.coming-soon',
		defaultMessage: 'Date is within {days, plural, one {# day} other {# days}}',
		description: 'Label for decoration rule for the number of days from now.',
	},
});
