import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { TooltipPrimitive } from '@atlaskit/tooltip';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Tooltip = styled(TooltipPrimitive)({
	display: 'block',
	boxSizing: 'border-box',
	margin: '0 -3px',
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	borderRadius: token('border.radius.100', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.danger', colors.R400),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.inverse', colors.N0),
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
	),
});
