import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	configureAtlasHelpMessage: {
		id: 'polaris-common.config.fields.field-item.atlas.configure-atlas-help-message',
		defaultMessage: 'You can configure Atlas site mapping in <a>Project settings</a>',
		description: 'Header label for the Atlas connection to goals',
	},
	goalTitle: {
		id: 'polaris-common.config.fields.field-item.atlas.goal-title',
		defaultMessage: 'Show how ideas contribute to shared goals.',
		description: 'Header label for the Atlas connection to goals',
	},
	projectTitle: {
		id: 'polaris-common.config.fields.field-item.atlas.project-title',
		defaultMessage: 'Show how ideas are coming to life.',
		description: 'Header label for the Atlas connection to projects',
	},
	sitesWithAtlas: {
		id: 'polaris-common.config.fields.field-item.atlas.sites-with-atlas',
		defaultMessage: 'Sites with Atlas',
		description: 'Option group label for sites with Atlas product installed',
	},
	otherSites: {
		id: 'polaris-common.config.fields.field-item.atlas.other-sites',
		defaultMessage: 'Other sites',
		description: 'Option group label for other sites ',
	},
	getAtlasButton: {
		id: 'polaris-common.config.fields.field-item.atlas.get-atlas-button',
		defaultMessage: 'Get Atlas (Free)',
		description: 'Button label for Atlas product',
	},
	refreshButton: {
		id: 'polaris-common.config.fields.field-item.atlas.refresh-button',
		defaultMessage: 'Refresh',
		description: 'Button label for refresh action',
	},
	signInToAtlasButton: {
		id: 'polaris-common.config.fields.field-item.atlas.sign-in-to-atlas-button',
		defaultMessage: 'Log into Atlas',
		description: 'Button label for sign in to Atlas product',
	},
	siteSelectNotFoundLabel: {
		id: 'polaris-common.config.fields.field-item.atlas.site-select-not-found-label',
		defaultMessage: 'This site does not have Atlas installed.',
		description: 'Label for site select if Atlas it not found',
	},
	goalActionItem1: {
		id: 'polaris-common.config.fields.field-item.atlas.goal-action-item-1',
		defaultMessage: 'Map ideas in Jira Product Discovery to goals in Atlas',
		description: 'Goal action item',
	},
	goalActionItem2: {
		id: 'polaris-common.config.fields.field-item.atlas.goal-action-item-2',
		defaultMessage: 'See what can be done to achieve an outcome by grouping ideas by goal',
		description: 'Goal action item',
	},
	projectActionItem1: {
		id: 'polaris-common.config.fields.field-item.atlas.project-action-item-1',
		defaultMessage: 'Map ideas in Jira Product Discovery to projects in Atlas',
		description: 'Project action item',
	},
	projectActionItem2: {
		id: 'polaris-common.config.fields.field-item.atlas.project-action-item-2',
		defaultMessage: 'See the status of projects being delivered',
		description: 'Project action item',
	},
	projectActionItem3: {
		id: 'polaris-common.config.fields.field-item.atlas.project-action-item-3',
		defaultMessage: 'See how ideas are progressing by grouping them by project status',
		description: 'Project action item',
	},
	learnMoreAboutGoals: {
		id: 'polaris-common.config.fields.field-item.atlas.learn-more-about-goals',
		defaultMessage: 'Learn more about Atlas goals',
		description: 'Learn more about goals hint',
	},
	learnMoreAboutProjects: {
		id: 'polaris-common.config.fields.field-item.atlas.learn-more-about-projects',
		defaultMessage: 'Learn more about Atlas projects',
		description: 'Learn more about Atlas projects',
	},
	weDontPlanUseAtlas: {
		id: 'polaris-common.config.fields.field-item.atlas.we-dont-plan-use-atlas',
		defaultMessage: "We don't plan to use Atlas",
		description: 'Label for button that will disabled Atlas fields for project',
	},
	atlasGoalSectionTitle: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-goal-section-title',
		defaultMessage: 'Map ideas to goals in Atlas',
		description: 'Title for Atlas goals section message',
	},
	atlasProjectSectionTitle: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-project-section-title',
		defaultMessage: 'Map ideas to projects in Atlas',
		description: 'Title for Atlas project section message',
	},
	atlasGoalSectionDescription: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-goal-section-description',
		defaultMessage:
			'Connect a specific Atlas site to map ideas in Jira Product Discovery to goals in Atlas.',
		description: 'Description for Atlas goals section message',
	},
	atlasProjectSectionDescription: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-project-section-description',
		defaultMessage:
			'Connect a specific Atlas site to map ideas in Jira Product Discovery to projects in Atlas.',
		description: 'Description for Atlas project section message',
	},
	atlasSectionConfigurationMessage: {
		id: 'polaris-common.config.fields.field-item.atlas.atlas-section-configuration-message',
		defaultMessage: 'Configuration for Atlas fields can now be done in project settings.',
		description: 'Description for Atlas section message',
	},
	configureButton: {
		id: 'polaris-common.config.fields.field-item.atlas.configure-button',
		defaultMessage: 'Configure',
		description: 'Label for button that will redirect to project settings',
	},
});
