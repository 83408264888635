import type { ShareAsCommentState } from '@atlassian/jira-issue-view-common-types/src/share-as-comment-type';
import {
	type ClearSharedAsCommentValuesAction,
	type ShareAsCommentAction,
	SHARE_AS_COMMENT,
	CLEAR_SHARED_AS_COMMENT_VALUES,
} from '../actions/share-as-comment-actions';

type Action = ShareAsCommentAction | ClearSharedAsCommentValuesAction;

const initialState = {
	timestamp: 0,
	value: undefined,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ShareAsCommentState = initialState, action: Action) => {
	switch (action.type) {
		case SHARE_AS_COMMENT:
			return {
				timestamp: Date.now(),
				value: action.payload.value,
			};
		case CLEAR_SHARED_AS_COMMENT_VALUES:
			return {
				timestamp: Date.now(),
			};
		default:
			return state;
	}
};
