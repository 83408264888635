import React, { useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { endOfMonth, isAfter, isBefore, startOfMonth } from 'date-fns';
import Button, { CustomThemeButton } from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/glyph/chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { Box, xcss } from '@atlaskit/primitives';
import { N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { CalendarProps, CalendarState } from '../../../../common/types/date-picker';
import { format } from '../../../../common/utils/date/date';
import { formatIsoLocalDate } from '../../../../common/utils/date/date-format';
import { getInitialMonthPickerDate } from '../../../../common/utils/date/date-picker';
import { pickerButtonStyle } from '../../utils';

const MONTHS_DATA = (() => {
	const today = new Date();
	const months = [];
	for (let i = 0; i < 12; i++) {
		months.push({
			month: i,
			name: format(new Date(today.getFullYear(), i), 'MMM'),
		});
	}
	return months;
})();

const MonthPicker = (props: CalendarProps) => {
	const [date, setDate] = useState(getInitialMonthPickerDate(props));

	const { selectedDate, dateType, onSelect } = props;
	const { month, year, initialYear } = date;

	const onDateChange = useCallback(
		(newDate: CalendarState) => {
			setDate(newDate);
			const newDateObject = new Date(newDate.year, newDate.month);
			const newDateInterval = {
				start: formatIsoLocalDate(startOfMonth(newDateObject)),
				end: formatIsoLocalDate(endOfMonth(newDateObject)),
			};
			onSelect(newDateInterval);
		},
		[onSelect],
	);

	const isMonthDisabled = useCallback(
		(isSelected: boolean, monthItem: number) => {
			if (!selectedDate) {
				return false;
			}
			const currentDate = new Date(year, monthItem);
			return (
				!isSelected &&
				(dateType === 'start'
					? isBefore(selectedDate, currentDate)
					: isAfter(selectedDate, currentDate))
			);
		},
		[dateType, selectedDate, year],
	);

	return (
		<Box xcss={monthPickerWrapperStyles}>
			<YearPickerContainer>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={() => setDate({ initialYear, month, year: year - 1 })}
					iconAfter={
						<ChevronLeftLargeIcon
							label="See previous year"
							primaryColor={token('color.icon.subtle', N70)}
						/>
					}
				/>
				<div>
					<b>{year}</b>
				</div>
				<Button
					appearance="subtle"
					spacing="none"
					onClick={() => setDate({ initialYear, month, year: year + 1 })}
					iconAfter={
						<ChevronRightLargeIcon
							label="See next year"
							primaryColor={token('color.icon.subtle', N70)}
						/>
					}
				/>
			</YearPickerContainer>
			<MonthPickerContainer>
				{MONTHS_DATA.map((item) => {
					const isSelected = date.initialYear === year && month === item.month;
					return (
						<CustomThemeButton
							key={item.month}
							appearance="subtle"
							onClick={() => onDateChange({ initialYear, year, month: item.month })}
							isSelected={isSelected}
							isDisabled={isMonthDisabled(isSelected, item.month)}
							theme={pickerButtonStyle(isSelected)}
						>
							{item.name}
						</CustomThemeButton>
					);
				})}
			</MonthPickerContainer>
		</Box>
	);
};

export default MonthPicker;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const YearPickerContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingLeft: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MonthPickerContainer = styled.div({
	marginTop: token('space.200', '16px'),
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-around',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *, > button': {
		flex: '0 0 24%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: '70px !important',
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginBottom: '5px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button > span': {
		alignSelf: 'center',
	},
});

const monthPickerWrapperStyles = xcss({
	padding: 'space.200',
});
