import { defineMessages } from '@atlassian/jira-intl';
import {
	AVG_ROLLUP,
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
	MAX_ROLLUP,
	RANGE_ROLLUP,
	MIN_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';

export default defineMessages({
	/* eslint-disable jira/i18n/id-named-by-package */
	none: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.none',
		defaultMessage: 'None',
		description: 'Dropdown option for clearing the value',
	},
	[AVG_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.avg-rollup',
		defaultMessage: 'Average',
		description: 'Dropdown option for averaging the values',
	},
	[COUNT_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.count-rollup',
		defaultMessage: 'Count',
		description: 'Dropdown option for counting the values',
	},
	[EMPTY_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.empty-rollup',
		defaultMessage: 'Empty',
		description: 'Dropdown option for counting the empty values',
	},
	[FILLED_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.filled-rollup',
		defaultMessage: 'Filled',
		description: 'Dropdown option for counting the filled values',
	},
	[MAX_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.max-rollup',
		defaultMessage: 'Max',
		description: 'Dropdown option for calculating the max of the values',
	},
	[MEDIAN_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.median-rollup',
		defaultMessage: 'Median',
		description: 'Dropdown option for calculating the median of the values',
	},
	[MIN_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.min-rollup',
		defaultMessage: 'Min',
		description: 'Dropdown option for calculating the min of the values',
	},
	[SUM_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.sum-rollup',
		defaultMessage: 'Sum',
		description: 'Dropdown option for summing the values',
	},
	[RANGE_ROLLUP]: {
		id: 'polaris-ideas.view-content.idea-list.cell.utils.dropdown-group-cell.range-rollup',
		defaultMessage: 'Range',
		description: 'Dropdown option for calculating the range of the values',
	},
	/* eslint-enable jira/i18n/id-named-by-package */
});
