import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { N900 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type UserFieldLoadingProps = {
	name: string;
};

export const UserFieldLoading = memo<UserFieldLoadingProps>(({ name }: UserFieldLoadingProps) => (
	<Wrapper>
		<Skeleton />
		<Container>
			<Text>{name}</Text>
		</Container>
	</Wrapper>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	alignItems: 'center',
	border: '2px solid transparent',
	boxSizing: 'border-box',
	color: 'inherit',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: 'inherit',
	fontStyle: 'normal',
	fontWeight: 'normal',
	lineHeight: 1,
	outline: 'none',
	margin: 0,
	padding: token('space.050', '4px'),
	textAlign: 'left',
	textDecoration: 'none',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	minWidth: 0,
	maxWidth: '100%',
	paddingLeft: token('space.100', '8px'),
	flex: '1 1 100%',
	lineHeight: '1.4',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.div({
	display: 'block',
	margin: 0,
	color: token('color.text', N900),
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Skeleton = styled.div({
	width: '16px',
	height: '16px',
	minHeight: '16px',
	minWidth: '16px',
	display: 'inline-block',
	borderRadius: '50%',
	backgroundColor: 'currentcolor',
	border: '2px solid transparent',
	opacity: 0.15,
});
