import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { unfurl } from '../unfurl';

export const handleAuthSuccess =
	(authToken?: string): Action<State, Props> =>
	({ setState, dispatch }) => {
		setState({
			resolvedObject: undefined,
			authToken,
		});

		dispatch(unfurl());
	};
