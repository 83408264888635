import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ButtonSkeleton } from '@atlassian/jira-polaris-common/src/common/ui/skeleton';
import { useIsViewsLoading } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/meta-hooks';
import { useIssueLinkingEnabled } from '@atlassian/jira-polaris-component-environment-tenant';
import { useCanLinkIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { AttachButton } from './attach';
import { LinkButton } from './link';

type Props = {
	showLabels?: boolean;
	onLinkIssue: () => void;
};

export const Controls = memo<Props>(({ onLinkIssue, showLabels = false }: Props) => {
	const canLinkIssues = useCanLinkIssues();
	const issueLinkingEnabled = useIssueLinkingEnabled();
	const isViewsLoading = useIsViewsLoading();

	if (isViewsLoading) {
		return (
			<Container>
				<ButtonContainer>
					<ButtonSkeleton width={showLabels ? '146px' : '36px'} />
				</ButtonContainer>
				<ButtonContainer>
					<ButtonSkeleton width={showLabels ? '106px' : '36px'} />
				</ButtonContainer>
			</Container>
		);
	}

	return (
		<Container>
			<ButtonContainer>
				<AttachButton showLabel={showLabels} />
			</ButtonContainer>
			{canLinkIssues && issueLinkingEnabled && (
				<ButtonContainer>
					<LinkButton showLabel={showLabels} onClicked={onLinkIssue} />
				</ButtonContainer>
			)}
		</Container>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonContainer = styled.span({
	marginRight: token('space.050', '4px'),
});
