'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function loading(image) {
  return {
    image: image,
    isLoaded: false,
    isErrored: false
  };
}

function loaded() {
  return {
    isLoaded: true
  };
}

function errored() {
  return {
    isErrored: true
  };
}

function hasPropsChanged(prevProps, nextProps) {
  var prevSrc = prevProps.src;
  var nextSrc = nextProps.src;

  return prevSrc !== nextSrc;
}

var ImageRenderer = function (_React$Component) {
  _inherits(ImageRenderer, _React$Component);

  function ImageRenderer() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, ImageRenderer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ImageRenderer.__proto__ || Object.getPrototypeOf(ImageRenderer)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isLoaded: false,
      isErrored: false
    }, _this.handleLoad = function () {
      var onLoad = _this.props.onLoad;

      _this.unload();
      _this.setState(loaded(), function () {
        if (onLoad) {
          onLoad();
        }
      });
    }, _this.handleError = function () {
      var onError = _this.props.onError;

      _this.unload();
      _this.setState(errored(), function () {
        if (onError) {
          onError();
        }
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(ImageRenderer, [{
    key: 'load',
    value: function load() {
      var src = this.props.src;
      var image = this.state.image;


      if (image) {
        image.onload = this.handleLoad;
        image.onerror = this.handleError;
        image.src = src;
      }
    }
  }, {
    key: 'unload',
    value: function unload() {
      var image = this.state.image;

      if (image) {
        image.onload = undefined;
        image.onerror = undefined;
      }
    }
  }, {
    key: 'componentWillMount',
    value: function componentWillMount() {
      if (typeof window === 'undefined') {
        return;
      }
      this.setState(loading(new Image()));
    }
  }, {
    key: 'componentDidMount',
    value: function componentDidMount() {
      this.load();
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      if (hasPropsChanged(this.props, nextProps)) {
        this.setState(loading(new Image()));
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      if (hasPropsChanged(prevProps, this.props)) {
        this.unload();
        this.load();
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      this.unload();
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          loading = _props.loading,
          loaded = _props.loaded,
          errored = _props.errored,
          children = _props.children;
      var _state = this.state,
          image = _state.image,
          isLoaded = _state.isLoaded,
          isErrored = _state.isErrored;


      if (isLoaded && loaded) {
        return loaded;
      }

      if (isErrored && errored) {
        return errored;
      }

      if (!isLoaded && !isErrored && loading) {
        return loading;
      }

      if (children) {
        return children({
          image: isLoaded ? image : undefined,
          loaded: isLoaded,
          errored: isErrored
        });
      }

      return null;
    }
  }]);

  return ImageRenderer;
}(React.Component);

exports.default = ImageRenderer;