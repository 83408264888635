import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import {
	type AssociatedIssue,
	type IssueLinkUIState,
	REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID,
	type RemoteIssueLink,
	type SaveRemoteIssueLinkPayload,
	type ServerRemoteAssociatedIssue,
	type ServerRemoteIssueLink,
} from '@atlassian/jira-issue-shared-types';
import {
	toHref,
	toIssueId,
	toIssueKey,
	toRemoteLinkGlobalId,
} from '@atlassian/jira-shared-types/src';

const initialUIState: IssueLinkUIState = {
	isLoading: false,
	hasError: false,
	isSaved: false,
	isDeleted: false,
	isOptimistic: false,
};

const transformAssociatedIssue = (
	associatedIssue: ServerRemoteAssociatedIssue,
	issueLink: string,
): AssociatedIssue => ({
	id: associatedIssue.issueId.toString(),
	issueKey: associatedIssue.issueKey.stringValue,
	issueSummary: associatedIssue.summary.textValue,
	statusCategoryId: associatedIssue.status?.statusCategoryId,
	statusCategory: statusCategoryForId(associatedIssue.status?.statusCategoryId),
	issueLink: toHref(issueLink),
	statusName: associatedIssue.status?.name,
	statusId: associatedIssue.status?.statusId,
	issuePriorityUrl: associatedIssue.priority && toHref(associatedIssue.priority.iconUrl),
	issuePriorityName: associatedIssue.priority?.name ?? null,
	issuePrioritySequence: associatedIssue.priority?.sequence ?? undefined,
	issueTypeIconUrl: toHref(associatedIssue.issueType.iconUrl),
	issueTypeName: associatedIssue.issueType.name,
	assigneeDisplayName: associatedIssue.assignee?.userValue?.displayName,
	assigneeUrl: associatedIssue.assignee?.userValue
		? toHref(associatedIssue.assignee.userValue.avatarUrl)
		: null,
});

export const transformServerRemoteIssueLinks = (
	serverRemoteIssues: ServerRemoteIssueLink[],
): RemoteIssueLink[] =>
	// @ts-expect-error - TS2322 - Type '{ isLoading: boolean; hasError?: boolean | undefined; isSaved?: boolean | undefined; isDeleted?: boolean | undefined; isOptimistic?: boolean | undefined; id: string; globalId: string | undefined; ... 5 more ...; remoteIssueId: string; }[]' is not assignable to type 'RemoteIssueLink[]'.
	serverRemoteIssues.map((issue) => {
		const { preview, details } = issue;
		const isLoading = !details;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let flattenedDetails: Record<string, any> = {};
		if (!details) {
			flattenedDetails = { issueDetails: null, remoteIssueLinkError: null };
			// @ts-expect-error - TS2339 - Property 'error' does not exist on type 'ServerRemoteIssueLinkDetails'.
		} else if (!details.error) {
			flattenedDetails = {
				// @ts-expect-error - TS2339 - Property 'associatedIssue' does not exist on type 'ServerRemoteIssueLinkDetails'.
				issueDetails: transformAssociatedIssue(details.associatedIssue, preview.href),
				remoteIssueLinkError: null,
			};
		} else {
			flattenedDetails = {
				issueDetails: null,
				remoteIssueLinkError: details,
			};
		}
		return {
			// from preview
			id: preview.id.toString(),
			globalId: preview.globalId === undefined ? undefined : toRemoteLinkGlobalId(preview.globalId),
			applicationType: preview.applicationType,
			applicationName: preview.applicationName,
			issueLinkUrl: toHref(preview.href),
			relationship: preview.relationship,
			issueKey: toIssueKey(preview.title),
			remoteIssueId: toIssueId(preview.issueId.toString()),
			// from details
			...flattenedDetails,
			// UI state
			...initialUIState,
			isLoading,
		};
	});

export const transformOptimisticRemoteIssueLink = (
	saveRemoteIssueLinkPayload: SaveRemoteIssueLinkPayload,
): RemoteIssueLink => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let remoteIssueLinkPreview: Record<string, any> = {};
	if (saveRemoteIssueLinkPayload.payloadType === REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID) {
		const { payloadType, ...rest } = saveRemoteIssueLinkPayload;
		remoteIssueLinkPreview = rest;
	} else {
		const { payloadType, ...rest } = saveRemoteIssueLinkPayload;
		remoteIssueLinkPreview = {
			...rest,
			remoteIssueId: toIssueId(`OPTIMISTIC_ISSUE_ID_${Date.now()}`),
		};
	}
	const optimisticUIState = {
		...initialUIState,
		isLoading: true,
		isOptimistic: true,
	};
	// @ts-expect-error - TS2322 - Type '{ isLoading: boolean; isOptimistic: boolean; hasError?: boolean | undefined; isSaved?: boolean | undefined; isDeleted?: boolean | undefined; issueDetails: null; remoteIssueLinkError: null; }' is not assignable to type 'RemoteIssueLink'.
	return {
		...remoteIssueLinkPreview,
		issueDetails: null,
		remoteIssueLinkError: null,
		...optimisticUIState,
	};
};
