import React, { type FC } from 'react';
import AKProfileCardResourced, {
	type ProfileCardAction,
	type ProfileClient,
} from '@atlaskit/profilecard';
import { useFlagsService, AkFlag, toFlagId } from '@atlassian/jira-flags';
import type { FlagCustomRenderProps } from '@atlassian/jira-flags/src/services/types';
import { isCustomerAccount } from '../../../utils';

type Props = {
	accountId: string;
	cloudId: string;
	actions: ProfileCardAction[];
	profileClient: ProfileClient;
	onProfileClick?: (arg1: string) => void;
};

export const ProfileCardResourcedNew: FC<Props> = ({
	cloudId,
	profileClient,
	accountId,
	actions,
	onProfileClick,
}: Props) => {
	const { showFlag, dismissFlag } = useFlagsService();
	const onReportingLinesClick = onProfileClick
		? // @ts-expect-error - TS2304 - Cannot find name 'ReportingLinesUser'.
			(user) => onProfileClick(user.accountIdentifier)
		: undefined;
	const profileCardProps = {
		userId: accountId,
		cloudId,
		actions: isCustomerAccount(accountId) ? [] : actions,
		resourceClient: profileClient,
		onReportingLinesClick,
	};

	return (
		<AKProfileCardResourced
			{...profileCardProps}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			addFlag={(flag: any) => {
				const flagId = toFlagId(flag.id);
				showFlag({
					id: flagId,
					render: (props: FlagCustomRenderProps) => (
						<AkFlag
							{...flag}
							onDismissed={() => {
								dismissFlag(flagId);
							}}
							{...props}
						/>
					),
				});
			}}
		/>
	);
};

const ProfileCardResourced: typeof ProfileCardResourcedNew = ProfileCardResourcedNew;
export default ProfileCardResourced;
