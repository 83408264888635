import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noDateSet: {
		id: 'polaris-common.date-picker.fuzzy-date.no-date-set',
		defaultMessage: 'No date set',
		description: 'Message to show when no date is set',
	},
	clearDate: {
		id: 'polaris-common.date-picker.fuzzy-date.clear-date',
		defaultMessage: 'Clear date',
		description: 'Label for button to clear date field',
	},
});
