import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../types';
import { deleteInsight } from './delete-insight';

export const toogleInsightSeletion =
	(insight: string) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const { selectedInsights } = getState();
		const newSelectedInsights = { ...selectedInsights };
		if (newSelectedInsights[insight] === true) {
			delete newSelectedInsights[insight];
		} else {
			newSelectedInsights[insight] = true;
		}
		if (!Object.keys(newSelectedInsights).length) {
			setState({
				selectedInsights: {},
			});
			return;
		}
		setState({
			selectedInsights: newSelectedInsights,
		});
	};

export const clearInsightSeletion =
	() =>
	({ setState }: StoreActionApi<State>) => {
		setState({
			selectedInsights: {},
			activeAction: undefined,
		});
	};

export const deleteSelectedInsights =
	() =>
	({ getState, dispatch }: StoreActionApi<State>) => {
		Object.keys(getState().selectedInsights).forEach((selectedInsight) => {
			dispatch(deleteInsight(selectedInsight, { isBulkDeletion: true }));
		});
		dispatch(clearInsightSeletion());
	};
