import React from 'react';
import { styled } from '@compiled/react';
import { components, type OptionProps, type SingleValueProps } from '@atlaskit/select';
import { N20, N100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export type SelectedProjectIssueType = {
	id: string;
	description: string;
	iconUrl: string;
	name: string;
	subtask: boolean;
	avatarId: number;
	hierarchyLevel: number;
};

export type SelectedProject = {
	label: string;
	value: string;
	item: {
		issueTypes: SelectedProjectIssueType[];
		id: string;
		name: string;
		key: string;
		avatarUrls: {
			['16x16']: string;
			['24x24']: string;
			['32x32']: string;
			['48x48']: string;
		};
		projectTypeKey: string;
		simplified: boolean;
		style: string;
		isPrivate: boolean;
	};
};

export const OptionToSelect = (props: OptionProps<SelectedProject>) => {
	const project = props.data.item;
	const { ...innerProps } = props.innerProps;
	return (
		<Container
			{...innerProps}
			hasHoverEffects
			data-testid="polaris-common.common.ui.project-picker.option"
		>
			<Icon src={project.avatarUrls['16x16']} alt="type" />
			<Key limitWidth title={project.key}>
				{project.key}
			</Key>
			<Summary>{project.name}</Summary>
		</Container>
	);
};

export const SelectedOption = (props: SingleValueProps<SelectedProject>) => {
	const project = props.data.item;
	return (
		<components.SingleValue {...props}>
			<Container hasHoverEffects={false} limitWidth>
				<Icon src={project.avatarUrls['16x16']} alt="type" />
				<Summary title={`${project.name} (${project.key})`}>{project.name}</Summary>
			</Container>
		</components.SingleValue>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Icon = styled.img({
	height: '16px',
	width: '16px',
	marginRight: token('space.150', '12px'),
	marginLeft: token('space.150', '12px'),
	borderRadius: '2px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.div<{ limitWidth?: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	color: token('color.text.subtlest', N100),
	overflow: 'hidden',
	flexShrink: 0,
	textOverflow: 'ellipsis',
	marginRight: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ limitWidth }) => (limitWidth ? '60px' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	maxWidth: '100%',
	flexShrink: 1000,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ limitWidth?: boolean; hasHoverEffects: boolean }>({
	display: 'flex',
	alignItems: 'center',
	padding: `${token('space.050', '4px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ limitWidth }) => (limitWidth ? '95%' : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ limitWidth }) => (limitWidth ? 'auto' : '100%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ hasHoverEffects }) => (hasHoverEffects ? 'pointer' : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transition: ({ hasHoverEffects }) => (hasHoverEffects ? '0.3s' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ hasHoverEffects }) =>
			hasHoverEffects ? token('color.background.neutral.subtle.hovered', N20) : undefined,
	},
});
