import mapKeys from 'lodash/mapKeys';
import { fetchCommentsAndInsightsMetadata } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/main.tsx';
import type { MetadataPerIssueAri } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/types';
import type { Action } from '@atlassian/react-sweet-state';
import { createIssueAri, createProjectAri } from '../../../../common/utils/ari';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids';
import type { State, Props } from '../../types';

const setMetadata = (state: State, metadata: MetadataPerIssueAri, props: Props): State => {
	const ariToLocalIssueId = mapKeys(getLocalIssueIdsByJiraIssueId(state, props), (value, issueId) =>
		createIssueAri(props.cloudId, issueId),
	);
	return {
		...state,
		properties: {
			...state.properties,
			commentsMetadata: mapKeys(metadata.comments, (v, issueAri) => ariToLocalIssueId[issueAri]),
			insightsMetadata: mapKeys(metadata.insights, (v, issueAri) => ariToLocalIssueId[issueAri]),
		},
	};
};

export const loadTrackingMetadata =
	(): Action<State, Props> =>
	({ getState, setState }, props) => {
		const { cloudId, projectId, apolloClient } = props;
		if (!projectId) {
			return;
		}
		fetchCommentsAndInsightsMetadata(apolloClient, createProjectAri(cloudId, projectId)).then(
			(metadata) => {
				setState(setMetadata(getState(), metadata, props));
			},
		);
	};
