import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base';
import {
	fetchParentCandidatesForExistingIssue,
	deleteIssueParentCMP,
	deleteIssueParentsTMP,
	setIssueParentCMP,
	setIssueParentsTMP,
	type IssueParent,
} from '@atlassian/jira-issue-parent-services';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import { mapParentIdByIssueId } from '@atlassian/jira-issue-view-services/src/issue/issue-parent-server';
import {
	type Action,
	fetchIssueParentsWithInfoSuccess,
	fetchIssueParentsFailure,
	setParentSuccess,
	unsetParentSuccess,
	type SetParentRequestAction,
	type SetParentSuccessAction,
	type UnsetParentRequestAction,
	type UnsetParentSuccessAction,
	FETCH_ISSUE_PARENTS_WITH_INFO_REQUEST,
	SET_PARENT_REQUEST,
	UNSET_PARENT_REQUEST,
	SET_PARENT_SUCCESS,
	UNSET_PARENT_SUCCESS,
	type SetParentOptimisticAction,
	SET_PARENT_OPTIMISTIC,
} from '@atlassian/jira-issue-view-store/src/actions/assign-issue-parent-modal-actions';
import { issueRelationshipUpdateSuccess } from '@atlassian/jira-issue-view-store/src/actions/issue-relationship-actions';
import {
	issueKeySelector,
	cloudIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	isSimplifiedProjectSelector,
	projectIdSelector,
	parentLevelIssuesSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { type IssueId, toIssueId } from '@atlassian/jira-shared-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchParentWithInfoRequest = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FETCH_ISSUE_PARENTS_WITH_INFO_REQUEST)
		.mergeMap(() => {
			const state = store.getState();
			const issueKey = issueKeySelector(state);
			const cloudId = cloudIdSelector(state);
			return fetchParentCandidatesForExistingIssue(cloudId, issueKey, '', true);
		})
		.map(fetchIssueParentsWithInfoSuccess)
		// @ts-expect-error - TS2345 - Argument of type '(error: any) => FetchIssueParentsFailure' is not assignable to parameter of type '(err: any, caught: Observable<FetchIssueParentsSuccess>) => ObservableInput<unknown>'.
		.catch((error) => {
			trackOrLogClientError('issue.fetch-parent.failure', '', error);
			return fetchIssueParentsFailure();
		});

const getSetParentAction = (
	state: State,
	action: SetParentRequestAction | SetParentOptimisticAction,
	projectId: number,
	changedIssueIds: IssueId[],
) => {
	const isSimplified = isSimplifiedProjectSelector(state);
	if (action.type === SET_PARENT_OPTIMISTIC) {
		return Observable.fromPromise(action.payload.requestPromise);
	}
	if (isSimplified) {
		return setIssueParentsTMP(projectId, action.payload.parentId, {
			issueIds: changedIssueIds,
		});
	}
	return setIssueParentCMP(action.payload.parentId, {
		issueId: changedIssueIds[0],
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setParentRequest = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(SET_PARENT_REQUEST, SET_PARENT_OPTIMISTIC)
		.mergeMap((action: SetParentRequestAction | SetParentOptimisticAction) => {
			const state = store.getState();
			const {
				payload: { issues },
			} = action;
			const parentId =
				action.type === SET_PARENT_OPTIMISTIC
					? action.payload.parentIssue && action.payload.parentIssue.id
					: action.payload.parentId;

			const parentIssues = parentLevelIssuesSelector(state);
			const parentIssue =
				action.type === SET_PARENT_OPTIMISTIC
					? action.payload.parentIssue
					: parentIssues.find((issue) => issue.id === parentId);

			const changedIssueIds: IssueId[] = issues
				.filter((issue) => issue.parentId !== parentId && issue.id)
				.map((issue) => toIssueId(issue.id || ''));

			const oldParentIdsByIssueIds = mapParentIdByIssueId(
				// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
				issues.filter((issue) => changedIssueIds.includes(issue.id)),
			);

			if (!changedIssueIds.length) {
				return Observable.empty<never>();
			}

			const projectId = projectIdSelector(state);

			// @ts-expect-error - TS2345 - Argument of type 'number | null' is not assignable to parameter of type 'number'.
			const setParentAction = getSetParentAction(state, action, projectId, changedIssueIds);

			return (
				setParentAction
					// @ts-expect-error - TS2345 - Argument of type '() => Observable<{ type: "SET_PARENT_SUCCESS"; payload: { parentIssue: IssueParent; oldParentIdsByIssueIds: { [key: string]: string | null; }; }; }> | Observable<{ type: "UNSET_PARENT_SUCCESS"; payload: { ...; }; }>' is not assignable to parameter of type '(value: unknown, index: number) => ObservableInput<{ type: "SET_PARENT_SUCCESS"; payload: { parentIssue: IssueParent; oldParentIdsByIssueIds: { [key: string]: string | null; }; }; }>'.
					.flatMap(() => {
						if (parentIssue) {
							return Observable.of(setParentSuccess(parentIssue, oldParentIdsByIssueIds));
						}
						return Observable.of(unsetParentSuccess(oldParentIdsByIssueIds));
					})
					.catch((error) => {
						trackOrLogClientError('issue.set-parent.failure', '', error);
						return Observable.empty<never>();
					})
			);
		});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsetParentRequest = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(UNSET_PARENT_REQUEST).mergeMap((action: UnsetParentRequestAction) => {
		const state = store.getState();
		const {
			payload: { issues },
		} = action;

		const changedIssueIds: IssueId[] = issues
			.filter((issue) => issue.parentId && issue.id)
			.map((issue) => toIssueId(issue.id || ''));

		const oldParentIdsByIssueIds = mapParentIdByIssueId(
			// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
			issues.filter((issue) => changedIssueIds.includes(issue.id)),
		);

		if (changedIssueIds.length === 0) return Observable.empty<never>();

		const projectId = projectIdSelector(state);
		const isSimplified = isSimplifiedProjectSelector(state);

		const deleteIssueParentResult = () => {
			if (isSimplified) {
				// @ts-expect-error - TS2345 - Argument of type 'number | null' is not assignable to parameter of type 'number'.
				return deleteIssueParentsTMP(projectId, {
					issueIds: changedIssueIds,
				});
			}
			return deleteIssueParentCMP({
				issueId: changedIssueIds[0],
			});
		};

		return deleteIssueParentResult()
			.flatMap(() => Observable.of(unsetParentSuccess(oldParentIdsByIssueIds)))
			.catch((error) => {
				trackOrLogClientError('issue.unset-parent.failure', '', error);
				return Observable.empty<never>();
			});
	});

const changeParentSuccess =
	(fieldValueActions: FieldValueServiceActions) =>
	(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(SET_PARENT_SUCCESS, UNSET_PARENT_SUCCESS)
			.map((action: SetParentSuccessAction | UnsetParentSuccessAction) => {
				const fieldId = 'parent';
				const state = store.getState();
				const issueKey = issueKeySelector(state);
				if (action.type === SET_PARENT_SUCCESS) {
					const parentIssue: IssueParent | null = action?.payload.parentIssue || null;

					parentIssue &&
						fieldValueActions.setFieldValue(issueKey, fieldId, {
							id: parentIssue.id,
							key: parentIssue.key,
							fields: {
								summary: parentIssue.summary,
								issuetype: parentIssue.issueType,
							},
							color: parentIssue.color,
						});
				} else if (action.type === UNSET_PARENT_SUCCESS) {
					fieldValueActions.setFieldValue(issueKey, fieldId, null);
				}

				return issueRelationshipUpdateSuccess(
					get(action, 'payload.oldParentIdsByIssueIds'),
					get(action, 'payload.parentIssue.id', null),
				);
			});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (fieldValueActions: FieldValueServiceActions) =>
	combineEpics(
		fetchParentWithInfoRequest,
		setParentRequest,
		unsetParentRequest,
		changeParentSuccess(fieldValueActions),
	);
