import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	summaryRequiredError: {
		id: 'polaris-common.create-idea-dialog.field-config.summary-required-error',
		defaultMessage: 'Summary is required',
		description: 'Placeholder for summary of the issue to be created',
	},
	summaryMaxLengthError: {
		id: 'polaris-common.create-idea-dialog.field-config.summary-max-length-error',
		defaultMessage: "Summary can't exceed 255 characters.",
		description: 'Message to describe error that issue summary exceeds max length',
	},
	textFieldLimitError: {
		id: 'polaris-common.create-idea-dialog.field-config.text-field-limit-error',
		defaultMessage: 'Short text fields are limited to 255 characters',
		description: 'Message to describe error that short text field exceeds max length',
	},
	fieldValueRequiredError: {
		id: 'polaris-common.create-idea-dialog.field-config.field-value-required-error',
		defaultMessage: '{fieldLabel} is a required field',
		description: 'Validation message for required fields',
	},
});
