import React from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FieldName } from '../../../common/ui/field/styled';
import { useFieldLabel } from '../../../controllers/field/selectors/field-hooks';
import { useSingleSelect } from '../../../controllers/issue/selectors/properties/hooks';
import { DecoratedTag } from '../../common/decoration/decoration/tag';
import { TooltipFieldWithLabelContainer } from '../styled';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const SingleSelectField = ({ issueId, fieldKey }: Props) => {
	const label = useFieldLabel(fieldKey);
	const value = useSingleSelect(fieldKey, issueId)[0];
	return label !== undefined && value ? (
		<TooltipFieldWithLabelContainer key={fieldKey}>
			<FieldName>{label}</FieldName>
			<DecoratedTag key={value.id} fieldKey={fieldKey} id={value.id} value={value.value} />
		</TooltipFieldWithLabelContainer>
	) : null;
};
