import React, { useCallback, useState, useEffect, useMemo, type SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import LinkIcon from '@atlaskit/icon/glyph/link';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { G600 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import messages from './messages';

type CopyLinkProps = {
	size: 'small' | 'medium' | 'large' | 'xlarge';
	appearance?: 'default' | 'danger' | 'link' | 'primary' | 'subtle' | 'subtle-link' | 'warning';
	url?: string | null;
	label?: string;
	position?: 'bottom' | 'left' | 'right' | 'top' | 'mouse';
	onCopy: () => void;
};

export const CopyLink = ({
	size,
	appearance = 'default',
	url,
	onCopy,
	label,
	position = 'top',
}: CopyLinkProps) => {
	const { formatMessage } = useIntl();
	const [isCopied, setCopied] = useState(false);

	const copyLinkMessage = useMemo(
		() => label || formatMessage(messages.copyLink),
		[formatMessage, label],
	);

	const handleCopy = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();
			const shareUrl = url === null || url === undefined ? new URL(window.location.href) : url;
			// Copy URL into clipboard
			navigator.clipboard
				.writeText(shareUrl.toString())
				.then(() => {
					setCopied(true);
					onCopy();
				})
				.catch(() => setCopied(false));
		},
		[onCopy, url],
	);

	useEffect(() => {
		const copyTimer = setTimeout(() => setCopied(false), 2500);
		return () => {
			clearTimeout(copyTimer);
		};
	}, [isCopied]);

	return (
		<Tooltip
			position={position}
			content={isCopied ? formatMessage(messages.copiedMessage) : copyLinkMessage}
		>
			<Button
				onClick={handleCopy}
				appearance={isCopied ? 'subtle-link' : appearance}
				spacing="none"
				iconBefore={
					isCopied ? (
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<CheckCircleIcon primaryColor={G600} size={size} label={copyLinkMessage} />
					) : (
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<LinkIcon size={size} label={copyLinkMessage} />
					)
				}
			/>
		</Tooltip>
	);
};
