import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const OptionWeightTypeNumberTooltipImageDark = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="276"
		height="128"
		viewBox="0 0 276 128"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_353_3630)">
			<rect width="276" height="128" rx="3" fill="#1D2125" />
			<path
				d="M7.33333 27.5C7.79357 27.5 8.16667 27.1269 8.16667 26.6667C8.16667 26.2064 7.79357 25.8333 7.33333 25.8333C6.8731 25.8333 6.5 26.2064 6.5 26.6667C6.5 27.1269 6.8731 27.5 7.33333 27.5Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 27.5C11.1269 27.5 11.5 27.1269 11.5 26.6667C11.5 26.2064 11.1269 25.8333 10.6667 25.8333C10.2064 25.8333 9.83333 26.2064 9.83333 26.6667C9.83333 27.1269 10.2064 27.5 10.6667 27.5Z"
				fill="#9FADBC"
			/>
			<path
				d="M7.33333 34.1667C7.79357 34.1667 8.16667 33.7936 8.16667 33.3333C8.16667 32.8731 7.79357 32.5 7.33333 32.5C6.8731 32.5 6.5 32.8731 6.5 33.3333C6.5 33.7936 6.8731 34.1667 7.33333 34.1667Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 34.1667C11.1269 34.1667 11.5 33.7936 11.5 33.3333C11.5 32.8731 11.1269 32.5 10.6667 32.5C10.2064 32.5 9.83333 32.8731 9.83333 33.3333C9.83333 33.7936 10.2064 34.1667 10.6667 34.1667Z"
				fill="#9FADBC"
			/>
			<path
				d="M7.33333 30.8333C7.79357 30.8333 8.16667 30.4602 8.16667 30C8.16667 29.5398 7.79357 29.1667 7.33333 29.1667C6.8731 29.1667 6.5 29.5398 6.5 30C6.5 30.4602 6.8731 30.8333 7.33333 30.8333Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 30.8333C11.1269 30.8333 11.5 30.4602 11.5 30C11.5 29.5398 11.1269 29.1667 10.6667 29.1667C10.2064 29.1667 9.83333 29.5398 9.83333 30C9.83333 30.4602 10.2064 30.8333 10.6667 30.8333Z"
				fill="#9FADBC"
			/>
			<rect x="17" y="19" width="116" height="22" rx="3" fill="#1C3329" />
			<rect x="25" y="27" width="100" height="6" rx="3" fill="#216E4E" />
			<path
				d="M7.33333 61.5C7.79357 61.5 8.16667 61.1269 8.16667 60.6667C8.16667 60.2064 7.79357 59.8333 7.33333 59.8333C6.8731 59.8333 6.5 60.2064 6.5 60.6667C6.5 61.1269 6.8731 61.5 7.33333 61.5Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 61.5C11.1269 61.5 11.5 61.1269 11.5 60.6667C11.5 60.2064 11.1269 59.8333 10.6667 59.8333C10.2064 59.8333 9.83333 60.2064 9.83333 60.6667C9.83333 61.1269 10.2064 61.5 10.6667 61.5Z"
				fill="#9FADBC"
			/>
			<path
				d="M7.33333 68.1667C7.79357 68.1667 8.16667 67.7936 8.16667 67.3333C8.16667 66.8731 7.79357 66.5 7.33333 66.5C6.8731 66.5 6.5 66.8731 6.5 67.3333C6.5 67.7936 6.8731 68.1667 7.33333 68.1667Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 68.1667C11.1269 68.1667 11.5 67.7936 11.5 67.3333C11.5 66.8731 11.1269 66.5 10.6667 66.5C10.2064 66.5 9.83333 66.8731 9.83333 67.3333C9.83333 67.7936 10.2064 68.1667 10.6667 68.1667Z"
				fill="#9FADBC"
			/>
			<path
				d="M7.33333 64.8333C7.79357 64.8333 8.16667 64.4602 8.16667 64C8.16667 63.5398 7.79357 63.1667 7.33333 63.1667C6.8731 63.1667 6.5 63.5398 6.5 64C6.5 64.4602 6.8731 64.8333 7.33333 64.8333Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 64.8333C11.1269 64.8333 11.5 64.4602 11.5 64C11.5 63.5398 11.1269 63.1667 10.6667 63.1667C10.2064 63.1667 9.83333 63.5398 9.83333 64C9.83333 64.4602 10.2064 64.8333 10.6667 64.8333Z"
				fill="#9FADBC"
			/>
			<rect x="17" y="53" width="82" height="22" rx="3" fill="#1C2B41" />
			<rect x="25" y="61" width="66" height="6" rx="3" fill="#0055CC" />
			<path
				d="M7.33333 95.5C7.79357 95.5 8.16667 95.1269 8.16667 94.6667C8.16667 94.2064 7.79357 93.8333 7.33333 93.8333C6.8731 93.8333 6.5 94.2064 6.5 94.6667C6.5 95.1269 6.8731 95.5 7.33333 95.5Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 95.5C11.1269 95.5 11.5 95.1269 11.5 94.6667C11.5 94.2064 11.1269 93.8333 10.6667 93.8333C10.2064 93.8333 9.83333 94.2064 9.83333 94.6667C9.83333 95.1269 10.2064 95.5 10.6667 95.5Z"
				fill="#9FADBC"
			/>
			<path
				d="M7.33333 102.167C7.79357 102.167 8.16667 101.794 8.16667 101.333C8.16667 100.873 7.79357 100.5 7.33333 100.5C6.8731 100.5 6.5 100.873 6.5 101.333C6.5 101.794 6.8731 102.167 7.33333 102.167Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 102.167C11.1269 102.167 11.5 101.794 11.5 101.333C11.5 100.873 11.1269 100.5 10.6667 100.5C10.2064 100.5 9.83333 100.873 9.83333 101.333C9.83333 101.794 10.2064 102.167 10.6667 102.167Z"
				fill="#9FADBC"
			/>
			<path
				d="M7.33333 98.8333C7.79357 98.8333 8.16667 98.4602 8.16667 98C8.16667 97.5398 7.79357 97.1667 7.33333 97.1667C6.8731 97.1667 6.5 97.5398 6.5 98C6.5 98.4602 6.8731 98.8333 7.33333 98.8333Z"
				fill="#9FADBC"
			/>
			<path
				d="M10.6667 98.8333C11.1269 98.8333 11.5 98.4602 11.5 98C11.5 97.5398 11.1269 97.1667 10.6667 97.1667C10.2064 97.1667 9.83333 97.5398 9.83333 98C9.83333 98.4602 10.2064 98.8333 10.6667 98.8333Z"
				fill="#9FADBC"
			/>
			<rect x="17" y="87" width="101" height="22" rx="3" fill="#2B273F" />
			<rect x="25" y="95" width="85" height="6" rx="3" fill="#5E4DB2" />
			<rect x="199" y="18" width="60" height="24" rx="3" fill="#22272B" />
			<rect
				x="199.5"
				y="18.5"
				width="59"
				height="23"
				rx="2.5"
				stroke="#A6C5E2"
				strokeOpacity="0.16"
			/>
			<path
				d="M246.687 35.2324C246.076 35.2324 245.531 35.1117 245.053 34.8701C244.574 34.6286 244.189 34.3005 243.897 33.8857C243.61 33.4665 243.442 32.9948 243.392 32.4707L243.385 32.3955H244.567L244.574 32.4502C244.615 32.7646 244.729 33.0518 244.916 33.3115C245.107 33.5667 245.356 33.7695 245.661 33.9199C245.971 34.0703 246.317 34.1455 246.7 34.1455C247.138 34.1455 247.523 34.0498 247.855 33.8584C248.188 33.6624 248.448 33.3936 248.635 33.0518C248.822 32.71 248.915 32.318 248.915 31.876V31.8623C248.915 31.4248 248.819 31.0374 248.628 30.7002C248.441 30.3584 248.184 30.0918 247.855 29.9004C247.527 29.709 247.149 29.6133 246.721 29.6133C246.393 29.6133 246.09 29.6634 245.812 29.7637C245.538 29.8639 245.294 30.0143 245.08 30.2148C244.993 30.2969 244.911 30.3857 244.834 30.4814C244.761 30.5771 244.693 30.6842 244.629 30.8027H243.562L244.082 25.1357H249.571V26.2363H245.053L244.718 29.5723H244.827C244.973 29.349 245.153 29.1621 245.367 29.0117C245.581 28.8613 245.821 28.7474 246.085 28.6699C246.354 28.5879 246.636 28.5469 246.933 28.5469C247.552 28.5469 248.102 28.6904 248.58 28.9775C249.059 29.2601 249.435 29.6497 249.708 30.1465C249.981 30.6387 250.118 31.2038 250.118 31.8418V31.8555C250.118 32.5163 249.97 33.1019 249.674 33.6123C249.382 34.1182 248.979 34.5146 248.464 34.8018C247.949 35.0889 247.356 35.2324 246.687 35.2324Z"
				fill="#B6C2CF"
			/>
			<g clipPath="url(#clip1_353_3630)">
				<path
					d="M178.743 32.188C178.649 32.4151 178.389 32.5217 178.162 32.4275C177.935 32.3329 177.828 32.0729 177.923 31.8462L179.975 26.2738C180.069 26.0471 180.329 25.94 180.555 26.0342C180.782 26.1289 180.889 26.3889 180.795 26.6155L178.743 32.188Z"
					fill="#9FADBC"
				/>
				<path
					d="M183.189 31.8462C183.283 32.0729 183.176 32.3329 182.949 32.4275C182.722 32.5217 182.462 32.4151 182.368 32.188L180.317 26.6155C180.222 26.3889 180.329 26.1289 180.556 26.0342C180.783 25.94 181.043 26.0471 181.137 26.2738L183.189 31.8462ZM187.633 32.188C187.539 32.4151 187.278 32.5217 187.052 32.4275C186.825 32.3329 186.718 32.0729 186.813 31.8462L188.864 26.2733C188.958 26.0471 189.218 25.94 189.445 26.0342C189.672 26.1289 189.779 26.3889 189.685 26.6155L187.633 32.188Z"
					fill="#9FADBC"
				/>
				<path
					d="M192.077 31.8462C192.171 32.0729 192.065 32.3329 191.838 32.4275C191.611 32.5217 191.351 32.4151 191.256 32.188L189.205 26.6155C189.11 26.3889 189.218 26.1289 189.445 26.0342C189.671 25.94 189.931 26.0471 190.026 26.2738L192.077 31.8462Z"
					fill="#9FADBC"
				/>
				<path
					d="M183.603 31.7778H177.508C177.228 31.7778 177 32.0049 177 32.2858C177 33.4076 178.592 34.3174 180.556 34.3174C182.519 34.3174 184.111 33.4076 184.111 32.2858C184.111 32.0049 183.884 31.7778 183.603 31.7778ZM187.667 37.1111H182.333C182.088 37.1111 181.889 36.9125 181.889 36.6667C181.889 35.6849 183.282 34.8889 185 34.8889C186.718 34.8889 188.111 35.6849 188.111 36.6667C188.111 36.9125 187.912 37.1111 187.667 37.1111ZM192.492 31.7778H186.397C186.116 31.7778 185.889 32.0049 185.889 32.2858C185.889 33.4076 187.48 34.3174 189.444 34.3174C191.408 34.3174 193 33.4076 193 32.2858C193 32.0049 192.773 31.7778 192.492 31.7778Z"
					fill="#9FADBC"
				/>
				<path
					d="M185.445 23.3333C185.445 23.0879 185.246 22.8888 185 22.8888C184.755 22.8888 184.556 23.0879 184.556 23.3333V35.3333C184.556 35.5791 184.755 35.7777 185 35.7777C185.246 35.7777 185.445 35.5791 185.445 35.3333V23.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M184.999 24.6666C185.49 24.6666 185.888 24.2686 185.888 23.7777C185.888 23.2868 185.49 22.8888 184.999 22.8888C184.508 22.8888 184.11 23.2868 184.11 23.7777C184.11 24.2686 184.508 24.6666 184.999 24.6666Z"
					fill="#9FADBC"
				/>
				<path
					d="M180.555 27.3333C181.046 27.3333 181.444 26.9353 181.444 26.4444C181.444 25.9535 181.046 25.5555 180.555 25.5555C180.064 25.5555 179.666 25.9535 179.666 26.4444C179.666 26.9353 180.064 27.3333 180.555 27.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M189.445 27.3333C189.936 27.3333 190.334 26.9353 190.334 26.4444C190.334 25.9535 189.936 25.5555 189.445 25.5555C188.954 25.5555 188.556 25.9535 188.556 26.4444C188.556 26.9353 188.954 27.3333 189.445 27.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M189.445 26.4444C189.445 26.9351 189.445 26.4444 185 26.4444C180.556 26.4444 180.556 26.9351 180.556 26.4444C180.556 25.9538 181.889 24.6667 185 24.6667C188.111 24.6667 189.445 25.9538 189.445 26.4444Z"
					fill="#9FADBC"
				/>
			</g>
			<rect x="199" y="52" width="60" height="24" rx="3" fill="#22272B" />
			<rect
				x="199.5"
				y="52.5"
				width="59"
				height="23"
				rx="2.5"
				stroke="#A6C5E2"
				strokeOpacity="0.16"
			/>
			<path
				d="M246.68 69.1641C246.001 69.1641 245.408 69.0479 244.902 68.8154C244.396 68.583 243.995 68.264 243.699 67.8584C243.408 67.4528 243.239 66.9902 243.193 66.4707L243.187 66.3887H244.369L244.376 66.457C244.408 66.7806 244.522 67.0654 244.718 67.3115C244.918 67.5576 245.185 67.7513 245.518 67.8926C245.855 68.0293 246.242 68.0977 246.68 68.0977C247.113 68.0977 247.491 68.0225 247.814 67.8721C248.143 67.7217 248.398 67.5143 248.58 67.25C248.762 66.9811 248.854 66.6735 248.854 66.3271V66.3135C248.854 65.7074 248.651 65.2425 248.245 64.9189C247.844 64.5908 247.297 64.4268 246.604 64.4268H245.367V63.3877H246.55C246.942 63.3877 247.288 63.3148 247.589 63.1689C247.89 63.0186 248.124 62.8158 248.293 62.5605C248.462 62.3008 248.546 62.0068 248.546 61.6787V61.665C248.546 61.3187 248.473 61.0247 248.327 60.7832C248.181 60.5417 247.967 60.3571 247.685 60.2295C247.407 60.1019 247.067 60.0381 246.666 60.0381C246.279 60.0381 245.932 60.1042 245.627 60.2363C245.326 60.3639 245.085 60.5485 244.902 60.79C244.72 61.0316 244.611 61.3187 244.574 61.6514L244.567 61.7197H243.385L243.392 61.6514C243.446 61.1136 243.615 60.6442 243.897 60.2432C244.185 59.8421 244.563 59.5299 245.032 59.3066C245.506 59.0833 246.051 58.9717 246.666 58.9717C247.295 58.9717 247.842 59.0811 248.307 59.2998C248.776 59.514 249.138 59.8125 249.394 60.1953C249.653 60.5736 249.783 61.0133 249.783 61.5146V61.5283C249.783 61.9339 249.694 62.2939 249.517 62.6084C249.339 62.9229 249.093 63.1849 248.778 63.3945C248.464 63.6042 248.102 63.7523 247.691 63.8389V63.8662C248.411 63.9255 248.993 64.1693 249.435 64.5977C249.881 65.0215 250.104 65.5843 250.104 66.2861V66.2998C250.104 66.8467 249.956 67.3366 249.66 67.7695C249.364 68.2025 248.958 68.5443 248.443 68.7949C247.928 69.041 247.34 69.1641 246.68 69.1641Z"
				fill="#B6C2CF"
			/>
			<g clipPath="url(#clip2_353_3630)">
				<path
					d="M178.743 66.188C178.649 66.4151 178.389 66.5217 178.162 66.4275C177.935 66.3329 177.828 66.0729 177.923 65.8462L179.975 60.2738C180.069 60.0471 180.329 59.94 180.555 60.0342C180.782 60.1289 180.889 60.3889 180.795 60.6155L178.743 66.188Z"
					fill="#9FADBC"
				/>
				<path
					d="M183.189 65.8462C183.283 66.0729 183.176 66.3329 182.949 66.4275C182.722 66.5217 182.462 66.4151 182.368 66.188L180.317 60.6155C180.222 60.3889 180.329 60.1289 180.556 60.0342C180.783 59.94 181.043 60.0471 181.137 60.2738L183.189 65.8462ZM187.633 66.188C187.539 66.4151 187.278 66.5217 187.052 66.4275C186.825 66.3329 186.718 66.0729 186.813 65.8462L188.864 60.2733C188.958 60.0471 189.218 59.94 189.445 60.0342C189.672 60.1289 189.779 60.3889 189.685 60.6155L187.633 66.188Z"
					fill="#9FADBC"
				/>
				<path
					d="M192.077 65.8462C192.171 66.0729 192.065 66.3329 191.838 66.4275C191.611 66.5217 191.351 66.4151 191.256 66.188L189.205 60.6155C189.11 60.3889 189.218 60.1289 189.445 60.0342C189.671 59.94 189.931 60.0471 190.026 60.2738L192.077 65.8462Z"
					fill="#9FADBC"
				/>
				<path
					d="M183.603 65.7778H177.508C177.228 65.7778 177 66.0049 177 66.2858C177 67.4076 178.592 68.3174 180.556 68.3174C182.519 68.3174 184.111 67.4076 184.111 66.2858C184.111 66.0049 183.884 65.7778 183.603 65.7778ZM187.667 71.1111H182.333C182.088 71.1111 181.889 70.9125 181.889 70.6667C181.889 69.6849 183.282 68.8889 185 68.8889C186.718 68.8889 188.111 69.6849 188.111 70.6667C188.111 70.9125 187.912 71.1111 187.667 71.1111ZM192.492 65.7778H186.397C186.116 65.7778 185.889 66.0049 185.889 66.2858C185.889 67.4076 187.48 68.3174 189.444 68.3174C191.408 68.3174 193 67.4076 193 66.2858C193 66.0049 192.773 65.7778 192.492 65.7778Z"
					fill="#9FADBC"
				/>
				<path
					d="M185.445 57.3333C185.445 57.0879 185.246 56.8888 185 56.8888C184.755 56.8888 184.556 57.0879 184.556 57.3333V69.3333C184.556 69.5791 184.755 69.7777 185 69.7777C185.246 69.7777 185.445 69.5791 185.445 69.3333V57.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M184.999 58.6666C185.49 58.6666 185.888 58.2686 185.888 57.7777C185.888 57.2868 185.49 56.8888 184.999 56.8888C184.508 56.8888 184.11 57.2868 184.11 57.7777C184.11 58.2686 184.508 58.6666 184.999 58.6666Z"
					fill="#9FADBC"
				/>
				<path
					d="M180.555 61.3333C181.046 61.3333 181.444 60.9353 181.444 60.4444C181.444 59.9535 181.046 59.5555 180.555 59.5555C180.064 59.5555 179.666 59.9535 179.666 60.4444C179.666 60.9353 180.064 61.3333 180.555 61.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M189.445 61.3333C189.936 61.3333 190.334 60.9353 190.334 60.4444C190.334 59.9535 189.936 59.5555 189.445 59.5555C188.954 59.5555 188.556 59.9535 188.556 60.4444C188.556 60.9353 188.954 61.3333 189.445 61.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M189.445 60.4444C189.445 60.9351 189.445 60.4444 185 60.4444C180.556 60.4444 180.556 60.9351 180.556 60.4444C180.556 59.9538 181.889 58.6667 185 58.6667C188.111 58.6667 189.445 59.9538 189.445 60.4444Z"
					fill="#9FADBC"
				/>
			</g>
			<rect x="199" y="86" width="60" height="24" rx="3" fill="#22272B" />
			<rect
				x="199.5"
				y="86.5"
				width="59"
				height="23"
				rx="2.5"
				stroke="#A6C5E2"
				strokeOpacity="0.16"
			/>
			<path
				d="M247.596 103V100.956H242.79V99.835C243.059 99.3883 243.332 98.9372 243.61 98.4814C243.888 98.0212 244.169 97.5632 244.451 97.1074C244.738 96.6471 245.025 96.1914 245.312 95.7402C245.6 95.2891 245.884 94.8447 246.167 94.4072C246.454 93.9697 246.737 93.5459 247.015 93.1357H248.799V99.8555H250.2V100.956H248.799V103H247.596ZM244.048 99.8623H247.603V94.2773H247.521C247.297 94.61 247.069 94.9564 246.837 95.3164C246.604 95.6719 246.368 96.0365 246.126 96.4102C245.889 96.7839 245.652 97.1598 245.415 97.5381C245.178 97.9163 244.943 98.2946 244.711 98.6729C244.483 99.0465 244.262 99.4157 244.048 99.7803V99.8623Z"
				fill="#B6C2CF"
			/>
			<g clipPath="url(#clip3_353_3630)">
				<path
					d="M178.743 100.188C178.649 100.415 178.389 100.522 178.162 100.428C177.935 100.333 177.828 100.073 177.923 99.8462L179.975 94.2738C180.069 94.0471 180.329 93.94 180.555 94.0342C180.782 94.1289 180.889 94.3889 180.795 94.6155L178.743 100.188Z"
					fill="#9FADBC"
				/>
				<path
					d="M183.189 99.8462C183.283 100.073 183.176 100.333 182.949 100.428C182.722 100.522 182.462 100.415 182.368 100.188L180.317 94.6155C180.222 94.3889 180.329 94.1289 180.556 94.0342C180.783 93.94 181.043 94.0471 181.137 94.2738L183.189 99.8462ZM187.633 100.188C187.539 100.415 187.278 100.522 187.052 100.428C186.825 100.333 186.718 100.073 186.813 99.8462L188.864 94.2733C188.958 94.0471 189.218 93.94 189.445 94.0342C189.672 94.1289 189.779 94.3889 189.685 94.6155L187.633 100.188Z"
					fill="#9FADBC"
				/>
				<path
					d="M192.077 99.8462C192.171 100.073 192.065 100.333 191.838 100.428C191.611 100.522 191.351 100.415 191.256 100.188L189.205 94.6155C189.11 94.3889 189.218 94.1289 189.445 94.0342C189.671 93.94 189.931 94.0471 190.026 94.2738L192.077 99.8462Z"
					fill="#9FADBC"
				/>
				<path
					d="M183.603 99.7778H177.508C177.228 99.7778 177 100.005 177 100.286C177 101.408 178.592 102.317 180.556 102.317C182.519 102.317 184.111 101.408 184.111 100.286C184.111 100.005 183.884 99.7778 183.603 99.7778ZM187.667 105.111H182.333C182.088 105.111 181.889 104.912 181.889 104.667C181.889 103.685 183.282 102.889 185 102.889C186.718 102.889 188.111 103.685 188.111 104.667C188.111 104.912 187.912 105.111 187.667 105.111ZM192.492 99.7778H186.397C186.116 99.7778 185.889 100.005 185.889 100.286C185.889 101.408 187.48 102.317 189.444 102.317C191.408 102.317 193 101.408 193 100.286C193 100.005 192.773 99.7778 192.492 99.7778Z"
					fill="#9FADBC"
				/>
				<path
					d="M185.445 91.3333C185.445 91.0879 185.246 90.8888 185 90.8888C184.755 90.8888 184.556 91.0879 184.556 91.3333V103.333C184.556 103.579 184.755 103.778 185 103.778C185.246 103.778 185.445 103.579 185.445 103.333V91.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M184.999 92.6666C185.49 92.6666 185.888 92.2686 185.888 91.7777C185.888 91.2868 185.49 90.8888 184.999 90.8888C184.508 90.8888 184.11 91.2868 184.11 91.7777C184.11 92.2686 184.508 92.6666 184.999 92.6666Z"
					fill="#9FADBC"
				/>
				<path
					d="M180.555 95.3333C181.046 95.3333 181.444 94.9353 181.444 94.4444C181.444 93.9535 181.046 93.5555 180.555 93.5555C180.064 93.5555 179.666 93.9535 179.666 94.4444C179.666 94.9353 180.064 95.3333 180.555 95.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M189.445 95.3333C189.936 95.3333 190.334 94.9353 190.334 94.4444C190.334 93.9535 189.936 93.5555 189.445 93.5555C188.954 93.5555 188.556 93.9535 188.556 94.4444C188.556 94.9353 188.954 95.3333 189.445 95.3333Z"
					fill="#9FADBC"
				/>
				<path
					d="M189.445 94.4444C189.445 94.9351 189.445 94.4444 185 94.4444C180.556 94.4444 180.556 94.9351 180.556 94.4444C180.556 93.9538 181.889 92.6667 185 92.6667C188.111 92.6667 189.445 93.9538 189.445 94.4444Z"
					fill="#9FADBC"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_353_3630">
				<rect width="276" height="128" rx="3" fill="white" />
			</clipPath>
			<clipPath id="clip1_353_3630">
				<rect width="16" height="16" fill="white" transform="translate(177 22)" />
			</clipPath>
			<clipPath id="clip2_353_3630">
				<rect width="16" height="16" fill="white" transform="translate(177 56)" />
			</clipPath>
			<clipPath id="clip3_353_3630">
				<rect width="16" height="16" fill="white" transform="translate(177 90)" />
			</clipPath>
		</defs>
	</svg>
);
