import type { GlanceUiState } from '@atlassian/jira-issue-view-common-types/src/glance-type';
import {
	ECOSYSTEM_SHOW_GLANCE,
	type EcosystemGlanceAction,
} from '../actions/ecosystem-glance-actions';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: GlanceUiState | null = null, action: EcosystemGlanceAction) => {
	switch (action.type) {
		case ECOSYSTEM_SHOW_GLANCE: {
			return action.payload;
		}
		default:
			return state;
	}
};
