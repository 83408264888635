import React, { useEffect } from 'react';
import values from 'lodash/values';
import { metrics } from '@atlassian/browser-metrics';

export const MARKS = {
	IDEAS_EMOJI: 'ideas.container.emoji',
	COLLAB_CONTAINER_LOADING_STATE: 'controllers.collab-container.loading',
	COLLAB_CONTAINER: 'controllers.collab-container',
	PROJECT_LOADING_GATEKEEPER: 'gatekeeper.project-loading',
	INSTANCE_DATA_CONTAINER: 'containers.instance-data',
	IDEA_VIEW_DATA_CONTAINER: 'containers.idea-view-data',
	ISSUE_CONTAINER_FIELDS_LOADING: 'container.issue.fields-loading',
	ISSUE_CONTAINER: 'container.issue',
	PROJECT_CONTAINER: 'container.project',
	VIEW_CONTAINER: 'container.view',
	FIELD_CONTAINER_LOADING: 'container.field.loading',
	FIELD_CONTAINER: 'container.field',
	MAIN_DATA_CONTAINER: 'container.main',
	MAIN_DATA_CONTAINER_X: 'container.main.x',
	MAIN_COMPONENT_LOADING: 'component.main.loading',
	ADF_CONTROLLER: 'controller.adf',
	SMART_CARD_PROVIDER: 'provider.smart-card',
	USER_CONTAINER: 'container.user',
	ENVIRONMENT_CONTROLLER: 'controller.environment',
	CONTEXT_PROVIDER: 'provider.context',
	APOLLO_PROVIDER: 'provider.apollo',
} as const;

const timing = (mark: string) => ({
	key: mark,
	endMark: `${mark}.end`,
	startMark: `${mark}.start`,
});

const commonIdeaSettings = {
	team: 'Polaris',
	timings: values(MARKS).map(timing),
} as const;

// metrics for idea view opened as part of the app usage
const ideaViewLoad = metrics.pageSegmentLoad({
	...commonIdeaSettings,
	key: 'jpd.idea-view.page-load',
});

// metrics for idea view opening as part of a page refresh
const directIdeaViewLoad = metrics.pageSegmentLoad({
	...commonIdeaSettings,
	key: 'jpd.idea-view.page-load.direct',
});

export const markIdeaViewStart = (isDirect: boolean) => {
	if (isDirect) {
		directIdeaViewLoad.start();
	} else {
		ideaViewLoad.start();
	}
};

export const markIdeaView = (mark: string) => {
	ideaViewLoad.mark(mark);
	directIdeaViewLoad.mark(mark);
};

export const markIdeaViewTTI = () => {
	ideaViewLoad.stop();
	directIdeaViewLoad.stop();
};

const IdeaViewMark = ({ mark }: { mark: string }) => {
	useEffect(() => {
		markIdeaView(mark);
	}, [mark]);

	return null;
};

export const IdeaViewStartMark = ({ mark }: { mark: string }) => (
	<IdeaViewMark mark={`${mark}.start`} />
);

export const IdeaViewEndMark = ({ mark }: { mark: string }) => (
	<IdeaViewMark mark={`${mark}.end`} />
);
