import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	reviewMappingHeader: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.review-mapping-header',
		defaultMessage: 'Review mapping',
		description: 'Header of the Review mapping modal',
	},
	reviewMappingDescription: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.review-mapping-description',
		defaultMessage:
			'We mapped identical labels of the project field options to global field options. Review and make changes if needed.',
		description: 'Description of the Review mapping modal',
	},
	mapOptionsHeader: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.map-options-header',
		defaultMessage: 'Map options',
		description: 'Header of the Map options modal',
	},
	mapOptionsDescription: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.map-options-description',
		defaultMessage:
			'Review and map options to proceed with copying the field values. If there is no option to map to, ask your Jira admin to create an identical option for the global field you\'ve chosen or select "Don\'t copy".',
		description: 'Description of the Map options modal',
	},
	save: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.save',
		defaultMessage: 'Save',
		description: 'Label of the Save button',
	},
	cancel: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Label of the Cancel button',
	},
	projectField: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.project-field',
		defaultMessage: 'Project field',
		description: 'Label of the Project field input',
	},
	globalField: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.global-field',
		defaultMessage: 'Global field',
		description: 'Label of the Global field input',
	},
	closeModal: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.options-mapping.options-mapping-modal.close-modal',
		defaultMessage: 'Close Modal',
		description: 'Label for the "Close Modal" icon',
	},
});
