import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	performPutRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { DESCRIPTION_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type {
	FetchSharingSettings,
	DeleteSharingSettings,
	UpdateSharingSettings,
	SharingSettingsServiceType,
	UpdatePublicSharingSettings,
} from './types';
import {
	getSharingSettingsPublicUrl,
	getSharingSettingsUrl,
	getSharingUrl,
	mapSharingSettings,
	putPublicSharingSettingsUrl,
	putSharingSettingsUrl,
} from './utils';

export const fetchSharingSettings: FetchSharingSettings = (viewUUID) =>
	fetchJson<SharingSettingsServiceType>(getSharingSettingsUrl(viewUUID)).then(mapSharingSettings);

// Only Published view should call public endpoint. The main view should call original /v1/sharing/settings endpoint.
// We do not want any call from main view to be rate limited as more strict rate limits apply on public endpoints.
export const fetchSharingSettingsPublic: FetchSharingSettings = (viewUUID) =>
	fetchJson<SharingSettingsServiceType>(getSharingSettingsPublicUrl(viewUUID)).then(
		mapSharingSettings,
	);

export const updatePublicSharingSettings: UpdatePublicSharingSettings = async (
	viewUUID,
	{ enabled },
) => {
	await performPutRequest(putPublicSharingSettingsUrl(viewUUID), {
		body: JSON.stringify({
			enabled,
		}),
	});
};

export const updateSharingSettings: UpdateSharingSettings = async (viewUUID, settings) => {
	const updatedSettings: Partial<SharingSettingsServiceType> = { ...settings };

	if (settings.ideaDescription !== undefined) {
		updatedSettings.ideaDescription = {
			fieldKey:
				settings.ideaDescription.fieldKey === DESCRIPTION_FIELDKEY
					? null
					: settings.ideaDescription.fieldKey,
		};
	}

	return performPutRequest(putSharingSettingsUrl(viewUUID), {
		body: JSON.stringify(updatedSettings),
	});
};

export const deleteSharingSettings: DeleteSharingSettings = (viewUUID) =>
	performDeleteRequest(getSharingUrl(viewUUID));
