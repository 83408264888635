import type { Action } from '@atlassian/react-sweet-state';
import type { AtlasProjectsSearch } from '../../../../services/atlas/projects/types';
import type { Props, State } from '../../types';

export const addProjects =
	(projects: AtlasProjectsSearch): Action<State, Props> =>
	({ setState, getState }, { onUpdate }) => {
		setState({
			projects: {
				...getState().projects,
				items: projects.reduce(
					(result, project) =>
						Object.assign(result, {
							[project.id]: {
								...project,
							},
						}),
					getState().projects.items,
				),
			},
		});
		onUpdate(getState().projects.items);
	};
