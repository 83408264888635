import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '../../../common/types/user';

type UsersResponse = Array<{ accountId: string; displayName: string; avatarUrls: string[] }>;

const getAssignableUsersSearchUrl = (issueKey: string, query = '') =>
	`/rest/internal/3/polaris/user/assignable/search?issueKey=${issueKey}&recommend=true&query=${query}`;

export const getAssignableUsers = (issueKey: string, query?: string): Promise<User[]> =>
	fetchJson<UsersResponse>(getAssignableUsersSearchUrl(issueKey, query)).then((users) =>
		users.map(({ accountId, displayName, avatarUrls }) => ({
			id: accountId,
			name: displayName || '',
			avatarUrl: Object.values(avatarUrls).pop(),
		})),
	);
