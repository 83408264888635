import { getAllMediaIdsFromComments } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch';
import type { InsightsRemote } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getInsightsAttachments, getInsightsMediaIds } from '../../../../common/utils/insights';
import { createAttachments } from '../../../../services/jira/create-attachments';
import { getAttachment } from '../../../../services/jira/get-attachment';
import { getFileBinary } from '../../../../services/media/get-file-binary';

export const copyMediaAndAttachments = async (
	insightsRemote: InsightsRemote,
	issuesRemote: IssuesRemote,
	sourceIssueId: number,
	sourceIssueKey: string,
	targetIssueKey: IssueKey,
) =>
	Promise.all([
		issuesRemote.fetchIssue({ issueIdOrKey: sourceIssueKey }),
		issuesRemote.fetchIssueAttachments({ issueIdOrKey: sourceIssueKey }),
	]).then(async ([issueResponse, issueAttachmentsResponse]) => {
		const commentMediaIds = getAllMediaIdsFromComments(
			issueResponse.fields.comment?.comments || [],
		);
		const files = issueAttachmentsResponse.tokensWithFiles[0]?.files;
		if (!Array.isArray(files)) {
			return undefined;
		}
		const token = issueAttachmentsResponse.tokensWithFiles[0]?.token;
		if (!token) {
			throw new Error('media token for read attachments is empty');
		}

		const insightsWithSnippetData =
			(await insightsRemote.fetchInsightsWithSnippetData?.({
				issueId: String(sourceIssueId),
			})) ?? [];

		const snippetsAttachmentsMap = getInsightsAttachments(
			insightsWithSnippetData.map(({ snippets }) => ({
				snippets: snippets.map(({ data }) => ({ data })),
			})),
		) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>(
				(result, attachment) => ({
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...result,
					[attachment.attachmentId]: true,
				}),
				{},
			);

		const insightsMediaIds = getInsightsMediaIds(
			insightsWithSnippetData.map(({ description }) => ({
				description,
			})),
		);

		return runInBatch(
			files
				.filter(
					(file) =>
						file.attachmentMediaApiId &&
						!snippetsAttachmentsMap[file.attachmentId] &&
						!commentMediaIds.includes(file.attachmentMediaApiId) &&
						!insightsMediaIds.includes(file.attachmentMediaApiId),
				)
				.map(
					(file) => () =>
						issuesRemote
							.createIssueAttachment({
								issueIdOrKey: targetIssueKey,
								fileId: file.attachmentMediaApiId,
							})
							.catch(async (err) => {
								// if attachment uploaded to different media collection
								if (err.statusCode === 403) {
									const [blob, attachment] = await Promise.all([
										getFileBinary(
											issueAttachmentsResponse.endpointUrl,
											issueAttachmentsResponse.clientId,
											token,
											file.attachmentMediaApiId,
										),
										getAttachment(file.attachmentId),
									]);
									const formData = new FormData();
									formData.append('file', blob, attachment.filename);
									return createAttachments(targetIssueKey, formData);
								}
								throw err;
							}),
				),
			5,
		);
	});
