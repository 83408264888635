import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_UpdateViewRankV2 as UpdateViewRank,
	jira_polaris_UpdateViewRankV2Variables as UpdateViewRankVariables,
} from './__generated_apollo__/jira_polaris_UpdateViewRankV2';
import type { UpdateViewRankProps, UpdateViewRankReturn } from './types';

const UPDATE_VIEW_RANK_MUTATION = gqlTagPolaris`
mutation jira_polaris_UpdateViewRankV2(
    $id: ID!
    $update: UpdatePolarisViewRankInput!
  ) {
    updatePolarisViewRankV2(id: $id, input: $update) {
      success
      errors {
        message
      }
      node {
        id
        name
        views {
          ...ViewRankProps
        }
        viewsets {
          id
          name
          rank
          views {
            ...ViewRankProps
          }
        }
      }
    }
  }
  
  fragment ViewRankProps on PolarisView {
    __typename
    id
    rank
  }
`;

export const updateViewRank = async (
	apolloClient: PolarisApolloClient,
	props: UpdateViewRankProps,
): Promise<UpdateViewRankReturn> => {
	const result = await apolloClient.mutate<UpdateViewRank, UpdateViewRankVariables>({
		mutation: UPDATE_VIEW_RANK_MUTATION,
		variables: {
			id: props.viewId,
			update: {
				rank: props.rank,
				container: props.containerId,
			},
		},
	});

	if (result.errors !== undefined) {
		throw new Error(
			`project-config.update-view-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.updatePolarisViewRankV2?.node) {
		throw new Error('project-config.update-view-error: no data or no data node');
	}

	return {
		node: { ...result.data.updatePolarisViewRankV2.node },
	};
};
