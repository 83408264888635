import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import type { UpdateCommentResponse } from '../types';
import { getIssueApiUrlForComment } from '../utils';

export const updateCommentInJira = (
	issueIdOrKey: string,
	commentId: string,
	comment: ADF,
): Promise<UpdateCommentResponse> =>
	performPutRequest(getIssueApiUrlForComment(issueIdOrKey, commentId), {
		body: JSON.stringify({ body: comment }),
	});
