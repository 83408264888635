import { Fragment } from 'react';
import type { Command, CommandActionCommandList } from '../../types/commands/index.tsx';
import { CommandPaletteBody } from './body';
import { CommandRow } from './command-row';
import { Footer } from './footer';
import { Header } from './header';

type Components = NonNullable<
	Required<CommandActionCommandList['components'] & Command['components']>
>;

export const components: Components = {
	CommandRow,
	Name: Fragment,
	LeftIcon: Fragment,
	Wrapper: Fragment,
	Footer,
	Body: CommandPaletteBody,
	Header,
};
