import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import { createAri } from '@atlassian/jira-platform-ari';
import type { SnippetPropertiesConfig } from '@atlassian/jira-polaris-domain-insight/src/snippet-properties-config/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { GetSnippetPropertiesConfig } from './types';

export const GET_SNIPPET_PROPERTIES_CONFIG = gqlTagPolaris`
query jira_polaris_GetSnippetPropertiesConfig($project: ID!, $oauthClientId: String!, $groupId: String!) {
  polarisSnippetPropertiesConfig(project: $project, oauthClientId: $oauthClientId, groupId: $groupId) {
      config
  }
}
`;

export const getSnippetPropertiesConfig = (
	client: PolarisApolloClient,
	cloudId: string,
	projectId: string,
	oauthClientId: string,
	groupId: string,
): Promise<SnippetPropertiesConfig | null> => {
	const projectAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

	return client
		.query<GetSnippetPropertiesConfig>({
			query: GET_SNIPPET_PROPERTIES_CONFIG,
			variables: {
				project: projectAri.toString(),
				oauthClientId,
				groupId,
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-insights.polaris-snippet-propeperties-config:${result.errors
						.map((e) => e.message)
						.join(', ')}`,
				);
			}

			if (!result.data) {
				throw new Error(
					'polaris-insights.polaris-snippet-propeperties-config: no data or no data node',
				);
			}

			return (
				(result.data.polarisSnippetPropertiesConfig &&
					result.data.polarisSnippetPropertiesConfig.config) ||
				null
			);
		});
};
