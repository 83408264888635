import type { ViewContext } from '@atlassian/jira-issue-gira-transformer-types';
import {
	FETCH_VIEW_CONTEXT_SUCCESS,
	FETCH_VIEW_CONTEXT_FAILURE,
	MARK_TOKEN_AS_OUTDATED,
	MARK_TOKEN_AS_UP_TO_DATE,
} from './view-context-actions';

const initialState = null;
const FETCH_ERROR = 'FETCH_ERROR'; // Invalid token to be sent to the media service in case of fetch error
const fetchErrorState = {
	clientId: FETCH_ERROR,
	serviceHost: FETCH_ERROR,
	tokenLifespanInMs: 0,
	tokenIssueTimestamp: 0,
	token: null,
	areTokenPermissionsUpToDate: false,
	collection: null,
} as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (state: ViewContext | null = initialState, action: any) => {
	const { type, payload } = action;

	switch (type) {
		case FETCH_VIEW_CONTEXT_FAILURE:
			return fetchErrorState;

		case FETCH_VIEW_CONTEXT_SUCCESS:
			return payload;

		case MARK_TOKEN_AS_OUTDATED:
			return {
				...state,
				areTokenPermissionsUpToDate: false,
			};

		case MARK_TOKEN_AS_UP_TO_DATE:
			return {
				...state,
				areTokenPermissionsUpToDate: true,
			};

		default:
			return state;
	}
};
