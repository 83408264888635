import { defaultTimeTrackingConfiguration } from '@atlassian/jira-common-constants';
import type { JiraSettingsGira } from '@atlassian/jira-issue-shared-types';
import type { JiraSettings } from '@atlassian/jira-issue-view-common-types/src/gira';

export { getQuery, getTimeTrackingQuery } from './graphql';

export const transformTimeTrackingData = (jiraSettings: JiraSettings) => ({
	timeTrackingConfiguration: jiraSettings.timeTracking || defaultTimeTrackingConfiguration,
});

export const transformSubtaskData = (jiraSettings: JiraSettings) => ({
	subtasksConfiguration: jiraSettings.subtasks,
});

export const transformNewIssueViewTransition = (jiraSettings: JiraSettings) => ({
	newIssueViewTransitionSettings: jiraSettings.newIssueViewTransitionSettings,
});

export const transformData = (jiraSettings: JiraSettings): { jiraSettings: JiraSettingsGira } => {
	const newIssueViewTransitionSettings = transformNewIssueViewTransition(jiraSettings);

	return {
		jiraSettings: {
			...newIssueViewTransitionSettings,
		},
	};
};
