import React, { useState, useRef, useMemo, type ElementRef } from 'react';
import MediaServicesPresentationIcon from '@atlaskit/icon/glyph/media-services/presentation';
import Select from '@atlaskit/select';
import { Section, ButtonItem } from '@atlaskit/side-navigation';
import Textfield from '@atlaskit/textfield';
import { N0, N20A, N200 } from '@atlaskit/theme/colors';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import { useSortedSnippetLabels } from '../../../../../../controllers/issue/selectors/properties/insights/hooks';
import { FIELD_ITEM_OUTER_SPACING } from '../../../field-item/constants';
import {
	AppAvatar,
	CustomIconWrapper,
	DecoratorContainer,
	DecoratorItemLabel,
	IconLabel,
	IconLabelWrapper,
	Label,
	LabelWrapper,
	PercentageField,
	PercentageWrapper,
	RollupItem,
	RollupItemComponentWrapper,
	ReadonlyRollupItemContainer,
} from '../styled';
import { RollupItemFieldIconComponent } from './field-icon';
import { messages } from './messages';

export type PercentageProps = {
	value: number | undefined;
	onChangePercentage: (arg1: number | undefined) => void;
};

type RollupItemComponentProps = {
	isPolarisApp?: boolean | undefined;
	label: string;
	fieldKey: FieldKey | undefined;
	appAvatarUrl: string | undefined;
	appName: string | undefined;
	percentage: PercentageProps | undefined;
	filteredLabels: string[] | undefined;
	readonly: boolean;
	onFilterChange: ((arg1: string[] | undefined) => void) | undefined;
	onDelete: () => void;
	defaultValue: number;
	isUnbalanced: boolean;
};

type RollupItemDropdownContentProps = {
	label: string;
	fieldKey: FieldKey | undefined;
	appAvatarUrl: string | undefined;
	appName: string | undefined;
	filteredLabels: string[] | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectRef: ElementRef<any>;
	onFilterChange: ((arg1: string[] | undefined) => void) | undefined;
};

const buttonItemCss = () => ({
	backgroundColor: 'transparent',
});

const RollupItemDropdownContent = ({
	appName,
	label,
	appAvatarUrl,
	fieldKey,
	selectRef,
	filteredLabels,
	onFilterChange,
}: RollupItemDropdownContentProps) => {
	const sortedSnippetLabels = useSortedSnippetLabels();
	const { formatMessage } = useIntl();

	return (
		<>
			{appName !== undefined && appName !== '' && (
				<DecoratorContainer>
					<DecoratorItemLabel>{formatMessage(messages.sourceLabel)}</DecoratorItemLabel>
					<LabelWrapper>
						<IconLabelWrapper>
							{appAvatarUrl !== undefined && <AppAvatar src={appAvatarUrl} alt={appName} />}
							{fieldKey !== undefined && <RollupItemFieldIconComponent fieldKey={fieldKey} />}
							<Label>{appName}</Label>
						</IconLabelWrapper>
					</LabelWrapper>
				</DecoratorContainer>
			)}
			<DecoratorContainer>
				<DecoratorItemLabel>{formatMessage(messages.propertyLabel)}</DecoratorItemLabel>
				<LabelWrapper>
					<Label>{label}</Label>
				</LabelWrapper>
			</DecoratorContainer>
			<DecoratorContainer>
				<DecoratorItemLabel>{formatMessage(messages.dialogLabel)}</DecoratorItemLabel>
				<Select
					// @ts-expect-error - TS2322 - Type '{ components: Partial<SelectComponents<unknown, boolean, GroupTypeBase<unknown>>>; select: StateManager<unknown, false, GroupTypeBase<unknown>, Select<unknown, false, GroupTypeBase<...>>> | null; ... 22 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; }' is not assignable to type '((string | ((instance: { components: Partial<SelectComponents<{ label: string; value: string; }, true, GroupTypeBase<{ label: string; value: string; }>>>; ... 23 more ...; UNSAFE_componentWillUpdate?(nextProps: Readonly<...>, nextState: Readonly<...>, nextContext: any): void; } | null) => void) | RefObject<...>) & (...'.
					ref={selectRef}
					styles={{
						control: (base, { isFocused }) => ({
							...base,
							backgroundColor: isFocused ? N0 : N20A,
						}),
					}}
					options={sortedSnippetLabels.map((snippetLabel) => ({
						label: snippetLabel,
						value: snippetLabel,
					}))}
					value={
						filteredLabels
							? filteredLabels.map((snippetLabel) => ({
									label: snippetLabel,
									value: snippetLabel,
								}))
							: undefined
					}
					isMulti
					isSearchable
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onChange={(selectedOptions: any) => {
						onFilterChange &&
							onFilterChange(
								selectedOptions
									? // @ts-expect-error - TS7031 Parameter 'base' implicitly has an 'any' type.
										selectedOptions.map(({ value }) => value)
									: undefined,
							);
					}}
					placeholder={formatMessage(messages.selectionPlaceholder)}
				/>
			</DecoratorContainer>
		</>
	);
};

export const RollupItemComponent = ({
	isPolarisApp = false,
	label,
	fieldKey,
	appAvatarUrl,
	appName,
	filteredLabels,
	onDelete,
	percentage,
	onFilterChange,
	isUnbalanced,
	readonly,
	defaultValue,
}: RollupItemComponentProps) => {
	const { formatMessage } = useIntl();
	const [percentageValue, setPercentageValue] = useState<unknown>(undefined);
	const formatPercentage = (value: number) => value.toFixed(0);

	const selectRef = useRef<unknown>(null);
	const focusOnSelect = () => {
		if (selectRef && selectRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			selectRef.current.focus();
		}
	};

	const [isOpen, setIsOpen] = useState(false);

	const { placeholder, value, readonlyValue } = useMemo(() => {
		if (percentage?.value !== undefined) {
			return {
				readonlyValue: `${formatPercentage(percentage.value)}%`,
				value: formatPercentage(percentage.value),
				placeholder: undefined,
			};
		}
		return {
			readonlyValue: `${formatPercentage(defaultValue)}%`,
			value: undefined,
			placeholder: formatPercentage(defaultValue),
		};
	}, [defaultValue, percentage?.value]);

	const rollupItem = (
		<>
			{percentage && (
				<PercentageField>
					{readonly ? (
						readonlyValue
					) : (
						<Textfield
							isReadOnly={readonly}
							appearance={readonly ? 'none' : 'standard'}
							data-interactive
							value={value}
							placeholder={placeholder}
							onChange={(event) => {
								if (!event || !event.target) {
									return;
								}
								// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
								setPercentageValue(event.target.value);
								if (
									// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
									event.target.value === undefined ||
									// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
									event.target.value === null ||
									// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
									event.target.value === ''
								) {
									percentage.onChangePercentage(undefined);
								} else {
									// @ts-expect-error - TS2339 - Property 'strValue' does not exist on type 'EventTarget'.
									const strValue = Number(event.target.value);
									const safeValue = Number.isSafeInteger(strValue) ? strValue : undefined;
									percentage.onChangePercentage(safeValue);
								}
							}}
							isInvalid={Number.isNaN(Number(percentageValue || 0)) || isUnbalanced}
							width={58}
							isCompact
							elemAfterInput={<PercentageWrapper>%</PercentageWrapper>}
						/>
					)}
				</PercentageField>
			)}
			<RollupItem data-testid="polaris-common.ui.config.fields.common.rollup-formula-item.item.rollup-item">
				{!isOpen && (
					<LabelWrapper>
						<IconLabelWrapper>
							<IconLabel>
								{!isPolarisApp ? (
									<>
										{appAvatarUrl !== undefined && <AppAvatar src={appAvatarUrl} alt={appName} />}
										{fieldKey !== undefined && <RollupItemFieldIconComponent fieldKey={fieldKey} />}
									</>
								) : (
									<CustomIconWrapper>
										{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
										<MediaServicesPresentationIcon label="" size="small" primaryColor={N200} />
									</CustomIconWrapper>
								)}
							</IconLabel>
							<Label>{label}</Label>
						</IconLabelWrapper>
					</LabelWrapper>
				)}
			</RollupItem>
		</>
	);

	return (
		<RollupItemComponentWrapper>
			{readonly ? (
				<ReadonlyRollupItemContainer>{rollupItem}</ReadonlyRollupItemContainer>
			) : (
				<MenuOption
					menuComponent={
						<Section>
							{onFilterChange && (
								<>
									<ButtonItem
										// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
										cssFn={buttonItemCss}
										onClick={() => setIsOpen(true)}
									>
										{formatMessage(messages.detailsMenuLabel)}
									</ButtonItem>
									<ButtonItem
										// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
										cssFn={buttonItemCss}
										onClick={() => {
											setIsOpen(true);
											setTimeout(() => focusOnSelect(), 25);
										}}
									>
										{formatMessage(messages.filterMenuLabel)}
									</ButtonItem>
								</>
							)}
							{/* eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis */}
							<ButtonItem cssFn={buttonItemCss} onClick={onDelete}>
								{formatMessage(messages.deleteMenuLabel)}
							</ButtonItem>
						</Section>
					}
					isActive={isOpen}
					isDragEnabled={false}
					setOpenOption={() => setIsOpen(false)}
					outerSpacing={FIELD_ITEM_OUTER_SPACING}
				>
					{rollupItem}
				</MenuOption>
			)}
			{!readonly && isOpen && (
				<RollupItemDropdownContent
					appAvatarUrl={appAvatarUrl}
					appName={appName}
					fieldKey={fieldKey}
					filteredLabels={filteredLabels}
					selectRef={selectRef}
					label={label}
					onFilterChange={onFilterChange}
				/>
			)}
		</RollupItemComponentWrapper>
	);
};
