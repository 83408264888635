import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { STATUS_ONLY } from '@atlassian/jira-polaris-common/src/ui/linked-issues-progress';
import type { StatusCategoryKey } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import messages from '../../messages';
import { ProgressItem } from '../../progress-item';

type DeliveryTicketItemSkeletonProps = {
	issueKey: string;
	status: {
		label: string;
		type: StatusCategoryKey;
	};
	nameLengthPx: number;
};

const DeliveryTicketItemSkeleton = (props: DeliveryTicketItemSkeletonProps) => (
	<DeliveryTicketContainer>
		<DeliveryTicketIcon />
		<DeliveryTicketKeyContainer>{props.issueKey}</DeliveryTicketKeyContainer>
		<DeliveryTicketNameContainer>
			<DeliveryTicketName width={props.nameLengthPx} />
		</DeliveryTicketNameContainer>
		<DeliveryTicketStatusContainer>
			<ProgressItem
				distribution={{}}
				status={{
					name: props.status.label,
					statusCategoryKey: props.status.type,
				}}
				appearance={STATUS_ONLY}
			/>
		</DeliveryTicketStatusContainer>
	</DeliveryTicketContainer>
);

export const DeliveryTicketsSkeleton = () => {
	const { formatMessage } = useIntl();

	return (
		<DeliveryTicketsContainer>
			<SectionHeading>{formatMessage(messages.sectionHeading)}</SectionHeading>
			<DeliveryTicketItemsWrapper>
				<DeliveryTicketItemSkeleton
					issueKey="KEY-123"
					status={{
						label: 'Parking lot',
						type: 'new',
					}}
					nameLengthPx={140}
				/>
				<DeliveryTicketItemSkeleton
					issueKey="KEY-124"
					status={{
						label: 'In progress',
						type: 'indeterminate',
					}}
					nameLengthPx={230}
				/>
				<DeliveryTicketItemSkeleton
					issueKey="KEY-125"
					status={{
						label: 'Done',
						type: 'done',
					}}
					nameLengthPx={140}
				/>
			</DeliveryTicketItemsWrapper>
			<FadeOverlay />
		</DeliveryTicketsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketsContainer = styled.div({
	marginTop: token('space.300', '24px'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeading = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	fontWeight: '600',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.disabled', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketItemsWrapper = styled.div({
	marginTop: token('space.100', '8px'),
	height: '220px', // pinned height here is needed to make theme-aware FadeOverlay bottom part softer
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border', colors.N30A)}`,
	padding: token('space.150', '12px'),
	alignItems: 'center',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		borderBottom: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketIcon = styled.div({
	width: '16px',
	height: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtler', colors.N300),
	borderRadius: '3px',
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketKeyContainer = styled.div({
	padding: `0 ${token('space.150', '12px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.disabled', colors.N500),
	fontWeight: '600',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketNameContainer = styled.div({
	padding: `0 ${token('space.150', '12px')}`,
	flexGrow: '1',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketName = styled.div<{ width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => `${props.width}px`,
	height: '8px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtler', colors.N300),
	borderRadius: '10px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DeliveryTicketStatusContainer = styled.div({
	flex: '0 0 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FadeOverlay = styled.div({
	position: 'absolute',
	bottom: '0px',
	width: '100%',
	height: '100%',
	backgroundImage: `linear-gradient(to bottom, transparent, ${token(
		'elevation.surface',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		colors.N0,
	)})`,
});
