import React, { createContext, type ReactNode } from 'react';
import noop from 'lodash/noop';
import type {
	PolarisCommentsUpdateSignal,
	PolarisIdeasCommonContextType,
	PolarisIdeasContextListener,
	PolarisInsightUpdateSignal,
} from './types';

const NOOP_CONTEXT: PolarisIdeasCommonContextType = {
	register: noop,
	unregister: noop,
	onUpdateInsight: noop,
	onUpdateComments: noop,
};

const createPolarisIdeaCommonContext = (): PolarisIdeasCommonContextType => {
	const listeners: PolarisIdeasContextListener[] = [];

	return {
		register: (listener: PolarisIdeasContextListener) => {
			listeners.push(listener);
		},
		unregister: (listener: PolarisIdeasContextListener) => {
			listeners.splice(listeners.indexOf(listener), 1);
		},
		onUpdateInsight: (signal: PolarisInsightUpdateSignal) =>
			listeners.forEach((listener) => listener.onUpdateInsight && listener.onUpdateInsight(signal)),
		onUpdateComments: (signal: PolarisCommentsUpdateSignal) =>
			listeners.forEach(
				(listener) => listener.onUpdateComments && listener.onUpdateComments(signal),
			),
	};
};

export const PolarisIdeasCommonContext = createContext<PolarisIdeasCommonContextType>(NOOP_CONTEXT);

export const PolarisIdeasCommonContextProvider = ({ children }: { children: ReactNode }) => (
	<PolarisIdeasCommonContext.Provider value={createPolarisIdeaCommonContext()}>
		{children}
	</PolarisIdeasCommonContext.Provider>
);
