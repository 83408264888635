import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	performPutRequest,
	performDeleteRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	MarkersResponse,
	FetchViewMarkers,
	CreateViewMarker,
	DeleteViewMarker,
	UpdateViewMarker,
} from './types';
import { getMarkersUrl, getMarkersUrlWithId } from './utils';

export const fetchViewMarkers: FetchViewMarkers = (viewUUID) =>
	fetchJson<MarkersResponse>(getMarkersUrl(viewUUID));

export const createViewMarker: CreateViewMarker = (viewUUID, marker) =>
	performPostRequest(getMarkersUrl(viewUUID), {
		body: JSON.stringify(marker),
	});

export const updateViewMarker: UpdateViewMarker = (viewUUID, updatedMarker) => {
	if (updatedMarker.id) {
		return performPutRequest(getMarkersUrlWithId(viewUUID, updatedMarker.id), {
			body: JSON.stringify(updatedMarker),
		});
	}
};

export const deleteViewMarker: DeleteViewMarker = (viewUUID, markerId) =>
	performDeleteRequest(getMarkersUrlWithId(viewUUID, markerId));
