import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

const getIssueTypeQuery = (projectId: string, issueTypeId: IssueTypeId) =>
	`query IssueTypePageSingleIssueType {
      simplifiedProject(projectId: ${projectId}) {
        issueType(id: ${issueTypeId}) {
          id
          externalUuid
          name
        }
      }
    }`;

export const generateGraphqlUrl = (): string => '/rest/gira/latest/?jpd-workflow-editor';

export const getIssueTypeExternalUuid = (
	issueTypeId: IssueTypeId,
	projectId: string,
): Promise<string | undefined> =>
	fetchJson(generateGraphqlUrl(), {
		method: 'POST',
		body: JSON.stringify({
			query: getIssueTypeQuery(projectId, issueTypeId),
		}),
	}).then((response) => response?.data?.simplifiedProject?.issueType?.externalUuid);
