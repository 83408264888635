import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

const MATRIX_SUPPORTED_FIELD_TYPES_X_AND_Y_AXES: FieldType[] = [
	FIELD_TYPES.NUMBER,
	FIELD_TYPES.SLIDER,
	FIELD_TYPES.RATING,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.STATUS,
	FIELD_TYPES.CREATOR,
	FIELD_TYPES.REPORTER,
	FIELD_TYPES.ASSIGNEE,
	FIELD_TYPES.VOTES,
	FIELD_TYPES.ISSUE_COMMENTS,
];

const MATRIX_SUPPORTED_FIELD_TYPES_Z_AXIS: FieldType[] = [
	FIELD_TYPES.NUMBER,
	FIELD_TYPES.SLIDER,
	FIELD_TYPES.RATING,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.INSIGHTS,

	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,

	FIELD_TYPES.VOTES,
	FIELD_TYPES.ISSUE_COMMENTS,
];

export const isMatrixSupportedField =
	(dimension: string) =>
	(field: Field): boolean => {
		let supportedFields: FieldType[] = [];

		if (dimension === 'x' || dimension === 'y') {
			supportedFields = MATRIX_SUPPORTED_FIELD_TYPES_X_AND_Y_AXES;
		} else if (dimension === 'z') {
			supportedFields = MATRIX_SUPPORTED_FIELD_TYPES_Z_AXIS;
		}

		return supportedFields.includes(field.type);
	};
