import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import isPlainObject from 'lodash/isPlainObject';
import { Emoji, type EmojiProvider, type OptionalEmojiDescription } from '@atlaskit/emoji';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type {
	SnippetPropertiesObject,
	SnippetPropertyValueType,
	SnippetRefreshStatus,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import {
	getSnippetPropertyName,
	getSnippetPropertyNameDecoration,
	getSnippetPropertyType,
	getSnippetPropertyValue,
	getSnippetPropertyValueDecoration,
} from '../../../common/utils/snippet';
import { useIsSelectedIssueArchived } from '../../../controllers/issue/selectors/properties/hooks';
import { ActionProperties } from './actions';
import { Tag } from './tag';

const LABELS_PROPERTY_KEY = 'labels';

type PropertiesProps = {
	isReadOnly: boolean;
	icon?: string | undefined;
	properties: SnippetPropertiesObject | null;
	emojiProvider?: Promise<EmojiProvider>;
	refresh?: SnippetRefreshStatus | null;
	updated: string | null;
	onConfigureProperties?: () => void;
	onSnippetRefreshRequested?: () => void;
};

type PropertyProps = {
	icon?: string | undefined;
	name: string;
	type?: string;
	value: SnippetPropertyValueType;
	emojiProvider?: Promise<EmojiProvider>;
};

const Property = ({ icon, name, value, type, emojiProvider }: PropertyProps) => {
	const [emoji, setEmoji] = useState<OptionalEmojiDescription | null>(null);
	useEffect(() => {
		(async () => {
			const resolvedEmojiProvider = await Promise.resolve(emojiProvider);
			const anEmoji = await resolvedEmojiProvider?.findByShortName(name);
			setEmoji(anEmoji);
		})();
	}, [emojiProvider, name]);

	if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
		return null;
	}

	const decoratedValue = getSnippetPropertyValueDecoration(value, type);
	const decoratedName = getSnippetPropertyNameDecoration(name);
	const nameEl = emoji ? (
		<EmojiWrapper>
			<Emoji fitToHeight={16} emoji={emoji} />
		</EmojiWrapper>
	) : (
		`${decoratedName}${decoratedName.slice(-1) === ':' ? '' : ''}`
	);
	return (
		<PropertyWrapper
			data-testid="polaris-common.ui.snippet.properties.property-wrapper"
			key={decoratedName}
		>
			{icon !== undefined && <Icon src={icon} />}
			<PropertyName>{nameEl}</PropertyName>
			<PropertyValue>{decoratedValue}</PropertyValue>
		</PropertyWrapper>
	);
};

const LabelProperty = ({
	value,
	icon,
}: {
	value: SnippetPropertyValueType;
	icon?: string | undefined;
}) => {
	if (value === undefined || !Array.isArray(value) || value.length === 0) {
		return null;
	}
	const sortedLabels = value.sort();
	return (
		<>
			<PropertyWrapper data-testid="polaris-common.ui.snippet.properties.property-wrapper-label">
				{icon !== undefined && <Icon src={icon} />}
				<PropertyName>Labels</PropertyName>
				{sortedLabels.map((label) => (
					<Tag key={label} compact value={label} />
				))}
			</PropertyWrapper>
		</>
	);
};

export const isPropertiesDefined = (properties: SnippetPropertiesObject | null): boolean =>
	properties !== null && isPlainObject(properties) && Object.keys(properties).length > 0;

export const Properties = ({
	isReadOnly,
	icon,
	properties,
	emojiProvider,
	refresh,
	updated,
	onSnippetRefreshRequested,
	onConfigureProperties,
}: PropertiesProps) => {
	const isArchived = useIsSelectedIssueArchived();

	if ((!onConfigureProperties || isReadOnly || isArchived) && !isPropertiesDefined(properties)) {
		return null;
	}

	const labelsValue = properties
		? getSnippetPropertyValue(properties, LABELS_PROPERTY_KEY)
		: undefined;

	return (
		<Wrapper data-testid="polaris-common.ui.snippet.properties.wrapper">
			{properties !== null && isPropertiesDefined(properties)
				? Object.keys(properties)
						.filter((key) => key !== LABELS_PROPERTY_KEY)
						.sort()
						.map((key) => {
							const name = getSnippetPropertyName(properties, key);
							const type = getSnippetPropertyType(properties, key);
							const value = getSnippetPropertyValue(properties, key);
							return (
								<Property
									icon={icon}
									emojiProvider={emojiProvider}
									key={key}
									name={name}
									type={type}
									value={value}
								/>
							);
						})
				: null}
			<LabelProperty icon={icon} value={labelsValue} />
			{isReadOnly || isArchived ? null : (
				<ActionProperties
					alwaysVisible={!isPropertiesDefined(properties)}
					refresh={refresh}
					updated={updated}
					onSnippetRefreshRequested={onSnippetRefreshRequested}
					onConfigureProperties={onConfigureProperties}
				/>
			)}
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	padding: `${token('space.050', '4px')} ${token('space.200', '16px')} 0px 0px`,
	display: 'flex',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	width: '12px',
	height: '12px',
	marginRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertyWrapper = styled.span({
	lineHeight: '20px',
	marginRight: token('space.400', '32px'),
	marginTop: token('space.100', '8px'),
	display: 'inline-flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.div({
	marginRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		marginTop: token('space.negative.050', '-4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertyName = styled.span({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PropertyValue = styled.span({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	marginLeft: token('space.100', '8px'),
	lineHeight: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});
