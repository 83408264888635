import React from 'react';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	useProjectMetadataByKey,
	useProjectsKeysForContainer,
	useProjectsMetadataLoading,
} from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import { ProjectTag } from '@atlassian/jira-polaris-lib-project-tag/src/ui/index.tsx';
import messages from './messages';
import { ProjectsSkeleton } from './skeleton';

type Props = {
	collectionUUID: string | undefined;
};

const ProjectLinkTag = ({ projectKey }: { projectKey: string }) => {
	const project = useProjectMetadataByKey({ projectKey });

	if (!project) {
		return null;
	}

	return (
		<ProjectTag
			projectKey={project.key}
			projectName={project.name}
			projectAvatarUrl={project.avatarUrls['16x16']}
			isLink
			maxWidth={160}
		/>
	);
};

export const ProjectsList = ({ collectionUUID }: Props) => {
	const { formatMessage } = useIntl();
	const isProjectsLoading = useProjectsMetadataLoading({ containerId: collectionUUID });
	const projectKeys = useProjectsKeysForContainer({ containerId: collectionUUID });

	if (isProjectsLoading) {
		return <ProjectsSkeleton />;
	}

	if (projectKeys.length === 0) {
		return <Text color="color.text.subtlest">{formatMessage(messages.noProjectsMessage)}</Text>;
	}

	return (
		<Stack space="space.150">
			{projectKeys.map((projectKey) => (
				<Box key={projectKey} xcss={tagContainerStyles}>
					<ProjectLinkTag projectKey={projectKey} />
				</Box>
			))}
		</Stack>
	);
};

const tagContainerStyles = xcss({
	width: 'fit-content', // fixes tooltip positioning
});
