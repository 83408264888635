import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	jira_polaris_GetSnippetProviders as GetSnippetProviders,
	jira_polaris_GetSnippetProviders_polarisProject_snippetProviders as SnippetProvidersGQL,
} from './__generated_apollo__/jira_polaris_GetSnippetProviders';
import type { ArchivedMode } from './types';

const GET_SNIPPET_PROVIDERS_QUERY = gqlTagPolaris`
query jira_polaris_GetSnippetProviders($ari: ID!, $archivedMode: ArchivedMode!) {
    polarisProject(id: $ari, skipRefresh: true) {
        id
        snippetProviders(archivedMode: $archivedMode) {
            id
            app {
              id
              name
              avatarUrl
              oauthClientId
            }
            properties {
              id
              key
              label
              kind
            }
        }
    }
}
`;

export const fetchSnippetProviders = async (
	apolloClient: PolarisApolloClient,
	ari: Ari,
	archivedMode: ArchivedMode,
): Promise<ReadonlyArray<SnippetProvidersGQL>> => {
	const result = await apolloClient.query<GetSnippetProviders>({
		query: GET_SNIPPET_PROVIDERS_QUERY,
		variables: {
			ari,
			archivedMode,
		},
		fetchPolicy: 'no-cache',
	});

	if (result.errors !== undefined) {
		throw new Error(
			`fields.get-snippet-providers-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data.polarisProject?.snippetProviders) {
		throw new Error('fields.get-snippet-providers-error: no data');
	}

	return result.data.polarisProject.snippetProviders;
};
