import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	buttonLabel: {
		id: 'polaris-ideas.view-controls.manage-bar.archive-ideas.button-label',
		defaultMessage: 'Archive',
		description: 'Button label to archive selected ideas',
	},
	notificationTitle: {
		id: 'polaris-ideas.view-controls.manage-bar.archive-ideas.notification-title',
		defaultMessage: 'Your ideas were archived',
		description: 'Archivation success notification title',
	},
	notificationDescription: {
		id: 'polaris-ideas.view-controls.manage-bar.archive-ideas.notification-description',
		defaultMessage: 'They won’t appear in any views or be used in formula calculations.',
		description: 'Archivation success notification description',
	},
	notificationViewArchiveButton: {
		id: 'polaris-ideas.view-controls.manage-bar.archive-ideas.notification-view-archive-button',
		defaultMessage: 'View archive',
		description: 'Archivation success notification view archive button label',
	},
	notificationUndoButton: {
		id: 'polaris-ideas.view-controls.manage-bar.archive-ideas.notification-undo-button',
		defaultMessage: 'Undo',
		description: 'Archivation success notification undo button label',
	},
});
