import React, { memo } from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { ReactRenderer } from '@atlaskit/renderer';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { fg } from '@atlassian/jira-feature-gating';
import { onLinkClick, smartLinksDefault } from '@atlassian/jira-linking-platform-utils';
import { MountEvent, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	description: JSONDocNode;
};

const Card = memo<Props>(({ description }: Props) => {
	const onMount = (analyticsEvent: UIAnalyticsEvent) =>
		fireTrackAnalytics(analyticsEvent, 'CustomFieldDescription mounted');

	return (
		<Container>
			<MountEvent onMount={onMount} />
			<ReactRenderer
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				document={description as DocNode}
				schema={defaultSchema}
				disableHeadingIDs
				eventHandlers={{
					smartCard: { onClick: onLinkClick },
					link: { onClick: onLinkClick },
				}}
				featureFlags={{
					codeBidiWarnings: true,
					'code-bidi-warnings': true,
					'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
				}}
				smartLinks={smartLinksDefault}
			/>
		</Container>
	);
});

export default Card;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 32}px`,
	width: 'auto',
});
