import { createSelector } from 'reselect';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Props, State, CommentsMetadataMap, IssueMetadata } from '../../../types';
import { getIsSharedView } from '../../view';

const getIssueMetadataProperties = (state: State): Record<LocalIssueId, IssueMetadata> =>
	state.properties.issueMetadata;

const getCurrentUser = (state: State, props?: Props): AccountId | undefined => props?.currentUser;

const getCommentsMetadataMap = (state: State): CommentsMetadataMap =>
	state.properties.commentsMetadata;

export const createGetCommentsCount = (id: LocalIssueId) => (state: State) =>
	getIssueMetadataProperties(state)[id]?.comments.count || 0;

const createGetLatestCommentCreatedTimestamp =
	(id: LocalIssueId) =>
	(state: State): string | undefined =>
		getIssueMetadataProperties(state)[id]?.comments.lastCreated || undefined;

const createGetLatestCommentAuthor =
	(id: LocalIssueId) =>
	(state: State): string | undefined =>
		getIssueMetadataProperties(state)[id]?.comments.lastCreatedAuthor || undefined;

export const createGetCommentsCounts = (ids: LocalIssueId[]) => (state: State) => {
	const commentIdsCount: Record<string, number> = {};
	ids.forEach((id) => {
		commentIdsCount[id] = createGetCommentsCount(id)(state);
	});
	return commentIdsCount;
};

export const createGetTimestampCurrentUserSeenComments =
	(localIssueId: LocalIssueId) => (state: State) =>
		getCommentsMetadataMap(state)[localIssueId]?.timestampCurrentUserSeenComments;

export const createIsIssueHavingUnseenComments = (localIssueId: LocalIssueId) =>
	createSelector(
		createGetTimestampCurrentUserSeenComments(localIssueId),
		createGetCommentsCount(localIssueId),
		createGetLatestCommentCreatedTimestamp(localIssueId),
		createGetLatestCommentAuthor(localIssueId),
		getCurrentUser,
		getIsSharedView,
		(
			timestampCurrentUserSeenComments,
			commentsCount,
			latestCreatedTimestamp,
			latestAuthor,
			currentUser,
			isSharedView,
		) => {
			// TODO: Remove this check when we have support for "last viewed" in shared views https://pi-dev-sandbox.atlassian.net/browse/POL-6124
			if (isSharedView) {
				return false;
			}

			if (commentsCount === 0 || currentUser === undefined) {
				return false;
			}

			if (
				timestampCurrentUserSeenComments === undefined ||
				latestCreatedTimestamp === undefined ||
				latestAuthor === undefined
			) {
				return true;
			}

			return (
				new Date(latestCreatedTimestamp).getTime() > timestampCurrentUserSeenComments &&
				latestAuthor !== currentUser
			);
		},
	);
