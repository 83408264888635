import { useRef, useEffect } from 'react';
import type { Placement } from '@atlaskit/popper';

// evHook
export const useEventListener = (
	eventName: string,
	handler: () => void,
	element: HTMLElement | null,
	// @ts-expect-error - TS2304 - Cannot find name 'EventListenerOptionsOrUseCapture'.
	options: EventListenerOptionsOrUseCapture,
) => {
	// Create a ref that stores handler
	const savedHandler = useRef<unknown>();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			// Create event listener that calls handler function stored in ref
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			const eventListener = () => savedHandler.current && savedHandler.current();

			// Add event listener
			element && element.addEventListener(eventName, eventListener, options);

			// Remove event listener on cleanup
			return () => {
				element && element.removeEventListener(eventName, eventListener, options);
			};
		},
		[eventName, element, options], // Re-run if eventName or element changes
	);
};

type UseHideProfileCardDelayedType = {
	getTimeoutId: () => null | number;
	setCardRef: () => void;
	setTriggerRef: () => void;
	unsetCardRef: () => void;
	unsetTriggerRef: () => void;
};

export const useHideProfileCardDelayed = (
	initialfirst: boolean,
	initialSecond: boolean,
	callback: () => void,
): UseHideProfileCardDelayedType => {
	const triggerRef = useRef<boolean>(initialfirst);
	const cardRef = useRef<boolean>(initialSecond);
	const timeoutId = useRef<unknown>(null);

	const setStateDelayed = () => {
		if (timeoutId.current) {
			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(timeoutId.current);
		}
		timeoutId.current = setTimeout(() => {
			if (!triggerRef.current && !cardRef.current) {
				callback();
			}
		}, 400);
	};

	const setTriggerRef = () => {
		triggerRef.current = true;
		setStateDelayed();
	};

	const unsetTriggerRef = () => {
		triggerRef.current = false;
		setStateDelayed();
	};
	const setCardRef = () => {
		cardRef.current = true;
		setStateDelayed();
	};
	const unsetCardRef = () => {
		cardRef.current = false;
		setStateDelayed();
	};

	const getTimeoutId = () => timeoutId.current;
	return {
		setTriggerRef,
		setCardRef,
		unsetTriggerRef,
		unsetCardRef,
		// @ts-expect-error - TS2322 - Type '() => unknown' is not assignable to type '() => number | null'.
		getTimeoutId,
	};
};

export const getPlacement = (referenceElement: HTMLElement): Placement => {
	if (
		referenceElement &&
		typeof referenceElement.id === 'string' &&
		referenceElement.id.indexOf('issue_summary') > -1
	) {
		return 'left-start';
	}

	return 'top-start';
};
