import React from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

type Props = {
	onClick: () => void;
};

export const TryAgainButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Button testId="polaris-common.controllers.unfurl.utils.retry.button" onClick={onClick}>
			{formatMessage(messages.tryAgainButton)}
		</Button>
	);
};
