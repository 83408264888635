/** @jsx jsx */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@compiled/react';
import keyBy from 'lodash/keyBy';
import { IconButton } from '@atlaskit/button/new';
import EyeOpen from '@atlaskit/icon/core/eye-open';
import EyeOpenStrikethrough from '@atlaskit/icon/core/eye-open-strikethrough';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldFilter } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import { DecoratedRating } from '@atlassian/jira-polaris-common/src/ui/decoration/rating/index.tsx';
import { CheckboxField } from '@atlassian/jira-polaris-common/src/ui/fields/checkbox';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { Draggable, useDroppableEvents } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { type ExtendedOption, EMPTY_VALUE_ID } from '../../../../common/utils/board';
import {
	useRestrictedOptions,
	useExtendedOptionsInNaturalOrder,
} from '../../../view-content/common/utils/field-visibility-options';
import {
	getNewFieldValueFilterOnHide,
	getNewFieldValueFilterOnShow,
} from '../../../view-content/idea-board/column/header/utils';
import { dateFieldValueConfig } from './dates';
import { EmptyFieldValue, emptyFieldValueConfig } from './empty';
import { externalReferenceFieldValueConfig } from './external-reference';
import { externalReferencePropertyFieldValueConfig } from './external-reference-property';
import { labelsFieldValueConfig } from './labels';
import { FieldValueContent as FieldValueContentLegacy, Values as ValuesLegacy } from './legacy';
import { messages } from './messages';
import { numberFieldValueConfig } from './numbers';
import { optionsFieldValueConfig } from './options';
import { projectFieldValueConfig } from './project';
import { reactionsFieldValueConfig } from './reactions';
import { statusFieldValueConfig } from './status';
import type { FieldValueConfig } from './types';
import { userFieldValueConfig } from './users';

const fieldValues: FieldValueConfig = {
	config: {
		[FIELD_TYPES.SINGLE_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.MULTI_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.JSW_MULTI_SELECT]: optionsFieldValueConfig,
		[FIELD_TYPES.STATUS]: statusFieldValueConfig,
		[FIELD_TYPES.ASSIGNEE]: userFieldValueConfig,
		[FIELD_TYPES.CREATOR]: userFieldValueConfig,
		[FIELD_TYPES.REPORTER]: userFieldValueConfig,
		[FIELD_TYPES.PEOPLE]: userFieldValueConfig,
		[FIELD_TYPES.JSW_PEOPLE]: userFieldValueConfig,
		[FIELD_TYPES.LABELS]: labelsFieldValueConfig,
		[FIELD_TYPES.CUSTOM_LABELS]: labelsFieldValueConfig,
		[FIELD_TYPES.NUMBER]: numberFieldValueConfig,
		[FIELD_TYPES.FORMULA]: numberFieldValueConfig,
		[FIELD_TYPES.SLIDER]: numberFieldValueConfig,
		[FIELD_TYPES.RATING]: numberFieldValueConfig,
		[FIELD_TYPES.CHECKBOX]: numberFieldValueConfig,
		[FIELD_TYPES.INSIGHTS]: numberFieldValueConfig,
		[FIELD_TYPES.DATE]: dateFieldValueConfig,
		[FIELD_TYPES.ATLAS_GOAL]: externalReferenceFieldValueConfig,
		[FIELD_TYPES.ATLAS_PROJECT]: externalReferenceFieldValueConfig,
		[FIELD_TYPES.ATLAS_PROJECT_STATUS]: externalReferencePropertyFieldValueConfig,
		[FIELD_TYPES.REACTIONS]: reactionsFieldValueConfig,
		[FIELD_TYPES.PROJECT]: projectFieldValueConfig,
	},
	default: emptyFieldValueConfig,
};

type FieldValueContentProps = {
	field: Field;
	fieldKey: FieldKey;
	groupIdentity?: string;
};

export const FieldValueContent = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	memo<FieldValueContentProps>(({ field, fieldKey, groupIdentity }: FieldValueContentProps) => {
		const decorations = useDecorationsForField(fieldKey);

		const { Component, EmptyComponent } =
			(field.type && fieldValues.config[field.type]) || fieldValues.default;

		if (groupIdentity === undefined) {
			const SafeEmptyComponent = EmptyComponent || EmptyFieldValue;
			return <SafeEmptyComponent fieldKey={fieldKey} />;
		}
		if (field.type === FIELD_TYPES.CHECKBOX) {
			return <CheckboxField value={Number(groupIdentity)} />;
		}
		if (field.type === FIELD_TYPES.RATING) {
			return <DecoratedRating value={Number(groupIdentity)} decorations={decorations} />;
		}
		return <Component fieldKey={fieldKey} groupIdentity={groupIdentity} />;
	}),
	FieldValueContentLegacy,
);

const NO_VALUE_RESTRICTION = 'no-value';

type ValuesProps = {
	field: Field;
	options: ExtendedOption<unknown>[];
	setOptions: (options: GroupValue[]) => void;
	isDisabledDnD?: boolean;
	isHideButtonVisible?: boolean;
};

export const Values = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	({
		field,
		options,
		setOptions,
		isDisabledDnD = false,
		isHideButtonVisible = false,
	}: ValuesProps) => {
		const { formatMessage } = useIntl();
		const restrictions = useRestrictedOptions(field);
		const restrictionsMap = useMemo(
			() => keyBy(restrictions, ({ groupIdentity }) => groupIdentity ?? NO_VALUE_RESTRICTION),
			[restrictions],
		);

		const sortedGroupOptions = useSortedGroupOptions(field.key);
		const statusOptions = useExtendedOptionsInNaturalOrder(field);
		const { updateFieldFilter } = useViewActions();
		const fieldFilter = useFieldFilter(field.key);
		const [localOptions, setLocalOptions] = useState(options);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		useEffect(() => {
			setLocalOptions(options);
		}, [options]);

		const onShowGroupIdentity = (groupIdentity?: string) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
				'showInView',
				{
					fieldValueId: groupIdentity,
				},
			);
			sendPendoTrackEvent({
				actionSubjectAndAction: 'icon clicked',
				actionSubjectId: 'showInView',
				attributes: { fieldValueId: groupIdentity || '' },
			});
			updateFieldFilter(
				getNewFieldValueFilterOnShow(
					field,
					sortedGroupOptions,
					statusOptions,
					fieldFilter,
					groupIdentity,
				),
			);
		};

		const onHideGroupIdentity = (groupIdentity?: string) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'icon' }),
				'hideInView',
				{
					fieldValueId: groupIdentity,
				},
			);
			sendPendoTrackEvent({
				actionSubjectAndAction: 'icon clicked',
				actionSubjectId: 'hideInView',
				attributes: { fieldValueId: groupIdentity || '' },
			});
			updateFieldFilter(
				getNewFieldValueFilterOnHide(
					field,
					sortedGroupOptions,
					statusOptions,
					fieldFilter,
					groupIdentity,
				),
			);
		};

		const { isHideable: isFieldHideable } =
			(field?.type !== undefined ? fieldValues.config[field.type] : undefined) ||
			fieldValues.default;

		const onSort = useCallback(
			({ srcId, dstId, edge }: { srcId: number; dstId: number; edge: Edge }) => {
				let dstAdj = srcId < dstId && edge === 'top' ? -1 : 0;
				dstAdj = srcId > dstId && edge === 'bottom' ? 1 : dstAdj;

				const newOrder: ExtendedOption<unknown>[] = [...localOptions];
				const [removed] = newOrder.splice(srcId, 1);
				newOrder.splice(dstId + dstAdj, 0, removed);

				const newSortedGroupOptions = newOrder.map((option: ExtendedOption<unknown>) => ({
					id: option.groupIdentity ?? EMPTY_VALUE_ID,
				}));

				setLocalOptions(newOrder);
				setOptions(newSortedGroupOptions);
			},
			[localOptions, setOptions],
		);

		useDroppableEvents({
			onSort,
		});

		const isDragDisabled = isDisabledDnD || localOptions.length === 1;

		if (!field) {
			return <Box xcss={valuesContainerStyles} />;
		}

		return (
			<Box xcss={valuesContainerStyles}>
				{localOptions.map((option, index) => {
					const isValueHidden = !restrictionsMap[option.groupIdentity ?? NO_VALUE_RESTRICTION];

					return (
						<Draggable isDragDisabled={isDragDisabled} key={`${field.key}-${index}`} id={index}>
							<div
								key={`${field.key}-${index}`}
								css={[valueContainerStyles, !isValueHidden && visibleValueValueContainerStyles]}
							>
								<Box paddingInlineStart="space.050" xcss={[isDragDisabled && hiddenStyles]}>
									<DragHandleButtonSmall
										appearance="subtle"
										type="button"
										label="view-dragHandle"
									/>
								</Box>
								<FieldValueContent
									fieldKey={field.key}
									field={field}
									groupIdentity={option.groupIdentity}
								/>
								<Box xcss={valueContainerSpacerStyles} />
								{isHideButtonVisible && isFieldHideable && (
									<>
										<Tooltip
											content={
												isValueHidden
													? formatMessage(messages.showGroupOption)
													: formatMessage(messages.filterGroupOption)
											}
										>
											<Box padding="space.050">
												<IconButton
													label={
														isValueHidden
															? formatMessage(messages.showGroupOption)
															: formatMessage(messages.filterGroupOption)
													}
													id="pendo.group-identity.button"
													onClick={() =>
														isValueHidden
															? onShowGroupIdentity(option.groupIdentity)
															: onHideGroupIdentity(option.groupIdentity)
													}
													appearance="subtle"
													spacing="compact"
													icon={({ label }) => (
														<>
															{isValueHidden ? (
																<EyeOpenStrikethrough label={label} color="currentColor" />
															) : (
																<Box
																	data-component-selector="visible-image-bS93"
																	xcss={[noDisplayStyles]}
																>
																	<EyeOpen label={label} color="currentColor" />
																</Box>
															)}
														</>
													)}
												/>
											</Box>
										</Tooltip>
									</>
								)}
								{!isHideButtonVisible && isFieldHideable && isValueHidden && (
									<Box xcss={[readonlyHiddenIconStyles]}>
										<EyeOpenStrikethrough label="" color="currentColor" />
									</Box>
								)}
							</div>
						</Draggable>
					);
				})}
			</Box>
		);
	},
	ValuesLegacy,
);

const valuesContainerStyles = xcss({
	maxHeight: 'calc(100vh - var(--topNavigationHeight) - 325px)',
	overflowY: 'auto',
	marginBlockEnd: 'space.300',
});

const valueContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
	height: '32px',
	paddingInlineEnd: token('space.200'),
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
	},
});

const hiddenStyles = xcss({
	visibility: 'hidden',
});

const noDisplayStyles = xcss({
	display: 'none',
});

const visibleValueValueContainerStyles = css({
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'[data-component-selector="visible-image-bS93"]': {
			display: 'block',
		},
	},
});

const readonlyHiddenIconStyles = xcss({
	height: token('space.200'),
	paddingInline: 'space.100',
});

const valueContainerSpacerStyles = xcss({
	flex: 1,
});
