import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	prompt: {
		id: 'polaris-common.navigation-blocker.prompt',
		defaultMessage: 'You have unsaved changes. Save or discard your changes to continue.',
		description:
			'Description on modal that shows up when user attempts to navigate away from unsaved page.',
	},
});
