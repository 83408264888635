import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const loadProperty =
	(propertyKey: string): Action<State, Props> =>
	({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}
		// skip, if already loaded or loading
		if (
			(getState().properties[propertyKey]?.isLoading === false &&
				getState().properties[propertyKey]?.error === null) ||
			getState().properties[propertyKey]?.isLoading === true
		) {
			return;
		}

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					isLoading: true,
					error: null,
					value: null,
				},
			},
		});

		getUserProperty(accountId, propertyKey)
			.then((value) => {
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							isLoading: false,
							error: null,
							value,
						},
					},
				});
			})
			.catch((error) => {
				if (error.statusCode === 404) {
					setState({
						properties: {
							...getState().properties,
							[propertyKey]: {
								isLoading: false,
								error: null,
								value: null,
							},
						},
					});
					return;
				}
				log.safeErrorWithoutCustomerData(
					'polaris.user-properties-error',
					`Failed to load user property with key: ${propertyKey}`,
					error,
				);
				setState({
					properties: {
						...getState().properties,
						[propertyKey]: {
							isLoading: false,
							error,
							value: null,
						},
					},
				});
			});
	};
