import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	dismissButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button for the tour spotlight',
	},
	nextButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.next-button',
		defaultMessage: 'Next',
		description: 'Next button for the tour spotlight',
	},
	backButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.back-button',
		defaultMessage: 'Back',
		description: 'Back button for the tour spotlight',
	},
	okButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.ok-button',
		defaultMessage: 'OK',
		description: 'OK button for the tour spotlight, moves to the last spotlight from the tour',
	},
	gotItButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.got-it-button',
		defaultMessage: 'Got it',
		description: 'Got it button for the tour spotlight, closes the last spotlight from the tour',
	},
	firstStepBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.first-step-body',
		defaultMessage: 'Create project fields that will be available only in this project.',
		description: 'Body for the first step of the project fields tour spotlight',
	},
	secondStepBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.second-step-body',
		defaultMessage:
			'Reuse global fields to achieve consistency across projects. Jira admins can create global fields and any project admin can add them to their project.',
		description: 'Body for the second step of the project fields tour spotlight',
	},
	thirdStepBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.third-step-body',
		defaultMessage: 'Quickly switch between managing all, project and global fields.',
		description: 'Body for the third step of the project fields tour spotlight',
	},
	forthStepBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.forth-step-body',
		defaultMessage: 'Edit field details such as fields’ name, description and add formatting.',
		description: 'Body for the forth step of the project fields tour spotlight',
	},
	fifthStepBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.fifth-step-body',
		defaultMessage: 'Jira admins can manage global fields on Global fields page.',
		description: 'Body for the forth step of the project fields tour spotlight',
	},
	lastStepBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.spotlights.last-step-body',
		defaultMessage: 'Get back to the project fields tour anytime.',
		description: 'Body for the last step of the project fields tour spotlight',
	},
});
