import {
	statusComplete,
	statusFailed,
	statusNone,
	statusPending,
	type FetchIssueTypesStatusState,
} from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	type FetchingIssueTypesAction,
	type FetchIssueTypesFailedAction,
	type SetIssueTypesAction,
	FETCH_ISSUE_TYPES_FAILED,
	FETCHING_ISSUE_TYPES,
	SET_ISSUE_TYPES,
} from '../actions/issue-types-actions';

export const initialState: FetchIssueTypesStatusState = statusNone;

type FetchIssueTypesStatusAction =
	| SetIssueTypesAction
	| FetchingIssueTypesAction
	| FetchIssueTypesFailedAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: FetchIssueTypesStatusState = initialState,
	action: FetchIssueTypesStatusAction,
): FetchIssueTypesStatusState => {
	switch (action.type) {
		case FETCHING_ISSUE_TYPES:
			return statusPending;

		case SET_ISSUE_TYPES:
			return statusComplete;

		case FETCH_ISSUE_TYPES_FAILED:
			return statusFailed;

		default:
			// prettier-ignore
			// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(action.type as never);
			return state;
	}
};
