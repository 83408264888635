import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	buttonLabel: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.button-label',
		defaultMessage: 'Clone',
		description: 'Button label to clone idea',
	},
	copiedIdeaSummary: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.copied-idea-summary',
		defaultMessage: 'Copy of {summary}',
		description: 'Cloned idea summary - copy of "the original idea summary"',
	},
});
