import log from '@atlassian/jira-common-util-logging/src/log';

export { NetworkError } from './network-error';
export {
	GenericError,
	GenericErrorWithRefresh,
	ProjectNotFoundError,
	ProjectLoadError,
	ProjectNotOnboardedError,
	RateLimitError,
	ViewUnavailableError,
} from './main';

export const ERROR_PREFIX = 'polaris-error';

/**
 * Logs polaris errors to the backend so they can be monitored using splunk.
 */
export const logPolarisError = (postfix: string, error: Error) => {
	// TODO: Determine some test function to determine if an error is 'interesting' to us and filter
	// the rest.

	log.safeErrorWithoutCustomerData(`${ERROR_PREFIX}.${postfix}`, error.message, error);
};
