import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Box, Flex, Stack, Text, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	ACCESS_ROLE_EDITOR,
	ACCESS_ROLE_VIEWER,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	PrincipalType,
	ViewAccess,
	ViewAccessLevel,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { UserPickerApi } from '@atlassian/jira-polaris-lib-user-picker/src/ui/index.tsx';
import { AccessLevelSelect } from './access-level-select';
import { AccessUserList, type AccessUserListProps } from './access-user-list';
import { CopyLinkButton } from './copy-link-button';
import messages from './messages';
import { NoEditorsSection, type NoEditorsSectionProps } from './no-editors-section';
import { UserPicker, type UserPickerProps } from './user-picker';
import { isPrincipalTypeItem } from './utils';

export type AccessScreenProps = ViewAccess & {
	currentUserAccountId: AccessUserListProps['currentUserAccountId'];
	userPickerOptions: UserPickerProps['userPickerOptions'];
	isUserPickerLoading: boolean;
	failures?: {
		role: AccessRole;
		principals: { id: string; name: string; type: PrincipalType }[];
	};
	skippedAccountFailures?: AccessUserListProps['skippedAccountFailures'];
	canEdit?: boolean;
	setUserPickerOptions: UserPickerProps['setUserPickerOptions'];
	onPrincipalRoleChange: AccessUserListProps['onPrincipalRoleChange'];
	onRemovePrincipals: AccessUserListProps['onRemovePrincipals'];
	onUpdateAccessLevel: (accessLevel: ViewAccessLevel) => Promise<void>;
	onAddPrincipals: ({
		principals,
		role,
	}: {
		role: AccessRole;
		principals: { id: string; name: string; type: PrincipalType; avatarUrl?: string }[];
	}) => Promise<void>;
	onClearFailures: (role?: AccessRole) => void;
	onDuplicateView: NoEditorsSectionProps['onDuplicateView'];
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
};

export const AccessScreen = (props: AccessScreenProps) => {
	const {
		isUserPickerLoading,
		canEdit = false,
		accessLevel,
		additionalAccess,
		userPickerOptions,
		currentUserAccountId,
		failures,
		skippedAccountFailures,
		setUserPickerOptions,
		onUpdateAccessLevel,
		onPrincipalRoleChange,
		onRemovePrincipals,
		onAddPrincipals,
		onClearFailures,
		onDuplicateView,
		onMenuClose,
		onMenuOpen,
	} = props;
	const { formatMessage } = useIntl();

	useEffect(() => {
		experience.viewPermissions.openDialog.success();
	}, []);

	return (
		<>
			{userPickerOptions.length === 0 && (
				<Stack space="space.250">
					<Stack space="space.150">
						<Heading size="xxsmall">{formatMessage(messages.accessLevelHeading)}</Heading>
						<AccessLevelSelect
							accessLevel={accessLevel}
							onUpdateAccessLevel={onUpdateAccessLevel}
							isDisabled={!canEdit}
							onMenuOpen={onMenuOpen}
							onMenuClose={onMenuClose}
						/>
					</Stack>
					<Stack space="space.150">
						<Heading size="xxsmall">{formatMessage(messages.additionalAccessHeading)}</Heading>
						<UserPicker
							isLoading={isUserPickerLoading}
							groups={additionalAccess.groups}
							profiles={additionalAccess.profiles}
							userPickerOptions={userPickerOptions}
							setUserPickerOptions={setUserPickerOptions}
							isDisabled={!canEdit}
							onMenuOpen={onMenuOpen}
							onMenuClose={onMenuClose}
						/>
						<NoEditorsSection
							accessLevel={accessLevel}
							additionalAccess={additionalAccess}
							canEdit={canEdit}
							onDuplicateView={onDuplicateView}
						/>
						{failures && (
							<SectionMessage
								testId="polaris-component-view-access.ui.access-screen.failures-section"
								key={failures.role}
								title={formatMessage(messages.addPrincipalsFailureMessage)}
								appearance="error"
								actions={[
									<SectionMessageAction
										key="retry"
										onClick={() => {
											onAddPrincipals(failures);
										}}
									>
										{formatMessage(messages.addPrincipalsRetryAction)}
									</SectionMessageAction>,
									<SectionMessageAction key="cancel" onClick={() => onClearFailures(failures.role)}>
										{formatMessage(messages.addPrincipalsCancelAction)}
									</SectionMessageAction>,
								]}
							>
								{failures.principals.map(({ name }) => name).join(', ')}
							</SectionMessage>
						)}
						<AccessUserList
							accessLevel={accessLevel}
							additionalAccess={additionalAccess}
							isDisabled={!canEdit}
							currentUserAccountId={currentUserAccountId}
							skippedAccountFailures={skippedAccountFailures}
							onPrincipalRoleChange={onPrincipalRoleChange}
							onRemovePrincipals={onRemovePrincipals}
						/>
					</Stack>
					<Box xcss={separatorStyles} />
					<Flex justifyContent="end">
						<CopyLinkButton />
					</Flex>
				</Stack>
			)}
			{userPickerOptions.length > 0 && <AddPrincipalsSubScreen {...props} />}
		</>
	);
};

type AddPrincipalsSubScreenProps = Omit<AccessScreenProps, 'accessLevel'>;

type PermissionOption = { label: string; value: AccessRole };

const AddPrincipalsSubScreen = ({
	isUserPickerLoading,
	additionalAccess,
	userPickerOptions,
	onAddPrincipals,
	setUserPickerOptions,
}: AddPrincipalsSubScreenProps) => {
	const userPickerRef = useRef<UserPickerApi>(null);
	const [selectedRole, setSelectedRole] = useState<AccessRole>(ACCESS_ROLE_EDITOR);
	const { formatMessage } = useIntl();

	const permissionOptions: PermissionOption[] = useMemo(
		() => [
			{ label: formatMessage(messages.canViewLabel), value: ACCESS_ROLE_VIEWER },
			{ label: formatMessage(messages.canEditLabel), value: ACCESS_ROLE_EDITOR },
		],
		[formatMessage],
	);

	const handleAddPrincipals = useCallback(async () => {
		const principals = userPickerOptions.flatMap(({ id, type, name, avatarUrl }) =>
			isPrincipalTypeItem(type) ? [{ id, type, name, avatarUrl }] : [],
		);

		await onAddPrincipals({ principals, role: selectedRole });
		setUserPickerOptions([]);
	}, [onAddPrincipals, selectedRole, setUserPickerOptions, userPickerOptions]);

	useLayoutEffect(() => {
		userPickerRef.current?.focus();
	}, []);

	return (
		<Stack space="space.300">
			<Stack space="space.150">
				<UserPicker
					ref={userPickerRef}
					isLoading={isUserPickerLoading}
					groups={additionalAccess.groups}
					profiles={additionalAccess.profiles}
					userPickerOptions={userPickerOptions}
					setUserPickerOptions={setUserPickerOptions}
				/>
				<Select<PermissionOption, false>
					options={permissionOptions}
					defaultValue={permissionOptions.find((option) => option.value === selectedRole)}
					menuPosition="fixed"
					onChange={(arg) => {
						if (arg) {
							setSelectedRole(arg.value);
						}
					}}
				/>
				{selectedRole === ACCESS_ROLE_EDITOR && (
					<Flex gap="space.075" alignItems="center">
						<InfoIcon
							label={formatMessage(messages.infoIconLabel)}
							size="small"
							primaryColor={token('color.icon.selected')}
						/>
						<Text size="UNSAFE_small">{formatMessage(messages.canEditOptionMessage)}</Text>
					</Flex>
				)}
			</Stack>
			<Flex justifyContent="end" gap="space.100">
				<Button
					appearance="default"
					onClick={() => setUserPickerOptions([])}
					isDisabled={isUserPickerLoading}
				>
					{formatMessage(messages.cancelButtonLabel)}
				</Button>
				<Button
					appearance="primary"
					onClick={handleAddPrincipals}
					testId="polaris-component-view-access.ui.access-screen.add-button"
					isDisabled={isUserPickerLoading}
					isLoading={isUserPickerLoading}
				>
					{formatMessage(messages.addButtonLabel)}
				</Button>
			</Flex>
		</Stack>
	);
};

const separatorStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.border', colors.N30),
	height: token('border.width', '1px'),
	width: '100%',
});
