import { fetchIssueForgeData } from '@atlassian/jira-issue-fetch-services/src';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types';
import type { CloudId, IssueKey, ActivationId } from '@atlassian/jira-shared-types';

export const fetchAllForgeData = async (
	cloudId: CloudId,
	issueKey: IssueKey,
	activationId: ActivationId,
	prefetchedResource?: Promise<ForgeResponse> | null,
): Promise<ForgeResponse> =>
	prefetchedResource || fetchIssueForgeData(cloudId, issueKey, activationId);
