import { createContainer, createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { loadEntityLimits } from './actions/load/index.tsx';
import { EMPTY_STATE } from './constants';
import type { State, Props } from './types';

const actions = {
	loadEntityLimits,
} as const;

export type Actions = typeof actions;

export const EntityLimitsStore = createStore<State, Actions>({
	initialState: EMPTY_STATE,
	actions,
	name: 'PolarisEntityLimitsStore',
});

export const useEntityLimitsActions = createActionsHook(EntityLimitsStore);

export const EntityLimitsContainer = createContainer<State, Actions, Props>(EntityLimitsStore, {
	onInit:
		() =>
		({ dispatch }) =>
			dispatch(loadEntityLimits()),
});
