import type { MediaContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';

export const getShortestLifespan = (ctx?: MediaContext): number => {
	if (ctx === undefined) {
		// default to 5mins
		return 1000 * 60 * 5;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (Object.keys(ctx) as Array<keyof MediaContext>).reduce((min, curr) => {
		const currCtx = ctx[curr];
		const currLifespan =
			currCtx && 'tokenLifespanInMs' in currCtx && typeof currCtx.tokenLifespanInMs === 'number'
				? currCtx.tokenLifespanInMs
				: undefined;
		if (currCtx && currLifespan !== null && currLifespan !== undefined && currLifespan < min) {
			return currLifespan;
		}
		return min;
	}, Number.MAX_VALUE);
};
