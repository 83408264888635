import {
	type ProjectKey,
	type BaseUrl,
	type ProjectUuid,
	toBaseUrl,
	toProjectUuid,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	NotificationSettingsResponse,
	NotificationEventTypeId,
	NotificationConfiguration,
} from '../types';

export const getTranslationOverrides = (
	notifications: NotificationSettingsResponse,
): NotificationConfiguration[] =>
	notifications.map((notification) => ({
		...notification,
		eventType: {
			...notification.eventType,
			name: notification.eventType.name,
		},
	}));

export const buildNotificationSettingsUrl = (
	baseUrl: BaseUrl = toBaseUrl(''),
	projectUuid: ProjectUuid = toProjectUuid(''),
) => `${baseUrl}/rest/internal/simplified/latest/projects/${projectUuid}/notifications`;

export const buildDeleteUrl = ({
	// Defaults provided for testing purposes, these will always be provided via sweet-state container props
	baseUrl = toBaseUrl(''),

	projectUuid = toProjectUuid(''),
	eventTypeId,
}: {
	baseUrl: BaseUrl;
	projectUuid: ProjectUuid;
	eventTypeId: NotificationEventTypeId;
}) =>
	`${baseUrl}/rest/internal/simplified/1.0/projects/${projectUuid}/notifications/${eventTypeId}`;

export const buildRecipientsUpdateUrl = (
	baseUrl: BaseUrl = toBaseUrl(''),
	projectUuid: ProjectUuid,
	eventTypeId: NotificationEventTypeId,
) =>
	`${baseUrl}/rest/internal/simplified/latest/projects/${projectUuid}/notifications/${eventTypeId}`;

export const buildSupportedEventTypesUrl = (
	baseUrl: BaseUrl = toBaseUrl(''),
	projectUuid: ProjectUuid = toProjectUuid(''),
) => `${baseUrl}/rest/internal/simplified/latest/projects/${projectUuid}/notifications/eventTypes`;

export const buildSupportedNotificationRecipientTypesUrl = (baseUrl: BaseUrl = toBaseUrl('')) =>
	`${baseUrl}/rest/internal/simplified/latest/notifications/notificationTypes`;

export const buildProjectRolesUrl = (
	baseUrl: BaseUrl = toBaseUrl(''),
	projectKey: ProjectKey = toProjectKey(''),
) => `${baseUrl}/rest/api/3/project/${projectKey}/roledetails?excludeConnectAddons=true`;
