import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	collapseButtonExpandLabel: {
		id: 'polaris-lib-list.group-row.collapse-button-expand-label',
		defaultMessage: 'Expand group',
		description: 'Button label for expanding a group (when the group is collapsed)',
	},
	collapseButtonCollapseLabel: {
		id: 'polaris-lib-list.group-row.collapse-button-collapse-label',
		defaultMessage: 'Collapse group',
		description: 'Button label for collapsing a group (when the group is expanded)',
	},
});
