import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	insightImpact: {
		id: 'polaris-ideas.insights.insights.insight.properties.insight-impact',
		defaultMessage: 'Insight impact',
		description: 'Insight impact',
	},
	insightLabels: {
		id: 'polaris-ideas.insights.insights.insight.properties.insight-labels',
		defaultMessage: 'Insight labels',
		description: 'Insight labels',
	},
	labels: {
		id: 'polaris-ideas.insights.insights.insight.properties.labels',
		defaultMessage: 'Labels',
		description: 'Labels',
	},
});
