import keyBy from 'lodash/keyBy';
import { ff } from '@atlassian/jira-feature-flagging';
import type { Action } from '@atlassian/react-sweet-state';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_INSIGHTS_START,
	PAGE_LOAD_MARK_LOAD_INSIGHTS_END,
} from '../../../../common/utils/metrics/project';
import type { State, Props } from '../../types';

export const loadInsights =
	({ fireAnalyticsEvent = false, archivedOnly = false } = {}): Action<State, Props> =>
	async (
		{ getState, setState },
		{ insightsRemote, onProjectLoadingError, createAnalyticsEvent },
	) => {
		setState({
			meta: {
				...getState().meta,
				insights: { loading: !archivedOnly, error: undefined },
			},
		});

		if (archivedOnly && !ff('polaris.split-archived-insights-loading')) {
			return;
		}

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_INSIGHTS_START);

		try {
			const archivedMode = archivedOnly ? 'ARCHIVED_ONLY' : 'ACTIVE_ONLY';
			const insights = await insightsRemote?.fetchProjectConfigInsights?.({
				createAnalyticsEvent,
				fireAnalyticsEvent,
				archivedMode: ff('polaris.split-archived-insights-loading') ? archivedMode : 'ALL',
			});

			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_INSIGHTS_END);

			const existingInsights = getState().project.insights || [];
			const insightsById = keyBy(insights, 'id');
			existingInsights.map((insight) => {
				if (insightsById[insight.id]) {
					delete insightsById[insight.id];
					return insightsById[insight.id];
				}
				return insight;
			});

			setState({
				meta: {
					...getState().meta,
					insights: { loading: false, error: undefined },
				},
				project: {
					...getState().project,
					insights: [...existingInsights, ...Object.values(insightsById)],
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_INSIGHTS_END);

			setState({
				meta: {
					...getState().meta,
					insights: { loading: false, error },
				},
			});
			onProjectLoadingError(error);
		}
	};
