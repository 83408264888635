import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';

type ProjectsResponse = {
	projects: Array<{
		id: string;
		key: string;
		name: string;
		description: string;
		avatars: {
			xsmall: string;
			small: string;
			medium: string;
			large: string;
			xlarge: string;
			xxlarge: string;
			'small@3x': string;
			'large@3x': string;
			'xxlarge@2x': string;
			'xxlarge@3x': string;
			'xxxlarge@2x': string;
			'xxxlarge@3x': string;
		};
		issueTypeIds: string[];
	}>;
};

export const fetchProjectsForJql = async (jql: string): Promise<ProjectMetadata[]> =>
	performPostRequest<ProjectsResponse>('/rest/polaris/projects/jql', {
		body: JSON.stringify({
			jql,
			expand: true,
		}),
	}).then(({ projects }) =>
		projects.map((project) => ({
			id: project.id,
			key: project.key,
			name: project.name,
			avatarUrls: {
				'16x16': project.avatars.small,
				'24x24': project.avatars.xsmall,
				'32x32': project.avatars.medium,
				'48x48': project.avatars.large,
			},
		})),
	);
