import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useBackButtonUrl } from '@atlassian/jira-issue-context-service/src/main';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils';
import withCompactMode from '@atlassian/jira-issue-view-compact-mode';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { errorSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import BackButton from '../../back-button';
import Breadcrumbs from '../../breadcrumbs';
import { HeaderWrapper } from '../header-actions/styled';
import { IssueNavigator } from './issue-navigator';

type Props = {
	isCompact?: boolean;
	shouldShowProjectLevelBreadcrumb?: boolean;
	shouldShowRootProjectsBreadcrumb?: boolean;
	error: string | null;
};

export const HeaderBreadcrumbsView = ({
	error = null,
	isCompact,
	shouldShowProjectLevelBreadcrumb = false,
	shouldShowRootProjectsBreadcrumb = false,
}: Props) => {
	const backButtonUrl = useBackButtonUrl();

	if (error !== null) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isEmbedMode = useIsEmbedMode();

	return (
		<HeaderBreadcrumbsWrapper>
			<LeftAligned isCompact={isCompact}>
				{backButtonUrl !== undefined && <BackButton url={backButtonUrl} />}
				<Breadcrumbs
					externalId="issue"
					shouldShowProjectLevelBreadcrumb={isEmbedMode ? false : shouldShowProjectLevelBreadcrumb}
					shouldShowRootProjectsBreadcrumb={isEmbedMode ? false : shouldShowRootProjectsBreadcrumb}
				/>
				<IssueNavigator />
			</LeftAligned>
		</HeaderBreadcrumbsWrapper>
	);
};

export const HeaderBreadcrumbs = flowWithSafeComponent(
	connect((state: State) => ({
		error: errorSelector(state),
	})),
)(withCompactMode(HeaderBreadcrumbsView));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderBreadcrumbsWrapper = styled(HeaderWrapper)({
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftAligned = styled.div<{ isCompact?: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"[dir='rtl'] &": {
			paddingLeft: 0,
			paddingRight: token('space.250', '20px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCompact }) => (isCompact ? { font: token('font.body.UNSAFE_small') } : {}),
);
