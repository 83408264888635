import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { User as UserType } from '@atlassian/jira-polaris-common/src/common/types/user';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { usePeopleTransformed } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { UsersField } from '@atlassian/jira-polaris-common/src/ui/fields/users';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	portalElement?: HTMLElement;
};

export const Users = ({ fieldKey, localIssueId, portalElement }: Props) => {
	const { formatMessage } = useIntl();
	const value = usePeopleTransformed(fieldKey, localIssueId);
	const field = useField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	if (field === undefined) {
		return null;
	}
	return (
		<UsersField
			value={value}
			fieldKey={fieldKey}
			interactive
			isEditable={canEditIssues && field.editable}
			portalElement={portalElement}
			onUpdate={(users: UserType[]) => {
				const displayUsers: UserFieldValue[] = users.map((user) => ({
					accountId: user.id,
					displayName: user.name || '',
					avatarUrls: {
						'16x16': undefined,
						'24x24': undefined,
						'32x32': undefined,
						'48x48': user.avatarUrl || '',
					},
				}));
				updateFieldValue({
					fieldKey,
					localIssueIds: [localIssueId],
					newValue: displayUsers,
				});
			}}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
