import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldSelectLabel: {
		id: 'polaris-component-field-configuration.configuration.interval-override.delivery-date.field-select-label',
		defaultMessage: 'Date field',
		description: 'Label for date field select',
	},
	calculationTypeSelectLabel: {
		id: 'polaris-component-field-configuration.configuration.interval-override.delivery-date.calculation-type-select-label',
		defaultMessage: 'Calculate',
		description: 'Label for calculation type select',
	},
	calculationTypeLatestOption: {
		id: 'polaris-component-field-configuration.configuration.interval-override.delivery-date.calculation-type-latest-option',
		defaultMessage: 'Latest date',
		description: 'Label for latest date option',
	},
	calculationTypeEarliestOption: {
		id: 'polaris-component-field-configuration.configuration.interval-override.delivery-date.calculation-type-earliest-option',
		defaultMessage: 'Earliest date',
		description: 'Label for earliest date option',
	},
});
