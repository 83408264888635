import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { ErrorMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fieldsReferencableFrom } from '@atlassian/jira-polaris-domain-field/src/formula/index.tsx';
import type {
	ExpressionFormula,
	DynamicFieldFormula,
} from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { compile, type CompilationResult } from '../../../../../controllers/expressions';
import { useFieldActions } from '../../../../../controllers/field/main.tsx';
import { useFieldsArray } from '../../../../../controllers/field/selectors/field-hooks';
import { dotStartingDecimalRegex } from '../constants';
import { messages as commonMessages } from '../messages';
import { messages } from './messages';

type ExpressionTypeInProps = {
	thisFieldKey?: FieldKey;
	initFormula?: ExpressionFormula;
	readonly: boolean;
	isPreview?: boolean;
	onChange: (formula: DynamicFieldFormula, numInputs: number) => void;
};

export const ExpressionTypeInLegacy = (props: ExpressionTypeInProps) => {
	const [fields] = useFieldsArray();

	const { readonly, thisFieldKey } = props;

	const initialValue: string = props.initFormula ? props.initFormula.parameters.expression : '';
	const [text, setText] = useState<string>(initialValue);
	const [parsed, setParsed] = useState<CompilationResult>(compile(initialValue, fields));
	const { throttledFetchSnippetProviders } = useFieldActions();

	// figure out which fields the user is allowed to use in the expression; if we are a new expression, then it's
	// any numeric field.  If we are editing an existing expression, then it's any numeric field that doesn't
	// reference this field directly or indirectly.
	const allowedFields = useMemo<Field[]>(
		() => fieldsReferencableFrom(thisFieldKey, fields),
		[fields, thisFieldKey],
	);

	const { formatMessage } = useIntl();

	useEffect(() => {
		if (readonly) {
			return;
		}
		// re-fetch the snippet providers when we mount this
		// component, so that we have the most up-to-date information
		// for reporting on unknown props during parsing (NOTE, as of
		// this writing, we don't actually report on unknown data
		// props, partly because that should be a warning and not an
		// error and we don't have a notion of warnings.  So this is
		// just setting the stage for better expression validation.
		// Also note that paying attention to data props would also
		// enable us to specify props by *label* and not just by *key*,
		// something we very much need to do for oauth client ids)
		throttledFetchSnippetProviders(true);
	}, [throttledFetchSnippetProviders, readonly]);

	const errorMessage = () => {
		if (text.match(dotStartingDecimalRegex)) {
			return (
				<ErrorMessage testId="polaris-common.ui.config.fields.common.expression-formula-item.error">
					{formatMessage(commonMessages.customFormulaDecimalError)}
				</ErrorMessage>
			);
		}
		if (text.trim().length < 1) {
			return (
				<ErrorMessage testId="polaris-common.ui.config.fields.common.expression-formula-item.error">
					{formatMessage(messages.expressionRequiredError)}
				</ErrorMessage>
			);
		}
		if (parsed.error !== undefined && !props.isPreview) {
			return (
				<ErrorMessage testId="polaris-common.ui.config.fields.common.expression-formula-item.error">
					{formatMessage(parsed.error.msg, parsed.error.args)}
				</ErrorMessage>
			);
		}

		return null;
	};

	return (
		// eslint-disable-next-line jira/react/no-empty-divs
		<div>
			<ExpressionLabelContainer>{formatMessage(messages.expressionLabel)}</ExpressionLabelContainer>

			<Textfield
				placeholder="{Reach} * {Impact} * {Confidence} / {Effort}"
				value={text}
				isCompact={props.readonly}
				testId="polaris-common.ui.config.fields.common.expression-formula-item.custom"
				isReadOnly={props.readonly}
				appearance={props.readonly ? 'none' : 'standard'}
				onChange={(ev) => {
					// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
					setText(ev.target.value);
					// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'EventTarget'.
					const expr = ev.target.value.trim();
					if (expr.length > 0) {
						const result = compile(expr, fields, allowedFields);
						if (result.formula !== undefined && props.onChange !== undefined) {
							props.onChange(result.formula, result.fields.length);
						}
						setParsed(result);
					}
				}}
			/>
			{errorMessage()}
		</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpressionLabelContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N100,
	font: token('font.body.small'),
	display: 'inline-flex',
});
