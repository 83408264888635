import React, { memo } from 'react';

const cache = new Map();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const whyDidYouRenderPlaceholder = (componentName: string, whyDidYouRenderOptions?: any) => {
	if (process.env.NODE_ENV !== 'development' || !window?.STORYBOOK_ENV) {
		return null;
	}

	if (cache.has(componentName)) {
		const Component = cache.get(componentName);
		return <Component />;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Component = (_: any) => null;
	Component.displayName = componentName;
	Component.whyDidYouRender = {
		customName: componentName,
		logOnDifferentValues: true,
		...whyDidYouRenderOptions,
	};

	cache.set(componentName, Component);

	return <Component />;
};

export const WithWhyDidYouRenderPlaceholder = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Component: React.ComponentType<any>,
	componentName: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	whyDidYouRenderOptions?: any,
) => {
	if (process.env.NODE_ENV !== 'development' || !window?.STORYBOOK_ENV) {
		return Component;
	}

	const MemoizedPlaceholder = memo(() =>
		whyDidYouRenderPlaceholder(componentName, whyDidYouRenderOptions),
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (props: any) => {
		const { children, ...rest } = props;
		return (
			<>
				<Component {...props} />
				<MemoizedPlaceholder {...rest} />
			</>
		);
	};
};
