import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { createHigherLevelIssueHook, createSelectedIssueHook2 } from '../../utils';
import {
	createGetCommentsCount,
	createGetTimestampCurrentUserSeenComments,
	createIsIssueHavingUnseenComments,
} from './index';

export const useIssueCommentCount = createHigherLevelIssueHook<LocalIssueId, number>(
	createGetCommentsCount,
);

export const useUnseenCommentsAvailableForIssue = createHigherLevelIssueHook<LocalIssueId, boolean>(
	createIsIssueHavingUnseenComments,
);

export const useTimestampCurrentUserSeenComments = createSelectedIssueHook2<number | undefined>(
	createGetTimestampCurrentUserSeenComments,
);
