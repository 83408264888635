import React, { type ReactNode, forwardRef } from 'react';
import { styled } from '@compiled/react';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, {
	type TooltipProps as ATKTooltipProps,
	type TooltipPrimitiveProps,
	TooltipPrimitive,
} from '@atlaskit/tooltip';

const TooltipContainer = forwardRef<HTMLElement, TooltipPrimitiveProps>((props, ref) => {
	const { children, ...rest } = props;
	return (
		// @ts-expect-error - TS2783 - 'ref' is specified more than once, so this usage will be overwritten.
		<TooltipPrimitive ref={ref} {...rest}>
			<TooltipContainerInner>{children}</TooltipContainerInner>
		</TooltipPrimitive>
	);
});

type TooltipProps = Omit<ATKTooltipProps, 'content'> & {
	header?: ReactNode;
	content: ReactNode;
};

export const TooltipComponent = ({ children, header, content, ...rest }: TooltipProps) => (
	<Tooltip
		component={TooltipContainer}
		position="left"
		content={
			<TooltipContentWrapper>
				<TooltipContentHeader>{header}</TooltipContentHeader>
				<TooltipContentBody>{content}</TooltipContentBody>
			</TooltipContentWrapper>
		}
		{...rest}
	>
		{children}
	</Tooltip>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContainerInner = styled.div({
	display: 'flex',
	width: '192px',
	boxSizing: 'content-box',
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: '3px',
	background: token('elevation.surface.overlay', N0),
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentBody = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TooltipContentHeader = styled.div({
	display: 'flex',
});
