import { useMemo } from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { useFieldsByKey } from '../field/selectors/field-hooks.tsx';
import * as actions from './actions';
import type { State, Props } from './types';

export type Actions = typeof actions;

const initialState: State = {
	meta: {
		loading: true,
		error: undefined,
	},
	globalFields: {},
	copyValuesTasks: {},
	containerProps: undefined,
};

const GlobalFieldsStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisGlobalFieldsStore',
});

const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(GlobalFieldsStore);

export const GlobalFieldsContainer = Container;
export const useGlobalFieldsActions = useActions;

export const useGlobalFieldCopyValuesActions = () => {
	const { copyValues } = useGlobalFieldsActions();
	const [fieldsByKeys] = useFieldsByKey();

	return useMemo(
		() => ({
			copyValues: (
				fromProjectFieldKey: FieldKey,
				toGlobalFieldKey: FieldKey,
				fieldOptionsValueMapping?: Record<string, string | undefined>,
				onComplete?: () => void,
				onProgressPollingError?: () => void,
			) => {
				const fromField = fieldsByKeys[fromProjectFieldKey];
				const toField = fieldsByKeys[toGlobalFieldKey];

				return copyValues(
					fromField,
					toField,
					fieldOptionsValueMapping,
					onComplete,
					onProgressPollingError,
				);
			},
		}),
		[fieldsByKeys, copyValues],
	);
};

export { createHook, createHigherLevelHook };
