import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	columnsLabel: {
		id: 'polaris-ideas.view-controls.config-columns.columns-label',
		defaultMessage: 'Columns',
		description: '',
	},
	groupByLabel: {
		id: 'polaris-ideas.view-controls.config-columns.group-by-label',
		defaultMessage: 'Group by',
		description: '',
	},
});
