const SAFE_URL_REGEX = new RegExp(
	'^((ftp|https?):\\/\\/)' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*',
	'i', // port and path
);

export const isSafeUrl = (unsafe?: string): boolean =>
	unsafe === undefined || (!unsafe.includes(' ') && SAFE_URL_REGEX.test(unsafe));
