import React, { type ReactNode, useEffect } from 'react';
import { Card, CardAction } from '@atlaskit/smart-card';
import {
	useIsSmartCard,
	useSmartCardActions,
} from '@atlassian/jira-polaris-lib-smart-card/src/controllers/index.tsx';

type SmartCardWrapperProps = {
	children: ReactNode;
	url: string | null;
	showChildren: boolean;
	container?: HTMLElement;
};

export const SmartCardWrapper = ({
	children,
	url,
	showChildren,
	container,
}: SmartCardWrapperProps) => {
	const isSmartCard = useIsSmartCard(url || '');
	const { checkUrl } = useSmartCardActions();

	useEffect(() => {
		if (url !== null && !showChildren) {
			checkUrl(url);
		}
	}, [checkUrl, showChildren, url]);
	return (
		<>
			{url !== null && !showChildren && isSmartCard ? (
				<Card
					container={container}
					key={url}
					appearance="block"
					url={url}
					isFrameVisible={false}
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
				/>
			) : null}
			{isSmartCard && !showChildren ? null : children}
		</>
	);
};
