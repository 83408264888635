import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { Column } from 'react-base-table';
import type { RowId, RowGroupId, ColumnId } from '../../common/types';
import type { ColumnShape } from '../../common/types/react-base-table';
import {
	CLASS_CHANGING_ROW_CELL,
	CLASS_EXPORTING_HEADER_CELL,
	CLASS_EXPORTING_ROW_CELL,
	CLASS_FIXED_HEADER_CELL,
	CLASS_PRESENTATIONAL_HEADER_CELL,
	CLASS_PRESENTATIONAL_ROW_CELL,
	CLASS_SUMMARY_CELL,
	MIN_COLUMN_WIDTH,
} from '../../common/ui/constants';
import { NO_VALUE_GROUP_ID, PRESENTATIONAL_FIELDKEYS } from '../../constants';
import { createBaseTableRows } from '../common/items';
import type { RowGrouping, State, UpdatedRowProps } from '../types';
import {
	getColumnMinSizes,
	getColumnSizes,
	getHoverableColumns,
	getHoveredColumn,
} from './columns';
import { isColumnResizingEnabled } from './operations';

const getFixedColumns = (state: State): ColumnId[] => state.fixedColumns;

const getIsExporting = (state: State): boolean => state.isExporting;

const getRowIds = (state: State): RowId[] => state.rows;
export const getUpdatedRowIds = (state: State): RowId[] => state.updatedRows;
export const getUpdatedRowsProps = (state: State): UpdatedRowProps[] => state.updatedRowsProps;
export const getColumnIds = (state: State): ColumnId[] => state.columns;
export const getGroupedIds = (state: State): RowGrouping | undefined => state.groupedIds;
export const getHideEmptyGroups = (state: State): boolean => state.hideEmptyGroups;

export const getRowGroups = createSelector(
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	(state) => state.rowGroups,
	(rowGroups) =>
		rowGroups?.map((groupId: undefined | RowGroupId) =>
			groupId !== undefined ? groupId : NO_VALUE_GROUP_ID,
		),
);

const getCollapsedGroups = (state: State): RowGroupId[] => state.collapsedGroups;

const getDefaultColumnSize = (columnId: ColumnId): number => {
	const isPresentational = Object.values(PRESENTATIONAL_FIELDKEYS).includes(columnId);
	return isPresentational ? 20 : 150;
};

export const getSelectedRows = (state: State): RowId[] | undefined => state.selectedRows;
export const getHoveredRow = (state: State): RowId | undefined => state.hoveredRow;

export const SUMMARY_COLUMN_KEY = 'summary';

export const getColumnConfiguration = createSelector(
	getColumnIds,
	getColumnSizes,
	getColumnMinSizes,
	getFixedColumns,
	getIsExporting,
	isColumnResizingEnabled,
	getHoveredColumn,
	getHoverableColumns,
	getSelectedRows,
	getHoveredRow,
	(
		columnIds,
		columnSizes,
		columnMinSizes,
		fixedColumns,
		isExporting,
		columnResizingEnabled,
		hoveredColumn,
		hoverableColumns,
		selectedRows,
		hoveredRow,
	) =>
		columnIds.map((id) => {
			const isFrozen = fixedColumns.includes(id);
			const isPresentational = Object.values(PRESENTATIONAL_FIELDKEYS).includes(id);
			const minWidth = isPresentational ? undefined : columnMinSizes[id] || MIN_COLUMN_WIDTH;
			const width = columnSizes[id] || getDefaultColumnSize(id);

			const config: ColumnShape = {
				key: id,
				dataKey: id,
				title: id,
				width: minWidth !== undefined ? Math.max(minWidth, width) : width,
				resizable: columnResizingEnabled && !isPresentational,
				minWidth,
				frozen: isFrozen ? Column.FrozenDirection.LEFT : false,
			};

			const className: string[] = [];
			const headerClassName: string[] = [];

			const isActiveCell =
				id !== SUMMARY_COLUMN_KEY &&
				id === hoveredColumn &&
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				selectedRows?.includes(hoveredRow!) &&
				hoverableColumns.includes(hoveredColumn);

			if (isActiveCell) {
				className.push(CLASS_CHANGING_ROW_CELL);
			}

			if (isFrozen) {
				headerClassName.push(CLASS_FIXED_HEADER_CELL);
			}

			if (isPresentational) {
				className.push(CLASS_PRESENTATIONAL_ROW_CELL);
				headerClassName.push(CLASS_PRESENTATIONAL_HEADER_CELL);
			}
			if (SUMMARY_COLUMN_KEY === id) {
				className.push(CLASS_SUMMARY_CELL);
				headerClassName.push(CLASS_SUMMARY_CELL);
			}

			// POL-8439 & POL-8107 - Change position property during export
			if (isExporting) {
				headerClassName.push(CLASS_EXPORTING_HEADER_CELL);
				className.push(CLASS_EXPORTING_ROW_CELL);
			}

			config.className = className.join(' ');
			config.headerClassName = headerClassName.join(' ');

			return config;
		}),
);

export const createGetColumnConfiguration = (columnId?: ColumnId) =>
	createSelector(getColumnConfiguration, (columnConfiguration) =>
		columnConfiguration.find(({ key }) => key === columnId),
	);

export const getActualColumnSizes = createSelector(getColumnConfiguration, (config) =>
	mapValues(
		keyBy(config, ({ key }) => key),
		({ width }: ColumnShape) => width,
	),
);

export const createGetColumnSize = (columnId?: ColumnId | null) =>
	createSelector(getColumnSizes, (columnSizes) => (columnId ? columnSizes[columnId] ?? 0 : 0));

export const getFixedColumnSizes = createSelector(
	getColumnSizes,
	getFixedColumns,
	(columnSizes, fixedColumns) => pickBy(columnSizes, (_, key) => fixedColumns.includes(key)),
);

export const getShouldRenderRowPinnedBottomForGroups = (state: State): boolean =>
	state.shouldRenderRowPinnedBottomForGroups || false;

export const getShouldRenderRowPinnedBottomForNoGroups = (state: State): boolean =>
	state.shouldRenderRowPinnedBottomForNoGroups || false;

export const getRowConfiguration = createSelector(
	getRowIds,
	getRowGroups,
	getGroupedIds,
	getCollapsedGroups,
	getUpdatedRowsProps,
	getHideEmptyGroups,
	getShouldRenderRowPinnedBottomForGroups,
	getShouldRenderRowPinnedBottomForNoGroups,
	createBaseTableRows,
);

export const getGroupRowConfiguration = createSelector(getRowConfiguration, (rowsConfig) =>
	rowsConfig.filter(({ type }) => type === 'GROUP'),
);

export const getUpdatedRowProps = createSelector(
	getUpdatedRowsProps,
	getRowConfiguration,
	(updatedRowsProps, rowsConfig) => {
		// @TODO: make multi idea update indication possible
		const updatedRowProp = updatedRowsProps?.[0] ?? {};

		let idx = null;
		if (updatedRowProp.id !== null) {
			const rowConfig = rowsConfig.find(({ id }) => id === updatedRowProp.id);
			idx = rowConfig?.index ?? -1;
		}

		return { ...updatedRowProp, idx };
	},
);
