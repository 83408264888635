import { defineMessages } from '@atlassian/jira-intl';

const termsHtmlText = `
<p>
    By signing up for this Early Access Program (“EAP”), you acknowledge the Atlassian
    <a href="https://www.atlassian.com/legal/privacy-policy">Privacy Policy</a> and that use
    of the EAP products is governed by the Atlassian
    <a href="https://www.atlassian.com/legal/cloud-terms-of-service">
        Cloud Terms of Service
    </a>
    (“TOS”). The EAP products are considered “Beta Versions”, as set forth in Section 14 of
    the TOS and are subject to applicable terms, conditions, and disclaimers.
</p>
<p>
    Atlassian will use the information you provide on this sign-up form to contact you about
    the EAP program, including updates to the program, feedback requests, and offers.
</p>
`;

export default defineMessages({
	accept: {
		id: 'polaris-ideas.consent.accept',
		defaultMessage: 'Accept',
		description: 'Label on button to accept terms of service',
	},
	heading: {
		id: 'polaris-ideas.consent.heading',
		defaultMessage: 'Early Access Program terms of service',
		description: 'Heading label for Early Access Program terms of service',
	},
	termsText: {
		id: 'polaris-ideas.consent.terms-text',
		defaultMessage: termsHtmlText,
		description: 'Terms of service text content shown to the user',
	},
});
