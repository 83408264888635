import React from 'react';
import keyBy from 'lodash/keyBy';
import { useFieldOptionsWithAriResolved } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { DecoratedTag } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/tag';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type Props = {
	groupIdentity: string;
	fieldKey: FieldKey;
};

export const OptionFieldLabel = ({ groupIdentity, fieldKey }: Props) => {
	const options = useFieldOptionsWithAriResolved(fieldKey);

	const optionsByKey = keyBy(options, ({ id }) => id);
	const option = optionsByKey[groupIdentity];

	return option ? <DecoratedTag fieldKey={fieldKey} id={option.id} value={option.value} /> : null;
};
