import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteOption: {
		id: 'polaris-lib-field-option-configuration.field-decoration-popup.delete-option',
		defaultMessage: 'Delete option',
		description: 'Button that deletes a field option',
	},
	optionNameLabel: {
		id: 'polaris-lib-field-option-configuration.field-decoration-popup.option-name-label',
		defaultMessage: 'Option name',
		description: 'Label for an input used to modify an option name',
	},
});
