import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIsSelectedIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { Attachments } from '../../attachments';
import { Controls } from '../../controls';
import { Description } from '../../description';
import { Fields } from '../../fields';
import { IssueLinks } from '../../issue-links';

export const OverviewTab = ({ isSharedView }: { isSharedView: boolean }) => {
	const [isCreatingIssueLink, setIsCreatingIssueLink] = useState(false);
	const isArchived = useIsSelectedIssueArchived();

	return (
		<>
			<Description />
			{!isSharedView && !isArchived && (
				<ControlsContainer>
					<Controls showLabels onLinkIssue={() => setIsCreatingIssueLink(true)} />
				</ControlsContainer>
			)}
			<Attachments />
			<IssueLinks
				isCreatingIssueLink={isCreatingIssueLink}
				onLinkIssue={() => setIsCreatingIssueLink(true)}
				onCancelOrCreated={() => setIsCreatingIssueLink(false)}
			/>
			<Fields isCompact />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsContainer = styled.div({
	padding: `0 ${token('space.100', '8px')}`,
	marginBottom: token('space.150', '12px'),
});
