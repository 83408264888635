import React, { type ReactNode, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link';
import { ff } from '@atlassian/jira-feature-flagging';
import HelpPanelButton from '@atlassian/jira-help-panel-button';

type Props = {
	href: string;
	inProductHelpArticleId?: string;
	learnMoreText: string | ReactNode;
	onClick?: (event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
};

export const InProductHelpLink = ({
	href,
	inProductHelpArticleId,
	learnMoreText,
	onClick = noop,
}: Props) => {
	const learnMoreLink = (
		<Link target="_blank" rel="noopener noreferrer" href={href} onClick={onClick}>
			{learnMoreText}
		</Link>
	);

	if (inProductHelpArticleId === undefined) {
		return learnMoreLink;
	}
	return (
		<LinkStyledHelpPanelButton
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			testId="servicedesk-common.components.in-product-help-link.link-styled-help-panel-button"
			fallbackComponent={learnMoreLink}
			articleId={inProductHelpArticleId}
			onClick={onClick}
			appearance="link"
			spacing="none"
		>
			{ff('itsm-agni-25q1-a11y-fixes-_skehn') ? (
				<Box xcss={linkTextContainerstyles}>{learnMoreText}</Box>
			) : (
				learnMoreText
			)}
		</LinkStyledHelpPanelButton>
	);
};

const linkTextContainerstyles = xcss({
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkStyledHelpPanelButton = styled(HelpPanelButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		fontWeight: 'inherit',
	},
	textDecoration: 'underline',
});
