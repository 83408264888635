import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createHook, createStateHook, createSelector } from '@atlassian/react-sweet-state';
import { findView } from '../actions/utils';
import { NavigationStore } from '../index';
import {
	type EntityLimitsHookProp,
	getEntityLimits,
	getProjectViewsCount,
	getProjectViewsetsCount,
} from './views';

export const useNavigationViews = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => (projectState ? projectState.views : []),
	),
});

export const useIsNavigationLoading = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => projectState?.isLoading || false,
	),
});

export const useIsNavigationInitialized = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => projectState?.initialized || false,
	),
});

export const useIsNavigationLocked = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(projectState) => projectState?.isLocked || false,
	),
});

export const useNavigationView = createHook(NavigationStore, {
	selector: createSelector(
		(state, { projectId }: { projectId: string }) => state.projects[projectId],
		(_, { localId }: { projectId: string; localId: LocalViewId }) => localId,
		(projectState, localId) => findView(projectState, localId)?.view || null,
	),
});

export const useProjectViewsCount = createStateHook(NavigationStore, {
	selector: getProjectViewsCount,
});

export const useHasReachedViewsLimit = createStateHook(NavigationStore, {
	selector: createSelector(
		(_, props: EntityLimitsHookProp) => props,
		getEntityLimits,
		getProjectViewsCount,
		getProjectViewsetsCount,
		({ entityLimitType }, limit, viewsCount, viewsetsCount) => {
			if (!limit) {
				return null;
			}

			if (entityLimitType === ENTITY_LIMIT_TYPE.VIEWS_PER_PROJECT && limit <= viewsCount) {
				return limit;
			}

			if (entityLimitType === ENTITY_LIMIT_TYPE.VIEWSETS_PER_PROJECT && limit <= viewsetsCount) {
				return limit;
			}

			return null;
		},
	),
});
