import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	attachButtonCaption: {
		id: 'polaris-ideas.idea-view.controls.attach.attach-button-caption',
		defaultMessage: 'Add attachment',
		description: 'Button label for the attach button',
	},
	failedToUploadCaption: {
		id: 'polaris-ideas.idea-view.controls.attach.failed-to-upload-caption',
		defaultMessage: 'Failed to upload attachment',
		description: 'Error notification caption when an attachment upload fails',
	},
	firstFailedToUploadMessage: {
		id: 'polaris-ideas.idea-view.controls.attach.first-failed-to-upload-message',
		defaultMessage: 'We could not upload your attachment. Please try again.',
		description:
			'Error notification message when an attachment upload fails for the first time and we still assume we can repair the mechanism (fetch new token).',
	},
	finalFailedToUploadMessage: {
		id: 'polaris-ideas.idea-view.controls.attach.final-failed-to-upload-message',
		defaultMessage: 'We could not upload your attachment. Please refresh the page and try again.',
		description:
			'Error notification message when an attachment upload fails and we were unable to self-heal',
	},
});
