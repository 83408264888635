import React, { forwardRef, type HTMLProps } from 'react';
import { COMMAND_CONTAINER_ROW_TEST_ID, COMPAL_LISTBOX_ITEM_ID } from '../constants';

export type ListItemProps = {
	id?: string;
	active?: boolean;
};

export const CommandPaletteSearchResultListItem = forwardRef<
	HTMLDivElement,
	HTMLProps<HTMLDivElement> & ListItemProps
>(({ id, active, ...rest }, ref) => (
	<div
		ref={ref}
		data-testid={COMMAND_CONTAINER_ROW_TEST_ID}
		role="option"
		id={`${COMPAL_LISTBOX_ITEM_ID}-${id}`}
		aria-selected={active}
		{...rest}
	/>
));
