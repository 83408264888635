import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'polaris-common.controllers.workflow.error.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Flag title to communicate an error.',
	},
	workflowLoadingFailedDescription: {
		id: 'polaris-common.controllers.workflow.error.workflow-loading-failed-description',
		defaultMessage:
			'We had some difficulties to load some workflow data from the server. Please refresh the page.',
		description: 'Flag description for an error occurring while loading the project workflow.',
	},
});
