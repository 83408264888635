import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noManageViewFieldsPermissions: {
		id: 'polaris-common.field-config.item.toggle.no-manage-view-fields-permissions',
		defaultMessage: "You don't have permissions to manage fields in views",
		description: 'Information message for missing manage fields in views permissions.',
	},
	noPinIssueViewFieldsPermissions: {
		id: 'polaris-common.field-config.item.toggle.no-pin-issue-view-fields-permissions',
		defaultMessage: "You don't have permissions to pin fields in this view",
		description:
			'Information message for missing permissions to pin the fields in the single idea view.',
	},
	addField: {
		id: 'polaris-common.field-config.item.toggle.add-field',
		defaultMessage: 'Remove from view',
		description: 'Button label for hiding the current field in the view.',
	},
	removeField: {
		id: 'polaris-common.field-config.item.toggle.remove-field',
		defaultMessage: 'Add to view',
		description: 'Button label for showing the current field in the view.',
	},
	notRemoveable: {
		id: 'polaris-common.field-config.item.toggle.not-removeable',
		defaultMessage: 'This field cannot be removed',
		description: 'Tooltip label indicating that the current field cannot be removed',
	},
});
