import type { IssueViewRealtimeEventType } from '@atlassian/jira-issue-shared-types';
import type { ProjectId, IssueId, AccountId } from '@atlassian/jira-shared-types';

// REALTIME_UPDATE_CURRENT_ISSUE
export const REALTIME_UPDATE_CURRENT_ISSUE = 'REALTIME_UPDATE_CURRENT_ISSUE' as const;

export type RealtimeUpdateCurrentIssueActionPayload = {
	atlassianId: AccountId;
	projectId: ProjectId;
	issueId: IssueId;
	updateType: IssueViewRealtimeEventType;
};

export const realtimeUpdateCurrentIssue = (payload: RealtimeUpdateCurrentIssueActionPayload) => ({
	type: REALTIME_UPDATE_CURRENT_ISSUE,
	payload,
});

export type RealtimeUpdateCurrentIssue = ReturnType<typeof realtimeUpdateCurrentIssue>;
