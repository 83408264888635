import React, { type MouseEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useCurrentViewVisibleIssueActionFields } from '../../../controllers/views/selectors/view-hooks';
import { CommentsField } from '../../fields/comments';
import { InsightsField } from '../../fields/insights';
import { PlayField } from '../../fields/play';
import { ReactionsField } from '../../fields/reactions';

type Props = {
	localIssueId: LocalIssueId;
	className?: string;
};

export const ActionFields = ({ localIssueId, className }: Props) => {
	const fields = useCurrentViewVisibleIssueActionFields();
	const handleContainerClick = useCallback((e: MouseEvent) => e.stopPropagation(), []);

	if (fields.length === 0) {
		return null;
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<Container className={className}>
			<ActionsWrapper>
				{fields.map((field) => {
					if (field.type === FIELD_TYPES.ISSUE_COMMENTS) {
						return (
							<FieldContainer onClick={handleContainerClick} key={field.key}>
								<CommentsField appearance="board" localIssueId={localIssueId} />
							</FieldContainer>
						);
					}
					if (field.type === FIELD_TYPES.INSIGHTS) {
						return (
							<FieldContainer onClick={handleContainerClick} key={field.key}>
								<InsightsField appearance="board" localIssueId={localIssueId} />
							</FieldContainer>
						);
					}
					if (field.type === FIELD_TYPES.VOTES) {
						return (
							field.play !== undefined && (
								<FieldContainer onClick={handleContainerClick} key={field.key}>
									<PlayField
										appearance="board"
										localIssueId={localIssueId}
										playId={field.play.id}
										showPlaceholderLabel={false}
									/>
								</FieldContainer>
							)
						);
					}
					return null;
				})}
			</ActionsWrapper>
			<ReactionsWrapper>
				{fields.map((field) => {
					if (field.type === FIELD_TYPES.REACTIONS) {
						return (
							<ReactionsFieldContainer onClick={handleContainerClick} key={field.key}>
								<ReactionsField localIssueId={localIssueId} fieldKey={field.key} />
							</ReactionsFieldContainer>
						);
					}
					return null;
				})}
			</ReactionsWrapper>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	cursor: 'default',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '5px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '5px',
	paddingLeft: token('space.150', '12px'),
	paddingRight: token('space.150', '12px'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	marginRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="table-cell-wrapper-show-G7d8"]': {
		display: 'inherit',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="table-cell-wrapper-hide-95Gj"]': {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsWrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReactionsWrapper = styled(ActionsWrapper)({
	minWidth: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReactionsFieldContainer = styled(FieldContainer)({
	minWidth: '0',
	margin: '0',
});
