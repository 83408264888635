import React from 'react';

import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';

const CustomGlyph = (props: CustomGlyphProps) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.75961 3.82038L0.469614 1.53039L1.53027 0.469727L15.5303 14.4697L14.4696 15.5304L11.9569 13.0177C10.7749 13.6441 9.42396 14.0001 7.99985 14.0001C4.43327 14.0001 1.31894 11.7676 0.167087 8.61989C0.0206752 8.21978 0.0206129 7.78052 0.167056 7.38031C0.683493 5.96897 1.59401 4.74336 2.75961 3.82038ZM3.82896 4.88973C2.80461 5.65938 2.01287 6.70107 1.57571 7.89577C1.5511 7.96303 1.55109 8.03706 1.57574 8.10442C2.50926 10.6555 5.05721 12.5001 7.99985 12.5001C9.00694 12.5001 9.96863 12.284 10.8358 11.8965L9.52381 10.5846C9.07723 10.8483 8.55598 11.0001 7.99994 11.0001C6.34309 11.0001 4.99994 9.65691 4.99994 8.00006C4.99994 7.44402 5.15166 6.92277 5.41542 6.47619L3.82896 4.88973ZM6.55063 7.6114C6.51755 7.7353 6.49994 7.86555 6.49994 8.00006C6.49994 8.82848 7.17152 9.50006 7.99994 9.50006C8.13445 9.50006 8.2647 9.48246 8.3886 9.44937L6.55063 7.6114ZM7.99985 3.50006C7.50971 3.50006 7.03064 3.55121 6.56817 3.64826L6.26011 2.18024C6.82282 2.06215 7.40511 2.00006 7.99985 2.00006C11.5664 2.00006 14.6809 4.23249 15.8328 7.38022C15.9792 7.78032 15.9793 8.21955 15.8328 8.61977C15.5258 9.45892 15.0793 10.2324 14.5232 10.9153L13.3601 9.96815C13.8143 9.41034 14.1762 8.78194 14.4242 8.10432C14.4488 8.03708 14.4488 7.96307 14.4242 7.8957C13.4906 5.34464 10.9425 3.50006 7.99985 3.50006Z"
			fill="currentColor"
		/>
	</svg>
);

export const HiddenIcon = (props: Omit<IconProps, 'glyph'>) => (
	<Icon glyph={CustomGlyph} {...props} />
);
