import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import type { State, IssueTypeStatusConfig, IssueTypeToStatusMap, Status } from '../types';

export const getIssueTypeToStatusMap = (state: State): IssueTypeToStatusMap => state.statuses;

export const getAreStatusesInitialized = (state: State): boolean => state.meta.initialized;

export const getAllStatusesById = createSelector(
	getIssueTypeToStatusMap,
	(
		typeStatusMap: IssueTypeToStatusMap,
	): {
		[key: string]: Status;
	} =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		values(typeStatusMap).reduce<Record<string, any>>(
			(
				agg: {
					[key: string]: Status;
				},
				typeStatusConfig: IssueTypeStatusConfig,
			) => ({
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...agg,

				...keyBy(typeStatusConfig.statuses, ({ id }) => id),
			}),
			{},
		),
);

export const getAllStatusesAsList = createSelector(
	getAllStatusesById,
	(statuses: { [key: string]: Status }) => flatten(values(statuses)),
);
