import mapValues from 'lodash/mapValues';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CollectionView } from '@atlassian/jira-polaris-domain-collection';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fetchCollectionsViewsCounts as fetchCollectionsViewsCountsRequest } from '../../../services/collections';
import {
	createView as createViewRequest,
	deleteView as deleteViewRequest,
} from '../../../services/views';
import type { State } from '../types';

export type ViewToCreate = {
	visualizationType: ViewKind;
	name: string;
	fields?: string[];
};

export const createView =
	(
		collectionUUID: string,
		view: ViewToCreate,
	): Action<State, void, Promise<CollectionView | undefined>> =>
	async ({ setState, getState }) => {
		try {
			const state = getState();
			const existingCollection = state.collections[collectionUUID];
			const data = existingCollection?.data;

			if (!data) {
				return;
			}

			return await wrapWithExperience(experience.collections.createView, async () => {
				const newView = await createViewRequest(collectionUUID, view);

				setState({
					collections: {
						...state.collections,
						[collectionUUID]: {
							...existingCollection,
							data: {
								...data,
								views: [...(data?.views || []), newView],
							},
						},
					},
				});

				return newView;
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionViewCreationFailed', error),
				);
				throw error;
			}
		}
	};

export const fetchCollectionsViewsCounts =
	(): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		const state = getState();

		const collectionsUUIDs = Object.keys(state.collections);

		if (collectionsUUIDs.length === 0) {
			return;
		}

		try {
			await wrapWithExperience(experience.collections.loadViewCount, async () => {
				const collectionsViewsCountMap = await fetchCollectionsViewsCountsRequest(collectionsUUIDs);

				setState({
					collections: mapValues(state.collections, (collection) => {
						if (collection?.data) {
							return {
								...collection,
								data: {
									...collection.data,
									viewsCount: collectionsViewsCountMap[collection.data.uuid] || 0,
								},
							};
						}
						return collection;
					}),
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionsViewsCountLoadingFailed', error),
				);
			}
			throw error;
		}
	};

export const deleteView =
	(collectionUUID: string, viewUUID: string): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		try {
			const state = getState();
			const existingCollection = state.collections[collectionUUID];
			const data = existingCollection?.data;

			if (!data) {
				return;
			}

			await wrapWithExperience(experience.collections.deleteView, async () => {
				await deleteViewRequest(collectionUUID, viewUUID);

				setState({
					collections: {
						...state.collections,
						[collectionUUID]: {
							...existingCollection,
							data: {
								...data,
								viewsCount: data.viewsCount ? data.viewsCount - 1 : undefined,
								views: data?.views?.filter((view) => view.uuid !== viewUUID),
							},
						},
					},
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionViewDeletionFailed', error),
				);
				throw error;
			}
		}
	};
