import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notificationTitle: {
		id: 'polaris-common.merge-ideas-dialog.notifications.notification-title',
		defaultMessage: 'Idea successfully merged',
		description: 'Notification title on successful merge of ideas',
	},
	notificationDescriptionPrefix: {
		id: 'polaris-common.merge-ideas-dialog.notifications.notification-description-prefix',
		defaultMessage: 'Your ideas were merged into the new idea ',
		description: 'Merge success notification description prefix',
	},
	notificationDescriptionSuffix: {
		id: 'polaris-common.merge-ideas-dialog.notifications.notification-description-suffix',
		defaultMessage: ' and archived.',
		description: 'Merge success notification description suffix',
	},
	notificationViewArchiveButton: {
		id: 'polaris-common.merge-ideas-dialog.notifications.notification-view-archive-button',
		defaultMessage: 'View archive',
		description: 'Merge success notification view archive button label',
	},
	notificationErrorTitle: {
		id: 'polaris-common.merge-ideas-dialog.notifications.notification-error-title',
		defaultMessage: 'Unable to merge ideas',
		description: 'Notification title on failed merge of ideas',
	},
	notificationErrorDescription: {
		id: 'polaris-common.merge-ideas-dialog.notifications.notification-error-description',
		defaultMessage:
			'An error occurred when trying to merge your selection into a new idea. {error}',
		description: 'Merge failure notification description',
	},
});
