import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	aboutThisView: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.about-this-view',
		defaultMessage: 'About this view',
		description:
			"Text that's displayed just above the view description, should indicate that it's view info in the sidebar",
	},
});
