/** @jsx jsx */
import React, { useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import {
	useIssueIdField,
	useSortedDistinctIssueStatuses,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { useSortedGroupOptions } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import { useIssuesWithBasicProperties } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useFieldFilter,
	useHasSharedViewNoValueFilter,
	useIntervalFilter,
	useNumericFilter,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/filters-hooks';
import { useSortedStatusesAsList } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/statuses-hooks';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AlignedShortLabelContext } from '../../../../common/ui/issue/short-label';
import { CheckboxFilterComponent } from './checkbox';
import { DeliveryFilterComponent } from './delivery-tickets';
import { IntervalFilterComponent } from './interval';
import { messages } from './messages';
import { NumDataPointsFilterComponent } from './num-data-points';
import { NumericFilterComponent } from './numeric';
import { VariableOptionRenderer, useOptionsWithLabels } from './options';
import { BasicIssueOptionRenderer } from './options/renderers/basic-issue/index.tsx';
import { SelectFilterComponent } from './select';

type FilterOption = {
	groupIdentity: string | undefined;
	value?: unknown;
};

const useFilterOptions = (field: Field): FilterOption[] => {
	const fieldOptions = useSortedGroupOptions(field.key);
	const hasSharedViewNoValueFilter = useHasSharedViewNoValueFilter(field);
	const statusesFromProject = useSortedStatusesAsList(field);
	const distinctIssueStatusesSorted = useSortedDistinctIssueStatuses();
	const isCollectionView = useIsCollectionView();

	return useMemo(() => {
		if (field.type === FIELD_TYPES.STATUS) {
			const statuses = isCollectionView ? distinctIssueStatusesSorted : statusesFromProject;
			return statuses.map((status) => ({
				groupIdentity: status.id,
				value: status,
			}));
		}

		const nullableFieldOptions = (fieldOptions.options || []).map(
			({ groupIdentity, value }): FilterOption => ({
				groupIdentity,
				value,
			}),
		);

		if (!hasSharedViewNoValueFilter) {
			return nullableFieldOptions;
		}

		return [{ groupIdentity: undefined }, ...nullableFieldOptions];
	}, [
		field.type,
		fieldOptions.options,
		hasSharedViewNoValueFilter,
		isCollectionView,
		distinctIssueStatusesSorted,
		statusesFromProject,
	]);
};

type PolarisIdeaIssueIdFilterProps = {
	isLastFilter: boolean;
	field: Field;
	isDisabled?: boolean;
	isTemporary?: boolean;
};

const PolarisIdeaIssueIdFilter = ({
	isLastFilter,
	field,
	isDisabled,
	isTemporary,
}: PolarisIdeaIssueIdFilterProps) => {
	const allIssues = useIssuesWithBasicProperties();
	const filter = useFieldFilter(field.key, { isTemporary });
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { updateFieldFilter } = useViewActions();
	const { formatMessage } = useIntl();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	const componentOptions = useMemo(
		() =>
			allIssues
				.filter((issue) => !issue.isArchived)
				.map((issue) => ({
					label: `${issue.key} ${issue.summary}`,
					previewLabel: issue.key,
					id: String(issue.id),
					OptionRenderComponent: () => <BasicIssueOptionRenderer basicIssue={issue} />,
				})),
		[allIssues],
	);

	return (
		<div css={filterComponentWrapperStyles}>
			<AlignedShortLabelContext>
				<SelectFilterComponent
					label={formatMessage(messages.excludeIdeas)}
					fieldTypeIcon={fieldTypeIcon}
					emoji={emoji}
					selected={
						filter.type === 'FIELD' ? filter.values.map(({ stringValue }) => stringValue) : []
					}
					isLastFilter={isLastFilter}
					onChange={(values) => {
						fireUIAnalytics(
							createAnalyticsEvent({}),
							'excludedIssuesFilter updated',
							'view-controls',
							{
								fieldKey: field.key,
								fieldType: field.type,
							},
						);
						experience.headerView.viewFilter.start();
						updateFieldFilter(
							{
								...filter,
								values: values.map((stringValue) => ({ stringValue })),
								isTemporary,
							},
							() => {
								experience.headerView.viewFilter.success();
							},
							(error?: Error) => {
								experience.headerView.viewFilter.failure(error);
							},
						);
					}}
					options={componentOptions}
					isDisabled={isDisabled}
					isTemporary={isTemporary}
				/>
			</AlignedShortLabelContext>
		</div>
	);
};

type MultiOptionComponentProps = {
	isLastFilter: boolean;
	field: Field;
	filter: Filter;
	setFieldFilter: (arg1: (string | undefined)[]) => void;
	options: {
		groupIdentity: string | undefined;
		value?: unknown;
	}[];
	defaultOpen?: boolean;
	isDisabled?: boolean;
	isTemporary?: boolean;
};

const MultiOptionComponent = ({
	isLastFilter,
	defaultOpen,
	field,
	filter,
	setFieldFilter,
	options,
	isDisabled,
	isTemporary,
}: MultiOptionComponentProps) => {
	const optionsWithLabels = useOptionsWithLabels(field, options);

	const componentOptions = optionsWithLabels.map((option) => ({
		label: option.label,
		id: option.groupIdentity,
		OptionRenderComponent: () => (
			<VariableOptionRenderer
				field={field}
				groupIdentity={option.groupIdentity}
				value={option.value}
			/>
		),
	}));

	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<SelectFilterComponent
			label={field.label}
			fieldType={field.type}
			fieldTypeIcon={fieldTypeIcon}
			emoji={emoji}
			selected={filter.type === 'FIELD' ? filter.values.map(({ stringValue }) => stringValue) : []}
			onChange={setFieldFilter}
			options={componentOptions}
			isLastFilter={isLastFilter}
			defaultOpen={defaultOpen}
			isDisabled={isDisabled}
			isTemporary={isTemporary}
		/>
	);
};

type FilterComponentProps = {
	field: Field;
	isLastFilter: boolean;
	defaultOpen?: boolean;
	isTemporary?: boolean;
	isDisabled?: boolean;
};

export const FilterComponent = ({
	field,
	isLastFilter,
	defaultOpen,
	isTemporary,
	isDisabled = false,
}: FilterComponentProps) => {
	const filterOptions = useFilterOptions(field);
	const fieldFilter = useFieldFilter(field.key, { isTemporary });
	const numericFilter = useNumericFilter(field.key, { isTemporary });
	const intervalFilter = useIntervalFilter(field.key, { isTemporary });

	const {
		updateFieldFilter,
		updateNumericFilter,
		updateIntervalFilter,
		updateFilters,
		clearFieldFilter,
	} = useViewActions();
	const idField = useIssueIdField();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fieldTypeIcon = useFieldTypeIcon(field.key, undefined);
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	switch (field.type) {
		case FIELD_TYPES.INSIGHTS:
			return (
				<div css={filterComponentWrapperStyles}>
					<NumDataPointsFilterComponent
						label={field.label}
						values={numericFilter.values}
						intervalValues={intervalFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'numericFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateFilters(
								[
									{
										...numericFilter,
										values,
										isTemporary,
									},
									{
										...intervalFilter,
										values: [],
										isTemporary,
									},
								],
								isTemporary,
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						onChangeIntervalValue={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'intervalFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateFilters(
								[
									{
										...numericFilter,
										values: [],
										isTemporary,
									},
									{
										...intervalFilter,
										values,
										isTemporary,
									},
								],
								isTemporary,
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isTemporary={isTemporary}
					/>
				</div>
			);
		case FIELD_TYPES.CHECKBOX:
			return (
				<div css={filterComponentWrapperStyles}>
					<CheckboxFilterComponent
						label={field.label}
						values={numericFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'checkboxFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateNumericFilter(
								{
									...numericFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isTemporary={isTemporary}
					/>
				</div>
			);
		case FIELD_TYPES.NUMBER:
		case FIELD_TYPES.RATING:
		case FIELD_TYPES.SLIDER:
		case FIELD_TYPES.FORMULA:
		case FIELD_TYPES.LINKED_ISSUES:
			return (
				<div css={filterComponentWrapperStyles}>
					<NumericFilterComponent
						label={field.label}
						values={numericFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'numericFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateNumericFilter(
								{
									...numericFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isTemporary={isTemporary}
					/>
				</div>
			);
		case FIELD_TYPES.ASSIGNEE:
		case FIELD_TYPES.CREATOR:
		case FIELD_TYPES.REPORTER:
		case FIELD_TYPES.PEOPLE:
		case FIELD_TYPES.JSW_PEOPLE:
		case FIELD_TYPES.SINGLE_SELECT:
		case FIELD_TYPES.MULTI_SELECT:
		case FIELD_TYPES.JSW_MULTI_SELECT:
		case FIELD_TYPES.LABELS:
		case FIELD_TYPES.CUSTOM_LABELS:
		case FIELD_TYPES.STATUS:
		case FIELD_TYPES.ATLAS_GOAL:
		case FIELD_TYPES.ATLAS_PROJECT:
		case FIELD_TYPES.ATLAS_PROJECT_STATUS:
		case FIELD_TYPES.REACTIONS:
		case FIELD_TYPES.PROJECT:
			return (
				<div css={filterComponentWrapperStyles}>
					<MultiOptionComponent
						field={field}
						filter={fieldFilter}
						isLastFilter={isLastFilter}
						defaultOpen={defaultOpen}
						setFieldFilter={(values) => {
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'multiOptionFilter updated',
								'view-controls',
								{
									fieldKey: field.key,
									fieldType: field.type,
								},
							);

							if (values.length) {
								experience.headerView.viewFilter.start();
								updateFieldFilter(
									{
										...fieldFilter,
										values: values.map((stringValue) => ({ stringValue })),
										isTemporary,
									},
									() => {
										experience.headerView.viewFilter.success();
									},
									(error?: Error) => {
										experience.headerView.viewFilter.failure(error);
									},
								);
							} else {
								experience.headerView.viewFilter.start();
								clearFieldFilter(
									fieldFilter.field,
									isTemporary,
									() => {
										experience.headerView.viewFilter.success();
									},
									(error?: Error) => {
										experience.headerView.viewFilter.failure(error);
									},
								);
							}
						}}
						options={filterOptions}
						isDisabled={isDisabled}
						isTemporary={isTemporary}
					/>
				</div>
			);
		case FIELD_TYPES.ISSUE_ID:
			return idField ? (
				<PolarisIdeaIssueIdFilter
					field={idField}
					isLastFilter={isLastFilter}
					isDisabled={isDisabled}
					isTemporary={isTemporary}
				/>
			) : null;
		case FIELD_TYPES.DATE:
		case FIELD_TYPES.CREATED:
		case FIELD_TYPES.UPDATED:
		case FIELD_TYPES.INTERVAL:
			return (
				<div css={filterComponentWrapperStyles}>
					<IntervalFilterComponent
						label={field.label}
						values={intervalFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						defaultOpen={defaultOpen}
						isLastFilter={isLastFilter}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'intervalFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateIntervalFilter(
								{
									...intervalFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isTemporary={isTemporary}
					/>
				</div>
			);
		case FIELD_TYPES.DELIVERY_PROGRESS:
		case FIELD_TYPES.DELIVERY_STATUS:
			return (
				<div css={filterComponentWrapperStyles}>
					<DeliveryFilterComponent
						label={field.label}
						values={numericFilter.values}
						fieldTypeIcon={fieldTypeIcon}
						emoji={emoji}
						defaultOpen={defaultOpen}
						isLastFilter={isLastFilter}
						onChange={(values) => {
							fireUIAnalytics(createAnalyticsEvent({}), 'deliveryFilter updated', 'view-controls', {
								fieldKey: field.key,
							});
							experience.headerView.viewFilter.start();
							updateNumericFilter(
								{
									...numericFilter,
									values,
									isTemporary,
								},
								() => {
									experience.headerView.viewFilter.success();
								},
								(error?: Error) => {
									experience.headerView.viewFilter.failure(error);
								},
							);
						}}
						isDisabled={isDisabled}
						isTemporary={isTemporary}
					/>
				</div>
			);
		default:
			return null;
	}
};

const filterComponentWrapperStyles = css({
	flexGrow: 1,
	margin: `${token('space.050')} 0`,
	minWidth: '0',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		width: '100%',
		textAlign: 'left',
	},
});
