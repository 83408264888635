import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { noopJiraUpdateService } from '../common';
import type { JiraFieldMapping } from '../types';

export const jiraStatusMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<StatusFieldValue> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key] ?? undefined,
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	...noopJiraUpdateService, // TODO status update not supported yet
});
