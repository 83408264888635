import React from 'react';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type DeleteWeightingConfirmModalProps = {
	isOpen: boolean;
	onConfirm: () => void;
	onCancel: () => void;
};

export const DeleteWeightingConfirmModal = (props: DeleteWeightingConfirmModalProps) => {
	const { formatMessage } = useIntl();
	const { isOpen, onConfirm, onCancel } = props;

	return (
		<ModalTransition>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog width="normal">
						<ModalHeader>
							<ModalTitle appearance="danger">{formatMessage(messages.header)}</ModalTitle>
						</ModalHeader>
						<ModalBody>{formatMessage(messages.description)}</ModalBody>
						<ModalFooter>
							<Button appearance="subtle-link" onClick={onCancel}>
								{formatMessage(messages.cancel)}
							</Button>
							<Button
								appearance="danger"
								onClick={onConfirm}
								testId="polaris-lib-field-option-configuration.ui.field-options-config-menu-bar.change-option-weight-type-action.delete-weighting-confirm-modal.delete-button"
							>
								{formatMessage(messages.delete)}
							</Button>
						</ModalFooter>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ModalTransition>
	);
};
