import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.idea-view.not-found-view.title',
		defaultMessage: "We can't find the idea",
		description: 'Label for title of not found state',
	},
	description: {
		id: 'polaris-ideas.idea-view.not-found-view.description',
		defaultMessage: "We looked everywhere, but it doesn't exist or has been deleted.",
		description: 'Label for description of not found state',
	},
	buttonLabel: {
		id: 'polaris-ideas.idea-view.not-found-view.button-label',
		defaultMessage: 'Go to all ideas',
		description: 'Label for button of not found state',
	},
});
