import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	axisLockedMessage: {
		id: 'polaris-lib-matrix.axis.axis-locked-message',
		defaultMessage:
			'You can’t drag and drop bubbles on this axis because some fields don’t support it',
		description: 'Tooltip that says the field on the matrix axis cannot be changed',
	},
});
