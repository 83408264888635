import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fetchProjectMetadata } from '../services/polaris/fetch-project-meta';
import type { State, Props } from './types';

export const setIsProjectOnboarded =
	(projectId: string, isProjectOnboarded: boolean): Action<State, Props, void> =>
	({ setState, getState }) => {
		setState({
			[projectId]: {
				...getState()[projectId],
				metadata: {
					...getState()[projectId]?.metadata,
					isProjectOnboarded,
				},
			},
		});
	};

export const loadProjectMetadata =
	(): Action<State, Props, Promise<void>> =>
	async ({ setState, getState }, { client, cloudId, projectId }: Props) => {
		if (!client) {
			return;
		}

		if (getState()[projectId]?.isLoading || getState()[projectId]?.isLoaded) {
			return;
		}

		setState({
			[projectId]: {
				isLoading: true,
				isLoaded: false,
				error: undefined,
				metadata: undefined,
			},
		});

		try {
			// fetch single project
			const project = await fetchProjectMetadata(client, cloudId, projectId);

			setState({
				[projectId]: {
					isLoading: false,
					isLoaded: true,
					error: undefined,
					metadata: {
						isProjectOnboarded: project.onboarded,
					},
				},
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.fetchNavigationProjectMetadataFailed', error),
				);

				setState({
					[projectId]: {
						isLoading: false,
						isLoaded: false,
						error,
						metadata: undefined,
					},
				});
			}

			throw error;
		}
	};
