import { merge, setIn, chain } from 'icepick';
import forEach from 'lodash/forEach';
import { unsetIn } from '@atlassian/jira-common-icepick';
import type { IssueLinks } from '@atlassian/jira-issue-shared-types';
import {
	type FetchLinkedIssuesSuccess,
	type FetchLinkedIssuesDataSuccess,
	type SaveLinkedIssueRequest,
	type SaveLinkedIssueFailure,
	type SaveLinkedIssueSuccess,
	type FetchLinkedIssuesDataFailure,
	type SaveLinkedIssueRetry,
	type DeleteLinkedIssueRequest,
	type DeleteLinkedIssueFailure,
	type DeleteLinkedIssueSuccess,
	type SaveLinkedIssueCancel,
	type AddIssueLinkClick,
	type CloseAddIssueLink,
	type SetCreateLinkedIssuePermission,
	type CreateLinkedIssueSuccess,
	type ClearRelatedIssueLink,
	FETCH_LINKED_ISSUES_SUCCESS,
	FETCH_LINKED_ISSUES_DATA_SUCCESS,
	SAVE_LINKED_ISSUE_REQUEST,
	SAVE_LINKED_ISSUE_FAILURE,
	SAVE_LINKED_ISSUE_SUCCESS,
	FETCH_LINKED_ISSUES_DATA_FAILURE,
	SAVE_LINKED_ISSUE_RETRY,
	DELETE_LINKED_ISSUE_REQUEST,
	DELETE_LINKED_ISSUE_FAILURE,
	DELETE_LINKED_ISSUE_SUCCESS,
	SAVE_LINKED_ISSUE_CANCEL,
	ADD_ISSUE_LINK_CLICKED,
	ADD_ISSUE_LINK_CLOSED,
	SET_CREATE_LINKED_ISSUE_PERMISSION,
	CREATE_LINKED_ISSUE_SUCCESS,
	CLEAR_RELATED_ISSUE_LINK,
	SAVE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH,
	type SaveLinkedIssueRequestWithoutFetch,
	type DeleteLinkedIssueRequestWithoutFetch,
	DELETE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH,
	type DeleteLinkedIssueFailureNoFlags,
	DELETE_LINKED_ISSUE_FAILURE_NO_FLAG,
} from '../../actions/issue-links-actions';
import {
	type RefreshIssueSuccessAction,
	REFRESH_ISSUE_SUCCESS,
} from '../../common/actions/issue-fetch-actions';

type State = IssueLinks;

type Action =
	| FetchLinkedIssuesSuccess
	| FetchLinkedIssuesDataSuccess
	| SaveLinkedIssueRequest
	| SaveLinkedIssueRequestWithoutFetch
	| SaveLinkedIssueFailure
	| SaveLinkedIssueSuccess
	| FetchLinkedIssuesDataFailure
	| SaveLinkedIssueRetry
	| DeleteLinkedIssueRequest
	| DeleteLinkedIssueRequestWithoutFetch
	| DeleteLinkedIssueFailure
	| DeleteLinkedIssueFailureNoFlags
	| DeleteLinkedIssueSuccess
	| SaveLinkedIssueCancel
	| AddIssueLinkClick
	| CloseAddIssueLink
	| SetCreateLinkedIssuePermission
	| RefreshIssueSuccessAction
	| CreateLinkedIssueSuccess
	| ClearRelatedIssueLink;

export const initialState: State = {
	issueLinks: {},
	linkedIssues: {},
	linkedIssueKeys: [],
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case FETCH_LINKED_ISSUES_SUCCESS:
			return merge(state, { linkedIssues: action.payload });

		case FETCH_LINKED_ISSUES_DATA_SUCCESS: {
			const { linkedIssues, issueLinks, optimisticIds } = action.payload;
			const newState = unsetIn(state, ['issueLinks', optimisticIds]);
			return chain(newState).merge({ linkedIssues }).merge(issueLinks).value();
		}

		case SAVE_LINKED_ISSUE_REQUEST:
		case SAVE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH: {
			const { newIssueLinks } = action.payload;
			return chain(state)
				.merge(newIssueLinks.issueLinks)
				.setIn(['issueLinks', newIssueLinks.optimisticId, 'isOptimistic'], true)
				.setIn(['issueLinks', newIssueLinks.optimisticId, 'isLoading'], true)
				.setIn(['issueLinks', newIssueLinks.optimisticId, 'hasError'], false)
				.setIn(['issueLinks', newIssueLinks.optimisticId, 'isSaved'], false)
				.value();
		}

		case SAVE_LINKED_ISSUE_FAILURE:
			return chain(state)
				.setIn(['issueLinks', action.payload.optimisticId, 'isLoading'], false)
				.setIn(['issueLinks', action.payload.optimisticId, 'hasError'], true)
				.setIn(['issueLinks', action.payload.optimisticId, 'error'], action.payload.error)
				.value();

		case SAVE_LINKED_ISSUE_SUCCESS: {
			return chain(state)
				.setIn(['issueLinks', action.payload.optimisticId, 'isLoading'], false)
				.setIn(['issueLinks', action.payload.optimisticId, 'isSaved'], true)
				.value();
		}

		case FETCH_LINKED_ISSUES_DATA_FAILURE: {
			let newState = state;
			action.payload.optimisticIds.forEach((optimisticId) => {
				newState = setIn(newState, ['issueLinks', optimisticId, 'isLoading'], false);
			});
			return newState;
		}

		case SAVE_LINKED_ISSUE_RETRY:
			return chain(state)
				.setIn(['issueLinks', action.payload.optimisticId, 'isLoading'], true)
				.setIn(['issueLinks', action.payload.optimisticId, 'hasError'], false)
				.value();

		case DELETE_LINKED_ISSUE_REQUEST:
		case DELETE_LINKED_ISSUE_REQUEST_WITHOUT_FETCH: {
			const newState = setIn(
				state,
				['issueLinks', action.payload.linkedIssueId, 'isDeleted'],
				true,
			);
			return unsetIn(newState, ['deleteModalLinkId']);
		}

		case DELETE_LINKED_ISSUE_FAILURE:
		case DELETE_LINKED_ISSUE_FAILURE_NO_FLAG:
			return setIn(state, ['issueLinks', action.payload, 'isDeleted'], false);

		case DELETE_LINKED_ISSUE_SUCCESS:
		case SAVE_LINKED_ISSUE_CANCEL:
			return unsetIn(state, ['issueLinks', action.payload]);

		case ADD_ISSUE_LINK_CLICKED:
			return chain(state)
				.setIn(['quickAddClicked'], ((state || {}).quickAddClicked || 0) + 1)
				.setIn(['quickAddClickedIssue'], action.payload?.linkedIssue)
				.setIn(['isOpenedFromJsmSimilarIssues'], !!action.payload?.isOpenedFromJsmSimilarIssues)
				.value();

		case CLEAR_RELATED_ISSUE_LINK:
			return unsetIn(state, ['quickAddClickedIssue']);

		case ADD_ISSUE_LINK_CLOSED:
			return setIn(state, ['quickAddClicked'], 0);

		case SET_CREATE_LINKED_ISSUE_PERMISSION:
			return setIn(state, ['canCreateLinkedIssue'], action.payload.canCreateLinkedIssue);

		case REFRESH_ISSUE_SUCCESS: {
			if (action.payload.issueLinks) {
				const refreshedState = action.payload.issueLinks;
				return (
					chain(refreshedState)
						// The refreshed state won't have the `canCreateLinkedIssue` permission available. We need to get it
						// from the current state.
						// TODO: Ideally, `canCreateLinkedIssue` should be returned from Graphql/1, so we also get it when issue
						//  is refreshed (https://jdog.jira-dev.com/browse/SHIELD-4307)
						.setIn(['canCreateLinkedIssue'], state.canCreateLinkedIssue)
						//  The refreshed state won't have the `quickAddClicked` property as it is UI state.
						//  Here we are ensuring that it is maintained through `REFRESH_ISSUE_SUCCESS`
						//  otherwise, the add issue link form will close, preventing the user from linking an issue.
						.setIn(['quickAddClicked'], state.quickAddClicked)
						.value()
				);
			}
			return state;
		}

		case CREATE_LINKED_ISSUE_SUCCESS: {
			const { newIssueLinks } = action.payload;
			forEach(newIssueLinks.issueLinks, (issueLink, issueLinkId) => {
				newIssueLinks.issueLinks[issueLinkId] = {
					...issueLink,
					isOptimistic: false,
					isLoading: true,
					hasError: false,
					isSaved: true,
				};
			});

			return merge(state, newIssueLinks);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
