import React from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks';
import {
	useIsInitialized,
	useIsLoadingLinkedIssues,
} from '../../../../controllers/issue/selectors/meta-hooks';
import {
	useLinkedDeliveryIssuesExist,
	useLinkedProgress,
} from '../../../../controllers/issue/selectors/properties/hooks';
import { useStatusCategories } from '../../../../controllers/workflow/selectors/status-categories-hook.tsx';
import { DeliveryDataRestrictedMarker } from '../../../field-info-marker/delivery-data-restricted';
import {
	LinkedIssuesProgressComponent,
	BAR_ONLY,
	BAR_WITH_DONE_PERCENTAGE,
} from '../../../linked-issues-progress';
import { FIELD_HEIGHT, FIELD_HEIGHT_COMPACT } from '../../constants';
import { EmptyDeliveryProgressField } from '../empty';
import { FieldValue, FieldLabel } from '../field/styled';

type Appearance = 'default' | 'compact' | 'ultra-compact';

type LinkedIssuesProgressFieldProps = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	/**
	 * Defines a styles that will be applied to progress bar
	 */
	appearance?: Appearance;
	/**
	 * Whether the field is rendered in the summary card tooltip
	 */
	isSummary?: boolean;
	hideEmpty?: boolean;
};

export const LinkedIssuesProgressField = ({
	issueId,
	fieldKey,
	appearance = 'default',
	isSummary,
	hideEmpty,
}: LinkedIssuesProgressFieldProps) => {
	const progress = useLinkedProgress(issueId);
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(issueId);
	const statusCategories = useStatusCategories();
	const isLoading = useIsLoadingLinkedIssues();
	const isInitialized = useIsInitialized();
	const label = useFieldLabel(fieldKey);

	const hideComponent = hideEmpty && (!deliveryIssuesExist || statusCategories === undefined);
	const isCompact = appearance === 'compact';
	const isUltraCompact = appearance === 'ultra-compact';

	if (hideComponent) {
		return null;
	}

	const isProgressEmpty =
		statusCategories === undefined || isEmpty(statusCategories) || deliveryIssuesExist === false;

	if (isSummary && isProgressEmpty) {
		return (
			<>
				<FieldLabel>{label}</FieldLabel>
				<FieldValue>
					<EmptyDeliveryProgressField />
				</FieldValue>
			</>
		);
	}

	return (
		<LinkedIssuesProgressFieldValue isFullwidth>
			{!isUltraCompact && (
				<DeliveryDataRestrictedMarker fieldKey={fieldKey} marginRight={6} isInline />
			)}
			<LinkedIssuesProgressWrapper isCompact={isCompact} isUltraCompact={isUltraCompact}>
				<LinkedIssuesProgressComponent
					progress={progress}
					fieldKey={fieldKey}
					statusCategories={statusCategories}
					deliveryIssuesExist={deliveryIssuesExist}
					isLoading={isLoading || !isInitialized}
					height={isUltraCompact ? 4 : 8}
					appearance={isCompact || isUltraCompact ? BAR_ONLY : BAR_WITH_DONE_PERCENTAGE}
				/>
			</LinkedIssuesProgressWrapper>
		</LinkedIssuesProgressFieldValue>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkedIssuesProgressFieldValue = styled(FieldValue)({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkedIssuesProgressWrapper = styled.div<{ isCompact: boolean; isUltraCompact: boolean }>({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-7px',
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ isCompact, isUltraCompact }) =>
		// eslint-disable-next-line no-nested-ternary, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${isCompact ? FIELD_HEIGHT_COMPACT : isUltraCompact ? 4 : FIELD_HEIGHT}px`,
});
