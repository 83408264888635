import keyBy from 'lodash/keyBy';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type { Field, ServerRestUser } from '@atlassian/jira-issue-shared-types';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type';
import type {
	NextGenChildIssue,
	EpicIssue,
} from '@atlassian/jira-issue-view-common-types/src/issue-server-type';
import {
	ISSUE_TYPE,
	PRIORITY_TYPE,
	SUMMARY_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	TIME_TRACKING_TYPE,
	CREATED,
	UPDATED,
	RESOLUTION_TYPE,
	STORY_POINTS_TYPE,
} from '@atlassian/jira-platform-field-config';
import { toBaseUrl } from '@atlassian/jira-shared-types';
import { getIssueHtmlUrl } from './issue-urls';
import { transformUser } from './user-transformer';

// we need to define a type here for default because of flow ambiguous error
// does not really matter because epicIssue type and nextgen issue type is same
const defaultChildrenIssues: EpicIssue[] = [];

const getAssigneeFields = (
	assignee: ServerRestUser | null,
): {
	assigneeUrl?: string | null;
	assigneeDisplayName: string | null;
} => {
	if (assignee != null) {
		const { avatarUrl, displayName } = transformUser(assignee);
		return {
			assigneeUrl: avatarUrl,
			assigneeDisplayName: displayName,
		};
	}
	return {
		assigneeUrl: null,
		assigneeDisplayName: null,
	};
};

export const transformChild = (
	child: NextGenChildIssue,
	isSimplifiedProject = true,
): ChildIssue => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const systemFields: Record<string, Field> = keyBy<any>(
		child.fields,
		(field) => field.schema.system,
	);

	const issueType = systemFields[ISSUE_TYPE];
	const priority = systemFields[PRIORITY_TYPE];
	const summary = systemFields[SUMMARY_TYPE];
	const status = systemFields[STATUS_TYPE];
	const assignee = systemFields[ASSIGNEE_TYPE];
	const estimateField = child.fields.find((field) =>
		isSimplifiedProject
			? field.schema.custom === STORY_POINT_ESTIMATE_CF_TYPE
			: field.schema.custom === STORY_POINTS_TYPE,
	);
	const timeTrackingField = systemFields[TIME_TRACKING_TYPE];
	const created = systemFields[CREATED];
	const updated = systemFields[UPDATED];
	const resolution = systemFields[RESOLUTION_TYPE];

	const assigneeFields = assignee ? getAssigneeFields(assignee.value) : {};
	return {
		id: child.id,
		issueLink: getIssueHtmlUrl(toBaseUrl(''), child.key),
		issueTypeIconUrl: issueType?.value.iconUrl,
		issueTypeName: issueType?.value.name,
		issuePriorityUrl: priority?.value?.iconUrl ?? undefined,
		issuePriorityName: priority?.value?.name ?? undefined,
		issueKey: child.key,
		issueSummary: summary?.value,
		statusCategory: status?.value && statusCategoryForId(status.value.statusCategory.id),
		statusCategoryId: status?.value?.statusCategory.id,
		statusName: status?.value?.name ?? undefined,
		statusId: status?.value?.id ?? undefined,
		estimateFieldId: estimateField?.key ?? undefined,
		estimateValue: estimateField?.value ?? undefined,
		// @ts-expect-error - TS2339 - Property 'assigneeDisplayName' does not exist on type '{}'.
		assigneeDisplayName: assigneeFields.assigneeDisplayName,
		// @ts-expect-error - TS2339 - Property 'assigneeUrl' does not exist on type '{}'.
		assigneeUrl: assigneeFields.assigneeUrl,
		isLoading: false,
		issueTimeTracking: timeTrackingField?.value ?? undefined,
		...(created?.value && { created: new Date(created.value) }),
		...(updated?.value && { updated: new Date(updated.value) }),
		isResolved: !!resolution?.value,
	};
};

export const transformChildrenNew = (
	children: NextGenChildIssue[] | EpicIssue[] | null = defaultChildrenIssues,
	isSimplifiedProject?: boolean,
): ChildIssue[] =>
	!children ? [] : children.map((child) => transformChild(child, isSimplifiedProject));
