import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyScreenHeader: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.empty-states.empty-screen.empty-screen-header',
		defaultMessage: 'No available fields',
		description: 'Header of the empty fields screen',
	},
	emptyScreenDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.empty-states.empty-screen.empty-screen-description',
		defaultMessage: 'Create your first field and use it in your project.',
		description: 'Description of the empty fields screen',
	},
	createField: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.empty-states.empty-screen.create-field',
		defaultMessage: 'Create field',
		description: 'Label of Create field button',
	},
});
