import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	saveAction: {
		id: 'polaris-common.configure-properties-dialog.save-action',
		defaultMessage: 'Save',
		description: 'Save button',
	},
	cancelAction: {
		id: 'polaris-common.configure-properties-dialog.cancel-action',
		defaultMessage: 'Cancel',
		description: 'Cancel button',
	},
	configurePropertiesDialogHeader: {
		id: 'polaris-common.configure-properties-dialog.configure-properties-dialog-header',
		defaultMessage: 'Configure Properties for "{name}"',
		description: 'Configure Properties dialog header',
	},
});
