import { AnyAri } from '@atlassian/ari/any-ari';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import { isFakeId } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/actions/utils.tsx';
import type { NavigationView } from '@atlassian/jira-polaris-component-navigation-store/src/controllers/views/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View, ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { type State, type Props, ViewSectionTypeViews } from '../../types';
import { loadView } from '../load';
import { setViewSets } from '../set';
import { createNewViewEntity, internRemoteView, doesViewMatchSlug } from '../utils/views';

const createNewViewSet = (cloudId: string, id: string, name: string) => {
	const viewSetAri = AnyAri.create({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'viewset',
		resourceId: String(id),
	}).toString();
	const newViewSet: ViewSet = {
		id: viewSetAri,
		name,
		type: 'SECTION',
		rank: 0,
		collapsed: false,
		views: [],
	};
	return newViewSet;
};

const createNewView = (
	cloudId: string,
	viewSetId: string,
	id: string,
	name: string,
	kind: ViewKind,
	emoji: string | undefined,
) => {
	const viewAri = AnyAri.create({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'view',
		resourceId: String(id),
	}).toString();
	const newView: View = createNewViewEntity(viewSetId, kind, name, 0, false);
	newView.viewId = viewAri;
	newView.emoji = emoji;
	newView.viewLegacyId = parseInt(id, 10);
	newView.modified = false;
	return newView;
};

const updateExistingView = (view: View, name: string, emoji: string | undefined) => {
	if (view.title === name && view.emoji === emoji) {
		return view;
	}
	const updatedView: View = {
		...view,
		title: name,
		emoji,
	};
	return updatedView;
};

export const syncViewsWithNavigation =
	(navigationViews: NavigationView[]): Action<State, Props> =>
	(
		{ getState, dispatch },
		{ cloudId, router, fields, currentViewSlug, isSharedView, isCollectionView },
	) => {
		if (
			getState().meta.loading ||
			!getState().meta.loadingProps?.containerAri ||
			!getWillShowNav4()
		) {
			return;
		}

		let hasChanges = false;
		const viewSets = getState().viewSets;
		const changedViews: Record<string, View> = {};
		let manuallyCreatedView: View | undefined;
		const updatedViewSets = viewSets.map((viewSet) => {
			if (viewSet.type !== 'PRIORITIZE') {
				return viewSet;
			}

			const updatedViewSet: ViewSet = {
				...viewSet,
				viewSets: viewSet.viewSets || [],
				views: viewSet.views || [],
			};

			const viewsMap: Record<string, View> = updatedViewSet.views?.reduce((acc, view) => {
				const { viewId } = view;
				if (!viewId) {
					return acc;
				}
				return Object.assign(acc, { [viewId]: view });
			}, {});

			const viewSetsMap: Record<string, ViewSet> =
				viewSet.viewSets?.reduce((acc, innerViewSet) => {
					const { id } = innerViewSet;
					if (!id) {
						return acc;
					}
					innerViewSet.views?.forEach((view) => {
						const { viewId } = view;
						if (!viewId) {
							return;
						}
						viewsMap[viewId] = view;
					});
					return Object.assign(acc, { [id]: innerViewSet });
				}, {}) || {};

			navigationViews.forEach((navView) => {
				if (navView.viewType === 'SECTION') {
					if (isFakeId(navView.id)) {
						return;
					}
					const newViewSet = createNewViewSet(cloudId, String(navView.polarisId), navView.name);
					if (!viewSetsMap[newViewSet.id]) {
						updatedViewSet.viewSets = [...(updatedViewSet.viewSets || []), newViewSet];
						viewSetsMap[newViewSet.id] = newViewSet;
						hasChanges = true;
					}
					navView.views?.forEach((innerNavView: NavigationView) => {
						if (!innerNavView.viewType) {
							return;
						}
						if (isFakeId(innerNavView.id)) {
							return;
						}
						const newView = createNewView(
							cloudId,
							newViewSet.id,
							String(innerNavView.polarisId),
							innerNavView.name,
							innerNavView.viewType,
							innerNavView.emoji,
						);
						if (!newView.viewId) {
							return;
						}
						if (viewsMap[newView.viewId]) {
							const updatedView = updateExistingView(
								viewsMap[newView.viewId],
								innerNavView.name,
								innerNavView.emoji,
							);
							if (updatedView !== viewsMap[newView.viewId]) {
								changedViews[newView.viewId] = updatedView;
								hasChanges = true;
							}
						} else {
							viewSetsMap[newViewSet.id].views = [
								...(viewSetsMap[newViewSet.id].views || []),
								newView,
							];
							hasChanges = true;
							if (innerNavView.isManuallyCreated) {
								manuallyCreatedView = newView;
							}
						}
					});
					return;
				}
				if (!navView.viewType) {
					return;
				}
				if (isFakeId(navView.id)) {
					return;
				}
				const newView = createNewView(
					cloudId,
					viewSet.id,
					String(navView.polarisId),
					navView.name,
					navView.viewType,
					navView.emoji,
				);
				if (!newView.viewId) {
					return;
				}
				if (viewsMap[newView.viewId]) {
					const updatedView = updateExistingView(
						viewsMap[newView.viewId],
						navView.name,
						navView.emoji,
					);
					if (updatedView !== viewsMap[newView.viewId]) {
						changedViews[newView.viewId] = updatedView;
						hasChanges = true;
					}
				} else {
					updatedViewSet.views = [...(viewSet.views || []), newView];
					hasChanges = true;
					if (navView.isManuallyCreated) {
						manuallyCreatedView = newView;
					}
				}
			});

			return {
				...updatedViewSet,
				views: updatedViewSet.views?.map((view) => {
					const { viewId } = view;
					if (!viewId) {
						return view;
					}
					if (changedViews[viewId]) {
						return { ...changedViews[viewId] };
					}
					return view;
				}),
				viewSets: updatedViewSet.viewSets?.map((innerViewSet) => ({
					...innerViewSet,
					views: innerViewSet.views?.map((view) => {
						const { viewId } = view;
						if (!viewId) {
							return view;
						}
						if (changedViews[viewId]) {
							return { ...changedViews[viewId] };
						}
						return view;
					}),
				})),
			};
		});

		if (hasChanges) {
			dispatch(setViewSets(updatedViewSets));
			if (manuallyCreatedView?.viewLegacyId) {
				dispatch(
					loadView(manuallyCreatedView.viewLegacyId, undefined, () => {
						if (manuallyCreatedView === undefined) {
							return;
						}
						const modRemoteView = internRemoteView(
							fields,
							manuallyCreatedView,
							isSharedView,
							isCollectionView,
						);
						if (
							currentViewSlug !== undefined &&
							!doesViewMatchSlug(modRemoteView, currentViewSlug) &&
							isSharedView !== true
						) {
							router.routeTo({
								section: ViewSectionTypeViews,
								resource: modRemoteView.slug,
							});
						}
					}),
				);
			}
		}
	};
