import React, { useEffect } from 'react';
import UserPicker from '@atlassian/jira-base-user-picker/src/main.tsx';
import type { UserPickerProps } from '@atlassian/jira-base-user-picker/src/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

const ReporterEdit = (props: UserPickerProps) => {
	const {
		appearance,
		onChange,
		value = null,
		autoFocus = false,
		placeholder = '',
		isDisabled = false,
		isClearable = false,
		fetchSuggestions,
		portalElement,
		fieldId,
	} = props;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'issueField',
		);
	}, [createAnalyticsEvent]);

	return (
		<UserPicker
			appearance={appearance}
			fieldId={fieldId}
			onChange={onChange}
			value={value}
			autoFocus={autoFocus}
			placeholder={placeholder}
			isDisabled={isDisabled}
			fetchSuggestions={fetchSuggestions}
			isClearable={isClearable}
			shouldShowEmailInOptions
			portalElement={portalElement}
		/>
	);
};

export default ReporterEdit;
