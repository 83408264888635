import type { IssueKey } from '@atlassian/jira-shared-types';
import { createHook, createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'media-context',
	initialState: {},
	actions,
});

const getMediaSelector = (state: State, issueKey: IssueKey) => state[issueKey];

export const useMediaContextStore = createHook(store, {
	selector: getMediaSelector,
});

export const useMediaContextStoreActions = createHook(store);
