import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueTypeTransitionConfig, Status } from '../types';
import {
	createGetTransitionForStatus,
	getIssueTypeToTransitionsMap,
	createGetValidTransitionsForIssueTypeAndStatus,
} from './transitions';
import {
	createHigherLevelWorkflowHook2,
	createHigherLevelWorkflowHook3,
	createWorkflowHook,
} from './utils';

export const useTransitionForStatus = createHigherLevelWorkflowHook3<
	IssueTypeId,
	Status,
	Status,
	IssueTypeTransitionConfig | undefined
>(createGetTransitionForStatus);

export const useTransitionsMap = createWorkflowHook(getIssueTypeToTransitionsMap);

export const useValidTransitionsForIssueTypeAndStatus = createHigherLevelWorkflowHook2<
	IssueTypeId | undefined,
	Status | undefined,
	IssueTypeTransitionConfig[]
>(createGetValidTransitionsForIssueTypeAndStatus);
