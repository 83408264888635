import type { Observable as ObservableType } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-fetch';
import type { IssueId } from '@atlassian/jira-shared-types';

type Payload = {
	issueIds: IssueId[];
};

/*
 * This API can only be used to delete the parent of Team Managed Project (TMP) issues. However, multiple issue's parents may be changed in one call.
 */

export const getIssueDeleteParentsUrlTMP = (projectId: number): string =>
	`/rest/internal/simplified/1.0/projects/${projectId}/issues/delete-parents`;

export const deleteIssueParentsTMP = (
	projectId: number,
	payload: Payload,
): ObservableType<unknown> =>
	fetchJson$(getIssueDeleteParentsUrlTMP(projectId), {
		method: 'POST',
		body: JSON.stringify(payload),
	});
