import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const renameGlobalFieldOption = async (
	fieldKey: string,
	contextId: number,
	optionId: string,
	value: string,
): Promise<void> => {
	await performPutRequest(`/rest/api/3/field/${fieldKey}/context/${contextId}/option/`, {
		body: JSON.stringify({
			options: [
				{
					id: optionId,
					value,
				},
			],
		}),
	});
};
