import React from 'react';
import GlobalFieldIcon from '@atlaskit/icon/glyph/office-building-filled';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type GlobalFieldMarkerProps = {
	color?: string;
	noMargins?: boolean;
	hideTooltip?: boolean;
	inline?: boolean;
};

export const GlobalFieldMarker = ({
	color,
	noMargins,
	hideTooltip = false,
	inline = false,
}: GlobalFieldMarkerProps) => {
	const { formatMessage } = useIntl();

	return (
		<Box
			testId="polaris-lib-global-field-marker.ui"
			xcss={[
				!noMargins && globalFieldIconContainerStyles,
				inline && globalFieldIconInlineContainerStyles,
			]}
		>
			<Tooltip content={!hideTooltip && formatMessage(messages.globalField)}>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				<GlobalFieldIcon
					primaryColor={color}
					label={formatMessage(messages.globalField)}
					size="small"
				/>
			</Tooltip>
		</Box>
	);
};

const globalFieldIconContainerStyles = xcss({
	marginLeft: 'space.075',
});

const globalFieldIconInlineContainerStyles = xcss({
	display: 'inline-block',
});
