import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import { normalizeAggregatedDeliveryProgressResponse } from '../../common/normalize-aggregated-delivery-progress-response';
import type { AggregatedDeliveryProgressResponse } from '../../jira/types';

const getDeliveryUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v1'
	}/views/${viewId}/delivery-data`;

export const fetchDeliveryDataOld = (viewId: string): Promise<AggregatedDeliveryProgressResponse> =>
	fetchJson(getDeliveryUrl(viewId));

export const fetchDeliveryDataNew = (viewId: string): Promise<AggregatedDeliveryProgressResponse> =>
	fetchJson(getDeliveryUrl(viewId)).then(normalizeAggregatedDeliveryProgressResponse);

export const fetchDeliveryData = functionWithCondition(
	() => ff('polaris.publish.fix-error-dialog-on-published-views-after-reload'),
	fetchDeliveryDataNew,
	fetchDeliveryDataOld,
);
