import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noCreatedInsightsHint: {
		id: 'polaris-ideas.view-content.filtered-issue-modal.active-filters.field.num-data-points.no-created-insights-hint',
		defaultMessage:
			'No insights created {count, plural, =0 {today} one {in last # day} other {in last # days}}',
		description: 'Hint for missing insights that were created in last n days.',
	},
});
