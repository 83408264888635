import React, { useMemo, memo } from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useCurrentViewVerticalGroupBy } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { CellProps, GroupCellProps } from '@atlassian/jira-polaris-lib-list/src/types.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { RenderCell } from './cells/main.tsx';
import { RenderGroupCell } from './group-cells';

export const Cell = memo(({ columnId, rowId, isHovered }: CellProps) => {
	const fieldType = useFieldType(columnId);

	const analyticsData = useMemo(
		() => ({
			issueFieldKey: columnId,
			issueFieldType: fieldType,
		}),
		[columnId, fieldType],
	);

	return (
		<ContextualAnalyticsData attributes={analyticsData}>
			<RenderCell fieldKey={columnId} localIssueId={rowId} key={columnId} isHovered={isHovered} />
		</ContextualAnalyticsData>
	);
});

Cell.displayName = 'Cell';

export const GroupCell = memo(({ columnId, isHovered, groupId }: GroupCellProps) => {
	const verticalGroupByField = useCurrentViewVerticalGroupBy();

	return (
		<RenderGroupCell
			key={columnId}
			fieldKey={columnId}
			groupId={groupId}
			groupFieldKey={verticalGroupByField?.key}
			isHovered={isHovered}
		/>
	);
});

GroupCell.displayName = 'GroupCell';
