import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';

export const MAX_DELIVERY_STATUS_COMPACT_WIDTH = 110;
export const DELIVERY_STATUS_COMPACT_GAP = -6;
export const OPTION_COMPACT_MAX_WIDTH = 65;
export const MULTI_OPTION_COMPACT_MAX_WIDTH = 85;
export const SUMMARY_CARD_GAP = 4;

export const FULL_WIDTH_FIELD_TYPES: FieldType[] = [
	FIELD_TYPES.RATING,
	FIELD_TYPES.VOTES,
	FIELD_TYPES.ISSUE_COMMENTS,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.HYPERLINK,
];
