import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editField: {
		id: 'polaris-ideas.idea-view.fields.field.menu.edit-field',
		defaultMessage: 'Edit field',
		description: 'Dropdown item label to edit a field config',
	},
	fieldSettings: {
		id: 'polaris-ideas.idea-view.fields.field.menu.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button to open settings for the currently open field',
	},
	deleteField: {
		id: 'polaris-ideas.idea-view.fields.field.menu.delete-field',
		defaultMessage: 'Delete field',
		description: 'Dropdown item label to delete a field',
	},
	removeField: {
		id: 'polaris-ideas.idea-view.fields.field.menu.remove-field',
		defaultMessage: 'Remove from project',
		description: 'Dropdown item label to remove a global field from project',
	},
	noDeletePersmissions: {
		id: 'polaris-ideas.idea-view.fields.field.menu.no-delete-persmissions',
		defaultMessage: 'Only project admins can delete fields',
		description: 'Information message for missing field delete permissions.',
	},
	noPinPermissions: {
		id: 'polaris-ideas.idea-view.fields.field.menu.no-pin-permissions',
		defaultMessage: 'You don’t have permission to pin fields',
		description: 'Information message for missing pin permissions.',
	},
	noUnpinPermissions: {
		id: 'polaris-ideas.idea-view.fields.field.menu.no-unpin-permissions',
		defaultMessage: 'You don’t have permission to unpin fields',
		description: 'Information message for missing pin permissions.',
	},
	systemField: {
		id: 'polaris-ideas.idea-view.fields.field.menu.system-field',
		defaultMessage: "Jira created this field. You can't delete it.",
		description: 'Tooltip text for system field that cannot be deleted',
	},
});
