import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import { SimpleTag } from '@atlaskit/tag';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

export const EmptyField = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingInlineStart="space.050" xcss={emptyFieldStyles}>
			{formatMessage(messages.emptyField)}
		</Box>
	);
};

export const EmptyDeliveryProgressField = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingInlineStart="space.050" xcss={emptyFieldStyles}>
			{formatMessage(messages.emptyDeliveryProgressField)}
		</Box>
	);
};

export const EmptyUserField = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingInlineStart="space.050">
			<SimpleTag
				text={formatMessage(messages.emptyUserField)}
				appearance="rounded"
				elemBefore={<Avatar borderColor="transparent" size="xsmall" />}
			/>
		</Box>
	);
};

const emptyFieldStyles = xcss({
	color: 'color.text.disabled',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	fontWeight: 500,
	letterSpacing: '-0.6px',
});
