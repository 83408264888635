import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unsavedChanges: {
		id: 'polaris-common.navigation-blocker.prompt.unsaved-changes',
		defaultMessage: 'Unsaved changes',
		description:
			'Header text on modal that shows up when user attempts to navigate away from unsaved page.',
	},
	leave: {
		id: 'polaris-common.navigation-blocker.prompt.leave',
		defaultMessage: 'Leave',
		description: 'Text on button that will discard changes and navigate user away from page.',
	},
});
