import { SET_VALUE_ARRAY_SIZE_LIMIT } from '../constants';
import { baseScopesRequiredPerField } from '../fields-scopes';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid';
import { isNotEmptyString } from '../utils/is-not-empty-string';

export type PublicGetValue = {
	id: string;
	value: string;
};

export type MultiSelectPublicSetValue = string[];

export const validateMultiSelectSetValue = (value: unknown): value is MultiSelectPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const validateMultiSelectWithAriSetValue = (
	value: unknown,
): value is MultiSelectPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every((val) => isNotEmptyString(val) && isAriResourceIdValid(val));

export const multiSelectScopes = baseScopesRequiredPerField;
