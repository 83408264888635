import type { EntityLimitType } from '@atlassian/jira-polaris-component-entity-limits-store/src/common/types';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import { ENTITY_LIMIT_TYPE as COLLECTION_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-collection/src/constants';
import { ENTITY_LIMIT_TYPE as PROJECT_ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import { useIsCollectionView } from '../../../../environment';
import { createViewHook } from '../../utils';
import { getViewCount, getPrioritizeViewCount, getViewsetsCount } from './index';

export const useViewCount = createViewHook(getViewCount);

export const usePrioritizeViewCount = createViewHook(getPrioritizeViewCount);

export const useViewsetsCount = createViewHook(getViewsetsCount);

// Returns the limit type and value if reached, otherwise returns null
export const useHasReachedViewsLimit = (isSection?: boolean): [EntityLimitType | null, number] => {
	const isCollectionView = useIsCollectionView();

	const VIEW_LIMIT_TYPE = isCollectionView
		? COLLECTION_ENTITY_LIMIT_TYPE.VIEWS_PER_COLLECTION
		: PROJECT_ENTITY_LIMIT_TYPE.VIEWS_PER_PROJECT;

	const viewsCount = useViewCount();
	const viewsLimit = useEntityLimitsByType(VIEW_LIMIT_TYPE);

	const viewsetsCount = useViewsetsCount();
	const viewsetsLimit = useEntityLimitsByType(PROJECT_ENTITY_LIMIT_TYPE.VIEWSETS_PER_PROJECT);

	if (!isSection && viewsLimit && viewsCount >= viewsLimit) {
		return [VIEW_LIMIT_TYPE, viewsLimit];
	}

	if (isSection && viewsetsLimit && viewsetsCount >= viewsetsLimit) {
		return [PROJECT_ENTITY_LIMIT_TYPE.VIEWSETS_PER_PROJECT, viewsetsLimit];
	}

	return [null, 0];
};
