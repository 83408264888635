import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { ProjectField } from '@atlassian/jira-polaris-common/src/ui/fields/project';
import { useProjectMetadata } from '@atlassian/jira-polaris-component-project-metadata/src/controllers/index.tsx';
import messages from './messages';

type Props = {
	groupIdentity: string;
};

const EmptyProjectRenderer = () => {
	const { formatMessage } = useIntl();
	return <Box xcss={noValueCss}>{formatMessage(messages.noValueOption)}</Box>;
};

const ProjectOptionRenderer = ({ groupIdentity }: Props) => {
	const projectMeta = useProjectMetadata({ projectId: groupIdentity });

	if (groupIdentity === undefined) {
		return <EmptyProjectRenderer />;
	}

	return <ProjectField project={projectMeta} />;
};

const noValueCss = xcss({
	color: 'color.text.subtlest',
	marginLeft: 'space.100',
});

export const projectFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: ProjectOptionRenderer,
	EmptyComponent: EmptyProjectRenderer,
} as const;
