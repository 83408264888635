import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueCreatedProperty } from '../types';

export const insertId = (
	newSorted: LocalIssueId[],
	issueCreatedProperty: IssueCreatedProperty,
	id: LocalIssueId | undefined,
	visitedIds: LocalIssueId[],
) => {
	// do nothing if anchor id is undefined
	if (id === undefined) {
		return;
	}

	// if has already been called from the recursion, then exit.
	if (visitedIds.indexOf(id) !== -1) {
		return;
	}
	visitedIds.push(id);

	const createdProperty = issueCreatedProperty[id];

	// if id is not in the part of the createdProperty or is already been in the sorted array, exit.
	if (createdProperty === undefined || newSorted.indexOf(id) !== -1) {
		return;
	}

	// look at the left element
	const { anchorBefore } = createdProperty;
	if (anchorBefore !== undefined) {
		insertId(newSorted, issueCreatedProperty, anchorBefore, visitedIds);
		const beforeIndex = newSorted.indexOf(anchorBefore);
		if (beforeIndex !== -1) {
			// already added or not a createdIssueId
			newSorted.splice(beforeIndex + 1, 0, id);
			return; // no need to look at the "right" element
		}
	}

	// look at the right element
	const { anchorAfter } = createdProperty;
	if (anchorAfter !== undefined) {
		insertId(newSorted, issueCreatedProperty, anchorAfter, visitedIds);
		const afterIndex = newSorted.indexOf(anchorAfter);
		if (afterIndex !== -1) {
			// already added or not a createdIssueId
			newSorted.splice(afterIndex, 1, id, anchorAfter);
			return;
		}
	}

	// no before or after anchor => add as last
	newSorted.push(id);
};

export const addCreatedIssueIdsToSortedIds = (
	createProperty: IssueCreatedProperty,
	sorted: LocalIssueId[],
): LocalIssueId[] => {
	const newSorted: LocalIssueId[] = [...sorted];

	const createdIssueIds = Object.keys(createProperty);
	createdIssueIds.forEach((id) => {
		insertId(newSorted, createProperty, id, []);
	});

	return newSorted;
};
