import React from 'react';
import type { ColumnId } from '../../../common/types';
import { MIN_COLUMN_WIDTH } from '../../../common/ui/constants';
import { useListActions } from '../../../controllers';
import { useBaseTableApi } from '../../../controllers/selectors/ui-hooks';
import { Resizer } from '../../utils/resizer';

type ColumnResizerProps = {
	onResize: (width: number) => void;
	columnId: ColumnId;
	minWidth?: number;
	width?: number;
};

export const ColumnResizer = ({
	onResize,
	columnId,
	minWidth = MIN_COLUMN_WIDTH,
	width = MIN_COLUMN_WIDTH,
}: ColumnResizerProps) => {
	const tableApi = useBaseTableApi();
	const { resizeColumn, setResizingColumn } = useListActions();

	const handleColumnResize = (newWidth: number) => {
		tableApi?.getColumnManager().setColumnWidth(columnId, newWidth);
		tableApi?.forceUpdate();

		setResizingColumn({ [columnId]: newWidth });
		onResize(newWidth);
	};

	const handleColumnResizeStop = (newWidth: number) => {
		resizeColumn(columnId, newWidth);
		setResizingColumn();
	};

	return (
		<Resizer
			minWidth={minWidth}
			originalWidth={width}
			onResize={handleColumnResize}
			onResizeStop={handleColumnResizeStop}
		/>
	);
};
