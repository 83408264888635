import type { SnippetRefreshStatus } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';

export const isAuthRefreshError = (refresh?: SnippetRefreshStatus | null): boolean =>
	refresh?.errorType === 'NEED_AUTH';

export const isRefreshError = (refresh?: SnippetRefreshStatus | null): boolean =>
	refresh?.error !== null && refresh?.error !== undefined;

export const isPendingRefresh = (refresh?: SnippetRefreshStatus | null): boolean =>
	refresh?.queued !== null && refresh?.queued !== undefined;
