import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-view-empty-state.views.timeline-view.header',
		defaultMessage: 'Set up timeline',
		description: 'Header of the new timeline view hint',
	},
	description1: {
		id: 'polaris-lib-view-empty-state.views.timeline-view.description-1',
		defaultMessage: 'Select the date fields to use a timeline view.',
		description: 'New timeline view hint to start',
	},
	description2: {
		id: 'polaris-lib-view-empty-state.views.timeline-view.description-2',
		defaultMessage: 'You can drag and drop ideas into it.',
		description: 'New timeline view hint to start',
	},
});
