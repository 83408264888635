import React, { type SyntheticEvent, memo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Popup from '@atlaskit/popup';
import { Radio } from '@atlaskit/radio';
import { N30A, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewLayoutType,
	useHasLayoutTypeUnsavedChanges,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ActionButton } from '../action-button';
import { messages } from './messages';
import { ConfigLayoutPreview } from './preview-image';

export const getTooltipContentMessage = (isCompact: boolean, isSummary: boolean) => {
	if (isSummary) {
		return messages.optionSummaryNonFinal;
	}
	if (isCompact) {
		return messages.optionCompact;
	}
	return messages.optionDetailed;
};

export const ConfigLayout = memo(() => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentViewKind = useCurrentViewKind();

	const { setLayoutType } = useViewActions();
	const layoutType = useCurrentViewLayoutType();
	const hasUnsavedChanges = useHasLayoutTypeUnsavedChanges();

	const isSummary = layoutType === ViewLayoutType.SUMMARY;
	const isCompact = layoutType === ViewLayoutType.COMPACT;
	const isDetailed = layoutType === ViewLayoutType.DETAILED;
	const isTimelineView = currentViewKind === VIEW_KIND_TIMELINE;

	const tooltipContent = `${formatMessage(messages.title)}: ${formatMessage(
		getTooltipContentMessage(isCompact, isSummary),
	)}`;

	const handleOpenPopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			setIsOpen(!isOpen);
			fireUIAnalytics(analyticsEvent, 'cardLayout');
			if (!isOpen) {
				fireCompoundAnalyticsEvent.View.viewLayoutSettingOpened();
			}
		},
		[isOpen],
	);

	const handleSetSummary = useCallback(() => {
		setLayoutType(ViewLayoutType.SUMMARY);
		fireCompoundAnalyticsEvent.View.viewLayoutChanged(
			createAnalyticsEvent({}),
			ViewLayoutType.SUMMARY,
		);
	}, [createAnalyticsEvent, setLayoutType]);

	const handleSetCompact = useCallback(() => {
		setLayoutType(ViewLayoutType.COMPACT);
		fireCompoundAnalyticsEvent.View.viewLayoutChanged(
			createAnalyticsEvent({}),
			ViewLayoutType.COMPACT,
		);
	}, [createAnalyticsEvent, setLayoutType]);

	const handleSetDetailed = useCallback(() => {
		setLayoutType(ViewLayoutType.DETAILED);
		fireCompoundAnalyticsEvent.View.viewLayoutChanged(
			createAnalyticsEvent({}),
			ViewLayoutType.DETAILED,
		);
	}, [createAnalyticsEvent, setLayoutType]);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
			content={() => (
				<PopupContentContainer>
					<Title>{formatMessage(messages.title)}</Title>
					{isTimelineView && (
						<RadioWrapper>
							<Radio
								id="pendo.config-layout.summary-button"
								label={
									<RadioLabelWrapper>
										{formatMessage(messages.optionSummaryNonFinal)}
										<ConfigLayoutPreview isSelected={isSummary} isSummary />
									</RadioLabelWrapper>
								}
								name="card-layout"
								isChecked={isSummary}
								onChange={handleSetSummary}
							/>
						</RadioWrapper>
					)}
					<RadioWrapper>
						<Radio
							id="pendo.config-layout.compact-button"
							label={
								<RadioLabelWrapper>
									{formatMessage(messages.optionCompact)}
									<ConfigLayoutPreview isSelected={isCompact} isCompact />
								</RadioLabelWrapper>
							}
							name="card-layout"
							isChecked={isCompact}
							onChange={handleSetCompact}
						/>
					</RadioWrapper>
					<RadioWrapper>
						<Radio
							id="pendo.config-layout.detailed-button"
							label={
								<RadioLabelWrapper>
									{formatMessage(messages.optionDetailed)}
									<ConfigLayoutPreview isSelected={isDetailed} />
								</RadioLabelWrapper>
							}
							name="card-layout"
							isChecked={isDetailed}
							onChange={handleSetDetailed}
						/>
					</RadioWrapper>
				</PopupContentContainer>
			)}
			trigger={(triggerProps) => (
				<ButtonWrapper {...triggerProps}>
					<Tooltip content={!isOpen && tooltipContent} position="right-start">
						<ActionButton
							id="polaris-ideas.ui.view-controls.config-layout.button"
							testId="polaris-ideas.ui.view-controls.config-layout"
							isSelected={isOpen}
							isEmpty={isTimelineView ? isSummary : isDetailed}
							iconBefore={
								<CardLayoutIconWrapper>
									<PolarisIcon name={PolarisIconType.CardLayout} label={tooltipContent} />
								</CardLayoutIconWrapper>
							}
							onClick={handleOpenPopup}
							hasUnsavedChanges={hasUnsavedChanges}
						/>
					</Tooltip>
				</ButtonWrapper>
			)}
		/>
	);
});
export default ConfigLayout;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupContentContainer = styled.div({
	padding: token('space.150', '12px'),
	boxSizing: 'border-box',
	width: '290px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	color: token('color.text.subtle', N500),
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RadioWrapper = styled.div({
	borderTop: `2px solid ${token('color.background.neutral', N30A)}`,
	paddingTop: token('space.100', '8px'),
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	label: {
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		flex: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RadioLabelWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	display: 'inline-flex',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardLayoutIconWrapper = styled.span({
	width: '24px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
