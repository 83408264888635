import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-ideas.insights.insights.add-insight.add-link-hint.title',
		defaultMessage: 'Capture more data with links',
		description: 'Hint title',
	},
	description: {
		id: 'polaris-ideas.insights.insights.add-insight.add-link-hint.description',
		defaultMessage:
			'Jira Product Discovery fetches data to use for ideas prioritization through a link integration.',
		description: 'Hint description',
	},
	learnMoreButton: {
		id: 'polaris-ideas.insights.insights.add-insight.add-link-hint.learn-more-button',
		defaultMessage: 'Learn more about integrations',
		description: 'Button caption for learn more button',
	},
	learnMore: {
		id: 'polaris-ideas.insights.insights.add-insight.add-link-hint.learn-more',
		defaultMessage: 'Learn more',
		description: 'Button caption for learn more button',
	},
});
