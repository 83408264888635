import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	restrictedContext: {
		id: 'polaris-lib-restricted-field.restricted-field-banner.restricted-context',
		defaultMessage:
			"You can't add this field to a project, because its context has changed. <a>Add or edit context</a> to manage global context for this field.",
		description:
			'Message explaining that the field cannot be added to the project due to Jira admin context restrictions.',
	},
	restrictedFieldWarningForJiraAdmin: {
		id: 'polaris-lib-restricted-field.restricted-field-banner.restricted-field-warning-for-jira-admin',
		defaultMessage:
			'The context of this field has changed. Jira admins can <a>add or edit global context</a> to make it available in the project.',
		description: 'Text in warning banner for when a Jira admin opens a restricted field',
	},
	restrictedFieldWarningForOthers: {
		id: 'polaris-lib-restricted-field.restricted-field-banner.restricted-field-warning-for-others',
		defaultMessage:
			'The context of this field has changed. Ask your Jira admin to change the context to make it available in the project.',
		description: 'Text in warning banner for when a non-Jira admin opens a restricted field',
	},
});
