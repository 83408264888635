import React, {
	type FC,
	type PropsWithChildren,
	forwardRef,
	useEffect,
	useMemo,
	useRef,
	useState,
	type RefAttributes,
} from 'react';
import noop from 'lodash/noop';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { CleanupFn } from '@atlaskit/pragmatic-drag-and-drop/types';
import { Box } from '@atlaskit/primitives';
import { TreeItemBase } from '@atlassian/jira-polaris-lib-tree/src/common/ui/item-base/index.tsx';
import { TreeItemDraggable } from '@atlassian/jira-polaris-lib-tree/src/ui/item-draggable/index.tsx';
import { getClosestScrollableElement } from '@atlassian/jira-polaris-lib-tree/src/utils/scrollable-element.tsx';
import type { TreeItemHeadProps, TreeItemContainerProps } from './common/ui/item-base';
import { DEFAULR_INDENT_PER_LEVEL } from './constants';
import type {
	DragEnabledProp,
	DragPreview,
	DropEnabledProp,
	ItemId,
	OnDragEnd,
	OnDragStart,
	RenderItem,
	TreeItem,
	TreeObject,
	TreeSourcePosition,
	RenderItemEmptyState,
} from './types';

export type TreeProps<TItem extends TreeItem> = {
	tree: TreeObject<TItem>;
	renderItem: RenderItem<TItem>;
	indentPerLevel: number;
	onExpand?(itemId: ItemId): void;
	onCollapse?(itemId: ItemId): void;
	isDragEnabled?: DragEnabledProp<TItem>;
	isDropEnabled?: DropEnabledProp<TItem>;
	onDragStart?: OnDragStart;
	onDragEnd?: OnDragEnd;
	dragPreview?: DragPreview<TItem>;
	itemType?: string;
	renderItemEmptyState?: RenderItemEmptyState<TItem>;
	TreeItemParentContainer?: React.FC<TreeItemParentContainerProps & RefAttributes<HTMLElement>>;
	TreeItemHead?: React.FC<TreeItemHeadProps<TItem>>;
	TreeItemContainer?: React.FC<TreeItemContainerProps<TItem>>;
};

const Child = <TItem extends TreeItem>({
	item,
	pathToParent,
	parentId,
	index,
	parentLevel,
	isLastInGroup,
	TreeItemComponent,
	...shared
}: {
	item: TItem;
	pathToParent: TreeSourcePosition[];
	index: number;
	parentLevel: number;
	parentId: ItemId;
	isLastInGroup: boolean;
	TreeItemComponent: typeof TreeItemDraggable;
} & SharedProps<TItem>) => {
	const { indentPerLevel = DEFAULR_INDENT_PER_LEVEL } = shared;

	const path: TreeSourcePosition[] = useMemo(
		() => [...pathToParent, { parentId, index }],
		[pathToParent, parentId, index],
	);

	return (
		<TreeItemComponent<TItem>
			key={`tree-item-${item.id}`}
			item={item}
			renderItem={shared.renderItem}
			indentPerLevel={indentPerLevel}
			currentLevel={parentLevel}
			isExpanded={item.isExpanded}
			onExpand={shared.onExpand}
			onCollapse={shared.onCollapse}
			// draggable props
			index={index}
			TreeItemHead={shared.TreeItemHead}
			TreeItemContainer={shared.TreeItemContainer}
			isLastInGroup={isLastInGroup}
			path={path}
			onDragStart={shared.onDragStart ?? noop}
			onDragEnd={shared.onDragEnd ?? noop}
			dragPreview={shared.dragPreview}
			isDragEnabled={shared.isDragEnabled}
			isDropEnabled={shared.isDropEnabled}
			itemType={shared.itemType ?? 'tree-item'}
		>
			{item.children.length ? (
				<Parent
					parent={item}
					currentPath={path}
					TreeItemParentContainer={shared.TreeItemParentContainer}
					TreeItemComponent={TreeItemComponent}
					{...shared}
				/>
			) : (
				shared.renderItemEmptyState?.({ item, indent: parentLevel * indentPerLevel })
			)}
		</TreeItemComponent>
	);
};

type SharedProps<TItem extends TreeItem> = PropsWithChildren<TreeProps<TItem>> & {
	TreeItemComponent: typeof TreeItemDraggable;
};

type ParentProps<TItem extends TreeItem> = {
	ref?: React.Ref<HTMLDivElement>;
	parent: TItem;
	currentPath: TreeSourcePosition[];
} & SharedProps<TItem>;

/**
 * Generics in prop types don't seem to play well with `forwardRef` so we
 * are using this to work around type errors.
 *
 * See <https://stackoverflow.com/a/73795494> for further information.
 */
interface ParentWithForwardRef extends FC<ParentProps<TreeItem>> {
	<TItem extends TreeItem>(props: ParentProps<TItem>): ReturnType<React.FC<ParentProps<TItem>>>;
}

export type TreeItemParentContainerProps = PropsWithChildren<{
	role: string;
}>;

const DefaultTreeItemParentContainer = forwardRef(
	({ role, children }: TreeItemParentContainerProps, ref: React.Ref<HTMLElement>) => (
		<Box ref={ref} role={role}>
			{children}
		</Box>
	),
);

const Parent: ParentWithForwardRef = forwardRef(
	<TItem extends TreeItem>(
		{
			parent,
			currentPath,
			...shared
		}: {
			parent: TItem;
			currentPath: TreeSourcePosition[];
		} & SharedProps<TItem>,
		ref: React.Ref<HTMLDivElement>,
	) => {
		// If the parent has no children, we don't need to render anything
		if (parent.children.length === 0) {
			return null;
		}

		const parentLevel = currentPath.length;
		const Container = shared.TreeItemParentContainer || DefaultTreeItemParentContainer;

		return (
			<Container ref={ref} role={parentLevel === 0 ? 'tree' : 'group'}>
				{parent.children.map((childId: ItemId, index: number, array: ItemId[]) => {
					const item = shared.tree.items[childId];
					return (
						<Child
							key={`tree-item-${item.id}`}
							item={item}
							pathToParent={currentPath}
							parentLevel={parentLevel}
							parentId={parent.id}
							index={index}
							isLastInGroup={index === array.length - 1}
							{...shared}
						/>
					);
				})}
			</Container>
		);
	},
);

export const Tree = <TItem extends TreeItem>(props: PropsWithChildren<TreeProps<TItem>>) => {
	const TreeItemComponent = useMemo(
		() => (props.isDragEnabled ? TreeItemDraggable : TreeItemBase),
		[props.isDragEnabled],
	);

	const [path] = useState([]);

	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		let cleanupAutoScroll: CleanupFn | null = null;

		return combine(
			monitorForElements({
				onDragStart: () => {
					// Setups auto-scrolling only once when dragging starts
					if (cleanupAutoScroll) {
						return;
					}

					// Find the closest scrollable parent element to the tree
					const closestScrollable = getClosestScrollableElement(ref.current);
					if (!closestScrollable) {
						return;
					}
					cleanupAutoScroll = autoScrollForElements({
						element: closestScrollable,
						canScroll: ({ source }) => source.data.type === 'tree-item',
					});
				},
				canMonitor: ({ source }) => source.data.type === 'tree-item',
			}),
			() => cleanupAutoScroll?.(),
		);
	}, []);

	const root = props.tree.items[props.tree.rootId];

	// Cannot render a tree without a root tree item
	if (!root) {
		return null;
	}

	return (
		<Parent
			ref={ref}
			parent={root}
			currentPath={path}
			TreeItemComponent={TreeItemComponent}
			{...props}
		/>
	);
};
