import { baseScopesRequiredPerField } from '../fields-scopes';
import type { InternalToPublicPropertyTransformer } from '../types';

type PublicValue = string;
type InternalValue = string | undefined | null;

export const validateSingleLineTextValue = (value: unknown): value is PublicValue =>
	typeof value === 'string';

export const transformSingleLineTextToPublicValue: InternalToPublicPropertyTransformer<
	InternalValue,
	PublicValue
> = (internalValue) => internalValue || '';

export const singleLineTextScopes = baseScopesRequiredPerField;
