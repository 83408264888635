import React, { type ComponentType } from 'react';
import set from 'lodash/set';
import { components, type MenuProps } from '@atlaskit/select';
import { Control } from './control';
import { IndicatorsContainer } from './indicators-container';
import {
	createMenuListComponent,
	createNoOptionCreate,
	HAS_MORE_GLOBAL_LABELS_FLAG_ID,
} from './menu-list';
import { createMultiValueLabelComponent } from './multi-value-label';
import { createEditOptionComponent } from './option';
import { Placeholder } from './placeholder';
import { createSingleValueComponent } from './single-value';
import { ValueContainer } from './value-container';

export { HAS_MORE_GLOBAL_LABELS_FLAG_ID };

type AddMode = 'add-first' | 'search-first' | 'config-only';

type DecoratableSelectProps = {
	isMulti: boolean;
	hideOptionEditButtons?: boolean;
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
	FieldDecorator: ComponentType<{
		id: string;
	}>;
	addMode?: AddMode;
	setOpenOption?: (arg1: string | null) => void;
	menuId?: string;
	onAddOption?: (arg1: string) => Promise<string | undefined>;
	onConfigRequested?: () => void;
	hideInput?: boolean;
};

const createMenuComponentNext =
	(id: string) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	({ children, ...rest }: MenuProps<any>) => (
		<div id={id}>
			<components.Menu {...rest}>{children}</components.Menu>
		</div>
	);

export const createDecoratableSelectCustomComponents = ({
	isMulti,
	onAddOption,
	DecoratedTagComponent,
	addMode,
	menuId,
	onConfigRequested,
	hideOptionEditButtons,
	hideInput = false,
}: DecoratableSelectProps) => {
	const Option = createEditOptionComponent({
		isMulti,
		hideEditButton: hideOptionEditButtons,
		DecoratedTagComponent,
		setOpenOption: onConfigRequested,
	});
	const SingleValue = createSingleValueComponent({ DecoratedTagComponent });
	const MultiValueLabel = createMultiValueLabelComponent({ DecoratedTagComponent });

	// Instead of tags we show count of them
	const MultiValueContainer = () => null;

	// Don't show the input at all
	const Input = () => null;

	const customComponents = {
		Control,
		MultiValueContainer,
		MultiValueLabel,
		Option,
		SingleValue,
		ValueContainer,
		IndicatorsContainer,
		Placeholder,
		Input: components.Input,
	};

	if (hideInput) {
		set(customComponents, 'Input', Input);
	}

	if (menuId !== undefined) {
		set(customComponents, 'Menu', createMenuComponentNext(menuId));
	}

	if (addMode === 'config-only') {
		set(
			customComponents,
			'MenuList',
			createMenuListComponent({ showCreateButton: false, onConfigRequested }),
		);
		set(customComponents, 'NoOptionsMessage', () => null);
	} else if (onAddOption !== undefined) {
		if (addMode === 'add-first') {
			set(
				customComponents,
				'MenuList',
				createMenuListComponent({ showCreateButton: true, onAddOption, onConfigRequested }),
			);
			set(customComponents, 'NoOptionsMessage', () => null);
		} else if (addMode === 'search-first') {
			set(
				customComponents,
				'MenuList',
				createMenuListComponent({
					showCreateButton: false,
					onConfigRequested,
					showOnlyConfig: true,
				}),
			);
			set(customComponents, 'NoOptionsMessage', createNoOptionCreate(onAddOption));
		}
	}

	return customComponents;
};
