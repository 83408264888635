import React from 'react';
import { styled } from '@compiled/react';
import ButtonBase from '@atlaskit/button';
import {
	ButtonWithTooltip as ButtonWithTooltipBase,
	type ButtonWithTooltipProps,
} from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';

type DropdownMenuItemProps = Partial<
	Pick<ButtonWithTooltipProps, 'children' | 'isDisabled' | 'onClick' | 'tooltipProps'>
>;

export const DropdownMenuItem = (props: DropdownMenuItemProps) => {
	const { children, tooltipProps, isDisabled, onClick } = props;

	if (tooltipProps) {
		return (
			<ButtonWithTooltip
				tooltipProps={tooltipProps}
				isDisabled={isDisabled}
				onClick={onClick}
				appearance="subtle"
				shouldFitContainer
			>
				{children}
			</ButtonWithTooltip>
		);
	}

	return (
		<Button isDisabled={isDisabled} onClick={onClick} appearance="subtle" shouldFitContainer>
			{children}
		</Button>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWithTooltip = styled(ButtonWithTooltipBase)({
	borderRadius: 0,
	textAlign: 'start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Button = styled(ButtonBase)({
	borderRadius: 0,
	textAlign: 'start',
	display: 'block',
});
