import { ff, getMultivariateFeatureFlag } from '@atlassian/jira-feature-flagging';

export const isJsmTimelineEnabled = (): boolean => ff('jsm-timelines-phase-2_70gja', false);

export const isGiraAGGConsistencyCheckEnabled = () =>
	getMultivariateFeatureFlag('issue.details.gira-agg-consistency-check-master-flag', 'OLD', [
		'OLD',
		'NEW',
		'CHECK',
		'CHECK_RETURN_NEW',
	]) === 'CHECK';

export const isIssueExportControlEnabled = () => ff('dlp-gordian-enabled', false);
