import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	votesText: {
		id: 'polaris-common.plays.vote-input.votes-text',
		defaultMessage:
			'You have {numberStart}{number, plural, =0 {no votes} one {# vote} other {# votes}}{numberEnd} left to spread among ideas',
		description: 'Explainer text for the number of votes left',
	},
	votesHelpText: {
		id: 'polaris-common.plays.vote-input.votes-help-text',
		defaultMessage: 'Set the number of votes you’d like to give',
		description: 'Help text for the voting button',
	},
	noVotesLeftTooltip: {
		id: 'polaris-common.plays.vote-input.no-votes-left-tooltip',
		defaultMessage: 'You’ve used all your votes',
		description: 'Tooltip if no votes left',
	},
	archivedIdea: {
		id: 'polaris-common.plays.vote-input.archived-idea',
		defaultMessage: 'The idea is archived',
		description: 'Tooltip if the idea is archived',
	},
	votesTitle: {
		id: 'polaris-common.plays.vote-input.votes-title',
		defaultMessage: 'Votes',
		description: 'Title for votes sidebar',
	},
	addVoteButtonLabel: {
		id: 'polaris-common.plays.vote-input.add-vote-button-label',
		defaultMessage: 'Add',
		description: 'Label for add vote button',
	},
	removeVoteButtonLabel: {
		id: 'polaris-common.plays.vote-input.remove-vote-button-label',
		defaultMessage: 'Remove',
		description: 'Label for remove vote button',
	},
});
