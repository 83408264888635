import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useHasViewUnsavedChanges,
	useIsCurrentViewAutosaveEnabled,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { PolarisNavigationBlocker } from '@atlassian/jira-polaris-common/src/ui/navigation-blocker';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ActionButton } from '../action-button';
import { AutosaveViewConfig } from './autosave-view-config';
import { messages } from './messages';
import { SaveViewButton } from './save-view-button';

export const ConfigExtra = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const { setAutosaveEnabled, resetDraft, saveUnsavedChanges } = useViewActions();
	const isAutosaveEnabled = useIsCurrentViewAutosaveEnabled();
	const hasViewUnsavedChanges = useHasViewUnsavedChanges();

	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();

	const tooltipContent = formatMessage(
		isAutosaveEnabled ? messages.autosaveEnabledTooltip : messages.autosaveDisabledTooltip,
	);

	const handleOpenPopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'button clicked', 'autosave');

			setIsOpen(!isOpen);
			if (!isOpen) {
				fireCompoundAnalyticsEvent.View.viewSettingsOpened();
			}
		},
		[isOpen],
	);

	const handleChangeAutosave = useCallback(
		(isEnabled: boolean) => {
			setAutosaveEnabled(isEnabled);
			fireCompoundAnalyticsEvent.View.autosaveChanged(createAnalyticsEvent({}), isEnabled);
		},
		[createAnalyticsEvent, setAutosaveEnabled],
	);

	const handleReset = useCallback(() => {
		resetDraft();
		fireCompoundAnalyticsEvent.View.unsavedChangesReset(createAnalyticsEvent({}));
	}, [createAnalyticsEvent, resetDraft]);

	if (ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews) {
		return hasViewUnsavedChanges ? (
			<Button
				id="polaris-ideas.ui.view-controls.unsaved-changes.reset"
				appearance="subtle"
				onClick={handleReset}
			>
				{formatMessage(messages.resetButton)}
			</Button>
		) : null;
	}

	return (
		<ConfigExtraContainer data-testid="polaris-ideas.ui.view-controls.config-extra">
			{!fg('polaris_just-for-you') && (
				<Popup
					shouldUseCaptureOnOutsideClick
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom-start"
					content={() => (
						<PopupContentContainer>
							<AutosaveViewConfig isEnabled={isAutosaveEnabled} onChange={handleChangeAutosave} />
						</PopupContentContainer>
					)}
					trigger={(triggerProps) => (
						<SettingsButtonWrapper {...triggerProps}>
							<Tooltip content={!isOpen && tooltipContent} position="right-start">
								<ActionButton
									id="polaris-ideas.ui.view-controls.extra-settings"
									isSelected={isOpen}
									isEmpty={isAutosaveEnabled}
									iconBefore={
										<AutosaveIconWrapper>
											<PolarisIcon
												name={
													isAutosaveEnabled
														? PolarisIconType.AutosaveOn
														: PolarisIconType.AutosaveOff
												}
												label={tooltipContent}
											/>
										</AutosaveIconWrapper>
									}
									onClick={handleOpenPopup}
								/>
							</Tooltip>
						</SettingsButtonWrapper>
					)}
				/>
			)}
			<SaveViewButton />
			{hasViewUnsavedChanges && (
				<Button
					id="polaris-ideas.ui.view-controls.unsaved-changes.reset"
					appearance="subtle"
					onClick={handleReset}
				>
					{formatMessage(messages.resetButton)}
				</Button>
			)}
			<PolarisNavigationBlocker
				onSave={(continueNavigation) => {
					saveUnsavedChanges();
					continueNavigation();
				}}
				title={messages.unsavedChangesTitle}
				prompt={messages.unsavedChangesPrompt}
				isDirty={hasViewUnsavedChanges}
				withSaveButton
				shouldIgnoreQueryParams
			/>
		</ConfigExtraContainer>
	);
};
export default ConfigExtra;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigExtraContainer = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupContentContainer = styled.div({
	padding: token('space.150', '12px'),
	boxSizing: 'border-box',
	width: '270px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SettingsButtonWrapper = styled.span({
	display: 'inline-flex',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AutosaveIconWrapper = styled.span({
	width: '24px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
