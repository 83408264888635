import type { Failures, SetViewAccessPrincipalErrorResponse, SkippedAccount } from './types';

const isObject = (arg: unknown): arg is Record<string, unknown> =>
	typeof arg === 'object' && arg !== null;

const isFailuresObject = (arg: unknown): arg is Failures =>
	isObject(arg) && isStringArray(arg.accountIds) && isStringArray(arg.groupIds);

const isStringArray = (arg: unknown): arg is string[] =>
	Array.isArray(arg) && arg.every((item) => typeof item === 'string');

const isSkippedAccountsArray = (arg: unknown): arg is SkippedAccount[] =>
	Array.isArray(arg) &&
	arg.every(
		(item) =>
			isObject(item) && typeof item.accountId === 'string' && typeof item.errorCode === 'string',
	);

export const isSetViewAccessPrincipalRolesErrorResponse = (
	arg: unknown,
): arg is SetViewAccessPrincipalErrorResponse => {
	if (
		isObject(arg) &&
		typeof arg.status === 'number' &&
		typeof arg.error === 'string' &&
		typeof arg.path === 'string' &&
		isObject(arg.details)
	) {
		if (isObject(arg.details.failures) && !isFailuresObject(arg.details.failures)) {
			return false;
		}

		if (
			Array.isArray(arg.details.skippedAccounts) &&
			!isSkippedAccountsArray(arg.details.skippedAccounts)
		) {
			return false;
		}

		return true;
	}

	return false;
};
