import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { GlobalFieldContextResponse } from '../types';

export const getGlobalFieldContextId = async (fieldKey: string) => {
	const response = await performGetRequest<GlobalFieldContextResponse>(
		`/rest/api/3/field/${fieldKey}/context?isGlobalContext=true`,
		{},
	);

	if (response.values?.length > 0) {
		return Number(response.values[0].id);
	}

	return undefined;
};
