import getMeta from '@atlassian/jira-get-meta';

export const userInEnglishLocale = (): boolean => {
	const usersLocale: string | null = getMeta('ajs-user-locale');

	return (
		!!usersLocale &&
		typeof usersLocale === 'string' &&
		usersLocale.toLowerCase().indexOf('en', 0) !== -1
	);
};
