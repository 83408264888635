/* eslint-disable @typescript-eslint/no-extraneous-class */
import React, { useState } from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import { Field, CheckboxField, Fieldset } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	NotificationRecipient,
	ProjectRole,
	NotificationRecipientKey,
} from '@atlassian/jira-project-settings-apps-notifications-services/src/services/notification-settings-service/types.tsx';
import { DataIdWrapper } from '../styled';
import messages from './messages';
import type {
	Props,
	RoleCheckBoxPropNew,
	CheckBoxProp,
	MultiSelectOption,
	SetProjectRoleRecipientsArgs,
} from './types';

// This implementation fixes a bug from JDW-1925
// Add notification doesn't force you to select a project role if project role is checked as one of the recipients type
export const RecipientSelection = (props: Props) => {
	const {
		allRoles,
		recipients,
		supportedRecipients,
		setRecipients,
		eventTypeId,
		intl: { formatMessage },
		initialProjectRolesChecked,
		forceProjectRolesSelectOpen,
	} = props;

	const [state, setState] = useState({
		isProjectRolesChecked: initialProjectRolesChecked === true,
		hasProjectRoleSelected: false,
	});

	const updateParentRecipients = ({
		// @ts-expect-error - TS7031 - Binding element 'isProjectRolesChecked' implicitly has an 'any' type.
		isProjectRolesChecked,
		// @ts-expect-error - TS7031 - Binding element 'hasProjectRoleSelected' implicitly has an 'any' type.
		hasProjectRoleSelected,
		// @ts-expect-error - TS7031 - Binding element 'updatedRecipients' implicitly has an 'any' type.
		updatedRecipients,
	}) => {
		if (
			(updatedRecipients.length > 0 && !isProjectRolesChecked) ||
			(isProjectRolesChecked && hasProjectRoleSelected)
		)
			setRecipients({ recipients: updatedRecipients, canSubmit: true });
		else setRecipients({ recipients: updatedRecipients, canSubmit: false });
		setState({ isProjectRolesChecked, hasProjectRoleSelected });
	};

	const toggleRecipient = (recipient: NotificationRecipient, isAdd: boolean): void => {
		const existingRecipients = recipients;
		const newRecipients = isAdd
			? [...existingRecipients, recipient]
			: existingRecipients.filter((existingRecipient) => existingRecipient.key !== recipient.key);
		updateParentRecipients({ ...state, updatedRecipients: newRecipients });
	};

	const setProjectRoleRecipients = (args: SetProjectRoleRecipientsArgs) => {
		const { recipients: updatedRecipients, isProjectRolesChecked, hasProjectRoleSelected } = args;
		updateParentRecipients({
			isProjectRolesChecked,
			hasProjectRoleSelected,
			updatedRecipients,
		});
	};

	return (
		<DataIdWrapper data-test-id="project-settings-apps-notifications-common.common.ui.recipient-selection.recipient-selection-wrapper">
			<Fieldset legend={formatMessage(messages.recipientsLabel)}>
				{supportedRecipients.map((supportedRecipient) => {
					switch (supportedRecipient.key) {
						case 'AllWatchers':
						case 'CurrentAssignee':
						case 'CurrentUser':
						case 'ProjectLead':
						case 'Reporter':
							return (
								<SimpleRecipientCheckbox
									key={supportedRecipient.key}
									supportedRecipient={supportedRecipient}
									allRecipients={recipients}
									toggleRecipient={toggleRecipient}
									isDisabled={!eventTypeId}
								/>
							);
						case 'ProjectRole':
							return (
								<ProjectRoleCheckBox
									initialProjectRolesChecked={initialProjectRolesChecked === true}
									forceProjectRolesSelectOpen={!!forceProjectRolesSelectOpen}
									key={`${eventTypeId || ''}-${supportedRecipient.key}`}
									roleTypeRecipient={supportedRecipient}
									allRecipients={recipients}
									allRoles={allRoles}
									setRecipients={setProjectRoleRecipients}
									isDisabled={!eventTypeId}
								/>
							);

						default: {
							fireErrorAnalytics({
								error: new Error('Unknown notification recipient type'),
								meta: {
									id: 'recipientSelection',
									packageName: 'jiraProjectSettingsAppsNotificationsCommon',
								},
							});
							return null;
						}
					}
				})}
			</Fieldset>
		</DataIdWrapper>
	);
};

const SimpleRecipientCheckbox = ({
	supportedRecipient,
	allRecipients,
	toggleRecipient,
	isDisabled,
}: CheckBoxProp) => {
	const isChecked =
		allRecipients.find((recipient) => recipient.key === supportedRecipient.key) || false;
	return (
		<CheckboxField
			name={supportedRecipient.key}
			value={supportedRecipient.key}
			isDisabled={isDisabled}
		>
			{({ fieldProps }) => (
				<Checkbox
					{...fieldProps}
					// @ts-expect-error - TS2322 - Type 'false | NotificationRecipient' is not assignable to type 'boolean | undefined'.
					isChecked={isChecked}
					onChange={(e) => toggleRecipient({ ...supportedRecipient }, e.target.checked)}
					label={supportedRecipient.name}
				/>
			)}
		</CheckboxField>
	);
};

class RoleCheckboxUtils {
	static toRoleSelectOptions(role: ProjectRole): MultiSelectOption {
		return {
			label: role.name,
			value: role.key,
		};
	}

	static getChosenRolesMultiSelectOptions(
		roleTypeRecipientKey: NotificationRecipientKey,
		allRecipients: NotificationRecipient[],
		allRoles: ProjectRole[],
	): MultiSelectOption[] {
		const allRolesMap: Map<string, ProjectRole> = new Map(allRoles.map((role) => [role.key, role]));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return allRecipients.reduce<Array<any>>((selectOptions, recipient) => {
			const { parameter: roleId, key } = recipient;

			if (roleTypeRecipientKey === key && roleId != null) {
				const role = allRolesMap.get(roleId);
				selectOptions.push({
					label: role ? role.name : roleId,
					value: roleId,
				});
			}

			return selectOptions;
		}, []);
	}
}

const ProjectRoleCheckBox = injectIntl(
	({
		roleTypeRecipient,
		allRecipients,
		allRoles,
		isDisabled,
		setRecipients,
		initialProjectRolesChecked,
		forceProjectRolesSelectOpen,
		intl: { formatMessage },
	}: RoleCheckBoxPropNew) => {
		const allRolesMultiSelectOptions = allRoles.map(RoleCheckboxUtils.toRoleSelectOptions);
		const chosenRolesMultiSelectOptions = RoleCheckboxUtils.getChosenRolesMultiSelectOptions(
			roleTypeRecipient.key,
			allRecipients,
			allRoles,
		);
		const [isChecked, setIsChecked] = useState(
			chosenRolesMultiSelectOptions.length > 0 || initialProjectRolesChecked,
		);

		const toggleRoleCheckBox = (isTrue: boolean) => {
			if (!isTrue) {
				setRecipients({
					recipients: allRecipients.filter((rec) => rec.key !== roleTypeRecipient.key),
					isProjectRolesChecked: false,
					hasProjectRoleSelected: false,
				});
			} else
				setRecipients({
					recipients: allRecipients,
					isProjectRolesChecked: true,
					hasProjectRoleSelected: false,
				});
			setIsChecked(isTrue);
		};

		const setNewChosenRoles = (newRoleOptions: MultiSelectOption[] = []) => {
			// removing all existing role based recipients
			const newRecipients = allRecipients.filter((rec) => rec.key !== roleTypeRecipient.key);
			// adding new Role based recipients
			newRoleOptions.forEach((option) => {
				newRecipients.push({
					key: roleTypeRecipient.key,
					name: roleTypeRecipient.name,
					parameter: option.value,
				});
			});
			setRecipients({
				recipients: newRecipients,
				isProjectRolesChecked: true,
				hasProjectRoleSelected: newRoleOptions.length > 0,
			});
		};

		return (
			<>
				<CheckboxField
					name={roleTypeRecipient.key}
					value={roleTypeRecipient.key}
					isDisabled={isDisabled || allRoles.length === 0}
				>
					{({ fieldProps }) => (
						<Checkbox
							{...fieldProps}
							isChecked={isChecked}
							onChange={(e) => toggleRoleCheckBox(e.target.checked)}
							label={roleTypeRecipient.name}
						/>
					)}
				</CheckboxField>
				{isChecked && (
					<Field name="rolesMultiSelect" isDisabled={isDisabled}>
						{({ fieldProps }) => (
							<>
								<Select
									{...fieldProps}
									menuIsOpen={forceProjectRolesSelectOpen || undefined}
									options={allRolesMultiSelectOptions}
									value={chosenRolesMultiSelectOptions}
									// @ts-expect-error - TS2345 - Argument of type 'OptionsType<MultiSelectOption>' is not assignable to parameter of type 'MultiSelectOption[]'.
									onChange={(options) => setNewChosenRoles(options)}
									isMulti
									placeholder={formatMessage(messages.selectRolePlaceholder)}
									menuPortalTarget={document.body}
									aria-label={formatMessage(messages.selectRolePlaceholder)}
									styles={{
										menuPortal: (styles) => ({
											...styles,

											zIndex: layers.modal,
										}),
									}}
								/>
							</>
						)}
					</Field>
				)}
			</>
		);
	},
);
