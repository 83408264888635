import React, { useCallback, useMemo, type ReactNode } from 'react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Lozenge from '@atlaskit/lozenge';
import { R300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { getEntityLimitError, getErrorMessageDescriptor } from '../common/utils/entity-limit-error';
import type { ErrorHandlers, ExperienceCallback } from '../types';
import messages from './messages';
import { getMetadata, isConfigError, isPermissionError } from './utils';

export const createErrorAnalytics = (id: string, error: Error) => ({
	meta: { id },
	error,
	sendToPrivacyUnsafeSplunk: true,
});

const handleCallback = (errorObject: Error, callback?: ExperienceCallback) => {
	callback && callback(getMetadata(errorObject));
};

const useGeneralDataLoadingFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: Error, callback?: ExperienceCallback) => {
			handleCallback(errorObject, callback);
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.serverLoadFailedDescription),
			});
			fireErrorAnalytics(
				createErrorAnalytics('polaris.error.generalDataLoadingFailed', errorObject),
			);
		},
		[errorWithRefresh, formatMessage],
	);
};

const useGeneralDataUpdateFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { error, errorWithRefresh, customFlag } = useNotifications();
	return useCallback(
		(errorObject: Error, callback?: ExperienceCallback) => {
			handleCallback(errorObject, callback);

			fireErrorAnalytics(
				createErrorAnalytics('polaris.error.generalDataUpdateFailed', errorObject),
			);

			const entityLimitError = getEntityLimitError(errorObject);
			if (entityLimitError) {
				const errorMessage = getErrorMessageDescriptor(entityLimitError);
				return errorMessage
					? customFlag({
							title: formatMessage(messages.entityLimitErrorTitle),
							description: formatMessage(errorMessage, {
								item: (chunks: ReactNode[]) => <Lozenge>{chunks}</Lozenge>,
							}),
							icon: <ErrorIcon label="Error" primaryColor={token('color.icon.danger', R300)} />,
						})
					: null;
			}

			if (isPermissionError(errorObject)) {
				return error({
					title: formatMessage(messages.errorTitle),
					description: formatMessage(messages.permissionErrorDescription),
				});
			}

			if (isConfigError(errorObject)) {
				return error({
					title: formatMessage(messages.configErrorTitle),
					description: formatMessage(messages.configErrorDescription),
				});
			}

			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.serverUpdateFailedDescription),
			});
		},
		[customFlag, error, errorWithRefresh, formatMessage],
	);
};

const useGeneralActionFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: Error, callback?: ExperienceCallback) => {
			handleCallback(errorObject, callback);
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.generalActionFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

const usePossibleAccessLossError = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: Error, callback?: ExperienceCallback) => {
			handleCallback(errorObject, callback);
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorPossibleAccessLoss),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.generalActionFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

export const useErrorHandlers = (): ErrorHandlers => {
	const possibleAccessLossError = usePossibleAccessLossError();
	const generalActionFailedError = useGeneralActionFailedHandling();
	const generalDataUpdateFailedError = useGeneralDataUpdateFailedHandling();
	const generalDataLoadingFailedError = useGeneralDataLoadingFailedHandling();

	return useMemo(
		() => ({
			possibleAccessLossError,
			generalActionFailedError,
			generalDataUpdateFailedError,
			generalDataLoadingFailedError,
		}),
		[
			possibleAccessLossError,
			generalActionFailedError,
			generalDataUpdateFailedError,
			generalDataLoadingFailedError,
		],
	);
};

export const useOnCyclicFormulaFieldError = () => {
	const { formatMessage } = useIntl();
	const { error } = useNotifications();
	return useCallback(
		(fieldLabels: string[]) => {
			error({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.cyclicFormulaFieldDescription, {
					fieldLabels: fieldLabels.join(', '),
				}),
			});
			fireErrorAnalytics(
				createErrorAnalytics('polaris.error.cyclicFormula', new Error('Cyclic formula field')),
			);
		},
		[error, formatMessage],
	);
};
