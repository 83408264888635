import noop from 'lodash/noop';
import urlParse from 'url-parse';
import type { RouterActionPush as Push } from '@atlassian/jira-router';
import getTopmostSameOriginWindow from '../utils/topmost-same-origin-window';

export const getWindowOrigin = (win: typeof window): string =>
	win.origin ||
	(win.location &&
		(win.location.origin ||
			`${win.location.protocol}//${win.location.hostname}${
				win.location.port ? `:${win.location.port}` : ''
			}`));

export const getRelativeUrl = (url: string): string => {
	const parsedUrl = urlParse(url);
	const { pathname, query, hash } = parsedUrl;
	return `${pathname}${query}${hash}`;
};

export const isSameOriginUrl = (url: string, win: typeof window) => {
	const parsedUrl = urlParse(url);
	return parsedUrl.origin === getWindowOrigin(win);
};

export const iframeRedirect =
	(
		win: any = window, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	(url: string, shouldOpenInNewTab: boolean, pushAction?: Push): void => {
		const isUrlSameOrigin = isSameOriginUrl(url, win);

		// Component is in an iframe
		if (win.parent !== win) {
			let isComponentInJira;
			// If parent win is not jira then accessing win.parent will throw a cross origin error
			try {
				isComponentInJira = win.parent.jira || win.parent.JIRA;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				isComponentInJira = false;
			}

			if (!isComponentInJira || shouldOpenInNewTab) {
				win.open(url, '_blank');
			} else {
				getTopmostSameOriginWindow(win).location.assign(url);
			}
		} else if (shouldOpenInNewTab) {
			win.open(url, '_blank');
		} else if (pushAction && isUrlSameOrigin) {
			pushAction(getRelativeUrl(url));
		} else {
			win.location.assign(url);
		}
	};

export default __SERVER__ ? noop : iframeRedirect(window);
