import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getRelevantParentLinkFields } from '../../../issue/actions/utils';
import type { Props, State } from '../../types';
import { loadDeliveryTicketsEstimate } from '../load-delivery-tickets-estimate';
import { transformIssueLinkData } from './utils';

export const loadDeliveryTickets =
	(parentIssueKey: IssueKey): Action<State, Props> =>
	async (
		{ setState, getState },
		{ issueKey, issuesRemote, arjConfiguration, hasNoProjectPermissions },
	) => {
		const state = getState();
		if (!issueKey || !parentIssueKey || hasNoProjectPermissions) {
			return;
		}

		setState({
			meta: {
				...state.meta,
				loadingLinkedIssues: true,
			},
		});
		const response = await issuesRemote.fetchChildIssues({
			parentIssueKey,
			parentLinkFields: getRelevantParentLinkFields(arjConfiguration),
			storyPointsCustomFieldId: arjConfiguration?.storyPointsCustomFieldId || undefined,
		});
		const updatedExistingIssues = state.deliveryTickets.map((ticket) => {
			const newIssue = response.issues.find(
				({ issueId, parentKey }) => issueId === ticket.issueId && parentKey === ticket.parentKey,
			);
			if (newIssue) {
				return {
					...ticket,
					...newIssue,
				};
			}

			return ticket;
		});

		const newDeliveryTickets = [
			...updatedExistingIssues,
			...response.issues.filter(
				(data) =>
					// handle case when the issue returned from hierarchy was already added directly as delivery ticket
					!updatedExistingIssues.some(
						(item) => item.issueId === data.issueId && item.parentKey === data.parentKey,
					),
			),
		];
		setState({
			...state,
			deliveryTickets: newDeliveryTickets,
			meta: {
				...state.meta,
				loadingLinkedIssues: false,
			},
		});
	};

export const updateDeliveryTickets =
	(): Action<State, Props> =>
	({ getState, setState, dispatch }, { polarisIssueLinkTypes, arjConfiguration }: Props) => {
		const state = getState();
		const issueLinkData = transformIssueLinkData(
			state.issueLinks,
			polarisIssueLinkTypes.polarisDeliveryIssueLinkType,
			state.deliveryTickets,
		);

		setState({
			...state,
			deliveryTickets: issueLinkData,
			polarisDeliveryIssueLinkType: polarisIssueLinkTypes.polarisDeliveryIssueLinkType,
			storyPointsCustomFieldId: arjConfiguration?.storyPointsCustomFieldId || undefined,
			meta: {
				...state.meta,
				loadingLinkedIssues: false,
			},
		});

		dispatch(loadDeliveryTicketsEstimate());
	};
