import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import Query from '@atlassian/jira-relay/src/__generated__/panelJpdPublishGateQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	productKey: CanonicalId;
	offeringKey: string;
};

export const panelEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "jpd-publish-feature-gate" */ './index'),
	),
	getPreloadProps: ({ cloudId, productKey, offeringKey }: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			query: {
				parameters: Query,
				variables: {
					cloudId,
					hamsProductKey: productKey,
					offeringKey,
				},
			},
		},
	}),
});
