import { useEffect, useRef, useState } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIsBeta } from '@atlassian/jira-polaris-component-environment-tenant';
import { useSiteRemote } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/legacy/site-remote/main.tsx';
import {
	useHasNoProjectPermissions,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useIsSharedView } from '../../controllers/environment';
import { useHasFieldsError } from '../../controllers/field/selectors/field-hooks';
import { useCurrentUser } from '../../controllers/user';
import { useHasViewSetsError } from '../../controllers/views/selectors/view-hooks';
import {
	type InlineScriptsProps,
	type JpdEdition,
	type PendoWidgetSettings,
	Edition,
} from './types';
import { getProductDiscoveryRole, jpdEditionToProductDiscoveryEdition } from './utils';

const PENDO_APP_KEY = '568765c2-daf3-47fb-7b95-fe1569e5a0d9';
let pendingRequest = Promise.resolve();

const addPendoWidget = ({
	atlassianAccountId,
	userFullname,
	emailAddress,
	isJiraAdmin,
	isSiteAdmin,
	appPermissions,
	appEditions,
	jpdEdition,
	hasProjectPermissions,
}: PendoWidgetSettings) => {
	// POL-7279 Fix Pendo breaking CI/CD
	if (window.location.hostname === 'localhost') {
		return undefined;
	}
	const scriptId = 'jpd-pendo-widget';
	if (document.getElementById(scriptId)) {
		return undefined;
	}
	const currentInstanceName = window.location.hostname.replace(
		/(\.jira(-dev)?)?\.com$|(\.atlassian)?\.net$/,
		'',
	);

	const pendoConfig = {
		visitor: {
			id: atlassianAccountId, // atlassian account id or empty string for anonymous users
			full_name: userFullname, // Recommended if using Pendo Feedback
			email: emailAddress,
			isJiraAdmin,
			isSiteAdmin,
			hasCoreAccess: appPermissions?.hasCoreAccess,
			hasSoftwareAccess: appPermissions?.hasSoftwareAccess,
			hasServiceDeskAccess: appPermissions?.hasServiceDeskAccess,
			hasProductDiscoveryAccess: appPermissions?.hasProductDiscoveryAccess,
			coreEdition: appEditions?.core,
			softwareEdition: appEditions?.software,
			serviceDeskEdition: appEditions?.serviceDesk,
			productDiscoveryEdition: jpdEditionToProductDiscoveryEdition(jpdEdition, appEditions),
			productDiscoveryRole: getProductDiscoveryRole(appPermissions, hasProjectPermissions),
		},
		account: {
			id: currentInstanceName, // Highly recommended
			hasCoreAccess: appPermissions?.hasCoreAccess,
			hasSoftwareAccess: appPermissions?.hasSoftwareAccess,
			hasServiceDeskAccess: appPermissions?.hasServiceDeskAccess,
			hasProductDiscoveryAccess: appPermissions?.hasProductDiscoveryAccess,
			coreEdition: appEditions?.core,
			softwareEdition: appEditions?.software,
			serviceDeskEdition: appEditions?.serviceDesk,
			productDiscoveryEdition: jpdEditionToProductDiscoveryEdition(jpdEdition, appEditions),
			productDiscoveryRole: getProductDiscoveryRole(appPermissions, hasProjectPermissions),
		},
	};
	pendingRequest = pendingRequest.then(() => initializePendo(PENDO_APP_KEY, pendoConfig));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initializePendo = (apiKey: string, pendoConfig: any) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	new Promise<any>((resolve) => {
		const id = `pendo-agent-${apiKey}`;
		if (window.pendo && window.pendo.teardown && apiKey !== window.pendo.apiKey) {
			window.pendo.teardown();
			delete window.pendo;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		let script = document.getElementById(id) as HTMLScriptElement | null;
		if (script) {
			// @ts-expect-error as id is not in the global window
			window.pendo = window[id];
			window.pendo.initialize(pendoConfig);

			resolve(script);
		} else {
			// eslint-disable-next-line jira/js/no-document-script
			script = document.createElement('script');
			script.id = id;
			script.defer = true;
			script.onload = () => {
				// @ts-expect-error as id is not in the global window
				window[id] = window.pendo;
				window.pendo.initialize(pendoConfig);
				resolve(script);
			};
			script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
			document.head.appendChild(script);
		}
	});
const addStatusPageWidget = () => {
	const scriptId = 'jpd-statuspage-widget';

	if (document.getElementById(scriptId)) {
		return undefined;
	}

	// eslint-disable-next-line jira/js/no-document-script
	const s = document.createElement('script');
	s.id = scriptId;
	s.type = 'text/javascript';
	s.src = 'https://qmzzdxyvmbmk.statuspage.io/embed/script.js';
	s.defer = true;
	document.head?.appendChild(s);
	return s;
};

export const InlineScripts = ({ embedded }: InlineScriptsProps) => {
	const tenantContext = useTenantContext();
	const isSharedView = useIsSharedView();
	const hasFieldsError = useHasFieldsError();
	const hasConfigError = useHasViewSetsError();
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();
	const { data: userData, error: userDataError } = useCurrentUser();
	const areScriptsAdded = useRef<boolean>(false);

	const [jpdEdition, setJpdEdition] = useState<JpdEdition | undefined>(undefined);
	const isBeta = useIsBeta();

	const { getJpdEditionsMeta } = useSiteRemote();

	useEffect(() => {
		if (ff('polaris.environment-tenant-component')) {
			if (isBeta === undefined) {
				setJpdEdition(Edition.UNKNOWN);
				return;
			}

			setJpdEdition(isBeta ? Edition.BETA_EDITION : Edition.LICENSED);
		} else {
			getJpdEditionsMeta().then(({ beta }) => {
				// in case of Shared view we don't know what the edition is
				if (beta === undefined) {
					setJpdEdition(Edition.UNKNOWN);
					return;
				}

				setJpdEdition(beta ? Edition.BETA_EDITION : Edition.LICENSED);
			});
		}
	}, [getJpdEditionsMeta, isBeta]);

	useEffect(() => {
		if (areScriptsAdded.current) {
			return undefined;
		}

		if (embedded) {
			return undefined;
		}

		const userLoaded = userData || !tenantContext.atlassianAccountId || userDataError;
		if (!userLoaded || jpdEdition === undefined) {
			return undefined;
		}

		// Permissions will load only when projectID is available.
		// If there are no /fields response, /config will not be started and projectID will be absent.
		// Check permissionsLoaded only if there are no /fields and /config errors otherwise skip condition and initialise Pendo.
		if (isSharedView && !(hasFieldsError || hasConfigError || permissionsLoaded)) {
			return undefined;
		}

		let statusPageWidgetScript: HTMLScriptElement | undefined;
		let pendoWidgetScript: HTMLScriptElement | undefined;

		if (tenantContext.atlassianAccountId === null) {
			pendoWidgetScript = addPendoWidget({
				atlassianAccountId: '',
				userFullname: '',
				emailAddress: '',
				isJiraAdmin: false,
				isSiteAdmin: false,
				appPermissions: undefined,
				appEditions: undefined,
				jpdEdition,
				hasProjectPermissions: !hasNoProjectPermissions,
			});
			statusPageWidgetScript = addStatusPageWidget();
		} else {
			pendoWidgetScript = addPendoWidget({
				atlassianAccountId: tenantContext.atlassianAccountId,
				userFullname: tenantContext.userFullname || '',
				emailAddress: userData?.emailAddress || '',
				isJiraAdmin: tenantContext.isAdmin,
				isSiteAdmin: tenantContext.isSiteAdmin,
				appPermissions: tenantContext.appPermissions,
				appEditions: tenantContext.appEditions,
				jpdEdition,
				hasProjectPermissions: !hasNoProjectPermissions,
			});
			statusPageWidgetScript = addStatusPageWidget();
		}
		areScriptsAdded.current = true;

		return () => {
			document.getElementById('pendo-base')?.remove();
			statusPageWidgetScript?.parentNode?.removeChild(statusPageWidgetScript);
			// @ts-expect-error - Property 'parentNode' does not exist on type 'never'.
			pendoWidgetScript?.parentNode?.removeChild(pendoWidgetScript);
		};
	}, [
		hasConfigError,
		hasFieldsError,
		isSharedView,
		permissionsLoaded,
		hasNoProjectPermissions,
		userData,
		userDataError,
		embedded,
		tenantContext,
		jpdEdition,
	]);

	return null;
};
