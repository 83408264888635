import { useCallback, useMemo } from 'react';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { useRecentIssuesStore } from './context';

export const useRecentIssueActions = () => {
	const [, recentIssuesStoreActions] = useRecentIssuesStore();

	const getRecentIssue = useCallback(
		(issueKey: IssueKey) => recentIssuesStoreActions.getRecentIssue(issueKey),
		[recentIssuesStoreActions],
	);

	const setRecentIssue = useCallback(
		(issueKey: IssueKey, issueState: IssueState) =>
			recentIssuesStoreActions.setRecentIssue(issueKey, issueState),
		[recentIssuesStoreActions],
	);

	const getRecentIssuesCount = useCallback(
		() => recentIssuesStoreActions.getRecentIssuesCount(),
		[recentIssuesStoreActions],
	);

	const recentIssuesActions = useMemo(
		() => ({
			setRecentIssue,
			getRecentIssue,
			getRecentIssuesCount,
		}),
		[getRecentIssue, setRecentIssue, getRecentIssuesCount],
	);

	return [null, recentIssuesActions] as const;
};
