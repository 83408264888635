import React from 'react';
import { Box, xcss, Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import { isAttachmentNumberExceedLimit } from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import messages from './messages';

type Props = {
	totalCount: number;
};

export const ExceedWarning = ({ totalCount }: Props) => {
	const { formatMessage } = useIntl();

	if (isAttachmentNumberExceedLimit(totalCount)) {
		return (
			<Box xcss={wrapperStyles}>
				<SectionMessage appearance="warning">
					<Text as="p">{formatMessage(messages.exceedWarningLabel)}</Text>
				</SectionMessage>
			</Box>
		);
	}

	return null;
};

const wrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.100',
	marginLeft: 'space.100',
});
