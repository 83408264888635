import cloneDeep from 'lodash/cloneDeep';
import type { IssuesByCell } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping';
import { EMPTY_VALUE_ID } from '../../../../common/utils/board';
import type { Group, Column } from '../use-board-virtualization/types.tsx';

/**
 * Returns an array containing all columns with their data originating from the IssuesByCell data structure.
 * It also makes sure to add columns and groups containing empty values.
 */
export const getAllColumnsWithEmptyValues = (
	partialColumns: IssuesByCell,
	isEmptyColumnVisible: boolean,
	isEmptyGroupVisible: boolean,
) => {
	const allColumns = Object.entries(partialColumns.groups);

	if (isEmptyColumnVisible) {
		const emptyColumnGroups = partialColumns.xEmpty;

		if (isEmptyGroupVisible) {
			emptyColumnGroups[EMPTY_VALUE_ID] = partialColumns.empty || [];
		}

		allColumns.unshift([EMPTY_VALUE_ID, emptyColumnGroups]);
	}

	if (isEmptyGroupVisible) {
		const columnsWithCardsInEmptyGroup = Object.keys(partialColumns.yEmpty);
		for (const columnId of columnsWithCardsInEmptyGroup) {
			const existingColumnIndex = allColumns.findIndex((column) => column[0] === columnId);
			if (existingColumnIndex !== -1) {
				if (typeof allColumns[existingColumnIndex][1] === 'object') {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					allColumns[existingColumnIndex][1]![EMPTY_VALUE_ID] = partialColumns.yEmpty[columnId];
				} else {
					allColumns[existingColumnIndex][1] = {
						[EMPTY_VALUE_ID]: partialColumns.yEmpty[columnId],
					};
				}
			} else {
				allColumns.push([
					columnId,
					{
						[EMPTY_VALUE_ID]: partialColumns.yEmpty[columnId],
					},
				]);
			}
		}
	}

	return allColumns;
};

export const getRankedGroups = (
	groupsMap: Map<string, Column[]>,
	visibleGroupKeys: string[],
	visibleColumnKeys: string[],
	collapsedSwimlanes: Record<string, boolean>,
) => {
	// sort the groups by their rank
	const rankedGroups: Group[] = visibleGroupKeys.map((uid) => ({
		uid,
		columns: [],
		height: 0,
		contentHeight: 0,
		offset: 0,
		maxColumnContentHeight: 0,
		isCollapsed: collapsedSwimlanes[uid === EMPTY_VALUE_ID ? 'undefined' : uid] ?? true,
	}));

	Array.from(groupsMap).forEach(([uid, columns]) => {
		const index = visibleGroupKeys.indexOf(uid);
		let isEmpty = true;
		for (let i = 0; i < columns.length; i += 1) {
			if (columns[i].cards.length > 0) {
				isEmpty = false;
				break;
			}
		}

		rankedGroups[index] = {
			...rankedGroups[index],
			columns,
			isCollapsed: collapsedSwimlanes[uid === EMPTY_VALUE_ID ? 'undefined' : uid] ?? isEmpty,
		};
	});

	// for each group, sort its columns by their rank
	for (const group of rankedGroups) {
		const rankedColumns: Column[] = visibleColumnKeys.map((uid) => ({
			uid: `${group.uid}.${uid}`,
			columnUid: uid,
			cards: [],
			height: 0,
			contentHeight: 0,
		}));

		for (const column of group.columns) {
			rankedColumns[visibleColumnKeys.indexOf(column.columnUid)] = column;
		}

		group.columns = rankedColumns;
	}

	return rankedGroups;
};

export const filterOutGroupColumnsWithoutCards = (groups: Group[], visibleColumnKeys: string[]) => {
	const updatedGroups = cloneDeep(groups);

	for (let i = 0; i < visibleColumnKeys.length; i += 1) {
		let isEmpty = true;

		for (const group of groups) {
			const column = group.columns[i];

			if (column.cards.length > 0) {
				isEmpty = false;
				break;
			}
		}

		if (isEmpty) {
			const columnIndex = updatedGroups[0].columns.findIndex(
				(column) => column.columnUid === visibleColumnKeys[i],
			);

			for (const group of updatedGroups) {
				group.columns.splice(columnIndex, 1);
			}
		}
	}

	return updatedGroups;
};

const isGroupEmpty = (group: Group) => group.columns.every((column) => column.cards.length === 0);

export const filterOutEmptyGroups = (groups: Group[]) =>
	groups.filter((group) => !isGroupEmpty(group));
