import React, { useState, useMemo, useCallback, type ReactElement } from 'react';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DeleteOptionModal } from '@atlassian/jira-polaris-lib-option-utils/src/ui/delete-option/delete-modal/index.tsx';
import { useFieldActions } from '../../../field/main.tsx';
import { useIssueActions } from '../../main.tsx';
import { useFieldMapping } from '../../selectors/fields-hooks';
import { useGroupOptions } from '../../selectors/grouping-hooks';

type RequestDeleteCallback = (groupIdentity?: string) => void;

type ChildRenderProps = {
	onOptionDeleteRequested: RequestDeleteCallback;
};

type DeleteOptionHandlerProps = {
	fieldKey?: FieldKey;
	children: (arg1: ChildRenderProps) => ReactElement;
};

type RequestedDeleteOptionHandlerProps = {
	children: (arg1: ChildRenderProps) => ReactElement;
	childRenderProps: ChildRenderProps;
	fieldKey: FieldKey;
	groupIdentity: string;
	onClearHandlerConfiguration: () => void;
};

const useOptionLabel = <TValue,>(
	fieldKey: FieldKey,
	groupIdentity: string,
	fieldValue: TValue,
): string | undefined => {
	const mapping = useFieldMapping(fieldKey);
	if (groupIdentity === undefined) {
		return undefined;
	}
	return mapping?.getLabel(groupIdentity, fieldValue);
};

const useOptionValue = (fieldKey: FieldKey, groupIdentity: string): unknown | undefined => {
	const options = useGroupOptions(fieldKey);

	const optionsByGI = useMemo(
		() => keyBy(options.options, (option) => option.groupIdentity),
		[options.options],
	);

	if (groupIdentity === undefined || !has(optionsByGI, groupIdentity)) {
		return undefined;
	}

	return optionsByGI[groupIdentity].value;
};

export const RequestedDeleteOptionHandler = ({
	fieldKey,
	groupIdentity,
	children,
	childRenderProps,
	onClearHandlerConfiguration,
}: RequestedDeleteOptionHandlerProps) => {
	const { deleteOption } = useFieldActions();
	const invalidValue = useOptionValue(fieldKey, groupIdentity);
	const optionLabel = useOptionLabel(fieldKey, groupIdentity, invalidValue);
	const { invalidateFieldValue } = useIssueActions();

	const onCancelDelete = useCallback(() => {
		onClearHandlerConfiguration();
	}, [onClearHandlerConfiguration]);

	const onConfirmDelete = useCallback(() => {
		if (groupIdentity !== undefined) {
			// removed option from all issues
			return invalidateFieldValue({
				fieldKey,
				invalidValue,
				performSideEffects: true,
			})
				.then(() => {
					// delete option from field store
					deleteOption(fieldKey, groupIdentity);
				})
				.then(() => {
					// close dialog
					onClearHandlerConfiguration();
				});
		}
		return Promise.resolve(undefined).then(() => {
			onClearHandlerConfiguration();
		});
	}, [
		onClearHandlerConfiguration,
		deleteOption,
		fieldKey,
		groupIdentity,
		invalidValue,
		invalidateFieldValue,
	]);

	return (
		<>
			{invalidValue !== undefined && (
				<DeleteOptionModal
					isOpen={groupIdentity !== undefined}
					optionLabel={optionLabel}
					onCancel={onCancelDelete}
					onConfirm={onConfirmDelete}
				/>
			)}
			{children(childRenderProps)}
		</>
	);
};

export const DeleteOptionHandler = ({
	children,
	fieldKey: fieldKeyToDelete,
}: DeleteOptionHandlerProps) => {
	const [{ fieldKey, groupIdentity }, setDeleteOptions] = useState<{
		fieldKey?: FieldKey;
		groupIdentity?: string;
	}>({
		fieldKey: undefined,
		groupIdentity: undefined,
	});

	const onOptionDeleteRequested = useCallback(
		(groupIdentityToDelete?: string) => {
			setDeleteOptions({
				fieldKey: fieldKeyToDelete,
				groupIdentity: groupIdentityToDelete,
			});
		},
		[fieldKeyToDelete],
	);

	const childRenderProps = useMemo(
		() => ({
			onOptionDeleteRequested,
		}),
		[onOptionDeleteRequested],
	);

	const onClearHandlerConfiguration = useCallback(() => {
		setDeleteOptions({
			fieldKey: undefined,
			groupIdentity: undefined,
		});
	}, []);

	if (fieldKey === undefined || groupIdentity === undefined) {
		return <>{children(childRenderProps)}</>;
	}

	return (
		<RequestedDeleteOptionHandler
			fieldKey={fieldKey}
			groupIdentity={groupIdentity}
			childRenderProps={childRenderProps}
			onClearHandlerConfiguration={onClearHandlerConfiguration}
		>
			{children}
		</RequestedDeleteOptionHandler>
	);
};
