import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-view-empty-state.views.matrix-view.header',
		defaultMessage: 'Set up matrix axes',
		description: 'Header of the new matrix view hint',
	},
	description: {
		id: 'polaris-lib-view-empty-state.views.matrix-view.description',
		defaultMessage:
			'Select fields for both the y-axis and the x-axis\nto organize your ideas on a matrix.',
		description: 'New matrix view hint to start',
	},
});
