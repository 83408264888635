import React from 'react';
import { parseAri } from '@atlassian/jira-platform-ari';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type {
	ChainableContextProvider,
	PolarisApolloClient,
} from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { createCreateRanking } from '@atlassian/jira-polaris-remote-ranking/src/services/ranking/create/index.tsx';
import { createGetRanking } from '@atlassian/jira-polaris-remote-ranking/src/services/ranking/get/index.tsx';
import { createMoveAfter } from '@atlassian/jira-polaris-remote-ranking/src/services/ranking/move-after/index.tsx';
import { createMoveBefore } from '@atlassian/jira-polaris-remote-ranking/src/services/ranking/move-before/index.tsx';
import {
	createMoveBatchFirst,
	createMoveFirst,
} from '@atlassian/jira-polaris-remote-ranking/src/services/ranking/move-first/index.tsx';
import { createMoveLast } from '@atlassian/jira-polaris-remote-ranking/src/services/ranking/move-last/index.tsx';
import { getRankingList } from '@atlassian/jira-polaris-remote-ranking/src/services/sharing/get/index.tsx';
import type { RankingRemote } from '../types';

const createRankingRemote = (apolloClient: PolarisApolloClient): RankingRemote => ({
	create: createCreateRanking(apolloClient),
	get: createGetRanking(apolloClient),
	moveBefore: createMoveBefore(apolloClient),
	moveAfter: createMoveAfter(apolloClient),
	moveFirst: createMoveFirst(apolloClient),
	moveBatchFirst: createMoveBatchFirst(apolloClient),
	moveLast: createMoveLast(apolloClient),
});

const { RemoteContextProvider, useRemoteFromContext } =
	createRemoteContext<RankingRemote>('ranking');

export const useRankingRemote = useRemoteFromContext;

export const RankingRemoteProvider: ChainableContextProvider = ({ apolloClient, children }) => {
	const remote = createRankingRemote(apolloClient);

	return <RemoteContextProvider remote={remote}>{children}</RemoteContextProvider>;
};

const createSharingRankingRemote = (): RankingRemote => ({
	create: () => Promise.resolve(),
	get: ({ listId }: { listId: string }) => {
		const parsed = parseAri(listId);
		if (parsed === undefined) {
			throw new Error(`sharing.bad-ranking-list-resource: ${listId}`);
		}
		return getRankingList(parsed.resourceId).then((list) => list.map((item) => item.itemId));
	},
	moveBefore: () => Promise.resolve(),
	moveAfter: () => Promise.resolve(),
	moveFirst: () => Promise.resolve(),
	moveBatchFirst: () => Promise.resolve(),
	moveLast: () => Promise.resolve(),
});

export const SharingRankingRemoteProvider: ChainableContextProvider = ({ children }) => {
	const remote = createSharingRankingRemote();

	return <RemoteContextProvider remote={remote}>{children}</RemoteContextProvider>;
};
