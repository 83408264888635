import React, { useState, useEffect } from 'react';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { ExternalReferenceField as InnerField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal';
import { useFieldUpdate, useIsEditable } from '../../utils';

type ExternalReferenceFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
	isHovered: boolean;
};

export const ExternalReferenceField = ({
	localIssueId,
	fieldKey,
	isHovered,
}: ExternalReferenceFieldProps) => {
	const [isActive, setIsActive] = useState(isHovered);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const portalElement = useListPortalElement();
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	useEffect(() => {
		if (isHovered) {
			setIsActive(true);
		}
	}, [isHovered]);

	return (
		<InnerField
			isActive={isActive}
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			isEditable={isEditable && !isIdeaArchived}
			onUpdate={onUpdate}
			menuPortalTarget={portalElement}
		/>
	);
};
