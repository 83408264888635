import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { findView, isFakeId, updateProjectState } from '../utils';

export const deleteViewSet =
	(localId: LocalViewId): Action<State, Props> =>
	async (
		{ setState, getState },
		{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
	) => {
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view } = findView(getState().projects[projectId], localId);

		if (!view) {
			return;
		}

		if (isFakeId(view.id)) {
			setState(
				updateProjectState(getState(), projectId, {
					views: getState().projects[projectId].views.filter(
						(innerView) => !innerView.views || innerView.localId !== localId,
					),
				}),
			);
			return;
		}

		setState(
			updateProjectState(getState(), projectId, {
				views: [
					...getState().projects[projectId].views.map((innerView) =>
						innerView.localId === localId
							? { ...innerView, isRenaming: false, isLocked: true }
							: innerView,
					),
				],
			}),
		);

		try {
			await navigationRemote.deleteView({
				viewId: view.id,
			});
			setState(
				updateProjectState(getState(), projectId, {
					views: getState().projects[projectId].views.filter(
						(innerView) => innerView.viewType !== 'SECTION' || innerView.localId !== localId,
					),
				}),
			);
			onSuccess?.('deleteSection');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState(
				updateProjectState(getState(), projectId, {
					views: [
						...getState().projects[projectId].views.map((innerView) =>
							innerView.localId === localId ? { ...innerView, isLocked: false } : innerView,
						),
					],
				}),
			);

			onFailure?.(error, 'deleteSection');
		}
	};
