import {
	SET_WORKLOG_SCROLL_STATUS,
	type SetWorklogScrollStatus,
	ADD_WORKLOG_SUCCESS,
	EDIT_WORKLOG_SUCCESS,
	type AddWorklogSuccessAction,
	type EditWorklogSuccessAction,
} from '../../../common/actions/worklog-actions';

export const initialHighlightedWorklogId = null;

type PermalinkStatusState = {
	hasScrolledPermalink: boolean;
};

export const initialPermalinkStatusState: PermalinkStatusState = {
	hasScrolledPermalink: false,
};

type Action = SetWorklogScrollStatus | AddWorklogSuccessAction | EditWorklogSuccessAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: PermalinkStatusState = initialPermalinkStatusState,
	action: Action,
): PermalinkStatusState => {
	switch (action.type) {
		case SET_WORKLOG_SCROLL_STATUS:
			return { ...state, hasScrolledPermalink: action.payload.status };
		case ADD_WORKLOG_SUCCESS:
		case EDIT_WORKLOG_SUCCESS:
			return { ...state, hasScrolledPermalink: false };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
