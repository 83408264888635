import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AccountId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const watchUrl = ({ issueKey }: { issueKey: IssueKey }) =>
	`/rest/api/2/issue/${issueKey}/watchers`;

export const addWatcher = (issueKey: IssueKey, accountId: AccountId) =>
	performPostRequest(watchUrl({ issueKey }), {
		body: JSON.stringify(accountId),
	});
