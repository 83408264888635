import React from 'react';
import type { Vote as VoteValue } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import { useDisableResolvedIssueVoting } from './services/disable-resolved-issue-voting-service/index.tsx';
import IssueVotersBase from './ui/main.tsx';
import VoteToggleBase from './ui/vote-toggle/index.tsx';

interface Props {
	onFieldUpdated?: (value: VoteValue) => void;
}

export const VoteToggle = ({ onFieldUpdated }: Props) => {
	const disableVotingProps = useDisableResolvedIssueVoting();
	return <VoteToggleBase disableVotingProps={disableVotingProps} onFieldUpdated={onFieldUpdated} />;
};

export const IssueVoters = ({ onFieldUpdated }: Props) => {
	const disableVotingProps = useDisableResolvedIssueVoting();
	return (
		<IssueVotersBase disableVotingProps={disableVotingProps} onFieldUpdated={onFieldUpdated} />
	);
};
