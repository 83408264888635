import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	affectedViews: {
		id: 'polaris-common.config.fields.views-configured-by-field.affected-views',
		defaultMessage: 'Check the list with affected views',
		description: 'Title to describe views configured by global field that user wants to delete',
	},
	toggleList: {
		id: 'polaris-common.config.fields.views-configured-by-field.toggle-list',
		defaultMessage: 'Toggle list',
		description: 'Button to toggle list with affected views',
	},
});
