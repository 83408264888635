import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { type CreateUIAnalyticsEvent, withAnalyticsEvents } from '@atlaskit/analytics-next';
import UserPicker, { type UserPickerProps, type Value } from '@atlaskit/user-picker';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import getMeta from '@atlassian/jira-get-meta';
import { userInEnglishLocale } from '@atlassian/jira-growth-experiment-utils/src/common/utils/user-in-english-locale.tsx';
import { triggerOpenDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { INVITE_PEOPLE_ID, USER_PICKER_EMPTY } from './constants';
import {
	getInviteFromAssignCohort,
	INVITE_FROM_ASSIGN,
	isInviteFromAssignEnabled,
	isInviteFromAssignModalFeEnabled,
	getInviteFromAssignModalCohort,
} from './feature-flags';
import type { Props, State, UserOption, UserOptionValue } from './types';

const emptyUserOption: UserOption = {
	id: USER_PICKER_EMPTY,
	name: '',
};

export const FETCH_DEBOUNCE = 300;

const stringContains = (str?: string | null, substr?: string | null) => {
	if (str == null) {
		return false;
	}

	if (substr == null || substr === '') {
		return true;
	}

	return str.toLowerCase().includes(substr.toLowerCase());
};

const fireInviteFromAssignExposureEvent = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	fieldId: string,
	suggestionsCount: number,
) => {
	if (createAnalyticsEvent === undefined || createAnalyticsEvent === null) {
		return;
	}

	const action = 'exposed';
	const actionSubject = 'feature';

	const event = createAnalyticsEvent({
		action,
		actionSubject,
	});

	const attrs: Attributes = {
		fieldId,
		flagKey: INVITE_FROM_ASSIGN,
		value: getInviteFromAssignCohort(),
		userRole: getMeta('ajs-is-admin') === 'true' ? 'admin' : 'basic',
		suggestionsCount,
		source: 'assigneeField',
	};

	fireOperationalAnalytics(event, `${actionSubject} ${action}`, attrs);
};

const fireInviteItemRendererEvent = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	fieldId: string,
	suggestionsCount: number,
) => {
	if (createAnalyticsEvent === undefined || createAnalyticsEvent === null) {
		return;
	}

	const action = 'rendered';
	const actionSubject = 'inviteItem';

	const event = createAnalyticsEvent({
		action,
		actionSubject,
	});

	const attrs: Attributes = {
		fieldId,
		userRole: getMeta('ajs-is-admin') === 'true' ? 'admin' : 'basic',
		suggestionsCount,
		source: 'assigneeField',
	};
	fireUIAnalytics(event, `${actionSubject} ${action}`, attrs);
};

const triggerOpenDrawerOrModal = (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	isIssueViewModal: boolean | undefined,
	assignModalCohort: string,
) => {
	if (!isIssueViewModal) {
		triggerOpenDrawer(
			createAnalyticsEvent ?? null,
			{
				inviteFlow: 'assignee',
			},
			{
				inviteFromAssignModalCohort: assignModalCohort,
			},
		);
	} else {
		triggerOpenDrawer(createAnalyticsEvent ?? null, {
			inviteFlow: 'assignee',
		});
	}
};

// eslint-disable-next-line jira/react/no-class-components
export class UserPickerView extends Component<Props, State> {
	static displayName = 'UserPickerView';

	static defaultProps = {
		isDisabled: false,
		value: null,
		initialOptions: [],
		emptyOption: null,
		invitePeopleOption: null,
		enablePeopleInvite: false,
		width: undefined,
		menuMinWidth: undefined,
		portalElement: undefined,
		fetchSuggestions: () => Promise.resolve([]),
		onChange: noop,
		onCancel: noop,
		onSelection: noop,
		onDataRequest: noop,
		onDataLoaded: noop,
		emailLabel: undefined,
		suggestEmailsForDomain: undefined,
		allowEmail: false,
		onCreateOption: noop,
	};

	state = {
		suggestions: [],
		isDropdownVisible: true,
		wasFocused: false,
		isLoading: false,
		wasQueryCleared: false,
		query: null,
	};

	componentWillUnmount() {
		this.wasUnmounted = true;
		this.debouncedRequestSuggestion.cancel();
	}

	wasUnmounted = false;

	// stored not in state because of race condition of setState and onBlur event handler
	wasCleared = false;

	invitePeopleExposeEventFired = false;

	onChange = (value: UserOptionValue) => {
		// Don't fire the onChange if the user selects the same value again.
		// We don't expect value to be null or an array since this component only supports a single
		// select currently, but need to check to satisfy flow.
		if (!value || Array.isArray(value) || this.isCurrentValue(value)) {
			return;
		}

		const valueId = value.id;
		const initialOption = this.props.initialOptions.find((option) => option.id === valueId);
		if (initialOption) {
			// If the value is an initialOption, emit the special value.
			this.props.onChange(initialOption.id ? initialOption : null);
		} else if (valueId === USER_PICKER_EMPTY) {
			// If the value is the empty option, emit null.
			this.props.onChange(null);
		} else if (
			isInviteFromAssignEnabled() &&
			this.props.enablePeopleInvite === true &&
			valueId === INVITE_PEOPLE_ID
		) {
			if (isInviteFromAssignModalFeEnabled()) {
				const inviteFromAssignModalCohort = getInviteFromAssignModalCohort();
				triggerOpenDrawerOrModal(
					this.props.createAnalyticsEvent ?? null,
					this.props.isIssueViewModal,
					inviteFromAssignModalCohort,
				);
			} else {
				triggerOpenDrawer(this.props.createAnalyticsEvent ?? null, {
					inviteFlow: 'assignee',
				});
			}
		} else {
			// Otherwise emit the value that the user picked.
			this.props.onChange(value);
		}
	};

	onSuggestionSelect = (value: Value, sessionId?: string) => {
		this.wasCleared = false;
		this.setState({
			suggestions: [],
			wasFocused: false,
			isLoading: false,
			wasQueryCleared: false,
		});

		this.onChange(Array.isArray(value) ? null : value);
		this.props.onSelection(sessionId);
	};

	onBlur = () => {
		this.setState({
			suggestions: [],
			isDropdownVisible: false,
			wasFocused: false,
			isLoading: false,
			wasQueryCleared: false,
		});
		if (this.wasCleared) {
			this.onSuggestionSelect(emptyUserOption);
			return;
		}
		this.props.onCancel();
	};

	onFocus = (sessionId?: string) => {
		this.wasCleared = false;
		this.setState({
			isDropdownVisible: true,
			isLoading: true,
			wasFocused: true,
		});

		// Submit an empty query on initial load.
		this.requestSuggestions(sessionId);
	};

	onQueryChange = (query?: string, sessionId?: string) => {
		this.setState({
			wasFocused: false,
			isLoading: true,
			query: query || '',
			wasQueryCleared: !query,
		});

		this.debouncedRequestSuggestion(sessionId);
	};

	onClear = () => {
		this.wasCleared = true;
		this.onChange(emptyUserOption);
	};

	getEmptyValue = (): UserOption | null =>
		this.props.emptyOption
			? {
					...this.props.emptyOption,
					id: USER_PICKER_EMPTY,
				}
			: null;

	getInvitePeopleValue = (): UserOption | null =>
		this.props.invitePeopleOption
			? {
					...this.props.invitePeopleOption,
					id: INVITE_PEOPLE_ID,
				}
			: null;

	getInitialAndEmptyOptionSuggestions = (): UserOption[] => {
		const { initialOptions, emptyOption } = this.props;
		const { wasFocused, wasQueryCleared } = this.state;

		const shouldShowInitialOptions = wasFocused && initialOptions.length > 0;
		const shouldShowEmptyOption = emptyOption && !shouldShowInitialOptions && wasQueryCleared;

		const emptyValue = this.getEmptyValue();

		const initialOptionsToDisplay = shouldShowInitialOptions ? initialOptions : [];
		const emptyOptionToDisplay = shouldShowEmptyOption === true && emptyValue ? [emptyValue] : [];
		return [...initialOptionsToDisplay, ...emptyOptionToDisplay];
	};

	debouncedRequestSuggestion = debounce(
		(sessionId?: string) => this.requestSuggestions(sessionId),
		FETCH_DEBOUNCE,
	);

	requestSuggestions = (sessionId?: string) => {
		const { onDataRequest, fetchSuggestions } = this.props;
		const { query } = this.state;
		onDataRequest(query === null);
		const queryStr = query || '';

		return fetchSuggestions(queryStr, sessionId)
			.then((results) => this.handleSuggestions(results, queryStr))
			.catch(() => this.handleSuggestions([], queryStr));
	};

	isMatchingQuery = (suggestion: UserOption): boolean => {
		// when feature flag is false the predict should allow all
		const { query, isLoading } = this.state;
		const { displayName, name } = suggestion;
		return !isLoading || stringContains(displayName, query) || stringContains(name, query);
	};

	// Prepend any required initialOptions or emptyOption to the suggestions.
	getSuggestions = (): UserOption[] => {
		const { createAnalyticsEvent, enablePeopleInvite, fieldId } = this.props;
		const { query, isLoading } = this.state;

		const queryStr = query || '';

		const initialAndEmptyOptions = this.getInitialAndEmptyOptionSuggestions();

		// Filter out any suggestions that are in the initial or empty options.
		const suggestionIdsToFilterOut: string[] = initialAndEmptyOptions.map((option) => option.id);

		const suggestions = this.state.suggestions.filter(
			(suggestion: UserOption) =>
				!suggestionIdsToFilterOut.includes(suggestion.id) &&
				// apply filters on the front while backend is serving the request, this is to make the filtering faster.
				// so filter already displayed results while backend is serving the request.
				this.isMatchingQuery(suggestion),
		);

		const allSuggestions = [...initialAndEmptyOptions, ...suggestions];

		const invitePeopleValue = this.getInvitePeopleValue();
		const invitePeopleOptions = [];

		const isAssigneeFieldType = fieldId === ASSIGNEE_TYPE;

		const showEmailOption = ff('issue.details.invite-and-assign-refactoring')
			? Boolean(
					queryStr &&
						!isLoading &&
						isAssigneeFieldType &&
						this.props.allowEmail &&
						this.props.enablePeopleInvite,
				)
			: Boolean(
					queryStr &&
						!isLoading &&
						isAssigneeFieldType &&
						this.props.allowEmail &&
						this.props.enablePeopleInvite &&
						userInEnglishLocale(),
				);

		// refresh

		if (
			!isLoading &&
			enablePeopleInvite === true &&
			invitePeopleValue &&
			queryStr &&
			allSuggestions.length <= 2
		) {
			if (!this.invitePeopleExposeEventFired) {
				fireInviteFromAssignExposureEvent(createAnalyticsEvent, fieldId, allSuggestions.length);
				this.invitePeopleExposeEventFired = true;
			}
			if (isInviteFromAssignEnabled()) {
				fireInviteItemRendererEvent(createAnalyticsEvent, fieldId, allSuggestions.length);
				if (!showEmailOption) {
					invitePeopleOptions.push(invitePeopleValue);
				}
			}
		}

		return [...allSuggestions, ...invitePeopleOptions].map((userOption: UserOption): UserOption => {
			const { displayName, ...option } = userOption;
			// BENTO-4739: The AK user picker uses publicName instead of displayName in its API, so
			// we need to transform the suggestions to the correct shape.
			return displayName
				? {
						...option,
						// @ts-expect-error - TS2322 - Type '{ id: string; accountId?: string | undefined; avatarUrl?: string | undefined; active?: boolean | undefined; name: string; byline?: string | undefined; } | { publicName: string; id: string; ... 4 more ...; byline?: string | undefined; }' is not assignable to type 'UserOption'.
						publicName: displayName,
					}
				: option;
		});
	};

	handleSuggestions = (newSuggestions: UserOption[], resultsForQuery?: string) => {
		const { isDropdownVisible, query } = this.state;
		const isForCurrentQuery = resultsForQuery === (query || '');

		if (this.wasUnmounted || !isDropdownVisible || !isForCurrentQuery) {
			return;
		}

		// Prevent the selected value from appearing in the list of suggestions at initial load.
		const suggestions = this.state.wasFocused
			? newSuggestions.filter((suggestion) => !this.isCurrentValue(suggestion))
			: newSuggestions;

		this.setState({
			isLoading: false,
			suggestions,
		});

		this.props.onDataLoaded(query === null, suggestions.length);
	};

	isCurrentValue(value: UserOption) {
		if (!value) {
			return this.props.value === null;
		}
		return this.props.value ? value.id === this.props.value.id : value.id === USER_PICKER_EMPTY;
	}

	render() {
		const suggestions = this.getSuggestions();
		const pickerValue = this.props.value || this.getEmptyValue();

		const emailInviteProps: Partial<UserPickerProps> =
			userInEnglishLocale() || ff('issue.details.invite-and-assign-refactoring')
				? {
						emailLabel: this.props.emailLabel,
						// only show email suggestion when there is a query
						suggestEmailsForDomain:
							this.state.query && !this.state.isLoading
								? this.props.suggestEmailsForDomain
								: undefined,
						allowEmail: Boolean(this.props.enablePeopleInvite && this.props.allowEmail),
						onChange: (value, action) => {
							if (action === 'create-option') {
								this.props.onCreateOption(value, action);
							}
						},
					}
				: {};

		return (
			<UserPicker
				value={pickerValue}
				options={suggestions}
				open={this.state.isDropdownVisible}
				fieldId={this.props.fieldId}
				menuMinWidth={this.props.menuMinWidth}
				menuPortalTarget={this.props.portalElement}
				width={this.props.width}
				appearance="compact"
				isClearable={!!this.props.value}
				isDisabled={this.props.isDisabled}
				placeholder={this.props.placeholder}
				isLoading={this.state.isLoading && this.state.isDropdownVisible}
				onBlur={this.onBlur}
				onClear={this.onClear}
				onFocus={this.onFocus}
				onInputChange={this.onQueryChange}
				onSelection={this.onSuggestionSelect}
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: layers.modal }),

					menu: (base) => ({ ...base, zIndex: layers.modal }),
				}}
				{...emailInviteProps}
			/>
		);
	}
}

export default withAnalyticsEvents()(UserPickerView);
