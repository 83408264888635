import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'polaris-ideas.main-content.view-main-content-error-boundary.header',
		defaultMessage: "We couldn't load this view",
		description: 'A text to indicate the user a technical error happened',
	},
	description: {
		id: 'polaris-ideas.main-content.view-main-content-error-boundary.description',
		defaultMessage: 'Try refreshing the page. If this keeps happening, <a>contact support.</a>',
		description: 'A text to help the user when a technical error happens',
	},
});
