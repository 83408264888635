import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Button, { ButtonGroup } from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Stack, xcss, type Space } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { BANNER, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge/src/utils/analytics-context-wrapper';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics-component';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { checkIfActivatedMoreThanFourDays } from '../../../common/utils';
import { useOnboardingFlowsActions } from '../../../controllers';
import {
	useExploreSectionMessageDismissed,
	useProjectFieldsPageViewed,
} from '../../../controllers/selectors/project-fields-hooks';
import { messages } from './messages';

type Props = {
	paddingBlock?: Space;
	paddingInline?: Space;
	innerPaddingInline?: Space;
	projectKey: ProjectKey;
	productUsageStartTimestamp?: string;
};

export const ExploreProjectFieldsSectionMessage = ({
	paddingBlock,
	paddingInline,
	innerPaddingInline,
	projectKey,
	productUsageStartTimestamp,
}: Props) => {
	const projectFieldsPageViewed = useProjectFieldsPageViewed();
	const exploreSectionMessageDismissed = useExploreSectionMessageDismissed();
	const {
		loadExploreSectionMessageDismissed,
		setExploreSectionMessageDismissed,
		loadProjectFieldsPageViewed,
	} = useOnboardingFlowsActions();
	const { formatMessage } = useIntl();

	const isActiveMoreThanFourDays = productUsageStartTimestamp
		? checkIfActivatedMoreThanFourDays(productUsageStartTimestamp)
		: false;

	useEffect(() => {
		loadExploreSectionMessageDismissed();
		loadProjectFieldsPageViewed();
	}, [loadExploreSectionMessageDismissed, loadProjectFieldsPageViewed]);

	if (
		!isActiveMoreThanFourDays ||
		projectFieldsPageViewed !== false ||
		exploreSectionMessageDismissed !== false
	) {
		return null;
	}

	return (
		<ContextualAnalyticsData sourceType={BANNER} sourceName="exploreGlobalFields">
			<FireScreenAnalytics />
			<Box paddingBlock={paddingBlock} paddingInline={paddingInline}>
				<Box padding="space.200" paddingInline={innerPaddingInline} xcss={sectionMessageStyles}>
					<Stack space="space.100">
						<Heading size="xsmall">{formatMessage(messages.title)}</Heading>
						<Box>{formatMessage(messages.body)}</Box>
						<Box xcss={secondaryTextStyles}>{formatMessage(messages.secondaryText)}</Box>
						<Box>
							<ButtonGroup>
								<DiscoveryButton
									iconAfter={<ShortcutIcon label="" />}
									appearance="primary"
									href={`/jira/polaris/projects/${projectKey}/ideas/settings/fields`}
									target="_blank"
									onClick={(_, analyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'exploreGlobalFields');
									}}
								>
									{formatMessage(messages.exploreNowButton)}
								</DiscoveryButton>
								<Button
									onClick={(_, analyticsEvent) => {
										setExploreSectionMessageDismissed();
										fireUIAnalytics(analyticsEvent, 'dismiss');
									}}
								>
									{formatMessage(messages.dismissButton)}
								</Button>
							</ButtonGroup>
						</Box>
					</Stack>
				</Box>
			</Box>
		</ContextualAnalyticsData>
	);
};

const sectionMessageStyles = xcss({
	backgroundColor: 'color.background.discovery',
	borderRadius: 'border.radius.100',
});

const secondaryTextStyles = xcss({
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DiscoveryButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	backgroundColor: `${token('color.background.discovery.bold', '#6e5dc6')} !important`,
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.discovery.bold.hovered', '#5d4cb2')} !important`,
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.discovery.bold.pressed', '#352d63')} !important`,
	},
});
