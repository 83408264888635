import { endOfQuarter, startOfQuarter, formatISO } from 'date-fns';
import { format } from './date';

const DIFF_YEAR_FORMAT = 'MMM d, yyyy';
const MONTH_ONLY_FORMAT_SHORT = 'MMM';
const MONTH_ONLY_FORMAT_DIFF_YEAR = 'MMM, yyyy';
const MONTH_WITH_DIFF_YEAR_FORMAT = 'MMM yyyy';

const formatWithLocale = (date: Date, dateFormat: string, locale?: string) =>
	format(date, dateFormat, locale ? { locale: { code: locale } } : undefined);

export const formatNiceDate = (date: Date, locale?: string) =>
	formatWithLocale(date, DIFF_YEAR_FORMAT, locale);

export const formatNiceMonthDate = (date: Date, locale?: string) =>
	formatWithLocale(date, MONTH_ONLY_FORMAT_DIFF_YEAR, locale);

export const formatMonthAndYearDate = (date: Date, locale?: string) =>
	formatWithLocale(date, MONTH_WITH_DIFF_YEAR_FORMAT, locale);

export const formatQuarterAndYearDate = (date: Date, locale?: string) =>
	`${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
		endOfQuarter(date),
		MONTH_WITH_DIFF_YEAR_FORMAT,
		locale,
	)}`;

export const formatNiceQuarterDate = (date: Date, locale?: string) =>
	`${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
		endOfQuarter(date),
		MONTH_ONLY_FORMAT_DIFF_YEAR,
		locale,
	)}`;

export const formatIsoLocalDate = (date: Date) => formatISO(date, { representation: 'date' });
