import React from 'react';
import { MEDIAN_ROLLUP } from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import { useGetNumberFieldsRollup } from '../../../../controllers/issue/selectors/fields-rollup-hooks';
import { FieldGroupedMedian } from '../../common/median';
import type { Props } from '../../types';

export const NumberFieldGroupedMedian = ({ fieldKey, groupId, groupFieldKey }: Props) => {
	const rollup = useGetNumberFieldsRollup(fieldKey, groupFieldKey, groupId);
	const val = rollup[MEDIAN_ROLLUP];

	if (val === undefined) {
		return null;
	}

	return <FieldGroupedMedian val={val} />;
};
