import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	submitSuccess: {
		id: 'polaris-common.create-idea-dialog.notification.submit-success',
		defaultMessage: 'Thank you!',
		description: 'Notification header that an idea has been successfully created.',
	},
	submitSuccessDescription: {
		id: 'polaris-common.create-idea-dialog.notification.submit-success-description',
		defaultMessage: "You've created your idea successfully",
		description: 'Notification body that an idea has been successfully created',
	},
	submitSuccessInvisibleDescription: {
		id: 'polaris-common.create-idea-dialog.notification.submit-success-invisible-description',
		defaultMessage: 'Your idea was created, but filters prevent it from showing in this view',
		description:
			'Notification body that an idea has been successfully created but now shown on view because of filters',
	},
	goToIdeaButton: {
		id: 'polaris-common.create-idea-dialog.notification.go-to-idea-button',
		defaultMessage: 'Open the idea',
		description: 'Message for go to the idea action',
	},
});
