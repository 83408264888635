import { sendPendoTrackEvent as sendTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';

export const sendMatrixSingletonBubbleClicked = () =>
	sendTrackEvent('matrixSingletonBubble clicked');
export const sendMatrixGroupBubbleClicked = () => sendTrackEvent('matrixGroupBubble clicked');
export const sendMatrixSingularDragOperationStarted = () =>
	sendTrackEvent('matrixSingularDrag started');
export const sendMatrixGroupDragOperationStarted = () => sendTrackEvent('matrixGroupDrag started');
export const sendMatrixSingularDragOperationEnded = () =>
	sendTrackEvent('matrixSingularDrag ended');
export const sendMatrixGroupDragOperationEnded = () => sendTrackEvent('matrixGroupDrag ended');
