export const CONSTANT_TEMPLATE = 'const';

/**
 * Formula for a constant value.
 */
export type ConstantFormula = {
	template: typeof CONSTANT_TEMPLATE;
	parameters: {
		value: number;
	};
};
