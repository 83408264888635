import type { LocalViewId, View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { saveView } from '../save';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

export const renameCurrentView =
	(title: string, onAfterSave?: (arg1: View | undefined) => void): Action<State, Props> =>
	({ getState, setState, dispatch }, { currentViewSlug, onSyncViewName }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({ ...view, title, isEditingTitle: false, modified: true }),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id, onAfterSave));
			changedView.viewLegacyId &&
				onSyncViewName(String(changedView.viewLegacyId), changedView.kind, title);
		}
	};

export const setEditCurrentViewTitle =
	(isEditing: boolean): Action<State, Props> =>
	({ getState, setState }, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({ ...view, isEditingTitle: isEditing }),
		);

		if (changedView) {
			setState({ viewSets });
		}
	};

export const renameView =
	(
		id: LocalViewId,
		title: string,
		onAfterSave?: (arg1: View | undefined) => void,
	): Action<State, Props> =>
	({ getState, setState, dispatch }, { createAnalyticsEvent }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			(view) => view.id === id,
			(view: View) => ({ ...view, title, isEditingTitle: false, modified: true }),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id, onAfterSave));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'name' }],
			});
		}
	};

export const addViewEmoji =
	(id: LocalViewId, emoji?: string): Action<State> =>
	({ getState, setState, dispatch }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			(view) => view.id === id,
			(view: View) => ({ ...view, emoji, modified: true }),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
		}
	};

export const addCurrentViewEmoji =
	(emoji?: string): Action<State, Props> =>
	({ getState, setState, dispatch }, { currentViewSlug, onSyncViewEmoji }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({ ...view, emoji, modified: true }),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
			changedView.viewLegacyId &&
				onSyncViewEmoji(String(changedView.viewLegacyId), changedView.kind, emoji);
		}
	};
