import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { actions } from './actions';
import { initialState } from './constants';

export const Store = createStore({
	initialState,
	actions,
	name: 'jpd-environment-tenant-store',
});

export const useStoreActions = createActionsHook(Store);
