import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { saveViewWithAutoSave } from '../save';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

export const setGroupBy =
	(groupBy: FieldKey): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => {
				const changedProperties = {
					groupBy,
					verticalGroupBy: undefined,
					groupValues: [], // reset group values as the new grouping field might have a shared id range
					verticalGroupValues: [], // reset vertical group values as the new grouping field might have a shared id range
				};

				return {
					...view,
					...(view.isAutosaveEnabled ? changedProperties : {}),
					modified: view.isAutosaveEnabled,
					draft: {
						...view.draft,
						...changedProperties,
					},
				};
			},
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'groupBy',
						oldVal: currentView?.groupBy,
						newVal: changedView.groupBy,
					},
				],
			});
		}
	};

export const setVerticalGroupBy =
	(
		verticalGroupBy?: FieldKey,
		onSuccess?: () => void,
		onError?: (e: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => {
				const changedProperties = {
					verticalGroupBy,
					verticalGroupValues: [], // reset vertical group values as the new grouping field might have a shared id range
					modified: true,
				};

				return {
					...view,
					...(view.isAutosaveEnabled ? changedProperties : {}),
					modified: view.isAutosaveEnabled,
					draft: {
						...view.draft,
						...changedProperties,
					},
				};
			},
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined): void => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'verticalGroupBy',
						oldVal: currentView?.verticalGroupBy,
						newVal: changedView.verticalGroupBy,
					},
				],
			});
		}
	};

export const setHideEmptyGroups =
	(hideEmptyGroups: boolean): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				hideEmptyGroups: view.isAutosaveEnabled ? hideEmptyGroups : view.hideEmptyGroups,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					hideEmptyGroups,
				},
			}),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'hideEmptyGroups',
						oldVal: currentView?.hideEmptyGroups,
						newVal: changedView.hideEmptyGroups,
					},
				],
			});
		}
	};

export const setGroupValues =
	(groupValues: GroupValue[]): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				groupValues: view.isAutosaveEnabled ? groupValues : view.groupValues,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					groupValues,
				},
			}),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'groupValues' }],
			});
		}
	};

export const setHideEmptyColumns =
	(hideEmptyColumns: boolean): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				hideEmptyColumns: view.isAutosaveEnabled ? hideEmptyColumns : view.hideEmptyColumns,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					hideEmptyColumns,
				},
			}),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [
					{
						name: 'hideEmptyColumns',
						oldVal: currentView?.hideEmptyColumns,
						newVal: changedView.hideEmptyColumns,
					},
				],
			});
		}
	};

export const setVerticalGroupValues =
	(verticalGroupValues: GroupValue[]): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				verticalGroupValues: view.isAutosaveEnabled
					? verticalGroupValues
					: view.verticalGroupValues,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					verticalGroupValues,
				},
			}),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));
		}
	};
