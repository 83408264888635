import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getAtlasProjectsByAri } from '../../../../services/atlas/projects';
import type { Props, State } from '../../types';

export const loadProjects =
	(withQueue = false, retry = 0): Action<State, Props> =>
	(
		{ setState, getState, dispatch },
		{ projectAris, onUpdate, apolloClient, initialized, isSingleIssueLoaded },
	) => {
		if (!initialized && !isSingleIssueLoaded) {
			return;
		}

		if ((projectAris === undefined || projectAris.length === 0) && !withQueue) {
			setState({
				projects: {
					...getState().projects,
					loaded: true,
				},
			});
			return;
		}

		if (retry > 3) {
			return;
		}

		if (getState().projects.isLoading === true) {
			!withQueue &&
				setState({
					projects: {
						...getState().projects,
						queue: Array.from(new Set(getState().projects.queue.concat(projectAris))),
					},
				});
			return;
		}

		if (
			!withQueue &&
			projectAris.every((projectAri) => getState().projects.items[projectAri] !== undefined)
		) {
			return;
		}

		const projectArisToLoad = (withQueue ? getState().projects.queue : projectAris).filter(
			(projectAri) => getState().projects.items[projectAri] === undefined,
		);

		if (projectArisToLoad.length === 0) {
			return;
		}

		setState({
			projects: {
				...getState().projects,
				queue: [],
				items: getState().projects.items,
				isLoading: true,
				error: null,
			},
		});

		getAtlasProjectsByAri(apolloClient, projectArisToLoad)
			.then((response) => {
				const newProjects = response.reduce(
					(result, project) =>
						Object.assign(result, {
							[project.id]: {
								...project,
							},
						}),
					projectArisToLoad.reduce(
						(result, ari) =>
							Object.assign(result, {
								[ari]: null,
							}),
						{},
					),
				);
				setState({
					projects: {
						loaded: true,
						queue: getState().projects.queue,
						items: {
							...getState().projects.items,
							...newProjects,
						},
						isLoading: false,
						error: null,
					},
				});
				onUpdate(getState().projects.items);
				const { queue } = getState().projects;
				if (queue.length) {
					dispatch(loadProjects(true, retry + 1));
				}
			})
			.catch((error) => {
				let isAuthError = false;

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				error?.graphQLErrors?.forEach((graphQLError: any) => {
					if (graphQLError?.extensions?.statusCode === 401) {
						isAuthError = true;
					}
				});

				if (!isAuthError) {
					log.safeErrorWithoutCustomerData(
						'polaris.load-atlas-projects-by-ari',
						'Failed to load Atlas projects by ari',
						error,
					);
				}

				setState({
					projects: {
						...getState().projects,
						items: {
							...getState().projects.items,
							...projectArisToLoad.reduce(
								(result, ari) =>
									Object.assign(result, {
										[ari]: null,
									}),
								{},
							),
						},
						isLoading: false,
						loaded: true,
						error,
					},
				});
			});
	};
