import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useRetryInterval, useAllowedRetries } from '../../common/utils';
import { ProgressProvider, useProgress } from '../context';
import type { BulkIssueUpdateProgressProps } from '../types';

export const BulkIssueUpdateProgressPolling = ({
	children,
	onFinished,
	getUpdateIssueFieldsBulkProgress,
	taskId,
}: BulkIssueUpdateProgressProps) => {
	const { formatMessage } = useIntl();
	const { retryStatus, resetRetryCount } = useAllowedRetries();
	const { progress, setError, updateProgress } = useProgress();
	const clearRetryInterval = useRef<() => void>();

	const retry = useCallback(() => {
		getUpdateIssueFieldsBulkProgress({ taskId })
			.then((response) => {
				if (!response) {
					return;
				}

				const newProgress = response.progress;
				// If the task is not in 'RUNNING' state, it's finished - stop polling
				if (response.status !== 'RUNNING') {
					clearRetryInterval.current?.();
					// Notify the parent component that the task has finished
					onFinished?.();
				}

				// If the progress has changed, reset the retry count
				if (progress !== newProgress) {
					resetRetryCount();
				}

				updateProgress(newProgress);
			})
			.catch(() => {
				setError();
				clearRetryInterval.current?.();
			});
	}, [
		getUpdateIssueFieldsBulkProgress,
		onFinished,
		progress,
		resetRetryCount,
		setError,
		taskId,
		updateProgress,
	]);

	clearRetryInterval.current = useRetryInterval(taskId, retry);

	useEffect(() => {
		if (retryStatus !== 'underLimit') {
			setError();
			clearRetryInterval.current?.();
		}

		return () => {
			clearRetryInterval.current?.();
		};
	}, [clearRetryInterval, formatMessage, retryStatus, setError]);

	return <>{children}</>;
};

export const BulkIssueUpdateProgress = (props: BulkIssueUpdateProgressProps) => (
	<ProgressProvider>
		<BulkIssueUpdateProgressPolling {...props} />
	</ProgressProvider>
);
