export const getFields = (
	arjParentCustomFieldId?: string,
	epicLinkCustomFieldId?: string,
	storyPointsCustomFieldId?: string,
): string[] => [
	'status',
	'summary',
	'issuetype',
	'parent',
	...(storyPointsCustomFieldId !== undefined ? [storyPointsCustomFieldId] : []),
	...(arjParentCustomFieldId !== undefined ? [arjParentCustomFieldId] : []),
	...(epicLinkCustomFieldId !== undefined ? [epicLinkCustomFieldId] : []),
];
