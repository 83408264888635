import React, { useMemo, useRef } from 'react';
import { styled } from '@compiled/react';
import { Box, Flex } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { WeightType } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import { ChangeOptionWeightTypeAction } from './change-option-weight-type-action';
import { FormattingAction } from './formatting-action';
import messages from './messages';
import { SearchAndCreateInput } from './search-and-create-input';

type Props = {
	label?: string | React.JSX.Element;
	entityLimitMessage?: string | null;
	existingOptionNames: string[];
	hideActionButtons: boolean;
	hideSearch: boolean;
	isAutoFormattingOn: boolean;
	optionWeightType?: WeightType;
	isOptionCreationDisabled?: boolean;
	onSearch: (query: string) => void;
	onOptionWeightTypeChange: (weightType: WeightType | undefined) => void;
	onOptionCreation: (optionName: string) => Promise<void>;
	onChangeAutoFormatting: (value: boolean) => void;
	onClearFormattingForAllOptions: () => void;
};

export const FieldOptionsConfigMenuBar = ({
	label,
	entityLimitMessage,
	existingOptionNames,
	hideActionButtons,
	hideSearch,
	isAutoFormattingOn,
	optionWeightType,
	isOptionCreationDisabled = false,
	onSearch,
	onOptionWeightTypeChange,
	onOptionCreation,
	onChangeAutoFormatting,
	onClearFormattingForAllOptions,
}: Props) => {
	const { formatMessage } = useIntl();
	const inputRef = useRef<HTMLElement>(null);

	const inputPlaceholder = useMemo(() => {
		if (isOptionCreationDisabled) {
			return formatMessage(messages.searchOptionPlaceholder);
		}

		if (existingOptionNames.length > 0) {
			return formatMessage(messages.searchOrCreateOptionPlaceholder);
		}

		return formatMessage(messages.createOptionPlaceholder);
	}, [isOptionCreationDisabled, existingOptionNames.length, formatMessage]);

	const displayInput = !isOptionCreationDisabled || !hideSearch;

	return (
		<Box>
			<Box paddingBlockEnd="space.100">
				<Flex justifyContent="space-between">
					<LabelWrapper>{label}</LabelWrapper>
					{!hideActionButtons && existingOptionNames.length > 0 && (
						<Flex gap="space.050">
							<FormattingAction
								label={formatMessage(messages.formattingLabel)}
								isAutoFormattingOn={isAutoFormattingOn}
								onChangeAutoFormatting={onChangeAutoFormatting}
								onClearFormattingForAllOptions={onClearFormattingForAllOptions}
							/>
							<ChangeOptionWeightTypeAction
								label={formatMessage(messages.weightLabel)}
								optionWeightType={optionWeightType}
								onOptionWeightTypeChange={onOptionWeightTypeChange}
							/>
						</Flex>
					)}
				</Flex>
			</Box>
			{displayInput && (
				<SearchAndCreateInput
					ref={inputRef}
					placeholder={inputPlaceholder}
					entityLimitMessage={entityLimitMessage}
					existingOptionNames={existingOptionNames}
					onOptionCreation={onOptionCreation}
					onSearch={onSearch}
					isOptionCreationDisabled={isOptionCreationDisabled}
				/>
			)}
		</Box>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'*': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		padding: '0 !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	'&&': () =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		fg('jpd-visual-refresh_typography_horizon')
			? {
					font: token('font.body.small'),
					fontWeight: token('font.weight.medium'),
				}
			: {
					// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
					fontSize: '12px',
					lineHeight: '16px',
					fontWeight: 500,
				},
});
