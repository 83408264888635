import { useCallback, useMemo } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fetchIssueSuggestions } from '@atlassian/jira-polaris-lib-issue-select/src/services/jira/issue-suggest-v2/index.tsx';
import { useArchivedFieldsConfig } from '../../../controllers/field/selectors/field-hooks';
import { useUnarchivedIssuesForIssueSelect } from '../../../controllers/issue/selectors/issue-ids-hooks';
import { useSelectedIssueKey } from '../../../controllers/issue/selectors/properties/hooks';
import messages from './messages';
import type { GroupedOption, IssueOption } from './types';

export const useExcludedProjectTypes = () =>
	useMemo(() => [CORE_PROJECT, SOFTWARE_PROJECT, SERVICE_DESK_PROJECT], []);

export const useDefaultOptions = (): GroupedOption => {
	const { formatMessage } = useIntl();
	const unarchivedIdeas = useUnarchivedIssuesForIssueSelect();

	return useMemo(() => {
		const defaultOptions = unarchivedIdeas.map((idea) => ({
			label: idea.summary,
			value: `${idea.key} ${idea.summary}`,
			item: {
				id: idea.id,
				key: idea.key,
				keyHtml: idea.key,
				summaryText: idea.summary,
				img: idea.issuetype?.iconUrl || '',
			},
		}));

		return {
			label: formatMessage(messages.issuePickerDefaultGroupLabel),
			options: defaultOptions,
		};
	}, [formatMessage, unarchivedIdeas]);
};

export const useFetchIssueSuggestions = () => {
	const issueKey = useSelectedIssueKey();
	const [{ archivedField }] = useArchivedFieldsConfig();

	return useCallback(
		(
			projectId: string | undefined,
			excludedIssueIds: number[],
			search: string,
			excludedProjectTypes: ProjectType[],
			hideArchived: boolean,
			exactIssueKeySearch?: boolean,
		) =>
			fetchIssueSuggestions({
				issueKey: issueKey || '',
				projectId,
				excludedIssueIds,
				query: search,
				excludedProjectTypes,
				archivedFieldLabel: hideArchived ? archivedField?.label : undefined,
				exactIssueKeySearch,
			}),
		[archivedField, issueKey],
	);
};

type Props = {
	projectId: string | undefined;
	search: string;
	excludedProjectTypes: ProjectType[];
	hideArchived: boolean;
	exactIssueKeySearch?: boolean;
	excludedIssueIds: number[];
};

export const useFetchIssues = () => {
	const issueSuggestions = useFetchIssueSuggestions();

	return useCallback(
		({
			projectId,
			search,
			excludedProjectTypes,
			hideArchived,
			exactIssueKeySearch,
			excludedIssueIds,
		}: Props): Promise<IssueOption[]> =>
			issueSuggestions(
				projectId,
				excludedIssueIds,
				search,
				excludedProjectTypes,
				hideArchived,
				exactIssueKeySearch,
			).then((issues) =>
				issues.map((issue) => ({
					label: issue.summaryText,
					value: `${issue.key} ${issue.summaryText}`,
					item: issue,
				})),
			),
		[issueSuggestions],
	);
};

export const getSearchParams = (inputValue: string) => {
	const issueKeyMatch = inputValue.match(
		/(browse\/|selectedIssue=|\/queues\/issue\/|\/queues\/.+\/.+\/)*(?<issueKey>([A-Z][A-Z0-9]+)-([0-9]+))/,
	);
	let searchString = inputValue;
	let isExactIssueSearchKey = false;

	if (issueKeyMatch != null && issueKeyMatch.groups?.issueKey != null) {
		searchString = issueKeyMatch.groups.issueKey;
		isExactIssueSearchKey = true;
	}

	return {
		searchString,
		isExactIssueSearchKey,
	};
};
