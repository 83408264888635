import sum from 'lodash/sum';

const getSlackValueCount = (percentages: (number | undefined)[]) =>
	percentages.filter((value) => value === undefined).length;

const getDefinedSum = (percentages: (number | undefined)[]) =>
	sum(percentages.filter((it) => it !== undefined));

export const getSplitPercentageValue = (percentages: (number | undefined)[]) => {
	const definedSum = getDefinedSum(percentages);
	if (definedSum >= 100) {
		return 0;
	}
	return (100 - definedSum) / Math.max(1, getSlackValueCount(percentages));
};

export const isInvalidPercentageDistribution = (percentages: (number | undefined)[]) => {
	const definedSum = getDefinedSum(percentages);
	return (
		definedSum > 100 ||
		(definedSum < 100 && percentages.filter((value) => value === undefined).length === 0)
	);
};
