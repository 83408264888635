import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';

const getDeleteViewsServiceViewUrl = (viewId: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewId}`;

export const deleteViewsServiceView = async (viewUuid: string | undefined): Promise<void> => {
	if (viewUuid === undefined) {
		throw new Error('project-config.delete-view-error: no viewUUID to cleanup view');
	}
	return performDeleteRequest(getDeleteViewsServiceViewUrl(viewUuid));
};
