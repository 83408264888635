import type { Dispatch } from 'redux';
import { withAnalyticsContext } from '@atlaskit/analytics-next';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react';
import type { Issue } from '@atlassian/jira-assign-issue-parent-modal';
import type { IssueParent } from '@atlassian/jira-issue-parent-services';
import type { Action } from '@atlassian/jira-issue-view-actions';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils';
import { epicModalOpenInteraction } from '@atlassian/jira-issue-view-common-utils/src/utils/performance-analytics';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import {
	fetchIssueParentsWithInfoRequest,
	setParentRequest,
	setParentOptimistic,
	unsetParentRequest,
	showAssignIssueParentModal,
} from '@atlassian/jira-issue-view-store/src/actions/assign-issue-parent-modal-actions';
import {
	projectIdSelector,
	parentLevelIssuesSelector,
	isSimplifiedProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { assignIssueParentModalIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/assign-issue-parent-modal-selector';
import { issueTypesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-types-selector';
import type { IssueId } from '@atlassian/jira-shared-types';
import IssueParentSwitcher from './view';

export default flowWithSafeComponent(
	withAnalyticsContext({ componentName: 'dropdownMenu' }),
	withFireUiAnalytics({
		onToggle: 'toggled',
		onClick: 'clicked',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ projectId: number | null; issueTypes: IssueType[]; issues: Issue[]; issueParents: IssueParent[]; isSimplifiedProject: boolean; } & { fetchIssueParents: () => void; setIssueParent: (issues: Issue[], parentId?: string | undefined) => void; setIssueParentOptimistic: (issues: Issue[...' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<PropsWithoutRef<LibraryManagedAttributes<Component, Props & WithContextProps>> & RefAttributes<...>>, { ...; }>, ConnectedComponent<...>>'.
	connect(
		(state: State) => ({
			projectId: projectIdSelector(state),
			issueTypes: issueTypesSelector(state),
			issues: assignIssueParentModalIssuesSelector(state),
			issueParents: parentLevelIssuesSelector(state),
			isSimplifiedProject: isSimplifiedProjectSelector(state),
		}),
		(dispatch: Dispatch<Action>) => ({
			fetchIssueParents: () => {
				dispatch(fetchIssueParentsWithInfoRequest());
			},
			setIssueParent: (issues: Issue[], parentId?: IssueId) => {
				dispatch(parentId ? setParentRequest(issues, parentId) : unsetParentRequest(issues));
			},
			setIssueParentOptimistic: (issues: Issue[], parent: IssueParent | null) => {
				dispatch(setParentOptimistic(issues, parent, Promise.resolve()));
			},
			onViewAllParentsClick: () => {
				epicModalOpenInteraction.start();
				dispatch(showAssignIssueParentModal());
			},
		}),
	),
)(IssueParentSwitcher);
