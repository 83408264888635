import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadingSuggestions: {
		id: 'polaris-lib-issue-select.menu.loading-suggestions',
		defaultMessage: 'Searching issues in {cloudName}',
		description:
			'Loading message below issue link select component when loading of linkable issues is in progress',
	},
});
