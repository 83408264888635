import React, { type ReactNode, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import noop from 'lodash/noop';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import { toAri } from '@atlassian/jira-platform-ari';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import { ViewRealtimeHandler } from '@atlassian/jira-polaris-component-view-realtime/src/ui/index.tsx';
import type { PolarisViewChanges } from '@atlassian/jira-polaris-component-view-realtime/src/ui/types.tsx';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { transformView } from '@atlassian/jira-polaris-remote-view/src/controllers/views/polaris-api/transform/index.tsx';
import type { GetViewResponse } from '@atlassian/jira-polaris-remote-view/src/services/polaris-api/fetch-view/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { createViewAri } from '../../common/utils/ari';
import { useCloudId } from '../../common/utils/tenant-context';
import { getUserName } from '../../services/jira/get-user';
import { EVENT_DEBOUNCE_WAIT_MS } from '../realtime/constants';
import { useCurrentUserAccountId } from '../user';
import { actions } from './actions';
import { setCurrentViewFromRoute } from './actions/current';
import { loadViews } from './actions/load/index.tsx';
import { initialState } from './constants';
import messages from './messages';
import type { State, Props } from './types';

export type Actions = typeof actions;

export const ViewStore = createPolarisStore<State, Actions>({
	name: 'PolarisViewStore',
	initialState,
	actions,
});

const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(ViewStore, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(loadViews(true));
		},
	onUpdate:
		() =>
		({ dispatch }, { currentViewSlug }) => {
			dispatch(loadViews());
			if (getWillShowNav4() && currentViewSlug) {
				dispatch(setCurrentViewFromRoute());
			}
		},
});

export const createViewHook = createHook;
export const createHigherLevelViewHook = createHigherLevelHook;
const ViewSweetStateContainer = Container;
export const useViewActions = useActions;

export type ExternalProps = Props & {
	children: ReactNode;
};

const useDebouncedRefreshViewComments = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { loadViewComments } = useViewActions();
	const cloudId = useCloudId();

	return useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const funcCache: Record<string, any> = {};

		return (viewIdOrAri: string) => {
			if (!has(funcCache, viewIdOrAri)) {
				funcCache[viewIdOrAri] = debounce(() => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'JpdJiraRealtimeEvent refreshViewComments');
					const ari = toAri(viewIdOrAri) || createViewAri(cloudId, viewIdOrAri);
					loadViewComments(ari, true);
				}, EVENT_DEBOUNCE_WAIT_MS);
			}

			funcCache[viewIdOrAri]();
		};
	}, [cloudId, createAnalyticsEvent, loadViewComments]);
};

const ViewContainerInternal = ({ fields }: { fields: FieldsByKey }) => {
	const onRefreshViewComments = useDebouncedRefreshViewComments();
	const {
		deleteViewFromState,
		loadView,
		refreshArrangementInformation,
		refreshViewsRanks,
		deleteSectionFromState,
		loadSection,
	} = useViewActions();
	const { showFlag } = useNotifications();
	const currentUserId = useCurrentUserAccountId();
	const projectId = useProjectIdUnsafe();
	const cloudId = useCloudId();

	const handleDeleteView = useCallback(
		async (id: number, viewUUID: string, userId: string) => {
			const { isCurrentView } = deleteViewFromState(id);

			if (isCurrentView && userId !== currentUserId) {
				const userName = await getUserName(userId);

				showFlag({
					id: 'polaris.real-time-event-delete-view',
					type: 'warning',
					title: [messages.viewDeleted, { userName }],
					isAutoDismiss: true,
				});
			}
		},
		[deleteViewFromState, showFlag, currentUserId],
	);

	const handleCreateView = useMemo(
		() => async (id: number, uuid: string, view?: GetViewResponse) => {
			const remoteView = view ? transformView(view, fields) : undefined;
			await loadView(id, remoteView);
		},
		[loadView, fields],
	);

	const handleUpdateView = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const funcCache: Record<string, any> = {};

		const callback = async (
			id: number,
			uuid: string,
			userId: string,
			changes: PolarisViewChanges[],
			view?: GetViewResponse,
		) => {
			if (
				getWillShowNav4() &&
				(changes?.includes('name') || changes?.includes('emoji')) &&
				changes?.includes('*') &&
				changes?.length === 2
			) {
				// We don't need to update the the main app state for name and emoji changes when nav4 is enabled
				return;
			}

			const remoteView = view ? transformView(view, fields) : undefined;
			const { isDraftReset } = await loadView(id, remoteView);

			if (userId === currentUserId) {
				return;
			}

			const userName = await getUserName(userId);

			if (isDraftReset) {
				showFlag({
					id: 'polaris.real-time-event-update-view',
					type: 'warning',
					title: [messages.viewUpdated, { userName }],
					isAutoDismiss: true,
				});
			}
		};

		return (
			id: number,
			uuid: string,
			userId: string,
			changes: PolarisViewChanges[],
			view?: GetViewResponse,
		) => {
			if (view) {
				callback(id, uuid, userId, changes, view);
				return;
			}

			if (!has(funcCache, id)) {
				funcCache[id] = debounce(callback, EVENT_DEBOUNCE_WAIT_MS);
			}

			funcCache[id](id, uuid, userId, changes, view);
		};
	}, [loadView, showFlag, fields, currentUserId]);

	const handleUpdateViewArrangementInfo = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const funcCache: Record<string, any> = {};

		return (id: number) => {
			if (!has(funcCache, id)) {
				funcCache[id] = debounce(() => {
					const viewAri = createViewAri(cloudId, id.toString());
					refreshArrangementInformation(viewAri);
				}, EVENT_DEBOUNCE_WAIT_MS);
			}

			funcCache[id]();
		};
	}, [cloudId, refreshArrangementInformation]);

	const debouncedRefreshViewsRanks = useMemo(
		() => debounce(refreshViewsRanks, EVENT_DEBOUNCE_WAIT_MS),
		[refreshViewsRanks],
	);

	const handleRefreshViewsRanks = useCallback(
		() => debouncedRefreshViewsRanks(),
		[debouncedRefreshViewsRanks],
	);

	const handleRefreshViewSetsRanks = useCallback(
		() => debouncedRefreshViewsRanks(),
		[debouncedRefreshViewsRanks],
	);

	const handleCreateViewSet = useCallback((id: number) => loadSection(id), [loadSection]);
	const handleUpdateViewSet = useCallback((id: number) => loadSection(id, true), [loadSection]);
	const handleDeleteViewSet = useCallback(
		(id: number) => deleteSectionFromState(id),
		[deleteSectionFromState],
	);

	return (
		<ViewRealtimeHandler
			projectId={projectId}
			onRefreshViewComments={onRefreshViewComments}
			onDeleteView={handleDeleteView}
			onCreateView={handleCreateView}
			onUpdateView={handleUpdateView}
			onUpdateViewArrangementInfo={handleUpdateViewArrangementInfo}
			// We don't need to update the the main app state for views sets changes when nav4 is enabled
			onCreateViewSet={getWillShowNav4() ? noop : handleCreateViewSet}
			onDeleteViewSet={getWillShowNav4() ? noop : handleDeleteViewSet}
			onUpdateViewSet={getWillShowNav4() ? noop : handleUpdateViewSet}
			onUpdateViewsRanks={getWillShowNav4() ? noop : handleRefreshViewsRanks}
			onUpdateViewSetsRanks={getWillShowNav4() ? noop : handleRefreshViewSetsRanks}
		/>
	);
};

export const ViewContainer = (props: ExternalProps) => {
	const { children, ...rest } = props;
	return (
		<ViewSweetStateContainer {...rest}>
			{rest.isSharedView ? null : <ViewContainerInternal fields={props.fields} />}
			{children}
		</ViewSweetStateContainer>
	);
};
