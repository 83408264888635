import type { FieldOptions } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type { IssueKey } from '@atlassian/jira-shared-types';
import type { FieldError } from '../../../common/experience-tracking/field-error-classifier';

type SaveSuccessMeta = {
	issueKey: IssueKey;
	shouldFetchViewContext: boolean;
};

// FIELD_SAVE_REQUEST
export const FIELD_SAVE_REQUEST = 'FIELD_SAVE_REQUEST' as const;

export type FieldAnalyticsPayload = {
	updatedField: string;
	oldValId?: string;
	newValId?: string;
	oldValName?: string;
	newValName?: string;
};

export type FieldSaveRequestReturnType<T> = {
	type: typeof FIELD_SAVE_REQUEST;
	payload: {
		fieldId: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
		fieldOptions: FieldOptions<T>;
		saveFieldData?: T;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		analyticsEvent: any;
		shouldFetchViewContext: boolean;
		eventData?: FieldAnalyticsPayload;
	};
};

export const fieldSaveRequest = <T,>(
	fieldId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
	fieldOptions: FieldOptions<T>,
	saveFieldData: T | null | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsEvent: any,
	fieldAnalyticsData: FieldAnalyticsPayload | null | undefined,
	shouldFetchViewContext = false,
): FieldSaveRequestReturnType<T> => ({
	type: FIELD_SAVE_REQUEST,
	payload: {
		fieldId,
		value,
		fieldOptions,
		// @ts-expect-error - TS2322 - Type 'T | null | undefined' is not assignable to type 'T | undefined'.
		saveFieldData,
		analyticsEvent,
		shouldFetchViewContext,
		// passed through as the 'eventData' prop because the analytics epic
		// that picks this up will spread the data in eventData into the final
		// analyticsObject
		...(fieldAnalyticsData && { eventData: fieldAnalyticsData }),
	},
});

export type FieldSaveRequestAction = ReturnType<typeof fieldSaveRequest>;

// FIELD_SAVE_SUCCESS
export const FIELD_SAVE_SUCCESS = 'FIELD_SAVE_SUCCESS' as const;

type FieldSaveSuccessReturnType<T> = {
	type: typeof FIELD_SAVE_SUCCESS;
	payload: {
		fieldId: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fieldValue: any;
		fieldOptions: FieldOptions<T>;
		saveFieldResult?: T;
	};
	meta?: SaveSuccessMeta;
};

export const fieldSaveSuccess = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldValue: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	saveFieldResult: any,
	meta?: SaveSuccessMeta,
): FieldSaveSuccessReturnType<T> => ({
	type: FIELD_SAVE_SUCCESS,
	payload: {
		fieldId,
		fieldOptions,
		fieldValue,
		saveFieldResult,
	},
	meta,
});

export type FieldSaveSuccessAction = ReturnType<typeof fieldSaveSuccess>;

// FIELD_SAVE_FAILURE
export const FIELD_SAVE_FAILURE = 'FIELD_SAVE_FAILURE' as const;

type FieldSaveFailureReturnType<T> = {
	type: typeof FIELD_SAVE_FAILURE;
	payload: {
		fieldId: string;
		invalidMessage: string | null;
		fieldOptions: FieldOptions<T>;
		error: FieldError;
	};
};

export const fieldSaveFailure = <T,>(
	fieldId: string,
	invalidMessage: string | null,
	error: FieldError,
	fieldOptions: FieldOptions<T>,
): FieldSaveFailureReturnType<T> => ({
	type: FIELD_SAVE_FAILURE,
	payload: {
		fieldId,
		invalidMessage,
		error,
		fieldOptions,
	},
});

export type FieldSaveFailureAction = ReturnType<typeof fieldSaveFailure>;

// SWEET_STATE_FIELD_SAVE_FAILURE
export const SWEET_STATE_FIELD_SAVE_FAILURE = 'SWEET_STATE_FIELD_SAVE_FAILURE' as const;

type SweetStateFieldSaveFailureReturnType<T> = {
	type: typeof SWEET_STATE_FIELD_SAVE_FAILURE;
	payload: {
		fieldId: string;
		invalidMessage: string | null;
		fieldOptions: FieldOptions<T>;
		error: FieldError;
	};
};

export const sweetStateFieldSaveFailure = <T,>(
	fieldId: string,
	invalidMessage: string | null,
	error: FieldError,
	fieldOptions: FieldOptions<T>,
): SweetStateFieldSaveFailureReturnType<T> => ({
	type: SWEET_STATE_FIELD_SAVE_FAILURE,
	payload: {
		fieldId,
		invalidMessage,
		error,
		fieldOptions,
	},
});

export type SweetStateFieldSaveFailureAction = ReturnType<typeof sweetStateFieldSaveFailure>;

// FIELD_SAVE_SUCCESS_WITH_PASTED_CONTENT
export const FIELD_SAVE_SUCCESS_WITH_PASTED_CONTENT =
	'FIELD_SAVE_SUCCESS_WITH_PASTED_CONTENT' as const;

type FieldSaveSuccessWithPastedContentReturnType<T> = {
	type: typeof FIELD_SAVE_SUCCESS_WITH_PASTED_CONTENT;
	payload: {
		fieldId: string;
		fieldOptions: FieldOptions<T>;
		value: string | null;
		pastedContent: string[];
	};
};
export const fieldSaveSuccessWithPastedContent = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
	value: string | null,
	pastedContent: string[],
): FieldSaveSuccessWithPastedContentReturnType<T> => ({
	type: FIELD_SAVE_SUCCESS_WITH_PASTED_CONTENT,
	payload: {
		fieldId,
		fieldOptions,
		value,
		pastedContent,
	},
});

export type FieldSaveSuccessWithPastedContentAction = ReturnType<
	typeof fieldSaveSuccessWithPastedContent
>;

// FIELD_UPDATED
export const FIELD_UPDATED = 'FIELD_UPDATED' as const;

type FieldUpdatedReturnType<T> = {
	type: typeof FIELD_UPDATED;
	payload: {
		fieldId: string;
		fieldValue: T;
		extraParams?: {
			viaDialog?: boolean;
		};
	};
};
export const fieldUpdated = <T,>(
	fieldId: string,
	fieldValue: T,
	extraParams?: {
		viaDialog?: boolean;
	},
): FieldUpdatedReturnType<T> => ({
	type: FIELD_UPDATED,
	payload: {
		fieldId,
		fieldValue,
		extraParams,
	},
});

export type FieldUpdatedAction = ReturnType<typeof fieldUpdated>;

// SWEET_STATE_FIELD_UPDATED
export const SWEET_STATE_FIELD_UPDATED = 'SWEET_STATE_FIELD_UPDATED' as const;

type SweetStateFieldUpdatedReturnType<T> = {
	type: typeof SWEET_STATE_FIELD_UPDATED;
	payload: {
		fieldId: string;
		fieldValue: T;
		extraParams?: {
			viaDialog?: boolean;
		};
	};
};
export const sweetStateFieldUpdated = <T,>(
	fieldId: string,
	fieldValue: T,
	extraParams?: {
		viaDialog?: boolean;
	},
): SweetStateFieldUpdatedReturnType<T> => ({
	type: SWEET_STATE_FIELD_UPDATED,
	payload: {
		fieldId,
		fieldValue,
		extraParams,
	},
});

export type SweetStateFieldUpdatedAction = ReturnType<typeof sweetStateFieldUpdated>;

// FIELD_CONFIRM_NEW_FIELD
export const FIELD_CONFIRM_NEW_FIELD = 'FIELD_CONFIRM_NEW_FIELD' as const;

type FieldConfirmNewFieldReturnType<T> = {
	type: typeof FIELD_CONFIRM_NEW_FIELD;
	payload: {
		fieldId: string;
		fieldValue: T;
	};
};
export const fieldConfirmNewField = <T,>(
	fieldId: string,
	fieldValue: T,
): FieldConfirmNewFieldReturnType<T> => ({
	type: FIELD_CONFIRM_NEW_FIELD,
	payload: {
		fieldId,
		fieldValue,
	},
});

export type FieldConfirmNewFieldAction = ReturnType<typeof fieldConfirmNewField>;

// FIELD_CONFIRM_FAILURE_NEW_FIELD
export const FIELD_CONFIRM_FAILURE_NEW_FIELD = 'FIELD_CONFIRM_FAILURE_NEW_FIELD' as const;

type FieldConfirmFailureNewFieldReturnType<T> = {
	type: typeof FIELD_CONFIRM_FAILURE_NEW_FIELD;
	payload: {
		fieldId: string;
		fieldValue: T;
	};
};
export const fieldConfirmFailureNewField = <T,>(
	fieldId: string,
	fieldValue: T,
): FieldConfirmFailureNewFieldReturnType<T> => ({
	type: FIELD_CONFIRM_FAILURE_NEW_FIELD,
	payload: {
		fieldId,
		fieldValue,
	},
});

export type FieldConfirmFailureNewFieldAction = ReturnType<typeof fieldConfirmFailureNewField>;

// FIELD_UPDATE
export const FIELD_UPDATE = 'FIELD_UPDATE' as const;

type FieldUpdateReturnType<T> = {
	type: typeof FIELD_UPDATE;
	payload: {
		fieldId: string;
		fieldValue: T;
		fieldOptions: FieldOptions<T>;
	};
};
export const fieldUpdate = <T,>(
	fieldId: string,
	fieldValue: T,
	fieldOptions: FieldOptions<T>,
): FieldUpdateReturnType<T> => ({
	type: FIELD_UPDATE,
	payload: {
		fieldId,
		fieldValue,
		fieldOptions,
	},
});

export type FieldUpdateAction = ReturnType<typeof fieldUpdate>;

export type FieldSaveAction =
	| FieldSaveRequestAction
	| FieldSaveSuccessAction
	| FieldSaveFailureAction
	| FieldSaveSuccessWithPastedContentAction
	| FieldUpdateAction;
