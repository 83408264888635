import React, { memo, useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { EMPTY_VALUE_ID } from '../../../../common/utils/board';
import { useVirtualBoard } from '../board-dimensions-provider';
import {
	COLUMN_CLASS_NAME,
	BOARD_COLUMN_MIN_HEIGHT,
	BOARD_COLUMN_CONTENT_TOP_PADDING,
} from '../constants';
import type { Card as CardType } from '../use-board-virtualization/types';
import { type DraggableCardProps, DraggableCard } from './draggable-card';
import { FilteredColumnPanel } from './filtered-ideas-text';

type CardProps = {
	card: CardType;
	droppableId: string;
	viewLayoutType: ViewLayoutType;
	isReadOnly: boolean;
} & Pick<DraggableCardProps, 'onDragStart' | 'initialDragStatus'>;

const Card = memo((props: CardProps) => {
	const { card, droppableId, viewLayoutType, isReadOnly, onDragStart, initialDragStatus } = props;

	return (
		<CardWrapper start={card.offset}>
			<DraggableCard
				ideaId={card.uid}
				draggableId={`${droppableId}-${card.uid}`}
				droppableId={droppableId}
				index={card.index}
				canDrag={!isReadOnly}
				viewLayoutType={viewLayoutType}
				initialDragStatus={initialDragStatus}
				onDragStart={onDragStart}
			/>
		</CardWrapper>
	);
});

type CardListProps = {
	droppableId: string;
	isReadOnly: boolean;
	viewLayoutType: ViewLayoutType;
	showHiddenIdeasText?: boolean;
	groupIdentity: string | undefined;
	columnIdentity?: string;
	isPreview?: boolean;
	isFullHeight?: boolean;
};

export const CardList = memo((props: CardListProps) => {
	const {
		groupIdentity,
		columnIdentity,
		droppableId,
		isReadOnly,
		viewLayoutType,
		showHiddenIdeasText = false,
		isPreview = false,
		isFullHeight = false,
	} = props;
	const { groupColumnContentHeights, subscribeToColumnChanges, unsubscribeToColumnChanges } =
		useVirtualBoard();
	const [cards, setCards] = useState<CardType[]>([]);
	const [draggedCardUid, setDraggedCardUid] = useState<string>();
	const height = groupColumnContentHeights.get(groupIdentity || EMPTY_VALUE_ID) || 0;

	const handleCardsChange = useCallback((updatedCards: CardType[]) => {
		setCards(updatedCards);
	}, []);

	useEffect(() => {
		if (isPreview) {
			return undefined;
		}

		subscribeToColumnChanges(columnIdentity || EMPTY_VALUE_ID, handleCardsChange);

		return () => {
			unsubscribeToColumnChanges(columnIdentity || EMPTY_VALUE_ID, handleCardsChange);
		};
	}, [
		isPreview,
		groupIdentity,
		columnIdentity,
		subscribeToColumnChanges,
		handleCardsChange,
		unsubscribeToColumnChanges,
	]);

	// we're not doing this inside of monitorForElements because we only
	// want to change the state of the column where the drag started
	const handleDragStart = useCallback((cardUid: string) => {
		setDraggedCardUid(cardUid);
	}, []);

	useEffect(() => {
		const cleanupDragAndDrop = monitorForElements({
			canMonitor: ({ source }) => source.data.type === 'CARD',
			onDrop: () => {
				setDraggedCardUid(undefined);
			},
		});

		return cleanupDragAndDrop;
	}, []);

	return (
		<Wrapper
			// used by image export
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={COLUMN_CLASS_NAME}
			isFullHeight={isFullHeight}
			height={isPreview ? 0 : height}
			boardViewColumnMinHeight={BOARD_COLUMN_MIN_HEIGHT}
			data-testid="polaris-ideas.ui.view-content.idea-board.card-list.container"
		>
			<List>
				{showHiddenIdeasText && <FilteredColumnPanel />}
				{cards.map((card) => (
					<Card
						key={card.uid}
						card={card}
						droppableId={droppableId}
						viewLayoutType={viewLayoutType}
						isReadOnly={isReadOnly}
						initialDragStatus={draggedCardUid === card.uid ? 'dragging' : undefined}
						onDragStart={handleDragStart}
					/>
				))}
			</List>
		</Wrapper>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const List = styled.div({
	position: 'relative',
	width: '100%',
	height: '100%',
	boxSizing: 'border-box',
	// necessary for the drag and drop top edge to show correctly on the first card
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingTop: `${BOARD_COLUMN_CONTENT_TOP_PADDING}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.div<{ start: number }>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ start }) => `${start}px`,
	left: 0,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{
	height: number;
	isFullHeight: boolean;
	boardViewColumnMinHeight: number;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexGrow: ({ isFullHeight }) => (isFullHeight ? 1 : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ isFullHeight, boardViewColumnMinHeight }) =>
		isFullHeight ? undefined : `${boardViewColumnMinHeight}px`,
	overflow: 'hidden',
	// necessary for the drag and drop top edge to show correctly on the first card
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '-3px',
});
