import { createSelector } from 'reselect';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import lastElement from 'lodash/last';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import pickBy from 'lodash/pickBy';
import sortedUniq from 'lodash/sortedUniq';
import sum from 'lodash/sum';
import values from 'lodash/values';
import { AnyAri } from '@atlassian/ari/any-ari';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type {
	Snippet,
	SnippetRefreshStatus,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import {
	TRUE_CONSTANT,
	type RecursiveFilter,
} from '@atlassian/jira-polaris-lib-formula/src/utils/filter/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getSnippetPropertyValue } from '../../../../../common/utils/snippet';
import { testInsight } from '../../../../project/insight/filter';
import type { InsightsProperty, Props, State, InsightsMetadataMap } from '../../../types';

const getInsightsRaw = (state: State) => state.properties.insights;
export const getInsights = createSelector(getInsightsRaw, (insights) => {
	const newInsights: InsightsProperty = {};

	return Object.keys(insights).reduce(
		(acc, key) =>
			Object.assign(acc, {
				[key]: insights[key]?.filter((insight) => {
					if (insight.play) {
						return false;
					}
					try {
						// "ari:cloud:jira-product-discovery::item/ac8c6712-6167-4153-8495-6c58253f89a8/158882"
						return AnyAri.parse(insight.id).resourceType !== 'item';
					} catch (e) {
						// do nothing
					}
					return true;
				}),
			}),
		newInsights,
	);
});

const getInsightsMetadataMap = (state: State): InsightsMetadataMap =>
	state.properties.insightsMetadata;

export const createGetInsights = cacheSelectorCreator(
	(id: LocalIssueId, filter: RecursiveFilter = TRUE_CONSTANT) =>
		createSelector(getInsights, (insights) =>
			(insights[id] || []).filter((insight) => testInsight(insight, filter)),
		),
);

export const createGetSnippets = cacheSelectorCreator(
	(id: LocalIssueId, filter: RecursiveFilter = TRUE_CONSTANT, oauthClientId?: string) => {
		const getInsightsForIssue = createGetInsights(id, filter);

		return createSelector(getInsightsForIssue, (insights) =>
			flatten(insights.map(({ snippets }) => snippets)).filter(
				(snippet) =>
					oauthClientId === undefined ||
					(snippet.appInfo !== undefined && snippet.appInfo?.oauthClientId === oauthClientId),
			),
		);
	},
);

export const createGetInsight = (insightId: Ari) =>
	createSelector<State, Props | undefined, InsightsProperty, Insight | undefined>(
		getInsights,
		(insightsMap) => {
			const insights = flatten(Object.values(insightsMap));
			return find(insights, ({ id }) => id === insightId);
		},
	);

const getAllInsights = createSelector(getInsights, (insightsByIssue) =>
	flatten(map(insightsByIssue, (insights) => insights)),
);

const getAllSnippets = createSelector(getAllInsights, (insights) =>
	flatten(map(insights, ({ snippets }) => snippets)),
);

const getAllSnippetRefreshStates = createSelector(getAllSnippets, (snippets) =>
	snippets.map(({ refresh }) => refresh).filter(Boolean),
);

export const getSortedSnippetLabels = createSelector(getAllSnippets, (snippets) => {
	const result = flatten(
		snippets.map((snippet) => {
			const value = getSnippetPropertyValue(snippet.properties, 'labels');
			return Array.isArray(value) ? value : [];
		}),
	);
	result.sort();
	return sortedUniq(result);
});

export const getLastSnippetRefresh = createSelector(getAllSnippetRefreshStates, (refreshs) =>
	max(refreshs.map(({ last }) => last).filter(Boolean)),
);

const createGetInsightsByRefreshCondition = (
	condition: (arg1: SnippetRefreshStatus | null) => boolean,
) =>
	createSelector(getInsights, (insightsByIssue) => {
		const insightsWithSnippetsMeetingCondition = mapValues(insightsByIssue, (insights: Insight[]) =>
			insights
				.map((insight) => {
					const validSnippets: Snippet[] = insight.snippets.filter(
						({ refresh }) => refresh !== undefined && condition(refresh),
					);

					if (validSnippets.length > 0) {
						return {
							...insight,
							snippets: validSnippets,
						};
					}

					return undefined;
				})
				.filter(Boolean),
		);

		return pickBy(insightsWithSnippetsMeetingCondition, (insights) => insights.length > 0);
	});

/**
 * all refresh errors, regardless of whether they are currently being refreshed as well
 */
export const getInsightRefreshErrors = createGetInsightsByRefreshCondition(
	(refresh) => refresh?.error !== undefined && refresh.error !== null,
);

export const getRefreshErrorSnippetsIds = createSelector(
	getInsightRefreshErrors,
	(insightRefreshErrors) => {
		const insightErrorsSet = new Set<string>();

		Object.values(insightRefreshErrors).forEach((insights) => {
			insights.forEach(({ snippets }) => {
				snippets.forEach(({ appInfo }) => {
					if (appInfo?.oauthClientId) {
						insightErrorsSet.add(appInfo?.oauthClientId);
					}
				});
			});
		});

		return insightErrorsSet;
	},
);

const countSnippets = (conditionalInsights: InsightsProperty): number =>
	sum(
		values(conditionalInsights).map((insights) =>
			insights !== undefined ? sum(insights.map(({ snippets }) => snippets.length)) : 0,
		),
	);

export const getInsightRefreshErrorCount = createSelector(getInsightRefreshErrors, countSnippets);

const getCurrentUser = (state: State, props: Props | undefined): AccountId | undefined =>
	props?.currentUser;

export const createGetTimestampCurrentUserSeenInsights =
	(localIssueId: LocalIssueId) => (state: State) =>
		getInsightsMetadataMap(state)[localIssueId]?.timestampCurrentUserSeenInsights;

export const createIsIssueHavingUnseenInsights = (localIssueId: LocalIssueId) =>
	createSelector(
		createGetInsightsForIssue(localIssueId),
		getInsightsMetadataMap,
		getCurrentUser,
		(insights, metadataMap, currentUser) => {
			const metadata = metadataMap[localIssueId];
			if (insights === undefined || insights.length === 0 || currentUser === undefined) {
				return false;
			}
			if (metadata === undefined) {
				return true;
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const lastInsight = lastElement(insights) as Insight;
			const latestCreatedTimestamp = new Date(lastInsight.created).getTime();
			return (
				latestCreatedTimestamp > metadata.timestampCurrentUserSeenInsights &&
				lastInsight.account?.accountId !== currentUser
			);
		},
	);

export const createGetInsightsCount = (localIssueId: LocalIssueId) =>
	createSelector(getInsights, (insightsMap) => insightsMap[localIssueId]?.length ?? 0);

export const createGetInsightsForIssue = (localIssueId: LocalIssueId) =>
	createSelector(getInsights, (insightsMap) => insightsMap[localIssueId] ?? []);
