import type { ProformaState } from '@atlassian/jira-issue-view-common-types/src/proforma-type';
import { type ProformaAction, PROFORMA_FORM_EDIT_MODE_CHANGE } from '../actions/proforma-actions';

const initialState: ProformaState = {
	isProformaFormInEditMode: false,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ProformaState = initialState, action: ProformaAction) => {
	switch (action.type) {
		case PROFORMA_FORM_EDIT_MODE_CHANGE: {
			const isProformaFormInEditMode = !!action.payload;
			return {
				...state,
				isProformaFormInEditMode,
			};
		}

		default:
			return state;
	}
};
