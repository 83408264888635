import React, {
	type PropsWithChildren,
	createContext,
	useContext,
	useMemo,
	useEffect,
	useState,
} from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { QueueType, RealtimeQueueContextType } from '../../types';
import { createQueue } from '../queue';

const getRealtimeEventsQueueLimit = (): number =>
	getFeatureFlagValue<number>('polaris.realtime-events-queue-limit', 0);

const RealtimeQueueContext = createContext<RealtimeQueueContextType | undefined>(undefined);

const isEnabled = () => getRealtimeEventsQueueLimit() > 0;

export const useRealtimeQueueContext = () => {
	const context = useContext(RealtimeQueueContext);
	if (context === undefined) {
		throw new Error('useRealtimeQueueContext must be used within a RealtimeQueueContextProvider');
	}
	return context;
};

const registry = new Map<string, QueueType>();

type RealtimeQueueContextProviderProps = PropsWithChildren & {
	/**
	 * define scope to group events in scoped queue
	 */
	scope?: string;
};

export const RealtimeQueueContextProvider = ({
	children,
	scope,
}: RealtimeQueueContextProviderProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [eventsInQueue, setEventsInQueue] = useState(0);
	const [initialScope] = useState(scope);
	const [areQueueQuotasExceeded, setAreQueueQuotasExceeded] = useState(false);
	const queueWithScopeEnabled = fg('jpd-realtime-queue-with-scope');

	const queue: QueueType = useMemo(() => {
		if (queueWithScopeEnabled && initialScope && registry.has(initialScope)) {
			const queueFromRegistry = registry.get(initialScope);
			if (queueFromRegistry) {
				return queueFromRegistry;
			}
		}
		const newQueue = createQueue(createAnalyticsEvent, (eventsInQueueParam: number) => {
			setEventsInQueue(eventsInQueueParam);
			setAreQueueQuotasExceeded(true);
		});
		if (queueWithScopeEnabled && initialScope) {
			registry.set(initialScope, newQueue);
		}
		return newQueue;
	}, [createAnalyticsEvent, queueWithScopeEnabled, initialScope]);

	useEffect(
		() => () => {
			if (queueWithScopeEnabled && initialScope) {
				const queueFromRegistry = registry.get(initialScope);
				if (queueFromRegistry?.isEmpty()) {
					registry.delete(initialScope);
				}
			}
		},
		[initialScope, queueWithScopeEnabled],
	);

	useEffect(() => {
		if (isEnabled()) {
			return queue.start();
		}
		return undefined;
	}, [queue]);

	return (
		<RealtimeQueueContext.Provider
			value={{
				queue,
				eventsInQueue,
				areQueueQuotasExceeded,
			}}
		>
			{children}
		</RealtimeQueueContext.Provider>
	);
};
