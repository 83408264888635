import React, { useMemo } from 'react';
import { functionWithFF } from '@atlassian/jira-feature-flagging-utils';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import type {
	ChainableContextProvider,
	PolarisApolloClient,
} from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { cachedPromise } from '@atlassian/jira-polaris-lib-remote-context/src/utils/cache.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { getJpdEditionsMeta } from '../../../services/jira/edition-meta';
import type { EditionsMetaResponse } from '../../../services/jira/edition-meta/types';
import { fetchIsEditorAiEnabled } from '../../../services/jira/editor-ai';
import { getClassicProjectEpicNameFieldKey } from '../../../services/jira/epic-name';
import { getIssueLinkTypes } from '../../../services/jira/issue-link-types';
import { addCurrentUserToSiteCreators } from '../../../services/jira/jpd-permissions';
import { setEnforcePermissionChecksOnSiteEnabled } from '../../../services/jira/toggle-enforce-permission-checks';
import { setSelfServeOnSiteEnabled } from '../../../services/jira/toggle-self-serve';
import { createAddAsSiteCreator } from '../../../services/polaris-api/site-creator';
import { notImplementedError } from './common/utils';
import type { SiteRemote } from './types';

const createSiteRemote = (apolloClient: PolarisApolloClient, cloudId: string): SiteRemote => ({
	getJpdEditionsMeta: cachedPromise(getJpdEditionsMeta),
	addCurrentUserToSiteCreators,
	getClassicProjectEpicNameFieldKey,
	getIssueLinkTypes,
	addAsSiteCreator: createAddAsSiteCreator(apolloClient, cloudId),
	setSelfServeOnSiteEnabled,
	setEnforcePermissionChecksOnSiteEnabled,
	fetchIsEditorAiEnabled: () => fetchIsEditorAiEnabled(apolloClient, cloudId),
});

const { RemoteContextProvider, useRemoteFromContext } = createRemoteContext<SiteRemote>('site');

export const SiteRemoteProvider: ChainableContextProvider = ({ children, apolloClient }) => {
	const { cloudId } = useTenantContext();
	const siteRemote = useMemo(
		() => createSiteRemote(apolloClient, cloudId),
		[apolloClient, cloudId],
	);

	return <RemoteContextProvider remote={siteRemote}>{children}</RemoteContextProvider>;
};

export const useSiteRemote = functionWithFF(
	'polaris.environment-tenant-component',
	() => createNotImplementedSiteRemote(),
	useRemoteFromContext,
);

const createNotImplementedSiteRemote = (): SiteRemote => ({
	getJpdEditionsMeta: notImplementedError('getJpdEditionsMeta'),
	addCurrentUserToSiteCreators: notImplementedError('addCurrentUserToSiteCreators'),
	getClassicProjectEpicNameFieldKey: notImplementedError('getClassicProjectEpicNameFieldKey'),
	getIssueLinkTypes: notImplementedError('getIssueLinkTypes'),
	addAsSiteCreator: notImplementedError('addAsSiteCreator'),
	setSelfServeOnSiteEnabled: notImplementedError('setSelfServeOnSiteEnabled'),
	setEnforcePermissionChecksOnSiteEnabled: notImplementedError(
		'setEnforcePermissionChecksOnSiteEnabled',
	),
	fetchIsEditorAiEnabled: notImplementedError('fetchIsEditorAiEnabled'),
});

const createSharingSiteRemote = (): SiteRemote => ({
	...createNotImplementedSiteRemote(),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getJpdEditionsMeta: () => Promise.resolve({} as EditionsMetaResponse),
});

export const SharingSharingSiteRemoteProvider: ChainableContextProvider = ({ children }) => {
	const siteRemote = useMemo(() => createSharingSiteRemote(), []);

	return <RemoteContextProvider remote={siteRemote}>{children}</RemoteContextProvider>;
};
