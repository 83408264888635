import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories';
import type { AggAssociatedIssue } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/issue-type.tsx';
import type { AssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import { transformAggFieldsToLegacyFields } from './fields-transformer';

export const transformAggAssociatedIssueToAssociatedIssue = (
	issue: AggAssociatedIssue,
): AssociatedIssue => {
	const fields: FieldsState = transformAggFieldsToLegacyFields(issue.fieldsById?.edges);

	const assignee = fields.assignee?.value;
	const issueType = fields.issuetype?.value;
	const priority = fields.priority?.value;
	const summary = fields.summary?.value;
	const status = fields.status?.value;

	return {
		id: issue.issueId,
		issueKey: issue.key,
		issueLink: issue.webUrl ?? '',
		issueSummary: summary || '',
		statusCategory: statusCategoryForId(status?.statusCategory?.id),
		statusCategoryId: status?.statusCategory?.id,
		statusName: status?.name,
		statusId: status?.id,
		issuePriorityUrl: priority?.iconUrl ?? null,
		issuePriorityName: priority?.name ?? null,
		issuePrioritySequence: priority?.sequence ?? null,
		issueTypeIconUrl: issueType?.iconUrl ?? null,
		issueTypeName: issueType?.name ?? null,
		assigneeDisplayName: assignee?.displayName || null,
		assigneeUrl: assignee?.avatarUrl || null,
	};
};
