export const getIssueLink = (
	projectKey?: string,
	issueKey?: string,
	isSharedView?: boolean,
): string | undefined => {
	if (!issueKey) return undefined;

	if (isSharedView) return `${window.location.pathname}?selectedIssue=${issueKey}`;

	return projectKey
		? `/jira/polaris/projects/${projectKey}/ideas?selectedIssue=${issueKey}`
		: undefined;
};

export const getDirectIssueLink = (issueKey?: string, isSharedView?: boolean) =>
	isSharedView
		? `${window.location.origin}${window.location.pathname}?selectedIssue=${issueKey}`
		: `${window.location.origin}/browse/${issueKey}`;
