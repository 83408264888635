import { useMemo } from 'react';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useValidTransitionsForIssueTypeAndStatus } from '../../workflow/selectors/transitions-hooks';
import type { IssueTypeTransitionConfig } from '../../workflow/types';
import { useIssueType, useStatus } from '../selectors/properties/hooks';

export const useIssueTypeAndStatus = (issueId?: LocalIssueId) => {
	const issueType = useIssueType(issueId);
	const status = useStatus(issueId);

	return useMemo(
		() => ({
			issueType,
			status,
		}),
		[issueType, status],
	);
};

export const useValidTransitionTargets = (issueId?: LocalIssueId): IssueTypeTransitionConfig[] => {
	const { issueType, status } = useIssueTypeAndStatus(issueId);
	return useValidTransitionsForIssueTypeAndStatus(issueType?.id, status);
};

export const useValidTransitionStatusTargetIds = (issueId?: LocalIssueId): string[] => {
	const targets = useValidTransitionTargets(issueId);
	return useMemo(() => targets.map(({ to }) => to.id), [targets]);
};
