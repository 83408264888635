import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import isValidExperienceError from '@atlassian/jira-issue-view-common-utils/src/utils/is-valid-experience-error';
import { WorkflowTransitionsStoreContainer } from '@atlassian/jira-issue-workflow-transitions-services/src/context.tsx';
import { useWorkflowTransitionsValue } from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import { fireTrackAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { STATUS_FIELD_FETCH_TRANSITIONS_KEY } from '../../common/constants';
import { transitionIssueExperienceDescription } from '../experience-descriptions';
import type { Props } from './types';
import {
	filterTransitionsWithDestinationAndCategory,
	transformTransitionToStore,
	transformTransitionFromStore,
} from './utils';

export const getStatusTransitionsUrl = (_baseUrl: BaseUrl, issueKey: IssueKey) =>
	`/rest/api/2/issue/${issueKey}/transitions?sortByOpsBarAndStatus=true`;

const enhancedSendExperienceAnalytics = (
	successful: boolean,
	// @ts-expect-error - TS2304 - Cannot find name 'ProjectType'.
	projectType: ProjectType | null | undefined,
	errorMessage: undefined | string,
	traceId: undefined,
) => {
	const description =
		typeof errorMessage === 'string'
			? transitionIssueExperienceDescription(
					successful,
					'GET',
					'Issue View BaseTransitionsQuery',
					projectType || null,
					errorMessage,
					traceId,
				)
			: transitionIssueExperienceDescription(
					successful,
					'GET',
					'Issue View BaseTransitionsQuery',
					projectType || null,
				);
	sendExperienceAnalytics(description);
};

export const BaseTransitionsQuery = (props: Props) => {
	const { status, issueKey, baseUrl, projectType, preFetch, createAnalyticsEvent, isSaving } =
		props;
	const [transitionsState, setTransitionsState] = useWorkflowTransitionsValue();
	const isSingletonRequestRef = useRef(false);

	const [fieldValues] = useFieldsValues(issueKey);

	const fetch = useCallback(
		async (event: UIAnalyticsEvent) => {
			if (transitionsState.loading) {
				return;
			}
			if (isSingletonRequestRef.current) {
				return;
			}
			isSingletonRequestRef.current = true;

			setTransitionsState({
				loading: true,
				error: null,
			});

			try {
				const result = await fetchJson(getStatusTransitionsUrl(baseUrl, issueKey));
				const data = filterTransitionsWithDestinationAndCategory(result.transitions);

				fireTrackAnalyticsDeferred(event, 'statusField transitionsFetched', 'statusField', {
					count: data.length,
				});

				// Issue Transition SLA
				// @ts-expect-error - TS2554 - Expected 4 arguments, but got 2.
				enhancedSendExperienceAnalytics(true, projectType);

				setTransitionsState({
					data: data.map(transformTransitionToStore),
					loading: false,
					error: null,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				trackOrLogClientError(STATUS_FIELD_FETCH_TRANSITIONS_KEY, e.toString(), e);

				// Issue Transition SLA
				if (isValidExperienceError(e)) {
					enhancedSendExperienceAnalytics(
						false,
						projectType,
						`${e.name || ''} ${e.message}`.trim(),
						e.traceId,
					);
				} else {
					// @ts-expect-error - TS2554 - Expected 4 arguments, but got 2.
					enhancedSendExperienceAnalytics(true, projectType);
				}

				setTransitionsState({
					data: null,
					loading: false,
					error: e,
				});
			}
			isSingletonRequestRef.current = false;
		},
		// we want to fetch the transtions whenever any field values change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[baseUrl, issueKey, projectType, setTransitionsState, fieldValues],
	);

	// re-evaluates fetching data again whenever issueKey changes
	// we have to use useMemo instead of useEffect here because useMemo will be invoked before render
	useMemo(() => {
		if (preFetch === true && createAnalyticsEvent) {
			fetch(createAnalyticsEvent({}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issueKey, preFetch, createAnalyticsEvent, setTransitionsState, fetch]);

	// We need to clear the "data" list whenever the status object changes, because
	// each status has a different list of transitions associated with it.
	// We could achieve a similar behaviour by using a "key" prop when rendering
	// TransitionQuery, but that causes problems with keeping the focus on the component
	// when the status changes. We are using the full status object as "self-transitions"
	// from one status to itself need to exhibit the same behaviour, and it also fixes a bug
	// with PopupSelect downstream
	useEffect(() => {
		if (isSaving) {
			setTransitionsState({
				data: null,
			});
		}
		// component just finished saving, changing from saving to not saving, so we fetch the transitions for the saved status!
		else if (!isSaving && preFetch === true && createAnalyticsEvent) {
			fetch(createAnalyticsEvent({}));
		}
	}, [isSaving, status, preFetch, createAnalyticsEvent, setTransitionsState, fetch]);

	return props.children({
		...transitionsState,
		data: transitionsState.data ? transitionsState.data.map(transformTransitionFromStore) : null,
		fetch,
	});
};

export const ScopedTransitionsQuery = (props: Props) => (
	<WorkflowTransitionsStoreContainer scope={props.issueKey}>
		<BaseTransitionsQuery {...props} />
	</WorkflowTransitionsStoreContainer>
);

const transactionsQueryWithAnalytics = withAnalyticsEvents()(ScopedTransitionsQuery);
export { transactionsQueryWithAnalytics as TransitionsQuery };
