import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { DecoratedTag } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/tag/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { NoValueContainer } from '../../../../common/board-header-column/styled';
import messages from './messages';

type ColumnHeaderComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const LabelColumnHeader = ({ fieldKey, groupIdentity }: ColumnHeaderComponentProps) => (
	<DecoratedTag compact fieldKey={fieldKey} id={groupIdentity} value={groupIdentity} />
);

const EmptyLabelColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

export const labelsColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: true,
	Component: LabelColumnHeader,
	EmptyComponent: EmptyLabelColumnHeader,
} as const;
