import type { OriginalEstimateValue } from '@atlassian/jira-issue-field-original-estimate-inline-edit/src/services';
import { numberScopes } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/number';
import { commonIssueViewGranularScopes } from './fields-scopes';

export type EstimateValue = number | null;
const SECONDS_PER_MINUTE = 60;

export const validateEstimate = (value: unknown): value is EstimateValue =>
	value === null || (typeof value === 'number' && value >= 0);

export const transformSetValueOriginalEstimate = (
	value: OriginalEstimateValue,
): OriginalEstimateValue => {
	if (!value) {
		return null;
	}
	return value * SECONDS_PER_MINUTE;
};

export const transformGetValueOriginalEstimate = (
	value: OriginalEstimateValue,
): OriginalEstimateValue => {
	if (!value) {
		return null;
	}

	return value / SECONDS_PER_MINUTE;
};

export const originalEstimateIssueViewScopes = {
	...numberScopes,
	granular: [...numberScopes.granular, ...commonIssueViewGranularScopes],
};
