import omit from 'lodash/omit';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getFieldMappings } from '../../selectors/fields';
import type { State, Props } from '../../types';

export const removeIssueFromState =
	(localIssueId: LocalIssueId) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const { ids } = state;
		const newIds = ids.filter((id) => id !== localIssueId);

		const fieldMappings = getFieldMappings(state, props);
		const properties =
			props.fields !== undefined
				? props.fields.reduce(
						(propertyMap, field) => fieldMappings[field.key].remove(propertyMap, localIssueId),
						state.properties,
					)
				: state.properties;

		const newCreated = omit(state.properties.created, localIssueId);

		setState({
			ids: newIds,
			properties: {
				...properties,
				created: {
					...newCreated,
				},
			},
		});
	};
