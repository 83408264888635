import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	xAxisLabel: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.x-axis-label',
		defaultMessage: 'X-Axis',
		description: '',
	},
	yAxisLabel: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.y-axis-label',
		defaultMessage: 'Y-Axis',
		description: '',
	},
	zAxisLabel: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.z-axis-label',
		defaultMessage: 'Size',
		description: '',
	},
	axisHelpText: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.axis-help-text',
		defaultMessage: 'Select a field for the axis in the view.',
		description: '',
	},
	noEditPermissions: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.no-edit-permissions',
		defaultMessage: 'Only project admins can edit fields',
		description: 'Information message for missing field edit permissions.',
	},
	editField: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.edit-field',
		defaultMessage: 'Edit field',
		description: '',
	},
	switchAxesLabel: {
		id: 'polaris-ideas.view-controls.config-matrix-axis.switch-axes-label',
		defaultMessage: 'Switch axes',
		description: 'Label for a button to switch matrix axes',
	},
});
