import { ff } from '@atlassian/jira-feature-flagging';
import { notImplementedError } from '../../common/utils';
import {
	fetchSharingSettings,
	updateSharingSettings,
	deleteSharingSettings,
	updatePublicSharingSettings,
	fetchSharingSettingsPublic,
} from '../../services/jpd-views-service/sharing-settings';
import {
	addSharingStakeholders,
	fetchSharingStakeholders,
	removeSharingStakeholders,
} from '../../services/jpd-views-service/sharing-stakeholders';
import type { ViewSharingRemote } from './types';

export const createNotImplementedViewSharingRemote = (): ViewSharingRemote => ({
	fetchSharingSettings: notImplementedError('fetchSharingSettings'),
	updateSharingSettings: notImplementedError('updateSharingSettings'),
	updatePublicSharingSettings: notImplementedError('updatePublicSharingSettings'),
	deleteSharingSettings: notImplementedError('deleteSharingSettings'),
	fetchSharingStakeholders: notImplementedError('fetchSharingStakeholders'),
	addSharingStakeholders: notImplementedError('addSharingStakeholders'),
	removeSharingStakeholders: notImplementedError('removeSharingStakeholders'),
});

export const createViewSharingRemote = (): ViewSharingRemote => ({
	fetchSharingSettings: (viewUUID) => fetchSharingSettings(viewUUID),
	updateSharingSettings: (viewUUID, settings) => updateSharingSettings(viewUUID, settings),
	updatePublicSharingSettings: (viewUUID, settings) =>
		updatePublicSharingSettings(viewUUID, settings),
	deleteSharingSettings: (viewUUID) => deleteSharingSettings(viewUUID),
	fetchSharingStakeholders: (viewUUID) =>
		fetchSharingStakeholders(viewUUID).then(({ profiles, groups }) => ({
			users: profiles.map(({ accountId, name, avatarUrl }) => ({
				id: accountId,
				name,
				avatarUrl,
			})),
			groups: groups.map(({ groupId, name }) => ({
				id: groupId,
				name,
			})),
		})),
	addSharingStakeholders,
	removeSharingStakeholders,
});

export const createSharedViewSharingRemote = (): ViewSharingRemote => ({
	...createNotImplementedViewSharingRemote(),
	fetchSharingSettings: (viewUUID) =>
		ff('polaris.published-view-public-links_dj5gi', false)
			? fetchSharingSettingsPublic(viewUUID)
			: fetchSharingSettings(viewUUID),
	fetchSharingStakeholders: () =>
		Promise.resolve({
			users: [],
			groups: [],
		}),
});

export const createCollectionViewSharingRemote = (): ViewSharingRemote => ({
	...createNotImplementedViewSharingRemote(),
	fetchSharingSettings: (viewUUID) => fetchSharingSettings(viewUUID),
	fetchSharingStakeholders: () =>
		Promise.resolve({
			users: [],
			groups: [],
		}),
});
