import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/window';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/filter';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/mergeAll';
import {
	saveCommentSuccessWithPastedContent,
	type CommentAction,
	ADD_COMMENT_FORM_EXPAND,
	EDIT_COMMENT_BEGIN,
	PASTED_INTO_COMMENT_FORM,
	SAVE_COMMENT_SUCCESS,
	type AddCommentFormExpandAction,
	type EditCommentBeginAction,
	type PastedIntoCommentFormAction,
	type SaveCommentSuccessAction,
	type SaveCommentSuccessWithPastedContentAction,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants';

type EditorOpenedAction = AddCommentFormExpandAction | EditCommentBeginAction;
type TargetEditorAction = PastedIntoCommentFormAction | SaveCommentSuccessAction;
type FilteredAction = EditorOpenedAction | TargetEditorAction;
type PasteAggregation = string[];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<CommentAction>) =>
	// @ts-expect-error - TS2769 - No overload matches this call. | TS2684 - The 'this' context of type 'Observable<GroupedObservable<string | null, FilteredAction>>' is not assignable to method's 'this' of type 'Observable<ActionsObservable<FilteredAction>>'.
	action$
		.ofType(
			ADD_COMMENT_FORM_EXPAND,
			EDIT_COMMENT_BEGIN,
			PASTED_INTO_COMMENT_FORM,
			SAVE_COMMENT_SUCCESS,
		)
		.groupBy((action: FilteredAction): string | null => {
			switch (action.type) {
				case ADD_COMMENT_FORM_EXPAND:
					return NEW_COMMENT_ID;

				case EDIT_COMMENT_BEGIN:
					return action.payload.id;

				case PASTED_INTO_COMMENT_FORM:
					return action.payload.id;

				case SAVE_COMMENT_SUCCESS:
					return action.payload.optimisticId.startsWith(NEW_COMMENT_ID)
						? NEW_COMMENT_ID
						: action.payload.optimisticId;

				default:
					return null;
			}
		})
		.map(
			(
				groupedStream$: ActionsObservable<FilteredAction>,
			): ActionsObservable<SaveCommentSuccessWithPastedContentAction> => {
				// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<FilteredAction>' but required in type 'ActionsObservable<EditorOpenedAction>'.
				const newEditorSession$: ActionsObservable<EditorOpenedAction> = groupedStream$.filter(
					({ type }) => type === ADD_COMMENT_FORM_EXPAND || type === EDIT_COMMENT_BEGIN,
				);

				// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<Observable<FilteredAction>>' is not assignable to method's 'this' of type 'Observable<ActionsObservable<TargetEditorAction>>'.
				return groupedStream$
					.window(newEditorSession$)
					.map(
						(
							sessionStream$: ActionsObservable<TargetEditorAction>,
						): ActionsObservable<SaveCommentSuccessWithPastedContentAction> => {
							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<TargetEditorAction>' but required in type 'ActionsObservable<SaveCommentSuccessAction>'.
							const sessionSaveSuccess$: ActionsObservable<SaveCommentSuccessAction> =
								sessionStream$.filter(({ type }) => type === SAVE_COMMENT_SUCCESS);

							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<TargetEditorAction>' but required in type 'ActionsObservable<PastedIntoCommentFormAction>'.
							const sessionPastes$: ActionsObservable<PastedIntoCommentFormAction> = sessionStream$
								.filter(({ type }) => type === PASTED_INTO_COMMENT_FORM)
								.takeUntil(sessionSaveSuccess$);

							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<any[]>' but required in type 'ActionsObservable<PasteAggregation>'.
							const pasteAggregator$: ActionsObservable<PasteAggregation> =
								// @ts-expect-error - TS2769 - No overload matches this call.
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								sessionPastes$.reduce<Array<any>>(
									(acc: string[], curr: PastedIntoCommentFormAction) => [
										// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
										...acc,
										curr.payload.pastedContent,
									],
									[],
								);

							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<SaveCommentSuccessWithPastedContentAction | null>' but required in type 'ActionsObservable<SaveCommentSuccessWithPastedContentAction>'.
							return sessionSaveSuccess$.withLatestFrom(pasteAggregator$).mergeMap(
								([saveSuccessAction, pastedContent]: [
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									any,
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									any,
								]): Observable<SaveCommentSuccessWithPastedContentAction | null> => {
									const { comment } = saveSuccessAction.payload;
									return pastedContent.length === 0
										? Observable.empty<never>()
										: Observable.of(saveCommentSuccessWithPastedContent(comment, pastedContent));
								},
							);
						},
					)
					.mergeAll();
			},
		)
		.mergeAll();
