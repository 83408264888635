import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionHeader: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.section-header',
		defaultMessage: 'More options',
		description: 'Header label for advanced sort settings',
	},
	viewRankIndicator: {
		id: 'polaris-ideas.right-sidebar.sort.advanced.view-rank-indicator',
		defaultMessage: 'View rank',
		description: 'Text in the view rank indicator.',
	},
});
