import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.read-view.placeholder',
		defaultMessage: 'Add a description to provide context about this view...',
		description:
			'Add a description to provide context or additional resources for viewers for the View',
	},
	placeholderSharedView: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.read-view.placeholder-shared-view',
		defaultMessage: 'No description has been added to this view',
		description: 'Help text if no description has been added to the view',
	},
	editDescriptionButtonLabel: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.read-view.edit-description-button-label',
		defaultMessage: 'Edit description',
		description: 'Edit description icon button label',
	},
});
