import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.shared-view-signup-hero.title',
		defaultMessage: 'Someone from {organizationName} shared this with you',
		description: 'Header text for sign-up hero screen',
	},
	description: {
		id: 'polaris-ideas.shared-view-signup-hero.description',
		defaultMessage:
			'To access it, you need an Atlassian account. Don’t worry; it only takes a minute to setup.',
		description: 'Content text sign-up hero screen',
	},
	createAccount: {
		id: 'polaris-ideas.shared-view-signup-hero.create-account',
		defaultMessage: 'Create a free Atlassian account',
		description: 'Primary action to navigate the user to create an account',
	},
	logIn: {
		id: 'polaris-ideas.shared-view-signup-hero.log-in',
		defaultMessage: 'Already have an account? Log in',
		description: 'Tertiary action to navigate the user to log in',
	},
});
