import { createSelector } from 'reselect';
import { ISSUE_RESTRICTION } from '@atlassian/jira-issue-view-configurations';
import { fieldSelector } from '../../../common/state/selectors/field-selector';

export const issueRestrictionSelector = fieldSelector(ISSUE_RESTRICTION);

export type Value = {
	restrictionValue: string;
};

export const currentIssueRestrictionSelector = createSelector(
	issueRestrictionSelector,
	(currentRestriction) =>
		(currentRestriction &&
			currentRestriction.value &&
			currentRestriction.value.issuerestrictions &&
			currentRestriction.value.issuerestrictions.projectrole) ||
		[],
);

export const isIssueRestrictionDisplaySelector = createSelector(
	issueRestrictionSelector,
	(currentRestriction): boolean =>
		(currentRestriction && currentRestriction.value && currentRestriction.value.shouldDisplay) ||
		false,
);
