import { ff } from '@atlassian/jira-feature-flagging';
import {
	useIsCreatingIdeasForGroupingSupported,
	useIsReadOnlyFieldForGrouping,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useCurrentViewContainsArchived } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import { useCanCreateAndEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';

export function useCanCreateIdeasWhenNoGrouping(isExporting: boolean): boolean {
	const canCreateAndEditIssues = useCanCreateAndEditIssues();
	const isArchiveView = useCurrentViewContainsArchived();

	return (
		ff('polaris.idea-list-row-group-column-header-add-idea') &&
		!isExporting &&
		canCreateAndEditIssues &&
		!isArchiveView
	);
}

type UseCanCreateIdeaWhenGroupingOptions = {
	isExporting: boolean;
	fieldKey?: string;
};

export function useCanCreateIdeasWhenGrouping({
	isExporting,
	fieldKey,
}: UseCanCreateIdeaWhenGroupingOptions): boolean {
	const canCreateIdeasWhenNoGrouping = useCanCreateIdeasWhenNoGrouping(isExporting);
	const isReadOnlyFieldForGrouping = useIsReadOnlyFieldForGrouping(fieldKey);
	const isCreatingIdeasForGroupingSupported = useIsCreatingIdeasForGroupingSupported(fieldKey);

	return (
		canCreateIdeasWhenNoGrouping &&
		!isReadOnlyFieldForGrouping &&
		isCreatingIdeasForGroupingSupported
	);
}
