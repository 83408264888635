import React, { useMemo, useState, useRef, useEffect, type ChangeEvent } from 'react';
import debounce from 'lodash/debounce';
import Button from '@atlaskit/button';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	value?: string | undefined;
	onChange: (value: string) => void;
};

export const TextFilterComponent = ({ value, onChange }: Props) => {
	const { formatMessage } = useIntl();

	const inputEl = useRef<unknown>(null);

	const [localValue, setValue] = useState(value);

	const debouncedChange = useMemo(() => debounce(onChange, 200), [onChange]);

	useEffect(() => {
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		inputEl.current?.focus();
		// Clear local value on filter clear.
		if (value === '') {
			setValue(value);
		}
	}, [value]);

	return (
		<Textfield
			ref={inputEl}
			isCompact
			value={localValue}
			placeholder={formatMessage(messages.searchHint)}
			onChange={(event: ChangeEvent<HTMLInputElement>) => {
				setValue(event.target.value);
				debouncedChange(event.target.value);
			}}
			elemAfterInput={
				typeof localValue !== 'undefined' && localValue !== '' ? (
					<Button
						iconBefore={<EditorCloseIcon label="" />}
						appearance="subtle-link"
						spacing="none"
						onClick={() => {
							setValue('');
							onChange('');
						}}
					/>
				) : (
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<EditorSearchIcon primaryColor={token('color.border.input', colors.N100)} label="" />
				)
			}
		/>
	);
};
