import { createSelector } from 'reselect';
import { differenceInBusinessDays } from 'date-fns';
import { SpotlightTypes } from '../../common/types';
import type { State } from '../types';
import { getProductUsageStartTimestamp } from './product-usage-start-timestamp';
import { getRemoteState } from './remote-state';

const getSpotlights = createSelector(getRemoteState, (remoteState) => remoteState?.spotlights);

export const createIsSpotlightClosed = (spotlightType: SpotlightTypes) =>
	createSelector(getSpotlights, (spotlights) => spotlights?.[spotlightType] === true);

const getIgnoredSpotlights = (state: State) => state.ignoredSpotlights;

export const createIsSpotlightClosedOrIgnored = (spotlightType: SpotlightTypes) =>
	createSelector(
		createIsSpotlightClosed(spotlightType),
		getIgnoredSpotlights,
		getProductUsageStartTimestamp,
		(isClosed, ignoredSpotlights, productUsageStartTimestamp) =>
			isClosed || ignoredSpotlights.includes(spotlightType) || !productUsageStartTimestamp,
	);

const CREATE_IDEA_SPOTLIGHT_DELAY = 3; // 3 business days
export const getIsCreateIdeaSpotlightShown = createSelector(
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_IDEA),
	getProductUsageStartTimestamp,

	(isClosed, productUsageStartTimestamp) => {
		if (!productUsageStartTimestamp) {
			return false;
		}

		const diffInBusinessDays = differenceInBusinessDays(
			new Date(),
			new Date(productUsageStartTimestamp),
		);

		return !isClosed && diffInBusinessDays >= CREATE_IDEA_SPOTLIGHT_DELAY;
	},
);

const CREATE_INSIGHT_SPOTLIGHT_DELAY = 5; // 5 business days
export const getIsCreateInsightSpotlightShown = createSelector(
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_INSIGHT),
	getProductUsageStartTimestamp,
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_IDEA),

	(isClosed, productUsageStartTimestamp, isCreateIdeaSpotlightClosed) => {
		if (!productUsageStartTimestamp) {
			return false;
		}

		const diffInBusinessDays = differenceInBusinessDays(
			new Date(),
			new Date(productUsageStartTimestamp),
		);

		return (
			!isClosed &&
			diffInBusinessDays >= CREATE_INSIGHT_SPOTLIGHT_DELAY &&
			isCreateIdeaSpotlightClosed
		);
	},
);

const CREATE_DELIVERY_TICKET_SPOTLIGHT_DELAY = 7; // 7 business days
export const getIsCreateDeliveryTicketSpotlightShown = createSelector(
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_DELIVERY_TICKET),
	getProductUsageStartTimestamp,
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_IDEA),
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_INSIGHT),

	(
		isClosed,
		productUsageStartTimestamp,
		isCreateIdeaSpotlightClosed,
		isCreateInsightSpotlightClosed,
	) => {
		if (!productUsageStartTimestamp) {
			return false;
		}

		const diffInBusinessDays = differenceInBusinessDays(
			new Date(),
			new Date(productUsageStartTimestamp),
		);

		return (
			!isClosed &&
			diffInBusinessDays >= CREATE_DELIVERY_TICKET_SPOTLIGHT_DELAY &&
			isCreateIdeaSpotlightClosed &&
			isCreateInsightSpotlightClosed
		);
	},
);

const JOIN_COMMUNITY_FLAG_DELAY = 9; // 9 business days
export const getIsJoinCommunityFlagShown = createSelector(
	createIsSpotlightClosedOrIgnored(SpotlightTypes.JOIN_COMMUNITY),
	getProductUsageStartTimestamp,
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_IDEA),
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_INSIGHT),
	createIsSpotlightClosedOrIgnored(SpotlightTypes.CREATE_DELIVERY_TICKET),
	(
		isClosed,
		productUsageStartTimestamp,
		isCreateIdeaSpotlightClosed,
		isCreateInsightSpotlightClosed,
		isCreateDeliveryTicketSpotlightClosed,
	) => {
		if (!productUsageStartTimestamp) {
			return false;
		}

		const diffInBusinessDays = differenceInBusinessDays(
			new Date(),
			new Date(productUsageStartTimestamp),
		);

		return (
			!isClosed &&
			diffInBusinessDays >= JOIN_COMMUNITY_FLAG_DELAY &&
			isCreateIdeaSpotlightClosed &&
			isCreateInsightSpotlightClosed &&
			isCreateDeliveryTicketSpotlightClosed
		);
	},
);
