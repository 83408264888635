import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { createPlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/create/index.tsx';
import { deletePlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/delete/index.tsx';
import {
	getIssueContributions,
	getPlayContribution,
} from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/fetch/index.tsx';
import { updatePlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/update/index.tsx';
import { notImplementedError } from '../../common/utils';
import { transformPlayContribution } from '../../services/polaris-api/play-contribution/utils';
import type { PlayContributionRemote } from './types';

export const createNotImplementedPlayContributionRemote = (): PlayContributionRemote => ({
	createPlayContribution: notImplementedError('createPlayContribution'),
	deletePlayContribution: notImplementedError('deletePlayContribution'),
	getIssueContributions: notImplementedError('getIssueContributions'),
	getPlayContribution: notImplementedError('getPlayContribution'),
	updatePlayContribution: notImplementedError('updatePlayContribution'),
});

export const createPolarisApiPlayContributionRemote = (
	apolloClient: PolarisApolloClient,
	projectAri: string,
): PlayContributionRemote => ({
	createPlayContribution: (input) =>
		createPlayContribution(apolloClient, input).then((response) =>
			transformPlayContribution(response),
		),
	deletePlayContribution: (id) => deletePlayContribution(apolloClient, id),
	getIssueContributions: (playId, subjectAri) =>
		getIssueContributions(apolloClient, projectAri, playId, subjectAri).then(
			(response) => response,
		),
	getPlayContribution: (playId, contribId) =>
		getPlayContribution(apolloClient, projectAri, playId, contribId).then((response) =>
			transformPlayContribution(response),
		),
	updatePlayContribution: (id, input) =>
		updatePlayContribution(apolloClient, id, input).then((response) =>
			transformPlayContribution(response),
		),
});
