import React from 'react';
import { styled } from '@compiled/react';

type Props = {
	text: string | undefined;
};

export const AxisLabel = ({ text }: Props) => <AxisLabelWrapper>{text}</AxisLabelWrapper>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisLabelWrapper = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
