import { useContext, createContext } from 'react';

export interface HiddenCountComponentProps {
	count: number;
}

interface Context {
	HiddenCountComponent?: React.ComponentType<HiddenCountComponentProps>;
}

export const ObservableValuesListContext = createContext<Context>({});

export const useObservableValuesListContext = () => useContext(ObservableValuesListContext);
