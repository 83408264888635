import type {
	AttachmentPickerState,
	Attachment,
} from '@atlassian/jira-issue-view-common-types/src/attachment-type';
import {
	type AttachmentPanelAction,
	ATTACHMENT_PANEL_DRAG_ENTER,
	ATTACHMENT_PANEL_DRAG_LEAVE,
	ATTACHMENT_PANEL_DROP,
} from '../actions/attachment-panel-actions';
import {
	type AttachmentPickerAction,
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_BEGIN,
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_END,
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR,
	ATTACHMENT_JIRA_CREATE_SUCCESS,
	ATTACHMENT_JIRA_CREATE_FAILURE,
} from '../actions/attachment-picker-actions';
import {
	type AttachmentPickerPopupAction,
	ATTACHMENT_PICKER_POPUP_OPEN_SUCCESS,
	ATTACHMENT_PICKER_POPUP_CLOSE_SUCCESS,
} from '../actions/attachment-popup-actions';

type AttachmentAction =
	| AttachmentPickerAction
	| AttachmentPanelAction
	| AttachmentPickerPopupAction;

const initialState: AttachmentPickerState = {
	isPopupOpen: false,
	attachments: {},
	placeholderAttachmentCount: 0,
};

const isValidAttachment = (attachment: Attachment) =>
	Boolean(attachment?.id && attachment?.mediaApiFileId);

const removeAttachmentFromStore = (state: AttachmentPickerState, attachment: Attachment) => {
	if (!isValidAttachment(attachment)) {
		return state;
	}

	const { id, mediaApiFileId } = attachment;
	const identifiersToRemove = [id, mediaApiFileId];
	const attachments = Object.keys(state.attachments)
		.filter((key) => !identifiersToRemove.includes(key)) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Record<string, any>>(
			(obj, key) => ({
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...obj,
				[key]: state.attachments[key],
			}),
			{},
		);

	return {
		...state,
		attachments,
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AttachmentPickerState = initialState, action: AttachmentAction) => {
	switch (action.type) {
		case ATTACHMENT_MEDIA_SERVICES_UPLOAD_BEGIN: {
			const attachment: Attachment = action.payload;
			return {
				...state,
				attachments: {
					...state.attachments,
					[attachment.id]: attachment,
				},
			};
		}

		case ATTACHMENT_MEDIA_SERVICES_UPLOAD_END:
			return state;

		case ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR: {
			const { fileId } = action.payload;
			if (fileId === null || fileId === undefined) {
				return state;
			}
			const attachments = Object.keys(state.attachments)
				.filter((key) => key !== fileId) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.reduce<Record<string, any>>(
					(obj, key) => ({
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...obj,
						attachments: {
							...obj.attachments,
							[key]: state.attachments[key],
						},
					}),
					{},
				);

			return {
				...state,
				attachments,
			};
		}

		// Since we are doing the API call when it's a success,
		// remove the old id is required to avoid the same id twice in the page
		case ATTACHMENT_JIRA_CREATE_SUCCESS: {
			return removeAttachmentFromStore(state, action.payload);
		}

		// When it's an error, we should remove the pending state removing the id from the list added in our redux store
		case ATTACHMENT_JIRA_CREATE_FAILURE: {
			return removeAttachmentFromStore(state, action.payload.attachment);
		}

		case ATTACHMENT_PICKER_POPUP_OPEN_SUCCESS:
			return {
				...state,
				isPopupOpen: true,
			};

		case ATTACHMENT_PICKER_POPUP_CLOSE_SUCCESS:
			return {
				...state,
				isPopupOpen: false,
			};

		case ATTACHMENT_PANEL_DRAG_ENTER:
			return {
				...state,
				placeholderAttachmentCount: action.payload,
			};

		case ATTACHMENT_PANEL_DRAG_LEAVE:
		case ATTACHMENT_PANEL_DROP:
			return {
				...state,
				placeholderAttachmentCount: 0,
			};

		default:
			return state;
	}
};
