import React from 'react';
import { AVG_ROLLUP } from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import { useGetNumberFieldsRollup } from '../../../../controllers/issue/selectors/fields-rollup-hooks';
import { FieldGroupedAvg } from '../../common/avg';
import type { Props } from '../../types';

export const NumberFieldGroupedAvg = ({ fieldKey, groupId, groupFieldKey }: Props) => {
	const rollup = useGetNumberFieldsRollup(fieldKey, groupFieldKey, groupId);
	const val = rollup[AVG_ROLLUP];

	if (val === undefined) {
		return null;
	}

	return <FieldGroupedAvg val={val} />;
};
