import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import messages from './messages';

export const useDependencyAddedNotification = () => {
	const { formatMessage } = useIntl();
	const { success } = useNotifications();
	return useCallback(() => {
		success({
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
		});
	}, [formatMessage, success]);
};
