import React, { useState, useCallback, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { isValid, lastDayOfMonth, parseISO } from 'date-fns';
import AkCalendar from '@atlaskit/calendar';
import type { CalendarProps } from '../../../../common/types/date-picker';

const getDateObj = (date: Date) => ({
	day: date.getDate(),
	month: date.getMonth() + 1,
	year: date.getFullYear(),
});

const getValidDate = (iso?: string) => {
	if (!iso) {
		return {};
	}

	const date: Date = parseISO(iso);

	return isValid(date) ? getDateObj(date) : {};
};

export const padToTwo = (num: number) => (num <= 99 ? `0${num}`.slice(-2) : `${num}`);

export const DayPicker = memo(
	(props: CalendarProps) => {
		const currentDate = props.date;
		const [viewDate, setViewDate] = useState(currentDate);

		const onSelect = useCallback(
			(iso: string) => {
				setViewDate(iso);
				const newDateObj = {
					start: iso,
					end: iso,
				};
				props.onSelect(newDateObj);
			},
			[props],
		);

		const onChange = useCallback((iso?: string) => {
			if (!iso) {
				return;
			}

			const [year, month, date] = iso.split('-');
			let newIso = iso;

			const parsedDate: number = parseInt(date, 10);
			const parsedMonth: number = parseInt(month, 10);
			const parsedYear: number = parseInt(year, 10);
			const lastDayInMonth: number = lastDayOfMonth(
				new Date(
					parsedYear,
					parsedMonth - 1, // This needs to be -1, because the Date constructor expects an index of the given month
				),
			).getDate();

			if (lastDayInMonth < parsedDate) {
				newIso = `${year}-${padToTwo(parsedMonth)}-${padToTwo(lastDayInMonth)}`;
			} else {
				newIso = `${year}-${padToTwo(parsedMonth)}-${padToTwo(parsedDate)}`;
			}
			setViewDate(newIso);
		}, []);

		return (
			<AkCalendar
				{...getValidDate(currentDate)}
				{...getValidDate(viewDate)}
				selected={[currentDate || '']}
				onChange={(e) => onChange(e.iso)}
				onSelect={(e) => onSelect(e.iso)}
				testId={props.testId && `${props.testId}-ak--calander`}
			/>
		);
	},
	(prevProps: CalendarProps, nextProps: CalendarProps) => isEqual(prevProps.date, nextProps.date),
);
