import join from 'lodash/join';
import reduce from 'lodash/reduce';
import type { PolarisQuery } from '../types';

export const createQueryStringPostfix = (
	query: PolarisQuery,
	keysToDrop?: (keyof PolarisQuery)[],
): string => {
	// @ts-expect-error - TS2769 - No overload matches this call.
	const paramsList: string[] = reduce(
		query,
		(agg, val, key: keyof PolarisQuery) => {
			if (val === undefined || (keysToDrop && keysToDrop.includes(key))) {
				return agg;
			}
			return [...agg, `${key}=${val}`] as const;
		},
		[],
	);

	if (paramsList.length === 0) {
		return '';
	}

	const flatGetParams = join(paramsList, '&');

	return `?${flatGetParams}`;
};
