import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import {
	isNotFoundError,
	isPermissionError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types';

export const loadStatuses =
	(): Action<State, Props> =>
	async (
		{ getState, setState },
		{
			projectId,
			ideaTypes,
			createAnalyticsEvent,
			onWorkflowLoadingFailed,
			issuesRemote,
			isProjectLoading,
		},
	) => {
		if (
			projectId !== undefined &&
			ideaTypes !== undefined &&
			issuesRemote.fetchTransitionsForIssueType !== undefined &&
			!isProjectLoading
		) {
			const ideaTypeIds = ideaTypes.map(({ jiraIssueTypeId }) => jiraIssueTypeId) || [];

			const statusesPromise = issuesRemote
				.fetchStatuses()
				.then((statusesResponse) => {
					const statusesResponseById = keyBy(statusesResponse, ({ id }) => id);

					setState({
						statuses: pick(statusesResponseById, ideaTypeIds),
						meta: {
							...getState().meta,
							initialized: true,
						},
					});
				})
				.catch((error) => {
					fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadStatuses failure');
					onWorkflowLoadingFailed(error);
				});

			const transitionPromises = ideaTypeIds.map((issueTypeId) =>
				issuesRemote.fetchTransitionsForIssueType?.({ issueTypeId }).then(({ transitions }) => ({
					[`${issueTypeId}`]: transitions,
				})),
			);

			await Promise.all([statusesPromise, ...transitionPromises])
				.then(([_, ...transitionsByIdeaType]) => {
					setState({
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						transitions: transitionsByIdeaType.reduce<Record<string, any>>(
							(agg, transition) => ({
								// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
								...agg,
								...transition,
							}),
							{},
						),
					});
				})
				.catch((error) => {
					fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadWorkflow failure');
					onWorkflowLoadingFailed(error);
				});
		}
	};

export const loadStatusesForSharedView =
	(): Action<State, Props> =>
	async (
		{ getState, setState },
		{ createAnalyticsEvent, onWorkflowLoadingFailed, issuesRemote },
	) => {
		try {
			const statusesResponse = await issuesRemote.fetchStatuses();

			setState({
				statuses: keyBy(statusesResponse, ({ id }) => id),
				meta: {
					...getState().meta,
					initialized: true,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadStatuses failure');
			if (isNotFoundError(error) || isPermissionError(error)) {
				return;
			}
			onWorkflowLoadingFailed(error);
		}
	};
