import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotlightTitle: {
		id: 'polaris-lib-control-sharing.controllers.view-emoji-spotlight-provider.spotlight-title',
		defaultMessage: 'Heads up',
		description:
			'Title of the spotlight appearing when we automatically change the view emoji in case when the view becomes publicly shared',
	},
	spotlightMessageForPublishing: {
		id: 'polaris-lib-control-sharing.controllers.view-emoji-spotlight-provider.spotlight-message-for-publishing',
		defaultMessage:
			'This view can now be accessed by anyone with the link. We changed the emoji to help you find this view in the navigation.',
		description:
			'Content of the spotlight appearing when we automatically change the view emoji in case when the view becomes publicly shared',
	},
	spotlightMessageForUnpublishing: {
		id: 'polaris-lib-control-sharing.controllers.view-emoji-spotlight-provider.spotlight-message-for-unpublishing',
		defaultMessage:
			'This view can no longer be accessed by anyone with the link. We changed the emoji back to the default one.',
		description:
			'Content of the spotlight appearing when we automatically change the view emoji in case when the view stops being publicly shared',
	},
	spotlightAcknowledgement: {
		id: 'polaris-lib-control-sharing.controllers.view-emoji-spotlight-provider.spotlight-acknowledgement',
		defaultMessage: 'Ok, got it',
		description: 'Label on the "ok" button in the emoji replacement spotlight',
	},
});
