import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noMatches: {
		id: 'polaris-common.field-config.list.no-matches',
		defaultMessage: 'No matches found',
		description: 'Placeholder text if no fields are found matching the search criteria',
	},
	allFieldsInUse: {
		id: 'polaris-common.field-config.list.all-fields-in-use',
		defaultMessage: 'All available fields are in use',
		description: 'Placeholder text when all fields are selected and no fields remain',
	},
	removeFieldHeader: {
		id: 'polaris-common.field-config.list.remove-field-header',
		defaultMessage: 'Drop here to remove a field from a view',
		description: 'Help text for field list removal area',
	},
});
