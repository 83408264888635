import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unknownOption: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.options.renderers.default.unknown-option',
		defaultMessage: 'Unknown',
		description: 'Unknown filter value option. Can happen if a value is no longer present in Jira',
	},
	noValueOption: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.options.renderers.default.no-value-option',
		defaultMessage: 'No Value',
		description: 'Special option to filter for ideas that have no value set for a particular field',
	},
});
