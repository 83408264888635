import React from 'react';
import { styled } from '@compiled/react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsArchivingEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useIsSelectedIssueArchived,
	useSelectedIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useCanArchiveIdeas } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import messages from './messages';

export const ArchivedNotification = ({ isCompact = false }) => {
	const isArchived = useIsSelectedIssueArchived();
	const localIssueId = useSelectedIssue();
	const { unarchiveIssues } = useIssueActions();
	const { formatMessage } = useIntl();
	const [isArchivingEnabled] = useIsArchivingEnabled();
	const canArchiveIdeas = useCanArchiveIdeas();

	const actions = canArchiveIdeas
		? [
				{
					key: '1',
					text: formatMessage(messages.restoreButton),
					onClick: () => {
						if (localIssueId === undefined) {
							return;
						}
						unarchiveIssues([localIssueId]);
					},
				},
			]
		: [];

	return isArchived && isArchivingEnabled ? (
		<Container isCompact={isCompact}>
			<SectionMessage
				testId="polaris-ideas.ui.idea-view.archived.section-message"
				appearance="information"
				actions={actions.map(({ text, key, ...restAction }) => (
					<SectionMessageAction
						testId="polaris-ideas.ui.idea-view.archived.unarchive"
						key={key}
						{...restAction}
					>
						{text}
					</SectionMessageAction>
				))}
			>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>{formatMessage(messages.title)}</p>
			</SectionMessage>
		</Container>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isCompact: boolean }>({
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingBottom: ({ isCompact }) =>
		isCompact ? token('space.200', '16px') : token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
});
