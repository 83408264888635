import React, { useEffect } from 'react';
import { useCollectionJql } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useActions } from './controllers';

export {
	useProjectMetadata,
	useProjectsKeysForContainer,
	useProjectMetadataByKey,
	useProjectsMetadataForContainer,
	useProjectsMetadataHasError,
	useProjectsMetadataLoading,
	useProjectsCountForContainer,
} from './controllers';
export { useActions as useProjectMetadataActions };

type ProjectMetadataProviderProps = {
	metadata: ProjectMetadata | undefined;
};

/**
 * registers project metadata of a project that is already available in the context
 * @param props the project metadata, or undefined if the project is not available; will only trigger if all properties are supplied
 */
export const ProjectMetadataProvider = ({ metadata }: ProjectMetadataProviderProps) => {
	const { addProjectsMetadata } = useActions();

	useEffect(() => {
		if (metadata !== undefined) {
			addProjectsMetadata(metadata.id, [metadata]);
		}
	}, [metadata, addProjectsMetadata]);

	return null;
};

const ProjectContainerMetadataProvider = ({ projectId }: { projectId: ProjectId }) => {
	const { fetchProjectMetadata } = useActions();
	const { generalDataLoadingFailedError } = useErrorHandlers();

	useEffect(() => {
		fetchProjectMetadata(projectId).catch(generalDataLoadingFailedError);
	}, [projectId, fetchProjectMetadata, generalDataLoadingFailedError]);

	return null;
};

const CollectionContainerMetadataProvider = ({ collectionId }: { collectionId: string }) => {
	const collectionJql = useCollectionJql({ collectionUUID: collectionId });
	const { fetchCollectionMetadata } = useActions();
	const { generalDataLoadingFailedError } = useErrorHandlers();

	useEffect(() => {
		fetchCollectionMetadata(collectionId, collectionJql).catch(generalDataLoadingFailedError);
	}, [collectionJql, fetchCollectionMetadata, collectionId, generalDataLoadingFailedError]);

	return null;
};

/**
 * loads project metadata from the backend for whatever environment container is currently in the context.
 * see @atlassian/jira-polaris-component-environment-container for more information
 * @constructor
 */
export const ContainerProjectMetadataProvider = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return <ProjectContainerMetadataProvider projectId={container.projectId} />;
	}
	if (container?.type === PolarisEnvironmentContainerTypes.COLLECTION) {
		return <CollectionContainerMetadataProvider collectionId={container.collectionId} />;
	}

	return null;
};
