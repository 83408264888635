import React, {
	type SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type ComponentPropsWithoutRef,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { Box, Flex } from '@atlaskit/primitives';
import { ConnectedReactionsView } from '@atlaskit/reactions';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { getEmojiProviderWithCustomEmojiUploadDisabled } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { ff, getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { onLinkClick, smartLinksDefault } from '@atlassian/jira-linking-platform-utils';
import { getAriConfig } from '@atlassian/jira-platform-ari';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant';
import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment';
import { isEmpty } from '@atlassian/jira-polaris-lib-adf-utils/src/utils/index.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { N200A } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { Editor, EditorBoundary } from '@atlassian/jira-polaris-lib-editor/src/async';
import { prepareForRender } from '@atlassian/jira-polaris-lib-editor/src/common/utils/adf.tsx';
import { WaitForAdfConsumerPropsForComment } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/main.tsx';
import type { AkRendererProps } from '@atlassian/jira-polaris-lib-editor/src/controllers/adf/types.tsx';
import { InlineDelete } from '@atlassian/jira-polaris-lib-inline-delete/src/ui/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { FormattedDate } from '../../../common/ui/date';
import { cloudifyViewAri } from '../../../common/utils/ari';
import { getCopyUrl, getPermalinkStatus, PermalinkType } from '../../../common/utils/permalink';
import { useIsSharedView } from '../../../controllers/environment';
import { useSelectedIssue } from '../../../controllers/issue/selectors/properties/hooks';
import { useCurrentUserAccountId } from '../../../controllers/user';
import { useHasUnsavedChanges } from '../../../controllers/views/selectors/view-hooks.tsx';
import { useReactionStore } from '../../../services/polaris-api/reactions';
import { useScrollableContainer } from '../../common/scrollable-container/main';
import { CopyLink } from '../../copy-link';
import { PolarisNavigationBlocker } from '../../navigation-blocker';
import { MAX_CONTENT_SIZE } from '../constants';
import messages from './messages';

const CommentHeaderAuthorDetails = (
	props: ComponentPropsWithoutRef<typeof CommentHeaderAuthorDetailsComponent>,
) => (
	<Heading size="xsmall">
		<CommentHeaderAuthorDetailsComponent {...props} />
	</Heading>
);

const getTTRSeverityTrackingConfiguration = () =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getFeatureFlagValue<any>('issue.details.renderer.ttr-severity-tracking', {});

const getUnsupportedContentLevelsTrackingConfiguration = () =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getFeatureFlagValue<any>('issue.details.renderer.unsupported-content-levels-tracking', {});

const SEPARATOR = '・';

type Props = {
	comment: PolarisComment;
	reactionContainerId: string | undefined; // the container to tell Reactions service that reactions are grouped inside,
	reactionCommentAri: string;
	canAdd: boolean;
	canEditOwn: boolean;
	canEditAll: boolean;
	onDeleteRequested: (arg1: string) => void;
	onCommentUpdateRequested: (arg1: string, arg2: ADF) => Promise<void>;
	onDirty?: (_: boolean) => void;
	onError?: (error: Error) => void;
};

type DeleteActionProps = {
	commentId: string;
	onDeleteRequested: (arg1: string) => void;
};

const DeleteAction = ({ commentId, onDeleteRequested }: DeleteActionProps) => {
	const { formatMessage } = useIntl();

	const performDelete = useCallback(() => {
		onDeleteRequested(commentId);
	}, [commentId, onDeleteRequested]);

	return (
		<InlineDelete
			title={formatMessage(messages.deleteCommentWarningHeader)}
			onSubmit={performDelete}
		>
			{({ onOpen }) => (
				<Button
					testId="polaris-common.ui.comments.comment.delete"
					spacing="none"
					appearance="subtle-link"
					onClick={(_, analyticsEvent) => {
						onOpen();
						fireUIAnalytics(analyticsEvent, 'deleteComment');
					}}
				>
					{formatMessage(messages.deleteComment)}
				</Button>
			)}
		</InlineDelete>
	);
};

type CommentReadViewProps = AkRendererProps & {
	value: ADF;
	onError?: (error: Error) => void;
};

const CommentReadView = ({ value, onError, ...akRendererProps }: CommentReadViewProps) => (
	<AkRenderer
		{...akRendererProps}
		appearance="comment"
		document={value}
		analyticsEventSeverityTracking={getTTRSeverityTrackingConfiguration()}
		unsupportedContentLevelsTracking={getUnsupportedContentLevelsTrackingConfiguration()}
		useSpecBasedValidator={ff('issue.details.renderer.spec-based-validator')}
		disableHeadingIDs
		allowAltTextOnImages
		eventHandlers={{
			smartCard: { onClick: onLinkClick },
			link: { onClick: onLinkClick },
		}}
		featureFlags={{
			codeBidiWarnings: true,
			'code-bidi-warnings': true,
			'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
		}}
		onError={onError}
		smartLinks={smartLinksDefault}
	/>
);

export const CommentComponent = ({
	comment,
	reactionContainerId,
	reactionCommentAri,
	onDirty,
	onDeleteRequested,
	onCommentUpdateRequested,
	onError,
	canAdd,
	canEditOwn,
	canEditAll,
}: Props) => {
	const { formatMessage } = useIntl();
	const [inEditMode, setEditMode] = useState(false);
	const currentUser = useCurrentUserAccountId();
	const cloudId = useCloudId();
	const reactionsStore = useReactionStore();
	const selectedIssue = useSelectedIssue();
	const scrollCommentRef = useRef<HTMLDivElement | null>(null);
	const hasUnsavedChanges = useHasUnsavedChanges();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSharedView = useIsSharedView();
	const isEditorAiEnabled = useEditorAiEnabled();
	const scrollableContainer = useScrollableContainer();

	const commentType = useMemo(
		() => (selectedIssue ? PermalinkType.ISSUE_COMMENTS : PermalinkType.VIEW_COMMENTS),
		[selectedIssue],
	);

	const commentResourceId = useMemo(() => {
		if (selectedIssue || isSharedView) return comment.id;
		try {
			return getAriConfig(comment.id).resourceId;
		} catch (e) {
			return '';
		}
	}, [comment.id, isSharedView, selectedIssue]);

	const { hasPermalink, permalinkId } = getPermalinkStatus(commentType, window.location.search);

	const reactionsEmojiProvider = useMemo(
		() => getEmojiProviderWithCustomEmojiUploadDisabled(cloudId, currentUser),
		[cloudId, currentUser],
	);

	useEffect(() => {
		if (
			scrollCommentRef &&
			scrollCommentRef.current &&
			hasPermalink &&
			commentResourceId === permalinkId
		) {
			scrollCommentRef.current.scrollIntoView(true);
		}
	}, [commentResourceId, hasPermalink, permalinkId]);

	const doUpdate = useCallback(
		(value: ADF) => {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'button',
				}),
				'saveComment',
			);

			if (!isEmpty(value)) {
				onCommentUpdateRequested(comment.id, value).then(() => {
					setEditMode(false);
				});
			} else {
				setEditMode(false);
			}
			onDirty?.(false);
		},
		[comment.id, onCommentUpdateRequested, onDirty, createAnalyticsEvent],
	);

	const safeCommentBody = useMemo(() => prepareForRender(comment.body), [comment.body]);

	const cancelEditing = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'discardComment',
		);
		setEditMode(false);
		onDirty?.(false);
	}, [onDirty, createAnalyticsEvent]);

	const onNavigationAllowed = () => {
		onDirty?.(false);
	};

	const copyCommentUrl = useMemo(
		() => getCopyUrl(commentType, commentResourceId, window.location.search),
		[commentResourceId, commentType],
	);

	const onCopyLink = () => {
		selectedIssue
			? fireCompoundAnalyticsEvent.IdeaView.copyIdeaCommentLinkButtonClicked(
					createAnalyticsEvent({}),
				)
			: fireCompoundAnalyticsEvent.View.copyViewCommentLinkButtonClicked(createAnalyticsEvent({}));
	};

	return (
		<CommentContainer
			ref={scrollCommentRef}
			data-testid="polaris-common.ui.comments.comment.comment-container"
			data-component-selector="comment-container-65Cu"
			isHighlighted={hasPermalink && commentResourceId === permalinkId && !inEditMode}
		>
			<PolarisNavigationBlocker
				isDirty={inEditMode && hasUnsavedChanges}
				onDiscard={onNavigationAllowed}
			/>
			<CommentHeaderContainer data-testid="polaris-common.ui.comments.comment.comment-header-container">
				<Box>
					<Avatar src={comment.author.avatarUrls['32x32']} size="medium" />
				</Box>
				<CommentHeaderDetailsContainer>
					<Flex alignItems="center">
						<CommentHeaderAuthorDetails>{comment.author.displayName}</CommentHeaderAuthorDetails>
						<CopyLinkIconWrapper>
							<CopyLink
								size="small"
								appearance="subtle-link"
								onCopy={onCopyLink}
								url={copyCommentUrl}
								label={formatMessage(messages.copy)}
							/>
						</CopyLinkIconWrapper>
					</Flex>
					<CommentHeaderDetails>
						<Box>
							<FormattedDate date={comment.created} />
						</Box>
						{comment.updated !== comment.created && (
							<Box>
								{SEPARATOR}
								{formatMessage(messages.commentEdited)}
							</Box>
						)}
					</CommentHeaderDetails>
				</CommentHeaderDetailsContainer>
			</CommentHeaderContainer>
			<CommentContentContainer>
				<WaitForAdfConsumerPropsForComment commentId={comment.id}>
					{({ akRendererProps, akEditorProps }) => (
						<>
							{!inEditMode && (
								<CommentReadView value={safeCommentBody} {...akRendererProps} onError={onError} />
							)}
							{inEditMode && (
								<EditorBoundary
									fallback={
										<CommentReadView
											value={safeCommentBody}
											{...akRendererProps}
											onError={onError}
										/>
									}
								>
									<Editor
										{...akEditorProps}
										defaultValue={safeCommentBody}
										minHeight={128}
										maxContentSize={MAX_CONTENT_SIZE}
										shouldFocus
										onChange={() => onDirty?.(true)}
										onSave={doUpdate}
										onCancel={cancelEditing}
										popupsBoundariesElement={scrollableContainer || undefined}
										popupsMountPoint={scrollableContainer ? undefined : document.body}
										withDebouncedOnChange={false}
										allowFullEditorCapabilities
										isAiEnabled={isEditorAiEnabled}
										useStickyToolbar
									/>
								</EditorBoundary>
							)}
						</>
					)}
				</WaitForAdfConsumerPropsForComment>
			</CommentContentContainer>
			{!inEditMode && (
				<CommentFooterContainer>
					{((canEditOwn && currentUser === comment.author.accountId) || canEditAll) && (
						<ControlsContainer>
							<Box>
								<Button
									testId="polaris-common.ui.comments.comment.edit"
									spacing="none"
									appearance="subtle-link"
									onClick={(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
										fireUIAnalytics(analyticsEvent, 'editComment');
										setEditMode(true);
									}}
								>
									{formatMessage(messages.editComment)}
								</Button>
							</Box>
							{SEPARATOR}
							<Box>
								<DeleteAction commentId={comment.id} onDeleteRequested={onDeleteRequested} />
							</Box>
							{SEPARATOR}
						</ControlsContainer>
					)}
					{canAdd && reactionsEmojiProvider && reactionContainerId !== undefined && (
						<ConnectedReactionsView
							ari={reactionCommentAri}
							containerAri={cloudifyViewAri(cloudId, reactionContainerId)}
							store={reactionsStore}
							emojiProvider={reactionsEmojiProvider}
							allowAllEmojis
						/>
					)}
				</CommentFooterContainer>
			)}
		</CommentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentContainer = styled.div<{ isHighlighted: boolean }>({
	padding: `${token('space.100', '8px')} 0`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isHighlighted }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isHighlighted ? token('color.background.neutral', N200A) : undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentHeaderContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentHeaderDetailsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentHeaderDetails = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '3px',
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N200,
	marginBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentContentContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '42px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentFooterContainer = styled.div({
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '42px',
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N200,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ControlsContainer = styled.div({
	marginRight: 0,
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CopyLinkIconWrapper = styled.div({
	alignSelf: 'flex-start',
	visibility: 'hidden',
	cursor: 'pointer',
	opacity: 0,
	paddingLeft: 0,
	transition: 'opacity 150ms, visibility 300ms, padding-left 300ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"[data-component-selector='comment-container-65Cu']:hover &": {
		visibility: 'visible',
		opacity: 1,
		paddingLeft: token('space.150', '12px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='presentation']": {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentHeaderAuthorDetailsComponent = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '16px',
	marginTop: '0px',
});
