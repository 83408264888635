import React, {
	memo,
	useMemo,
	useEffect,
	useRef,
	useState,
	useCallback,
	Children,
	type RefObject,
} from 'react';
import { getValidChildren } from '../../../common/utils/react/children';
import { MoreTag } from './more-tag';
import { Observer } from './observer';
import type { ObservableValuesListProps } from './types';

const getDimensions = (ref?: RefObject<HTMLDivElement | null>) => ({
	width: ref?.current?.clientWidth ?? 0,
	height: ref?.current?.clientHeight ?? 0,
});

export const ObservableValuesList = memo(
	({
		containerRef,
		listRef,
		isActive,
		children,
		previewChildren,
		hiddenCountExternalRef,
		setHiddenCountExternalRefValue,
		MoreTagWrapper,
	}: ObservableValuesListProps) => {
		const [hiddenCount, setHiddenCount] = useState(hiddenCountExternalRef?.current || 0);
		const [observer, setObserver] = useState<IntersectionObserver>();
		const hiddenCountRef = useRef<number>(hiddenCountExternalRef?.current || -1);
		const childrenCountRef = useRef<number>(0);
		const observerRef = useRef<IntersectionObserver>();

		const containerDimensions = getDimensions(containerRef);
		const listDimensions = getDimensions(listRef);
		const isListOverflown =
			!listRef ||
			listDimensions.width > containerDimensions.width ||
			listDimensions.height > containerDimensions.height;

		const intersectionCallback = useCallback(
			(entries: IntersectionObserverEntry[]) => {
				// the initial render returns entries for all children, then we receive delta, e.g. only 1 entry if resizing slowly
				// in cases where the container is being re-initiated, num of children is the same as entries.length
				const isInitialRender = hiddenCountExternalRef
					? entries.length === Children.count(children)
					: hiddenCountRef.current === -1;
				// On initial render only hidden options are counted
				if (isInitialRender) {
					hiddenCountRef.current = entries.filter(
						({ isIntersecting, boundingClientRect }) =>
							!!boundingClientRect.height && !isIntersecting,
					).length;
				} else {
					hiddenCountRef.current += entries.reduce(
						(result, { isIntersecting }) => result + (isIntersecting ? -1 : 1),
						0,
					);
				}
				setHiddenCount(hiddenCountRef.current);
				setHiddenCountExternalRefValue?.(hiddenCountRef.current);
			},
			[hiddenCountExternalRef, setHiddenCountExternalRefValue, children],
		);
		const validChildren = useMemo(() => getValidChildren(children), [children]);

		const createIntersectionObserver = useCallback(
			(rootNode: HTMLElement) => {
				// additional 56px for right margin to include 'more' tag width
				const observerParams = { root: rootNode, rootMargin: '0px -56px -4px 0px' };
				const newObserver = new IntersectionObserver(intersectionCallback, observerParams);

				return newObserver;
			},
			[intersectionCallback],
		);

		// recreate intersection observer when it exists and the amount of children changes, skips initial render
		useEffect(() => {
			const childrenCount = Children.count(validChildren);
			if (childrenCountRef.current !== childrenCount) {
				childrenCountRef.current = childrenCount;

				if (observerRef.current && containerRef?.current && hiddenCountRef.current !== -1) {
					const rootNode = containerRef.current;

					observerRef.current.disconnect();

					hiddenCountRef.current = -1;

					const newObserver = createIntersectionObserver(rootNode);
					observerRef.current = newObserver;

					setObserver(newObserver);
				}
			}
		}, [validChildren, containerRef, createIntersectionObserver]);

		useEffect(() => {
			const definedObserver = observerRef.current;
			const rootNode = containerRef?.current;
			const hasIOSupport = !!window.IntersectionObserver;

			if (!hasIOSupport || !rootNode || definedObserver) return;
			const newObserver = createIntersectionObserver(rootNode);
			observerRef.current = newObserver;

			setObserver(newObserver);
		}, [containerRef, observerRef, createIntersectionObserver]);

		useEffect(
			() => () => {
				observerRef.current?.disconnect();
				setHiddenCountExternalRefValue?.(0);
			},
			[setHiddenCountExternalRefValue],
		);

		const moreTag = (
			<MoreTag count={hiddenCount} showTooltip={isActive}>
				{previewChildren || validChildren}
			</MoreTag>
		);

		return (
			<>
				{Array.isArray(validChildren)
					? validChildren.map((child) => (
							<Observer key={child.key} observer={observer}>
								{child}
							</Observer>
						))
					: null}
				{hiddenCount > 0 &&
					// as we use additional margin for intersection observer sometimes it returns isIntersecting=true for the last element
					// when it's actually visible so we need to check if the list is overflown to cover this case
					isListOverflown &&
					(MoreTagWrapper ? <MoreTagWrapper>{moreTag}</MoreTagWrapper> : moreTag)}
			</>
		);
	},
);
