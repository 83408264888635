import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State, FieldSidebarMode } from '../../types';

export const setFieldSidebarOptionId =
	(optionId?: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			fieldsSidebar: {
				...getState().fieldsSidebar,
				optionId,
			},
		});
	};

export const setFieldSidebarFieldKey =
	(fieldKey: FieldKey): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			fieldsSidebar: {
				...getState().fieldsSidebar,
				fieldKey,
			},
		});
	};

export const setFieldSidebarMode =
	(mode: FieldSidebarMode): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			fieldsSidebar: {
				...getState().fieldsSidebar,
				mode,
			},
		});
	};

export const resetFieldSidebarMode =
	(): Action<State, Props> =>
	({ setState }) => {
		setState({
			fieldsSidebar: {
				mode: 'VALUES',
				fieldKey: undefined,
				optionId: undefined,
			},
		});
	};

export const setFieldSidebar =
	(fieldKey: FieldKey, optionId?: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			fieldsSidebar: {
				...getState().fieldsSidebar,
				mode: 'FIELD',
				fieldKey,
				optionId,
			},
		});
	};
