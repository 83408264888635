export const jiraStatusesMock = [
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10001',
		id: '10001',
		name: 'Task',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10002',
		id: '10002',
		name: 'Epic',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10003',
		id: '10003',
		name: 'Subtask',
		subtask: true,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10004',
		id: '10004',
		name: 'Idea',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10005',
		id: '10005',
		name: 'Bug',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10006',
		id: '10006',
		name: 'Room',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10007',
		id: '10007',
		name: '$10 Voting Play',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
	{
		self: 'https://polaris-v0.jira-dev.com/rest/api/3/issuetype/10051',
		id: '10051',
		name: 'testissuetype',
		subtask: false,
		statuses: [
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10002',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'Готово',
				untranslatedName: 'Done',
				id: '10002',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/3',
					id: 3,
					key: 'done',
					colorName: 'green',
					name: 'Готово',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10001',
				description: 'В данный момент по этой задаче ведется активная работа назначенным лицом.',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'В работе',
				untranslatedName: 'In Progress',
				id: '10001',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/4',
					id: 4,
					key: 'indeterminate',
					colorName: 'yellow',
					name: 'В работе',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
			{
				self: 'https://polaris-v0.jira-dev.com/rest/api/3/status/10000',
				description: '',
				iconUrl: 'https://polaris-v0.jira-dev.com/',
				name: 'К выполнению',
				untranslatedName: 'To Do',
				id: '10000',
				statusCategory: {
					self: 'https://polaris-v0.jira-dev.com/rest/api/3/statuscategory/2',
					id: 2,
					key: 'new',
					colorName: 'blue-gray',
					name: 'К выполнению',
				},
				scope: { type: 'PROJECT', project: { id: '10000' } },
			},
		],
	},
];
