import type { AxisValue, CenterPosition, ItemId } from '../../types';
import {
	createGetRelativeItemCenterPosition,
	createGetRelativeXAxisValueCenterPosition,
	createGetRelativeYAxisValueCenterPosition,
	createGetZAxisBucketedPositionForItems,
} from './positions';
import { createHigherLevelHook } from './utils';

export const useRelativeItemCenterPosition = createHigherLevelHook<CenterPosition, [ItemId]>(
	createGetRelativeItemCenterPosition,
);
export const useRelativeXAxisPositionForValue = createHigherLevelHook<
	number,
	[AxisValue | undefined]
>(createGetRelativeXAxisValueCenterPosition);
export const useRelativeYAxisPositionForValue = createHigherLevelHook<
	number,
	[AxisValue | undefined]
>(createGetRelativeYAxisValueCenterPosition);
export const useZAxisPositionForItems = createHigherLevelHook<number, [ItemId[]]>(
	createGetZAxisBucketedPositionForItems,
);
