import React from 'react';
import { N30, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const getSummaryCardLayout = (primaryColor: string) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="162" height="50" fill="none">
		<rect width="162" height="50" fill={token('color.background.neutral', N30)} rx="8" />
		<g filter="url(#a2)">
			<rect
				width="154"
				height="19"
				x="4"
				y="4"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="92" height="7" x="10" y="10" fill={primaryColor} rx="3.5" />
		</g>
		<g filter="url(#b2)">
			<rect
				width="75"
				height="19"
				x="4"
				y="27"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="62.5" height="7" x="10" y="33" fill={primaryColor} rx="3.5" />
		</g>
		<g filter="url(#c2)">
			<rect
				width="75"
				height="19"
				x="83"
				y="27"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="62.5" height="7" x="89" y="33" fill={primaryColor} rx="3.5" />
		</g>
		<defs>
			<filter
				id="a2"
				width="155"
				height="20.5"
				x="3.5"
				y="3.5"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93312" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93312" result="effect2_dropShadow_1976_93312" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93312" result="shape" />
			</filter>
			<filter
				id="b2"
				width="76"
				height="20.5"
				x="3.5"
				y="26.5"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93312" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93312" result="effect2_dropShadow_1976_93312" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93312" result="shape" />
			</filter>
			<filter
				id="c2"
				width="76"
				height="20.5"
				x="82.5"
				y="26.5"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93312" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93312" result="effect2_dropShadow_1976_93312" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93312" result="shape" />
			</filter>
		</defs>
	</svg>
);

export const getCompactCardLayout = (primaryColor: string, secondaryColor: string) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="162" height="69" fill="none">
		<rect width="162" height="69" fill={token('color.background.neutral', N30)} rx="8" />
		<g filter="url(#a)">
			<rect
				width="154"
				height="27.5"
				x="4"
				y="4"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="92" height="7" x="10" y="10" fill={primaryColor} rx="3.5" />
			<rect width="35.5" height="5.5" x="10" y="21" fill={secondaryColor} rx="2.75" />
			<rect width="35.5" height="5.5" x="50.5" y="21" fill={secondaryColor} rx="2.75" />
			<rect width="23.5" height="5.5" x="91" y="21" fill={secondaryColor} rx="2.75" />
		</g>
		<g filter="url(#b)">
			<rect
				width="75"
				height="27.5"
				x="4"
				y="37.5"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="62.5" height="7" x="10" y="43.5" fill={primaryColor} rx="3.5" />
			<rect width="35.5" height="5.5" x="10" y="54.5" fill={secondaryColor} rx="2.75" />
		</g>
		<g filter="url(#c)">
			<rect
				width="75"
				height="27.5"
				x="83"
				y="37.5"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="62.5" height="7" x="89" y="43.5" fill={primaryColor} rx="3.5" />
			<rect width="35.5" height="5.5" x="89" y="54.5" fill={secondaryColor} rx="2.75" />
		</g>
		<defs>
			<filter
				id="a"
				width="155"
				height="29"
				x="3.5"
				y="3.5"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93312" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93312" result="effect2_dropShadow_1976_93312" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93312" result="shape" />
			</filter>
			<filter
				id="b"
				width="76"
				height="29"
				x="3.5"
				y="37"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93312" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93312" result="effect2_dropShadow_1976_93312" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93312" result="shape" />
			</filter>
			<filter
				id="c"
				width="76"
				height="29"
				x="82.5"
				y="37"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93312" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93312" result="effect2_dropShadow_1976_93312" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93312" result="shape" />
			</filter>
		</defs>
	</svg>
);

export const getDetailedCardLayout = (primaryColor: string, secondaryColor: string) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="162" height="56" fill="none">
		<rect width="162" height="55.5" fill={token('color.background.neutral', N30)} rx="8" />
		<g filter="url(#a1)">
			<rect
				width="154"
				height="47.5"
				x="4"
				y="4"
				fill={token('elevation.surface.raised', N0)}
				rx="4"
			/>
			<rect width="92" height="7" x="10" y="10" fill={primaryColor} rx="3.5" />
			<rect width="35.5" height="5.5" x="10" y="21" fill={secondaryColor} rx="2.75" />
			<rect width="35.5" height="5.5" x="10" y="30.5" fill={secondaryColor} rx="2.75" />
			<rect width="23.5" height="5.5" x="10" y="40" fill={secondaryColor} rx="2.75" />
		</g>
		<defs>
			<filter
				id="a1"
				width="155"
				height="49"
				x="3.5"
				y="3.5"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0" />
				<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1976_93334" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy=".5" />
				<feGaussianBlur stdDeviation=".25" />
				<feColorMatrix values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.25 0" />
				<feBlend in2="effect1_dropShadow_1976_93334" result="effect2_dropShadow_1976_93334" />
				<feBlend in="SourceGraphic" in2="effect2_dropShadow_1976_93334" result="shape" />
			</filter>
		</defs>
	</svg>
);
