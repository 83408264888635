import React, { type SyntheticEvent, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari';
import {
	ProjectPicker,
	PROJECT_TYPES_WITHOUT_JPD,
} from '@atlassian/jira-polaris-common/src/common/ui/project-picker';
import type { SelectedProject } from '@atlassian/jira-polaris-common/src/common/ui/project-picker/option';
import { useIdeaActions } from '@atlassian/jira-polaris-common/src/controllers/idea/main.tsx';
import { useIssueLinks } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useLocalIssueIdsByJiraIssueId } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import {
	useSelectedIssue,
	useSelectedIssueKey,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useProjectActions } from '@atlassian/jira-polaris-common/src/controllers/project/main';
import type {
	GroupedOption,
	IssueOption,
} from '@atlassian/jira-polaris-common/src/ui/common/issue-select/types.tsx';
import { LinkIssueSelect } from '@atlassian/jira-polaris-common/src/ui/link-issue-select';
import { useDeliveryIssueLinkType } from '@atlassian/jira-polaris-component-environment-tenant';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { IssueLinkTypePicker } from './issue-link-type-picker';
import messages from './messages';
import type { SelectedIssueLinkType } from './types';
import { useFirstIssueLinkType } from './utils';

type Props = {
	isPolarisIssueLink: boolean;
	isCompact?: boolean;
	excludedProjectTypes: ProjectType[];
	hideArchived?: boolean;
	hideCaption?: boolean;
	defaultOptions?: GroupedOption;
	onCancel: () => void;
	onIssueLinked: () => void;
};

export const LinkIssue = (props: Props) => {
	const {
		excludedProjectTypes,
		hideArchived,
		hideCaption,
		isPolarisIssueLink,
		defaultOptions,
		onIssueLinked,
		onCancel,
		isCompact,
	} = props;
	const { formatMessage } = useIntl();
	const issueKey = useSelectedIssueKey();
	const localIssueId = useSelectedIssue();
	const { linkIssue, linkDeliveryIssue } = useIssueActions();
	const { refreshIssueLinks } = useIdeaActions();
	const { linkIssueInJira } = useIssueRemote();

	const issueLinks = useIssueLinks();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const cloudId = useCloudId();
	const { setSelectedDeliveryProject } = useProjectActions();
	const firstIssueLinkType = useFirstIssueLinkType();

	const initialIssueLinkType: SelectedIssueLinkType | undefined =
		isPolarisIssueLink && polarisDeliveryIssueLinkType !== undefined
			? { issueLinkTypeId: polarisDeliveryIssueLinkType, direction: 'outward' }
			: firstIssueLinkType;

	const [selectedIssueLinkType, setSelectedIssueLinkType] = useState<
		SelectedIssueLinkType | undefined
	>(initialIssueLinkType);
	const [progress, setProgress] = useState<'linking' | 'done' | 'failed'>();
	const [selectedProject, setSelectedProject] = useState<SelectedProject | undefined>(undefined);
	const [selectedIssue, setSelectedIssue] = useState<IssueOption>();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const idMap = useLocalIssueIdsByJiraIssueId();

	const onCancelClicked = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'cancelAddDeliveryTicket');
			onCancel();
		},
		[onCancel],
	);

	const onLinkClicked = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'confirmAddDeliveryTicket');
			const item = selectedIssue?.item;
			const isNewIssueLinked = issueLinks.some(({ outwardIssue, inwardIssue, type }) => {
				const targetIssue =
					selectedIssueLinkType?.direction === 'inward' ? outwardIssue : inwardIssue;

				return (
					type.id === selectedIssueLinkType?.issueLinkTypeId && targetIssue?.id === String(item?.id)
				);
			});

			if (isNewIssueLinked) {
				onIssueLinked();
				return;
			}

			if (
				localIssueId !== undefined &&
				issueKey !== undefined &&
				selectedIssue !== undefined &&
				selectedIssueLinkType !== undefined &&
				item !== undefined
			) {
				experience.ideaView.issueLinkCreate.start();
				setProgress('linking');
				linkIssueInJira({
					issueLinkTypeId: selectedIssueLinkType.issueLinkTypeId,
					direction: selectedIssueLinkType.direction,
					deliveryIssue: {
						key: item.key,
						id: item.id.toString(),
					},
					ideaIssue: {
						localIssueId,
						key: issueKey,
					},
					onIssueLinked: () => {
						if (isPolarisIssueLink) {
							// link issue also in the UI store
							linkDeliveryIssue(localIssueId, item.id.toString()).then(() => {
								refreshIssueLinks(() => {
									experience.ideaView.issueLinkCreate.success();
									fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), idMap[localIssueId], {
										addedDeliveryTicketId: item.id.toString(),
									});
									setProgress('done');
									onIssueLinked();
								});
							});
						} else {
							linkIssue(localIssueId, item.id.toString());
							// refresh issuelink data
							refreshIssueLinks(() => {
								experience.ideaView.issueLinkCreate.success();
								fireAnalyticsEventForIssueUpdate(createAnalyticsEvent({}), idMap[localIssueId], {
									addedDeliveryTicketId: item.id.toString(),
								});
								setProgress('done');
								onIssueLinked();
							});
						}

						// store selected delivery project in polaris backend
						if (selectedProject?.item.id !== undefined) {
							const deliveryProjectAri = createAri({
								resourceOwner: 'jira',
								cloudId,
								resourceType: 'project',
								resourceId: selectedProject?.item.id,
							});
							setSelectedDeliveryProject(deliveryProjectAri);
						}
					},
					onOperationFailed: (_: string, error: Error) => {
						if (!isClientFetchError(error)) {
							experience.ideaView.issueLinkCreate.failure(error);
						}
						setProgress('failed');
					},
				});
			}
		},
		[
			selectedIssue,
			issueLinks,
			localIssueId,
			issueKey,
			selectedIssueLinkType,
			onIssueLinked,
			linkIssueInJira,
			isPolarisIssueLink,
			linkDeliveryIssue,
			refreshIssueLinks,
			idMap,
			createAnalyticsEvent,
			linkIssue,
			cloudId,
			setSelectedDeliveryProject,
			selectedProject,
		],
	);

	const onIssueSelected = useCallback((selectedItem?: IssueOption) => {
		setSelectedIssue(selectedItem || undefined);
	}, []);

	const onProjectChanged = useCallback((payload: SelectedProject | undefined) => {
		setSelectedProject(payload);
	}, []);

	const onIssueLinkTypeSelected = useCallback(
		(issueLinkTypeId: string, direction: SelectedIssueLinkType['direction']) => {
			setSelectedIssueLinkType({
				issueLinkTypeId,
				direction,
			});
		},
		[],
	);

	return (
		<Box xcss={wrapperStyles}>
			{hideCaption !== true && <Caption>{formatMessage(messages.heading)}</Caption>}
			<Container>
				<PickerComponents isCompact={isCompact}>
					{isPolarisIssueLink && (
						<ProjectSelectWrapper isCompact={isCompact || false}>
							<ProjectPicker
								includeProjectTypes={PROJECT_TYPES_WITHOUT_JPD}
								width="100%"
								onProjectSelected={onProjectChanged}
								selectedProject={selectedProject}
							/>
						</ProjectSelectWrapper>
					)}
					{!isPolarisIssueLink && (
						<IssueTypeSelectWrapper isCompact={isCompact || false}>
							<IssueLinkTypePicker
								isAttachedToBody
								onIssueLinkTypeSelected={onIssueLinkTypeSelected}
							/>
						</IssueTypeSelectWrapper>
					)}
					<SelectWrapper
						isCompact={isCompact}
						data-testid="polaris-ideas.common.ui.link-issue.select-wrapper"
					>
						<LinkIssueSelect
							isPoalrisIssueLink={isPolarisIssueLink}
							isDisabled={false}
							hideArchived={!!hideArchived}
							excludedProjectTypes={excludedProjectTypes}
							projectId={selectedProject?.item.id}
							defaultOptions={defaultOptions}
							onIssueSelected={onIssueSelected}
							onProjectChanged={onProjectChanged}
						/>
					</SelectWrapper>
				</PickerComponents>
			</Container>
			<ButtonGroup isCompact={isCompact}>
				<Button appearance="subtle-link" onClick={onCancelClicked}>
					{formatMessage(messages.cancel)}
				</Button>
				<LoadingButton
					testId="polaris-ideas.common.ui.link-issue.loading-button"
					appearance="primary"
					isDisabled={
						selectedIssue === undefined || (progress !== undefined && progress !== 'done')
					}
					onClick={onLinkClicked}
					isLoading={progress !== undefined && progress !== 'done'}
				>
					{formatMessage(messages.link)}
				</LoadingButton>
			</ButtonGroup>
		</Box>
	);
};

const wrapperStyles = xcss({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div({
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.150', '12px'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PickerComponents = styled.div<{ isCompact?: boolean }>({
	display: 'flex',
	flexGrow: 1,
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) =>
		isCompact ? token('space.negative.050', '-4px') : token('space.negative.075', '-6px'),
	// Idea view tabs have z-index 101, so we need to be above that in order to prevent overlap with opened select
	zIndex: 102,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroup = styled.div<{ isCompact?: boolean }>({
	display: 'flex',
	justifyContent: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isCompact }) => (isCompact ? 'row-reverse' : 'row'),
	marginTop: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SelectWrapper = styled.div<{ isCompact?: boolean }>({
	flex: '1 1 400px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => (isCompact ? token('space.050', '4px') : token('space.075', '6px')),
	width: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectSelectWrapper = styled.div<{ isCompact?: boolean }>({
	flex: '1 1 360px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => (isCompact ? token('space.050', '4px') : token('space.075', '6px')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueTypeSelectWrapper = styled.div<{ isCompact?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isCompact }) => (isCompact ? token('space.050', '4px') : token('space.075', '6px')),
});
