import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createCommentPlaceholder: {
		id: 'polaris-common.comments.add-comment.create-comment-placeholder',
		defaultMessage: 'Create a comment',
		description: 'Placeholder for the create comment editor',
	},
	create: {
		id: 'polaris-common.comments.add-comment.create',
		defaultMessage: 'Create',
		description: 'Button caption to create the new comment',
	},
	cancel: {
		id: 'polaris-common.comments.add-comment.cancel',
		defaultMessage: 'Cancel',
		description: 'Button caption to discard the comment that is currently being edited',
	},
});
