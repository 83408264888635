import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createAGlobalField: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.global-field-prompt.create-a-global-field',
		defaultMessage: 'Create a global field',
		description: 'Label for the link that takes the user to the global fields page',
	},
	noGlobalFieldsOfTypeAdmin: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.global-field-prompt.no-global-fields-of-type-admin',
		defaultMessage: "You don't have any global field of this type.",
		description:
			'Message displayed to admins when there are no available global fields of the same type as the source field',
	},
	noGlobalFieldsOfType: {
		id: 'polaris-component-copy-values.copy-values-sidebar.copy-values-form.global-field-prompt.no-global-fields-of-type',
		defaultMessage: 'Contact your Jira admin to create a global field of this type.',
		description:
			'Message displayed to non-admins when there are no available global fields of the same type as the source field',
	},
});
