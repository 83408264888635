import React, { useState, useCallback, memo } from 'react';
import { useOpenRightSidebarOnFieldList } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import {
	RightSidebarShowingCreateField,
	RightSidebarShowingField,
	RightSidebarShowingFieldList,
	RightSidebarShowingFilter,
	RightSidebarShowingGroupByOptions,
	RightSidebarShowingXAxisOptions,
	RightSidebarShowingYAxisOptions,
	RightSidebarShowingZAxisOptions,
	RightSidebarShowingPlay,
	RightSidebarShowingSort,
	RightSidebarShowingVerticalGroupByOptions,
	RightSidebarShowingIdeaTemplates,
	RightSidebarShowingIdeasBucket,
	RightSidebarShowingTimelineConfig,
	type Showing,
	RightSidebarShowingGlobalFields,
	RightSidebarShowingViewInfo,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { useIsIssueOpenInSidebar } from '@atlassian/jira-polaris-common/src/controllers/route';
import { DeleteFieldModal } from '@atlassian/jira-polaris-common/src/ui/config/fields/delete-field';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ColumnsPanel } from '../columns';
import { FieldComponent } from '../field';
import { FieldCreationComponent } from '../field-create';
import { FieldsList } from '../fields';
import { FilterPanel } from '../filter';
import { GlobalFieldsPanel } from '../global-fields';
import { GroupByPanel } from '../group-by';
import { IdeaPanel } from '../idea';
import { IdeaTemplatesPanel } from '../idea-templates/main.tsx';
import { IdeasBucket } from '../ideas-bucket';
import { XAxisPanel } from '../matrix-axis/x-axis/index.tsx';
import { YAxisPanel } from '../matrix-axis/y-axis/index.tsx';
import { ZAxisPanel } from '../matrix-axis/z-axis/index.tsx';
import { PlayPanel } from '../play';
import { SortPanel } from '../sort';
import { TimelinePanel } from '../timeline';
import { ViewInfoPanel } from '../view-info';

type RightSidebarContentProps = {
	sidebarShowing: Showing;
	isIssueOpenInSidebar: boolean;
	onClose: (event?: Event) => void;
	onDelete: (fieldKey: FieldKey) => void;
};

const RightSidebarContent = memo(
	({ sidebarShowing, isIssueOpenInSidebar, onClose, onDelete }: RightSidebarContentProps) => {
		switch (sidebarShowing.mode) {
			case RightSidebarShowingGroupByOptions:
				return <ColumnsPanel onClose={onClose} />;
			case RightSidebarShowingVerticalGroupByOptions:
				return <GroupByPanel onClose={onClose} />;
			case RightSidebarShowingFieldList:
				return <FieldsList onClose={onClose} />;
			case RightSidebarShowingField:
				return <FieldComponent onClose={onClose} onDelete={onDelete} />;
			case RightSidebarShowingCreateField:
				return <FieldCreationComponent onClose={onClose} />;
			case RightSidebarShowingFilter:
				return <FilterPanel onClose={onClose} />;
			case RightSidebarShowingSort:
				return <SortPanel onClose={onClose} />;
			case RightSidebarShowingPlay:
				return <PlayPanel onClose={onClose} />;
			case RightSidebarShowingIdeaTemplates:
				return (
					<IdeaTemplatesPanel
						onClose={onClose}
						onApplyTemplate={(template) => sidebarShowing.onApplyTemplate(template)}
					/>
				);
			case RightSidebarShowingTimelineConfig:
				return <TimelinePanel onClose={onClose} />;
			case RightSidebarShowingXAxisOptions:
				return <XAxisPanel onClose={onClose} />;
			case RightSidebarShowingYAxisOptions:
				return <YAxisPanel onClose={onClose} />;
			case RightSidebarShowingZAxisOptions:
				return <ZAxisPanel onClose={onClose} />;
			case RightSidebarShowingIdeasBucket:
				return <IdeasBucket onClose={onClose} />;
			case RightSidebarShowingGlobalFields:
				return <GlobalFieldsPanel onClose={onClose} />;
			case RightSidebarShowingViewInfo:
				return <ViewInfoPanel onClose={onClose} />;
			default:
				return isIssueOpenInSidebar ? <IdeaPanel /> : null;
		}
	},
);

type SidebarContentProps = {
	onClose: (event?: Event) => void;
};

export const SidebarContent = ({ onClose }: SidebarContentProps) => {
	const [sidebarShowing] = useRightSidebarShowing();
	const openFieldList = useOpenRightSidebarOnFieldList();
	const isIssueOpenInSidebar = useIsIssueOpenInSidebar();

	const [fieldKeyForDelete, setFieldKeyForDelete] = useState<FieldKey | undefined>(undefined);
	const isOpenedFromProjectFieldsPage =
		sidebarShowing.mode === RightSidebarShowingField &&
		sidebarShowing.origin === 'projectFieldsPage';

	const onDelete = useCallback(
		(fk: FieldKey) => {
			if (!isOpenedFromProjectFieldsPage) {
				openFieldList();
			}
			setFieldKeyForDelete(fk);
		},
		[isOpenedFromProjectFieldsPage, openFieldList],
	);

	// !Note! - we are wrapping all the contents here even though we do not support delete in all of them because
	// we want to be able to switch sidebar views while the modal is opening up.
	return (
		<>
			{fieldKeyForDelete !== undefined && (
				<DeleteFieldModal
					fieldKey={fieldKeyForDelete}
					onClose={() => {
						if (isOpenedFromProjectFieldsPage) {
							onClose();
						}
						setFieldKeyForDelete(undefined);
					}}
				/>
			)}
			<RightSidebarContent
				sidebarShowing={sidebarShowing}
				isIssueOpenInSidebar={isIssueOpenInSidebar}
				onClose={onClose}
				onDelete={onDelete}
			/>
		</>
	);
};
