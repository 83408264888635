import React from 'react';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	type IssueError,
} from '@atlassian/jira-issue-shared-types';
import type { IssueErrorAttributes } from '@atlassian/jira-issue-shared-types/src/common/types/error-type';
import {
	AuthenticationErrorView,
	PermissionErrorView,
	UnknownErrorView,
} from '@atlassian/jira-issue-view-errors';
import {
	ViewExperienceFailureTracker,
	ViewExperienceSuccessTracker,
} from '@atlassian/jira-issue-view-experience-tracking';

type Props = {
	errorType: IssueError;
	errorAttributes?: IssueErrorAttributes;
	loginRedirectUrl?: string;
};

export const SUCCESS_TRACKER_TESTID =
	'issue.views.issue-details.error.ui.issue-error-view-with-success-tracking.view-experience-success-tracker';

export const FAILURE_TRACKER_TESTID =
	'issue.views.issue-details.error.ui.issue-error-view-with-success-tracking.view-experience-failure-tracker';

export const IssueErrorViewWithSuccessTracking = ({
	errorType,
	loginRedirectUrl,
	errorAttributes,
}: Props) => {
	switch (errorType) {
		case AUTHENTICATION_ERROR:
			return (
				<ViewExperienceSuccessTracker data-testid={SUCCESS_TRACKER_TESTID} location="auth-error">
					<AuthenticationErrorView loginRedirectUrl={loginRedirectUrl} />
				</ViewExperienceSuccessTracker>
			);
		case NOT_FOUND_OR_NO_PERMISSION_ERROR:
			return (
				<ViewExperienceSuccessTracker
					data-testid={SUCCESS_TRACKER_TESTID}
					location="not-found-or-no-permission-error"
				>
					<PermissionErrorView />
				</ViewExperienceSuccessTracker>
			);
		case CONNECTIVITY_ERROR:
			return <UnknownErrorView />;
		default:
			return (
				<ViewExperienceFailureTracker
					data-testid={FAILURE_TRACKER_TESTID}
					location="unknown-error"
					failureEventAttributes={errorAttributes}
				>
					<UnknownErrorView />
				</ViewExperienceFailureTracker>
			);
	}
};
