import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getEntityLimitError } from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/index.tsx';
import {
	createErrorAnalytics,
	useErrorHandlers,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { IssueError } from '@atlassian/jira-polaris-lib-errors/src/types.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import messages from './messages';

export const useIssueCreationFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: Error) => {
			errorWithRefresh({
				title: formatMessage(messages.ideaCreationFailedErrorTitle),
				description: errorObject?.message || formatMessage(messages.issueCreationFailedDescription),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueCreationFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

export const useIssueUpdateFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: IssueError) => {
			// if we have dedicated errors, let's show it (the first error from the list) to users in the error flag
			if (errorObject.statusCode && errorObject.errors?.length) {
				const firstError = errorObject.errors[0];

				errorWithRefresh({
					title: formatMessage(messages.errorTitle),
					description: firstError.error,
				});

				return;
			}

			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.issueUpdateFailedDescription),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueCreationFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

export const useIssueLoadingFailedHandling = () => {
	const { formatMessage } = useIntl();
	const { errorWithRefresh } = useNotifications();
	return useCallback(
		(errorObject: Error) => {
			errorWithRefresh({
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.issueLoadingFailedDescription),
			});
			fireErrorAnalytics(createErrorAnalytics('polaris.error.issueLoadingFailed', errorObject));
		},
		[errorWithRefresh, formatMessage],
	);
};

export const useIssueActionFailedHandling = () => {
	const { generalActionFailedError, generalDataUpdateFailedError } = useErrorHandlers();
	return useCallback(
		(errorObject: Error) => {
			// check if we have entity limit error
			const entityLimitError = getEntityLimitError(errorObject);
			if (entityLimitError) {
				generalDataUpdateFailedError(entityLimitError);
				return;
			}

			// fire general action error otherwise
			generalActionFailedError(errorObject);
		},
		[generalActionFailedError, generalDataUpdateFailedError],
	);
};
