import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	notVisibleGlobalFieldAddedDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.not-visible-global-field-added-description',
		defaultMessage: 'You can find it under the <i>Global fields</i> tab.',
		description: 'Description of the notification when the added global field is not visible',
	},
	notVisibleProjectFieldAddedDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.not-visible-project-field-added-description',
		defaultMessage: 'You can find it under the <i>Project fields</i> tab.',
		description: 'Description of the notification when the added project field is not visible',
	},
	notVisibleGlobalFieldsAddedDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.not-visible-global-fields-added-description',
		defaultMessage: 'You can find them under the <i>Global fields</i> tab.',
		description: 'Description of the notification when the added global fields are not visible',
	},
	notVisibleProjectFieldsAddedDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.not-visible-project-fields-added-description',
		defaultMessage: 'You can find them under the <i>Project fields</i> tab.',
		description: 'Description of the notification when the added project fields are not visible',
	},
	notVisibleFieldAddedDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.not-visible-field-added-description',
		defaultMessage: 'Your filter selection prevents it from showing up in the list.',
		description:
			'Description of the notification when the added field is not visible because of filters',
	},
	notVisibleFieldsAddedDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.not-visible-fields-added-description',
		defaultMessage: 'Your filter selection prevents them from showing up in the list.',
		description:
			'Description of the notification when the added fields are not visible because of the filter',
	},
	showMe: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.show-me',
		defaultMessage: 'Show me',
		description: 'Label of the Show me button',
	},
	addFieldSuccessTitle: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.add-field-success-title',
		defaultMessage: '"{field}" has been added to your project',
		description: 'Notification title for a field added to the project',
	},
	createFieldSuccessTitle: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.create-field-success-title',
		defaultMessage: '"{field}" has been created',
		description: 'Notification title for a field created',
	},
	addSeveralFieldsSuccessTitle: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.add-several-fields-success-title',
		defaultMessage:
			'{count, plural, =1 {1 field has} other {# fields have}} been added to the project',
		description: 'Notification title for several fields added to the project',
	},
	addSeveralGlobalFieldsSuccessTitle: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.notifications.add-several-global-fields-success-title',
		defaultMessage:
			'{count, plural, =1 {1 global field has} other {# global fields have}} been added to the project',
		description: 'Notification title for several global fields added to the project',
	},
});
