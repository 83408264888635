import React from 'react';
import { Flex, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useAllExternalReferencePropertiesOnIssues } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/external-reference-property-hooks';
import { ExternalReferencePropertyField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference-property/main';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const ExternalReferencePropertyFieldValue = ({
	fieldKey,
	groupIdentity,
}: FieldValueComponentProps) => {
	const allExternalReferencePropertiesOnIssues = useAllExternalReferencePropertiesOnIssues();
	return (
		<Flex>
			<ExternalReferencePropertyField
				fieldKey={fieldKey}
				value={allExternalReferencePropertiesOnIssues[groupIdentity]}
			/>
		</Flex>
	);
};

const EmptyExternalReferencePropertyFieldValue = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyFieldValue)}</Box>;
};

export const externalReferencePropertyFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: ExternalReferencePropertyFieldValue,
	EmptyComponent: EmptyExternalReferencePropertyFieldValue,
} as const;
