import React, { useState, useCallback } from 'react';
import { useDateDecoration } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks';
import {
	useIsIssueArchived,
	useStringValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	DateFieldReadView,
	DateFieldEditView,
} from '@atlassian/jira-polaris-common/src/ui/fields/date';
import type { Value, LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils';

type DateFieldProps = {
	interactive: boolean;
	localIssueId: LocalIssueId;
	fieldKey: string;
};

export const DateField = ({ localIssueId, fieldKey, interactive }: DateFieldProps) => {
	const [isEditing, setIsEditing] = useState(false);

	const value = useStringValue(fieldKey, localIssueId);
	const decoration = useDateDecoration(fieldKey, value);
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);
	const fieldUpdate = useFieldUpdate(localIssueId, fieldKey);

	const onEdit = useCallback(() => {
		setIsEditing(true);
	}, []);

	const onCancel = useCallback(() => {
		setIsEditing(false);
	}, []);

	const onUpdate = useCallback(
		(newValue: Value) => {
			fieldUpdate(newValue);
			setIsEditing(false);
		},
		[fieldUpdate],
	);

	if (isEditable && !isIdeaArchived && (interactive || isEditing)) {
		return (
			<DateFieldEditView value={value} onUpdate={onUpdate} onEdit={onEdit} onCancel={onCancel} />
		);
	}

	return (
		// Inline Edit component wraps a border around the read view component, we do the same here
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ border: '2px solid transparent' }}>
			<DateFieldReadView value={value} decoration={decoration} />
		</div>
	);
};
