import React, { useCallback, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Field from '@atlaskit/form/Field';
import Form from '@atlaskit/form/Form';
import type { InputFormProps } from './types';

export const InputForm = (props: InputFormProps) => {
	const {
		isRequired = false,
		defaultValue,
		label,
		validate,
		editView,
		onConfirm,
		onCancel: providedOnCancel = noop,
		shouldPreventOnBlur,
	} = props;

	const wasFocusReceivedSinceLastBlurRef = useRef(false);
	const isMounted = useRef(true);
	const isSubmitted = useRef(false);
	const isSubmittedByBlur = useRef(false);
	const timerRef = useRef<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	const onCancel = useCallback(() => {
		providedOnCancel();
	}, [providedOnCancel]);

	const tryAutoSubmitWhenBlur = useCallback(
		(isFieldInvalid: boolean, onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void) => {
			if (!isFieldInvalid && !wasFocusReceivedSinceLastBlurRef.current) {
				isSubmittedByBlur.current = true;
				onSubmit();
			}
		},
		[],
	);

	const onEditViewWrapperBlur = useCallback(
		(isFieldInvalid: boolean, onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void) => {
			wasFocusReceivedSinceLastBlurRef.current = false;
			timerRef.current = setTimeout(() => tryAutoSubmitWhenBlur(isFieldInvalid, onSubmit), 0);
		},
		[tryAutoSubmitWhenBlur],
	);

	const onEditViewWrapperFocus = useCallback(() => {
		wasFocusReceivedSinceLastBlurRef.current = true;
		isSubmittedByBlur.current = false;
	}, []);

	const handleSubmit = useCallback(
		(data: { InputField: string | undefined }) => {
			if (data.InputField) {
				if (!isSubmitted.current && isMounted.current) {
					isSubmitted.current = true;
					onConfirm(data.InputField, isSubmittedByBlur.current);
				}
			} else {
				onCancel();
			}
		},
		[onCancel, onConfirm],
	);

	return (
		<Form onSubmit={handleSubmit}>
			{({ formProps: { onKeyDown, onSubmit, ref } }) => (
				<FormElement
					id="pendo.create-issue-form.submit"
					onKeyDown={(e) => {
						onKeyDown(e);
						if (e.key === 'Esc' || e.key === 'Escape') {
							onCancel();
						}
					}}
					onSubmit={onSubmit}
					ref={ref}
				>
					<Field
						key="input-field"
						aria-required={isRequired}
						name="InputField"
						label={label}
						defaultValue={defaultValue}
						validate={validate}
						isRequired={isRequired}
					>
						{({ fieldProps, error }) => (
							<div
								onBlur={(event) => {
									if (shouldPreventOnBlur(event.relatedTarget)) {
										return;
									}
									onEditViewWrapperBlur(fieldProps.isInvalid, onSubmit);
								}}
								onFocus={onEditViewWrapperFocus}
							>
								{editView({
									...fieldProps,
									errorMessage: error,
								})}
							</div>
						)}
					</Field>
				</FormElement>
			)}
		</Form>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FormElement = styled.form({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		marginTop: 0,
	},
});
