import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common';
import { STRING } from '../constants';
import type { JiraFieldMapping } from '../types';

export const jiraStringMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<string | undefined> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key] ?? undefined,
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	getFieldValueForJiraBulkUpdate: (value) => ({
		[STRING]: {
			fieldId: field.key,
			text: value ?? '',
		},
	}),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
