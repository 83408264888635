import {
	getClusteredItemRendererComponent,
	getComponents,
	getDnDTooltipRendererComponent,
	getItemRendererComponent,
	getItemWrapperRendererComponent,
} from './components';
import { createSelectorHook } from './utils';

export const useComponents = createSelectorHook(getComponents);

export const useItemRendererComponent = createSelectorHook(getItemRendererComponent);
export const useItemWrapperRendererComponent = createSelectorHook(getItemWrapperRendererComponent);
export const useClusteredItemRendererComponent = createSelectorHook(
	getClusteredItemRendererComponent,
);
export const useDnDTooltipRendererComponent = createSelectorHook(getDnDTooltipRendererComponent);
