import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	selectedInsightsCount: {
		id: 'polaris-ideas.insights.insights.actions.selected-insights-count',
		defaultMessage: '{count, plural, =0 {no items} one {insight} other {insights}}',
		description: 'Number of selected insights',
	},
	copyButton: {
		id: 'polaris-ideas.insights.insights.actions.copy-button',
		defaultMessage: 'Copy to idea',
		description: 'Copy to idea button',
	},
	deleteButton: {
		id: 'polaris-ideas.insights.insights.actions.delete-button',
		defaultMessage: 'Delete',
		description: 'Delete button',
	},
	clearSelection: {
		id: 'polaris-ideas.insights.insights.actions.clear-selection',
		defaultMessage: 'Clear selection',
		description: 'Clear selection button',
	},
	deleteInsightWarningHeader: {
		id: 'polaris-ideas.insights.insights.actions.delete-insight-warning-header',
		defaultMessage:
			'Delete {selectedInsightsCount, plural, one {this insight} other {these insights}}?',
		description: 'Header for delete insights confirmation dialog',
	},
});
