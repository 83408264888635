import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	dropAfterHint: {
		id: 'polaris-ideas.common.dnd-v2.drop-after-hint',
		defaultMessage:
			'Drop after {count, plural, =0 {no items} one {# idea} other {# ideas}} hidden by filter',
		description: 'Hint displayed after issue',
	},
	dropBeforeHint: {
		id: 'polaris-ideas.common.dnd-v2.drop-before-hint',
		defaultMessage:
			'Drop before {count, plural, =0 {no items} one {# idea} other {# ideas}} hidden by filter',
		description: 'Hint displayed after issue',
	},
});
