import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cantBeChangedHereHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.cant-be-changed-here-hint',
		defaultMessage: 'It cannot be changed from here',
		description: "Value can't be changed here hint",
	},
	dateIsOverriddenByHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.date-is-overridden-by-hint',
		defaultMessage: 'is overridden by:',
		description: 'The date is overriden by hint',
	},
	configureButton: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.configure-button',
		defaultMessage: 'Configure',
		description: 'Configure button hint',
	},
	atlasProjectStartDate: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.atlas-project-start-date',
		defaultMessage: 'Atlas project start date',
		description: 'Label for Atlas project start date field',
	},
	atlasProjectTargetDate: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-drag-disabled-hint.atlas-project-target-date',
		defaultMessage: 'Atlas project target date',
		description: 'Label for Atlas project target date field',
	},
});
