/** @jsx jsx */
import React, { useState, useEffect, type SyntheticEvent, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import Button, { IconButton } from '@atlaskit/button/new';
import MoreVerticalIcon from '@atlaskit/icon/glyph/more-vertical';
import Popup from '@atlaskit/popup';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import { MenuOption as MenuOptionLegacy } from './legacy';
import messages from './messages';
import type { MenuOptionProps } from './types';

export const MenuOption = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	({
		children,
		menuComponent = null,
		isActive = false,
		isDragEnabled,
		hideActionMenu,
		outerSpacing,
		setOpenOption,
		setIsMenuOpen,
	}: MenuOptionProps) => {
		const { formatMessage } = useIntl();
		const [open, setOpen] = useState(false);

		const onToggleOpen = useCallback(() => {
			hideActionMenu !== true && setOpen((prev) => !prev);
		}, [hideActionMenu]);

		useEffect(() => {
			setIsMenuOpen?.(open);
		}, [open, setIsMenuOpen]);

		return (
			<Popup
				zIndex={1000}
				autoFocus={false}
				placement="bottom-end"
				isOpen={open}
				onClose={() => setOpen(false)}
				content={() => (
					<div
						onClick={() => setOpen(false)}
						onKeyPress={() => setOpen(false)}
						role="menu"
						tabIndex={0}
					>
						{menuComponent}
					</div>
				)}
				trigger={(triggerProps) => (
					<div {...triggerProps}>
						<div
							data-component-selector="option-item-wrapper-kC88"
							data-testid={`polaris-lib-decoration.ui.menu-option.option-item-wrapper-${isActive ? 'active' : 'inactive'}`}
							css={[
								optionItemWrapperStyles,
								isActive && optionItemWrapperActiveStyles,
								isDragEnabled && optionItemWrapperDragEnabledStyles,
							]}
						>
							<div
								onClick={({ target, currentTarget }: SyntheticEvent<HTMLElement>) => {
									let isInteractiveEl = false;
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									let node: HTMLElement = target as HTMLElement;
									while (!isInteractiveEl && node && node !== currentTarget) {
										isInteractiveEl = !!node.getAttribute('data-interactive');
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										node = node.parentNode as HTMLElement;
									}
									// Do not open menu if element is interactive, e.g. input
									if (isInteractiveEl) {
										return setOpen(false);
									}
									return isActive ? setOpenOption(null) : onToggleOpen();
								}}
								onKeyDown={noop}
								role="menuitem"
								tabIndex={0}
								css={[
									optionItemStyles,
									open && hideActionMenu !== true && optionItemSelectedStyles,
									!(open && hideActionMenu !== true) && optionItemNotSelectedStyles,
									isDragEnabled && optionItemIsDraggableStyles,
								]}
								// eslint-disable-next-line jira/react/no-style-attribute
								style={{
									paddingLeft: isDragEnabled ? '0' : outerSpacing,
									paddingRight: outerSpacing,
								}}
							>
								{isDragEnabled && (
									// eslint-disable-next-line jira/react/no-style-attribute
									<div css={dragHandleWrapperStyles} style={{ width: outerSpacing }}>
										<DragHandleButtonSmall
											appearance="subtle"
											label={formatMessage(messages.dragHandleLabel)}
										/>
									</div>
								)}
								<Flex xcss={itemWrapperStyles}>{children}</Flex>
								{isActive && (
									<Button
										testId="polaris-lib-decoration.ui.menu-option.done-button"
										spacing="compact"
										appearance="primary"
									>
										{formatMessage(messages.saveOption)}
									</Button>
								)}
								{hideActionMenu !== true && !isActive && (
									<Box xcss={[!open && hiddenStyles]}>
										<IconButton
											testId="polaris-lib-decoration.ui.menu-option.more-button"
											data-component-selector="more-button-9Kk3"
											isSelected={open}
											spacing="compact"
											label="btn.more"
											icon={(iconProps) => <MoreVerticalIcon size="small" {...iconProps} />}
										/>
									</Box>
								)}
							</div>
						</div>
					</div>
				)}
			/>
		);
	},
	MenuOptionLegacy,
);

const hiddenStyles = xcss({
	visibility: 'hidden',
});

const optionItemWrapperStyles = css({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="more-button-9Kk3"]': {
		visibility: 'visible',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:active [data-component-selector="more-button-9Kk3"]': {
		visibility: 'visible',
	},
});

const optionItemWrapperActiveStyles = css({
	paddingTop: token('space.150'),
	backgroundColor: token('color.background.disabled'),
});

const optionItemWrapperDragEnabledStyles = css({
	'&:hover': {
		backgroundColor: token('color.background.disabled'),
	},
	'&:active': {
		backgroundColor: token('color.background.disabled'),
	},
});

const optionItemStyles = css({
	position: 'relative',
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	color: token('color.text.subtle'),
	overflow: 'hidden',
	padding: '0',
	backgroundColor: 'transparent',
	'&:hover': {
		color: token('color.text'),
		cursor: 'pointer',
		textDecoration: 'none',
	},
	'&:focus': {
		outline: 'none',
		boxShadow: 'none',
	},
	'&:active': {
		borderRadius: token('border.radius'),
		color: token('color.text.subtle'),
	},
});

const optionItemSelectedStyles = css({
	backgroundColor: token('color.background.disabled'),
});

const optionItemNotSelectedStyles = css({
	backgroundColor: 'transparent',
});

const optionItemIsDraggableStyles = css({
	'&:active': {
		backgroundColor: token('color.background.disabled'),
	},
});

const itemWrapperStyles = xcss({
	flex: '1',
	alignItems: 'center',
	minWidth: '0',
	overflow: 'hidden',
});

const dragHandleWrapperStyles = css({ display: 'flex', justifyContent: 'center' });
