import { createSelector } from 'reselect';
import { uiSelector } from '../common/state/selectors/issue-selector';
import { permissionsSelector } from '../common/state/selectors/permissions-selector';

export const canCreateAttachmentsSelector = createSelector(
	permissionsSelector,
	(permissions) => permissions.canCreateAttachments,
);

export const attachmentsSelector = createSelector(uiSelector, (ui) => ui.attachments);
