import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { N200, N20A, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MenuInlineDialogContainer = styled.div({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RollupContainer = styled.div<{ readonly: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: (props) => (props.readonly ? '0' : '-0.5rem'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RollupItemComponentWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadonlyRollupItemContainer = styled.div({
	display: 'flex',
	marginLeft: token('space.200', '16px'),
	height: '28px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddInputContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	lineHeight: '8px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddInputInnerContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddInputSearchContainer = styled.div({
	padding: token('space.200', '1rem'),
	borderBottom: `1px solid ${token('color.border', '#ebecf0')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddButton = styled(Button)({
	marginBottom: '0.75rem',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PercentageWrapper = styled.span({
	paddingRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RollupContainerHeader = styled.div({
	color: token('color.text.subtlest', N200),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	flex: '0 0 auto',
	padding: '1rem 0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& + p': {
		marginTop: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RollupFieldsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	margin: '0 -1rem',
	paddingBottom: token('space.200', '1rem'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RollupOptionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	maxHeight: '300px',
	overflowX: 'none',
	overflowY: 'auto',
	flex: '1 1 auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RollupItem = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	flex: 1,
	alignItems: 'center',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PercentageField = styled.div({
	display: 'flex',
	alignItems: 'center',
	maxWidth: '60px',
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelWrapper = styled.div({
	display: 'flex',
	alignItems: 'start',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconLabel = styled.div({
	gridArea: 'icon',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Label = styled.div({
	gridArea: 'title',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	marginRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AppAvatar = styled.img({
	width: '16px',
	height: '16px',
	marginRight: token('space.100', '8px'),
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DecoratorContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	padding: `0 ${token('space.200', '16px')} ${token('space.150', '12px')} ${token(
		'space.200',
		'16px',
	)}`,
	boxSizing: 'border-box',
	backgroundColor: N20A,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DecoratorItemLabel = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	color: token('color.text.subtlest', N200),
	paddingBottom: token('space.050', '4px'),
	maxWidth: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconLabelWrapper = styled.div({
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: '1fr',
	gap: '0px 0px',
	gridTemplateAreas: '"icon title"',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CustomIconWrapper = styled.div({
	transform: 'scale(1.5)',
	marginRight: token('space.100', '8px'),
});

// Todo: delete this with isNegativeWeightsEnabled FF cleanup
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AddInputMessage = styled.div({
	marginLeft: token('space.100', '8px'),
	color: token('color.text.subtle', N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HelpText = styled.p<{}>({
	fontSize: '0.75rem',
	color: token('color.text.subtle', '#44546f'),
	padding: '0.5rem 0 1rem',
	whiteSpace: 'pre-line',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldRollupTypeWrapperComponent = styled.div({
	marginTop: 0,
	color: token('color.text.subtlest', N200),
});

export const FieldRollupTypeWrapper = (
	props: ComponentPropsWithoutRef<typeof FieldRollupTypeWrapperComponent>,
) => (
	<Heading level="h300">
		<FieldRollupTypeWrapperComponent {...props} />
	</Heading>
);
