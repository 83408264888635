import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { jira_polaris_GetConsent as GetConsent } from './__generated_apollo__/jira_polaris_GetConsent';
import type {
	jira_polaris_UpdateConsent as UpdateConsent,
	jira_polaris_UpdateConsentVariables as UpdateConsentVariables,
} from './__generated_apollo__/jira_polaris_UpdateConsent';
import type { ConsentFetcher, ConsentUpdater, ConsentResult } from './types';
import { getValidatedCodeOrUnknown } from './utils';

const GET_TOS_CONSENT_QUERY = gqlTagPolaris`
query jira_polaris_GetConsent($cloudID: ID!) {
    polarisTermsConsent(cloudID: $cloudID) {
      consented
      requiresMarketingConsent
      locale
    }
}
`;

export const useFetchConsent = (): [ConsentFetcher] => {
	const client = useApolloClient();

	return [
		useCallback(
			async (cloudID: string): Promise<ConsentResult> => {
				const result = await client.query<GetConsent>({
					query: GET_TOS_CONSENT_QUERY,
					variables: {
						cloudID,
					},
				});

				if (result.errors !== undefined) {
					throw new Error(
						`consent.get-consent-error:${result.errors.map((e) => e.message).join(', ')}`,
					);
				}

				if (
					result.data.polarisTermsConsent === null ||
					result.data.polarisTermsConsent === undefined
				) {
					throw new Error('consent.get-consent-error: no data');
				}

				return {
					consented: result.data.polarisTermsConsent.consented,
					locale: result.data.polarisTermsConsent.locale,
				};
			},
			[client],
		),
	];
};

const UPDATE_TOS_CONSENT_MUTATION = gqlTagPolaris`
mutation jira_polaris_UpdateConsent($input: UpdatePolarisTermsConsentInput!) {
    updatePolarisTermsConsent(input: $input) {
        success
        errors {
            message
        }
    }
}
`;

export const useUpdateConsent = (): [ConsentUpdater] => {
	const [updateConsent] = useMutation<UpdateConsent, UpdateConsentVariables>(
		UPDATE_TOS_CONSENT_MUTATION,
	);

	return [
		useCallback(
			async (input: UpdateConsentVariables['input']): Promise<void> => {
				try {
					const { errors, data } = await updateConsent({
						variables: {
							input: {
								...input,
								locale: getValidatedCodeOrUnknown(input.locale),
							},
						},
					});
					if (errors !== undefined) {
						throw new Error(
							`consent.update-consent-error:${errors.map((e) => e.message).join(', ')}`,
						);
					}
					if (!data?.updatePolarisTermsConsent?.success) {
						throw new Error('consent.update-consent-error: no error but not successful either');
					}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					// Logging input data to figure out why consent update fails https://pi-dev-sandbox.atlassian.net/browse/POL-3558
					log.safeErrorWithoutCustomerData(
						'consent.update-consent-error',
						`Failed to update consent with input data: ${JSON.stringify(input)}`,
						error,
					);
				}
			},
			[updateConsent],
		),
	];
};
