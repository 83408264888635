import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const saveProperty =
	(propertyKey: string, value: unknown): Action<State, Props> =>
	({ setState, getState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const oldValue = getState().properties[propertyKey]?.value;

		setState({
			properties: {
				...getState().properties,
				[propertyKey]: {
					value,
					isLoading: false,
					error: null,
				},
			},
		});

		setUserProperties(accountId, propertyKey, JSON.stringify(value) || '').catch((error) => {
			log.safeErrorWithoutCustomerData(
				'polaris.user-properties-error',
				`Failed to save user property with key: ${propertyKey}`,
				error,
			);
			setState({
				// @ts-expect-error - TS2322 - Type '{ [x: string]: UserPropertyState | { value: unknown; } | undefined; }' is not assignable to type '{ [userPropertyKey: string]: UserPropertyState | undefined; }'.
				properties: {
					...getState().properties,
					[propertyKey]: {
						value: oldValue,
					},
				},
			});
		});
	};
