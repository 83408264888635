import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
	type PropsWithChildren,
} from 'react';
import {
	FieldEditingContainer,
	useFieldIsEditing,
} from '@atlassian/jira-issue-field-editing-store';

export const IssueContext = createContext('localStateMode');

export const IssueFieldEditingContextProvider = ({
	children,
	scope,
}: { scope?: string } & PropsWithChildren) => (
	<IssueContext.Provider value="sharedStateMode">
		<FieldEditingContainer scope={scope}>{children}</FieldEditingContainer>
	</IssueContext.Provider>
);

type OptionallyControlledEditingStateType = (
	/**
	 * initialState of local useState
	 */
	initialState: boolean,
	/**
	 * fieldId of parent to fetch value from the store
	 */
	fieldId: string,
	/**
	 * These methods allow for general or in depth ability to hook into this component's state changes.
	 * onChange is fired on any change to the editing state, local or shared.
	 * onSharedChange is only fired when the useFieldIsEditing store value updated.
	 * onLocalChange is only fired when the local state value is updated.
	 */
	onChangeMethods?: {
		onChange?: (newValue: boolean, prevValue: boolean) => void;
		onSharedChange?: (newValue: boolean, prevValue: boolean) => void;
		onLocalChange?: (newValue: boolean, prevValue: boolean) => void;
	},
) => [boolean, (newValue: boolean) => void];

export const useOptionallyControlledEditingState: OptionallyControlledEditingStateType = (
	initialState,
	fieldId,
	onChangeMethods,
) => {
	const editingContext = useContext(IssueContext);
	const isLocalStateMode: boolean = editingContext !== 'sharedStateMode';

	const [sharedState, { setFieldEditingState }] = useFieldIsEditing(fieldId);
	const [localState, setLocalState] = useState<boolean>(
		isLocalStateMode ? initialState : sharedState,
	);

	const unifiedOnChange: (newValue: boolean) => void = useCallback(
		(newValue: boolean) => {
			onChangeMethods?.onLocalChange?.(newValue, localState);
			onChangeMethods?.onChange?.(newValue, localState);
			setLocalState(newValue);
			if (!isLocalStateMode) {
				setFieldEditingState(fieldId, newValue);
			}
		},
		[fieldId, isLocalStateMode, onChangeMethods, setFieldEditingState, localState],
	);

	useEffect(() => {
		// If sharedState updates to a value that is different from localState, it's a shared state update.
		// Otherwise, it's just the shared state following a locally triggered state update
		if (!isLocalStateMode && sharedState !== localState) {
			onChangeMethods?.onSharedChange?.(sharedState, !sharedState);
			onChangeMethods?.onChange?.(sharedState, !sharedState);
			setLocalState(sharedState);
		}
		// We want to ensure this useEffect is only called when the sharedState changes, not when any other dependencies change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sharedState]);

	const resultState: boolean = isLocalStateMode ? localState : sharedState;
	return [resultState, unifiedOnChange];
};
