import flatten from 'lodash/flatten';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import {
	CONJUNCTION,
	CONSTANT,
	DISJUNCTION,
	INCLUDE_STRING_LIST_PROPERTY,
	NEGATION,
	type IncludeInStringListProperty,
	type Conjunction,
	type Negation,
	type Disjunction,
	type RecursiveFilter,
} from '@atlassian/jira-polaris-lib-formula/src/utils/filter/types.tsx';
import { getSnippetPropertyValue } from '../../../../common/utils/snippet';

const testStringListInclusionFilterInsight = (
	insight: Insight,
	filter: IncludeInStringListProperty,
): boolean => {
	const { propertyKey, values } = filter.parameters;
	const mergedValues = flatten(
		insight.snippets.map(
			(snippet) => getSnippetPropertyValue(snippet.properties, propertyKey) || [],
		),
	);
	return values.every((expectedValue) => mergedValues.includes(expectedValue));
};

export const testInsight = (insight: Insight, filter: RecursiveFilter): boolean => {
	switch (filter.template) {
		case DISJUNCTION:
			return filter.parameters.filters
				.map((subFilter) => testInsight(insight, subFilter))
				.some((value) => value);
		case CONJUNCTION:
			return filter.parameters.filters
				.map((subFilter) => testInsight(insight, subFilter))
				.every((value) => value);
		case NEGATION:
			return !testInsight(insight, filter.parameters.formula);
		case CONSTANT:
			return filter.parameters.value;
		case INCLUDE_STRING_LIST_PROPERTY:
			return testStringListInclusionFilterInsight(insight, filter);
		default:
			throw new Error('Unhandled operation');
	}
};

export const createConjunction = (subFilters: RecursiveFilter[]): Conjunction => ({
	template: CONJUNCTION,
	parameters: { filters: subFilters },
});

export const createDisjunction = (subFilters: RecursiveFilter[]): Disjunction => ({
	template: DISJUNCTION,
	parameters: { filters: subFilters },
});

export const createNegation = (formula: RecursiveFilter): Negation => ({
	template: NEGATION,
	parameters: { formula },
});

export const createIncludeLabelFilter = (label: string): IncludeInStringListProperty => ({
	template: INCLUDE_STRING_LIST_PROPERTY,
	parameters: {
		propertyKey: 'labels',
		values: [label],
	},
});

export const parseConjunctiveLabelFilter = (filter: RecursiveFilter): string[] | undefined => {
	if (filter.template === CONJUNCTION) {
		return flatten(
			filter.parameters.filters
				.map((subFilter) =>
					subFilter.template === INCLUDE_STRING_LIST_PROPERTY
						? subFilter.parameters.values
						: undefined,
				)
				.filter(Boolean),
		);
	}
	return undefined;
};

export const conjunctiveLabelFilter = (labels: string[]) =>
	createConjunction(labels.map(createIncludeLabelFilter));
