import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const updateGlobalField = async (
	fieldKey: string,
	description: string,
	name: string,
): Promise<void> => {
	await performPutRequest(`/rest/api/3/field/${fieldKey}`, {
		body: JSON.stringify({ description, name }),
	});
};
