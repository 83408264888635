import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/bufferToggle';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { isPageVisible } from '@atlassian/jira-common-page-visibility';
import getVisibility$ from '@atlassian/jira-common-page-visiblity-stream/src/page-visibility-stream';
import { Reason, type IssueRefreshServiceActions } from '@atlassian/jira-issue-refresh-service';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	type RealtimeUpdateCurrentIssue,
	REALTIME_UPDATE_CURRENT_ISSUE,
} from '@atlassian/jira-issue-view-store/src/actions/issue-realtime-actions';
import { refreshIssueRequest } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions';
import { loggedInUserDetailsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFromTheSameUser = (action: any, store: MiddlewareAPI<State>): boolean => {
	const state = store.getState();
	const { atlassianId } = action.payload;
	const loggedInUserDetails = loggedInUserDetailsSelector(state);

	return !!loggedInUserDetails && atlassianId === loggedInUserDetails.id;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldRefresh = (action: any, store: MiddlewareAPI<State>): boolean => {
	const state = store.getState();
	const { atlassianId } = action.payload;
	const loggedInUserDetails = loggedInUserDetailsSelector(state);
	const isFromAnotherUser = !!loggedInUserDetails && atlassianId !== loggedInUserDetails.id;

	return isFromAnotherUser;
};

export const issueRealtimeEpic =
	(issueRefreshServiceActions: IssueRefreshServiceActions) =>
	(action$: ActionsObservable<RealtimeUpdateCurrentIssue>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(REALTIME_UPDATE_CURRENT_ISSUE)
			.filter(() => isPageVisible())
			.switchMap((action) => {
				if (shouldRefresh(action, store)) {
					return Observable.of(
						refreshIssueRequest({
							reason: Reason.Realtime,
							details: action.payload,
						}),
					);
				}

				if (isFromTheSameUser(action, store)) {
					issueRefreshServiceActions.setEvent({
						refreshed: false,
						reason: Reason.Realtime,
						details: action.payload,
					});
				}

				return Observable.empty<never>();
			});

export const bufferedUpdatesWhenUnfocussedEpic =
	() => (action$: ActionsObservable<RealtimeUpdateCurrentIssue>, store: MiddlewareAPI<State>) => {
		const visibility$ = getVisibility$();
		return action$
			.ofType(REALTIME_UPDATE_CURRENT_ISSUE)
			.bufferToggle(
				visibility$.filter((isVisible) => !isVisible),
				() => visibility$.filter((isVisible) => Boolean(isVisible)),
			)
			.switchMap((actionsArray) => {
				/**
				 * When the tab gets in the focus and issue details update, we want to refresh the UI in two cases:
				 * 1. The update was made by another user (same logic that we apply for the "active" tab)
				 * 2. The update was made by the same user but from another tab, so when they are back to this tab, they see the latest data
				 */
				const matchedAction = actionsArray.find(
					(action) => shouldRefresh(action, store) || isFromTheSameUser(action, store),
				);
				if (matchedAction) {
					return Observable.of(
						refreshIssueRequest({
							reason: Reason.Realtime,
							details: matchedAction.payload,
						}),
					);
				}

				return Observable.empty<never>();
			});
	};
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issueRefreshServiceActions: IssueRefreshServiceActions) =>
	combineEpics<RealtimeUpdateCurrentIssue, State>(
		// @ts-expect-error - TS2345: Property 'payload' is missing in type '{ type: "REFRESH_ISSUE_REQUEST"; }' but required in type '{ type: "REALTIME_UPDATE_CURRENT_ISSUE"; payload: RealtimeUpdateCurrentIssueActionPayload; }'.
		issueRealtimeEpic(issueRefreshServiceActions),
		bufferedUpdatesWhenUnfocussedEpic(),
	);
