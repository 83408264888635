import type { IssueId, IssueKey } from '@atlassian/jira-shared-types';
import { getClient, type GiraQuery } from '../../common/utils/client';
import type { GraphQlError } from '../../types';

type Data = {
	viewIssue: {
		issueId: IssueId;
	} | null;
};

export type GraphQlResponse = {
	data?: Data;
	errors?: GraphQlError[];
};

const ISSUE_EXISTENCE_QUERY: GiraQuery<GraphQlResponse> = `
    query IssueExistence($issueKey: String!) {
        viewIssue(issueKey: $issueKey) {
            issueId
        }
    }
`;

export const doesIssueExist = async (issueKey: IssueKey): Promise<boolean> => {
	const client = getClient('');
	const variables = { issueKey };

	// @ts-expect-error - Property 'data' does not exist on type 'unknown'. | Property 'errors' does not exist on type 'unknown'.
	const { data, errors } = await client.query({
		query: ISSUE_EXISTENCE_QUERY,
		variables,
	});

	if (errors != null && errors.length > 0) {
		const [{ message }] = errors;
		throw new Error(message);
	}

	return data?.viewIssue != null;
};
