import { createHigherLevelHook, createHook } from '../index';
import {
	getAreGlobalLabelsLoading,
	getField,
	getFieldDefaultOpenOptionId,
	getFieldDescription,
	getFieldEmoji,
	getFieldFormula,
	getFieldIsLocked,
	getFieldKey,
	getFieldLabel,
	getFieldOption,
	getFieldOptionAutoFormatting,
	getFieldOptionCount,
	getFieldOptions,
	getFieldOptionWeight,
	getFieldOptionWeightType,
	getFieldPeopleDisplayMode,
	getFieldTypeIcon,
	getFieldValueDecorations,
	getIsGlobalCustomField,
	getIsGlobalLabelsLoadingFailed,
	getNewFieldType,
	hasDefaultFieldOptionWeights,
	hasReachedFieldOptionsLimit,
} from './field';

export const useField = createHook(getField);
export const useFieldKey = createHook(getFieldKey);
export const useFieldLabel = createHook(getFieldLabel);
export const useFieldDescription = createHook(getFieldDescription);
export const useFieldType = createHook(getNewFieldType);
export const useFieldTypeIcon = createHook(getFieldTypeIcon);
export const useIsGlobalCustomField = createHook(getIsGlobalCustomField);
export const useAreGlobalLabelsLoading = createHook(getAreGlobalLabelsLoading);
export const useIsGlobalLabelsLoadingFailed = createHook(getIsGlobalLabelsLoadingFailed);
export const useFieldIsLocked = createHook(getFieldIsLocked);
export const useFieldEmoji = createHook(getFieldEmoji);
export const useFieldFormula = createHook(getFieldFormula);
export const useFieldValueDecorations = createHook(getFieldValueDecorations);
export const useFieldOptions = createHook(getFieldOptions);
export const useFieldOption = createHigherLevelHook(getFieldOption);
export const useFieldOptionWeight = createHigherLevelHook(getFieldOptionWeight);
export const useFieldOptionWeightType = createHook(getFieldOptionWeightType);
export const useFieldOptionAutoFormatting = createHook(getFieldOptionAutoFormatting);
export const useFieldDefaultOpenOptionId = createHook(getFieldDefaultOpenOptionId);
export const useHasDefaultFieldOptionWeights = createHook(hasDefaultFieldOptionWeights);
export const useFieldOptionCount = createHook(getFieldOptionCount);
export const useFieldPeopleDisplayMode = createHook(getFieldPeopleDisplayMode);

// Returns the limit type and value if reached, otherwise returns null
export const useHasReachedFieldOptionsLimit = createHook(hasReachedFieldOptionsLimit);
