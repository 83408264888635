import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Label } from '@atlaskit/form';
import { Box } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import debouncePromise from '@atlassian/jira-platform-debounce-promise/src/ui/debounced-promise/index.tsx';
import { DELIVERY_DATE_AGGREGATION_TYPES } from '@atlassian/jira-polaris-domain-field/src/delivery/types.tsx';
import { IssueFieldPicker } from '@atlassian/jira-polaris-lib-issue-field-picker/src/ui/index.tsx';
import { useGlobalFieldRemote } from '@atlassian/jira-polaris-remote-global-field';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCallbacks } from '../../../../controllers/selectors/callback-hooks';
import { useReadonly } from '../../../../controllers/selectors/config-hooks';
import { useField } from '../../../../controllers/selectors/field-hooks';
import { searchSystemFields } from '../../../../services/field-search';
import messages from './messages';

const SUPPORTED_FIELD_TYPES = [
	'com.atlassian.jira.plugin.system.customfieldtypes:datepicker',
	'com.atlassian.jira.plugin.system.customfieldtypes:datetime',
];
const SUPPORTED_SYSTEM_FIELD_KEYS = ['duedate'];
const DEFAULT_SYSTEM_FIELD_KEY = 'duedate';
const FIELD_SEARCH_DEBOUNCE_MS = 300;

const fieldSelectId = 'interval-override-config-field-select';
const calculationTypeSelectId = 'interval-override-config-calculation-type';

type FieldSelectOption = {
	label: string;
	value: {
		key: string;
	};
};

type CalculationSelectOption = {
	label: string;
	value:
		| typeof DELIVERY_DATE_AGGREGATION_TYPES.LATEST
		| typeof DELIVERY_DATE_AGGREGATION_TYPES.EARLIEST;
};

export const DeliveryDateConfiguration = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const field = useField();
	const isReadonly = useReadonly();
	const {
		onDeliveryDateSourceFieldUpdated,
		onDeliveryDateAggregationTypeUpdated,
		onDeliveryDateConfigurationComplete,
	} = useCallbacks();
	const globalFieldsRemote = useGlobalFieldRemote();

	const [selectedFieldOption, setSelectedFieldOption] = useState<FieldSelectOption>();
	const [searchString, setSearchString] = useState<string | undefined>(undefined);
	const [defaultOptions, setDefaultOptions] = useState<FieldSelectOption[]>([]);
	const [dirty, setDirty] = useState<boolean>(false);
	const [systemFieldOptions, setSystemFieldOptions] = useState<FieldSelectOption[]>([]);

	const { formatMessage } = useIntl();

	const calculationOptionLatest = useMemo(
		() => ({
			label: formatMessage(messages.calculationTypeLatestOption),
			value: DELIVERY_DATE_AGGREGATION_TYPES.LATEST,
		}),
		[formatMessage],
	);

	const calculationOptionEarliest = useMemo(
		() => ({
			label: formatMessage(messages.calculationTypeEarliestOption),
			value: DELIVERY_DATE_AGGREGATION_TYPES.EARLIEST,
		}),
		[formatMessage],
	);

	const calculationOptions = useMemo(
		() => [calculationOptionLatest, calculationOptionEarliest],
		[calculationOptionEarliest, calculationOptionLatest],
	);

	useEffect(() => {
		searchSystemFields().then((fields) => {
			const supportedSystemFields = fields.filter((f) =>
				SUPPORTED_SYSTEM_FIELD_KEYS.includes(f.key),
			);
			const options = supportedSystemFields.map((f) => ({
				label: f.name,
				value: f,
			}));

			setSystemFieldOptions(options);

			if (!field?.configuration?.customfieldKey) {
				if (!field) {
					return;
				}

				const defaultOption = options.find(
					(option) => option.value.key === DEFAULT_SYSTEM_FIELD_KEY,
				);

				if (defaultOption) {
					onDeliveryDateSourceFieldUpdated?.(defaultOption.value.key, createAnalyticsEvent({}));
					setSelectedFieldOption(defaultOption);
					setDirty(true);
				}
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createAnalyticsEvent]); // it should not trigger on field change

	useEffect(() => {
		if (
			onDeliveryDateConfigurationComplete &&
			dirty &&
			field?.configuration?.customfieldKey &&
			field?.configuration?.aggregationType
		) {
			onDeliveryDateConfigurationComplete();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		// dirty is only taken into account when the field is updated
		field?.configuration?.aggregationType,
		field?.configuration?.customfieldKey,
		onDeliveryDateConfigurationComplete,
	]);

	const searchFieldsReturnOptions = useCallback(
		async (query?: string) => {
			const fields = await globalFieldsRemote.searchGlobalFields({
				query,
				fieldTypes: SUPPORTED_FIELD_TYPES,
			});

			const options = fields.map((f) => ({
				label: f.label,
				value: f,
			}));

			const searchedSystemOptions = systemFieldOptions.filter((f) => {
				if (!query) {
					return true;
				}
				return f.label.toLowerCase().includes(query.toLowerCase());
			});

			return [...searchedSystemOptions, ...options];
		},
		[globalFieldsRemote, systemFieldOptions],
	);

	useEffect(() => {
		if (field?.key === undefined) {
			return;
		}

		searchFieldsReturnOptions().then((options) => {
			const selectedOption = options.find(
				(option) => option.value.key === field?.configuration?.customfieldKey,
			);
			if (selectedOption) {
				setSelectedFieldOption(selectedOption);
			}
			setDefaultOptions(options);
		});
	}, [field?.configuration?.customfieldKey, field?.key, searchFieldsReturnOptions]);

	const onLoadOptions = (search?: string) => {
		setSearchString(search);
		return searchFieldsReturnOptions(search);
	};

	const debouncedOnLoadOptions = debouncePromise(onLoadOptions, FIELD_SEARCH_DEBOUNCE_MS);

	useEffect(() => {
		if (field?.configuration?.aggregationType === undefined) {
			onDeliveryDateAggregationTypeUpdated?.(
				DELIVERY_DATE_AGGREGATION_TYPES.LATEST,
				createAnalyticsEvent({}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const defaultCalculationTypeOption = useMemo(() => {
		switch (field?.configuration?.aggregationType) {
			case DELIVERY_DATE_AGGREGATION_TYPES.LATEST:
				return calculationOptionLatest;
			case DELIVERY_DATE_AGGREGATION_TYPES.EARLIEST:
				return calculationOptionEarliest;
			default:
				return calculationOptionLatest;
		}
	}, [calculationOptionEarliest, calculationOptionLatest, field?.configuration?.aggregationType]);

	const [selectedCalculationType, setSelectedCalculationType] = useState<CalculationSelectOption>(
		defaultCalculationTypeOption,
	);

	const handleCalculationTypeChange = useCallback(
		(item: CalculationSelectOption | null) => {
			if (!item) {
				return;
			}

			onDeliveryDateAggregationTypeUpdated?.(item.value, createAnalyticsEvent({}));
			setSelectedCalculationType(item);
			setDirty(true);
		},
		[createAnalyticsEvent, onDeliveryDateAggregationTypeUpdated],
	);

	const onFieldSelected = (selectedField: FieldSelectOption | null) => {
		if (field?.key === undefined) {
			return;
		}

		if (!selectedField) {
			onDeliveryDateSourceFieldUpdated?.(undefined, createAnalyticsEvent({}));
			setSelectedFieldOption(undefined);

			return;
		}

		if (selectedField.value) {
			onDeliveryDateSourceFieldUpdated?.(selectedField.value.key, createAnalyticsEvent({}));
			setSelectedFieldOption(selectedField);
			setDirty(true);
		}
	};

	return (
		<>
			<Box>
				<Label htmlFor={fieldSelectId}>{formatMessage(messages.fieldSelectLabel)}</Label>
				<IssueFieldPicker
					onFieldSelected={onFieldSelected}
					onLoadOptions={debouncedOnLoadOptions}
					searchString={searchString}
					defaultOptions={defaultOptions}
					value={selectedFieldOption}
					readonly={isReadonly}
					inputId={fieldSelectId}
				/>
			</Box>
			<Box>
				<Label htmlFor={calculationTypeSelectId}>
					{formatMessage(messages.calculationTypeSelectLabel)}
				</Label>
				<Select<CalculationSelectOption>
					value={selectedCalculationType}
					options={calculationOptions}
					onChange={handleCalculationTypeChange}
					isDisabled={isReadonly}
					inputId={calculationTypeSelectId}
				/>
			</Box>
		</>
	);
};
