import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.apps.apps.salesforce.title',
		defaultMessage: 'Salesforce',
		description: 'Title of the polaris Salesforce app',
	},
	description: {
		id: 'polaris-common.apps.apps.salesforce.description',
		defaultMessage:
			'Add Salesforce objects to ideas to quantify their impact on sales opportunities',
		description: 'Description of the polaris Salesforce app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.salesforce.unfurl-dialog-title',
		defaultMessage: 'Connect to Salesforce',
		description: 'Title of the Salesforce try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.salesforce.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to a Salesforce object here',
		description: 'Placeholder of the Salesforce try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.salesforce.unfurl-dialog-success',
		defaultMessage: 'This is what it looks like when you add a Salesforce object to an idea.',
		description: 'Success message of Salesforce the try-it-now unfurl dialog',
	},
});
