import type { ColumnId } from '../../common/types';
import { getFixedColumns, getHoveredColumn, getResizingColumn, isHoveredColumn } from './columns';
import { createHigherLevelHook, createSelectorHook } from './utils';

export const useFixedColumns = createSelectorHook(getFixedColumns);

export const useHoveredColumn = createSelectorHook(getHoveredColumn);

export const useIsHoveredColumn = createHigherLevelHook<boolean, [ColumnId]>(isHoveredColumn);

export const useResizingColumn = createSelectorHook(getResizingColumn);
