import type { CustomItemProps } from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { gridSize, fontSize } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

const spacingUnit = gridSize();
const fontSizeUnit = fontSize();

export const getViewsItemStyles: CustomItemProps['cssFn'] = (currentStyles) => ({
	...currentStyles,
	display: 'flex',
	boxSizing: 'border-box',
	padding: `${token('space.100', '8px')} ${token('space.075', '6px')} ${token('space.075', '6px')}`,
	backgroundColor: 'transparent',
	border: '2px solid transparent',
	lineHeight: (spacingUnit * 2.5) / fontSizeUnit,
	color: token('color.text', colors.N800),
	'@keyframes fadeIn': {
		from: { opacity: 0 },
		to: { opacity: 1 },
	},
	'&:hover': {
		color: token('color.text', colors.N800),
		backgroundColor: 'transparent',
		cursor: 'pointer',
		textDecoration: 'underline',
	},
	'&:focus, &:active': {
		color: token('color.text', colors.N800),
		outline: 'none',
		boxShadow: 'none',
		backgroundColor: 'transparent',
	},
	'& [data-item-elem-after]': {
		flex: '1',
	},
	'& [data-item-elem-after] .buttons-start': {
		opacity: '0',
	},
	'&:hover [data-item-elem-after] .buttons-start': {
		animation: 'fadeIn 150ms',
		animationDelay: '300ms',
		animationFillMode: 'forwards',
	},
	'& [data-item-elem-after] .buttons-end': {
		opacity: '0',
		transition: 'opacity 50ms',
	},
	'&:hover [data-item-elem-after] .buttons-end': {
		opacity: '1',
	},
	'& span': {
		flexGrow: 0,
		overflowX: 'hidden',
	},
	'&[aria-disabled="true"]': {
		cursor: 'not-allowed',
		backgroundColor: 'transparent!important',
		textDecoration: 'none',
	},
	'& [data-item-title]:hover': {
		textDecoration: 'underline',
	},
	'& button': {
		height: '24px',
		width: '24px',
	},
	'& > span, & > div': {
		display: 'grid',
		gridAutoFlow: 'column',
	},
});
