import React from 'react';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useMatrixAxisFieldOptions } from '../../../view-content/common/utils/matrix-axis-field-options';
import { Values } from '../../common/field-values';

export const MatrixAxisFieldOptions = ({
	field,
	dimension,
}: {
	field: Field;
	dimension: string;
}) => {
	const { setFieldValues } = useViewActions();
	const matrixOptions = useMatrixAxisFieldOptions(field, true, dimension);
	const isContinuousAxis =
		field.type === FIELD_TYPES.NUMBER ||
		field.type === FIELD_TYPES.SLIDER ||
		field.type === FIELD_TYPES.FORMULA ||
		field.type === FIELD_TYPES.LINKED_ISSUES ||
		field.type === FIELD_TYPES.ISSUE_COMMENTS ||
		field.type === FIELD_TYPES.VOTES;
	return (
		<Values
			isDisabledDnD={isContinuousAxis}
			field={field}
			options={matrixOptions}
			setOptions={(options) => setFieldValues(options, dimension)}
		/>
	);
};
