import { createStateHook } from '@atlassian/react-sweet-state';
import { Reason, type State } from '../types';
import { store, useIssueRefreshServiceStore } from './context';

export const useIssueRefreshServiceActions = () => {
	const [, { setEvent, setEventAsReported }] = useIssueRefreshServiceStore();
	return [undefined, { setEvent, setEventAsReported }] as const;
};

export const useIssueRefreshData = () => {
	const [
		{
			events: { REALTIME },
		},
	] = useIssueRefreshServiceStore();
	return { REALTIME };
};

export const useOnDemandIssueRefreshTime = () => {
	const [
		{
			events: { ON_DEMAND },
		},
	] = useIssueRefreshServiceStore();
	return ON_DEMAND?.timestamp || 0;
};

const getSoftRefreshEvent = (state: State) => state.events[Reason.SoftRefresh];
export const useSoftRefreshIssueRefreshData = createStateHook(store, {
	selector: getSoftRefreshEvent,
});
