import type { JiraFieldOptionWeight } from '../../field-option/types';

/**
 * field value for all option-types (single, multi select)
 *
 * Even though the Jira response would provide us with the option value (label), we do not
 * store it on the field value - Options are mutable, and the source of truth for the id -> label mapping
 * is in the field store;
 */
export type OptionFieldValue = {
	id: string;
};

export const WEIGHT_TYPE_RATING = 'RATING';
export const WEIGHT_TYPE_NUMBER = 'NUMBER';

export type WeightType = typeof WEIGHT_TYPE_RATING | typeof WEIGHT_TYPE_NUMBER;

export type OptionFieldConfiguration = {
	/**
	 * The option weight type - default value is undefined - used with the SINGLE_SELECT AND MULTI_SELECT field types.
	 */
	optionWeightType?: WeightType;
	optionWeights?: JiraFieldOptionWeight[];
	optionAutoFormatDisabled?: boolean;
};
