import { createSelector } from 'reselect';
import find from 'lodash/fp/find';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { getCurrentViewDraft, isCurrentViewAutosaveEnabled } from './view/autosave/index.tsx';
import { getCurrentView } from './view/current/index.tsx';

export const createGetCurrentViewFieldRollup = (fieldKey: FieldKey) =>
	createSelector(
		getCurrentView,
		getCurrentViewDraft,
		isCurrentViewAutosaveEnabled,
		(view, draft, isAutosaveEnabled) => {
			const currentView = isAutosaveEnabled ? view : draft;
			return find(['fieldKey', fieldKey], currentView?.fieldRollups)?.rollup;
		},
	);
