import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expandButtonLabel: {
		id: 'polaris-ideas.view-header.fullscreen.expand-button-label',
		defaultMessage: 'Expand',
		description: 'Tooltip text displayed on hover for the expand icon button',
	},
	collapseButtonLabel: {
		id: 'polaris-ideas.view-header.fullscreen.collapse-button-label',
		defaultMessage: 'Collapse',
		description: 'Tooltip text displayed on hover for the collapse icon button',
	},
});
