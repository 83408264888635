import React, { useMemo, useCallback, useState, useEffect } from 'react';
import noop from 'lodash/noop';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { Box, Pressable, Stack, xcss } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { Draggable, useDroppableEvents } from '@atlassian/jira-polaris-lib-dnd/src/ui/index.tsx';
import { SortFieldComponent } from './field';
import { ConfigSort as ConfigSortLegacy } from './legacy';
import { messages } from './messages';

type Props = {
	fields: Field[];
	sortFields?: SortField[];
	onReset: () => void;
	onSave: (sortBy: SortField[]) => void;
};

// prevents infinite loop in useEffect, `sortFields = []` would result in the array reference changing on each render
const DEFAULT_SORT_FIELDS: SortField[] = [];

export const ConfigSort = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	({ fields, sortFields = DEFAULT_SORT_FIELDS, onSave, onReset }: Props) => {
		const { formatMessage } = useIntl();
		const canManageCurrentView = useCanManageCurrentView();
		const canManageViews = useCanManageViews();

		const [sortFieldsLocal, setSortFieldsLocal] = useState<SortField[]>([]);
		const [fieldsLocal, setfieldsLocal] = useState<Field[]>([]);

		const isDisabled = useMemo(() => {
			if (fg('polaris_just-for-you')) {
				if (ff('polaris.view-permissions_plaoi')) {
					return !canManageCurrentView;
				}

				return !canManageViews;
			}

			return false;
		}, [canManageCurrentView, canManageViews]);

		// Optimistically save sortFields.
		useEffect(() => {
			setSortFieldsLocal(sortFields);
		}, [sortFields]);

		// Optimistically save fields.
		useEffect(() => {
			setfieldsLocal(fields);
		}, [fields]);

		const unusedFields = useMemo(() => {
			const usedFieldKeys = new Set(sortFieldsLocal.map((sf) => sf.fieldKey));
			const ret = fieldsLocal.filter((f: Field) => !usedFieldKeys.has(f.key));

			ret.sort((a, b) => a.label.localeCompare(b.label));
			return ret;
		}, [fieldsLocal, sortFieldsLocal]);

		const onAddNewField = useCallback(
			(field: Field, asc = true) => {
				const newFields = [...sortFieldsLocal, { fieldKey: field.key, asc }];
				onSave(newFields);
				fireCompoundAnalyticsEvent.RightSidebarViewSortFieldAdded();
			},
			[onSave, sortFieldsLocal],
		);

		const onToggleDirection = useCallback(
			(fieldKey: FieldKey) => {
				const newFields = sortFieldsLocal.map((sortField) => {
					if (fieldKey === sortField.fieldKey) {
						return {
							...sortField,
							asc: !sortField.asc,
						};
					}

					return sortField;
				});
				// Optimistic save
				setSortFieldsLocal(newFields);
				onSave(newFields);
				fireCompoundAnalyticsEvent.RightSidebarViewSortDirectionChanged();
			},
			[onSave, sortFieldsLocal],
		);

		const onChangeField = useCallback(
			(field: Field, fieldKey: FieldKey) => {
				const newFields = sortFieldsLocal.map((sortField) => {
					if (fieldKey === sortField.fieldKey) {
						return {
							fieldKey: field.key,
							asc: sortField.asc,
						};
					}
					return sortField;
				});
				// Optimistic save
				setSortFieldsLocal(newFields);
				onSave(newFields);
				fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsChanged();
			},
			[onSave, sortFieldsLocal],
		);

		const onClearSort = useCallback(
			(fieldKey: FieldKey) => {
				const newFields = sortFieldsLocal.filter(({ fieldKey: key }) => key !== fieldKey);
				// Clear view sort if no sort fields left update otherwise
				if (newFields.length) {
					onSave(newFields);
					fireCompoundAnalyticsEvent.RightSidebarViewSortFieldRemoved();
				} else {
					onReset();
					fireCompoundAnalyticsEvent.RightSidebarViewSortReset();
				}
			},
			[onReset, onSave, sortFieldsLocal],
		);

		const handleSort = useCallback(
			({ srcIdx, dstIdx }: { srcIdx: number; dstIdx: number }) => {
				// outside the sortFieldsLocal list
				if (dstIdx < 0 || dstIdx >= sortFieldsLocal.length + 1) {
					return;
				}

				const newSortFields = [...sortFieldsLocal];
				const [removed] = newSortFields.splice(srcIdx, 1);
				newSortFields.splice(dstIdx, 0, removed);

				// Optimistic save
				setSortFieldsLocal(newSortFields);
				// Save to the server
				onSave(newSortFields);
				fireCompoundAnalyticsEvent.RightSidebarViewSortFieldsReordered();
			},
			[onSave, sortFieldsLocal],
		);

		const onSort = useCallback(
			({ srcId, dstId, edge }: { srcId: string; dstId: string; edge: Edge }) => {
				const srcIdx = sortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === srcId);
				const dstIdx = sortFieldsLocal.findIndex(({ fieldKey }) => fieldKey === dstId);

				let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
				dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;

				handleSort({ srcIdx, dstIdx: dstIdx + dstAdj });
			},
			[handleSort, sortFieldsLocal],
		);

		useDroppableEvents({
			onSort,
		});

		return (
			<Stack alignBlock="start" xcss={containerStyles}>
				<Box paddingBlockStart="space.200" paddingBlockEnd="space.150" paddingInline="space.200">
					{isDisabled ? (
						<Box xcss={sectionStyles}>{formatMessage(messages.sortDisabledText)}</Box>
					) : (
						formatMessage(messages.helpText)
					)}
				</Box>
				<Stack>
					{sortFieldsLocal.map(({ asc, fieldKey }, idx) => {
						const selectedField = fieldsLocal.find(({ key }) => fieldKey === key);
						const labelMessage = idx ? messages.sortThenLabel : messages.sortFirstLabel;

						return (
							<Draggable key={fieldKey} id={fieldKey} isDragDisabled={isDisabled}>
								<SortFieldComponent
									key={fieldKey}
									selectedField={selectedField}
									asc={asc}
									fields={unusedFields}
									label={formatMessage(labelMessage)}
									onClearSort={() => onClearSort(fieldKey)}
									onChangeField={(newField) => onChangeField(newField, fieldKey)}
									onClickDirection={() => onToggleDirection(fieldKey)}
									isDisabled={isDisabled}
									isHoverable={!isDisabled}
								/>
							</Draggable>
						);
					})}
					{!isDisabled && !!unusedFields.length && (
						<SortFieldComponent
							fields={unusedFields}
							asc
							label={formatMessage(
								sortFieldsLocal.length ? messages.sortThenLabel : messages.sortFirstLabel,
							)}
							onChangeField={(newField) => onAddNewField(newField)}
							onClickDirection={noop}
							isHoverable={false}
						/>
					)}
				</Stack>
				{!isDisabled && !!sortFieldsLocal.length && (
					<Box paddingBlock="space.050" paddingInline="space.200">
						<Pressable
							id="polaris-ideas.ui.view-controls.config-sort.reset-sort-button"
							onClick={onReset}
							xcss={resetButtonStyles}
						>
							{formatMessage(messages.resetButton)}
						</Pressable>
					</Box>
				)}
			</Stack>
		);
	},
	ConfigSortLegacy,
);

const containerStyles = xcss({
	height: '100%',
});

const resetButtonStyles = xcss({
	padding: '0',
	background: 'none',
	lineHeight: '2em',
	':hover': {
		textDecoration: 'underline',
	},
});

const sectionStyles = xcss({
	padding: 'space.150',
	backgroundColor: 'color.background.information',
});
