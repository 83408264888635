import { ff } from '@atlassian/jira-feature-flagging';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';

export const toggleSelfServeAddAsSiteCreator =
	(): Action<State, Props> =>
	async ({ getState, setState }, { siteRemote }) => {
		const { editionMeta } = getState();
		const { isSelfServeEnabledOnSite } = editionMeta;

		if (ff('polaris.beta-migration-handling')) {
			setState({
				editionMeta: {
					...getState().editionMeta,
					isSelfServeEnabledOnSite: !isSelfServeEnabledOnSite,
				},
			});
			siteRemote.setSelfServeOnSiteEnabled(!isSelfServeEnabledOnSite).catch(() => {
				setState({
					editionMeta: {
						...getState().editionMeta,
						isSelfServeEnabledOnSite,
					},
				});
			});
		}
	};
