import { createSelector } from 'reselect';
import type { State } from '../types';

export const getProjectFieldsFlow = (state: State) => state.projectFields;

export const getExploreSectionMessageDismissed = createSelector(
	getProjectFieldsFlow,
	(projectFields) => projectFields.exploreSectionMessageDismissed,
);

export const getProjectFieldsPageViewed = createSelector(
	getProjectFieldsFlow,
	(projectFields) => projectFields.pageViewed,
);

export const getSeenPulsatingFieldsButton = createSelector(
	getProjectFieldsFlow,
	(projectFields) => projectFields.seenPulsatingFieldsButton,
);

export const getTourDismissed = createSelector(
	getProjectFieldsFlow,
	(projectFields) => projectFields.tourDismissed,
);

export const getTourSpotlightStep = createSelector(
	getProjectFieldsFlow,
	(projectFields) => projectFields.tourSpotlightStep,
);
