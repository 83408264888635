import { createSelector } from 'reselect';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import { getFields } from '../../fields';
import { getCurrentViewDraft, isCurrentViewAutosaveEnabled } from '../autosave';
import { getCurrentView } from '../current';

export const getCurrentViewMatrixConfig = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		isAutosaveEnabled ? view?.matrixConfig : draft?.matrixConfig,
);

const getCurrentViewAxes = createSelector(
	getCurrentViewMatrixConfig,
	(matrixConfig) => matrixConfig?.axes,
);

export const getCurrentViewXAxisField = createSelector(
	getFields,
	getCurrentViewAxes,
	(fields, axes) => {
		const axisField = axes?.find((axis) => axis.dimension === 'x')?.field;

		if (axisField && fields[axisField.key]) {
			return axisField;
		}
	},
);

export const getCurrentViewXAxis = createSelector(getCurrentViewXAxisField, (field) => field?.key);

export const getCurrentViewYAxisField = createSelector(
	getFields,
	getCurrentViewAxes,
	(fields, axes) => {
		const axisField = axes?.find((axis) => axis.dimension === 'y')?.field;

		if (axisField && fields[axisField.key]) {
			return axisField;
		}
	},
);

export const getCurrentViewYAxis = createSelector(getCurrentViewYAxisField, (field) => field?.key);

export const getCurrentViewZAxisField = createSelector(
	getFields,
	getCurrentViewAxes,
	(fields, axes) => {
		const axisField = axes?.find((axis) => axis.dimension === 'z')?.field;

		if (axisField && fields[axisField.key]) {
			return axisField;
		}
	},
);

export const getCurrentViewZAxis = createSelector(getCurrentViewZAxisField, (field) => field?.key);

export const getMatrixViewConfigured = createSelector(
	getCurrentViewXAxis,
	getCurrentViewYAxis,
	(xAxis, yAxis) => xAxis !== undefined && yAxis !== undefined,
);

const EMPTY_GROUPS: Array<GroupValue> = [];
export const getMatrixFieldOptions = (dimension: string) =>
	createSelector(
		getCurrentViewAxes,
		(axes) => axes?.find((axis) => axis.dimension === dimension)?.fieldOptions || EMPTY_GROUPS,
	);

export const getMatrixOptionsHasReverseOrder = (dimension: string) =>
	createSelector(
		getCurrentViewAxes,
		(axes) => axes?.find((axis) => axis.dimension === dimension)?.reversed || false,
	);
