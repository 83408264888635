import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useOnboardingFlowsActions } from '../../../controllers';
import { useTimelineSectionMessageDismissed } from '../../../controllers/selectors/timeline-view-spotlight-hooks';
import { messages } from './messages';

export const TimelineSectionMessage = () => {
	const { formatMessage } = useIntl();
	const isTimelineSectionMessageDismissed = useTimelineSectionMessageDismissed();
	const { loadTimelineSectionMessageDismissed, setTimelineSectionMessageDismissed } =
		useOnboardingFlowsActions();

	useEffect(() => {
		loadTimelineSectionMessageDismissed();
	}, [loadTimelineSectionMessageDismissed]);

	if (isTimelineSectionMessageDismissed !== false) {
		return null;
	}

	return (
		<SectionMessageWrapper>
			<SectionMessage
				appearance="discovery"
				actions={[
					<SectionMessageAction key={messages.timelineFieldsSpotlightOkBtn.id}>
						<Button
							spacing="compact"
							appearance="default"
							onClick={setTimelineSectionMessageDismissed}
						>
							{formatMessage(messages.timelineFieldsSpotlightOkBtn)}
						</Button>
					</SectionMessageAction>,
					<SectionMessageAction key={messages.timelineFieldsSpotlightLearnMore.id}>
						<Box xcss={okSectionButtonStyles}>
							<Button
								appearance="link"
								spacing="none"
								href="https://support.atlassian.com/jira-product-discovery/docs/configure-autofill-dates/"
								target="_blank"
							>
								{formatMessage(messages.timelineFieldsSpotlightLearnMore)}
							</Button>
						</Box>
					</SectionMessageAction>,
				]}
			>
				<Text>{formatMessage(messages.timelineFieldsSpotlightText)}</Text>
			</SectionMessage>
		</SectionMessageWrapper>
	);
};

const okSectionButtonStyles = xcss({
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionMessageWrapper = styled.div({
	margin: `0 ${token('space.200', '16px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > section > div > div:first-child': {
		display: 'none',
	},
});
