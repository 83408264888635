import {
	createFieldHook as createFieldHookMain,
	createHigherLevelFieldHook as createHigherLevelFieldHookMain,
} from '../main';
import type { State, Props } from '../types';

export const createFieldHook = createFieldHookMain;

export const createHigherLevelFieldHook = <TArg1, TReturnValue>(
	selectorCreator: (arg1: TArg1) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1) => TReturnValue) =>
	createHigherLevelFieldHookMain<TReturnValue, [TArg1]>(selectorCreator);

export const createHigherLevelFieldHook2 = <TArg1, TArg2, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2) => TReturnValue) =>
	createHigherLevelFieldHookMain<TReturnValue, [TArg1, TArg2]>(selectorCreator);

export const createHigherLevelFieldHook3 = <TArg1, TArg2, TArg3, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
		arg3: TArg3,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2, arg3: TArg3) => TReturnValue) =>
	createHigherLevelFieldHookMain<TReturnValue, [TArg1, TArg2, TArg3]>(selectorCreator);
