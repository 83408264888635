import every from 'lodash/every';
import { ff } from '@atlassian/jira-feature-flagging';
import { timestampAggDateToInterval } from '@atlassian/jira-polaris-domain-delivery/src/dates/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	RANGE_ROLLUP,
	MIN_ROLLUP,
	MAX_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import { type Filter, MONTH } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { externalReferenceMapping } from '../external-reference';
import { stringMapping } from '../string';
import type { FieldMapping, MappingFilterFunction } from '../types';
import { createFilterFunction } from './filter';
import { formatIntervalNext, tryParseIntervalString } from './utils';

export const intervalStringMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
	allFields: Field[],
): FieldMapping<string> => {
	const basicStringMapping = stringMapping(issuesRemote, field);

	const atlasProjectField =
		field.configuration?.source === INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE &&
		allFields.find((f) => f.type === FIELD_TYPES.ATLAS_PROJECT);

	const valueAccessor: FieldMapping<string>['valueAccessor'] = (state, props, localIssueId) => {
		if (atlasProjectField && ff('polaris.atlas-project-as-interval-field-source')) {
			const externalReferenceValue = externalReferenceMapping(
				issuesRemote,
				atlasProjectField,
			).valueAccessor(state, props, localIssueId);
			if (typeof externalReferenceValue === 'string') {
				const entity = state.properties.externalReferenceEntities[externalReferenceValue];
				switch (field.configuration?.source) {
					case INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE:
						if (
							entity &&
							entity.dueDate?.dateRange?.start &&
							entity.dueDate?.dateRange?.end &&
							entity.state?.value !== 'pending'
						) {
							return JSON.stringify({
								start: new Date(entity.dueDate.dateRange.start).toISOString().slice(0, 10),
								end: new Date(entity.dueDate.dateRange.end).toISOString().slice(0, 10),
							});
						}
						break;
					default:
					// do nothing
				}
			}
		}

		if (field.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE) {
			const customFieldKey = field?.configuration?.customfieldKey;
			const aggregationType = field?.configuration?.aggregationType;

			if (customFieldKey && aggregationType) {
				const value = state.properties.aggregatedDeliveryDates[localIssueId]?.find(
					(v) =>
						v.fieldKey === customFieldKey &&
						v.aggregationType.toLowerCase() === aggregationType?.toLowerCase(),
				);

				const intervalValue = timestampAggDateToInterval(value, props?.locale, props?.timezone);

				if (intervalValue) {
					return JSON.stringify(intervalValue);
				}
			}
		}

		return basicStringMapping.valueAccessor(state, props, localIssueId);
	};

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		localIssueId,
	) => {
		const value = valueAccessor(state, props, localIssueId);
		return value ? formatIntervalNext(value) : '';
	};

	return {
		...basicStringMapping,
		getDependencyValues: (state) => {
			if (field.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE) {
				return state.properties.aggregatedDeliveryDates;
			}

			return state.properties.externalReferenceEntities;
		},
		valueAccessor,
		valueAccessorToExport,
		valueAccessorRealValues: basicStringMapping.valueAccessor,
		getValueFromJiraIssue: (issue) => {
			if (issue.fields[field.key] === null) {
				return undefined;
			}
			const interval = tryParseIntervalString(issue.fields[field.key]);
			return interval ? JSON.stringify(interval) : undefined;
		},
		getFilter: (filter: Filter): MappingFilterFunction<string> | undefined => {
			if (filter.type === 'INTERVAL' && filter.field === field.key) {
				const filterFunctions = filter.values.map(createFilterFunction(MONTH));
				return (value: undefined | string, state, props, localIssueId) =>
					every(filterFunctions, (filterFunction) =>
						filterFunction(value, state, props, localIssueId),
					);
			}
			return undefined;
		},
		getRollupOperations: () => [RANGE_ROLLUP, MIN_ROLLUP, MAX_ROLLUP],
	};
};
