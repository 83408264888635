import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { abortAttachmentInUpload, addAttachment, addAttachmentInUpload } from './add-attachment';
import * as commentsActions from './comments';
import { deleteAttachment, removeAttachments } from './delete-attachment';
import { loadDeliveryTickets, updateDeliveryTickets } from './delivery-tickets';
import { saveDescriptionDraft, loadDescriptionDraft, clearDescriptionDraft } from './draft';
import {
	setFieldSidebarFieldKey,
	setFieldSidebarMode,
	setFieldSidebarOptionId,
	setFieldSidebar,
	resetFieldSidebarMode,
} from './fields-sidebar';
import { loadIssue } from './load-issue';
import { refreshIssueLinks } from './refresh-issue-links';
import { closeSummaryEditor, setSummaryEditing } from './summary';
import { unlinkIssue, removeIssueFromIssueLinks } from './unlink-issue';
import { watchIssue, unWatchIssue, toggleIssueWatching } from './watchers';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	loadIssue,
	refreshIssueLinks,
	unlinkIssue,
	removeIssueFromIssueLinks,
	addAttachment,
	addAttachmentInUpload,
	abortAttachmentInUpload,
	deleteAttachment,
	removeAttachments,
	saveDescriptionDraft,
	loadDescriptionDraft,
	clearDescriptionDraft,
	loadDeliveryTickets,
	setFieldSidebarFieldKey,
	setFieldSidebarMode,
	setFieldSidebarOptionId,
	setFieldSidebar,
	resetFieldSidebarMode,
	watchIssue,
	unWatchIssue,
	toggleIssueWatching,
	updateDeliveryTickets,
	setSummaryEditing,
	closeSummaryEditor,
	...commentsActions,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
