import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	authErrorHeader: {
		id: 'issue-view-errors.authentication-error-view.auth-error-header',
		defaultMessage: 'Log in to Jira to see this issue',
		description:
			'Main title for issue authentication error screen, explaining that the user needs to log in to be able to see this issue.',
	},
	authButton: {
		id: 'issue-view-errors.authentication-error-view.auth-button',
		defaultMessage: 'Log in',
		description:
			'Primary action button for the issue authentication screen, leads to a log in experience',
	},
});
