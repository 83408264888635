import { createSelector } from 'reselect';
import type { AttachmentId } from '@atlassian/jira-issue-view-common-types/src/attachment-type';
import { attachmentsSelector } from './attachment-selector';

const attachmentPickerSelector = createSelector(
	attachmentsSelector,
	(attachments) => attachments.picker,
);

export const uploadedAttachmentsByIdSelector = createSelector(
	attachmentPickerSelector,
	// @ts-expect-error - TS2339 - Property 'uploadedAttachments' does not exist on type 'AttachmentPickerState'.
	(attachmentPicker) => attachmentPicker.attachments || attachmentPicker.uploadedAttachments,
);

export const isAttachmentPickerPopupOpenSelector = createSelector(
	attachmentPickerSelector,
	(attachmentPicker) => attachmentPicker.isPopupOpen,
);

export const uploadedAttachmentSelector = (attachmentId: AttachmentId) =>
	createSelector(uploadedAttachmentsByIdSelector, (attachments) => attachments[attachmentId]);
