import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const PinFilled = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
		<path
			fill="currentColor"
			d="M5.48121736,7.00404408 L7.59617451,4.89248498 C7.83826329,4.65078605 7.83826329,4.2589269 7.59617451,4.01722797 L7.45004745,3.8713518 C7.28862892,3.71022438 7.28862892,3.44898234 7.45004745,3.28784713 L8.61890792,2.12084557 C8.78032645,1.95971814 9.04199766,1.95971814 9.20333816,2.12084557 L13.8789361,6.78886738 C14.0403546,6.94999481 14.0403546,7.21123684 13.8789361,7.37239542 L12.7100756,8.53937361 C12.5486571,8.70053219 12.2869859,8.70053219 12.1255674,8.53937361 L11.9795183,8.39348187 C11.7374295,8.15178294 11.3449227,8.15178294 11.102834,8.39348187 L8.9878534,10.5050565 C8.74576463,10.7467555 8.74576463,11.1385523 8.9878534,11.3802512 L9.57236166,11.9638182 C9.73370217,12.1248989 9.73370217,12.3861487 9.57236166,12.5473073 L8.40342317,13.7142855 C8.24208266,13.8754441 7.98041145,13.8754441 7.81899293,13.7142855 L5.48655377,11.3856258 L2.99050379,13.8776251 C2.91356278,13.9544266 2.8093702,13.9978903 2.70055118,13.9985135 L2.42647516,14 C2.20319644,14.0012397 2.01932444,13.8251258 2.0112106,13.6023547 L2.00027253,13.3019252 C1.99610639,13.187346 2.03985869,13.076272 2.12104386,12.9951864 L4.60989281,10.5103532 L2.26677303,8.17102227 C2.10538571,8.00986368 2.10538571,7.74861386 2.26677303,7.58753317 L3.43565691,6.42050825 C3.59705203,6.25938082 3.85871544,6.25938082 4.02010275,6.42050825 L4.6045486,7.00404408 C4.84663737,7.245743 5.23912858,7.245743 5.48121736,7.00404408 Z"
		/>
	</svg>
);
