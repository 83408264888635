import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import type {
	AggResponseData,
	AGGPermissionLevel,
	AGGCommentUserNode,
} from '@atlassian/jira-issue-agg-field-transformers/src/common/types/issue-type.tsx';
import {
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	COMMENT_VISIBILITY_TYPE_GROUP,
	COMMENT_VISIBILITY_TYPE_ROLE,
	type RawComment,
	type RawUser,
	type CommentVisibility,
} from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types';

export const COMMENT_VISIBILITY_PUBLIC: CommentVisibility = {
	type: COMMENT_VISIBILITY_TYPE_PUBLIC,
	value: '',
};

const logError = (msg: string) =>
	log.safeErrorWithoutCustomerData('issue.agg-transformers.comments', msg);

export const transformAggCommentsToLegacyGira = (data: AggResponseData): RawComment[] => {
	const edges = data.jira.issueByKey?.comments?.edges;
	if (!edges) {
		return [];
	}
	const comments = edges.reduce((acc: RawComment[], edge) => {
		const { node } = edge ?? {};
		if (!node) {
			logError('Comment was null or undefined');
			return acc;
		}

		const comment: RawComment = {
			id: node.commentId,
			author: transformAggUser(node.author),
			updateAuthor: transformAggUser(node.updateAuthor),
			visibility: transformAggPermissionToCommentVisibility(node.permissionLevel),
			createdDate: node.created,
			updatedDate: node.updated || '',
			bodyAdf: node.richText?.adfValue?.json,
			bodyHtml: '',
			isInternal: !!(node.visibility && node.visibility !== 'VISIBLE_TO_HELPSEEKER'),
			edited: !!node.updated && node.updated !== node.created,
		};
		if (node.authorCanSeeRequest !== undefined && node.authorCanSeeRequest !== null) {
			comment.jsdAuthorCanSeeRequest = node.authorCanSeeRequest;
		}
		if (ff('jsm-timelines-phase-2_70gja')) {
			comment.eventOccurredAt = node.eventOccurredAt;
			comment.jsdIncidentActivityViewHidden = node.jsdIncidentActivityViewHidden;
		}

		acc.push(comment);
		return acc;
	}, []);

	return comments;
};

/**
 * Transforms JiraCommentVisibilities from the Gira Endpoint
 */
export const transformAggPermissionToCommentVisibility = (
	permissionLevel: AGGPermissionLevel,
): CommentVisibility => {
	if (!permissionLevel) {
		return COMMENT_VISIBILITY_PUBLIC;
	}
	if (permissionLevel.role) {
		return {
			type: COMMENT_VISIBILITY_TYPE_ROLE,
			value: permissionLevel.role.name || '',
		};
	}
	if (permissionLevel.group) {
		return {
			type: COMMENT_VISIBILITY_TYPE_GROUP,
			groupId: permissionLevel.group.groupId,
			value: permissionLevel.group.name,
		};
	}
	return COMMENT_VISIBILITY_PUBLIC;
};

/**
 * Transforms user data from the AGG endpoint.
 */
export const transformAggUser = (user: AGGCommentUserNode | null | undefined): RawUser | null => {
	if (!user) return null;
	const { accountId, name, picture } = user;

	return {
		avatarUrl: picture,
		displayName: name,
		id: accountId,
	};
};
