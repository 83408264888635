import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	numInsightsLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.data-options.num-insights-label',
		defaultMessage: 'Number of insights',
		description: 'Label for a number of insights field',
	},
	numInsightsHelpText: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.data-options.num-insights-help-text',
		defaultMessage: 'The number of insights added to the idea',
		description: 'Help message for the number of insights',
	},
	appsNumInsightsHelpText: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.data-options.apps-num-insights-help-text',
		defaultMessage: 'The number of insights from {appName} added to the idea',
		description: 'Help message for the number of insights',
	},
	impactTypeHelpText: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.data-options.impact-type-help-text',
		defaultMessage: 'A roll-up of the impact of each insights added to the idea',
		description: 'Help message',
	},
	insightValueTypeHelpText: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.data-options.insight-value-type-help-text',
		defaultMessage: 'A roll-up of this property value for each insight added to the idea',
		description: 'Help message',
	},
});
