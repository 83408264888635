import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	useTimelineDateFieldsKeys,
	useTimelineDuration,
} from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCanCreateFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { TimelineSectionMessage } from '@atlassian/jira-polaris-lib-onboarding-flows/src/ui/timeline/section-message/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { DatePicker } from '../date-picker';
import { FieldSelection } from '../field-selection';
import { ModeToggle } from '../mode-toggle';
import { messages } from './messages';

export const TimelineConfiguration = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const canCreateField = useCanCreateFields();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();
	const { startDate, endDate } = useTimelineDuration();
	const {
		setStartDateField,
		setEndDateField,
		setStartTimelineIntervalDate,
		setEndTimelineIntervalDate,
	} = useViewActions();
	const isDateFieldsSelected = startDateFieldKey && endDateFieldKey;

	const onStartDateFieldSelected = useCallback(
		(fieldKey: string) => {
			setStartDateField(fieldKey);
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
				'startDateField',
				{
					selectedIssueField: fieldKey,
				},
			);
		},
		[createAnalyticsEvent, setStartDateField],
	);

	const onEndDateFieldSelected = useCallback(
		(fieldKey: string) => {
			setEndDateField(fieldKey);
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
				'targetDateField',
				{
					selectedIssueField: fieldKey,
				},
			);
		},
		[createAnalyticsEvent, setEndDateField],
	);

	return (
		<>
			{ff('polaris.new-timeline-config-for-autofill-date-fields') &&
				isDateFieldsSelected &&
				canCreateField && <TimelineSectionMessage />}
			{!isDateFieldsSelected && (
				<HelpText>{formatMessage(messages.timelineFieldsHelpText)}</HelpText>
			)}
			<DateFieldsFieldGroup
				isNewConfigOn={ff('polaris.new-timeline-config-for-autofill-date-fields')}
			>
				<FieldSelection
					testId="polaris-ideas.ui.view-controls.config-timeline.timeline-configuration.start-date-btn"
					setDateField={onStartDateFieldSelected}
					fieldKey={startDateFieldKey}
					disabledFieldKey={endDateFieldKey}
					message={formatMessage(messages.timelineStartField)}
				/>
				<FieldSelection
					testId="polaris-ideas.ui.view-controls.config-timeline.timeline-configuration.end-date-btn"
					setDateField={onEndDateFieldSelected}
					fieldKey={endDateFieldKey}
					disabledFieldKey={startDateFieldKey}
					message={formatMessage(messages.timelineEndField)}
				/>
			</DateFieldsFieldGroup>
			{isDateFieldsSelected && (
				<>
					<Divider />
					<ConfigTitle>{formatMessage(messages.timelineRangeLabel)}</ConfigTitle>
					<HelpText>{formatMessage(messages.timelineRangeHelpText)}</HelpText>
					<FieldGroup>
						<FieldSelectContainer>
							<FieldName>{formatMessage(messages.timelineRangeStart)}</FieldName>
							<DatePicker
								setIntervalDate={setStartTimelineIntervalDate}
								date={startDate}
								selectedRangeDate={endDate}
								dateType="start"
							/>
						</FieldSelectContainer>
						<FieldSelectContainer>
							<FieldName>{formatMessage(messages.timelineRangeEnd)}</FieldName>
							<DatePicker
								setIntervalDate={setEndTimelineIntervalDate}
								date={endDate}
								selectedRangeDate={startDate}
								dateType="end"
							/>
						</FieldSelectContainer>
					</FieldGroup>
					<HelpText>{formatMessage(messages.timelineScaleHelpText)}</HelpText>
					<ModeToggle />
				</>
			)}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled.div({
	width: 'auto',
	height: '2px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('color.background.selected', colors.N30A),
	alignSelf: 'stretch',
	flexGrow: 0,
	margin: `0 ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigTitle = styled.div({
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	padding: `0 ${token('space.200', '16px')}`,
	marginTop: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N700),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldSelectContainer = styled.div({
	width: '138px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldName = styled.div({
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelpText = styled.p({
	padding: `0 ${token('space.200', '16px')} 10px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldGroup = styled.div({
	margin: `${token('space.200', '16px')} 0`,
	padding: `0 ${token('space.200', '16px')}`,
	justifyContent: 'space-between',
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		width: '100%',
		textAlign: 'left',
		'&:hover': {
			width: '100%',
			textAlign: 'left',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateFieldsFieldGroup = styled(FieldGroup)<{ isNewConfigOn: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	flexDirection: ({ isNewConfigOn }) => (isNewConfigOn ? 'column' : 'row'),
});
