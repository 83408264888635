import React from 'react';
import JpdMatrixBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-matrix-blank';
import { useIntl } from '@atlassian/jira-intl';
import { messages as commonMessages } from '../../../common/messages';
import { Container, Wrapper } from '../../../common/ui/container/styled';
import { ViewEmptyState } from '../../../common/ui/view-empty-state';
import type { ViewKindEmptyStateProps } from '../../../types';
import { messages } from './messages';
import { AxisXPlaceholder, AxisYPlaceholder, MatrixViewPlaceholder } from './styled';

export type MatrixViewEmptyStateProps = ViewKindEmptyStateProps & {
	isXAxisActive: boolean;
	isYAxisActive: boolean;
};

export const MatrixViewEmptyState = ({
	onButtonClick,
	isHintHidden = false,
	isXAxisActive = false,
	isYAxisActive = false,
}: MatrixViewEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			{!isHintHidden && (
				<Wrapper>
					<ViewEmptyState
						renderImage={JpdMatrixBlankIllustrationWrapper}
						header={formatMessage(messages.header)}
						description={formatMessage(messages.description)}
						buttonText={formatMessage(commonMessages.buttonTextFields, {
							fieldsCount: 2,
						})}
						onButtonClick={onButtonClick}
					/>
				</Wrapper>
			)}
			<MatrixViewPlaceholder isActive={isXAxisActive || isYAxisActive}>
				<AxisYPlaceholder isActive={isYAxisActive} />
				<AxisXPlaceholder isActive={isXAxisActive} />
			</MatrixViewPlaceholder>
		</Container>
	);
};

const JpdMatrixBlankIllustrationWrapper = () => <JpdMatrixBlankIllustration alt="" width={306} />;
