import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { translateToPublicIssue } from '../util';
import type { GetIssueResponse, JpdJiraSearchApiResponse } from './types';

const SEARCH_ISSUES_JQL_URL = '/rest/polaris/issues/searchJql';

export const getIssuesWithJql = (jql: string): Promise<GetIssueResponse> =>
	performPostRequest<JpdJiraSearchApiResponse>(SEARCH_ISSUES_JQL_URL, {
		body: JSON.stringify({
			jql,
			archived: 'ACTIVE_ONLY',
			includeProjectData: true,
		}),
	}).then((response) => ({
		issues: response.issues.map((issue) => translateToPublicIssue(response, issue)),
	}));
