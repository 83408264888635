import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { User } from '../../../services/jira/get-user';
import type { State, CollabParticipant } from '../types';

export const presenceJoined =
	(joined: CollabParticipant[]) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const newParticipantSessionIds = joined.map(({ sessionId }) => sessionId);
		setState({
			presence: [
				...joined,
				...getState().presence.filter(
					({ sessionId }) => !newParticipantSessionIds.includes(sessionId),
				),
			],
		});
	};

export const updatePresence =
	(user: User | {}) =>
	({ getState, setState }: StoreActionApi<State>) => {
		setState({
			presence: getState().presence.map((participant) => {
				if ('userId' in user && participant.clientId === user.userId) {
					return {
						...participant,
						avatar: user.avatar,
						name: user.name,
						email: user.email,
					};
				}
				return participant;
			}),
		});
	};

export const presenceLeft =
	(left: { sessionId: string }[]) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const leftSessionIds = left.map(({ sessionId }) => sessionId);

		setState({
			presence: [
				...getState().presence.filter(({ sessionId }) => !leftSessionIds.includes(sessionId)),
			],
		});
	};
