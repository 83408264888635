import React, { type SyntheticEvent, useCallback, useMemo, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	BreadcrumbSkeleton,
	SkeletonIcon,
} from '@atlassian/jira-polaris-common/src/common/ui/skeleton';
import { shouldOpenInNewTab } from '@atlassian/jira-polaris-common/src/common/utils/events';
import {
	getDirectIssueLink,
	getIssueLink,
} from '@atlassian/jira-polaris-common/src/common/utils/issue-link';
import {
	useIsCollectionView,
	useIsSharedView,
} from '@atlassian/jira-polaris-common/src/controllers/environment';
import { usePreloadedIssueTypeIcon } from '@atlassian/jira-polaris-common/src/controllers/idea/selectors/hooks';
import {
	useSelectedIssueIssueType,
	useSelectedIssueKey,
	useSelectedIssueProject,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useCloseRightSidebar } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route';
import { CopyLink } from '@atlassian/jira-polaris-common/src/ui/copy-link';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const IssueKey = () => {
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();
	const issueKey = useSelectedIssueKey();
	const preloadedIssueTypeIcon = usePreloadedIssueTypeIcon();
	const issueType = useSelectedIssueIssueType();
	const closeRightSidebar = useCloseRightSidebar();
	const { openIssueView } = usePolarisRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectKey = useSelectedIssueProject()?.key;

	const linkUrl = useMemo(
		() => getIssueLink(projectKey, issueKey, isSharedView),
		[isSharedView, issueKey, projectKey],
	);
	const issueLinkUrl = getDirectIssueLink(issueKey, isSharedView);

	const issueTypeIcon = useMemo(
		() => issueType?.iconUrl || preloadedIssueTypeIcon,
		[preloadedIssueTypeIcon, issueType],
	);

	const onOpenIdeaOverlay = useCallback(
		(evt: SyntheticEvent) => {
			evt.preventDefault();
			if (!issueKey) return;

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if ((shouldOpenInNewTab(evt as MouseEvent) || isCollectionView) && linkUrl) {
				window.open(linkUrl, '_blank');
				return;
			}

			openIssueView(issueKey);

			closeRightSidebar();
		},
		[closeRightSidebar, isCollectionView, issueKey, linkUrl, openIssueView],
	);

	const onCopyLink = () => {
		fireCompoundAnalyticsEvent.IdeaView.copyIdeaLinkButtonClicked(createAnalyticsEvent({}));
	};

	if (issueKey === undefined) {
		return <BreadcrumbSkeleton />;
	}

	return issueTypeIcon === undefined ? (
		<BreadcrumbsItem
			testId="polaris-ideas.ui.idea-view.breadcrumbs.key.key"
			text={issueKey}
			iconBefore={
				<Box xcss={skeletonIconWrapperStyles}>
					<SkeletonIcon />
				</Box>
			}
		/>
	) : (
		<BreadcrumbsItemWrapper data-component-selector="breadcrumbs-item-wrapper-hX88">
			<BreadcrumbsItem
				testId="polaris-ideas.ui.idea-view.breadcrumbs.key.key"
				text={issueKey}
				iconBefore={<Avatar appearance="square" src={issueTypeIcon} size="xsmall" />}
				onClick={onOpenIdeaOverlay}
				href={issueLinkUrl}
				iconAfter={
					<LinkIconWrapper>
						<CopyLink
							size="small"
							appearance="subtle-link"
							onCopy={onCopyLink}
							url={issueLinkUrl}
						/>
					</LinkIconWrapper>
				}
			/>
		</BreadcrumbsItemWrapper>
	);
};

const skeletonIconWrapperStyles = xcss({
	marginRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsItemWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkIconWrapper = styled.div({
	visibility: 'hidden',
	marginLeft: token('space.negative.025', '-2px'),
	paddingLeft: 0,
	cursor: 'pointer',
	opacity: 0,
	transition: 'opacity 150ms, visibility 300ms, margin-left 300ms, padding-left 300ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="breadcrumbs-item-wrapper-hX88"]:hover &': {
		visibility: 'visible',
		opacity: 1,
		marginLeft: 0,
		paddingLeft: token('space.050', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='presentation']": {
		display: 'flex',
	},
});
