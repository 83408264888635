import type { PolarisQuery } from '../types';
import { createQueryStringPostfix } from './query';

type RouterAction = (url: string) => void;

export class DebouncedRouterActions {
	pushTimeout: ReturnType<typeof setTimeout> | undefined;

	replaceTimeout: ReturnType<typeof setTimeout> | undefined;

	currentPath: string | undefined;

	currentQuery: PolarisQuery | undefined;

	newPath: string | undefined;

	newQuery: PolarisQuery | undefined;

	nativePush: RouterAction | undefined;

	nativeReplace: RouterAction | undefined;

	constructor() {
		this.pushTimeout = undefined;
		this.replaceTimeout = undefined;

		this.currentPath = undefined;
		this.currentQuery = undefined;

		this.newPath = undefined;
		this.newQuery = undefined;

		this.nativePush = undefined;
		this.nativeReplace = undefined;
	}

	setCurrentState(currentPath: string, currentQuery: PolarisQuery) {
		this.currentPath = currentPath;
		this.currentQuery = currentQuery;
	}

	setNativeRouterMethods(nativePush: RouterAction, nativeReplace: RouterAction) {
		this.nativePush = nativePush;
		this.nativeReplace = nativeReplace;
	}

	setNewState(newPath: string | null, newQuery?: PolarisQuery) {
		this.newQuery = newQuery ?? this.newQuery ?? this.currentQuery ?? {};
		this.newPath = newPath ?? this.newPath ?? this.currentPath;
	}

	resetNewState() {
		this.newQuery = undefined;
		this.newPath = undefined;
	}

	getNewUrl() {
		return `${this.newPath ?? ''}${createQueryStringPostfix(this.newQuery ?? {})}`;
	}

	clearTimeouts() {
		clearTimeout(this.pushTimeout);
		clearTimeout(this.replaceTimeout);
	}

	push(newPath: string | null, newQuery?: PolarisQuery) {
		this.setNewState(newPath, newQuery);
		this.clearTimeouts();

		this.pushTimeout = setTimeout(() => {
			this.pushTimeout = undefined;
			this.nativePush?.(this.getNewUrl());
			this.resetNewState();
		}, 0);
	}

	replace(newPath: string | null, newQuery?: PolarisQuery) {
		this.setNewState(newPath, newQuery);
		this.clearTimeouts();

		this.replaceTimeout = setTimeout(() => {
			this.replaceTimeout = undefined;
			this.nativeReplace?.(this.getNewUrl());
			this.resetNewState();
		}, 0);
	}
}

// global debounce function in order to catch all usages of push() and replace()
const debouncedRouterActions: DebouncedRouterActions = new DebouncedRouterActions();
export default debouncedRouterActions;
