import React from 'react';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FieldName } from '../../../common/ui/field/styled';
import { useDecorationForNumberValue } from '../../../controllers/field/selectors/decoration/hooks.tsx';
import { useFieldLabel } from '../../../controllers/field/selectors/field-hooks';
import { useNumberValue } from '../../../controllers/issue/selectors/properties/hooks';
import { DecoratedNumber } from '../../decoration/number/index.tsx';
import { TooltipFieldWithLabelContainer } from '../styled';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
};

export const NumberField = ({ issueId, fieldKey }: Props) => {
	const label = useFieldLabel(fieldKey);
	const { value, initialized } = useNumberValue(fieldKey, issueId);
	const decoration = useDecorationForNumberValue(fieldKey, value);

	return label !== undefined && value !== undefined && value !== null ? (
		<TooltipFieldWithLabelContainer key={fieldKey}>
			<FieldName>{label}</FieldName>
			{initialized ? (
				<DecoratedNumber value={value} decoration={decoration} />
			) : (
				<Skeleton height="16px" />
			)}
		</TooltipFieldWithLabelContainer>
	) : null;
};
