import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { TaskProgress } from '@atlassian/jira-polaris-domain-field/src/global-field/types.tsx';
import type { TaskProgressResponse } from '../types';

export const copyValuesToGlobalField = (
	fromProjectFieldKey: FieldKey,
	toGlobalFieldKey: FieldKey,
	projectId: string,
	fieldOptionsValueMapping?: Record<string, string | undefined>,
): Promise<{ taskId: string }> =>
	performPostRequest<{ taskId: string }>(
		`/rest/polaris/v2/projects/${projectId}/fields/${fromProjectFieldKey}/copy-values-to/${toGlobalFieldKey}`,
		{
			body: fieldOptionsValueMapping ? JSON.stringify(fieldOptionsValueMapping) : null,
		},
	);

export const getCopyValuesProgress = async (taskId: string): Promise<TaskProgress> => {
	const taskProgress = await fetchJson<TaskProgressResponse>(
		`/rest/polaris/tasks/${taskId}/progress`,
	);

	return {
		...taskProgress,
		status: mapTaskProgressStatus(taskProgress.status),
	};
};

const mapTaskProgressStatus = (status: TaskProgressResponse['status']): TaskProgress['status'] => {
	switch (status) {
		case 'ENQUEUED':
		case 'RUNNING': {
			return 'INPROGRESS';
		}
		case 'COMPLETE': {
			return 'DONE';
		}
		case 'FAILED':
		case 'CANCEL_REQUESTED':
		case 'CANCELLED':
		case 'DEAD': {
			return 'FAILED';
		}
		default: {
			throw new Error(`Unknown task progress status: ${status}`);
		}
	}
};
