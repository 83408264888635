import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraUserMapping } from '../../../../field/mapping/user';
import type { PropertyMaps, State } from '../../../types';
import { nullSafeComparator, stringComparator } from '../../comparators';
import { createStringValueContainsFilter } from '../common/filter-utils';
import { removePropertyValue } from '../common/remove-utils';
import type { FieldMapping } from '../types';

export const userMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<UserFieldValue> => {
	const valueAccessor: FieldMapping<UserFieldValue>['valueAccessor'] = (state, props, issueId) =>
		state.properties.user[field.key] !== undefined
			? state.properties.user[field.key][issueId]
			: undefined;

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const user = valueAccessor(state, props, issueId);
		return user?.displayName || ''; // '' = Unassigned
	};

	return {
		...jiraUserMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: UserFieldValue) =>
			set(maps.user, [field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: IssueUserProperty) => { user: { [x: string]: Record<string, IssueUserProperty> | { [x: string]: IssueUserProperty | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: IssueUserProperty | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: UserFieldValue) => {
			if (maps.user[field.key] && isEqual(maps.user[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				user: {
					...maps.user,
					[field.key]: {
						...maps.user[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'user'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator: nullSafeComparator<UserFieldValue>((a, b, direction) =>
			stringComparator(a.displayName, b.displayName, direction),
		),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) =>
			state.properties.user[field.key] ? state.properties.user[field.key] : {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.user[fieldKey],
				(ids, user) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									user !== undefined && user[issueId] !== undefined
										? [
												{
													groupIdentity: user[issueId].accountId,
													value: user[issueId],
												},
											]
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) =>
			state.properties.user[field.key] !== undefined &&
			state.properties.user[field.key][issueId] !== undefined
				? [
						{
							groupIdentity: state.properties.user[field.key][issueId].accountId,
							value: state.properties.user[field.key][issueId],
						},
					]
				: [],
		getRollupOperations: () => [COUNT_ROLLUP, EMPTY_ROLLUP, FILLED_ROLLUP],
		allowEmptyGroup: true,
		getLabel: (groupIdentity, value) => value?.displayName,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueContainsFilter = createStringValueContainsFilter(filter);
				if (stringValueContainsFilter === undefined) {
					return undefined;
				}
				return (value: undefined | UserFieldValue, state, props, localIssueId) =>
					stringValueContainsFilter(value?.accountId, state, props, localIssueId);
			}
			return undefined;
		},
	};
};
