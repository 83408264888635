import getOr from 'lodash/fp/getOr';
import set from 'lodash/fp/set';
import merge from 'lodash/merge';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { PolarisArjConfiguration } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdsByJiraIssueId, getLocalIssueIdToJiraId } from '../../selectors/issue-ids';
import type { State, Props, PlaysProperty } from '../../types';

export const getRelevantParentLinkFields = (
	configuration: PolarisArjConfiguration | undefined,
): FieldKey[] | undefined =>
	!configuration
		? undefined
		: [configuration.epicLinkCustomFieldId, configuration.parentCustomFieldId].filter(
				(s): s is string => !!s,
			);

export const setLoadingLinkedIssuesState = (state: State, loading: boolean): State => ({
	...state,
	meta: {
		...state.meta,
		loadingLinkedIssues: loading,
	},
});

/**
 * Fetches the ranks for the given issues and updates the local state with the new ranks
 */
export const updateIssuesRanks =
	(localIssueIds: LocalIssueId[]) =>
	({ getState, setState }: StoreActionApi<State>, props: Props): Promise<void> => {
		const { projectId, ideaTypes, rankField, hasNoProjectPermissions } = props;

		if (!rankField || !projectId || !ideaTypes || hasNoProjectPermissions) {
			return Promise.resolve();
		}

		const jiraIdByLocalIssueId = getLocalIssueIdToJiraId(getState(), props);
		const issueIds = localIssueIds.map((id) => jiraIdByLocalIssueId[id]);

		return props.issuesRemote
			.fetchRanks({
				rankField,
				issueIdsOrKeys: issueIds,
				issueTypeIds: ideaTypes.map((type) => type.jiraIssueTypeId),
			})
			.then((response) => {
				const localIssueIdByJiraId = getLocalIssueIdsByJiraIssueId(getState(), props);

				const lexoRanks = response?.issues?.reduce<Record<LocalIssueId, string>>((acc, issue) => {
					const lexoRank = issue.fields?.[rankField];
					const localIssueId = localIssueIdByJiraId[issue.id];

					if (lexoRank && localIssueId) {
						Object.assign(acc, {
							[localIssueId]: lexoRank,
						});
					}

					return acc;
				}, {});

				if (lexoRanks) {
					setState({
						properties: {
							...getState().properties,
							lexoRank: {
								...getState().properties.lexoRank,
								...lexoRanks,
							},
						},
					});
				}
			});
	};

/**
 * This function tries to enrich the contribution payload with additional data
 * which is not received from the server during real-time updates.
 */
export function enrichPlayContributionPayload(
	contribution: PolarisPlayContribution,
	localPlays: PlaysProperty,
): PolarisPlayContribution {
	// Check if the contribution already has user account data then return it as is
	if (contribution?.author?.accountId && contribution?.author.displayName) {
		return contribution;
	}

	const accountId = contribution?.author?.accountId;

	// Try to enrich contribution with the user account data from the other contributions
	for (const localPlay of Object.values(localPlays)) {
		for (const localContributions of Object.values(localPlay)) {
			const localContribution = localContributions.find(({ aaid }) => aaid === accountId);
			if (localContribution) {
				const enrichedContribution = set(
					'author',
					merge(getOr({}, 'author', contribution), localContribution?.author),
					contribution,
				);
				return enrichedContribution;
			}
		}
	}

	// contribution cannot be enriched return as is
	return contribution;
}
