import React, { type SyntheticEvent } from 'react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import ArchiveIcon from '@atlaskit/icon/glyph/archive';
import { useIntl } from '@atlassian/jira-intl';
import { useIsArchivingEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useArchiveViewId,
	useViewSlug,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge/src';
import { useVisibleSelectedIssueIds } from '../../../utils';
import { messages } from './messages';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const { formatMessage } = useIntl();
	const visibleSelectedIssueIds = useVisibleSelectedIssueIds();
	const { archiveIssues, unarchiveIssues } = useIssueActions();
	const { clearSelection } = useViewActions();
	const { success } = useNotifications();
	const { routeTo } = usePolarisRouter();
	const archiveViewId = useArchiveViewId();
	const archiveViewSlug = useViewSlug(archiveViewId);
	const [isArchivingEnabled] = useIsArchivingEnabled();

	if (isEmpty(visibleSelectedIssueIds) || !isArchivingEnabled) {
		return null;
	}

	return (
		<Button
			id="polaris-ideas.ui.view-controls.archive-button"
			testId="polaris-ideas.ui.view-controls.manage-bar.archive-ideas.archive-ideas"
			onClick={(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'archiveIdeas');
				archiveIssues(visibleSelectedIssueIds);
				clearSelection(visibleSelectedIssueIds);
				success({
					title: formatMessage(messages.notificationTitle),
					description: (
						<>
							<FireUiAnalytics
								action="viewed"
								actionSubject="flag"
								actionSubjectId="confirmIdeasArchived"
							/>
							{formatMessage(messages.notificationDescription)}
						</>
					),
					actions: [
						{
							testId: 'view-archive',
							content: formatMessage(messages.notificationViewArchiveButton),
							onClick: () => {
								routeTo({
									section: ViewSectionTypeViews,
									resource: archiveViewSlug,
								});
							},
						},
						{
							testId: 'undo',
							content: formatMessage(messages.notificationUndoButton),
							onClick: () => {
								unarchiveIssues(visibleSelectedIssueIds);
							},
						},
					],
				});
			}}
			appearance="subtle"
			iconBefore={<ArchiveIcon label={formatMessage(messages.buttonLabel)} />}
		>
			{formatMessage(messages.buttonLabel)}
		</Button>
	);
};
