import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	avatarOnly: {
		id: 'polaris-component-field-configuration.configuration.people.avatar-only',
		defaultMessage: 'This field only displays avatars.',
		description: 'Field display type text for when the field is readonly',
	},
	fullNameWithAvatar: {
		id: 'polaris-component-field-configuration.configuration.people.full-name-with-avatar',
		defaultMessage: 'This field displays the full name with the avatar.',
		description: 'Field display type text for when the field is readonly',
	},
	avatarOnlyLabel: {
		id: 'polaris-component-field-configuration.configuration.people.avatar-only-label',
		defaultMessage: 'Avatar only',
		description: 'Label for the "Avatar only" option',
	},
	fullNameWithAvatarLabel: {
		id: 'polaris-component-field-configuration.configuration.people.full-name-with-avatar-label',
		defaultMessage: 'Full name with avatar',
		description: 'Label for the "Full name with avatar" option',
	},
	displayModeDescription: {
		id: 'polaris-component-field-configuration.configuration.people.display-mode-description',
		defaultMessage: 'Select how to display this field.',
		description: 'Describes the option',
	},
	optionsHeader: {
		id: 'polaris-component-field-configuration.configuration.people.options-header',
		defaultMessage: 'Options',
		description: 'Header for the options section',
	},
	displayModeAltText: {
		id: 'polaris-component-field-configuration.configuration.people.display-mode-alt-text',
		defaultMessage: 'Example of what the field will look like.',
		description: 'Image alt text',
	},
});
