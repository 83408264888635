import React from 'react';
import { Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useDateDecoration } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks';
import { DecoratedDate } from '@atlassian/jira-polaris-common/src/ui/decoration/date/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const DateFieldValue = ({ fieldKey, groupIdentity }: FieldValueComponentProps) => {
	const decoration = useDateDecoration(fieldKey, groupIdentity);
	return (
		<Box paddingBlock="space.025">
			<DecoratedDate datetime={groupIdentity} decoration={decoration} />
		</Box>
	);
};

const EmptyDateFieldValue = () => {
	const { formatMessage } = useIntl();

	return (
		<Box paddingBlock="space.025" paddingInlineStart="space.050">
			{formatMessage(messages.emptyFieldValue)}
		</Box>
	);
};

export const dateFieldValueConfig = {
	isHideable: false,
	isDecoratable: false,
	Component: DateFieldValue,
	EmptyComponent: EmptyDateFieldValue,
} as const;
