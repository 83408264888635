import React, { useState, useRef, useCallback } from 'react';
import findLast from 'lodash/findLast';
import Button, { IconButton } from '@atlaskit/button/new';
import { Emoji } from '@atlaskit/emoji';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { DragHandleButtonSmall } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button-small';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import {
	useFieldEmoji,
	useFieldTypeIcon,
} from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { SearchableDropdown } from '@atlassian/jira-polaris-common/src/ui/common/decoration/searchable-dropdown';
import { EmojiWrapper } from '@atlassian/jira-polaris-common/src/ui/field-config/item/emoji-wrapper';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { PolarisInlineDialog } from '@atlassian/jira-polaris-lib-inline-dialog/src/ui/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { SortFieldComponent as SortFieldComponentLegacy } from './legacy';
import { messages } from './messages';

const OptionComponent = ({ field }: { field: Field }) => {
	const icon = useFieldTypeIcon(field.key, {
		label: field.label,
	});
	const emojiId = useFieldEmoji(field.key);
	const emoji = useEmoji(emojiId);

	return (
		<Flex alignItems="center">
			{emoji ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Flex xcss={iconContainerStyles}>{icon}</Flex>
			)}
			<Box xcss={optionTextStyles}>{field.label}</Box>
		</Flex>
	);
};

type SortFieldComponentProps = {
	selectedField?: Field;
	asc: boolean;
	fields: Field[];
	label?: string;
	isDisabled?: boolean;
	isHoverable?: boolean;
	onClearSort?: () => void;
	onClickDirection: () => void;
	onChangeField: (field: Field) => void;
};

export const SortFieldComponent = componentWithFG(
	'polaris_groupby-filter-sort-fields-panels-refresh',
	({
		selectedField,
		asc,
		fields,
		label,
		isDisabled = false,
		isHoverable = true,
		onClearSort,
		onClickDirection,
		onChangeField,
	}: SortFieldComponentProps) => {
		const { formatMessage } = useIntl();
		const buttonRef = useRef<HTMLButtonElement | null>(null);
		const [dialogOpen, setDialogOpen] = useState(false);

		const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

		return (
			<Flex
				xcss={[
					sortFieldContainerStyles,
					!isDisabled && isHoverable && sortFieldContainerHoverStyles,
				]}
			>
				<Box paddingInline="space.050" xcss={[(isDisabled || !isHoverable) && hiddenStyles]}>
					<DragHandleButtonSmall appearance="subtle" type="button" label="view-dragHandle" />
				</Box>
				<Box paddingInlineEnd="space.100">{label}</Box>
				<Flex xcss={sortFieldGroupStyles}>
					<Box xcss={inputContainerStyles}>
						<PolarisInlineDialog
							noPadding
							onClose={(target) => {
								if (buttonRef.current && !buttonRef.current.contains(target)) {
									setDialogOpen(false);
								}
							}}
							isOpen={dialogOpen}
							placement="bottom"
							content={
								<>
									<FireUiAnalytics
										actionSubject="sortFieldMenu"
										action="viewed"
										actionSubjectId="view-controls"
									/>
									<SearchableDropdown
										options={fields.map(({ key, label: fieldLabel }) => ({
											key,
											label: fieldLabel,
										}))}
										onSelect={(key) => {
											const field = findLast(fields, { key });
											field !== undefined && onChangeField(field);
											setDialogOpen(false);
										}}
										onClose={() => setDialogOpen(false)}
									/>
								</>
							}
						>
							<Box xcss={!selectedField && selectFieldButtonWrapperStyles}>
								<Button
									testId="polaris-ideas.ui.view-controls.config-sort.field.button"
									ref={buttonRef}
									isSelected={dialogOpen}
									onClick={toggleDialog}
									isDisabled={isDisabled}
									iconAfter={(iconProps) => <ChevronDownIcon size="medium" {...iconProps} />}
									shouldFitContainer
								>
									{selectedField ? (
										<OptionComponent field={selectedField} />
									) : (
										<Flex justifyContent="start" alignItems="center">
											{formatMessage(messages.fieldSelectPlaceholder)}
										</Flex>
									)}
								</Button>
							</Box>
						</PolarisInlineDialog>
					</Box>
					{selectedField && (
						<IconButton
							onClick={onClickDirection}
							label={
								asc ? formatMessage(messages.ascSortButton) : formatMessage(messages.descSortButton)
							}
							isDisabled={isDisabled}
							icon={asc ? ArrowUpIcon : ArrowDownIcon}
						/>
					)}
					{!isDisabled && onClearSort && (
						<IconButton
							appearance="subtle"
							onClick={onClearSort}
							label={formatMessage(messages.clearButton)}
							icon={(iconProps) => <TrashIcon size="small" {...iconProps} />}
						/>
					)}
				</Flex>
			</Flex>
		);
	},
	SortFieldComponentLegacy,
);

const inputContainerStyles = xcss({
	flexGrow: 1,
	minWidth: '0',
});

const sortFieldContainerStyles = xcss({
	alignItems: 'center',
	paddingBlock: 'space.050',
});

const sortFieldContainerHoverStyles = xcss({
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const sortFieldGroupStyles = xcss({
	flex: 1,
	gap: 'space.050',
	paddingInlineEnd: 'space.200',
	minWidth: '0',
});

const iconContainerStyles = xcss({
	alignItems: 'center',
	paddingInlineEnd: 'space.100',
});

const optionTextStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
});

const hiddenStyles = xcss({
	visibility: 'hidden',
});

// Align "Select field" button with sort rules, requires reducing maxWidth by 2 button widths and spacing around it
const selectFieldButtonWrapperStyles = xcss({
	maxWidth: `calc(100% - ${token('space.800')} - ${token('space.100')})`,
});
