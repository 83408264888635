import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { FuzzyScale } from '../../../../common/utils/date/date-picker';

const localStorage = createLocalStorageProvider('polaris');

const DATE_FIELD_SCALE_STORAGE_KEY = 'last-used-date-field-scale';

export const setLastUsedDateFieldScaleToStorage = (scale: FuzzyScale) => {
	try {
		localStorage.set(DATE_FIELD_SCALE_STORAGE_KEY, scale);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'polaris.date-field-error',
			'Failed to save date field scale to localStorage',
			error,
		);
	}
};

export const getLastUsedDateFieldScaleFromStorage = (): FuzzyScale | null =>
	localStorage.get(DATE_FIELD_SCALE_STORAGE_KEY) || null;
