import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/window';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/mergeAll';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { FieldOptions } from '@atlassian/jira-issue-view-common-types/src/connect-field-type';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { fieldPersistedValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector';
import {
	type FieldPasteAction,
	type FieldEditBeginAction,
	FIELD_EDIT_BEGIN,
	FIELD_PASTE,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions';
import {
	fieldSaveSuccessWithPastedContent,
	type FieldSaveSuccessAction,
	type FieldSaveSuccessWithPastedContentAction,
	FIELD_SAVE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions';

type TargetEditorAction = FieldPasteAction | FieldSaveSuccessAction;
type FilteredAction = FieldEditBeginAction | TargetEditorAction;
type Action = TargetEditorAction | FilteredAction;
type PasteAggregation = string[];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<GroupedObservable<string, Action>>' is not assignable to method's 'this' of type 'Observable<ActionsObservable<FilteredAction>>'.
	action$
		.ofType(FIELD_EDIT_BEGIN, FIELD_PASTE, FIELD_SAVE_SUCCESS)
		.groupBy((action: FilteredAction): string => action.payload.fieldId)
		.map(
			(
				groupedStream$: ActionsObservable<FilteredAction>,
				// @ts-expect-error - TS2315 - Type 'FieldSaveSuccessWithPastedContentAction' is not generic.
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			): ActionsObservable<FieldSaveSuccessWithPastedContentAction<any>> => {
				// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<FilteredAction>' but required in type 'ActionsObservable<any>'. | TS2315 - Type 'FieldEditBeginAction' is not generic.
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const newEditorSession$: ActionsObservable<FieldEditBeginAction<any>> =
					groupedStream$.filter(({ type }) => type === FIELD_EDIT_BEGIN);

				// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<Observable<FilteredAction>>' is not assignable to method's 'this' of type 'Observable<ActionsObservable<TargetEditorAction>>'.
				return groupedStream$
					.window(newEditorSession$)
					.map(
						(
							sessionStream$: ActionsObservable<TargetEditorAction>,
							// @ts-expect-error - TS2315 - Type 'FieldSaveSuccessWithPastedContentAction' is not generic.
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
						): ActionsObservable<FieldSaveSuccessWithPastedContentAction<any>> => {
							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<TargetEditorAction>' but required in type 'ActionsObservable<FieldSaveSuccessReturnType<unknown>>'.
							const sessionSaveSuccess$: ActionsObservable<FieldSaveSuccessAction> =
								sessionStream$.filter(({ type }) => type === FIELD_SAVE_SUCCESS);

							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<TargetEditorAction>' but required in type 'ActionsObservable<{ payload: { fieldId: string; fieldOptions: FieldOptions<unknown>; pastedContent: string; }; type: "FIELD_PASTE"; }>'.
							const sessionPastes$: ActionsObservable<FieldPasteAction> = sessionStream$
								.filter(({ type }) => type === FIELD_PASTE)
								.takeUntil(sessionSaveSuccess$);

							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<any[]>' but required in type 'ActionsObservable<PasteAggregation>'.
							const pasteAggregator$: ActionsObservable<PasteAggregation> =
								// @ts-expect-error - TS2769 - No overload matches this call.
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								sessionPastes$.reduce<Array<any>>(
									(acc: string[], curr: FieldPasteAction) => [
										// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
										...acc,
										curr.payload.pastedContent,
									],
									[],
								);

							// @ts-expect-error - TS2741 - Property 'ofType' is missing in type 'Observable<FieldSaveSuccessWithPastedContentReturnType<unknown> | null>' but required in type 'ActionsObservable<any>'.
							return sessionSaveSuccess$.withLatestFrom(pasteAggregator$).mergeMap(
								([saveSuccessAction, pastedContent]: [
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									any,
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									any,
								]): Observable<FieldSaveSuccessWithPastedContentAction | null> => {
									const {
										fieldId,
										fieldOptions,
									}: {
										fieldId: string;
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										fieldOptions: FieldOptions<any>;
									} = saveSuccessAction.payload;
									const value: string | null = fieldPersistedValueSelector(fieldId)(
										store.getState(),
									);

									return pastedContent.length === 0
										? Observable.empty<never>()
										: Observable.of(
												fieldSaveSuccessWithPastedContent(
													fieldId,
													fieldOptions,
													value,
													pastedContent,
												),
											);
								},
							);
						},
					)
					.mergeAll();
			},
		)
		.mergeAll();
