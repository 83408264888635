const SLACK_INTEGRATION_STG = 'jira-slack-integration-stg-east__slack-config-page';
const SLACK_INTEGRATION = 'jira-slack-integration__slack-config-page';

export const isSupportedConnectPlugin = (id: string) => {
	switch (id) {
		case SLACK_INTEGRATION_STG:
			return true;
		case SLACK_INTEGRATION:
			return true;
		default:
			return false;
	}
};
