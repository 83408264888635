import { createSelector } from 'reselect';
import { findViewWithSlug } from '../../../actions/utils/views';
import type { Props, State } from '../../../types';

export const getCurrentView = createSelector(
	(state: State) => state.viewSets,
	(state: State, props: Props | undefined) => props?.currentViewSlug,
	findViewWithSlug,
);

export const getSharedViewConfigError = createSelector(getCurrentView, (view) => view?.configError);

export const hasSharedViewConfigError = createSelector(
	getSharedViewConfigError,
	(configError) => configError !== undefined,
);
