import chunk from 'lodash/chunk';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { RankCustomFieldId } from '@atlassian/jira-polaris-domain-field/src/field-types/rank/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const rankIssueApiUrl = '/rest/agile/1.0/issue/rank';
const BATCH_SIZE = 50;

type RankBatch = {
	issues: IssueKey[];
	rankBeforeIssue?: IssueKey;
	rankAfterIssue?: IssueKey;
};

const createBatches = (
	issueKeys: IssueKey[],
	anchor: IssueKey,
	rankAfter: boolean,
): RankBatch[] => {
	const batches = chunk(issueKeys, BATCH_SIZE);
	if (rankAfter) {
		return batches.map((batch, i) => ({
			issues: batch,
			rankAfterIssue: i === 0 ? anchor : batches[i - 1][batches[i - 1].length - 1],
		}));
	}
	return batches.reverse().map((batch, i) => ({
		issues: batch,
		rankBeforeIssue: i === 0 ? anchor : batches[i - 1][0],
	}));
};

const createPromisesSequentially = (
	batches: RankBatch[],
	rankCustomFieldId?: RankCustomFieldId,
): Promise<void> =>
	batches.reduce(
		(promiseChain, currentBatch) =>
			promiseChain.then(() =>
				performPutRequest(rankIssueApiUrl, {
					body: JSON.stringify({
						...currentBatch,
						rankCustomFieldId,
					}),
				}).then((result) => {
					// if the result is not empty the operation only succeeded partially
					// https://developer.atlassian.com/cloud/jira/software/rest/api-group-issue/#api-rest-agile-1-0-issue-rank-put
					if (result) {
						throw new Error('Ranking failed');
					}
				}),
			),
		Promise.resolve(),
	);

const rankIdeas = (
	issueKeys: IssueKey[],
	rankBeforeIssueKey?: IssueKey,
	rankAfterIssueKey?: IssueKey,
	rankCustomFieldId?: RankCustomFieldId,
): Promise<void> => {
	if (issueKeys.length === 0) {
		return Promise.resolve();
	}
	if ((!rankBeforeIssueKey && !rankAfterIssueKey) || (rankBeforeIssueKey && rankAfterIssueKey)) {
		return Promise.reject(new Error('Invalid request'));
	}
	return createPromisesSequentially(
		createBatches(
			issueKeys,
			rankBeforeIssueKey || rankAfterIssueKey || '',
			rankAfterIssueKey !== undefined,
		),
		rankCustomFieldId,
	);
};

export const rankIdeaRest = (
	issueKeys: IssueKey[],
	rankBeforeIssueKey?: IssueKey,
	rankAfterIssueKey?: IssueKey,
	rankCustomFieldId?: RankCustomFieldId,
) => rankIdeas(issueKeys, rankBeforeIssueKey, rankAfterIssueKey, rankCustomFieldId);
