import { useEffect, useRef } from 'react';

const calculateFrameRate = (frames: number, deltaTime: number) => (frames / deltaTime) * 1000;

const calculateLatency = (latencies: number[]) =>
	latencies.reduce((a, b) => a + b, 0) / latencies.length;

export const useScrollPerformance = (
	container: HTMLElement | null,
	onPerformanceMeasured?: (fps: number, latency: number) => void,
) => {
	const scrollTimeFpsLast = useRef(performance.now());
	const scrollTimeLatencyLast = useRef(performance.now());
	const frames = useRef(0);
	const animationFrameId = useRef<number | null>(null);
	const scrollLatencies = useRef<number[]>([]);

	const measureScrollPerformance = () => {
		const currentTime = performance.now();
		const deltaTimeFps = currentTime - scrollTimeFpsLast.current;
		const deltaTimeLatency = currentTime - scrollTimeLatencyLast.current;

		// Latency of the scroll event
		scrollLatencies.current.push(deltaTimeLatency);

		// Calculate the frame rate per second
		if (deltaTimeFps >= 1000) {
			const frameRate = calculateFrameRate(frames.current, deltaTimeFps);
			const scrollLatency = calculateLatency(scrollLatencies.current);
			onPerformanceMeasured?.(frameRate, scrollLatency);

			scrollTimeFpsLast.current = currentTime;
			frames.current = 0;
			scrollLatencies.current = [];
		}

		scrollTimeLatencyLast.current = currentTime;
	};

	const measureOnAnimationFrame = () => {
		if (animationFrameId.current !== null) {
			measureScrollPerformance();
			animationFrameId.current = window.requestAnimationFrame(measureOnAnimationFrame);
		}
	};

	useEffect(() => {
		let scrollTimeout: ReturnType<typeof setTimeout> | null = null;

		// Event listener for scroll events
		const handleScroll = () => {
			// Increment the frame count
			frames.current++;

			if (animationFrameId.current === null) {
				// Set init time for latency measurement
				scrollTimeLatencyLast.current = performance.now();

				animationFrameId.current = window.requestAnimationFrame(measureOnAnimationFrame);
			}

			// Clear the timeout if it exists
			if (scrollTimeout !== null) {
				clearTimeout(scrollTimeout);
			}

			// If no more scroll events come in within 100ms - scroll was ended
			scrollTimeout = setTimeout(() => {
				// Cancel perf measuring when the scroll ends
				animationFrameId.current && window.cancelAnimationFrame(animationFrameId.current);
				animationFrameId.current = null;
			}, 100);
		};

		container?.addEventListener('scroll', handleScroll);

		// Cleanup function to remove the event listener and stop perf measuring
		return () => {
			container?.removeEventListener('scroll', handleScroll);
			animationFrameId.current && window.cancelAnimationFrame(animationFrameId.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [container]);
};
