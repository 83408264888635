import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	colorPickerHeader: {
		id: 'polaris-lib-color-picker.color-picker-header',
		defaultMessage: 'Color',
		description: 'Header text for the polaris color picker',
	},
	clearColorFormatting: {
		id: 'polaris-lib-color-picker.clear-color-formatting',
		defaultMessage: 'Clear formatting',
		description: 'Button that clears color formatting',
	},
	colorChanged: {
		id: 'polaris-lib-color-picker.color-changed',
		defaultMessage: 'Selected color is now {color}',
		description: 'Accessibility message read when the selected color changed',
	},
	colorCleared: {
		id: 'polaris-lib-color-picker.color-cleared',
		defaultMessage: 'Selected color has been cleared',
		description: 'Accessibility message read when the selected color has been cleared',
	},
	selectedColor: {
		id: 'polaris-lib-color-picker.selected-color',
		defaultMessage: 'Selected color: {color}',
		description: 'Accessibility message displayed to indicate the currently selected color',
	},
});
