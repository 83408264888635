import React, {
	useCallback,
	memo,
	useMemo,
	type ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { B400, N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { ColumnId, RowGroupId } from '../../../common/types';
import type { RowData } from '../../../common/types/react-base-table';
import {
	CLASS_ROW,
	CLASS_ROW_CELL,
	CLASS_SUMMARY_CELL,
	CLASS_FIXED_ROW_CELL,
} from '../../../common/ui/constants';
import { NO_VALUE_GROUP_ID } from '../../../constants';
import { useListActions } from '../../../controllers';
import { useResizingColumn } from '../../../controllers/selectors/columns-hooks';
import {
	useRowGroupComponent,
	useGroupCellComponent,
} from '../../../controllers/selectors/components-hooks';
import { useColumnConfigurationByColumnId } from '../../../controllers/selectors/items-hooks';
import { useCurrentStickyGroupRow } from '../../../controllers/selectors/rows-hooks';
import {
	useBaseTableApi,
	useVisibleColumnIds,
	useInvisibleColumnPlaceholderWidthLeft,
	useIsExporting,
} from '../../../controllers/selectors/ui-hooks';
import { SelectAllInGroupCheckBox } from '../../cell/selection-checkbox';
import { borderColor, DEFAULT_ROW_HEIGHT } from '../../constants';
import { RowContainer } from '../../row/main';

type GroupCellProps = {
	columnId: ColumnId;
	groupId: RowGroupId;
	isFixed?: boolean;
};

const GroupCell = memo<GroupCellProps>(({ columnId, groupId }: GroupCellProps) => {
	const GroupCellComponent = useGroupCellComponent();

	const colConfig = useColumnConfigurationByColumnId(columnId);
	const className = useMemo(() => `${CLASS_ROW_CELL} ${colConfig?.className}`, [colConfig]);

	const resizingColumn = useResizingColumn();
	const resizingColWidth = resizingColumn?.[columnId];

	return (
		<GroupCellWrapper
			role="gridcell"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			$width={resizingColWidth ?? colConfig?.width}
		>
			<GroupCellComponent isHovered columnId={columnId} groupId={groupId} />
		</GroupCellWrapper>
	);
});

const DefaultRowGroupContainer = memo<{
	children?: ReactNode;
	rowData: RowData;
	isDarkMode: boolean;
}>(({ children, rowData, isDarkMode }) => {
	const tableApi = useBaseTableApi();
	const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();
	const fixedColumnsWidth = tableApi?.getColumnManager()?.getLeftFrozenColumnsWidth();

	const { isExpanded, isEmpty, id, type, key } = rowData;
	const groupId = key === undefined ? NO_VALUE_GROUP_ID : key;

	const { toggleRowGroupExpansion } = useListActions();

	const onChangeExpansion = useCallback(() => {
		toggleRowGroupExpansion(groupId);
	}, [groupId, toggleRowGroupExpansion]);

	const visibleColumns = useVisibleColumnIds();
	const visibleCells = useMemo(
		() =>
			visibleColumns.map((columnId) => (
				<GroupCell key={columnId} columnId={columnId} groupId={groupId} />
			)),
		[groupId, visibleColumns],
	);

	const fixedPart = useMemo(
		() => (
			<GroupCellWrapper
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${CLASS_ROW_CELL} ${CLASS_SUMMARY_CELL} ${CLASS_FIXED_ROW_CELL}`}
				role="gridcell"
				$width={fixedColumnsWidth}
			>
				<FixedPartWrapper>
					<ActionContainer>
						<CollapseButtonContainer>
							<Button
								onClick={onChangeExpansion}
								appearance="subtle"
								spacing="none"
								iconBefore={
									isExpanded ? <ChevronDownIcon label="" /> : <ChevronRightIcon label="" />
								}
							/>
						</CollapseButtonContainer>
						<SelectAllInGroupCheckBox groupId={groupId} />
					</ActionContainer>
					{children}
				</FixedPartWrapper>
			</GroupCellWrapper>
		),
		[fixedColumnsWidth, onChangeExpansion, isExpanded, groupId, children],
	);

	const wrapperProps = useMemo(
		() => ({
			className: `${CLASS_ROW} ${isExpanded ? 'expanded' : ''} ${isEmpty ? 'empty' : ''}`.trim(),
		}),
		[isExpanded, isEmpty],
	);
	const { trackRowDraggingInfo } = useListActions();

	const rowRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

	useEffect(() => {
		if (!rowRef.current) return undefined;

		const resetEdge = () => {
			setClosestEdge(null);
			trackRowDraggingInfo({
				dropEdge: null,
			});
		};

		const cleanupDragAndDrop = combine(
			dropTargetForElements({
				element: rowRef.current,
				getData({ input, element }) {
					return attachClosestEdge(
						{ id, groupId, type },
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				canDrop({ source }) {
					return source.data.type === 'ITEM' || source.data.type === 'GROUP';
				},
				onDrag(args) {
					const dropEdge = extractClosestEdge(args.self.data);
					if (closestEdge !== dropEdge) {
						setClosestEdge(dropEdge);
						trackRowDraggingInfo({
							dropTarget: groupId,
							type: 'GROUP',
							dropEdge,
						});
					}
				},
				onDragLeave: resetEdge,
				onDrop: resetEdge,
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [closestEdge, id, trackRowDraggingInfo, type, groupId]);

	return (
		<GroupRowWrapper {...wrapperProps} role="rowgroup" ref={rowRef} closestEdge={closestEdge}>
			<RowGroupContainer data-testid="polaris-lib-list.ui.group-row.sticky.row">
				<ContentContainer>
					{fixedPart}
					{placeholderWidthLeft > 0 && (
						<PlaceholderLeft isDarkMode={isDarkMode} $width={placeholderWidthLeft} />
					)}
					{visibleCells}
					<PlaceholderRight isDarkMode={isDarkMode} />
				</ContentContainer>
			</RowGroupContainer>
		</GroupRowWrapper>
	);
});

type StickyRowGroupComponentProps = {
	isDarkMode: boolean;
};

export const LegacyStickyRowGroupComponent = ({ isDarkMode }: StickyRowGroupComponentProps) => {
	const ConsumerComponent = useRowGroupComponent();
	const currentStickyGroupRow = useCurrentStickyGroupRow();
	const isExporting = useIsExporting();
	const { key: groupId } = currentStickyGroupRow ?? {};
	const { triggerRowGroupExpansion } = useListActions();

	const onExpandGroup = useCallback(() => {
		if (groupId !== undefined) {
			triggerRowGroupExpansion(groupId);
		}
	}, [groupId, triggerRowGroupExpansion]);

	// POL-8106 - Fix for broken sticky group row when exporting
	if (isExporting) {
		return null;
	}

	if (currentStickyGroupRow === undefined) {
		return null;
	}

	if (ConsumerComponent !== undefined) {
		return (
			<DefaultRowGroupContainer rowData={currentStickyGroupRow} isDarkMode={isDarkMode}>
				<ConsumerComponent
					groupId={groupId === NO_VALUE_GROUP_ID ? undefined : groupId}
					isExporting={isExporting}
					onExpandGroup={onExpandGroup}
				/>
			</DefaultRowGroupContainer>
		);
	}

	return (
		<DefaultRowGroupContainer rowData={currentStickyGroupRow} isDarkMode={isDarkMode}>
			<RowContentContainer>{groupId}</RowContentContainer>
		</DefaultRowGroupContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionContainer = styled.div({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapseButtonContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	minWidth: '32px',
	minHeight: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContentContainer = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowGroupContainer = styled(RowContainer)({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div<{ isDarkMode: boolean }>({
	boxSizing: 'border-box',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isDarkMode }) => (isDarkMode ? '#22272B' : N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${borderColor}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderLeft = styled(Placeholder)<{ $width: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ $width }) => `${$width}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderRight = styled(Placeholder)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupRowWrapper = styled.div<{ closestEdge: Edge | null }>({
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: DEFAULT_ROW_HEIGHT,
	zIndex: 100,
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${DEFAULT_ROW_HEIGHT}px`,
	'&::before': {
		content: '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ closestEdge }) => !closestEdge && 'none',
		position: 'absolute',
		top: 'auto',
		bottom: '-1.5px',
		width: '100%',
		height: '3px',
		zIndex: 200,
		background: token('color.border.brand', B400),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupCellWrapper = styled.div<{ $width?: number }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ $width }) => `${$width}px`,
	left: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FixedPartWrapper = styled.div({
	display: 'flex',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	marginLeft: token('space.negative.100', '-8px'),
});
