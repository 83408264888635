import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import { fetchJson$, fetchJson } from '@atlassian/jira-fetch';
import {
	getMediaUploadPermissionUrl,
	type MediaUploadPermissionResponse,
} from '@atlassian/jira-issue-fetch-services-common';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors';

export const fetchMediaUploadPermission$ = (
	baseUrl: string,
	issueKey: string,
): Observable<MediaUploadPermissionResponse> =>
	fetchJson$<MediaUploadPermissionResponse>(getMediaUploadPermissionUrl(baseUrl, issueKey)).catch(
		(error) => {
			throw new IssueViewFetchError(error, getMediaUploadPermissionUrl('', '{issueKey}'));
		},
	);

export const fetchMediaUploadPermission = (
	baseUrl: string,
	issueKey: string,
): Promise<MediaUploadPermissionResponse> =>
	fetchJson(getMediaUploadPermissionUrl(baseUrl, issueKey));
