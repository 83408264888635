import React from 'react';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useDateDecoration } from '../../../../controllers/field/selectors/decoration/hooks';
import { useFieldLabel } from '../../../../controllers/field/selectors/field-hooks';
import { useStringValue } from '../../../../controllers/issue/selectors/properties/hooks';
import { DecoratedDate } from '../../../decoration/date/index.tsx';
import { EmptyField } from '../empty';
import { FieldLabel, FieldValue } from '../field/styled';

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	isCompact?: boolean;
	isSummary?: boolean;
	hideEmpty?: boolean;
	disableLazyRendering?: boolean;
};

export const DateField = ({
	issueId,
	fieldKey,
	isCompact = false,
	isSummary = false,
	hideEmpty = false,
	disableLazyRendering = false,
}: Props) => {
	const label = useFieldLabel(fieldKey);
	const value = useStringValue(fieldKey, issueId);
	const decoration = useDateDecoration(fieldKey, value);

	if (hideEmpty && !value) {
		return null;
	}

	return (
		<>
			{!isCompact && <FieldLabel>{label}</FieldLabel>}
			<FieldValue>
				{value !== undefined ? (
					<DecoratedDate
						isCompact={isCompact}
						datetime={value}
						decoration={decoration}
						disableLazyRendering={disableLazyRendering}
					/>
				) : (
					isSummary && <EmptyField />
				)}
			</FieldValue>
		</>
	);
};
