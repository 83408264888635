import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export const HANDLED_FIELD_TYPES = [
	FIELD_TYPES.SHORT_TEXT,
	FIELD_TYPES.HYPERLINK,
	FIELD_TYPES.DATE,
	FIELD_TYPES.CREATED,
	FIELD_TYPES.UPDATED,
	FIELD_TYPES.INTERVAL,
	FIELD_TYPES.NUMBER,
	FIELD_TYPES.CHECKBOX,
	FIELD_TYPES.FORMULA,
	FIELD_TYPES.SLIDER,
	FIELD_TYPES.RATING,
	FIELD_TYPES.LINKED_ISSUES,
	FIELD_TYPES.SINGLE_SELECT,
	FIELD_TYPES.LABELS,
	FIELD_TYPES.CUSTOM_LABELS,
	FIELD_TYPES.MULTI_SELECT,
	FIELD_TYPES.JSW_MULTI_SELECT,
	FIELD_TYPES.ASSIGNEE,
	FIELD_TYPES.REPORTER,
	FIELD_TYPES.CREATOR,
	FIELD_TYPES.PEOPLE,
	FIELD_TYPES.JSW_PEOPLE,
	FIELD_TYPES.DELIVERY_STATUS,
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,
	FIELD_TYPES.DELIVERY_PROGRESS,
	FIELD_TYPES.PROJECT,
] as const;

export type FieldListFieldType = (typeof HANDLED_FIELD_TYPES)[number];

export type FieldListField = Omit<Field, 'type'> & {
	type: FieldListFieldType;
};

export const isHandledFieldType = (field: Field): field is FieldListField =>
	HANDLED_FIELD_TYPES.some((t) => t === field.type);
