import { createHook } from '@atlassian/react-sweet-state';
import type { ScrollTarget, State } from './types';
import { store } from './index';

const getScrollTarget = (state: State): ScrollTarget => state.scrollTarget;

export const useScrollStoreScrollTarget = createHook(store, {
	selector: getScrollTarget,
});

export const useScrollStoreScrollTargetActions = createHook(store, {
	selector: null,
});
