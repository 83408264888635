// JIV-18779: remove this module on cleanup of FG issue-view-remove-viewscreenidquery
import { useEffect } from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-issue-view-react-redux';
import { fetchScreenIdRequest as fetchScreenIdRequestAction } from '@atlassian/jira-issue-view-store/src/common/actions/issue-screenid-actions';
import { isSimplifiedProjectSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';

type Props = {
	isSimplifiedProject: boolean;
	fetchScreenIdRequest: () => void;
};

const FetchScreenIdService = (props: Props) => {
	const { fetchScreenIdRequest, isSimplifiedProject } = props;
	useEffect(() => {
		if (!isSimplifiedProject) {
			fetchScreenIdRequest();
		}
	}, [fetchScreenIdRequest, isSimplifiedProject]);
	return null;
};

const FetchScreenIdServiceOld = connect(
	(state) => ({
		isSimplifiedProject: isSimplifiedProjectSelector(state),
	}),
	{
		fetchScreenIdRequest: fetchScreenIdRequestAction,
	},
)(FetchScreenIdService);

const emptyComponent = () => null;

export default componentWithCondition(
	() => fg('issue-view-remove-viewscreenidquery'),
	emptyComponent,
	FetchScreenIdServiceOld,
);
