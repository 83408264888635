import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useColumnSize } from '../../../controllers/selectors/items-hooks';
import {
	useColumnDraggingInfo,
	useScrollPositionLeft,
} from '../../../controllers/selectors/ui-hooks';

export const ColumnDndOverlay = () => {
	const tableScrollPositionLeft = useScrollPositionLeft();

	const { columnId, initialOffsetLeft, delta } = useColumnDraggingInfo();
	const width = useColumnSize(columnId);

	if (!columnId || initialOffsetLeft === null || delta === null) {
		return null;
	}

	return (
		<Box
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				width,
				left: initialOffsetLeft + delta - tableScrollPositionLeft,
			}}
			xcss={baseStyles}
		/>
	);
};

const baseStyles = xcss({
	display: 'block',
	background: token('color.background.neutral.subtle.pressed'),
	position: 'absolute',
	height: '100vh',
	zIndex: 'card',
	// This is to prevent the overlay from capturing pointer events
	pointerEvents: 'none',
});
