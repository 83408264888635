import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { getAppliedDateDecoration } from '@atlassian/jira-polaris-common/src/controllers/field/decoration';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	useSelectedIssueStringValue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { DateField } from '@atlassian/jira-polaris-common/src/ui/fields/date';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useUpdateFieldValue } from '../utils';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
};

export const Date = ({ fieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const { locale } = useTenantContext();
	const field = useField(fieldKey);
	const value = useSelectedIssueStringValue(fieldKey);
	const fieldValueDecorations = useDecorationsForField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (field === undefined) {
		return null;
	}

	return (
		<DateField
			value={value}
			placeholder={formatMessage(messages.noValue)}
			decoration={getAppliedDateDecoration(fieldValueDecorations, value, locale)}
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			onUpdate={updateFieldValue}
		/>
	);
};
