import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchGoalsHint: {
		id: 'polaris-common.fields.external-reference.edit.search-goals-hint',
		defaultMessage: 'Search goals in Atlas',
		description: 'Search hint for goals in the search box component',
	},
	searchProjectsHint: {
		id: 'polaris-common.fields.external-reference.edit.search-projects-hint',
		defaultMessage: 'Search projects in Atlas',
		description: 'Search hint for projects in the search box component',
	},
});
