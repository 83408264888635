import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalAnalytics } from '@atlassian/jira-analytics-web-react/src';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import { log } from '@atlassian/jira-common-util-logging';
import { isClientFetchError } from '@atlassian/jira-fetch';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics';
import {
	NUM_INITIAL_ITEMS_TO_LOAD,
	NUM_PAGED_ITEMS_TO_LOAD_OLD,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector';
import { sortedWorklogsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector';
import type { IssueKey } from '@atlassian/jira-shared-types';
import { worklogExperienceDescription } from '../../common/experience-description';
import fetchWorklogs from '../../services/fetch-worklogs';
import {
	type Action,
	FETCH_WORKLOGS_REQUEST,
	type FetchWorklogsRequestAction,
	FETCH_WORKLOGS_REQUEST_INITIAL,
	type FetchWorklogsRequestInitialAction,
	fetchWorklogsSuccess,
	fetchWorklogsFailure,
	type WorklogMap,
} from '../../state';

const fetchAndTransformWorklogs = (
	baseUrl: string,
	issueKey: IssueKey,
	projectType: null | ProjectType,
	analyticsEvent: UIAnalyticsEvent,
	startAt: number,
	maxResults: number,
) =>
	Observable.from(fetchWorklogs(baseUrl, issueKey, startAt, maxResults, true))
		.do((worklogsData) =>
			fireOperationalAnalytics(analyticsEvent, {
				attributes: { worklogItemCount: worklogsData.worklogs.length },
			}),
		)
		.map(({ totalWorklogs, worklogs }) => {
			const sortedWorklogIds = worklogs.map((worklog) => worklog.id);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const allWorklogsMap = worklogs.reduce<Record<string, any>>(
				(worklogMap: WorklogMap, worklog) => ({
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...worklogMap,
					[worklog.id]: worklog,
				}),
				{},
			);

			sendExperienceAnalytics(
				worklogExperienceDescription({
					wasSuccessful: true,
					action: 'FETCH',
					analyticsSource: 'fetchAndTransformWorklogs',
					projectType,
				}),
			);

			return fetchWorklogsSuccess(sortedWorklogIds, allWorklogsMap, totalWorklogs);
		})
		.catch((error) => {
			!isClientFetchError(error) &&
				sendExperienceAnalytics(
					worklogExperienceDescription({
						wasSuccessful: false,
						action: 'FETCH',
						analyticsSource: 'fetchAndTransformWorklogs',
						projectType,
						errorMessage: error.message,
					}),
				);
			log.safeErrorWithoutCustomerData(
				'issue.activity.worklog.fetch-worklogs',
				'Could not fetch worklogs:',
				error,
			);

			return Observable.of(fetchWorklogsFailure());
		});

export const fetchWorklogsRequest = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(action$.ofType(FETCH_WORKLOGS_REQUEST) as Observable<FetchWorklogsRequestAction>).switchMap(
		({ payload: { baseUrl, issueKey }, meta: { analyticsEvent } }) => {
			const state = store.getState();
			const loadedWorklogs = sortedWorklogsSelector(state).length;
			const startAt = loadedWorklogs;
			const projectType = projectTypeSelector(state);
			return fetchAndTransformWorklogs(
				baseUrl,
				issueKey,
				projectType,
				analyticsEvent,
				startAt,
				NUM_PAGED_ITEMS_TO_LOAD_OLD,
			);
		},
	);

export const fetchWorklogsRequestInitial = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	/* eslint-disable @typescript-eslint/consistent-type-assertions */
	(action$.ofType(FETCH_WORKLOGS_REQUEST_INITIAL) as Observable<FetchWorklogsRequestInitialAction>)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
		.switchMap(({ payload: { baseUrl, issueKey }, meta: { analyticsEvent } }) => {
			const state = store.getState();
			const projectType = projectTypeSelector(state);

			return fetchAndTransformWorklogs(
				baseUrl,
				issueKey,
				projectType,
				analyticsEvent,
				0,
				NUM_INITIAL_ITEMS_TO_LOAD,
			);
		});
