import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	previewIdea: {
		id: 'polaris-common.idea-card-v2.menu.preview-idea',
		defaultMessage: 'Preview idea',
		description: 'Text displayed when preview idea icon has hovered',
	},
	openIdea: {
		id: 'polaris-common.idea-card-v2.menu.open-idea',
		defaultMessage: 'Open idea',
		description: 'Text displayed when open idea icon has hovered',
	},
});
