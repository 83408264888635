import React from 'react';
import isNumber from 'lodash/isNumber';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks';
import { DecoratedRating } from '@atlassian/jira-polaris-common/src/ui/decoration/rating';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type Props = {
	value: string | number | undefined;
	fieldKey: FieldKey;
};

export const RatingNumberFieldLabel = ({ value, fieldKey }: Props) => {
	const decorations = useDecorationsForField(fieldKey);
	return isNumber(value) ? (
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		<DecoratedRating value={value as number} decorations={decorations} onChange={undefined} />
	) : null;
};
