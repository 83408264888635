import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	publishButton: {
		id: 'polaris-lib-control-sharing.publish-button.publish-button',
		defaultMessage: 'Publish',
		description: 'Publish dialog button label when not published',
	},
	publishedButton: {
		id: 'polaris-lib-control-sharing.publish-button.published-button',
		defaultMessage: 'Published',
		description: 'Publish dialog button label when published',
	},
	publicAccessTooltip: {
		id: 'polaris-lib-control-sharing.publish-button.public-access-tooltip',
		defaultMessage: 'Anyone on the internet with the link can access this view.',
		description: 'Tooltip for the publish button with public access',
	},
});
