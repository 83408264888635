import React, { useEffect, useState, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Spinner from '@atlaskit/spinner';
import PageHeader from '@atlassian/jira-common-components-page-header';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	CORE_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages';
import { FormattedMessage, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import CustomEmailCard from '@atlassian/jira-project-settings-apps-notifications-custom-email-card';
import { AsyncNotificationEmailModal } from '@atlassian/jira-project-settings-apps-notifications-custom-email-modal';
import {
	type EmailAddress,
	useNotificationEmailService,
} from '@atlassian/jira-project-settings-apps-notifications-services';
import type { ProjectContext } from '@atlassian/jira-providers-project-context/src/types';
import { InProductHelpLink } from '@atlassian/jira-servicedesk-common/src/ui/components/in-product-help-link';
import { toProjectId } from '@atlassian/jira-shared-types';
import messages from './messages';

export type Props = {
	projectContext: ProjectContext;
	headerStyle?: 'section' | 'page';
};

type PropsWithIntl = Props & {
	intl: IntlShape;
};

const LEARN_MORE_LINK =
	'https://support.atlassian.com/organization-administration/docs/add-custom-email-addresses-for-product-notifications/';
const LEARN_MORE_LINK_ARTICLE_ID = '4O2kRLysNsfcLj9yOvZvy';

export const NotificationEmailPage = (props: PropsWithIntl) => {
	const {
		projectContext: { projectType, projectId },
		intl: { formatMessage },
		headerStyle = 'page',
	} = props;

	const PROJECT_TYPE_LABELS = {
		[PRODUCT_DISCOVERY_PROJECT]: 'Jira Product Discovery',
		[SOFTWARE_PROJECT]: 'Jira',
		[CORE_PROJECT]: 'Jira',
		[SERVICE_DESK_PROJECT]: 'Jira Service Management',
		[CUSTOMER_SERVICE_PROJECT]: 'Jira Customer Service',
	};

	const [
		{
			data: { currentEmail, currentEmailStatus, allowList, defaultAtlassianEmail },
			loadingEmail,
			loadingAllowList,
			loadingDefaultEmail,
			error,
		},
		{ fetchCurrentEmail, fetchAllowList, updateEmail, fetchDefaultEmailAndAllowList },
	] = useNotificationEmailService(toProjectId(String(projectId)));

	const [openModal, setOpenModal] = useState<boolean>(false);

	useEffect(() => {
		if (!loadingEmail && !currentEmail && !error) {
			fetchCurrentEmail();
		}
	}, [fetchAllowList, fetchCurrentEmail, currentEmail, loadingEmail, error]);

	useEffect(() => {
		fetchDefaultEmailAndAllowList(true);
	}, [fetchDefaultEmailAndAllowList]);

	const isEmailValid = isEmpty(currentEmailStatus);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (error) {
		return (
			<ContextualAnalyticsData
				sourceName="notificationEmailErrorPage"
				sourceType={SCREEN}
				containerType="project"
				containerId={String(projectId)}
				attributes={{
					projectId,
					projectType,
				}}
			>
				<FireScreenAnalytics />
				<JSErrorPageAsync error={error} />
			</ContextualAnalyticsData>
		);
	}
	if (loadingEmail || loadingDefaultEmail) {
		return (
			<SpinnerWrapper>
				<Spinner size="medium" />
			</SpinnerWrapper>
		);
	}

	const cardOnEditButtonClicked = (): void => {
		fetchDefaultEmailAndAllowList();
		setOpenModal(true);
	};

	const modalOnCancelButtonClicked = () => {
		setOpenModal(false);
	};

	const modalOnSaveButtonClicked = (updatedEmail: EmailAddress) => {
		setOpenModal(false);
		updateEmail(updatedEmail);
	};

	const onLearnMoreLinkClicked = (_: MouseEvent<HTMLElement>, analyticEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticEvent, 'learnMoreButton');
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'helpLink clicked',
			'learnMoreAboutSendingEmailsFromYourDomain',
			{ articleId: LEARN_MORE_LINK_ARTICLE_ID },
		);
	};

	return (
		<ContextualAnalyticsData
			containerType="project"
			containerId={String(projectId)}
			attributes={{
				projectId,
				projectType,
			}}
			sourceName="notificationEmailPage"
			sourceType={SCREEN}
		>
			{headerStyle === 'page' ? (
				<PageHeader>{formatMessage(messages.pageTitle)}</PageHeader>
			) : (
				<h3>{formatMessage(messages.sectionTitle)}</h3>
			)}
			<p>
				<FormattedMessage
					{...messages.pageDescription}
					values={{
						learnMore: (
							<InProductHelpLink
								href={LEARN_MORE_LINK}
								onClick={onLearnMoreLinkClicked}
								inProductHelpArticleId={LEARN_MORE_LINK_ARTICLE_ID}
								learnMoreText={formatMessage(messages.learnMore)}
							/>
						),
						project: projectType === SERVICE_DESK_PROJECT ? 'service project' : 'project',
						productName: PROJECT_TYPE_LABELS[projectType],
					}}
				/>
			</p>
			<CustomEmailCard
				currentEmail={currentEmail ?? defaultAtlassianEmail}
				onEditButtonClicked={cardOnEditButtonClicked}
				isModalLoading={loadingAllowList && openModal}
				status={isEmailValid ? 'success' : 'error'}
			/>
			<ModalTransition>
				{openModal && !loadingAllowList && (
					<AsyncNotificationEmailModal
						currentEmail={isEmailValid && currentEmail ? currentEmail : defaultAtlassianEmail}
						defaultEmail={defaultAtlassianEmail}
						allowList={allowList}
						onCancel={modalOnCancelButtonClicked}
						onSave={modalOnSaveButtonClicked}
					/>
				)}
			</ModalTransition>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
