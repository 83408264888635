import React from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectName } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import { useCurrentPolarisRouteSection } from '@atlassian/jira-polaris-common/src/controllers/route';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import messages from './messages';

export const BreadcrumbsProjectSettings = () => {
	const { formatMessage } = useIntl();
	const section = useCurrentPolarisRouteSection();
	const [projectName] = useProjectName();
	const projectKey = useProjectKeyUnsafe();

	return (
		<Breadcrumbs>
			<BreadcrumbsItem
				testId="polaris-ideas.ui.settings.breadcrumbs.breadcrumbs-item-project"
				href="/jira/projects"
				text={formatMessage(messages.projectsTitle)}
			/>
			<BreadcrumbsItem
				testId="polaris-ideas.ui.settings.breadcrumbs.breadcrumbs-item-project-name"
				href={`/jira/polaris/projects/${projectKey}/ideas/view`}
				text={projectName || ''}
			/>
			<BreadcrumbsItem
				testId="polaris-ideas.ui.settings.breadcrumbs.breadcrumbs-item-project-settings"
				href={`/jira/polaris/projects/${projectKey}/ideas/${section}`}
				text={formatMessage(messages.projectSettings)}
			/>
		</Breadcrumbs>
	);
};
