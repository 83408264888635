import React from 'react';
import drop from 'lodash/drop';
import { useApolloClient } from '@apollo/react-hooks';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main';
import { createApolloClient } from './apollo';
import type { RemoteContextProviderChainProps, RemoteContextProvidersProps } from './types';

const ContextProviderChain = ({ providers, children }: RemoteContextProviderChainProps) => {
	const apolloClient = useApolloClient();

	if (providers.length === 0) {
		return <>{children}</>;
	}

	const Provider = providers[0];

	return (
		<Provider apolloClient={apolloClient}>
			<ContextProviderChain providers={drop(providers)}>{children}</ContextProviderChain>
		</Provider>
	);
};

export const RemoteContextProviders = ({
	providers,
	children,
	apolloClientMeta,
}: RemoteContextProvidersProps) => {
	const apolloClient = createApolloClient(apolloClientMeta);

	return (
		<ApolloClientProvider client={apolloClient}>
			<ContextProviderChain providers={providers}>{children}</ContextProviderChain>
		</ApolloClientProvider>
	);
};
