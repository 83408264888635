import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { CommentUser } from '@atlassian/jira-polaris-domain-comment';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { getPlaysData } from '../../services/project-config/get-plays';
import type { RemotePlaysFetcher } from '../../services/project-config/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformCommentUser = (user?: any | null): CommentUser => {
	if (!user) {
		return {
			accountId: 'unknown',
			avatarUrls: {
				'16x16': '#',
				'24x24': '#',
				'32x32': '#',
				'48x48': '#',
			},
			displayName: 'Unknown user',
			active: false,
		};
	}
	return {
		accountId: user.accountId,
		avatarUrls: {
			'16x16': user.picture,
			'24x24': user.picture,
			'32x32': user.picture, // this is the one used by the comment stream
			'48x48': user.picture,
		},
		displayName: user.name,
		active: true,
	};
};

export const fetchPlays =
	(
		apolloClient: PolarisApolloClient,
		projectAri: Ari,
		createAnalyticsEvent: CreateUIAnalyticsEvent,
		fireAnalyticsEvent?: boolean,
	): RemotePlaysFetcher =>
	() =>
		getPlaysData(apolloClient, projectAri, createAnalyticsEvent, fireAnalyticsEvent).then(
			(plays) => [
				...plays.map((play) => ({
					id: play.id,
					kind: play.kind,
					label: play.label,
					parameters: play.parameters ?? {},
					contributions: play.contributions?.map(
						({ aaid, subject, id, amount, comment, created, updated, account }) => ({
							aaid,
							subject,
							id,
							amount: amount ?? 0,
							created,
							updated,
							author: transformCommentUser(account),
							comment: comment
								? {
										id: comment.id,
										updated: comment.updated,
										created: comment.created,
										body: comment.content,
										updateAuthor: undefined,
									}
								: undefined,
						}),
					),
				})),
			],
		);
