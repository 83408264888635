import React from 'react';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { EXTERNAL_REFERENCE_PROPERTY_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/external-reference-property/types.tsx';
import { useField } from '../../../controllers/issue/selectors/fields-hooks';
import type { Value } from '../../../controllers/issue/utils/field-mapping/external-reference-property';
import type { AtlasProjectState } from '../../../services/atlas/projects/types';

export const AtlasState = ({ state }: { state: AtlasProjectState }) => {
	if (!state) {
		return null;
	}
	let appearance: ThemeAppearance = 'default';
	switch (state.value) {
		case 'on_track':
			appearance = 'success';
			break;
		case 'at_risk':
			appearance = 'moved';
			break;
		case 'off_track':
			appearance = 'removed';
			break;
		case 'done':
			appearance = 'success';
			break;
		default:
			appearance = 'default';
	}
	return <Lozenge appearance={appearance}>{state.label}</Lozenge>;
};

type ExternalReferencePropertyFieldProps = {
	fieldKey: FieldKey;
	value: Value | undefined;
};

export const ExternalReferencePropertyField = ({
	value,
	fieldKey,
}: ExternalReferencePropertyFieldProps) => {
	const field = useField(fieldKey);

	if (field?.formula?.template !== EXTERNAL_REFERENCE_PROPERTY_TEMPLATE) {
		return null;
	}

	const items = Array.isArray(value) ? value : [value];

	switch (field?.formula?.parameters?.property) {
		case 'state':
			return (
				<div>
					{items.map(
						(item, key) =>
							item?.__typename === 'TownsquareProjectState' && (
								<AtlasState key={key} state={item} />
							),
					)}
				</div>
			);
		default:
			return null;
	}
};
