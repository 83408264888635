import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	highlightLabel: {
		id: 'polaris-lib-decoration.decorator.highlight-label',
		defaultMessage: 'Highlight rows and cards with this color',
		description: 'Label to explain what the highlight toggle does.',
	},
	emojiPickerHeader: {
		id: 'polaris-lib-decoration.decorator.emoji-picker-header',
		defaultMessage: 'Emoji',
		description: 'Header text for the polaris emoji picker',
	},
});
