import { notImplementedError } from '../../common/utils';
import type { ViewConfigFetcher } from '../../services/jpd-views-service/view-config/types';
import type { ViewRankFieldRemote } from './types';

export const createNotImplementedViewRankFieldRemote = (): ViewRankFieldRemote => ({
	fetchRankField: notImplementedError('fetchRankField'),
});

export const createSharingImplementedViewRankFieldRemote = (
	viewConfigFetcher: ViewConfigFetcher,
): ViewRankFieldRemote => ({
	fetchRankField: () => viewConfigFetcher().then(({ rankField }) => rankField),
});
