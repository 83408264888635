import type { ProjectType } from '@atlassian/jira-common-constants';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { User as RestUser } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

const SERVICEDESK_APPLICATION_ROLE = 'jira-servicedesk' as const;
const SOFTWARE_APPLICATION_ROLE = 'jira-software' as const;
const CORE_APPLICATION_ROLE = 'jira-core' as const;

export type ApplicationRole =
	| typeof SERVICEDESK_APPLICATION_ROLE
	| typeof SOFTWARE_APPLICATION_ROLE
	| typeof CORE_APPLICATION_ROLE;

export type ServerUser = RestUser & {
	emailAddress: string;
	timeZone: string;
	avatarUrls: {
		['48x48']: string;
	};
	applicationRoles: {
		items: {
			key: ApplicationRole;
		}[];
	};
};

export const subProductFromProjectType = (projectType?: ProjectType | null): string => {
	switch (projectType) {
		case SERVICE_DESK_PROJECT:
			return 'Jira Service Management';
		default:
			return 'Jira';
	}
};
