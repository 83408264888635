import {
	createContainer,
	createStore,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { loadAvailableProducts } from './actions/load-available-products';
import type { State, Props } from './types';

const actions = {
	loadAvailableProducts,
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

export type Actions = typeof actions;

const initialState: State = {
	loading: false,
	error: null,
	data: null,
};

export const AvailableProductsStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisAvailableProductsStore',
});

export const useAvailableProductsActions = createHook(AvailableProductsStore, {
	selector: null,
});

export const AvailableProductsContainer = createContainer<State, Actions, Props>(
	AvailableProductsStore,
);
