export const callWhenIdle = (callback: () => void, timeout = 200) => {
	if (typeof window.requestIdleCallback === 'function') {
		const callbackId = window.requestIdleCallback(callback);
		return () => cancelIdleCallback(callbackId);
	}
	const timer = setTimeout(callback, timeout);
	return () => {
		clearTimeout(timer);
	};
};
