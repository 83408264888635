import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common';
import { STRING } from '../constants';
import type { JiraFieldMapping } from '../types';

export type Value = string | string[] | undefined;

export const jiraExternalReferenceMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<Value> => ({
	field,
	getValueFromJiraIssue: (issue) => {
		const value = issue.fields[field.key] ? issue.fields[field.key] : undefined;
		if (field.configuration?.isMulti) {
			try {
				return JSON.parse(value);
			} catch (err) {
				return [];
			}
		} else {
			return value;
		}
	},
	getFieldValueForJiraUpdate: (value) => {
		if (value === undefined) {
			return null;
		}

		if (!Array.isArray(value)) {
			return value;
		}

		if (Array.isArray(value) && value.length === 0) {
			return null;
		}

		return JSON.stringify(value);
	},
	getFieldValueForJiraBulkUpdate: (value) => {
		let text = '';
		if (value !== undefined) {
			if (Array.isArray(value) && value.length > 0) {
				text = JSON.stringify(value);
			} else if (!Array.isArray(value)) {
				text = value;
			}
		}
		return {
			[STRING]: {
				fieldId: field.key,
				text,
			},
		};
	},
	isMultiValueField: field.configuration?.isMulti || false,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
