import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noProjectsFound: {
		id: 'polaris-common.common.project-picker.no-projects-found',
		defaultMessage: 'No projects found',
		description: 'Placeholder text in project picker dropdown, when no projects could be found.',
	},
	typeToSearch: {
		id: 'polaris-common.common.project-picker.type-to-search',
		defaultMessage: 'Type to search for a project',
		description:
			'Initial placeholder text in project picker dropdown before a search request was made.',
	},
	projectPickerPlaceholder: {
		id: 'polaris-common.common.project-picker.project-picker-placeholder',
		defaultMessage: 'Search for a project',
		description: 'Placeholder text in project picker select component.',
	},
	preferredGroupLabel: {
		id: 'polaris-common.common.project-picker.preferred-group-label',
		defaultMessage: 'Previously selected',
		description: 'Group section in project search result showing the preferred delivery project',
	},
	searchResultsGroupLabel: {
		id: 'polaris-common.common.project-picker.search-results-group-label',
		defaultMessage: 'Search results ({maxResults} of {total})',
		description:
			'Group section in project search result showing the projects returned by project search',
	},
});
