import isEqual from 'lodash/isEqual';
import type { RemoteIssueLinksState } from '@atlassian/jira-issue-shared-types';
import { toRemoteLinkGlobalId, toIssueId } from '@atlassian/jira-shared-types/src';
import {
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
	type FetchIssueRemoteDataSuccess,
	type FetchIssueRemoteDataFailure,
} from '../../actions/issue-remote-data-actions';
import {
	DELETE_REMOTE_LINKED_ISSUE_REQUEST,
	DELETE_REMOTE_LINKED_ISSUE_FAILURE,
	DELETE_REMOTE_LINKED_ISSUE_SUCCESS,
	type RemoteIssueLinksAction,
	SAVE_REMOTE_LINKED_ISSUE_REQUEST,
	SAVE_REMOTE_LINKED_ISSUE_SUCCESS,
	SAVE_REMOTE_LINKED_ISSUE_FAILURE,
	SAVE_REMOTE_LINKED_ISSUE_RETRY,
	SAVE_REMOTE_LINKED_ISSUE_CANCEL,
} from '../../actions/remote-issue-links-actions';
import {
	FETCH_ISSUE_SUCCESS,
	type FetchIssueSuccessAction,
} from '../../common/actions/issue-fetch-actions';
import {
	transformServerRemoteIssueLinks,
	transformOptimisticRemoteIssueLink,
} from '../../common/service/issue/remote-issue-links-transformer';

type State = RemoteIssueLinksState;

type Action =
	| FetchIssueSuccessAction
	| FetchIssueRemoteDataSuccess
	| FetchIssueRemoteDataFailure
	| RemoteIssueLinksAction;

export const initialState: State = {
	remoteIssueCount: 0,
	remoteIssues: [],
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case FETCH_ISSUE_SUCCESS: {
			const { remoteLinks = {} } = action.payload;
			const { remoteLinkedIssues = [] } = remoteLinks;
			const issueCount = remoteLinkedIssues.length;

			// FETCH_ISSUE_SUCCESS and FETCH_ISSUE_REMOTE_DATA_SUCCESS come asynchronically, where race condition is possible
			// FETCH_ISSUE_SUCCESS is ONLY used for TTI preview, which should _not_ update store if full details are already fetched from FETCH_ISSUE_REMOTE_DATA_SUCCESS
			if (state.remoteIssueCount) {
				return state;
			}
			const nextState = {
				...state,
				remoteIssueCount: issueCount,
				remoteIssues: transformServerRemoteIssueLinks(remoteLinkedIssues),
			};

			return {
				...nextState,
			};
		}
		case FETCH_ISSUE_REMOTE_DATA_SUCCESS: {
			const { remoteLinks = {} } = action.payload;
			const { remoteLinkedIssues = [] } = remoteLinks;
			const issueCount = remoteLinkedIssues.length;

			const nextState = {
				...state,
				remoteIssueCount: issueCount,
				remoteIssues: transformServerRemoteIssueLinks(remoteLinkedIssues),
			};

			return isEqual(nextState, state)
				? state
				: {
						...nextState,
					};
		}
		case DELETE_REMOTE_LINKED_ISSUE_REQUEST: {
			const { globalId: globalIdtoDelete } = action.payload;
			return {
				...state,
				remoteIssues: state.remoteIssues.map((issue) => {
					if (issue.globalId === globalIdtoDelete) {
						return { ...issue, isDeleted: true };
					}
					return issue;
				}),
			};
		}
		case DELETE_REMOTE_LINKED_ISSUE_SUCCESS: {
			const deletedGlobalId = action.payload;
			return {
				...state,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				remoteIssues: state.remoteIssues.reduce<Array<any>>((newIssues, issue) => {
					if (issue.globalId !== deletedGlobalId) {
						newIssues.push(issue);
					}
					return newIssues;
				}, []),
			};
		}
		case DELETE_REMOTE_LINKED_ISSUE_FAILURE: {
			const globalId = action.payload;
			return {
				...state,
				remoteIssues: state.remoteIssues.map((issue) => {
					if (issue.globalId === globalId) {
						return { ...issue, isDeleted: false };
					}
					return issue;
				}),
			};
		}
		case SAVE_REMOTE_LINKED_ISSUE_REQUEST: {
			const { saveRemoteIssueLinkPayload } = action.payload;
			return {
				...state,
				remoteIssues: state.remoteIssues.concat(
					transformOptimisticRemoteIssueLink(saveRemoteIssueLinkPayload),
				),
			};
		}
		case SAVE_REMOTE_LINKED_ISSUE_SUCCESS: {
			const {
				optimisticId,
				saveRemoteLinkResponse: { id, issueId, globalId },
			} = action.payload;
			// TODO NIMBUS-243: improve response payload
			return {
				...state,
				remoteIssues: state.remoteIssues.map((link) =>
					link.id === optimisticId
						? {
								...link,
								id: id.toString(),
								globalId: toRemoteLinkGlobalId(globalId),
								remoteIssueId:
									issueId !== undefined ? toIssueId(issueId.toString()) : link.remoteIssueId,
								isOptimistic: false,
								isSaved: true,
							}
						: link,
				),
			};
		}
		case SAVE_REMOTE_LINKED_ISSUE_FAILURE: {
			const { optimisticId, error } = action.payload;
			if (error.statusCode === 400) {
				return {
					...state,
					remoteIssues: state.remoteIssues.filter((link) => link.id !== optimisticId),
				};
			}
			return {
				...state,
				remoteIssues: state.remoteIssues.map((link) =>
					link.id === optimisticId ? { ...link, isLoading: false, hasError: true } : link,
				),
			};
		}
		case SAVE_REMOTE_LINKED_ISSUE_RETRY: {
			const { optimisticId } = action.payload;
			return {
				...state,
				remoteIssues: state.remoteIssues.map((link) =>
					link.id === optimisticId ? { ...link, isLoading: true, hasError: false } : link,
				),
			};
		}
		case SAVE_REMOTE_LINKED_ISSUE_CANCEL: {
			const optimisticId = action.payload;
			return {
				...state,
				remoteIssues: state.remoteIssues.filter((link) => link.id !== optimisticId),
			};
		}
		default:
			return state;
	}
};
