import React, { useCallback } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Box, Flex, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useSetSortModeOfCurrentViewToProjectRankAndCommitViewRank } from '@atlassian/jira-polaris-common/src/controllers/idea-ranking';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useCanManageCurrentView } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	useCanEditIssues,
	useCanManageViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';
import { useSwitchedToProjectRankFlag } from './utils';

const SwitchToProjectRankItem = () => {
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();

	const showSwitchedToProjectRankFlag = useSwitchedToProjectRankFlag();
	const { setSortModeOfCurrentViewToProjectRank } = useViewActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickBackToProjectRank = useCallback(() => {
		setSortModeOfCurrentViewToProjectRank();
		showSwitchedToProjectRankFlag();
		fireCompoundAnalyticsEvent.SortChangeToProjectRankButtonClicked(createAnalyticsEvent({}));
	}, [createAnalyticsEvent, setSortModeOfCurrentViewToProjectRank, showSwitchedToProjectRankFlag]);

	const { formatMessage } = useIntl();
	return (
		<DropdownItem
			testId="polaris-ideas.ui.right-sidebar.sort.advanced.view-rank.switch-to-project-rank"
			description={
				<Box xcss={descriptionStyles}>{formatMessage(messages.backToProjectRankDescription)}</Box>
			}
			onClick={onClickBackToProjectRank}
			isDisabled={ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews}
		>
			{formatMessage(messages.backToProjectRank)}
		</DropdownItem>
	);
};

export const CommitToProjectRankItem = () => {
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const [canEditIssues] = useCanEditIssues();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const showSwitchedToProjectRankFlag = useSwitchedToProjectRankFlag();
	const setSortModeOfCurrentViewToProjectRankAndCommitViewRank =
		useSetSortModeOfCurrentViewToProjectRankAndCommitViewRank();

	const onClickBackToProjectRankWithCommit = useCallback(() => {
		setSortModeOfCurrentViewToProjectRankAndCommitViewRank();
		showSwitchedToProjectRankFlag();
		fireCompoundAnalyticsEvent.SortChangeToProjectRankAndCommitButtonClicked(
			createAnalyticsEvent({}),
		);
	}, [
		createAnalyticsEvent,
		setSortModeOfCurrentViewToProjectRankAndCommitViewRank,
		showSwitchedToProjectRankFlag,
	]);

	const { formatMessage } = useIntl();
	return (
		<DropdownItem
			testId="polaris-ideas.ui.right-sidebar.sort.advanced.view-rank.convert-to-project-rank"
			description={
				<Box xcss={descriptionStyles}>
					{formatMessage(messages.backToProjectRankWithUpdateDescription)}
				</Box>
			}
			onClick={onClickBackToProjectRankWithCommit}
			isDisabled={
				!(
					(ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews) &&
					canEditIssues
				)
			}
		>
			{formatMessage(messages.backToProjectRankWithUpdate)}
		</DropdownItem>
	);
};

export const ViewRankContent = () => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlockEnd="space.300">
			<Box paddingBlockStart="space.075" paddingBlockEnd="space.150">
				<Text>{formatMessage(messages.manualRankDescription)}</Text>
			</Box>
			<Box paddingBlockEnd="space.075">
				<Text size="UNSAFE_small">{formatMessage(messages.viewRankHeader)}</Text>
			</Box>
			<Box paddingBlockEnd="space.200">
				<Text size="UNSAFE_small">{formatMessage(messages.viewRankDescription)}</Text>
			</Box>
			<Flex alignItems="center">
				<DropdownMenu
					testId="polaris-ideas.ui.right-sidebar.sort.advanced.view-rank.change-to-project-rank-mode-trigger"
					trigger={formatMessage(messages.changeToProjectRankModeTrigger)}
					placement="top"
				>
					<Box xcss={dropdownMenuWrapperStyles}>
						<DropdownItemGroup>
							<SwitchToProjectRankItem />
							<CommitToProjectRankItem />
						</DropdownItemGroup>
					</Box>
				</DropdownMenu>
				<Box paddingInlineStart="space.050">
					<Tooltip content={formatMessage(messages.changeToProjectRankModeTooltip)}>
						<EditorInfoIcon label="info" />
					</Tooltip>
				</Box>
			</Flex>
		</Box>
	);
};

const descriptionStyles = xcss({
	whiteSpace: 'normal',
});

const dropdownMenuWrapperStyles = xcss({
	maxWidth: '320px',
});
