import React from 'react';
import { useGetStringFieldsRollup } from '../../../controllers/issue/selectors/fields-rollup-hooks';
import { CommonFieldGrouped } from '../common';
import type { Props } from '../types';

export const StringFieldGrouped = ({
	fieldKey,
	groupId,
	groupFieldKey,
	rollupOperation,
}: Props) => {
	const val = useGetStringFieldsRollup(fieldKey, groupFieldKey, groupId, rollupOperation);

	return <CommonFieldGrouped rollupOperation={rollupOperation} val={val} />;
};
