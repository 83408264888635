import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isAccessForbiddenError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { Permission } from './types';

export { type Permission };
export { getPermissionsMock, getViewerPermissionsMock, mockGetPermissions } from './mocks';

const permsOfInterestV2 = [
	'ADMINISTER_PROJECTS',
	'ADD_COMMENTS',
	'EDIT_OWN_COMMENTS',
	'EDIT_ALL_COMMENTS',
	'CREATE_ISSUES',
	'EDIT_ISSUES',
	'SCHEDULE_ISSUES',
	'DELETE_ISSUES',
	'CREATE_ATTACHMENTS',
	'DELETE_OWN_ATTACHMENTS',
	'DELETE_ALL_ATTACHMENTS',
	'LINK_ISSUES',
	'MOVE_ISSUES',

	'CREATE_DISCOVERY_INSIGHTS',
	'MANAGE_DISCOVERY_INSIGHTS',
	'MANAGE_DISCOVERY_DELIVERY',
	'MERGE_DISCOVERY_ISSUES',
	'ARCHIVE_DISCOVERY_ISSUES',
	'DISCOVERY_VOTE',
	'MANAGE_DISCOVERY_IDEA_TEMPLATES',

	'PIN_DISCOVERY_ISSUE_VIEW_FIELDS',
	'MANAGE_DISCOVERY_VIEWS',
	'SHARE_DISCOVERY_VIEWS',
	'PUBLISH_DISCOVERY_VIEWS',
	'CREATE_DISCOVERY_VIEW_COMMENTS',
	'MANAGE_DISCOVERY_VIEW_COMMENTS',

	'MANAGE_WATCHERS',
	'VIEW_VOTERS_AND_WATCHERS',
	'MODIFY_REPORTER',
	'BULK_CHANGE',
];

const getPermissionsQueryString = () =>
	[...permsOfInterestV2, 'PUBLISH_PUBLIC_DISCOVERY_VIEWS'].join();

// If the API returns 404 (project not available for user (permissions), we do not throw an error
export const fetchPermissions = (projectId: number): Promise<Permission[]> =>
	fetchJson(
		`/rest/api/3/mypermissions?projectId=${projectId}&permissions=${ff('polaris.published-view-public-links_dj5gi') ? getPermissionsQueryString() : permsOfInterestV2.join()}`,
	)
		.then((permissionsResponse) =>
			Object.keys(permissionsResponse.permissions).map((item) => ({
				key: permissionsResponse.permissions[item].key,
				havePermission: permissionsResponse.permissions[item].havePermission,
			})),
		)
		.catch((error) => {
			if (error.statusCode === 404) {
				return [];
			}
			throw error;
		});

export type FetchCurrentUserAccess = {
	accessLevel: ViewAccessLevel;
	permissions: Record<'edit_view' | 'read_view', boolean>;
};

export const fetchViewPermissionsForCurrentUser = async (
	viewId: string,
): Promise<{
	canView: boolean;
	canEdit: boolean;
}> => {
	try {
		const url = `${getJpdViewsServicePathPrefix()}/v1/views/${viewId}/access/my-access`;
		const response = await fetchJson<FetchCurrentUserAccess>(url);

		if (!response.permissions.read_view && !response.permissions.edit_view) {
			throw new Error('polaris.view-access.load-current-user-view-access-role: invalid response');
		}

		return {
			canView: response.permissions.read_view,
			canEdit: response.permissions.edit_view,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (isAccessForbiddenError(error) === false) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-access.load-current-user-view-access-role', error),
			);
		}

		return {
			canView: false,
			canEdit: false,
		};
	}
};
