import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectsTitle: {
		id: 'polaris-ideas.settings.breadcrumbs.projects-title',
		defaultMessage: 'Projects',
		description: 'Title of the breadcrumb that navigates to projects directory page',
	},
	projectSettings: {
		id: 'polaris-ideas.settings.breadcrumbs.project-settings',
		defaultMessage: 'Project settings',
		description: 'Title of the breadcrumb that navigates to project settings directory page',
	},
});
