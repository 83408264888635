import { ff } from '@atlassian/jira-feature-flagging';
import type { Action } from '@atlassian/react-sweet-state';
import { setSelfServeOnSiteEnabled } from '../../../services/jira/toggle-self-serve';
import type { State } from '../../types';

export const toggleSelfServeAddAsSiteCreator =
	(): Action<State> =>
	async ({ getState, setState }) => {
		const { features } = getState();
		const { isSelfServeEnabledOnSite } = features;

		if (ff('polaris.beta-migration-handling')) {
			setState({
				features: {
					...getState().features,
					isSelfServeEnabledOnSite: !isSelfServeEnabledOnSite,
				},
			});
			setSelfServeOnSiteEnabled(!isSelfServeEnabledOnSite).catch(() => {
				setState({
					features: {
						...getState().features,
						isSelfServeEnabledOnSite,
					},
				});
			});
		}
	};
