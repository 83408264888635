import React from 'react';
import { Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { flatPalette } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import {
	type ColorPickerProps,
	Color,
	ColorGrid,
	ColorPickerHeading,
} from '@atlassian/jira-polaris-lib-color-picker/src/ui/index.tsx';
import messages from './messages';

export const TemplateColorPicker = ({ color, onChange, compact }: ColorPickerProps) => {
	const { formatMessage } = useIntl();

	return (
		<Flex direction="column">
			<ColorPickerHeading>{formatMessage(messages.thumbnailColorPickerHeader)}</ColorPickerHeading>
			<ColorGrid compact={compact}>
				{flatPalette.map(({ mainColor }) => (
					<Color
						key={mainColor}
						color={mainColor}
						isSelected={color === mainColor}
						onChange={onChange}
						compact={compact}
						isFocused={false}
					/>
				))}
			</ColorGrid>
		</Flex>
	);
};
