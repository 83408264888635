import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';
import type { CascadingSelectPublicSetValue } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/cascading-select';
import { selectScopes } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/select';
import type { PublicToInternalPropertyTransformerContext } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types';
import { commonIssueViewGranularScopes } from './fields-scopes';

type CascadingSelectPublicGetValue = {
	parent: { id: string; value: string };
	child: { id: string; value: string } | null;
} | null;

type CascadingSelectInternalFullShapeValue = {
	id: string;
	value: string;
	child: {
		id: string;
		value: string;
	} | null;
} | null;

export const transformSetValueCascadingSelect = (
	value: CascadingSelectPublicSetValue,
	_lookupValues: undefined,
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
): CascadingSelectPublicSetValue => {
	if (value === null) {
		return null;
	}
	let childAri = null;

	const parentAri = JiraIssueFieldOptionAri.create({
		siteId: tenantContext.cloudId,
		activationId: tenantContext.activationId,
		fieldId: fieldContext.fieldId,
		optionId: value.parentId,
	}).toString();

	if (value?.childId) {
		childAri = JiraIssueFieldOptionAri.create({
			siteId: tenantContext.cloudId,
			activationId: tenantContext.activationId,
			fieldId: fieldContext.fieldId,
			optionId: value.childId,
		}).toString();
	}

	return {
		parentId: parentAri,
		childId: childAri,
	};
};

export const transformGetValueCascadingSelect = (
	value: CascadingSelectInternalFullShapeValue,
): CascadingSelectPublicGetValue => {
	if (!value) {
		return null;
	}

	const parent = { id: value.id, value: value.value };
	const child = value.child ? { id: value.child.id, value: value.child.value } : null;

	return { parent, child };
};

export const cascadingSelectIssueViewScopes = {
	...selectScopes,
	granular: [...selectScopes.granular, ...commonIssueViewGranularScopes],
};
