import React, { useMemo, useCallback } from 'react';
import { useOpenPreviewMode } from '@atlassian/jira-polaris-common/src/controllers/preview-mode';
import {
	useIssueCreationEnabled,
	useSaveIssueCreationEnabled,
	useSaveSubmitIdeaDescriptionTemplateId,
	useSaveSubmitIdeaFormMandatoryFields,
	useSubmitIdeaFormMandatoryFields,
	useSaveSubmitIdeaFormViewId,
	useSubmitIdeaFormDescriptionTemplate,
	useSubmitIdeaFormView,
} from '@atlassian/jira-polaris-common/src/controllers/project-properties';
import { useAllViewsNameAndIds } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { IssueCreationForm } from '@atlassian/jira-polaris-common/src/ui/create-idea-dialog/creation-form/index.tsx';
import { useErrorHandlers } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import PolarisProjectFormSettingsScreen from '@atlassian/jira-polaris-lib-forms-settings';
import type { Components } from '@atlassian/jira-polaris-lib-forms-settings/src/controllers/types.tsx';
import { IdeaTemplatesPanel } from '../../right-sidebar/idea-templates/main.tsx';
import { SectionMessageComponent } from '../../right-sidebar/idea-templates/section-message';
import { BreadcrumbsProjectSettings } from '../breadcrumbs';

export const PolarisFormsSettings = () => {
	const { generalActionFailedError } = useErrorHandlers();
	const issueCreationEnabled = useIssueCreationEnabled();
	const onIssueCreationChanged = useSaveIssueCreationEnabled();
	const viewId = useSubmitIdeaFormView();
	const onViewIdChanged = useSaveSubmitIdeaFormViewId();
	const descriptionTemplateId = useSubmitIdeaFormDescriptionTemplate();
	const onTemplateIdChanged = useSaveSubmitIdeaDescriptionTemplateId();
	const areFieldsMandatory = useSubmitIdeaFormMandatoryFields();
	const onFieldsMandatoryChanged = useSaveSubmitIdeaFormMandatoryFields();
	const viewOptions = useAllViewsNameAndIds();
	const openPreviewMode = useOpenPreviewMode();

	const components: Components = useMemo(
		() => ({
			IssueForm: (props) => <IssueCreationForm {...props} />,
			IssueFormSectionMessage: SectionMessageComponent,
			DescriptionTemplates: (props) => <IdeaTemplatesPanel {...props} />,
		}),
		[],
	);

	const onPreviewOpen = useCallback(
		(previewRoleName = 'contributor') => {
			openPreviewMode(previewRoleName);
		},
		[openPreviewMode],
	);

	return (
		<PolarisProjectFormSettingsScreen
			viewId={viewId}
			issueCreationEnabled={issueCreationEnabled}
			areFieldsMandatory={areFieldsMandatory}
			components={components}
			viewOptions={viewOptions}
			descriptionTemplateId={{
				...descriptionTemplateId,
				isLoading: descriptionTemplateId.isLoading,
			}}
			onError={generalActionFailedError}
			onIssueCreationChanged={onIssueCreationChanged}
			onViewIdChanged={onViewIdChanged}
			onDescriptionTemplateIdChanged={onTemplateIdChanged}
			onFieldsMandatoryChanged={onFieldsMandatoryChanged}
			onPreviewModeOpen={onPreviewOpen}
			breadcrumbs={<BreadcrumbsProjectSettings />}
		/>
	);
};
