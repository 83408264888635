import React, { memo, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Emoji } from '@atlaskit/emoji';
import SettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import StarIcon from '@atlaskit/icon/glyph/star';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import { ButtonItem } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { N500, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	useCanEditFields,
	useCanManageViews,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import { MenuOption } from '@atlassian/jira-polaris-lib-decoration/src/ui/menu-option/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldTypeIcon,
	useIsGlobalCustomField,
} from '../../../controllers/field/selectors/field-hooks';
import { useFieldsSidebarConfig } from '../../../controllers/idea/selectors/hooks';
import { useViewActions } from '../../../controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewLayoutType,
	useCurrentViewSummaryCardFieldKey,
	useCurrentViewKind,
} from '../../../controllers/views/selectors/view-hooks';
import { FIELD_ITEM_OUTER_SPACING } from '../../config/fields/field-item/constants';
import { DeliveryDataRestrictedMarker } from '../../field-info-marker/delivery-data-restricted';
import { FieldTooltip } from '../../field-tooltip';
import type { TogglePermissionType } from '../constants';
import { EmojiWrapper } from './emoji-wrapper';
import messages from './messages';
import { FieldToggle } from './toggle';

const cssFn = () => ({
	display: 'flex',
	padding: `${token('space.050', '4px')} 10px ${token('space.050', '4px')} 0`,
	minHeight: 'initial',
	backgroundColor: 'transparent',
	color: token('color.text.subtle', N500),
	'&:hover': {
		color: token('color.text', N800),
		backgroundColor: 'transparent',
		cursor: 'pointer',
		textDecoration: 'none',
	},
	'&:focus, & *:focus': {
		outline: 'none',
		boxShadow: 'none',
	},
	'&:active': {
		backgroundColor: 'transparent',
		color: token('color.text.subtle', N500),
	},
	'&:visited, &:active, &:focus': {
		textDecoration: 'none',
	},
});

type Props = {
	isSelected: boolean;
	isHidden: boolean;
	isDragEnabled?: boolean;
	fieldKey: FieldKey;
	hasReachedViewFieldsLimit?: string | null;
	togglePermissionType: TogglePermissionType;
	onEdit: (arg1: FieldKey) => void;
	onToggle: (arg1: FieldKey, arg2: boolean) => void;
	onHide?: (arg1: FieldKey) => void;
};

export const FieldListItem = memo(
	({
		fieldKey,
		isSelected,
		isHidden,
		isDragEnabled = true,
		hasReachedViewFieldsLimit = null,
		togglePermissionType,
		onEdit,
		onHide,
		onToggle,
	}: Props) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const canEditFields = useCanEditFields();
		const canManageCurrentView = useCanManageCurrentView();
		const canManageViews = useCanManageViews();
		const label = useFieldLabel(fieldKey);
		const icon = useFieldTypeIcon(fieldKey, undefined);
		const emojiId = useFieldEmoji(fieldKey);
		const emoji = useEmoji(emojiId);
		const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
		const isFieldHideable = fieldKey !== SUMMARY_FIELDKEY;
		const isTimelineView = useCurrentViewKind() === VIEW_KIND_TIMELINE;
		const fieldsSidebarConfig = useFieldsSidebarConfig();
		const isSummaryLayout = useCurrentViewLayoutType() === ViewLayoutType.SUMMARY;
		const isFieldSelectedForSummaryCard = useCurrentViewSummaryCardFieldKey() === fieldKey;
		const [isSummaryCardFieldToggleHovered, setIsSummaryCardFieldToggleHovered] = useState(false);
		const { setSummaryCardField } = useViewActions();

		const toggleFieldVisibilityTooltipContent = useMemo(() => {
			if (ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews) {
				return formatMessage(messages.noManageViewFieldsPermissions);
			}
			if (!isFieldHideable) {
				return formatMessage(messages.notHideable);
			}
			if (isHidden) {
				return formatMessage(messages.showField);
			}
			return formatMessage(messages.hideField);
		}, [canManageCurrentView, canManageViews, formatMessage, isFieldHideable, isHidden]);

		const fieldSettingsLabel = !canEditFields
			? formatMessage(messages.fieldSettings)
			: formatMessage(messages.editField);

		const shouldShowSummaryCardFieldToggle =
			(ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews) &&
			isFieldHideable &&
			isSelected &&
			isTimelineView &&
			fieldsSidebarConfig.mode !== 'LIST' &&
			isSummaryLayout;

		return (
			<FieldTooltip
				fieldKey={fieldKey}
				strategy="hover"
				position="left"
				isSummaryCardField={shouldShowSummaryCardFieldToggle && isSummaryCardFieldToggleHovered}
			>
				<Container>
					<MenuOption
						hideActionMenu
						isDragEnabled={isDragEnabled}
						setOpenOption={() => undefined}
						setIsMenuOpen={() => undefined}
						outerSpacing={FIELD_ITEM_OUTER_SPACING}
					>
						<LinkItemWrapper isFieldSelectedForSummaryCard={isFieldSelectedForSummaryCard}>
							<ButtonItem
								testId="polaris-common.ui.field-config.item.link-item"
								onClick={({ target, currentTarget }) => {
									let isInteractiveEl = false;
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									let node: HTMLElement = target as HTMLElement;
									while (!isInteractiveEl && node && node !== currentTarget) {
										isInteractiveEl = !!node.getAttribute('data-interactive');
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										node = node.parentNode as HTMLElement;
									}
									if (!isInteractiveEl && canEditFields) {
										fireUIAnalytics(
											createAnalyticsEvent({
												action: 'clicked',
												actionSubject: 'listItem',
											}),
											'field',
											{
												issueFieldKey: fieldKey,
											},
										);
										onEdit(fieldKey);
									}
								}}
								// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
								cssFn={cssFn}
								key={fieldKey}
								iconAfter={
									<ActionsIconsWrapper>
										<ButtonWithTooltipWrapper data-component-selector="settings-button-wrapper-2xZ4">
											<IconButton
												id="pendo.field-config.settings-button"
												testId="polaris-common.ui.field-config.item.settings-button"
												appearance="subtle"
												spacing="none"
												iconBefore={
													<SettingsIcon
														label={fieldSettingsLabel}
														primaryColor={token('color.icon', '#44546F')}
													/>
												}
												onClick={(e) => {
													e.stopPropagation();
													fireUIAnalytics(
														createAnalyticsEvent({
															action: 'clicked',
															actionSubject: 'icon',
														}),
														'editFieldValue',
														{
															issueFieldKey: fieldKey,
														},
													);
													sendPendoTrackEvent({
														actionSubjectAndAction: 'icon clicked',
														actionSubjectId: 'editFieldValue',
														attributes: { issueFieldKey: fieldKey },
													});
													onEdit(fieldKey);
												}}
												tooltipProps={{
													content: fieldSettingsLabel,
												}}
											/>
										</ButtonWithTooltipWrapper>
										{onHide && isSelected && (
											<ButtonWithTooltipWrapper
												isActive={isHidden}
												data-component-selector="hide-button-wrapper-35fC"
											>
												<IconButton
													testId="polaris-common.ui.field-config.item.hide-button"
													data-interactive
													isDisabled={
														!isFieldHideable ||
														(ff('polaris.view-permissions_plaoi')
															? !canManageCurrentView
															: !canManageViews)
													}
													onClick={() => {
														fireUIAnalytics(
															createAnalyticsEvent({
																action: 'clicked',
																actionSubject: 'icon',
															}),
															isHidden ? 'showField' : 'hideField',
															{
																issueFieldKey: fieldKey,
															},
														);
														onHide(fieldKey);
													}}
													appearance="subtle"
													spacing="compact"
													iconAfter={
														isHidden ? (
															<PolarisIcon
																name={PolarisIconType.Hidden}
																size="medium"
																label={messages.toggleField.id}
																primaryColor={token('color.icon', '#44546F')}
															/>
														) : (
															<PolarisIcon
																name={PolarisIconType.Visible}
																size="medium"
																label={messages.toggleField.id}
																primaryColor={
																	isFieldHideable &&
																	(ff('polaris.view-permissions_plaoi')
																		? canManageCurrentView
																		: canManageViews)
																		? token('color.icon', '#44546F')
																		: undefined
																}
															/>
														)
													}
													tooltipProps={{
														content: toggleFieldVisibilityTooltipContent,
													}}
												/>
											</ButtonWithTooltipWrapper>
										)}
										{shouldShowSummaryCardFieldToggle && (
											<StarButton
												id={`pendo.field-config.summary-card-toggle.${isFieldSelectedForSummaryCard ? 'disable' : 'enable'}`}
												testId="polaris-common.ui.field-config.item.summary-card-toggle"
												data-component-selector="summary-card-field-button-wrapper-56fC"
												data-interactive
												onClick={() => {
													setSummaryCardField(isFieldSelectedForSummaryCard ? undefined : fieldKey);
													fireUIAnalytics(
														createAnalyticsEvent({
															action: 'clicked',
															actionSubject: 'icon',
														}),
														isFieldSelectedForSummaryCard
															? 'hideFieldFromSummaryCard'
															: 'showFieldOnSummaryCard',
														{
															issueFieldKey: fieldKey,
														},
													);
												}}
												onMouseOver={() => {
													setIsSummaryCardFieldToggleHovered(true);
												}}
												onMouseLeave={() => {
													setIsSummaryCardFieldToggleHovered(false);
												}}
												appearance="subtle"
												spacing="none"
												iconAfter={
													isFieldSelectedForSummaryCard ? (
														<StarFilledIcon
															label={formatMessage(messages.showField)}
															primaryColor={token('color.icon', '#44546F')}
														/>
													) : (
														<StarIcon label={formatMessage(messages.hideField)} />
													)
												}
											/>
										)}
										<FieldToggle
											testId="polaris-common.ui.field-config.item.toggle-field-button"
											fieldKey={fieldKey}
											hasReachedViewFieldsLimit={hasReachedViewFieldsLimit}
											isSelected={isSelected}
											togglePermissionType={togglePermissionType}
											onToggle={onToggle}
										/>
									</ActionsIconsWrapper>
								}
							>
								<FieldListItemContainer>
									{emoji ? (
										<EmojiWrapper>
											<Emoji emoji={emoji} fitToHeight={16} showTooltip />
										</EmojiWrapper>
									) : (
										<Box xcss={iconWrapperStyles}>{icon}</Box>
									)}
									<Label>{label}</Label>
									{isGlobalCustomField && <GlobalFieldMarker />}
									<DeliveryDataRestrictedMarker fieldKey={fieldKey} marginLeft={8} />
								</FieldListItemContainer>
							</ButtonItem>
						</LinkItemWrapper>
					</MenuOption>
				</Container>
			</FieldTooltip>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'block',
});

const iconWrapperStyles = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionsIconsWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const iconButtonStyles = {
	transform: 'scale(0.85)',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const IconButton = styled(ButtonWithTooltip)(iconButtonStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const StarButton = styled(Button)(iconButtonStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWithTooltipWrapper = styled.div<{ isActive?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isActive }) => (isActive ? 'visible' : 'hidden'),
	transition: 'visibility 0.2s ease-in-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkItemWrapper = styled.div<{ isFieldSelectedForSummaryCard: boolean }>({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="summary-card-field-button-wrapper-56fC"] svg': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isFieldSelectedForSummaryCard }) =>
			isFieldSelectedForSummaryCard
				? token('color.icon', '#44546F')
				: token('color.icon.disabled', '#091E4224'),
		transition: 'color 0s 0.2s ease-in-out',
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="hide-button-wrapper-35fC"], [data-component-selector="settings-button-wrapper-2xZ4"]':
			{
				visibility: 'visible',
			},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="summary-card-field-button-wrapper-56fC"] svg': {
			transition: 'none',
			color: token('color.icon', '#44546F'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldListItemContainer = styled.div({
	maxWidth: '262px',
	alignItems: 'center',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
