import React, { useCallback, useEffect, useState, type ComponentType } from 'react';
import isFunction from 'lodash/isFunction';
import type { CallbackProps, SizedProps } from './types';

// from src/packages/software/roadmap/timeline-table/src/common/utils/use-resize-observer.js
const useResizeObserver = (element: HTMLElement | null, callback: () => void) => {
	useEffect(() => {
		let resizeObserver: ResizeObserver;
		if (element && typeof ResizeObserver !== 'undefined') {
			resizeObserver = new ResizeObserver(callback);
			resizeObserver.observe(element);
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		};
	}, [element, callback]);

	return null;
};

export const sized =
	<TProps,>(
		WrappedComponent: ComponentType<SizedProps<TProps>>,
	): ComponentType<CallbackProps<TProps>> =>
	(props: CallbackProps<TProps>) => {
		const { onSizeChanged, innerRef, ...rest } = props;
		const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

		const onContainerSizeChanged = useCallback(() => {
			if (containerRef) {
				onSizeChanged && onSizeChanged(containerRef.offsetWidth, containerRef.offsetHeight);
			}
		}, [containerRef, onSizeChanged]);

		const onInnerRef = useCallback(
			(ref: HTMLElement) => {
				setContainerRef(ref);
				if (innerRef !== undefined && innerRef !== null) {
					if (isFunction(innerRef)) {
						innerRef(ref);
					} else {
						// @ts-expect-error - TS2339 - Property 'current' does not exist on type 'never'.
						innerRef.current = ref;
					}
				}
			},
			[innerRef],
		);

		useResizeObserver(containerRef, onContainerSizeChanged);

		// @ts-expect-error Type 'Omit<CallbackProps<TProps>, "onSizeChanged" | "innerRef"> & { innerRef: (ref: any) => void; }' is not assignable to type 'IntrinsicAttributes & TProps & { innerRef: (arg1: HTMLElement) => void; } & { children?: ReactNode; }'.
		return <WrappedComponent {...rest} ref={onInnerRef} />;
	};
