import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import { createHigherLevelIssueHook, createSelectedIssueHook2, createIssueHook } from '../../utils';
import {
	getInsightRefreshErrors,
	getLastSnippetRefresh,
	getInsightRefreshErrorCount,
	getSortedSnippetLabels,
	createGetInsightsCount,
	createGetInsightsForIssue,
	createIsIssueHavingUnseenInsights,
	getRefreshErrorSnippetsIds,
	createGetTimestampCurrentUserSeenInsights,
} from './index';

export const useLastSnippetRefresh = createIssueHook(getLastSnippetRefresh);
export const useInsightRefreshErrors = createIssueHook(getInsightRefreshErrors);
export const useRefreshErrorSnippetsIds = createIssueHook(getRefreshErrorSnippetsIds);
export const useInsightRefreshErrorCount = createIssueHook(getInsightRefreshErrorCount);
export const useSortedSnippetLabels = createIssueHook(getSortedSnippetLabels);
export const useInsightsForIssue = createHigherLevelIssueHook<LocalIssueId, Insight[]>(
	createGetInsightsForIssue,
);
export const useInsightsCountForIssue = createHigherLevelIssueHook<LocalIssueId, number>(
	createGetInsightsCount,
);
export const useUnseenInsightsAvailableForIssue = createHigherLevelIssueHook<LocalIssueId, boolean>(
	createIsIssueHavingUnseenInsights,
);
export const useTimestampCurrentUserSeenInsights = createSelectedIssueHook2<number | undefined>(
	createGetTimestampCurrentUserSeenInsights,
);
