import React, { type ReactNode, useRef, useEffect } from 'react';

type Props = {
	observer?: IntersectionObserver;
	children: ReactNode;
};

export const Observer = ({ observer, children }: Props) => {
	const elementRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const el = elementRef?.current;
		el && observer?.observe(el);
		() => el && observer?.unobserve(el);
	}, [elementRef, observer]);

	return <div ref={elementRef}>{children}</div>;
};
