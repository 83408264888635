import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import head from 'lodash/head';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueTypeToTransitionsMap, Status } from '../types';

export type TransitionSubState = {
	transitions: IssueTypeToTransitionsMap;
};

export const getIssueTypeToTransitionsMap = (
	state: TransitionSubState,
): IssueTypeToTransitionsMap => state.transitions;

export const createGetTransitionsForIssueType = (issueTypeId?: IssueTypeId) =>
	createSelector(getIssueTypeToTransitionsMap, (map) => {
		if (issueTypeId === undefined) {
			return [];
		}
		return map[issueTypeId];
	});

export const createGetValidTransitionsForIssueTypeAndStatus = (
	issueTypeId?: IssueTypeId,
	status?: Status,
) => {
	const getTransitionsForIssueType = createGetTransitionsForIssueType(issueTypeId);

	return createSelector(getTransitionsForIssueType, (transitions) => {
		if (transitions === undefined || issueTypeId === undefined || status === undefined) {
			return [];
		}
		return filter(
			transitions,
			(transition) =>
				transition.isGlobal ||
				(transition.from?.id !== undefined && transition.from.id === status.id),
		);
	});
};

export const createGetValidTransitionTargetsForIssueTypeAndStatus = (
	issueTypeId: IssueTypeId,
	status: Status,
) => {
	const getValidTransitionsForIssueTypeAndStatus = createGetValidTransitionsForIssueTypeAndStatus(
		issueTypeId,
		status,
	);

	return createSelector(getValidTransitionsForIssueTypeAndStatus, (transitions) =>
		transitions.map(({ to }) => to),
	);
};

export const createGetTransitionForStatus = (
	issueTypeId: IssueTypeId,
	fromStatus: Status,
	toStatus: Status,
) => {
	const getValidTransitionsForIssueTypeAndStatus = createGetValidTransitionsForIssueTypeAndStatus(
		issueTypeId,
		fromStatus,
	);

	return createSelector(getValidTransitionsForIssueTypeAndStatus, (transitions) =>
		head(filter(transitions, ({ to }) => to.id === toStatus.id)),
	);
};
