import React, { useState } from 'react';
import { styled } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentViewSortMode } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	PROJECT_RANK,
	VIEW_RANK,
	FIELD_SORT,
} from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import { FieldSortContent } from './field-sort';
import messages from './messages';
import { ProjectRankContent } from './project-rank';
import { ViewRankContent } from './view-rank';

export const AdvancedSortingPanel = () => {
	const { formatMessage } = useIntl();
	const sortMode = useCurrentViewSortMode();
	const [expanded, setExpanded] = useState<boolean>(false);

	const handleChange = () => {
		setExpanded(!expanded);
	};
	if (sortMode === undefined) {
		return null;
	}
	return (
		<PanelWrapper>
			<PanelHeader id="polaris.ideas.src.ui.sidebar.settings.sort.advanced" onClick={handleChange}>
				<PanelTitle>
					{expanded ? <ChevronDownIcon label="collapse" /> : <ChevronRightIcon label="expand" />}
					<Header>{formatMessage(messages.sectionHeader)}</Header>
				</PanelTitle>
				{sortMode === VIEW_RANK && (
					<ViewRankIndicator>{formatMessage(messages.viewRankIndicator)}</ViewRankIndicator>
				)}
			</PanelHeader>
			{expanded ? (
				<PanelContent>
					{sortMode === PROJECT_RANK && <ProjectRankContent />}
					{sortMode === VIEW_RANK && <ViewRankContent />}
					{sortMode === FIELD_SORT && <FieldSortContent />}
				</PanelContent>
			) : null}
		</PanelWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.input.hovered', colors.N10),
	minHeight: '48px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.span({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	textTransform: 'uppercase',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelContent = styled.div({
	alignSelf: 'flex-start',
	paddingLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelHeader = styled.div({
	alignItems: 'center',
	justifyContent: 'space-between',
	cursor: 'pointer',
	display: 'flex',
	width: '100%',
	height: '48px',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		background: token('color.background.neutral.hovered', colors.N30A),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelTitle = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ViewRankIndicator = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.discovery', colors.P300),
	paddingRight: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	lineHeight: '20px',
});
