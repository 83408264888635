/** @jsx jsx */
import { useCallback, memo, useMemo, cloneElement } from 'react';
import { jsx, css } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Flex, xcss } from '@atlaskit/primitives';
import { N20 } from '@atlaskit/theme/colors';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import type { RowGroupRendererProps } from '../../common/types/react-base-table';
import { NO_VALUE_GROUP_ID } from '../../constants';
import { useListActions } from '../../controllers';
import { useRowGroupComponent } from '../../controllers/selectors/components-hooks';
import {
	useFixedColumnIndices,
	useVisibleColumnIndices,
	useInvisibleColumnPlaceholderWidthLeft,
	useIsExporting,
} from '../../controllers/selectors/ui-hooks';
import { SelectAllInGroupCheckBox } from '../cell/selection-checkbox';
import { borderColor } from '../constants';
import { RowContainer } from '../row/main';
import { LegacyRowGroupComponent } from './legacy';
import { messages } from './messages';

const DefaultRowGroupContainer = memo<RowGroupRendererProps>(
	({ cells, children, rowData }: RowGroupRendererProps) => {
		const { formatMessage } = useIntl();
		const fixedColumnIndices = useFixedColumnIndices();
		const visibleColumnIndices = useVisibleColumnIndices();
		const placeholderWidthLeft = useInvisibleColumnPlaceholderWidthLeft();
		const { colorMode } = useThemeObserver();
		const isDarkMode = colorMode === 'dark';

		const visibleCells = useMemo(
			() =>
				cells.filter(
					(_, index) => visibleColumnIndices.includes(index) && !fixedColumnIndices.includes(index),
				),
			[cells, fixedColumnIndices, visibleColumnIndices],
		);

		const fixedColumnsWidth = useMemo(
			() =>
				cells
					.filter((_, index) => fixedColumnIndices.includes(index))
					.reduce(
						// @ts-expect-error expected
						(acc, cell) => acc + cell?.props?.style?.width || 0,
						0,
					),
			[cells, fixedColumnIndices],
		);

		const { isExpanded, key } = rowData;
		const groupId = key === undefined ? NO_VALUE_GROUP_ID : key;

		const { toggleRowGroupExpansion } = useListActions();

		const onChangeExpansion = useCallback(() => {
			toggleRowGroupExpansion(groupId);
		}, [groupId, toggleRowGroupExpansion]);

		const fixedPart = useMemo(
			() => (
				<Flex xcss={fixedPartWrapperStyles}>
					<Flex alignItems="center">
						<div css={collapseButtonContainerStyles}>
							<IconButton
								onClick={onChangeExpansion}
								icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
								label={formatMessage(
									isExpanded
										? messages.collapseButtonCollapseLabel
										: messages.collapseButtonExpandLabel,
								)}
								appearance="subtle"
								spacing="compact"
							/>
						</div>
						<SelectAllInGroupCheckBox groupId={groupId} />
					</Flex>
					{children}
				</Flex>
			),
			[onChangeExpansion, isExpanded, formatMessage, groupId, children],
		);

		const lastFixedColumnIndex = fixedColumnIndices[fixedColumnIndices.length - 1];

		const fixedCell = useMemo(
			() =>
				cloneElement(
					// @ts-expect-error expected
					cells[lastFixedColumnIndex],
					{
						style: {
							// @ts-expect-error expected
							...(cells[lastFixedColumnIndex]?.props?.style ?? {}),
							width: fixedColumnsWidth,
							left: 0,
						},
					},
					fixedPart,
				),
			[cells, fixedColumnsWidth, fixedPart, lastFixedColumnIndex],
		);

		return (
			<RowContainer
				data-testid="polaris-lib-list.ui.group-row.row"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={isExpanded ? 'expanded' : 'collapsed'}
			>
				<Flex xcss={contentContainerStyles} alignItems="center">
					{fixedCell}
					{placeholderWidthLeft > 0 && (
						<div
							css={[
								placeholderStyles,
								isDarkMode && darkModeStyles,
								!isDarkMode && lightModeStyles,
							]}
							// eslint-disable-next-line jira/react/no-style-attribute
							style={{
								width: `${placeholderWidthLeft}px`,
							}}
						/>
					)}
					{visibleCells}
					<div
						css={[
							placeholderStyles,
							placeholderRightStyles,
							isDarkMode && darkModeStyles,
							!isDarkMode && lightModeStyles,
						]}
					/>
				</Flex>
			</RowContainer>
		);
	},
);

export const RowGroupComponent = componentWithFG(
	'polaris_refactor_table_headers_hover_cursor',
	memo<RowGroupRendererProps>((props: RowGroupRendererProps) => {
		const { key: groupId } = props.rowData;

		const isExporting = useIsExporting();

		const { toggleRowGroupExpansion, triggerRowGroupExpansion } = useListActions();
		const onChangeExpansion = useCallback(() => {
			toggleRowGroupExpansion(groupId);
		}, [groupId, toggleRowGroupExpansion]);

		const onExpandGroup = useCallback(() => {
			triggerRowGroupExpansion(groupId);
		}, [groupId, triggerRowGroupExpansion]);

		const ConsumerComponent = useRowGroupComponent();

		if (ConsumerComponent !== undefined) {
			return (
				<DefaultRowGroupContainer {...props} onChangeExpansion={onChangeExpansion}>
					<ConsumerComponent
						groupId={groupId === NO_VALUE_GROUP_ID ? undefined : groupId}
						isExporting={isExporting}
						onExpandGroup={onExpandGroup}
					/>
				</DefaultRowGroupContainer>
			);
		}

		return (
			<DefaultRowGroupContainer {...props} onChangeExpansion={onChangeExpansion}>
				<Flex xcss={rowGroupContainerStyles} alignItems="center">
					{groupId}
				</Flex>
			</DefaultRowGroupContainer>
		);
	}),
	LegacyRowGroupComponent,
);

export { StickyRowGroupComponent } from './sticky';

const collapseButtonContainerStyles = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minWidth: token('space.300'),
	minHeight: token('space.300'),
	margin: `0 ${token('space.050')}`,
});

const placeholderStyles = css({
	boxSizing: 'border-box',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderTop: `1px solid ${borderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	borderBottom: `1px solid ${borderColor}`,
});

const lightModeStyles = css({
	backgroundColor: N20,
});

const darkModeStyles = css({
	backgroundColor: token('elevation.surface.raised'),
});

const placeholderRightStyles = css({
	flex: 1,
});

const fixedPartWrapperStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	marginLeft: 'space.negative.100',
});

const rowGroupContainerStyles = xcss({
	width: '100%',
});

const contentContainerStyles = xcss({
	width: '100%',
});
