import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import LockIconNew from '@atlaskit/icon/core/lock-locked';
import UnlockIconNew from '@atlaskit/icon/core/lock-unlocked';
import LockIcon from '@atlaskit/icon/glyph/lock';
import UnlockIcon from '@atlaskit/icon/glyph/unlock';
import { R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import commonMessages from '../../../common/messages';
import { ButtonContent } from '../../../common/ui/styled';
import messages from './messages';
import type { Props } from './types';

const getTooltipText = (
	value:
		| null
		// @ts-expect-error - TS2304 - Cannot find name 'SecurityLevel'.
		| SecurityLevel
		| {
				description: string;
				id: number;
				name: string;
		  },
	// @ts-expect-error - TS2749 - 'MessageDescriptorV2' refers to a value, but is being used as a type here. Did you mean 'typeof MessageDescriptorV2'?
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage: (MessageDescriptorV2: MessageDescriptorV2, values?: any) => string,
) => {
	if (value) {
		return `${formatMessage(commonMessages.securityLevel)}: ${value.name}`;
	}

	return formatMessage(messages.setSecurityLevel);
};

const ButtonWithAnalytics = AnalyticsEventToProps('button', {
	onMouseEnter: 'hovered',
})(Button);

const ButtonWithAnalyticsNew = AnalyticsEventToProps('button', {
	onMouseEnter: 'hovered',
})(IconButton);

export const MenuItem = ({ value, isOpen = false, onClick, onHover }: Props) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onMouseEnter = onHover ? (_: any, event: UIAnalyticsEvent) => onHover(event) : undefined;

	const renderIcon = useCallback(() => {
		return value ? (
			<LockIconNew label="" color={token('color.icon.danger')} />
		) : (
			<UnlockIconNew label="" color={isOpen ? token('color.icon.brand') : 'currentColor'} />
		);
	}, [value, isOpen]);

	return (
		<Tooltip content={getTooltipText(value, formatMessage)} position="top">
			<div data-testid="issue-field.security-level.ui.security-level-view.menu-item.security-level-menu-item">
				{isVisualRefreshEnabled() ? (
					<ButtonWithAnalyticsNew
						testId="issue-field.security-level.ui.security-level-view.menu-item.button"
						appearance="default"
						spacing="default"
						isSelected={isOpen}
						onClick={onClick}
						onMouseEnter={onMouseEnter}
						icon={renderIcon}
					/>
				) : (
					<ButtonWithAnalytics
						testId="issue-field.security-level.ui.security-level-view.menu-item.button"
						appearance="subtle"
						spacing="default"
						isSelected={isOpen}
						onClick={onClick}
						onMouseEnter={onMouseEnter}
						iconBefore={
							<ButtonContent hasSecurityLevel={!!value} isActive={isOpen}>
								{value ? (
									<LockIcon label="" primaryColor={token('color.icon.danger', R400)} />
								) : (
									<UnlockIcon label="" />
								)}
							</ButtonContent>
						}
					/>
				)}
			</div>
		</Tooltip>
	);
};
