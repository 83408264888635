import React from 'react';
import { PolarisIdeasCommonContext } from '../context';
import { Container, IdeaContextHandler } from './main';
import type { Props } from './types';

export { useIdeaActions } from './main';

export const IdeaViewContainer = ({ children, ...rest }: Props) => (
	<PolarisIdeasCommonContext.Consumer>
		{(context) => (
			<Container {...rest}>
				<IdeaContextHandler context={context} />
				{children}
			</Container>
		)}
	</PolarisIdeasCommonContext.Consumer>
);

export type { ExternalProps } from './types';
