import { ff } from '@atlassian/jira-feature-flagging';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../types';

export const load =
	() =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ createAnalyticsEvent, onLoadingFailed, siteRemote }: Props,
	) => {
		siteRemote
			.getClassicProjectEpicNameFieldKey()
			.then((epicNameFieldKey?: string) => {
				setState({
					epicNameFieldKey,
				});
			})
			.catch((error: Error) => {
				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'epicNameField failure');
				onLoadingFailed(error);
			});

		siteRemote
			.getIssueLinkTypes()
			.then((response) => {
				setState({
					issueLinkTypes: response.issueLinkTypes,
				});
			})
			.catch((error: FetchError) => {
				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadIssueLinkTypes failure');

				if (error.statusCode !== 404) {
					onLoadingFailed(error);
				}

				setState({
					meta: {
						...getState().meta,
						errors: {
							issueLinkTypes: error,
						},
					},
				});
			});

		siteRemote
			.getJpdEditionsMeta()
			.then((response) => {
				setState({
					editionMeta: response,
				});
			})
			.catch((error) => {
				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'getEditionsMeta failure');
				onLoadingFailed(error);
			});

		if (ff('polaris.editor-with-ai')) {
			siteRemote
				.fetchIsEditorAiEnabled()
				.then((isEditorAiEnabled) => {
					setState({
						isEditorAiEnabled,
					});
				})
				.catch((error) => {
					fireOperationalAnalyticsDeferred(
						createAnalyticsEvent({}),
						'fetchIsEditorAiEnabled failure',
					);
					onLoadingFailed(error);
				});
		}
	};
