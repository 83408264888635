import React from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { ff } from '@atlassian/jira-feature-flagging';
import { useLeftSidebarFlyoutLock } from '@atlassian/jira-navigation-system';
import {
	useCanManageView,
	useViewsCountFromViewSetTypes,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { ActionItem, SaveAsNewViewActionItem } from './item';
import { messages } from './messages';

type ActionsProps = {
	viewId?: string;
	onDelete?: () => void;
	onRemoveEmoji?: () => void;
	onRename: () => void;
	onSaveAsNewBoard?: () => void;
	onSaveAsNewList?: () => void;
	onSaveAsNewMatrix?: () => void;
	onSaveAsNewTimeline?: () => void;
};

const SaveAsNewViewActions = ({
	onSaveAsNewBoard,
	onSaveAsNewList,
	onSaveAsNewMatrix,
	onSaveAsNewTimeline,
}: ActionsProps) => (
	<>
		<SaveAsNewViewActionItem
			testId="polaris-ideas.ui.sidebar.sections.view-item.actions.save-as-new-list"
			message={messages.saveAsNewList}
			onClick={onSaveAsNewList}
		/>
		<SaveAsNewViewActionItem
			testId="polaris-ideas.ui.sidebar.sections.view-item.actions.save-as-new-board"
			message={messages.saveAsNewBoard}
			onClick={onSaveAsNewBoard}
		/>
		<SaveAsNewViewActionItem
			testId="polaris-ideas.ui.sidebar.sections.view-item.actions.save-as-new-matrix"
			message={messages.saveAsNewMatrix}
			onClick={onSaveAsNewMatrix}
		/>
		<SaveAsNewViewActionItem
			testId="polaris-ideas.ui.sidebar.sections.view-item.actions.save-as-new-timeline"
			message={messages.saveAsNewTimeline}
			onClick={onSaveAsNewTimeline}
		/>
	</>
);

export const Actions = (props: ActionsProps) => {
	const { viewId, onDelete, onRemoveEmoji, onRename } = props;

	const canManageView = useCanManageView(viewId);

	useLeftSidebarFlyoutLock();

	const allVisibleViewsCount = useViewsCountFromViewSetTypes(['CAPTURE', 'PRIORITIZE']);
	const isLastView = allVisibleViewsCount === 1;

	return (
		<DropdownItemGroup>
			{(!ff('polaris.view-permissions_plaoi') || canManageView) && (
				<>
					<ActionItem
						testId="polaris-ideas.ui.sidebar.sections.view-item.actions.rename"
						message={messages.rename}
						onClick={onRename}
					/>
					<ActionItem
						testId="polaris-ideas.ui.sidebar.sections.view-item.actions.remove-emoji"
						message={messages.removeEmoji}
						onClick={onRemoveEmoji}
					/>
				</>
			)}
			<SaveAsNewViewActions {...props} />
			{(!ff('polaris.view-permissions_plaoi') || canManageView) && (
				<ActionItem
					isDisabled={isLastView}
					testId="polaris-ideas.ui.sidebar.sections.view-item.actions.delete"
					message={messages.delete}
					onClick={onDelete}
				/>
			)}
		</DropdownItemGroup>
	);
};
