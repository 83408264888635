import React, {
	forwardRef,
	useRef,
	useImperativeHandle,
	memo,
	type RefObject,
	type ReactElement,
	type PropsWithChildren,
	type ForwardedRef,
	type Ref,
	type RefAttributes,
} from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import type { DraggableState, ItemId, RenderItem, TreeItem } from '../../../types';

export type TreeItemHeadProps<TItem extends TreeItem> = PropsWithChildren<{
	ariaLabel?: string;
	item: TItem;
}>;

export type TreeItemContainerProps<TItem extends TreeItem> = PropsWithChildren<{
	item: TItem;
	indentPerLevel: number;
	currentLevel: number;
	isExpanded?: boolean;
}>;

export type RefHandler = {
	containerRef: RefObject<HTMLDivElement>;
	draggableRef: RefObject<HTMLDivElement>;
};

export type TreeItemBaseProps<TItem extends TreeItem> = {
	item: TItem;
	renderItem: RenderItem<TItem>;
	indentPerLevel: number;
	currentLevel: number;
	isExpanded?: boolean;
	onExpand?(itemId: ItemId): void;
	onCollapse?(itemId: ItemId): void;
	ariaLabel?: string;
	dropIndicator?: ReactElement | null;
	draggableState?: DraggableState;
	TreeItemHead?: React.FC<TreeItemHeadProps<TItem> & RefAttributes<HTMLElement>>;
	TreeItemContainer?: React.FC<TreeItemContainerProps<TItem & RefAttributes<HTMLElement>>>;
};

const DefaultTreeItemBaseContainer = forwardRef(
	<TItem extends TreeItem>(
		{
			children,
			item,
			isExpanded,
			indentPerLevel,
			currentLevel,
		}: PropsWithChildren<{
			item: TItem;
			indentPerLevel: number;
			currentLevel: number;
			isExpanded?: boolean;
		}>,
		ref: Ref<HTMLDivElement>,
	) => (
		<Box
			ref={ref}
			role="treeitem"
			paddingBlockStart="space.0"
			paddingInline="space.0"
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...(item.hasChildren && { 'aria-expanded': isExpanded })}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ paddingLeft: `${indentPerLevel * currentLevel}px` }}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				position: 'relative',
			})}
		>
			{children}
		</Box>
	),
);

const DefaultTreeItemBaseHead = forwardRef(
	<TItem extends TreeItem>(
		{ children, ariaLabel }: PropsWithChildren<{ ariaLabel?: string; item: TItem }>,
		ref: Ref<HTMLDivElement>,
	) => (
		<Box ref={ref} aria-label={ariaLabel}>
			{children}
		</Box>
	),
);

const TreeItemBaseInner = <TItem extends TreeItem>(
	props: PropsWithChildren<TreeItemBaseProps<TItem>>,
	ref: ForwardedRef<RefHandler>,
) => {
	const {
		children,
		item,
		renderItem,
		isExpanded,
		onExpand,
		onCollapse,
		dropIndicator,
		draggableState,
		ariaLabel,
		TreeItemContainer,
		TreeItemHead,
	} = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const draggableRef = useRef<HTMLDivElement>(null);
	useImperativeHandle(
		ref,
		(): RefHandler => ({
			containerRef,
			draggableRef,
		}),
	);

	const Container = TreeItemContainer || DefaultTreeItemBaseContainer;
	const Head = TreeItemHead || DefaultTreeItemBaseHead;

	return (
		<Container ref={containerRef} {...props}>
			<Head ref={draggableRef} ariaLabel={ariaLabel} item={item}>
				{renderItem({
					item,
					onExpand,
					onCollapse,
					draggableState,
				})}
				{dropIndicator}
			</Head>
			{isExpanded && children}
		</Container>
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const TreeItemBase = memo(forwardRef(TreeItemBaseInner)) as <TItem extends TreeItem>(
	props: PropsWithChildren<TreeItemBaseProps<TItem>> & {
		ref?: RefObject<RefHandler> | null;
	},
) => ReactElement;
