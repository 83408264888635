import { useEffect, useMemo, useState, useCallback } from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useShowMessage = () => useState(false);

const EP_MESSAGE_ID = 'command-palette-issue-action-spotlight';

export const useSpotlightControl = () => {
	const { cloudId } = useTenantContext();
	const { data: orgId } = useOrgId();
	const client = useMemo(
		() => new CoordinationClient(cloudId, '/gateway/api', false, orgId),
		[cloudId, orgId],
	);
	const [msgStarted, stopMsg] = useCoordination(client, EP_MESSAGE_ID);
	const [show, setShow] = useShowMessage();

	useEffect(() => {
		if (msgStarted) {
			setShow(true);
		}
	}, [msgStarted, setShow]);

	const dismiss = useCallback(() => {
		setShow(false);
		stopMsg();
	}, [setShow, stopMsg]);

	return { show, dismiss };
};
