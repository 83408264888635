import { ApolloError } from 'apollo-client';
import ExtensibleError from '@atlassian/jira-extensible-error';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';
import { ENTITY_LIMIT_ERROR_TYPE, type LimitType } from './types';

type EntityLimitErrorTypeKeys = keyof typeof ENTITY_LIMIT_ERROR_TYPE;

export class EntityLimitError extends ExtensibleError {
	name = 'EntityLimitError';

	errors: { error: string }[];

	statusCode: number | null;

	type: LimitType;

	constructor(
		message: string,
		errors: { error: string }[] = [],
		statusCode: number | null = null,
		type: LimitType,
	) {
		super(message, { cause: errors[0] });
		this.errors = errors;
		this.statusCode = statusCode;
		this.type = type;
	}
}

export const getEntityLimitError = (error: Error | EntityLimitError): EntityLimitError | null => {
	// If already an EntityLimitError, return it
	if (error instanceof EntityLimitError) {
		return error;
	}
	// If not an ApolloError, return null as we can't handle it
	if (!(error instanceof ApolloError)) {
		return null;
	}

	// Get first entity limit error
	const entityLimitError = error.graphQLErrors?.filter((err) => err.extensions?.limitType)[0];
	// If no entity limit error or no extensions, return null
	if (!entityLimitError?.extensions) {
		return null;
	}

	const { statusCode, limitType } = entityLimitError.extensions;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const entityLimitErrorType = ENTITY_LIMIT_ERROR_TYPE[limitType as EntityLimitErrorTypeKeys];

	return new EntityLimitError(
		entityLimitError.message,
		[
			{
				error: entityLimitError.message,
			},
		],
		statusCode,
		entityLimitErrorType,
	);
};

export const getErrorMessageDescriptor = (error: EntityLimitError): MessageDescriptor | null =>
	messages[error.type] || null;
