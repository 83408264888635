export class AssociationHardLimitError extends Error {
	private readonly projectsErrors: Record<string, string> | undefined;

	constructor(message: string, projectsErrors?: Record<string, string>) {
		super(message);
		this.projectsErrors = projectsErrors;
	}

	getProjectsErrors(): Record<string, string> | undefined {
		return this.projectsErrors;
	}
}
