import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editField: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.edit-field',
		defaultMessage: 'Edit field',
		description: 'Button label for editing the field.',
	},
	fieldSettings: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button label for opening field settings.',
	},
	sort: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.sort',
		defaultMessage: 'Sort',
		description: 'Button label for sorting by column.',
	},
	sortAscending: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.sort-ascending',
		defaultMessage: 'Sort ascending',
		description: 'Button label for sorting by column.',
	},
	sortDescending: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.sort-descending',
		defaultMessage: 'Sort descending',
		description: 'Button label for sorting by column.',
	},
	removeSort: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.remove-sort',
		defaultMessage: 'Remove sort',
		description: 'Button label for removing the sorting by column.',
	},
	removeFromView: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.remove-from-view',
		defaultMessage: 'Remove from view',
		description: 'Button label for removing the field from the view.',
	},
	viewAsBoard: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.view-as-board',
		defaultMessage: 'View as board',
		description: 'Button label for creating a board based on the field.',
	},
	viewAsBoardDisabled: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.view-as-board-disabled',
		defaultMessage: 'This operation is not available for views in this section',
		description:
			'Message for explaining why the user cannot create a board view from the current view.',
	},
	nonRemovableFromView: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.non-removable-from-view',
		defaultMessage: 'This field cannot be removed from the view',
		description: 'Tooltip label indicating that the current field cannot be removed from the view',
	},
	noManageViewPermissions: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.no-manage-view-permissions',
		defaultMessage: 'Only project admins can manage views',
		description: 'Information message for missing view manage permissions.',
	},
});
