import { ff } from '@atlassian/jira-feature-flagging';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { loadSelectedFields } from '../load-selected-fields';
import { setNewlyAddedGlobalFields } from '../newly-added-global-fields';

/**
 * The `dependencies` array is intended for the dependencies of Global Formula fields
 */
export const associateGlobalFields =
	(fieldKeys: FieldKey[], dependencies: FieldKey[] = []): Action<State, Props> =>
	async (
		{ dispatch },
		{ projectId, ideaTypes, createAnalyticsEvent, globalFieldRemote },
	): Promise<void> => {
		const ideaType = ideaTypes?.[0];

		if (ideaType === undefined) {
			throw new Error('cannot associate field for unknown idea type');
		}
		if (projectId === undefined) {
			throw new Error('cannot associate field for unknown project');
		}
		await wrapWithExperience(experience.globalFieldsAdministration.globalFieldAssociate, () =>
			globalFieldRemote.addGlobalFieldsToProject({
				fieldKeys,
				issueType: {
					issueTypeId: ideaType.jiraIssueTypeId,
					projectId,
				},
			}),
		);

		const allNewFields: FieldKey[] = [...fieldKeys];
		if (ff('polaris.global-formulas-dependencies')) {
			allNewFields.push(...dependencies);
		}
		await dispatch(loadSelectedFields(allNewFields));
		dispatch(setNewlyAddedGlobalFields(allNewFields));

		fireTrackAnalytics(createAnalyticsEvent({}), 'field associated', {
			issueFieldKeys: fieldKeys,
		});
	};
