import orderBy from 'lodash/orderBy';
import { fieldTypeNameForPolarisFieldType } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/field-type-name/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Props, State } from '../../types';

export const orderFieldsBy = (
	fields: Field[],
	column: State['sort']['column'] = 'label',
	direction: State['sort']['direction'],
	formatMessage?: Props['formatMessage'],
) => {
	if (column === 'fieldType') {
		return orderBy(
			fields,
			(field) => {
				const type = fieldTypeNameForPolarisFieldType(field.type);

				if (!type) {
					return '';
				}

				return formatMessage?.(type) ?? type.defaultMessage;
			},
			direction,
		);
	}

	if (column === 'label' || column === 'description') {
		return orderBy(fields, (field) => field[column]?.toString().toLowerCase(), direction);
	}

	return orderBy(fields, column, direction);
};
