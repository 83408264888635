import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	startDateLabel: {
		id: 'polaris-common.controllers.issue.utils.view-filtering.view-timeline.start-date-label',
		defaultMessage: 'Starts in: {date}',
		description: '',
	},
	endDateLabel: {
		id: 'polaris-common.controllers.issue.utils.view-filtering.view-timeline.end-date-label',
		defaultMessage: 'Ends in: {date}',
		description: '',
	},
});
