import React from 'react';
import {
	SUMMARY_FIELDKEY,
	DESCRIPTION_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useSubmitIdeaFormView } from '../../../controllers/project-properties';
import { useValidIssueSubmissionFormFieldKeys } from '../../../controllers/views/selectors/view-hooks';
import { FieldRenderer } from '../render-field';
import { usePredefinedFiltersFieldValues } from '../utils';

export const IssueCreationForm = ({
	isPreview,
	shouldUpdateTemplate,
	setFieldValue,
}: {
	isPreview?: boolean;
	shouldUpdateTemplate?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue?: (name: string, value: any) => void;
}) => {
	const viewId = useSubmitIdeaFormView();
	const viewFieldKeys = useValidIssueSubmissionFormFieldKeys(viewId.value);

	const predefinedValues = usePredefinedFiltersFieldValues(viewId.value);

	// use summary and description fields by default if empty fieldKeys list return for form
	const fieldKeysWithDescription = viewFieldKeys.length ? [...viewFieldKeys] : [SUMMARY_FIELDKEY];
	fieldKeysWithDescription.splice(
		fieldKeysWithDescription.indexOf(SUMMARY_FIELDKEY) + 1,
		0,
		DESCRIPTION_FIELDKEY,
	);

	return (
		<>
			{fieldKeysWithDescription.map((fieldKey: FieldKey) => {
				const predefinedValue = predefinedValues.find(
					(field) => field.fieldKey === fieldKey,
				)?.value;
				return (
					<FieldRenderer
						key={fieldKey}
						predefinedValue={predefinedValue}
						fieldKey={fieldKey}
						setFieldValue={setFieldValue}
						isPreview={isPreview}
						shouldUpdateTemplate={shouldUpdateTemplate}
					/>
				);
			})}
		</>
	);
};
