import debounce from 'lodash/debounce';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { updateProjectState } from '../utils';
import { mergeViews } from './utils';

const debounceInterval = 1000; // 1 sec

const refreshViewsFunc = async (
	{ setState, getState, dispatch }: StoreActionApi<State>,
	{ navigationRemote, cloudId, projectId, onFailure, onSuccess }: Props,
) => {
	if (!cloudId || !projectId || !navigationRemote || !getState().projects[projectId]?.initialized) {
		return;
	}

	if (getState().projects[projectId]?.isRefreshing) {
		setState(
			updateProjectState(getState(), projectId, {
				scheduledRefresh: true,
			}),
		);
		return;
	}

	setState(
		updateProjectState(getState(), projectId, {
			isRefreshing: true,
			initialized: true,
			scheduledRefresh: false,
			refreshError: null,
		}),
	);

	try {
		const views = await navigationRemote.fetch(projectId);
		setState(
			updateProjectState(getState(), projectId, {
				isRefreshing: false,
				refreshError: null,
				views: mergeViews(getState().projects[projectId].views, views),
			}),
		);
		onSuccess?.('refreshViews');

		// next load if scheduled
		if (getState().projects[projectId]?.scheduledRefresh) {
			dispatch(refreshViews());
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		setState(
			updateProjectState(getState(), projectId, {
				isRefreshing: false,
				refreshError: error,
			}),
		);

		onFailure?.(error, 'refreshViews');
	}
};

export const refreshViews = (): Action<State, Props> => async (storeApi, props) => {
	const { getState, setState } = storeApi;
	if (!getState().projects[props.projectId]?.debouncedRefresh) {
		const debouncedRefresh = debounce(refreshViewsFunc, debounceInterval);
		setState(
			updateProjectState(getState(), props.projectId, {
				debouncedRefresh,
			}),
		);
	}
	await getState().projects[props.projectId]?.debouncedRefresh?.(storeApi, props);
};
