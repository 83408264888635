import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-lib-onboarding.create-idea.title',
		defaultMessage: 'Create new ideas',
		description: 'Spotlight title for "create idea" button',
	},
	description: {
		id: 'polaris-lib-onboarding.create-idea.description',
		defaultMessage:
			'An idea can represent a user problem, a product feature, an opportunity, or a solution.',
		description: 'Spotlight description for "create idea" button',
	},
	learnMoreText: {
		id: 'polaris-lib-onboarding.create-idea.learn-more-text',
		defaultMessage: 'Learn more how to create ideas in the list view',
		description: 'Accessible text fot "Learn more" button',
	},
});
