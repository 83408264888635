import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const addInsightsGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.2855 14.5857L16.2858 14.6271C16.2858 15.9607 15.2048 17.0417 13.8712 17.0417C12.5377 17.0417 11.4566 15.9607 11.4566 14.6271C11.4566 14.5717 11.4585 14.5168 11.4622 14.4623L10.4058 13.4059C10.1963 13.4652 9.97532 13.4969 9.74688 13.4969C9.54452 13.4969 9.34797 13.472 9.16014 13.4251L5.81169 16.7736C5.82326 16.8694 5.82921 16.967 5.82921 17.0659C5.82921 18.3995 4.74815 19.4805 3.4146 19.4805C2.08105 19.4805 1 18.3995 1 17.0659C1 15.7324 2.08105 14.6513 3.4146 14.6513C3.60411 14.6513 3.78851 14.6731 3.96544 14.7144L7.34529 11.3346C7.33669 11.2517 7.33228 11.1675 7.33228 11.0823C7.33228 9.74878 8.41333 8.66772 9.74688 8.66772C11.0804 8.66772 12.1615 9.74878 12.1615 11.0823C12.1615 11.1388 12.1595 11.1949 12.1557 11.2504L13.2096 12.3043C13.4198 12.2445 13.6418 12.2125 13.8712 12.2125C14.1368 12.2125 14.3923 12.2554 14.6312 12.3345L17.0895 9.91117C17.1059 9.89501 17.1322 9.89487 17.1488 9.91086L19.054 11.7495C19.071 11.7659 19.0713 11.7932 19.0545 11.81L16.2855 14.5857Z"
			fill="currentColor"
		/>
		<path
			d="M21.2207 6.88915C21.2207 6.39809 20.8226 6 20.3315 6C19.8405 6 19.4424 6.39809 19.4424 6.88915V10.4458C19.4424 10.9368 19.8405 11.3349 20.3315 11.3349C20.8226 11.3349 21.2207 10.9368 21.2207 10.4458V6.88915Z"
			fill="currentColor"
		/>
		<path
			d="M22.1098 7.7783H18.5532C18.0621 7.7783 17.6641 8.17639 17.6641 8.66746C17.6641 9.15852 18.0621 9.55661 18.5532 9.55661H22.1098C22.6009 9.55661 22.999 9.15852 22.999 8.66746C22.999 8.17639 22.6009 7.7783 22.1098 7.7783Z"
			fill="currentColor"
		/>
	</svg>
);
