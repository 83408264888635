import type { RecursiveFilter } from '../../filter/types';
import { type DataPointCountFormula, COUNT_TEMPLATE } from './types';

export const count = (
	oauthClientId: string,
	excludedClientIds: string[],
	filter?: RecursiveFilter,
): DataPointCountFormula => ({
	template: COUNT_TEMPLATE,
	parameters: {
		oauthClientId: excludedClientIds.includes(oauthClientId) ? undefined : oauthClientId,
		filter,
	},
});
