import { ff } from '@atlassian/jira-feature-flagging';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';

export const tryAddCurrentUserAsSiteCreator =
	(): Action<State, Props> =>
	async ({ getState, setState }, { siteRemote }) => {
		const { editionMeta } = getState();
		const { canSelfServe } = editionMeta;

		if (ff('polaris.beta-migration-handling') && canSelfServe) {
			Promise.all([
				siteRemote.addAsSiteCreator(), // perform action on polaris-api
				siteRemote.addCurrentUserToSiteCreators(), // log on monolith
			])
				.then(() => {
					setState({
						editionMeta: {
							...getState().editionMeta,
							siteCreator: true,
						},
					});
				})
				.catch(() => {
					setState({
						editionMeta: {
							...getState().editionMeta,
							siteCreator: true,
							canSelfServe: false,
						},
					});
				});
		}
	};
