import React, { memo } from 'react';
import { useRankedIssueCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import {
	useCurrentViewVisibleIssueActionFields,
	useCurrentViewVisibleFields,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { Observer } from '@atlassian/jira-polaris-lib-performance/src/controllers/index.tsx';

type PerformanceObserverProps = {
	scrollingContainer: HTMLElement | null;
	isGrouped: boolean;
};

export const PerformanceObserver = memo<PerformanceObserverProps>(
	({ scrollingContainer, isGrouped }: PerformanceObserverProps) => {
		const visibleFields = useCurrentViewVisibleFields();
		const visibleIssueActionFields = useCurrentViewVisibleIssueActionFields();
		const issuesCount = useRankedIssueCount();
		return (
			<Observer
				scrollingContainer={scrollingContainer}
				jpdExperience={experience.boardView.scrollPerformance}
				metadata={{
					isGrouped: String(isGrouped),
					fieldsCount: String(visibleFields.length + visibleIssueActionFields.length),
					issuesCount: String(issuesCount),
				}}
			/>
		);
	},
);
