import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	insightInfoBudget: {
		id: 'polaris-ideas.insights.insights.play-insight.insight-info-budget',
		defaultMessage:
			'This idea had {totalBudget, plural, =0 {no votes} one {# vote} other {# votes}}',
		description: 'Helpfull insight information',
	},
	commentsInfo: {
		id: 'polaris-ideas.insights.insights.play-insight.comments-info',
		defaultMessage: '{comments, plural, one {# total comment} other {# total comments}}',
		description: 'Helpfull play contributions information',
	},
	votesInfo: {
		id: 'polaris-ideas.insights.insights.play-insight.votes-info',
		defaultMessage: '{votes, plural, one {# total vote} other {# total votes}} given',
		description: 'Helpfull play contributions information',
	},
	votersInfo: {
		id: 'polaris-ideas.insights.insights.play-insight.voters-info',
		defaultMessage: '{voters, plural, one {# total participant} other {# total participants}}',
		description: 'Helpfull play contributions information',
	},
	seeDetails: {
		id: 'polaris-ideas.insights.insights.play-insight.see-details',
		defaultMessage: 'See details',
		description: 'Link text to see votes details',
	},
	playCardTitleVoters: {
		id: 'polaris-ideas.insights.insights.play-insight.play-card-title-voters',
		defaultMessage: 'Voters',
		description: 'Voters play card title',
	},
	playCardTitleVotes: {
		id: 'polaris-ideas.insights.insights.play-insight.play-card-title-votes',
		defaultMessage: 'Votes',
		description: 'Voters play card title',
	},
	playCardTitleComments: {
		id: 'polaris-ideas.insights.insights.play-insight.play-card-title-comments',
		defaultMessage: 'Comments',
		description: 'Voters play card title',
	},
});
