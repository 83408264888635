import type { State } from './types';

export const initialState: State = {
	meta: {
		loading: true,
		error: undefined,
		loadingProps: undefined,
	},
	viewSets: [],
	containerProps: undefined,
	isExporting: false,
	isReadyToExport: false,
	hasUnsavedChanges: false,
	forceReloadOnNextUpdate: false,
	hasManageCurrentViewPermission: false,
};
