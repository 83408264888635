import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'project-settings-apps-notification-email-page.page-title',
		defaultMessage: 'Notification email',
		description: 'Title for email notifications page when used as page',
	},
	sectionTitle: {
		id: 'project-settings-apps-notification-email-page.section-title',
		defaultMessage: 'Email',
		description: 'Title for email notifications page when used as section',
	},
	pageDescription: {
		id: 'project-settings-apps-notification-email-page.page-description',
		defaultMessage:
			'Notification emails for this {project} can be sent directly from {productName}, or you can use a custom email with your own domain instead. {learnMore}.',
		description: 'Description text for the email notifications page',
	},
	learnMore: {
		id: 'project-settings-apps-notification-email-page.learn-more',
		defaultMessage: 'Learn more about sending emails from your domain',
		description: 'Clickable link to a support page',
	},
});
