import React, { useState, useCallback } from 'react';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { User as UserType } from '../../../../common/types/user';
import { useField } from '../../../../controllers/issue/selectors/fields-hooks';
import { usePeopleByAccountId } from '../../../../controllers/issue/selectors/properties/hooks';
import type { FieldComponentProps } from '../types';
import { UsersField } from './users-field';

type UsersFieldProps = FieldComponentProps & {
	fieldKey: FieldKey;
};

export const UsersFieldComponent = ({
	fieldKey,
	onUpdate,
	isEditable,
	predefinedValue,
	...fieldProps
}: UsersFieldProps) => {
	const field = useField(fieldKey);

	const userInfo = usePeopleByAccountId(fieldKey, predefinedValue?.[0].accountId ?? '');
	const [value, setValue] = useState<UserType[]>(userInfo ? [userInfo] : []);

	const updateFieldValue = useCallback(
		(users: UserType[]) => {
			const displayUsers: UserFieldValue[] = users.map((user) => ({
				accountId: user.id,
				displayName: user.name || '',
				avatarUrls: {
					'16x16': user.avatarUrl || '',
					'24x24': user.avatarUrl || '',
					'32x32': user.avatarUrl || '',
					'48x48': user.avatarUrl || '',
				},
			}));
			setValue(users);
			onUpdate(displayUsers);
		},
		[onUpdate],
	);

	if (field === undefined) {
		return null;
	}

	return (
		<UsersField
			{...fieldProps}
			isEditable={isEditable}
			value={value}
			fieldKey={fieldKey}
			onUpdate={(users: UserType[]) => updateFieldValue(users)}
		/>
	);
};
