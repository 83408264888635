import React, { type SyntheticEvent, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import find from 'lodash/find';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import PickerWithAvatar from '@atlassian/jira-common-components-picker/src/with-avatar';
import { fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari';
import { EPIC_ISSUE_TYPE_NAME } from '@atlassian/jira-polaris-common/src/common/types/issue-types';
import { ModalTransitionUniversal } from '@atlassian/jira-polaris-common/src/common/ui/modal-transition';
import {
	ProjectPicker,
	PROJECT_TYPES_WITHOUT_JPD,
} from '@atlassian/jira-polaris-common/src/common/ui/project-picker';
import type {
	SelectedProject,
	SelectedProjectIssueType,
} from '@atlassian/jira-polaris-common/src/common/ui/project-picker/option';
import { useCloudId } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useKeys,
	useSummaries,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useProjectActions } from '@atlassian/jira-polaris-common/src/controllers/project/main';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useDeliveryIssueLinkType,
	useEpicNameFieldKey,
} from '@atlassian/jira-polaris-component-environment-tenant';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { OnRequiredFields } from '@atlassian/jira-polaris-remote-issue/src/common/types.tsx';
import { useIssueRemote } from '@atlassian/jira-polaris-remote-issue/src/main.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useRequiredFieldsDeliveryTicketCreation } from '../../../common/utils/on-required-fields-delivery-tickets-creation';
import { useVisibleSelectedIssueIds } from '../../utils';
import { LinkRow } from './link-row';
import messages from './messages';
import { LabelText } from './styled';

type IdeaModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

type CommitState =
	| {
			issueIds: LocalIssueId[];
			linkedJiraIssueKeys: (IssueKey | undefined)[];
			errors: (Error | undefined)[];
	  }
	| undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createSpliced = (i: number, value: Error | string, array: any[]) => {
	const newArray = [...array];
	newArray.splice(i, 1, value);
	return newArray;
};

export const SendToJiraDialog = (props: IdeaModalProps) => {
	const { isOpen, onClose } = props;
	const [selectedProject, setSelectedProject] = useState<SelectedProject | undefined>(undefined);

	const [selectedIssueType, setSelectedIssueType] = useState<SelectedProjectIssueType | undefined>(
		undefined,
	);
	const [commitState, setCommitState] = useState<CommitState>(undefined);
	const sortedSelectedIssueIds = useVisibleSelectedIssueIds();
	const { clearSelection } = useViewActions();
	const summaries = useSummaries();
	const jiraKeys = useKeys();
	const { formatMessage } = useIntl();
	const polarisDeliveryIssueLinkType = useDeliveryIssueLinkType();
	const epicNameFieldKey = useEpicNameFieldKey();
	const { linkDeliveryIssue } = useIssueActions();
	const { setSelectedDeliveryProject } = useProjectActions();
	const { createAndLinkIssues } = useIssueRemote();
	const onRequiredFields: OnRequiredFields = useRequiredFieldsDeliveryTicketCreation();
	const cloudId = useCloudId();
	const [hasRequiredFieldsError, setHasRequiredFieldsError] = useState<boolean>(false);

	const onCloseButtonClick = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'close');
			onClose();
			setHasRequiredFieldsError(false);
			clearSelection(sortedSelectedIssueIds);
		},
		[clearSelection, onClose, sortedSelectedIssueIds],
	);

	const onCloseModal = useCallback(() => {
		setSelectedProject(undefined);
		setSelectedIssueType(undefined);
		setHasRequiredFieldsError(false);
		setCommitState(undefined);
		clearSelection(sortedSelectedIssueIds);
		onClose();
	}, [clearSelection, onClose, sortedSelectedIssueIds]);

	const onCreate = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'create');
			if (!selectedProject?.item || !selectedIssueType) {
				throw new Error('Invalid state');
			}

			// store selected delivery project in polaris backend
			const deliveryProjectAri = createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'project',
				resourceId: selectedProject.item.id,
			});

			setSelectedDeliveryProject(deliveryProjectAri);

			const linkedJiraIssueKeys: (IssueKey | undefined)[] = new Array(
				sortedSelectedIssueIds.length,
			);
			setCommitState({
				issueIds: sortedSelectedIssueIds,
				linkedJiraIssueKeys,
				errors: new Array(sortedSelectedIssueIds.length),
			});
			createAndLinkIssues({
				ideaIds: sortedSelectedIssueIds,
				summaries: sortedSelectedIssueIds.map((id) => summaries[id]),
				jiraKeys: sortedSelectedIssueIds.map((id) => jiraKeys[id]),
				projectId: selectedProject.item.id,
				issueTypeId: selectedIssueType.id,
				issueLinkTypeId: polarisDeliveryIssueLinkType,
				epicNameFieldKey,
				onIssueLinked: (id, linkedJiraIssueKey) => {
					linkDeliveryIssue(id, linkedJiraIssueKey);
				},
				onIssueCreated: (id, linkedJiraIssueKey) => {
					setCommitState((state) =>
						state
							? {
									...state,
									linkedJiraIssueKeys: createSpliced(
										state.issueIds.indexOf(id),
										linkedJiraIssueKey,
										state.linkedJiraIssueKeys,
									),
								}
							: state,
					);
				},
				onOperationFailed: (id, error) => {
					setCommitState((state) =>
						state
							? {
									...state,
									errors: createSpliced(state.issueIds.indexOf(id), error, state.errors),
								}
							: state,
					);
					fireErrorAnalytics({
						meta: {
							id: 'polaris.error.issueCreationFailed',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				},
				onRequireFieldsMissing: () => {
					setHasRequiredFieldsError(true);
				},
				onRequiredFields: (fields) => onRequiredFields(fields, onCloseModal),
			});
		},
		[
			selectedProject,
			selectedIssueType,
			cloudId,
			setSelectedDeliveryProject,
			sortedSelectedIssueIds,
			createAndLinkIssues,
			polarisDeliveryIssueLinkType,
			epicNameFieldKey,
			summaries,
			jiraKeys,
			linkDeliveryIssue,
			onRequiredFields,
			onCloseModal,
		],
	);

	const onCancel = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'cancel');
			setSelectedProject(undefined);
			setSelectedIssueType(undefined);
			setHasRequiredFieldsError(false);
			onClose();
		},
		[onClose],
	);

	if (!isOpen) {
		return null;
	}

	const dialogContent =
		commitState && selectedIssueType ? (
			<>
				{hasRequiredFieldsError ? (
					<SectionMessage
						title={formatMessage(messages.errorHasRequiredFields)}
						appearance="warning"
					>
						{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
						<p>{formatMessage(messages.errorHasRequiredFieldsDescription)}</p>
					</SectionMessage>
				) : (
					<>
						<div>{formatMessage(messages.resultDescription)}</div>
						<Box xcss={linkRowsContainerStyles}>
							{sortedSelectedIssueIds.map((id, i) => (
								<LinkRow
									key={id}
									issueId={id}
									linkedJiraIssueKey={commitState.linkedJiraIssueKeys[i]}
									error={commitState.errors[i]}
									issueTypeIconUrl={selectedIssueType.iconUrl}
								/>
							))}
						</Box>
					</>
				)}
			</>
		) : (
			<>
				<div>{formatMessage(messages.pickDescription)}</div>
				<>
					<Label>
						<LabelText>
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							<span>{formatMessage(messages.projectLabel)}</span>
							<RequiredIndicator>*</RequiredIndicator>
						</LabelText>
					</Label>
					<ProjectPicker
						includeProjectTypes={PROJECT_TYPES_WITHOUT_JPD}
						width="100%"
						isAttachedToBody
						selectedProject={selectedProject}
						onProjectSelected={(payload) => {
							if (payload !== undefined) {
								setSelectedProject(payload);
								const epicIssueType = find(
									payload.item.issueTypes,
									(issueType) => issueType.name === EPIC_ISSUE_TYPE_NAME,
								);
								setSelectedIssueType(epicIssueType);
							} else {
								setSelectedProject(undefined);
								setSelectedIssueType(undefined);
							}
						}}
					/>
				</>
				<PickerWithAvatar
					isCompact
					value={selectedIssueType || null}
					label={formatMessage(messages.issueTypeLabel)}
					width="50%"
					isDisabled={!selectedProject}
					isRequired
					options={
						selectedProject
							? selectedProject.item.issueTypes
									.filter((type) => !type.subtask)
									.map((type) => ({
										...type,
										value: type.id,
										avatar: type.iconUrl,
									}))
							: []
					}
					onSelected={(selected) => {
						setSelectedIssueType(selected);
					}}
					isClearable={false}
					formatOptionDataTransformer={(issueType) => ({
						avatar: issueType.iconUrl,
						label: issueType.name,
					})}
					placeholder={formatMessage(messages.issueTypeLabel)}
				/>
			</>
		);

	return (
		<ModalTransitionUniversal>
			{isOpen && (
				<ContextualAnalyticsData sourceName="createDeliveryTickets" sourceType={MODAL}>
					<ShortcutScope>
						<ModalDialog
							width="420px"
							onClose={() => {
								onClose();
								setSelectedProject(undefined);
								setSelectedIssueType(undefined);
								setHasRequiredFieldsError(false);
								if (commitState !== undefined) {
									clearSelection(sortedSelectedIssueIds);
								}
							}}
						>
							<ModalHeader>
								<ModalTitle>{formatMessage(messages.heading)}</ModalTitle>
								<FireScreenAnalytics />
							</ModalHeader>
							<ModalBody>{dialogContent}</ModalBody>
							<ModalFooter>
								{commitState ? (
									<Button onClick={onCloseButtonClick} appearance="primary">
										{formatMessage(messages.close)}
									</Button>
								) : (
									<>
										<Button
											onClick={onCreate}
											appearance="primary"
											isDisabled={!selectedProject || !selectedIssueType}
										>
											{formatMessage(messages.create)}
										</Button>
										<Button onClick={onCancel} appearance="subtle">
											{formatMessage(messages.cancel)}
										</Button>
									</>
								)}
							</ModalFooter>
						</ModalDialog>
					</ShortcutScope>
				</ContextualAnalyticsData>
			)}
		</ModalTransitionUniversal>
	);
};

const linkRowsContainerStyles = xcss({
	paddingTop: 'space.250',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.label({
	display: 'block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequiredIndicator = styled.span({
	color: '#FF5630',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	paddingLeft: token('space.025', '2px'),
});
