import React from 'react';
import Spinner from '@atlaskit/spinner';
import { useUnfurlActions, useUnfurlState } from '../index';
import { useAuthentication } from './authentication';
import { TryAgainButton } from './retry';

export const UnfurlStatus = () => {
	const { isResolving, tryAgain } = useUnfurlState();
	const { unfurl } = useUnfurlActions();
	const { AuthenticationButton, requiresAuthentication } = useAuthentication();

	let actionButton;
	if (!isResolving && tryAgain) {
		actionButton = <TryAgainButton onClick={() => unfurl()} />;
	} else if (requiresAuthentication) {
		actionButton = AuthenticationButton;
	}

	return (
		<>
			{isResolving && (
				<Spinner testId="polaris-common.controllers.unfurl.utils.spinner" key="spinner" />
			)}
			{actionButton}
		</>
	);
};

export const useHasActionableItems = () => {
	const { isResolving, tryAgain } = useUnfurlState();
	const { requiresAuthentication } = useAuthentication();
	return (!isResolving && tryAgain) || requiresAuthentication;
};
