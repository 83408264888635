import {
	createActionsHook,
	createStateHook,
	createStore,
	type Selector,
} from '@atlassian/react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

const initialState: State = {
	hasManageViewsPermission: false,
	viewAccessMap: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisViewAccessMapStore',
});

export const createViewAccessStateHook = <T,>(selector: Selector<State, void, T>) =>
	createStateHook<State, typeof actions, T>(Store, { selector });

export const useActions = createActionsHook(Store);
