import React, { useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Flex } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIsInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCanManageCurrentView,
	useCurrentViewContainsArchived,
	useCurrentViewEmoji,
	useCurrentViewIsEditingTitle,
	useCurrentViewTitle,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCanManageViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { EditableViewTitle } from '@atlassian/jira-polaris-lib-editable-view-title/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';

type Props = {
	onClose: () => void;
};

export const Header = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	const title = useCurrentViewTitle();
	const viewKind = useCurrentViewKind();
	const emojiId = useCurrentViewEmoji();
	const isEditingTitle = useCurrentViewIsEditingTitle();
	const { renameCurrentView, setEditCurrentViewTitle, addCurrentViewEmoji } = useViewActions();
	const isArchiveView = useCurrentViewContainsArchived();
	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const isIssuesLoading = useIsIssuesLoading();
	const isInitialized = useIsInitialized();

	const onCloseHandler = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'close');
			onClose();
		},
		[onClose],
	);

	const onCancel = useCallback(() => {
		setEditCurrentViewTitle(false);
		experience.listView.changeViewName.abort();
	}, [setEditCurrentViewTitle]);

	const onEdit = useCallback(() => {
		setEditCurrentViewTitle(true);
		experience.listView.changeViewName.start();
	}, [setEditCurrentViewTitle]);

	const onRenameView = useCallback(
		(newTitle: string) => {
			renameCurrentView(newTitle, (view): void => {
				if (isClientFetchError(view?.saveError)) {
					experience.listView.changeViewName.abort(view?.saveError);
				} else if (view && view.saveError) {
					experience.listView.changeViewName.failure(view.saveError);
				} else if (view && !view.saveError) {
					experience.listView.changeViewName.success();
				}
			});
		},
		[renameCurrentView],
	);

	return (
		<Flex alignItems="center" justifyContent="space-between">
			<EditableViewTitle
				title={title}
				emojiId={emojiId}
				viewKind={viewKind}
				isEditingTitle={isEditingTitle}
				onEmojiChange={addCurrentViewEmoji}
				onTitleEdit={onEdit}
				onTitleCancel={onCancel}
				onTitleRename={onRenameView}
				isReadOnly={
					(ff('polaris.view-permissions_plaoi') ? !canManageCurrentView : !canManageViews) ||
					isArchiveView
				}
				isLoading={isIssuesLoading || !isInitialized}
			/>
			<IconButton
				id="pendo.right-sidebar.close-button"
				testId="polaris-ideas.ui.right-sidebar.view-info.header.close"
				appearance="subtle"
				icon={CrossIcon}
				onClick={onCloseHandler}
				label={formatMessage(messages.closeSidebar)}
			/>
		</Flex>
	);
};
