import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'polaris-ideas.insights.insights.insight.properties.labels.placeholder',
		defaultMessage: 'Type a label',
		description: '',
	},
	noOptions: {
		id: 'polaris-ideas.insights.insights.insight.properties.labels.no-options',
		defaultMessage: 'No options',
		description: 'Indicator that there are no options available in the value selection dropdown',
	},
	addALabel: {
		id: 'polaris-ideas.insights.insights.insight.properties.labels.add-a-label',
		defaultMessage: 'Add label',
		description: 'Button label when there is no label yet.',
	},
	none: {
		id: 'polaris-ideas.insights.insights.insight.properties.labels.none',
		defaultMessage: 'None',
		description: 'Tag when there is no label yet.',
	},
});
