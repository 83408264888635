import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyLink: {
		id: 'polaris-common.copy-link.copy-link',
		defaultMessage: 'Copy link',
		description: 'Copy link button tooltip.',
	},
	copiedMessage: {
		id: 'polaris-common.copy-link.copied-message',
		defaultMessage: 'Copied!',
		description: 'Success message after copying the URL.',
	},
});
