import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	useSelectedIssueStringValue,
	useIsSelectedIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { StringField } from '@atlassian/jira-polaris-common/src/ui/fields/string';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useUpdateFieldValue } from '../utils';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
	isIdeaView?: boolean;
};

export const String = ({ fieldKey, isIdeaView }: Props) => {
	const { formatMessage } = useIntl();
	const field = useField(fieldKey);
	const value = useSelectedIssueStringValue(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const isIdeaArchived = useIsSelectedIssueArchived();
	const { updateFieldValue } = useUpdateFieldValue(fieldKey);

	if (field === undefined) {
		return null;
	}

	return (
		<StringField
			isIdeaView={isIdeaView}
			value={value}
			fieldType={field.type}
			isEditable={canEditIssues && field.editable && !isIdeaArchived}
			onUpdate={updateFieldValue}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
