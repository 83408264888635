import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	inactiveChangesHeading: {
		id: 'polaris-ideas.main-content.realtime-events-refresh-banner.inactive-changes-heading',
		defaultMessage: 'The view on this page is out of date.',
		description:
			'The heading displayed in the banner when too many changes were done by other users',
	},
	inactiveChangesText: {
		id: 'polaris-ideas.main-content.realtime-events-refresh-banner.inactive-changes-text',
		defaultMessage: 'People have made changes to the project while you were inactive.',
		description: 'The text displayed in the banner when too many changes were done by other users',
	},
	refreshButton: {
		id: 'polaris-ideas.main-content.realtime-events-refresh-banner.refresh-button',
		defaultMessage: 'Refresh to see the latest',
		description: 'A button to refresh the page',
	},
});
