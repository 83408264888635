import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type OnboardingContentType from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyOnboardingContent = lazyForPaint<typeof OnboardingContentType>(
	() => import(/* webpackChunkName: "async-polaris-lib-onboarding-content" */ './ui'),
	{ ssr: false },
);

const OnboardingContent = () => (
	<Placeholder name="polaris-lib-onboarding-content">
		<LazyOnboardingContent />
	</Placeholder>
);

export default OnboardingContent;
