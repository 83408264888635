import React, { useMemo } from 'react';
import { useFieldValueChangeReporter } from '@atlassian/jira-polaris-use-field-value-change-reporter';
import { useExternalReferenceValue } from '../../../controllers/issue/selectors/properties/hooks';
import { ExternalReferenceField as InnerField } from './main';
import type { Props } from './types';

export const ExternalReferenceField = (props: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const { localIssueId, fieldKey, onValueChange = () => {} } = props;
	const value = useExternalReferenceValue(fieldKey, localIssueId);
	const resolvedValue = useMemo(() => {
		if (value === undefined) {
			return undefined;
		}
		if (Array.isArray(value)) {
			return value;
		}
		return [value];
	}, [value]);

	useFieldValueChangeReporter(value, onValueChange);

	return <InnerField value={resolvedValue} {...props} />;
};
