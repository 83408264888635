import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ValueRule } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { messages } from './messages';
import { getRangeTypeFromRules } from './utils';

type Props = {
	rules: ValueRule[];
	color: string | undefined;
	emojiId: string | undefined;
};

export const NumberDecorationRangeTag = (props: Props) => {
	const { formatMessage } = useIntl();
	const { rules, color, emojiId } = props;
	const emoji = useEmoji(emojiId);

	const { ltRule, gtRule } = getRangeTypeFromRules(rules);
	if (gtRule === undefined && ltRule !== undefined) {
		return (
			<RangeItem>
				<Caption autoWidth>{formatMessage(messages.lessThan)}</Caption>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={ltRule.value} />
			</RangeItem>
		);
	}
	if (gtRule !== undefined && ltRule === undefined) {
		return (
			<RangeItem>
				<Caption autoWidth>{formatMessage(messages.greaterThan)}</Caption>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={gtRule.value} />
			</RangeItem>
		);
	}
	// should be the default case, but flow doesn't get it
	if (gtRule !== undefined && ltRule !== undefined) {
		return (
			<RangeItem>
				<Caption autoWidth>{formatMessage(messages.between)}</Caption>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={gtRule.value} />
				<Caption autoWidth>{formatMessage(messages.and)}</Caption>
				<CommonDecoratedTag mainColor={color} emoji={emoji} value={ltRule.value} />
			</RangeItem>
		);
	}
	return null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RangeItem = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div<{ autoWidth?: boolean }>({
	paddingRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.autoWidth ? 'auto' : '100px'),
});
