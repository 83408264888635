import { type ReactElement, type ReactNode, Children, isValidElement } from 'react';

export const getValidChildren = (children: ReactNode) => {
	const arr = Children.toArray(children);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const valid = arr.filter(isValidElement) as ReactElement[];
	if (valid.length === arr.length) {
		return children;
	}
	return valid;
};
