import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.apps.apps.teams.title',
		defaultMessage: 'Microsoft Teams',
		description: 'Title of the polaris teams app',
	},
	description: {
		id: 'polaris-common.apps.apps.teams.description',
		defaultMessage: 'Add messages to ideas to keep track of requests and important conversations',
		description: 'Description of the polaris teams app',
	},
	showMeHow: {
		id: 'polaris-common.apps.apps.teams.show-me-how',
		defaultMessage: 'Show me how',
		description: 'Button caption that opens the dialog to explain how to install the teams app',
	},
});
