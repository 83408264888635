import React, { useCallback, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import EditorDateIcon from '@atlaskit/icon/glyph/editor/date';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { formatMonthAndYearDate } from '@atlassian/jira-polaris-common/src/common/utils/date/date-format';
import { useTimelineDuration } from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline';
import { useOpenRightSidebarTimelineFieldConfig } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingTimelineConfig } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { useHasTimelineConfigUnsavedChanges } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ActionButton } from '../action-button';
import { messages } from './messages';

export const ConfigTimeline = () => {
	const [sidebarShowing] = useRightSidebarShowing();
	const openTimelineFieldConfig = useOpenRightSidebarTimelineFieldConfig();
	const { startDate, endDate } = useTimelineDuration();
	const { formatMessage } = useIntl();

	const formattedStartDate = startDate ? formatMonthAndYearDate(startDate) : undefined;
	const formattedEndDate = endDate ? formatMonthAndYearDate(endDate) : undefined;
	const timelineRangeLabel = `${formattedStartDate} - ${formattedEndDate}`;

	const handleOpenTimelineConfig = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'timelineSettings');
			sendPendoTrackEvent({
				actionSubjectAndAction: 'openTimelineSettingsSidebarButton clicked',
			});
			openTimelineFieldConfig();
		},
		[openTimelineFieldConfig],
	);

	const hasTimelineConfigUnsavedChanges = useHasTimelineConfigUnsavedChanges();

	return (
		<ActionButton
			id="polaris.ideas.ui.view-controls.open-timeline-config-sidebar"
			testId="polaris-ideas.ui.view-controls.config-timeline.action-button"
			isSelected={sidebarShowing.mode === RightSidebarShowingTimelineConfig}
			isEmpty={!formattedStartDate && !formattedEndDate}
			onClick={handleOpenTimelineConfig}
			hasUnsavedChanges={hasTimelineConfigUnsavedChanges}
		>
			<TitleWrapper>
				<EditorDateIcon label="calendar" />
				<Label>
					{formattedStartDate && formattedEndDate
						? timelineRangeLabel
						: formatMessage(messages.timelineLabel)}
				</Label>
			</TitleWrapper>
		</ActionButton>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.span({
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});
