import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorScreenDescription: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.error-state.error-screen-description',
		defaultMessage: 'Try refreshing the page or <a>contact support.</a>',
		description: 'Description of the error global fields panel',
	},
	refresh: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.error-state.refresh',
		defaultMessage: 'Refresh',
		description: 'Label of Refresh page button',
	},
});
