import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.description',
		defaultMessage: 'Select global fields to reuse in your discovery project.',
		description: 'Description of the Add global field panel',
	},
	searchFields: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.search-fields',
		defaultMessage: 'Search fields',
		description: 'Placeholder for Search fields input',
	},
	selectAllDiscovery: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.select-all-discovery',
		defaultMessage:
			'Select all {fieldsCount, plural, =1 {# discovery field} other {# discovery fields}}',
		description: 'Label for Select all fields checkbox',
	},
	selectAllJira: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.select-all-jira',
		defaultMessage: 'Select all {fieldsCount, plural, =1 {# Jira field} other {# Jira fields}}',
		description: 'Label for Select all fields checkbox',
	},
	selectOneDiscovery: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.select-one-discovery',
		defaultMessage: 'Select 1 discovery field',
		description: 'Label for Select all fields checkbox when there is only one field available',
	},
	selectOneJira: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.select-one-jira',
		defaultMessage: 'Select 1 Jira field',
		description: 'Label for Select all fields checkbox when there is only one field available',
	},
	noFieldsFound: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.no-fields-found',
		defaultMessage: "We couldn't find the fields you're looking for.",
		description: 'Message when there are no fields correspond to a given search criteria',
	},
	fieldsCount: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.fields-count',
		defaultMessage: '{fieldsCount, plural, =1 {# field} other {# fields}} match your search.',
		description: 'Message when there are some fields correspond to a given search criteria',
	},
	discoveryFields: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.discovery-fields',
		defaultMessage: 'Discovery fields',
		description: 'Discovery fields filter',
	},
	jiraFields: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.jira-fields',
		defaultMessage: 'Jira fields',
		description: 'Jira fields filter',
	},
	resetSearchAriaLabel: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.reset-search-aria-label',
		defaultMessage: 'Reset search',
		description: 'Aria label for the reset search button',
	},
});
