import type { AssignIssueParentModalUiState } from '@atlassian/jira-issue-view-common-types/src/assign-issue-parent-modal-type';
import {
	type Action,
	SHOW_ASSIGN_ISSUE_PARENT_MODAL,
	HIDE_ASSIGN_ISSUE_PARENT_MODAL,
} from '../actions/assign-issue-parent-modal-actions';

const initialState = {
	isOpen: false,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AssignIssueParentModalUiState = initialState, action: Action) => {
	switch (action.type) {
		case SHOW_ASSIGN_ISSUE_PARENT_MODAL:
			return {
				...state,
				isOpen: true,
			};

		case HIDE_ASSIGN_ISSUE_PARENT_MODAL:
			return {
				...state,
				isOpen: false,
			};

		default:
			return state;
	}
};
