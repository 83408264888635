/**
 * @generated SignedSource<<ae0f43805ed1c4fa154f23a40abfc8fd>>
 * @relayHash 2c42be6de41ae55a76ca61473151f2ee
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 94c8e12ecf532eeebfc0a52d901cafd1116e0bf378d3fe16815b86aaf972cace

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { panelJpdPublishGateQuery } from './panelJpdPublishGateQuery.graphql';

const node: PreloadableConcreteRequest<panelJpdPublishGateQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "94c8e12ecf532eeebfc0a52d901cafd1116e0bf378d3fe16815b86aaf972cace",
    "metadata": {},
    "name": "panelJpdPublishGateQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
