import { performDeleteRequest } from '@atlassian/jira-fetch';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types';
import { getWorklogUrl } from './common';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	worklogId: string,
	timeRemaining: string,
	isAdjustTimeRemainingChecked: boolean,
): Promise<null> =>
	performDeleteRequest(
		`${getWorklogUrl(baseUrl, issueKey)}/${worklogId}?adjustEstimate=${
			isAdjustTimeRemainingChecked ? `new&newEstimate=${timeRemaining}` : 'leave'
		}`,
	);
