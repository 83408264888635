import type { ApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { type JpdEdition, Role, Edition } from '../types';

export const getProductDiscoveryRole = (
	appPermissions: ApplicationPermissions | undefined,
	hasProjectPermissions: boolean,
): Role => {
	if (appPermissions?.hasProductDiscoveryAccess) {
		return Role.CREATOR;
	}

	if (
		appPermissions?.hasCoreAccess ||
		appPermissions?.hasServiceDeskAccess ||
		appPermissions?.hasSoftwareAccess ||
		hasProjectPermissions
	) {
		return Role.CONTRIBUTOR;
	}

	return Role.STAKEHOLDER;
};

export const jpdEditionToProductDiscoveryEdition = (
	jpdEdition: JpdEdition,
	appEditions: { productDiscovery: string } | undefined,
): string | undefined => {
	if (jpdEdition === Edition.UNKNOWN) {
		return undefined;
	}

	if (jpdEdition === Edition.BETA_EDITION) {
		return Edition.BETA_EDITION;
	}

	return appEditions?.productDiscovery;
};
