import React from 'react';
import type { User as UserType } from '@atlassian/jira-polaris-common/src/common/types/user';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import {
	useIssueKey,
	useUser,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { UserField } from '@atlassian/jira-polaris-common/src/ui/fields/user';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	portalElement?: HTMLElement;
};

export const User = ({ fieldKey, localIssueId, portalElement }: Props) => {
	const value = useUser(fieldKey, localIssueId);
	const field = useField(fieldKey);
	const ideaKey = useIssueKey(localIssueId);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	if (field === undefined) {
		return null;
	}

	return (
		<UserField
			user={value}
			issueKey={ideaKey}
			fieldKey={fieldKey}
			isEditable={canEditIssues && field.editable}
			portalElement={portalElement}
			onUpdate={(user?: UserType) => {
				const displayUser: UserFieldValue | undefined = user
					? {
							accountId: user.id,
							displayName: user.name || '',
							avatarUrls: {
								'16x16': undefined,
								'24x24': undefined,
								'32x32': undefined,
								'48x48': user.avatarUrl || '',
							},
						}
					: undefined;
				updateFieldValue({
					fieldKey,
					localIssueIds: [localIssueId],
					newValue: displayUser,
				});
			}}
		/>
	);
};
