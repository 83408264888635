import React from 'react';
import { styled } from '@compiled/react';
import { lazyForPaint } from 'react-loosely-lazy';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type HistoryType from '@atlassian/jira-issue-history';
import { N500 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { toIssueKey, type IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages';

const HistoryLazy = lazyForPaint<typeof HistoryType>(
	() => import(/* webpackChunkName: "async-jira-issue-history" */ '@atlassian/jira-issue-history'),
);
HistoryLazy.displayName = 'History';

type HistoryProps = {
	issueKey: IssueKey;
};
export const History = (props: HistoryProps) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<HistoryWarning>
				<WarningIcon label="" size="small" />
				{formatMessage(messages.historyNotFullySupported)}
			</HistoryWarning>
			<HistoryLazy
				baseUrl=""
				issueKey={toIssueKey(props.issueKey)}
				timeZone={undefined}
				sortOrder="NEWEST_FIRST"
			/>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HistoryWarning = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: N500,
	padding: `${token('space.050', '4px')} 0`,
});
