import { useCallback, useMemo } from 'react';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useIsInitialized as useIsIssuesInitialized } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { useSelectedIssueIssueType } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useTimelineDateFieldsKeys } from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks.tsx';
import {
	useCurrentViewFieldKeys,
	useCurrentViewGroupBy,
	useCurrentViewVerticalGroupBy,
	usePinnedViewFields,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import {
	KEY_FIELDKEY,
	SUMMARY_FIELDKEY,
	VOTES_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapPromiseWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { COMPOSITION_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/composition/types.tsx';
import { EXPRESSION_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/expression/types.tsx';

const HIDDEN_KEYS = [SUMMARY_FIELDKEY, KEY_FIELDKEY, VOTES_FIELDKEY];

const useGroupingFieldKeys = () => {
	const groupField1 = useCurrentViewGroupBy();
	const groupField2 = useCurrentViewVerticalGroupBy();

	// It's possible for both fields to be the same, using the Set is an easy way to ensure there aren't duplicates
	return useMemo(
		() =>
			Array.from(
				new Set(
					[groupField1, groupField2]
						.map((field) => (field ? field.key : ''))
						.filter((key) => key.length > 0),
				),
			),
		[groupField1, groupField2],
	);
};

export const useFieldSections = () => {
	const selectedIssueType = useSelectedIssueIssueType();
	const allMultiIssueTypesFields = useFieldsForViewControls();
	const fieldsInCurrentView = useCurrentViewFieldKeys();
	const currentViewKind = useCurrentViewKind();
	const timelineDateFieldKeys = useTimelineDateFieldsKeys();
	const pinnedFields = usePinnedViewFields();
	const groupingFieldKeys = useGroupingFieldKeys();
	const isSharedView = useIsSharedView();
	const isIssuesInitialized = useIsIssuesInitialized();

	const allFields = useMemo(
		() =>
			selectedIssueType?.id
				? pickBy(allMultiIssueTypesFields, (field) =>
						field.issueTypes.includes(selectedIssueType.id),
					)
				: allMultiIssueTypesFields,
		[allMultiIssueTypesFields, selectedIssueType?.id],
	);

	const safeFieldsInCurrentView = useMemo(() => {
		let fieldsSet: FieldKey[] = [];
		if (fieldsInCurrentView !== undefined) {
			fieldsSet = fieldsInCurrentView;
		}

		fieldsSet = fieldsSet.filter((key) => !!allFields[key]);

		if (currentViewKind === VIEW_KIND_TIMELINE) {
			const timelineFieldKeys = timelineDateFieldKeys.filter(
				(key): key is FieldKey => key !== undefined,
			);
			fieldsSet = [...new Set([...fieldsSet, ...timelineFieldKeys])];
		}
		return fieldsSet;
	}, [currentViewKind, fieldsInCurrentView, timelineDateFieldKeys, allFields]);

	const otherFields = useMemo(() => {
		const allFieldKeys = Object.keys(allFields);
		const otherFieldsUnsorted = allFieldKeys.filter(
			(key) =>
				!pinnedFields.includes(key) &&
				!safeFieldsInCurrentView.includes(key) &&
				!groupingFieldKeys.includes(key),
		);
		return sortBy(otherFieldsUnsorted, (fieldKey) => allFields[fieldKey].label);
	}, [allFields, pinnedFields, safeFieldsInCurrentView, groupingFieldKeys]);

	const nonPinnedCurrentViewFields = useMemo(
		() =>
			[
				...groupingFieldKeys,
				...safeFieldsInCurrentView.filter((key) => !groupingFieldKeys.includes(key)),
				...(isSharedView ? otherFields : []),
			].filter((key) => !pinnedFields.includes(key)),
		[groupingFieldKeys, safeFieldsInCurrentView, isSharedView, otherFields, pinnedFields],
	);

	const isFormulaFieldThatMightRequireAllIdeas = useCallback(
		(field: Field) =>
			field.formula !== undefined &&
			[EXPRESSION_TEMPLATE, COMPOSITION_TEMPLATE].includes(field.formula.template),
		[],
	);

	const fieldKeysThatMightRequireAllIdeas = useMemo(
		() =>
			Object.entries(allFields)
				.filter(([, field]) => isFormulaFieldThatMightRequireAllIdeas(field))
				.map(([, field]) => field.key),
		[allFields, isFormulaFieldThatMightRequireAllIdeas],
	);

	const isFieldVisible = useCallback(
		(fieldKey: FieldKey) =>
			!HIDDEN_KEYS.includes(fieldKey) &&
			(isIssuesInitialized || !fieldKeysThatMightRequireAllIdeas.includes(fieldKey)),
		[isIssuesInitialized, fieldKeysThatMightRequireAllIdeas],
	);

	return useMemo(
		() => ({
			pinnedFields: {
				/**
				 * All pinned fields without filtered out weighted score formula fields
				 */
				fields: pinnedFields.filter(isFieldVisible),
				/**
				 * All pinned fields including filtered out weighted score formula fields
				 */
				allFields: pinnedFields.filter((fieldKey: FieldKey) => !HIDDEN_KEYS.includes(fieldKey)),
			},
			nonPinnedCurrentViewFields: {
				fields: nonPinnedCurrentViewFields.filter(isFieldVisible),
			},
			otherFields: {
				fields: isSharedView ? [] : otherFields.filter(isFieldVisible),
			},
			hasFieldsRequiringAllIdeas: values(allFields).some(
				(field) =>
					isFormulaFieldThatMightRequireAllIdeas(field) ||
					field.type === FIELD_TYPES.LABELS ||
					field.type === FIELD_TYPES.CUSTOM_LABELS,
			),
			hasFormulaFields: values(allFields).some((field) =>
				isFormulaFieldThatMightRequireAllIdeas(field),
			),
			hasLabelFields: values(allFields).some(
				(field) => field.type === FIELD_TYPES.LABELS || field.type === FIELD_TYPES.CUSTOM_LABELS,
			),
		}),
		[
			allFields,
			isFieldVisible,
			isFormulaFieldThatMightRequireAllIdeas,
			isSharedView,
			nonPinnedCurrentViewFields,
			otherFields,
			pinnedFields,
		],
	);
};

/**
 * Updates a field value and wraps the action call in an experience
 */
export const useUpdateFieldValue = (fieldKey: string) => {
	const { updateFieldValueForSelectedIssue } = useIssueActions();

	const updateFieldValue = useCallback(
		async (
			newValue:
				| string
				| string[]
				| number
				| boolean
				| UserFieldValue
				| UserFieldValue[]
				| OptionProperty
				| OptionProperty[]
				| undefined,
		) => {
			const fieldUpdateExperience = experience.ideaView.makeFieldUpdate();

			try {
				await wrapPromiseWithExperience(
					new Promise((resolve, reject) => {
						updateFieldValueForSelectedIssue(
							{
								fieldKey,
								newValue,
							},
							() => resolve(undefined),
							(err) => reject(err),
						);
					}),
					fieldUpdateExperience,
				);
			} catch {
				return undefined;
			}
		},
		[fieldKey, updateFieldValueForSelectedIssue],
	);

	return { updateFieldValue };
};
