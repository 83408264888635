import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	useCurrentViewEndDateField,
	useCurrentViewStartDateField,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { ExternalDateActionDisabledHintProps } from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import messages from './messages';

export const ExternalDateResizeDisabledHint = ({ side }: ExternalDateActionDisabledHintProps) => {
	const startDateField = useCurrentViewStartDateField();
	const endDateField = useCurrentViewEndDateField();
	const { formatMessage } = useIntl();

	const field = side === 'start' ? startDateField : endDateField;

	switch (field?.configuration?.source) {
		case INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_START_DATE:
			return <>{formatMessage(messages.startDateResizeDisabledHint)}</>;
		case INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE:
			return <>{formatMessage(messages.targetDateResizeDisabledHint)}</>;
		case INTERVAL_FIELD_SOURCES.DELIVERY_DATE:
			return <>{formatMessage(messages.deliveryDateResizeDisabledHint)}</>;
		default:
			return null;
	}
};
