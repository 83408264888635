const defaultWindowOptions = 'width=600,height=700';

export const openAuthorizeWindow = (
	authorizeUrl: string,
	windowKey: string,
	onSuccess: () => void,
	onFailure: (err: Error) => void,
	options: string = defaultWindowOptions,
) => {
	const originalOauthCallback = window.oauthCallback;
	const newWin = window.open(authorizeUrl, windowKey, options);

	if (!newWin) {
		return;
	}

	window.oauthCallback = {
		success: () => {
			onSuccess();
			newWin.close();
		},
		failure: () => {
			onFailure(new Error('authorization:failure from oauthCallback'));
			newWin.close();
		},
	};

	newWin.onbeforeunload = () => {
		window.oauthCallback = originalOauthCallback;
	};

	window.addEventListener(
		'message',
		(evt) => {
			const type = evt.data && evt.data.type;
			if (type === 'authorization:success') {
				onSuccess();
				newWin.close();
			} else if (type === 'authorization:failure') {
				const { message } = evt.data;
				onFailure(new Error(`authorization:failure. message: ${message}.`));
				newWin.close();
			} else {
				onFailure(new Error('Unexpected message receive from authorization window'));
			}
		},
		false,
	);
};
