import React from 'react';
import { Emoji } from '@atlaskit/emoji';
import { Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { NoValueContainer } from '../../../../common/board-header-column/styled';
import messages from './messages';

type ColumnHeaderComponentProps = {
	groupIdentity: string;
	fieldKey?: FieldKey;
};

const ReactionsColumnHeader = ({ groupIdentity }: ColumnHeaderComponentProps) => {
	const emoji = useEmoji(groupIdentity);
	return emoji ? (
		<Flex justifyContent="center" alignItems="center" xcss={emojiWrapperStyles}>
			<Emoji emoji={emoji} fitToHeight={16} showTooltip />
		</Flex>
	) : null;
};

const EmptyReactionsColumnHeader = () => {
	const { formatMessage } = useIntl();

	return <NoValueContainer>{formatMessage(messages.emptyColumnHeader)}</NoValueContainer>;
};

const emojiWrapperStyles = xcss({
	width: '28px',
	height: '28px',
});

export const reactionsColumnHeaderConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: ReactionsColumnHeader,
	EmptyComponent: EmptyReactionsColumnHeader,
} as const;
