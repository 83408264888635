import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldRollupOperation } from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types';
import { saveViewWithAutoSave } from '../save';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

export const setRollupFieldsValue =
	(fieldKey: FieldKey, rollup: FieldRollupOperation | null): Action<State, Props> =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const viewMutation = (view: View): View => {
			const { isAutosaveEnabled } = view;
			const fieldRollups = isAutosaveEnabled ? view.fieldRollups : view.draft.fieldRollups;
			const newFieldRollups = fieldRollups.filter((val) => val.fieldKey !== fieldKey);

			if (rollup !== null) {
				newFieldRollups.push({ fieldKey, rollup });
			}

			return {
				...view,
				fieldRollups: isAutosaveEnabled ? newFieldRollups : view.fieldRollups,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					fieldRollups: newFieldRollups,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));
		}
	};
