import flow from 'lodash/flow';
import { WithDataProvider } from '@atlassian/jira-providers-issue/src/components';
import WithInitialSpaRender from '../../../common/metrics/component/with-initial-spa-render';
import metricsContextProvider from '../../../common/metrics/context/provider';
import AppProvider from './main';

export {
	IssueViewCriticalQueryPreloader,
	IssueViewRouteResourceConsumer,
} from './resource-manager';

export default flow(WithDataProvider, metricsContextProvider(), WithInitialSpaRender)(AppProvider);
export type { Props, AppProviderWithConfigProps, AppProviderProps } from './types';
