import { createSelector } from 'reselect';
import type {
	EntitiesState,
	Project,
} from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { entitiesSelector } from '../common/state/selectors/issue-selector';

export const projectSelector = createSelector(
	entitiesSelector,
	(entities: EntitiesState): Project | undefined => entities.project,
);

export const projectNameSelector = createSelector(
	projectSelector,
	(project?: Project): string | null => (project ? project.projectName : null),
);

export const projectAvatarUrlSelector = createSelector(
	projectSelector,
	(project?: Project): string | null => (project ? project.avatarUrl : null),
);
