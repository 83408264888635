import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	taskInProgressTitle: {
		id: 'polaris-component-copy-values.copy-values-progress.task-in-progress-title',
		defaultMessage:
			'Copying values from <i>{projectFieldName}</i> to <i>{globalFieldName}</i> <icon></icon>',
		description: 'The heading displayed in the notification when copy values task is in progress.',
	},
	taskInProgressDescription: {
		id: 'polaris-component-copy-values.copy-values-progress.task-in-progress-description',
		defaultMessage: 'We are currently coping values, we will update this message when it is done.',
		description:
			'The description displayed in the notification when copy values task is in progress.',
	},
	taskFinishedTitle: {
		id: 'polaris-component-copy-values.copy-values-progress.task-finished-title',
		defaultMessage:
			"We've copied the values from <i>{projectFieldName}</i> to <i>{globalFieldName}</i> <icon></icon>",
		description: 'The heading displayed in the notification when copy values task is finished.',
	},
	taskFinishedDescription: {
		id: 'polaris-component-copy-values.copy-values-progress.task-finished-description',
		defaultMessage: 'You can now see these values in any view using this global field.',
		description: 'The description displayed in the notification when copy values task is finished.',
	},
	taskFailedTitle: {
		id: 'polaris-component-copy-values.copy-values-progress.task-failed-title',
		defaultMessage: "We can't copy the values",
		description: 'The heading displayed in the notification when copy values task is failed.',
	},
	taskFailedDescription: {
		id: 'polaris-component-copy-values.copy-values-progress.task-failed-description',
		defaultMessage: 'Try again.',
		description: 'The description displayed in the notification when copy values task is failed.',
	},
});
