import { ProfileClient } from '@atlaskit/profilecard';
import {
	type Action,
	createStore,
	createSubscriber,
	createContainer,
	createHook,
	type SubscriberComponent,
	type Store,
	type ContainerComponent,
} from '@atlassian/react-sweet-state';
import type { OnLoadError, State, ProfileCardContainerProps } from './types';
import { CustomProfileClient, type CustomProfileClientType } from './utils';

export type { CustomProfileClientType, OnLoadError };

const GATEWAY_GRAPHQL_URL = '/gateway/api/graphql';

export const createUserProfileCardClient = (
	fabricPfDirUrl: string,
	baseUrl: string,
	onLoadError: OnLoadError | undefined,
	teamCentralUrl: string,
	teamCentralBaseUrl: string,
	gatewayGraphqlUrl?: string,
	cloudId?: string,
): CustomProfileClientType => {
	const userClient = new CustomProfileClient(fabricPfDirUrl, baseUrl, onLoadError);
	// From upward version 14 of `@atlaskit/profilecard`, `ProfileClient` class accepts `userClient` and `teamClient` in the 2nd argument.

	return new ProfileClient(
		{
			cacheMaxAge: 1000 * 60 * 3,
			cacheSize: 20,
			url: fabricPfDirUrl,
			teamCentralUrl,
			teamCentralBaseUrl,
			productIdentifier: 'jira',
			gatewayGraphqlUrl,
			cloudId,
		},
		{
			userClient,
		},
	);
};

export const bootstrap =
	(): Action<State, any> => // eslint-disable-line @typescript-eslint/no-explicit-any
	(
		{ setState },
		{
			fabricPfDirUrl,
			baseUrl,
			profileClient,
			onLoadError,
			teamCentralUrl,
			teamCentralBaseUrl,
			cloudId,
		},
	) => {
		const finalProfileClient =
			profileClient ||
			createUserProfileCardClient(
				fabricPfDirUrl,
				baseUrl,
				onLoadError,
				teamCentralUrl,
				teamCentralBaseUrl,
				GATEWAY_GRAPHQL_URL,
				cloudId,
			);

		setState({
			profileClient: finalProfileClient,
		});
	};

export const actions = {
	bootstrap,
} as const;

type Actions = typeof actions;

export const initialState: State = {
	profileClient: null,
};

export const store: Store<
	State,
	{
		bootstrap: () => Action<State>;
	}
> = createStore<State, typeof actions>({
	initialState,
	actions,
});

// @ts-expect-error - TS2322 - Type 'SubscriberComponent<State, BoundActions<State, { readonly bootstrap: () => Action<State, any, void | Promise<void>>; }>, unknown>' is not assignable to type 'SubscriberComponent<State, { readonly bootstrap: () => Action<State, any, void | Promise<void>>; }, Record<any, any>>'.
export const ProfileClientSubscriber: SubscriberComponent<
	State,
	Actions,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
> = createSubscriber<State, Actions>(store);

export const useProfileClient = createHook(store);

export const ProfileClientContainer: ContainerComponent<ProfileCardContainerProps> =
	createContainer<State, Actions, ProfileCardContainerProps>(store, {
		onInit:
			() =>
			({ dispatch }) =>
				dispatch(actions.bootstrap()),
	});
