export const defaultScheme = (rawURL: string, scheme: string): string => {
	if (rawURL.indexOf('//') === 0) {
		return `${scheme}:${rawURL}`;
	}

	if (rawURL.indexOf('://') === -1) {
		return `${scheme}://${rawURL}`;
	}

	return rawURL;
};
