// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum ProductType {
	ATLAS = 'TEAM_CENTRAL',
	JIRA_SOFTWARE = 'JIRA_SOFTWARE',
}

export type AvailableProduct = {
	productType: ProductType;
	url: string | null;
};

export type Organization = {
	id: string;
	displayName: string | null;
};

export type Site = {
	adminAccess: boolean;
	cloudId: string;
	displayName: string;
	url: string;
	availableProducts: AvailableProduct[];
	org: Organization | undefined; // this might be undefined for e.g. BITBUCKET
};

export type AvailableProductsResponse = {
	sites: Site[];
};
