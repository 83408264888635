import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	formatting: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-header.formatting',
		defaultMessage: 'Formatting',
		description: 'Label for section that shows formatting configuration for a field',
	},
	actions: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-header.actions',
		defaultMessage: 'Actions',
		description: 'Label for section that shows list of actions',
	},
	options: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-header.options',
		defaultMessage: 'Options',
		description: 'Label for section that shows list of options',
	},
	voteHeader: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-header.vote-header',
		defaultMessage: 'Number of votes',
		description: 'Label for section that shows the voting field',
	},
	labels: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-header.labels',
		defaultMessage: 'Labels',
		description: 'Label for section that shows list of labels',
	},
	formattingTooltip: {
		id: 'polaris-component-field-configuration.decoration.decoration-config-header.formatting-tooltip',
		defaultMessage: 'Formatting is applied in the order the rules are defined below',
		description: 'Tooltip to explain that order of rules matters in formatting',
	},
});
