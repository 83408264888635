import { createSelector } from 'reselect';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { ff } from '@atlassian/jira-feature-flagging';
import { issuesProgressFromAggregate } from '@atlassian/jira-polaris-domain-field/src/field-types/progress/index.tsx';
import type {
	LocalIssueId,
	ExternalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import type {
	IssueFieldDate,
	State,
	ExternalIssueData,
	ExternalIssueDataMap,
	IssueMetadata,
} from '../../../types';

const EMPTY_LINKED_PROGRESS = {
	total: 0,
	distribution: {},
};

const getAggregatedDeliveryProgress = (state: State): Record<string, number[]> =>
	state.properties.aggregatedDeliveryProgress;

export const getAggregatedDeliveryDates = (state: State): Record<string, IssueFieldDate[]> =>
	state.properties.aggregatedDeliveryDates;

export const getExternalIssueData = (state: State): ExternalIssueDataMap => state.externalIssueData;

export const getLinkedDeliveryIssues = (state: State): Record<LocalIssueId, ExternalIssueId[]> =>
	state.properties.linkedDeliveryIssues;

const getIssueMetadataProperties = (state: State): Record<LocalIssueId, IssueMetadata> =>
	state.properties.issueMetadata;

const EMPTY: Array<ExternalIssueData> = [];
export const createGetLinkedIssueData = (id: LocalIssueId) =>
	createSelector(
		getLinkedDeliveryIssues,
		getExternalIssueData,
		(linkedIssues, externalIssueData) =>
			linkedIssues[id]
				? linkedIssues[id].map((linkedIssueId) => externalIssueData[linkedIssueId]).filter(Boolean)
				: EMPTY,
	);

export const createGetLinkedIssuesCount = (id: LocalIssueId) =>
	createSelector(
		getIssueMetadataProperties,
		(issueMetadata) => issueMetadata[id]?.issueLinks.count || 0,
	);

export const createGetLinkedDeliveryIssuesExist = (id: LocalIssueId) =>
	createSelector(
		getAggregatedDeliveryProgress,
		(aggregatedDeliveryProgress) =>
			aggregatedDeliveryProgress && aggregatedDeliveryProgress[id] !== undefined,
	);

export const createGetAggregatedDeliveryProgress = (issueId?: LocalIssueId) =>
	createSelector(
		getLinkedDeliveryIssues,
		getAggregatedDeliveryProgress,
		(linkedIssuesMap, aggregatedDeliveryProgress) => {
			if (
				issueId === undefined ||
				(linkedIssuesMap[issueId] === undefined &&
					!ff('polaris.use-polaris-issues-search-endpoint'))
			) {
				return EMPTY_LINKED_PROGRESS;
			}

			if (aggregatedDeliveryProgress && aggregatedDeliveryProgress[issueId]) {
				return issuesProgressFromAggregate(aggregatedDeliveryProgress[issueId]);
			}

			return issuesProgressFromAggregate([0, 0, 0]);
		},
	);

export const createGetLinkedProgress = cacheSelectorCreator((issueId?: LocalIssueId) =>
	createGetAggregatedDeliveryProgress(issueId),
);

export const createGetLinkedDeliveryTicketsCount = (id: LocalIssueId) =>
	createSelector(getLinkedDeliveryIssues, (linkedIssues) => linkedIssues[id]?.length || 0);

export const createGetAggregatedDeliveryDates = (issueId?: LocalIssueId) =>
	createSelector(
		getLinkedDeliveryIssues,
		getAggregatedDeliveryDates,
		(linkedIssuesMap, aggregatedDeliveryDates) => {
			if (
				issueId === undefined ||
				(linkedIssuesMap[issueId] === undefined &&
					!ff('polaris.use-polaris-issues-search-endpoint'))
			) {
				return undefined;
			}

			if (aggregatedDeliveryDates && aggregatedDeliveryDates[issueId]) {
				return aggregatedDeliveryDates[issueId];
			}

			return undefined;
		},
	);

export const createGetLinkedDates = cacheSelectorCreator((issueId?: LocalIssueId) =>
	createGetAggregatedDeliveryDates(issueId),
);

export const createGetAggregatedDeliveryDate = (
	issueId?: LocalIssueId,
	fieldKey?: string,
	aggregationType?: string,
) =>
	createSelector(createGetLinkedDates(issueId), (aggregatedDeliveryDates) => {
		if (!aggregatedDeliveryDates) {
			return undefined;
		}

		if (!fieldKey || !aggregationType) {
			return undefined;
		}

		return aggregatedDeliveryDates.find(
			(date) =>
				date.aggregationType.toLowerCase() === aggregationType?.toLowerCase() &&
				date.fieldKey === fieldKey &&
				date.date,
		);
	});

export const getExternalIssueProjects = createSelector(
	getExternalIssueData,
	(externalIssueDataMap) => {
		const allProjectIds = uniq(map(externalIssueDataMap, ({ projectId }) => projectId));

		return allProjectIds.filter(Boolean);
	},
);
