import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import type { OptionType } from '@atlaskit/select';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import { raiseTicket } from '../../services';
import { Content } from './content';
import { getFlagContent } from './flags';
import { Footer } from './footer';
import messages from './messages';
import {
	type TemplateKey,
	FeedbackCategory,
	type FeedbackModalProps,
	type TemplateOptions,
	type TemplateDescriptor,
} from './types';

const templateOptions: TemplateOptions = {
	feedback: {
		key: 'feedback',
		title: messages.title,
		selectLabel: messages.feedbackOptionLabel,
		target: FeedbackCategory.CustomerFeedback,
		template: (msg) => msg,
	},
	help: {
		key: 'help',
		title: messages.title,
		selectLabel: messages.helpOptionLabel,
		target: FeedbackCategory.GetSupportAtlassianCom,
		template: (msg) => msg,
	},
};

const templatesKeys = Object.keys(templateOptions);

export const FeedbackModal = ({
	onCancel,
	defaultCategoryName = 'feedback',
	onSuccess,
	onError,
	testId,
}: FeedbackModalProps) => {
	const { formatMessage } = useIntl();
	const [successMessage, errorMessage] = getFlagContent(formatMessage);

	const selectOptions: Array<OptionType> = templatesKeys.map((key) => ({
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		label: formatMessage(templateOptions[key as TemplateKey].selectLabel),
		value: key,
	}));

	const defaultTemplate: TemplateDescriptor =
		templateOptions[defaultCategoryName] || templateOptions.feedback;

	const [selectedTemplate, setSelectedTemplate] = useState<TemplateDescriptor>(defaultTemplate);
	const [title, setTitle] = useState<MessageDescriptor>(defaultTemplate.title);

	const [message, setMessage] = useState<string>('');
	const [transmitting, setTransmitting] = useState(false);

	useEffect(() => {
		setTitle(selectedTemplate.title);
	}, [selectedTemplate]);

	const onOptionChange = (item: OptionType | null) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		setSelectedTemplate(templateOptions[item?.value as TemplateKey] || defaultTemplate);
	};

	const onSubmit = async () => {
		const { target } = selectedTemplate;
		const templatedMessage = selectedTemplate.template(message);

		setTransmitting(true);

		await raiseTicket(templatedMessage, target)
			.then(() => {
				onSuccess(successMessage);
				onCancel();
			})
			.catch(() => onError(errorMessage));

		setTransmitting(false);
	};

	return (
		<ModalTransition>
			<ShortcutScope>
				<ModalDialog
					shouldCloseOnOverlayClick={false}
					onClose={onCancel}
					width="450px"
					testId={testId}
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(title)}</ModalTitle>
					</ModalHeader>
					<Content
						message={message}
						sectionMessage={selectedTemplate.sectionMessage}
						setMessage={setMessage}
						onCategoryChange={onOptionChange}
						feedbackCategories={selectOptions}
						selectedCategory={{
							label: formatMessage(selectedTemplate.selectLabel),
							value: selectedTemplate.key,
						}}
					/>
					<Footer
						isDisabled={!message || transmitting}
						onSubmit={onSubmit}
						onCancel={onCancel}
						transmitting={transmitting}
					/>
				</ModalDialog>
			</ShortcutScope>
		</ModalTransition>
	);
};

export default FeedbackModal;
