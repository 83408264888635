import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	expressionLabel: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expression-label',
		defaultMessage: 'Expression',
		description: 'Header label for expression field',
	},
	expressionRequiredError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expression-required-error',
		defaultMessage: 'A non-empty expression is required',
		description: 'Error message representing an empty or missing expression',
	},
	customFormulaDecimalError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.custom-formula-decimal-error',
		defaultMessage: "Numbers can't start with dot",
		description:
			'Message to describe the error for a formula containing decimal numbers starting with dot',
	},
});
