import React, { memo, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownIconNew from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIconNew from '@atlaskit/icon/utility/chevron-right';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { centerUnderPointer } from '@atlaskit/pragmatic-drag-and-drop/element/center-under-pointer';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { colors } from '@atlaskit/theme';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { ColumnHeader } from '../../../column/header';
import {
	type DraggableGroupData,
	type DragState,
	isDraggableGroupData,
} from '../../../utils/draggable.types';
import { HEADER_HEIGHT } from '../../constants';
import { getSwimlanesBoardWidth } from '../../utils';
import { messages } from './messages';

type DraggableGroupStickyHeaderProps = {
	draggableId: string;
	index: number;
	isReadOnly: boolean;
	isVisible: boolean;
	columnsCount: number;
	localIssuesCount: number;
	fieldKey: string;
	isLastGroup: boolean;
	groupIdentity?: string;
	isPreview?: boolean;
};

export const DraggableGroupStickyHeader = memo((props: DraggableGroupStickyHeaderProps) => {
	const {
		draggableId,
		index,
		isReadOnly,
		isVisible,
		columnsCount,
		localIssuesCount,
		fieldKey,
		isLastGroup,
		groupIdentity,
		isPreview = false,
	} = props;
	const [isMenuOpen, setMenuOpen] = useState(false);
	const { formatMessage } = useIntl();
	const { toogleSwimlane } = useViewActions();

	const rowHeaderRef = useRef<HTMLDivElement>(null);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
	const [dragStatus, setDragStatus] = useState<DragState>('idle');
	const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!rowHeaderRef.current) {
			return undefined;
		}

		const cleanupDragAndDrop = combine(
			draggable({
				element: rowHeaderRef.current,
				canDrag: () => !isPreview && !isReadOnly,
				onGenerateDragPreview: ({ nativeSetDragImage }) => {
					setDragStatus('preview');
					setCustomNativeDragPreview({
						getOffset: centerUnderPointer,
						render: ({ container }) => {
							setPreviewContainer(container);
							return () => {
								setPreviewContainer(null);
							};
						},
						nativeSetDragImage,
					});
				},
				getInitialData() {
					const data: DraggableGroupData = {
						draggableId,
						index,
						type: 'GROUP',
					};
					return data;
				},
				onDragStart() {
					setDragStatus('dragging');
				},
				onDrop() {
					// the setTimeout prevents a flickering before a group is moved to another location
					setTimeout(() => setDragStatus('idle'));
				},
			}),
			dropTargetForElements({
				element: rowHeaderRef.current,
				canDrop({ source }) {
					return !isReadOnly && isDraggableGroupData(source.data);
				},
				getData({ input, element }) {
					return attachClosestEdge(
						{ draggableId, index, type: 'GROUP' },
						{
							input,
							element,
							allowedEdges: ['top', 'bottom'],
						},
					);
				},
				onDrag({ source, self }) {
					if (!isDraggableGroupData(source.data)) {
						return;
					}
					if (source.data.draggableId !== draggableId) {
						const dropEdge = extractClosestEdge(self.data);
						if (closestEdge !== dropEdge) {
							setClosestEdge(dropEdge);
						}
					}
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);

		return () => {
			cleanupDragAndDrop?.();
		};
	}, [fieldKey, draggableId, index, closestEdge, isReadOnly, isPreview, setDragStatus]);

	return (
		<>
			<RowHeaderContainer
				data-testid="polaris-ideas.ui.view-content.idea-board.swimlanes.body.draggable-group-sticky-header"
				ref={rowHeaderRef}
				isMenuOpen={isMenuOpen}
				columnsCount={columnsCount}
				dragStatus={dragStatus}
				closestEdge={closestEdge}
				isDragDisabled={isReadOnly}
				isFirstGroup={index === 0}
				isLastGroup={isLastGroup}
				isPreview={isPreview}
			>
				<RowHeaderStickyContainer>
					<CollapseButtonContainer>
						{ff('polaris.collapse-all-board-groups') ? (
							<IconButton
								label="toggle group"
								onClick={() => toogleSwimlane(String(groupIdentity), localIssuesCount === 0)}
								appearance="subtle"
								spacing="compact"
								icon={isVisible ? ChevronDownIconNew : ChevronRightIconNew}
							/>
						) : (
							<Button
								onClick={() => toogleSwimlane(String(groupIdentity), localIssuesCount === 0)}
								appearance="subtle"
								iconBefore={
									isVisible ? <ChevronDownIconOld label="" /> : <ChevronRightIconOld label="" />
								}
							/>
						)}
					</CollapseButtonContainer>
					<ColumnHeader
						isRow
						isReadOnly={isReadOnly}
						onMenuOpen={setMenuOpen}
						elemAfter={
							localIssuesCount ? (
								<IssueCountContainer>
									{formatMessage(messages.issuesCountLabel, {
										issuesCount: localIssuesCount,
									})}
								</IssueCountContainer>
							) : null
						}
						fieldKey={fieldKey}
						groupIdentity={groupIdentity}
					/>
				</RowHeaderStickyContainer>
			</RowHeaderContainer>
			{previewContainer
				? ReactDOM.createPortal(
						<PreviewWrapper>
							<DraggableGroupStickyHeader {...props} isReadOnly isPreview />
						</PreviewWrapper>,
						previewContainer,
					)
				: null}
		</>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowHeaderContainer = styled.div<{
	columnsCount: number;
	isMenuOpen: boolean;
	closestEdge: Edge | null;
	dragStatus: string;
	isDragDisabled: boolean;
	isFirstGroup: boolean;
	isLastGroup: boolean;
	isPreview: boolean;
}>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isDragDisabled }) => !isDragDisabled && 'grab',
	minHeight: '40px',
	alignItems: 'center',
	padding: `${token('space.100', '8px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: ({ isPreview }) => isPreview && '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${({ columnsCount }: { columnsCount: number }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		getSwimlanesBoardWidth(columnsCount)}px`,
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	top: `${HEADER_HEIGHT}px`,
	background: token('elevation.surface', 'white'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: ({ isMenuOpen }) => (isMenuOpen ? 3 : 2),
	// necessary for the drag and drop top and bottom edges to show correctly over siblings
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '1px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		display: ({ dragStatus, closestEdge }) => (!closestEdge || dragStatus !== 'idle') && 'none',
		content: '',
		width: '100%',
		height: '2px',
		position: 'absolute',
		zIndex: 100,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: ({ closestEdge, isFirstGroup }) =>
			// eslint-disable-next-line no-nested-ternary
			closestEdge === 'top' ? (isFirstGroup ? '-1px' : '-2px') : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		bottom: ({ closestEdge, isLastGroup }) =>
			// eslint-disable-next-line no-nested-ternary
			closestEdge === 'bottom' ? (isLastGroup ? '-1px' : '-2px') : 'auto',
		left: 0,
		right: 0,
		background: token('color.border.brand', B400),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		filter: ({ dragStatus }) => dragStatus === 'dragging' && 'saturate(0)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ dragStatus }) => dragStatus === 'dragging' && 0.3,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowHeaderStickyContainer = styled.div({
	position: 'sticky',
	left: 0,
	display: 'inline-flex',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCountContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CollapseButtonContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'center',
	minWidth: '32px',
	minHeight: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewWrapper = styled.div({
	width: '60vw',
	overflow: 'hidden',
	borderRadius: '5px',
});
