import React, { useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useFieldLabel } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { N300 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { useOnCreatedOrMovedIssueFiltered } from '../../../common/on-created-filtered-issue';
import messages from './messages';
import { AddCard } from './search-and-create';

type Props<TValue> = {
	fieldKey: FieldKey;
	fieldValue: TValue;
	groupIdentity: string | undefined;
	fullWidth?: boolean;
	verticalFieldKey?: FieldKey;
	verticalGroupIdentity?: string;
	verticalFieldValue?: TValue;
};

export const ADD_IDEA_HEIGHT = 32;

export const Footer = ({
	fieldKey,
	fieldValue,
	groupIdentity,
	fullWidth,
	verticalFieldKey,
	verticalGroupIdentity,
	verticalFieldValue,
}: Props<unknown>) => {
	const { formatMessage } = useIntl();
	const { updateFieldValues } = useIssueActions();
	const [isInAddingCardState, setIsInAddingCardState] = useState<boolean>(false);
	const label = useFieldLabel(fieldKey, groupIdentity, fieldValue);
	const onMovedIssueFiltered = useOnCreatedOrMovedIssueFiltered();

	const onMoveIssueToColumn = useCallback(
		(issueId: string) => {
			const fieldsToUpdate = {
				[fieldKey]: fieldValue,
			};

			if (verticalFieldKey !== undefined) {
				fieldsToUpdate[verticalFieldKey] = verticalFieldValue;
			}

			const { filtered } = updateFieldValues({
				localIssueIds: [issueId],
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				fields: Object.entries(fieldsToUpdate).reduce<Record<string, any>>(
					(acc, [keyToUpdate, valueToUpdate]) => {
						acc[keyToUpdate] = {
							newValue: valueToUpdate,
							appendMultiValues: true,
						};

						return acc;
					},
					{},
				),
			});

			if (filtered === true) {
				onMovedIssueFiltered(issueId, true, label);
			}
			setIsInAddingCardState(false);
		},
		[
			fieldKey,
			fieldValue,
			label,
			updateFieldValues,
			verticalFieldKey,
			verticalFieldValue,
			onMovedIssueFiltered,
		],
	);

	if (isInAddingCardState) {
		return (
			<Container
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="hide-from-export"
				fullWidth={fullWidth}
			>
				<AddCard
					fieldKey={fieldKey}
					fieldValue={fieldValue}
					groupIdentity={groupIdentity}
					onBlur={() => setIsInAddingCardState(false)}
					onMoveIssueToColumn={onMoveIssueToColumn}
					verticalFieldKey={verticalFieldKey}
					verticalFieldValue={verticalFieldValue}
					verticalGroupIdentity={verticalGroupIdentity}
				/>
			</Container>
		);
	}
	return (
		<Container
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="hide-from-export"
			fullWidth={fullWidth}
			hasSeparator
		>
			<Button
				testId="polaris-ideas.ui.view-content.idea-board.column.footer.button"
				label="polaris-ideas.ui.view-content.idea-board.column.footer.button"
				iconBefore={<EditorAddIcon label="add" size="small" />}
				appearance="subtle-link"
				onClick={() => setIsInAddingCardState(true)}
			>
				{formatMessage(messages.addCard)}
			</Button>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ fullWidth?: boolean; hasSeparator?: boolean }>({
	display: 'flex',
	height: `${ADD_IDEA_HEIGHT}px`,
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ fullWidth }) => (fullWidth ? 0 : token('space.200', '16px')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: ({ hasSeparator }) => `1px solid ${hasSeparator ? N300 : 'transparent'}`,
});
