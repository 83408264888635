import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	notSelectedField: {
		id: 'polaris-common.common.field-select.not-selected-field',
		defaultMessage: 'Select a field',
		description: '',
	},
	clearSelectionButton: {
		id: 'polaris-common.common.field-select.clear-selection-button',
		defaultMessage: 'Clear selection',
		description: '',
	},
});
