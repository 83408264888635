import React from 'react';
import { Skeleton, Spacer } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export type AdfSkeletonProps = {
	skeletonRows?: string[];
};

const DEFAULT_ADF_SKELETON_ROWS = ['70%', '50%', '60%', '30%'];

export const AdfSkeleton = ({ skeletonRows = DEFAULT_ADF_SKELETON_ROWS }: AdfSkeletonProps) => (
	<>
		{skeletonRows.map((rowWidth, index) => (
			<div key={`skeleton-row-${index}`}>
				<Skeleton height="18px" width={rowWidth} />
				<Spacer height="8px" />
			</div>
		))}
	</>
);
