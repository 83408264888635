import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'polaris-ideas.settings.advanced.header',
		defaultMessage: 'Atlas integration',
		description: 'Page header',
	},
	enableAtlasIntegrationSetting: {
		id: 'polaris-ideas.settings.advanced.enable-atlas-integration-setting',
		defaultMessage: 'Enable Atlas integration',
		description: 'Label for use Atlas integration checkbox',
	},
	atlasTitle: {
		id: 'polaris-ideas.settings.advanced.atlas-title',
		defaultMessage: 'Atlas fields',
		description: 'Title for Atlas fields',
	},
	atlasDescription: {
		id: 'polaris-ideas.settings.advanced.atlas-description',
		defaultMessage:
			'With this feature, you can map ideas to Atlas goals and projects. You can also follow the status of projects and how ideas become deliverables by grouping them by project status. <a>Learn more about Atlas goals and projects</a>',
		description: 'Description for Atlas integration fields',
	},
});
