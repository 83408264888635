/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { type GroupType, components } from '@atlaskit/select';

// @atlaskit/select doesn't export IndicatorsContainerProps
export const IndicatorsContainer = <Option, IsMulti extends boolean = false>(
	props: React.ComponentProps<
		typeof components.IndicatorsContainer<Option, IsMulti, GroupType<Option>>
	>,
) => <components.IndicatorsContainer {...props} css={indicatorsContainerStyles} />;

const indicatorsContainerStyles = css({ flexShrink: 0, paddingRight: 0 });
