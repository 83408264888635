import React, { type PropsWithChildren } from 'react';
import { ButtonItem, Section } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import {
	PolarisIcon,
	PolarisIconType,
} from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import {
	VIEW_KIND_TABLE,
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { CreateEntityLimitTooltip } from '@atlassian/jira-polaris-lib-limits/src/ui/index.tsx';
import messages from './messages';

type ActionsProps = {
	onCreateView: (kind: ViewKind) => void;
	onCreateSection?: () => void;
	viewEntityLimitMessage?: string | null;
	sectionEntityLimitMessage?: string | null;
};

type SectionItemProps = {
	children: React.ReactNode;
	iconBefore: string;
	testId?: string;
	onCreate: () => void;
	entityLimitMessage?: string | null;
};

type EntityLimitTooltipWrapperProps = PropsWithChildren<{ entityLimitMessage?: string | null }>;

const EntityLimitTooltipWrapper = ({
	children,
	entityLimitMessage,
}: EntityLimitTooltipWrapperProps) => {
	if (entityLimitMessage) {
		return (
			<CreateEntityLimitTooltip message={entityLimitMessage}>{children}</CreateEntityLimitTooltip>
		);
	}

	return <>{children}</>;
};

const SectionItem = ({
	testId,
	children,
	iconBefore,
	onCreate,
	entityLimitMessage,
}: SectionItemProps) => (
	<EntityLimitTooltipWrapper entityLimitMessage={entityLimitMessage}>
		<ButtonItem
			isDisabled={!!entityLimitMessage}
			testId={testId}
			iconBefore={<PolarisIcon name={iconBefore} size="medium" label="" />}
			onClick={() => onCreate()}
		>
			{children}
		</ButtonItem>
	</EntityLimitTooltipWrapper>
);

export const Actions = ({
	onCreateView,
	onCreateSection,
	viewEntityLimitMessage,
	sectionEntityLimitMessage,
}: ActionsProps) => {
	const { formatMessage } = useIntl();

	return (
		<Section>
			{onCreateSection && (
				<SectionItem
					iconBefore={PolarisIconType.ChevronRight}
					testId="polaris-lib-create-view-popup.ui.actions.section"
					onCreate={onCreateSection}
					entityLimitMessage={sectionEntityLimitMessage}
				>
					{formatMessage(messages.createNewSection)}
				</SectionItem>
			)}
			<SectionItem
				iconBefore={PolarisIconType.BulletList}
				testId="polaris-lib-create-view-popup.ui.actions.table"
				onCreate={() => onCreateView(VIEW_KIND_TABLE)}
				entityLimitMessage={viewEntityLimitMessage}
			>
				{formatMessage(messages.createNewList)}
			</SectionItem>
			<SectionItem
				iconBefore={PolarisIconType.Board}
				testId="polaris-lib-create-view-popup.ui.actions.board"
				onCreate={() => onCreateView(VIEW_KIND_BOARD)}
				entityLimitMessage={viewEntityLimitMessage}
			>
				{formatMessage(messages.createNewBoard)}
			</SectionItem>
			<SectionItem
				iconBefore={PolarisIconType.Matrix}
				testId="polaris-lib-create-view-popup.ui.actions.matrix"
				onCreate={() => onCreateView(VIEW_KIND_MATRIX)}
				entityLimitMessage={viewEntityLimitMessage}
			>
				{formatMessage(messages.createNewMatrix)}
			</SectionItem>
			<SectionItem
				iconBefore={PolarisIconType.Timeline}
				testId="polaris-lib-create-view-popup.ui.actions.timeline"
				onCreate={() => onCreateView(VIEW_KIND_TIMELINE)}
				entityLimitMessage={viewEntityLimitMessage}
			>
				{formatMessage(messages.createNewTimeline)}
			</SectionItem>
		</Section>
	);
};
