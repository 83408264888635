import React, { useCallback } from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import errorIcon from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/generic-error';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ContactSupportLink } from '@atlassian/jira-polaris-common/src/common/utils/errors/contact-support-link';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type ErrorStateProps = {
	header: string;
};

export const ErrorState = ({ header }: ErrorStateProps) => {
	const { formatMessage } = useIntl();

	const handleRefreshClick = useCallback(
		(_event: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'refreshPage');
			window.location.reload();
		},
		[],
	);

	return (
		<EmptyState
			header={header}
			renderImage={getAkEmptyStateRenderImageFn(errorIcon)}
			maxImageWidth={120}
			description={formatMessage(messages.errorScreenDescription, {
				a: (text: React.ReactNode) => <ContactSupportLink>{text}</ContactSupportLink>,
			})}
			primaryAction={
				<Button appearance="link" onClick={handleRefreshClick}>
					{formatMessage(messages.refresh)}
				</Button>
			}
		/>
	);
};
