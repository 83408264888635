import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cancel: {
		id: 'polaris-lib-feedback.modal.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Button caption to cancel the feedback and close the modal.',
	},
	sendFeedback: {
		id: 'polaris-lib-feedback.modal.footer.send-feedback',
		defaultMessage: 'Send feedback',
		description: 'Button caption to send feedback',
	},
});
