import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch';
import type { Action } from '@atlassian/react-sweet-state';
import {
	type Props,
	type State,
	LinkedDeliveryIssuesMergeOption,
	DataPointsMergeOption,
} from '../../types';

export const copyIssueLinks =
	(isSameProject: boolean): Action<State, Props> =>
	async (
		{ getState },
		{
			onIssueLinked,
			projectId,
			polarisDeliveryIssueLinkType,
			polarisDataPointIssueLinkType,
			issuesRemote,
		},
	) => {
		const { targetIssue, selectedIssues, mergeOptions } = getState();
		if (projectId === null || targetIssue === null) {
			return;
		}

		const copyInsightIssueLinks = mergeOptions[DataPointsMergeOption];
		const copyDeliveryIssueLinks = mergeOptions[LinkedDeliveryIssuesMergeOption];

		if (!copyInsightIssueLinks && !copyDeliveryIssueLinks) {
			return;
		}

		try {
			await runInBatch(
				selectedIssues.map((selectedIssue) => async () => {
					const selectedIssueData = await issuesRemote.fetchIssue({
						issueIdOrKey: String(selectedIssue.id),
					});

					if ('meta' in selectedIssueData) {
						const selectedIssueLinks = selectedIssueData?.meta?.issueLinks?.links || [];
						const linksToCopy = selectedIssueLinks.filter(
							(link) =>
								(copyInsightIssueLinks &&
									String(link.typeId) === String(polarisDataPointIssueLinkType)) ||
								(copyDeliveryIssueLinks &&
									String(link.typeId) === String(polarisDeliveryIssueLinkType)),
						);

						await Promise.all(
							linksToCopy.map(async (link) => {
								const inwardIssueKey =
									selectedIssueData.key === link.inwardIssue.key
										? targetIssue.key
										: link.inwardIssue.key;
								const outwardIssueKey =
									selectedIssueData.key === link.outwardIssue.key
										? targetIssue.key
										: link.outwardIssue.key;

								await issuesRemote.createIssueLink({
									issueLinkTypeId: link.typeId,
									inwardIssueKey,
									outwardIssueKey,
								});

								if (onIssueLinked) {
									onIssueLinked(outwardIssueKey, inwardIssueKey, isSameProject);
								}
							}),
						);
					}
				}),
				5,
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			log.safeErrorWithoutCustomerData(
				'polaris.merge-ideas-error',
				'Failed to copy linked issues to target idea',
				err,
			);
			throw err;
		}
	};
