import React, { useCallback, useState, useMemo } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { slackIcon } from '../../../../common/ui/apps';
import { SlackHowToDialog } from '../../../../common/ui/apps/modals/slack/index.tsx';
import type { AppPreset } from '../types';
import messages from './messages';

export const SLACK_KEY = 'slack';

const SlackInfoAction = () => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const close = useCallback(() => setIsOpen(false), []);

	return (
		<>
			<Button spacing="none" appearance="link" onClick={() => setIsOpen(true)}>
				{formatMessage(messages.showMeHow)}
			</Button>
			<SlackHowToDialog isOpen={isOpen} onClose={close} />
		</>
	);
};

export const useSlackPreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: SLACK_KEY,
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: slackIcon,
			isRecommended: false,
			Action: SlackInfoAction,
		}),
		[formatMessage],
	);
};
