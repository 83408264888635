import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import type { ItemId } from '../../types';
import type { State } from '../types';
import {
	computeAxisValueIndex,
	getXAxisValues,
	getYAxisValues,
	getZAxis,
	getZAxisValues,
} from './axis';
import { getZPosition } from './positions';

export const getItemIds = (state: State): ItemId[] => state.items;

export const getClusteredItemIds = createSelector(
	getItemIds,
	getXAxisValues,
	getYAxisValues,
	getZAxisValues,
	getZAxis,
	(itemIds, xValues, yValues, zValues, zAxis) => {
		const groupedItemIds = groupBy(itemIds, (id) => {
			const zItemAxisIndex = computeAxisValueIndex(zAxis, zValues[id]);
			const zPosition = getZPosition(zItemAxisIndex, zAxis);

			return `${xValues[id]}-${yValues[id]}-${zPosition}`;
		});

		return values(groupedItemIds).map((unsorted) =>
			sortBy(unsorted, (id) => zValues[id]).reverse(),
		);
	},
);

export const getEmptyState = (state: State) => state.emptyState;
