import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createStore, type StoreActionApi } from '@atlassian/react-sweet-state';
import { setFormulaDataFieldsByKey } from './actions';
import type { State, Props } from './types';

export const initialState: State = {
	fieldData: undefined,
	canEditField: false,
	callbacks: {
		onValidateFieldName: () => undefined,
		onUpdateFieldName: () => Promise.resolve(true),
		onUpdateDescription: () => Promise.resolve(),
		onUpdateEmoji: () => Promise.resolve(),
	},
	decorationsComponents: {},
	optionsLimitCount: null,
	outerSpacing: '0px',
	readonly: false,
	components: {},
	isPreview: false,
	isDescriptionEditorAiEnabled: false,
	containerProps: undefined,
	formulaFieldData: {
		fieldsByKey: {},
	},
};

const actions = { setFormulaDataFieldsByKey };
export type Actions = typeof actions;

export const FieldConfigurationStore = createStore<State, Actions, Props>({
	initialState,
	actions,
	name: 'PolarisFieldConfigurationStore',
});

const updateStateFromProps =
	() =>
	(
		{ setState }: StoreActionApi<State>,
		{
			fieldData,
			canEditField,
			decorationsComponents = {},
			notEditableReason,
			onValidateFieldName,
			onUpdateFieldName,
			onUpdateDescription,
			onUpdateEmoji,
			onValueDecorationCreated,
			onMultipleValueDecorationsCreated,
			onValueDecorationDeleted,
			onAllValueDecorationsDeleted,
			onValueDecorationUpdated,
			onOptionAdded,
			onOptionDeleted,
			onOptionOpened,
			onOptionRenamed,
			onOptionsSearch,
			onOptionsReordered,
			onFieldOptionWeightUpdated,
			onFieldOptionWeightTypeUpdated,
			onFieldOptionAutoFormattingUpdated,
			onIntervalFieldSourceUpdated,
			onDeliveryDateSourceFieldUpdated,
			onDeliveryDateAggregationTypeUpdated,
			onDeliveryDateConfigurationComplete,
			onFieldPeopleDisplayModeUpdated,
			optionsLimitCount,
			outerSpacing,
			projectId,
			projectKey,
			readonly = false,
			isPreview = false,
			renderConfigSection,
			formulaContent,
			isDescriptionEditorAiEnabled = false,
			defaultOpenSidebarOptionId,
		}: Props,
	) => {
		setState({
			fieldData,
			canEditField,
			decorationsComponents,
			notEditableReason,
			projectId,
			projectKey,
			readonly,
			isPreview,
			callbacks: {
				onValidateFieldName,
				onUpdateFieldName,
				onUpdateDescription,
				onUpdateEmoji,
				onValueDecorationCreated,
				onMultipleValueDecorationsCreated,
				onValueDecorationDeleted,
				onAllValueDecorationsDeleted,
				onValueDecorationUpdated,
				onOptionAdded,
				onOptionDeleted,
				onOptionOpened,
				onOptionRenamed,
				onOptionsReordered,
				onOptionsSearch,
				onFieldOptionWeightUpdated,
				onFieldOptionWeightTypeUpdated,
				onFieldOptionAutoFormattingUpdated,
				onIntervalFieldSourceUpdated,
				onDeliveryDateSourceFieldUpdated,
				onDeliveryDateAggregationTypeUpdated,
				onDeliveryDateConfigurationComplete,
				onFieldPeopleDisplayModeUpdated,
			},
			optionsLimitCount,
			outerSpacing,
			components: {
				formulaContent,
				renderConfigSection,
			},
			isDescriptionEditorAiEnabled,
			defaultOpenSidebarOptionId,
		});
	};

export const {
	Container: FieldConfigurationContainer,
	useActions,
	createHook,
	createHigherLevelHook,
} = createJpdContainer<Props, State, Actions>(FieldConfigurationStore, {
	onInit: updateStateFromProps,
	onUpdate: updateStateFromProps,
});
