import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { FieldTitleWithIcon as BaseFieldTitleWithIcon } from '@atlassian/jira-polaris-component-field-configuration/src/common/ui/field-title-with-icon/index.tsx';
import { fieldTypeNameForPolarisFieldType } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/field-type-name/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	useFieldTypeIcon,
	useFieldType,
} from '../../../../../controllers/field/selectors/field-hooks';
import { messages as commonMessages } from '../messages';

export const FieldTitleWithIcon = ({ fieldKey }: { fieldKey: FieldKey }) => {
	const { formatMessage } = useIntl();
	const fieldType = useFieldType(fieldKey);
	const icon = useFieldTypeIcon(fieldKey, undefined);

	const fieldTypeName = fieldTypeNameForPolarisFieldType(fieldType);
	const fieldTypeNameSuffix = formatMessage(commonMessages.fieldTypeLabelSuffix);

	const title = `${fieldTypeName ? formatMessage(fieldTypeName) : ''} ${fieldTypeNameSuffix}`;

	return <BaseFieldTitleWithIcon icon={icon} title={title} />;
};
