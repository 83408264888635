import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { ProjectFormsSettings } from '@atlassian/jira-polaris-lib-forms-settings/src/ui/main.tsx';
import type { Props } from './controllers/types';

export type { Components, DescriptionTemplatesProps } from './controllers/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPolarisFormsSettingsScreen = lazyForPaint<typeof ProjectFormsSettings>(
	() => import(/* webpackChunkName: "async-polaris-project-forms-settings" */ './ui'),
	{ ssr: false },
);

const SafeLazyPolarisFormsSettingsScreen = (props: Props) => (
	<JSErrorBoundary
		fallback="flag"
		id="async.jira.polaris.project-settings"
		packageName="atlassian/jira-polaris-lib-project-access"
	>
		<Placeholder name="polaris-project-forms-settings" fallback={<></>}>
			<LazyPolarisFormsSettingsScreen {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

SafeLazyPolarisFormsSettingsScreen.defaultProps = {
	breadcrumbs: undefined,
};

export default SafeLazyPolarisFormsSettingsScreen;
