import type { ViewMountStatusUiState } from '@atlassian/jira-issue-view-common-types/src/view-mount-status-type';
import {
	type IssueViewMountedAction,
	ISSUE_VIEW_MOUNTED,
} from '../actions/view-mount-status-actions';

type Action = IssueViewMountedAction;

const initialState: ViewMountStatusUiState = {
	hasIssueViewMounted: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: ViewMountStatusUiState = initialState,
	action: Action,
): ViewMountStatusUiState => {
	switch (action.type) {
		case ISSUE_VIEW_MOUNTED: {
			return { ...state, hasIssueViewMounted: true };
		}
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(action.type as never);
			return state;
	}
};
