import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';

const template: ADF = {
	version: 1,
	type: 'doc',

	content: [
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'After you’ve used the problem definition template to clearly define the problem you’re going after, use this template to define potential solutions and validate them.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#403294',
							},
						},
					],
				},
			],
		},
		{
			type: 'table',
			attrs: {
				isNumberColumnEnabled: false,
				layout: 'default',
				localId: '7b5278a4-9816-4f8b-8217-55a0bb8c05be',
			},
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Driver',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who’s the driver for this exploration?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Contributors',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who are contributors helping define and validate potential solutions?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colwidth: [165],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Informed',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'strong',
												},
											],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colwidth: [593],
							},
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'text',
											text: 'Who should be informed of the findings?',
											// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
											marks: [
												{
													type: 'textColor',
													attrs: {
														color: '#97a0af',
													},
												},
											],
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':thought_balloon:',
						id: '1f4ad',
						text: '💭',
					},
				},
				{
					type: 'text',
					text: ' Problem definition',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Recap what’s the problem you’re trying to solve.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':notepad_spiral:',
						id: '1f5d2',
						text: '🗒',
					},
				},
				{
					type: 'text',
					text: ' Solution definition',
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'What are possible solutions?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Describe the solutions you’ve explored to solve the problem, and the ones you decided to pursue.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Link to design prototypes, e.g. Figma.',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'What’s the initial scope?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Define the initial scope of the solution you’d like to test with customers. ',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'How will we measure success?',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Clearly articulate how you’re going to measure success for the solution, covering both qualitative and quantitative aspects',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'emoji',
					attrs: {
						shortName: ':test_tube:',
						id: '1f9ea',
						text: '🧪',
					},
				},
				{
					type: 'text',
					text: ' Validation',
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'How did we validate this? ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'What data or insights can you provide to validate that the solution is:',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'bulletList',
			content: [
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Feasible technically (e.g. via a spike)',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Valuable to customers ',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Usable (e.g. via prototype testing)',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'listItem',
					content: [
						// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: "paragraph"; content: { type: "text"; text: string; marks: { type: string; attrs: { color: string; }; }[]; }[]; }' is not assignable to type 'ParagraphDefinition | CodeBlockDefinition | MediaSingleDefinition'.
						{
							type: 'paragraph',

							content: [
								{
									type: 'text',
									text: 'Viable from a business perspective',
									marks: [
										{
											type: 'textColor',
											attrs: {
												color: '#97a0af',
											},
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'Assumptions ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'What assumptions are you making that need to be validated or refuted?',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 3,
			},
			content: [
				{
					type: 'text',
					text: 'Knowledge gaps ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'What are the gaps in your understanding?',
					// @ts-expect-error([Part of upgrade 4.9-5.4]) - Type '{ type: string; attrs: { color: string; }; }[]' is not assignable to type '(any[] & (LinkDefinition | EmDefinition | StrongDefinition | StrikeDefinition | SubSupDefinition | UnderlineDefinition | TextColorDefinition | AnnotationMarkDefinition)[]) | (any[] & (LinkDefinition | ... 1 more ... | CodeDefinition)[]) | undefined'.
					marks: [
						{
							type: 'textColor',
							attrs: {
								color: '#97a0af',
							},
						},
					],
				},
			],
		},
		{
			type: 'heading',
			attrs: {
				level: 2,
			},
			content: [
				{
					type: 'text',
					text: 'Resources',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: 'Here are plays from the Atlassian team playbook that are relevant to this:',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/mind-mapping',
					},
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/disruptive-brainstorming',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/elevator-pitch',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/trade-offs',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
		{
			type: 'paragraph',
			content: [
				{
					type: 'inlineCard',
					attrs: {
						url: 'https://www.atlassian.com/team-playbook/plays/pre-mortem',
					},
				},
				{
					type: 'text',
					text: ' ',
				},
			],
		},
	],
};

export default template;
