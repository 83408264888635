import React, { type ReactNode, useEffect, useCallback, type SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';
import { openOauth2AuthenticationWindow } from './utils';

type Props = {
	url: string;
	text?: ReactNode;
	triggerOnMount: boolean;
	authUrl: string;
	onSuccess: () => void;
	onFailure: (error: Error) => void;
};

export const AuthenticateOauth2Button = ({
	url,
	text,
	authUrl,
	onSuccess,
	onFailure,
	triggerOnMount,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleClick = useCallback(
		(evt: SyntheticEvent | undefined) => {
			evt?.preventDefault();
			if (!authUrl || url === null) {
				return;
			}
			const handleSuccess = () => {
				onSuccess();
			};
			const handleFailure = (error: Error) => {
				onFailure(error);
			};
			openOauth2AuthenticationWindow(authUrl, url, handleSuccess, handleFailure);
		},
		[authUrl, onFailure, onSuccess, url],
	);

	useEffect(() => {
		if (triggerOnMount) {
			handleClick(undefined);
		}
	}, [handleClick, triggerOnMount]);

	return (
		<Button
			onClick={handleClick}
			testId="polaris-common.controllers.unfurl.utils.authentication.oauth2.button"
		>
			{text !== undefined ? text : formatMessage(messages.authenticateButton)}
		</Button>
	);
};
