import React, { type ReactNode, useCallback } from 'react';
import { PolarisRouteContainer } from '@atlassian/jira-polaris-common/src/controllers/route';
import {
	type PolarisRoute,
	POLARIS_QUERY_VALID_VALUES,
} from '@atlassian/jira-polaris-common/src/controllers/route/types';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import { constructAppUrl } from '../utils';

const DEFAULT_ALLOWED_SECTIONS = ['apps', ViewSectionTypeViews, 'settings'];

export const IdeasRouteContainer = ({ children }: { children: ReactNode }) => {
	const projectKey = useProjectKeyUnsafe();

	const getAppUrl = useCallback(
		(route: PolarisRoute) => constructAppUrl(projectKey, route),
		[projectKey],
	);

	if (projectKey === undefined) {
		return <>{children}</>;
	}

	return (
		<PolarisRouteContainer
			getAppUrl={getAppUrl}
			storageKey={projectKey}
			allowedSections={DEFAULT_ALLOWED_SECTIONS}
			queryValidValues={POLARIS_QUERY_VALID_VALUES}
			defaultSection={ViewSectionTypeViews}
		>
			{children}
		</PolarisRouteContainer>
	);
};
