import {
	type PublicGetValue,
	type MultiSelectPublicSetValue,
	multiSelectScopes as baseMultiSelectScopes,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/multi-select';
import { commonIssueViewGranularScopes } from './fields-scopes';

export type InternalValue = {
	id: string;
	value: string;
};

export type MultiSelectPublicGetValue = PublicGetValue[];

export type MultiSelectInternalFullShapeValue = InternalValue[];

export type MultiSelectInternalIdShapeValue = { id: string }[];

export const transformMultiSelectSetValue = (
	values: MultiSelectPublicSetValue,
): MultiSelectInternalIdShapeValue => values.map((id) => ({ id }));

export const transformMultiSelectGetValue = (
	internalValue: MultiSelectInternalFullShapeValue,
): MultiSelectPublicGetValue => {
	if (!internalValue) return [];

	return internalValue.map(({ id, value }) => ({ id, value }));
};

export const multiSelectScopes = {
	...baseMultiSelectScopes,
	granular: [...baseMultiSelectScopes.granular, ...commonIssueViewGranularScopes],
};
