import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pinField: {
		id: 'polaris-ideas.idea-view.fields.field.pin-field',
		defaultMessage: 'Pin to top',
		description: 'Button to move field to pinned list',
	},
	unpinField: {
		id: 'polaris-ideas.idea-view.fields.field.unpin-field',
		defaultMessage: 'Unpin',
		description: 'Button to remove field from pinned list',
	},
});
