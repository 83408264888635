import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types';
import { transformResponse } from '../../common/transform-worklogs';
import type { Worklogs } from '../../model';
import fetchWorklogs from '../../rest/fetch-worklogs';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	startAt: number,
	maxResults: number,
	reverseSort: boolean,
): Promise<Worklogs> =>
	fetchWorklogs(baseUrl, issueKey, startAt, maxResults, reverseSort).then((result) =>
		transformResponse(result),
	);
