import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	contributionEdited: {
		id: 'polaris-common.plays.add-contribution.header.contribution-edited',
		defaultMessage: 'Edited',
		description: 'Indicator whether or not this contribution was edited',
	},
	editVoteTooltip: {
		id: 'polaris-common.plays.add-contribution.header.edit-vote-tooltip',
		defaultMessage: 'Edit your vote',
		description: 'Text for the edit vote tooltip',
	},
});
