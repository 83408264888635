import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { PatchOperation } from '@atlassian/jira-polaris-lib-json-patch/src/types.tsx';

export const updateGlobalFieldConfiguration = async (
	fieldKey: string,
	patch: PatchOperation[],
): Promise<void> => {
	await performPostRequest(`/rest/polaris/global-fields/${fieldKey}/configuration/patch`, {
		body: JSON.stringify({
			patch,
		}),
	});
};
