import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ideaLinkedToHint: {
		id: 'polaris-common.fields.atlas-project-date.hint.idea-linked-to-hint',
		defaultMessage: 'This idea is linked to:',
		description: 'Idea linked to hint',
	},
	dateIsOverriddenByHint: {
		id: 'polaris-common.fields.atlas-project-date.hint.date-is-overridden-by-hint',
		defaultMessage: 'The date is overridden by:',
		description: 'The date is overridden by hint',
	},
	configureButton: {
		id: 'polaris-common.fields.atlas-project-date.hint.configure-button',
		defaultMessage: 'Configure',
		description: 'Configure button hint',
	},
	atlasProjectStartDate: {
		id: 'polaris-common.fields.atlas-project-date.hint.atlas-project-start-date',
		defaultMessage: 'Atlas project start date',
		description: 'Label for Atlas project start date field',
	},
	atlasProjectTargetDate: {
		id: 'polaris-common.fields.atlas-project-date.hint.atlas-project-target-date',
		defaultMessage: 'Atlas project target date',
		description: 'Label for Atlas project target date field',
	},
});
