// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	__schema: {
		types: [
			{
				kind: 'INTERFACE',
				name: 'PolarisIdeaField',
				possibleTypes: [
					{
						name: 'PolarisIdeaStringField',
					},
					{
						name: 'PolarisIdeaDocumentField',
					},
					{
						name: 'PolarisIdeaDateField',
					},
					{
						name: 'PolarisIdeaDateTimeField',
					},
					{
						name: 'PolarisIdeaStatusField',
					},
					{
						name: 'PolarisIdeaIntervalField',
					},
					{
						name: 'PolarisIdeaKeyField',
					},
					{
						name: 'PolarisIdeaIssueIdField',
					},
					{
						name: 'PolarisIdeaIssueTypeField',
					},
					{
						name: 'PolarisIdeaArchivedField',
					},
					{
						name: 'PolarisIdeaArchivedByField',
					},
					{
						name: 'PolarisIdeaArchivedOnField',
					},
					{
						name: 'PolarisIdeaSpecialField',
					},
					{
						name: 'PolarisIdeaNumberField',
					},
					{
						name: 'PolarisIdeaExternalReferenceField',
					},
					{
						name: 'PolarisIdeaExternalReferencePropertyField',
					},
					{
						name: 'PolarisIdeaLabelsField',
					},
					{
						name: 'PolarisIdeaUserField',
					},
					{
						name: 'PolarisIdeaUsersField',
					},
					{
						name: 'PolarisIdeaOptionField',
					},
					{
						name: 'PolarisIdeaOptionsField',
					},
					{
						name: 'PolarisIdeaLinkedIssuesField',
					},
					{
						name: 'PolarisIdeaLinkedIssuesProgressField',
					},
					{
						name: 'PolarisIdeaIssueCommentsField',
					},
					{
						name: 'PolarisIdeaPlayField',
					},
					{
						name: 'PolarisIdeaReactionsField',
					},
				],
			},
			{
				kind: 'INTERFACE',
				name: 'PolarisIdeaFieldValue',
				possibleTypes: [
					{
						name: 'PolarisIdeaStringFieldValue',
					},
					{
						name: 'PolarisIdeaDocumentFieldValue',
					},
					{
						name: 'PolarisIdeaNumberFieldValue',
					},
					{
						name: 'PolarisIdeaDateFieldValue',
					},
					{
						name: 'PolarisIdeaDateTimeFieldValue',
					},
					{
						name: 'PolarisIdeaLabelsFieldValue',
					},
					{
						name: 'PolarisIdeaUserFieldValue',
					},
					{
						name: 'PolarisIdeaUsersFieldValue',
					},
					{
						name: 'PolarisIdeaOptionsFieldValue',
					},
					{
						name: 'PolarisIdeaOptionFieldValue',
					},
				],
			},
			{
				kind: 'INTERFACE',
				name: 'Node',
				possibleTypes: [
					{
						name: 'PolarisSupport',
					},
					{
						name: 'PolarisImplementation',
					},
				],
			},
			{
				kind: 'INTERFACE',
				name: 'PolarisVisualization',
				possibleTypes: [
					{
						name: 'PolarisTableVisualization',
					},
					{
						name: 'PolarisBoardVisualization',
					},
				],
			},
			{
				kind: 'INTERFACE',
				name: 'MutationErrorExtension',
				possibleTypes: [],
			},
			{
				kind: 'INTERFACE',
				name: 'Payload',
				possibleTypes: [
					{
						name: 'ChangePolarisIdeaPayload',
					},
					{
						name: 'CreatePolarisIdeaPayload',
					},
				],
			},
		],
	},
};
