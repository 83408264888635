import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	uimIssueViewConsentMessage: {
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX THIS SUPPRESSION
		id: 'ui-modifications-fields-configuration.common.utils.views.issue-view.uim-issue-view-consent-message',
		defaultMessage:
			'Allow “{appTitle}” to access your Atlassian products to get the full issue experience.',
		description:
			'When a user opens a context the first time, we require that they give consent for their data otherwise they cannot get the UIM`s applied',
	},
});
