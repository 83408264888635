import { useMemo } from 'react';
import { statusCategoryForId, DONE } from '@atlassian/jira-common-constants/src/status-categories';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { DisableVotingProps } from '../../common/types';
import messages from './messages';

export const useDisableResolvedIssueVoting = (): DisableVotingProps => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const [statusFieldValue] = useFieldValue({
		issueKey,
		fieldKey: 'status',
	});

	const isDisabled = useMemo(
		() => statusCategoryForId(statusFieldValue?.statusCategory.id) === DONE,
		[statusFieldValue],
	);

	const tooltips = useMemo(
		() =>
			isDisabled
				? {
						addVote: formatMessage(messages.addVoteDisabledTooltip),
						removeVote: formatMessage(messages.removeVoteDisabledTooltip),
					}
				: undefined,
		[formatMessage, isDisabled],
	);

	return { isDisabled, tooltips };
};
