import { useState, useCallback, useLayoutEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

interface Size {
	width: number;
	height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
	(node: T | null) => void,
	Size,
] {
	const [size, setSize] = useState<Size>({
		width: 0,
		height: 0,
	});
	const ref = useRef<T | null>(null);
	const observer = useRef<ResizeObserver | null>(null);

	const updateSize = useCallback(() => {
		const node = ref.current;
		if (node) {
			setSize({
				width: node.offsetWidth,
				height: node.offsetHeight,
			});
		}
	}, []);

	const setRef = useCallback(
		(node: T | null) => {
			if (ref.current && observer.current) {
				observer.current.unobserve(ref.current);
			}

			ref.current = node;

			if (ref.current) {
				observer.current = new ResizeObserver(debounce(updateSize, 150));
				observer.current.observe(ref.current);
			}
		},
		[updateSize],
	);

	useLayoutEffect(() => {
		updateSize();
		return () => {
			if (ref.current && observer.current) {
				observer.current.unobserve(ref.current);
			}
		};
	}, [updateSize]);

	return [setRef, size];
}
