import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.header.label',
		defaultMessage: 'Name',
		description: 'Name column header',
	},
	fieldType: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.header.field-type',
		defaultMessage: 'Type',
		description: 'Type column header',
	},
	description: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.header.description',
		defaultMessage: 'Description',
		description: 'Description column header',
	},
	sortAscending: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.header.sort-ascending',
		defaultMessage: 'Sort ascending',
		description: 'Button label for sorting by column.',
	},
	sortDescending: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.header.sort-descending',
		defaultMessage: 'Sort descending',
		description: 'Button label for sorting by column.',
	},
	removeSort: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.header.remove-sort',
		defaultMessage: 'Remove sort',
		description: 'Button label for removing the sorting by column.',
	},
});
