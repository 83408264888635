import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getCurrentViewMatrixConfig } from '../../../selectors/view/matrix';
import type { State, Props } from '../../../types';
import { saveViewWithAutoSave } from '../../save';
import { fireViewUpdatedEvent } from '../../utils/analytics';
import { updateViewState } from '../../utils/state';
import { currentViewFilter } from '../../utils/views';

export const setFieldValues =
	(fieldValues: GroupValue[], dimension: string): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();

		const viewMutation = (view: View): View => {
			const matrixConfig = getCurrentViewMatrixConfig(state, props);
			if (matrixConfig === undefined) {
				return view;
			}
			const newAxes = matrixConfig.axes.map((axis) => {
				if (axis.dimension === dimension) {
					const fieldOptions = axis.reversed ? [...fieldValues].reverse() : fieldValues;
					return { ...axis, fieldOptions };
				}
				return axis;
			});
			const newMatrixConfig = {
				...matrixConfig,
				axes: newAxes,
			};

			return {
				...view,
				matrixConfig: view.isAutosaveEnabled ? newMatrixConfig : view.matrixConfig,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					matrixConfig: newMatrixConfig,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
				updatedItems: [{ name: `matrixConfig.axes.${dimension}.fieldOptions` }],
			});
		}
	};

export const setFieldValuesReverseOrder =
	(isReverseOrder: boolean, dimension: string): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();

		const viewMutation = (view: View): View => {
			const matrixConfig = getCurrentViewMatrixConfig(state, props);
			if (matrixConfig === undefined) {
				return view;
			}
			const newAxes = matrixConfig.axes.map((axis) => {
				if (axis.dimension === dimension) {
					return { ...axis, reversed: isReverseOrder };
				}
				return axis;
			});
			const newMatrixConfig = {
				...matrixConfig,
				axes: newAxes,
			};

			return {
				...view,
				matrixConfig: view.isAutosaveEnabled ? newMatrixConfig : view.matrixConfig,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					matrixConfig: newMatrixConfig,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
				updatedItems: [{ name: `matrixConfig.axes.${dimension}.reversed` }],
			});
		}
	};
