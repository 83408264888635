import React, { memo, type ReactNode, type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FormattedDate } from '../../../../common/ui/date';
import messages from './messages';

const SEPARATOR = '・';

type Props = {
	displayName?: string;
	avatarUrl?: string;
	created?: string;
	updated?: string;
	children?: ReactNode;
};

export const AuthorDetails = (props: ComponentPropsWithoutRef<typeof AuthorDetailsComponent>) => (
	<Heading size="xsmall">
		<AuthorDetailsComponent {...props} />
	</Heading>
);

export const PlayContributionHeaderComponent = memo<Props>(
	({ displayName = '', avatarUrl = '', created, updated, children }: Props) => {
		const { formatMessage } = useIntl();

		return (
			<Container>
				<DetailsWrapper>
					<div>
						<Avatar src={avatarUrl} size="medium" />
					</div>
					<DetailsContainer>
						<AuthorDetails>{displayName}</AuthorDetails>
						<Details>
							{updated !== undefined ? (
								<div>
									<FormattedDate date={updated} />
								</div>
							) : (
								created !== undefined && (
									<div>
										<FormattedDate date={created} />
									</div>
								)
							)}
							{updated !== created && (
								<div>
									{SEPARATOR}
									{formatMessage(messages.contributionEdited)}
								</div>
							)}
						</Details>
					</DetailsContainer>
				</DetailsWrapper>
				<div>{children}</div>
			</Container>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailsWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	marginBottom: token('space.075', '6px'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Details = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '3px',
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N200,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AuthorDetailsComponent = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N900),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '16px',
	marginTop: '0px',
});
