import { N0, N30, N40, N20, B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const DEFAULT_MAX_TABLE_HEIGHT = 650;
export const DEFAULT_FOOTER_HEIGHT = 45;
export const OVERSCAN_ROW_COUNT = 4;

export const DEFAULT_ROW_HEIGHT = 42;
export const DEFAULT_HEADER_HEIGHT = 42;
export const ROW_BORDER_WIDTH = 1;
export const ROW_BORDER_RADIUS = 6;

export const ESTIMATED_ROW_HEIGHT = DEFAULT_ROW_HEIGHT;
export const ACTIVE_ROW_HEIGHT = DEFAULT_ROW_HEIGHT + ROW_BORDER_WIDTH; // to account for the selection border

export const tableFontSize = '14px';
export const columnPadding = '7.5px';
export const backgroundColor = token('elevation.surface', N0);
export const borderColor = token('color.border', N40);
export const activeBorderColor = token('color.border.selected');
export const boxShadowColor = token('elevation.surface.raised.hovered', N30);
export const boxShadowOffset = '2px';
export const boxShadowBlur = '4px';
export const rowHoveredBackgroundColor = token('elevation.surface.raised.hovered', N20);
export const rowSelectedBackgroundColor = token('color.background.selected', '#EEF5FF');
export const rowSelectedHoveredBackgroundColor = token('color.background.selected.hovered');
export const rowGroupDropTargetBackgroundColor = token('color.background.input', N20);
export const columnResizerColor = token('color.text.brand', B300);
export const activeChangingCellBackGroundColor = token(
	'color.background.accent.blue.subtler',
	'rgba(179, 212, 255, 0.5)',
);

export const STICKY_GROUP_CELLS: Array<never> = [];
export const STICKY_GROUP_COLUMNS: Array<never> = [];

// this id is used as selector in export process
export const EXPORT_LIST_ID = 'list-view-table';

// The CSS custom property has a suffix at the end to avoid
// potential global namespace conflict
export const TABLE_VERTICAL_SCROLLBAR_SIZE_CSS_VARIABLE = '--tableVerticalScrollbarSize-72kl1n';
