import without from 'lodash/without';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const addAttachmentInUpload =
	(attachmentMediaApiId: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			attachments: {
				...getState().attachments,
				inUpload: [attachmentMediaApiId, ...getState().attachments.inUpload],
			},
		});
	};

export const abortAttachmentInUpload =
	(attachmentMediaApiId: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			attachments: {
				...getState().attachments,
				inUpload: without(getState().attachments.inUpload, attachmentMediaApiId),
			},
		});
	};

export const addAttachment =
	(
		attachmentId: number,
		attachmentMediaApiId: string,
		mediaClientConfig: MediaClientConfig,
		authorAccountId: string | null,
	): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			attachments: {
				inUpload: getState().attachments.inUpload.filter((id) => id !== attachmentMediaApiId),
				attachments: [
					{
						file: {
							attachmentId,
							attachmentMediaApiId,
							authorAccountId: authorAccountId ?? '',
						},
						mediaClientConfig,
					},
					...getState().attachments.attachments,
				],
			},
		});
	};
