import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';

const tryExtractRatingValue = (decoration: ValueDecoration) => {
	if (isDecorationWithLogic(decoration)) {
		return undefined; // TODO: Handle Decoration Logic
	}

	if (
		decoration.rules.length !== 2 ||
		decoration.rules[0].value !== decoration.rules[1].value ||
		!decoration.rules.find((rule) => rule.operator === ValueRuleOperator.GTE) ||
		!decoration.rules.find((rule) => rule.operator === ValueRuleOperator.LTE)
	) {
		return undefined;
	}
	return decoration.rules[0].value;
};

/**
 * This function calculates valid rating decorations from a list of decorations removing duplicates and
 * decorations with unsuited rules (rating decorations have a LTE and a GTE rule with the same value).
 * Note: this needed to be introduced because of a bug that resulted in duplicated decorations for rating fields:
 * https://pi-dev-sandbox.atlassian.net/browse/POL-2451
 */
export const getDeduplicatedRatingDecorations = (
	decorations: ValueDecoration[],
): ValueDecoration[] => {
	const deduplicatedDecorations: Array<ValueDecoration> = [];
	const values = new Set();
	decorations.forEach((decoration) => {
		const value = tryExtractRatingValue(decoration);
		if (value === undefined || values.has(value)) {
			return;
		}
		values.add(value);
		deduplicatedDecorations.push(decoration);
	});
	return deduplicatedDecorations;
};
