import { createSelector } from 'reselect';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraUsersMapping } from '../../../../field/mapping/users';
import type { PropertyMaps, State } from '../../../types';
import { nullSafeComparator, stringListComparator } from '../../comparators';
import { CSV_SEPARATOR } from '../common/constanst';
import { createStringValueIntersectionFilter } from '../common/filter-utils';
import { removePropertyValue } from '../common/remove-utils';
import type { FieldMapping } from '../types';

export const usersMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<UserFieldValue[]> => {
	const valueAccessor: FieldMapping<UserFieldValue[]>['valueAccessor'] = (state, _, issueId) =>
		state.properties.people[field.key]?.[issueId];

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const value = valueAccessor(state, props, issueId);
		return value?.map((user) => user.displayName).join(CSV_SEPARATOR) || '';
	};

	return {
		...jiraUsersMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: UserFieldValue[]) =>
			set(maps.people, [field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: IssueUserProperty[]) => { people: { [x: string]: Record<string, IssueUserProperty[]> | { [x: string]: IssueUserProperty[] | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: IssueUserProperty[] | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: UserFieldValue[]) => {
			if (maps.people[field.key] && isEqual(maps.people[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				people: {
					...maps.people,
					[field.key]: {
						...maps.people[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'people'),
		modifyImmutableIfMultiValueField: (
			maps: PropertyMaps,
			issueId: LocalIssueId,
			addValues?: UserFieldValue[],
			removeValues: UserFieldValue[] = [],
		) => {
			if (!addValues || addValues.length === 0) {
				return maps;
			}
			const oldValues = (maps.people[field.key] || {})[issueId] || [];
			const newValues = oldValues
				.filter(
					(oldValue) =>
						removeValues.findIndex((removeValue) => oldValue.accountId === removeValue.accountId) <
						0,
				)
				.concat(
					addValues.filter(
						(newValue) =>
							oldValues.findIndex((oldValue) => oldValue.accountId === newValue.accountId) < 0,
					),
				);
			return {
				...maps,
				people: {
					...maps.people,
					[field.key]: {
						...maps.people[field.key],
						[issueId]: newValues,
					},
				},
			};
		},
		comparator: nullSafeComparator<UserFieldValue[]>((a, b, direction) =>
			stringListComparator(
				a.map(({ displayName }) => displayName),
				b.map(({ displayName }) => displayName),
				direction,
			),
		),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) =>
			state.properties.people[field.key] ? state.properties.people[field.key] : {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.people[fieldKey],
				(ids, people) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									people !== undefined && people[issueId] !== undefined
										? people[issueId].map((value) => ({
												groupIdentity: value.accountId,
												value: [value],
											}))
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) =>
			state.properties.people[field.key] !== undefined &&
			state.properties.people[field.key][issueId] !== undefined
				? state.properties.people[field.key][issueId].map((value) => ({
						groupIdentity: value.accountId,
						value: [value],
					}))
				: [],
		getRollupOperations: () => [COUNT_ROLLUP, EMPTY_ROLLUP, FILLED_ROLLUP],
		allowEmptyGroup: true,
		getLabel: (groupIdentity, value) => head(value)?.displayName,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueIntersectionFilter = createStringValueIntersectionFilter(filter);
				if (stringValueIntersectionFilter === undefined) {
					return undefined;
				}
				return (value: undefined | Array<UserFieldValue>, state, props, localIssueId) =>
					stringValueIntersectionFilter(
						value?.map(({ accountId }) => accountId),
						state,
						props,
						localIssueId,
					);
			}
			return undefined;
		},
	};
};
