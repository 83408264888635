import differenceBy from 'lodash/differenceBy';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';

export const createOptionsGroups = (
	selected: OptionProperty[],
	all: OptionProperty[],
	isMulti: boolean,
) => [
	...(isMulti
		? [
				{
					options: selected,
				},
			]
		: []),
	{
		options: differenceBy<OptionProperty, OptionProperty>(all, selected, 'id') ?? [],
	},
];
