import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const deleteGlobalFieldOption = async (
	fieldKey: string,
	contextId: number,
	optionId: string,
): Promise<void> => {
	await performDeleteRequest(
		`/rest/api/3/field/${fieldKey}/context/${contextId}/option/${optionId}`,
	);
};
