import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	commentEdited: {
		id: 'polaris-common.plays.contribution.comment-edited',
		defaultMessage: 'Edited',
		description: 'Indicator whether or not this comment was edited',
	},
	editVoteTooltip: {
		id: 'polaris-common.plays.contribution.edit-vote-tooltip',
		defaultMessage: 'Edit your vote',
		description: 'Text for the edit vote tooltip',
	},
});
