import { createSelector } from 'reselect';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	type SUPPORTED_EXTERNAL_REFERENCE_PROPERTY,
	EXTERNAL_REFERENCE_PROPERTY_TEMPLATE,
} from '@atlassian/jira-polaris-lib-formula/src/utils/formula/external-reference-property/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type {
	ExternalReferencePropertyEntity,
	ExternalReferenceEntity,
} from '../../../../../services/atlas/types';
import type { State } from '../../../types';
import { safeStringListComparator } from '../../comparators';
import {
	getExternalReferencePropertyIdentificator,
	getExternalReferencePropertyLabel,
} from '../../external-reference-property';
import { createStringValueIntersectionFilter } from '../common/filter-utils';
import { defaultMapping } from '../default';
import { externalReferenceMapping, getGroupIdentitiesSelector } from '../external-reference';
import type { FieldMapping, GroupIdentity, GroupIdMap } from '../types';

export type Value = ExternalReferencePropertyEntity | ExternalReferencePropertyEntity[];

const mapPropertyToSort = (
	v: ExternalReferencePropertyEntity,
	property: SUPPORTED_EXTERNAL_REFERENCE_PROPERTY | undefined,
) => {
	switch (property) {
		case 'state':
			return getExternalReferencePropertyIdentificator(v);
		default:
			return undefined;
	}
};

const mapPropertyToLabel = (v: ExternalReferencePropertyEntity, property: string | undefined) => {
	switch (property) {
		case 'state':
			return getExternalReferencePropertyLabel(v);
		default:
			return undefined;
	}
};

const mapEntityToProperty = (v: ExternalReferenceEntity | null, property: string | undefined) => {
	if (!v) {
		return undefined;
	}
	switch (property) {
		case 'state':
			return v.state;
		default:
	}
	return undefined;
};

export const externalReferencePropertyMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
	allFields: Field[],
): FieldMapping<Value> => {
	const { formula } = field;
	if (formula?.template !== EXTERNAL_REFERENCE_PROPERTY_TEMPLATE) {
		return { ...defaultMapping };
	}
	const propertyField = allFields.find(
		(f) => f.key === formula?.parameters?.externalReferenceFieldKey,
	);
	if (!propertyField) {
		return { ...defaultMapping };
	}

	// AtlasGoalDetailsWithUsedFields_state | AtlasProjectDetailstWithUsedFields_state
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const valueAccessor: FieldMapping<any>['valueAccessor'] = (state, props, localIssueId) => {
		const externalReferenceValue = externalReferenceMapping(
			issuesRemote,
			propertyField,
		).valueAccessor(state, props, localIssueId);
		if (!externalReferenceValue) {
			return undefined;
		}
		if (Array.isArray(externalReferenceValue)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return externalReferenceValue
				.map((id) =>
					mapEntityToProperty(
						state.properties.externalReferenceEntities[id],
						formula?.parameters?.property,
					),
				)
				.filter(Boolean) as unknown as ExternalReferencePropertyEntity[];
		}
		const property = mapEntityToProperty(
			state.properties.externalReferenceEntities[externalReferenceValue],
			formula?.parameters?.property,
		);
		if (!property) {
			return undefined;
		}
		return property;
	};

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		localIssueId,
	) => valueAccessor(state, props, localIssueId)?.label || '';

	return {
		...defaultMapping,
		field,
		allowEmptyGroup: true,
		getDependencyValues: (state) => state.properties.externalReferenceEntities,
		comparatorWithMapping(state, props, a, b, direction) {
			let aValues: ExternalReferencePropertyEntity[] = [];
			if (a !== undefined) {
				if (field.configuration?.isMulti) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					aValues = a as ExternalReferencePropertyEntity[];
				} else {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					aValues = [a as ExternalReferencePropertyEntity];
				}
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const aValuesStr = aValues
				.map((v) => mapPropertyToSort(v, formula?.parameters?.property))
				.filter(Boolean) as string[];
			let bValues: ExternalReferencePropertyEntity[] = [];
			if (b !== undefined) {
				if (field.configuration?.isMulti) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					bValues = b as ExternalReferencePropertyEntity[];
				} else {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					bValues = [b as ExternalReferencePropertyEntity];
				}
			}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const bValuesStr = bValues
				.map((v) => mapPropertyToSort(v, formula?.parameters?.property))
				.filter(Boolean) as string[];

			return safeStringListComparator(aValuesStr, bValuesStr, direction);
		},
		valueAccessor,
		valueAccessorToExport,
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				getGroupIdentitiesSelector(propertyField.key, issueIdsSelector),
				(state: State) => state.properties.externalReferenceEntities,
				(groupIdentities, externalReferenceEntities) =>
					Object.keys(groupIdentities).reduce((result, issueId) => {
						const mapped = groupIdentities[issueId]
							.map((groupIdentitity) => {
								const property = mapEntityToProperty(
									externalReferenceEntities[groupIdentitity.groupIdentity],
									formula?.parameters?.property,
								);
								if (!property) {
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									return undefined as unknown as GroupIdentity<Value>;
								}
								return {
									groupIdentity: getExternalReferencePropertyIdentificator(property),
									value: property,
								};
							})
							.filter(Boolean);
						return Object.assign(result, {
							[issueId]: mapped,
						});
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					}, {} as GroupIdMap<Value>),
			),
		getGroupIdentities: (state, props, localIssueId) => {
			const groupIdentities = externalReferenceMapping(
				issuesRemote,
				propertyField,
			).getGroupIdentities(state, props, localIssueId);
			const mapped = groupIdentities
				.map((groupIdentitity) => {
					const property = mapEntityToProperty(
						state.properties.externalReferenceEntities[groupIdentitity.groupIdentity],
						formula?.parameters?.property,
					);
					if (!property) {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						return undefined as unknown as GroupIdentity<Value>;
					}
					return {
						groupIdentity: getExternalReferencePropertyIdentificator(property),
						value: property,
					};
				})
				.filter(Boolean);
			return mapped;
		},
		getLabel: (_, value) => {
			if (Array.isArray(value)) {
				return value.length === 1
					? mapPropertyToLabel(value[0], formula?.parameters?.property)
					: undefined;
			}
			return value !== undefined && mapPropertyToLabel(value, formula?.parameters?.property);
		},
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueIntersectionFilter = createStringValueIntersectionFilter(filter);
				if (stringValueIntersectionFilter === undefined) {
					return undefined;
				}
				return (value: undefined | Value, state, props, localIssueId) => {
					if (value === undefined) {
						return stringValueIntersectionFilter([], state, props, localIssueId);
					}
					const valueAsArray = Array.isArray(value) ? value : [value];
					return stringValueIntersectionFilter(
						valueAsArray.map((v) => getExternalReferencePropertyIdentificator(v)),
						state,
						props,
						localIssueId,
					);
				};
			}
			return undefined;
		},
	};
};
