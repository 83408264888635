import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ACCESS_ROLE_EDITOR_ARI,
	ACCESS_ROLE_VIEWER_ARI,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRoleAri,
	ViewAccessLevel,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { isAccessForbiddenError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	fetchCurrentUserPermissions,
	fetchCurrentUserPermissionsLegacy,
} from '../../../services/jpd-views-service';
import type { State } from '../../types';

export type LoadCurrentUserViewAccessState = 'succeeded' | 'loading' | 'no-access' | 'failed';

export const loadCurrentUserViewAccess =
	(viewUUID: string, reload = false) =>
	async ({
		getState,
		setState,
	}: StoreActionApi<State>): Promise<{
		role: AccessRoleAri | undefined;
		accessLevel: ViewAccessLevel | undefined;
		state: LoadCurrentUserViewAccessState;
	}> => {
		const viewAccessMap = getState().viewAccessMap;

		const currentViewAccess = viewAccessMap[viewUUID];

		if (currentViewAccess && !reload) {
			return {
				role: currentViewAccess.state === 'succeeded' ? currentViewAccess.role : undefined,
				accessLevel:
					currentViewAccess.state === 'succeeded' ? currentViewAccess.accessLevel : undefined,
				state: currentViewAccess.state,
			};
		}

		if (!reload) {
			setState({
				viewAccessMap: {
					...viewAccessMap,
					[viewUUID]: {
						state: 'loading',
					},
				},
			});
		}

		try {
			if (!fg('polaris_view_permissions_dynamic_view_access_icon')) {
				const response = await fetchCurrentUserPermissionsLegacy(viewUUID);

				let role: AccessRoleAri | undefined;
				if (response.read_view) {
					role = ACCESS_ROLE_VIEWER_ARI;
				}
				if (response.edit_view) {
					role = ACCESS_ROLE_EDITOR_ARI;
				}

				if (role === undefined) {
					throw new Error(
						'polaris.view-access.load-current-user-view-access-role: invalid response',
					);
				}

				setState({
					viewAccessMap: {
						...getState().viewAccessMap,
						[viewUUID]: {
							role,
							accessLevel: VIEW_ACCESS_LEVELS.RESTRICTED,
							state: 'succeeded',
						},
					},
				});

				return {
					role,
					accessLevel: undefined,
					state: 'succeeded',
				};
			}

			const response = await fetchCurrentUserPermissions(viewUUID);

			let role: AccessRoleAri | undefined;
			if (response.permissions.read_view) {
				role = ACCESS_ROLE_VIEWER_ARI;
			}
			if (response.permissions.edit_view) {
				role = ACCESS_ROLE_EDITOR_ARI;
			}

			if (role === undefined) {
				throw new Error('polaris.view-access.load-current-user-view-access-role: invalid response');
			}

			setState({
				viewAccessMap: {
					...getState().viewAccessMap,
					[viewUUID]: {
						role,
						accessLevel: response.accessLevel,
						state: 'succeeded',
					},
				},
			});

			return {
				role,
				accessLevel: response.accessLevel,
				state: 'succeeded',
			};

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (isAccessForbiddenError(error)) {
				setState({
					viewAccessMap: {
						...getState().viewAccessMap,
						[viewUUID]: {
							state: 'no-access',
						},
					},
				});

				return {
					role: undefined,
					accessLevel: undefined,
					state: 'no-access',
				};
			}

			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-access.load-current-user-view-access-role', error),
			);

			setState({
				viewAccessMap: {
					...getState().viewAccessMap,
					[viewUUID]: {
						state: 'failed',
					},
				},
			});

			return {
				role: undefined,
				accessLevel: undefined,
				state: 'failed',
			};
		}
	};
