import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import CheckIcon from '@atlaskit/icon/glyph/check';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import WorldIcon from '@atlaskit/icon/glyph/world';
import type { TriggerProps } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { R500, G200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { ButtonWithTooltip } from '@atlassian/jira-polaris-lib-button-with-tooltip/src/ui/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

type PublishButtonProps = {
	isSelected: boolean;
	isMisconfigured?: boolean;
	onClick: (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	triggerProps: TriggerProps;
	isPublished: boolean;
	isPublic: boolean;
};

export const PublishButton = ({
	isSelected,
	onClick,
	triggerProps,
	isPublished,
	isPublic,
	isMisconfigured,
}: PublishButtonProps) => {
	const { formatMessage } = useIntl();

	const getIconBefore = () => {
		if (isPublished && isMisconfigured) {
			return <ErrorIcon label="" primaryColor={token('color.icon.danger', R500)} />;
		}

		if (ff('polaris.published-view-public-links_dj5gi')) {
			if (isPublished && isPublic) {
				return <WorldIcon label="" />;
			}
		}

		if (isPublished) {
			return ff('polaris.publish-icon-replacement_j57gg') ? (
				// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
				<PresenceActiveIcon
					size="small"
					primaryColor={token('color.background.accent.green.subtle', G200)}
					label=""
				/>
			) : (
				<CheckIcon label="" />
			);
		}
		return undefined;
	};

	return (
		<ButtonWrapper
			isPublished={isPublished}
			isSelected={isSelected}
			isMisconfigured={isMisconfigured}
		>
			<ButtonWithTooltip
				id="pendo.publish-modal.open-button"
				testId="polaris-lib-control-sharing.ui.publish-button"
				{...triggerProps}
				isSelected={isSelected}
				onClick={onClick}
				iconBefore={getIconBefore()}
				tooltipProps={{
					content:
						ff('polaris.published-view-public-links_dj5gi') &&
						isPublished &&
						isPublic &&
						formatMessage(messages.publicAccessTooltip),
				}}
			>
				{formatMessage(isPublished ? messages.publishedButton : messages.publishButton)}
			</ButtonWithTooltip>
		</ButtonWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div<{
	isPublished?: boolean;
	isSelected?: boolean;
	isMisconfigured?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& button': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingLeft: ({ isPublished }) => (isPublished ? token('space.075', '6px') : undefined),
		/* eslint-disable no-nested-ternary */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: ({ isPublished, isSelected, isMisconfigured }) =>
			isPublished && !isSelected && !isMisconfigured
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.accent.blue.subtler', colors.B50)
				: isPublished && isMisconfigured
					? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						token('color.background.danger', colors.R50)
					: undefined,

		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			background: ({ isSelected, isMisconfigured, isPublished }) =>
				isMisconfigured && isPublished
					? !isSelected
						? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							token('color.background.danger.hovered', colors.R50)
						: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							token('color.background.danger', colors.R50)
					: undefined,
		},
		/* eslint-enable no-nested-ternary */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& span': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			color: ({ isMisconfigured, isPublished }) =>
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				isMisconfigured && isPublished ? token('color.text', colors.N800) : undefined,
		},
	},
});
