import React, { Component, createRef, type MouseEvent, type Ref } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { token } from '@atlaskit/tokens';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { ISSUE_HIERARCHY_LEVEL_BASE } from '@atlassian/jira-issue-type-hierarchies';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types';
import BreadcrumbAddParent from './add-parent';
import BreadcrumbCurrentIssue from './current-issue';
import messages from './messages';
import BreadcrumbParentIssue from './parent-issue';
import BreadcrumbProject from './project';
import {
	BreadcrumbsWrapper,
	SmallBreadcrumbsItem,
	BREADCRUMBS_WRAPPER_SELECTOR_NAME,
} from './styled';
import type { IssueBreadcrumbsProps as Props } from './types';

// eslint-disable-next-line jira/react/no-class-components
export class IssueBreadcrumbs extends Component<Props> {
	static displayName = 'IssueBreadcrumbs';

	static defaultProps = {
		isSimplifiedProject: false,
		isLoadingComplete: false,
		issueTypeName: '',
		issueTypeIconUrl: '',
		issueHierarchyLevel: 0,
		shouldShowRootProjectsBreadcrumb: false,
		error: null,
		parentIssues: [],
		onCopyClick: noop,
		onExperienceSuccess: noop,
	};

	componentDidMount() {
		this.props.onExperienceSuccess();
	}

	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.parentIssues.length > 0 !== this.props.parentIssues.length > 0 &&
			this.isFocusReturnToTrigger
		) {
			// Set the focus back to trigger while view changing between add parent to edit parent buttons
			requestAnimationFrame(() => {
				this.parentTriggerRef.current?.focus();
				this.isFocusReturnToTrigger = false;
			});
		} else {
			this.isFocusReturnToTrigger = false;
		}
	}

	parentTriggerRef = createRef<HTMLElement | null>();

	isFocusReturnToTrigger = false;

	onClick = (event: MouseEvent) => {
		!__SERVER__ && this.props.onClick(event);
	};

	onProjectClick = (event: MouseEvent) => {
		if (event.ctrlKey || event.metaKey || event.shiftKey) {
			return;
		}
		event.preventDefault();

		const { projectUrl, onProjectClick } = this.props;
		!__SERVER__ && projectUrl && onProjectClick(projectUrl, this.props.pushAction);
	};

	onParentClick =
		(parentIssueKey: string) => (event: MouseEvent, analyticsEvent?: UIAnalyticsEvent) => {
			if (event.ctrlKey || event.metaKey || event.shiftKey) {
				return;
			}

			event.preventDefault();
			const { onParentClick, issueKey } = this.props;
			const parentKey = toIssueKey(parentIssueKey);
			!__SERVER__ &&
				onParentClick({
					childIssueKey: issueKey,
					parentIssueKey: parentKey,
					onIssueKeyChange: this.props.onIssueKeyChange,
				});
			if (analyticsEvent) {
				fireUIAnalytics(analyticsEvent, 'breadcrumbsParentIssue clicked');
			} else {
				fireErrorAnalytics({
					error: new Error('analyticsEvent is required'),
					meta: {
						id: 'breadcrumbs',
						/* eslint-disable-next-line jira/js/package-name-enforcer-for-error-analytics */
						packageName: 'jiraIssueView',
						teamName: 'bento',
					},
				});
			}
		};

	onRootBreadcrumbClick = (event: MouseEvent) => {
		if (event.ctrlKey || event.metaKey || event.shiftKey) {
			return;
		}

		const { onRootBreadcrumbClick } = this.props;

		!__SERVER__ && onRootBreadcrumbClick();
	};

	onAddOrRemoveParent = () => {
		this.isFocusReturnToTrigger = true;
	};

	render() {
		const {
			error,
			isSimplifiedProject,
			isLoadingComplete,
			shouldShowProjectLevelBreadcrumb,
			shouldShowRootProjectsBreadcrumb,
			projectName,
			projectUrl,
			projectAvatarUrl,
			parentIssues,
			isIssueTypeFieldEditable,
			issueKey,
			issueTypeName,
			issueTypeIconUrl,
			relativeIssueUrl,
			fullIssueUrl,
			onCopyClick,
			intl: { formatMessage },
			canLinkToParent,
			issueHierarchyLevel,
			parentLevelIssueTypeNames,
			isEditable,
		} = this.props;

		const hasParents = parentIssues.length > 0;
		const isIssueParentSwitcherReady =
			!__SERVER__ && canLinkToParent && error === null && isLoadingComplete;

		const isParentEditable = isEditable !== undefined ? isEditable : true;

		const showIssueParentSwitcher =
			isParentEditable &&
			((isSimplifiedProject && issueHierarchyLevel === ISSUE_HIERARCHY_LEVEL_BASE) ||
				!isSimplifiedProject) &&
			isIssueParentSwitcherReady;

		const extraProps = {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			triggerRef: this.parentTriggerRef as Ref<HTMLElement>,
			onAddOrRemoveParent: this.onAddOrRemoveParent,
		};

		return (
			<BreadcrumbsWrapper data-component-selector={BREADCRUMBS_WRAPPER_SELECTOR_NAME}>
				<Breadcrumbs maxItems={5} label={formatMessage(messages.breadcrumbNavLabel)}>
					{shouldShowRootProjectsBreadcrumb && (
						<BreadcrumbsProjectsContainer>
							<BreadcrumbsItem
								/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
								testId="issue.views.issue-base.foundation.breadcrumbs.item"
								href="/jira/projects"
								text={formatMessage(messages.rootProjectsLink)}
								onClick={this.onRootBreadcrumbClick}
								component={SmallBreadcrumbsItem}
							/>
						</BreadcrumbsProjectsContainer>
					)}
					<BreadcrumbProject
						shouldShowProjectLevelBreadcrumb={shouldShowProjectLevelBreadcrumb}
						projectName={projectName}
						projectUrl={projectUrl}
						projectAvatarUrl={projectAvatarUrl}
						onClick={this.onProjectClick}
					/>
					{hasParents
						? parentIssues.map((parentIssue, index) => (
								<BreadcrumbParentIssue
									key={index}
									isSimplifiedProject={isSimplifiedProject}
									isDisabled={!isParentEditable || !isIssueParentSwitcherReady}
									parentIssue={parentIssue}
									currentIssueKey={issueKey}
									issueHierarchyLevel={issueHierarchyLevel}
									onClick={this.onParentClick(parentIssue ? parentIssue.key : '')}
									{...extraProps}
								/>
							))
						: showIssueParentSwitcher && (
								<BreadcrumbAddParent
									isSimplifiedProject={isSimplifiedProject}
									issueTypeName={parentLevelIssueTypeNames[0]}
									issueHierarchyLevel={issueHierarchyLevel}
									{...extraProps}
								/>
							)}
					<BreadcrumbCurrentIssue
						isSimplifiedProject={isSimplifiedProject}
						isIssueTypeFieldEditable={__SERVER__ ? false : isIssueTypeFieldEditable}
						issueKey={issueKey}
						issueTypeName={issueTypeName}
						issueTypeIconUrl={issueTypeIconUrl}
						relativeIssueUrl={relativeIssueUrl}
						fullIssueUrl={fullIssueUrl}
						onClick={this.onClick}
						onCopyClick={onCopyClick}
					/>
				</Breadcrumbs>
			</BreadcrumbsWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsProjectsContainer = styled.div({
	display: 'flex',
	marginRight: token('space.025', '2px'),
	alignItems: 'center',
	paddingLeft: token('space.050', '4px'),
	marginLeft: token('space.negative.050', '-4px'),
	'&::after': {
		display: 'flex',
		content: '/',
		flexShrink: 0,
		justifyContent: 'center',
		width: '8px',
		padding: `0 ${token('space.050', '4px')}`,
		font: token('font.body'),
	},
});
