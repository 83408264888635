import React, { useState, useMemo, useCallback } from 'react';
import { css, keyframes, styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji';
import AddIcon from '@atlaskit/icon/glyph/add';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { IdeaTemplate } from '@atlassian/jira-polaris-common/src/common/types/idea-templates/types';
import { useSubmitIdeaFormDescriptionTemplate } from '@atlassian/jira-polaris-common/src/controllers/project-properties';
import { N0 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import { DeleteTemplateModal } from '../common/delete-modal';
import { IdeaTemplateModal } from '../common/template-modal';
import { UpdateTemplateForm } from '../template-form';
import { Menu } from './menu';
import { messages } from './messages';

type IdeaTemplateProps = {
	data: IdeaTemplate;
	onUpdate?: ((data: IdeaTemplate) => void) | undefined;
	onApplyTemplate: (template: ADF | undefined, applyAfterUpdate?: boolean) => void;
	onDelete?: ((id: string) => void) | undefined;
	inSidebar?: boolean;
};

export const IdeaTemplateComponent = ({
	data,
	onUpdate,
	onApplyTemplate,
	onDelete,
	inSidebar,
}: IdeaTemplateProps) => {
	const { id, title, description, color, emoji, template } = data;
	const descriptionTemplateId = useSubmitIdeaFormDescriptionTemplate();
	const { formatMessage } = useIntl();
	const [animated, setAnimated] = useState(false);
	const selectedEmoji = useEmoji(emoji);

	const [isHovered, setHovered] = useState(false);
	const [isOpenModal, setOpenModal] = useState(false);
	const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

	const closeDeleteModal = useCallback(() => setOpenDeleteModal(false), []);
	const onAnimationEnd = useCallback(() => setAnimated(false), []);

	const isAppliedTemplate = useMemo(
		() => !inSidebar && descriptionTemplateId.value === id,
		[descriptionTemplateId.value, id, inSidebar],
	);

	const onUpdateTemplate = useCallback(
		(updated: IdeaTemplate) => {
			setAnimated(true);
			onUpdate && onUpdate(updated);
			isAppliedTemplate && onApplyTemplate(template, true);
		},
		[onUpdate, isAppliedTemplate, onApplyTemplate, template],
	);

	const triggerContent = useCallback(
		(triggerProps?: TriggerProps) => (
			<Template {...triggerProps} data-component-selector="template-bR3m">
				<IconContainer bgColor={color ?? token('color.text.brand', colors.B300)}>
					{selectedEmoji !== undefined ? (
						<Emoji emoji={selectedEmoji} fitToHeight={32} />
					) : (
						// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
						<DocumentsIcon primaryColor={N0} label="default_template" size="large" />
					)}
				</IconContainer>
				<TemplateInfoContainer>
					<Title>{title || formatMessage(messages.defaultTemplateTitle)}</Title>
					<Description isEmpty={description === undefined || description === null}>
						{description ?? formatMessage(messages.defaultTemplateDescription)}
					</Description>
				</TemplateInfoContainer>
				<Menu
					isAppliedTemplate={isAppliedTemplate}
					onApply={() => onApplyTemplate(template)}
					onEdit={onUpdate ? () => setOpenModal(true) : undefined}
					onDelete={onDelete ? () => setOpenDeleteModal(true) : undefined}
				/>
			</Template>
		),
		[
			color,
			description,
			formatMessage,
			isAppliedTemplate,
			onApplyTemplate,
			onDelete,
			onUpdate,
			selectedEmoji,
			template,
			title,
		],
	);

	return (
		<>
			{onUpdate && isOpenModal && (
				<UpdateTemplateForm
					templateData={data}
					onUpdate={onUpdateTemplate}
					onClose={() => setOpenModal(false)}
				/>
			)}
			{onDelete && isOpenDeleteModal && (
				<DeleteTemplateModal title={title} id={id} onClose={closeDeleteModal} onDelete={onDelete} />
			)}
			<TemplateContainer
				onAnimationEnd={onAnimationEnd}
				animated={animated}
				isSelected={isAppliedTemplate}
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				onClick={(e) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const ariaLabel = (e.target as HTMLElement).getAttribute('aria-label');
					// Prevent applying template when clicking on the menu button
					if (ariaLabel !== 'more') {
						onApplyTemplate(template);
					}
				}}
			>
				<Popup
					isOpen={!!inSidebar && isHovered}
					content={() => (
						<IdeaTemplateModal color={color} emoji={emoji} template={template} title={title} />
					)}
					placement="left-start"
					trigger={triggerContent}
					// Typeahead popup in the editor have z-index of modal (510), so templates preview should have higher z-index to be visible
					zIndex={layers.modal + 1}
				/>
			</TemplateContainer>
		</>
	);
};

export const AddIdeaTemplateComponent = ({ onCreateClick }: { onCreateClick: () => void }) => {
	const { formatMessage } = useIntl();

	return (
		<TemplateContainer isNewTemplate>
			<Template>
				<IconContainer isNewTemplate onClick={onCreateClick}>
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
					<AddIcon
						size="large"
						label="create template"
						primaryColor={token('color.text.brand', colors.B300)}
					/>
				</IconContainer>
				<TemplateInfoContainer>
					<Title isNewTemplate>{formatMessage(messages.createTemplateTitle)}</Title>
					<Description>{formatMessage(messages.createTemplateDescription)}</Description>
				</TemplateInfoContainer>
			</Template>
		</TemplateContainer>
	);
};

const animationKeyFrames = keyframes({
	'0%': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.selected', colors.B75),
	},
	'100%': {
		backgroundColor: 'white',
	},
});

const animationStyles = css({
	animation: `${animationKeyFrames} 3s 1`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Template = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	overflow: 'hidden !important',
	alignItems: 'center',
	justifyContent: 'space-between',
	boxSizing: 'border-box',
	margin: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div<{ isNewTemplate?: boolean; bgColor?: string }>({
	minWidth: '64px',
	width: '64px',
	height: '64px',
	borderRadius: '6px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isNewTemplate, bgColor }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isNewTemplate ? token('color.background.neutral.subtle.hovered', colors.N30) : bgColor,
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isNewTemplate }) =>
		isNewTemplate
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`1px dashed ${token('color.border.brand', colors.B300)}`
			: '1px solid transparent',
	'&:hover': {
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateInfoContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	width: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div<{ isNewTemplate?: boolean }>({
	display: '-webkit-box',
	WebkitLineClamp: '1',
	WebkitBoxOrient: 'vertical',
	width: '100%',
	overflow: 'hidden',
	fontWeight: 600,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '16px',
	lineHeight: '20px',
	marginBottom: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isNewTemplate }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isNewTemplate ? token('color.text.brand', colors.B300) : token('color.text', colors.N600),
	textOverflow: 'ellipsis',
	overflowWrap: 'break-word',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div<{ isEmpty?: boolean }>({
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	overflowWrap: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isEmpty }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isEmpty ? token('color.text', colors.N300) : token('color.text', colors.N600),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateContainer = styled.div<{
	animated?: boolean;
	isNewTemplate?: boolean;
	isSelected?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ animated }) => animated && animationStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isNewTemplate }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			!isNewTemplate ? token('color.background.neutral.subtle.hovered', colors.N20) : undefined,
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isSelected }) =>
		isSelected ? token('color.background.selected', '#E9F2FF') : undefined,
	borderRadius: '4px',
	padding: `${token('space.050', '4px')} 0`,
});
