export function cleanupPath(path: string) {
	const pathParts = path.split('/');
	return pathParts
		.map((part) => {
			if (/[A-Z]{2,}/.test(part)) {
				return '{param}';
			}
			return part === '' ||
				/^[a-z]+-[a-z]+$/.test(part) ||
				/^[a-zA-Z]+\d?$/.test(part) ||
				/^\d$/.test(part)
				? part
				: '{param}';
		})
		.join('/');
}

const getUrlPath = (urlOrRequset: string | URL | Request): string => {
	let url: undefined | URL;
	try {
		if (typeof urlOrRequset === 'string' && urlOrRequset.startsWith('http')) {
			url = new URL(urlOrRequset);
		} else if (urlOrRequset instanceof URL) {
			url = urlOrRequset;
		} else if (urlOrRequset instanceof Request) {
			url = new URL(urlOrRequset.url);
		}
	} catch {
		// do nothing
	}
	if (url) {
		return url.pathname;
	}
	if (typeof urlOrRequset === 'string') {
		return removeQueryParams(urlOrRequset);
	}
	return 'unknown';
};

const removeQueryParams = (url: string) => url.split('?')[0];

export const getMethod = (urlOrRequset: string | URL | Request, params?: RequestInit): string =>
	((urlOrRequset instanceof Request ? urlOrRequset.method : params?.method) || 'GET').toUpperCase();

export const getCleanedPath = (
	urlOrRequset: string | URL | Request,
): { originPath: string; path: string } => {
	const path = getUrlPath(urlOrRequset);
	return {
		originPath: path,
		path: cleanupPath(path),
	};
};
