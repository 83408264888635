import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useGetIntervalFieldsRollup } from '../../../../controllers/issue/selectors/fields-rollup-hooks';
import { Wrapper } from '../../styled';
import type { Props } from '../../types';
import { messages } from '../messages';

export const IntervalFieldGroupedMax = ({ fieldKey, groupId, groupFieldKey }: Props) => {
	const { formatMessage } = useIntl();
	const val = useGetIntervalFieldsRollup(fieldKey, groupFieldKey, groupId);
	const emptyMessage = formatMessage(messages.empty);

	return (
		<Wrapper>{formatMessage(messages.maxVal, { val: `${val?.max ?? emptyMessage}` })}</Wrapper>
	);
};
