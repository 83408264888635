import React, { type ComponentPropsWithoutRef, type FC } from 'react';
import OriginalAppBase from '@atlassian/jira-app-base';
import { Provider } from '@atlassian/jira-issue-view-react-redux';

const AppBase: FC<ComponentPropsWithoutRef<typeof OriginalAppBase>> = ({
	children,
	store,
	...props
}) => (
	<OriginalAppBase {...props}>
		{store ? <Provider store={store}>{children}</Provider> : children}
	</OriginalAppBase>
);

export default AppBase;
