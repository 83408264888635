import React, { type PropsWithChildren, useState, useCallback } from 'react';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import { Box, Flex, xcss } from '@atlaskit/primitives';

type SectionProps = {
	title: string;
	defaultExpanded?: boolean;
};

export const Section = ({ defaultExpanded, title, children }: PropsWithChildren<SectionProps>) => {
	const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

	const handleSectionToggle = useCallback(() => {
		setIsExpanded((prevIsExpanded) => !prevIsExpanded);
	}, [setIsExpanded]);

	return (
		<Box>
			<Box onClick={handleSectionToggle} xcss={sectionHeaderStyles}>
				<Flex alignItems="center" gap="space.050">
					{isExpanded ? <HipchatChevronDownIcon label="" /> : <ChevronRightLargeIcon label="" />}
					{title}
				</Flex>
			</Box>
			{isExpanded && <Box>{children}</Box>}
		</Box>
	);
};

const sectionHeaderStyles = xcss({
	cursor: 'pointer',
	marginBottom: 'space.150',
	fontWeight: '600',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});
