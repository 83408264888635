import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import {
	type ViewDraft,
	ViewLayoutType,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { getTimelineConfigDraft } from '../../../actions/utils/autosave';
import type { Props, State } from '../../../types';
import { getCurrentView } from '../current';
import { getCanManageCurrentView } from '../permissions';

export const isCurrentViewAutosaveEnabled = createSelector(
	getCurrentView,
	(state: State, props: Props | undefined) => !!props?.hasManageViewsPermission,
	(state: State, props: Props | undefined) => props?.isSharedView,
	getCanManageCurrentView,
	(view, hasManageViewsPermission, isSharedView, hasManageCurrentViewPermission) =>
		!!view?.isAutosaveEnabled &&
		!isSharedView &&
		(ff('polaris.view-permissions_plaoi')
			? hasManageCurrentViewPermission
			: hasManageViewsPermission),
);

export const getCurrentViewDraft = createSelector(getCurrentView, (view) => view?.draft);

export const isDraftForConfigsChanged = (configurations: (keyof ViewDraft)[]) =>
	createSelector(
		getCurrentView,
		getCurrentViewDraft,
		isCurrentViewAutosaveEnabled,
		(view, draft, isAutosaveEnabled) =>
			view !== undefined &&
			draft !== undefined &&
			!isAutosaveEnabled &&
			configurations.some((configuration) => !isEqual(draft[configuration], view[configuration])),
	);

export const hasVerticalGroupByUnsavedChanges = isDraftForConfigsChanged([
	'verticalGroupBy',
	'verticalGroupValues',
	'hideEmptyGroups',
]);

export const hasGroupByUnsavedChanges = isDraftForConfigsChanged([
	'groupBy',
	'groupValues',
	'hideEmptyColumns',
]);

export const hasFieldRollupUnsavedChanges = isDraftForConfigsChanged(['fieldRollups']);

export const hasFiltersUnsavedChanges = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) => {
		if (fg('polaris_just-for-you')) {
			return !!view?.temporaryData?.filter?.length;
		}

		return (
			view !== undefined &&
			draft !== undefined &&
			!isAutosaveEnabled &&
			!isEqual(
				view.filter.filter((f) => !(f.type === 'TEXT' && f.localId === 'quick')),
				draft.filter?.filter((f) => !(f.type === 'TEXT' && f.localId === 'quick')),
			)
		);
	},
);

export const hasSortUnsavedChanges = isDraftForConfigsChanged(['sortBy', 'sortMode']);

export const hasSummaryCardFieldUnsavedChanges = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		view !== undefined &&
		draft !== undefined &&
		!isAutosaveEnabled &&
		view.kind === VIEW_KIND_TIMELINE &&
		view.timelineConfig?.summaryCardField?.key !== draft.timelineConfig?.summaryCardField?.key,
);

export const hasFieldsUnsavedChanges = createSelector(
	isDraftForConfigsChanged(['fields', 'hidden']),
	hasSummaryCardFieldUnsavedChanges,
	(fieldsUnsavedChanges, summaryCardFieldUnsavedChanges) =>
		fieldsUnsavedChanges || summaryCardFieldUnsavedChanges,
);

const isDraftForMatrixAxisChanged = (dimension: 'x' | 'y' | 'z') =>
	createSelector(
		getCurrentView,
		getCurrentViewDraft,
		isCurrentViewAutosaveEnabled,
		(view, draft, isAutosaveEnabled) =>
			view !== undefined &&
			draft !== undefined &&
			!isAutosaveEnabled &&
			!isEqual(
				view.matrixConfig?.axes.find((axis) => axis.dimension === dimension),
				draft.matrixConfig?.axes.find((axis) => axis.dimension === dimension),
			),
	);

export const hasMatrixXAxisUnsavedChanges = isDraftForMatrixAxisChanged('x');
export const hasMatrixYAxisUnsavedChanges = isDraftForMatrixAxisChanged('y');
export const hasMatrixZAxisUnsavedChanges = isDraftForMatrixAxisChanged('z');

export const hasTimelineConfigUnsavedChanges = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) => {
		const { summaryCardField, ...config } = getTimelineConfigDraft(view?.timelineConfig);
		const { summaryCardField: draftSummaryCardField, ...draftConfig } = getTimelineConfigDraft(
			draft?.timelineConfig,
		);
		return (
			view !== undefined &&
			draft !== undefined &&
			!isAutosaveEnabled &&
			!isEqual(getTimelineConfigDraft(config), getTimelineConfigDraft(draftConfig))
		);
	},
);

export const hasLayoutTypeUnsavedChanges = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) =>
		view !== undefined &&
		draft !== undefined &&
		!isAutosaveEnabled &&
		(view.layoutType === null
			? view.layoutType !== draft.layoutType &&
				(view.kind === VIEW_KIND_TIMELINE
					? draft.layoutType !== ViewLayoutType.SUMMARY
					: draft.layoutType !== ViewLayoutType.DETAILED)
			: view.layoutType !== draft.layoutType),
);

export const hasViewUnsavedChanges = createSelector(
	isCurrentViewAutosaveEnabled,
	hasVerticalGroupByUnsavedChanges,
	hasGroupByUnsavedChanges,
	hasFiltersUnsavedChanges,
	hasSortUnsavedChanges,
	hasFieldsUnsavedChanges,
	hasMatrixXAxisUnsavedChanges,
	hasMatrixYAxisUnsavedChanges,
	hasMatrixZAxisUnsavedChanges,
	hasTimelineConfigUnsavedChanges,
	hasFieldRollupUnsavedChanges,
	hasLayoutTypeUnsavedChanges,
	(isAutosaveEnabled, ...unsavedChanges) => !isAutosaveEnabled && unsavedChanges.some(Boolean),
);
