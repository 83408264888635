import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	link: {
		id: 'polaris-ideas.idea-view.watchers.add-watchers.link',
		defaultMessage: 'Add watchers',
		description:
			'Indicate to users that when the link is clicked, the add watchers user-picker will be displayed.',
	},
	inputPlaceholder: {
		id: 'polaris-ideas.idea-view.watchers.add-watchers.input-placeholder',
		defaultMessage: 'Add watchers',
		description:
			'Placeholder text displayed in the user picker field used to select users to be added to the watcher list.',
	},
	noUsersFound: {
		id: 'polaris-ideas.idea-view.watchers.add-watchers.no-users-found',
		defaultMessage: 'No users match your search',
		description: 'Text displayed in the user-picker when the user search returns empty.',
	},
});
