import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/defer';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { log } from '@atlassian/jira-common-util-logging';
import { fetchJson$ } from '@atlassian/jira-fetch';
import {
	transformAggResponseToLegacyGira,
	transformAggResponseToLegacyGraphql,
} from '@atlassian/jira-issue-agg-field-transformers';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types';
import { ISSUE_VIEW_INTERACTIVE_QUERY } from '@atlassian/jira-issue-fetch-services-common';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { withRetries2 } from '@atlassian/jira-issue-view-common-utils';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors';
import {
	baseUrlSelector,
	cloudIdSelector,
	issueKeySelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types';
import { isGiraAGGConsistencyCheckEnabled } from '../feature-flags';
import { fetchAggData } from './agg';
import { fetchAllGiraData, fetchDynamicGiraData } from './gira';
import { type CombinedData, combineResponseData } from './gira/agg';
import type { DynamicIssueQueryParams } from './gira/graphql';
import { transformIssueLinks } from './issue-links-transformer';
import { getIssueLinksUrl } from './issue-urls';
import { fetchBulkIssues } from './issues-bulk-fetch-server';

export const fetchDynamicAppDataWithRetries = (
	state: State,
	dynamicIssueQueueParams?: DynamicIssueQueryParams,
) => {
	const baseUrl = baseUrlSelector(state);
	const issueKey = issueKeySelector(state);
	const cloudId = cloudIdSelector(state);

	// withRetries2 is not working here properly since we are not passing a function ref to the retry but rather a converted promise
	// more details here: https://github.com/ReactiveX/rxjs/issues/1596
	// this code will be obsoleted with release of relay so we won't attempt o fix this
	return Observable.zip(
		...withRetries2(
			Observable.fromPromise(fetchDynamicGiraData(baseUrl, issueKey, dynamicIssueQueueParams)),
			Observable.fromPromise(fetchAggData(issueKey, cloudId)),
		),
		(giraData, aggData) => {
			const aggLegacyGraphqlData = transformAggResponseToLegacyGraphql(aggData);

			if (!aggLegacyGraphqlData) {
				// The transformers did not return anything - this happens if the data is missing for some reason
				// We should never fall into this case but its here for type checking
				const message =
					'Failed to transform issue data from AGG, transformers returned empty value';
				throw new Error(message);
			}

			return {
				...giraData,
				...aggLegacyGraphqlData,
			};
		},
	);
};

/**
 * Get a promise out of the given {@param prefetchedResourceManager} and clear it from the resourceManager.
 * It's necessary to do a destructive read in a retryable scenario, to prevent re-using a failed response and instead kick off a fresh fetch
 */
const getAndResetPrefetchedPromise = <TKey extends 'issueGiraData' | 'issueAggData'>(
	prefetchedResourceManager: ResourceManager | undefined,
	promiseKey: TKey,
) => {
	const prefetchedResource = prefetchedResourceManager?.[promiseKey];
	// eslint-disable-next-line no-param-reassign
	prefetchedResourceManager && (prefetchedResourceManager[promiseKey] = null);
	return prefetchedResource;
};

const getStatusCodeFromErrorIfPresent = (error: Error | IssueViewFetchError | undefined) =>
	error && 'statusCode' in error && error.statusCode && error.statusCode;

/**
 * To be used in an {@code Observable.retryWhen}
 * Will check if the error is retryable with the given {@param isErrorRetryable} and if so, will retry up to the given {@param retryLimit} times (defaults to 1)
 */
const retryRequestIf = (
	isErrorRetryable: (error: Error) => boolean,
	codes: number[],
	onFailure: (codes: number[]) => void,
	timeout: [min: number, max: number] = [100, 1000],
	retryLimit = 1,
) => {
	let retries = 0;
	return (errors: Observable<Error>) =>
		errors.mergeMap((error) => {
			codes.push(getStatusCodeFromErrorIfPresent(error) || 418 /* not a teapot */);
			if (isErrorRetryable(error) && retries < retryLimit) {
				retries += 1;
				return Observable.of(retries).delay(
					// use a random delay to reduce pressure on the network and prevent concurrent requests
					// the top boundary is set to 1s and should be decreased once our understanding on retry-ability improves
					timeout[0] + Math.random() * (timeout[1] - timeout[0]),
				);
			}
			onFailure(codes);
			return Observable.throw(error);
		});
};

/**
 * Returns a boolean if the statuscode is one that indicates a retry might be helpful
 */
const isStatusCodeTransientError = (statusCode: number) => {
	if (statusCode === 429 || statusCode >= 500) {
		// jira is too busy, or server error
		return true;
	}
	return false;
};

const isIssueViewFetchErrorRetryable = (error: Error | IssueViewFetchError | undefined) =>
	!!(
		error &&
		'statusCode' in error &&
		error.statusCode &&
		isStatusCodeTransientError(error.statusCode)
	);

const onFail = (type: 'gira' | 'agg') => (codes: number[]) => {
	// is any error was isIssueViewFetchErrorRetryable
	if (codes.length >= 2) {
		log.safeInfoWithoutCustomerData(
			`jira-issue-view-services.fetchAllAppDataWithRetries:${type}`,
			codes[0] !== codes[1]
				? 'retryRequestIf: failed with different codes'
				: 'retryRequestIf: failed with 2 retries',
			{
				code1: codes[0],
				code2: codes[1],
			},
		);
	}
};
const checkRetryCodes = (type: 'gira' | 'agg', codes: number[]) => {
	// if code present, then retry DID HELP mitigating the problem
	if (codes.length > 0) {
		log.safeInfoWithoutCustomerData(
			`jira-issue-view-services.fetchAllAppDataWithRetries:${type}`,
			'retryRequestIf: passed after failure',
			{
				code1: codes[0],
			},
		);
	}
};

// Fetches data needed to initialize the app with Gira.
export const fetchAllAppDataWithRetries = (
	state: State,
	prefetchResourceManager?: ResourceManager,
	issueContextActions?: IssueContextServiceActions,
): Observable<CombinedData> => {
	const baseUrl = baseUrlSelector(state);
	const issueKey = issueKeySelector(state);
	const projectKey = projectKeySelector(state);

	const giraData$ = Observable.defer(() =>
		fetchAllGiraData(
			baseUrl,
			issueKey,
			getAndResetPrefetchedPromise(prefetchResourceManager, 'issueGiraData'), // resets the prefetched promise so it's not re-used in a retry scenario
			isGiraAGGConsistencyCheckEnabled() ? issueContextActions : undefined,
		),
	).catch((error): ReturnType<typeof fetchAllGiraData> => {
		throw new IssueViewFetchError(error, ISSUE_VIEW_INTERACTIVE_QUERY);
	}); // This will go away once all data has been migrated from gira

	const cloudId = cloudIdSelector(state);

	const aggData$ = Observable.defer(() =>
		fetchAggData(
			issueKey,
			cloudId,
			getAndResetPrefetchedPromise(prefetchResourceManager, 'issueAggData'), // resets the prefetched promise so it's not re-used in a retry scenario
		),
	);

	const giraCodes: number[] = [];
	const aggCodes: number[] = [];

	const dataObservablesWithRetries = [
		giraData$.retryWhen(retryRequestIf(isIssueViewFetchErrorRetryable, giraCodes, onFail('gira'))),
		aggData$.retryWhen(retryRequestIf(isIssueViewFetchErrorRetryable, aggCodes, onFail('agg'))),
	] as const;

	return Observable.zip(...dataObservablesWithRetries, (giraData, aggData) => {
		checkRetryCodes('gira', giraCodes);
		checkRetryCodes('agg', aggCodes);

		const aggLegacyGraphqlData = transformAggResponseToLegacyGraphql(aggData);
		const aggLegacyGiraData = transformAggResponseToLegacyGira(aggData, projectKey);

		if (!aggLegacyGraphqlData) {
			// The transformers did not return anything - this happens if the data is missing for some reason
			// We should never fall into this case but its here for type checking
			const message = 'Failed to transform issue data from AGG, transformers returned empty value';
			throw new Error(message);
		}

		if (isGiraAGGConsistencyCheckEnabled()) {
			issueContextActions?.mergeIssueContext({
				aggResponse: cloneDeep(aggData),
			});
		}

		return combineResponseData(aggLegacyGraphqlData, aggLegacyGiraData, giraData);
	});
};

export const fetchIssueLinks = (baseUrl: BaseUrl, issueKey: IssueKey) =>
	fetchJson$(getIssueLinksUrl(baseUrl, issueKey)).map(
		(response) =>
			// @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
			response.fields.issuelinks && transformIssueLinks(baseUrl, response.fields.issuelinks),
	);

export const fetchIssueLinksProjectType = (issueKeys: IssueKey[]) =>
	fetchBulkIssues('', issueKeys, ['project'])
		.map((response) => response.map(({ fields }) => fields?.project?.projectTypeKey))
		.toPromise();
