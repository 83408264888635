import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-view-empty-state.views.collection-view-no-projects.header',
		defaultMessage: "You don't have projects in this roadmap",
		description: 'Header of the roadmap list view with no projects hint',
	},
	description: {
		id: 'polaris-lib-view-empty-state.views.collection-view-no-projects.description',
		defaultMessage: 'Add projects and ideas will show up here.',
		description: 'Roadmap list view with no projects hint',
	},
});
