import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import noop from 'lodash/noop';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type OptionsMappingContextData = {
	toField: Field | undefined;
	unmappedOptionsCount: number;
	automappedOptionsCount: number;
	optionsValueMapping: Record<
		string,
		{ optionId: string | undefined; automapped: boolean } | undefined
	>;
	onSetOptionValueMapping: (mapping: Record<string, string | undefined>) => void;
};

const OptionsMappingContext = createContext<OptionsMappingContextData>({
	toField: undefined,
	unmappedOptionsCount: 0,
	automappedOptionsCount: 0,
	optionsValueMapping: {},
	onSetOptionValueMapping: noop,
});

type OptionsMappingContextProviderProps = OptionsMappingContextData;

export const OptionsMappingContextProvider = ({
	children,
	toField,
	unmappedOptionsCount,
	automappedOptionsCount,
	optionsValueMapping,
	onSetOptionValueMapping,
}: PropsWithChildren<OptionsMappingContextProviderProps>) => {
	const value = useMemo(
		() => ({
			toField,
			unmappedOptionsCount,
			automappedOptionsCount,
			optionsValueMapping,
			onSetOptionValueMapping,
		}),
		[
			toField,
			unmappedOptionsCount,
			automappedOptionsCount,
			optionsValueMapping,
			onSetOptionValueMapping,
		],
	);

	return <OptionsMappingContext.Provider value={value}>{children}</OptionsMappingContext.Provider>;
};

export const useTargetField = () => {
	const { toField } = useContext(OptionsMappingContext);
	return toField;
};

export const useUnmappedOptionsCount = () => {
	const { unmappedOptionsCount } = useContext(OptionsMappingContext);
	return unmappedOptionsCount;
};

export const useAutomappedOptionsCount = () => {
	const { automappedOptionsCount } = useContext(OptionsMappingContext);
	return automappedOptionsCount;
};

export const useFieldOptionsValuesMapping = () => {
	const { optionsValueMapping } = useContext(OptionsMappingContext);
	return optionsValueMapping;
};

export const useSetFieldOptionsValuesMapping = () => {
	const { onSetOptionValueMapping } = useContext(OptionsMappingContext);
	return onSetOptionValueMapping;
};
