import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { mergeIdeas } from './merge';
import { setMergeFromOption } from './merge-from';
import { setTargetIssue } from './target-issue';
import { toggleMergeIdeasDialog } from './toggle-dialog';
import { toggleMergeOption } from './toggle-merge-option';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	toggleMergeIdeasDialog,
	setMergeFromOption,
	setTargetIssue,
	mergeIdeas,
	toggleMergeOption,
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
