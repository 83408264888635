import type { Input } from '@atlaskit/pragmatic-drag-and-drop/types';

/**
 * TODO: a PR has been created in pragmatic-dnd repo to share this util.
 * Import it from pragmatic-dnd when it's available and remove it from here.
 */
export const preserveOffsetFromPointer =
	({ sourceElement, input }: { sourceElement: HTMLElement; input: Input }) =>
	({ container }: { container: HTMLElement }) => {
		const sourceRect = sourceElement.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();

		const offsetX = Math.min(input.clientX - sourceRect.x, containerRect.width);
		const offsetY = Math.min(input.clientY - sourceRect.y, containerRect.height);

		return { x: offsetX, y: offsetY };
	};
