import React, { type ReactNode, useMemo, memo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { useLocalIssuesCountForVerticalGroupIdentity } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewCollapsedSwimlanes,
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewHideEmptyGroups,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createGroupIdentityPair } from '../../../../../common/utils/board';
import { useGroupOptions, useNonEmptyGroupOptions } from '../../../common/utils/group-options';
import { useExtendedVerticalGroupOptions } from '../../../common/utils/vertical-group-options';
import { type UseValidTargetsProps, useCardDropDisabled } from '../../utils/drop-disabled';
import { Cell } from '../cell/main.tsx';
import { DraggableGroupStickyHeader } from './draggable-group-sticky-header';

type BodyRowProps = {
	index: number;
	isLastGroup: boolean;
	isReadOnly: boolean;
	isFooterVisible: boolean;
	groupByField: Field;
	verticalGroupByField: Field;
	draggableId: string;
	groupIdentity?: string;
	verticalFieldValue: unknown;
} & Pick<UseValidTargetsProps, 'draggedCard'>;

const BodyRow = memo((props: BodyRowProps) => {
	const {
		index,
		isLastGroup,
		groupByField,
		verticalGroupByField,
		isReadOnly,
		isFooterVisible,
		draggableId,
		groupIdentity,
		verticalFieldValue,
		draggedCard,
	} = props;
	const extendedOptions = useGroupOptions(groupByField);
	const nonEmptyOptions = useNonEmptyGroupOptions(groupByField);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();

	const columnsCount = useMemo(
		() => (hideEmptyColumns ? nonEmptyOptions.length : extendedOptions.length),
		[extendedOptions, hideEmptyColumns, nonEmptyOptions],
	);

	const collapsedSwimlanes = useCurrentViewCollapsedSwimlanes();
	const hideEmptyGroups = useCurrentViewHideEmptyGroups();
	const cardDropDisabledByColumn = useCardDropDisabled({
		field: groupByField,
		extendedOptions,
		draggedCard,
	});

	const localIssuesCount = useLocalIssuesCountForVerticalGroupIdentity(
		groupByField.key,
		verticalGroupByField.key,
		groupIdentity,
	);

	const isVisible =
		collapsedSwimlanes[String(groupIdentity)] === undefined
			? localIssuesCount > 0
			: collapsedSwimlanes[String(groupIdentity)] === false;

	const isEmptyGroup = localIssuesCount === 0;

	return (
		<RowContainer isHidden={hideEmptyGroups && isEmptyGroup}>
			<DraggableGroupStickyHeader
				draggableId={`row-${draggableId}`}
				index={index}
				isReadOnly={isReadOnly}
				isVisible={isVisible}
				columnsCount={columnsCount}
				localIssuesCount={localIssuesCount}
				fieldKey={verticalGroupByField.key}
				isLastGroup={isLastGroup}
				groupIdentity={groupIdentity}
			/>
			{isVisible ? (
				<RowContentContainer>
					{extendedOptions.map((extendedOption, columnIndex) => {
						const isCardDropDisabled =
							isReadOnly ||
							(extendedOption.groupIdentity !== undefined &&
								cardDropDisabledByColumn[extendedOption.groupIdentity]);
						return (
							<Cell
								key={`${columnIndex}-${extendedOption.groupIdentity || ''}`}
								droppableId={createGroupIdentityPair(draggableId, extendedOption.droppableId)}
								groupIdentity={extendedOption.groupIdentity}
								verticalGroupIdentity={groupIdentity}
								index={columnIndex}
								fieldKey={groupByField.key}
								verticalFieldKey={verticalGroupByField.key}
								fieldValue={extendedOption.value}
								verticalFieldValue={verticalFieldValue}
								isReadOnly={isReadOnly}
								isFooterVisible={isFooterVisible}
								isCardDropDisabled={isCardDropDisabled}
							/>
						);
					})}
				</RowContentContainer>
			) : null}
		</RowContainer>
	);
});

BodyRow.displayName = 'SwimlanesBodyRow';

export type BodyProps = {
	isReadOnly: boolean;
	isFooterVisible: boolean;
	groupByField: Field;
	verticalGroupByField: Field;
	children: ReactNode;
} & Pick<UseValidTargetsProps, 'draggedCard'>;

export const SwimlanesBody = memo(
	({
		verticalGroupByField,
		groupByField,
		isReadOnly,
		isFooterVisible,
		children,
		draggedCard,
	}: BodyProps) => {
		const { initSwimlanes } = useViewActions();
		const extendedVerticalOptions = useExtendedVerticalGroupOptions(verticalGroupByField);

		useEffect(() => {
			if (ff('polaris.collapse-all-board-groups')) {
				const swimlanes = extendedVerticalOptions.map(
					({ groupIdentity }) => groupIdentity ?? 'undefined', // we use "undefined" as a valid key
				);
				initSwimlanes(swimlanes);
			}
		}, [extendedVerticalOptions, initSwimlanes]);

		return (
			<RowsContainer>
				{children}

				{extendedVerticalOptions.map((verticalExtendedOption, index) => (
					<BodyRow
						key={verticalExtendedOption.droppableId}
						index={index}
						isLastGroup={index === extendedVerticalOptions.length - 1}
						isReadOnly={isReadOnly}
						isFooterVisible={isFooterVisible}
						groupIdentity={verticalExtendedOption.groupIdentity}
						draggableId={verticalExtendedOption.droppableId}
						groupByField={groupByField}
						verticalGroupByField={verticalGroupByField}
						verticalFieldValue={verticalExtendedOption.value}
						draggedCard={draggedCard}
					/>
				))}
			</RowsContainer>
		);
	},
);

SwimlanesBody.displayName = 'SwimlanesBody';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContainer = styled.div<{ isHidden: boolean }>({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'flex'),
	flexDirection: 'column',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowContentContainer = styled.div({
	width: '100%',
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RowsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	marginBottom: token('space.200', '16px'),
});
