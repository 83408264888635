import { createSelector } from 'reselect';
import {
	projectSelector,
	uiSelector,
	isSimplifiedProjectSelector,
} from '../../../common/state/selectors/issue-selector';
import { sameLevelIssueTypesIdsSelector } from './hierarchy-level-selector';

export const availableProjectIssueTypesSelector = createSelector(
	projectSelector,
	sameLevelIssueTypesIdsSelector,
	isSimplifiedProjectSelector,
	(project, sameLevelIssueTypesIds, isSimplifiedProject) => {
		const issueTypes = (project && project.issueTypes) || [];

		// We want to restrict the available issue types to the ones at the same level in next gen projects
		return isSimplifiedProject
			? // @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
				issueTypes.filter((issuetype) => sameLevelIssueTypesIds.includes(issuetype.id))
			: issueTypes;
	},
);

export const shouldShowIssueTypeList = createSelector(
	uiSelector,
	(ui) => !!(ui.issueType && ui.issueType.shouldShowIssueTypeList),
);
