import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	restrictedContextCantSee: {
		id: 'polaris-lib-restricted-field.restricted-field-indicator.restricted-context-cant-see',
		defaultMessage: "You can't see this field in project views because its context has changed.",
		description: 'Label showing that a custom field is in restricted context.',
	},
	restrictedContextAddOrEdit: {
		id: 'polaris-lib-restricted-field.restricted-field-indicator.restricted-context-add-or-edit',
		defaultMessage: '<button>Add or edit context</button> to manage global context for this field.',
		description: 'Label showing that a custom field is in restricted context.',
	},
});
