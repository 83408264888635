import flatten from 'lodash/flatten';
import { doc as docBuilder, p } from '@atlaskit/adf-utils/builders';
import { traverse } from '@atlaskit/adf-utils/traverse';
import {
	emptyAdfObject,
	isOnlyWhitespaceAdf,
} from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';

type GenericComment = {
	body: ADF;
};

export const getMediaIds = (doc?: ADF | null): string[] => {
	const mediaIds: Array<string> = [];
	if (doc !== undefined && doc !== null && !isOnlyWhitespaceAdf(doc)) {
		traverse(doc, {
			media: (node) => {
				if (!node.attrs?.id || node.attrs.collection === undefined) {
					return;
				}
				mediaIds.push(node.attrs.id);
			},
		});
	}
	return mediaIds;
};

export const getAllMediaIdsFromComments = (comments: GenericComment[]) =>
	flatten(comments.map((comment) => getMediaIds(comment.body)));

export const parseAdfString = (value: string | undefined) => {
	if (!value) return emptyAdfObject;

	try {
		// case when value is string representation of a number
		if (!Number.isNaN(Number(value))) {
			return docBuilder(p(value));
		}

		return JSON.parse(value);
		// this catch is needed for old descriptions that were saved as primitive strings and cannot be parsed as a JSON
	} catch (e) {
		return docBuilder(p(value));
	}
};

export const isEmpty = isOnlyWhitespaceAdf;
