import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editField: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.edit-field',
		defaultMessage: 'Edit field',
		description: 'Button label for editing the field.',
	},
	fieldSettings: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.field-settings',
		defaultMessage: 'Field settings',
		description: 'Button label for opening field settings.',
	},
	sort: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.sort',
		defaultMessage: 'Sort',
		description: 'Button label for sorting by column.',
	},
	sortAscending: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.sort-ascending',
		defaultMessage: 'Sort ascending',
		description: 'Button label for sorting by column.',
	},
	sortDescending: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.sort-descending',
		defaultMessage: 'Sort descending',
		description: 'Button label for sorting by column.',
	},
	clearSort: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.clear-sort',
		defaultMessage: 'Clear sort',
		description: 'Button label for clearing the sorting by column.',
	},
	filter: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.filter',
		defaultMessage: 'Filter',
		description: 'Button label for filtering by column.',
	},
	clearFilter: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.clear-filter',
		defaultMessage: 'Clear filter',
		description: 'Button label for clearing a filtering based on a column',
	},
	removeFromView: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.remove-from-view',
		defaultMessage: 'Remove from view',
		description: 'Button label for removing the field from the view.',
	},
	viewAsBoard: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.view-as-board',
		defaultMessage: 'View as board',
		description: 'Button label for creating a board based on the field.',
	},
	viewAsBoardDisabled: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.view-as-board-disabled',
		defaultMessage: 'This operation is not available for views in this section',
		description:
			'Message for explaining why the user cannot create a board view from the current view.',
	},
	nonRemovableFromView: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.non-removable-from-view',
		defaultMessage: 'This field cannot be removed from the view',
		description: 'Tooltip label indicating that the current field cannot be removed from the view',
	},
	noManageViewPermissions: {
		id: 'polaris-ideas.view-content.idea-list.header.cell.cell-dropdown-menu.no-manage-view-permissions',
		defaultMessage: 'Only project admins can manage views',
		description: 'Information message for missing view manage permissions.',
	},
});
