import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import {
	ProjectOnboardingStatusEnum,
	ProjectTemplateEnum,
	type PolarisProjectTemplate,
} from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { projectRemote as legacyProjectRemote } from '@atlassian/jira-polaris-remote-legacy-project/src/services/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { logPolarisError } from '../../../common/utils/errors';
import type { Props, State } from '../types';

const getPolarisDefaultProjectTemplate = () => {
	const flag = getFeatureFlagValue<string>('polaris.project.creation.template', 'none');
	return flag;
};

const logError = (error: Error) => {
	logPolarisError('controllers.project.actions.applyTemplate', error);
};

export const getProjectTemplate =
	(): Action<State, Props, PolarisProjectTemplate | undefined> =>
	({ getState }) => {
		const { projectTemplate } = getState();
		return projectTemplate;
	};

type ValueOf<T> = T[keyof T];
const POLARIS_API_TEMPLATES: ValueOf<typeof ProjectTemplateEnum>[] = [
	ProjectTemplateEnum.DISCOVERY,
	ProjectTemplateEnum.ROADMAP,
	// GALILEO-1300 START
	ProjectTemplateEnum.PRIORITIZATION,
	// GALILEO-1300 END
];

export const isPolarisApiTemplate = (projectTemplate?: PolarisProjectTemplate): boolean =>
	projectTemplate ? POLARIS_API_TEMPLATES.includes(projectTemplate.initialProjectTemplate) : false;

export const applyProjectTemplate =
	(isOnboardingExperimentEnabled: boolean): Action<State, Props, Promise<boolean>> =>
	async ({ getState }, { apolloClient }) => {
		const template = isOnboardingExperimentEnabled ? 'v17' : getPolarisDefaultProjectTemplate();

		const { project, projectTemplate } = getState();

		if (!project.id) {
			const error = new Error(
				'Cannot onboard missing project. Action must not be called without known project id.',
			);
			logError(error);
			throw error;
		}

		// Skip if not a Polaris API template (i.e. Blank template)
		if (!isPolarisApiTemplate(projectTemplate)) {
			return true;
		}

		if (template === 'none') {
			experience.project.applyTemplate.abort('Template version unknown');
			const error = new Error(
				'Cannot onboard without known template. Template version must be set.',
			);
			logError(error);
			throw error;
		}

		experience.project.applyTemplate.mark(
			experience.project.applyTemplate.marks.APPLY_TEMPLATE_POLLING_START_MARK,
		);

		await legacyProjectRemote.applyProjectTemplate(
			apolloClient,
			project.id,
			project.ideaIssueTypes[0].id,
			template,
		);

		return true;
	};

export const checkProjectOnboarded =
	(): Action<State, Props, Promise<boolean>> =>
	async ({ getState }, { apolloClient, projectRemote }) => {
		const { project, projectTemplate } = getState();

		if (!project.id) {
			const error = new Error('Cannot check missing project');
			logError(error);
			return false;
		}

		if (!isPolarisApiTemplate(projectTemplate)) {
			const { onboardingStatus } = await projectRemote.getOnboardingInformation();

			return onboardingStatus === ProjectOnboardingStatusEnum.DONE;
		}

		const { isOnboarded } = await legacyProjectRemote.checkProjectOnboarded(
			apolloClient,
			project.id,
		);

		return isOnboarded;
	};
