import { createSharingHook } from '../main';
import {
	getIsCurrentViewSharingInitialLoading,
	getIsCurrentViewSharingSettingsInitialLoading,
	getCurrentViewSharingSettings,
	getCurrentViewSharingStakeholders,
	getIdeaDescriptionFieldKey,
	hasCurrentViewSharingError,
	hasCurrentViewSharingSettingsError,
	getIsSharingOnSiteEnabled,
	getIsSharingSiteConfigLoading,
	getProjectAccessLevel,
	getIsPublishDialogContentLoading,
	getPublicSharingControls,
	getIsCurrentViewOpenedAsPublic,
} from './index';

export const useIsCurrentViewSharingSettingsInitialLoading = createSharingHook(
	getIsCurrentViewSharingSettingsInitialLoading,
);
export const useIsCurrentViewSharingInitialLoading = createSharingHook(
	getIsCurrentViewSharingInitialLoading,
);
export const useCurrentViewSharingSettings = createSharingHook(getCurrentViewSharingSettings);
export const useCurrentViewSharingStakeholders = createSharingHook(
	getCurrentViewSharingStakeholders,
);
export const usePublicSharingControls = createSharingHook(getPublicSharingControls);
export const useIsCurrentViewOpenedAsPublic = createSharingHook(getIsCurrentViewOpenedAsPublic);
export const useIdeaDescriptionFieldKey = createSharingHook(getIdeaDescriptionFieldKey);
export const useHasCurrentViewSharingSettingsError = createSharingHook(
	hasCurrentViewSharingSettingsError,
);
export const useHasCurrentViewSharingError = createSharingHook(hasCurrentViewSharingError);

export const useIsSharingOnSiteEnabled = createSharingHook(getIsSharingOnSiteEnabled);
export const useIsSharingSiteConfigLoading = createSharingHook(getIsSharingSiteConfigLoading);

export const useProjectAccessLevel = createSharingHook(getProjectAccessLevel);

export const useIsPublishDialogContentLoading = createSharingHook(getIsPublishDialogContentLoading);
