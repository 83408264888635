import flatten from 'lodash/flatten';
import has from 'lodash/has';
import join from 'lodash/join';
import keyBy from 'lodash/keyBy';
import { colors } from '@atlaskit/theme';
import { token, type ThemeIds } from '@atlaskit/tokens';
import * as colorsNew from './colors';

export type PolarisPaletteEntry = {
	/**
	 * General palette color as can be selected by the user
	 */
	mainColor: string;
	/**
	 * Transparent overlay on top of the palette color for the emoji display region
	 */
	emojiOverlay: string;
	/**
	 * Text color depending on the palette color; needs to pass accessibility checks
	 */
	textColor: string;
	/**
	 * Color used for object highlighting (row, card, column headers)
	 */
	highlightColor: string;
};

export const subtle = (
	color: string,
	highlightColor: string,
	textColor: string,
): PolarisPaletteEntry => ({
	mainColor: color,
	emojiOverlay: color,
	highlightColor,
	textColor,
});

/**
 * Creates a color with a given opacity
 *
 * @param {string} rgb - color code #RRGGBB
 * @param {number} a - number in range [0..1]
 *
 * @returns {string} New color with given alpha chanel #RRGGBBAA
 */
export const alpha = (rgb: string, a: number) =>
	`${rgb}${Math.floor(a * 255)
		.toString(16)
		.toUpperCase()}`;

/**
 * This is the source of truth for all palette entries we support. Stored as an array of
 * arrays to represent the visualisation order of the palette picker (rows, cols).
 */
const lightThemePalette: PolarisPaletteEntry[][] = [
	[
		subtle(colorsNew.N200, alpha(colorsNew.N200, 0.3), colorsNew.N800),
		subtle(colorsNew.B100, alpha(colorsNew.B100, 0.3), colorsNew.B800),
		subtle(colorsNew.T100, alpha(colorsNew.T100, 0.3), colorsNew.T800),
		subtle(colorsNew.G100, alpha(colorsNew.G100, 0.3), colorsNew.G800),
		subtle(colorsNew.Y100, alpha(colorsNew.Y100, 0.3), colorsNew.Y800),
		subtle(colorsNew.O100, alpha(colorsNew.O100, 0.3), colorsNew.O800),
		subtle(colorsNew.R100, alpha(colorsNew.R100, 0.3), colorsNew.R800),
		subtle(colorsNew.M100, alpha(colorsNew.M100, 0.3), colorsNew.M800),
		subtle(colorsNew.P100, alpha(colorsNew.P100, 0.3), colorsNew.P800),
	],
	[
		subtle(colorsNew.N300, alpha(colorsNew.N200, 0.6), colorsNew.N900),
		subtle(colorsNew.B200, alpha(colorsNew.B100, 0.6), colorsNew.B900),
		subtle(colorsNew.T200, alpha(colorsNew.T100, 0.6), colorsNew.T900),
		subtle(colorsNew.G200, alpha(colorsNew.G100, 0.6), colorsNew.G900),
		subtle(colorsNew.Y200, alpha(colorsNew.Y100, 0.6), colorsNew.Y900),
		subtle(colorsNew.O200, alpha(colorsNew.O100, 0.6), colorsNew.O900),
		subtle(colorsNew.R200, alpha(colorsNew.R100, 0.6), colorsNew.R900),
		subtle(colorsNew.M200, alpha(colorsNew.M100, 0.6), colorsNew.M900),
		subtle(colorsNew.P200, alpha(colorsNew.P100, 0.6), colorsNew.P900),
	],
	[
		subtle(colorsNew.N400, alpha(colorsNew.N200, 0.9), colorsNew.N1000),
		subtle(colorsNew.B300, alpha(colorsNew.B100, 0.9), colorsNew.B1000),
		subtle(colorsNew.T300, alpha(colorsNew.T100, 0.9), colorsNew.T1000),
		subtle(colorsNew.G300, alpha(colorsNew.G100, 0.9), colorsNew.G1000),
		subtle(colorsNew.Y300, alpha(colorsNew.Y100, 0.9), colorsNew.Y1000),
		subtle(colorsNew.O300, alpha(colorsNew.O100, 0.9), colorsNew.O1000),
		subtle(colorsNew.R300, alpha(colorsNew.R100, 0.9), colorsNew.R1000),
		subtle(colorsNew.M300, alpha(colorsNew.M100, 0.9), colorsNew.M1000),
		subtle(colorsNew.P300, alpha(colorsNew.P100, 0.9), colorsNew.P1000),
	],
	[
		subtle(colorsNew.N700, alpha(colorsNew.N200, 0.9), colorsNew.N100),
		subtle(colorsNew.B700, alpha(colorsNew.B100, 0.9), colorsNew.B100),
		subtle(colorsNew.T700, alpha(colorsNew.T100, 0.9), colorsNew.T100),
		subtle(colorsNew.G700, alpha(colorsNew.G100, 0.9), colorsNew.G100),
		subtle(colorsNew.Y700, alpha(colorsNew.Y100, 0.9), colorsNew.Y100),
		subtle(colorsNew.O700, alpha(colorsNew.O100, 0.9), colorsNew.O100),
		subtle(colorsNew.R700, alpha(colorsNew.R100, 0.9), colorsNew.R100),
		subtle(colorsNew.M700, alpha(colorsNew.M100, 0.9), colorsNew.M100),
		subtle(colorsNew.P700, alpha(colorsNew.P100, 0.9), colorsNew.P100),
	],
	[
		subtle(colorsNew.N900, alpha(colorsNew.N200, 0.9), colorsNew.N100),
		subtle(colorsNew.B900, alpha(colorsNew.B100, 0.9), colorsNew.B100),
		subtle(colorsNew.T900, alpha(colorsNew.T100, 0.9), colorsNew.T100),
		subtle(colorsNew.G900, alpha(colorsNew.G100, 0.9), colorsNew.G100),
		subtle(colorsNew.Y900, alpha(colorsNew.Y100, 0.9), colorsNew.Y100),
		subtle(colorsNew.O900, alpha(colorsNew.O100, 0.9), colorsNew.O100),
		subtle(colorsNew.R900, alpha(colorsNew.R100, 0.9), colorsNew.R100),
		subtle(colorsNew.M900, alpha(colorsNew.M100, 0.9), colorsNew.M100),
		subtle(colorsNew.P900, alpha(colorsNew.P100, 0.9), colorsNew.P100),
	],
];

const darkThemePalette: PolarisPaletteEntry[][] = [
	[
		subtle(colorsNew.N200, alpha(colorsNew.N900, 0.3), colorsNew.N800),
		subtle(colorsNew.B100, alpha(colorsNew.B1000, 0.3), colorsNew.B800),
		subtle(colorsNew.T100, alpha(colorsNew.T1000, 0.3), colorsNew.T800),
		subtle(colorsNew.G100, alpha(colorsNew.G1000, 0.3), colorsNew.G800),
		subtle(colorsNew.Y100, alpha(colorsNew.Y1000, 0.3), colorsNew.Y800),
		subtle(colorsNew.O100, alpha(colorsNew.O1000, 0.3), colorsNew.O800),
		subtle(colorsNew.R100, alpha(colorsNew.R1000, 0.3), colorsNew.R800),
		subtle(colorsNew.M100, alpha(colorsNew.M1000, 0.3), colorsNew.M800),
		subtle(colorsNew.P100, alpha(colorsNew.P1000, 0.3), colorsNew.P800),
	],
	[
		subtle(colorsNew.N300, alpha(colorsNew.N900, 0.6), colorsNew.N900),
		subtle(colorsNew.B200, alpha(colorsNew.B1000, 0.6), colorsNew.B900),
		subtle(colorsNew.T200, alpha(colorsNew.T1000, 0.6), colorsNew.T900),
		subtle(colorsNew.G200, alpha(colorsNew.G1000, 0.6), colorsNew.G900),
		subtle(colorsNew.Y200, alpha(colorsNew.Y1000, 0.6), colorsNew.Y900),
		subtle(colorsNew.O200, alpha(colorsNew.O1000, 0.6), colorsNew.O900),
		subtle(colorsNew.R200, alpha(colorsNew.R1000, 0.6), colorsNew.R900),
		subtle(colorsNew.M200, alpha(colorsNew.M1000, 0.6), colorsNew.M900),
		subtle(colorsNew.P200, alpha(colorsNew.P1000, 0.6), colorsNew.P900),
	],
	[
		subtle(colorsNew.N400, alpha(colorsNew.N900, 0.9), colorsNew.N1000),
		subtle(colorsNew.B300, alpha(colorsNew.B1000, 0.9), colorsNew.B1000),
		subtle(colorsNew.T300, alpha(colorsNew.T1000, 0.9), colorsNew.T1000),
		subtle(colorsNew.G300, alpha(colorsNew.G1000, 0.9), colorsNew.G1000),
		subtle(colorsNew.Y300, alpha(colorsNew.Y1000, 0.9), colorsNew.Y1000),
		subtle(colorsNew.O300, alpha(colorsNew.O1000, 0.9), colorsNew.O1000),
		subtle(colorsNew.R300, alpha(colorsNew.R1000, 0.9), colorsNew.R1000),
		subtle(colorsNew.M300, alpha(colorsNew.M1000, 0.9), colorsNew.M1000),
		subtle(colorsNew.P300, alpha(colorsNew.P1000, 0.9), colorsNew.P1000),
	],
	[
		subtle(colorsNew.N700, alpha(colorsNew.N900, 0.6), colorsNew.N200),
		subtle(colorsNew.B700, alpha(colorsNew.B1000, 0.6), colorsNew.B200),
		subtle(colorsNew.T700, alpha(colorsNew.T1000, 0.6), colorsNew.T200),
		subtle(colorsNew.G700, alpha(colorsNew.G1000, 0.6), colorsNew.G200),
		subtle(colorsNew.Y700, alpha(colorsNew.Y1000, 0.6), colorsNew.Y200),
		subtle(colorsNew.O700, alpha(colorsNew.O1000, 0.6), colorsNew.O200),
		subtle(colorsNew.R700, alpha(colorsNew.R1000, 0.6), colorsNew.R200),
		subtle(colorsNew.M700, alpha(colorsNew.M1000, 0.6), colorsNew.M200),
		subtle(colorsNew.P700, alpha(colorsNew.P1000, 0.6), colorsNew.P200),
	],
	[
		subtle(colorsNew.N900, alpha(colorsNew.N900, 0.3), colorsNew.N100),
		subtle(colorsNew.B900, alpha(colorsNew.B1000, 0.3), colorsNew.B100),
		subtle(colorsNew.T900, alpha(colorsNew.T1000, 0.3), colorsNew.T100),
		subtle(colorsNew.G900, alpha(colorsNew.G1000, 0.3), colorsNew.G100),
		subtle(colorsNew.Y900, alpha(colorsNew.Y1000, 0.3), colorsNew.Y100),
		subtle(colorsNew.O900, alpha(colorsNew.O1000, 0.3), colorsNew.O100),
		subtle(colorsNew.R900, alpha(colorsNew.R1000, 0.3), colorsNew.R100),
		subtle(colorsNew.M900, alpha(colorsNew.M1000, 0.3), colorsNew.M100),
		subtle(colorsNew.P900, alpha(colorsNew.P1000, 0.3), colorsNew.P100),
	],
];

const hexToRgb = (hex?: string) => {
	if (hex !== undefined) {
		const matchResult = hex
			.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
			.substring(1)
			.match(/.{2}/g);

		if (matchResult !== null && matchResult !== undefined) {
			const rgbValues = matchResult.map((x) => parseInt(x, 16));
			return `rgba(${join(rgbValues, ',')}, 0.7)`; // give it a little opacity
		}
	}
	// transparent by default
	return 'rgba(255, 255, 255, 0)';
};

const mainColorHexToRgbCache: { [mainColorHex: string]: string } = {};
export const convertMainColorHexToRgb = (mainColorHex?: string) => {
	if (!mainColorHex) {
		return hexToRgb(mainColorHex);
	}
	if (mainColorHexToRgbCache[mainColorHex]) {
		return mainColorHexToRgbCache[mainColorHex];
	}
	mainColorHexToRgbCache[mainColorHex] = hexToRgb(mainColorHex);
	return mainColorHexToRgbCache[mainColorHex];
};

export const flatPalette: PolarisPaletteEntry[] = flatten(lightThemePalette);

const lightPaletteByMainColor = keyBy(flatPalette, ({ mainColor }) => mainColor);
const darkPaletteByMainColor = keyBy(flatten(darkThemePalette), ({ mainColor }) => mainColor);

const getPalettesByTheme = (theme?: ThemeIds | null) => {
	const palette = theme === 'dark' ? darkPaletteByMainColor : lightPaletteByMainColor;

	// Gen1 colors
	const newPaletteByGen1MainColor: Record<string, PolarisPaletteEntry> = {
		[colors.N900]: palette[colorsNew.N400],
		[colors.B500]: palette[colorsNew.B300],
		[colors.T500]: palette[colorsNew.T300],
		[colors.G500]: palette[colorsNew.G300],
		[colors.Y500]: palette[colorsNew.Y300],
		[colors.R500]: palette[colorsNew.R300],
		[colors.P500]: palette[colorsNew.P300],

		[colors.N80]: palette[colorsNew.N300],
		[colors.B300]: palette[colorsNew.B200],
		[colors.T300]: palette[colorsNew.T200],
		[colors.G300]: palette[colorsNew.G200],
		[colors.Y200]: palette[colorsNew.Y200],
		[colors.R200]: palette[colorsNew.R200],
		[colors.P300]: palette[colorsNew.P200],

		[colors.N0]: palette[colorsNew.N200],
		[colors.B75]: palette[colorsNew.B100],
		[colors.T75]: palette[colorsNew.T100],
		[colors.G75]: palette[colorsNew.G100],
		[colors.Y75]: palette[colorsNew.Y100],
		[colors.R75]: palette[colorsNew.R100],
		[colors.P75]: palette[colorsNew.P100],
	};

	// Gen2 colors
	const newPaletteByGen2MainColor: Record<string, PolarisPaletteEntry> = {
		[colors.N100]: palette[colorsNew.N400],
		[colors.B300]: palette[colorsNew.B300],
		[colors.T300]: palette[colorsNew.T300],
		[colors.G300]: palette[colorsNew.G300],
		[colors.Y300]: palette[colorsNew.Y300],
		[colors.R300]: palette[colorsNew.R300],
		[colors.P300]: palette[colorsNew.P300],

		[colors.N80]: palette[colorsNew.N300],
		[colors.B200]: palette[colorsNew.B200],
		[colors.T200]: palette[colorsNew.T200],
		[colors.G200]: palette[colorsNew.G300],
		[colors.Y100]: palette[colorsNew.Y200],
		[colors.R200]: palette[colorsNew.R200],
		[colors.P200]: palette[colorsNew.P200],

		[colors.N40]: palette[colorsNew.N200],
		[colors.B75]: palette[colorsNew.B100],
		[colors.T100]: palette[colorsNew.T100],
		[colors.G75]: palette[colorsNew.G100],
		// @ts-expect-error([Part of upgrade 4.9-5.4]) - An object literal cannot have multiple properties with the same name.
		[colors.Y100]: palette[colorsNew.Y100],
		[colors.R75]: palette[colorsNew.R100],
		[colors.P75]: palette[colorsNew.P100],
	};

	// Additional missing colors
	const newPaletteByMissingMainColor: Record<string, PolarisPaletteEntry> = {
		[colors.Y200]: palette[colorsNew.Y300],
		[colors.G300]: palette[colorsNew.G300],
	};

	return {
		palette,
		newPaletteByGen2MainColor,
		newPaletteByMissingMainColor,
		newPaletteByGen1MainColor,
	};
};

export const DEFAULT = subtle(
	token('elevation.surface', colorsNew.N0),
	token('elevation.surface', colorsNew.N0),
	token('color.text', colorsNew.N1000),
);

export const SORTED_MAIN_COLORS: string[][] = lightThemePalette.map((row) =>
	row.map(({ mainColor }) => mainColor),
);

const darkPaletteEntryForMainColor = getPalettesByTheme('dark');
const lightPaletteEntryForMainColor = getPalettesByTheme('light');

export const getPaletteEntryForMainColor = (
	color?: string,
	theme?: ThemeIds | null,
): PolarisPaletteEntry => {
	const {
		palette,
		newPaletteByGen2MainColor,
		newPaletteByMissingMainColor,
		newPaletteByGen1MainColor,
	} = theme === 'dark' ? darkPaletteEntryForMainColor : lightPaletteEntryForMainColor;

	if (color !== undefined && has(palette, color)) {
		return palette[color];
	}

	// handle gen2 main colors gracefully
	if (color !== undefined && has(newPaletteByGen2MainColor, color)) {
		return newPaletteByGen2MainColor[color];
	}

	// handle gen1 main colors gracefully
	if (color !== undefined && has(newPaletteByGen1MainColor, color)) {
		return newPaletteByGen1MainColor[color];
	}

	// handle gen1 main colors gracefully
	if (color !== undefined && has(newPaletteByMissingMainColor, color)) {
		return newPaletteByMissingMainColor[color];
	}

	return DEFAULT;
};

export const getPaletteEntryForMainColorWithNoDefault = (
	color?: string,
	theme?: ThemeIds | null,
): PolarisPaletteEntry | undefined => {
	const {
		palette,
		newPaletteByGen2MainColor,
		newPaletteByMissingMainColor,
		newPaletteByGen1MainColor,
	} = theme === 'dark' ? darkPaletteEntryForMainColor : lightPaletteEntryForMainColor;

	if (color !== undefined && has(palette, color)) {
		return palette[color];
	}

	// handle gen2 main colors gracefully
	if (color !== undefined && has(newPaletteByGen2MainColor, color)) {
		return newPaletteByGen2MainColor[color];
	}

	// handle gen1 main colors gracefully
	if (color !== undefined && has(newPaletteByGen1MainColor, color)) {
		return newPaletteByGen1MainColor[color];
	}

	// handle gen1 main colors gracefully
	if (color !== undefined && has(newPaletteByMissingMainColor, color)) {
		return newPaletteByMissingMainColor[color];
	}

	return undefined;
};
