import { createInsight as createInsightCall } from '../../services/polaris-api/create-insight';
import type { CreateInsightRequest, ProviderProps, RemoteInsightCreator } from '../../types';

export const createInsight =
	({ apolloClient, cloudId }: Omit<ProviderProps, 'projectId'>): RemoteInsightCreator =>
	({ issueId, snippets, description, projectId }: CreateInsightRequest) =>
		createInsightCall(apolloClient, {
			cloudID: cloudId,
			projectID: Number(projectId),
			issueID: issueId !== undefined ? issueId : null,
			snippets,
			description,
		}).then((insight) => ({
			...insight,
			snippets: [
				...insight.snippets.map((snippet) => ({
					...snippet,
					properties: {
						...snippet.properties,
					},
				})),
			],
		}));
