import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import type { ProjectType } from '../../../types';

export const ARCHIVED_FIELD_JQL_TYPE = 'Dropdown';

export const getIsArchivedQuery = (archivedFieldLabel: string) =>
	`( "${archivedFieldLabel}[${ARCHIVED_FIELD_JQL_TYPE}]" = EMPTY )`;

type Issue = {
	id: number;
	key: string;
	keyHtml: string;
	img: string;
	summary: string;
	summaryText: string;
};

type Section = {
	id: string;
	issues: Issue[];
	label?: string;
	msg?: string;
	sub?: string;
};

type IssueHistorySearchResults = {
	sections: Section[];
};

const transformIssueSuggestionItems = (
	{ sections = [] }: IssueHistorySearchResults = { sections: [] },
): Issue[] =>
	sections
		.map((section) => section.issues)
		.filter((issues) => !!issues)
		.reduce<Issue[]>((flatten, current) => flatten.concat(current.map((issue) => issue)), [])
		.reduce<Issue[]>(
			(uniq, current) =>
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				uniq.findIndex((item) => item.id === current.id) < 0 ? [...uniq, current] : uniq,
			[],
		);

const getIssuePickerUrl = (
	currentJQL: string,
	issueKey: string,
	projectId: string | undefined,
	query: string,
) => {
	const projectIdParamValue = projectId !== undefined ? `&currentProjectId=${projectId}` : '';
	// on hello and other large instances, returning subtasks results in a faster sql query and is aligned
	// with the behavior of jira software, hence the query param showSubTasks=true
	return `/rest/api/2/issue/picker?currentIssueKey=${issueKey}${projectIdParamValue}&showSubTasks=true&query=${encodeURI(
		query,
	)}&currentJQL=${encodeURI(currentJQL)}`;
};

const JIRA_ISSUE_KEY_REGEX = /^([A-Z][A-Z0-9]+)-([0-9]*)$/i;
const parseProjectKey = (query: string) => {
	if (query) {
		const parsedGroups = JIRA_ISSUE_KEY_REGEX.exec(query);
		if (parsedGroups && parsedGroups.length === 3) {
			return parsedGroups[1];
		}
	}
	return undefined;
};

// next-gen JSD issues may not have the resolution field and instead use statusCategory,
// but all others (classic JSD/JSW, next-gen JSW) prefer resolution
const unresolvedAndOrder =
	'(resolution = Unresolved OR statusCategory != Done) ORDER BY priority DESC, updated DESC';

const getIssueKeyClause = (issueKey: string) => `issueKey="${issueKey}"`;

const getUrlForIssueSelect = (
	issueKey: string,
	projectId: string | undefined,
	excludedIssueIds: number[],
	query: string,
	excludedProjectTypes: ProjectType[],
	archivedFieldLabel?: string,
	exactIssueKeySearch?: boolean,
): string => {
	let currentJQL = `project in projectsWhereUserHasPermission("Link Issues") AND ${
		exactIssueKeySearch === true ? getIssueKeyClause(query) : unresolvedAndOrder
	}`;

	if (excludedIssueIds !== undefined && excludedIssueIds.length > 0) {
		const ids = join(excludedIssueIds, ',');
		currentJQL = `id NOT IN (${ids}) AND ${currentJQL}`;
	}

	const projectKey = parseProjectKey(query);
	if (projectKey !== undefined) {
		currentJQL = `project = "${projectKey.toUpperCase()}" AND ${currentJQL}`;
	}

	if (!isEmpty(excludedProjectTypes)) {
		currentJQL = `projectType NOT IN (${join(excludedProjectTypes, ',')}) AND ${currentJQL}`;
	}

	if (archivedFieldLabel !== undefined && exactIssueKeySearch !== true) {
		currentJQL = `${getIsArchivedQuery(archivedFieldLabel)} AND ${currentJQL}`;
	}

	return getIssuePickerUrl(currentJQL, issueKey, projectId, query);
};

export const fetchIssueSuggestions = ({
	issueKey,
	projectId,
	excludedIssueIds,
	query,
	excludedProjectTypes,
	archivedFieldLabel,
	exactIssueKeySearch,
}: {
	issueKey: string;
	projectId: string | undefined;
	excludedIssueIds: number[];
	query: string;
	excludedProjectTypes: ProjectType[];
	archivedFieldLabel: string | undefined;
	exactIssueKeySearch?: boolean;
}): Promise<Issue[]> => {
	const queryUrl = getUrlForIssueSelect(
		issueKey,
		projectId,
		excludedIssueIds,
		query,
		excludedProjectTypes,
		archivedFieldLabel,
		exactIssueKeySearch,
	);

	experience.issues.loadIssuePickerIssuesRest.start();

	return fetchJson(queryUrl)
		.then((response) => {
			experience.issues.loadIssuePickerIssuesRest.success();
			return transformIssueSuggestionItems(response);
		})
		.catch((error) => {
			experience.issues.loadIssuePickerIssuesRest.failure(error);
			throw error;
		});
};
