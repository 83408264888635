import React, { useState, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import Range from '@atlaskit/range';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { SLIDER_FIELD_MIN_VALUE, SLIDER_FIELD_MAX_VALUE } from '../../common/fields';

type SliderFieldProps = {
	isDisabled: boolean;
	value: number | undefined;
	onChange?: (arg1: number) => void;
};

export const SliderField = ({ value: initialValue, onChange, isDisabled }: SliderFieldProps) => {
	const [value, setValue] = useState(initialValue ?? SLIDER_FIELD_MIN_VALUE);

	useEffect(() => {
		setValue(initialValue ?? SLIDER_FIELD_MIN_VALUE);
	}, [initialValue]);

	const handleChange = useCallback((newValue: number) => {
		setValue(newValue);
	}, []);

	const handleChangeFinish = useCallback(() => {
		onChange?.(value);
	}, [onChange, value]);

	return (
		<Tooltip position="auto" content={value}>
			<Container>
				<Range
					testId="polaris-common.ui.fields.slider.range"
					defaultValue={SLIDER_FIELD_MIN_VALUE}
					step={1}
					min={SLIDER_FIELD_MIN_VALUE}
					max={SLIDER_FIELD_MAX_VALUE}
					value={value}
					isDisabled={isDisabled}
					onChange={handleChange}
					onMouseUp={handleChangeFinish}
					onTouchEnd={handleChangeFinish}
				/>
			</Container>
		</Tooltip>
	);
};

SliderField.defaultProps = {
	isDisabled: false,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	maxHeight: '36px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		maxHeight: '36px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'input::-webkit-slider-thumb': {
		background: token('elevation.surface.overlay', 'white'),
	},
});
