import { sendPendoTrackEvent as sendTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { EVENTS } from '../../../../services/pendo';
import type { JiraUpdateServiceType } from '../types';

export const defaultJiraUpdateService = (
	issuesRemote: IssuesRemote,
	fieldKey?: string,
): {
	getJiraUpdateService: JiraUpdateServiceType;
} => ({
	getJiraUpdateService: (issueKey: string, value: unknown) => {
		if (fieldKey === undefined) {
			return Promise.reject(new Error('No jira field key'));
		}
		sendTrackEvent(EVENTS.IdeaFieldUpdated);
		return issuesRemote.updateIssueField({
			issueKey,
			fieldKey,
			value,
		});
	},
});

export const noopJiraUpdateService: {
	getJiraUpdateService: JiraUpdateServiceType;
} = {
	getJiraUpdateService: () => Promise.resolve(),
};
