import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { useIntl } from '@atlassian/jira-intl';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route';
import NotFoundImage from './assets/not-found.svg';
import messages from './messages';

export const IdeaNotFoundView = () => {
	const { closeIssueView } = usePolarisRouter();
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<EmptyState
				imageUrl={NotFoundImage}
				header={formatMessage(messages.title)}
				description={formatMessage(messages.description)}
				primaryAction={
					<Button onClick={closeIssueView} appearance="primary">
						{formatMessage(messages.buttonLabel)}
					</Button>
				}
			/>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: 215,
});
