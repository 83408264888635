import React, { useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-up';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';
import type { Props } from './types';

export const ExpandableSection = ({
	title,
	onToggle,
	children,
	backgroundColor = 'color.background.accent.yellow.subtlest',
	testId = 'polaris-lib-expandable-section.ui',
}: Props) => {
	const [isExpanded, setIsExpanded] = useState(false);
	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	const ToggleIcon = isExpanded ? ChevronUpIcon : ChevronDownIcon;

	const { formatMessage } = useIntl();

	const handleToggleClick = () => {
		setIsExpanded((prevIsExpanded) => {
			onToggle?.(!prevIsExpanded);
			return !prevIsExpanded;
		});
	};

	return (
		<Box
			testId={testId}
			xcss={fg('jpd-visual-refresh_typography_sirius') ? containerStyles : containerStylesOld}
			padding="space.150"
			backgroundColor={backgroundColor}
		>
			<Box xcss={headerStyles} onClick={handleToggleClick}>
				{fg('jpd-visual-refresh_typography_sirius') ? <Text>{title}</Text> : title}
				<IconButton
					testId={`${testId}.toggle-button`}
					appearance="subtle"
					label={formatMessage(messages.toggleButtonLabel)}
					icon={(iconProps) => (
						<ToggleIcon {...iconProps} primaryColor={token('color.icon', colors.N800)} />
					)}
				/>
			</Box>
			{isExpanded && <>{children}</>}
		</Box>
	);
};

const containerStyles = xcss({
	gap: 'space.100',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: 'border.radius.100',
});

const containerStylesOld = xcss({
	gap: 'space.100',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: 'border.radius.100',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: '20px',
	color: 'color.text',
});

const headerStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	cursor: 'pointer',
	gap: 'space.050',
});
