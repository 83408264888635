import type { Ari } from '@atlassian/jira-platform-ari';
import type { FieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/field/types.tsx';
import type { DynamicFieldFormula, FieldVisitor } from '../types';
import { FIELD_TEMPLATE } from './types';

export const field = (id: Ari, normalize?: boolean): FieldFormula => ({
	template: FIELD_TEMPLATE,
	parameters: { id, normalize },
});

export const isField = (formula: DynamicFieldFormula): boolean =>
	formula.template === FIELD_TEMPLATE;

export const fieldVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
): boolean => {
	if (formula.template !== FIELD_TEMPLATE) {
		return false;
	}
	visitField(formula.parameters.id);
	return true;
};
