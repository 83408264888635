import React from 'react';
import { MinimalLozenge } from '../../lozenge';

export const StatusLozenge = ({
	statusName,
	statusCategoryKey,
}: {
	statusCategoryKey: string;
	statusName: string;
}) => {
	switch (statusCategoryKey) {
		case 'indeterminate':
			return <MinimalLozenge appearance="inprogress">{statusName}</MinimalLozenge>;
		case 'done':
			return <MinimalLozenge appearance="success">{statusName}</MinimalLozenge>;
		default:
			return <MinimalLozenge>{statusName}</MinimalLozenge>;
	}
};
