import type { FlagConfiguration } from '@atlassian/jira-flags';
import { ISSUE_PANEL_MULTIPLE_LIMIT } from '@atlassian/jira-forge-ui-constants';
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'forge-ui.common.flags.issue-panel.operation-action.limit-failed.title',
		defaultMessage: 'Panel cannot be added',
		description:
			"Flag title. Flag is shown when user reaches limit of added issue panels and we don't know panel name.",
	},
	titleWithName: {
		id: 'forge-ui.common.flags.issue-panel.operation-action.limit-failed.title-with-name',
		defaultMessage: '{issuePanelName} cannot be added',
		description:
			'Flag title. Flag is shown when user reaches limit of added issue panels and we know panel name.',
	},
	description: {
		id: 'forge-ui.common.flags.issue-panel.operation-action.limit-failed.description',
		defaultMessage: 'There is a limit of {limit} {limit, plural, one {panel} other {panels}}.',
		description: 'Flag description. Flag is shown when user reaches limit of added issue panels.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issuePanelName?: string): FlagConfiguration => ({
	type: 'error',
	title:
		issuePanelName === undefined ? messages.title : [messages.titleWithName, { issuePanelName }],
	description: [messages.description, { limit: ISSUE_PANEL_MULTIPLE_LIMIT }],
	isAutoDismiss: false,
});
