import type React from 'react';
import { useEffect, useCallback, useRef, useState } from 'react';
import {
	type PermalinkType,
	getPermalinkStatus,
} from '@atlassian/jira-polaris-common/src/common/utils/permalink';

const PAGE_SIZE = 12;
const SCROLL_BOTTOM_OFFSET = 700;

const getPermalinkIdDefault = (item: string) => item;

export const useInfiniteScrollEmulator = (
	scrollableRef: React.RefObject<HTMLElement>,
	items: string[],
	options?: Partial<{
		pageSize: number;
		scrollBottomOffset: number;
		permalinkType: PermalinkType;
		getItemPermalinkId: (item: string) => string | undefined;
	}>,
) => {
	const pageSize = options?.pageSize || PAGE_SIZE;
	const scrollBottomOffset = options?.scrollBottomOffset || SCROLL_BOTTOM_OFFSET;
	const getItemPermalinkId = options?.getItemPermalinkId || getPermalinkIdDefault;

	const setFocusedAsVisibleRef = useRef(false);
	const pageSizeExtraRef = useRef(0);
	const [visibleCount, setVisibleCount] = useState(PAGE_SIZE);

	useEffect(() => {
		const permalinkType = options?.permalinkType;
		if (!permalinkType) {
			return;
		}
		const { hasPermalink, permalinkId } = getPermalinkStatus(permalinkType, window.location.search);
		if (!hasPermalink || setFocusedAsVisibleRef.current || !items.length) {
			return;
		}
		const index = items.findIndex((item) => {
			const itemPermalinkId = getItemPermalinkId(item);
			if (itemPermalinkId === undefined) {
				return false;
			}
			return itemPermalinkId === permalinkId;
		});
		if (index === -1) {
			setFocusedAsVisibleRef.current = true;
			return;
		}
		setFocusedAsVisibleRef.current = true;
		setVisibleCount((count) => {
			if (index + 1 > count) {
				return index + 1;
			}
			return count;
		});
	}, [items, getItemPermalinkId, options?.permalinkType]);

	const onScroll = useCallback(() => {
		const elm = scrollableRef.current;
		if (!elm) {
			return;
		}
		const didScrollToBottom =
			elm.scrollTop + elm.clientHeight > elm.scrollHeight - scrollBottomOffset;
		if (!didScrollToBottom) {
			return;
		}
		setVisibleCount((count) => {
			const newCount = count + pageSize + pageSizeExtraRef.current;
			if (newCount > items.length) {
				return items.length;
			}
			pageSizeExtraRef.current += 1;
			return newCount;
		});
	}, [scrollableRef, items, scrollBottomOffset, pageSize]);

	useEffect(() => {
		onScroll();
		const elm = scrollableRef.current;
		if (!elm) {
			return undefined;
		}
		elm.addEventListener('scroll', onScroll);
		return () => {
			elm.removeEventListener('scroll', onScroll);
		};
	}, [scrollableRef, onScroll, visibleCount]);

	return visibleCount;
};
