import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sidebarHeader: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-details.sidebar-header',
		defaultMessage: 'Field settings',
		description: 'Header for the sidebar displaying the configuration for a global field',
	},
	sidebarBackLabel: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-details.sidebar-back-label',
		defaultMessage: 'Go back to global fields list',
		description: 'Label for the button to go back to the global fields list',
	},
	addField: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-details.add-field',
		defaultMessage: 'Add field',
		description: 'Button to add a global field to the current project',
	},
	addErrorTitle: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-details.add-error-title',
		defaultMessage: "We couldn't add the field to the project",
		description: 'Notification title for failed field adding to the project',
	},
	addErrorDescription: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-details.add-error-description',
		defaultMessage: 'Try to add the field to the project again.',
		description: 'Notification description for failed field adding to the project',
	},
});
