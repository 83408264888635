import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { InsightsUI, InsightsActionsWrapper } from '../../../insights/insights/index.tsx';

type Props = {
	issueId: IssueId;
	localIssueId: LocalIssueId;
	scrollableContainerRef: React.RefObject<HTMLElement>;
};

export const Capture = memo<Props>(({ issueId, localIssueId, scrollableContainerRef }) => (
	<>
		<InsightsActionsContainer>
			<InsightsActionsWrapper />
		</InsightsActionsContainer>
		<InsightsUIContainer>
			<InsightsUI
				scrollableRef={scrollableContainerRef}
				issueId={issueId}
				localIssueId={localIssueId}
			/>
		</InsightsUIContainer>
	</>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InsightsActionsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	position: 'sticky',
	top: '33px',
	background: token('elevation.surface', 'white'),
	zIndex: 10,
	padding: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InsightsUIContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	padding: `0 ${token('space.100', '8px')}`,
});
