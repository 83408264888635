import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipDateOverridenWithLatest: {
		id: 'polaris-common.fields.delivery-date.tooltip-date-overriden-with-latest',
		defaultMessage: 'Latest date from linked delivery tickets',
		description: 'Tooltip for delivery date field when overriden with latest date',
	},
	tooltipDateOverridenWithEarliest: {
		id: 'polaris-common.fields.delivery-date.tooltip-date-overriden-with-earliest',
		defaultMessage: 'Earliest date from linked delivery tickets',
		description: 'Tooltip for delivery date field when overriden with earliest date',
	},
});
