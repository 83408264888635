import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noContributionsHeader: {
		id: 'polaris-common.plays.no-contributions.no-contributions-header',
		defaultMessage: 'No one has voted yet',
		description: 'Header for no contributions message',
	},
	noOtherContributionsHeader: {
		id: 'polaris-common.plays.no-contributions.no-other-contributions-header',
		defaultMessage: 'No one else has voted yet',
		description: 'Header for no other contributions message',
	},
	noContributionsText: {
		id: 'polaris-common.plays.no-contributions.no-contributions-text',
		defaultMessage: "You'll see other votes here once someone votes for this idea",
		description: 'Default text if no one voted yet',
	},
	noOtherContributionsText: {
		id: 'polaris-common.plays.no-contributions.no-other-contributions-text',
		defaultMessage: "You'll see other votes here once someone else votes for this idea",
		description: 'Default text if no one else voted yet ',
	},
});
