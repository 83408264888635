import React from 'react';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useGroupOptions } from '../../../view-content/common/utils/group-options';
import { useExtendedVerticalGroupOptions } from '../../../view-content/common/utils/vertical-group-options';
import { Values } from '../../common/field-values';

type GroupOptionsProps = {
	field: Field;
	isDisabled?: boolean;
};

type VerticalGroupOptionsProps = GroupOptionsProps;

export const GroupOptions = ({ field, isDisabled }: GroupOptionsProps) => {
	const { setGroupValues } = useViewActions();
	const groupOptions = useGroupOptions(field, true);

	return (
		<Values
			field={field}
			setOptions={setGroupValues}
			options={groupOptions}
			isDisabledDnD={isDisabled}
			isHideButtonVisible={!isDisabled}
		/>
	);
};

export const VerticalGroupOptions = ({ field, isDisabled }: VerticalGroupOptionsProps) => {
	const { setVerticalGroupValues } = useViewActions();
	const verticalGroupOptions = useExtendedVerticalGroupOptions(field, true);

	return (
		<Values
			field={field}
			setOptions={setVerticalGroupValues}
			options={verticalGroupOptions}
			isDisabledDnD={isDisabled}
			isHideButtonVisible={!isDisabled}
		/>
	);
};
