import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export const moveGlobalFieldOption = async (
	fieldKey: string,
	contextId: number,
	optionIds: string[],
): Promise<void> => {
	await performPutRequest(`/rest/api/3/field/${fieldKey}/context/${contextId}/option/move`, {
		body: JSON.stringify({
			customFieldOptionIds: optionIds,
			position: 'First',
		}),
	});
};
