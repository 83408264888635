import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelTextComponent = styled.div({
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	margin: 0,
	padding: `${token('space.250', '20px')} 0px ${token('space.050', '4px')} 0px`,
});

export const LabelText = (props: ComponentPropsWithoutRef<typeof LabelTextComponent>) => (
	<Heading level="h200">
		<LabelTextComponent {...props} />
	</Heading>
);
