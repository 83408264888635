import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectMultiValueLabelComponentProps = any;

export type MultiValueLabelComponentProps = {
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
};

export const createMultiValueLabelComponent =
	({ DecoratedTagComponent }: MultiValueLabelComponentProps) =>
	({ innerRef, innerProps, data }: SelectMultiValueLabelComponentProps) => {
		const { id } = data;

		return (
			<MultiValueDecorationItemContainer ref={innerRef} {...innerProps}>
				<DecoratedTagComponent id={id} compact />
			</MultiValueDecorationItemContainer>
		);
	};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueDecorationItemContainer = styled.div({
	lineHeight: '16px',
	minHeight: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	margin: '0px !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '0px !important',
	display: 'inline-block',
});
