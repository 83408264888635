import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { IssueTypeStatusesResponse } from './types';

const getSharingStatusesUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v1'
	}/views/${viewUUID}/sharing/issue-type-statuses`;

export const fetchSharingViewStatuses = (viewUUID: string): Promise<IssueTypeStatusesResponse> =>
	fetchJson(getSharingStatusesUrl(viewUUID));
