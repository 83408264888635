import union from 'lodash/union';
import without from 'lodash/without';
import { fg } from '@atlassian/jira-feature-gating';
import { hasFieldKeyDependingOnDeliveryData } from '@atlassian/jira-polaris-domain-field/src/delivery/index.tsx';
import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getDeliveryDataFetchError } from '../../selectors/meta';
import {
	getCurrentViewFields,
	getCurrentViewFieldKeys,
	getCurrentViewHiddenFieldKeys,
} from '../../selectors/view';
import { getCurrentViewSummaryCardField } from '../../selectors/view/timeline/index.tsx';
import { getPinnedViewId } from '../../selectors/view/view-sets/index.tsx';
import type { State, Props } from '../../types';
import { showStoredDeliveryDataFetchError } from '../delivery-data';
import { saveViewWithAutoSave } from '../save';
import { setSummaryCardField } from '../timeline';
import { fireViewUpdatedEvent } from '../utils/analytics';
import { updateViewState } from '../utils/state';
import { currentViewFilter } from '../utils/views';

type ViewSelector = (view: View) => boolean;

type AfterSaveCb = (view: View | undefined) => void;

type updateViewProps = {
	fields?: FieldKey[];
	hidden?: FieldKey[];
};

const updateView =
	(
		{ fields, hidden }: updateViewProps,
		viewSelectorPredicate?: ViewSelector,
		onAfterSave?: AfterSaveCb,
	): Action<State, Props> =>
	async ({ dispatch, getState, setState }, props) => {
		const predicate =
			viewSelectorPredicate !== undefined
				? viewSelectorPredicate
				: currentViewFilter(props.currentViewSlug);
		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			predicate,
			(view: View): View => {
				const changedProperties = {
					...(fields ? { fields } : {}),
					...(hidden ? { hidden } : {}),
				};

				return {
					...view,
					...(view.isAutosaveEnabled ? changedProperties : {}),
					modified: view.isAutosaveEnabled,
					draft: {
						...view.draft,
						...changedProperties,
					},
				};
			},
		);

		if (changedView) {
			setState({ viewSets });

			const updatedItems = [];
			const hiddenLengthChanged = currentView?.hidden.length !== changedView?.hidden.length;
			const fieldsLengthChanged = currentView?.fields.length !== changedView?.fields.length;
			const fieldsReoredred = !hiddenLengthChanged && !fieldsLengthChanged;

			if (
				getDeliveryDataFetchError(getState()) &&
				hasFieldKeyDependingOnDeliveryData(changedView.fields, props.fields)
			) {
				dispatch(showStoredDeliveryDataFetchError());
			}

			if (hiddenLengthChanged) {
				updatedItems.push({
					name: 'hidden',
					oldVal: currentView?.hidden,
					newVal: changedView.hidden,
				});
			}

			if (fieldsLengthChanged || fieldsReoredred) {
				updatedItems.push({
					name: 'fields',
					oldVal: currentView?.fields,
					newVal: changedView.fields,
				});
			}

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, { updatedItems });

			dispatch(saveViewWithAutoSave(changedView.id, onAfterSave));
		}
	};

export const setViewColumns =
	(fields: Field[], viewSelectorPredicate?: ViewSelector): Action<State, Props> =>
	async ({ getState, setState, dispatch }, { currentViewSlug, createAnalyticsEvent }) => {
		const state = getState();
		const fieldsKeys = fields.map(({ key }) => key);
		if (fieldsKeys.length > 0) {
			const predicate =
				viewSelectorPredicate !== undefined
					? viewSelectorPredicate
					: currentViewFilter(currentViewSlug);
			const { changedView, currentView, viewSets } = updateViewState(
				state.viewSets,
				predicate,
				(view: View): View => {
					const pinnedViewId = getPinnedViewId(state);
					const isCurrentViewPinned = view.id === pinnedViewId;
					const isAutosaveEnabled = isCurrentViewPinned || view.isAutosaveEnabled;

					return {
						...view,
						fields: isAutosaveEnabled ? fieldsKeys : view.fields,
						modified: isAutosaveEnabled,
						draft: {
							...view.draft,
							fields: fieldsKeys,
						},
					};
				},
			);

			if (changedView) {
				setState({ viewSets });

				fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
					updatedItems: [
						{
							name: 'fields',
							oldVal: currentView?.fields,
							newVal: changedView.fields,
						},
					],
				});

				dispatch(saveViewWithAutoSave(changedView.id));
			}
		}
	};

export const removeFieldFromView =
	(fieldKey?: FieldKey): Action<State, Props> =>
	({ getState, dispatch }, props) => {
		if (fieldKey === undefined) {
			return;
		}

		const currentFields = getCurrentViewFields(getState(), props);
		const newFields = currentFields.filter((field) => field.key !== fieldKey) || [];

		dispatch(setViewColumns(newFields));
	};

type SortViewFieldProps = {
	fieldKey: FieldKey;
	movedToKey: FieldKey;
};

export const sortViewField =
	({ fieldKey, movedToKey }: SortViewFieldProps): Action<State, Props> =>
	async ({ getState, dispatch }, props) => {
		const currentViewFieldKeys = getCurrentViewFieldKeys(getState(), props);

		const sortedFields = [...currentViewFieldKeys];
		const oldIndex = sortedFields.indexOf(fieldKey);
		const newIndex = sortedFields.indexOf(movedToKey);

		if (fg('polaris_fix_fields_drag_drop_runtime_error')) {
			if (oldIndex === -1 && newIndex === -1) {
				sortedFields.push(fieldKey);
			} else if (oldIndex > -1) {
				sortedFields.splice(oldIndex, 1);
				sortedFields.splice(newIndex, 0, fieldKey);
			} else if (newIndex > -1) {
				sortedFields.splice(newIndex, 0, fieldKey);
			}
		} else {
			sortedFields.splice(oldIndex, 1);
			sortedFields.splice(newIndex, 0, fieldKey);
		}

		dispatch(updateView({ fields: sortedFields }));
	};

type ToggleViewFieldProps = {
	fieldKey: FieldKey;
	isChecked: boolean;
};

export const toggleViewField =
	(
		{ fieldKey, isChecked }: ToggleViewFieldProps,
		viewSelectorPredicate?: ViewSelector,
		onAfterSave?: AfterSaveCb,
	): Action<State, Props> =>
	async ({ getState, dispatch }, props) => {
		const currentViewFieldKeys = getCurrentViewFieldKeys(getState(), props);
		const fields = isChecked
			? union(without(currentViewFieldKeys, fieldKey), [fieldKey])
			: without(currentViewFieldKeys, fieldKey);

		const currentViewHiddenFieldKeys = getCurrentViewHiddenFieldKeys(getState(), props);
		const hidden = isChecked
			? without(currentViewHiddenFieldKeys, fieldKey)
			: without(currentViewHiddenFieldKeys, fieldKey);

		dispatch(updateView({ fields, hidden }, viewSelectorPredicate, onAfterSave));

		const summaryCardField = getCurrentViewSummaryCardField(getState(), props);

		if (!isChecked && summaryCardField?.key === fieldKey) {
			dispatch(setSummaryCardField(undefined));
		}
	};

export const hideViewField =
	(
		fieldKey: FieldKey,
		viewSelectorPredicate?: ViewSelector,
		onAfterSave?: AfterSaveCb,
	): Action<State, Props> =>
	async ({ getState, dispatch }, props) => {
		const currentViewHiddenFieldKeys = getCurrentViewHiddenFieldKeys(getState(), props);
		const hidden = currentViewHiddenFieldKeys.includes(fieldKey)
			? without(currentViewHiddenFieldKeys, fieldKey)
			: union(currentViewHiddenFieldKeys, [fieldKey]);

		dispatch(updateView({ hidden }, viewSelectorPredicate, onAfterSave));
	};
