import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unavailable: {
		id: 'polaris-common.fields.external-reference.common.unavailable.unavailable',
		defaultMessage: 'Unavailable',
		description: 'Title of unavailable button',
	},
	refresh: {
		id: 'polaris-common.fields.external-reference.common.unavailable.refresh',
		defaultMessage: 'Refresh',
		description: 'Title of refresh button',
	},
});
