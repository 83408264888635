import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueRule,
	ValueOperator,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';

export type DecorationRange = {
	type: 'BT' | 'LT' | 'GT' | undefined;
	ltRule: ValueRule | undefined;
	gtRule: ValueRule | undefined;
};

export const getRangeTypeFromRules = (rules: ValueRule[]): DecorationRange => {
	const { LT, LTE, GT, GTE } = ValueRuleOperator;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const ltRule = rules.find(({ operator }) => ([LT, LTE] as ValueOperator[]).includes(operator));
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const gtRule = rules.find(({ operator }) => ([GT, GTE] as ValueOperator[]).includes(operator));
	let type;
	if (gtRule === undefined && ltRule !== undefined) {
		type = 'LT' as const;
	}
	if (gtRule !== undefined && ltRule !== undefined) {
		type = 'BT' as const;
	}
	if (gtRule !== undefined && ltRule === undefined) {
		type = 'GT' as const;
	}
	return {
		type,
		ltRule,
		gtRule,
	};
};
