import React, { useCallback, useMemo } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Text, Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { DisplayMode } from '@atlassian/jira-polaris-domain-field/src/field/people/types.tsx';
import { useCallbacks } from '../../../controllers/selectors/callback-hooks';
import { useReadonly } from '../../../controllers/selectors/config-hooks';
import { useFieldPeopleDisplayMode } from '../../../controllers/selectors/field-hooks';
import AvatarImgDark from './assets/avatar-dark.png';
import AvatarImg from './assets/avatar.png';
import FullnameImgDark from './assets/fullname-dark.png';
import FullnameImg from './assets/fullname.png';
import messages from './messages';
import type { Mode } from './types';

const ReadonlyOption = ({ displayMode }: { displayMode: DisplayMode }) => {
	const { formatMessage } = useIntl();
	return (
		<Box paddingBlock="space.100">
			<Text>{formatMessage(messages[displayMode])}</Text>
		</Box>
	);
};

export const PeopleConfiguration = () => {
	const { formatMessage } = useIntl();
	const displayMode = useFieldPeopleDisplayMode();
	const isReadonly = useReadonly();
	const { onFieldPeopleDisplayModeUpdated } = useCallbacks();

	const defaultMode = useMemo<Mode>(
		() => ({
			label: formatMessage(messages.avatarOnlyLabel),
			value: 'avatarOnly',
		}),
		[formatMessage],
	);

	const options = useMemo<Mode[]>(
		() => [
			defaultMode,
			{
				label: formatMessage(messages.fullNameWithAvatarLabel),
				value: 'fullNameWithAvatar',
			},
		],
		[formatMessage, defaultMode],
	);

	const selected = displayMode ?? defaultMode.value;

	const handleSelect = useCallback(
		(item: Mode) => {
			onFieldPeopleDisplayModeUpdated?.(item.value);
		},
		[onFieldPeopleDisplayModeUpdated],
	);

	const ImgSrc = useMemo(() => {
		switch (selected) {
			case 'fullNameWithAvatar':
				return { light: FullnameImg, dark: FullnameImgDark };
			default:
				return { light: AvatarImg, dark: AvatarImgDark };
		}
	}, [selected]);

	return (
		<>
			<Heading size="xsmall">{formatMessage(messages.optionsHeader)}</Heading>
			{isReadonly ? (
				<ReadonlyOption displayMode={selected} />
			) : (
				<>
					<Box xcss={descriptionContainerStyles}>
						<Text>{formatMessage(messages.displayModeDescription)}</Text>
					</Box>
					<Box xcss={dropdownContainerStyles}>
						<DropdownMenu trigger={formatMessage(messages[`${selected}Label`])}>
							<DropdownItemGroup>
								{options.map((option) => (
									<DropdownItem
										isDisabled={isReadonly}
										key={option.value}
										isSelected={option.value === selected}
										onClick={() => handleSelect(option)}
									>
										{option.label}
									</DropdownItem>
								))}
							</DropdownItemGroup>
						</DropdownMenu>
					</Box>
				</>
			)}
			<Image
				src={ImgSrc.light}
				srcDark={ImgSrc.dark}
				alt={formatMessage(messages.displayModeAltText)}
			/>
		</>
	);
};

const descriptionContainerStyles = xcss({
	marginTop: 'space.200',
});

const dropdownContainerStyles = xcss({
	marginTop: 'space.100',
	marginBottom: 'space.200',
});
