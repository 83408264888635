import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	showEmptyColumns: {
		id: 'polaris-ideas.view-content.idea-board.on-added-hidden-column.show-empty-columns',
		defaultMessage: 'Show empty columns',
		description: 'Notification action to allow the user to show empty columns.',
	},
	addingColumnSuccess: {
		id: 'polaris-ideas.view-content.idea-board.on-added-hidden-column.adding-column-success',
		defaultMessage: 'Column successfully added',
		description:
			'Notification header that column has been successfully added, but might not be visible. See notification description message for more detail.',
	},
	addingColumnSuccessInvisibleDescription: {
		id: 'polaris-ideas.view-content.idea-board.on-added-hidden-column.adding-column-success-invisible-description',
		defaultMessage:
			"The new field option was created, but the column is hidden because it doesn't have ideas",
		description:
			'Notification description that column has been successfully added, but might not be visible.',
	},
});
