import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { updateIssueInsightsLastViewed } from '../../services/polaris-api/update-issue-insights-last-viewed';
import type { IssueInsightsRemote } from './types';

export const createNotImplementedIssueInsightsRemote = (): IssueInsightsRemote => ({
	updateLastInsightsViewedTimestamp: notImplementedError('updateLastInsightsViewedTimestamp'),
});

export const createIssueInsightsRemote = (
	apolloClient: PolarisApolloClient,
	cloudId: CloudId,
): IssueInsightsRemote => ({
	updateLastInsightsViewedTimestamp: ({ issueId, timestamp, projectId }) =>
		updateIssueInsightsLastViewed(apolloClient, {
			projectId,
			cloudId,
			ideaId: issueId,
			lastInsightsViewedTimestamp: timestamp,
		}).then(() => undefined),
});
