import React, { memo } from 'react';
import { ButtonItem, type CSSFn } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { FieldTitleWithIcon } from '../../field-item-title';
import {
	RollupItemFormulaTypeComponent,
	RollupItemFormulaHelpTextComponent,
} from '../item/field-help-text';
import { RollupItemFieldIconComponent } from '../item/field-icon';
import { messages } from '../messages';
import { TooltipComponent } from '../tooltip';
import type { FieldRollupField } from '../types';

const cssFn: CSSFn = (currentStyles) => ({
	...currentStyles,
	padding: `${token('space.050', '4px')} ${token('space.200', '16px')}`,
});

type MultiselectOptionsProps = {
	fields: Field[];
	isFiltered: (x: string) => boolean;
	onClick: (rf: FieldRollupField) => void;
};

export const MultiselectOptions = memo<MultiselectOptionsProps>(
	({ fields, onClick, isFiltered }: MultiselectOptionsProps) => {
		const { formatMessage } = useIntl();
		const optionFields = fields.filter(
			({ type, label }) =>
				(type === FIELD_TYPES.SINGLE_SELECT ||
					type === FIELD_TYPES.MULTI_SELECT ||
					type === FIELD_TYPES.JSW_MULTI_SELECT) &&
				isFiltered(label),
		);
		if (optionFields.length === 0) return null;
		return (
			<>
				{optionFields.map((field) => (
					<TooltipComponent
						key={field.key}
						header={<FieldTitleWithIcon fieldKey={field.key} />}
						content={
							<RollupItemFormulaHelpTextComponent
								fieldKey={field.key}
								fieldType={field.type}
								helpText={formatMessage(messages.countMultiSelectTypeHelpText)}
							/>
						}
					>
						<ButtonItem
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							iconBefore={<RollupItemFieldIconComponent fieldKey={field.key} />}
							iconAfter={
								<RollupItemFormulaTypeComponent
									fieldKey={field.key}
									typeName={formatMessage(messages.countType)}
								/>
							}
							onClick={() => {
								onClick({
									kind: 'field',
									value: field,
								});
							}}
						>
							{field.label}
						</ButtonItem>
					</TooltipComponent>
				))}
			</>
		);
	},
);
