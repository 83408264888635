import {
	createContainer,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type { AtlasGoal } from '../../services/atlas/goals/types';
import type { AtlasProject } from '../../services/atlas/projects/types';
import actions from './actions';
import type { Props, State } from './types';

type Actions = typeof actions;

const initialState: State = {
	goals: {
		items: {},
		queue: [],
		isLoading: false,
		loaded: false,
		error: null,
	},
	projects: {
		items: {},
		queue: [],
		isLoading: false,
		loaded: false,
		error: null,
	},
};

export const AtlasStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisAtlasStore',
});

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const useAtlasActions = createHook<State, Actions, Props, void>(AtlasStore, {
	selector: null,
});

const handlePropsChange =
	() =>
	({ dispatch }: StoreActionApi<State>) => {
		dispatch(actions.loadGoals());
		dispatch(actions.loadProjects());
	};

export const AtlasContainer = createContainer<State, Actions, Props>(AtlasStore, {
	onInit: handlePropsChange,
	onUpdate: handlePropsChange,
});

export const useAtlasGoals = createHook<
	State,
	Actions,
	{ isLoading: boolean; error: Error | null; data: AtlasGoal[] },
	{ goalAris: string[] }
>(AtlasStore, {
	selector: (state, { goalAris }) => ({
		data: (goalAris || [])
			.map((goalAri) => {
				const item = state.goals.items[goalAri];
				return item === null ? undefined : item;
			})
			.filter((item: AtlasGoal | undefined): item is AtlasGoal => item !== undefined),
		isLoading: state.goals.isLoading,
		error: state.goals.error,
	}),
});

export const useAtlasProjects = createHook<
	State,
	Actions,
	{ isLoading: boolean; error: Error | null; data: AtlasProject[] },
	{ projectAris: string[] }
>(AtlasStore, {
	selector: (state, { projectAris }) => ({
		data: (projectAris || [])
			.map((projectAri) => {
				const item = state.projects.items[projectAri];
				return item === null ? undefined : item;
			})
			.filter((item: AtlasProject | undefined): item is AtlasProject => item !== undefined),
		isLoading: state.projects.isLoading,
		error: state.projects.error,
	}),
});

export const useIsAtlasInitalLoading = createHook(AtlasStore, {
	selector: (state) =>
		(state.goals.isLoading && !state.goals.loaded) ||
		(state.projects.isLoading && !state.projects.loaded),
});
