import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';

const DELETE_INSIGHT_QUERY = gqlTagPolaris`
mutation jira_polaris_DeleteInsight($id: ID!) {
  deletePolarisInsight(id: $id) {
    success
    errors {
      message
    }
  }
}
`;

export const deleteInsight = async (apolloClient: PolarisApolloClient, id: string) => {
	const result = await apolloClient.mutate({
		mutation: DELETE_INSIGHT_QUERY,
		variables: {
			id,
		},
		// @ts-expect-error - TS2345 - Argument of type '{ variables: { id: string; }; notifyOnNetworkStatusChange: boolean; }' is not assignable to parameter of type 'MutationFunctionOptions<jira_polaris_DeleteInsight, jira_polaris_DeleteInsightVariables>'.
		notifyOnNetworkStatusChange: true,
	});

	if (result.errors !== undefined) {
		throw new Error(
			`polaris-insights.delete-insight-error:${result.errors.map((e) => e.message).join(', ')}`,
		);
	}

	if (!result.data?.deletePolarisInsight?.success) {
		throw new Error('polaris-insights.delete-insight-error: no error but not successful either');
	}
};
