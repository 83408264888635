import React, { useMemo, useRef } from 'react';
import { styled } from '@compiled/react';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { useValueDecorationPalette } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks.tsx';
import {
	useLocalIssueIdsForCell,
	useLocalIssueIdsForGroupIdentity,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/grouping-hooks';
import {
	useCurrentViewHideEmptyBoardColumns,
	useCurrentViewLayoutType,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { N100, B400 } from '@atlassian/jira-polaris-lib-color-palette/src/ui/colors/index.tsx';
import { DEFAULT } from '@atlassian/jira-polaris-lib-color-palette/src/ui/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { EMPTY_VALUE_ID } from '../../../../../common/utils/board';
import { CardList } from '../../card-list';
import { Footer } from '../../column/footer';
import {
	BOARD_COLUMN_GAP,
	BOARD_COLUMN_WIDTH,
	BOARD_COLUMN_PADDING,
	BOARD_COLUMN_BORDER_WIDTH,
} from '../../constants';
import { useColumnDND } from '../../utils/draggable';
import { isDraggableCardData } from '../../utils/draggable.types';

export type CellProps<TValue> = {
	isCardDropDisabled: boolean;
	isReadOnly: boolean;
	isFooterVisible: boolean;
	fieldKey: FieldKey;
	fieldValue: TValue;
	verticalFieldKey: FieldKey;
	verticalFieldValue: TValue;
	verticalGroupIdentity: string | undefined;
	groupIdentity: string | undefined;
	droppableId: string;
	index: number;
};

export const Cell = ({
	isCardDropDisabled,
	isReadOnly,
	isFooterVisible,
	fieldKey,
	groupIdentity,
	index,
	fieldValue,
	droppableId,
	verticalFieldKey,
	verticalGroupIdentity,
	verticalFieldValue,
}: CellProps<unknown>) => {
	const cellContainerRef = useRef<HTMLDivElement | null>(null);

	const ideaIds = useLocalIssueIdsForCell(
		fieldKey,
		groupIdentity,
		verticalFieldKey,
		verticalGroupIdentity,
	);

	const columnIdeaIds = useLocalIssueIdsForGroupIdentity(fieldKey, groupIdentity);
	const hideEmptyColumns = useCurrentViewHideEmptyBoardColumns();

	const isEmptyColumn = useMemo(() => !columnIdeaIds.length, [columnIdeaIds.length]);

	const analyticAttributes = {
		columnNoValue: fieldValue === undefined,
		columnIndex: index,
		columnCardDropDisabled: isCardDropDisabled,
		columnCardCount: ideaIds.length,
	};

	const { colorMode } = useThemeObserver();
	const theme = colorMode === 'dark' ? 'dark' : 'light';
	const columnValueDecorationPalette = useValueDecorationPalette(fieldKey, groupIdentity, theme);
	const viewLayoutType = useCurrentViewLayoutType();
	const canDrop: Parameters<typeof useColumnDND>[0]['canDrop'] = ({ source }) =>
		isDraggableCardData(source.data) && !isCardDropDisabled;
	const { isCardBeingDraggedOver } = useColumnDND({
		draggableId: droppableId,
		droppableId,
		index,
		columnContainerRef: cellContainerRef,
		ideaIds,
		canDrop,
	});

	return (
		<ContextualAnalyticsData attributes={analyticAttributes}>
			<CellContainer
				data-testid="polaris-ideas.ui.view-content.idea-board.swimlanes.cell.column"
				ref={cellContainerRef}
				backgroundColor={columnValueDecorationPalette.highlightColor}
				isNoValueColumn={groupIdentity === undefined}
				isHidden={hideEmptyColumns && isEmptyColumn}
				isBeingDraggedOver={isCardBeingDraggedOver}
			>
				<CardList
					groupIdentity={verticalGroupIdentity}
					columnIdentity={`${verticalGroupIdentity || EMPTY_VALUE_ID}.${
						groupIdentity || EMPTY_VALUE_ID
					}`}
					droppableId={droppableId}
					isReadOnly={isReadOnly}
					viewLayoutType={viewLayoutType}
				/>
				{!isReadOnly && isFooterVisible && (
					<Footer
						groupIdentity={groupIdentity}
						fieldKey={fieldKey}
						fieldValue={fieldValue}
						fullWidth
						verticalFieldKey={verticalFieldKey}
						verticalFieldValue={verticalFieldValue}
						verticalGroupIdentity={verticalGroupIdentity}
					/>
				)}
			</CellContainer>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CellContainer = styled.div<{
	isNoValueColumn: boolean;
	backgroundColor: string;
	isHidden: boolean;
	isBeingDraggedOver: boolean;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${BOARD_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${BOARD_COLUMN_WIDTH}px`,
	flex: '1 1 auto',
	minHeight: '100px',
	boxSizing: 'border-box',
	borderRadius: '5px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `${BOARD_COLUMN_PADDING}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isHidden }) => (isHidden ? 'none' : 'flex'),
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-child)': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginRight: `${BOARD_COLUMN_GAP}px`,
	},
	/* eslint-disable no-nested-ternary */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: ({ isNoValueColumn, backgroundColor }) =>
		isNoValueColumn
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.background.neutral', N100)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor === undefined || backgroundColor === DEFAULT.highlightColor
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.neutral', N100)
				: backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: ({ isBeingDraggedOver }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${BOARD_COLUMN_BORDER_WIDTH}px solid ${
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isBeingDraggedOver ? token('color.border.brand', B400) : 'transparent'
		}`,
	/* eslint-enable no-nested-ternary */
});
