import React from 'react';
import { styled } from '@compiled/react';
import { Emoji } from '@atlaskit/emoji';
import { Flex, Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { ISSUEID_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { GlobalFieldMarker } from '@atlassian/jira-polaris-lib-global-field-marker/src/ui/index.tsx';
import {
	useFieldEmoji,
	useFieldLabel,
	useFieldTypeIcon,
	useIsGlobalCustomField,
} from '../../../../../controllers/field/selectors/field-hooks';
import { EmojiWrapper } from '../../../../field-config/item/emoji-wrapper';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
};

export const FieldSelectOptionComponent = ({ fieldKey }: Props) => {
	const { formatMessage } = useIntl();

	let label = useFieldLabel(fieldKey);
	const icon = useFieldTypeIcon(fieldKey, undefined);
	const emojiId = useFieldEmoji(fieldKey);
	const emoji = useEmoji(emojiId);
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);

	if (fieldKey === ISSUEID_FIELDKEY) {
		label = formatMessage(messages.excludeIdeas);
	}

	return (
		<Flex alignItems="center">
			{emoji ? (
				<EmojiWrapper>
					<Emoji emoji={emoji} fitToHeight={16} showTooltip />
				</EmojiWrapper>
			) : (
				<Box xcss={iconContainerStyles}>{icon}</Box>
			)}
			<OptionContent>{label}</OptionContent>
			{isGlobalCustomField && <GlobalFieldMarker />}
		</Flex>
	);
};

const iconContainerStyles = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	minWidth: 0,
});
