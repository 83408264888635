import { isNotAutomaticId } from '../utils/is-not-automatic-id';
import { isNotEmptyString } from '../utils/is-not-empty-string';

export type ReporterPublicSetValue = null | string;

export type ReporterPublicGetValue = null | {
	accountId: string;
};

export const reporterSetValueValidator = (value: unknown): value is ReporterPublicSetValue =>
	// We should omit automatic id special value so it won't be picked up by this field
	// It is only applicable to the assignee field
	value === null || (isNotEmptyString(value) && isNotAutomaticId(value));
