import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'polaris-ideas.view-content.idea-list.row.drag-handle.tooltip',
		defaultMessage: 'Reordering is disabled in sorting mode.',
		description:
			'Placeholder message as tooltip on drag-handle when dragging is not allowed b/c of sorting is enabled.',
	},
});
