import React from 'react';
import { MatrixContainer } from './controllers';
import type { Props } from './types';
import { Main } from './ui';

export const Matrix = ({
	items,
	xAxis,
	yAxis,
	zAxis,
	highlightedItem,
	components,
	emptyState,
	viewId,
	onMoveItems,
	onSortAxisOptions,
	xAxisLocked,
	yAxisLocked,
	isAxesDragDisabled,
	isSidebarOpen,
	isItemsDragDisabled,
}: Props) => (
	<MatrixContainer
		items={items}
		xAxis={xAxis}
		yAxis={yAxis}
		zAxis={zAxis}
		highlightedItem={highlightedItem}
		components={components}
		viewId={viewId}
		emptyState={emptyState}
		onSortAxisOptions={onSortAxisOptions}
		onMoveItems={onMoveItems}
		xAxisLocked={xAxisLocked}
		yAxisLocked={yAxisLocked}
		isAxesDragDisabled={isAxesDragDisabled}
	>
		<Main isSidebarOpen={isSidebarOpen} isItemsDragDisabled={isItemsDragDisabled} />
	</MatrixContainer>
);
