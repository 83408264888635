import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { HighlightedField } from '../types';
import {
	createGetHighlightedDecorations,
	createGetHighlightedDecorationsForList,
	createGetHighlightedFields,
} from './decorations';
import { createHigherLevelIssueHook } from './utils';

export const useHighlightedFields = createHigherLevelIssueHook<LocalIssueId, HighlightedField[]>(
	createGetHighlightedFields,
);

export const useHighlightedDecorations = createHigherLevelIssueHook<
	LocalIssueId,
	ValueDecoration[]
>(createGetHighlightedDecorations);

export const useHighlightedDecorationsForList = createHigherLevelIssueHook<
	LocalIssueId[],
	ValueDecoration[]
>(createGetHighlightedDecorationsForList);
