import { Observable, type Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { fetchJson$ } from '@atlassian/jira-fetch';
import { fetchSubtaskTypesPerf } from '@atlassian/jira-issue-view-common-constants';
import type { HeadersProcessor } from '@atlassian/jira-issue-view-common-types/src/analytics-types';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils';
import type { BaseUrl, Href, ProjectKey } from '@atlassian/jira-shared-types';
import { getProjectCreateMetaUrl } from './issue-urls';

export type ServerIssueType = {
	self: string;
	id: number;
	description: string;
	iconUrl: string;
	name: string;
	subtask: boolean;
	expand: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fields: any;
};

export type IssueAndSubtaskType = {
	subtaskTypes: ServerIssueType[];
	issueTypes: ServerIssueType[];
};

const transformProjectSubtaskTypes = (result: unknown) => {
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	if (result.projects[0]) {
		return {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS7006 - Parameter 'issuetype' implicitly has an 'any' type.
			subtaskTypes: result.projects[0].issuetypes.filter((issuetype) => issuetype.subtask),
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			issueTypes: result.projects[0].issuetypes,
		};
	}
	return {
		subtaskTypes: [],
		issueTypes: [],
	};
};

const fetchSubtaskTypesFromServer = (
	projectCreateMetaUrl: Href,
	headersProcessor?: HeadersProcessor,
) =>
	fetchJson$(projectCreateMetaUrl, {
		perf: fetchSubtaskTypesPerf,
		headersProcessor: headersProcessor && headersProcessor(fetchSubtaskTypesPerf.key),
	})
		.map(transformProjectSubtaskTypes)
		.catch((error) => {
			trackOrLogClientError(
				'issue.issue-base.content.subtasks.type.subtasks-types-epic',
				'Failed to fetch subtask types.',
				error,
			);
			return Observable.of({
				subtaskTypes: [],
				issueTypes: [],
			});
		});

export const fetchSubtaskTypes = (
	baseUrl: BaseUrl,
	projectKey: ProjectKey,
	headersProcessor?: HeadersProcessor,
): ObservableType<IssueAndSubtaskType> => {
	const projectCreateMetaUrl = getProjectCreateMetaUrl(baseUrl, projectKey);
	return fetchSubtaskTypesFromServer(projectCreateMetaUrl, headersProcessor);
};
