import React from 'react';
import { styled } from '@compiled/react';
import { components } from '@atlaskit/select';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ControlComponentProps = any;

export const Control = ({ children, options, isMulti, ...rest }: ControlComponentProps) => {
	const { formatMessage } = useIntl();
	const optionsCount = options[0]?.options?.length;
	const text = formatMessage(messages.optionsCount, { count: optionsCount });
	const tagWrapperTestId =
		'polaris-common.ui.fields.external-reference.edit.components.control.tag-wrapper';
	const componentsWithTagWrapper = (
		<components.Control {...rest}>
			{optionsCount > 0 && (
				<TagWrapper data-testid={tagWrapperTestId}>
					<Tag text={text} color="greyLight" />
				</TagWrapper>
			)}
			{children}
		</components.Control>
	);
	const componentsWithoutTagWrapper = (
		<components.Control {...rest} options={options}>
			{children}
		</components.Control>
	);

	return isMulti ? componentsWithTagWrapper : componentsWithoutTagWrapper;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TagWrapper = styled.div({
	marginLeft: token('space.100', '8px'),
	color: token('color.text.subtle', N500),
});
