import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	showGroupOption: {
		id: 'polaris-ideas.view-controls.common.field-values.show-group-option',
		defaultMessage: 'Show in view',
		description: '',
	},
	filterGroupOption: {
		id: 'polaris-ideas.view-controls.common.field-values.filter-group-option',
		defaultMessage: 'Filter from view',
		description: '',
	},
});
