import { parseISO, isValid } from 'date-fns';
import { renderDateString } from '../../../../../common/utils/date/date-picker';

const isValidIsoDateString = (value: string | undefined) => value && isValid(parseISO(value));

export type Interval = {
	start: string;
	end: string;
};

export const tryParseIntervalString = (value: string): Interval | undefined => {
	if (isValidIsoDateString(value)) {
		return {
			start: value,
			end: value,
		};
	}
	try {
		const interval = JSON.parse(value);
		return isValidIsoDateString(interval.start) && isValidIsoDateString(interval.end)
			? interval
			: undefined;
	} catch (error) {
		return undefined;
	}
};

export const formatIntervalNext = (datetime: string): string => {
	const dateObject: { start: string; end: string } = JSON.parse(datetime);
	return renderDateString(dateObject) ?? '';
};
