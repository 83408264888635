import type { Event } from '../../types';

export const eventPayloadToCompareDuplicates = (event: Event) => {
	switch (event.type) {
		case 'avi:polaris:created:insight':
		case 'avi:polaris:updated:insight': {
			const { insight, updatedTime, ...other } = event.payload;
			return {
				...event,
				payload: {
					...other,
				},
			};
		}
		case 'avi:polaris:created:view':
		case 'avi:polaris:updated:view': {
			const { view, ...other } = event.payload;
			return {
				...event,
				payload: {
					...other,
				},
			};
		}
		default:
			return event;
	}
};
