import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

export const IssueLeftIcon = ({
	iconUrl,
	issueTypeName,
}: {
	iconUrl?: string;
	issueTypeName?: string;
}) => {
	const { formatMessage } = useIntl();
	return (
		<img
			src={iconUrl}
			alt={formatMessage(messages.issueIconAltText, {
				issueTypeName,
			})}
			height="16"
		/>
	);
};
