import type { MouseEvent } from 'react';

export const isLeftClick = (e: MouseEvent): boolean => e.button === 0;

export const isLeftClickOnly = (e: MouseEvent): boolean =>
	isLeftClick(e) && !(e.metaKey || e.ctrlKey);

export const isMiddleClick = (e: MouseEvent): boolean => e.button === 1;

export const isCmdOrCtrlClick = (e: MouseEvent): boolean =>
	isLeftClick(e) && (e.metaKey || e.ctrlKey);

export const shouldOpenInNewTab = (e: MouseEvent): boolean =>
	isCmdOrCtrlClick(e) || isMiddleClick(e);
