import React, { forwardRef } from 'react';
import { ListContainer } from './controllers';
import type { Props, ListApi } from './types';
import { Table } from './ui';

export const List = forwardRef<ListApi | undefined, Props>(
	(
		{
			rows,
			rowGroups,
			groupedIds,
			selectedRows,
			updatedRows,
			alwaysHighlightUpdatedRows,
			columns,
			fixedColumns,
			hoverableColumns,
			components,
			columnSizes,
			columnMinSizes,
			onItemRanked,
			onItemGroupChanged,
			onGroupOrderChanged,
			onColumnRankEnd,
			highlightedRow,
			onColumnResize,
			onSelectionChanged,
			onRowDragUpdate,
			onUpdatedRowsSeen,
			onOpenIdeaPreview,
			onHoverActiveCellColumn,
			onReadyToExport,
			isExporting = false,
			hideEmptyGroups = false,
			isMoveBetweenGroupsDisabled,
			isHidden,
			onReady,
			displayMode,
			shouldRenderRowPinnedBottomForGroups,
			shouldRenderRowPinnedBottomForNoGroups,
		},
		ref,
	) => (
		<ListContainer
			rows={rows}
			rowGroups={rowGroups}
			groupedIds={groupedIds}
			columns={columns}
			fixedColumns={fixedColumns}
			hoverableColumns={hoverableColumns}
			components={components}
			columnSizes={columnSizes}
			columnMinSizes={columnMinSizes}
			selectedRows={selectedRows}
			highlightedRow={highlightedRow}
			updatedRows={updatedRows}
			onColumnRankEnd={onColumnRankEnd}
			onColumnResize={onColumnResize}
			onHoverActiveCellColumn={onHoverActiveCellColumn}
			onSelectionChanged={onSelectionChanged}
			onItemRanked={onItemRanked}
			onItemGroupChanged={onItemGroupChanged}
			onGroupOrderChanged={onGroupOrderChanged}
			onUpdatedRowsSeen={onUpdatedRowsSeen}
			onOpenIdeaPreview={onOpenIdeaPreview}
			onRowDragUpdate={onRowDragUpdate}
			isExporting={isExporting}
			hideEmptyGroups={hideEmptyGroups}
			alwaysHighlightUpdatedRows={alwaysHighlightUpdatedRows}
			displayMode={displayMode}
			shouldRenderRowPinnedBottomForGroups={shouldRenderRowPinnedBottomForGroups}
			shouldRenderRowPinnedBottomForNoGroups={shouldRenderRowPinnedBottomForNoGroups}
		>
			<Table
				onReady={onReady}
				ref={ref}
				isMoveBetweenGroupsDisabled={isMoveBetweenGroupsDisabled}
				onReadyToExport={onReadyToExport}
				isHidden={isHidden}
			/>
		</ListContainer>
	),
);

List.displayName = 'List';
