import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	valueEmptyError: {
		id: 'polaris-common.common.decoration.decoration.select.field-decorator.value-empty-error',
		defaultMessage: 'Name must not be empty',
		description: 'Error message for trying to create an option with an empty name',
	},
	valueDuplicateError: {
		id: 'polaris-common.common.decoration.decoration.select.field-decorator.value-duplicate-error',
		defaultMessage: 'No duplicate names are allowed',
		description: 'Error message for trying to create an option a name that already exists',
	},
	valueTooLongError: {
		id: 'polaris-common.common.decoration.decoration.select.field-decorator.value-too-long-error',
		defaultMessage: "You've reached the 255 character limit",
		description: 'Error message for too long option field value',
	},
});
