import React, { useCallback } from 'react';
import { useOpenIdeaViewFieldSidebar } from '@atlassian/jira-polaris-common/src/controllers/idea/utils/idea-view';
import { useIsIssueArchived } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { SingleSelectField } from '@atlassian/jira-polaris-common/src/ui/fields/select';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useListPortalElement } from '../../../portal';
import { useFieldUpdate, useIsEditable } from '../../utils';

type EditableSingleSelectFieldProps = {
	localIssueId: LocalIssueId;
	fieldKey: string;
	interactive: boolean;
};

export const EditableSingleSelectField = ({
	localIssueId,
	fieldKey,
	interactive,
}: EditableSingleSelectFieldProps) => {
	const isEditable = useIsEditable(fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	const portalElement = useListPortalElement();
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const openIdeaViewFieldSidebar = useOpenIdeaViewFieldSidebar(localIssueId);
	const onConfigRequested = useCallback(
		(optionId?: string) => {
			openIdeaViewFieldSidebar(fieldKey, optionId);
		},
		[openIdeaViewFieldSidebar, fieldKey],
	);

	return (
		<SingleSelectField
			localIssueId={localIssueId}
			fieldKey={fieldKey}
			isActive={interactive}
			isEditable={isEditable && !isIdeaArchived}
			onUpdate={onUpdate}
			onConfigRequested={onConfigRequested}
			menuPortalTarget={portalElement}
		/>
	);
};
