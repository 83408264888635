import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

export const messages: {
	header: MessageDescriptor;
	noFilters: MessageDescriptor;
	filters: MessageDescriptor;
	clearFilter: MessageDescriptor;
} = defineMessages({
	header: {
		id: 'polaris-ideas.view-content.idea-list.empty-view.header',
		defaultMessage: 'No ideas to show',
		description: 'Shown if the ideas view is empty',
	},
	noFilters: {
		id: 'polaris-ideas.view-content.idea-list.empty-view.no-filters',
		defaultMessage: 'There are no ideas to show on this view.',
		description: 'Shown if there are no ideas to show on the view with no filters set',
	},
	filters: {
		id: 'polaris-ideas.view-content.idea-list.empty-view.filters',
		defaultMessage: 'Some ideas might not be visible due to filters',
		description:
			'Shown if the ideas view does not have any ideas because everything is filtered out',
	},
	clearFilter: {
		id: 'polaris-ideas.view-content.idea-list.empty-view.clear-filter',
		defaultMessage: 'Clear Filters',
		description:
			'Button shown if ideas view does not have ideas because of filters and allows the filters to be cleared',
	},
});
