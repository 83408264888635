import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-common.apps.apps.zendesk.title',
		defaultMessage: 'Zendesk',
		description: 'Title of the polaris zendesk app',
	},
	description: {
		id: 'polaris-common.apps.apps.zendesk.description',
		defaultMessage:
			'Add support tickets and filters to ideas to keep track of important problems to fix',
		description: 'Description of the polaris zendesk app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.zendesk.unfurl-dialog-title',
		defaultMessage: 'Connect to Zendesk',
		description: 'Title of the Zendesk try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.zendesk.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to a Zendesk ticket or filter here',
		description: 'Placeholder of the Zendesk try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.zendesk.unfurl-dialog-success',
		defaultMessage:
			'This is what it looks like when you add a Zendesk filter or ticket to an idea.',
		description: 'Success message of Zendesk the try-it-now unfurl dialog',
	},
});
