import { useMemo } from 'react';
import filter from 'lodash/filter';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	createActionsHook,
	createContainer,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { actions } from './actions';
import type { EditionMeta, IssueLinkType, Props, State } from './types';

export type Actions = typeof actions;

const initialState: State = {
	issueLinkTypes: [],
	polarisDeliveryIssueLinkType: undefined,
	polarisMergeIssueLinkType: undefined,
	polarisDataPointLinkType: undefined,
	epicNameFieldKey: undefined,
	editionMeta: {
		beta: undefined,
		siteCreator: false,
		canSelfServe: false,
		isSelfServeEnabledOnSite: false,
		isEnforcePermissionChecksEnabledOnSite: false,
	},
	meta: {
		errors: {
			issueLinkTypes: undefined,
		},
		loadingProps: undefined,
	},
	isEditorAiEnabled: false,
};

export const InstanceStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisInstanceStore',
});

export const useInstanceData = createHook(InstanceStore);

const onPropsChange = (
	{ dispatch, getState, setState }: StoreActionApi<State>,
	{ polarisIssueLinkType }: Props,
) => {
	const newLoadingProps = { polarisIssueLinkType };
	if (
		polarisIssueLinkType === undefined ||
		isShallowEqual(newLoadingProps, getState().meta.loadingProps)
	) {
		return;
	}

	setState({
		meta: {
			...getState().meta,
			loadingProps: newLoadingProps,
		},
		polarisDeliveryIssueLinkType: String(polarisIssueLinkType.delivery),
		polarisMergeIssueLinkType: String(polarisIssueLinkType.merge),
		polarisDataPointLinkType: String(polarisIssueLinkType.datapoint),
	});

	dispatch(actions.load());
};

export const InstanceContainer = createContainer<State, Actions, Props>(InstanceStore, {
	onInit: () => onPropsChange,
	onUpdate: () => onPropsChange,
});

export const useIssueLinkTypes = (): IssueLinkType[] => {
	const [
		{
			issueLinkTypes,
			polarisDeliveryIssueLinkType,
			polarisDataPointLinkType,
			polarisMergeIssueLinkType,
		},
	] = useInstanceData();
	return useMemo(
		() =>
			filter(
				issueLinkTypes,
				(linkType: IssueLinkType) =>
					linkType.id !== polarisMergeIssueLinkType &&
					linkType.id !== polarisDeliveryIssueLinkType &&
					linkType.id !== polarisDataPointLinkType,
			),
		[
			issueLinkTypes,
			polarisDeliveryIssueLinkType,
			polarisDataPointLinkType,
			polarisMergeIssueLinkType,
		],
	);
};

export const useHiddenPolarisIssueLinkTypes = (): string[] => {
	const [{ polarisDeliveryIssueLinkType, polarisDataPointLinkType }] = useInstanceData();
	return useMemo(
		() => [polarisDeliveryIssueLinkType, polarisDataPointLinkType].filter(Boolean),
		[polarisDeliveryIssueLinkType, polarisDataPointLinkType],
	);
};

export const useArjLicensed = (): boolean => {
	const tenantContext = useTenantContext();
	// We need to check for the JSW edition here. For commerce reasons JPD will always be on free.
	const application = getApplicationForProject(SOFTWARE_PROJECT);
	const edition = getEdition(application, tenantContext.appEditions);
	return edition === PREMIUM_EDITION;
};

export const useEditionMeta = (): EditionMeta => {
	const [{ editionMeta }] = useInstanceData();

	return editionMeta;
};

export const useIssueLinkingEnabled = () => {
	const [{ meta }] = useInstanceData();

	return meta.errors?.issueLinkTypes?.statusCode !== 404;
};

export const useEditorAiEnabled = () => {
	const [{ isEditorAiEnabled }] = useInstanceData();

	return isEditorAiEnabled;
};

export const useInstanceActions = createActionsHook(InstanceStore);
