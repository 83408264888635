import React, { type ReactNode, useMemo, type ReactElement } from 'react';
import {
	ProviderFactory,
	type ContextIdentifierProvider,
	type MediaProvider,
} from '@atlaskit/editor-common/provider-factory';
import type { IssueKey, ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import { AdfSkeleton, type AdfSkeletonProps } from '../../common/ui/adf-skeleton';
import type { PolarisAdfConsumerProps, PolarisAdfConfiguration } from './types';
import {
	IssuelessAdfProvider,
	PolarisAdfProvider,
	IdeaCreateAdfProvider,
	ContainerlessAdfProvider,
} from './utils/provider';
import type { GetMediaContext, IdeaCreateGetMediaContext } from './utils/types';

type State = {
	data: PolarisAdfConsumerProps;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: Record<string, any> = {};

type Actions = typeof actions;

type Props = {
	data: PolarisAdfConsumerProps;
};

const initialState: State = {
	data: {
		loading: true,
		adfConfiguration: undefined,
	},
};

const AdfControllerStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisAdfControllerStore',
});

const AdfControllerContainer = createContainer<State, Actions, Props>(AdfControllerStore, {
	onInit:
		() =>
		({ setState }, { data }) => {
			setState({ data });
		},
	onUpdate:
		() =>
		({ setState }, { data }) => {
			setState({ data });
		},
});

export const useIsAdfLoading = createHook(AdfControllerStore, {
	selector: ({ data }) => data.loading,
});

export const useAdfConfiguration = createHook(AdfControllerStore, {
	selector: ({ data }) => data.adfConfiguration,
});

type ExternalProps = IssuelessExternalProps & {
	issueKey: IssueKey;
	getMediaContext: GetMediaContext;
	isSharedView?: boolean;
};

export const AdfController = ({ children, ...rest }: ExternalProps) => (
	<>
		<PolarisAdfProvider {...rest}>
			{(consumerProps) => (
				<AdfControllerContainer data={consumerProps}>{children}</AdfControllerContainer>
			)}
		</PolarisAdfProvider>
	</>
);

type IssuelessExternalProps = {
	children: ReactNode;
	projectId: ProjectId;
	projectKey: ProjectKey;
};

export const IssuelessAdfController = ({ children, ...rest }: IssuelessExternalProps) => (
	<IssuelessAdfProvider {...rest}>
		{(consumerProps) => (
			<AdfControllerContainer data={consumerProps}>{children}</AdfControllerContainer>
		)}
	</IssuelessAdfProvider>
);

type ContainerlessExternalProps = {
	children: ReactNode;
};

export const ContainerlessAdfController = ({ children, ...rest }: ContainerlessExternalProps) => (
	<ContainerlessAdfProvider {...rest}>
		{(consumerProps) => (
			<AdfControllerContainer data={consumerProps}>{children}</AdfControllerContainer>
		)}
	</ContainerlessAdfProvider>
);

type IdeaCreateAdfControllerProps = {
	children: ReactNode;
	projectId: ProjectId;
	projectKey: ProjectKey;
	getMediaContext: IdeaCreateGetMediaContext;
};

export const IdeaCreateAdfController = ({ children, ...rest }: IdeaCreateAdfControllerProps) => (
	<IdeaCreateAdfProvider {...rest}>
		{(consumerProps) => (
			<AdfControllerContainer data={consumerProps}>{children}</AdfControllerContainer>
		)}
	</IdeaCreateAdfProvider>
);

const useAdfConsumerPropsForComment = (commentId: string): PolarisAdfConsumerProps => {
	const [loading] = useIsAdfLoading();
	const [adfConfiguration] = useAdfConfiguration();

	return useMemo(() => {
		if ((loading && adfConfiguration === undefined) || adfConfiguration === undefined) {
			return {
				loading: true,
				adfConfiguration: undefined,
			};
		}

		const commentEditorContext = adfConfiguration.akEditorProps.contextIdentifierProvider?.then(
			(context: ContextIdentifierProvider) => ({
				...context,
				childObjectId: commentId,
			}),
		);

		const providerFactory = new ProviderFactory();
		providerFactory.setProvider('emojiProvider', adfConfiguration.emojiProvider);
		providerFactory.setProvider(
			'mentionProvider',
			// @ts-expect-error - TS2345 - Argument of type 'Promise<AbstractMentionProvider | undefined>' is not assignable to parameter of type 'Promise<MentionProvider>'.
			Promise.resolve(adfConfiguration.mentionProvider),
		);
		if (adfConfiguration.mediaProvider !== undefined) {
			providerFactory.setProvider('mediaProvider', adfConfiguration.mediaProvider);
		}
		providerFactory.setProvider(
			'contextIdentifierProvider',
			// @ts-expect-error - Type 'undefined' is not assignable to type 'ContextIdentifierProvider'.
			Promise.resolve(commentEditorContext),
		);

		const contextIdentifierProvider =
			commentEditorContext !== undefined ? Promise.resolve(commentEditorContext) : undefined;

		return {
			loading: false,
			adfConfiguration: {
				...adfConfiguration,
				dataProviders: providerFactory,
				akEditorProps: {
					...adfConfiguration.akEditorProps,
					contextIdentifierProvider,
				},
				akEditorPropsWithoutMedia: {
					...adfConfiguration.akEditorPropsWithoutMedia,
					contextIdentifierProvider,
				},
				akRendererProps: {
					dataProviders: providerFactory,
				},
			},
		};
	}, [commentId, loading, adfConfiguration]);
};

type WaitForAdfConsumerPropsForInsightsProps = WaitForAdfConsumerPropsProps & {
	viewMediaProvider?: MediaProvider;
};

export const WaitForAdfConsumerPropsForInsight = ({
	skeletonRows,
	forceLoadingView,
	viewMediaProvider,
	children,
}: WaitForAdfConsumerPropsForInsightsProps) => {
	const [loading] = useIsAdfLoading();
	const [data] = useAdfConfiguration();

	const consumerProps = useMemo(() => {
		if (data === undefined) {
			return undefined;
		}
		const providerFactory = new ProviderFactory();
		providerFactory.setProvider('emojiProvider', data.emojiProvider);
		// @ts-expect-error - TS2345 - Argument of type 'Promise<AbstractMentionProvider | undefined>' is not assignable to parameter of type 'Promise<MentionProvider>'.
		providerFactory.setProvider('mentionProvider', Promise.resolve(data.mentionProvider));
		if (viewMediaProvider !== undefined) {
			providerFactory.setProvider(
				'mediaProvider',
				data.akEditorProps.media?.provider?.then((providerData: MediaProvider) => ({
					...providerData,
					viewMediaClientConfig: viewMediaProvider.viewMediaClientConfig,
				})),
			);
		}

		providerFactory.setProvider(
			'contextIdentifierProvider',
			// @ts-expect-error - TS2345 - Argument of type 'Promise<ContextIdentifierProvider | undefined>' is not assignable to parameter of type 'Promise<ContextIdentifierProvider>'.
			Promise.resolve(data.akEditorProps.contextIdentifierProvider),
		);

		return {
			...data,
			dataProviders: providerFactory,
			akEditorProps: data.akEditorProps,
			akEditorPropsWithoutMedia: data.akEditorPropsWithoutMedia,
			akRendererProps: {
				dataProviders: providerFactory,
			},
		};
	}, [viewMediaProvider, data]);

	if (
		(loading && consumerProps === undefined) ||
		consumerProps === undefined ||
		forceLoadingView === true
	) {
		return <AdfSkeleton skeletonRows={skeletonRows} />;
	}

	return children(consumerProps);
};

type WaitForAdfConsumerPropsForCommentProps = WaitForAdfConsumerPropsProps & {
	commentId: string;
};

export const WaitForAdfConsumerPropsForComment = ({
	commentId,
	children,
	skeletonRows,
	forceLoadingView,
}: WaitForAdfConsumerPropsForCommentProps) => {
	const { loading, adfConfiguration } = useAdfConsumerPropsForComment(commentId);

	if (
		(loading && adfConfiguration === undefined) ||
		adfConfiguration === undefined ||
		forceLoadingView === true
	) {
		return <AdfSkeleton skeletonRows={skeletonRows} />;
	}

	return children(adfConfiguration);
};

type WaitForAdfConsumerPropsProps = AdfSkeletonProps & {
	forceLoadingView?: boolean;
	children: (arg1: PolarisAdfConfiguration) => ReactElement;
};

export const WaitForAdfConsumerProps = ({
	children,
	skeletonRows,
	forceLoadingView,
}: WaitForAdfConsumerPropsProps) => {
	const [loading] = useIsAdfLoading();
	const [adfConfiguration] = useAdfConfiguration();

	if (
		(loading && adfConfiguration === undefined) ||
		adfConfiguration === undefined ||
		forceLoadingView === true
	) {
		return <AdfSkeleton skeletonRows={skeletonRows} />;
	}

	return <>{children(adfConfiguration)}</>;
};
