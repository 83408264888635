import React, { useMemo } from 'react';
import LockLockedIcon from '@atlaskit/icon/core/migration/lock-locked--lock-filled';
import LockUnlockedIcon from '@atlaskit/icon/core/migration/lock-unlocked--unlock-filled';
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import UnlockFilledIcon from '@atlaskit/icon/glyph/unlock-filled';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import messages from './messages';
import type { OptionType } from './select-components';

export const useAccessLevelOptions = (): OptionType[] => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => [
			{
				label: formatMessage(messages.open),
				description: formatMessage(messages.openOptionDescription),
				value: VIEW_ACCESS_LEVELS.OPEN,
				icon: fg('polaris_view_permissions_dynamic_view_access_icon') ? (
					<LockUnlockedIcon label={formatMessage(messages.open)} color="currentColor" />
				) : (
					<UnlockFilledIcon label={formatMessage(messages.open)} />
				),
			},
			{
				label: formatMessage(messages.limited),
				description: formatMessage(messages.limitedOptionDescription),
				value: VIEW_ACCESS_LEVELS.LIMITED,
				icon: fg('polaris_view_permissions_dynamic_view_access_icon') ? (
					<LockLockedIcon label={formatMessage(messages.limited)} color="currentColor" />
				) : (
					<LockFilledIcon label={formatMessage(messages.limited)} />
				),
			},
			{
				label: formatMessage(messages.restricted),
				description: formatMessage(messages.restrictedOptionDescription),
				value: VIEW_ACCESS_LEVELS.RESTRICTED,
				icon: fg('polaris_view_permissions_dynamic_view_access_icon') ? (
					<LockLockedIcon
						label={formatMessage(messages.restricted)}
						color="var(--ds-icon-accent-red)"
					/>
				) : (
					<LockFilledIcon label={formatMessage(messages.restricted)} />
				),
			},
		],
		[formatMessage],
	);
};
