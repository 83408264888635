import type { DeliveryIssue } from '@atlassian/jira-polaris-domain-delivery/src/types.tsx';

export const transformData = (ticket: DeliveryIssue) => ({
	...ticket,
	status: {
		name: ticket.status.name,
		statusCategoryKey: ticket.status.statusCategory.key,
	},
	issueType: {
		iconUrl: ticket.issueType.iconUrl,
		name: ticket.issueType.name,
		isSubtask: ticket.issueType.subtask,
	},
});
