import type { AggResponseData } from '@atlassian/jira-issue-agg-field-transformers/src/common/types/issue-type.tsx';
import {
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
	BOTH_LINK_DIRECTION,
	type IssueLinkType,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';

export const transformAggResponseToIssueLinkTypes = (data: AggResponseData) => {
	const edges = data.jira.issueLinkTypes?.edges ?? [];

	return edges.reduce((issueLinkTypes: IssueLinkType[], edge) => {
		if (!edge?.node || !edge.node) {
			return issueLinkTypes;
		}

		const { linkTypeId, inwards, outwards } = edge.node;

		if (inwards && inwards === outwards) {
			issueLinkTypes.push({
				id: linkTypeId || '',
				label: inwards,
				direction: BOTH_LINK_DIRECTION,
			});
		} else {
			issueLinkTypes.push({
				id: linkTypeId || '',
				label: inwards || '',
				direction: INWARD_LINK_DIRECTION,
			});
			issueLinkTypes.push({
				id: linkTypeId || '',
				label: outwards || '',
				direction: OUTWARD_LINK_DIRECTION,
			});
		}
		return issueLinkTypes;
	}, []);
};
