import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'polaris-ideas.insights.insights.empty-state.description',
		defaultMessage:
			'Capture user feedback, product analytics, support tickets or any important notes related to this idea with insights.',
		description: 'Description displayed when the current idea has no insights',
	},
	descriptionReadOnly: {
		id: 'polaris-ideas.insights.insights.empty-state.description-read-only',
		defaultMessage:
			'You will be able to find here all insights supporting this idea. Check it back later!',
		description:
			'Description displayed when the idea has no insights and the current user does have read-only permissions',
	},
	descriptionArchived: {
		id: 'polaris-ideas.insights.insights.empty-state.description-archived',
		defaultMessage:
			'The idea was archived before anyone added insights to it. To add an insight, you must restore the idea first.',
		description: 'Description displayed when the idea has been archived without any insights.',
	},
	learnMoreLink: {
		id: 'polaris-ideas.insights.insights.empty-state.learn-more-link',
		defaultMessage: 'Learn more about insights',
		description: 'Link capture to open help panel with article about insights',
	},
});
