import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleInsights: {
		id: 'polaris-common.apps.apps.user-testing.title-insights',
		defaultMessage: 'UserTesting',
		description: 'Title of the User testing app',
	},
	descriptionInsights: {
		id: 'polaris-common.apps.apps.user-testing.description-insights',
		defaultMessage:
			'Add UserTesting sessions, clips, and highlight reels to ideas to capture video-based human perspective',
		description: 'Description of the User testing app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.user-testing.unfurl-dialog-title',
		defaultMessage: 'Connect to User testing',
		description: 'Title of the User testing try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.user-testing.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to UserTesting here',
		description: 'Placeholder of the UserTesting try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.user-testing.unfurl-dialog-success',
		defaultMessage: 'This is what it looks like when you add a UserTesting link to an idea.',
		description: 'Success message of UserTesting the try-it-now unfurl dialog',
	},
});
