import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import { removeCollectionFromAdf, type ADF } from '@atlassian/jira-rich-content';
import type { TaskAction, TaskActionMap } from './types';

const updateTaskItemNode = (node: ADFEntity, { objectKey, state }: TaskAction): ADFEntity => ({
	...node,
	attrs: {
		localId: objectKey.localId,
		state,
	},
});

const createTaskActionVisitor = (task: TaskAction) => (node: ADFEntity) => {
	if (node.attrs?.localId === task.objectKey.localId) return updateTaskItemNode(node, task);
};

const createTaskActionMapVisitor = (tasks: TaskActionMap) => (node: ADFEntity) => {
	const task = tasks.get(node.attrs?.localId ?? '');

	if (task != null) return updateTaskItemNode(node, task);
};

export const updateAdfTaskItems = (tasks: TaskAction | TaskActionMap, adf?: ADF | null) => {
	if (adf == null) return false;

	const visitor =
		tasks instanceof Map ? createTaskActionMapVisitor(tasks) : createTaskActionVisitor(tasks);

	try {
		// Make a copy of `adf` so `traverse` doesn't complain about read-only objects
		const updatedAdf = traverse(removeCollectionFromAdf(adf), { taskItem: visitor });

		// We know it's a DocNode
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return updatedAdf ? (updatedAdf as ADF) : false;
	} catch (_e) {
		return false;
	}
};

export const getTaskItemsMetadata = (adf?: ADF | null) => {
	let numberOfTaskItems = 0;
	let numberOfTaskItemsDone = 0;
	let numberOfTaskItemsTodo = 0;

	try {
		traverse(removeCollectionFromAdf(adf), {
			taskItem: (node) => {
				numberOfTaskItems++;

				switch (node.attrs?.state) {
					case 'DONE':
						numberOfTaskItemsDone++;
						break;
					case 'TODO':
						numberOfTaskItemsTodo++;
						break;
					default:
						break;
				}
			},
		});
	} catch (_e) {
		// Do nothing
	}

	return {
		numberOfTaskItemsDone,
		numberOfTaskItemsTodo,
		numberOfTaskItems,
	};
};
