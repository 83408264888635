import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const deleteFieldLocally =
	(fieldKey: FieldKey) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const updatedFields = { ...getState().fields };
		delete updatedFields[fieldKey];

		const updatedDecorations = { ...getState().fieldValueDecorations };
		delete updatedDecorations[fieldKey];

		setState({
			fields: updatedFields,
			fieldValueDecorations: updatedDecorations,
		});
	};

export const deleteField =
	(fieldKey: FieldKey, onSuccess?: () => void, onError?: (e: Error) => void) =>
	async (
		{ getState, dispatch }: StoreActionApi<State>,
		{
			onFieldUpdateFailed,
			deletePlay,
			createAnalyticsEvent,
			fieldRemote,
			globalFieldRemote,
			projectId,
			ideaTypes,
		}: Props,
	): Promise<void> => {
		try {
			const currFieldMap = getState().fields;
			const { key, custom, global } = currFieldMap[fieldKey];
			const deletedFieldPlay = currFieldMap[fieldKey]?.play;

			const isGlobalField = custom && global;

			if (!isGlobalField) {
				await fieldRemote.deleteField({
					fieldKey: key,
					deletePlayId: deletedFieldPlay?.id,
				});
			} else {
				if (!projectId) {
					throw new Error('project id is not available');
				}

				const { jiraIssueTypeId: issueTypeId } = ideaTypes?.[0] ?? {};

				if (!issueTypeId) {
					throw new Error('issue type id is not available');
				}

				await wrapWithExperience(experience.globalFieldsAdministration.globalFieldUnassociate, () =>
					globalFieldRemote.removeGlobalFieldFromProject({
						fieldKey: key,
						issueType: {
							projectId,
							issueTypeId,
						},
					}),
				);
			}

			// FE state only removal of play; this has no side effects
			if (deletedFieldPlay) {
				deletePlay(deletedFieldPlay);
			}

			dispatch(deleteFieldLocally(fieldKey));

			fireCompoundAnalyticsEvent.FieldConfig.fieldDeletedSuccess(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: currFieldMap[fieldKey]?.type,
				isGlobalField,
			});

			onSuccess?.();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			fireCompoundAnalyticsEvent.FieldConfig.fieldDeletedFailure(createAnalyticsEvent({}));
			onError?.(e);
			onFieldUpdateFailed(e);
		}
		return undefined;
	};
