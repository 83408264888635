import React from 'react';
import { Flex, Text, xcss } from '@atlaskit/primitives';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

type FieldNameProps = {
	field: Field;
};

export const FieldName = ({ field }: FieldNameProps) => {
	const fieldIcon = iconForPolarisFieldType(field.type);

	return (
		<Flex xcss={fieldNameStyles} gap="space.100" alignItems="center">
			{fieldIcon}
			<Text maxLines={1} color="color.text.disabled">
				{field.label}
			</Text>
		</Flex>
	);
};

const fieldNameStyles = xcss({
	backgroundColor: 'color.background.disabled',
	color: 'color.text.disabled',
	padding: 'space.150',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	borderRadius: 'border.radius.100',
	width: '250px',
	border: '1px solid',
	borderColor: 'color.border.disabled',
	marginTop: 'space.050',
});
