import { useCallback } from 'react';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { IssueKey, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const PreferenceKeysEnum = {
	PINNED_FIELDS: 'jira.user.issue.details.pinned-fields',
	ATTACHMENT_VIEW: 'jira.user.issue.attachment.view',
} as const;

export const fieldPinButtonSelectorName = 'jira-issue-field-pin-field-pin-button';
export const FIELD_PIN_BUTTON_COMPONENT_SELECTOR = `[data-component-selector="${fieldPinButtonSelectorName}"]`;

export const unpinnedIconWrapperSelectorName = 'jira-issue-field-pin-unpinned-icon-wrapper';
export const UNPINNED_ICON_WRAPPER_COMPONENT_SELECTOR = `[data-component-selector="${unpinnedIconWrapperSelectorName}"]`;

export const fieldPinWithButtonSelectorName = 'jira-issue-field-pin-with-button-wrapper';
export const PIN_BUTTON_WRAPPER_COMPONENT_SELECTOR = `[data-component-selector="${fieldPinWithButtonSelectorName}"]`;

export const MAXIMUM_ENCODED_FIELD_ID_LENGTH = 255;

export const getPreferenceName = (projectKey: ProjectKey): string => {
	if (!projectKey) {
		return PreferenceKeysEnum.PINNED_FIELDS;
	}
	return `${PreferenceKeysEnum.PINNED_FIELDS}.${projectKey}`;
};

export const isFieldPinned = (
	pinnedFields: string[] | null | undefined,
	fieldId: string,
): boolean => {
	if (!pinnedFields || !Array.isArray(pinnedFields || !fieldId)) {
		return false;
	}
	return pinnedFields.includes(fieldId);
};

export const decodePinnedFields = (pinnedFields: string | null): string[] => {
	if (
		pinnedFields === undefined ||
		pinnedFields === null ||
		pinnedFields === '' ||
		pinnedFields === 'null'
	) {
		return [];
	}
	// TODO: Temporary until preferences API is extended (the .trim())
	return pinnedFields.trim().split(',');
};

export const encodePinnedFields = (pinnedFields: string[]): string => {
	// Null value will delete the preference key
	if (pinnedFields.filter((e) => typeof e === 'string' && e !== '').length === 0) {
		return 'null';
	}
	return pinnedFields.join(',');
};

export const usePinnedFields = (issueKey: IssueKey) => {
	const projectKey = useProjectKey(issueKey);
	const preferenceName = getPreferenceName(projectKey);
	const [rawPinnedFields, setPinnedFieldsUserPreferences] = useUserPreferencesValue(preferenceName);
	const pinnedFields = decodePinnedFields(rawPinnedFields);

	const setPinnedFields = useCallback(
		(newPinnedFields: string[]) => {
			const encoded = encodePinnedFields(newPinnedFields);
			if (newPinnedFields.length > 0) {
				if (encoded.length > MAXIMUM_ENCODED_FIELD_ID_LENGTH) {
					return false;
				}
				setPinnedFieldsUserPreferences.setValue(encoded);
				return true;
			}
			setPinnedFieldsUserPreferences.deleteValue();
			return true;
		},
		[setPinnedFieldsUserPreferences],
	);

	const sortPinnedField = useCallback(
		(idSource: string, idDestination: string, hasMovedUp: boolean) => {
			const decodedFields = decodePinnedFields(rawPinnedFields);

			const indexSource = decodedFields.indexOf(idSource);

			if (indexSource === -1) return;

			decodedFields.splice(indexSource, 1);

			let indexDestination = decodedFields.indexOf(idDestination);

			if (indexDestination === -1) return;

			if (!hasMovedUp) indexDestination += 1;

			decodedFields.splice(indexDestination, 0, idSource);
			setPinnedFields(decodedFields);
		},
		[rawPinnedFields, setPinnedFields], // passing rawPinnedFields to memoize the callback
	);

	return [pinnedFields, setPinnedFields, sortPinnedField] as const;
};
