import { GroupType, UserType } from '@atlaskit/user-picker';

export const AnalyticsSettingsNames = {
	VIEW_COMMENTS: 'viewComments',
	PINNED_FIELDS: 'pinnedFields',
	IDEA_COMMENTS: 'ideaComments',
	ATTACHMENTS: 'attachments',
	SITE_ACCESS: 'siteAccess',
	PUBLIC_ACCESS: 'publicAccess',
} as const;

export const StakeholderType = {
	USER: UserType,
	GROUP: GroupType,
};

export const ACCESS_PAGE_URL = '/jira/settings/products/jira-product-discovery/access';

export const PUBLISH_VIEWS_DOC_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/share-project-views/';

export const PUBLISH_MODAL_ANALYTICS_SOURCE = 'publishModal';
