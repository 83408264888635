import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	countMultiSelectTypeHelpText: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.multi-select-options.count-multi-select-type-help-text',
		defaultMessage: 'The number of selected options',
		description: 'Help message for the count options',
	},
	countType: {
		id: 'polaris-component-field-configuration.configuration.formula.rollup.multi-select-options.count-type',
		defaultMessage: 'Count',
		description: 'Count formula type',
	},
});
