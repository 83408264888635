import { ADD_IDEA_HEIGHT } from '../../column/footer';
import { BOARD_COLUMN_PADDING, BOARD_COLUMN_TITLE_HEIGHT } from '../../constants';
import { GROUP_HEADER_HEIGHT } from '../../swimlanes/body/styled';
import { HEADER_HEIGHT } from '../../swimlanes/constants';
import type { Card, Group } from '../types';
import {
	getGroupHighestColumnContent,
	getGroupHighestColumn,
	getVisibleCards,
	getVisibleColumns,
	getVisibleGroups,
	getColumnContentHeight,
} from './utils';

export class BoardVirtualizer {
	private groups: Group[];

	private containerWidth: number;

	private containerHeight: number;

	private columnCardCumulativeHeightsMap = new Map<string, Array<number>>();

	private previousColumnCardsMap = new Map<string, Card[]>();

	private hasColumnWithHeaders: boolean;

	constructor(
		groups: Group[],
		containerWidth: number,
		containerHeight: number,
		isHiddenAddIdeaInFooter: boolean,
		hasColumnWithHeaders: boolean,
	) {
		// do this outside of Virtualizer (also to avoid mutation)
		let offset = hasColumnWithHeaders ? 0 : HEADER_HEIGHT;
		for (const group of groups) {
			group.offset = offset;
			for (const column of group.columns) {
				column.contentHeight = getColumnContentHeight(column);
				column.height =
					column.contentHeight +
					(hasColumnWithHeaders ? BOARD_COLUMN_PADDING : BOARD_COLUMN_PADDING * 2);
				if (hasColumnWithHeaders) {
					column.height += BOARD_COLUMN_TITLE_HEIGHT;
				}
				if (!isHiddenAddIdeaInFooter) {
					column.height += ADD_IDEA_HEIGHT;
				}
			}
			group.maxColumnContentHeight = group.isCollapsed ? 0 : getGroupHighestColumnContent(group);
			group.contentHeight = group.isCollapsed ? 0 : getGroupHighestColumn(group);
			group.height = GROUP_HEADER_HEIGHT + group.contentHeight;
			offset += group.height;
		}
		this.groups = groups;
		this.containerWidth = containerWidth;
		this.containerHeight = containerHeight;
		this.hasColumnWithHeaders = hasColumnWithHeaders;

		for (const group of groups) {
			for (const column of group.columns) {
				const heights: Array<number> = [];
				column.cards.forEach((card) => heights.push(card.height + card.offset));
				this.columnCardCumulativeHeightsMap.set(column.uid, heights);
			}
		}
	}

	updateContainerDimensions(containerWidth: number, containerHeight: number) {
		this.containerWidth = containerWidth;
		this.containerHeight = containerHeight;
	}

	handleScroll(offsetX: number, offsetY: number, isExporting = false) {
		const columnCardsMap = new Map<string, Card[]>();

		const visibleGroups = getVisibleGroups(this.groups, offsetY, this.containerHeight, isExporting);

		for (const group of visibleGroups) {
			const visibleColumns = getVisibleColumns(group, offsetX, this.containerWidth, isExporting);

			for (const visibleColumn of visibleColumns) {
				const groupHeaderHeight = this.hasColumnWithHeaders
					? BOARD_COLUMN_TITLE_HEIGHT
					: GROUP_HEADER_HEIGHT;
				const columnTopPadding = this.hasColumnWithHeaders ? 0 : BOARD_COLUMN_PADDING;
				const visibleCards = getVisibleCards(
					visibleColumn,
					offsetY - group.offset - groupHeaderHeight - columnTopPadding,
					this.containerHeight,
					this.columnCardCumulativeHeightsMap,
					isExporting,
				);

				columnCardsMap.set(visibleColumn.uid, visibleCards);
			}
		}

		const changedColumnUids = [];
		for (const [columnUid, value] of columnCardsMap) {
			const previousValue = this.previousColumnCardsMap.get(columnUid);

			if (!previousValue) {
				// the column was not visible and is now visible
				changedColumnUids.push(columnUid);
			} else if (
				value.length !== previousValue.length ||
				JSON.stringify(value) !== JSON.stringify(previousValue)
			) {
				// the column is visible like before, but the cards displayed are different
				changedColumnUids.push(columnUid);
			}
		}

		for (const columnUid of this.previousColumnCardsMap.keys()) {
			if (columnCardsMap.get(columnUid) === undefined) {
				// the column is no longer visible: mark it as changed
				changedColumnUids.push(columnUid);
			}
		}

		this.previousColumnCardsMap = columnCardsMap;

		return { changedColumnUids, columnCardsMap };
	}

	getGroupColumnContentHeights() {
		const updatedGroupColumnContentHeights = new Map<string, number>();
		for (const group of this.groups) {
			updatedGroupColumnContentHeights.set(group.uid, group.maxColumnContentHeight);
		}
		return updatedGroupColumnContentHeights;
	}
}
