import React from 'react';
import { useFieldType } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import {
	useStringValue,
	useIsIssueArchived,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { StringField as CommonStringField } from '@atlassian/jira-polaris-common/src/ui/fields/string';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { useFieldUpdate, useIsEditable } from '../../utils';

type StringFieldProps = {
	fieldKey: string;
	localIssueId: LocalIssueId;
};

export const StringField = ({ fieldKey, localIssueId }: StringFieldProps) => {
	const value = useStringValue(fieldKey, localIssueId);
	const fieldType = useFieldType(fieldKey);
	const isEditable = useIsEditable(fieldKey);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);
	const isIdeaArchived = useIsIssueArchived(localIssueId);

	return (
		<PolarisTooltip hideTooltipOnMouseDown content={value} maxWidth="240px">
			<CommonStringField
				value={value}
				fieldType={fieldType}
				isEditable={isEditable && !isIdeaArchived}
				onUpdate={onUpdate}
			/>
		</PolarisTooltip>
	);
};
