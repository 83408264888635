import { useMemo } from 'react';
import difference from 'lodash/difference';
import dropRightWhile from 'lodash/dropRightWhile';
import sumBy from 'lodash/sumBy';
import type { ColumnId } from '../../common/types';
import type { BaseTableApi, ColumnShape } from '../../common/types/react-base-table';
import { useFixedColumns } from '../../controllers/selectors/columns-hooks';
import {
	useColumnConfiguration,
	useGetFixedColumnSizes,
} from '../../controllers/selectors/items-hooks';
import { cellRenderer } from '../cell';

export const handleColumnsCollectionChange = (
	oldColumns: ColumnShape[],
	newColumns: ColumnShape[],
	scrollPositionLeft: number,
	viewPortWidth: number,
	tableApi?: BaseTableApi,
) => {
	if (!tableApi) {
		return;
	}

	const newColumnKeys = newColumns.map(({ key }) => key);
	const oldColumnKeys = oldColumns.map(({ key }) => key);

	const addedColumns = difference(newColumnKeys, oldColumnKeys);

	if (addedColumns.length === 0 || oldColumnKeys.length === 0) {
		return;
	}

	const scrollToColumn = addedColumns[0];

	const colsUpUntilAddedColumn = dropRightWhile(newColumns, ({ key }) => key !== scrollToColumn);
	const columnEndPosition = sumBy(colsUpUntilAddedColumn, ({ width }) => width);

	const columnWidth = newColumns.find(({ key }) => key === scrollToColumn)?.width || 0;
	const columnStartPosition = columnEndPosition - columnWidth;

	const scrollOffset =
		scrollPositionLeft > columnEndPosition ? columnStartPosition : columnEndPosition;

	const totalColumnsWidth = tableApi.getTotalColumnsWidth();
	const maxScrollOffset = totalColumnsWidth - viewPortWidth;

	tableApi.scrollToLeft(Math.min(scrollOffset, maxScrollOffset));
};

/**
 * Populate column data info with components renderers.
 */
export const useFullColumnConfig = () => {
	const columnConfigWithoutRenderers = useColumnConfiguration();

	return useMemo(
		() =>
			columnConfigWithoutRenderers.map<ColumnShape>((conf) => ({
				...conf,
				cellRenderer,
			})),
		[columnConfigWithoutRenderers],
	);
};

/**
 * Get left position of sticky column.
 */
export const useStickyColumnLeftPosition = (columnId: ColumnId) => {
	const fixedColumnSizes = useGetFixedColumnSizes();
	const fixedColumns = useFixedColumns();
	const columnIndex = fixedColumns.indexOf(columnId);

	return useMemo(() => {
		if (columnIndex === -1) {
			return undefined;
		}

		return fixedColumns
			.slice(0, columnIndex)
			.reduce((acc, colId) => acc + fixedColumnSizes[colId], 0);
	}, [columnIndex, fixedColumnSizes, fixedColumns]);
};
