import { getInsightsWithSnippetData } from '../../services/polaris-api/get-insights-for-idea';
import type {
	FetchInsightsWithSnippetDataRequest,
	ProviderProps,
	RemoteInsightsWithSnippetDataFetcher,
} from '../../types';

export const fetchInsightsWithSnippetData =
	({ apolloClient, cloudId, projectId }: ProviderProps): RemoteInsightsWithSnippetDataFetcher =>
	({ issueId }: FetchInsightsWithSnippetDataRequest) =>
		getInsightsWithSnippetData(apolloClient, cloudId, projectId, issueId).then((insights) => [
			...insights.map((insight) => ({
				...insight,
				snippets: [...insight.snippets],
			})),
		]);
