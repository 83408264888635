import { createSelector } from 'reselect';
import { buildChannelString } from '@atlassian/jira-realtime';
import { cloudIdSelector } from '../common/state/selectors/context-selector';
import { projectIdSelector } from '../common/state/selectors/issue-selector';

export const realtimeChannelsSelector = createSelector(
	cloudIdSelector,
	projectIdSelector,
	// @ts-expect-error - TS2345 - Argument of type 'number | null' is not assignable to parameter of type 'string | number'.
	(cloudId, projectId) => [buildChannelString(cloudId, projectId)],
);
