import { useCallback, useEffect } from 'react';
import { useCurrentViewKind } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks.tsx';
import {
	VIEW_KIND_MATRIX,
	VIEW_KIND_TIMELINE,
	VIEW_KIND_BOARD,
	VIEW_KIND_TABLE,
	VIEW_KIND_TWOXTWO,
	VIEW_KIND_SECTION,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';

const experienceMapping = {
	[VIEW_KIND_MATRIX]: experience.matrixView,
	[VIEW_KIND_TIMELINE]: experience.timelineView,
	[VIEW_KIND_BOARD]: experience.boardView,
	[VIEW_KIND_TABLE]: experience.listView,
	[VIEW_KIND_TWOXTWO]: undefined,
	[VIEW_KIND_SECTION]: undefined,
};

export const useCurrentViewSuccessExperience = () => {
	const viewKind = useCurrentViewKind();

	const sendSuccessExperience = useCallback(() => {
		if (!viewKind) return;

		const currentExperience = experienceMapping[viewKind];

		if (!currentExperience) return;

		currentExperience.pageSegmentLoad.success();
	}, [viewKind]);

	useEffect(() => {
		// call success experience using timeout to ensure the entire react tree is rendered,
		// if some of the child components failed to render, error boundary will call failure experience before a success one
		const timeout = setTimeout(sendSuccessExperience);

		return () => clearTimeout(timeout);
	}, [sendSuccessExperience]);
};
