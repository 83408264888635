import React, { Component, type MouseEvent, type Ref } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import AkTooltip from '@atlaskit/tooltip';
import type { ParentIssue } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector';
import IssueParentSwitcher from '../../issue-parent-switcher';
import { truncateBreadcrumbs } from '../common/utils';
import { IssueContainer, SmallBreadcrumbsItem } from '../styled';

export type Props = {
	isSimplifiedProject: boolean;
	isDisabled: boolean;
	issueHierarchyLevel: number | null;
	parentIssue: ParentIssue;
	currentIssueKey: string;
	onClick: (event: MouseEvent, analyticsEvent?: UIAnalyticsEvent) => void;
	triggerRef?: Ref<HTMLElement>;
	onAddOrRemoveParent?: () => void;
};

type State = {
	isHovered: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class BreadcrumbParentIssue extends Component<Props, State> {
	static defaultProps = {
		isSimplifiedProject: false,
		isDisabled: true,
		issueHierarchyLevel: 0,
		onClick: noop,
		triggerRef: { current: null },
		onAddOrRemoveParent: noop,
	};

	state: State = { isHovered: false };

	onHoveredChange = (isHovered: boolean) => () => {
		this.setState({ isHovered });
	};

	renderBreadcrumbsItem() {
		const { isSimplifiedProject, parentIssue, onClick } = this.props;
		const { key, summary, url } = parentIssue;

		return (
			<AkTooltip
				content={`${key}: ${summary}`}
				position="top"
				delay={0}
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.parent-issue.tooltip"
			>
				<BreadcrumbsItem
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.issue-base.foundation.breadcrumbs.parent-issue.item"
					href={url}
					onClick={onClick}
					text={truncateBreadcrumbs(key)}
					truncationWidth={isSimplifiedProject ? undefined : 300}
					component={SmallBreadcrumbsItem}
				/>
			</AkTooltip>
		);
	}

	render() {
		const {
			isDisabled,
			parentIssue,
			issueHierarchyLevel,
			currentIssueKey,
			triggerRef,
			onAddOrRemoveParent,
		} = this.props;
		const { key, issueTypeName, issueTypeIconUrl } = parentIssue;
		const breadcrumbsItemWithTooltip = this.renderBreadcrumbsItem();

		return (
			<IssueContainer
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="issue.views.issue-base.foundation.breadcrumbs.breadcrumb-parent-issue-container"
				onMouseEnter={!isDisabled ? this.onHoveredChange(true) : undefined}
				onMouseLeave={!isDisabled ? this.onHoveredChange(false) : undefined}
				fixExtraDivider
				shouldTruncate
			>
				<IssueParentSwitcher
					isDisabled={isDisabled}
					currentIssueKey={currentIssueKey}
					issueKey={key}
					issueTypeName={issueTypeName}
					issueTypeIconUrl={this.state.isHovered ? '' : issueTypeIconUrl}
					issueHierarchyLevel={issueHierarchyLevel}
					onSelect={this.onHoveredChange(false)}
					onAddOrRemoveParent={onAddOrRemoveParent}
					triggerRef={triggerRef}
					component={SmallBreadcrumbsItem}
				/>
				{breadcrumbsItemWithTooltip}
			</IssueContainer>
		);
	}
}
