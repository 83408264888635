import { defineMessages } from '@atlassian/jira-intl';

// Keep up to date with jira/src/packages/issue/keyboard-shortcuts/src/services/assign-to-me-or-unassign/messages.tsx

export default defineMessages({
	submitFailedTitle: {
		id: 'issue-field-validation-handler.controllers.server-validation-handler.submit-failed-title',
		defaultMessage: 'We couldn’t update {fieldName}',
		description: 'Flag title shown when an error occurred trying to update an issue field.',
	},
	submitFailedDescription: {
		id: 'issue-field-validation-handler.controllers.server-validation-handler.submit-failed-description',
		defaultMessage: 'You can try again in a few minutes, or refresh the page.',
		description: 'Flag description shown when an error occurred trying to update an issue field.',
	},
	submitFailedTryAgain: {
		id: 'issue-field-validation-handler.controllers.server-validation-handler.submit-failed-try-again',
		defaultMessage: 'Try editing the field again',
		description: 'Action button text to allow the user to try again to edit a field.',
	},
});
