import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startDateResizeDisabledHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-resize-disabled-hint.start-date-resize-disabled-hint',
		defaultMessage: 'The Atlas project - start date cannot be changed here',
		description:
			'Hint message that appears when users start resizing card and one of card sides based on external date',
	},
	targetDateResizeDisabledHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-resize-disabled-hint.target-date-resize-disabled-hint',
		defaultMessage: 'The Atlas project - target date cannot be changed here',
		description:
			'Hint message that appears when users start resizing card and one of card sides based on external date',
	},
	deliveryDateResizeDisabledHint: {
		id: 'polaris-ideas.view-content.idea-timeline.external-date-resize-disabled-hint.delivery-date-resize-disabled-hint',
		defaultMessage: 'The delivery date cannot be changed here',
		description:
			'Hint message that appears when users start resizing card and one of card sides based on external date',
	},
});
