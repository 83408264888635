import { styled } from '@compiled/react';
import { N0, N20 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { BOARD_COLUMN_GAP, BOARD_COLUMN_WIDTH } from './constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BoardViewPlaceholder = styled.div({
	display: 'flex',
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: '0',
	boxSizing: 'border-box',
	top: '0',
	left: '0',
	paddingRight: '0',
	paddingBottom: token('space.300', '24px'),

	'&::before': {
		content: '',
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: token('elevation.surface', N0),
		opacity: '0.6',
		zIndex: '1',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnPlaceholder = styled.div({
	height: 'auto',
	minHeight: '100%',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${BOARD_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginRight: `${BOARD_COLUMN_GAP}px`,
	backgroundColor: token('color.background.input.hovered', N20),
});
