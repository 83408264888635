import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	betaWarningAdmin: {
		id: 'polaris-ideas.beta-banner.beta-warning-admin',
		defaultMessage:
			'Your access to the Beta plan will end on {date}. To continue using Jira Product Discovery, please update your plan.',
		description: 'Warning message for admins on the Beta plan',
	},
	betaWarningUser: {
		id: 'polaris-ideas.beta-banner.beta-warning-user',
		defaultMessage:
			'Your access to the Beta plan will end on {date}. To continue using Jira Product Discovery, please contact your site admin to update the plan.',
		description: 'Warning message for users on the Beta plan',
	},
	betaWarningExtended: {
		id: 'polaris-ideas.beta-banner.beta-warning-extended',
		defaultMessage:
			"You've been identified as a customer who needs annual pricing or Partner payment support. To better support your move to a Free or Standard plan, your beta plan has been extended.",
		description: 'Warning message for users on proactively extended Beta plan',
	},
	betaWarningAnnualAdmin: {
		id: 'polaris-ideas.beta-banner.beta-warning-annual-admin',
		defaultMessage:
			'Your access to the Beta edition is extended while you move to an annual plan, please contact your Account Manager or Solution Partner to continue this process. You can move to a monthly plan immediately.',
		description: 'Warning message for admins on proactively extended Beta plan annual cohort',
	},
	betaWarningAnnualUser: {
		id: 'polaris-ideas.beta-banner.beta-warning-annual-user',
		defaultMessage:
			'Your access to the Beta edition has been extended while you move to an annual plan.',
		description: 'Warning message for users on proactively extended Beta plan annual cohort',
	},
	annualReady: {
		id: 'polaris-ideas.beta-banner.annual-ready',
		defaultMessage:
			'Your Beta trial of Jira Product Discovery ends on {date}. Annual subscriptions and Partner purchasing are now available.',
		description:
			'Banner text for the users on Standard trial plan or proactively extended Beta plan',
	},
	annualReadyStandard: {
		id: 'polaris-ideas.beta-banner.annual-ready-standard',
		defaultMessage:
			'Your Standard plan trial ends on {date}. Annual subscriptions and Partner purchasing are now available.',
		description: 'Banner text for the users on Standard trial plan waiting for partner payment',
	},
	betaWarningExtendedLink: {
		id: 'polaris-ideas.beta-banner.beta-warning-extended-link',
		defaultMessage: 'Learn more here',
		description:
			'Link to instructions on how to move from Beta to the Free or Standard plan when on proactively extended Beta plan',
	},
	betaWarningLink: {
		id: 'polaris-ideas.beta-banner.beta-warning-link',
		defaultMessage: 'Check detailed instructions',
		description: 'Link to instructions on how to move from Beta to the Free or Standard plan',
	},
});
