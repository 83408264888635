import { notImplementedError } from '../../common/utils';
import { addWatcher } from '../../services/jira/watchers/add';
import { deleteWatcher } from '../../services/jira/watchers/delete';
import { getAssignableWatchers, getWatchers } from '../../services/jira/watchers/get';
import type { IssueWatchersRemote } from './types';

export const createNotImplementedIssueWatchersRemote = (): IssueWatchersRemote => ({
	createIssueWatcher: notImplementedError('createIssueWatcher'),
	fetchIssueWatchers: notImplementedError('fetchIssueWatchers'),
	deleteIssueWatcher: notImplementedError('deleteIssueWatcher'),
	fetchAssignableWatchers: notImplementedError('fetchAssignableWatchers'),
});

export const createSharingIssueWatchersRemote = (): IssueWatchersRemote => ({
	createIssueWatcher: notImplementedError('createIssueWatcher'),
	fetchIssueWatchers: () => Promise.resolve({ isWatching: false, watchCount: 0, watchers: [] }),
	deleteIssueWatcher: notImplementedError('deleteIssueWatcher'),
	fetchAssignableWatchers: notImplementedError('fetchAssignableWatchers'),
});

export const createIssueWatchersRemote = (): IssueWatchersRemote => ({
	createIssueWatcher: ({ issueIdOrKey, accountId }) => addWatcher(issueIdOrKey, accountId),
	fetchIssueWatchers: ({ issueIdOrKey }) => getWatchers(issueIdOrKey),
	deleteIssueWatcher: ({ issueIdOrKey, accountId }) => deleteWatcher(issueIdOrKey, accountId),
	fetchAssignableWatchers: (request) => getAssignableWatchers(request),
});
