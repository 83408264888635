import React, { useEffect, useMemo } from 'react';
import Spinner from '@atlaskit/spinner';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import {
	ConnectAppsContainer,
	useConnectApps,
} from '@atlassian/jira-project-settings-apps-connect-apps/src/baskets/connect-apps';
import ConnectAppsSpaView from '@atlassian/jira-project-settings-apps-connect-apps/src/view';

type Props = {
	appId: string;
};

const PolarisProjectConnectConfigInternal = ({ appId }: Props) => {
	const [{ data, loading }, { fetch }] = useConnectApps();
	const projectKey = useProjectKeyUnsafe();

	useEffect(() => {
		fetch(projectKey);
	}, [fetch, projectKey]);

	const app = useMemo(() => {
		if (loading || data === null || data === undefined) {
			return undefined;
		}

		return data.find(({ id }) => id === appId);
	}, [appId, data, loading]);

	if (app === undefined) {
		return <Spinner />;
	}

	return <ConnectAppsSpaView {...app} />;
};

export const PolarisProjectConnectConfig = (props: Props) => {
	const projectKey = useProjectKeyUnsafe();

	return (
		<ConnectAppsContainer onError={undefined} scope={projectKey}>
			<PolarisProjectConnectConfigInternal {...props} />
		</ConnectAppsContainer>
	);
};
