import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	opEquals: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.op-equals',
		defaultMessage: 'is {value}',
		description: 'Numeric equals operator',
	},
	opNotEquals: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.op-not-equals',
		defaultMessage: 'is not {value}',
		description: 'Numeric not equals operator',
	},
	opGreaterThan: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.op-greater-than',
		defaultMessage: 'greater than {value}',
		description: 'Numeric > operator',
	},
	opLessThan: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.op-less-than',
		defaultMessage: 'less than {value}',
		description: 'Numeric < operator',
	},
	opBetween: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.op-between',
		defaultMessage: 'between {value1} and {value2}',
		description: 'Numeric range between two values operator',
	},
	opEmpty: {
		id: 'polaris-ideas.view-controls.config-filters.filter-component.numeric.op-empty',
		defaultMessage: 'is empty',
		description: 'Numeric empty value operator',
	},
});
