import { createSelector } from 'reselect';
import type { RowGroupId } from '../../common/types';
import type { State } from '../types';

export const getRowGroups = (state: State) => state.rowGroups;

export const getCollapsedGroups = (state: State): RowGroupId[] => state.collapsedGroups;

export const isGrouped = (state: State): boolean => (state.rowGroups || []).length > 0;

export const areAllRowGroupsCollapsed = createSelector(
	getRowGroups,
	getCollapsedGroups,
	(rowGroups, collapsedGroups) => rowGroups?.length === collapsedGroups.length,
);
