import React, { type ReactNode, createContext, useContext } from 'react';

type WeightContextType = {
	isEditingWeight: boolean;
};

const WeightContext = createContext<WeightContextType>({
	isEditingWeight: false,
});

export const useWeightContext = () => {
	const context = useContext(WeightContext);
	if (context === undefined) {
		throw new Error('useWeightContext must be used within a WeightContextProvider');
	}
	return context;
};

type WeightContextProviderProps = {
	children: ReactNode;
	isEditingWeight: boolean;
};

export const WeightContextProvider = (props: WeightContextProviderProps) => {
	const { children, isEditingWeight } = props;

	return <WeightContext.Provider value={{ isEditingWeight }}>{children}</WeightContext.Provider>;
};
