import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { PolarisApiInsight } from '../get-insight/types';

type Fn = (arg: PolarisApiInsight) => Insight;

export const transformInsight: Fn = (polarisApiInsight) => ({
	...polarisApiInsight,
	snippets: polarisApiInsight?.snippets ? [...polarisApiInsight.snippets] : [],
	play: polarisApiInsight?.play ? { ...polarisApiInsight.play } : null,
});
