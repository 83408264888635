import { ff } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { FetchResponse } from './types';

const getRankingListUrl = (viewId: string) =>
	`/gateway/api/jpd/${ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v0'}/views/${viewId}/ranking/lists`;

export const getRankingList = (viewId: string): Promise<FetchResponse> => {
	if (viewId === undefined) {
		return Promise.reject(new Error('viewId must be supplied, cannot load fields'));
	}
	return fetchJson(getRankingListUrl(viewId));
};
