import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import { useIsSharedView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useSortedSelectedFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks';
import {
	useCurrentViewContainsArchived,
	useCurrentViewSortBy,
	useCurrentViewSortMode,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { ConfigSort } from '../../view-controls/config-sort';
import { Header } from '../header';
import { AdvancedSortingPanel } from './advanced';
import { messages } from './messages';

type Props = {
	onClose: () => void;
};

export const SortPanel = ({ onClose }: Props) => {
	const { formatMessage } = useIntl();
	const sortBy = useCurrentViewSortBy();
	const viewKind = useCurrentViewKind();
	const sortedSelectedFields = useSortedSelectedFields();
	const { setSortFieldsOfCurrentView } = useViewActions();
	const containsArchived = useCurrentViewContainsArchived();
	const isSharedView = useIsSharedView();

	const onSaveSort = (newSortFields: SortField[]) => {
		experience.headerView.viewSort.start();
		setSortFieldsOfCurrentView(
			newSortFields,
			() => {
				experience.headerView.viewSort.success();
			},
			(error?: Error) => {
				experience.headerView.viewSort.failure(error);
			},
		);
	};

	const onResetSort = () => {
		experience.headerView.viewSort.start();
		setSortFieldsOfCurrentView(
			undefined,
			() => {
				experience.headerView.viewSort.success();
			},
			(error?: Error) => {
				experience.headerView.viewSort.failure(error);
			},
		);
	};

	const currentSortMode = useCurrentViewSortMode();

	if (currentSortMode === undefined) {
		return null;
	}

	const displayAdvancedSortingPanel =
		viewKind !== VIEW_KIND_TIMELINE && !isSharedView && !containsArchived;

	return (
		<FieldsContainer>
			<div>
				<Header title={formatMessage(messages.sort)} onClose={onClose} />
				<ConfigSort
					fields={sortedSelectedFields}
					sortFields={sortBy}
					onReset={onResetSort}
					onSave={onSaveSort}
				/>
			</div>
			{!!displayAdvancedSortingPanel && <AdvancedSortingPanel />}
		</FieldsContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	minHeight: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
});
