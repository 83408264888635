import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useArjLicensed = (): boolean => {
	const tenantContext = useTenantContext();
	// We need to check for the JSW edition here. For commerce reasons JPD will always be on free.
	const application = getApplicationForProject(SOFTWARE_PROJECT);
	const edition = getEdition(application, tenantContext.appEditions);
	return edition === PREMIUM_EDITION;
};
