import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapperInternal = styled.div({
	display: 'flex',
	alignItems: 'center',
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmojiWrapper = EmojiWrapperInternal;
