import type { Ari } from '@atlassian/jira-platform-ari';
import type { DynamicFieldFormula, FieldVisitor } from '../types';
import { MULTISELECT_COUNT_TEMPLATE, type MultiselectCountFormula } from './types';

export const multiselectCount = (id: Ari): MultiselectCountFormula => ({
	template: MULTISELECT_COUNT_TEMPLATE,
	parameters: { id },
});

export const isMultiselectCount = (formula: DynamicFieldFormula): boolean =>
	formula.template === MULTISELECT_COUNT_TEMPLATE;

export const multiselectCountVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
): boolean => {
	if (formula.template !== MULTISELECT_COUNT_TEMPLATE) {
		return false;
	}
	visitField(formula.parameters.id);
	return true;
};
