import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successFlagTitle: {
		id: 'polaris-lib-feedback.modal.flags.success-flag-title',
		defaultMessage: 'Thank you for your feedback',
		description: 'Title of the success flag',
	},
	successFlagDescription: {
		id: 'polaris-lib-feedback.modal.flags.success-flag-description',
		defaultMessage:
			'Join the Jira Product Discovery community group to discuss product improvements and get more help.',
		description:
			'Flag description. Flag is shown when the user successfully submits their feedback',
	},
	joinCommunity: {
		id: 'polaris-lib-feedback.modal.flags.join-community',
		defaultMessage: 'Join the community',
		description: 'Link text, which displayed at the bottom of the success flag',
	},
	errorFlagTitle: {
		id: 'polaris-lib-feedback.modal.flags.error-flag-title',
		defaultMessage: 'We couldn’t submit your feedback',
		description:
			'Title of the error flag. This flag is shown when the user tries to submit feedback and there is a network error.',
	},
	errorFlagDescription: {
		id: 'polaris-lib-feedback.modal.flags.error-flag-description',
		defaultMessage: 'Check your internet connection and try submitting your feedback again.',
		description:
			'Flag description. This flag is shown when the user tries to submit feedback and there is a network error.',
	},
	getHelp: {
		id: 'polaris-lib-feedback.modal.flags.get-help',
		defaultMessage: 'Get help',
		description: 'Link text, which displayed at the bottom of the error flag',
	},
});
