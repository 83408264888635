import { notImplementedError } from '../../common/utils';
import {
	associateGlobalField,
	associateGlobalFields,
} from '../../services/jira/associate-global-field';
import { getGlobalFieldProjectsUsageInfo } from '../../services/jira/get-global-field-projects-usage';
import { unassociateGlobalField } from '../../services/jira/unassociate-global-field';
import { getGlobalFieldProjectViews } from '../../services/polaris-api/get-global-field-project-views';
import type { GlobalFieldUsageRemote } from './types';

export const createNotImplementedJiraGlobalFieldUsageRemote = (): GlobalFieldUsageRemote => ({
	addGlobalFieldToProjects: notImplementedError('addGlobalFieldToProjects'),
	addGlobalFieldsToProject: notImplementedError('addGlobalFieldsToProject'),
	removeGlobalFieldFromProject: notImplementedError('removeGlobalFieldFromProject'),
	getGlobalFieldProjectUsage: notImplementedError('getGlobalFieldProjectUsage'),
	getGlobalFieldProjectViews: notImplementedError('getGlobalFieldProjectViews'),
});

export const createJiraGlobalFieldUsageRemote = (): GlobalFieldUsageRemote => ({
	addGlobalFieldToProjects: async ({ fieldKey, issueTypes }) => {
		await associateGlobalField(fieldKey, issueTypes);
	},
	addGlobalFieldsToProject: async ({ fieldKeys, issueType }) => {
		await associateGlobalFields(fieldKeys, issueType);
	},
	removeGlobalFieldFromProject: async ({ fieldKey, issueType }) => {
		await unassociateGlobalField(fieldKey, issueType);
	},
	getGlobalFieldProjectUsage: ({ fieldKey }) => getGlobalFieldProjectsUsageInfo(fieldKey),
	getGlobalFieldProjectViews: ({ fieldKey, projectId }) =>
		getGlobalFieldProjectViews(fieldKey, projectId),
});
