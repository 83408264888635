import { useIssueKey } from '@atlassian/jira-issue-context-service';

declare let window: {
	location: {
		protocol: string;
		host: string;
	};
};

/**
 * @returns the absolute URL for the current issue.
 * @example https://jdog.jira-dev.com/browse/MAGMA-215
 */
export const useIssueAbsoluteUrl = (): string => {
	const issueKey = useIssueKey();
	return `${window.location.protocol}//${window.location.host}/browse/${issueKey}`;
};
