/**
 * @generated SignedSource<<061ed40108f0d8824ab1a4be2fab5159>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type header_issueViewFoundation_HeaderItemWithoutCompactMode$data = {
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView">;
  readonly " $fragmentType": "header_issueViewFoundation_HeaderItemWithoutCompactMode";
};
export type header_issueViewFoundation_HeaderItemWithoutCompactMode$key = {
  readonly " $data"?: header_issueViewFoundation_HeaderItemWithoutCompactMode$data;
  readonly " $fragmentSpreads": FragmentRefs<"header_issueViewFoundation_HeaderItemWithoutCompactMode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "header_issueViewFoundation_HeaderItemWithoutCompactMode",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "headerActions_issueViewFoundation_HeaderActionsView"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "e79f73ed6e6249d2adc1e90aa807d7e7";

export default node;
