import { combineReducers } from 'redux';
import type { WorklogUiState } from '@atlassian/jira-issue-view-common-types/src/worklog-type';
import highlightedWorklogReducer, { initialHighlightedWorklogId } from './highlighted-worklog';
import loadingStageReducer, { initialLoadingStage } from './loading-stage';
import modalReducer, { initialModalState } from './modal';
import permalinkStatusReducer, { initialPermalinkStatusState } from './permalink-status';

export const initialUiState: WorklogUiState = {
	loadingStage: initialLoadingStage,
	modal: initialModalState,
	highlightedWorklogId: initialHighlightedWorklogId,
	permalinkStatus: initialPermalinkStatusState,
};

export default combineReducers({
	loadingStage: loadingStageReducer,
	modal: modalReducer,
	highlightedWorklogId: highlightedWorklogReducer,
	permalinkStatus: permalinkStatusReducer,
});
