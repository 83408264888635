import type { State } from './types';

export const EMPTY_STATE: State = {
	entityLimits: undefined,
	meta: {
		loaded: false,
		loading: false,
		loadingError: undefined,
	},
};
