import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recommended: {
		id: 'polaris-common.apps.app-panel.recommended',
		defaultMessage: 'Recommended',
		description: 'Lozenge text for recommended apps',
	},
	comingSoon: {
		id: 'polaris-common.apps.app-panel.coming-soon',
		defaultMessage: 'Coming soon',
		description: 'Indicator that an app is not yet available, but coming soon',
	},
});
