import React, {
	type ReactNode,
	useRef,
	useMemo,
	useCallback,
	useState,
	useEffect,
	useImperativeHandle,
	forwardRef,
} from 'react';
import { keyframes, styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import once from 'lodash/once';
import { AutoResizer, BaseTableV2 as BaseTable } from 'react-base-table';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { autoScroller } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { B50, N0, N10, N20, N40A } from '@atlaskit/theme/colors';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	DraggableRow,
	NoneDraggableRow,
} from '@atlassian/jira-polaris-lib-list/src/ui/dnd/row/index.tsx';
import { StickyRowGroupComponent } from '@atlassian/jira-polaris-lib-list/src/ui/group-row/sticky/index.tsx';
import type { RowId, RowType, RowGroupId } from '../common/types';
import type {
	BaseTableApi,
	BaseTableProps,
	CellRendererProps,
	ColumnShape,
	RowData,
	RowRendererProps,
} from '../common/types/react-base-table';
import { CLASS_PREFIX, CLASS_HEADER_CONTAINER } from '../common/ui/constants';
import { useListActions } from '../controllers';
import { useRowConfiguration, useUpdatedRowProps } from '../controllers/selectors/items-hooks';
import { useIsItemReorderingEnabled } from '../controllers/selectors/operations-hooks';
import { useIsGrouped } from '../controllers/selectors/rows-groups-hooks';
import { useRowsRendered, useSelectedRows } from '../controllers/selectors/rows-hooks';
import {
	useBaseTableApi,
	useScrollPositionLeft,
	useIsScrolledHorizontally,
	useIsScrolledVertically,
	useIsExporting,
	useIsFramedDisplayMode,
	useHighlightedRow,
} from '../controllers/selectors/ui-hooks';
import type { ListApi } from '../types';
import {
	DEFAULT_ROW_HEIGHT,
	EXPORT_LIST_ID,
	OVERSCAN_ROW_COUNT,
	ROW_BORDER_WIDTH,
	activeChangingCellBackGroundColor,
	backgroundColor,
	borderColor,
	boxShadowBlur,
	boxShadowColor,
	boxShadowOffset,
	columnPadding,
	columnResizerColor,
	DEFAULT_FOOTER_HEIGHT,
	ROW_BORDER_RADIUS,
	rowHoveredBackgroundColor,
	rowSelectedBackgroundColor,
	rowSelectedHoveredBackgroundColor,
	tableFontSize,
	activeBorderColor,
	ACTIVE_ROW_HEIGHT,
	TABLE_VERTICAL_SCROLLBAR_SIZE_CSS_VARIABLE,
} from './constants';
import { EmptyComponent } from './empty-view';
import { HeaderRow } from './header';
import { OverlayRenderer } from './overlay';
import { rowRenderer } from './row';
import type { DragAwareTableProps, InnerTableProps, TableProps, BaseProps } from './types';
import { useFullColumnConfig, handleColumnsCollectionChange } from './utils/columns';
import { createListApi } from './utils/list-api';
import { PerformanceObserver } from './utils/performance';
import { useScrollRestoration } from './utils/scroll-restoration';

type InnerElementTypeProps = {
	isDarkMode: boolean;
};

const useInnerElementType = ({ isDarkMode }: InnerElementTypeProps) => {
	const isFramedDisplayMode = useIsFramedDisplayMode();

	return useMemo(
		() =>
			forwardRef<
				HTMLDivElement | null,
				{
					children: ReactNode;
				}
			>(({ children, ...rest }, ref) => (
				<div {...rest} ref={ref}>
					<TableHeaderContainer
						isDarkMode={isDarkMode}
						isFramedDisplayMode={isFramedDisplayMode}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={CLASS_HEADER_CONTAINER}
					>
						<HeaderRow />
						<StickyRowGroupComponent isDarkMode={isDarkMode} />
					</TableHeaderContainer>
					{children}
				</div>
			)),
		[isDarkMode, isFramedDisplayMode],
	);
};

const InnerTable = ({
	width,
	height,
	isDraggable = false,
	isHidden,
	onReady,
	isDarkMode,
}: InnerTableProps) => {
	const cols = useFullColumnConfig();
	const scrollPositionLeft = useScrollPositionLeft();
	const isScrolledHorizontally = useIsScrolledHorizontally();
	const isScrolledVertically = useIsScrolledVertically();
	const { idx: updatedRowIdx } = useUpdatedRowProps();
	const rowsRendered = useRowsRendered();
	const selectedRows = useSelectedRows();
	const highlightedRow = useHighlightedRow();

	const isGrouped = useIsGrouped();
	const rowsData = useRowConfiguration();

	const innerElementType = useInnerElementType({ isDarkMode });
	const isExporting = useIsExporting();

	const [prevCols, setPrevCols] = useState(cols);
	const innerRef = useRef<BaseTableApi>();
	useScrollRestoration({ innerRef, isHidden });

	useEffect(() => {
		onReady?.();
	}, [onReady]);

	const {
		resizeColumn,
		setHoveredRow,
		setScrollPosition,
		setDimensions,
		setHoveredColumn,
		setRowsRendered,
		setBaseTableApi,
		setUpdatedRows,
	} = useListActions();

	useEffect(() => {
		setDimensions({
			width,
			height,
			clientWidth: () => {
				const tableBodyElement = innerRef.current?.getDOMNode()?.querySelector('.table__body');
				if (tableBodyElement) {
					return tableBodyElement.clientWidth;
				}
				return width;
			},
		});
	}, [width, height, setDimensions]);

	useEffect(() => {
		setBaseTableApi(innerRef.current);
	}, [setBaseTableApi]);

	const onColumnResizeEnd = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'column' implicitly has an 'any' type. | TS7031 - Binding element 'newWidth' implicitly has an 'any' type.
		({ column, width: newWidth }) => {
			resizeColumn(column.key, newWidth);
		},
		[resizeColumn],
	);

	const rowProps = useMemo(() => {
		const handlerCache: Record<string, () => void> = {};

		// TODO(@author:dkarabin@atlassian.com): Decide whether onMouseLeave is required or not
		// Currently it's undefined...
		const onMouseLeave = setHoveredRow();

		// If we want to make it working, it needs to be defined as below
		// const onMouseLeave = () => setHoveredRow();

		const getEnterHandler = (id: RowId) => {
			if (handlerCache[id] === undefined) {
				handlerCache[id] = () => {
					setHoveredRow(id);
				};
			}

			return handlerCache[id];
		};

		if (ff('polaris.idea-list-row-group-column-header-add-idea')) {
			const defaultTagName = isDraggable ? DraggableRow : NoneDraggableRow;

			const getTagName = (type: RowType) => {
				if (type === 'ITEM_PINNED_BOTTOM') {
					return NoneDraggableRow;
				}

				return defaultTagName;
			};

			return ({ rowData, ...rest }: RowRendererProps) => ({
				...rest,
				rowData,
				type: rowData.type,
				tagName: getTagName(rowData.type),
				index: rowData.index,
				id: rowData.id,
				key: rowData.key,
				groupId: rowData.groupId,
				onMouseEnter: getEnterHandler(rowData.id),
				onMouseLeave,
			});
		}

		return ({ rowData, ...rest }: RowRendererProps) => ({
			...rest,
			rowData,
			type: rowData.type,
			tagName: isDraggable ? DraggableRow : NoneDraggableRow,
			index: rowData.index,
			id: rowData.id,
			key: rowData.key,
			groupId: rowData.groupId,
			onMouseEnter: getEnterHandler(rowData.id),
			onMouseLeave,
		});
	}, [isDraggable, setHoveredRow]);

	const cellProps = useMemo(() => {
		const handlerCache: Record<string, () => void> = {};

		const onMouseLeave = () => setHoveredColumn(undefined);

		const getEnterHandler = (key: string) => {
			if (handlerCache[key] === undefined) {
				handlerCache[key] = () => {
					setHoveredColumn(key);
				};
			}
			return handlerCache[key];
		};

		return ({ column }: CellRendererProps) => ({
			onMouseEnter: getEnterHandler(String(column.key)),
			onMouseLeave,
		});
	}, [setHoveredColumn]);

	const onScroll = useCallback(
		({ scrollLeft, scrollTop }: { scrollLeft: number; scrollTop: number }) => {
			setScrollPosition({ left: scrollLeft, top: scrollTop });
		},
		[setScrollPosition],
	);

	const onRowsRendered = useCallback(
		({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
			setRowsRendered({ startIndex, stopIndex });
		},
		[setRowsRendered],
	);

	const tableClassName = useMemo(() => {
		const className = [];

		if (isExporting) {
			className.push('table--exporting');
		}
		if (isScrolledHorizontally && !isExporting) {
			className.push('table--scrolled-x');
		}
		if (isScrolledVertically && !isExporting) {
			className.push('table--scrolled-y');
		}
		if (isGrouped) {
			className.push('table--grouped');
		}

		return className.join(' ');
	}, [isGrouped, isScrolledHorizontally, isScrolledVertically, isExporting]);

	const rowClassName = useCallback(
		({ rowData }: { columns: ColumnShape[]; rowData: RowData; rowIndex: number }) => {
			const className = [];
			if (selectedRows.includes(rowData.id)) {
				className.push('row--selected');
			}
			if (rowData.isEmpty) {
				className.push('empty');
			}
			if (rowData.isExpanded) {
				className.push('expanded');
			}
			if (rowData.id === highlightedRow) {
				className.push('row--highlighted');
			}
			if (rowData.type === 'ITEM_PINNED_BOTTOM') {
				className.push('row--pinned-bottom');
			}
			return className.join(' ');
		},
		[highlightedRow, selectedRows],
	);

	const handleScrollbarPresenceChange: NonNullable<BaseTableProps['onScrollbarPresenceChange']> =
		useCallback(({ size, vertical }) => {
			const tableElement = innerRef.current?.getDOMNode();
			if (!tableElement) {
				return;
			}

			tableElement.style.setProperty(
				TABLE_VERTICAL_SCROLLBAR_SIZE_CSS_VARIABLE,
				vertical ? `${size}px` : '0px',
			);
		}, []);

	// Checks if updated idea seen by the user and clears it after the delay
	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;
		if (updatedRowIdx !== null && updatedRowIdx !== -1) {
			const { startIndex, stopIndex } = rowsRendered;
			if (updatedRowIdx >= startIndex && updatedRowIdx <= stopIndex) {
				timer = setTimeout(() => setUpdatedRows([]), 1750);
			}
		}
		return () => {
			clearTimeout(timer);
		};
	}, [rowsRendered, setUpdatedRows, updatedRowIdx]);

	useEffect(() => {
		if (!isEqual(cols, prevCols)) {
			handleColumnsCollectionChange(prevCols, cols, scrollPositionLeft, width, innerRef.current);
			setPrevCols(cols);
		}
	}, [cols, prevCols, width, scrollPositionLeft]);

	const overlayRenderer = useMemo(
		() => <OverlayRenderer isBulkEditDialogShown={selectedRows.length > 0} />,
		[selectedRows.length],
	);

	const emptyRenderer = useMemo(() => <EmptyComponent />, []);

	return (
		<>
			{/* @ts-expect-error - TS2769 - No overload matches this call. */}
			<BaseTable
				fixed
				ref={innerRef}
				classPrefix={CLASS_PREFIX}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={tableClassName}
				width={width - ROW_BORDER_WIDTH}
				height={height}
				columns={cols}
				data={rowsData}
				rowClassName={rowClassName}
				rowProps={rowProps}
				rowKey="key"
				cellProps={cellProps}
				rowRenderer={rowRenderer}
				onRowsRendered={onRowsRendered}
				onColumnResizeEnd={onColumnResizeEnd}
				overscanRowCount={isExporting ? rowsData.length : OVERSCAN_ROW_COUNT}
				rowHeight={DEFAULT_ROW_HEIGHT}
				onScroll={onScroll}
				overlayRenderer={overlayRenderer}
				emptyRenderer={emptyRenderer}
				// hide react-base-table header implementation
				headerHeight={0}
				// add our own table header here
				innerElementType={innerElementType}
				onScrollbarPresenceChange={
					ff('polaris.idea-list-row-group-column-header-add-idea')
						? handleScrollbarPresenceChange
						: undefined
				}
			/>
			<PerformanceObserver
				isGrouped={isGrouped}
				fieldsCount={cols.length}
				issuesCount={rowsData.length}
			/>
		</>
	);
};

const DragAwareTable = ({
	isDarkMode,
	isHidden,
	onReady,
	shouldInterruptDnd,
	isFramedDisplayMode,
	isDraggingEnabled = true,
}: DragAwareTableProps) => {
	const { setDraggingRowId, setPrevDraggingRowId, rankItems } = useListActions();
	const clientXPos = useRef<number>(0);
	const baseTableApi = useBaseTableApi();
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!isDraggingEnabled) {
			return;
		}

		const cleanupDragAndDrop = combine(
			monitorForElements({
				onGenerateDragPreview: () => {
					// scroll table to the left to prevent horizontal scroll while dragging row
					baseTableApi?.scrollToLeft(0);
				},
				canMonitor: ({ source }) => source.data.type === 'ITEM' || source.data.type === 'GROUP',
				onDragStart: ({ source, location }) => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					setDraggingRowId(source.data.id as string);
					// fix scroll position to the current container only
					clientXPos.current = location.current.input.clientX;
					autoScroller.start({
						input: {
							...location.current.input,
							// lock the x axis
							pageX: clientXPos.current,
							clientX: clientXPos.current,
						},
					});
				},
				onDrop: ({ source, location }) => {
					// reset the dragging row id
					setDraggingRowId();
					// save prev dragging row id
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					setPrevDraggingRowId(source.data.id as string);

					autoScroller.stop();
					if (location.current.dropTargets.length === 0) {
						return;
					}
					const target = location.current.dropTargets[0];
					if (source.data.id === target.data.id) {
						return;
					}
					if (source.data.type === 'ITEM' || source.data.type === 'GROUP') {
						const edge = extractClosestEdge(target.data);
						if (edge !== 'top' && edge !== 'bottom') {
							return;
						}

						if (
							shouldInterruptDnd(
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								target.data.id as RowId,
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								source.data.groupId as RowGroupId,
							)
						) {
							return;
						}

						rankItems({
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							sourceId: source.data.id as RowId,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							sourceGroupId: source.data.groupId as RowGroupId,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							targetId: target.data.id as RowId,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							targetGroupId: target.data.groupId as RowGroupId,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							targetType: target.data.type as RowType,
							dropEdge: edge,
						});
					}
				},
				onDrag: ({ location }) => {
					autoScroller.updateInput({
						input: {
							...location.current.input,
							// lock the x axis
							pageX: clientXPos.current,
							clientX: clientXPos.current,
						},
					});
				},
			}),
		);

		return () => {
			cleanupDragAndDrop();
		};
	}, [
		baseTableApi,
		isDraggingEnabled,
		rankItems,
		setDraggingRowId,
		setPrevDraggingRowId,
		shouldInterruptDnd,
	]);

	return (
		<Wrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="list-view-table--fixed-row-height"
			id={EXPORT_LIST_ID}
			isDarkMode={isDarkMode}
			isFramedDisplayMode={isFramedDisplayMode}
			ref={ref}
			data-testid="polaris-lib-list.ui.drag-aware-table"
		>
			<AutoResizer>
				{({ width, height }) => (
					<InnerTable
						width={width}
						height={height}
						isDraggable={isDraggingEnabled}
						isHidden={isHidden}
						isDarkMode={isDarkMode}
						onReady={onReady}
					/>
				)}
			</AutoResizer>
		</Wrapper>
	);
};

const NonDragAwareTable = ({ isDarkMode, isHidden, onReady }: BaseProps) => {
	const isFramedDisplayMode = useIsFramedDisplayMode();

	return (
		<Wrapper
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="list-view-table--fixed-row-height"
			id={EXPORT_LIST_ID}
			isDarkMode={isDarkMode}
			isFramedDisplayMode={isFramedDisplayMode}
			data-testid="polaris-lib-list.ui.non-drag-aware-table"
		>
			<AutoResizer>
				{({ width, height }) => (
					<InnerTable
						width={width}
						height={height}
						isHidden={isHidden}
						onReady={onReady}
						isDarkMode={isDarkMode}
					/>
				)}
			</AutoResizer>
		</Wrapper>
	);
};

const DragAwareTableExperimental = ({
	isDarkMode,
	isHidden,
	onReady,
	shouldInterruptDnd,
	isDraggingEnabled,
}: DragAwareTableProps) => {
	const isFramedDisplayMode = useIsFramedDisplayMode();

	return (
		<DragAwareTable
			onReady={onReady}
			isDarkMode={isDarkMode}
			shouldInterruptDnd={shouldInterruptDnd}
			isHidden={isHidden}
			isDraggingEnabled={isDraggingEnabled}
			isFramedDisplayMode={isFramedDisplayMode}
		/>
	);
};

export const Table = forwardRef<ListApi | undefined, TableProps>(
	({ onReadyToExport, onReady, isHidden = false, isMoveBetweenGroupsDisabled }, ref) => {
		const isExporting = useIsExporting();
		const isRankingEnabled = useIsItemReorderingEnabled();
		const baseTableApi = useBaseTableApi();
		const { colorMode } = useThemeObserver();
		const isDarkMode = colorMode === 'dark';

		const isGrouped = useIsGrouped();
		const rawRowsData = useRowConfiguration();
		const rowsRendered = useRowsRendered();
		const rowsData = useMemo(
			() => (isGrouped ? rawRowsData.slice(1) : rawRowsData),
			[isGrouped, rawRowsData],
		);

		// rawRowsData needs to be used through `ref` because that's the only way
		// to get access to the latest data when getRowIndexById is called
		const rawRowsDataRef = useRef(rawRowsData);
		rawRowsDataRef.current = rawRowsData;

		// rowsRenderedRef needs to be used through `ref` because that's the only way
		// to get access to the latest data when ensureRowVisibleByIndex is called
		const rowsRenderedRef = useRef(rowsRendered);
		rowsRenderedRef.current = rowsRendered;

		useImperativeHandle(ref, () => {
			if (!baseTableApi) {
				return undefined;
			}

			return createListApi({
				baseTableApi,
				rawRowsDataRef,
				rowsRenderedRef,
				isGrouped,
			});
		}, [baseTableApi, isGrouped]);

		const shouldInterruptDnd = useCallback(
			(destinationId: string, groupId?: string) => {
				if (isMoveBetweenGroupsDisabled) {
					const destinationGroupId = rowsData.find((i) => i.id === destinationId)?.groupId;

					return destinationGroupId !== groupId;
				}
				return false;
			},
			[isMoveBetweenGroupsDisabled, rowsData],
		);

		useEffect(() => {
			const tableElement = baseTableApi?.getDOMNode()?.querySelector('.table__table');
			const tableBody = tableElement?.querySelector('.table__body');

			const exportHandler = once(() => {
				onReadyToExport?.();
			});

			const debouncedExportHandler = debounce(exportHandler, 500);

			let timeoutId: number | undefined;

			// mutation observer tracks rendering of avatars and icons in the table
			// once they are rendered - the table is ready for export
			const observer = new MutationObserver(() => {
				// stop the mutation timeout timer because the table is being updated
				if (timeoutId !== undefined) {
					clearTimeout(timeoutId);
					timeoutId = undefined;
				}

				// report that table is stable once mutations stopped
				debouncedExportHandler();
			});

			if (tableBody && tableBody.firstChild && isExporting && onReadyToExport) {
				observer.observe(tableBody.firstChild, {
					childList: true,
					subtree: true,
				});

				// if the table is stable, report it as ready for export after some timeout
				const waitForMutationsTimeout = 2000;
				timeoutId = window.setTimeout(() => {
					// stop waiting for table's mutations, assume that the table is stable
					observer.disconnect();
					exportHandler();
				}, waitForMutationsTimeout);
			}

			return () => {
				observer.disconnect();
				clearTimeout(timeoutId);
			};
		}, [isExporting, baseTableApi, rawRowsData.length, onReadyToExport]);

		if (ff('polaris.idea-list-row-group-column-header-add-idea')) {
			return (
				<DragAwareTableExperimental
					onReady={onReady}
					isDarkMode={isDarkMode}
					shouldInterruptDnd={shouldInterruptDnd}
					isHidden={isHidden}
					isDraggingEnabled={isRankingEnabled}
				/>
			);
		}

		if (isRankingEnabled) {
			return (
				<DragAwareTable
					onReady={onReady}
					isDarkMode={isDarkMode}
					shouldInterruptDnd={shouldInterruptDnd}
					isHidden={isHidden}
				/>
			);
		}

		return <NonDragAwareTable onReady={onReady} isDarkMode={isDarkMode} isHidden={isHidden} />;
	},
);

export default Table;

// exported for testing
export { DragAwareTable, NonDragAwareTable };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableHeaderContainer = styled.div<{ isDarkMode: boolean; isFramedDisplayMode: boolean }>({
	position: 'sticky',
	top: 0,
	zIndex: 500,

	backgroundColor: token('elevation.surface', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFramedDisplayMode }) => (isFramedDisplayMode ? 'fit-content' : undefined),
});

const flash = keyframes({
	'from, 50%, to': {
		backgroundColor: token('color.background.accent.blue.subtlest', B50),
	},
	'25%, 75%': {
		backgroundColor: token('elevation.surface', N0),
	},
});

const firstRowCellBorderStyles = {
	[`.${CLASS_PREFIX}__row-cell:first-of-type`]: {
		boxShadow: ({ isDarkMode }: { isDarkMode: boolean }) =>
			`-${ROW_BORDER_RADIUS}px 0 0 ${isDarkMode ? '#1D2125' : N0}`,
		borderBottomLeftRadius: ({ isFramedDisplayMode }: { isFramedDisplayMode?: boolean }) =>
			!isFramedDisplayMode && `${ROW_BORDER_RADIUS}px`,
	},
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isDarkMode: boolean; isFramedDisplayMode?: boolean }>({
	display: 'flex',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${DEFAULT_ROW_HEIGHT * 2 + DEFAULT_FOOTER_HEIGHT}px`,
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`.${CLASS_PREFIX}`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor,
		boxSizing: 'border-box',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		fontSize: tableFontSize,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&--disabled': {
			opacity: 0.7,
			pointerEvents: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&--dynamic': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`.${CLASS_PREFIX}__row`]: {
				alignItems: 'stretch',
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`&:not(.${CLASS_PREFIX}--dynamic), .${CLASS_PREFIX}__row--frozen`]: {
			borderBottom: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`.${CLASS_PREFIX}__row-cell-text`]: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		[`&__${CLASS_PREFIX}`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor,
			position: 'absolute',
			top: 0,
			display: 'flex',
			flexDirection: 'column-reverse',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			borderTopLeftRadius: `${ROW_BORDER_RADIUS}px`,
			overscrollBehavior: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&-main': {
				left: 0,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&-frozen-left, &-frozen-right': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__header, .${CLASS_PREFIX}__body`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					overflow: 'hidden !important',
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&-frozen-left': {
				zIndex: 170,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				boxShadow: `${boxShadowOffset} 0 ${boxShadowBlur} 0 ${boxShadowColor}`,
				top: 0,
				left: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__header-row, .${CLASS_PREFIX}__row`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					paddingRight: '0 !important',
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__body`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					overflowY: 'auto !important',
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&-frozen-right': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				boxShadow: `-${boxShadowOffset} 0 ${boxShadowBlur} 0 ${boxShadowColor}`,
				top: 0,
				right: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__header-row, .${CLASS_PREFIX}__row`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					paddingLeft: '0 !important',
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__body`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
					overflowY: 'auto !important',
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.row--selected, .row--highlighted': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				backgroundColor: rowSelectedBackgroundColor,
				'&:hover': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					backgroundColor: rowSelectedHoveredBackgroundColor,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__row-cell--selected`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					backgroundColor: `${activeChangingCellBackGroundColor} !important`,
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'.row--highlighted': {
				zIndex: 10,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'.table__row-cell': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
					borderTop: `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
					borderBottom: `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
					'&:first-of-type': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
						borderLeft: `${ROW_BORDER_WIDTH}px solid ${activeBorderColor}`,
					},
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'.row--pinned-bottom': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
				borderBottomLeftRadius: `${ROW_BORDER_RADIUS}px`,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__header': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			overflow: 'hidden !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.idea-input-wrapper': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				border: 'none !important',
				marginTop: token('space.025', '2px'),
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__body': {
			overscrollBehavior: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			width: ({ isFramedDisplayMode }) => (isFramedDisplayMode ? '100% !important' : undefined),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&--exporting &__body': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			overflow: 'visible !important',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`.${CLASS_PREFIX}__header, .${CLASS_PREFIX}__body`]: {
			outline: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__header-row, &__row': {
			display: 'flex',
			alignItems: 'center',
			boxSizing: 'border-box',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			minHeight: `${DEFAULT_ROW_HEIGHT}px`,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__header-row': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			backgroundColor: ({ isFramedDisplayMode }) =>
				!isFramedDisplayMode && token('color.background.input.hovered', N10),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__row': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			width: ({ isFramedDisplayMode }) =>
				isFramedDisplayMode ? 'fit-content !important' : undefined,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.updated': {
				animationName: flash,
				animationDuration: '1750ms',
				animationDelay: '250ms',
				animationIterationCount: 'infinite',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'.drag-handle': {
				opacity: 0,
				transition: 'opacity 50ms',
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			'&:has(+ *[role="rowgroup"])': firstRowCellBorderStyles,

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			"&[role='rowgroup']": {
				marginTop: token('space.075', '6px'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				minHeight: `calc(${DEFAULT_ROW_HEIGHT}px - 6px)`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				height: `calc(${DEFAULT_ROW_HEIGHT}px - 6px) !important`,

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__row-cell`]: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
					backgroundColor: ({ isDarkMode }) => (isDarkMode ? '#22272B' : N20),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					borderTop: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
					'&:first-of-type': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
						borderTopLeftRadius: `${ROW_BORDER_RADIUS}px`,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
						borderBottomLeftRadius: `${ROW_BORDER_RADIUS}px`,
					},
				},

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'&.expanded': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					[`.${CLASS_PREFIX}__row-cell:first-of-type`]: {
						borderBottomLeftRadius: 0,
					},
				},

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				'&.expanded.empty': firstRowCellBorderStyles,

				'&:hover': {
					backgroundColor: 'transparent',
				},
			},

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			'&:last-of-type': firstRowCellBorderStyles,

			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&:hover, &--hovered': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				backgroundColor: rowHoveredBackgroundColor,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'.drag-handle': {
					opacity: 1,
				},
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__header-cell, &__row-cell': {
			minWidth: 0,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			padding: `0 ${columnPadding}`,
			boxSizing: 'border-box',

			backgroundColor: 'inherit',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			borderRight: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			borderBottom: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--align-center': {
				justifyContent: 'center',
				textAlign: 'center',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--align-right': {
				justifyContent: 'flex-end',
				textAlign: 'right',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--presentational': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				padding: '0 !important',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				borderRight: 'none !important',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--exporting': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				position: 'static !important',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--sticky': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:has(div[id="emoji-picker"], div[data-component-selector="all-emojis-dialog"])': {
					zIndex: 20,
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				position: 'sticky !important',
				zIndex: 10,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'> *': {
				width: '100%',
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__row-cell': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:first-of-type': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				borderLeft: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__header-cell': {
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			overflow: 'visible !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			borderTop: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
			cursor: 'default',

			backgroundColor: token('color.background.input.hovered', N10),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--presentational': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
				padding: '0 !important',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&--resizing': {
				cursor: 'col-resize',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__column-resizer`]: {
					opacity: 1,
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'&-text': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'normal',
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:first-of-type': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				borderTopLeftRadius: `${ROW_BORDER_RADIUS}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				borderLeft: `${ROW_BORDER_WIDTH}px solid ${borderColor}`,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:last-of-type': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				borderTopRightRadius: ({ isFramedDisplayMode }) =>
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					isFramedDisplayMode && `${ROW_BORDER_RADIUS}px`,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&--grouped': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`.${CLASS_PREFIX}__header-cell:first-of-type`]: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				borderBottomLeftRadius: `${ROW_BORDER_RADIUS}px`,
			},
			// if grouped, index 1 indicates the first row after the first header, which has higher z-index
			// increasing the z-index of the highlighted-row instead renders it on top of the header when scrolling
			// so we make an exception in this case and do not adjust its height
			// instead, we apply the same margin rule to its contents so that they don't move down by 1px when highlighted
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
			'.table__row.row--highlighted:not([index="1"])': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
				height: `${ACTIVE_ROW_HEIGHT}px !important`, // close the gap that then occurs between this row and the next one
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
				marginTop: `-${ROW_BORDER_WIDTH}px`, // so that the top border overlaps the bottom border of the previous row
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'.table__row.row--highlighted[index="1"]': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'.table__row-cell': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
					'> div, > button': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
						marginTop: `-${ROW_BORDER_WIDTH}px`,
					},
				},
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:not(&--grouped)': {
			// if grouped, index 1 indicates the first row after the first header, which has higher z-index
			// increasing the z-index of the highlighted-row instead renders it on top of the header when scrolling
			// so we make an exception in this case and do not adjust its height
			// instead, we apply the same margin rule to its contents so that they don't move down by 1px when highlighted
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors, @atlaskit/ui-styling-standard/no-nested-selectors
			'.table__row.row--highlighted:not([index="0"])': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
				height: `${ACTIVE_ROW_HEIGHT}px !important`, // close the gap that then occurs between this row and the next one
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
				marginTop: `-${ROW_BORDER_WIDTH}px`, // so that the top border overlaps the bottom border of the previous row
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
			'.table__row.row--highlighted[index="0"]': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
				'.table__row-cell': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
					'> div, > button': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
						marginTop: `-${ROW_BORDER_WIDTH}px`,
					},
				},
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		[`&--scrolled-y .${CLASS_HEADER_CONTAINER}`]: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			boxShadow: ({ isDarkMode }) => `0 5px 4px -4px ${isDarkMode ? 'black' : N40A}`,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__row-cell--summary': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			overflow: 'visible !important', // required for shadow to show on scroll
			'&::after': {
				content: '',
				position: 'absolute',
				right: '-5px',
				width: '4px',
				height: '100%',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				background: ({ isDarkMode }) =>
					`linear-gradient(to right, rgba(0, 0, 0, ${
						isDarkMode ? 0.8 : 0.1
					}) 0%, rgba(0, 0, 0, 0) 100%)`,
				opacity: 0,
				transition: 'opacity 250ms',
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&--scrolled-x': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`.${CLASS_PREFIX}__row[role='rowgroup']`]: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				[`.${CLASS_PREFIX}__row-cell--summary`]: {
					transformStyle: 'preserve-3d',
					'&::before': {
						content: '',
						width: '6px',
						height: 'calc(100% + 2px)',
						position: 'absolute',
						top: '-1px',
						left: '-1px',
						background: token('elevation.surface', N0),
						transform: 'translateZ(-1px)',
					},
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`.${CLASS_PREFIX}__header-cell`]: {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'&:first-of-type': {
					transformStyle: 'preserve-3d',
					'&::before': {
						content: '',
						width: '6px',
						height: 'calc(100% + 2px)',
						position: 'absolute',
						top: '-1px',
						left: '-1px',
						background: token('elevation.surface', N0),
						transform: 'translateZ(-1px)',
					},
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			[`.${CLASS_PREFIX}__row-cell--summary`]: {
				'&::after': {
					opacity: 0.5,
					transition: 'opacity 250ms',
				},
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__column-resizer': {
			zIndex: 190,
			width: '3px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			right: '-2px !important',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor: columnResizerColor,
			opacity: 0,
			transition: 'opacity 250ms',
			'&::before': {
				content: '',
				width: '0px',
				height: '0px',
				borderStyle: 'solid',
				borderWidth: '5px 5px 5px 0',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				borderColor: `transparent ${columnResizerColor} transparent transparent`,
				display: 'block',
				position: 'absolute',
				left: token('space.negative.100', '-8px'),
				top: '50%',
				transform: 'rotate(0deg) translateY(-50%)',
				pointerEvents: 'none',
			},
			'&::after': {
				content: '',
				width: '0px',
				height: '0px',
				borderStyle: 'solid',
				borderWidth: '5px 0 5px 5px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				borderColor: `transparent transparent transparent ${columnResizerColor}`,
				display: 'block',
				position: 'absolute',
				right: token('space.negative.100', '-8px'),
				top: '50%',
				transform: 'rotate(0deg) translateY(-50%)',
				pointerEvents: 'none',
			},
			'&:hover': {
				opacity: 1,
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__footer': {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			overflow: 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__resizing-line': {
			display: 'none',
			cursor: 'col-resize',
			position: 'absolute',
			top: 0,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor: columnResizerColor,
			width: '1px',
			transform: 'translateX(-100%)',
			zIndex: 190,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__empty-layer': {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			overflow: 'hidden',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			backgroundColor,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'&__overlay': {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			overflow: 'hidden',
			pointerEvents: 'none',
			zIndex: 190,
		},
	},
});
