import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	notAllowedTransitionsTitle: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.status-field.not-allowed-transitions-title',
		defaultMessage: 'Not allowed transitions',
		description: 'Notification title for not allowed status transitions',
	},
	notAllowedTransitionsDescription: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.status-field.not-allowed-transitions-description',
		defaultMessage:
			'We couldn’t change the status of {issuesCount, plural, one {the issue} other {issues}} {notAllowedIssueKeys} because these transitions are not allowed in the idea’s workflow.',
		description: 'Notification description for not allowed status transitions',
	},
});
