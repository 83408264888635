import React from 'react';
import { useGetSelectFieldsRollup } from '../../../controllers/issue/selectors/fields-rollup-hooks';
import { CommonFieldGrouped } from '../common';
import { messages } from '../messages';
import type { Props } from '../types';

export const SelectFieldGrouped = ({
	fieldKey,
	groupId,
	groupFieldKey,
	rollupOperation,
}: Props) => {
	const val = useGetSelectFieldsRollup(fieldKey, groupFieldKey, groupId, rollupOperation);

	return val !== undefined ? (
		<CommonFieldGrouped
			val={val}
			rollupOperation={rollupOperation}
			message={messages.optionsCount}
		/>
	) : null;
};
