import { useEffect } from 'react';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { JIRA_PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

type CreateAnalyticsEvent = ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'];

const fireExposure = (createAnalyticsEvent: CreateAnalyticsEvent) => {
	const [expConfig, fire] = UNSAFE_noExposureExp('jpd_publishing_feature_gate_design_improvement');
	fire();

	const value = expConfig.get('cohort', 'not-enrolled');
	fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', 'publishFeatureDiscovery', {
		value,
		growthInitiative: 'transmuters',
		flagKey: 'jpd_publishing_feature_gate_design_improvement',
	});
};

/**
 * This will fire the exposure event and return true/false if the user should see the experience
 */
export const useShouldShowPublishButton = (): boolean => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const appEditions = useAppEditions();

	const isOnFreeEdition = getEdition(JIRA_PRODUCT_DISCOVERY, appEditions) === FREE_EDITION;

	useEffect(() => {
		if (!isOnFreeEdition) {
			// Exit early when not on free tier as the user shouldn't be a part of this experience
			return;
		}

		fireExposure(createAnalyticsEvent);
	}, [createAnalyticsEvent, isOnFreeEdition]);

	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('jpd_publishing_feature_gate_design_improvement');
	const cohort = expConfig.get('cohort', 'not-enrolled');

	return cohort === 'variation' && isOnFreeEdition;
};
