import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';

export const FULL_SNIPPET_FRAGMENT = gqlTagPolaris`
  fragment Snippet on PolarisSnippet {
    id
    data
    oauthClientId
    updated
    appInfo {
      id
      name
      avatarUrl
      oauthClientId
    }
    url
    properties
    refresh {
      last
      next
      error
      errorType
      queued
    }
  }
`;

export const LIGHT_SNIPPET_FRAGMENT = gqlTagPolaris`
   fragment SnippetLight on PolarisSnippet {
    id
    updated
    appInfo {
      id
      name
      avatarUrl
      oauthClientId
    }
    properties
    refresh {
      last
      next
      error
      errorType
      queued
    }
  }
`;
