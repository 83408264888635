import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { IdeaTemplate } from '../../../common/types/idea-templates/types';
import type {
	jira_polaris_GetIdeaTemplates_polarisIdeaTemplates as GqlTemplate,
	jira_polaris_GetIdeaTemplates as IdeaTemplatesQuery,
} from './__generated_apollo__/jira_polaris_GetIdeaTemplates.ts';
import type {
	CreatePolarisIdeaTemplateInput,
	UpdatePolarisIdeaTemplateInput,
	DeletePolarisIdeaTemplateInput,
} from './types';

const MUTATION = gqlTagPolaris`
mutation jira_polaris_CreatePolarisIdeaTemplate($input: CreatePolarisIdeaTemplateInput!) {
    createPolarisIdeaTemplate(input: $input) {
        success
        errors {
          message
        }
        node {
            id
        }
      }
    }
`;

export const createIdeaTemplate = (
	client: PolarisApolloClient,
	input: CreatePolarisIdeaTemplateInput,
): Promise<string> =>
	client
		.mutate({
			mutation: MUTATION,
			variables: {
				input,
			},
		})
		.then((result) => {
			if (result.errors) {
				throw new Error(`idea-templates.create:${result.errors.map((e) => e.message).join(', ')}`);
			}
			if (result.data?.createPolarisIdeaTemplate?.node?.id === undefined) {
				throw new Error('idea-templates.create:missing-id');
			}
			return result.data.createPolarisIdeaTemplate.node.id;
		});

const UPDATE_GQL = gqlTagPolaris`
mutation jira_polaris_UpdatePolarisIdeaTemplate($input: UpdatePolarisIdeaTemplateInput!) {
    updatePolarisIdeaTemplate(input: $input) {
        success
        errors {
          message
        }
      }
    }
`;

export const updateIdeaTemplate = (
	client: PolarisApolloClient,
	input: UpdatePolarisIdeaTemplateInput,
): Promise<void> =>
	client
		.mutate({
			mutation: UPDATE_GQL,
			variables: {
				input,
			},
		})
		.then((result) => {
			if (result.errors) {
				throw new Error(`idea-templates.update:${result.errors.map((e) => e.message).join(', ')}`);
			}
		});

const DELETE_GQL = gqlTagPolaris`
mutation jira_polaris_DeletePolarisIdeaTemplate($input: DeletePolarisIdeaTemplateInput!) {
    deletePolarisIdeaTemplate(input: $input) {
        success
        errors {
          message
        }
      }
    }
`;

export const deleteIdeaTemplate = (
	client: PolarisApolloClient,
	input: DeletePolarisIdeaTemplateInput,
): Promise<void> =>
	client
		.mutate({
			mutation: DELETE_GQL,
			variables: {
				input,
			},
		})
		.then((result) => {
			if (result.errors) {
				throw new Error(`idea-templates.delete:${result.errors.map((e) => e.message).join(', ')}`);
			}
		});

const QUERY = gqlTagPolaris`
query jira_polaris_GetIdeaTemplates($project: ID!) {
  polarisIdeaTemplates(project: $project) {
    id
    title
    description
    color
    emoji
    template
    project
  }
}
`;

const transform = (gql: GqlTemplate): IdeaTemplate => ({
	id: gql.id,
	title: gql.title,
	template: gql.template !== null && gql.template !== undefined ? gql.template : undefined,
	color: gql.color !== null && gql.color !== undefined ? gql.color : undefined,
	emoji: gql.emoji !== null && gql.emoji !== undefined ? gql.emoji : undefined,
	description:
		gql.description !== null && gql.description !== undefined ? gql.description : undefined,
});

export const getIdeaTemplates = (
	client: PolarisApolloClient,
	project: string,
): Promise<IdeaTemplate[]> =>
	client
		.query<IdeaTemplatesQuery>({
			query: QUERY,
			variables: {
				project,
			},
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		})
		.then((result) => {
			if (result.errors !== undefined) {
				throw new Error(
					`polaris-idea-templates.get:${result.errors.map((e) => e.message).join(', ')}`,
				);
			}
			if (!result.data || !result.data.polarisIdeaTemplates) {
				throw new Error('polaris-idea-templates.get: no data or no data node');
			}
			return result.data.polarisIdeaTemplates.map(transform);
		});
