import { toProjectType } from '@atlassian/jira-common-constants/src/project-types';
import { toIssueTypeId, toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	PERMISSION_VIEW_READONLY_WORKFLOW,
	PERMISSION_ADMINISTER,
} from '../../../common/constants';
import type { GraphqlResponse, ViewWorkflowData } from './types';

// @ts-expect-error - TS7031 - Binding element 'key' implicitly has an 'any' type. | TS7031 - Binding element 'havePermission' implicitly has an 'any' type.
const simplifiedPermissionPredicate = ({ key, havePermission }) =>
	(key === PERMISSION_VIEW_READONLY_WORKFLOW && havePermission) ||
	(key === PERMISSION_ADMINISTER && havePermission);

// @ts-expect-error - TS7031 - Binding element 'key' implicitly has an 'any' type. | TS7031 - Binding element 'havePermission' implicitly has an 'any' type.
const classicPermissionPredicate = ({ key, havePermission }) =>
	key === PERMISSION_VIEW_READONLY_WORKFLOW && havePermission;

export const transform = (
	{ data }: GraphqlResponse,
	isSimplifiedCached?: boolean,
): ViewWorkflowData | undefined => {
	if (!data || !data.permissions || !data.jira) {
		return undefined;
	}

	const { permissions, project, jira } = data;
	const isSimplified = (project && project.simplified) || isSimplifiedCached;

	const permissionPredicate =
		isSimplified === true ? simplifiedPermissionPredicate : classicPermissionPredicate;

	const issueTypeField = jira.issueByKey?.fieldsById?.edges?.find(
		(edge) => edge.node?.fieldId === 'issuetype',
	);

	const result: ViewWorkflowData = {
		hasPermission: permissions.some(permissionPredicate),
		issueTypeId: toIssueTypeId(
			issueTypeField?.node?.issueType?.issueTypeId ? issueTypeField.node.issueType.issueTypeId : '',
		),
	};

	if (project) {
		result.projectId = toProjectId(String(project.projectId));
		result.projectType = toProjectType(project.type);
		result.isSimplified = project.simplified;
	}

	return result;
};
