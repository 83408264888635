import { tryAddCurrentUserAsSiteCreator } from './add-as-site-creator';
import { load } from './load';
import { toggleEnforcePermissionChecks } from './toggle-enforce-permission-checks';
import { toggleSelfServeAddAsSiteCreator } from './toggle-self-serve';

export const actions = {
	load,
	tryAddCurrentUserAsSiteCreator,
	toggleSelfServeAddAsSiteCreator,
	toggleEnforcePermissionChecks,
};
