import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const checkProperty =
	(
		property: {
			name: string;
			key: string;
		},
		isChecked: boolean,
	): Action<State, Props> =>
	({ getState, setState }) => {
		let { config } = getState();
		if (isChecked) {
			config = {
				...config,
				[property.key]: {
					name: property.name,
				},
			};
		} else if (config !== null) {
			delete config[property.key];
		}
		setState({
			config: {
				...config,
			},
		});
	};
