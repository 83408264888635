import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	learnMoreButton: {
		id: 'polaris-lib-onboarding.common.spotlight.learn-more-button',
		defaultMessage: 'Learn more',
		description: 'Button label open article in help panel',
	},
	gotItButton: {
		id: 'polaris-lib-onboarding.common.spotlight.got-it-button',
		defaultMessage: 'Got it',
		description: 'Button label to acknowledge and close the spotlight',
	},
});
