import React, { type ReactNode, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	ProfileClient,
	ProfilecardTriggerPosition,
	TriggerType,
	PrepopulatedData,
} from '@atlaskit/profilecard';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl';
import {
	fireTrackAnalytics,
	ContextualAnalyticsData,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { RouterActions } from '@atlassian/jira-router';
import type { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller';
import { profileCardNextSourceName, profileCardNextLegacySourceName } from './common/constants';
import type { Actions, AnalyticsData } from './types';
import ProfileCardView from './ui/profilecard';
import ProfileCardLegacyView from './ui/profilecard-legacy';
import { ProfileCardWrapper } from './ui/profilecard-wrapper';
import { getSpaActions } from './utils';

type ProfileCardBaseProps = {
	TenantContextSubscriber?: typeof TenantContextSubscriber;
	externalProfileClient?: ProfileClient;
	actions?: Actions[];
	prepopulatedData?: PrepopulatedData;
};

export type ProfileCardProps = ProfileCardBaseProps & {
	accountId: string;
	analyticsData?: AnalyticsData;
	children: ReactNode;
	position?: ProfilecardTriggerPosition;
	testId?: string;
	trigger?: TriggerType;
};

export const ProfileCard: ComponentType<ProfileCardProps> = (props: ProfileCardProps) => {
	const {
		TenantContextSubscriber: TenantContextSubscriberComponent,
		externalProfileClient,
		accountId,
		actions,
		analyticsData,
		children,
		position,
		testId,
		trigger,
		prepopulatedData,
	} = props;
	const intl = useIntl();
	const { showFlag, dismissFlag } = useFlagsService();

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName={profileCardNextSourceName}
			attributes={analyticsData}
		>
			<ProfileCardWrapper
				TenantContextSubscriber={TenantContextSubscriberComponent}
				externalProfileClient={externalProfileClient}
				analyticsData={analyticsData}
			>
				{/* @ts-expect-error - TS2769 - No overload matches this call. */}
				{(atlassianAccountId, profileClient, cloudId, baseUrl) => {
					if (atlassianAccountId !== '') {
						return (
							<RouterActions>
								{({ push }) => (
									<ProfileCardView
										profileClient={profileClient}
										cloudId={cloudId}
										accountId={accountId}
										actions={getSpaActions(accountId, baseUrl, intl, push, actions)}
										position={position}
										onProfileClick={(profileAccountId) =>
											push(`${baseUrl}/jira/people/${profileAccountId}`)
										}
										addFlag={showFlag}
										dismissFlag={dismissFlag}
										testId={testId}
										trigger={trigger}
										prepopulatedData={prepopulatedData}
										viewingUserId={atlassianAccountId || undefined}
									>
										{children}
									</ProfileCardView>
								)}
							</RouterActions>
						);
					}
					return children;
				}}
			</ProfileCardWrapper>
		</ContextualAnalyticsData>
	);
};

export const ProfileCardLegacy: ComponentType<ProfileCardBaseProps> = (
	props: ProfileCardBaseProps,
) => {
	const {
		TenantContextSubscriber: TenantContextSubscriberComponent,
		externalProfileClient,
		actions,
	} = props;

	const intl = useIntl();

	const onLoadError = (error: Error, analyticsEvent?: UIAnalyticsEvent) => {
		if (analyticsEvent) {
			fireTrackAnalytics(
				analyticsEvent,
				`${analyticsEvent.payload.actionSubject} ${analyticsEvent.payload.action}`,
				`${analyticsEvent.payload.actionSubject}${analyticsEvent.payload.action}`,
				{
					error,
				},
			);
		}
	};

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName={profileCardNextLegacySourceName}>
			<ProfileCardWrapper
				TenantContextSubscriber={TenantContextSubscriberComponent}
				externalProfileClient={externalProfileClient}
				onLoadError={onLoadError}
			>
				{(atlassianAccountId, profileClient, cloudId, baseUrl) =>
					// @ts-expect-error - TS2769 - No overload matches this call.
					atlassianAccountId !== null ? (
						<LegacyProfileCardStyled>
							<RouterActions>
								{({ push }) => (
									<ProfileCardLegacyView
										profileClient={profileClient}
										cloudId={cloudId}
										baseUrl={baseUrl}
										onGetActions={(accountId: string, onActionCleanup?: () => void) =>
											getSpaActions(accountId, baseUrl, intl, push, actions, onActionCleanup)
										}
										onProfileClick={(profileAccountId) =>
											push(`${baseUrl}/jira/people/${profileAccountId}`)
										}
									/>
								)}
							</RouterActions>
						</LegacyProfileCardStyled>
					) : null
				}
			</ProfileCardWrapper>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LegacyProfileCardStyled = styled.div({
	position: 'relative',
	zIndex: 100,
});
