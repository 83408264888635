/* eslint-disable jira/js/no-reduce-accumulator-spread */

import type { PayloadError } from 'relay-runtime';
import type { GraphQlError } from '@atlassian/jira-issue-view-common-types/src/gira';
import { GIRA_ISSUE_ERROR_ANALYTICS } from './gira/constants';

export type Errors = (PayloadError | GraphQlError)[];

export const makeErrorObject = (
	errors: Errors,
	_eventNameSuffix: string = GIRA_ISSUE_ERROR_ANALYTICS,
) => {
	let errorCounter = 0;

	const getErrorKey = (val: PayloadError | GraphQlError) => {
		// @ts-expect-error - TS2339 - Property 'path' does not exist on type 'PayloadError | GraphQlError'. | TS2339 - Property 'path' does not exist on type 'PayloadError | GraphQlError'.
		if (val.path !== null && Array.isArray(val.path)) {
			// @ts-expect-error - TS2339 - Property 'path' does not exist on type 'PayloadError | GraphQlError'.
			return val.path.join('.');
		}
		if (val.locations) {
			return val.locations.map((loc) => `line ${loc.line}, column ${loc.column}`).join('&');
		}
		errorCounter += 1;
		return `error without path or location: ${errorCounter}`;
	};

	const error = errors.reduce<Record<string, string>>(
		(acc, val) => ({ ...acc, [getErrorKey(val)]: val.message }),
		{},
	);
	return error;
};
