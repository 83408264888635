import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moveToTop: {
		id: 'polaris-lib-field-option-configuration.option-list.option-list-item.drag-handle.move-to-top',
		defaultMessage: 'Move to top',
		description: 'Dropdown item to move the item to the up',
	},
	moveUp: {
		id: 'polaris-lib-field-option-configuration.option-list.option-list-item.drag-handle.move-up',
		defaultMessage: 'Move up',
		description: 'Dropdown item to move the item to the up',
	},
	moveDown: {
		id: 'polaris-lib-field-option-configuration.option-list.option-list-item.drag-handle.move-down',
		defaultMessage: 'Move down',
		description: 'Dropdown item to move the item to the up',
	},
	moveToBottom: {
		id: 'polaris-lib-field-option-configuration.option-list.option-list-item.drag-handle.move-to-bottom',
		defaultMessage: 'Move to bottom',
		description: 'Dropdown item to move the item to the up',
	},
	reorderLabel: {
		id: 'polaris-lib-field-option-configuration.option-list.option-list-item.drag-handle.reorder-label',
		defaultMessage: 'Reorder option',
		description: 'Reorder handle button label',
	},
});
