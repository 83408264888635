import { SET_VALUE_ARRAY_SIZE_LIMIT } from '../constants';
import { baseScopesRequiredPerField } from '../fields-scopes';
import { isNotEmptyString } from '../utils/is-not-empty-string';

export type ComponentsPublicSetValue = string[];

export type ComponentsPublicGetValues = {
	id: string;
	name: string;
}[];

export const validateComponentsSetValue = (value: unknown): value is ComponentsPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const componentsRequiredScopes = {
	...baseScopesRequiredPerField,
	granular: [
		'read:project.component:jira',
		...baseScopesRequiredPerField.granular,
		'write:field.default-value:jira',
	],
};
