import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import { ff } from '@atlassian/jira-feature-flagging';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { ViewFragment } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
import type {
	jira_polaris_UpdateView as UpdateView,
	jira_polaris_UpdateViewVariables as UpdateViewVariables,
} from '@atlassian/jira-polaris-remote-view/src/services/polaris-api/update-view/__generated_apollo__/jira_polaris_UpdateView.ts';
import type { UpdateViewRequest } from '../../../common/types';
import { getSortMode } from '../../../common/utils';
import type { UpdateViewReturn } from './types';

const UPDATE_VIEW_MUTATION = gqlTagPolaris`
mutation jira_polaris_UpdateView(
    $id: ID!,
    $update: UpdatePolarisViewInput!,
    $isShareViewDialogEnabled: Boolean!,
    $skipDescription: Boolean!,
) {
  updatePolarisView(id: $id, input: $update) {
    success
    errors {
        message
    }
    node {
      ...EdgeView
    }
  }
}
${ViewFragment}
`;

export const updateView = async (
	apolloClient: PolarisApolloClient,
	props: UpdateViewRequest,
): Promise<UpdateViewReturn> => {
	if (!props.viewId) {
		throw new Error('project-config.update-view-error: viewId is undefined');
	}

	const result = await apolloClient.mutate<UpdateView, UpdateViewVariables>({
		mutation: UPDATE_VIEW_MUTATION,
		variables: {
			id: props.viewId,
			update: {
				emoji: props.emoji,
				name: props.name,
				description: props.description,
				fields: props.fields,
				hidden: props.hidden,
				groupBy: props.groupBy,
				verticalGroupBy: props.verticalGroupBy,
				groupValues: props.groupValues,
				verticalGroupValues: props.verticalGroupValues,
				hideEmptyGroups: props.hideEmptyGroups,
				hideEmptyColumns: props.hideEmptyColumns,
				// @ts-expect-error - TS2322 - Type 'UpdateFilter[] | null' is not assignable to type 'readonly PolarisViewFilterInput[] | null | undefined'.
				filter: props.filter,
				// @ts-expect-error - TS2322 - Type 'readonly SortedField[] | null' is not assignable to type 'readonly PolarisSortFieldInput[] | null | undefined'.
				sort: props.sort,
				// @ts-expect-error - TS2322 - Type '"PROJECT_RANK" | "FIELDS_SORT" | "VIEW_RANK" | undefined' is not assignable to type 'PolarisViewSortMode | null | undefined'.
				sortMode: getSortMode(props.sortMode),
				matrixConfig: props.matrixConfig,
				timelineConfig: props.timelineConfig,
				lastCommentsViewedTimestamp: props.lastCommentsViewedTimestamp,
				lastViewedTimestamp: props.lastViewedTimestamp,
				enabledAutoSave: props.enabledAutoSave,
				// @ts-expect-error - loosen type
				fieldRollups: props.fieldRollups,
				tableColumnSizes: props.tableColumnSizes,
				// @ts-expect-error - TS2322 - Type 'ViewLayoutType.COMPACT' is not assignable to type 'PolarisViewLayoutType | null | undefined'
				layoutType: props.layoutType,
			},
			isShareViewDialogEnabled: ff('polaris.sharing-enabled'),
			skipDescription: false,
		},
	});

	if (!result.data?.updatePolarisView?.node) {
		throw new ViewNotFoundError('project-config.update-view-error: no data or no data node');
	}

	return {
		node: { ...result.data.updatePolarisView.node },
	};
};
