import {
	type OpenDeleteIssueModal,
	type CloseDeleteIssueModal,
	type DeleteIssueRequest,
	type DeleteIssueSuccess,
	type DeleteIssueFailure,
	OPEN_DELETE_ISSUE_MODAL,
	CLOSE_DELETE_ISSUE_MODAL,
	DELETE_ISSUE_REQUEST,
	DELETE_ISSUE_SUCCESS,
	DELETE_ISSUE_FAILURE,
} from '../actions/issue-delete-actions';

type Action =
	| OpenDeleteIssueModal
	| CloseDeleteIssueModal
	| DeleteIssueRequest
	| DeleteIssueSuccess
	| DeleteIssueFailure;

type IssueDeleteUIState = {
	isDeleteIssueModalOpen: boolean;
	isDeletingIssue: boolean;
	didIssueDeleteFail: boolean;
	issueKey: string | null;
};

const defaultIssueDeleteUIState = {
	isDeleteIssueModalOpen: false,
	isDeletingIssue: false,
	didIssueDeleteFail: false,
	issueKey: null,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueDeleteUIState = defaultIssueDeleteUIState, action: Action) => {
	switch (action.type) {
		case OPEN_DELETE_ISSUE_MODAL:
			return {
				...state,
				isDeleteIssueModalOpen: true,
			};
		case CLOSE_DELETE_ISSUE_MODAL:
			return {
				...state,
				isDeleteIssueModalOpen: false,
			};
		case DELETE_ISSUE_REQUEST:
			return {
				...state,
				isDeletingIssue: true,
				didIssueDeleteFail: false,
				issueKey: action.payload.issueKey,
				application: action.payload.application,
				edition: action.payload.edition,
			};
		case DELETE_ISSUE_SUCCESS:
			return {
				...state,
				isDeletingIssue: false,
				didIssueDeleteFail: false,
				issueKey: action.payload,
			};
		case DELETE_ISSUE_FAILURE:
			return {
				...state,
				isDeletingIssue: false,
				didIssueDeleteFail: true,
				issueKey: action.payload.issueKey,
			};
		default:
			return state;
	}
};
