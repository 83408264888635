import React from 'react';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useDecorationForValue } from '../../../../../controllers/field/selectors/decoration/hooks.tsx';
import { useOptionLabel } from '../../../../../controllers/issue/selectors/options-hooks';
import { DecoratedTagComponent } from '../../../../decoration/tag/index.tsx';
import type { DecoratedTagProps, DynamicDecoratedTagProps } from './types';

const DecoratedTagInternal = ({ fieldKey, id, value, compact, iconOnly }: DecoratedTagProps) => {
	const decoration = useDecorationForValue(fieldKey, id);
	const emojiDescription = useEmoji(decoration?.emoji);

	return (
		<DecoratedTagComponent
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
			value={value}
			compact={compact}
			iconOnly={iconOnly}
		/>
	);
};

export const DynamicOptionDecoratedTag = ({
	fieldKey,
	id,
	compact,
	iconOnly,
}: DynamicDecoratedTagProps) => {
	const label = useOptionLabel(fieldKey, id);

	return label !== undefined ? (
		<DecoratedTagInternal
			fieldKey={fieldKey}
			id={id}
			value={label}
			compact={compact}
			iconOnly={iconOnly}
		/>
	) : null;
};

export const DecoratedTag = DecoratedTagInternal;
