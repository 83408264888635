import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { EXTERNAL_REFERENCE_PROPERTY_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/external-reference-property/types.tsx';
import type {
	ExternalReferenceEntitiesMap,
	ExternalReferencePropertyEntity,
} from '../../../services/atlas/types';
import type { PropertyMap } from '../types';
import { getExternalReferencePropertyIdentificator } from '../utils/external-reference-property';
import { getFields } from './fields';
import { getRankedIssueIds } from './issue-ids';
import {
	getExternalReferencesProperties,
	getExternalReferenceEntitiesProperties,
} from './properties';

export type ExternalReferenceProperties = {
	[key: string]: ExternalReferencePropertyEntity;
};

const getAllExternalReferenceFields = createSelector(getFields, (fields: Field[]): Field[] =>
	filter(
		fields,
		(field) => field.type === FIELD_TYPES.ATLAS_GOAL || field.type === FIELD_TYPES.ATLAS_PROJECT,
	),
);

const getAllExternalReferencePropertyFields = createSelector(
	getFields,
	(fields: Field[]): Field[] =>
		filter(fields, (field) => field.type === FIELD_TYPES.ATLAS_PROJECT_STATUS),
);

export const getAllExternalReferencesProperties = createSelector(
	getRankedIssueIds,
	getAllExternalReferenceFields,
	getAllExternalReferencePropertyFields,
	getExternalReferencesProperties,
	getExternalReferenceEntitiesProperties,
	(
		allIssues: LocalIssueId[],
		externalReferenceFields: Field[],
		externalReferencePropertyFields: Field[],
		externalReference: PropertyMap<string | string[] | undefined>,
		externalReferenceEntities: ExternalReferenceEntitiesMap,
	): ExternalReferenceProperties => {
		const retVal: ExternalReferenceProperties = {};
		externalReferencePropertyFields.forEach((externalReferencePropertyField: Field) => {
			const { formula } = externalReferencePropertyField;
			if (formula?.template !== EXTERNAL_REFERENCE_PROPERTY_TEMPLATE) {
				return;
			}
			if (!formula?.parameters?.externalReferenceFieldKey) {
				return;
			}
			const externalReferenceField = externalReferenceFields.find(
				(f: Field) => f.key === formula?.parameters?.externalReferenceFieldKey,
			);
			if (!externalReferenceField) {
				return;
			}
			allIssues.forEach((issueId) => {
				const externalReferencesValue = externalReference[externalReferenceField.key]?.[issueId];
				if (externalReferencesValue !== undefined) {
					(Array.isArray(externalReferencesValue)
						? externalReferencesValue
						: [externalReferencesValue]
					).forEach((value: string) => {
						if (!externalReferenceEntities[value]) {
							return;
						}
						const entity = externalReferenceEntities[value];

						if (!entity) {
							return;
						}

						let property;
						switch (formula?.parameters?.property) {
							case 'state':
								property = entity.state;
								break;
							default:
								return;
						}

						if (!property) {
							return;
						}

						retVal[getExternalReferencePropertyIdentificator(property)] = property;
					});
				}
			});
		});
		return retVal;
	},
);
