import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { type State, type SelectedIssue, initialState, type SuccessCallback } from '../../types';

export const toggleMergeIdeasDialog =
	(selectedIssues?: SelectedIssue[], successCallback?: SuccessCallback) =>
	({ setState, getState }: StoreActionApi<State>) => {
		const newIsOpen = !getState().isOpen;
		if (newIsOpen) {
			setState({
				isOpen: newIsOpen,
				successCallback,
				selectedIssues: Array.isArray(selectedIssues) ? selectedIssues : [],
			});
			return;
		}
		setState(initialState);
	};
