import uuid from 'uuid';
import { VIEW_KIND_SECTION } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { createFakeId, updateProjectState } from '../utils';

export const insertViewSet =
	(): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationRemote, cloudId, projectId } = props;
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		setState(
			updateProjectState(getState(), projectId, {
				views: [
					...getState().projects[projectId].views,
					{
						id: createFakeId(),
						polarisId: 0,
						name: '',
						localId: uuid.v4(),
						isRenaming: true,
						isExpanded: false,
						views: [],
						viewType: VIEW_KIND_SECTION,
					},
				],
			}),
		);
	};
