import { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks';
import { useBoardViewVisibleFields } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useStatusCategories } from '@atlassian/jira-polaris-common/src/controllers/workflow/selectors/status-categories-hook.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from '../../messages';
import { getFieldsLabels } from '../../utils';
import type { ExportViewKindProps } from '../types';

export const ExportBoardView = (props: ExportViewKindProps) => {
	const { exportStringValues } = useIssueActions();

	const { formatMessage } = useIntl();
	const { locale } = useTenantContext();
	const statusCategories = useStatusCategories();

	const visibleFields = useBoardViewVisibleFields();
	const issueIds = useSortedIssueIds();
	const fieldNeededValues = useMemo(
		() => ({
			locale,
			statusCategories,
			messages,
			formatMessage,
		}),
		[locale, statusCategories, formatMessage],
	);
	const stringMatrix = useMemo(() => {
		const valueMatrix = exportStringValues(visibleFields, issueIds, fieldNeededValues);
		return [getFieldsLabels(visibleFields), ...valueMatrix];
	}, [exportStringValues, visibleFields, issueIds, fieldNeededValues]);

	return props.children(stringMatrix);
};
