import {
	createGetColumnConfiguration,
	createGetColumnSize,
	getActualColumnSizes,
	getColumnConfiguration,
	getColumnIds,
	getFixedColumnSizes,
	getGroupRowConfiguration,
	getRowConfiguration,
	getRowGroups,
	getUpdatedRowProps,
	getShouldRenderRowPinnedBottomForGroups,
	getShouldRenderRowPinnedBottomForNoGroups,
} from './items';
import { createSelectorHook, createHigherLevelHook } from './utils';

export const useColumnIds = createSelectorHook(getColumnIds);
export const useRowGroups = createSelectorHook(getRowGroups);

export const useColumnConfiguration = createSelectorHook(getColumnConfiguration);
export const useColumnConfigurationByColumnId = createHigherLevelHook(createGetColumnConfiguration);
export const useRowConfiguration = createSelectorHook(getRowConfiguration);
export const useGroupRowConfiguration = createSelectorHook(getGroupRowConfiguration);

export const useUpdatedRowProps = createSelectorHook(getUpdatedRowProps);

export const useActualColumnSizes = createSelectorHook(getActualColumnSizes);

export const useGetFixedColumnSizes = createSelectorHook(getFixedColumnSizes);

export const useColumnSize = createHigherLevelHook(createGetColumnSize);

export const useShouldRenderRowPinnedBottomForGroups = createSelectorHook(
	getShouldRenderRowPinnedBottomForGroups,
);

export const useShouldRenderRowPinnedBottomForNoGroups = createSelectorHook(
	getShouldRenderRowPinnedBottomForNoGroups,
);
