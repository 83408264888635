import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	hint: {
		id: 'polaris-common.config.fields.common.voting-hint.hint',
		defaultMessage: 'Select the number of votes to give each person to allocate to ideas.',
		description: 'Explains how the voting work',
	},
	example: {
		id: 'polaris-common.config.fields.common.voting-hint.example',
		defaultMessage:
			'For example, you select 10 votes per person. A person can give 5 votes to one idea, and 1 vote to 5 other ideas, for a total of 10 votes.',
		description: 'Provides an example of how the voting work',
	},
});
