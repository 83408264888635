import every from 'lodash/every';
import has from 'lodash/has';
import partial from 'lodash/partial';
import type { ServerGiraUser, ServerRestUser, User } from '@atlassian/jira-issue-shared-types';
import { toAccountId } from '@atlassian/jira-shared-types';

const requiredKeys = ['accountId', 'displayName', 'avatarUrls', 'timeZone'];

/**
 * Transforms user data from the REST or `/rest/graphql` endpoints.
 */
export const transformUser = ({
	accountId,
	displayName,
	avatarUrls,
	timeZone,
}: ServerRestUser): User => ({
	displayName,
	id: toAccountId(accountId),
	avatarUrl: avatarUrls['48x48'],
	timeZone,
});

/**
 * Transforms user data from the Gira endpoint.
 */
export const transformGraphQlUser = (user: ServerGiraUser): User => {
	const { accountId, displayName, avatarUrl, timeZone } = user;

	return {
		avatarUrl,
		displayName,
		id: accountId,
		...(timeZone ? { timeZone } : {}),
	};
};

export const validateUser = (nullableResponse: ServerRestUser | null): ServerRestUser => {
	if (!nullableResponse) {
		throw new Error('User is null or undefined');
	}

	const hasExpectedKeys = nullableResponse && every(requiredKeys, partial(has, nullableResponse));

	if (!hasExpectedKeys) {
		throw new Error(
			`User object is not expected shape, keys present: ${Object.keys(
				nullableResponse,
			).toString()}`,
		);
	}
	return nullableResponse;
};
