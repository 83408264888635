import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldsExplained: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.explanation.fields-explained',
		defaultMessage: 'Fields explained',
		description: 'Button that opens up an explanation popup for the field filters',
	},
	discoveryFields: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.explanation.discovery-fields',
		defaultMessage: 'Discovery fields',
		description: 'Discovery fields filter',
	},
	discoveryFieldsExplanation: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.explanation.discovery-fields-explanation',
		defaultMessage: 'Fields created by your Jira admin, that can be reused in discovery projects.',
		description: 'Explanation for Discovery fields',
	},
	jiraFields: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.explanation.jira-fields',
		defaultMessage: 'Jira fields',
		description: 'Jira fields filter',
	},
	jiraFieldsExplanation: {
		id: 'polaris-ideas.right-sidebar.global-fields.global-field-list.global-fields-selector.explanation.jira-fields-explanation',
		defaultMessage:
			'Custom fields coming from other Jira products, compatible with discovery projects.',
		description: 'Explanation for Jira fields.',
	},
});
