import { usePathParam } from '@atlassian/react-resource-router';

export { setStorageRouteSlug } from './storage';

export { createQueryStringPostfix } from './query';

/*
    handles inconsistency between ideas and sharing urls
    https://polaris-v0.jira-dev.com/jira/polaris/projects/WQWQWQ/ideas/view/658748
    https://polaris-v0.jira-dev.com/jira/discovery/share/views/31a2851a-0a09-4a80-b0e3-a3b50dd424a0
*/
export const useUrlSection = (): string | undefined => {
	const [section] = usePathParam('section');
	return section === 'views' ? 'view' : section;
};

export const useUrlResource = (): string | undefined => {
	const [resource] = usePathParam('resource');
	return resource;
};
