import React, { useRef } from 'react';
import Popup from '@atlaskit/popup';
import { xcss, Box } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme/constants';
import { PopupComponent, TooltipContent, TriggerComponent } from '../components';
import { TOOLTIP_DELAY } from '../constants';
import { useTruncatedTooltip } from '../use-truncated-tooltip';
import type { TooltipRendererProps, TruncateWithTooltipOnActiveProps } from './types';

const TooltipRenderer = ({ children, shouldShowTooltip }: TooltipRendererProps) => (
	<Popup
		isOpen={!!shouldShowTooltip}
		autoFocus={false}
		placement="bottom-end"
		popupComponent={PopupComponent}
		content={() => (
			<TooltipContent data-testid="command-palette.common.ui.truncate-with-tooltip.truncate-with-tooltip-on-active.tooltip-content">
				{children}
			</TooltipContent>
		)}
		trigger={(triggerProps) => (
			<TriggerComponent
				data-testid="command-palette.common.ui.truncate-with-tooltip.truncate-with-tooltip-on-active.trigger-component"
				{...triggerProps}
			/>
		)}
		zIndex={layers.tooltip()}
	/>
);

export const useContainerRef = () => useRef<HTMLDivElement | null>(null);

export const TruncateWithTooltipOnActive = ({
	children,
	isActive,
	customTooltip,
	showTooltip,
	preventTruncate,
}: TruncateWithTooltipOnActiveProps) => {
	const containerRef = useContainerRef();
	const { shouldShowTruncatedTooltip } = useTruncatedTooltip({
		containerRef,
		tooltipDelay: TOOLTIP_DELAY,
	});

	return (
		<>
			<Box
				ref={containerRef}
				xcss={[tooltipContainerStyles, !preventTruncate && truncateTooltipStyles]}
			>
				{children}
			</Box>
			{isActive && (
				<TooltipRenderer shouldShowTooltip={showTooltip || shouldShowTruncatedTooltip}>
					{showTooltip && customTooltip ? customTooltip : children}
				</TooltipRenderer>
			)}
		</>
	);
};

const tooltipContainerStyles = xcss({
	position: 'relative',
	flexGrow: 1,
	textTransform: 'none',
});

const truncateTooltipStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
