import React, { useMemo } from 'react';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { useIntl } from '@atlassian/jira-intl';
import {
	ACCESS_ROLE_EDITOR_ARI,
	VIEW_ACCESS_LEVELS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type { ViewAccess } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import messages from './messages';

export type NoEditorsSectionProps = ViewAccess & {
	canEdit: boolean;
	onDuplicateView: () => Promise<void>;
};

export const NoEditorsSection = ({
	accessLevel,
	additionalAccess,
	canEdit = false,
	onDuplicateView,
}: NoEditorsSectionProps) => {
	const { formatMessage } = useIntl();

	const isNoEditorsSectionVisible = useMemo(
		() =>
			!canEdit &&
			accessLevel !== VIEW_ACCESS_LEVELS.OPEN &&
			!additionalAccess.groups.some(({ role }) => role === ACCESS_ROLE_EDITOR_ARI) &&
			!additionalAccess.profiles.some(({ role }) => role === ACCESS_ROLE_EDITOR_ARI),
		[accessLevel, additionalAccess.groups, additionalAccess.profiles, canEdit],
	);

	if (!isNoEditorsSectionVisible) {
		return null;
	}

	return (
		<SectionMessage
			title={formatMessage(messages.title)}
			appearance="information"
			actions={[
				<SectionMessageAction key="duplicate-view" onClick={onDuplicateView}>
					{formatMessage(messages.duplicateViewAction)}
				</SectionMessageAction>,
			]}
		>
			{formatMessage(messages.content)}
		</SectionMessage>
	);
};
