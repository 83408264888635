import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noticeText: {
		id: 'polaris-component-field-configuration.configuration.formula.dependency-info.footer.notice-text',
		defaultMessage:
			'<b>Note:</b> When you add a global custom formula to a project, all the fields used in the custom formula are also added to the project.',
		description:
			'Explains to the user that the formula field has dependencies that will be added to a project alongside it',
	},
});
