import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AccountId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Params = {
	issueKey: IssueKey;
	accountId: AccountId;
};

export const unwatchUrl = ({ issueKey, accountId }: Params) =>
	accountId ? `/rest/api/2/issue/${issueKey}/watchers?accountId=${accountId}` : '';

export const deleteWatcher = (issueKey: IssueKey, accountId: AccountId) =>
	performDeleteRequest(unwatchUrl({ issueKey, accountId }));
