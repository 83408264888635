import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { FieldMapping } from '../utils/field-mapping/types';
import { getIssueIdField } from './field-utils';
import {
	createGetFieldOptionsWithAriResolved,
	createGetField,
	createGetFieldMapping,
	createGetFieldLabel,
	createGetIsWeightedField,
	getAllFieldValues,
	getSortedDistinctIssueStatuses,
	getIssuesStatusById,
	getIssuesSimiliarStatusIdsByStatusId,
} from './fields';
import { createHigherLevelIssueHook, createHigherLevelIssueHook3, createIssueHook } from './utils';

export const useFieldOptionsWithAriResolved = createHigherLevelIssueHook<
	FieldKey | undefined,
	OptionProperty[]
>(createGetFieldOptionsWithAriResolved);

export const useField = createHigherLevelIssueHook<FieldKey | undefined, Field | undefined>(
	createGetField,
);

export const useFieldMapping = createHigherLevelIssueHook<
	FieldKey | undefined,
	FieldMapping<unknown> | undefined
>(createGetFieldMapping);
export const useIsWeightedField = createHigherLevelIssueHook<FieldKey, boolean>(
	createGetIsWeightedField,
);

export const useFieldLabel = createHigherLevelIssueHook3<
	FieldKey,
	string | undefined,
	unknown,
	string | undefined
>(createGetFieldLabel);

export const useIssueIdField = createIssueHook(getIssueIdField);

export const useAllFieldValues = createHigherLevelIssueHook(getAllFieldValues);
export const useSortedDistinctIssueStatuses = createIssueHook(getSortedDistinctIssueStatuses);

export const useIssuesStatusesById = createIssueHook(getIssuesStatusById);
export const useIssuesSimiliarStatusIdsByStatusId = createIssueHook(
	getIssuesSimiliarStatusIdsByStatusId,
);
