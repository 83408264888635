import React from 'react';
import { styled } from '@compiled/react';
import { Radio } from '@atlaskit/radio';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { NumericFieldFilterValue } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { filters } from '../utils';
import type { UiFilter } from '../utils/types';

type Props = {
	values: NumericFieldFilterValue[];
	onChange: (values: NumericFieldFilterValue[]) => void;
};
export const IntervalFilterContentContainer = ({ values, onChange }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ContentContainer>
			{filters.map((filter: UiFilter) => (
				<Radio
					id={filter.id}
					key={filter.id}
					name={filter.id}
					value={filter.id}
					label={formatMessage(filter.label)}
					isChecked={filter.isChecked(values)}
					onChange={() => {
						fireCompoundAnalyticsEvent.NavSidebarArchiveViewClicked(
							createAnalyticsEvent({ filter: filter.id }),
						);
						onChange(filter.getValues() || []);
					}}
				/>
			))}
		</ContentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '180px',
	maxWidth: '180px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		marginBottom: token('space.025', '2px'),
	},
});
