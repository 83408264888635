import type { Observable as ObservableType } from 'rxjs/Observable';
import { fetchJson$ } from '@atlassian/jira-fetch';
import type { IssueId } from '@atlassian/jira-shared-types';

type Payload = {
	issueId: IssueId;
};

export const getIssueSetParentUrlCMP = (issueId: IssueId) => `/rest/api/3/issue/${issueId}`;

export const setIssueParentCMP = (parentId: IssueId, payload: Payload): ObservableType<unknown> =>
	fetchJson$(getIssueSetParentUrlCMP(payload.issueId), {
		method: 'PUT',
		body: JSON.stringify({ fields: { parent: { id: String(parentId) } } }),
	});
