import {
	createViewHook as createViewHookMain,
	createHigherLevelViewHook as createHigherLevelViewHookMain,
} from '../main';
import type { Props, State } from '../types';

export const createViewHook = createViewHookMain;

export const createHigherLevelViewHook = <TArg1, TReturnValue>(
	selectorCreator: (arg1: TArg1) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1) => TReturnValue) =>
	createHigherLevelViewHookMain<TReturnValue, [TArg1]>(selectorCreator);
