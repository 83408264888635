import React, { useMemo } from 'react';
import { createRemoteContext } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/context/index.tsx';
import { createGlobalFieldRemote, createSharingGlobalFieldRemote } from './controllers';
import type { GlobalFieldRemote } from './controllers/types';

export type { View } from './services/polaris-api/types';
export type { IssueType, JiraApiGlobalField } from './services/jira/types';

export { PartialAssociationError } from './services/jira/associate-global-field';
export { AssociationHardLimitError } from './services/jira/associate-global-field/errors';

export type { GlobalFieldRemote };

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export {
	getGlobalFieldsMock,
	getMockSharedFieldProperties,
	getMockProjectCounts,
} from './services/jira/fetch-global-fields/mocks';

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { getGlobalFieldContextIdMock } from './services/jira/get-global-field-context-id/mocks';

// eslint-disable-next-line jira/import/no-dev-code-import-in-app-code
export { getAddGlobalFieldOptionMock } from './services/jira/add-global-field-option/mocks';

const { RemoteContextProvider, useRemoteFromContext } =
	createRemoteContext<GlobalFieldRemote>('global-field');

export const GlobalFieldRemoteProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const fieldRemote = useMemo(() => createGlobalFieldRemote(), []);

	return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
};

export const GlobalFieldSharingRemoteProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const fieldRemote = useMemo(() => createSharingGlobalFieldRemote(), []);

	return <RemoteContextProvider remote={fieldRemote}>{children}</RemoteContextProvider>;
};

export const useGlobalFieldRemote = useRemoteFromContext;
