import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	summary: {
		id: 'polaris-component-fixed-field-description.summary',
		defaultMessage: 'A one-liner explaining what the idea is',
		description: 'Fixed description for the summary field',
	},
	assignee: {
		id: 'polaris-component-fixed-field-description.assignee',
		defaultMessage: 'Which user the idea is assigned to',
		description: 'Fixed description for the assignee field',
	},
	created: {
		id: 'polaris-component-fixed-field-description.created',
		defaultMessage: 'When the idea was created',
		description: 'Fixed description for the created field',
	},
	creator: {
		id: 'polaris-component-fixed-field-description.creator',
		defaultMessage: 'The user who created the idea',
		description: 'Fixed description for the creator field',
	},
	key: {
		id: 'polaris-component-fixed-field-description.key',
		defaultMessage: 'The Jira issue key',
		description: 'Fixed description for the key field',
	},
	reporter: {
		id: 'polaris-component-fixed-field-description.reporter',
		defaultMessage:
			'If the idea was suggested by someone other than the creator, you can specify this user as the reporter',
		description: 'Fixed description for the reporter field',
	},
	status: {
		id: 'polaris-component-fixed-field-description.status',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			"The idea's status within the workflow. {br}To reorder options in the status field dropdown, <statusLink>see instructions here.</statusLink>",
		description: 'Fixed description for the status field',
	},
	updated: {
		id: 'polaris-component-fixed-field-description.updated',
		defaultMessage: 'When the idea was last updated',
		description: 'Fixed description for the updated field',
	},
	labels: {
		id: 'polaris-component-fixed-field-description.labels',
		defaultMessage: 'Data used to tag or classify ideas',
		description: 'Fixed description for the labels field',
	},
}) as { [key: FieldKey]: MessageDescriptorV2 };
