import React, { useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { zendeskIcon, zendeskIconDark } from '../../../../common/ui/apps';
import { UnfurlDialog } from '../../unfurl-dialog';
import type { AppPreset } from '../types';
import messages from './messages';

const UnfurlAction = () => {
	const { formatMessage } = useIntl();
	return (
		<UnfurlDialog
			title={formatMessage(messages.unfurlDialogTitle)}
			placeholder={formatMessage(messages.unfurlDialogPlaceholder)}
			successMessage={formatMessage(messages.unfurlDialogSuccess)}
		/>
	);
};

export const useZendeskPreset = (): AppPreset => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	return useMemo(
		() => ({
			key: 'zendesk',
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: isDarkMode ? zendeskIconDark : zendeskIcon,
			isRecommended: false,
			Action: UnfurlAction,
		}),
		[formatMessage, isDarkMode],
	);
};
