import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	invalidExpressionError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.invalid-expression-error',
		defaultMessage: 'Invalid expression',
		description: 'Error message representing an incorrect expression',
	},
	internalExpressionError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.internal-expression-error',
		defaultMessage: 'Could not parse expression',
		description: 'Error message representing an unspecified problem parsing an expression',
	},
	noMultipleAppsError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.no-multiple-apps-error',
		defaultMessage: 'Can only specify app once in a filter',
		description:
			'Error message indicating that an app clause was specified multiple times in a filter',
	},
	noSuchFieldError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.no-such-field-error',
		defaultMessage: 'Field "{0}" does not exist or was renamed',
		description:
			'Error message indicating that a field mentioned in the write your own formula expression does not exist or was renamed',
	},
	fieldRestrictedError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.field-restricted-error',
		defaultMessage: 'Field "{0}" is context-restricted',
		description: 'Error message indicating that a field is restricted',
	},
	unsupportedFieldError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.unsupported-field-error',
		defaultMessage: 'Field "{0}" is not supported yet',
		description: 'Error message indicating that a field is not supported',
	},
	cyclicReferenceError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.cyclic-reference-error',
		defaultMessage: 'Field "{0}" references this expression directly or indirectly',
		description:
			'Error message indicating that a field mentioned in the expression is already using this field',
	},
	appRequiredError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.app-required-error',
		defaultMessage: 'Must specify app for sum() operation',
		description:
			'Error message indicating that when using the sum() operation, an app must be specified in the filter',
	},
	sumFieldRequiredError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.sum-field-required-error',
		defaultMessage: 'First parameter of sum() must be a property identifier',
		description:
			'Error message indicating that the first argument to sum() must be the identifier for a property',
	},
	sumArgumentCountError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.sum-argument-count-error',
		defaultMessage: 'The sum() operation requires 1 or 2 arguments',
		description:
			'Error message indicating that the sum() operation needs to have either 1 or 2 arguments',
	},
	multipleFieldsError: {
		id: 'polaris-component-field-configuration.configuration.formula.expression-type-in.expressions.multiple-fields-error',
		defaultMessage: 'There are multiple fields with the name "{0}"',
		description: 'Error message indicating that there are multiple fields with the same name',
	},
});
