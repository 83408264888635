import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.slack.title',
		defaultMessage: 'Slack app',
		description: 'Title of the slack polaris app',
	},
	description: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.slack.description',
		defaultMessage: 'Add messages to ideas to keep track of requests and important conversations',
		description: 'Description of the slack polaris app',
	},
});
