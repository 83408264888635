import React, { useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useAreRealtimeQueueQuotasExceeded } from '@atlassian/jira-polaris-lib-realtime-queue';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import coffee from './assets/coffee.svg';
import messages from './messages';

export const RealtimeEventsRefreshBanner = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { areQueueQuotasExceeded, eventsInQueue } = useAreRealtimeQueueQuotasExceeded();
	const bannerShownTimeRef = useRef(0);

	useEffect(() => {
		if (areQueueQuotasExceeded) {
			bannerShownTimeRef.current = Date.now();
			// https://data-portal.internal.atlassian.com/analytics/registry/60605
			fireUIAnalytics(createAnalyticsEvent({}), 'realTimeEventsBanner shown', {
				eventsInQueue,
			});
		}
	}, [areQueueQuotasExceeded, eventsInQueue, createAnalyticsEvent]);

	if (!areQueueQuotasExceeded) {
		return null;
	}

	const handleRefresh = () => {
		const timeElapsedInSeconds = Math.floor((Date.now() - bannerShownTimeRef.current) / 1000);
		// https://data-portal.internal.atlassian.com/analytics/registry/60606
		fireUIAnalytics(createAnalyticsEvent({}), 'realTimeEventsBannerRefreshButton clicked', {
			timeElapsedInSeconds,
		});
		// add an artificial delay to ensure the analytic event is sent before refreshing the page
		setTimeout(() => window.location.reload(), 500);
	};

	return (
		<Banner>
			<img src={coffee} alt="coffee icon" />
			<BannerHeading>{formatMessage(messages.inactiveChangesHeading)}</BannerHeading>
			<>{formatMessage(messages.inactiveChangesText)}</>
			<Button appearance="link" onClick={handleRefresh}>
				{formatMessage(messages.refreshButton)}
			</Button>
		</Banner>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerHeading = styled.h5({
	margin: `0 0 ${token('space.075', '6px')} 0`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Banner = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	gap: token('space.200', '16px'),
	position: 'fixed',
	left: 0,
	top: 'var(--topNavigationHeight)',
	bottom: 0,
	right: 0,
	// above the left sidebar but below the top menu bar dropdown menus
	zIndex: 199,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: `linear-gradient(rgba(0, 0, 0, 0), ${token('color.background.accent.blue.subtlest', colors.B50)} 50%)`,
});
