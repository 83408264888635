import React, { useCallback, useMemo, type ReactNode, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Breadcrumbs, { BreadcrumbsItem as AtkBreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	useIssueKey,
	useIssueType,
	useSummary,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useCloseRightSidebar } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { usePolarisRouter } from '@atlassian/jira-polaris-common/src/controllers/route';
import { CopyLink } from '@atlassian/jira-polaris-common/src/ui/copy-link';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	localIssueId: LocalIssueId;
	title?: string;
	icon?: ReactNode;
	onClose: () => void;
};

export const IssueHeader = ({ localIssueId, title, icon = null, onClose }: Props) => {
	const issueKey = useIssueKey(localIssueId);
	const summary = useSummary(localIssueId);
	const issueType = useIssueType(localIssueId);
	const closeRightSidebar = useCloseRightSidebar();
	const { openIssueView } = usePolarisRouter();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const issueTypeIcon = useMemo(() => issueType?.iconUrl, [issueType]);

	const handleClick = useCallback(
		(evt: SyntheticEvent) => {
			evt.preventDefault();
			openIssueView(issueKey);
			closeRightSidebar();
		},
		[closeRightSidebar, issueKey, openIssueView],
	);

	const onCopyLink = () => {
		fireCompoundAnalyticsEvent.IdeaView.copyIdeaLinkButtonClicked(createAnalyticsEvent({}));
	};

	return (
		<>
			<HeaderContainer>
				<Left>
					<Breadcrumbs>
						<BreadcrumbsItem
							data-component-selector="breadcrumbs-item-7Hw5"
							testId="polaris-ideas.ui.right-sidebar.header.issue.key"
							// @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
							text={
								<>
									{issueKey}
									{title === undefined && (
										<LinkIconWrapper>
											<CopyLink size="small" appearance="subtle-link" onCopy={onCopyLink} />
										</LinkIconWrapper>
									)}
								</>
							}
							iconBefore={
								<Avatar
									testId="polaris-ideas.ui.right-sidebar.header.issue.avatar"
									src={issueTypeIcon}
									size="xsmall"
								/>
							}
							onClick={handleClick}
						/>
						{title !== undefined && (
							<BreadcrumbsItemLast
								testId="polaris-ideas.ui.right-sidebar.header.issue.sub"
								text={title}
								iconBefore={<Box xcss={iconContainerStyles}>{icon}</Box>}
							/>
						)}
					</Breadcrumbs>
				</Left>
				<Button
					appearance="subtle-link"
					iconBefore={<CrossIcon label="close" size="medium" />}
					onClick={onClose}
				/>
			</HeaderContainer>
			<Summary>{summary}</Summary>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	paddingRight: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingLeft: '14px',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '26px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '14px',
	lineHeight: '24px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '20px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N700),
	paddingRight: token('space.200', '16px'),
	paddingLeft: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Left = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const iconContainerStyles = xcss({
	paddingRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsItem = styled(AtkBreadcrumbsItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&& span': {
		display: 'flex',
		alignItems: 'center',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsItemLast = styled(AtkBreadcrumbsItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&&': {
		pointerEvents: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkIconWrapper = styled.div({
	visibility: 'hidden',
	marginLeft: token('space.negative.025', '-2px'),
	paddingLeft: 0,
	cursor: 'pointer',
	opacity: 0,
	transition: 'opacity 150ms, visibility 300ms, margin-left 300ms, padding-left 300ms',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[data-component-selector="breadcrumbs-item-7Hw5"]:hover &': {
		visibility: 'visible',
		opacity: 1,
		marginLeft: 0,
		paddingLeft: token('space.050', '4px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='presentation']": {
		display: 'flex',
	},
});
