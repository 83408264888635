import type { Ari } from '@atlassian/jira-platform-ari';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { getRootViewSet } from '@atlassian/jira-polaris-remote-view/src/controllers/views/jpd-view-service/transform/index.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { notImplementedError } from '../../common/utils';
import { fetchAllCollectionViews } from '../../services/jpd-views-service/fetch-collection-view';
import { updateCollectionViewRank } from '../../services/jpd-views-service/update-collection-view-rank';
import { getViewARI } from '../../services/jpd-views-service/utils';
import { fetchAndTransformViewRanks, updateAndTransformViewRank } from './main';
import type { RankedView, ViewRankRemote } from './types';

export const createPolarisApiViewRankRemote = (
	apolloClient: PolarisApolloClient,
	projectAri: Ari,
): ViewRankRemote => ({
	fetchViewRanks: () => fetchAndTransformViewRanks(apolloClient, projectAri),
	updateViewRank: (props) => updateAndTransformViewRank(apolloClient, props),
});

const transformViewToRankedView =
	(cloudId: CloudId) =>
	(view: ViewResponse, index: number): RankedView => ({
		id: getViewARI(cloudId, view.uuid),
		rank: index + 1,
	});

export const createCollectionViewRankRemote = (
	cloudId: CloudId,
	collectionUUID: string,
): ViewRankRemote => ({
	fetchViewRanks: () =>
		fetchAllCollectionViews(collectionUUID).then((views) => ({
			id: '',
			views: [],
			viewsets: [
				{
					viewsets: [],
					...getRootViewSet(views.map(transformViewToRankedView(cloudId))),
					id: getViewARI(cloudId, collectionUUID),
				},
			],
		})),
	updateViewRank: (props) =>
		updateCollectionViewRank(collectionUUID, props).then(() => ({
			views: [],
			viewsets: [],
		})),
});

export const createNotImplementedViewRankRemote = (): ViewRankRemote => ({
	fetchViewRanks: notImplementedError('fetchViewRanks'),
	updateViewRank: notImplementedError('updateViewRank'),
});
