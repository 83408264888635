import {
	createHook,
	createStateHook,
	createStore,
	createContainer,
} from '@atlassian/react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'issue-view-layout-ecosystem-service',
	initialState: {
		activityPanels: {},
		canInstallAddons: false,
		contentPanels: {},
		backgroundScripts: [],
		ecosystemOnDate: 0,
		glances: {},
		contextPanels: {},
		operations: [],

		issueKey: '',
	},
	actions,
});

export const EcosystemStoreContainer = createContainer(store);

export const useEcosystemStoreContentPanels = createHook(store, {
	selector: (state) => state.contentPanels,
});

export const useEcosystemStoreContextPanels = createHook(store, {
	selector: (state) => state.contextPanels,
});

export const useEcosystemStoreGlancePanels = createHook(store, {
	selector: (state) => state.glances,
});

export const useEcosystemStoreKey = createStateHook(store, {
	selector: (state) => state.issueKey,
});

export const useEcosystemActionsStore = createHook(store, {
	selector: null,
});
