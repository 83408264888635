import React, { memo, useCallback } from 'react';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left-large';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { BreadCrumbsButton } from '@atlassian/jira-issue-view-common-views/src/breadcrumbs-button';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { Link } from '@atlassian/jira-router';
import messages from './messages';

export type Props = {
	url: string;
};

export default memo<Props>(({ url }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'backFromIssueViewButton');
	}, [createAnalyticsEvent]);

	return (
		<Box xcss={wrapperStyles}>
			<BreadCrumbsButton
				href={url}
				appearance="subtle"
				onClick={handleClick}
				component={__SPA__ ? Link : undefined}
				iconBefore={
					<ChevronLeftIcon
						label=""
						size="medium"
						primaryColor={token('color.icon.subtle', colors.N300)}
					/>
				}
			>
				{formatMessage(messages.back)}
			</BreadCrumbsButton>
		</Box>
	);
});

const wrapperStyles = xcss({
	marginRight: 'space.150',
	marginLeft: 'space.negative.150',
});
