import type { FieldsByKey } from '../collections/types';
import { isDeliveryFieldType } from '../field-types';
import { isIntervalFieldWithDeliveryDateConfig } from '../field/interval/utils';
import type { Field, FieldKey } from '../field/types';

const isDependingOnDeliveryData = (field: Field): boolean =>
	isDeliveryFieldType(field.type) || isIntervalFieldWithDeliveryDateConfig(field);

export const hasFieldKeyDependingOnDeliveryData = (
	fieldKeys: FieldKey[],
	fields: FieldsByKey,
): boolean =>
	!!fieldKeys.find((fieldKey) => fields[fieldKey] && isDependingOnDeliveryData(fields[fieldKey]));
