import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { addAsLink } from './add-as-link';
import { handleAuthFailure } from './auth/handle-failure';
import { handleAuthSuccess } from './auth/handle-success';
import { unfurl } from './unfurl';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	unfurl,
	addAsLink,
	handleAuthSuccess,
	handleAuthFailure,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};
