import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { useHasDefaultFieldOptionWeights } from '../../../../../../../controllers/field/selectors/options-hooks';
import { messages } from '../../messages';
import { FieldRollupTypeWrapper } from '../../styled';

type RollupItemFormulaTypeComponentProps = {
	fieldKey: FieldKey;
	typeName: string;
};

type RollupItemFormulaHelpTextComponentProps = {
	fieldKey: FieldKey;
	fieldType: FieldType;
	helpText: string;
};

export const RollupItemFormulaTypeComponent = ({
	fieldKey,
	typeName,
}: RollupItemFormulaTypeComponentProps) => {
	const { formatMessage } = useIntl();
	const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(fieldKey);

	return (
		<FieldRollupTypeWrapper>
			{!hasDefaultFieldOptionWeights ? formatMessage(messages.weightType) : typeName}
		</FieldRollupTypeWrapper>
	);
};

export const RollupItemFormulaHelpTextComponent = ({
	fieldKey,
	fieldType,
	helpText,
}: RollupItemFormulaHelpTextComponentProps) => {
	const { formatMessage } = useIntl();
	const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(fieldKey);

	return (
		<>
			{!hasDefaultFieldOptionWeights
				? formatMessage(
						fieldType === FIELD_TYPES.SINGLE_SELECT
							? messages.weightSelectTypeHelpText
							: messages.weightMultiSelectTypeHelpText,
					)
				: helpText}
		</>
	);
};
