import { createSelector } from 'reselect';
import indexOf from 'lodash/indexOf';
import sumBy from 'lodash/sumBy';
import takeRightWhile from 'lodash/takeRightWhile';
import takeWhile from 'lodash/takeWhile';
import without from 'lodash/without';
import type { ColumnId, RowId } from '../../common/types';
import type { BaseTableApi } from '../../common/types/react-base-table';
import type { State } from '../types';
import { getFixedColumns } from './columns';
import { getActualColumnSizes, getColumnIds } from './items';

export const getDraggingRowId = (state: State): string | undefined => state.draggingRowId;

export const createIsDraggingRow = (rowId: RowId) =>
	createSelector(getDraggingRowId, (draggingRowId) => draggingRowId === rowId);

export const getScrollPositionLeft = (state: State): number => state.scrollPosition.left;

export const getScrollPositionTop = (state: State): number => state.scrollPosition.top;

export const getIsScrolledHorizontally = (state: State): boolean => state.scrollPosition.left !== 0;

export const getIsScrolledVertically = (state: State): boolean => state.scrollPosition.top !== 0;

export const getWidth = (state: State): number => state.dimensions.width;

export const getBaseTableApi = (state: State): BaseTableApi | undefined => state.baseTableApi;

export const getClientWidthFunction = (state: State): (() => number) =>
	state.dimensions.clientWidth;

export const getIsExporting = (state: State): boolean => state.isExporting;

const getNonFixedColumnIds = createSelector(
	getColumnIds,
	getFixedColumns,
	(columnIds, fixedColumns) => without(columnIds, ...fixedColumns),
);

const getFixedColumnsWidth = createSelector(
	getFixedColumns,
	getActualColumnSizes,
	(fixedColumns, columnSizes) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		sumBy(fixedColumns, (colId) => columnSizes[colId] as unknown as number),
);

export const getVisibleColumnIds = createSelector(
	getWidth,
	getScrollPositionLeft,
	getActualColumnSizes,
	getNonFixedColumnIds,
	getFixedColumnsWidth,
	getIsExporting,
	(width, scrollPosition, columnSizes, nonFixedColumnIds, fixedColumnWidth, isExporting) => {
		if (isExporting) {
			return nonFixedColumnIds;
		}

		const viewPortEnd = scrollPosition + width - fixedColumnWidth;
		const visibleColumnIds: Array<ColumnId> = [];
		let position = 0;

		nonFixedColumnIds.forEach((columnId) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const end = position + (columnSizes[columnId] as unknown as number);

			if (end > scrollPosition && position < viewPortEnd) {
				visibleColumnIds.push(columnId);
			}

			position = end;
		});
		return visibleColumnIds;
	},
);

export const getFixedColumnIndices = createSelector(
	getColumnIds,
	getFixedColumns,
	(columnIds, fixedColumnIds) => fixedColumnIds.map((id) => indexOf(columnIds, id)),
);

export const getVisibleColumnIndices = createSelector(
	getColumnIds,
	getVisibleColumnIds,
	getFixedColumnIndices,
	(columnIds, visibleColumnIds, fixedColumnIndices) => [
		...fixedColumnIndices,
		...visibleColumnIds.map((id) => indexOf(columnIds, id)),
	],
);

export const getInvisibleColumnPlaceholderWidthLeft = createSelector(
	getNonFixedColumnIds,
	getActualColumnSizes,
	getVisibleColumnIds,
	(columnIds, columnSizes, visibleColumns) => {
		const invisibleColsLeft = takeWhile(columnIds, (key) => !visibleColumns.includes(key));

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return sumBy(invisibleColsLeft, (key) => columnSizes[key] as unknown as number);
	},
);

export const getInvisibleColumnPlaceholderWidthRight = createSelector(
	getNonFixedColumnIds,
	getActualColumnSizes,
	getVisibleColumnIds,
	(columnIds, columnSizes, visibleColumns) => {
		const invisibleColsLeft = takeRightWhile(columnIds, (key) => !visibleColumns.includes(key));

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return sumBy(invisibleColsLeft, (key) => columnSizes[key] as unknown as number);
	},
);

export const getIsFramedDisplayMode = (state: State) => state.displayMode === 'framed';

export const getColumnDraggingInfo = (state: State) => state.columnDraggingInfo;

export const getHighlightedRow = (state: State): RowId | undefined => state.highlightedRow;
