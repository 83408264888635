import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-view-empty-state.views.publish-view.header',
		defaultMessage: 'The published view configuration needs to be changed',
		description: 'Header of the misconfigured published view',
	},
	descriptionForCreators: {
		id: 'polaris-lib-view-empty-state.views.publish-view.description-for-creators',
		defaultMessage:
			'You are using unsupported fields to configure this view. <a>Learn about unsupported fields</a>',
		description: 'Description of the misconfigured published view',
	},
	descriptionForStakeholders: {
		id: 'polaris-lib-view-empty-state.views.publish-view.description-for-stakeholders',
		defaultMessage:
			'Contact the person who sent you the link to {br} change the view configuration and publish it again.',
		description: 'Description of the misconfigured published view',
	},
});
