export const COLUMN_CLASS_NAME = 'view-board-column';

export const BOARD_COLUMN_WIDTH = 348;
export const BOARD_COLUMN_PADDING = 10;
export const BOARD_COLUMN_GAP = 8;
export const BOARD_COLUMN_BORDER_WIDTH = 2;
export const BOARD_CARD_GAP = 8;

export const BOARD_COLUMN_MIN_HEIGHT = 100;
export const BOARD_COLUMN_TITLE_HEIGHT = 50;
export const BOARD_COLUMN_CONTENT_TOP_PADDING = 5;
export const BOARD_CARD_HORIZONTAL_MARGIN = 1;
