import { useCallback, useEffect } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import type {
	AttachmentId,
	AttachmentsQueryOptions,
} from '@atlassian/jira-issue-attachments-store/src/common/types';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../messages';
import {
	useAttachmentStoreIsFetching,
	useAttachmentStoreError,
	useAttachmentStoreOrderDirection,
	useAttachmentStoreOrderField,
	useAttachmentStoreTotalCount,
	useAttachmentStoreDeletableCount,
	useAttachmentStoreStartAt,
	useAttachmentStoreVisibleAttachments,
	useAttachmentStoreActions,
} from './context';

export const useIssueAttachmentsActions = () => {
	const [, actions] = useAttachmentStoreActions();

	const { showFlag } = useFlagsService();

	const {
		setOrderDirection,
		setOrderField,
		setTotalCount,
		setDeletableCount,
		setVisibleAttachments,
		addNewAttachments,
		deleteAttachment: storeDeleteAttachment,
		getAttachments,
		getAttachmentsCount,
		refreshAttachments,
	} = actions;

	const deleteAttachment = useCallback(
		async (
			issueAttachmentKey: IssueKey,
			deletingAttachmentId: AttachmentId,
			fetchOptions?: AttachmentsQueryOptions,
		) => {
			try {
				await storeDeleteAttachment(issueAttachmentKey, deletingAttachmentId, fetchOptions);
			} catch {
				showFlag({
					type: 'error',
					title: messages.deleteFailedTitle,
					description: messages.deleteFailedDescription,
				});
			}
		},
		[storeDeleteAttachment, showFlag],
	);

	return [
		null,
		{
			setOrderDirection,
			setOrderField,
			setTotalCount,
			setDeletableCount,
			setVisibleAttachments,
			addNewAttachments,
			getAttachments,
			getAttachmentsCount,
			deleteAttachment,
			refreshAttachments,
		},
	] as const;
};

export const useIssueAttachments = (issueKey: IssueKey, options?: AttachmentsQueryOptions) => {
	const [, actions] = useIssueAttachmentsActions();
	const [isFetching] = useAttachmentStoreIsFetching(issueKey);
	const [error] = useAttachmentStoreError(issueKey);
	const [orderDirection] = useAttachmentStoreOrderDirection();
	const [orderField] = useAttachmentStoreOrderField();
	const [startAt] = useAttachmentStoreStartAt();
	const [totalCount] = useAttachmentStoreTotalCount(issueKey);
	const [deletableCount] = useAttachmentStoreDeletableCount(issueKey);
	const [visibleAttachments] = useAttachmentStoreVisibleAttachments(issueKey);

	const { getAttachments } = actions;

	useEffect(() => {
		if (totalCount === undefined && !isFetching && !error) {
			getAttachments(issueKey, options);
		}
	}, [error, getAttachments, issueKey, isFetching, options, totalCount]);

	const meta = {
		orderDirection,
		orderField,
		startAt,
	};

	const value = {
		attachments: visibleAttachments,
		totalCount: totalCount || 0,
		deletableCount: deletableCount || 0,
	};

	return [
		{
			error,
			meta,
			value,
			loading: isFetching,
		},
		actions,
	] as const;
};
