import { isValid, parseISO, format, isMatch } from 'date-fns';

type DatePickerPublicValue = string | null;
type DatePickerInternalGetValue = string | undefined;
type DatePickerInternalSetValue = string;

// Date time picker accepts only date string in format YYYY-MM-DDThh:mm:ssTZD

const formatString = "yyyy-MM-dd'T'HH:mmxxx";

export const checkDateHasExpectedFormat = (fullDate: unknown): boolean => {
	if (typeof fullDate !== 'string') return false;

	return isMatch(fullDate, formatString) && isValid(parseISO(fullDate));
};

export const validateDateTimePickerSetValue = (value: unknown): value is DatePickerPublicValue =>
	value === null || checkDateHasExpectedFormat(value);

export const transformDateTimePickerSetValue = (
	publicValue: DatePickerPublicValue,
): DatePickerInternalSetValue => (publicValue === null ? '' : publicValue);

export const transformDateTimePickerGetValue = (
	internalValue: DatePickerInternalGetValue,
): DatePickerPublicValue => {
	if (!internalValue) {
		return null;
	}

	const parsedDate = parseISO(internalValue);

	if (isValid(parsedDate)) {
		return format(parsedDate, formatString);
	}

	throw new Error(`Invalid date was provided: ${internalValue}`);
};
