import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'polaris-ideas.right-sidebar.field.hide-confirmation-dialog.title',
		defaultMessage: 'Hide {field} field',
		description: 'Title of the Hide field confirmation dialog',
	},
	descriptionUnusedField: {
		id: 'polaris-ideas.right-sidebar.field.hide-confirmation-dialog.description-unused-field',
		defaultMessage: "This field isn't used in any view. Go ahead to hide it.",
		description: 'Modal content for when the field is not used in any view',
	},
	descriptionUsedField: {
		id: 'polaris-ideas.right-sidebar.field.hide-confirmation-dialog.description-used-field',
		defaultMessage:
			"{viewsCount, plural, =1 {<b># view</b> is} other {<b># views</b> are}} using this field and its values. Hiding it for the project will make it unavailable for filtering, sorting, grouping, and using in custom formulas. Additionally, it'll impact the setup of views in your project",
		description: 'Modal content for when the field is used in views',
	},
	hide: {
		id: 'polaris-ideas.right-sidebar.field.hide-confirmation-dialog.hide',
		defaultMessage: 'Hide',
		description: 'Label for the button that hides the system field',
	},
	cancel: {
		id: 'polaris-ideas.right-sidebar.field.hide-confirmation-dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the button that closes the dialog',
	},
	affectedList: {
		id: 'polaris-ideas.right-sidebar.field.hide-confirmation-dialog.affected-list',
		defaultMessage: 'Check the list with affected views',
		description:
			'Prompt for opening the panel that displays views configured by field that user wants to hide',
	},
});
