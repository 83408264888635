import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { log } from '@atlassian/jira-common-util-logging';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { getProjectIssueTypes } from '@atlassian/jira-issue-view-services/src/issue/change-issue-type-server';
import {
	projectIssueTypeSuccess,
	projectIssueTypeFailure,
	PROJECT_ISSUE_TYPES_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/change-issue-type-actions';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import type { ProjectContextServiceActions } from '@atlassian/jira-project-context-service';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (projectContextActions: ProjectContextServiceActions) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
		action$.ofType(PROJECT_ISSUE_TYPES_REQUEST).mergeMap((action) => {
			const baseUrl = baseUrlSelector(store.getState());
			const projectKey = action.payload;
			return getProjectIssueTypes(baseUrl, projectKey)
				.do((issueTypes) => projectContextActions.mergeProjectProperty(projectKey, { issueTypes }))
				.map((issueTypes) => projectIssueTypeSuccess(issueTypes))
				.catch((error) => {
					log.safeErrorWithoutCustomerData('change.issue.type.fetchtypes', error.message, error);
					return Observable.of(projectIssueTypeFailure(error));
				});
		});
