import isString from 'lodash/isString';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { UFOExperience } from '@atlassian/ufo';
import type {
	CloseExperienceFunction,
	ExperienceErrorReason,
	ExperienceMetadata,
	JPDExperience,
	MarkedJPDExperience,
	Marks,
} from './types';

export const createMarkedJpdExperience = <TMarks extends Marks>(
	ufoExperience: UFOExperience,
	marks: TMarks,
): MarkedJPDExperience<TMarks> => ({
	...createJpdExperience(ufoExperience),
	marks,
});

const handleReason = (handler: CloseExperienceFunction, error: ExperienceErrorReason) => {
	if (error === undefined) {
		handler();
		return;
	}

	if (typeof error === 'object' && 'statusCode' in error && 'message' in error) {
		handler({
			metadata: {
				error: `status: ${error.statusCode} message: ${error.message} name: ${error.name}`,
				errors:
					'errors' in error && Array.isArray(error.errors)
						? error.errors
								.map((err) => {
									if (typeof err === 'string') {
										return err;
									}

									if (typeof err === 'object' && err !== null && 'error' in err) {
										return err.error;
									}

									return '';
								})
								.join(', ')
						: undefined,
			},
		});
		return;
	}

	if (error instanceof Error) {
		handler({
			metadata: {
				error: error.message,
			},
		});
		return;
	}

	if (isString(error)) {
		handler({
			metadata: {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: error as string,
			},
		});
		return;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	handler(error as ExperienceMetadata);
};

export const createJpdExperience = (ufoExperience: UFOExperience): JPDExperience => ({
	ufoExperience,
	start: (startTime) => {
		ufoExperience.start();
		// hack to set custom start time
		if (startTime !== undefined) {
			Object.assign(ufoExperience.metrics, {
				...ufoExperience.metrics,
				startTime,
			});
		}
	},
	mark: (mark: string) => {
		ufoExperience.mark(mark);
	},
	success: (meta, endTime) => {
		ufoExperience.success(meta);
		// hack to set custom end time
		if (endTime !== undefined) {
			Object.assign(ufoExperience.metrics, {
				...ufoExperience.metrics,
				endTime,
			});
		}
	},
	successWithReason: (reason: ExperienceErrorReason) => {
		handleReason((meta) => ufoExperience.success(meta), reason);
	},
	failure: (reason: ExperienceErrorReason) => {
		handleReason((meta) => ufoExperience.failure(meta), reason);
	},
	abort: (reason) => {
		handleReason((meta) => ufoExperience.abort(meta), reason);
	},
});

export const wrapPromiseWithExperience = <R,>(
	promise: Promise<R>,
	experience: JPDExperience,
	errorAnalyticsId?: string,
): Promise<R> => {
	experience.start();
	return promise
		.then((response) => {
			experience.success();
			return response;
		})
		.catch((error) => {
			if (errorAnalyticsId) {
				fireErrorAnalytics({
					meta: {
						id: errorAnalyticsId,
						teamName: 'polaris',
					},
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: error as Error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}

			if (isClientFetchError(error)) {
				experience.abort(error);
				throw error;
			}

			if (isValidationError(error)) {
				experience.abort(error);
				throw error;
			}

			experience.failure(error);
			throw error;
		});
};

export const wrapWithExperience = <R,>(
	experience: JPDExperience,
	callback: () => Promise<R>,
): Promise<R> => {
	experience.start();
	return callback()
		.then((result) => {
			experience.success();
			return result;
		})
		.catch((error) => {
			if (isClientFetchError(error)) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				experience.abort(error as Error);
				throw error;
			}

			if (error instanceof Error) {
				experience.failure(error);
			}
			throw error;
		});
};
