import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	tooltipHeader: {
		id: 'polaris-common.common.edit-global-field.permissions-icon.tooltip-header',
		defaultMessage: "You can't edit this field",
		description: 'Permissions tooltip header',
	},
	tooltipText: {
		id: 'polaris-common.common.edit-global-field.permissions-icon.tooltip-text',
		defaultMessage: 'You must be a Jira admin to manage <icon></icon> global fields.',
		description: 'Permissions tooltip text',
	},
	globalFieldIconLabel: {
		id: 'polaris-common.common.edit-global-field.permissions-icon.global-field-icon-label',
		defaultMessage: 'Global field icon',
		description: 'Global Field icon label',
	},
	lockIconLabel: {
		id: 'polaris-common.common.edit-global-field.permissions-icon.lock-icon-label',
		defaultMessage: 'Lock icon',
		description: 'Lock icon icon label',
	},
	infoIconLabel: {
		id: 'polaris-common.common.edit-global-field.permissions-icon.info-icon-label',
		defaultMessage: 'Information icon',
		description: 'Information icon label',
	},
});
