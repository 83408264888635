import { useCallback, useMemo, useState, useEffect } from 'react';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import {
	addMonths,
	addQuarters,
	endOfMonth,
	endOfQuarter,
	format,
	isBefore,
	setDate,
	startOfMonth,
	startOfQuarter,
} from 'date-fns';
import { formatIsoLocalDate } from '@atlassian/jira-polaris-common/src/common/utils/date/date-format';
import { getMiddleOfTheMonthDate } from '@atlassian/jira-polaris-common/src/common/utils/timeline';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import {
	useJiraIdToLocalIssueId,
	useLocalIssueIdToJiraIssueId,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import {
	useMultiSelect,
	usePeople,
	useStringListValue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { useIsSorted } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks';
import {
	useTimelineDateFieldsKeys,
	useTimelineDuration,
	useTimelineItemIds,
	useTimelineItems,
} from '@atlassian/jira-polaris-common/src/controllers/issue/utils/view-filtering/view-timeline';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewArrangementInformation,
	useCurrentViewTimelineMode,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	PolarisTimelineMode,
	type GroupValueIdToIdArrangement,
} from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { Header as TimelineHeader } from '@atlassian/jira-polaris-lib-timeline/src/common/types/timeline/index.tsx';
import {
	getTimelinePrimaryGridInterval,
	getTimelineSecondaryGridInterval,
} from '@atlassian/jira-polaris-lib-timeline/src/common/utils.tsx';
import type {
	ChangedItem,
	GroupedItemArrangement,
	GroupId,
	ItemRows,
} from '@atlassian/jira-polaris-lib-timeline/src/types.tsx';
import type { ExtendedOption } from '../../../../common/utils/board';
import { useBucketIssueIds } from '../../../right-sidebar/ideas-bucket';
import { useGroupDropHandler, useRowGrouping } from '../../common/utils/group-options';

export const getHeaderLabel = (date: Date, timelineMode: PolarisTimelineMode) => {
	if (timelineMode === 'MONTHS') {
		return format(date, 'MMMM yyyy');
	}
	return `${format(startOfQuarter(date), 'MMMM')}-${format(endOfQuarter(date), 'MMMM yyyy')}`;
};

export const useHeaders = () => {
	const { startDate: initialStartDate, endDate } = useTimelineDuration();
	const mode = useCurrentViewTimelineMode();

	return useMemo(() => {
		if (!initialStartDate || !endDate || !mode) {
			return [];
		}

		let startDate = initialStartDate;
		const result: TimelineHeader[] = [];

		while (isBefore(startDate, endDate)) {
			const header = getHeaderLabel(startDate, mode);
			const subheaders = [];
			if (mode === PolarisTimelineMode.QUARTERS) {
				let startDateOfQuarter = startOfQuarter(startDate);
				while (isBefore(startDateOfQuarter, endOfQuarter(startDate))) {
					subheaders.push(format(startDateOfQuarter, 'MMMM'));
					startDateOfQuarter = addMonths(startDateOfQuarter, 1);
				}
			}
			result.push({ header, subheaders });
			startDate =
				mode === PolarisTimelineMode.MONTHS ? addMonths(startDate, 1) : addQuarters(startDate, 1);
		}

		return result;
	}, [endDate, initialStartDate, mode]);
};

export const getNewGroupValue = (
	groupIds: GroupId[],
	extendedOptions: ExtendedOption<unknown>[],
): unknown[] | unknown | undefined => {
	const optionsByGroupId = keyBy(extendedOptions, ({ groupIdentity }) => String(groupIdentity));
	const values = groupIds.map((groupId) => optionsByGroupId[groupId].value);
	if (values.length === 0) {
		return undefined;
	}
	return Array.isArray(values[0]) ? flatten(values) : values[0];
};

const middleOfMonthEnd = (date: Date) => setDate(date, getMiddleOfTheMonthDate(date)[1]);
const middleOfMonthStart = (date: Date) => setDate(date, getMiddleOfTheMonthDate(date)[0]);

const transformToIntervalModel = (startDateValue: Date, endDateValue: Date) => ({
	start: formatIsoLocalDate(startDateValue),
	end: formatIsoLocalDate(endDateValue),
});

export const useUpdateItem = () => {
	const [item, setUpdatedItem] = useState<ChangedItem | null>(null);
	const mode = useCurrentViewTimelineMode();
	const timelineStartDate = useTimelineDuration().startDate;
	const [verticalGroupByField, extendedOptions] = useRowGrouping();
	const { updateFieldValues } = useIssueActions();
	const [startDateFieldKey, endDateFieldKey] = useTimelineDateFieldsKeys();
	const allItems = useTimelineItems();
	const bucketIssueIds = useBucketIssueIds('');

	const groupByFieldKey = verticalGroupByField?.key ?? '';
	const itemId = item?.id ?? '';
	const people = usePeople(groupByFieldKey, itemId);
	const multiSelectOptions = useMultiSelect(groupByFieldKey, itemId);
	const labels = useStringListValue(groupByFieldKey, itemId);

	useEffect(() => {
		if (!item) {
			return;
		}

		const isCurrentItemFromBucket = bucketIssueIds.includes(item.id);
		const currentItem = allItems.find((issue) => issue.id === item.id);

		if (!timelineStartDate || !startDateFieldKey || !endDateFieldKey) {
			return;
		}

		const primaryGridInterval = getTimelinePrimaryGridInterval(mode);
		const secondaryGridInterval = getTimelineSecondaryGridInterval(mode);

		// start is divided by 2 because in timeline 2 cells = 1 month
		// Math.floor is used to round down for the case when item.start is odd number
		const newStartDate = addMonths(timelineStartDate, Math.floor(item.start / 2));

		let startDateStartIntervalFn = middleOfMonthStart;
		let startDateIntervalEndFn = middleOfMonthStart;
		let endDateStartIntervalFn = middleOfMonthEnd;
		let endDateEndIntervalFn = middleOfMonthEnd;
		let startsInTheMiddleOfMonth = false;
		let endsInTheMiddleOfMonth = false;

		// starts on the edge of primary granularity
		if (item.start % primaryGridInterval === 0) {
			startDateStartIntervalFn =
				mode === PolarisTimelineMode.MONTHS ? startOfMonth : startOfQuarter;
			startDateIntervalEndFn = mode === PolarisTimelineMode.MONTHS ? endOfMonth : endOfQuarter;
		} else if (
			// starts on the edge of secondary granularity
			item.start % secondaryGridInterval === 0 &&
			mode === PolarisTimelineMode.QUARTERS
		) {
			startDateStartIntervalFn = startOfMonth;
			startDateIntervalEndFn = endOfMonth;
		} else {
			startsInTheMiddleOfMonth = true;
		}

		const startDateIntervalStart = startDateStartIntervalFn(newStartDate);
		const startDateIntervalEnd = startDateIntervalEndFn(newStartDate);

		// ends on the edge of primary granularity
		if ((item.size + item.start) % primaryGridInterval === 0) {
			endDateStartIntervalFn = mode === PolarisTimelineMode.MONTHS ? startOfMonth : startOfQuarter;
			endDateEndIntervalFn = mode === PolarisTimelineMode.MONTHS ? endOfMonth : endOfQuarter;
		} else if (
			// ends on the edge of secondary granularity
			(item.size + item.start) % secondaryGridInterval === 0 &&
			mode === PolarisTimelineMode.QUARTERS
		) {
			endDateStartIntervalFn = startOfMonth;
			endDateEndIntervalFn = endOfMonth;
		} else {
			endsInTheMiddleOfMonth = true;
		}

		// Size is divided by 2 because in timeline 2 cells = 1 month
		// In order to not include the following month:
		// - for full months we need to subtract 1
		// - for mid-month we don't need to subtract because item.size / 2 will be fractional (e.g. 1.5) and will be rounded down inside addMonths
		const newEndDate = addMonths(
			startDateIntervalStart,
			item.size / 2 - (startsInTheMiddleOfMonth || endsInTheMiddleOfMonth ? 0 : 1),
		);

		const endDateIntervalStart = endDateStartIntervalFn(newEndDate);
		const endDateIntervalEnd = endDateEndIntervalFn(newEndDate);

		const newStartFieldValue = JSON.stringify(
			transformToIntervalModel(startDateIntervalStart, startDateIntervalEnd),
		);
		const newEndFieldValue = JSON.stringify(
			transformToIntervalModel(endDateIntervalStart, endDateIntervalEnd),
		);

		let fields = {};

		const isSizeChanged = currentItem && item.size !== currentItem.size;
		const isStartDateChanged = currentItem && item.start !== currentItem.start;

		if (!currentItem || (isStartDateChanged && !isSizeChanged)) {
			fields = {
				[startDateFieldKey]: {
					newValue: newStartFieldValue,
				},
				[endDateFieldKey]: {
					newValue: newEndFieldValue,
				},
			};
		} else if (isStartDateChanged && isSizeChanged) {
			fields = {
				[startDateFieldKey]: {
					newValue: newStartFieldValue,
				},
			};
		} else if (!isStartDateChanged && isSizeChanged) {
			fields = {
				[endDateFieldKey]: {
					newValue: newEndFieldValue,
				},
			};
		}

		if (verticalGroupByField !== undefined && item.newGroupIds && verticalGroupByField.editable) {
			let newValue = getNewGroupValue(item.newGroupIds, extendedOptions || []);

			// https://pi-dev-sandbox.atlassian.net/browse/POL-8235
			if (isCurrentItemFromBucket && Array.isArray(newValue)) {
				const { type: fieldType } = verticalGroupByField;

				if ((fieldType === FIELD_TYPES.PEOPLE || fieldType === FIELD_TYPES.JSW_PEOPLE) && people) {
					newValue = uniqBy([...people, ...newValue], 'accountId');
				} else if (
					(fieldType === FIELD_TYPES.MULTI_SELECT || fieldType === FIELD_TYPES.JSW_MULTI_SELECT) &&
					multiSelectOptions
				) {
					newValue = uniqBy([...multiSelectOptions, ...newValue], 'id');
				} else if (
					(fieldType === FIELD_TYPES.LABELS || fieldType === FIELD_TYPES.CUSTOM_LABELS) &&
					labels
				) {
					newValue = uniq([...labels, ...newValue]);
				}
			}

			fields = {
				...fields,
				[verticalGroupByField.key]: {
					newValue,
				},
			};
		}

		updateFieldValues({
			localIssueIds: [item.id],
			fields,
		});

		setUpdatedItem(null);
	}, [
		labels,
		people,
		multiSelectOptions,
		item,
		bucketIssueIds,
		allItems,
		timelineStartDate,
		startDateFieldKey,
		endDateFieldKey,
		mode,
		verticalGroupByField,
		updateFieldValues,
		extendedOptions,
	]);

	return setUpdatedItem;
};

export const useLocalArrangementInformation = ():
	| Record<string, GroupedItemArrangement>
	| undefined => {
	const isSorted = useIsSorted();
	const remoteArrangement = useCurrentViewArrangementInformation();
	const jiraIdToLocalIssueId = useJiraIdToLocalIssueId();

	if (isSorted || remoteArrangement === undefined) {
		return undefined;
	}
	return mapValues(remoteArrangement, (itemArrangement: GroupedItemArrangement) =>
		mapValues(itemArrangement, (issuesJiraId) =>
			mapKeys(issuesJiraId, (_, jiraId) => jiraIdToLocalIssueId[Number.parseInt(jiraId, 10)]),
		),
	);
};

export const useOnArrangementUpdate = () => {
	const localIssueIdToJiraId = useLocalIssueIdToJiraIssueId();
	const { updateArrangementInformation } = useViewActions();
	return useCallback(
		(newLocalGroupArrangement: GroupedItemArrangement) => {
			const newRemoteGroupArrangement: GroupValueIdToIdArrangement = mapValues(
				newLocalGroupArrangement,
				(itemRows: ItemRows) => mapKeys(itemRows, (_, itemId) => localIssueIdToJiraId[itemId]),
			);
			updateArrangementInformation(newRemoteGroupArrangement);
		},
		[localIssueIdToJiraId, updateArrangementInformation],
	);
};

export const useOnGroupOrderChanged = () => {
	const groupDropHandler = useGroupDropHandler();
	const onGroupOrderChanged = useCallback(
		({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
			groupDropHandler({ oldIndex, newIndex });
		},
		[groupDropHandler],
	);
	return onGroupOrderChanged;
};

export const useTimelineIdeaCount = () => {
	const itemIds = useTimelineItemIds();

	return itemIds.length;
};
