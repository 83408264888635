import React, { type ReactNode, useCallback, useState } from 'react';
import Button from '@atlaskit/button';
import FeedbackModal from '@atlassian/jira-polaris-lib-feedback/src/ui/modal/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';

type ContactSupportLinkProps = {
	children: ReactNode | ReactNode[];
};

export const ContactSupportLink = (props: ContactSupportLinkProps) => {
	const { children } = props;
	const { success, error } = useNotifications();
	const [isOpen, setIsOpen] = useState(false);

	const onOpenDialog = useCallback(() => {
		setIsOpen(true);
	}, [setIsOpen]);

	const onCloseDialog = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	return (
		<>
			<Button onClick={onOpenDialog} appearance="link" spacing="none">
				{children}
			</Button>
			{isOpen && (
				<FeedbackModal
					onCancel={onCloseDialog}
					defaultCategoryName="help"
					onSuccess={success}
					onError={error}
				/>
			)}
		</>
	);
};
