import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common';
import { MULTI_SELECT } from '../constants';
import type { JiraFieldMapping } from '../types';

export const jiraOptionsMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<OptionFieldValue[]> => {
	const optionsById = keyBy(field.options, ({ jiraOptionId }) => jiraOptionId);

	return {
		field,
		getValueFromJiraIssue: (issue) =>
			issue.fields[field.key]
				? issue.fields[field.key]
						.map(({ id }: OptionFieldValue) => ({ id }))
						.filter(({ id }: OptionFieldValue) => has(optionsById, id))
				: undefined,
		getFieldValueForJiraUpdate: (value) => {
			if (value === undefined) {
				return null;
			}

			return value.map((item) => ({
				...item,
				value: optionsById[item.id]?.value,
			}));
		},
		getFieldValueForJiraBulkUpdate: (value) => {
			const options: Record<string, string>[] = [];
			value.forEach((option) => {
				const optionId = optionsById[option.id].jiraOptionId;
				if (optionId) {
					options.push({
						optionId,
					});
				}
			});
			return {
				[MULTI_SELECT]: {
					fieldId: field.key,
					options,
				},
			};
		},
		isMultiValueField: true,
		isSupportedByIssueUpdateApi: true,
		...defaultJiraUpdateService(issuesRemote, field.key),
	};
};
