import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	modalTitle: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.benefits-modal.modal-title',
		defaultMessage: 'All your project fields in one place',
		description: 'Title for the project fields benefits modal',
	},
	modalBody: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.benefits-modal.modal-body',
		defaultMessage:
			'Manage all project fields in one place and add global fields for your discovery project.',
		description: 'Body for the project fields benefits modal',
	},
	modalDismissButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.benefits-modal.modal-dismiss-button',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button for the project fields benefits modal',
	},
	modalStartTourButton: {
		id: 'polaris-lib-onboarding-flows.project-fields.tour.benefits-modal.modal-start-tour-button',
		defaultMessage: 'Give me a feature tour',
		description: 'Start tour button for the project fields benefits modal',
	},
});
