import isEqual from 'lodash/isEqual';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { isPageVisible, supportedVisiblityEvent } from '@atlassian/jira-common-page-visibility';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import {
	INACTIVE_TAB_INTERVAL,
	TIME_WINDOW_1M,
	TIME_WINDOW_5M,
	QUEUE_HANDLER_LIMIT,
	QUEUE_INTERVAL,
	MIN_QUOTA_CHECK_INACTIVE_TIME,
	REALTIME_EVENTS_QUEUE_INACTIVE_QUOTA,
} from '../../constants';
import type { Config, HandlerRef, QueueHandler, Event } from '../../types';
import { createQueueAnalytics } from '../analytics';
import { eventPayloadToCompareDuplicates } from './utils';

const getRealtimeEventsQueueLimit = (): number =>
	getFeatureFlagValue<number>('polaris.realtime-events-queue-limit', 0);

const getRealtimeEventsQueueJitter = (): number =>
	getFeatureFlagValue<number>('polaris.realtime-events-queue-jitter', 0);

const getRealtimeEventsQueueJitterForBatch = (): number =>
	getFeatureFlagValue<number>('polaris.realtime-events-queue-jitter-for-batch', 0);

/**
 * Events counter for realtime events in the last period of time
 * Stores the number of events per interval
 *
 * @param periodSize period size in ms
 * @param intervalSize interval size in ms
 */
const EventsCounter = (periodSize = 60000, intervalSize = 5000) => {
	const counter = new Map();

	const getIntervalKey = (timestamp: number) => Math.floor(timestamp / intervalSize) * intervalSize;

	return {
		updateEventsCount(eventType: string, timestamp: number, count = 1) {
			const intervalKey = getIntervalKey(timestamp);

			// Get or create the events count Map for the given eventType
			const typeCounts = counter.get(eventType) ?? new Map();
			typeCounts.set(intervalKey, (typeCounts.get(intervalKey) ?? 0) + count);
			counter.set(eventType, typeCounts);

			// Purge old events for all event types
			const cutoffTimestamp = timestamp - periodSize;
			counter.forEach((typeCountsMap, _) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				typeCountsMap.forEach((__: any, key: number) => {
					if (key < cutoffTimestamp) {
						typeCountsMap.delete(key);
					}
				});
			});
		},
		getEventsCount(eventType: string) {
			let totalCount = 0;
			const cutoffTimestamp = Date.now() - periodSize;

			const typeCounts = counter.get(eventType);
			if (typeCounts) {
				for (const [key, count] of typeCounts.entries()) {
					if (key >= cutoffTimestamp) {
						totalCount += count;
					}
				}
			}

			return totalCount;
		},
	};
};

/**
 * Rate limits for realtime events by event type
 */
const RateLimits = () => {
	const lastEventTimestamp = new Map<string, number>();
	const eventsCounter1m = EventsCounter(TIME_WINDOW_1M);
	const eventsCounter5m = EventsCounter(TIME_WINDOW_5M);

	function calculateDelay(eventType: string, initialDelay: number) {
		const delayIncreaseFactor = (1 + Math.sqrt(5)) / 2;

		const events1m = eventsCounter1m.getEventsCount(eventType);
		// exponential increase factor based on the number of events in the last minute
		const delayGrowthRate = Math.pow(delayIncreaseFactor, events1m);

		// Bound delay within a reasonable range
		const minDelay = 0; // 0ms
		const maxDelay = 15 * 1000; // 15s
		const delay = Math.floor(initialDelay * delayGrowthRate);

		return Math.max(minDelay, Math.min(delay, maxDelay));
	}

	function hasDelayLimitExceeded(eventType: string, initialDelay: number) {
		if (initialDelay === 0) {
			return false;
		}

		const headEventTimestamp = lastEventTimestamp.get(eventType);
		// if there are no events in the last minute, there is no delay
		if (!headEventTimestamp) {
			return false;
		}

		const currentDelay = calculateDelay(eventType, initialDelay);
		if (Date.now() - headEventTimestamp <= currentDelay) {
			return true;
		}

		return false;
	}

	function hasRateLimitBeenExceeded1m(eventType: string, eventsQueueLimit: number) {
		return eventsCounter1m.getEventsCount(eventType) >= eventsQueueLimit;
	}

	function hasRateLimitBeenExceeded5m(eventType: string, eventsQueueLimit: number) {
		return eventsCounter5m.getEventsCount(eventType) >= eventsQueueLimit * 2;
	}

	function update(eventType: string) {
		const currentTime = Date.now();
		lastEventTimestamp.set(eventType, currentTime);

		eventsCounter1m.updateEventsCount(eventType, currentTime);
		eventsCounter5m.updateEventsCount(eventType, currentTime);
	}

	return {
		hasDelayLimitExceeded,
		hasRateLimitBeenExceeded1m,
		hasRateLimitBeenExceeded5m,
		update,
	};
};

export const createQueue = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onQueueQuotasExceeded?: (eventsInQueue: number) => void,
) => {
	let queueConfig: Config = {
		bundleEvents: {},
		batchSizes: {},
	};

	const queueAnalytics = createQueueAnalytics(createAnalyticsEvent);

	const reatimeQueue = new Map<HandlerRef, QueueHandler>();
	const rateLimits = RateLimits();

	let isWindowFocused = isPageVisible();
	let windowLastFocusedTime = 0;
	let quotasExceeded = false;

	const onVisibilityChange = () => {
		const prevIsWindowFocused = isWindowFocused;
		isWindowFocused = isPageVisible();
		const hasMovedFocusAway = !isWindowFocused && prevIsWindowFocused;

		// run the code in the next tick because addEvent is called after onVisibilityChange
		setTimeout(() => {
			if (hasMovedFocusAway) {
				windowLastFocusedTime = Date.now();
			} else {
				const timeElapsedSinceLastWindowFocus = Date.now() - windowLastFocusedTime;
				const isQueueFull = getQueue().length > REALTIME_EVENTS_QUEUE_INACTIVE_QUOTA;

				if (timeElapsedSinceLastWindowFocus > MIN_QUOTA_CHECK_INACTIVE_TIME && isQueueFull) {
					quotasExceeded = true;
					onQueueQuotasExceeded?.(getQueue().length);
				}
			}
		});
	};

	const getLimit = () => {
		// if tab is inactive for more than 2 minutes, limit is 1
		if (!isWindowFocused && windowLastFocusedTime + INACTIVE_TAB_INTERVAL < Date.now()) {
			return 1;
		}
		return getRealtimeEventsQueueLimit();
	};

	// Collects all events from all handlers
	const getQueue = () =>
		[...reatimeQueue.keys()].reduce((acc, handler) => {
			const handlerQueue = reatimeQueue.get(handler);
			if (!handlerQueue) {
				return acc;
			}
			const handlerEvents = Object.keys(handlerQueue).reduce(
				(handlerEventsAcc, eventType) => handlerEventsAcc.concat(handlerQueue[eventType].events),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[] as Event[],
			);
			return acc.concat(handlerEvents);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		}, [] as Event[]);

	const isBatchedEvent = (eventType: string) => (queueConfig.batchSizes?.[eventType] ?? 0) > 1;

	const isRateLimitExemptEvent = (eventType: string) =>
		queueConfig.batchSizes?.[eventType] === Number.MAX_VALUE;

	const checkIfRateLimitExceeded = (eventType: string) => {
		if (isRateLimitExemptEvent(eventType)) {
			return false;
		}
		if (
			isBatchedEvent(eventType) &&
			rateLimits.hasDelayLimitExceeded(eventType, getRealtimeEventsQueueJitterForBatch())
		) {
			return true;
		}
		if (rateLimits.hasDelayLimitExceeded(eventType, getRealtimeEventsQueueJitter())) {
			return true;
		}
		if (rateLimits.hasRateLimitBeenExceeded1m(eventType, getLimit())) {
			return true;
		}
		if (rateLimits.hasRateLimitBeenExceeded5m(eventType, getLimit())) {
			return true;
		}
		return false;
	};

	return {
		start: () => {
			document.addEventListener(supportedVisiblityEvent, onVisibilityChange);

			const resetAnalytics = queueAnalytics.setup(isWindowFocused);

			const queueInterval = setInterval(() => {
				if (quotasExceeded) {
					cleanupListeners();
					return;
				}

				const eventsInQueue = getQueue();
				queueAnalytics.update(eventsInQueue);

				if (!reatimeQueue.size) {
					return;
				}

				for (const [handler, handlerQueue] of reatimeQueue.entries()) {
					for (const eventType of Object.keys(handlerQueue)) {
						if (checkIfRateLimitExceeded(eventType)) {
							return;
						}

						const { events } = handlerQueue[eventType];
						// remove empty event type queue and skip if no events
						if (!events.length) {
							delete handlerQueue[eventType];
							return;
						}

						const batchSize = queueConfig.batchSizes?.[eventType] ?? 1;
						const eventsToProcess = events.splice(0, batchSize);

						// process events with handler
						handler.current?.({
							type: eventType,
							payload: isBatchedEvent(eventType) ? eventsToProcess : eventsToProcess[0].payload,
						});

						// remove empty event type queue
						if (!events.length) {
							delete handlerQueue[eventType];
						}

						rateLimits.update(eventType);
					}
				}
			}, QUEUE_INTERVAL);

			const cleanupListeners = () => {
				document.removeEventListener(supportedVisiblityEvent, onVisibilityChange);
				resetAnalytics();
				clearInterval(queueInterval);
			};

			// Cleanup intervals and listeners
			return cleanupListeners;
		},
		addEvent: (ref: HandlerRef, event: Event) => {
			const eventsInQueue = getQueue();
			queueAnalytics.addEvent(event, eventsInQueue);

			const eventType = queueConfig.bundleEvents[event.type]?.bundledEventName ?? event.type;

			// Init queue for handler
			if (!reatimeQueue.has(ref)) {
				reatimeQueue.set(ref, { [eventType]: { time: Date.now(), events: [] } });
			} else if (!reatimeQueue.get(ref)?.[eventType]) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				reatimeQueue.get(ref)![eventType] = { time: Date.now(), events: [] };
			}

			if ((reatimeQueue.get(ref)?.[eventType]?.events.length || 0) > QUEUE_HANDLER_LIMIT) {
				quotasExceeded = true;
				onQueueQuotasExceeded?.(eventsInQueue.length);
				// overloaded, drop event
				return;
			}

			const events = reatimeQueue.get(ref)?.[eventType]?.events;
			// Check if event is already in queue to avoid duplicates if not add event to queue
			if (events) {
				const eventIndex = events.findIndex((e) =>
					isEqual(eventPayloadToCompareDuplicates(e), eventPayloadToCompareDuplicates(event)),
				);
				if (eventIndex === -1) {
					events.push(event);
				} else {
					// replace with new event
					events.splice(eventIndex, 1, event);
				}
			}
		},
		clearEvents: (ref: HandlerRef) => {
			reatimeQueue.delete(ref);
		},
		setConfig: (config: Config) => {
			queueConfig = config;
		},
		// when all listeners are removed, the queue is empty
		isEmpty: () => reatimeQueue.size === 0,
	};
};
