import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	viaApp: {
		id: 'polaris-ideas.insights.insights.insight.header.via-app',
		defaultMessage: 'Via {appName}',
		description: 'Via some app name',
	},
	deletedApp: {
		id: 'polaris-ideas.insights.insights.insight.header.deleted-app',
		defaultMessage: 'Deleted app',
		description: 'Deleted app',
	},
	deleteInsight: {
		id: 'polaris-ideas.insights.insights.insight.header.delete-insight',
		defaultMessage: 'Delete insight',
		description: 'Delete insight',
	},
	editTooltip: {
		id: 'polaris-ideas.insights.insights.insight.header.edit-tooltip',
		defaultMessage: 'Edit',
		description: 'Edit tooltip',
	},
	deleteTooltip: {
		id: 'polaris-ideas.insights.insights.insight.header.delete-tooltip',
		defaultMessage: 'Delete',
		description: 'Delete tooltip',
	},
	copyTooltip: {
		id: 'polaris-ideas.insights.insights.insight.header.copy-tooltip',
		defaultMessage: 'Copy link to insight',
		description: 'Copy tooltip',
	},
	deleteInsightWarningHeader: {
		id: 'polaris-ideas.insights.insights.insight.header.delete-insight-warning-header',
		defaultMessage: 'Delete this insight?',
		description: 'Header for delete comment confirmation dialog',
	},
});
