import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { notImplementedError } from '../../common/utils';
import {
	copyValuesToGlobalField,
	getCopyValuesProgress,
} from '../../services/jira/copy-value-to-global-field';
import type { GlobalFieldCopyValuesRemote } from './types';

export const createNotImplementedJiraGlobalFieldCopyValuesRemote =
	(): GlobalFieldCopyValuesRemote => ({
		copyValuesToGlobalField: notImplementedError('copyValuesToGlobalField'),
		getCopyValuesProgress: notImplementedError('getCopyValuesProgress'),
	});

export const createJiraGlobalFieldCopyValuesRemote = (): GlobalFieldCopyValuesRemote => ({
	copyValuesToGlobalField: (
		fromProjectFieldKey: FieldKey,
		toGlobalFieldKey: FieldKey,
		projectId: string,
		fieldOptionsValueMapping?: Record<string, string | undefined>,
	) =>
		copyValuesToGlobalField(
			fromProjectFieldKey,
			toGlobalFieldKey,
			projectId,
			fieldOptionsValueMapping,
		),
	getCopyValuesProgress: (taskId: string) => getCopyValuesProgress(taskId),
});
