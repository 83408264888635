import { useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import type { DataCallbackFn } from '../../../common/utils';

export const useAnimationData = ({ isMac }: { isMac: boolean }): DataCallbackFn => {
	const { colorMode } = useThemeObserver();
	const result = useMemo(() => {
		switch (true) {
			case colorMode === 'dark' && isMac:
				return () =>
					import(
						/* webpackChunkName: "compal-issue-action-spotlight-dark-mac" */ './assets/dark-mac-data'
					);
			case colorMode === 'light' && !isMac:
				return () =>
					import(
						/* webpackChunkName: "compal-issue-action-spotlight-light-win" */ './assets/light-win-data'
					);
			case colorMode === 'dark' && !isMac:
				return () =>
					import(
						/* webpackChunkName: "compal-issue-action-spotlight-dark-win" */ './assets/dark-win-data'
					);
			default:
			case colorMode === 'light' && isMac:
				return () =>
					import(
						/* webpackChunkName: "compal-issue-action-spotlight-light-mac" */ './assets/light-mac-data'
					);
		}
	}, [colorMode, isMac]);

	return result;
};
