import React from 'react';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import Dropdown from './dropdown';
import type { VotersProps } from './types';
import VoteToggle from './vote-toggle';

export const Voters = ({ disableVotingProps, onFieldUpdated }: VotersProps) => {
	const projectKey = useProjectKey();
	const [permissions] = useProjectPermissions(projectKey);
	const hasViewVotersPermissions = permissions.canViewVoters;

	if (hasViewVotersPermissions) {
		return <Dropdown disableVotingProps={disableVotingProps} onFieldUpdated={onFieldUpdated} />;
	}
	return <VoteToggle disableVotingProps={disableVotingProps} onFieldUpdated={onFieldUpdated} />;
};

export default Voters;
