import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-component-field-configuration.configuration.formula.dependency-info.notification.title',
		defaultMessage: "You've added new global fields",
		description:
			'Title for the flag explaining that the global fields have been added to a project',
	},
	description: {
		id: 'polaris-component-field-configuration.configuration.formula.dependency-info.notification.description',
		defaultMessage: 'Custom formula fields and their inputs have been added to projects.',
		description:
			'Explains to the user that the inputs of a custom formula field have been added alongside the field itself',
	},
});
