import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addNumberBasedWeights: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.add-number-based-weights',
		defaultMessage: 'Add number based weights',
		description:
			'Dropdown menu option text, can be clicked to add field option number based weight type',
	},
	addRatingBasedWeights: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.add-rating-based-weights',
		defaultMessage: 'Add rating based weights',
		description:
			'Dropdown menu option text, can be clicked to add field option rating based weight type',
	},
	changeToNumberBasedWeights: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.change-to-number-based-weights',
		defaultMessage: 'Change to number based weights',
		description:
			'Dropdown menu option text, can be clicked to change field option weight type to number based',
	},
	changeToRatingBasedWeights: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.change-to-rating-based-weights',
		defaultMessage: 'Change to rating based weights',
		description:
			'Dropdown menu option text, can be clicked to change field option weight type to rating based',
	},
	addWeightsTooltipText: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.add-weights-tooltip-text',
		defaultMessage:
			'Add a weight to each option to define its relative importance. Weights are used in formulas that include this field',
		description: 'Tooltip message describing the field option weighting mechanism',
	},
	weightTypeTooltipImageLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.weight-type-tooltip-image-label',
		defaultMessage: 'Option weight type image',
		description: 'Label for an image showing option weighting',
	},
	weightTypeNumberTooltipImageLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.weight-type-number-tooltip-image-label',
		defaultMessage: 'Number option weight type image',
		description: 'Label for an image showing number based option weight type',
	},
	weightTypeRatingTooltipImage: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.weight-type-rating-tooltip-image',
		defaultMessage: 'Rating option weight type image',
		description: 'Label for an image showing rating based option weight type',
	},
	removeWeighting: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.remove-weighting',
		defaultMessage: 'Remove weighting',
		description: 'Dropdown menu option text, can be clicked to remove field option weighting`',
	},
});
