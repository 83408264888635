import has from 'lodash/has';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { SINGLE_SELECT } from '../constants';
import { jiraOptionMapping } from '../option';
import type { JiraFieldMapping } from '../types';

export const jiraArchivedMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<OptionFieldValue> => {
	const optionsById = {
		[field.archivedOption?.jiraOptionId || '']: field.archivedOption,
	};

	return {
		...jiraOptionMapping(issuesRemote, field),
		getValueFromJiraIssue: (issue) =>
			issue.fields[field.key] && has(optionsById, issue.fields[field.key].id)
				? {
						id: issue.fields[field.key].id,
					}
				: undefined,
		getFieldValueForJiraBulkUpdate: (value) => ({
			[SINGLE_SELECT]: {
				fieldId: field.key,
				option: {
					optionId: value ? optionsById[value.id]?.id ?? -1 : -1,
				},
			},
		}),
	};
};
