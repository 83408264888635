import React, { useEffect } from 'react';
import { render } from 'react-dom';
import AppBase from '@atlassian/jira-app-base';
import { initBrowserMetrics3 } from '@atlassian/jira-browser-metrics';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing';
import { provideHealthcheck } from '@atlassian/jira-common-healthcheck';
import { JiraContextContainer } from '@atlassian/jira-context-container';
import ModalIssueApp from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import ShareView from '@atlassian/jira-product-discovery-sharing';
import { StrictMode } from '@atlassian/jira-react-strict-mode';
import { type Routes, createBrowserHistory, RouteComponent } from '@atlassian/jira-router';
import { Router } from '@atlassian/jira-router-components';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference';
import { JIRA_PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller';
import global from '../global';
import { Wrapper } from './utils';

provideHealthcheck();
global.install();

const container = document.getElementById('jira-frontend');

// ModalIssueApp is imported (but unused) to get webpack to bundle all dependencies properly.
// without this, package sizes all over jira-spa will explode
export const ModalIssueAppForWebpack = () => ModalIssueApp;

const JpdShareViewAppProvider = () => {
	initBrowserMetrics3();

	const appEditions = useAppEditions();
	const routes: Routes = [
		{
			name: 'jpd-share-view-static',
			exact: true,
			path: '/jira/discovery/share/:section/:resource',
			component: ShareView,
			resources: [themePreferenceResource],
		},
	];
	const history = createBrowserHistory();

	useEffect(() => {
		// override Jira's min sizes in embedded mode
		if (document.body !== null && document.body !== undefined) {
			document.body.style.minWidth = '0';
			document.body.style.minHeight = '0';
		}
	}, []);

	return (
		<StrictMode>
			<JiraContextContainer>
				<ViewExperienceTrackingProvider
					experienceId=""
					experience="viewJpdShareView"
					analyticsSource="jpdShareView"
					edition={appEditions.software}
					application={JIRA_PRODUCT_DISCOVERY}
				>
					<AppBase id="jpd-share-view">
						<ContextualAnalyticsData sourceType={SCREEN} sourceName="polaris">
							<SubProductUpdater subProduct="polaris" />
							<Router history={history} routes={routes}>
								<Wrapper>
									<RouteComponent />
								</Wrapper>
							</Router>
						</ContextualAnalyticsData>
					</AppBase>
				</ViewExperienceTrackingProvider>
			</JiraContextContainer>
		</StrictMode>
	);
};

if (container) {
	render(<JpdShareViewAppProvider />, container);
}
