import React, { useEffect } from 'react';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { useResource } from '@atlassian/jira-router';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { PolarisCustomEnvironmentContainerProvider } from '../custom';
import { useActions } from '../store';
import { PolarisEnvironmentContainerTypes } from '../types';

export const PolarisJiraSpaProjectEnvironmentProvider = () => {
	const { data, loading, error } = useResource(projectContextResourceWithCache);
	const { setLoading, setError, setData, reset } = useActions();
	const { cloudId } = useTenantContext();

	useEffect(() => {
		setLoading(loading);
	}, [setLoading, loading]);

	useEffect(() => {
		if (error !== null && error !== undefined) {
			setError(error.message);
		} else {
			setError(undefined);
		}
	}, [setError, error]);

	useEffect(() => {
		if (data !== undefined && data !== null) {
			const projectId = `${data.projectId}`;
			setData({
				type: PolarisEnvironmentContainerTypes.PROJECT,
				id: projectId,
				projectId,
				projectKey: data.projectKey,
				projectAri: JiraProjectAri.create({ siteId: cloudId, projectId }),
			});
		} else {
			setData(undefined);
		}
	}, [setData, cloudId, data]);

	useEffect(
		() => () => {
			// in a Jira SPA environment we need to reset the global state on unmount of this
			// provider component to avoid leaking state between different projects. Parts of the
			// application would already get re-rendered while the new project data hasn't propagated
			// from the router yet, which in turn would trigger JPD's own router and force a back-route
			// to the old project. This ensures that any SPA transition that unmounts the JPD tree also
			// resets the project state.
			reset();
		},
		[reset],
	);

	return null;
};

type PolarisCustomProjectEnvironmentContainerProviderProps = {
	projectId: string;
	projectKey: string;
	cloudId: string;
};
export const PolarisCustomProjectEnvironmentContainerProvider = ({
	projectKey,
	projectId,
	cloudId,
}: PolarisCustomProjectEnvironmentContainerProviderProps) => (
	<PolarisCustomEnvironmentContainerProvider
		projectKey={projectKey}
		projectId={projectId}
		id={projectId}
		projectAri={JiraProjectAri.create({ siteId: cloudId, projectId })}
		type={PolarisEnvironmentContainerTypes.PROJECT}
	/>
);
