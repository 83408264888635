import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	placeholder: {
		id: 'polaris-ideas.view-content.idea-list.cell.cells.linked-issues-field.empty.placeholder',
		defaultMessage: 'Create a delivery ticket',
		description:
			'Link displayed when hovering over an empty delivery status field. There is only limited space available for this message and is optimized for the length of the default message.',
	},
});
