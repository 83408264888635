import React, { type ReactNode, memo, useMemo, useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import sortedIndexBy from 'lodash/sortedIndexBy';
import type { EmojiDescription } from '@atlaskit/emoji';
import { Box, xcss } from '@atlaskit/primitives';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import {
	useProjectIdUnsafe,
	useProjectKeyUnsafe,
} from '@atlassian/jira-polaris-component-environment-container';
import { useEditorAiEnabled } from '@atlassian/jira-polaris-component-environment-tenant';
import type { Props as FieldConfigurationProps } from '@atlassian/jira-polaris-component-field-configuration/src/controllers/types.tsx';
import { FieldConfiguration } from '@atlassian/jira-polaris-component-field-configuration/src/main.tsx';
import { DescriptionEditor } from '@atlassian/jira-polaris-component-field-configuration/src/ui/field-main-properties-editor/description-editor/index.tsx';
import { FieldConfigurationSection } from '@atlassian/jira-polaris-component-field-configuration/src/ui/index.tsx';
import { useCanEditFields } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { UpdateValueDecorationProps } from '../../../../controllers/field/actions/decorations/update/types';
import { useFieldActions } from '../../../../controllers/field/main.tsx';
import { useDecorationsForField } from '../../../../controllers/field/selectors/decoration/hooks.tsx';
import {
	useFieldDescription,
	useFieldEmoji,
	useFieldFormula,
	useIsGlobalCustomField,
	useFieldType,
} from '../../../../controllers/field/selectors/field-hooks';
import { useFieldOptions } from '../../../../controllers/field/selectors/options-hooks';
import { useIssueActions } from '../../../../controllers/issue/main.tsx';
import { useField, useFieldMapping } from '../../../../controllers/issue/selectors/fields-hooks';
import { useSortedGroupOptions } from '../../../../controllers/issue/selectors/grouping-hooks';
import { useUpdateDynamicField } from '../../../../controllers/issue/utils/update-dynamic-field';
import { useRightSidebarShowing } from '../../../../controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingField } from '../../../../controllers/right-sidebar/types';
import { AtlasConfiguration } from './atlas';
import { DecoratorPlayItems } from './decoration/play-decoration/index.tsx';
import { DeliveryConfiguration } from './delivery';
import { FormulaContent } from './formula-content';

type FieldItemProps = {
	fieldKey: FieldKey;
	isSharedView?: boolean;
	openSidebarOption: string | null;
	onOpenRightSidebarOption: (id: string | null, field?: FieldKey) => void;
};

const useOptionLabels = (fieldKey: FieldKey) => {
	const { options } = useSortedGroupOptions(fieldKey);
	const mapping = useFieldMapping(fieldKey);
	return useMemo(
		() =>
			options.map(({ groupIdentity, value }) => ({
				id: groupIdentity,
				value: mapping?.getLabel(groupIdentity, value) || groupIdentity,
			})),
		[mapping, options],
	);
};

const useFieldWithLabels = (fieldKey: string) => {
	const field = useField(fieldKey);
	const fieldLabelOptions = useOptionLabels(fieldKey);
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);

	return useMemo(() => {
		if (!field) {
			return field;
		}

		// global labels were not loaded yet
		if (isGlobalCustomField && !field.options) {
			return field;
		}

		if (isGlobalCustomField && field.options && fieldLabelOptions.length) {
			const globalLabelsSet = new Set(field.options.map((l) => l.id) || []);

			const newLocalLabels = fieldLabelOptions
				.filter((l) => !globalLabelsSet.has(l.id))
				.map((l) => ({
					...l,
					jiraOptionId: l.id,
					weight: 1,
				}));

			if (!newLocalLabels.length) {
				// no new local labels available
				return field;
			}

			const options = [...field.options];

			// insert local labels in proper places of the sorted array
			for (const localLabel of newLocalLabels) {
				options.splice(
					sortedIndexBy(options, localLabel, (l) => l.id.toLocaleLowerCase()),
					0,
					localLabel,
				);
			}

			return {
				...field,
				options,
			};
		}

		if (!field.options && fieldLabelOptions.length) {
			return {
				...field,
				options: fieldLabelOptions.map((labelOption) => ({
					...labelOption,
					jiraOptionId: labelOption.id,
					weight: 1,
				})),
			};
		}

		return field;
	}, [field, fieldLabelOptions, isGlobalCustomField]);
};

export const FieldItem = memo<FieldItemProps>((props: FieldItemProps) => {
	const { fieldKey, onOpenRightSidebarOption, isSharedView, openSidebarOption } = props;
	const defaultOpenSidebarOptionId = useRef(openSidebarOption ?? undefined);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const field = useFieldWithLabels(fieldKey);
	const fieldValueDecorations = useDecorationsForField(fieldKey);
	const fieldDescription = useFieldDescription(fieldKey);
	const formula = useFieldFormula(fieldKey);
	const fieldType = useFieldType(fieldKey);
	const fieldOptions = useFieldOptions(fieldKey);
	const {
		updateFieldFormula,
		updateLabel,
		updateDescription,
		updateEmoji,
		createValueDecoration,
		createValueDecorationBulk,
		updateValueDecoration,
		deleteValueDecoration,
		deleteAllValueDecorations,
		addOption,
		deleteOption,
		renameOption,
		rankOptions,
		updateFieldOptionWeight,
		updateFieldOptionWeightType,
		updateFieldOptionAutoFormattingDisabled,
		updateIntervalFieldSource,
		updateDeliveryDateSelectedField,
		updateDeliveryDateAggregationType,
		updateUserFieldDisplayMode,
		loadGlobalLabels,
	} = useFieldActions();
	const updateDynamicField = useUpdateDynamicField();
	const canEditFields = useCanEditFields();
	const isGlobalCustomField = useIsGlobalCustomField(fieldKey);
	const emojiId = useFieldEmoji(fieldKey);
	const projectId = useProjectIdUnsafe();
	const projectKey = useProjectKeyUnsafe();
	const [sidebarShowing] = useRightSidebarShowing();
	const isEditorAiEnabled = useEditorAiEnabled();
	const { loadDeliveryProgress } = useIssueActions();
	const optionsLimitCount = useEntityLimitsByType(ENTITY_LIMIT_TYPE.OPTIONS_PER_FIELD);

	useEffect(() => {
		if (fieldType === FIELD_TYPES.CUSTOM_LABELS && isGlobalCustomField) {
			loadGlobalLabels(fieldKey);
		}
	}, [fieldKey, fieldType, isGlobalCustomField, loadGlobalLabels]);

	const handleCalculatedFieldUpdate = useCallback(
		async (updatedFormula: DynamicFieldFormula) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'formula updated', 'config-fields');

			const result = await updateFieldFormula(fieldKey, updatedFormula);
			updateDynamicField(result);
		},
		[fieldKey, createAnalyticsEvent, updateFieldFormula, updateDynamicField],
	);

	const handleCalculatedFieldLabelUpdate = useCallback(
		async (updatedLabel?: string): Promise<Error | boolean> => {
			if (updatedLabel === undefined) {
				return Promise.resolve(false);
			}

			return updateLabel(fieldKey, updatedLabel, createAnalyticsEvent);
		},
		[createAnalyticsEvent, fieldKey, updateLabel],
	);

	const handleUpdateDescription = useCallback(
		async (value?: string) => {
			if (value !== fieldDescription.value) {
				await updateDescription(fieldKey, value ?? '', createAnalyticsEvent);
			}
		},
		[createAnalyticsEvent, fieldDescription.value, fieldKey, updateDescription],
	);

	const handleUpdateEmoji = useCallback(
		async (newEmoji: EmojiDescription | undefined) => {
			const newEmojiId = newEmoji ? newEmoji.id : undefined;
			if (emojiId !== newEmojiId) {
				await updateEmoji(fieldKey, newEmojiId, createAnalyticsEvent);
			}
		},
		[createAnalyticsEvent, emojiId, fieldKey, updateEmoji],
	);

	const handleCreateDecoration = useCallback<
		NonNullable<FieldConfigurationProps['onValueDecorationCreated']>
	>(
		async (createProps) => {
			const localDecorationId = createValueDecoration({
				...createProps,
				fieldKey,
			});

			return localDecorationId;
		},
		[createValueDecoration, fieldKey],
	);

	const handleMultipleCreateDecoration = useCallback<
		NonNullable<FieldConfigurationProps['onMultipleValueDecorationsCreated']>
	>(
		async (multiCreateProps) => {
			const localDecorationIds = createValueDecorationBulk({
				decorations: multiCreateProps,
				fieldKey,
			});

			return localDecorationIds;
		},
		[createValueDecorationBulk, fieldKey],
	);

	const handleDeleteAllDecorations = useCallback<
		NonNullable<FieldConfigurationProps['onAllValueDecorationsDeleted']>
	>(async () => {
		deleteAllValueDecorations({
			fieldKey,
		});
	}, [deleteAllValueDecorations, fieldKey]);

	const handleUpdateDecoration = useCallback<
		NonNullable<FieldConfigurationProps['onValueDecorationUpdated']>
	>(
		async (updateProps) => {
			const optionIds = fieldOptions?.map((option) => option.id) || [];
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			updateValueDecoration({
				...updateProps,
				filterOutDecorationRuleFn: (decorationRule) => optionIds.includes(decorationRule.value),
				fieldKey,
			} as UpdateValueDecorationProps);
		},
		[fieldOptions, updateValueDecoration, fieldKey],
	);

	const handleDeleteDecoration = useCallback<
		NonNullable<FieldConfigurationProps['onValueDecorationDeleted']>
	>(
		async (deleteProps) => {
			deleteValueDecoration(fieldKey, deleteProps.localDecorationId);
		},
		[deleteValueDecoration, fieldKey],
	);

	const handleAddedOption = useCallback<NonNullable<FieldConfigurationProps['onOptionAdded']>>(
		async (value) => {
			const result = await addOption(fieldKey, value);
			return result !== undefined ? String(result) : result;
		},
		[addOption, fieldKey],
	);

	const handleDeletedOption = useCallback<NonNullable<FieldConfigurationProps['onOptionDeleted']>>(
		async (optionId) => deleteOption(fieldKey, optionId),
		[deleteOption, fieldKey],
	);

	const handleRenamedOption = useCallback<NonNullable<FieldConfigurationProps['onOptionRenamed']>>(
		async (optionId, value) => renameOption(fieldKey, optionId, value),
		[renameOption, fieldKey],
	);

	const handleReorderedOptions = useCallback<
		NonNullable<FieldConfigurationProps['onOptionsReordered']>
	>(async (optionIds) => rankOptions(fieldKey, optionIds), [rankOptions, fieldKey]);

	const handleUpdatedOptionWeightType = useCallback<
		NonNullable<FieldConfigurationProps['onFieldOptionWeightTypeUpdated']>
	>(
		async (optionWeightType) => {
			await updateFieldOptionWeightType(fieldKey, optionWeightType);
		},
		[fieldKey, updateFieldOptionWeightType],
	);

	const handleUpdatedFieldOptionWeight = useCallback<
		NonNullable<FieldConfigurationProps['onFieldOptionWeightUpdated']>
	>(
		async (optionId, weight) => {
			await updateFieldOptionWeight(fieldKey, optionId, weight);
		},
		[fieldKey, updateFieldOptionWeight],
	);

	const handleUpdatedFieldOptionAutoFormattingDisabled = useCallback<
		NonNullable<FieldConfigurationProps['onFieldOptionAutoFormattingUpdated']>
	>(
		async (value) => {
			await updateFieldOptionAutoFormattingDisabled(fieldKey, !value);
		},
		[fieldKey, updateFieldOptionAutoFormattingDisabled],
	);

	const handleUpdatedIntervalFieldSource = useCallback<
		NonNullable<FieldConfigurationProps['onIntervalFieldSourceUpdated']>
	>(
		async (source, analyticsEvent) => {
			await updateIntervalFieldSource(fieldKey, source, analyticsEvent);
		},
		[fieldKey, updateIntervalFieldSource],
	);

	const handleDeliveryDateSelectedField = useCallback<
		NonNullable<FieldConfigurationProps['onDeliveryDateSourceFieldUpdated']>
	>(
		async (selectedFieldKey, analyticsEvent) => {
			await updateDeliveryDateSelectedField(fieldKey, selectedFieldKey, analyticsEvent);
		},
		[fieldKey, updateDeliveryDateSelectedField],
	);

	const handleDeliveryDateAggregationTypedUpdated = useCallback<
		NonNullable<FieldConfigurationProps['onDeliveryDateAggregationTypeUpdated']>
	>(
		async (aggregationType, analyticsEvent) => {
			await updateDeliveryDateAggregationType(fieldKey, aggregationType, analyticsEvent);
		},
		[fieldKey, updateDeliveryDateAggregationType],
	);

	const handleDeliveryDateConfigurationComplete = useCallback<
		NonNullable<FieldConfigurationProps['onDeliveryDateConfigurationComplete']>
	>(() => {
		loadDeliveryProgress();
	}, [loadDeliveryProgress]);

	const handleFieldDisplayModeUpdated = useCallback<
		NonNullable<FieldConfigurationProps['onFieldPeopleDisplayModeUpdated']>
	>(
		async (displayMode) => {
			updateUserFieldDisplayMode(fieldKey, displayMode);
		},
		[fieldKey, updateUserFieldDisplayMode],
	);

	const emitSearchAnalyticsEvent = useMemo(
		() => debounce(() => fireCompoundAnalyticsEvent.RightSidebarFieldOptionsSearchOccured(), 1000),
		[],
	);

	const renderConfigSection = useCallback(
		(children?: ReactNode) => {
			switch (fieldType) {
				case FIELD_TYPES.DELIVERY_PROGRESS:
				case FIELD_TYPES.DELIVERY_STATUS:
					return (
						<FieldConfigurationSection>
							<DeliveryConfiguration fieldKey={fieldKey} readonly={!canEditFields} />
						</FieldConfigurationSection>
					);
				case FIELD_TYPES.ATLAS_GOAL:
				case FIELD_TYPES.ATLAS_PROJECT:
					return (
						<FieldConfigurationSection>
							<AtlasConfiguration fieldKey={fieldKey} />
						</FieldConfigurationSection>
					);
				default:
					return children;
			}
		},
		[canEditFields, fieldKey, fieldType],
	);

	if (isSharedView) {
		return (
			<Box paddingBlock="space.100" paddingInline="space.200">
				<DescriptionEditor
					fieldKey={fieldKey}
					fieldDescription={fieldDescription}
					isEditable={false}
					isEditorAiEnabled={false}
					projectId={projectId}
					projectKey={projectKey}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onUpdateDescription={() => {}}
				/>
			</Box>
		);
	}

	const isOpenedFromProjectFieldsPage =
		sidebarShowing.mode === RightSidebarShowingField &&
		sidebarShowing.origin === 'projectFieldsPage';

	if (!field || !fieldValueDecorations) {
		return null;
	}

	return (
		<Box
			paddingInline={isOpenedFromProjectFieldsPage ? 'space.0' : 'space.200'}
			xcss={wrapperStyles}
		>
			<FieldConfiguration
				canEditField={canEditFields}
				decorationsComponents={{
					[FIELD_TYPES.VOTES]: <DecoratorPlayItems fieldKey={fieldKey} readonly={!canEditFields} />,
				}}
				fieldData={{ field, valueDecorations: fieldValueDecorations }}
				onUpdateFieldName={handleCalculatedFieldLabelUpdate}
				onUpdateDescription={handleUpdateDescription}
				onUpdateEmoji={handleUpdateEmoji}
				onValueDecorationCreated={handleCreateDecoration}
				onMultipleValueDecorationsCreated={handleMultipleCreateDecoration}
				onValueDecorationUpdated={handleUpdateDecoration}
				onValueDecorationDeleted={handleDeleteDecoration}
				onAllValueDecorationsDeleted={handleDeleteAllDecorations}
				onOptionAdded={handleAddedOption}
				onOptionDeleted={handleDeletedOption}
				onOptionOpened={onOpenRightSidebarOption}
				onOptionRenamed={handleRenamedOption}
				onOptionsReordered={handleReorderedOptions}
				onOptionsSearch={emitSearchAnalyticsEvent}
				onFieldOptionWeightTypeUpdated={handleUpdatedOptionWeightType}
				onFieldOptionWeightUpdated={handleUpdatedFieldOptionWeight}
				onFieldOptionAutoFormattingUpdated={handleUpdatedFieldOptionAutoFormattingDisabled}
				onIntervalFieldSourceUpdated={handleUpdatedIntervalFieldSource}
				onDeliveryDateSourceFieldUpdated={handleDeliveryDateSelectedField}
				onDeliveryDateAggregationTypeUpdated={handleDeliveryDateAggregationTypedUpdated}
				onDeliveryDateConfigurationComplete={handleDeliveryDateConfigurationComplete}
				onFieldPeopleDisplayModeUpdated={handleFieldDisplayModeUpdated}
				readonly={isGlobalCustomField}
				optionsLimitCount={optionsLimitCount}
				outerSpacing={isOpenedFromProjectFieldsPage ? '24px' : '16px'}
				projectId={projectId}
				projectKey={projectKey}
				renderConfigSection={renderConfigSection}
				formulaContent={
					<FormulaContent
						fieldKey={fieldKey}
						formula={formula}
						onUpdateCalculatedField={handleCalculatedFieldUpdate}
						readonly={!canEditFields || isGlobalCustomField}
					/>
				}
				isDescriptionEditorAiEnabled={isEditorAiEnabled}
				defaultOpenSidebarOptionId={defaultOpenSidebarOptionId.current}
			/>
		</Box>
	);
});

const wrapperStyles = xcss({
	height: '100%',
});
