import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.settings.notifications.title',
		defaultMessage: 'Notifications',
		description: 'Settings page title - Notification settings',
	},
	pageDescription: {
		id: 'polaris-ideas.settings.notifications.page-description',
		defaultMessage:
			'Jira Product Discovery can send people or roles an email when events happen on an issue - for example, when someone comments on an issue. Settings on this page will be overridden by a user’s {personalNotificationsPrefLink}.',
		description: 'Description text for the internal notifications page',
	},
	personalNotificationsPreferences: {
		id: 'polaris-ideas.settings.notifications.personal-notifications-preferences',
		defaultMessage: 'personal notification preferences',
		description: 'Link to the personal notifications preferences page inside the pageDescription',
	},
	emailTitle: {
		id: 'polaris-ideas.settings.notifications.email-title',
		defaultMessage: 'Email',
		description: 'Title for email section of the notifications page',
	},
	learnMore: {
		id: 'polaris-ideas.settings.notifications.learn-more',
		defaultMessage: 'Learn more',
		description: 'Clickable link to a support page',
	},
	emailAddressDescription: {
		id: 'polaris-ideas.settings.notifications.email-address-description',
		defaultMessage: 'Notifications for this project will be sent from the following address:',
		description: 'Email Address Description text for the email notifications page',
	},
});
