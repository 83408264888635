import type { UserAuth } from '@atlassian/jira-issue-gira-transformer-types';

// FETCH_USER_AUTH_REQUEST
export const FETCH_USER_AUTH_REQUEST = 'FETCH_USER_AUTH_REQUEST' as const;

export type FetchUserAuthRequestAction = {
	type: typeof FETCH_USER_AUTH_REQUEST;
};

export const fetchUserAuthRequest = (): FetchUserAuthRequestAction => ({
	type: FETCH_USER_AUTH_REQUEST,
});

// FETCH_USER_AUTH_SUCCESS
export const FETCH_USER_AUTH_SUCCESS = 'FETCH_USER_AUTH_SUCCESS' as const;

export type FetchUserAuthSuccessAction = {
	type: typeof FETCH_USER_AUTH_SUCCESS;
	payload: UserAuth;
};

export const fetchUserAuthSuccess = (payload: UserAuth): FetchUserAuthSuccessAction => ({
	type: FETCH_USER_AUTH_SUCCESS,
	payload,
});

// FETCH_USER_AUTH_FAILURE
export const FETCH_USER_AUTH_FAILURE = 'FETCH_USER_AUTH_FAILURE' as const;

export type FetchUserAuthFailureActionPayload = {
	statusCode: number;
};

export type FetchUserAuthFailureAction = {
	type: typeof FETCH_USER_AUTH_FAILURE;
	payload: FetchUserAuthFailureActionPayload;
};

export const fetchUserAuthFailure = (statusCode: number): FetchUserAuthFailureAction => ({
	type: FETCH_USER_AUTH_FAILURE,
	payload: { statusCode },
});

// CHECK_USER_AUTH
export const CHECK_USER_AUTH = 'CHECK_USER_AUTH' as const;

export type CheckUserAuthAction = {
	type: typeof CHECK_USER_AUTH;
};

export const checkUserAuth = (): CheckUserAuthAction => ({
	type: CHECK_USER_AUTH,
});

export type UserAuthAction =
	| FetchUserAuthRequestAction
	| FetchUserAuthSuccessAction
	| FetchUserAuthFailureAction
	| CheckUserAuthAction;
