import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchHint: {
		id: 'polaris-ideas.view-controls.matrix-field-select.search-hint',
		defaultMessage: 'Search',
		description: 'Search hint for the search bar in a generic filter dropdown',
	},
	disableFieldsHeader: {
		id: 'polaris-ideas.view-controls.matrix-field-select.disable-fields-header',
		defaultMessage:
			'{count} {count, plural, one {field is hidden, as it is} other {fields are hidden, as they are}} not supported by Matrix view.',
		description: 'Select header for unsupported group of fields',
	},
	foundDisableFields: {
		id: 'polaris-ideas.view-controls.matrix-field-select.found-disable-fields',
		defaultMessage:
			'{count} {count, plural, one {field meeting criteria is hidden, as it is} other {fields meeting criteria are hidden, as they are}} not supported by Matrix view.',
		description: 'Select header for found by search unsupported group of fields',
	},
	showDisableFields: {
		id: 'polaris-ideas.view-controls.matrix-field-select.show-disable-fields',
		defaultMessage: 'Show unsupported fields',
		description: 'Button message for showing unsupported fields',
	},
	hideDisableFields: {
		id: 'polaris-ideas.view-controls.matrix-field-select.hide-disable-fields',
		defaultMessage: 'Hide unsupported fields',
		description: 'Button message for hiding unsupported fields',
	},
	noMatchesIndicator: {
		id: 'polaris-ideas.view-controls.matrix-field-select.no-matches-indicator',
		defaultMessage: 'No matches found',
		description: 'Indicator that no options are visible in the dropdown.',
	},
});
