import React, { type SyntheticEvent, useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { useThemeObserver, token } from '@atlaskit/tokens';
import HelpPanelButton from '@atlassian/jira-help-panel-button';
import { useIntl } from '@atlassian/jira-intl';
import {
	slackIcon,
	pendoIcon,
	teamsIcon,
	intercomIcon,
	intercomIconDark,
	vivunIcon,
	zendeskIcon,
	zendeskIconDark,
	salesforceIcon,
} from '@atlassian/jira-polaris-common/src/common/ui/apps';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import HintMessage from '@atlassian/jira-polaris-lib-hint-message/src/async';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages';

export const ADD_LINK_TO_INSIGHT_HINT_CLOSED_KEY = 'polaris.add-link-to-insight-hint-closed';
export type AddLinkHintProps = {
	onClose: () => void;
};

const HELP_ARTICLE_ID = '6f19aqUbDdRQJ1RrORLG6u';
const HELP_FALLBACK_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/create-insights-from-external-sources/';

export const AddLinkHint = ({ onClose }: AddLinkHintProps) => {
	const { formatMessage } = useIntl();
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';
	const { createAnalyticsEvent } = useAnalyticsEvents();

	let zendeskThemedIcon = zendeskIcon;
	let intercomThemedIcon = intercomIcon;

	if (isDarkMode) {
		zendeskThemedIcon = zendeskIconDark;
		intercomThemedIcon = intercomIconDark;
	}

	const handleClose = useCallback(() => {
		fireCompoundAnalyticsEvent.IdeaView.InsightsTab.addLinkToInsightsHintClosed(
			createAnalyticsEvent({}),
		);
		onClose();
	}, [onClose, createAnalyticsEvent]);

	return (
		<HintMessage
			title={formatMessage(messages.title)}
			description={formatMessage(messages.description)}
			onClose={handleClose}
			learnMore={{
				label: formatMessage(messages.learnMoreButton),
				articleId: HELP_ARTICLE_ID,
				fallbackHref: HELP_FALLBACK_URL,
				onClick: (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireCompoundAnalyticsEvent.IdeaView.InsightsTab.addLinkToInsightsLearnMoreButtonClicked(
						analyticsEvent,
						true,
					);
				},
			}}
		>
			<AppsListContainer>
				{[
					slackIcon,
					pendoIcon,
					teamsIcon,
					intercomThemedIcon,
					vivunIcon,
					zendeskThemedIcon,
					salesforceIcon,
				].map((icon, key) => (
					<AppIcon src={icon} key={key} />
				))}
			</AppsListContainer>
		</HintMessage>
	);
};

export const AddLinkTextHint = () => {
	const { formatMessage } = useIntl();

	return (
		<HelpPanelButton
			appearance="link"
			articleId={HELP_ARTICLE_ID}
			onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireCompoundAnalyticsEvent.IdeaView.InsightsTab.addLinkToInsightsLearnMoreButtonClicked(
					analyticsEvent,
					false,
				);
			}}
			fallbackComponent={
				<Button
					appearance="link"
					rel="noopener noreferrer"
					target="_blank"
					href={HELP_FALLBACK_URL}
				>
					{formatMessage(messages.learnMore)}
				</Button>
			}
		>
			{formatMessage(messages.learnMore)}
		</HelpPanelButton>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppsListContainer = styled.div({
	display: 'flex',
	margin: `${token('space.150', '12px')} 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AppIcon = styled.img({
	display: 'flex',
	marginRight: token('space.075', '6px'),
	width: '24px',
});
