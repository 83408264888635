import React from 'react';
import { styled } from '@compiled/react';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	DELIVERY_CALCULATION_STRATEGIES,
	DELIVERY_CALCULATION_MODE,
} from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import type {
	DeliveryFieldPresentationType,
	DeliveryFieldCalculationMode,
} from '@atlassian/jira-polaris-domain-field/src/presentation/types.tsx';
import progressDefaultDark from './assets/progress-default--dark.svg';
import progressDefaultStoryPointsDark from './assets/progress-default-story-points--dark.svg';
import progressDefaultStoryPoints from './assets/progress-default-story-points.svg';
import progressDefault from './assets/progress-default.svg';
import progressSimpleDark from './assets/progress-simple--dark.svg';
import progressSimple from './assets/progress-simple.svg';
import statusDefaultDark from './assets/status-default--dark.svg';
import statusDefaultStoryPointsDark from './assets/status-default-story-points--dark.svg';
import statusDefaultStoryPoints from './assets/status-default-story-points.svg';
import statusDefault from './assets/status-default.svg';
import statusSimpleDark from './assets/status-simple--dark.svg';
import statusSimple from './assets/status-simple.svg';

export function DeliveryConfigurationPreview({
	fieldType,
	calculationType,
	calculationMode,
	altText,
}: {
	fieldType?: typeof FIELD_TYPES.DELIVERY_PROGRESS | typeof FIELD_TYPES.DELIVERY_STATUS;
	calculationType: DeliveryFieldPresentationType;
	calculationMode: DeliveryFieldCalculationMode;
	altText: string;
}) {
	const isSimple = calculationType === DELIVERY_CALCULATION_STRATEGIES.SIMPLE;
	const isStoryPointCalculationMode = calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS;
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	let previewImage;

	const {
		progressSimpleImg,
		progressDefaultImg,
		progressDefaultStoryPointsImg,
		statusSimpleImg,
		statusDefaultImg,
		statusDefaultStoryPointsImg,
	} = isDarkMode
		? {
				progressSimpleImg: progressSimpleDark,
				progressDefaultImg: progressDefaultDark,
				progressDefaultStoryPointsImg: progressDefaultStoryPointsDark,
				statusSimpleImg: statusSimpleDark,
				statusDefaultImg: statusDefaultDark,
				statusDefaultStoryPointsImg: statusDefaultStoryPointsDark,
			}
		: {
				progressSimpleImg: progressSimple,
				progressDefaultImg: progressDefault,
				progressDefaultStoryPointsImg: progressDefaultStoryPoints,
				statusSimpleImg: statusSimple,
				statusDefaultImg: statusDefault,
				statusDefaultStoryPointsImg: statusDefaultStoryPoints,
			};

	if (fieldType === FIELD_TYPES.DELIVERY_PROGRESS) {
		if (isSimple) {
			previewImage = progressSimpleImg;
		} else {
			previewImage = isStoryPointCalculationMode
				? progressDefaultStoryPointsImg
				: progressDefaultImg;
		}
	} else if (isSimple) {
		previewImage = statusSimpleImg;
	} else {
		previewImage = isStoryPointCalculationMode ? statusDefaultStoryPointsImg : statusDefaultImg;
	}

	return <PreviewImage src={previewImage} alt={altText} />;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewImage = styled.img({
	marginTop: token('space.300', '24px'),
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 45}px`,
});
