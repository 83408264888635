import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	cloneStartTitle: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.notifications.clone-start-title',
		defaultMessage: 'Cloning {issueKey}',
		description: 'Notification title for idea cloning start',
	},
	cloneStartDescription: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.notifications.clone-start-description',
		defaultMessage:
			"When cloning is complete you'll receive another pop-up here just like this one.",
		description: 'Notification description for idea cloning start',
	},
	notificationTitle: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.notifications.notification-title',
		defaultMessage: 'Idea cloning complete',
		description: 'Notification title for completing cloning of idea',
	},
	notificationDescription: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.notifications.notification-description',
		defaultMessage: 'Your idea is cloned to a new idea',
		description: 'Idea clone success notification description prefix',
	},
	notificationErrorTitle: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.notifications.notification-error-title',
		defaultMessage: 'Idea cloning complete with errors',
		description: 'Notification title on failed clone of idea',
	},
	notificationErrorDescription: {
		id: 'polaris-ideas.idea-view.collaboration-controls.clone.notifications.notification-error-description',
		defaultMessage: 'An error occurred while cloning the idea',
		description: 'Clone failure notification description',
	},
});
