import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import { useSortedIssueIds } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks';
import { useProjectName } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import { useCurrentViewConfigured } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useCollectionName } from '@atlassian/jira-polaris-component-collections/src/controllers/collections/index.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import { useCanExportViews } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	FireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ExportViewAsCsv } from './export-as-csv';
import { ExportViewAsImage } from './export-as-image';
import messages from './messages';
import type { ExportViewAsProps } from './types';

const useContainerName = (): string | undefined => {
	const [projectName] = useProjectName();
	const containerId = useEnvironmentContainer()?.id ?? '';
	const collectionName = useCollectionName({ collectionUUID: containerId });
	const isCollectionView = useIsCollectionView();
	return isCollectionView ? collectionName : projectName;
};

const Warnings = ({ warnings }: { warnings: MessageDescriptor[] }) => {
	const { formatMessage } = useIntl();

	if (warnings.length === 1) {
		return <p>{formatMessage(warnings[0])}</p>;
	}

	return (
		<WarningList>
			{warnings.map((warning) => (
				<li key={warning.id}>{formatMessage(warning)}</li>
			))}
		</WarningList>
	);
};

export const ExportDialogContent = ({
	isLoading,
	onExporting,
}: Omit<ExportViewAsProps, 'isEnabled' | 'containerName'>) => {
	const canExportViews = useCanExportViews();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isCurrentViewConfigured = useCurrentViewConfigured();
	const isViewDisplayingIssues = useSortedIssueIds().length;
	const containerName = useContainerName();

	useEffect(() => {
		sendPendoTrackEvent({
			actionSubjectAndAction: 'exportModal opened',
		});

		return () => {
			sendPendoTrackEvent({
				actionSubjectAndAction: 'exportModal closed',
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'modal closed', 'export');
		};
	}, [createAnalyticsEvent]);

	const warnings: MessageDescriptor[] = [];
	if (!canExportViews) warnings.push(messages.noPermissions);
	if (!isCurrentViewConfigured || !isViewDisplayingIssues) warnings.push(messages.emptyView);

	if (warnings.length) {
		return (
			<Box padding="space.100" xcss={warningsContainerStyles}>
				<SectionMessage testId="polaris-ideas.ui.view-header.export-dialog.export-dialog-content.section-message">
					<Warnings warnings={warnings} />
				</SectionMessage>
			</Box>
		);
	}

	return (
		<Stack
			testId="polaris-ideas.ui.view-header.export-dialog.export-dialog-content.stack"
			xcss={exportContainerStyles}
		>
			<FireScreenAnalytics />
			<ExportViewAsImage
				isLoading={isLoading}
				onExporting={onExporting}
				containerName={containerName}
			/>
			<ExportViewAsCsv
				isLoading={isLoading}
				onExporting={onExporting}
				containerName={containerName}
			/>
		</Stack>
	);
};

const exportContainerStyles = xcss({
	minWidth: '200px',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
});

const warningsContainerStyles = xcss({
	maxWidth: '400px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningList = styled.ul({
	paddingLeft: token('space.200', '16px'),
});
