import mapValues from 'lodash/mapValues';
import type { Ari } from '@atlassian/jira-platform-ari';
import { formulaUsesFields } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/main.tsx';
import type { Field, FieldKey } from '../field/types';

// returns a list of fields, filtered to *not* include those
// that are calculated directly or indirectly from the given field
export const fieldsReferencableFrom = (
	thisFieldKey: FieldKey | undefined,
	fields: Field[],
): Field[] => {
	if (thisFieldKey === undefined) return fields;

	// first, build a cross reference for each formula field as to which other
	// fields it references.
	const xref: Record<Ari, Ari[]> = {};
	fields.forEach(({ key, formula }) => {
		if (formula) {
			xref[key] = formulaUsesFields(formula);
		}
	});

	// Now, build a memoized answer to the question "does this formula lead back to thisFieldKey"
	// (carefully arranged to avoid
	// an infinite loop when there is a preexisting cycle; which should not happen going
	// forward thanks to both backend and frontend code preventing it)
	const cyclic: Record<Ari, boolean> = {};

	cyclic[thisFieldKey] = true;

	const formulaLeadsToSelf = (fieldKey: FieldKey): boolean => {
		if (cyclic[fieldKey] === undefined) {
			cyclic[fieldKey] = true; // prevent an infinite loop
			cyclic[fieldKey] = (xref[fieldKey] || []).some(formulaLeadsToSelf);
		}
		return cyclic[fieldKey];
	};

	return fields.filter((f) => !formulaLeadsToSelf(f.key));
};

const detectCycleRecursively = (
	next: string,
	dependencies: Record<string, string[]>,
	visited: Set<string> = new Set(),
	toVisit: Set<string> = new Set(),
): boolean => {
	if (toVisit.has(next)) {
		return true;
	}
	if (visited.has(next)) {
		return false;
	}
	visited.add(next);
	toVisit.add(next);

	if (dependencies[next]) {
		for (const dependency of dependencies[next]) {
			if (detectCycleRecursively(dependency, dependencies, visited, toVisit)) {
				return true;
			}
		}
	}
	toVisit.delete(next);
	return false;
};

export const calculateCyclicDependencies = (
	dependencies: Record<string, string[]>,
): Record<string, boolean> =>
	mapValues(dependencies, (_, id) => detectCycleRecursively(id, dependencies));
