import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../../pendo';

export const ListView = {
	opened: (event: UIAnalyticsEvent, accessLevel?: string) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/M2-9XepSbr5FNP9yFynxrzIQUZQ
		sendPendoTrackEvent('listView opened');

		// https://data-portal.internal.atlassian.com/analytics/registry/51087
		fireScreenAnalytics(event, { accessLevel });
	},
	fieldValueUpdatedByDragBetweenSwimlanes: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} listDragBetweenSwimlanes`);
	},
	fieldValueUpdatedByInlineEdit: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} listInlineEdit`);
	},
	columnActionItemClicked: (
		event: UIAnalyticsEvent,
		attributes: { selectedActionItem: string },
	) => {
		sendPendoTrackEvent(`listViewColumnActionItem ${attributes.selectedActionItem}`);

		fireUIAnalytics(event, 'dropdownItem clicked', 'viewFieldActions', attributes);
	},
	learnMoreAboutArchiveButtonClicked: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/56971
		fireUIAnalytics(event, 'button clicked', 'learnMoreAboutArchivingIdeas', {});
	},
};
