import type { EcosystemState } from '@atlassian/jira-issue-view-common-types/src/ecosystem-types';
import {
	type ContentPanelSelected,
	type ContentPanelUnselect,
	CONTENT_PANEL_SELECTED,
	CONTENT_PANEL_UNSELECT,
} from '../../actions/ecosystem-content-panel-actions';
import {
	type FetchIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
} from '../../common/actions/issue-fetch-actions';

export const initialState: EcosystemState = {
	activityPanels: {},
	canInstallAddons: false,
	contentPanels: {},
	contextPanels: {},
	backgroundScripts: [], // JIV-15751: Remove `backgroundScripts` when cleaning up issue-view-remove-backgroundscripts-from-critical-fetch_6f6on
	ecosystemOnDate: 0,
	glances: {},
};

// JIV-16397: Remove when cleaning up issue-view-remove-connect-operations-from-critical-fetch_vtk4w
export const initialStateOld: EcosystemState = {
	...initialState,
	operations: [],
};

type Action = ContentPanelSelected | ContentPanelUnselect | FetchIssueSuccessAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: EcosystemState = initialStateOld, action: Action): EcosystemState => {
	switch (action.type) {
		case CONTENT_PANEL_SELECTED:
			return {
				...state,
				selectedContentPanel: action.payload,
			};

		case CONTENT_PANEL_UNSELECT:
			return {
				...state,
				selectedContentPanel: undefined,
			};

		case FETCH_ISSUE_SUCCESS:
			return {
				...state,
				...action.payload.ecosystem,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
