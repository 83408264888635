import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	addCommentFormExpand,
	changeCommentVisibility,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions';
import {
	type ShareAsCommentAction,
	SHARE_AS_COMMENT,
} from '@atlassian/jira-issue-view-store/src/actions/share-as-comment-actions';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants';
import { isAddCommentEditorExpandedSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<ShareAsCommentAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(SHARE_AS_COMMENT).switchMap(() => {
		const state: State = store.getState();

		const isEditorOpen = isAddCommentEditorExpandedSelector(state);

		if (!isEditorOpen) {
			return Observable.of(
				addCommentFormExpand(),
				// @ts-expect-error - TS2769 - No overload matches this call.
				changeCommentVisibility(NEW_COMMENT_ID, false),
			);
		}
		return Observable.empty<never>();
	});
