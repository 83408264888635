import type {
	ApproverFieldValue,
	GroupApproverPrincipal,
} from '@atlassian/jira-issue-shared-types';
import {
	SAVE_APPROVAL_ANSWER_ERROR,
	SAVE_APPROVAL_ANSWER_SUCCESS,
} from '@atlassian/jira-issue-view-common-constants';

// This action is used to update the value of an Approval field's linked user picker field, which
// can be either:
// * multi-user picker;
// * multi-group picker; or
// * singlue-user picker.
export const UPDATE_APPROVAL_LINKED_USER_PICKER_FIELD =
	'UPDATE_APPROVAL_LINKED_USER_PICKER_FIELD' as const;

export const updateApprovalLinkedUserPickerField = (
	linkedUserPickerFieldId: string | undefined,
	newApproverList:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverPrincipal>,
) => ({
	type: UPDATE_APPROVAL_LINKED_USER_PICKER_FIELD,
	payload: {
		linkedUserPickerFieldId,
		newApproverList,
	},
});

export type UpdateApprovalLinkedUserPickerFieldAction = ReturnType<
	typeof updateApprovalLinkedUserPickerField
>;

export const saveApprovalAnswerError = () => ({
	type: SAVE_APPROVAL_ANSWER_ERROR,
});

export const saveApprovalAnswerSuccess = () => ({
	type: SAVE_APPROVAL_ANSWER_SUCCESS,
});

export type SaveApproverAnswerSuccessAction = ReturnType<typeof saveApprovalAnswerSuccess>;

export type SaveApproverAnswerErrorAction = ReturnType<typeof saveApprovalAnswerError>;

// ZERO_APPROVAL_REQUIRED
export const ZERO_APPROVAL_REQUIRED = 'ZERO_APPROVAL_REQUIRED' as const;

export const zeroApprovalRequired = () => ({
	type: ZERO_APPROVAL_REQUIRED,
});
