import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noFieldsFound: {
		id: 'polaris-lib-issue-field-picker.no-fields-found',
		defaultMessage: 'No fields found',
		description: 'Message displayed in the dropdown when no fields are found',
	},
	typeToSearch: {
		id: 'polaris-lib-issue-field-picker.type-to-search',
		defaultMessage: 'Type to search',
		description: 'Message displayed in the dropdown when no search string is entered',
	},
});
