import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	inputHeader: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.input-header',
		defaultMessage: '{count, plural, one {Input} other {Inputs}}',
		description: 'Header label for formula input',
	},
	weightedScoreFormulaHelp: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.weighted-score-formula-help',
		defaultMessage: `This formula attributes a score between 0 and 100 to each idea based on the input provided.

The idea with the lowest score will get 0, the idea with the highest one will get 100, and all other ideas will get values in between.

The positive inputs change the score positively (e.g. impact), the negative inputs change the score negatively (e.g. effort).`,
		description: 'Explanation on how weighted score formula works',
	},
	positiveInputHeader: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.positive-input-header',
		defaultMessage: '{count, plural, one {Positive input} other {Positive inputs}}',
		description: 'Header label for formula input',
	},
	negativeInputHeader: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.negative-input-header',
		defaultMessage: '{count, plural, one {Negative input} other {Negative inputs}}',
		description: 'Header label for formula input',
	},
	fieldSectionHeader: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.field-section-header',
		defaultMessage: 'Number fields',
		description: 'Header label for field section',
	},
	selectSectionHeader: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.select-section-header',
		defaultMessage: 'Select fields',
		description: 'Header label for a select fields section',
	},
	dataSectionHeader: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.data-section-header',
		defaultMessage: 'Insights',
		description: 'Header label for insights section',
	},
	addInputButtonLabel: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.add-input-button-label',
		defaultMessage: 'Add an input',
		description: 'Button label for adding input fields',
	},
	numInsightsLabel: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.num-insights-label',
		defaultMessage: 'Number of insights',
		description: 'Label for a number of insights field',
	},
	numInsightsHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.num-insights-help-text',
		defaultMessage: 'The number of insights added to the idea',
		description: 'Help message for the number of insights',
	},
	appsNumInsightsHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.apps-num-insights-help-text',
		defaultMessage: 'The number of insights from {appName} added to the idea',
		description: 'Help message for the number of insights',
	},
	countType: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.count-type',
		defaultMessage: 'Count',
		description: 'Count formula type',
	},
	countSelectTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.count-select-type-help-text',
		defaultMessage: 'The number of selected options',
		description: 'Help message for the count options',
	},
	countMultiSelectTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.count-multi-select-type-help-text',
		defaultMessage: 'The number of selected options',
		description: 'Help message for the count options',
	},
	sumType: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.sum-type',
		defaultMessage: 'Sum',
		description: 'Sum formula type',
	},
	impactTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.impact-type-help-text',
		defaultMessage: 'A roll-up of the impact of each insights added to the idea',
		description: 'Help message',
	},
	valueType: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.value-type',
		defaultMessage: 'Value',
		description: 'Value formula type',
	},
	valueTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.value-type-help-text',
		defaultMessage: 'The value of this field',
		description: 'Help message',
	},
	insightValueTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.insight-value-type-help-text',
		defaultMessage: 'A roll-up of this property value for each insight added to the idea',
		description: 'Help message',
	},
	weightType: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.weight-type',
		defaultMessage: 'Weight',
		description: 'Weight formula type',
	},
	weightSelectTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.weight-select-type-help-text',
		defaultMessage: 'The weight of selected options',
		description: 'Help message for the weighted single select options',
	},
	weightMultiSelectTypeHelpText: {
		id: 'polaris-common.config.fields.common.rollup-formula-item.weight-multi-select-type-help-text',
		defaultMessage: 'The weight of selected options',
		description: 'Help message for the weighted multi select options',
	},
});
