import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlasSignIn: {
		id: 'polaris-common.fields.external-reference.common.sign-in.atlas-sign-in',
		defaultMessage: 'Sign in to Atlas',
		description: 'Title of sign in link to Atlas',
	},
	refresh: {
		id: 'polaris-common.fields.external-reference.common.sign-in.refresh',
		defaultMessage: 'Refresh',
		description: 'Title of refresh button',
	},
});
