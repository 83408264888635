import {
	FIELD_SORT,
	PROJECT_RANK,
	VIEW_RANK,
} from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';

export const getSortMode = (sortMode?: SortMode | null) => {
	if (sortMode === FIELD_SORT) {
		return 'FIELDS_SORT';
	}
	if (sortMode === VIEW_RANK) {
		return 'VIEW_RANK';
	}
	if (sortMode === PROJECT_RANK) {
		return 'PROJECT_RANK';
	}
	return undefined;
};

export const notImplementedError = (methodName?: string) => () => {
	throw new Error(`Method ${methodName || ''} not implemented in this view remote`);
};
