import last from 'lodash/last';
import {
	ValueRuleOperator,
	JsonLogicOperator,
} from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueDecoration,
	ValueDecorationRules,
	ValueDecorationLogic,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	isDecorationsWithLogic,
	isDecorationsWithRules,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';

const EMPTY_ARRAY: ValueDecorationRules[] | ValueDecorationLogic[] = [];

const getSortedDecorationsByRules = (
	decorations: ValueDecorationRules[],
): ValueDecorationRules[] => {
	const sortedDecorations = [...decorations];
	sortedDecorations.sort((decoration, other): number => {
		const decorationRule = decoration.rules.find(
			(rule) => rule.operator === ValueRuleOperator.LTE || rule.operator === ValueRuleOperator.LT,
		);
		const decorationGTERule = decoration.rules.find(
			(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
		);

		const otherRule = other.rules.find(
			(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
		);
		const otherGTERule = other.rules.find(
			(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
		);

		if (decorationRule !== undefined && otherRule !== undefined) {
			// single less than operator should be always be first
			if (decorationGTERule === undefined) {
				return -1;
			}
			if (otherGTERule === undefined) {
				return 1;
			}
			return Number(decorationRule.value) - Number(otherRule.value);
		}
		if (decorationRule === undefined && otherRule !== undefined) {
			return 1;
		}
		if (otherRule === undefined && decorationRule !== undefined) {
			return -1;
		}
		return 0;
	});
	// only care about number decorations we do understand (LTE and GT)
	return sortedDecorations.filter(
		(decoration) =>
			decoration.rules.find(
				(rule) =>
					rule.operator === ValueRuleOperator.LT ||
					rule.operator === ValueRuleOperator.LTE ||
					rule.operator === ValueRuleOperator.GTE ||
					rule.operator === ValueRuleOperator.GT,
			) !== undefined,
	);
};

const getSortedDecorationsByLogic = (
	decorations: ValueDecorationLogic[],
): ValueDecorationLogic[] => {
	const sortedDecorations = [...decorations];
	sortedDecorations.sort((decoration, other): number => {
		const { logic } = decoration;
		const decorationOperator = Object.keys(logic)[0];
		const decorationRule =
			decorationOperator === JsonLogicOperator.LT || decorationOperator === JsonLogicOperator.LTE;
		const decorationGTERule =
			decorationOperator === JsonLogicOperator.GT || decorationOperator === JsonLogicOperator.GTE;

		const { logic: otherLogic } = other;
		const [otherOperator] = Object.entries(otherLogic)[0];
		const otherRule =
			otherOperator === JsonLogicOperator.LT || otherOperator === JsonLogicOperator.LTE;
		const otherGTERule =
			otherOperator === JsonLogicOperator.GT || otherOperator === JsonLogicOperator.GTE;

		if (decorationRule && otherRule) {
			// single less than operator should be always be first
			if (decorationGTERule) {
				return -1;
			}
			if (otherGTERule) {
				return 1;
			}
			// As we are a less than or lte equal formula? The last item HAS to be the number
			const number1 = last(Object.values(logic)[0]);
			const number2 = last(Object.values(otherLogic)[0]);
			return Number(number1) - Number(number2);
		}
		if (!decorationRule && otherRule) {
			return 1;
		}
		if (!otherRule && decorationRule) {
			return -1;
		}
		return 0;
	});
	// only care about number decorations we do understand (LTE and GT)
	return sortedDecorations.filter((decoration) => {
		const operator = Object.keys(decoration.logic)[0];
		return (
			operator === JsonLogicOperator.LT ||
			operator === JsonLogicOperator.LTE ||
			operator === JsonLogicOperator.GTE ||
			operator === JsonLogicOperator.GT
		);
	});
};

// This allows us to support rules based (old) and logic based (new) without the need for a feature flag,
// which is handled elsewhere, old will be removed eventually and this gets cleaned up
export const getSortedDecorations = (decorations?: ValueDecoration[]): ValueDecoration[] => {
	if (decorations === undefined) {
		return EMPTY_ARRAY;
	}

	if (isDecorationsWithRules(decorations)) {
		return getSortedDecorationsByRules(decorations);
	}
	if (isDecorationsWithLogic(decorations)) {
		return getSortedDecorationsByLogic(decorations);
	}

	return EMPTY_ARRAY;
};
