import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptySearchHeader: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.empty-states.empty-search.empty-search-header',
		defaultMessage: 'No fields to show',
		description: 'Header of the empty search results screen',
	},
	emptySearchDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.empty-states.empty-search.empty-search-description',
		defaultMessage: 'Some fields might not be visible due to filters',
		description: 'Description of the empty search results screen',
	},
	clearFilters: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.empty-states.empty-search.clear-filters',
		defaultMessage: 'Clear filters',
		description: 'Label of Clear filters button',
	},
});
