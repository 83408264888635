import { getTime } from 'date-fns';
import { fg } from '@atlassian/jira-feature-gating';
import { toAri } from '@atlassian/jira-platform-ari';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type {
	Field,
	FieldKey,
	FieldMap,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	FieldRollup,
	FieldRollupOperation,
} from '@atlassian/jira-polaris-domain-field/src/rollup/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import {
	type Filter,
	INTERVAL_FIELD_FILTER,
	type IntervalFieldFilter,
	type IntervalFieldFilterOperator,
	type NumericFieldFilterValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { PolarisViewTableColumnSize } from '@atlassian/jira-polaris-domain-view/src/list/types.tsx';
import {
	FIELD_SORT,
	PROJECT_RANK,
	VIEW_RANK,
} from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import { PolarisTimelineMode } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type {
	jira_polaris_ProjectQuery_reduced_polarisProject_viewsets as ViewSetsTypeGQL,
	jira_polaris_ProjectQuery_reduced_polarisProject_viewsets_views as ViewsTypeGQL,
	jira_polaris_ProjectQuery_reduced_polarisProject_viewsets_views_filter_values as ViewsFilterValuesGQL,
} from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/get/__generated_apollo__/jira_polaris_ProjectQuery_reduced';

const transformIntervalFilterOperators = (
	gqlValues: ReadonlyArray<ViewsFilterValuesGQL>,
): IntervalFieldFilterOperator[] => {
	const filterOperators: IntervalFieldFilterOperator[] = [];
	gqlValues.forEach((operator) => {
		if (
			operator?.operator === 'END_AFTER_NOW' ||
			operator?.operator === 'END_BEFORE_NOW' ||
			operator?.operator === 'START_AFTER_NOW' ||
			operator?.operator === 'START_BEFORE_NOW'
		) {
			filterOperators.push({
				numericValue: operator.numericValue !== null ? operator.numericValue : 0,
				operator: operator.operator,
			});
		}
	});
	return filterOperators;
};

export const transformIntervalFilter = (
	gqlValues: ReadonlyArray<ViewsFilterValuesGQL>,
	fieldKey: FieldKey,
): IntervalFieldFilter => ({
	type: INTERVAL_FIELD_FILTER,
	field: fieldKey,
	values: transformIntervalFilterOperators(gqlValues),
});

export type GetFieldFunc = (field: { key: string | null } | null) => Field | undefined;

const getIsAutosaveEnabled = (gqlView: ViewsTypeGQL): boolean => {
	if (fg('polaris_just-for-you')) {
		return true;
	}

	return typeof gqlView.enabledAutoSave === 'boolean' ? gqlView.enabledAutoSave : true;
};

export const getTimelineConfig = (gqlView: ViewsTypeGQL, getField: GetFieldFunc) => {
	if (gqlView.visualizationType !== 'TIMELINE') {
		return undefined;
	}
	// return defaults if timelineConfig is not defined
	if (!gqlView.timelineConfig) {
		return {
			startDateField: undefined,
			dueDateField: undefined,
			mode: PolarisTimelineMode.QUARTERS,
		};
	}
	return {
		startDateField: getField(gqlView.timelineConfig.startDateField),
		dueDateField: getField(gqlView.timelineConfig.dueDateField),
		mode: gqlView.timelineConfig.mode,
		startTimestamp: gqlView.timelineConfig.startTimestamp,
		endTimestamp: gqlView.timelineConfig.endTimestamp,
		summaryCardField: getField(gqlView.timelineConfig.summaryCardField),
	};
};

const transformFilter = (gqlFilter: ViewsTypeGQL['filter'], getField: GetFieldFunc): Filter[] => {
	if (!gqlFilter) {
		return [];
	}

	const transformed: Array<Filter> = [];

	gqlFilter.forEach((filter) => {
		if (filter?.values.length !== 0) {
			const resolvedField = getField(filter.field);

			switch (filter.type) {
				case 'FIELD_IDENTITY': {
					if (resolvedField !== undefined) {
						const filterValues: Array<{
							stringValue: string | undefined;
						}> = [];
						filter.values.forEach((fv) => {
							if (fv !== null && fv !== undefined) {
								filterValues.push({
									stringValue: fv.stringValue !== null ? fv.stringValue : undefined,
								});
							}
						});
						transformed.push({
							type: 'FIELD',
							field: resolvedField.key,
							fieldType: resolvedField.type,
							values: filterValues,
						});
					}
					break;
				}
				case 'FIELD_NUMERIC': {
					if (resolvedField !== undefined) {
						const filterValues: Array<NumericFieldFilterValue> = [];
						filter.values.forEach((fv) => {
							if (
								fv?.operator === 'EQ' ||
								fv?.operator === 'GT' ||
								fv?.operator === 'LT' ||
								fv?.operator === 'GTE' ||
								fv?.operator === 'LTE' ||
								fv?.operator === 'NEQ'
							) {
								filterValues.push({
									numericValue: fv.numericValue !== null ? fv.numericValue : undefined,
									operator: fv.operator,
								});
							}
						});
						transformed.push({
							type: 'NUMBER',
							field: resolvedField.key,
							fieldType: resolvedField.type,
							values: filterValues,
						});
					}
					break;
				}
				case 'INTERVAL': {
					if (resolvedField !== undefined) {
						transformed.push(transformIntervalFilter(filter.values, resolvedField.key));
					}
					break;
				}
				case 'TEXT': {
					const filterValues: Array<{
						stringValue: string | undefined;
					}> = [];
					filter.values.forEach((fv) => {
						if (fv !== null && fv !== undefined) {
							filterValues.push({
								stringValue: fv.stringValue !== null ? fv.stringValue : undefined,
							});
						}
					});
					transformed.push({
						type: 'TEXT',
						values: filterValues,
					});
					break;
				}
				default:
				// do nothing
			}
		}
	});

	return transformed;
};

const transformGroupValues = (groupValues: ViewsTypeGQL['groupValues']): GroupValue[] => {
	if (groupValues === undefined || groupValues === null) {
		return [];
	}

	const transformed: Array<GroupValue> = [];

	groupValues.forEach((value) => {
		if (value !== null && value !== undefined) {
			transformed.push({
				id: value.id !== null ? value.id : undefined,
				label: value.label !== null ? value.label : undefined,
			});
		}
	});

	return transformed;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformDescription = (description: any) => (description !== null ? description : undefined);

const transformMatrixConfig = (
	matrixConfig: ViewsTypeGQL['matrixConfig'],
	getField: GetFieldFunc,
) => {
	if (!matrixConfig) return undefined;

	const axes =
		matrixConfig.axes?.map((axis) => ({
			dimension: axis.dimension,
			field: getField(axis.field),
			fieldOptions: transformGroupValues(axis.fieldOptions) ?? [],
			reversed: axis.reversed,
		})) ?? [];

	return {
		axes,
	};
};

const extractSortMode = (
	sortMode: ViewsTypeGQL['sortMode'],
	sortFieldsLength: number,
): SortMode => {
	if (sortMode === 'FIELDS_SORT' && sortFieldsLength > 0) {
		return FIELD_SORT;
	}
	if (sortMode === 'VIEW_RANK') {
		return VIEW_RANK;
	}
	return PROJECT_RANK;
};

const transformTableColumnSizes = (
	tableColumnSizes: NonNullable<ViewsTypeGQL['tableColumnSizes']>,
	getField: GetFieldFunc,
): PolarisViewTableColumnSize[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	tableColumnSizes
		.map(({ field, size }) => ({
			fieldKey: getField(field)?.key,
			size,
		}))
		.filter(({ fieldKey }) => !!fieldKey) as PolarisViewTableColumnSize[];

const transformFieldRollups = (
	fieldRollups: NonNullable<ViewsTypeGQL['fieldRollups']>,
	getField: GetFieldFunc,
): FieldRollup[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	fieldRollups
		.map(({ field, rollup }) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const rollupOperation = rollup as unknown as FieldRollupOperation;
			return {
				fieldKey: getField(field)?.key,
				rollup: rollupOperation,
			};
		})
		.filter(({ fieldKey }) => !!fieldKey) as FieldRollup[];

export const transformView = (gqlView: ViewsTypeGQL, fieldsByKey: FieldMap) => {
	const getField: GetFieldFunc = (field) => {
		if (!field?.key) {
			return undefined;
		}

		return fieldsByKey[field.key];
	};
	const resolvedGroupByKey = getField(gqlView.groupBy)?.key;

	const resolvedVerticalGroupByKey = getField(gqlView.verticalGroupBy)?.key;

	const resolvedFieldKeys: string[] = gqlView.fields
		.map(getField)
		.filter(Boolean)
		.map(({ key }) => key)
		.filter(Boolean);

	// defensive measure following past customer incidents as this would break a lot of functionnality
	// https://pi-dev-sandbox.atlassian.net/browse/POL-7518
	if (!resolvedFieldKeys.includes(SUMMARY_FIELDKEY)) {
		resolvedFieldKeys.unshift(SUMMARY_FIELDKEY);
	}

	const resolvedHiddenFieldKeys = (gqlView.hidden || [])
		.map(getField)
		.filter(Boolean)
		.map(({ key }) => key)
		.filter(Boolean);

	const resolvedSortBy = gqlView.sort
		? gqlView.sort
				.map((s) => ({
					asc: s.order === 'ASC',
					fieldKey: getField(s.field)?.key,
				}))
				.filter((s) => !!s.fieldKey)
		: undefined;

	const groupValues = transformGroupValues(gqlView.groupValues);
	const verticalGroupValues = transformGroupValues(gqlView.verticalGroupValues);
	const filter = transformFilter(gqlView.filter, getField);

	const viewId = toAri(gqlView.id);
	if (viewId === undefined) {
		throw new Error(`Expected ARI for viewId, got: '${gqlView.id}'`);
	}

	const description = transformDescription(gqlView.description);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return {
		viewId,
		viewLegacyId: gqlView.xid,
		kind: gqlView.visualizationType,
		title: gqlView.name,
		uuid: gqlView.uuid,
		emoji: gqlView.emoji,
		description,
		editable: !gqlView.immutable,
		filter,
		fields: resolvedFieldKeys,
		hidden: resolvedHiddenFieldKeys,
		fieldRollups: transformFieldRollups(gqlView.fieldRollups ?? [], getField),
		groupBy: resolvedGroupByKey,
		verticalGroupBy: resolvedVerticalGroupByKey,
		groupValues,
		verticalGroupValues,
		hideEmptyGroups: !!gqlView.hideEmptyGroups,
		hideEmptyColumns: !!gqlView.hideEmptyColumns,
		containsArchived: gqlView.containsArchived,
		rank: gqlView.rank,
		sortBy: resolvedSortBy,
		sortMode: extractSortMode(gqlView.sortMode, resolvedSortBy?.length ?? 0),
		viewSetId: gqlView.viewSetId,
		lastCommentsViewedTimestamp:
			gqlView.lastCommentsViewedTimestamp === null
				? undefined
				: getTime(new Date(gqlView.lastCommentsViewedTimestamp)),
		tableColumnSizes: transformTableColumnSizes(gqlView.tableColumnSizes ?? [], getField),
		matrixConfig: transformMatrixConfig(gqlView.matrixConfig, getField),
		timelineConfig: getTimelineConfig(gqlView, getField),
		isAutosaveEnabled: getIsAutosaveEnabled(gqlView),
		layoutType: gqlView.layoutType,
		createdAtTimestamp: gqlView.createdAt,
		updatedAtTimestamp: gqlView.updatedAt,
	} as View;
};

const transformViews = (views: readonly ViewsTypeGQL[], fieldsByKey: FieldMap) =>
	views
		.map((gqlView) => transformView(gqlView, fieldsByKey))
		// Sort array of views based on rank
		.sort(({ rank: rankA }, { rank: rankB }) => rankA - rankB);

export const transformViewSets = (
	gqViewSets: ReadonlyArray<ViewSetsTypeGQL>,
	fields: FieldMap,
	currentViewSlug: string | undefined,
	checkIsViewCollapsed: (view: View[], viewSlug: string | undefined) => boolean,
) =>
	gqViewSets.map((gqViewSet) => {
		const remoteViews = transformViews(gqViewSet.views, fields);

		// Transform views inside sections
		const transformedViewSets = gqViewSet.viewsets?.map((vSet) => {
			const transformedViews = transformViews(vSet.views, fields);
			const isCollapsed = checkIsViewCollapsed(transformedViews, currentViewSlug);
			return {
				...vSet,
				...{ views: transformedViews },
				...{ collapsed: isCollapsed },
			};
		});

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return {
			id: gqViewSet.id,
			name: gqViewSet.name,
			type: gqViewSet.type,
			views: remoteViews,
			viewSets: transformedViewSets,
			collapsed: false,
			rank: 0,
		} as ViewSet;
	});
