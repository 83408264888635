import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreApi } from '../../types';

export const setIsDownloading =
	(issueKey: IssueKey, isDownloading: boolean) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		setState({
			// @ts-expect-error - TS2322 - Type '{ [x: string]: { isDownloading: boolean; isFetching: boolean; error: Error | null; totalCount: number | undefined; deletableCount: number | undefined; visibleAttachments: Attachment[]; } | { ...; } | undefined; }' is not assignable to type 'Partial<Record<string, { isDownloading: boolean; isFetching: boolean; error: Error | null; totalCount: number | undefined; deletableCount: number | undefined; visibleAttachments: Attachment[]; } | undefined>>'.
			issueAttachments: {
				...oldState.issueAttachments,
				[issueKey]: {
					...oldState.issueAttachments[issueKey],
					isDownloading,
				},
			},
		});
	};
