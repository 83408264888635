import React, {
	createContext,
	useContext,
	useMemo,
	useState,
	type PropsWithChildren,
	type ReactElement,
} from 'react';
import merge from 'lodash/merge';
import type { EmojiDescription } from '@atlaskit/emoji';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { N0 } from '@atlaskit/theme/colors';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	ContextualAnalyticsData,
	MODAL,
	MountEvent,
	fireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { createUseUserProperty } from '@atlassian/jira-use-user-property';
import messages from './messages';

type SpotlightVariant = 'publishing' | 'unpublishing' | 'none';
type LoadedEmoji = undefined | null | EmojiDescription;

export type ViewEmojiSpotlightApi = {
	activateSpotlight: (options: { variant: Exclude<SpotlightVariant, 'none'> }) => void;
	deactivateSpotlight: () => void;
};

type SpotlightApiContextValue = ViewEmojiSpotlightApi;
const SpotlightApiContext = createContext<null | SpotlightApiContextValue>(null);
const SPOTLIGHT_ID = 'view-emoji-spotlight-for-public-sharing';
const analyticsSource = 'publicViewEmojiSpotlight';

const DEFAULT_EMOJI_ID = '';

export const emojis = {
	publicSharingOn: '1f310', // Globe emoji
	publicSharingOff: DEFAULT_EMOJI_ID, // Default emoji
} as const;

const useSpotlightMessages = (spotlightVariant: SpotlightVariant) => {
	const { formatMessage } = useIntl();

	switch (spotlightVariant) {
		case 'publishing':
			return {
				title: formatMessage(messages.spotlightTitle),
				content: formatMessage(messages.spotlightMessageForPublishing),
			};
		case 'unpublishing':
			return {
				title: formatMessage(messages.spotlightTitle),
				content: formatMessage(messages.spotlightMessageForUnpublishing),
			};
		case 'none':
		default:
			return {
				title: '',
				content: '',
			};
	}
};

const getExpectedEmoji = (spotlightVariant: SpotlightVariant) => {
	switch (spotlightVariant) {
		case 'publishing':
			return emojis.publicSharingOn;
		case 'unpublishing':
			return emojis.publicSharingOff;
		default:
			return null;
	}
};

const shouldDisplaySpotlight = (
	spotlightVariant: SpotlightVariant,
	loadedEmoji: LoadedEmoji,
	expectedEmoji: ReturnType<typeof getExpectedEmoji>,
) => {
	if (spotlightVariant === 'none') {
		return false;
	}

	// The initial emoji didn't load yet
	if (loadedEmoji === undefined) {
		return false;
	}

	// There is no emoji so we compare the default one with expected
	if (loadedEmoji === null) {
		return expectedEmoji === DEFAULT_EMOJI_ID;
	}

	return expectedEmoji === loadedEmoji.id;
};

const EmojiSpotlight = ({
	spotlightVariant,
	onAcknowledge,
}: {
	spotlightVariant: SpotlightVariant;
	onAcknowledge: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { title, content } = useSpotlightMessages(spotlightVariant);
	return (
		<ContextualAnalyticsData
			sourceName={analyticsSource}
			sourceType={MODAL}
			attributes={{ spotlightVariant }}
		>
			<JiraSpotlight
				actions={[
					{
						onClick: onAcknowledge,
						text: formatMessage(messages.spotlightAcknowledgement),
					},
				]}
				dialogPlacement="bottom left"
				heading={title}
				target={SPOTLIGHT_ID}
				key={SPOTLIGHT_ID}
				targetRadius={3}
				targetBgColor={N0}
				messageId="polaris-lib-control-sharing.controllers.view-emoji-spotlight-provider.jira-spotlight"
				messageType="transactional"
			>
				<MountEvent
					onMount={(event) => {
						fireScreenAnalytics(event);
						sendPendoTrackEvent({
							actionSubjectAndAction: `${analyticsSource}${MODAL} opened`,
						});
					}}
				/>
				{content}
			</JiraSpotlight>
		</ContextualAnalyticsData>
	);
};

const PUBLIC_VIEW_SPOTLIGHTS = 'polaris.public-view-spotlights';

export const usePublicViewSpotlights = createUseUserProperty<{
	hasSeenViewPublishingSpotlight?: boolean;
	hasSeenViewUnpublishingSpotlight?: boolean;
}>(PUBLIC_VIEW_SPOTLIGHTS);

const ViewEmojiSpotlightProviderNew = ({
	children,
}: {
	children: (props: { setLoadedEmoji: (emoji: EmojiDescription | null) => void }) => ReactElement;
}) => {
	const [spotlightVariant, setSpotlightVariant] = useState<SpotlightVariant>('none');
	const expectedEmoji = getExpectedEmoji(spotlightVariant);
	const [loadedEmoji, setLoadedEmoji] = useState<LoadedEmoji>(undefined);
	const [getPublicViewSpotlights, setPublicViewSpotlights] = usePublicViewSpotlights();
	const api = useMemo<ViewEmojiSpotlightApi>(
		() => ({
			activateSpotlight: async ({ variant }) => {
				const spotlights = await getPublicViewSpotlights();

				if (
					(variant === 'publishing' && !spotlights?.hasSeenViewPublishingSpotlight) ||
					(variant === 'unpublishing' && !spotlights?.hasSeenViewUnpublishingSpotlight)
				) {
					setSpotlightVariant(variant);
				}
			},
			deactivateSpotlight: async () => {
				const spotlights = await getPublicViewSpotlights();

				if (spotlightVariant === 'publishing' && !spotlights?.hasSeenViewPublishingSpotlight) {
					setPublicViewSpotlights(merge(spotlights, { hasSeenViewPublishingSpotlight: true }));
				} else if (
					spotlightVariant === 'unpublishing' &&
					!spotlights?.hasSeenViewUnpublishingSpotlight
				) {
					setPublicViewSpotlights(merge(spotlights, { hasSeenViewUnpublishingSpotlight: true }));
				}

				setSpotlightVariant('none');
			},
		}),
		[getPublicViewSpotlights, setPublicViewSpotlights, spotlightVariant],
	);

	return (
		<SpotlightManager>
			<SpotlightApiContext.Provider value={api}>
				{children({ setLoadedEmoji })}
			</SpotlightApiContext.Provider>
			<SpotlightTransition>
				{shouldDisplaySpotlight(spotlightVariant, loadedEmoji, expectedEmoji) && (
					<EmojiSpotlight
						spotlightVariant={spotlightVariant}
						onAcknowledge={api.deactivateSpotlight}
					/>
				)}
			</SpotlightTransition>
		</SpotlightManager>
	);
};

const ViewEmojiSpotlightProviderOld = ({
	children,
}: {
	children: (props: { setLoadedEmoji: (emoji: EmojiDescription | null) => void }) => ReactElement;
}) => {
	const [spotlightVariant, setSpotlightVariant] = useState<SpotlightVariant>('none');
	const expectedEmoji = getExpectedEmoji(spotlightVariant);
	const [loadedEmoji, setLoadedEmoji] = useState<LoadedEmoji>(undefined);
	const api = useMemo<ViewEmojiSpotlightApi>(
		() => ({
			activateSpotlight: ({ variant }) => setSpotlightVariant(variant),
			deactivateSpotlight: () => setSpotlightVariant('none'),
		}),
		[],
	);

	return (
		<SpotlightManager>
			<SpotlightApiContext.Provider value={api}>
				{children({ setLoadedEmoji })}
			</SpotlightApiContext.Provider>
			<SpotlightTransition>
				{shouldDisplaySpotlight(spotlightVariant, loadedEmoji, expectedEmoji) && (
					<EmojiSpotlight
						spotlightVariant={spotlightVariant}
						onAcknowledge={api.deactivateSpotlight}
					/>
				)}
			</SpotlightTransition>
		</SpotlightManager>
	);
};

export const ViewEmojiSpotlightProvider = componentWithFG(
	'polaris-persist-public-view-spotlights',
	ViewEmojiSpotlightProviderNew,
	ViewEmojiSpotlightProviderOld,
);

export const ViewEmojiSpotlightTarget = ({ children }: PropsWithChildren) => (
	<SpotlightTarget name={SPOTLIGHT_ID}>{children}</SpotlightTarget>
);

export const useViewEmojiSpotlightApi = () => {
	const value = useContext(SpotlightApiContext);

	if (value === null) {
		throw new Error('Missing ViewEmojiSpotlightProvider');
	}

	return value;
};
