import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const PULSE_ANIMATION_ID = 'polaris-pulse-animation';

export const startPulseAnimation = (target: HTMLElement | SVGElement) => {
	// Skipping if Element.animate() Web API is not supported in test environment (JSDOM doesn't support it)
	if (process.env.NODE_ENV !== 'production' && !('animate' in target)) {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}

	const baseShadow = `0 0 0 2px ${token('color.border.discovery', colors.P300)}`;
	const pulseStartKeyframe = {
		boxShadow: `${baseShadow}, 0 0 0 ${token('color.border.discovery', colors.P300)}`,
	};
	const pulseEndKeyframe = {
		boxShadow: `${baseShadow}, 0 0 0 10px rgba(101, 84, 192, 0.01)`,
	};

	const animation = target.animate(
		[pulseStartKeyframe, pulseStartKeyframe, pulseEndKeyframe, pulseEndKeyframe],
		{
			duration: 3000,
			easing: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
			iterations: Infinity,
			id: PULSE_ANIMATION_ID,
		},
	);

	const isPositionStatic = getComputedStyle(target).position === 'static';

	if (isPositionStatic) {
		Object.assign(target.style, { position: 'relative' });
	}

	return () => {
		if (isPositionStatic) {
			Object.assign(target.style, { position: 'static' });
		}
		animation.cancel();
	};
};
