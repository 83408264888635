import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

const QUERY = `
    query JiraGlobalIssueCreateModalLoad($project: JiraProjectInput) {
        jiraIssueCreateFields(project: $project) {
        ... on JiraIssueCreateFields {
            fields {
            edges {
                node {
                fieldId
                ... on JiraRichTextField {
                    renderer
                    mediaContext {
                    uploadToken {
                        ... on JiraMediaUploadToken {
                        endpointUrl
                        clientId
                        targetCollection
                        token
                        tokenDurationInMin
                        }
                    }
                    }
                }
                ... on JiraAttachmentsField {
                    maxAllowedTotalAttachmentsSize
                    mediaContext {
                    uploadToken {
                        ... on JiraMediaUploadToken {
                        endpointUrl
                        clientId
                        targetCollection
                        token
                        tokenDurationInMin
                        }
                    }
                    }
                }
                }
            }
            }
        }
        }
    }
`;

export const getIssueModalCreateConfig = (projectId: ProjectId) =>
	fetchJson('/rest/gira/1/', {
		method: 'POST',
		body: JSON.stringify({
			query: QUERY,
			variables: {
				project: {
					projectId,
				},
			},
		}),
	}).then((response) => response);
