import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	domainOptionLabel: {
		id: 'polaris-lib-user-picker.domain-option-label',
		defaultMessage: 'Domain',
		description: 'Dropdown item label',
	},
	peopleOptionLabel: {
		id: 'polaris-lib-user-picker.people-option-label',
		defaultMessage: 'People',
		description: 'Dropdown item label',
	},
	addButtonLabel: {
		id: 'polaris-lib-user-picker.add-button-label',
		defaultMessage: 'Add',
		description: 'Add button label',
	},
	inputPlaceholder: {
		id: 'polaris-lib-user-picker.input-placeholder',
		defaultMessage: 'Enter name, group, or email address',
		description: 'Search input placeholder',
	},
	inputWithoutEmailsPlaceholder: {
		id: 'polaris-lib-user-picker.input-without-emails-placeholder',
		defaultMessage: 'Enter name or group',
		description: 'Search input placeholder',
	},
	noOptions: {
		id: 'polaris-lib-user-picker.no-options',
		defaultMessage: 'No options',
		description: 'Dropdown message for no options',
	},
});
