import { loadSitePublicSharingConfig } from './private/load-site-public-sharing-config/index.tsx';
import { loadProjectAccessLevel, setProjectAccessLevel } from './project-access';
import { loadSharingSettings, updateSharingSettings } from './settings';
import { loadSharingSiteConfig } from './site-config';
import {
	addSharingStakeholders,
	loadSharingStakeholders,
	removeSharingStakeholders,
} from './stakeholders';
import { deletePublishedView } from './views';

export { loadSharingSettings, updateSharingSettings } from './settings';
export {
	addSharingStakeholders,
	loadSharingStakeholders,
	removeSharingStakeholders,
} from './stakeholders';
export { loadSharingSiteConfig } from './site-config';
export { setProjectAccessLevel, loadProjectAccessLevel } from './project-access';
export { deletePublishedView } from './views';

export const privateActions = {
	loadSitePublicSharingConfig,
} as const;

export const publicActions = {
	loadSharingSettings,
	updateSharingSettings,
	addSharingStakeholders,
	loadSharingStakeholders,
	removeSharingStakeholders,
	loadSharingSiteConfig,
	setProjectAccessLevel,
	loadProjectAccessLevel,
	deletePublishedView,
} as const;

export type Actions = typeof publicActions;
