import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { combineEpics, type ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import uuid from 'uuid';
import log from '@atlassian/jira-common-util-logging/src/log';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	fireTrackSavedEvent,
	fireOperationalSaveFailedEvent,
	fireTrackRemovedEvent,
	fireOperationalRemoveFailedEvent,
	fireTrackUpdateSucceedEvent,
	fireOperationalUpdateFailedEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/issue-panel';
import {
	isForgeExtension,
	toForgeKey,
	toForgeKeyFromExtensionId,
} from '@atlassian/jira-forge-ui-utils/src/utils/connect';
import { LimitError } from '@atlassian/jira-forge-ui-utils/src/utils/errors';
import {
	operationActionGenericError,
	issuePanelOperationActionLimitFailed,
} from '@atlassian/jira-forge-ui/src/common/ui/flags/issue-panel';
import type { PanelInstance } from '@atlassian/jira-issue-view-common-types/src/forge-types';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import {
	removeTimestamp,
	parseForgeIssuePanel,
} from '@atlassian/jira-issue-view-common-utils/src/ecosystem/module-key-helper';
import {
	addContentPanelSuccess,
	addContentPanelFailure,
	contentPanelSelected,
	removeContentPanelSuccess,
	removeContentPanelFailure,
	contentPanelsCustomisedSuccess,
	ADD_CONTENT_PANEL_REQUEST,
	ADD_CONTENT_PANEL_SUCCESS,
	REMOVE_CONTENT_PANEL_REQUEST,
	CONTENT_PANELS_CUSTOMISED_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/ecosystem-content-panel-actions';
import {
	FORGE_ISSUE_PANEL_UPDATE_REQUEST,
	forgeIssuePanelAdded,
	type forgeIssuePanelUpdateRequest,
	forgeIssuePanelUpdated,
	forgeIssuePanelRemoved,
} from '@atlassian/jira-issue-view-store/src/actions/forge-actions';
import {
	analyticsSourceSelector,
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector';
import {
	saveExtensionToIssue,
	removeExtensionFromIssue,
	saveContentPanelsCustomisedOnIssue,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extension-add-server';
import {
	ecosystemAllPanelsSelector,
	ecosystemAddedContentPanelsSelector,
	isIssueContentPanelCustomisedSelector,
	isIssueCreatedAfterEcosystemOnSelector,
	issueContentPanelsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector';
import {
	saveExtensionToIssue as saveForgeExtensionToIssue,
	removeExtensionFromIssue as removeForgeExtensionFromIssue,
	updateExtensionInIssue as updateForgeExtensionInIssue,
} from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extension-server';
import {
	forgePanelsSelector,
	getForgeIssuePanel,
} from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector';

const LOG_LOCATION = 'issue.ecosystem.ecosystem-content-panel-epic';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseState = (state: State, payload: any) => ({
	addonModuleKey: payload,
	baseUrl: baseUrlSelector(state),
	parentIssueKey: issueKeySelector(state),
});

const getPanelName = (state: State, forgePanelKey: string) => {
	const requestedPanel = getForgeIssuePanel(forgePanelKey)(state);
	if (requestedPanel) {
		return requestedPanel.name;
	}

	return undefined;
};

const saveContentPanelToIssue = (baseUrl: string, issueKey: string, addonModuleKey: string) => {
	const keyWithoutDigits = removeTimestamp(addonModuleKey, 10);
	return saveExtensionToIssue(baseUrl, issueKey, keyWithoutDigits)
		.map(() => addContentPanelSuccess(keyWithoutDigits))
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				LOG_LOCATION,
				`saveExtensionToIssue failed: ${error}`,
				error,
			);

			return Observable.of(addContentPanelFailure(error));
		});
};

const saveForgePanelToIssue = (
	// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'.
	baseUrl: BaseUrl,
	// @ts-expect-error - TS2304 - Cannot find name 'IssueKey'.
	issueKey: IssueKey,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	extensionId: any,
	allowMultiple: boolean,
	analyticsSource: undefined | string,
) => {
	const suggestedDate = Date.now();
	const panelInstanceId = uuid.v4().substring(0, 8);

	return saveForgeExtensionToIssue(
		baseUrl,
		issueKey,
		extensionId,
		suggestedDate,
		allowMultiple,
		panelInstanceId,
	).mergeMap((panels: PanelInstance[]) => {
		const panel = panels[panels.length - 1];
		const panelKey = toForgeKeyFromExtensionId(extensionId, panelInstanceId, panel.added);
		const observable = Observable.of(
			forgeIssuePanelAdded(panelKey),
			// @ts-expect-error - TS2769 - No overload matches this call.
			addContentPanelSuccess(panelKey),
		);

		fireTrackSavedEvent(analyticsSource);

		return observable;
	});
};

export const contentPanelAddRequestEpic = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(ADD_CONTENT_PANEL_REQUEST).flatMap((action) => {
		const { addonModuleKey, baseUrl, parentIssueKey } = parseState(
			store.getState(),
			action.payload,
		);

		const state = store.getState();
		const issueContentPanels = issueContentPanelsSelector(state);
		const forgePanels = forgePanelsSelector(state);
		const visibleContentPanels = ecosystemAddedContentPanelsSelector(state);
		const isCustomised = isIssueContentPanelCustomisedSelector(state);
		const isIssueCreatedBeforeEcosystemOn = !isIssueCreatedAfterEcosystemOnSelector(state);
		const analyticsSource = analyticsSourceSelector(state);

		if (isForgeExtension(action.payload)) {
			const panelToBeAdded = forgePanels.find(
				(panel) => toForgeKey(panel.appKey, panel.moduleKey) === action.payload,
			);
			const panelName = panelToBeAdded ? panelToBeAdded.name : undefined;
			const allowMultiple = !!panelToBeAdded?.allowMultiple;

			return saveForgePanelToIssue(
				baseUrl,
				parentIssueKey,
				addonModuleKey,
				allowMultiple,
				analyticsSource,
			).catch((error: Error) => {
				fireOperationalSaveFailedEvent(analyticsSource, { error });

				const { showFlag } = useFlagsService();
				if (error instanceof LimitError) {
					showFlag(issuePanelOperationActionLimitFailed(panelName));
				} else {
					showFlag(operationActionGenericError(panelName));
				}

				return Observable.of(addContentPanelFailure(error));
			});
		}

		// issue has never been customised for content panel.  So we need to add all currently visible panels to issue
		// And set it is customised.
		if (!isCustomised && isIssueCreatedBeforeEcosystemOn) {
			return Observable.merge(
				...visibleContentPanels
					.map((panel) => `${panel.appKey}_${panel.moduleKey}`)
					.filter((key) => !issueContentPanels.includes(key))
					.map((key) => saveContentPanelToIssue(baseUrl, parentIssueKey, key)),
			);
		}

		return saveContentPanelToIssue(baseUrl, parentIssueKey, addonModuleKey);
	});

const removeForgePanelFromIssue = (
	// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'.
	baseUrl: BaseUrl,
	// @ts-expect-error - TS2304 - Cannot find name 'IssueKey'.
	issueKey: IssueKey,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	panelKey: any,
	analyticsSource: undefined | string,
) => {
	const panelProperties = parseForgeIssuePanel(panelKey);

	if (panelProperties == null) {
		fireOperationalRemoveFailedEvent(analyticsSource, {
			error: Error(`Panel key doesn't match regexp: ${panelKey}`),
		});
		return Observable.of(removeContentPanelFailure());
	}

	const { extensionId } = panelProperties;
	const { id } = panelProperties;
	const date = panelProperties.added;

	return removeForgeExtensionFromIssue(baseUrl, issueKey, extensionId, date, id).mergeMap(() => {
		fireTrackRemovedEvent(analyticsSource);

		// @ts-expect-error - TS2769 - No overload matches this call.
		return Observable.of(removeContentPanelSuccess(panelKey), forgeIssuePanelRemoved(panelKey));
	});
};

export const contentPanelRemoveEpic = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(REMOVE_CONTENT_PANEL_REQUEST).flatMap((action) => {
		const { addonModuleKey, baseUrl, parentIssueKey } = parseState(
			store.getState(),
			action.payload,
		);

		const staticAddonModuleKey = removeTimestamp(addonModuleKey, 10);
		const state = store.getState();
		const issueContentPanels = issueContentPanelsSelector(state);
		const analyticsSource = analyticsSourceSelector(state);

		if (isForgeExtension(addonModuleKey)) {
			const panelName = getPanelName(state, action.payload);

			return removeForgePanelFromIssue(
				baseUrl,
				parentIssueKey,
				addonModuleKey,
				analyticsSource,
			).catch((error: Error) => {
				fireOperationalRemoveFailedEvent(analyticsSource, { error });

				const { showFlag } = useFlagsService();
				showFlag(operationActionGenericError(panelName));
				return Observable.of(removeContentPanelFailure(error));
			});
		}

		// If we are removing a legacy panel, we need to add other panels so they won't disappear with the current deleting one.
		if (
			!issueContentPanels.includes(addonModuleKey) &&
			!issueContentPanels.includes(staticAddonModuleKey)
		) {
			const availableAddons = ecosystemAllPanelsSelector(state);
			return Observable.merge(
				...availableAddons
					.map((panel) => `${panel.appKey}_${panel.moduleKey}`)
					.filter((panelKey) => panelKey !== addonModuleKey)
					.map((panelKey) => saveContentPanelToIssue(baseUrl, parentIssueKey, panelKey)),
			);
		}

		// find the issueContentPanels key used to remembered selection as the key for delete
		const rememberedKey = issueContentPanels.includes(addonModuleKey)
			? addonModuleKey
			: staticAddonModuleKey;

		return removeExtensionFromIssue(baseUrl, parentIssueKey, rememberedKey)
			.map(() => removeContentPanelSuccess(rememberedKey))
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					LOG_LOCATION,
					`removeExtensionFromIssue failed: ${error}`,
					error,
				);
				return Observable.of(removeContentPanelFailure(error));
			});
	});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const contentPanelSavedEpic = (action$: ActionsObservable<any>) =>
	action$.ofType(ADD_CONTENT_PANEL_SUCCESS).switchMap((action) => {
		const addonModuleKey = action.payload;
		return Observable.of(contentPanelSelected(addonModuleKey));
	});

export const saveIssueContentPanelsCustomisedEpic = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(ADD_CONTENT_PANEL_SUCCESS, CONTENT_PANELS_CUSTOMISED_REQUEST).flatMap((action) => {
		const { baseUrl, parentIssueKey } = parseState(store.getState(), action.payload);

		const customised = isIssueContentPanelCustomisedSelector(store.getState());
		if (customised) {
			return Observable.empty<never>();
		}

		return saveContentPanelsCustomisedOnIssue(baseUrl, parentIssueKey)
			.map(() => contentPanelsCustomisedSuccess())
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					LOG_LOCATION,
					`saveIssueCustomised failed: ${error}`,
					error,
				);
				return Observable.empty<never>();
			});
	});

export const updateForgeExtensionInIssueEpic = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(FORGE_ISSUE_PANEL_UPDATE_REQUEST)
		.flatMap((action: ReturnType<typeof forgeIssuePanelUpdateRequest>) => {
			const baseUrl = baseUrlSelector(store.getState());
			const analyticsSource = analyticsSourceSelector(store.getState());

			const { issueKey, panelKey, collapsed } = action.payload;

			const panelProperties = parseForgeIssuePanel(panelKey);

			if (panelProperties == null) {
				fireOperationalUpdateFailedEvent(analyticsSource, {
					error: Error(`Panel key doesn't match regexp: ${panelKey}`),
				});

				return Observable.empty<never>();
			}

			const issueParams = {
				...panelProperties,
				issueKey,
			};

			return updateForgeExtensionInIssue(baseUrl, issueParams, collapsed)
				.map(() => {
					fireTrackUpdateSucceedEvent(analyticsSource);

					return forgeIssuePanelUpdated({ panelKey, collapsed });
				})
				.catch((error: Error) => {
					fireOperationalUpdateFailedEvent(analyticsSource, { error });

					const state = store.getState();
					const panelName = getPanelName(state, panelKey);
					const { showFlag } = useFlagsService();
					showFlag(operationActionGenericError(panelName));
					return Observable.empty<never>();
				});
		});

export default combineEpics(
	contentPanelAddRequestEpic,
	contentPanelSavedEpic,
	contentPanelRemoveEpic,
	saveIssueContentPanelsCustomisedEpic,
	updateForgeExtensionInIssueEpic,
);
