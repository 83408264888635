import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { PROJECT_FIELDS_PAGE_VIEWED } from '../../../constants';
import type { Props, State } from '../../../types';

export const loadProjectFieldsPageViewed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		// User property is already loaded
		if (typeof getState().projectFields.pageViewed === 'boolean') {
			return;
		}

		try {
			const pageViewed = await getUserProperty<boolean>(accountId, PROJECT_FIELDS_PAGE_VIEWED);

			const state = getState();

			setState({
				...state,
				projectFields: {
					...state.projectFields,
					pageViewed,
				},
			});
		} catch (error) {
			// Expected error, the property is not set yet
			if (error instanceof FetchError && error.statusCode === 404) {
				const state = getState();

				setState({
					...state,
					projectFields: {
						...state.projectFields,
						pageViewed: false,
					},
				});

				return;
			}

			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.project-fields.page-viewed',
				`Failed to get user property with key: ${PROJECT_FIELDS_PAGE_VIEWED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};

export const setProjectFieldsPageViewed =
	(): Action<State, Props> =>
	async ({ getState, setState }, { accountId }) => {
		if (!accountId) {
			return;
		}

		const state = getState();

		setState({
			...state,
			projectFields: {
				...state.projectFields,
				pageViewed: true,
			},
		});

		try {
			await setUserProperties(accountId, PROJECT_FIELDS_PAGE_VIEWED, 'true');
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'polaris.onboarding.project-fields.page-viewed',
				`Failed to set user property with key: ${PROJECT_FIELDS_PAGE_VIEWED}`,
				error instanceof Error ? error : undefined,
			);
		}
	};
