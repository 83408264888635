import type { Ari } from '@atlassian/jira-platform-ari';

export const FIELD_TEMPLATE = 'field';

/**
 * Formula for extracting the value from a field.
 * This supports min-max normalization which yields values in the interval [0, 1]. Note that for fields with a
 * defined numeric range the limits of this range are used, whereas for unlimited domains the range is defined
 * by the minimum and maximum value of the stored issues.
 */
export type FieldFormula = {
	template: typeof FIELD_TEMPLATE;
	parameters: {
		id: Ari;
		normalize?: boolean;
	};
};
