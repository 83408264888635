import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noPermissions: {
		id: 'polaris-ideas.view-header.export-dialog.export-dialog-content.no-permissions',
		defaultMessage: "You don't have permission to export a view.",
		description: 'Warning when trying to export without permissions',
	},
	emptyView: {
		id: 'polaris-ideas.view-header.export-dialog.export-dialog-content.empty-view',
		defaultMessage: "You can't export a view without ideas in it.",
		description: 'Warning when trying to export an empty view',
	},
});
