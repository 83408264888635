import { createSelector } from 'reselect';
import { getTime } from 'date-fns';
import { getCurrentUser, getCurrentViewLastCommentsViewedTimestamp } from './view';
import { getCurrentView } from './view/current/index.tsx';

export const getCurrentViewComments = createSelector(getCurrentView, (view) =>
	view ? view.comments || [] : undefined,
);

export const getCurrentViewCommentsNewToCurrentUser = createSelector(
	getCurrentViewComments,
	getCurrentUser,
	getCurrentViewLastCommentsViewedTimestamp,
	(comments, currentUser, lastCommentsViewedTimestamp) => {
		if (comments === undefined) {
			return undefined;
		}

		return comments.filter(
			(comment) =>
				comment.author.accountId !== currentUser &&
				(lastCommentsViewedTimestamp === undefined ||
					getTime(new Date(comment.created)) > lastCommentsViewedTimestamp),
		);
	},
);

export const isCurrentViewHavingUnseenComments = createSelector(
	getCurrentViewCommentsNewToCurrentUser,
	(comments) => {
		if (comments === undefined) {
			return false;
		}

		return comments.length > 0;
	},
);

export const getCurrentViewCommentsLoaded = createSelector(
	getCurrentView,
	(view) => view?.commentsLoaded === true,
);
