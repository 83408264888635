import { useCallback, useEffect, useRef, useState } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import type { MediaContext as CommonMediaContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import {
	isNotFoundError,
	isPermissionError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages';
import type {
	MediaContextSupplierProps,
	MediaContext,
	IdeaCreateMediaContextSupplierProps,
} from './types';
import { getShortestLifespan } from './utils';

export const MediaContextSupplier = ({
	issueKey,
	children,
	getMediaContext,
	isSharedView,
}: MediaContextSupplierProps) => {
	const tokenRefreshTimeout = useRef<ReturnType<typeof setTimeout>>();
	const [data, setData] = useState<{
		context?: MediaContext;
		issueKey?: IssueKey;
		loading: boolean;
	}>({
		context: undefined,
		issueKey: undefined,
		loading: false,
	});
	const { error, customFlag } = useNotifications();
	const { formatMessage } = useIntl();

	const clearRefreshTimeout = useCallback(() => {
		clearTimeout(tokenRefreshTimeout.current);
	}, []);

	const fetchAndEmitContext = useCallback(
		(issueKeyForFetch: IssueKey) => {
			clearRefreshTimeout();
			setData((prev) => ({
				context: prev.context,
				issueKey: issueKeyForFetch,
				loading: true,
			}));
			getMediaContext({ issueKey: issueKeyForFetch })
				.then((response: MediaContext) => {
					setData({
						context: response,
						issueKey: issueKeyForFetch,
						loading: false,
					});
					const shortestLifespan = getShortestLifespan(response.mediaContext);
					tokenRefreshTimeout.current = setTimeout(
						() => fetchAndEmitContext(issueKeyForFetch),
						shortestLifespan - 1000 * 60,
					);
				})
				.catch((customError) => {
					if (
						ff('polaris.publish-view-custom-error-flag') &&
						isSharedView &&
						(isNotFoundError(customError) || isPermissionError(customError))
					) {
						// In published views a 404 / 403 response means that the view has been unpublished. We want to show a different message
						customFlag({
							title: formatMessage(messages.publishMediaContextFetchError),
							description: formatMessage(messages.publishMediaContextFetchErrorDescription),
						});
					} else {
						error({
							title: formatMessage(messages.mediaContextFetchError),
							description: formatMessage(messages.mediaContextFetchErrorDescription),
						});
					}

					setData({
						loading: false,
						issueKey: issueKeyForFetch,
					});
				});
		},
		[clearRefreshTimeout, error, customFlag, getMediaContext, formatMessage, isSharedView],
	);

	useEffect(() => {
		fetchAndEmitContext(issueKey);
		return clearRefreshTimeout;
	}, [clearRefreshTimeout, fetchAndEmitContext, issueKey]);

	return children(data.context, data.issueKey, data.loading);
};

export const IdeaCreateMediaContextSupplier = ({
	children,
	getMediaContext,
	projectId,
}: IdeaCreateMediaContextSupplierProps) => {
	const tokenRefreshTimeout = useRef<ReturnType<typeof setTimeout>>();
	const [data, setData] = useState<{
		context?: CommonMediaContext;
		projectId: string;
		loading: boolean;
	}>({
		context: undefined,
		projectId,
		loading: false,
	});
	const { error } = useNotifications();
	const { formatMessage } = useIntl();

	const clearRefreshTimeout = useCallback(() => {
		clearTimeout(tokenRefreshTimeout.current);
	}, []);

	const fetchAndEmitContext = useCallback(
		(projectIdForFetch: string) => {
			clearRefreshTimeout();
			setData((prev) => ({
				context: prev.context,
				projectId: projectIdForFetch,
				loading: true,
			}));

			getMediaContext({ projectId: projectIdForFetch })
				.then((response: CommonMediaContext) => {
					setData({
						context: response,
						projectId: projectIdForFetch,
						loading: false,
					});

					const shortestLifespan = getShortestLifespan(response);
					tokenRefreshTimeout.current = setTimeout(
						() => fetchAndEmitContext(projectIdForFetch),
						shortestLifespan - 1000 * 60,
					);
				})
				.catch(() => {
					error({
						title: formatMessage(messages.mediaContextFetchError),
						description: formatMessage(messages.mediaContextFetchErrorDescription),
					});

					setData({
						loading: false,
						projectId: projectIdForFetch,
					});
				});
		},
		[clearRefreshTimeout, error, getMediaContext, formatMessage],
	);

	useEffect(() => {
		fetchAndEmitContext(projectId);
		return clearRefreshTimeout;
	}, [clearRefreshTimeout, fetchAndEmitContext, projectId]);

	return children(data.context, data.projectId, data.loading);
};
