import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleComponent = styled.div({
	marginTop: token('space.200', '16px'),
});

export const Title = (props: ComponentPropsWithoutRef<typeof TitleComponent>) => (
	<Heading size="xsmall">
		<TitleComponent {...props} />
	</Heading>
);
