import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { JiraSearchApiResponse, BatchLoadedFn, EndpointParam } from './types';

const getIssueLoadingParallelRequests = (): number =>
	getFeatureFlagValue<number>('polaris.issue-loading-parallel-requests', 5);

const getPolarisInternalSearchEndpointBatchSize = () =>
	getFeatureFlagValue('polaris.internal-issue-search-batchsize', 100);

const defaultBatchSize = 100;

const POLARIS_URL = '/rest/internal/2/polaris/issues/search';
const JIRA_URL = '/rest/api/3/search';

const isPolarisSearchEndpoint = (endpointParam: EndpointParam): boolean =>
	'projectId' in endpointParam;

const getSearchEntpoindUrl = (endpointParam: EndpointParam): string => {
	if (isPolarisSearchEndpoint(endpointParam)) {
		return POLARIS_URL;
	}
	return JIRA_URL;
};

const getBatchSize = (endpointParam: EndpointParam): number => {
	if (isPolarisSearchEndpoint(endpointParam)) {
		return getPolarisInternalSearchEndpointBatchSize();
	}

	return defaultBatchSize;
};

const makeSearchBody = (
	endpointParam: EndpointParam,
	fields: string[] | null,
	expand: string[],
	batch: number,
): string => {
	const batchSize = getBatchSize(endpointParam);
	return 'projectId' in endpointParam
		? JSON.stringify({
				projectId: endpointParam.projectId,
				fields,
				startAt: batch * batchSize,
				maxResults: batchSize,
			})
		: JSON.stringify({
				jql: endpointParam.jql,
				fields,
				expand,
				startAt: batch * batchSize,
				maxResults: batchSize,
				validateQuery: 'strict',
			});
};

const createInitialSearchBody = (endpointParam: EndpointParam): string =>
	'projectId' in endpointParam
		? JSON.stringify({
				projectId: endpointParam.projectId,
				fields: null,
				startAt: 0,
				maxResults: 0,
			})
		: JSON.stringify({
				jql: endpointParam.jql,
				fields: [],
				expand: [],
				startAt: 0,
				maxResults: 0,
				validateQuery: 'strict',
			});

const fetchIssuesCountAndFields = (endpointParam: EndpointParam) =>
	fetchJson(getSearchEntpoindUrl(endpointParam), {
		method: 'POST',
		body: createInitialSearchBody(endpointParam),
	}).then((response) => ({
		count: response.total,
		...(isPolarisSearchEndpoint(endpointParam) ? { fields: response.fieldKeys } : {}),
	}));

const fetchBatch = (
	endpointParam: EndpointParam,
	fields: string[] | null,
	expand: string[],
	batch: number,
) =>
	fetchJson(getSearchEntpoindUrl(endpointParam), {
		method: 'POST',
		body: makeSearchBody(endpointParam, fields, expand, batch),
	});

export const fetchIssueIssueBatches = (
	endpointParam: EndpointParam,
	fields?: string[],
	expand?: string[],
	onBatchLoaded?: BatchLoadedFn,
	issueLimit?: number,
): Promise<JiraSearchApiResponse> => {
	const batchSize = getBatchSize(endpointParam);

	const fetchStart = new Date();
	const firstBatch = fetchBatch(endpointParam, fields ?? null, expand ?? [], 0);

	return fetchIssuesCountAndFields(endpointParam).then(
		({ count: numberOfIssues, fields: jiraFields }) => {
			const fieldsToLoad = fields ?? jiraFields;
			const requestCount = Math.min(
				Math.ceil(numberOfIssues / batchSize),
				issueLimit !== undefined ? Math.ceil(issueLimit / batchSize) : Number.MAX_SAFE_INTEGER,
			);
			// We already fetched the first 100 issues with "firstBatch"
			let count = 1;
			let aggregatedResponse: JiraSearchApiResponse = {
				issues: [],
				total: numberOfIssues,
			};
			const processLoadedBatch = (batchIndex: number) => (result: JiraSearchApiResponse) => {
				aggregatedResponse = {
					issues: [...aggregatedResponse.issues, ...result.issues],
					total: aggregatedResponse.total,
				};
				onBatchLoaded &&
					onBatchLoaded(batchSize, batchIndex, new Date().getTime() - fetchStart.getTime());
			};
			const createRecursiveFetch = (): Promise<void> => {
				if (count >= requestCount) {
					return Promise.resolve();
				}
				const batchIndex = count;
				count += 1;
				return fetchBatch(endpointParam, fieldsToLoad, expand ?? [], batchIndex)
					.then(processLoadedBatch(batchIndex))
					.then(createRecursiveFetch);
			};

			return Promise.all([
				firstBatch.then(processLoadedBatch(0)),
				...[...Array(getIssueLoadingParallelRequests()).keys()].map(createRecursiveFetch),
			]).then(() => aggregatedResponse);
		},
	);
};
