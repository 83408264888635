import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	turnOnAutoFormattingLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.formatting-action.turn-on-auto-formatting-label',
		defaultMessage: 'Turn on auto formatting',
		description:
			'Label for dropdown menu action that will turn on automatic color assignment for newly created field options',
	},
	turnOffAutoFormattingLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.formatting-action.turn-off-auto-formatting-label',
		defaultMessage: 'Turn off auto formatting',
		description:
			'Label for dropdown menu action that will turn off automatic color assignment for newly created field options',
	},
	clearFormattingForAllOptionsLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.formatting-action.clear-formatting-for-all-options-label',
		defaultMessage: 'Clear formatting for all options',
		description:
			'Label for dorpdown menu action that will clear all formatting settings for all field options',
	},
});
