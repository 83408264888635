import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	ideaFiltered: {
		id: 'polaris-lib-list.overlay.moved-row.idea-filtered',
		defaultMessage: 'Show filtered idea',
		description: '',
	},
	ideaMoved: {
		id: 'polaris-lib-list.overlay.moved-row.idea-moved',
		defaultMessage: 'Go to the idea',
		description: '',
	},
});
