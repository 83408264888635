import React, { useMemo, type ReactNode } from 'react';
import Badge from '@atlaskit/badge';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueViewSection } from '@atlassian/jira-polaris-common/src/common/types/issue/index.tsx';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	useIssueCommentCount,
	useUnseenCommentsAvailableForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/comments/hooks';
import { useLinkedDeliveryIssuesCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import {
	useUnseenInsightsAvailableForIssue,
	useInsightsCountForIssue,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/insights/hooks';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { useInsightsCount } from '../../../controllers/insights/selectors/insights-hooks';
import { messages } from './messages';
import { BadgeContainer, MessagesTabWrapper, MessageContainer } from './styled';

export type IssueViewTab = {
	name: IssueViewSection;
	label: ReactNode;
	content: null;
	testId: string;
};

export const useTabs = ({
	localIssueId,
	isSharedView,
	sharingOptions,
}: {
	localIssueId: LocalIssueId;
	isSharedView: boolean;
	sharingOptions?: SharingSettings;
}): IssueViewTab[] => {
	const { formatMessage } = useIntl();
	const isCollectionView = useIsCollectionView();
	const commentCount = useIssueCommentCount(localIssueId);
	// disabling for collection view for now: https://pi-dev-sandbox.atlassian.net/browse/POL-10557?focusedCommentId=729350
	const hasUnseenComment = useUnseenCommentsAvailableForIssue(localIssueId) && !isCollectionView;
	// used on project view
	const insightsCountForIssue = useInsightsCountForIssue(localIssueId);
	// disabling for collection view for now: https://pi-dev-sandbox.atlassian.net/browse/POL-10863?focusedCommentId=729349
	const hasUnreadInsights = useUnseenInsightsAvailableForIssue(localIssueId) && !isCollectionView;
	const linkedLinkedIssuesCount = useLinkedDeliveryIssuesCount(localIssueId);
	// used on collection view
	const insightsCount = useInsightsCount() || insightsCountForIssue;

	return useMemo(
		() =>
			[
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'comments' as IssueViewSection,
					label: (
						<MessagesTabWrapper>
							<MessageContainer>{formatMessage(messages.comments)}</MessageContainer>
							{(commentCount || 0) > 0 && (
								<BadgeContainer>
									<Badge appearance={hasUnseenComment ? 'primary' : 'default'}>
										{commentCount}
									</Badge>
								</BadgeContainer>
							)}
						</MessagesTabWrapper>
					),
					content: null,
					testId: 'polaris.idea.src.ui.sections.tabs.comments',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'capture' as IssueViewSection,
					label: (
						<MessagesTabWrapper>
							<MessageContainer>{formatMessage(messages.insights)}</MessageContainer>
							{(insightsCount || 0) > 0 && (
								<BadgeContainer>
									<Badge appearance={hasUnreadInsights ? 'primary' : 'default'}>
										{insightsCount}
									</Badge>
								</BadgeContainer>
							)}
						</MessagesTabWrapper>
					),
					content: null,
					testId: 'polaris.idea.src.ui.sections.tabs.capture',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'deliver' as IssueViewSection,
					label: (
						<MessagesTabWrapper>
							<MessageContainer>{formatMessage(messages.deliver)}</MessageContainer>
							{linkedLinkedIssuesCount > 0 && (
								<BadgeContainer>
									<Badge appearance="default">{linkedLinkedIssuesCount}</Badge>
								</BadgeContainer>
							)}
						</MessagesTabWrapper>
					),
					content: null,
					testId: 'polaris.idea.src.ui.sections.tabs.deliver',
				},
				{
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					name: 'history' as IssueViewSection,
					label: <>{formatMessage(messages.history)}</>,
					content: null,
					testId: 'polaris.idea.src.ui.tab-view.history',
				},
			].filter((tab) => {
				if (isSharedView) {
					if (tab.name === 'comments') {
						return sharingOptions?.showIdeaComments;
					}
					if (tab.name === 'capture') {
						return sharingOptions?.showIdeaInsights;
					}
					return false;
				}
				return true;
			}),
		[
			formatMessage,
			commentCount,
			hasUnseenComment,
			insightsCount,
			hasUnreadInsights,
			linkedLinkedIssuesCount,
			isSharedView,
			sharingOptions?.showIdeaComments,
			sharingOptions?.showIdeaInsights,
		],
	);
};
