import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { Checkbox } from '@atlaskit/checkbox';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectOptionComponentProps = any;

export const Option = ({
	data,
	innerProps,
	innerRef,
	isFocused,
	isSelected,
	isMulti,
	renderer: Renderer,
}: SelectOptionComponentProps) => {
	const { onClick, ...innerPropsRest } = innerProps;
	const { id } = data;

	return (
		<Wrapper>
			<OptionContentContainer isMulti={isMulti} isFocused={isFocused} {...innerPropsRest}>
				<OptionWrapper>
					{isMulti ? (
						<CheckboxWrapper>
							<Checkbox
								onChange={onClick}
								ref={innerRef}
								isChecked={isSelected}
								label={
									<OptionContent
										onClick={(evt: SyntheticEvent) => {
											evt.preventDefault();
											evt.stopPropagation();
											onClick(evt);
										}}
									>
										<Renderer id={id} />
									</OptionContent>
								}
							/>
						</CheckboxWrapper>
					) : (
						<OptionContent onClick={onClick}>
							<Renderer id={id} />
						</OptionContent>
					)}
				</OptionWrapper>
			</OptionContentContainer>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContentContainer = styled.div<{ isMulti: boolean; isFocused: boolean }>({
	width: '100%',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isMulti }) =>
		isMulti
			? `0 ${token('space.100', '8px')} 0 ${token('space.100', '8px')}`
			: `0 ${token('space.100', '8px')} 0 ${token('space.150', '12px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isFocused }) =>
		isFocused ? token('color.background.neutral.subtle.hovered', N20A) : 'transparent',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	width: '100%',
	margin: `${token('space.050', '4px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionWrapper = styled.div({
	height: '32px',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& label > svg': {
		alignSelf: 'center',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionContent = styled.div({
	flex: '1 1 auto',
	cursor: 'pointer',
});
