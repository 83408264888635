import { useEffect, useRef, useCallback } from 'react';
import type { MediaContext, ViewContext } from '../../common/types';
import { PromiseProvider, type Status } from '../../common/utils';
import { isTokenValid } from '../create-media-provider/utils';

type Token = string;
type Collection = string;

export type TokenProvider = (arg1: Collection | undefined) => Promise<Token>;

export type WithTokenProviderProps = {
	onViewRefresh?: () => void;
	onUploadRefresh?: () => void;
};

export type WithMediaContextProps = {
	mediaContext: MediaContext;
};

export const getValidMediaContextReadToken = (mediaContext: MediaContext): string | null => {
	const { viewContext } = mediaContext;
	if (!viewContext) return null;
	if (!viewContext.areTokenPermissionsUpToDate) return null;
	if (typeof viewContext.token !== 'string' || viewContext.token === '') return null;

	return viewContext.token;
};

export function shouldRefreshToken(
	tokenPromiseProvider: {
		status: Status;
	},
	tokenContext: ViewContext | null,
) {
	if (tokenPromiseProvider.status !== 'resolved') {
		return false;
	}

	if (tokenContext === null) {
		return false;
	}

	if (isTokenValid(tokenContext)) {
		return false;
	}

	return true;
}

/**
 * Get a tokenProvider:promise function
 * @returns async function to get upload/view token from the store for the media components to use.
 * @see https://atlaskit.atlassian.com/packages/media/media-picker#auth-provider
 */
export function useTokenProvider(
	mediaContext: MediaContext,
	onViewRefresh?: () => void,
	onUploadRefresh?: () => void,
): TokenProvider {
	const viewTokenPromiseProvider = useRef(new PromiseProvider<string>());
	const uploadTokenPromiseProvider = useRef(new PromiseProvider<string>());

	/**
	 * @param collection - Truthy when requesting an upload token. Falsy on view.
	 */
	const tokenProvider: TokenProvider = useCallback(
		async (collection?: string) => {
			const shouldReturnReadToken = !collection;

			// READ TOKEN
			if (shouldReturnReadToken) {
				const shouldRefresh =
					!!onViewRefresh &&
					shouldRefreshToken(
						{ status: viewTokenPromiseProvider.current.status },
						mediaContext.viewContext,
					);

				if (shouldRefresh) {
					// reset our promise & force a token refresh
					viewTokenPromiseProvider.current.resetIfSettled();
					onViewRefresh();
				}

				// if token exists, resolve it. Otherwise wait for aforementioned refresh.
				return viewTokenPromiseProvider.current.get();
			}

			// UPLOAD TOKEN
			// TODO BENTO-12541: this doesn't yet check for token validity like the upload token does.
			const uploadToken = mediaContext.uploadContext?.token;
			if (!uploadToken) {
				if (onUploadRefresh) {
					onUploadRefresh();
				}

				throw new Error('Upload token not available');
			}

			return uploadTokenPromiseProvider.current.get();
		},
		[mediaContext.uploadContext?.token, mediaContext.viewContext, onUploadRefresh, onViewRefresh],
	);

	useEffect(() => {
		const readToken = getValidMediaContextReadToken(mediaContext);
		if (readToken === null) {
			viewTokenPromiseProvider.current.resetIfSettled();
		} else {
			viewTokenPromiseProvider.current.resolve(readToken);
		}

		const uploadToken = mediaContext.uploadContext?.token;
		if (uploadToken) {
			uploadTokenPromiseProvider.current.resolve(uploadToken);
		} else {
			uploadTokenPromiseProvider.current.resetIfSettled();
		}
	}, [mediaContext]);

	return tokenProvider;
}
