const VIEW_CREATE_POPUP_TYPE_BUTTON = 'BUTTON';
const VIEW_CREATE_POPUP_TYPE_SIDEBAR_HEADER = 'SIDEBAR_HEADER';
const VIEW_CREATE_POPUP_TYPE_SIDEBAR_SECTION_ITEM = 'SIDEBAR_SECTION_ITEM';
const VIEW_CREATE_POPUP_TYPE_SIDEBAR_SUB_SECTION_ITEM = 'SIDEBAR_SUB_SECTION_ITEM';

export type ViewCreatePopupType =
	| typeof VIEW_CREATE_POPUP_TYPE_BUTTON
	| typeof VIEW_CREATE_POPUP_TYPE_SIDEBAR_HEADER
	| typeof VIEW_CREATE_POPUP_TYPE_SIDEBAR_SECTION_ITEM
	| typeof VIEW_CREATE_POPUP_TYPE_SIDEBAR_SUB_SECTION_ITEM;

type ViewCreatePopupTypeMap = {
	BUTTON: typeof VIEW_CREATE_POPUP_TYPE_BUTTON;
	SIDEBAR_HEADER: typeof VIEW_CREATE_POPUP_TYPE_SIDEBAR_HEADER;
	SIDEBAR_SECTION_ITEM: typeof VIEW_CREATE_POPUP_TYPE_SIDEBAR_SECTION_ITEM;
	SIDEBAR_SUB_SECTION_ITEM: typeof VIEW_CREATE_POPUP_TYPE_SIDEBAR_SUB_SECTION_ITEM;
};

export const VIEW_CREATE_POPUP_TYPES: ViewCreatePopupTypeMap = {
	BUTTON: VIEW_CREATE_POPUP_TYPE_BUTTON,
	SIDEBAR_HEADER: VIEW_CREATE_POPUP_TYPE_SIDEBAR_HEADER,
	SIDEBAR_SECTION_ITEM: VIEW_CREATE_POPUP_TYPE_SIDEBAR_SECTION_ITEM,
	SIDEBAR_SUB_SECTION_ITEM: VIEW_CREATE_POPUP_TYPE_SIDEBAR_SUB_SECTION_ITEM,
};
