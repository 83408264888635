import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fetchViewLastViewed } from '../../../services/polaris-api/fetch-view-last-viewed';
import { updateViewLastViewed } from '../../../services/polaris-api/update-view-last-viewed';
import type { ViewLastViewedRemote } from '../types';

export const createPolarisApiViewLastViewedRemote = (
	apolloClient: PolarisApolloClient,
): ViewLastViewedRemote => ({
	fetchViewLastViewed: (viewId) => fetchViewLastViewed(apolloClient, viewId),

	updateViewLastViewed: ({ viewId, lastViewed }) =>
		updateViewLastViewed(apolloClient, viewId, lastViewed),
});
