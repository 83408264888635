import React, { memo } from 'react';
import { ButtonItem, type CSSFn } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fieldTypeNameForPolarisFieldType } from '@atlassian/jira-polaris-component-field-configuration/src/common/utils/field-type-name/index.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { messages as commonMessages } from '../../../../../common/messages';
import { FieldTitleWithIcon } from '../../../../../common/ui/field-title-with-icon';
import { TooltipComponent } from '../../../../../common/ui/rollup-tooltip';
import {
	RollupItemFormulaHelpTextComponent,
	RollupItemFormulaTypeComponent,
} from '../../../../../common/ui/rollup/field-help-text';
import { RollupItemFieldIconComponent } from '../../../../../common/ui/rollup/field-icon';
import {
	useFieldEmoji,
	useFieldType,
	useFieldTypeIcon,
	useHasDefaultFieldOptionWeights,
} from '../../../../../controllers/selectors/formula-hooks';
import { messages } from './messages';
import type { MultiselectOptionProps, MultiselectOptionsProps } from './types';

const cssFn: CSSFn = (currentStyles) => ({
	...currentStyles,
	padding: `${token('space.050', '4px')} ${token('space.200', '16px')}`,
});

const MultiselectOption = memo<MultiselectOptionProps>(
	({ field, onClick }: MultiselectOptionProps) => {
		const { formatMessage } = useIntl();

		const hasDefaultFieldOptionWeights = useHasDefaultFieldOptionWeights(field.key);

		const emojiId = useFieldEmoji(field.key);
		const emoji = useEmoji(emojiId);
		const icon = useFieldTypeIcon(field.key);

		const fieldType = useFieldType(field.key);
		const fieldTypeName = fieldTypeNameForPolarisFieldType(fieldType);
		const fieldTypeNameSuffix = formatMessage(commonMessages.fieldTypeLabelSuffix);
		const title = `${fieldTypeName ? formatMessage(fieldTypeName) : ''} ${fieldTypeNameSuffix}`;

		const fieldTypeIcon = useFieldTypeIcon(field.key);

		return (
			<TooltipComponent
				header={<FieldTitleWithIcon emoji={emoji} icon={icon} title={title} />}
				content={
					<RollupItemFormulaHelpTextComponent
						fieldKey={field.key}
						fieldType={field.type}
						helpText={formatMessage(messages.countMultiSelectTypeHelpText)}
						hasDefaultFieldOptionWeights={hasDefaultFieldOptionWeights}
					/>
				}
			>
				<ButtonItem
					// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
					cssFn={cssFn}
					testId="polaris-component-field-configuration.ui.configuration.formula.rollup.multi-select-options.field-option"
					iconBefore={<RollupItemFieldIconComponent emoji={emoji} fieldTypeIcon={fieldTypeIcon} />}
					iconAfter={
						<RollupItemFormulaTypeComponent
							fieldKey={field.key}
							typeName={formatMessage(messages.countType)}
							hasDefaultFieldOptionWeights={hasDefaultFieldOptionWeights}
						/>
					}
					onClick={() => {
						onClick({
							kind: 'field',
							value: field,
						});
					}}
				>
					{field.label}
				</ButtonItem>
			</TooltipComponent>
		);
	},
);

export const MultiselectOptions = memo<MultiselectOptionsProps>(
	({ fields, onClick, isFiltered }: MultiselectOptionsProps) => {
		const optionFields = fields.filter(
			({ type, label }) =>
				(type === FIELD_TYPES.SINGLE_SELECT ||
					type === FIELD_TYPES.MULTI_SELECT ||
					type === FIELD_TYPES.JSW_MULTI_SELECT) &&
				isFiltered(label),
		);
		if (optionFields.length === 0) return null;
		return (
			<>
				{optionFields.map((field) => (
					<MultiselectOption key={field.key} field={field} onClick={onClick} />
				))}
			</>
		);
	},
);
