import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { FetchCurrentUserPermissionsResponse } from './types';

const getFetchCurrentUserPermissionsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access/my-access`;

const getFetchCurrentUserPermissionsUrlLegacy = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access/current-user-permissions`;

export const fetchCurrentUserPermissions = (
	viewUUID: string,
): Promise<FetchCurrentUserPermissionsResponse> =>
	fetchJson(getFetchCurrentUserPermissionsUrl(viewUUID));

export const fetchCurrentUserPermissionsLegacy = (
	viewUUID: string,
): Promise<FetchCurrentUserPermissionsResponse['permissions']> =>
	fetchJson(getFetchCurrentUserPermissionsUrlLegacy(viewUUID));
