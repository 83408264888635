import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.delete-weighting-confirm-modal.delete',
		defaultMessage: 'Delete',
		description: 'Delete button label for the delete weighting confirmation dialog',
	},
	cancel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.delete-weighting-confirm-modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button label for the delete weighting confirmation dialog',
	},
	header: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.delete-weighting-confirm-modal.header',
		defaultMessage: 'Remove weighting?',
		description: 'Header caption for the delete weighting confirmation dialog',
	},
	description: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.change-option-weight-type-action.delete-weighting-confirm-modal.description',
		defaultMessage: 'All weights associated to each option will be removed. This cannot be undone.',
		description: 'Description for the delete weighting confirmation dialog',
	},
});
