import type { ApolloClientId } from '@atlassian/jira-apollo-multiple-clients/src/common/types';
import client, { clientWithBranch } from '@atlassian/jira-apollo-polaris';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';

/**
 * Returns the current AGG branch to use, or undefined to use no branch
 * Use this to centrally override the AGG branch.
 * Example: 'branch-dkolbly-polaris-in-dev'
 *
 * see also: https://hello.atlassian.net/wiki/spaces/STAR/pages/1042558309/Run+a+local+JFE+against+a+local+polaris-api
 *
 * https://app.launchdarkly.com/jira/staging/features/polaris.agg-branch-name
 */
const getPolarisGraphqlBranch = () =>
	getFeatureFlagValue<string>('polaris.agg-branch-name', 'default');

export const createApolloClient = (meta?: Record<string, string>) => {
	const branchName = getPolarisGraphqlBranch();

	if (branchName === undefined || branchName === 'default') {
		return clientWithBranch('', meta);
	}

	return {
		...clientWithBranch(branchName, meta),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		clientId: `${client.clientId}-branch` as ApolloClientId,
	};
};
