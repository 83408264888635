import {
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
	ISSUE_COMMENTED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';

export const JIRA_SUBSCRIPTION_EVENTS = [
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
	ISSUE_COMMENTED_EVENT,
];
