/* eslint-disable no-restricted-globals */

import { startOfDay, differenceInDays } from 'date-fns';
import memoizeOne from 'memoize-one';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	ValueDecoration,
	ValueRule,
	ValueDecorationRules,
	ValueDecorationLogic,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import jsonLogic from '@atlassian/jira-polaris-lib-json-logic';

export type DatePickerValue = {
	start: string;
	end: string;
};

export const getAppliedDecoration = memoizeOne(
	(decorations?: ValueDecoration[], value?: string): ValueDecoration | undefined => {
		if (decorations !== undefined && value !== undefined) {
			return decorations.find((decoration: ValueDecoration) =>
				isDecorationWithLogic(decoration)
					? jsonLogic.apply(decoration.logic, { id: value })
					: decoration.rules.reduce((result, rule) => {
							const ruleApplies = rule.operator === ValueRuleOperator.EQ && rule.value === value;
							return result && ruleApplies;
						}, true),
			);
		}
		return undefined;
	},
);

export const getAppliedDecorations = memoizeOne(
	(decorations: ValueDecoration[], values: string[]) => {
		const decorationMap: Record<string, ValueDecoration | undefined> = {};

		for (const value of values) {
			decorationMap[value] = getAppliedDecoration(decorations, value);
		}

		return decorationMap;
	},
);

export const numberRuleApplies = (rule: ValueRule, value: number): boolean => {
	// early exist if rule value is not a number, which normally should never be the case

	// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
	if (isNaN(rule.value)) {
		return false;
	}
	const ruleValue = Number(rule.value);
	switch (rule.operator) {
		case ValueRuleOperator.EQ:
			return value === ruleValue;
		case ValueRuleOperator.GT:
			return value > ruleValue;
		case ValueRuleOperator.GTE:
			return value >= ruleValue;
		case ValueRuleOperator.LT:
			return value < ruleValue;
		case ValueRuleOperator.LTE:
			return value <= ruleValue;
		default:
			return false;
	}
};
export const getAppliedNumberDecoration = (
	decorations?: ValueDecoration[],
	value?: number,
): ValueDecoration | undefined => {
	if (decorations !== undefined && value !== undefined) {
		return decorations.find((decoration: ValueDecoration) =>
			isDecorationWithLogic(decoration)
				? jsonLogic.apply(decoration.logic, { value })
				: decoration.rules.reduce((result, rule) => {
						const ruleApplies = numberRuleApplies(rule, value);
						return result && ruleApplies;
					}, true),
		);
	}
	return undefined;
};
export const getAppliedDateDecoration = memoizeOne(
	(
		decorations: ValueDecoration[] | undefined,
		value: string | undefined,
		locale: Locale,
	): ValueDecoration | undefined => {
		if (decorations !== undefined && value !== undefined) {
			const given = startOfDay(
				new Date(formatWithLocale(new Date(value), 'dd MMMM yyyy HH:mm', locale)),
			);
			const current = startOfDay(
				new Date(formatWithLocale(new Date(), 'dd MMMM yyyy HH:mm', locale)),
			);
			const diffDays = differenceInDays(given, current);

			return decorations.find((decoration: ValueDecoration) =>
				isDecorationWithLogic(decoration)
					? jsonLogic.apply(decoration.logic, { daysUntilDueDate: diffDays })
					: decoration.rules.reduce((result, rule) => {
							const ruleApplies = numberRuleApplies(rule, diffDays);
							return result && ruleApplies;
						}, true),
			);
		}
		return undefined;
	},
);
export const getAppliedIntervalDecoration = memoizeOne(
	(
		decorations: ValueDecoration[] | undefined,
		value: DatePickerValue | undefined,
		locale: Locale,
	): ValueDecoration | undefined => {
		if (decorations !== undefined && value !== undefined) {
			const startDate = startOfDay(
				new Date(formatWithLocale(new Date(value?.start), 'dd MMMM yyyy HH:mm', locale)),
			);
			const endDate = startOfDay(
				new Date(formatWithLocale(new Date(value?.end), 'dd MMMM yyyy HH:mm', locale)),
			);
			const current = startOfDay(
				new Date(formatWithLocale(new Date(), 'dd MMMM yyyy HH:mm', locale)),
			);
			const diffDaysBeforeStart = differenceInDays(startDate, current);
			const diffDaysBeforeEnd = differenceInDays(endDate, current);

			const appliedDecoration = (
				decoration: ValueDecorationRules,
				ignoreRuleForStartDate: (rule: ValueRule) => boolean,
			) =>
				decoration.rules.reduce((result, rule) => {
					const ruleAppliesStart = ignoreRuleForStartDate(rule)
						? false
						: numberRuleApplies(rule, diffDaysBeforeStart);
					const ruleAppliesEnd = numberRuleApplies(rule, diffDaysBeforeEnd);
					return result && (ruleAppliesStart || ruleAppliesEnd);
				}, true);

			const handleRulesDecoration = (decoration: ValueDecorationRules) =>
				// overdue decoration is just defined by one rule with an operator equals 'LT' -> in this case we need to ignore the start of the interval
				decoration.rules.length === 1
					? appliedDecoration(decoration, (rule) => rule.operator === ValueRuleOperator.LT)
					: appliedDecoration(decoration, () => false);

			const handleLogicDecoration = (decoration: ValueDecorationLogic) =>
				// For a range of dates the start might fall in the range and so might the end.
				jsonLogic.apply(decoration.logic, { daysUntilDueDate: diffDaysBeforeStart }) ||
				jsonLogic.apply(decoration.logic, { daysUntilDueDate: diffDaysBeforeEnd });

			return decorations.find((decoration: ValueDecoration) =>
				isDecorationWithLogic(decoration)
					? handleLogicDecoration(decoration)
					: handleRulesDecoration(decoration),
			);
		}
		return undefined;
	},
);
