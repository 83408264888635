import React, { useCallback, useState, useEffect } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import CrossIcon from '@atlaskit/icon/glyph/cross';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Flex, Grid, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useSourceField } from '../../../../../controllers';
import {
	useAutomappedOptionsCount,
	useFieldOptionsValuesMapping,
	useSetFieldOptionsValuesMapping,
	useTargetField,
} from '../../../../../controllers/options-mapping';
import { FieldName } from './field-name';
import { GroupedOptions, OptionsMapper, type OptionsValueMapping } from './field-options-mapper';
import messages from './messages';

type OptionsMappingModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

export const OptionsMappingModal = ({ isOpen, onClose }: OptionsMappingModalProps) => {
	const { formatMessage } = useIntl();

	const automappedOptionsCount = useAutomappedOptionsCount();
	const optionsValueMapping = useFieldOptionsValuesMapping();
	const fromSelectField = useSourceField();
	const toSelectField = useTargetField();

	const setFieldOptionsValuesMapping = useSetFieldOptionsValuesMapping();

	const isAutomappedMode = automappedOptionsCount === Object.keys(optionsValueMapping).length;

	const [mapping, setMapping] = useState<OptionsValueMapping>({});

	useEffect(() => {
		setMapping(optionsValueMapping);
	}, [optionsValueMapping]);

	const onSetOptionValueMapping = useCallback(
		(fromOptionId: string, toOptionId: string | undefined) => {
			setMapping((optionsMap) => ({
				...optionsMap,
				[fromOptionId]: { optionId: toOptionId, automapped: false },
			}));
		},
		[setMapping],
	);

	const handleSaveClick = useCallback(() => {
		setFieldOptionsValuesMapping(convertOptionValueMappingToPlainMap(mapping, true));
		onClose();
	}, [mapping, setFieldOptionsValuesMapping, onClose]);

	if (!fromSelectField || !toSelectField) {
		return null;
	}

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={onClose}>
					<ModalHeader>
						<Grid gap="space.200" templateAreas={['title close']} xcss={gridStyles}>
							<Flex xcss={closeContainerStyles} justifyContent="end">
								<IconButton
									appearance="subtle"
									icon={CrossIcon}
									label={formatMessage(messages.closeModal)}
									onClick={onClose}
								/>
							</Flex>
							<Flex xcss={titleContainerStyles} justifyContent="start">
								<ModalTitle>
									{formatMessage(
										isAutomappedMode ? messages.reviewMappingHeader : messages.mapOptionsHeader,
									)}
								</ModalTitle>
							</Flex>
						</Grid>
					</ModalHeader>
					<ModalBody>
						<Box>
							{formatMessage(
								isAutomappedMode
									? messages.reviewMappingDescription
									: messages.mapOptionsDescription,
							)}
						</Box>
						<Flex xcss={fieldsNameStyles}>
							<Box>
								<Box>
									<b>{formatMessage(messages.projectField)}</b>
								</Box>
								<FieldName field={fromSelectField} />
							</Box>
							<Flex
								alignItems="center"
								justifyContent="center"
								xcss={[fieldNameArrowIconWrapperStyles, fieldNameArrowIconWrapperAlignedStyles]}
							>
								<ArrowRightIcon label="" primaryColor={token('color.icon.accent.gray')} />
							</Flex>
							<Box>
								<b>{formatMessage(messages.globalField)}</b>
								<FieldName field={toSelectField} />
							</Box>
						</Flex>
						<Box>
							{isAutomappedMode ? (
								<OptionsMapper
									optionValueMapping={mapping}
									onSetOptionValueMapping={onSetOptionValueMapping}
								/>
							) : (
								<GroupedOptions
									optionValueMapping={mapping}
									onSetOptionValueMapping={onSetOptionValueMapping}
								/>
							)}
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button appearance="subtle" onClick={onClose}>
							{formatMessage(messages.cancel)}
						</Button>
						<Button appearance="primary" onClick={handleSaveClick}>
							{formatMessage(messages.save)}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	);
};

export const convertOptionValueMappingToPlainMap = (
	fieldOptionsValueMapping: OptionsValueMapping,
	skipAutomappedOptions = false,
): Record<string, string | undefined> => {
	const plainMap: Record<string, string | undefined> = {};
	for (const [fromOptionId, toOption] of Object.entries(fieldOptionsValueMapping)) {
		if (skipAutomappedOptions && toOption?.automapped) {
			// eslint-disable-next-line no-continue
			continue;
		}

		plainMap[fromOptionId] = toOption?.optionId;
	}
	return plainMap;
};

const gridStyles = xcss({
	width: '100%',
});

const closeContainerStyles = xcss({
	gridArea: 'close',
});

const titleContainerStyles = xcss({
	gridArea: 'title',
});

const fieldNameArrowIconWrapperStyles = xcss({
	width: '40px',
});

const fieldNameArrowIconWrapperAlignedStyles = xcss({
	marginTop: 'space.300',
});

const fieldsNameStyles = xcss({
	marginTop: 'space.200',
	marginBottom: 'space.250',
});
