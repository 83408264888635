import { AnyAri } from '@atlassian/ari/any-ari';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ViewResponse } from '@atlassian/jira-polaris-domain-collection';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import type { UpdateViewRequest } from '../../../common/types';
import { getSortMode } from '../../../common/utils';
import type { ViewUpdateRequest, UpdateViewReturn } from './types';

const getPutUrl = (collectionUUID: string, viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}`;

const transformViewPayload = (props: UpdateViewRequest): ViewUpdateRequest => ({
	name: props.name,
	emoji: props.emoji,
	description: props.description,
	fields: props.fields,
	filter: props.filter,
	sort: props.sort,
	sortMode: getSortMode(props.sortMode),
	hidden: props.hidden,
	groupBy: props.groupBy,
	verticalGroupBy: props.verticalGroupBy,
	hideEmptyGroups: props.hideEmptyGroups,
	verticalGroupValues: props.verticalGroupValues,
	hideEmptyColumns: props.hideEmptyColumns,
	groupValues: props.groupValues,
	tableColumnSizes: props.tableColumnSizes,
	fieldRollups: props.fieldRollups,
	matrixConfig: props.matrixConfig,
	timelineConfig: props.timelineConfig,
	enabledAutoSave: props.enabledAutoSave,
	layoutType: props.layoutType,

	// -- to test --
	// lastCommentsViewedTimestamp: props.lastCommentsViewedTimestamp,
	// [POL-10943] lastViewedTimestamp: props.lastViewedTimestamp,
});

export const updateCollectionView = async (
	collectionUUID: string,
	props: UpdateViewRequest,
): Promise<UpdateViewReturn> => {
	const viewUUID = AnyAri.parse(props.viewId).resourceId;
	const { createdAt, updatedAt } = await performPutRequest<ViewResponse>(
		getPutUrl(collectionUUID, viewUUID),
		{
			body: JSON.stringify(transformViewPayload(props)),
		},
	);

	return {
		node: {
			createdAt,
			updatedAt,
		},
	};
};
