/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { type PlaceholderProps, components } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';

export const Placeholder = <Option, IsMulti extends boolean = false>(
	props: PlaceholderProps<Option, IsMulti>,
) => <components.Placeholder {...props} css={placeholderStyles} />;

const placeholderStyles = css({
	width: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingLeft: token('space.075', '6px'),
	paddingRight: token('space.075', '6px'),
});
