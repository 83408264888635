import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	rankingDisabledTooltip: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-top.ranking-disabled-tooltip',
		defaultMessage: 'Rows cannot be ranked while table is sorted',
		description: 'Tooltip displayed when table ranking is disabled',
	},
	buttonLabel: {
		id: 'polaris-ideas.view-controls.manage-bar.send-to-top.button-label',
		defaultMessage: 'Move to top',
		description: 'Button label to send ideas to top of table',
	},
});
