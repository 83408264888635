import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	selectedAmount: {
		id: 'polaris-lib-matrix.sidebar.sidebar-selected-header.selected-amount',
		defaultMessage: '{amount} selected',
		description: 'Shows how many ideas are selected in the matrix',
	},
	clearSelectedButton: {
		id: 'polaris-lib-matrix.sidebar.sidebar-selected-header.clear-selected-button',
		defaultMessage: 'Back to all ideas',
		description: 'Button text to clear selected items in matrix view',
	},
});
