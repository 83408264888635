import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ShowOnHoverTableCellWrapper = styled.div({
	display: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HideOnHoverTableCellWrapper = styled.div({
	display: 'inherit',
});
