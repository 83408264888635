import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { PolarisTooltip } from '@atlassian/jira-polaris-lib-tooltip/src/ui/index.tsx';
import { useObservableValuesListContext } from '../context';
import messages from './messages';

interface MoreTagContentProps {
	count: number;
}

const MoreTagContent = ({ count }: MoreTagContentProps) => {
	const { formatMessage } = useIntl();
	const { HiddenCountComponent } = useObservableValuesListContext();

	return (
		<MoreTagWrapper data-component-selector="more-tag-wrapper-93Qc">
			{HiddenCountComponent ? (
				<HiddenCountComponent count={count} />
			) : (
				<MoreTagContainer data-component-selector="more-tag-container-327a">
					{formatMessage(messages.optionsCount, { count })}
				</MoreTagContainer>
			)}
		</MoreTagWrapper>
	);
};

type MoreTagProps = {
	children: ReactNode;
	showTooltip?: boolean;
	count: number;
};

export const MoreTag = ({ children, count, showTooltip = false }: MoreTagProps) =>
	showTooltip ? (
		<PolarisTooltip
			hideTooltipOnMouseDown
			content={<PreviewContainer>{children}</PreviewContainer>}
		>
			<MoreTagContent count={count} />
		</PolarisTooltip>
	) : (
		<MoreTagContent count={count} />
	);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagWrapper = styled.div({
	position: 'absolute',
	right: 0,
	bottom: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: `linear-gradient(270deg, ${token('elevation.surface', colors.N0)}, transparent)`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MoreTagContainer = styled.div({
	borderRadius: '3px',
	padding: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	fontWeight: 600,
	lineHeight: '14px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.accent.gray.subtler', colors.N40),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	boxShadow: `-2px 0px 8px 4px ${token('elevation.surface', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	clipPath: 'inset(0px 0px 0px -12px)',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PreviewContainer = styled.span({
	display: 'flex',
	flexWrap: 'wrap',
	maxWidth: '370px',
});
