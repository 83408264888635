import React, { useCallback, type SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import LinkIcon from '@atlaskit/icon/glyph/link';
import { useIntl } from '@atlassian/jira-intl';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { useNotifications } from '@atlassian/jira-polaris-lib-notifications/src/controllers/index.tsx';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages';

export const CopyLinkButton = () => {
	const { error, success } = useNotifications();
	const { formatMessage } = useIntl();

	const onCopy = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'copyLink');
			sendPendoTrackEvent({
				source: 'viewAccessModal',
				actionSubjectAndAction: 'button clicked',
				actionSubjectId: 'copyLink',
			});

			const viewUrl = new URL(window.location.href);

			navigator.clipboard
				.writeText(viewUrl.toString())
				.then(() =>
					success({
						title: formatMessage(messages.copiedTitle),
						description: formatMessage(messages.copiedMessage),
					}),
				)
				.catch(() =>
					error({
						title: formatMessage(messages.copyFailTitle),
						description: formatMessage(messages.copyFailMessage),
					}),
				);
		},
		[error, formatMessage, success],
	);

	return (
		<Button
			onClick={onCopy}
			appearance="primary"
			iconBefore={<LinkIcon label={formatMessage(messages.copyLink)} />}
		>
			{formatMessage(messages.copyLink)}
		</Button>
	);
};
