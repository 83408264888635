import { createSelector } from 'reselect';
import find from 'lodash/find';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import { AnyAri } from '@atlassian/ari/any-ari';
import { POLARIS_OAUTH_CLIENT_ID } from '@atlassian/jira-polaris-common/src/common/types/snippet/constants';
import {
	getInsightsMediaIds,
	getInsightsAttachments,
} from '@atlassian/jira-polaris-common/src/common/utils/insights';
import { createDeepEqualitySelector } from '@atlassian/jira-polaris-common/src/common/utils/reselect';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { State, Props, InsightMap, PlayContributionMap } from '../types';
import { getInsightAnalyticsData } from '../utils/analytics';

const getInsightsRaw = (state: State): InsightMap => state.insights;
const getPlayContributions = (state: State): PlayContributionMap => state.playContributions;
const getPlayIdsToAris = (state: State): Record<string, string> => state.playIdsToAris;
const getInsights = createSelector(
	getInsightsRaw,
	getPlayContributions,
	getPlayIdsToAris,
	({ meta }) => meta?.cloudId,
	(insights, playContributions, playIdsToAris, cloudId) => {
		if (!cloudId) {
			return insights;
		}
		const playInsights: InsightMap = {};
		Object.keys(playContributions).forEach((playId) => {
			playContributions[playId].forEach((contribution) => {
				const playAri = playIdsToAris[playId];
				if (playAri === undefined) {
					return;
				}
				const key = AnyAri.create({
					resourceOwner: 'jira',
					cloudId,
					resourceType: 'item',
					resourceId: String(playId),
				}).toString();
				if (playInsights[key] && playInsights[key].updated >= contribution.updated) {
					return;
				}
				playInsights[key] = {
					...contribution,
					id: key,
					account: null,
					container: contribution.subject,
					description: null,
					snippets: [],
					play: {
						id: playAri,
					},
				};
			});
		});

		const newInsights: InsightMap = {};

		Object.keys(insights).forEach((key) => {
			if (insights[key].play) {
				return;
			}
			newInsights[key] = {
				...insights[key],
			};
		});

		return {
			...newInsights,
			...playInsights,
		};
	},
);

const getSelectedInsights = (state: State): { [insight: string]: boolean } =>
	state.selectedInsights;

const getInsightsWithCreationDate = createSelector(getInsights, (insights) =>
	mapValues(insights, ({ created }) => new Date(created)),
);

export const getInsightsCount = createSelector(
	getInsights,
	(insights) => Object.keys(insights).length,
);

export const getSelectedInsightsCount = createSelector(
	getSelectedInsights,
	(selectedInsights) => Object.keys(selectedInsights).length,
);

export const createGetInsightsAttachments = createSelector(getInsights, (insightsMap) => {
	const insights: Insight[] = values(insightsMap);
	return getInsightsAttachments(
		insights.map(({ snippets }) => ({
			snippets: snippets.map(({ data }) => ({ data })),
		})),
	);
});

export const createGetInsightsMediaIds = createSelector(getInsights, (insightsMap) => {
	const insights: Insight[] = values(insightsMap);
	return getInsightsMediaIds(
		insights.map(({ description }) => ({
			description,
		})),
	);
});

export const createGetPlayInsights = createSelector(getInsights, (insightsMap) => {
	const insights: Insight[] = values(insightsMap);
	return insights.filter(({ play }) => play !== null);
});

export const getInsightIds = createDeepEqualitySelector(
	getInsightsWithCreationDate,
	(idsByDate: ReturnType<typeof getInsightsWithCreationDate>) =>
		// @ts-expect-error - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type
		keys(idsByDate).sort((a, b) => idsByDate[b] - idsByDate[a]),
);

export const getPlayInsightIds = createDeepEqualitySelector(
	getInsightsWithCreationDate,
	(idsByDate: ReturnType<typeof getInsightsWithCreationDate>) =>
		// @ts-expect-error - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type
		keys(idsByDate).sort((a, b) => idsByDate[b] - idsByDate[a]),
);

export const createGetInsight = (insightId: string) =>
	createSelector(getInsights, (insights) => insights[insightId]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PolarisInsightDescription = any;

export const createGetInsightDescription = (insightId: string) => {
	const getInsight = createGetInsight(insightId);

	return createSelector(getInsight, (insight) => insight?.description);
};

export const createGetInsightUpdatedTimestamp = (insightId: string) => {
	const getInsight = createGetInsight(insightId);

	return createSelector(getInsight, (insight) => insight?.updated);
};

export const createGetInsightSnippets = (insightId: string) => {
	const getInsight = createGetInsight(insightId);
	const noSnippets: Array<never> = [];

	return createSelector(getInsight, (insight) => insight?.snippets || noSnippets);
};

export const createGetRealInsightSnippets = (insightId: string) => {
	const getInsightSnippets = createGetInsightSnippets(insightId);

	return createSelector(getInsightSnippets, (snippets) =>
		snippets.filter((snippet) => snippet.appInfo?.oauthClientId !== POLARIS_OAUTH_CLIENT_ID),
	);
};

export const createGetPolarisInsightSnippet = (insightId: string) => {
	const getInsightSnippets = createGetInsightSnippets(insightId);

	return createSelector(getInsightSnippets, (snippets) =>
		find(snippets, (snippet) => snippet.appInfo?.oauthClientId === POLARIS_OAUTH_CLIENT_ID),
	);
};

export const createGetRealInsightSnippetIds = (insightId: string) => {
	const getRealInsightSnippets = createGetRealInsightSnippets(insightId);

	const idSelector = createSelector(getRealInsightSnippets, (snippets) =>
		snippets.map(({ id }) => id),
	);

	return createDeepEqualitySelector<State, Props, string[], string[]>(idSelector, (ids) => ids);
};

export const createGetSnippet = (insightId: string, snippetId: string) => {
	const getInsightSnippets = createGetInsightSnippets(insightId);

	return createSelector(getInsightSnippets, (snippets) =>
		find(snippets, ({ id }) => id === snippetId),
	);
};

export const createGetInsightAccountId = (insightId: string) => {
	const getInsight = createGetInsight(insightId);

	return createSelector(getInsight, (insight) => insight?.account?.accountId);
};

export const createGetInsightAnalyticsData = (insightId: string) => {
	const getInsight = createGetInsight(insightId);

	return createSelector(getInsight, (insight) => {
		if (!insight) {
			return undefined;
		}

		return getInsightAnalyticsData(insight);
	});
};
