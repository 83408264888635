import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	save: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.edit-view-description-overlay.save',
		defaultMessage: 'Save',
		description: 'Label for Save button in View description editor modal.',
	},
	cancel: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.edit-view-description-overlay.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for Cancel button in View description editor modal.',
	},
	descriptionPlaceholder: {
		id: 'polaris-ideas.right-sidebar.view-info.view-description.edit-view-description-overlay.description-placeholder',
		defaultMessage: 'Description',
		description:
			'Placeholder text for view description shown in view description editor component.',
	},
});
