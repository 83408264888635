import { WithWhyDidYouRenderPlaceholder } from '@atlassian/jira-polaris-why-did-you-render-placeholder';
import {
	createContainer,
	createStore,
	createActionsHook,
	type Action,
} from '@atlassian/react-sweet-state';
import actions from './actions';
import { initialState } from './constants';
import type { State, Props } from './types';

export type Actions = typeof actions;

export const ListStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisListComponentStore',
});

const updateStateFromProps =
	(): Action<State, Props> =>
	(
		{ setState, dispatch },
		{
			rows,
			rowGroups,
			updatedRows,
			groupedIds,
			columns,
			fixedColumns,
			hoverableColumns,
			components,
			columnSizes,
			columnMinSizes,
			highlightedRow,
			selectedRows,
			onItemRanked,
			onColumnRankEnd,
			onColumnResize,
			onGroupOrderChanged,
			onSelectionChanged,
			isExporting,
			hideEmptyGroups,
			displayMode,
			shouldRenderRowPinnedBottomForGroups,
			shouldRenderRowPinnedBottomForNoGroups,
		},
	) => {
		dispatch(
			actions.updateItemState(
				rows,
				updatedRows,
				rowGroups,
				groupedIds,
				columns,
				fixedColumns,
				hoverableColumns,
				hideEmptyGroups,
			),
		);
		dispatch(actions.updateComponentsState(components));
		dispatch(actions.updateColumnSizesState(columnSizes, columnMinSizes));
		dispatch(actions.updateSelection(selectedRows));
		dispatch(actions.setHighlightedRow(highlightedRow));

		setState({
			isItemSelectionEnabled: onSelectionChanged !== undefined,
			isGroupReorderingEnabled: onGroupOrderChanged !== undefined,
			isColumnReorderingEnabled: onColumnRankEnd !== undefined,
			isColumnResizingEnabled: onColumnResize !== undefined,
			isItemReorderingEnabled: onItemRanked !== undefined,
			isExporting,
			hideEmptyGroups,
			displayMode,
			shouldRenderRowPinnedBottomForGroups,
			shouldRenderRowPinnedBottomForNoGroups,
		});
	};

const ListContainerInternal = createContainer<State, Actions, Props>(ListStore, {
	onInit: updateStateFromProps,
	onUpdate: updateStateFromProps,
});

export const ListContainer = WithWhyDidYouRenderPlaceholder(ListContainerInternal, 'ListContainer');

export const useListActions = createActionsHook(ListStore);
