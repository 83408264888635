/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Box, Flex, Inline, Stack, Text } from '@atlaskit/primitives';
import {
	components,
	type InputProps,
	type OptionProps,
	type SingleValueProps,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';

export type OptionType = {
	label: string;
	value: ViewAccessLevel;
	description: string;
	icon: React.JSX.Element;
};

export const Input = (props: InputProps<OptionType, false>) => (
	<components.Input {...props} css={inputStyles} />
);

export const SingleValue = ({ children, data, ...rest }: SingleValueProps<OptionType, false>) => (
	<components.ValueContainer {...rest} css={valueContainerStyles}>
		<Box paddingBlock="space.025" paddingInline="space.050">
			<Flex gap="space.150">
				<Inline alignBlock="center">{data.icon}</Inline>
				<Stack space="space.050">
					<Heading size="xsmall">{data.label}</Heading>
					<Text size="UNSAFE_small" color="color.text.subtlest">
						{data.description}
					</Text>
				</Stack>
			</Flex>
		</Box>
	</components.ValueContainer>
);

export const Option = ({ data, innerProps, ...rest }: OptionProps<OptionType, false>) => (
	<components.Option innerProps={innerProps} {...rest} data={data} css={optionStyles}>
		<Box paddingBlock="space.025" paddingInline="space.050">
			<Flex gap="space.150">
				<Inline alignBlock="center">{data.icon}</Inline>
				<Stack space="space.050">
					<Heading size="xsmall">{data.label}</Heading>
					<Text size="UNSAFE_small" color="color.text.subtlest">
						{data.description}
					</Text>
				</Stack>
			</Flex>
		</Box>
	</components.Option>
);

const optionStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		cursor: 'pointer',
	},
});

const inputStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		opacity: 0,
		width: 0,
		height: 0,
		margin: 0,
		padding: 0,
	},
});

const valueContainerStyles = css({
	padding: `${token('space.050', '4px')} ${token('space.075', '6px')}`,
});
