import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	caption: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.caption',
		defaultMessage: 'Delivery Progress:',
		description:
			'Caption for progress summary component, showing the overall progress of the linked Jira issues (including child issues).',
	},
	createEpic: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.create-epic',
		defaultMessage: 'Create a delivery ticket',
		description: 'Button caption for creating a delivery ticket and link it to this idea.',
	},
	linkToIssue: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.link-to-issue',
		defaultMessage: 'Link existing ticket',
		description: 'Button caption for linking an existing Jira issue to this idea.',
	},
	progressExplanationSingleDeliveryLink: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.progress-explanation-single-delivery-link',
		defaultMessage:
			'This idea is linked to one delivery ticket. The delivery progress is based on the status of the children of that ticket',
		description:
			'Tooltip body explaining how delivery progress is calculated if there is exactly one delivery ticket linked to an idea.',
	},
	progressExplanationSingleDoneDeliveryLink: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.progress-explanation-single-done-delivery-link',
		defaultMessage: 'This idea is linked to one delivery ticket marked as done',
		description:
			'Tooltip body explaining how delivery progress is calculated if there is exactly one delivery ticket linked to an idea, and that ticket is in DONE status.',
	},
	progressExplanationMultipleDeliveryLinks: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.progress-explanation-multiple-delivery-links',
		defaultMessage:
			'This idea is linked to multiple delivery tickets. The delivery progress is based on the status of each delivery ticket, not taking into account their children',
		description:
			'Tooltip body explaining how delivery progress is calculated if there is more than one delivery ticket linked to an idea.',
	},
	editField: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.edit-field',
		defaultMessage: 'Configure delivery progress',
		description: 'Button label for editing the field.',
	},
	editFieldTooltip: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.edit-field-tooltip',
		defaultMessage: 'Configure delivery progress calculation',
		description: 'Tooltip label for editing the field.',
	},
	noEditPermissions: {
		id: 'polaris-ideas.idea-view.sections.deliver.progress-summary.no-edit-permissions',
		defaultMessage: 'Only project admins can edit fields',
		description: 'Information message for missing field edit permissions.',
	},
});
