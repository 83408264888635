import React from 'react';
import { useStringValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useFieldUpdate } from '../../../utils';
import { ShortTextInput } from './short-text';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
	onCancel: () => void;
};

export const EditShortTextField = ({ fieldKey, localIssueId, onCancel }: Props) => {
	const value = useStringValue(fieldKey, localIssueId);
	const onUpdate = useFieldUpdate(localIssueId, fieldKey);

	return <ShortTextInput onConfirm={onUpdate} value={value} onCancel={onCancel} />;
};
