import React from 'react';
import {
	useIssueKey,
	useSummary,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { ShortLabel } from '../../../../../../../common/ui/issue/short-label';

type Props = {
	issueId: LocalIssueId;
};

export const Selectable = ({ issueId }: Props) => {
	const summary = useSummary(issueId);
	const issueKey = useIssueKey(issueId);

	return <ShortLabel issueKey={issueKey} summary={summary} />;
};
