import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const LoadingScreen = () => (
	<Inline
		testId="polaris-lib-control-sharing.ui.loading-screen"
		alignInline="center"
		alignBlock="center"
		space="space.100"
		xcss={containerStyles}
	>
		<Spinner size="large" />
	</Inline>
);

const containerStyles = xcss({
	minHeight: '250px',
});
