import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { EmojiDescription } from '@atlaskit/emoji';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { CommonDecoratedTag } from '@atlassian/jira-polaris-lib-decoration/src/ui/decoration/common/index.tsx';
import { useEmoji } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';

type DecoratedNumberProps = {
	mainColor: string | undefined;
	emoji: EmojiDescription | undefined;
	value: string | undefined;
	placeholder?: string | undefined;
	isCompact?: boolean;
};

export type Props = {
	decoration: ValueDecoration | undefined;
	value: number | undefined;
	placeholder?: string | undefined;
	isCompact?: boolean;
	disableLazyRendering?: boolean;
};

export const DecoratedNumberComponent = memo<DecoratedNumberProps>(
	({ mainColor, emoji, value, placeholder, isCompact }: DecoratedNumberProps) => {
		if (value === undefined && placeholder !== undefined) {
			return <Placeholder>{placeholder}</Placeholder>;
		}
		return (
			<CommonDecoratedTag mainColor={mainColor} emoji={emoji} value={value} compact={isCompact} />
		);
	},
);

const DecoratedNumberInternal = ({ decoration, value, placeholder, isCompact }: Props) => {
	const emojiDescription = useEmoji(decoration?.emoji);
	const { formatNumber } = useIntl();
	const formattedValue = useMemo(
		() => (value !== undefined ? formatNumber(value, { maximumFractionDigits: 1 }) : undefined),
		[value, formatNumber],
	);

	return (
		<DecoratedNumberComponent
			mainColor={decoration?.backgroundColor}
			emoji={emojiDescription}
			value={formattedValue}
			placeholder={placeholder}
			isCompact={isCompact}
		/>
	);
};

export const DecoratedNumber = (props: Props) => <DecoratedNumberInternal {...props} />;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	padding: `${token('space.075', '6px')} ${token('space.050', '4px')} ${token('space.075', '6px')}`,
});
