import { useEffect } from 'react';
import { useActions } from '../store';
import type { PolarisEnvironmentContainer } from '../types';

export const PolarisCustomEnvironmentContainerProvider = (data: PolarisEnvironmentContainer) => {
	const { setData, setLoading } = useActions();

	useEffect(() => {
		setData(data);
		setLoading(false);
	}, [setLoading, setData, data]);

	return null;
};
