import type { ConstantFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/constant/types.tsx';
import type { DynamicFieldFormula } from '../types';
import { CONSTANT_TEMPLATE } from './types';

export const constant = (value: number): ConstantFormula => ({
	template: CONSTANT_TEMPLATE,
	parameters: {
		value,
	},
});

export const isConstant = (formula: DynamicFieldFormula): boolean =>
	formula.template === CONSTANT_TEMPLATE;
