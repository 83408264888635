// eslint-disable-next-line jira/restricted/react-component-props
import React, { useState, useRef, useCallback, useEffect, type ComponentProps } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ErrorBoundaryFlag } from '@atlassian/jira-error-boundary-flag-renderer';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	SCOPE_GLOBAL,
	SCOPE_PROJECT,
} from '@atlassian/jira-project-configuration-commons/src/model/constants';
import {
	toIssueTypeId,
	type IssueTypeId,
	type ProjectId,
	toProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { StatusValue } from '../common/types';
import { StatusService } from '../services/status-service';
import { TransitionsQuery } from '../services/transitions-query';
import ViewWorkflowContextServiceDI from '../services/view-workflow-service';
import StatusView from './status-view';
import type { Props } from './types';
import ViewWorkflow from './view-workflow';
import WorkflowModal from './workflow-modal';

export const StatusField = ({
	isOptimistic = true,
	ViewWorkflowContextService = ViewWorkflowContextServiceDI,
	preFetchTransitions = false,
	projectType = null,
	onEditStart = noop,
	onEditCancel = noop,
	onSubmit = noop,
	onSuccess = noop,
	onFailure = noop,
	issueKey,
	issueId,
	isEditable,
	initialValue,
	appearance,
	value,
	statusButtonRef: statusButtonRefExternal,
	popperProps,
	registerInCommandPalette,
}: Props) => {
	// All these states can be removed on clean up of cmp_support_for_readonly_workflow_modal
	const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
	const [isWorkflowModalMounted, setisWorkflowModalMounted] = useState(false);
	const [issueTypeId, setIssueTypeId] = useState(toIssueTypeId(''));
	const [projectId, setProjectId] = useState(toProjectId(''));
	const previousIsWorkflowModalOpen = usePrevious(isWorkflowModalOpen);

	const statusButtonRef = useRef<HTMLElement>(null);

	const tenantContext = useTenantContext();

	useEffect(() => {
		if (previousIsWorkflowModalOpen === true && isWorkflowModalOpen === false) {
			if (statusButtonRef?.current) {
				statusButtonRef.current.focus();
			}
		}
	}, [isWorkflowModalOpen, previousIsWorkflowModalOpen]);

	const onOpenModal = useCallback((issueTypeIdInArgs: IssueTypeId, projectIdInArgs: ProjectId) => {
		setIsWorkflowModalOpen(true);
		setIssueTypeId(issueTypeIdInArgs);
		setProjectId(projectIdInArgs);
	}, []);

	const onCloseModal = useCallback(() => {
		if (fg('cmp_support_for_readonly_workflow_modal')) {
			statusButtonRef?.current?.focus();
		} else {
			setIsWorkflowModalOpen(false);
		}
	}, []);

	const onModalMounted = useCallback(() => {
		setisWorkflowModalMounted(true);
	}, []);

	return !tenantContext ? null : (
		<StatusService
			appearance={appearance}
			value={value}
			projectType={projectType}
			initialValue={initialValue}
			issueKey={issueKey}
			issueId={issueId}
			baseUrl={tenantContext.baseUrl}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			onFailure={onFailure}
			onEditStart={onEditStart}
			onEditCancel={onEditCancel}
		>
			{({ value: statusServiceValue, loading: saving, transitionStatus, error, resetError }) => (
				<TransitionsQuery
					issueKey={issueKey}
					baseUrl={tenantContext.baseUrl}
					// this will force an empty list of transitions when the status changes
					status={statusServiceValue}
					isSaving={saving}
					preFetch={preFetchTransitions}
					projectType={projectType}
				>
					{({ data: transitions, loading, fetch, ui }) => (
						<ViewWorkflowContextService baseUrl={tenantContext.baseUrl} issueKey={issueKey}>
							{(workflowServiceApi) => (
								<>
									<StatusView
										statusButtonRef={statusButtonRefExternal || statusButtonRef}
										value={statusServiceValue}
										isEditable={isEditable}
										isLoading={
											loading ||
											saving ||
											(ff('issue.details.fix-issue-status-transition-loading_6s8n8') &&
												Boolean(ui?.transitionInProgress))
										}
										isSaving={isOptimistic === true ? false : saving}
										appearance={appearance}
										transitions={transitions}
										onOpen={(analyticsEvent: UIAnalyticsEvent) => {
											onEditStart();
											fetch(analyticsEvent);

											const hasFetchedWorkflowService =
												workflowServiceApi.data &&
												workflowServiceApi.data.hasPermission !== undefined;
											if (hasFetchedWorkflowService !== true) {
												workflowServiceApi.fetch();
											}
											resetError();
										}}
										onClose={onEditCancel}
										onChange={transitionStatus}
										error={error}
										footer={
											<ErrorBoundary id="issue-field-view-workflow">
												<ViewWorkflow
													baseUrl={tenantContext.baseUrl}
													issueKey={issueKey}
													workflowServiceApi={workflowServiceApi}
													onOpenModal={onOpenModal}
													isModalMounted={isWorkflowModalMounted}
													onCloseModal={
														fg('cmp_support_for_readonly_workflow_modal') ? onCloseModal : undefined
													}
												/>
											</ErrorBoundary>
										}
										popperProps={popperProps}
										registerInCommandPalette={registerInCommandPalette}
										onSuccess={onSuccess}
										onFetch={fetch}
									/>

									{fg('cmp_support_for_readonly_workflow_modal') ? null : (
										<ErrorBoundaryFlag>
											<WorkflowModal
												onModalMounted={onModalMounted}
												issueKey={issueKey}
												issueTypeId={issueTypeId}
												baseUrl={tenantContext.baseUrl}
												locale={tenantContext.locale}
												cloudId={tenantContext.cloudId}
												projectId={projectId}
												isOpen={isWorkflowModalOpen}
												onClose={onCloseModal}
												scope={
													workflowServiceApi.data?.isSimplified === true
														? SCOPE_PROJECT
														: SCOPE_GLOBAL
												}
											/>
										</ErrorBoundaryFlag>
									)}
								</>
							)}
						</ViewWorkflowContextService>
					)}
				</TransitionsQuery>
			)}
		</StatusService>
	);
};

export default StatusField;

/**
 * We had to add these here to avid circular dependency.
 * The issue here is StatusField component has some defaultProps which were not included as optional in types earlier
 * So we created a new prop to be used from async file.
 */
type BaseProps = JSX.LibraryManagedAttributes<
	typeof StatusField,
	ComponentProps<typeof StatusField>
>;
export type ExpProps = BaseProps & {
	initialValue?: StatusValue;
	value?: StatusValue;
	statusButtonRef?: {
		current: HTMLElement | null;
	};
};
