import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { HeaderProps } from '../../../types/commands';
import { MODAL_HEADER_TEST_ID } from './constants';

export const Header = ({ children }: HeaderProps) => (
	<Box xcss={headerStyles} testId={MODAL_HEADER_TEST_ID}>
		{children}
	</Box>
);

const headerStyles = xcss({
	borderTopLeftRadius: 'inherit',
	borderTopRightRadius: 'inherit',
	padding: 'space.200',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `solid 1px ${token('color.border', colors.N40)}`,
});
