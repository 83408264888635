import {
	createContainer,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import actions from './actions';
import { ProjectProperties, type Props, type RemoteProperty, type State } from './types';

export const PRELOAD_PROJECT_PROPERTIES = [
	ProjectProperties.ATLAS_INTEGRATION_ENABLED,
	ProjectProperties.ATLAS_SITE_CLOUD_ID,
	ProjectProperties.IDEA_CREATION_BY_CONTRIBUTORS,
	ProjectProperties.SUBMIT_IDEA_FORM_VIEW,
	ProjectProperties.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE,
	ProjectProperties.SUBMIT_IDEA_FORM_MANDATORY_FIELDS,
];

type Actions = typeof actions;

const initialState: State = {
	properties: {},
	callbacks: {},
};

export const ProjectPPropertiesStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisProjectPropertiesStore',
});

export const useProjectPropertiesActions = createHook(ProjectPPropertiesStore, {
	selector: null,
});

export const useProjectProperty = createHook(ProjectPPropertiesStore, {
	selector: (state, scopedPropertyKey: ProjectProperties) => {
		const value =
			state.properties[scopedPropertyKey]?.isLoading === false &&
			state.properties[scopedPropertyKey]?.value !== undefined
				? state.properties[scopedPropertyKey]?.value
				: null;
		return {
			value,
			isLoading:
				state.properties[scopedPropertyKey]?.isLoading === undefined
					? true
					: !!state.properties[scopedPropertyKey]?.isLoading,
			error: state.properties[scopedPropertyKey]?.error || null,
		};
	},
});

export const useProjectPropertyCallbacks = createHook(ProjectPPropertiesStore, {
	selector: (state) => state.callbacks,
});

export const useIssueCreationEnabled = (): RemoteProperty<boolean> =>
	// @ts-expect-error - TS2322 - this is too difficult for TS
	useProjectProperty(ProjectProperties.IDEA_CREATION_BY_CONTRIBUTORS)[0];

export const useSaveIssueCreationEnabled = () => {
	const [, { saveProperty }] = useProjectPropertiesActions();
	const [{ onSubmitIdeaFormEnabled }] = useProjectPropertyCallbacks();
	return (isEnabled: boolean) => {
		saveProperty(ProjectProperties.IDEA_CREATION_BY_CONTRIBUTORS, isEnabled);
		onSubmitIdeaFormEnabled && onSubmitIdeaFormEnabled(isEnabled);
	};
};

export const useSubmitIdeaFormView = (): RemoteProperty<string> =>
	// @ts-expect-error - TS2322 - this is too difficult for TS
	useProjectProperty(ProjectProperties.SUBMIT_IDEA_FORM_VIEW)[0];

export const useSaveSubmitIdeaFormViewId = () => {
	const [, { saveProperty }] = useProjectPropertiesActions();
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	return (viewId: string | void) => saveProperty(ProjectProperties.SUBMIT_IDEA_FORM_VIEW, viewId);
};

export const useSubmitIdeaFormMandatoryFields = (): RemoteProperty<boolean> =>
	// @ts-expect-error - TS2322 - this is too difficult for TS
	useProjectProperty(ProjectProperties.SUBMIT_IDEA_FORM_MANDATORY_FIELDS)[0];

export const useSaveSubmitIdeaFormMandatoryFields = () => {
	const [, { saveProperty }] = useProjectPropertiesActions();
	return (areFieldsMandatory: boolean) =>
		saveProperty(ProjectProperties.SUBMIT_IDEA_FORM_MANDATORY_FIELDS, areFieldsMandatory);
};

export const useSubmitIdeaFormDescriptionTemplate = (): RemoteProperty<string> =>
	// @ts-expect-error - TS2322 - this is too difficult for TS
	useProjectProperty(ProjectProperties.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE)[0];

export const useSaveSubmitIdeaDescriptionTemplateId = () => {
	const [, { saveProperty }] = useProjectPropertiesActions();
	return (templateId: string | undefined) =>
		saveProperty(ProjectProperties.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE, templateId);
};

const handlePropsChange =
	() =>
	(
		{ dispatch, setState }: StoreActionApi<State>,
		{ preloadProperties, onSubmitIdeaFormEnabled }: Props,
	) => {
		setState({
			callbacks: {
				onSubmitIdeaFormEnabled,
			},
		});
		preloadProperties?.forEach((propertyKey) =>
			dispatch(
				actions.loadProperty(
					propertyKey,
					propertyKey === ProjectProperties.IDEA_CREATION_BY_CONTRIBUTORS
						? (value) => onSubmitIdeaFormEnabled && onSubmitIdeaFormEnabled(value === true)
						: undefined,
				),
			),
		);
	};

export const ProjectPropertiesContainer = createContainer<State, Actions, Props>(
	ProjectPPropertiesStore,
	{
		onInit: handlePropsChange,
		onUpdate: handlePropsChange,
	},
);
