import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	header: {
		id: 'polaris-lib-matrix.matrix-content.empty-content.header',
		defaultMessage: 'There are no ideas to show on the matrix',
		description: 'Shown if the matrix view is empty',
	},
	axis: {
		id: 'polaris-lib-matrix.matrix-content.empty-content.axis',
		defaultMessage: 'Add ideas to the view to get started',
		description:
			'Shown if the matrix view does not have any bubbles because no ideas have those corresponding axis field values',
	},
	filters: {
		id: 'polaris-lib-matrix.matrix-content.empty-content.filters',
		defaultMessage: 'Try with different filters to see ideas in this view.',
		description:
			'Shown if the matrix view does not have any bubbles because everything is filtered out',
	},
	emptyMatrix: {
		id: 'polaris-lib-matrix.matrix-content.empty-content.empty-matrix',
		defaultMessage: 'empty matrix',
		description: 'No search results empty matrix image alt tag',
	},
});
