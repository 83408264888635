import React, { type ReactElement } from 'react';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing';
import { EditExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking/src/editing';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { getApplicationForProject, getEdition } from '@atlassian/jira-shared-types';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller';

export const IssueViewExperienceTrackingProviders = ({
	analyticsSource,
	children,
	isExperienceReady,
	issueKey,
}: {
	analyticsSource: AnalyticsSource;
	children: ReactElement;
	isExperienceReady: boolean;
	issueKey: string;
}) => {
	const { data } = useProjectContext();
	const appEditions = useAppEditions();

	const projectType = data?.projectType;
	const application = projectType ? getApplicationForProject(projectType) : null;
	const edition = application ? getEdition(application, appEditions) : null;

	return (
		<ViewExperienceTrackingProvider
			experience="viewIssue"
			experienceId={issueKey}
			application={application}
			edition={edition}
			analyticsSource={analyticsSource}
			isExperienceReady={isExperienceReady}
		>
			<EditExperienceTrackingProvider
				experience="editIssue"
				application={application}
				edition={edition}
				analyticsSource={analyticsSource}
			>
				{children}
			</EditExperienceTrackingProvider>
		</ViewExperienceTrackingProvider>
	);
};
