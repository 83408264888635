import type { Action } from '@atlassian/react-sweet-state';
import { fetchViewPermissionsForCurrentUser } from '../../../../services/load-permissions';
import type { State, Props } from '../../types';

export const loadViewPermissionsForCurrentUser =
	(viewId: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		const { canView, canEdit } = await fetchViewPermissionsForCurrentUser(viewId);

		// this avoids duplicating the permissions in the store when we fetch them again
		const existingPermissionsWithoutTheseKeys = getState().permissions.filter(
			(p) => p.key !== `${viewId}.view` && p.key !== `${viewId}.edit`,
		);

		const updatedPermissions = [
			...existingPermissionsWithoutTheseKeys,
			{
				key: `${viewId}.view`,
				havePermission: canView,
			},
			{
				key: `${viewId}.edit`,
				havePermission: canEdit,
			},
		];

		setState({
			permissions: updatedPermissions,
		});
	};
