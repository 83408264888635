import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { transformCommentUser } from '@atlassian/jira-polaris-remote-legacy-project/src/controllers/fetch-plays';
import type { TransformablePolarisPlayContribution } from '@atlassian/jira-polaris-remote-play-contribution/src/services/polaris-api/play-contribution/fetch/types.tsx';

type Fn = (arg: TransformablePolarisPlayContribution) => PolarisPlayContribution;

export const transformPlayContribution: Fn = (playContribution) => {
	const { aaid, subject, id, amount, comment, created, updated, account } = playContribution;

	const author = transformCommentUser(account);

	return {
		aaid,
		subject,
		id,
		amount: amount ?? 0,
		created,
		updated,
		author,
		comment: comment
			? {
					id: comment.id,
					updated: comment.updated,
					created: comment.created,
					body: comment.content,
					updateAuthor: undefined,
				}
			: undefined,
	};
};
