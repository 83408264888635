import { useEffect, useState } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log';
import type { Ari } from '@atlassian/jira-platform-ari';
import { useFetchEarliestOnboardedProject } from './services/get-earliest-onboarded-project-timestamp';

// Returns null in case of loading status
export const useShouldShowOnboardingExperience = (projectAri: Ari | null): boolean | null => {
	const [fetchEarliestOnboardedProjectTimeStamp] = useFetchEarliestOnboardedProject();
	const [earliestOnboardedProjectTemplate, setEarliestOnboardedProjectTemplate] = useState<
		undefined | null | string
	>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!projectAri) {
			return;
		}

		fetchEarliestOnboardedProjectTimeStamp(projectAri)
			.then(({ template }) => setEarliestOnboardedProjectTemplate(template))
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					'polaris.onboarding.experiment-check.error',
					'Failed to fetch earliest onboarded project for current cloud id',
					error,
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [fetchEarliestOnboardedProjectTimeStamp, projectAri]);

	if (isLoading) {
		return null;
	}

	return earliestOnboardedProjectTemplate === null || earliestOnboardedProjectTemplate === 'v17';
};
