import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { findView, modifyView, updateProjectState } from '../utils';

export const deleteView =
	(localId: LocalViewId): Action<State, Props> =>
	async (
		{ setState, getState },
		{ navigationRemote, cloudId, projectId, onFailure, onSuccess },
	) => {
		if (
			!cloudId ||
			!projectId ||
			!navigationRemote ||
			getState().projects[projectId]?.isLoading ||
			!getState().projects[projectId]?.initialized
		) {
			return;
		}

		const { view } = findView(getState().projects[projectId], localId);

		if (!view) {
			return;
		}

		setState(
			modifyView(getState(), projectId, (viewToModify) => {
				if (viewToModify.id === localId) {
					return {
						...viewToModify,
						isLocked: true,
					};
				}
				return viewToModify;
			}),
		);

		try {
			await navigationRemote.deleteView({ viewId: view.id });
			setState(
				updateProjectState(getState(), projectId, {
					views: getState()
						.projects[projectId].views.filter((innerView) => innerView.localId !== localId)
						.map((childView) => {
							if (childView.views) {
								return {
									...childView,
									views: childView.views?.filter((innerView) => innerView.localId !== localId),
								};
							}
							return childView;
						}),
				}),
			);
			onSuccess?.('deleteView');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState(
				modifyView(getState(), projectId, (innerView) => {
					if (innerView.localId === localId) {
						return {
							...innerView,
							isLocked: false,
						};
					}
					return innerView;
				}),
			);

			onFailure?.(error, 'deleteView');
		}
	};
