import React, {
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	memo,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	issueKey: string;
	summary: string;
};

type ShortLabelContextType = {
	notifyOfKeyContainerWidth: (arg1: number) => void;
};

const ShortLabelContext = createContext<ShortLabelContextType>({
	notifyOfKeyContainerWidth: noop,
});

/**
 * needs to have an AlignedShortLabelContext somewhere above in the dom hierarchy
 * in order to correctly align the key divs.
 */
export const ShortLabel = memo<Props>(({ issueKey, summary }: Props) => {
	const { notifyOfKeyContainerWidth } = useContext(ShortLabelContext);
	return (
		<Container>
			<Key
				ref={(ref) => {
					if (ref !== null) {
						notifyOfKeyContainerWidth(ref.getBoundingClientRect().width);
					}
				}}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="short-label-key-container"
			>
				{issueKey}
			</Key>
			<Summary>{summary}</Summary>
		</Container>
	);
});

/**
 * use this anywhere in the dom hierarchy above the place where you want to coordinate
 * issue short labels. This provides context to all ShortLabel elements below and computes
 * the correct issue key div width.
 */
export const AlignedShortLabelContext = ({ children }: { children: ReactNode }) => {
	const [maxKeyContainerWidth, setMaxKeyContainerWidth] = useState(0);

	const notifyOfKeyContainerWidth = useCallback(
		(width: number) => {
			if (width > maxKeyContainerWidth) {
				setMaxKeyContainerWidth(width);
			}
		},
		[maxKeyContainerWidth, setMaxKeyContainerWidth],
	);

	return (
		<AlignedShortLabelContextContainer minWidth={maxKeyContainerWidth}>
			<ShortLabelContext.Provider
				value={{
					notifyOfKeyContainerWidth,
				}}
			>
				{children}
			</ShortLabelContext.Provider>
		</AlignedShortLabelContextContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
	flex: '0 0 auto',
	marginRight: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Summary = styled.div({
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignedShortLabelContextContainer = styled.div<{ minWidth: string | number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div.short-label-key-container': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		minWidth: ({ minWidth }) => `${minWidth}px`,
	},
});
