import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { fg } from '@atlassian/jira-feature-gating';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

const NOT_EXISTING_FIELD = '';

type HandleDragDropSortFieldsArgs = {
	srcId: string;
	dstId: string;
	edge: Edge;
	selectedFields: Field[];
	onFieldsSort?: (fieldKey: string, movedToKey: string) => void;
};

export const handleDragDropFieldsSort = ({
	srcId,
	dstId,
	edge,
	selectedFields,
	onFieldsSort,
}: HandleDragDropSortFieldsArgs) => {
	const srcIdx = selectedFields.findIndex(({ key }) => key === srcId);
	const dstIdx = selectedFields.findIndex(({ key }) => key === dstId);

	// Field can be removed while it's being dragged by another user
	// so when `handleDragDropSortFields` will be called
	// `srcId` won't exist anymore in `selectedFields`
	if (srcIdx === -1 && fg('polaris_fix_fields_drag_drop_runtime_error')) {
		const inRange = dstIdx + 1 < selectedFields.length;
		const dstAdj = edge === 'bottom' && inRange ? 1 : 0;
		onFieldsSort?.(
			srcId,
			edge === 'bottom' && !inRange ? NOT_EXISTING_FIELD : selectedFields[dstIdx + dstAdj].key,
		);
		return;
	}

	let dstAdj = srcIdx < dstIdx && edge === 'top' ? -1 : 0;
	dstAdj = srcIdx > dstIdx && edge === 'bottom' ? 1 : dstAdj;
	onFieldsSort?.(selectedFields[srcIdx].key, selectedFields[dstIdx + dstAdj].key);
};
