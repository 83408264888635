export const B100 = '#E9F2FF';
export const B200 = '#CCE0FF';
export const B300 = '#85B8FF';
export const B400 = '#579DFF';
export const B500 = '#388BFF';
export const B600 = '#1D7AFC';
export const B700 = '#0C66E4';
export const B800 = '#0055CC';
export const B900 = '#09326C';
export const B1000 = '#082145';

export const R100 = '#FFEDEB';
export const R200 = '#FFD2CC';
export const R300 = '#FF9A8F';
export const R400 = '#F97362';
export const R500 = '#F35844';
export const R600 = '#E8422C';
export const R700 = '#CE2E1C';
export const R800 = '#B22515';
export const R900 = '#601D16';
export const R1000 = '#391713';

export const Y100 = '#FFF7D6';
export const Y200 = '#FFE785';
export const Y300 = '#FFD138';
export const Y400 = '#EBB800';
export const Y500 = '#D19D00';
export const Y600 = '#B38600';
export const Y700 = '#946F00';
export const Y800 = '#7F5F01';
export const Y900 = '#533F04';
export const Y1000 = '#3D2E00';

export const G100 = '#DFFCF0';
export const G200 = '#AFF3D6';
export const G300 = '#76E0B4';
export const G400 = '#3ACF91';
export const G500 = '#2ABB7F';
export const G600 = '#22A06B';
export const G700 = '#1F845A';
export const G800 = '#216E4E';
export const G900 = '#164B35';
export const G1000 = '#133527';

export const P100 = '#F3F0FF';
export const P200 = '#DFD8FD';
export const P300 = '#B8ACF6';
export const P400 = '#9F8FEF';
export const P500 = '#8F7EE7';
export const P600 = '#8270DB';
export const P700 = '#6E5DC6';
export const P800 = '#5E4DB2';
export const P900 = '#352C63';
export const P1000 = '#231C3F';

export const T100 = '#E3FAFC';
export const T200 = '#ADEEF5';
export const T300 = '#74DBE7';
export const T400 = '#53C8D5';
export const T500 = '#37B4C3';
export const T600 = '#1D9AAA';
export const T700 = '#1D7F8C';
export const T800 = '#206B74';
export const T900 = '#1D474C';
export const T1000 = '#153337';

export const O100 = '#FFF5EB';
export const O200 = '#FFDEB8';
export const O300 = '#FEC176';
export const O400 = '#FFA333';
export const O500 = '#F88A0D';
export const O600 = '#DB6E00';
export const O700 = '#B85C00';
export const O800 = '#974F0C';
export const O900 = '#5F3811';
export const O1000 = '#43290F';

export const M100 = '#FFECF8';
export const M200 = '#FDD0EC';
export const M300 = '#F797D2';
export const M400 = '#E774BB';
export const M500 = '#DA62AC';
export const M600 = '#CD519D';
export const M700 = '#AE4787';
export const M800 = '#943D73';
export const M900 = '#50253F';
export const M1000 = '#341829';

export const DN0 = '#161A1D';
export const DN100 = '#1D2125';
export const DN100A = '#BCD6F00A';
export const DN200 = '#22272B';
export const DN200A = '#A1BDD914';
export const DN300 = '#2C333A';
export const DN300A = '#A6C5E229';
export const DN400 = '#454F59';
export const DN400A = '#BFDBF847';
export const DN500 = '#5A6977';
export const DN500A = '#A3C0DB7A';
export const DN600 = '#738496';
export const DN700 = '#8696A7';
export const DN800 = '#9FADBC';
export const DN900 = '#B6C2CF';
export const DN1000 = '#C7D1DB';
export const DN1100 = '#DEE4EA';

export const N0 = '#FFFFFF';
export const N100 = '#F7F8F9';
export const N100A = '#091E4208';
export const N200 = '#F1F2F4';
export const N200A = '#091E420F';
export const N300 = '#DCDFE4';
export const N300A = '#091E4224';
export const N400 = '#B3B9C4';
export const N400A = '#091E424F';
export const N500 = '#8993A5';
export const N500A = '#091E427A';
export const N600 = '#758195';
export const N700 = '#626F86';
export const N800 = '#44546F';
export const N900 = '#2C3E5D';
export const N1000 = '#172B4D';
export const N1100 = '#091E42';
