import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	SpacerCompiled as Spacer,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	SkeletonCompiled as Skeleton,
} from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

export const InsightSkeleton = () => (
	<Block>
		<Skeleton height="12px" width="12px" />
		<Content>
			<Skeleton height="16px" width="350px" />
			<Spacer height="8px" />
			<Skeleton height="16px" width="400px" />
			<Spacer height="8px" />
			<Skeleton height="16px" width="140px" />
		</Content>
	</Block>
);

export const InsightsSkeleton = () => (
	<LoadingWrapper>
		<InsightSkeleton />
		<InsightSkeleton />
	</LoadingWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingWrapper = styled.div({
	padding: `${token('space.300', '24px')} 10px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Block = styled.div({
	display: 'flex',
	flexDirection: 'row',
	paddingTop: token('space.300', '24px'),
	marginBottom: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	marginLeft: token('space.200', '16px'),
	overflow: 'hidden',
});
