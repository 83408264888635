import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	default: {
		id: 'polaris-common.config.fields.field-item.delivery.default',
		defaultMessage: 'Default',
		description: 'Name of the default progress calculation strategy',
	},
	simple: {
		id: 'polaris-common.config.fields.field-item.delivery.simple',
		defaultMessage: 'Simple',
		description: 'Name of the simple progress calculation strategy',
	},
	issueCount: {
		id: 'polaris-common.config.fields.field-item.delivery.issue-count',
		defaultMessage: 'Issue count',
		description: 'Name of the default progress calculation mode',
	},
	storyPoints: {
		id: 'polaris-common.config.fields.field-item.delivery.story-points',
		defaultMessage: 'Story points',
		description: 'Name of the simple progress calculation mode',
	},
	header: {
		id: 'polaris-common.config.fields.field-item.delivery.header',
		defaultMessage: 'Options',
		description: 'Delivery progress field configuration section header',
	},
	statusDescription: {
		id: 'polaris-common.config.fields.field-item.delivery.status-description',
		defaultMessage:
			'Select how to display delivery status. Setting is shared with the delivery progress field.',
		description: 'Delivery status field configuration section hint/explanation',
	},
	progressDescription: {
		id: 'polaris-common.config.fields.field-item.delivery.progress-description',
		defaultMessage:
			'Select how to display delivery progress. Setting is shared with the delivery status field.',
		description: 'Delivery progress field configuration section hint/explanation',
	},
});
