import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.teams.title',
		defaultMessage: 'Teams app',
		description: 'Title of the polaris teams app',
	},
	description: {
		id: 'polaris-ideas.insights.insights.add-insight.create-insight-hint.apps.teams.description',
		defaultMessage: 'Add messages to ideas to keep track of requests and important conversations',
		description: 'Description of the polaris teams app',
	},
});
