import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	placeholder: {
		id: 'polaris-common.fields.insights.placeholder',
		defaultMessage: 'Add',
		description:
			'Table cell placeholder (comments field) when there are no issue comments yet for this issue.',
	},
});
