import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export type FilterOperator = 'LT' | 'LTE' | 'GT' | 'GTE' | 'EQ' | 'NEQ';

/** filter type ID for numeric field filter */
export const NUMERIC_FIELD_FILTER = 'NUMBER';

export const GENERIC_FIELD_FILTER = 'FIELD';

export const TEXT_FIELD_FILTER = 'TEXT';

export type FilterCommonProperties = {
	isTemporary?: boolean;
};

/**
 * Common field value filter. Selects the values (reduced to string) that should be shown for a particular field.
 */
export type FieldValueFilter = FilterCommonProperties & {
	type: typeof GENERIC_FIELD_FILTER;
	field: FieldKey;
	fieldType?: FieldType;
	values: {
		stringValue: string | undefined;
	}[];
};

/**
 * A generic text filter not bound to a single field.
 */
export type TextFilter = FilterCommonProperties & {
	type: typeof TEXT_FIELD_FILTER;
	values: {
		stringValue: string | undefined;
	}[];
	localId?: string;
};

export type NumericFieldFilterValue = {
	numericValue: number | undefined;
	operator: FilterOperator;
};

/**
 * Filter for numeric ranges on an individual number field
 */
export type NumericFieldFilter = FilterCommonProperties & {
	type: typeof NUMERIC_FIELD_FILTER;
	fieldType?: FieldType;
	field: FieldKey;
	values: NumericFieldFilterValue[];
};
export const END_AFTER_NOW = 'END_AFTER_NOW';
export const END_BEFORE_NOW = 'END_BEFORE_NOW';
export const START_AFTER_NOW = 'START_AFTER_NOW';
export const START_BEFORE_NOW = 'START_BEFORE_NOW';

export type IntervalFilterOperatorCode =
	| typeof END_AFTER_NOW
	| typeof END_BEFORE_NOW
	| typeof START_AFTER_NOW
	| typeof START_BEFORE_NOW;

export type IntervalFieldFilterOperator = {
	numericValue: number;
	operator: IntervalFilterOperatorCode;
};

/** filter type ID for interval field filter */
export const INTERVAL_FIELD_FILTER = 'INTERVAL';
export type IntervalFieldFilter = FilterCommonProperties & {
	type: typeof INTERVAL_FIELD_FILTER;
	field: FieldKey;
	values: IntervalFieldFilterOperator[];
};

export const QUARTER = 'QUARTER';
export const MONTH = 'MONTH';
export const DAY = 'DAY';

export type INTERVAL_PERIOD = typeof QUARTER | typeof MONTH | typeof DAY;

/**
 * all possible filter types. future extension point for other filters, potentially even a custom user JQL
 */
export type Filter = FieldValueFilter | TextFilter | NumericFieldFilter | IntervalFieldFilter;
