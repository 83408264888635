import React, { memo, useRef } from 'react';
import { styled } from '@compiled/react';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { WeightTag } from '@atlassian/jira-polaris-component-weight-tag/src/ui/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { OptionProperty } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIsWeightedField } from '../../../../controllers/issue/selectors/fields-hooks';
import { DynamicOptionDecoratedTag } from '../../../common/decoration/decoration/tag/index.tsx';
import { ObservableValuesList } from '../../observable-values-list';

export type SelectViewProps = {
	isActive?: boolean;
	isMultiline?: boolean;
	isEditable: boolean;
	fieldKey: FieldKey;
	options: OptionProperty[];
	placeholder?: string | undefined;
	containerRef?: RefObject<HTMLDivElement | null>;
};

const SelectViewNonObservable = memo(({ isEditable, fieldKey, options }: SelectViewProps) => {
	const isWeightTagVisible = useIsWeightedField(fieldKey);

	return (
		<MultiValueContainer isEditable={isEditable}>
			{isWeightTagVisible && (
				<WeightTagContainer>
					<WeightTag options={options} />
				</WeightTagContainer>
			)}
			<OptionsContainerWrapper>
				{options.map(({ id }) => (
					<DynamicOptionDecoratedTag fieldKey={fieldKey} key={id} id={id} />
				))}
			</OptionsContainerWrapper>
		</MultiValueContainer>
	);
});

const SelectViewObservable = memo(
	({ isActive, isEditable, isMultiline, containerRef, fieldKey, options }: SelectViewProps) => {
		const isWeightTagVisible = useIsWeightedField(fieldKey);
		const listRef = useRef(null);

		return (
			<MultiValueContainer isEditable={isEditable}>
				{isWeightTagVisible && (
					<WeightTagContainer>
						<WeightTag options={options} />
					</WeightTagContainer>
				)}
				<OptionsContainerWrapper ref={listRef} isMultiline={isMultiline}>
					<ObservableValuesList listRef={listRef} isActive={isActive} containerRef={containerRef}>
						{options.map(({ id }) => (
							<DynamicOptionDecoratedTag key={id} fieldKey={fieldKey} id={id} />
						))}
					</ObservableValuesList>
				</OptionsContainerWrapper>
			</MultiValueContainer>
		);
	},
);

export const SelectView = memo(
	({
		isActive,
		isEditable,
		isMultiline,
		fieldKey,
		placeholder,
		options,
		containerRef,
	}: SelectViewProps) => {
		if (options.length === 0) {
			return (
				<PlaceholderContainer isEditable={isEditable}>
					{placeholder !== undefined ? placeholder : null}
				</PlaceholderContainer>
			);
		}

		if (options.length === 1) {
			return (
				<SelectViewNonObservable fieldKey={fieldKey} isEditable={isEditable} options={options} />
			);
		}

		return (
			<SelectViewObservable
				fieldKey={fieldKey}
				isActive={isActive}
				isEditable={isEditable}
				isMultiline={isMultiline}
				options={options}
				containerRef={containerRef}
			/>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => (isEditable ? 'pointer' : 'auto'),
	lineHeight: '16px',
	padding: `9px ${token('space.075', '6px')} 9px`,
	minHeight: '16px',
	color: token('color.text.subtlest', N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiValueContainer = styled.div<{ isEditable: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isEditable }) => isEditable && 'pointer',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	lineHeight: 1,
	overflow: 'hidden',
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: '1fr',
	gap: '0px 0px',
	gridTemplateAreas: '"weight options"',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionsContainerWrapper = styled.div<{ isMultiline?: boolean }>({
	gridArea: 'options',
	display: 'flex',
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isMultiline }) => !isMultiline && 'max-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ isMultiline }) => !isMultiline && 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WeightTagContainer = styled.div({
	margin: token('space.050', '4px'),
});
