import React, { forwardRef, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import Button from '@atlaskit/button/new';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import Popup, { type PopupProps, type PopupComponentProps } from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDecorator } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/index.tsx';
import type { FieldDecoratorProps } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/types.tsx';
import messages from './messages';

const VIEWPORT_MARGIN = 8;
const OPTION_ITEM_HEIGHT_WITH_MARGINS = 48;

type Props = {
	isOpen: boolean;
	isDeleteDisabled: boolean;
	deleteOptionTooltip?: string;
	onClose: () => void;
	onDeleteOption: () => void;
} & Pick<PopupProps, 'trigger'> &
	Omit<
		FieldDecoratorProps,
		'outerSpacing' | 'containerBackgroundColor' | 'preventFocusPropagation'
	>;

export const FieldDecorationPopup = ({
	isOpen,
	isDeleteDisabled,
	deleteOptionTooltip,
	onClose,
	onDeleteOption,
	trigger,
	...fieldDecoratorProps
}: Props) => {
	const { formatMessage } = useIntl();
	const [popupContentElement, setPopupContentElement] = useState<HTMLDivElement | null>(null);
	const [maxPopupHeight, setMaxPopupHeight] = useState<number | undefined>(undefined);
	const [placement, setPlacement] = useState<'top' | 'bottom'>('bottom');
	const [isPopupVisible, setIsPopupVisible] = useState(false);

	useEffect(() => {
		if (isOpen && popupContentElement) {
			defer(() => {
				const popupContentRect = popupContentElement.getBoundingClientRect();
				const spaceDifference = window.innerHeight - popupContentRect.top;

				if (spaceDifference < popupContentRect.top - OPTION_ITEM_HEIGHT_WITH_MARGINS) {
					setPlacement('top');
					setMaxPopupHeight(
						popupContentRect.top - OPTION_ITEM_HEIGHT_WITH_MARGINS - VIEWPORT_MARGIN,
					);
				} else {
					setMaxPopupHeight(spaceDifference - VIEWPORT_MARGIN);
				}

				setIsPopupVisible(true);
			});
		} else {
			setMaxPopupHeight(undefined);
			setPlacement('bottom');
			setIsPopupVisible(false);
		}
	}, [isOpen, popupContentElement]);

	return (
		<Popup
			isOpen={isOpen}
			onClose={onClose}
			placement={placement}
			popupComponent={CustomPopupComponent}
			autoFocus={false}
			content={() => (
				<PopupContainer
					ref={setPopupContentElement}
					maxHeight={maxPopupHeight}
					isVisible={isPopupVisible}
				>
					<FieldDecorator
						{...fieldDecoratorProps}
						nameLabel={formatMessage(messages.optionNameLabel)}
						outerSpacing="0"
						containerBackgroundColor="transparent"
						isInPopup
					/>
					<Separator />
					<Tooltip content={deleteOptionTooltip}>
						<Button
							testId="polaris-lib-field-option-configuration.ui.field-decoration-popup.delete-option-button"
							onClick={onDeleteOption}
							iconBefore={EditorRemoveIcon}
							appearance="subtle"
							spacing="compact"
							isDisabled={isDeleteDisabled}
						>
							{formatMessage(messages.deleteOption)}
						</Button>
					</Tooltip>
				</PopupContainer>
			)}
			trigger={trigger}
		/>
	);
};

const CustomPopupComponent = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => (
		<div {...props} ref={ref}>
			{children}
		</div>
	),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupContainer = styled.div<{ maxHeight?: number; isVisible: boolean }>({
	maxWidth: '324px',
	padding: token('space.200', '16px'),
	borderRadius: token('border.radius', '3px'),
	boxShadow: token('elevation.shadow.overlay', '0px 8px 12px #091e423f, 0px 0px 1px #091e424f'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	background: token('elevation.surface', colors.N0),
	overflowY: 'auto',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ maxHeight }) => (maxHeight ? `${maxHeight}px` : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isVisible }) => (isVisible ? 1 : 0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Separator = styled.hr({
	borderBottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: token('color.border', colors.N200A),
	marginTop: token('space.100', '8px'),
	marginBottom: token('space.200', '16px'),
});
