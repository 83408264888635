import { SpotlightTypes } from '../common/types';
import type { RemoteState } from './types';

export const ONBOARDING_USER_PROPERTY = 'polaris.onboarding';
export const SHOULD_SEE_ONBOARDING_USER_PROPERTY = 'polaris.should-see-onboarding-spotlights';
export const PRODUCT_START_TIMESTAMP = 'polaris.product-start-timestamp';

export const initialRemoteState: RemoteState = {
	spotlights: {
		[SpotlightTypes.CREATE_IDEA]: false,
		[SpotlightTypes.CREATE_INSIGHT]: false,
		[SpotlightTypes.CREATE_DELIVERY_TICKET]: false,
		[SpotlightTypes.JOIN_COMMUNITY]: false,
	},
	isVideoButtonInHelpPanelClicked: false,
};
