import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';
import { closeDialog } from '../close-dialog';

export const saveConfig =
	(): Action<State, Props> =>
	({ getState, setState, dispatch }, { insightsRemote }: Props) => {
		const { config, oauthClientId, snippetData, onConfigChanged } = getState();
		const groupId = snippetData?.group?.id || null;
		if (oauthClientId === null || snippetData === null || groupId === null || config === null) {
			return;
		}
		setState({
			isSaving: true,
		});
		insightsRemote
			.updateSnippetPropertiesConfig?.({
				oauthClientId,
				groupId,
				config,
			})
			.then(() => {
				dispatch(closeDialog());
				if (onConfigChanged !== null) {
					onConfigChanged();
				}
			})
			.catch((error: Error) => {
				setState({
					saveError: error,
				});
			})
			.finally(() => {
				setState({
					isSaving: false,
				});
			});
	};
