import {
	createContainer,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import actions from './actions';
import type { Props, State } from './types';

type Actions = typeof actions;

const initialState: State = {
	properties: {},
};

export const UserPropertiesStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisUserPropertiesStore',
});

export const useUserPropertiesActions = createHook(UserPropertiesStore, {
	selector: null,
});

export const useUserProperty = createHook(UserPropertiesStore, {
	selector: (state, propertyKey: string) => {
		const value =
			state.properties[propertyKey]?.isLoading === false &&
			state.properties[propertyKey]?.value !== undefined
				? state.properties[propertyKey]?.value
				: null;
		return {
			value,
			isLoading:
				state.properties[propertyKey]?.isLoading === undefined
					? true
					: state.properties[propertyKey]?.isLoading,
			error: state.properties[propertyKey]?.error || null,
		};
	},
});

const handlePropsChange =
	() =>
	({ dispatch }: StoreActionApi<State>, { preloadProperties }: Props) => {
		preloadProperties?.forEach((propertyKey: string) =>
			dispatch(actions.loadProperty(propertyKey)),
		);
	};

export const UserPropertiesContainer = createContainer<State, Actions, Props>(UserPropertiesStore, {
	onInit: handlePropsChange,
	onUpdate: handlePropsChange,
});
