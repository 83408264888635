import { useIsSharedView } from '../../environment';
import { useStatusCategoriesForPublishedView } from '../../views/selectors/view-hooks.tsx';
import type { State } from '../types';
import { createWorkflowHook } from './utils';

const getStatusCategories = (state: State) => state.statusCategories;

const useStatusCategoriesLocal = createWorkflowHook(getStatusCategories);

export const useStatusCategories = () => {
	const isSharedView = useIsSharedView();
	const statusCategoriesForPublished = useStatusCategoriesForPublishedView();
	const statusCategories = useStatusCategoriesLocal();

	return isSharedView ? statusCategoriesForPublished : statusCategories;
};
