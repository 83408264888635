import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { setCurrentViewAccessLevel } from './access-level';
import { setAutosaveEnabled, setDraft, saveUnsavedChanges } from './autosave';
import { resetDraft } from './autosave/reset-draft/index.tsx';
import { cloneView, cloneCurrentView, saveCurrentUnsavedViewAsNew } from './clone';
import { resizeFieldColumn, setHoveredFieldKeyForBulkEdit } from './column';
import {
	createViewComment,
	updateViewComment,
	deleteViewComment,
	seenViewComments,
} from './comments';
import { loadViewComments } from './comments/load-view-comments/index.tsx';
import { createNewView } from './create';
import { setCurrentView } from './current';
import { deleteView, deleteViewFromState } from './delete';
import { handleDeliveryDataFetchError } from './delivery-data';
import { changeViewDescription } from './description';
import { setIsExporting, setIsReadyToExport } from './export';
import {
	removeFieldFromView,
	setViewColumns,
	sortViewField,
	hideViewField,
	toggleViewField,
} from './fields';
import { setRollupFieldsValue } from './fields-rollups';
import {
	clearAllFilters,
	clearAllTemporaryFilters,
	updateFieldFilter,
	clearFieldFilter,
	clearFieldOrNumericFilter,
	updateNumericFilter,
	updateQuickSearchFilter,
	updateFilters,
} from './filter';
import { updateIntervalFilter } from './filter/interval/index.tsx';
import {
	setGroupBy,
	setVerticalGroupBy,
	setGroupValues,
	setVerticalGroupValues,
	setHideEmptyGroups,
	setHideEmptyColumns,
} from './group';
import { clearSelection, setSelection, toggleSelection } from './issue-selection';
import { loadViewLastViewed, updateLastViewedState } from './last-viewed';
import { setLayoutType } from './layout';
import { loadViews, loadView } from './load';
import { loadViewMarkers, createViewMarker, updateViewMarker, deleteViewMarker } from './markers';
import { setMatrixXAxis, setMatrixYAxis, setMatrixZAxis } from './matrix-options';
import {
	setFieldValues,
	setFieldValuesReverseOrder,
} from './matrix-options/field-options/index.tsx';
import { switchMatrixXAndYAxis } from './matrix-options/switch-axes/index.tsx';
import { setHasManageCurrentViewPermission } from './permissions';
import { rankViews, refreshViewsRanks } from './rank';
import {
	createSection,
	deleteSection,
	saveSection,
	deleteSectionFromState,
	loadSection,
} from './sections';
import { setViewSets } from './set';
import { setForceReloadViewOnNextUpdate } from './set-force-reload';
import {
	moveIssuesToTopOfCurrentView,
	moveIssueInCurrentView,
	setSortFieldsOfCurrentView,
	setSortModeOfCurrentViewToProjectRank,
	setSortModeOfCurrentViewToViewRank,
	insertIssueAtIndexInCurrentView,
} from './sort';
import { toogleSwimlane, toggleAllSwimlanes, initSwimlanes } from './swimlanes';
import { syncViewsWithNavigation } from './sync';
import {
	setTimelineMode,
	setStartDateField,
	setEndDateField,
	setStartTimelineIntervalDate,
	setEndTimelineIntervalDate,
	setSummaryCardField,
} from './timeline';
import {
	refreshArrangementInformation,
	updateArrangementInformation,
	setArrangementInformation,
} from './timeline/arrangement-information/index.tsx';
import {
	renameCurrentView,
	setEditCurrentViewTitle,
	renameView,
	addViewEmoji,
	addCurrentViewEmoji,
} from './title';
import { setUnsavedChanges } from './unsaved-changes';

export const actions = {
	setGroupBy,
	setHideEmptyColumns,
	updateFilters,
	setHideEmptyGroups,
	setStartDateField,
	setEndDateField,
	setStartTimelineIntervalDate,
	setEndTimelineIntervalDate,
	setVerticalGroupBy,
	setVerticalGroupValues,
	setGroupValues,
	setFieldValues,
	setFieldValuesReverseOrder,
	setViewColumns,
	sortViewField,
	hideViewField,
	toggleViewField,
	removeFieldFromView,
	setSortFieldsOfCurrentView,
	setSortModeOfCurrentViewToProjectRank,
	setSortModeOfCurrentViewToViewRank,
	moveIssueInCurrentView,
	moveIssuesToTopOfCurrentView,
	insertIssueAtIndexInCurrentView,
	setCurrentView,
	deleteView,
	deleteViewFromState,
	renameView,
	addViewEmoji,
	renameCurrentView,
	addCurrentViewEmoji,
	rankViews,
	refreshViewsRanks,
	setEditCurrentViewTitle,
	cloneView,
	cloneCurrentView,
	saveCurrentUnsavedViewAsNew,
	createNewView,
	updateFieldFilter,
	clearFieldFilter,
	clearFieldOrNumericFilter,
	updateNumericFilter,
	updateQuickSearchFilter,
	clearAllFilters,
	clearAllTemporaryFilters,
	clearSelection,
	toggleSelection,
	setSelection,
	changeViewDescription,
	toogleSwimlane,
	toggleAllSwimlanes,
	initSwimlanes,
	createSection,
	saveSection,
	deleteSection,
	deleteSectionFromState,
	loadSection,
	loadViewComments,
	createViewComment,
	updateViewComment,
	deleteViewComment,
	seenViewComments,
	loadViewLastViewed,
	updateLastViewedState,
	resizeFieldColumn,
	setMatrixXAxis,
	setMatrixYAxis,
	setMatrixZAxis,
	switchMatrixXAndYAxis,
	setTimelineMode,
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
	setIsExporting,
	setIsReadyToExport,
	setUnsavedChanges,
	refreshArrangementInformation,
	updateArrangementInformation,
	setArrangementInformation,
	updateIntervalFilter,
	setAutosaveEnabled,
	resetDraft,
	setDraft,
	saveUnsavedChanges,
	setHoveredFieldKeyForBulkEdit,
	setRollupFieldsValue,
	loadView,
	setLayoutType,
	handleDeliveryDataFetchError,
	setSummaryCardField,
	syncViewsWithNavigation,
	loadViewMarkers,
	createViewMarker,
	updateViewMarker,
	deleteViewMarker,
	setForceReloadViewOnNextUpdate,
	setHasManageCurrentViewPermission,
	setCurrentViewAccessLevel,
} as const;

export { actions as default, setViewSets, loadViews };
