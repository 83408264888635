import mapValues from 'lodash/mapValues';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { isOptimisticInsight } from '../utils/optimistic-updates';

const optimisicEmptySnippetRefreshState = {
	__typename: 'PolarisRefreshInfo',
	error: undefined,
	errorType: undefined,
	last: undefined,
	next: undefined,
} as const;

const updateSnippet = (
	state: State,
	snippetId: string,
	update: (arg1: Snippet) => Snippet,
): State => ({
	...state,
	insights: mapValues(state.insights, (insight) => {
		let { updated } = insight;
		const snippets = insight.snippets.map((snippet) => {
			if (snippet.id !== snippetId) {
				return snippet;
			}
			const updatedSnippet = update(snippet);
			updated = updatedSnippet.updated;
			return updatedSnippet;
		});

		return {
			...insight,
			updated,
			snippets,
		};
	}),
});

export const refreshSnippet =
	(snippetId: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { insightsRemote }: Props) => {
		if (isOptimisticInsight(snippetId)) {
			return;
		}

		setState(
			updateSnippet(getState(), snippetId, (snippet) => ({
				...snippet,
				// @ts-expect-error - TS2322 - Type '{ queued: string; __typename: "PolarisRefreshInfo"; last: string | null; next: string | null; error: string | null; errorType: PolarisRefreshError | null; } | { ...; }' is not assignable to type 'jira_polaris_ReadInsights_polarisInsights_snippets_refresh | null'.
				refresh: {
					...(snippet.refresh || optimisicEmptySnippetRefreshState),
					queued: 'manual',
				},
			})),
		);

		insightsRemote.refreshSnippet?.({ id: snippetId }).then((snippets) => {
			setState(
				snippets.reduce(
					(acc, snippet) => updateSnippet(acc, snippet.id, () => snippet),
					getState(),
				),
			);
		});
	};
