import React from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

type Props = {
	onClick: () => void;
};

export const AddAsLinkButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Button
			testId="polaris-ideas.ui.insights.insights.add-insight.unfurl-form.add-as-link.button"
			appearance="subtle-link"
			onClick={onClick}
		>
			{formatMessage(messages.addAsLinkButton)}
		</Button>
	);
};
