import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noDescription: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.cell.description.no-description',
		defaultMessage: 'No description',
		description:
			'Placeholder text to show in the Description column in a field does not have a description',
	},
});
