import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { getADGTimestampDisplaySetting, RELATIVE, getRelativeValueAndUnit } from './utils';

// TODO: these constant strings were floating around different places so i pulled them all here
// however, a fixed date format for all locales seems very wrong to me. potentially an i18n bug
export const DATETIME_FORMAT = 'LLL d, yyyy hh:mm a';
export const DATE_FORMAT = 'LLL d, yyyy';

export const DATE_FORMAT_DFNS = 'MMM D, YYYY';

type FormattedDateProps = {
	date: string | number;
};

export const useAdgCompliantRelativeDate = (date?: string | number | null): string | undefined => {
	const { formatDate, formatRelativeTime } = useIntl();
	return useMemo(() => {
		if (date === null || date === undefined) {
			return undefined;
		}
		if (getADGTimestampDisplaySetting(date) === RELATIVE) {
			return formatRelativeTime(...getRelativeValueAndUnit(date));
		}
		return formatDate(date, {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}, [date, formatDate, formatRelativeTime]);
};

export const FormattedDate = ({ date }: FormattedDateProps) => {
	const formatted = useAdgCompliantRelativeDate(date);
	return <>{formatted}</>;
};
