import type { ComponentType, ReactNode } from 'react';
import type { AxisLayoutMode } from './ui/axis/types';

/**
 * Identifier for values spread on the axis. Axis range definitions share that id
 * with the x/y values on the items. This allows us to map discrete values onto
 * the free interval of the entire axis
 */
export type AxisValue = string | number;

/**
 * Identifier for an item/bubble on the chart. Used for renderer props and d&d.
 */
export type ItemId = string;

type DiscreteAxisRange = {
	values: AxisValue[];
};

export type StepConfig = {
	/**
	 * max number of steps
	 */
	maxStepCount?: number;
	/**
	 * minimum width in pixel
	 */
	minStepWidth?: number;
};

export type ContinuousAxisRange = {
	min: number;
	max: number;
	isReverseOrder?: boolean;
	stepConfig?: StepConfig;
};

export const DISCRETE = 'DISCRETE';
export const CONTINUOUS = 'CONTINUOUS';

type AxisType = typeof DISCRETE | typeof CONTINUOUS;

type CommonAxisConfiguration = {
	type: AxisType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Label?: ComponentType<Record<any, any>>;
};

export type LabelComponentProps = {
	value: AxisValue;
	mode: AxisLayoutMode;
};

export type DnDTooltipProps = {
	itemIds: ItemId[];
	tooltipTitle?: string;
	xValue: AxisValue;
	yValue: AxisValue;
};

export type DiscreteAxis = CommonAxisConfiguration & {
	type: typeof DISCRETE;
	range: DiscreteAxisRange;
	components?: {
		Tooltip?: ComponentType<{
			value: AxisValue;
		}>;
		Label?: ComponentType<LabelComponentProps>;
	};
};

export type ContinuousAxis = CommonAxisConfiguration & {
	type: typeof CONTINUOUS;
	range: ContinuousAxisRange;
	components?: {
		Tooltip?: ComponentType<{
			value: number;
		}>;
		Label?: ComponentType<{
			value: number;
			mode: AxisLayoutMode;
		}>;
	};
};

export type Axis = DiscreteAxis | ContinuousAxis;

export type CenterPosition = {
	left: number;
	top: number;
};

/**
 * Props for item bubble renderers whth framework positioning
 */
export type ItemProps = {
	isHovered: boolean;
	isSelected: boolean;
	isDragging: boolean;
	isHighlighted: boolean;
	id: ItemId;
};

export type ItemWrapperProps = {
	itemIds: string[];
	children: ReactNode;
};

/**
 * Multiple items on the same spot
 */
export type ClusteredItemProps = {
	isHovered: boolean;
	isSelected: boolean;
	isHighlighted: boolean;
	isDragging: boolean;
	items: ItemId[];
};

/**
 * Component definitions for individual renderers
 * TODO: grouping / overlay / clustering of items
 * TODO: provide default components for everything?
 */
export type Components = {
	Item?: ComponentType<ItemProps>;
	ItemWrapper?: ComponentType<ItemWrapperProps>;
	ClusteredItem?: ComponentType<ClusteredItemProps>;
	ItemTooltip?: ComponentType<ItemProps>;
	SidebarItem?: ComponentType<{
		itemId: ItemId;
		isHovered: boolean;
		onHeightChange?: () => void;
	}>;
	EmptyStateActionButtons?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		axis?: ComponentType<Record<any, any>>;
	};
	DnDTooltip?: ComponentType<DnDTooltipProps>;
};

/**
 * Minimal definition of an item on the value range scale
 */
export type Item = {
	id: ItemId;
	x: AxisValue;
	y: AxisValue;
	z: AxisValue;
};

/**
 * Identifies the cause of empty state.
 * Filters: There is no idea in the view because of filters
 * Axis: There is no idea in the view because no idea has those axis values
 * None: Matrix is not empty and user can see bubbles
 */
export type MatrixEmptyState = 'NONE' | 'AXIS' | 'FILTERS';

/**
 * Axis field group value
 */
export type AxisGroupValue = {
	id?: string;
	label?: string;
};

/**
 * External props for matrix component
 */
export type Props = {
	isAxesDragDisabled: boolean;
	xAxis: Axis;
	yAxis: Axis;
	zAxis: Axis;
	components?: Components;
	items: Item[];
	emptyState: MatrixEmptyState;
	viewId?: string;
	xAxisLocked?: boolean;
	yAxisLocked?: boolean;
	isSidebarOpen?: boolean;
	highlightedItem?: ItemId;
	onMoveItems?: ((arg1: ItemId[], arg2: AxisValue, arg3: AxisValue) => void) | undefined;
	onSortAxisOptions?: (arg1: AxisGroupValue[], arg2: string) => void;
	isItemsDragDisabled?: boolean;
};
