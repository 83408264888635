import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	saveOption: {
		id: 'polaris-common.common.decoration.select.option.save-option',
		defaultMessage: 'Done',
		description: 'Button to save the currently open option',
	},
	editOption: {
		id: 'polaris-common.common.decoration.select.option.edit-option',
		defaultMessage: 'Edit option',
		description: 'Button to edit the currently open option',
	},
	removeOption: {
		id: 'polaris-common.common.decoration.select.option.remove-option',
		defaultMessage: 'Delete option',
		description: 'Button to edit the currently open option',
	},
	moveUpOption: {
		id: 'polaris-common.common.decoration.select.option.move-up-option',
		defaultMessage: 'Move up',
		description: 'Button to move up current option',
	},
	moveDownOption: {
		id: 'polaris-common.common.decoration.select.option.move-down-option',
		defaultMessage: 'Move down',
		description: 'Button to move down current option',
	},
	clearFormattingOption: {
		id: 'polaris-common.common.decoration.select.option.clear-formatting-option',
		defaultMessage: 'Clear formatting',
		description: 'Button to clear formatting for current option',
	},
	deleteLabelOptionDisabled: {
		id: 'polaris-common.common.decoration.select.option.delete-label-option-disabled',
		defaultMessage: 'Labels that are not in use will automatically be removed from this list.',
		description: 'Tooltip text to explain user why label option cannot be deleted',
	},
	deleteSelectOptionDisabled: {
		id: 'polaris-common.common.decoration.select.option.delete-select-option-disabled',
		defaultMessage: 'This field type requires at least one added option.',
		description: 'Tooltip text to explain user why select / multi-select option cannot be deleted',
	},
});
