import { useIntl } from '@atlassian/jira-intl';
import type { EntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { messages } from './messages';

type ErrorMessageProps = {
	hasReachedInsightsLimit: {
		limitType: EntityLimitType | null;
		limitValue: number;
	};
	isArchived?: boolean;
};

export const useErrorMessage = (props: ErrorMessageProps) => {
	const {
		isArchived,
		hasReachedInsightsLimit: { limitType, limitValue },
	} = props;

	const { formatMessage } = useIntl();
	const getEntityLimitMessage = useEntityLimitMessage();

	if (isArchived) {
		return formatMessage(messages.archivedError);
	}

	if (limitType) {
		return getEntityLimitMessage(limitType, limitValue);
	}
};
