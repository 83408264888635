import React, { memo } from 'react';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type { CardPreview as CardPreviewType } from '@atlassian/jira-polaris-domain-insight/src/snippet/card/types.tsx';
import { Image } from '../../../../common/ui/attachment';
import { Attachment } from '../../../common/attachments';

type CardPreviewProps = {
	preview: CardPreviewType;
	mediaClientConfig: MediaClientConfig;
	onClick?: (cardEvent: { mediaItemDetails?: unknown }) => void;
};

export const CardPreview = memo<CardPreviewProps>(
	({ preview, mediaClientConfig, onClick }: CardPreviewProps) => {
		if (preview.type === 'attachment') {
			return (
				<Attachment
					onClick={onClick}
					id={preview.attachmentId}
					mediaClientConfig={mediaClientConfig}
					preview
				/>
			);
		}
		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
			<Image preview src={preview.src} onClick={onClick} alt="" />
		);
	},
);
