import flatten from 'lodash/flatten';
import {
	CONJUNCTION,
	INCLUDE_STRING_LIST_PROPERTY,
	type Conjunction,
	type IncludeInStringListProperty,
	type RecursiveFilter,
} from '@atlassian/jira-polaris-lib-formula/src/utils/filter/types.tsx';

export const createIncludeLabelFilter = (label: string): IncludeInStringListProperty => ({
	template: INCLUDE_STRING_LIST_PROPERTY,
	parameters: {
		propertyKey: 'labels',
		values: [label],
	},
});

export const parseConjunctiveLabelFilter = (filter: RecursiveFilter): string[] | undefined => {
	if (filter.template === CONJUNCTION) {
		return flatten(
			filter.parameters.filters
				.map((subFilter) =>
					subFilter.template === INCLUDE_STRING_LIST_PROPERTY
						? subFilter.parameters.values
						: undefined,
				)
				.filter(Boolean),
		);
	}
	return undefined;
};

export const createConjunction = (subFilters: RecursiveFilter[]): Conjunction => ({
	template: CONJUNCTION,
	parameters: { filters: subFilters },
});

export const conjunctiveLabelFilter = (labels: string[]) =>
	createConjunction(labels.map(createIncludeLabelFilter));
