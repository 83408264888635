import { useEffect } from 'react';
import type { Store } from 'redux';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils';
import {
	ChangeEventTypes,
	useIssueViewFieldUpdateEvents,
} from '@atlassian/jira-issue-view-field-update-events';
import { useSoftRefresh } from './use-soft-refresh';
import { getSoftRefreshSupportedFields } from './utils';

export const useFieldChangeSoftRefreshSync = (store: Store<IssueState>) => {
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();
	const issueId = useIssueId();
	const issueKey = useIssueKey();

	const { onSoftRefresh } = useSoftRefresh();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', (event) => {
			if (event.type !== ChangeEventTypes.FIELD_CHANGED || event.issueId !== issueId) {
				return;
			}
			const field = store.getState()?.fields[event.fieldId];
			const fieldType = getFieldType(field);
			if (fieldType && getSoftRefreshSupportedFields().includes(fieldType)) {
				onSoftRefresh(issueKey, fieldType);
			}
		});

		return unregister;
	}, [registerEventHandler, store, issueId, issueKey, onSoftRefresh]);

	return null;
};
