import { useEffect, useState } from 'react';
import type { EmojiProvider, EmojiDescription } from '@atlaskit/emoji';
import { getEmojiProviderWithCustomEmoji } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import type { State, Props } from './types';

const actions = {};

type Actions = typeof actions;

const initialState: State = {
	provider: undefined,
};

const EmojiStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisEmojiStore',
});

const useEmojis = createHook(EmojiStore);

/**
 * used in various places to render emojis
 */
export const useEmoji = (emojiId?: string) => {
	const [emoji, setEmoji] = useState<EmojiDescription | undefined>(undefined);
	const [{ provider }] = useEmojis();

	useEffect(() => {
		if (emojiId !== undefined && provider !== undefined) {
			provider.then((resolvedProvider: EmojiProvider) => {
				Promise.resolve(resolvedProvider.findById(emojiId))
					.then((optionalDescription) =>
						optionalDescription !== undefined
							? resolvedProvider.getMediaEmojiDescriptionURLWithInlineToken(optionalDescription)
							: optionalDescription,
					)
					.then(setEmoji);
			});
		} else {
			setEmoji(undefined);
		}
	}, [provider, emojiId]);

	return emojiId !== undefined ? emoji : undefined;
};

/**
 * used to provide ak/emoji-picker with an emoji provider
 */
export const useEmojiProviderPromise = (): Promise<EmojiProvider> => {
	const [{ provider }] = useEmojis();
	return provider !== undefined
		? provider
		: Promise.reject(new Error('emoji provider not initialized'));
};

export const EmojiContainer = createContainer<State, Actions, Props>(EmojiStore, {
	onInit:
		() =>
		(storeActionApi, { cloudId, currentUser }) => {
			storeActionApi.setState({
				provider: getEmojiProviderWithCustomEmoji(cloudId, currentUser) || undefined,
			});
		},
});
