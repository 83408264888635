import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { LINE_SEPARATOR, VALUE_SEPARATOR } from './constants';

export const getFieldsLabels = (fields: Field[]) => fields.map(({ label }) => `"${label}"`);

export const joinListAsCsv = (list: string[]) => list.join(VALUE_SEPARATOR);

export const joinMatrixAsCsv = (matrix: string[][]) =>
	matrix.map(joinListAsCsv).join(LINE_SEPARATOR);

export const createCSVDownload = (csvString: string, filename: string) => {
	const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
	const link = document.createElement('a');
	const url = URL.createObjectURL(blob);
	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
