import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types';

export const loadGlobalLabels =
	(fieldKey: string): Action<State, Props, Promise<void>> =>
	async ({ getState, setState }, { globalFieldRemote }) => {
		const labelField = getState().fields[fieldKey];

		if (labelField.type !== FIELD_TYPES.CUSTOM_LABELS || !labelField?.global) {
			return;
		}

		if (labelField.options !== undefined) {
			// global labels are already loaded
			return;
		}

		try {
			const globalLabels = await globalFieldRemote.getGlobalLabels({ fieldKey });

			const fields = getState().fields;

			setState({
				fields: {
					...fields,
					[labelField.key]: {
						...fields[labelField.key],
						options: globalLabels.map((label) => ({
							id: label,
							jiraOptionId: label,
							value: label,
							weight: 1,
						})),
						meta: {
							...fields[labelField.key]?.meta,
							globalLabels: {
								error: false,
							},
						},
					},
				},
			});
		} catch (err) {
			const fields = getState().fields;

			setState({
				fields: {
					...fields,
					[labelField.key]: {
						...fields[labelField.key],
						options: [],
						meta: {
							...fields[labelField.key]?.meta,
							globalLabels: {
								error: true,
							},
						},
					},
				},
			});

			throw err;
		}
	};
