import type { GiraQuery } from '@atlassian/jira-issue-fetch-services/src/common/utils/client';
import type { CommentVisibilitiesGraphQlResponse } from '@atlassian/jira-issue-view-common-types/src/gira';
import { COMMENTS, getPermalinkStatus } from '@atlassian/jira-platform-issue-permalinks/src';

const commentParameter = () => {
	const { hasPermalink, permalinkId } = getPermalinkStatus(COMMENTS);

	return hasPermalink ? `commentId: ${permalinkId}` : '';
};

export const jiraCommentVisibility = ` jiraCommentVisibility {
  __typename
  ...on JiraRoleVisibility {
    name
  }
  ...on JiraGroupVisibility {
    group {
      groupId
      name
    }
  }
}`;

export const getCommentsQuery = (maxResults: number, startAt: number, orderBy: string) => `
    comments (first: ${startAt}, maxResults: ${maxResults}, orderBy: "${orderBy}",${commentParameter()}) {
        nodes {
            id
            author {
                accountId
                displayName
                avatarUrl
            }
            updateAuthor {
                accountId
                displayName
                avatarUrl
            }
            ${jiraCommentVisibility}
            created
            updated
            renderedBody
            jsdPublic
            bodyAdf
            jsdAuthorCanSeeRequest
        }
        totalCount
        startIndex
    }
`;

const jiraCommentVisibilities = `
jiraCommentVisibilities {
    edges {
      node {
        __typename
        ...on JiraRoleVisibility {
          name
        }
        ...on JiraGroupVisibility {
          group {
            groupId
            name
          }
        }
      }
    }
  }
`;

export const commentVisibilitiesQuery: GiraQuery<CommentVisibilitiesGraphQlResponse> = `
query CommentVisibilitiesQuery($issueKey: String!) {
    viewIssue(issueKey: $issueKey) {
        ${jiraCommentVisibilities}
    }
}
`;
