import React, { type PropsWithChildren, createContext, useContext } from 'react';

const ScrollableContainerContext = createContext<React.RefObject<HTMLElement> | null>(null);

export const useScrollableContainer = () => useContext(ScrollableContainerContext)?.current;

type ScrollableContainerContextProviderProps = PropsWithChildren<{
	scrollableContainerRef: React.RefObject<HTMLElement>;
}>;

export const ScrollableContainerContextProvider = ({
	children,
	scrollableContainerRef,
}: ScrollableContainerContextProviderProps) => (
	<ScrollableContainerContext.Provider value={scrollableContainerRef}>
		{children}
	</ScrollableContainerContext.Provider>
);
