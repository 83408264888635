import { useRef, useCallback, useEffect } from 'react';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';
import type { Event, Config, HandlerFunc } from './types';
import { useRealtimeQueueContext } from './utils/context';

export { RealtimeQueueContextProvider } from './utils/context';

const getRealtimeEventsQueueLimit = (): number =>
	getFeatureFlagValue<number>('polaris.realtime-events-queue-limit', 0);

const isEnabled = () => getRealtimeEventsQueueLimit() > 0;

type Fn = (
	handler: HandlerFunc,
	config?: Config,
	shouldSkipEvent?: (event: Event) => boolean,
) => [HandlerFunc, () => void];

export const useAreRealtimeQueueQuotasExceeded = () => {
	const { areQueueQuotasExceeded, eventsInQueue } = useRealtimeQueueContext();

	return { areQueueQuotasExceeded, eventsInQueue };
};

export const useWrappedRealtimeHandlerWithQueue: Fn = (handler, config, shouldSkipEvent) => {
	const { queue } = useRealtimeQueueContext();
	const ref = useRef<HandlerFunc | null>(null);

	useEffect(() => {
		ref.current = handler;
		// set queue config
		config && queue.setConfig(config);
	}, [ref, handler, config, queue]);

	const onReceive = useCallback(
		(event: Event) => {
			if (shouldSkipEvent && shouldSkipEvent(event)) {
				return;
			}

			if (isEnabled()) {
				ref.current && queue.addEvent(ref, event);
			} else {
				ref.current && ref.current(event);
			}
		},
		[queue, ref, shouldSkipEvent],
	);

	const clearEvents = useCallback(() => {
		isEnabled() && ref.current && queue.clearEvents(ref);
	}, [queue]);

	return [onReceive, clearEvents];
};

export type { Config } from './types';
