import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import { PLAY_CONTRIBUTION_COMMENT_FRAGMENT } from '../comment';

export const PLAY_CONTRIBUTION_FRAGMENT = gqlTagPolaris`
  fragment PlayContribution on PolarisPlayContribution {
    id
    aaid
    amount
    subject
    created
    updated
    account {
      accountId
      name
      picture
    }
    comment @skip(if: $skipComment) {
      ...PolarisPlayContributionComment
    }
  }
  ${PLAY_CONTRIBUTION_COMMENT_FRAGMENT}
`;

export const PLAY_FRAGMENT = gqlTagPolaris`
  fragment Play on PolarisPlay {
    id
    kind
    label
    parameters
    contributions {
      ...PlayContribution
    }
  }
  ${PLAY_CONTRIBUTION_FRAGMENT}
`;
