import React, { forwardRef, useCallback, useRef, useState, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import defer from 'lodash/defer';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import {
	OptionList as InternalOptionList,
	type DecoratedOption,
	type OptionListApi as InternalOptionListApi,
} from '@atlassian/jira-polaris-lib-field-option-configuration/src/ui/option-list/index.tsx';
import { useCallbacks } from '../../../../../../controllers/selectors/callback-hooks';
import {
	useIsPreview,
	useOuterSpacing,
} from '../../../../../../controllers/selectors/config-hooks';
import { useDecorationForValues } from '../../../../../../controllers/selectors/decoration-hooks';
import {
	useFieldDefaultOpenOptionId,
	useFieldOptionWeightType,
	useFieldType,
} from '../../../../../../controllers/selectors/field-hooks';
import { ControlledFieldDecorator } from './field-decorator';

type OptionListProps = {
	options: FieldOption[];
	isDragDisabled: boolean;
	isReadonly?: boolean;
	isLoading?: boolean;
	ErrorMessage?: ComponentType;
	onRankOption: (optionIds: string[]) => void;
	onOptionClick: (optionId: string) => void;
};

export type OptionListApi = InternalOptionListApi;

export const OptionList = forwardRef<OptionListApi, OptionListProps>(
	(
		{
			options,
			isDragDisabled,
			isReadonly = false,
			onRankOption,
			onOptionClick,
			isLoading,
			ErrorMessage,
		},
		ref,
	) => {
		const defaultOpenOptionId = useFieldDefaultOpenOptionId();
		const [ghostPosition, setGhostPosition] = useState({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		});
		const [openOptionId, setOpenOptionId] = useState<string | undefined>();
		const fieldType = useFieldType();
		const weightType = useFieldOptionWeightType();
		const outerSpacing = useOuterSpacing();
		const isPreview = useIsPreview();
		const { onFieldOptionWeightUpdated } = useCallbacks();
		const isDndDisabled =
			isReadonly ||
			isDragDisabled ||
			fieldType === FIELD_TYPES.LABELS ||
			fieldType === FIELD_TYPES.CUSTOM_LABELS;
		const optionIds = options.map((option) => option.id);
		const decorationValues = useDecorationForValues(optionIds);
		const decoratedOptions: DecoratedOption[] = options.map((option) => ({
			...option,
			emojiId: decorationValues[option.id]?.emoji,
			color: decorationValues[option.id]?.backgroundColor,
		}));
		const clickedOptionElementRef = useRef<HTMLElement>();

		const hasOnlyOneOption =
			(fieldType === FIELD_TYPES.MULTI_SELECT ||
				fieldType === FIELD_TYPES.JSW_MULTI_SELECT ||
				fieldType === FIELD_TYPES.SINGLE_SELECT) &&
			decoratedOptions?.length === 1;

		const isDeleteDisabled =
			fieldType === FIELD_TYPES.LABELS ||
			fieldType === FIELD_TYPES.CUSTOM_LABELS ||
			hasOnlyOneOption;

		const openDialog = useCallback(
			(optionId: string, optionElement: HTMLElement) => {
				if (openOptionId !== optionId) {
					onOptionClick(optionId);
					const optionElementRect = optionElement.getBoundingClientRect();
					setGhostPosition({
						x: optionElementRect.left,
						y: optionElementRect.top,
						width: optionElementRect.width,
						height: optionElementRect.height,
					});
					sendPendoTrackEvent({
						actionSubjectAndAction: 'option clicked',
						actionSubjectId: 'openOptionDecorationConfiguration',
						source: 'fieldOptionList',
					});
					setTimeout(() => {
						clickedOptionElementRef.current = optionElement;
						setOpenOptionId(optionId);
					});
				}
			},
			[onOptionClick, openOptionId],
		);

		const closeDialog = useCallback(() => {
			setOpenOptionId(undefined);
			defer(() => {
				if (clickedOptionElementRef.current) {
					clickedOptionElementRef.current.focus();
					clickedOptionElementRef.current = undefined;
				}
			});
		}, []);

		return (
			<>
				<ControlledFieldDecorator
					id={openOptionId ?? ''}
					autoFocusName
					onOptionDeletion={closeDialog}
					isOpen={!!openOptionId}
					isDeleteDisabled={isDeleteDisabled}
					onClose={closeDialog}
					onCloseRequested={closeDialog}
				>
					{(triggerProps) => <OptionGhost position={ghostPosition} {...triggerProps} />}
				</ControlledFieldDecorator>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{ErrorMessage ? (
							<ErrorMessage />
						) : (
							<InternalOptionList
								ref={ref}
								weightType={weightType}
								isReadonly={isReadonly}
								isDragDisabled={isDndDisabled}
								isPreview={!!isPreview}
								decoratedOptions={decoratedOptions}
								outerSpacing={outerSpacing}
								onOptionClick={(option, optionElement) => openDialog(option.id, optionElement)}
								onRankOption={onRankOption}
								onFieldOptionWeightUpdated={onFieldOptionWeightUpdated}
								selectedOptionId={openOptionId}
								defaultSelectedOptionId={defaultOpenOptionId}
							/>
						)}
					</>
				)}
			</>
		);
	},
);

const Loader = () => (
	<>
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
		<Skeleton width="100%" height="20px" />
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OptionGhost = styled.div<{
	position: { x: number; y: number; width: number; height: number };
}>({
	position: 'fixed',
	visibility: 'none',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ position }) => `${position.y}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: ({ position }) => `${position.x}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ position }) => `${position.width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ position }) => `${position.height}px`,
});
