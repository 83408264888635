import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';
import { getViewUUID } from '../utils';

const getUpdateLastViewedUrl = (collectionUUID: string, viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/last-viewed`;

export const updateCollectionViewLastViewed = async (
	collectionUUID: string,
	viewId: string,
	timestamp: string,
): Promise<void> => {
	performPutRequest(getUpdateLastViewedUrl(collectionUUID, getViewUUID(viewId)), {
		body: JSON.stringify({ timestamp }),
	});
};
