import { useCallback } from 'react';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getExternalIssueIdsByJiraIssueId,
	getIssuesWithBasicProperties,
	getLastUpdatedIssueIds,
	getLocalIssueIdsByJiraIssueId,
	getLocalIssueIdForJiraIssueId,
	getRankedIssueIds,
	getRankedIssueCount,
	getLocalIssueIdToJiraId,
	createGetUnarchivedIssuesForIssueSelect,
	getJiraIdToLocalIssueId,
	getCurrentUserManuallyCreatedIssueIds,
	getArchivedIssueCount,
	getActiveIssueCount,
} from './issue-ids';
import { useKeyProperties } from './properties/hooks';
import { createIssueHook, createHigherLevelIssueHook } from './utils';

export const useRankedIssueIds = createIssueHook(getRankedIssueIds);
export const useIssuesWithBasicProperties = createIssueHook(getIssuesWithBasicProperties);
export const useJiraIdToLocalIssueId = createIssueHook(getJiraIdToLocalIssueId);
export const useLocalIssueIdsByJiraIssueId = createIssueHook(getLocalIssueIdsByJiraIssueId);
export const useExternalIssueIdsByJiraIssueId = createIssueHook(getExternalIssueIdsByJiraIssueId);
export const useLocalIssueIdForJiraIssueId = createHigherLevelIssueHook<
	IssueId,
	LocalIssueId | undefined
>(getLocalIssueIdForJiraIssueId);

export const useRankedIssueCount = createIssueHook(getRankedIssueCount);

export const useActiveIssueCount = createIssueHook(getActiveIssueCount);
export const useArchivedIssueCount = createIssueHook(getArchivedIssueCount);

export const useLocalIssueIdToJiraIssueId: () => Record<LocalIssueId, IssueId> =
	createIssueHook(getLocalIssueIdToJiraId);

export const useJiraIssueIdByLocalIssueId = (localIssueId: LocalIssueId): IssueId => {
	const localIssueIdToJiraIssueId = useLocalIssueIdToJiraIssueId();
	return localIssueIdToJiraIssueId[localIssueId];
};

export const useLocalIssueIdToJiraIssueIdMapper = (): ((
	arg1: LocalIssueId,
) => IssueId | undefined) => {
	const map = useLocalIssueIdToJiraIssueId();
	return useCallback((localIssueId: LocalIssueId) => map[localIssueId], [map]);
};

export const useLocalIssueIdToJiraIssueKeyMapper = (): ((
	arg1: LocalIssueId,
) => IssueKey | undefined) => {
	const map = useKeyProperties();

	return useCallback((localIssueId: LocalIssueId) => map[localIssueId], [map]);
};

export const useUnarchivedIssuesForIssueSelect = createIssueHook(
	createGetUnarchivedIssuesForIssueSelect,
);

export const useLastUpdatedIssueIds = createIssueHook(getLastUpdatedIssueIds);

export const useCurrentUserManuallyCreatedIssueIds = createIssueHook(
	getCurrentUserManuallyCreatedIssueIds,
);
