import React, { Component, type ComponentType } from 'react';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type { AutoformattingProvider } from '@atlaskit/editor-common/provider-factory';
import { ISSUEKEY_REGEX } from '@atlassian/jira-common-match-jira-url';
import { doesIssueExist } from '@atlassian/jira-issue-fetch-services';
import { toIssueKey } from '@atlassian/jira-shared-types';

declare let window: {
	location: {
		protocol: string;
		host: string;
	};
};

const issueKeyAutoformattingRule = () => ({
	[ISSUEKEY_REGEX]: (match: string[]): Promise<ADFEntity | null> => {
		const issueKey = toIssueKey(match[1]);

		return doesIssueExist(issueKey)
			.then((issueExists) => {
				if (issueExists) {
					const url = `${window.location.protocol}//${window.location.host}/browse/${issueKey}`;
					return {
						type: 'inlineCard',
						attrs: { url },
					};
				}

				return null;
			})
			.catch(() => null);
	},
});

const createAutoFormattingProvider = (): AutoformattingProvider => ({
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getRules: async () => ({ ...(issueKeyAutoformattingRule() as unknown as ADFEntity) }),
});

export const withAutoformattingProvider = <T,>(
	WrappedComponent: ComponentType<T>,
): ComponentType<T> =>
	// eslint-disable-next-line jira/react/no-class-components
	class extends Component<T> {
		static displayName = 'WithAutoformattingProvider';

		render() {
			return (
				<WrappedComponent autoformattingProvider={createAutoFormattingProvider()} {...this.props} />
			);
		}
	};
