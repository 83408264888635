import React from 'react';
import { styled } from '@compiled/react';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

export const DraggableHandle = () => {
	if (fg('jpd-visual-refresh_typography_horizon')) {
		return <Handle />;
	}

	return <HandleLegacy data-test-id="polaris-lib-list.common.ui.renderer.handle.handle" />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HandleLegacy = styled.div({
	'&::before': {
		content: '⠿',
		cursor: 'grab',
		color: token('color.text', N300),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: '16px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Handle = styled.div({
	'&::before': {
		content: '⠿',
		cursor: 'grab',
		color: token('color.text', N300),
		font: token('font.body.large'),
	},
});
