import type { ProjectType } from '@atlassian/jira-common-constants';
import { toProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import {
	type UseProjectReturn,
	useProject,
} from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project';
import {
	type IssueKey,
	type BaseUrl,
	type IssueTypeId,
	toProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useViewWorkflowData } from '../use-view-workflow-data/main';

export type ServiceApi = {
	data: WorkflowServiceData | undefined;
	loading: boolean;
	error: FetchError | undefined;
	fetch: () => Promise<void>;
};

type WorkflowServiceData = {
	hasPermission?: boolean;
	issueTypeId?: IssueTypeId;
	projectId?: string | undefined;
	projectType?: ProjectType | undefined;
	isSimplified?: boolean | undefined;
};

export const emptyResult: ServiceApi = {
	data: undefined,
	loading: false,
	error: undefined,
	fetch: () => Promise.resolve(),
};

const getIssueProjectDataFromContext = (
	useProjectData: UseProjectReturn,
	issueProjectKey: string | null,
): WorkflowServiceData | undefined => {
	if (!useProjectData || !useProjectData.data) return undefined;

	const hasIssueProjectDataInContext = useProjectData.data.key === issueProjectKey;
	if (!hasIssueProjectDataInContext) return undefined;

	return {
		projectId: toProjectId(useProjectData.data.id.toString()),
		projectType: toProjectType(useProjectData.data.type),
		isSimplified: useProjectData.data.isSimplified,
	};
};

export const useProjectViewWorkflowService = (baseUrl: BaseUrl, issueKey: IssueKey): ServiceApi => {
	const issueProjectKey = issueKey ? extractProjectKey(issueKey) : null;

	const useProjectData = useProject();
	const projectContextData = getIssueProjectDataFromContext(useProjectData, issueProjectKey);
	const cloudId = useCloudId();
	const issueContextData = useViewWorkflowData({
		baseUrl,
		projectKey: projectContextData ? undefined : issueProjectKey || undefined,
		issueKey,
		isSimplified: projectContextData && projectContextData.isSimplified,
		cloudId,
	});

	if (!issueKey || !issueProjectKey) {
		return { ...emptyResult };
	}

	const combinedData: WorkflowServiceData | undefined =
		projectContextData || issueContextData.data
			? {
					...projectContextData,
					...issueContextData.data,
				}
			: undefined;

	return { ...issueContextData, data: combinedData };
};
