import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	getIsSorted,
	getSortedIssueIds,
	getSortedIssueIndex,
	getSortedUnfilteredIssueIds,
	getHiddenByFilter,
} from './sort';
import { createIssueHook, createHigherLevelIssueHook, createHigherLevelIssueHook2 } from './utils';

export const useIsSorted = createIssueHook(getIsSorted);
export const useSortedIssueIds = createIssueHook(getSortedIssueIds);
export const useSortedUnfilteredIssueIds = createIssueHook(getSortedUnfilteredIssueIds);
export const useSortedIssueIndex = createHigherLevelIssueHook<LocalIssueId, number>(
	getSortedIssueIndex,
);

export const useHiddenByFilter = createHigherLevelIssueHook2<
	LocalIssueId,
	'before' | 'after',
	number
>(getHiddenByFilter);
