import type { WorklogUiState } from '@atlassian/jira-issue-view-common-types/src/worklog-type';
import {
	EDIT_WORKLOG_SUCCESS,
	type EditWorklogSuccessAction,
	ADD_WORKLOG_SUCCESS,
	type AddWorklogSuccessAction,
	OPEN_MODAL,
	type OpenModalAction,
	DISPOSE_WORKLOG_APP,
	type DisposeWorklogAppAction,
} from '../../../common/actions/worklog-actions';

export const initialHighlightedWorklogId = null;

type HighlightedWorklogIdType = WorklogUiState['highlightedWorklogId'];

type Action =
	| AddWorklogSuccessAction
	| EditWorklogSuccessAction
	| OpenModalAction
	| DisposeWorklogAppAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: HighlightedWorklogIdType = initialHighlightedWorklogId,
	action: Action,
): HighlightedWorklogIdType => {
	switch (action.type) {
		case ADD_WORKLOG_SUCCESS:
		case EDIT_WORKLOG_SUCCESS:
			return action.payload.worklogId;
		case DISPOSE_WORKLOG_APP:
			return null;
		case OPEN_MODAL:
			return state;
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
