import camelCase from 'lodash/camelCase';
import { fg } from '@atlassian/jira-feature-gating';
import {
	RightSidebarShowingFieldList,
	RightSidebarShowingField,
	RightSidebarShowingCreateField,
	RightSidebarShowingIdeaTemplates,
	RightSidebarShowingPlay,
	type Showing,
	RightSidebarShowingIdeasBucket,
	RightSidebarShowingGlobalFields,
	RightSidebarShowingViewInfo,
	RightSidebarShowingSort,
	RightSidebarShowingGroupByOptions,
	RightSidebarShowingFilter,
	RightSidebarShowingVerticalGroupByOptions,
	RightSidebarShowingXAxisOptions,
	RightSidebarShowingZAxisOptions,
	RightSidebarShowingYAxisOptions,
} from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { getSidebarWidthFromStorage } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/utils/storage.tsx';
import {
	DEFAULT_SIDEBAR_MAX_WIDTH,
	SIDEBAR_MAX_WIDTH_RATIO,
	RESIZABLE_SIDEBAR_MIN_WIDTH,
} from './constants';

type ShowingMode = Showing['mode'];

const FIXED_SIDEBARS: ShowingMode[] = [
	RightSidebarShowingFieldList,
	RightSidebarShowingField,
	RightSidebarShowingCreateField,
	RightSidebarShowingIdeaTemplates,
	RightSidebarShowingIdeasBucket,
];

export const isSidebarResizable = (isIssueViewOpen: boolean, mode: ShowingMode): boolean => {
	if (FIXED_SIDEBARS.includes(mode)) {
		return false;
	}

	return isIssueViewOpen || mode === RightSidebarShowingPlay;
};

export const getCalculatedWidth = (width: number, deltaX: number, parentWidth?: number) =>
	Math.round(
		Math.min(
			Math.max(width + deltaX, RESIZABLE_SIDEBAR_MIN_WIDTH),
			parentWidth === undefined ? DEFAULT_SIDEBAR_MAX_WIDTH : parentWidth * SIDEBAR_MAX_WIDTH_RATIO,
		),
	);

const getDefaultWidth = (mode: ShowingMode) => {
	switch (mode) {
		case RightSidebarShowingFieldList:
		case RightSidebarShowingField:
		case RightSidebarShowingCreateField:
		case RightSidebarShowingPlay:
		case RightSidebarShowingIdeaTemplates:
		case RightSidebarShowingIdeasBucket:
		case RightSidebarShowingGlobalFields:
			return 400;
		case RightSidebarShowingSort:
		case RightSidebarShowingFilter:
		case RightSidebarShowingGroupByOptions:
		case RightSidebarShowingVerticalGroupByOptions:
		case RightSidebarShowingXAxisOptions:
		case RightSidebarShowingYAxisOptions:
		case RightSidebarShowingZAxisOptions:
			return fg('polaris_groupby-filter-sort-fields-panels-refresh') ? 400 : 320;
		case RightSidebarShowingViewInfo:
			return 500;
		default:
			return 320;
	}
};

export const getSidebarWidth = (mode: ShowingMode, isResizable: boolean) => {
	if (!isResizable) {
		return getDefaultWidth(mode);
	}

	return getSidebarWidthFromStorage() ?? RESIZABLE_SIDEBAR_MIN_WIDTH;
};

export const convertRightSidebarModeToAnalyticsSourceName = (mode: Showing['mode']): string => {
	const sourceNameMap: { [key in typeof mode]?: string } = {
		FIELD: 'editIssueField',
		CREATE_FIELD: 'createIssueField',
		TIMELINE_CONFIG: 'changeViewTimelineSettings',
		VERTICAL_GROUP_BY_OPTIONS: 'changeViewGroupByField',
		FILTER: 'changeViewFilterField',
		SORT: 'changeViewSortField',
		FIELD_LIST: 'fieldList',
		MATRIX_X_AXIS_OPTIONS: 'changeViewXAxisField',
		MATRIX_Y_AXIS_OPTIONS: 'changeViewYAxisField',
		MATRIX_Z_AXIS_OPTIONS: 'changeViewZAxisField',
		GROUP_BY_OPTIONS: 'changeViewColumns',
	};

	return sourceNameMap[mode] ?? camelCase(mode);
};
