import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'polaris-ideas.comments.empty-state.description',
		defaultMessage: 'Create comments to discuss, ask questions and share opinions about this view.',
		description: 'Description displayed when the view has no comments',
	},
	descriptionReadOnly: {
		id: 'polaris-ideas.comments.empty-state.description-read-only',
		defaultMessage:
			'You will be able to follow here any discussions regarding this view. Check it back later!',
		description:
			'Description displayed when the view has no comments and the current user does have read-only permissions',
	},
});
