import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	unsupportedFieldsInfoFirst: {
		id: 'polaris-ideas.right-sidebar.idea-templates.section-message.unsupported-fields-info-first',
		defaultMessage: "Not all fields in the view you've selected are supported for this.",
		description:
			'Description for previewing the idea creation form if there are unsupported fields',
	},
	unsupportedFieldsInfoSecond: {
		id: 'polaris-ideas.right-sidebar.idea-templates.section-message.unsupported-fields-info-second',
		defaultMessage: "The following fields won't be shown: {fields}",
		description:
			'Description for previewing the idea creation form if there are unsupported fields',
	},
});
