import { ff } from '@atlassian/jira-feature-flagging';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service';

export const getSharingMarkersUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/${
		ff('polaris.published-view-public-links_dj5gi', false) ? 'public' : 'v1'
	}/views/${viewUUID}/markers`;

export const getCollectionMarkersUrl = (collectionUUID: string, viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/markers`;

export const getCollectionMarkersUrlWithId = (
	collectionUUID: string,
	viewUUID: string,
	markerUUID: string,
) =>
	`${getJpdViewsServicePathPrefix()}/v1/collections/${collectionUUID}/views/${viewUUID}/markers/${markerUUID}`;
