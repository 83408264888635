import type { ViewContext } from '@atlassian/jira-issue-gira-transformer-types';

// FETCH_VIEW_CONTEXT_REQUEST
export const FETCH_VIEW_CONTEXT_REQUEST = 'FETCH_VIEW_CONTEXT_REQUEST' as const;

export const fetchViewContextRequest = () => ({
	type: FETCH_VIEW_CONTEXT_REQUEST,
});

export type FetchViewContextRequest = ReturnType<typeof fetchViewContextRequest>;

// FETCH_VIEW_CONTEXT_SUCCESS
export const FETCH_VIEW_CONTEXT_SUCCESS = 'FETCH_VIEW_CONTEXT_SUCCESS' as const;

export const fetchViewContextSuccess = (viewContext: ViewContext) => ({
	type: FETCH_VIEW_CONTEXT_SUCCESS,
	payload: viewContext,
});

export type FetchViewContextSuccess = ReturnType<typeof fetchViewContextSuccess>;

// FETCH_VIEW_CONTEXT_FAILURE
export const FETCH_VIEW_CONTEXT_FAILURE = 'FETCH_VIEW_CONTEXT_FAILURE' as const;

export const fetchViewContextFailure = (
	error: Error & {
		statusCode: number;
	},
) => ({
	type: FETCH_VIEW_CONTEXT_FAILURE,
	payload: { error },
});

export type FetchViewContextFailure = ReturnType<typeof fetchViewContextFailure>;

// CHECK_VIEW_CONTEXT
export const CHECK_VIEW_CONTEXT = 'CHECK_VIEW_CONTEXT' as const;

export type CheckViewContextAction = {
	type: typeof CHECK_VIEW_CONTEXT;
};

export const checkViewContext = (): CheckViewContextAction => ({
	type: CHECK_VIEW_CONTEXT,
});

// MARK_TOKEN_AS_OUTDATED
export const MARK_TOKEN_AS_OUTDATED = 'MARK_TOKEN_AS_OUTDATED' as const;

export const markTokenAsOutdated = () => ({
	type: MARK_TOKEN_AS_OUTDATED,
});

export type MarkTokenAsOutdated = ReturnType<typeof markTokenAsOutdated>;

// MARK_TOKEN_AS_UP_TO_DATE
export const MARK_TOKEN_AS_UP_TO_DATE = 'MARK_TOKEN_AS_UP_TO_DATE' as const;

export const markTokenAsUpToDate = () => ({
	type: MARK_TOKEN_AS_UP_TO_DATE,
});

export type MarkTokenAsUpToDate = ReturnType<typeof markTokenAsUpToDate>;

export type ViewContextAction =
	| FetchViewContextRequest
	| FetchViewContextSuccess
	| FetchViewContextFailure
	| CheckViewContextAction
	| MarkTokenAsOutdated
	| MarkTokenAsUpToDate;
