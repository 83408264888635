// MULTILINE_FIELD_EDIT_BEGIN
export const MULTILINE_FIELD_EDIT_BEGIN = 'MULTILINE_FIELD_EDIT_BEGIN' as const;

export const multilineFieldEditBegin = (
	multilineFieldIdsToClose: string[],
	commentIdsToClose: string[],
	shouldCloseAddCommentForm: boolean,
) => ({
	type: MULTILINE_FIELD_EDIT_BEGIN,
	payload: {
		multilineFieldIdsToClose,
		commentIdsToClose,
		shouldCloseAddCommentForm,
	},
});

export type MultilineFieldEditBeginAction = ReturnType<typeof multilineFieldEditBegin>;
