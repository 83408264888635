// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum ProjectProperties {
	ATLAS_INTEGRATION_ENABLED = 'JPD_ATLAS_INTERGRATION_ENABLED', // do not fix this typo - customers will lose their value
	ATLAS_SITE_CLOUD_ID = 'jpd-atlas-site-cloud-id',
	SUBMIT_IDEA_FORM_VIEW = 'JPD_SUBMIT_IDEA_FORM_VIEW',
	SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE = 'JPD_SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE',
	SUBMIT_IDEA_FORM_MANDATORY_FIELDS = 'JPD_SUBMIT_IDEA_FORM_MANDATORY_FIELDS',
	IDEA_CREATION_BY_CONTRIBUTORS = 'idea-creation-by-contributors',
}

export type RemoteProperty<T> = {
	isLoading: boolean;
	error: Error | null;
	value: T | undefined;
};

export type State = {
	properties: {
		[ProjectProperties.IDEA_CREATION_BY_CONTRIBUTORS]?: RemoteProperty<string>;
		[ProjectProperties.SUBMIT_IDEA_FORM_VIEW]?: RemoteProperty<string>;
		[ProjectProperties.SUBMIT_IDEA_FORM_DESCRIPTION_TEMPLATE]?: RemoteProperty<string>;
		[ProjectProperties.SUBMIT_IDEA_FORM_MANDATORY_FIELDS]?: RemoteProperty<boolean>;
		[ProjectProperties.ATLAS_INTEGRATION_ENABLED]?: RemoteProperty<boolean>;
		[ProjectProperties.ATLAS_SITE_CLOUD_ID]?: RemoteProperty<string>;
	};
	callbacks: {
		onSubmitIdeaFormEnabled?: (enabled: boolean) => void;
	};
};

export type Props = {
	projectIdOrKey: string | number;
	preloadProperties?: ProjectProperties[];
	onSubmitIdeaFormEnabled?: (enabled: boolean) => void;
};
