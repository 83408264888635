import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	atlasConnect: {
		id: 'polaris-common.available-products.atlas-connect',
		defaultMessage: 'Connect to Atlas',
		description: 'Title of login link to Atlas',
	},
	refresh: {
		id: 'polaris-common.available-products.refresh',
		defaultMessage: 'Refresh',
		description: 'Title of refresh button',
	},
});
