import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sortButton: {
		id: 'polaris-ideas.view-controls.sort-button.sort-button',
		defaultMessage: 'Sort',
		description: 'Caption on the button to open the sort configuration.',
	},
	viewRankIndicator: {
		id: 'polaris-ideas.view-controls.sort-button.view-rank-indicator',
		defaultMessage: 'View rank',
		description: 'Indicator text showing that the current view is sorted by view rank.',
	},
});
