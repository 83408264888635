import { useEffect } from 'react';
import { createHook, useOnboardingFlowsActions } from '../index';
import {
	getExploreSectionMessageDismissed,
	getProjectFieldsPageViewed,
	getSeenPulsatingFieldsButton,
	getTourDismissed,
	getTourSpotlightStep,
} from './project-fields';

export const useExploreSectionMessageDismissed = createHook(getExploreSectionMessageDismissed);

export const useProjectFieldsPageViewed = createHook(getProjectFieldsPageViewed);

export const useSeenPulsatingFieldsButton = createHook(getSeenPulsatingFieldsButton);

export const useTourDismissed = createHook(getTourDismissed);

export const useTourSpotlightStep = createHook(getTourSpotlightStep);

export function useTrackProjectFieldsPageViewed() {
	const projectFieldsPageViewed = useProjectFieldsPageViewed();
	const { setProjectFieldsPageViewed } = useOnboardingFlowsActions();

	useEffect(() => {
		if (projectFieldsPageViewed !== true) {
			setProjectFieldsPageViewed();
		}
	}, [projectFieldsPageViewed, setProjectFieldsPageViewed]);

	return projectFieldsPageViewed;
}
