import { gqlTagPolaris } from '@atlassian/jira-apollo-polaris';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	isEditorAiEnabledVariables as IsEditorAiEnabledQueryVariables,
	isEditorAiEnabled as IsEditorAiEnabledQueryResponse,
} from './__generated_apollo__/isEditorAiEnabled';

const query = gqlTagPolaris`
    query isEditorAiEnabled($projectType: JiraProjectType!, $cloudId: ID!) {
        jira @optIn(to: "JiraIsIssueViewEditorAiEnabled") {
            isIssueViewEditorAiEnabled(jiraProjectType: $projectType, cloudId: $cloudId)
        }
    }
`;

export const fetchIsEditorAiEnabled = async (
	client: PolarisApolloClient,
	cloudId: CloudId,
): Promise<boolean> => {
	const { data, errors } = await client.query<
		IsEditorAiEnabledQueryResponse,
		IsEditorAiEnabledQueryVariables
	>({
		query,
		variables: {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			projectType: 'PRODUCT_DISCOVERY' as any,
			cloudId,
		},
		fetchPolicy: 'network-only',
	});

	if (errors !== undefined) {
		throw new Error(
			`polaris-remote-site.is-editor-ai-enabled:${errors.map((e) => e.message).join(', ')}`,
		);
	}

	return (data.jira && data.jira.isIssueViewEditorAiEnabled) ?? false;
};
