import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	allFieldsFilter: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.filters.all-fields-filter',
		defaultMessage: 'All ({count})',
		description: 'All fields filter',
	},
	projectFieldsFilter: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.filters.project-fields-filter',
		defaultMessage: 'Project fields ({count})',
		description: 'Project fields filter',
	},
	globalFieldsFilter: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.filters.global-fields-filter',
		defaultMessage: 'Global fields ({count})',
		description: 'Global fields filter',
	},
	searchPlaceholder: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.filters.search-placeholder',
		defaultMessage: 'Search',
		description: 'Search input placeholder',
	},
	clearSearch: {
		id: 'polaris-ideas.settings.fields.project-fields-screen.fields-list.filters.clear-search',
		defaultMessage: 'Clear search',
		description: 'Clear search button',
	},
});
