import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	weightLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.weight-label',
		defaultMessage: 'Weight',
		description:
			'Label for an icon button that opens a dropdown to add/change field option weight type',
	},
	searchLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.search-label',
		defaultMessage: 'Search',
		description: 'Label for an icon button that toggles an input to field option search mode',
	},
	formattingLabel: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.formatting-label',
		defaultMessage: 'Formatting',
		description:
			'Label for an icon button that opens a dropdown to access field option formatting related actions',
	},
	createOptionPlaceholder: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.create-option-placeholder',
		defaultMessage: 'Add a new option',
		description: 'Placeholder for the input to create a new option',
	},
	searchOrCreateOptionPlaceholder: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.search-or-create-option-placeholder',
		defaultMessage: 'Find an option or create a new one',
		description: 'Placeholder for the input to filter or create options',
	},
	searchOptionPlaceholder: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.search-option-placeholder',
		defaultMessage: 'Find an option',
		description: 'Placeholder for the input to filter options',
	},
});
