import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editField: {
		id: 'polaris-common.field-config.item.edit-field',
		defaultMessage: 'Edit field',
		description: 'Button label for editing a field',
	},
	fieldSettings: {
		id: 'polaris-common.field-config.item.field-settings',
		defaultMessage: 'Show field settings',
		description: 'Button to open settings for the currently open field',
	},
	noManageViewFieldsPermissions: {
		id: 'polaris-common.field-config.item.no-manage-view-fields-permissions',
		defaultMessage: "You don't have permissions to manage fields in views",
		description: 'Information message for missing manage fields in views permissions.',
	},
	toggleField: {
		id: 'polaris-common.field-config.item.toggle-field',
		defaultMessage: 'Toggle in view',
		description: 'Button label for toggling the current field in the view.',
	},
	showField: {
		id: 'polaris-common.field-config.item.show-field',
		defaultMessage: 'Show in view',
		description: 'Button label for showing the current field in the view.',
	},
	hideField: {
		id: 'polaris-common.field-config.item.hide-field',
		defaultMessage: 'Hide in view',
		description: 'Button label for hiding the current field in the view.',
	},
	notHideable: {
		id: 'polaris-common.field-config.item.not-hideable',
		defaultMessage: 'This field cannot be hidden from the view',
		description: 'Tooltip label indicating that the current field cannot be hidden from the view',
	},
});
