import React, { useCallback, useMemo } from 'react';
import merge from 'lodash/merge';
import ArchiveIcon from '@atlaskit/icon/glyph/archive';
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { ButtonItem, Section, type CSSFn } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsArchivingEnabled } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useProjectSettingsUrl } from '@atlassian/jira-polaris-common/src/controllers/project/selectors/project-hooks';
import {
	useCurrentPolarisRoute,
	usePolarisRouter,
} from '@atlassian/jira-polaris-common/src/controllers/route';
import type { PolarisRoute } from '@atlassian/jira-polaris-common/src/controllers/route/types';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import {
	useCurrentViewId,
	useSystemViewsContainingArchived,
	useSystemViewsNotContainingArchived,
	useViewContainsArchived,
	useViewTitle,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useIsPreviewContributorMode } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/main.tsx';
import {
	useIsProjectAdmin,
	useCanArchiveIdeas,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { SectionInner } from '../styled';
import messages from './messages';

const cssFn: CSSFn = (currentStyles) => {
	const newStyle = {
		padding: `${token('space.050', '4px')} 18px`,
		color: currentStyles?.isSelected
			? token('color.text.brand', colors.B400)
			: token('color.text.subtle', colors.N500),
		'&:hover': {
			color: token('color.text', colors.N800),
		},
		'& [data-item-elem-before]': {
			width: 24,
			height: 24,
			'& svg': {
				width: 18,
				height: 18,
				marginTop: token('space.025', '2px'),
				marginLeft: 3,
			},
		},
	};
	return merge(currentStyles, newStyle);
};

type SettingsSectionProps = {
	onNavToSettings: (arg1: PolarisRoute) => void;
	isReadOnly?: boolean;
};

const ViewButton = ({ viewId }: { viewId: LocalViewId }) => {
	const { setCurrentView } = useViewActions();
	const title = useViewTitle(viewId);
	const currentViewId = useCurrentViewId();
	const containsArchived = useViewContainsArchived(viewId);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { closeIssueView } = usePolarisRouter();

	const onSelectView = useCallback(
		(selectViewId: string) => {
			if (selectViewId !== undefined) {
				fireCompoundAnalyticsEvent.NavSidebarArchiveViewClicked(createAnalyticsEvent({}));
				closeIssueView();
				setCurrentView(selectViewId);
			}
		},
		[createAnalyticsEvent, setCurrentView, closeIssueView],
	);

	return (
		<ButtonItem
			testId="polaris-ideas.ui.sidebar.settings.section.archive"
			onClick={() => onSelectView(viewId)}
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
			cssFn={cssFn}
			isSelected={currentViewId === viewId}
			iconBefore={
				containsArchived ? <ArchiveIcon label="" /> : <BulletListIcon label={title || ''} />
			}
		>
			{title}
		</ButtonItem>
	);
};

export const SettingsSection = ({ onNavToSettings }: SettingsSectionProps) => {
	const { formatMessage } = useIntl();
	const [isProjectAdmin] = useIsProjectAdmin();
	const canArchiveIdeas = useCanArchiveIdeas();
	const { isAdmin, isSiteAdmin } = useTenantContext();
	const [projectSettingsUrl] = useProjectSettingsUrl();
	const { routeTo } = usePolarisRouter();
	const polarisRoute = useCurrentPolarisRoute();
	const { section } = polarisRoute;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const archivedViewIds = useSystemViewsContainingArchived();
	const systemViewIds = useSystemViewsNotContainingArchived();

	const onSelectSettings = useCallback(() => {
		fireCompoundAnalyticsEvent.NavSidebarSettingsClicked(createAnalyticsEvent({}));

		onNavToSettings(polarisRoute);
		routeTo({ section: 'settings', resource: 'details' });
	}, [createAnalyticsEvent, onNavToSettings, polarisRoute, routeTo]);

	const [isArchivingEnabled] = useIsArchivingEnabled();
	const [isPreviewContributorMode] = useIsPreviewContributorMode();

	const canChangeProjectSettings =
		(isSiteAdmin || isAdmin || isProjectAdmin) && !isPreviewContributorMode;

	const filteredViews = useMemo(
		() => (isArchivingEnabled && canArchiveIdeas ? archivedViewIds : systemViewIds),
		[isArchivingEnabled, canArchiveIdeas, archivedViewIds, systemViewIds],
	);

	if ((projectSettingsUrl !== undefined && canChangeProjectSettings) || filteredViews.length > 0) {
		return (
			<Section hasSeparator>
				<SectionInner>
					{filteredViews.map((viewId) => (
						<ViewButton key={viewId} viewId={viewId} />
					))}
					{projectSettingsUrl !== undefined && canChangeProjectSettings ? (
						<Section>
							<ButtonItem
								id="polaris.ideas.src.ui.sidebar.settings.project-settings.button"
								isSelected={section === 'settings'}
								onClick={onSelectSettings}
								// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
								cssFn={cssFn}
								iconBefore={<SettingsIcon label={formatMessage(messages.settings)} />}
							>
								{formatMessage(messages.settings)}
							</ButtonItem>
						</Section>
					) : null}
				</SectionInner>
			</Section>
		);
	}
	return null;
};
