import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dialogHeader: {
		id: 'polaris-common.refresh-errors-dialog.dialog-header',
		defaultMessage: 'Data refresh errors',
		description: 'Header for the data refresh error detail dialog',
	},
	dialogConfirm: {
		id: 'polaris-common.refresh-errors-dialog.dialog-confirm',
		defaultMessage: 'Done',
		description: 'Button caption to close the data refresh error detail dialog',
	},
});
