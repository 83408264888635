import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	invalidAltText: {
		id: 'polaris-lib-editor.controllers.adf.utils.invalid-alt-text',
		defaultMessage:
			"The only accepted characters for alt text are letters, numbers, and these symbols: , ' . - _ ( )",
		description: 'Message for invalid alt text',
	},
	mediaContextFetchError: {
		id: 'polaris-lib-editor.controllers.adf.utils.media-context-fetch-error',
		defaultMessage: 'Error fetching media context',
		description: 'Error message when unable to fetch media embedded in an editor',
	},
	mediaContextFetchErrorDescription: {
		id: 'polaris-lib-editor.controllers.adf.utils.media-context-fetch-error-description',
		defaultMessage: 'Media and attachments are not available. Please refresh your page.',
		description: 'Error description for error when unable to fetch media embedded in an editor',
	},
	publishMediaContextFetchError: {
		id: 'polaris-lib-editor.controllers.adf.utils.publish-media-context-fetch-error',
		defaultMessage: 'This view is no longer available',
		description:
			'Generic warning message when unable to fetch media embedded in an editor in published view',
	},
	publishMediaContextFetchErrorDescription: {
		id: 'polaris-lib-editor.controllers.adf.utils.publish-media-context-fetch-error-description',
		defaultMessage: 'Some parts of this view will not work correctly. Please refresh your page.',
		description:
			'Generic warning description for problem when unable to fetch media embedded in an editor in published view',
	},
});
