import React from 'react';
import noop from 'lodash/noop';
import { Flex, Box } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { ExternalReferenceField } from '@atlassian/jira-polaris-common/src/ui/fields/external-reference/main';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const ExternalReferenceFieldValue = ({ fieldKey, groupIdentity }: FieldValueComponentProps) => (
	<Flex>
		<ExternalReferenceField
			isEditable={false}
			fieldKey={fieldKey}
			value={[groupIdentity]}
			onUpdate={noop}
		/>
	</Flex>
);

const EmptyExternalReferenceFieldValue = () => {
	const { formatMessage } = useIntl();

	return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyFieldValue)}</Box>;
};

export const externalReferenceFieldValueConfig = {
	isHideable: true,
	isDecoratable: false,
	Component: ExternalReferenceFieldValue,
	EmptyComponent: EmptyExternalReferenceFieldValue,
} as const;
