import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';

// eslint-disable-next-line @atlaskit/design-system/no-empty-styled-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DraftIndicatorComponent = styled.div({});

export const DraftIndicator = (props: ComponentPropsWithoutRef<typeof DraftIndicatorComponent>) => (
	<Heading level="h100">
		<DraftIndicatorComponent {...props} />
	</Heading>
);
