import React, { type ReactElement } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { SpotlightTypes } from '../../common/types';
import { Spotlight } from '../../common/ui/spotlight';
import { getSpotlightAnalyticsContextData } from '../../common/utils';
import { useActions } from '../../controllers';
import { useIsLoaded } from '../../controllers/selectors/remote-state-hooks';
import { useIsCreateIdeaSpotlightShown } from '../../controllers/selectors/spotlights-hooks';
import messages from './messages';

type Props = {
	children: ReactElement;
};

export const CreateIdeaSpotlight = ({ children }: Props) => {
	const { formatMessage } = useIntl();

	const isShown = useIsCreateIdeaSpotlightShown();
	const isLoaded = useIsLoaded();
	const { closeSpotlight } = useActions();

	return (
		<ContextualAnalyticsData {...getSpotlightAnalyticsContextData('createIdea')}>
			<Spotlight
				isActive={isLoaded && isShown}
				title={formatMessage(messages.title)}
				description={formatMessage(messages.description)}
				learnMoreText={formatMessage(messages.learnMoreText)}
				articleId="6mEl8C11pj5wpqonmZX4Tg"
				placement="bottom-start"
				onClose={() => closeSpotlight(SpotlightTypes.CREATE_IDEA)}
				mode="absolute"
			>
				{children}
			</Spotlight>
		</ContextualAnalyticsData>
	);
};
