import { createSelector } from 'reselect';
import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { getProperties } from '../index';

export const createGetProject = (localIssueId: LocalIssueId | undefined) =>
	createSelector(getProperties, (properties): ProjectFieldValue | undefined => {
		if (localIssueId === undefined) {
			return;
		}
		return properties.projects?.project?.[localIssueId];
	});
