import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';

const SUCCESS_FLAG_CONFIG = {
	type: 'success' as const,
	title: messages.successFlagTitle,
	description: messages.successFlagDescription,
	action: {
		content: messages.joinCommunity,
		href: 'https://community.atlassian.com/t5/Jira-Product-Discovery/ct-p/jpd',
		target: '_blank',
	},
	isAutoDismiss: false,
};

const ERROR_FLAG_CONFIG = {
	type: 'error' as const,
	title: messages.errorFlagTitle,
	description: messages.errorFlagDescription,
	action: {
		content: messages.getHelp,
		href: 'https://support.atlassian.com/',
		target: '_blank',
	},
	isAutoDismiss: false,
};

export const getFlagContent = (formatMessage: (_: MessageDescriptorV2) => string) =>
	[SUCCESS_FLAG_CONFIG, ERROR_FLAG_CONFIG].map((flagConfig) => ({
		title: formatMessage(flagConfig.title),
		description: formatMessage(flagConfig.description),
		actions: [{ ...flagConfig.action, content: formatMessage(flagConfig.action.content) }],
		isAutoDismiss: flagConfig.isAutoDismiss,
	}));
