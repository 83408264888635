import { stringify } from 'query-string';
import type { MentionDescription } from '@atlaskit/mention';
import type { ProjectType } from '@atlassian/jira-common-constants';
import type { BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { AbstractMentionProvider } from '../abstract-mention-provider';

export class ProjectMentionProvider extends AbstractMentionProvider {
	constructor(baseUrl: BaseUrl, projectKey: ProjectKey, projectType?: ProjectType | null) {
		super(baseUrl, projectType);
		this.projectKey = projectKey;
	}

	getQueryString = (query?: string) =>
		stringify({
			query,
			projectKey: this.projectKey,
			maxResults: 10,
		});

	getUrl = (queryString: string) =>
		`${this.baseUrl}/rest/api/2/user/viewissue/search?${queryString}`;

	shouldHighlightMention = (mention: MentionDescription) => mention.id === this.projectKey;

	projectKey: string;
}
