import React from 'react';
import { useGetUserFieldsRollup } from '../../../controllers/issue/selectors/fields-rollup-hooks';
import { CommonFieldGrouped } from '../common';
import { messages } from '../messages';
import type { Props } from '../types';

export const UserFieldGrouped = ({ fieldKey, groupId, groupFieldKey, rollupOperation }: Props) => {
	const val = useGetUserFieldsRollup(fieldKey, groupFieldKey, groupId, rollupOperation);

	return (
		<CommonFieldGrouped rollupOperation={rollupOperation} val={val} message={messages.usersCount} />
	);
};
