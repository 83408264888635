import type { DeliveryIssue } from '@atlassian/jira-polaris-domain-delivery/src/types.tsx';
import type { RemoteIssueLink } from '@atlassian/jira-polaris-remote-issue/src/controllers/issue-link/types.tsx';

export const transformIssueLinkData = (
	issueLinks: RemoteIssueLink[],
	polarisDeliveryIssueLinkType?: string,
	currentDeliveryTickets?: DeliveryIssue[],
	shouldRefreshAllDeliveryTickets?: boolean,
): DeliveryIssue[] => {
	if (!issueLinks) {
		return [];
	}

	const deliveryTickets: DeliveryIssue[] = [];

	issueLinks.forEach((issueLink) => {
		const linkedIssue = issueLink?.inwardIssue;
		const linkedIssueKey = linkedIssue?.key;
		if (!linkedIssue || !linkedIssueKey || issueLink.type.id !== polarisDeliveryIssueLinkType) {
			return;
		}
		const currentTicket = currentDeliveryTickets?.find(
			(ticket) => ticket.issueId === String(linkedIssue.id) && ticket.isTopLevelTicket,
		);
		if (currentTicket && !shouldRefreshAllDeliveryTickets) {
			deliveryTickets.push(currentTicket);
		} else {
			const deliveryTicket: DeliveryIssue = {
				issueId: String(linkedIssue.id),
				issueKey: linkedIssue.key,
				summary: linkedIssue.fields.summary,
				status: linkedIssue.fields.status,
				issueType: linkedIssue.fields.issuetype,
				issueLinkId: issueLink.id,
				isTopLevelTicket: true,
			};
			deliveryTickets.push(deliveryTicket);
		}
	});
	return deliveryTickets;
};
