import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Props } from '../../types';

const getChangedProperties = (view: View, remoteView: View) => {
	const changed = [];
	for (const property in remoteView) {
		// @ts-expect-error - TS7053
		if (JSON.stringify(view[property]) !== JSON.stringify(remoteView[property])) {
			changed.push(property);
		}
	}
	return changed;
};

const getInterestingProps = (props: Props) => ({
	isSharedView: props.isSharedView,
	hasNoProjectPermissions: props.hasNoProjectPermissions,
	hasManageViewsPermission: props.hasManageViewsPermission,
});

export const createUpdateErrorMessageLogSafe = (view: View, remoteView: View, props: Props) =>
	`props: ${JSON.stringify(getInterestingProps(props))} changed: ${JSON.stringify(
		getChangedProperties(view, remoteView),
	)}`;
