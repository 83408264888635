import React, { useMemo } from 'react';
import Button from '@atlaskit/button';
import { useIntl } from '@atlassian/jira-intl';
import { chromeIcon } from '@atlassian/jira-polaris-common/src/common/ui/apps';
import type { AppPreset } from '../types';
import messages from './messages';

const ChromeApp = () => {
	const { formatMessage } = useIntl();

	return (
		<a
			href="https://chrome.google.com/webstore/detail/polaris/flacajjkcnlngpeapmnmignmjgddamah"
			target="_blank"
		>
			<Button spacing="none" appearance="link">
				{formatMessage(messages.title)}
			</Button>
		</a>
	);
};

export const useChromePreset = (): AppPreset => {
	const { formatMessage } = useIntl();

	return useMemo(
		() => ({
			key: 'chrome',
			title: formatMessage(messages.title),
			description: formatMessage(messages.description),
			icon: chromeIcon,
			Action: ChromeApp,
		}),
		[formatMessage],
	);
};
