import { CONTINUOUS, type Axis, type AxisValue } from '../../types';
import {
	areXAndYAxisDiscrete,
	getXAxis,
	getXAxisValues,
	getYAxis,
	getYAxisValues,
	isXAxisDiscrete,
	isYAxisDiscrete,
	isXAxisLocked,
	isYAxisLocked,
	isAxesDragDisabled,
	isXAxisReversed,
	isYAxisReversed,
} from './axis';
import { createSelectorHook } from './utils';

export const useXAxis = createSelectorHook(getXAxis);
export const useYAxis = createSelectorHook(getYAxis);
export const useXAxisValues = createSelectorHook(getXAxisValues);
export const useYAxisValues = createSelectorHook(getYAxisValues);
export const useIsXAxisDiscrete = createSelectorHook(isXAxisDiscrete);
export const useIsYAxisDiscrete = createSelectorHook(isYAxisDiscrete);
export const useIsXAxisReversed = createSelectorHook(isXAxisReversed);
export const useIsYAxisReversed = createSelectorHook(isYAxisReversed);
export const useAreXAndYAxisDiscrete = createSelectorHook(areXAndYAxisDiscrete);
export const useIsXAxisLocked = createSelectorHook(isXAxisLocked);
export const useIsYAxisLocked = createSelectorHook(isYAxisLocked);
export const useIsAxesDragDisabled = createSelectorHook(isAxesDragDisabled);

const getDiscreteValuesCount = (axis: Axis): number => {
	if (axis.type === CONTINUOUS) {
		return axis.range.max - axis.range.min;
	}
	return 0;
};
export const useDiscreteXAxisCount = (): number | undefined => {
	const axis = useXAxis();
	return axis ? getDiscreteValuesCount(axis) : undefined;
};

export const useDiscreteYAxisCount = (): number | undefined => {
	const axis = useYAxis();
	return axis ? getDiscreteValuesCount(axis) : undefined;
};

const getValueCount = (axis: Axis): number => {
	if (axis.type === CONTINUOUS) {
		return axis.range.max - axis.range.min;
	}
	return axis.range.values.length;
};

export const useXAxisValueCount = (): number | undefined => {
	const axis = useXAxis();
	return axis ? getValueCount(axis) : undefined;
};

export const useYAxisValueCount = (): number | undefined => {
	const axis = useYAxis();
	return axis ? getValueCount(axis) : undefined;
};

const createPercentageMapperFunction = (axis?: Axis): ((arg1: number) => AxisValue) | undefined => {
	if (axis === undefined) {
		return undefined;
	}
	if (axis.type === CONTINUOUS) {
		return (percentage: number) => {
			const percentValue = axis.range.isReverseOrder ? 100 - percentage : percentage;
			return Math.round(((axis.range.max - axis.range.min) * percentValue) / 100);
		};
	}
	return (percentage: number) => {
		const valueWidthPercentage = 100 / axis.range.values.length;
		return axis.range.values[Math.floor(percentage / valueWidthPercentage)];
	};
};

export const useXAxisValueMapper = () => createPercentageMapperFunction(useXAxis());
export const useYAxisValueMapper = () => createPercentageMapperFunction(useYAxis());
