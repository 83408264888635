import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createOptionDescriptionText: {
		id: 'polaris-lib-field-option-configuration.field-options-config-menu-bar.search-and-create-input.create-option-description-text',
		defaultMessage: 'Press Enter to add <b>{optionName}</b>',
		description:
			'Description text displayed below an input, informs the user that perssing Enter will create a new option with this specific name',
	},
});
