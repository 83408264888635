import React, { type ReactElement } from 'react';
import { styled } from '@compiled/react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { iconForPolarisFieldType } from '@atlassian/jira-polaris-component-glyphs/src/ui/glyphs/main.tsx';
import { timestampAggDateToInterval } from '@atlassian/jira-polaris-domain-delivery/src/dates/index.tsx';
import { DELIVERY_DATE_AGGREGATION_TYPES } from '@atlassian/jira-polaris-domain-field/src/delivery/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { renderDateString } from '../../../common/utils/date/date-picker';
import { useIntervalDateDecoration } from '../../../controllers/field/selectors/decoration/hooks';
import { useField } from '../../../controllers/issue/selectors/fields-hooks';
import { useSingleIssueDeliveryDate } from '../../../controllers/issue/selectors/properties/hooks';
import { useCurrentUserTimezone } from '../../../controllers/user';
import { DecoratedDate } from '../../decoration/date/index.tsx';
import messages from './messages';

const AGGREGATION_TYPE_TO_TOOLTIP_MESSAGE = {
	[DELIVERY_DATE_AGGREGATION_TYPES.LATEST]: messages.tooltipDateOverridenWithLatest,
	[DELIVERY_DATE_AGGREGATION_TYPES.EARLIEST]: messages.tooltipDateOverridenWithEarliest,
};

type Props = {
	issueId: LocalIssueId;
	fieldKey: FieldKey;
	renderFallback: ReactElement | null;
	hideEmpty?: boolean;
};

export const DeliveryDate = ({ issueId, fieldKey, renderFallback, hideEmpty }: Props) => {
	const { locale } = useTenantContext();
	const { formatMessage } = useIntl();
	const currentUserTimeZone = useCurrentUserTimezone();

	const field = useField(fieldKey);
	const dateRaw = useSingleIssueDeliveryDate(
		issueId,
		field?.configuration?.customfieldKey,
		field?.configuration?.aggregationType,
	);

	const date = timestampAggDateToInterval(dateRaw, locale, currentUserTimeZone);
	const decoration = useIntervalDateDecoration(fieldKey, date || undefined, locale);
	const tooltipMessage = field?.configuration?.aggregationType
		? AGGREGATION_TYPE_TO_TOOLTIP_MESSAGE[field.configuration.aggregationType]
		: undefined;

	if (
		!tooltipMessage ||
		!field?.configuration?.customfieldKey ||
		!field?.configuration?.aggregationType ||
		!date
	) {
		if (renderFallback === null && hideEmpty) return null;
		return renderFallback;
	}

	return (
		<Container>
			<Tooltip content={formatMessage(tooltipMessage)}>
				<Container>
					<DecoratedDate datetime={renderDateString(date)} decoration={decoration} />
					{iconForPolarisFieldType(FIELD_TYPES.DELIVERY_STATUS)}
				</Container>
			</Tooltip>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});
