import React, { useCallback, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';
import { useIntl } from '@atlassian/jira-intl';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { FieldDecorator } from '@atlassian/jira-polaris-lib-decoration/src/ui/decorator/index.tsx';
import {
	fireTrackAnalytics,
	FireUiAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { MAX_FIELD_LENGTH } from '../../../../../../common/constants';
import { useFieldActions } from '../../../../../../controllers/field/main.tsx';
import { useDecorationForValue } from '../../../../../../controllers/field/selectors/decoration/hooks.tsx';
import { useFieldType } from '../../../../../../controllers/field/selectors/field-hooks';
import { FIELD_ITEM_OUTER_SPACING } from '../../../../../config/fields/field-item/constants';
import { canModifyOptionName } from '../../utils/fields';
import { useOptionsAsIdNameTuples } from '../utils/options';
import messages from './messages';

type ControlledFieldDecoratorProps = {
	fieldKey: FieldKey;
	id: string;
	autoFocusName?: boolean;
	onCloseRequested?: () => void;
};

const useFieldTypeDependentProps = (fieldKey: FieldKey, id: string) => {
	const { formatMessage } = useIntl();
	const { renameOption } = useFieldActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fieldType = useFieldType(fieldKey);
	const isEditable = useMemo(() => canModifyOptionName(fieldType), [fieldType]);

	const options = useOptionsAsIdNameTuples(fieldKey);
	const otherOptions = useMemo(
		() => options.filter(({ id: optionId }) => optionId !== id),
		[options, id],
	);

	const option = useMemo(() => {
		const optionsByKey = keyBy(options, ({ id: optionId }) => optionId);
		return optionsByKey[id];
	}, [id, options]);

	const onNameValidate = useCallback(
		(newValue: string) => {
			if (newValue === '') {
				return {
					isValid: false,
					error: formatMessage(messages.valueEmptyError),
				};
			}

			const otherCurrentValues = (otherOptions || []).map(({ value }) => value);
			if (otherCurrentValues.includes(newValue)) {
				return {
					isValid: false,
					error: formatMessage(messages.valueDuplicateError),
				};
			}

			if (newValue.length > MAX_FIELD_LENGTH) {
				return {
					isValid: false,
					error: formatMessage(messages.valueTooLongError),
				};
			}

			return {
				isValid: true,
			};
		},
		[formatMessage, otherOptions],
	);

	const onNameChange = useCallback(
		(newValue: string) => {
			if (id !== undefined) {
				fireUIAnalytics(createAnalyticsEvent({}), 'name changed', 'config-fields', {});
				renameOption(fieldKey, id, newValue);
			}
		},
		[createAnalyticsEvent, fieldKey, id, renameOption],
	);

	return useMemo(() => {
		let retVal: {
			value: string;
			onNameValidate?: (arg1: string) => { isValid: boolean; error?: string | undefined };
			onNameChange?: (arg1: string) => void;
		} = {
			value: option.value,
		};

		if (isEditable) {
			retVal = {
				...retVal,
				onNameValidate,
				onNameChange,
			};
		}

		return retVal;
	}, [isEditable, onNameChange, onNameValidate, option.value]);
};

export const ControlledFieldDecorator = ({
	fieldKey,
	id,
	autoFocusName,
	onCloseRequested,
}: ControlledFieldDecoratorProps) => {
	const decoration = useDecorationForValue(fieldKey, id);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fieldType = useFieldType(fieldKey);
	const { updateValueDecoration, createValueDecoration } = useFieldActions();
	const [currentColor, setCurrentColor] = useState(decoration?.backgroundColor);
	const [currentHighlighted, setCurrentHighlighted] = useState(
		decoration?.highlightContainer || false,
	);
	const [currentEmojiId, setCurrentEmojiId] = useState(decoration?.emoji);

	const additionalProps = useFieldTypeDependentProps(fieldKey, id);
	const optionIds = useOptionsAsIdNameTuples(fieldKey).map((option) => option.id);

	const onValueDecorationChanged = useCallback(
		({
			backgroundColor,
			emoji,
			highlightContainer,
		}: {
			backgroundColor: string | undefined;
			emoji: string | undefined;
			highlightContainer: boolean;
		}) => {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fieldDecoration changed', 'config-fields', {
				action: decoration !== undefined ? 'update' : 'create',
				backgroundColor,
				emoji,
				highlightContainer,
			});

			if (decoration !== undefined) {
				updateValueDecoration({
					fieldKey,
					localDecorationId: decoration.localDecorationId,
					backgroundColor,
					emoji,
					highlightContainer,
					rules: [{ value: id, operator: ValueRuleOperator.EQ }],
					filterOutDecorationRuleFn: (decorationRule) => optionIds.includes(decorationRule.value),
				});
			} else {
				createValueDecoration({
					fieldKey,
					backgroundColor,
					emoji,
					highlightContainer,
					rules: [{ value: id, operator: ValueRuleOperator.EQ }],
				});
			}
		},
		[
			createAnalyticsEvent,
			createValueDecoration,
			decoration,
			fieldKey,
			id,
			updateValueDecoration,
			optionIds,
		],
	);

	const onColorChanged = useCallback(
		(backgroundColor: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'color clicked', 'config-fields', {
				backgroundColor,
			});

			onValueDecorationChanged({
				backgroundColor,
				emoji: currentEmojiId,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentEmojiId, currentHighlighted, onValueDecorationChanged],
	);

	const onEmojiChanged = useCallback(
		(emoji: string | undefined) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'emoji clicked', 'config-fields', {
				emoji,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji,
				highlightContainer: currentHighlighted,
			});
		},
		[createAnalyticsEvent, currentColor, currentHighlighted, onValueDecorationChanged],
	);

	const onHighlightContainerChanged = useCallback(
		(highlightContainer: boolean) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'highlightContainer clicked', 'config-fields', {
				highlightContainer,
			});

			onValueDecorationChanged({
				backgroundColor: currentColor,
				emoji: currentEmojiId,
				highlightContainer,
			});
		},
		[createAnalyticsEvent, currentColor, currentEmojiId, onValueDecorationChanged],
	);

	return (
		<>
			<FireUiAnalytics
				actionSubject="fieldDecorationMenu"
				action="viewed"
				actionSubjectId="viewControls"
			/>
			<FieldDecorator
				isHighlighted={decoration?.highlightContainer === true}
				color={decoration?.backgroundColor}
				emojiId={decoration?.emoji}
				isNameEditable={canModifyOptionName(fieldType)}
				preventFocusPropagation
				onColorChanged={(hexCode) => {
					setCurrentColor(hexCode);
					onColorChanged(hexCode);
				}}
				onHighlightingChanged={(highlighted) => {
					setCurrentHighlighted(highlighted);
					onHighlightContainerChanged(highlighted);
				}}
				onEmojiSelected={(selectedEmoji) => {
					setCurrentEmojiId(selectedEmoji);
					onEmojiChanged(selectedEmoji);
				}}
				{...additionalProps}
				autoFocusName={autoFocusName === true}
				onCloseRequested={onCloseRequested}
				outerSpacing={FIELD_ITEM_OUTER_SPACING}
			/>
		</>
	);
};
