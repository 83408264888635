import React, { type FC, useCallback, useMemo, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics';
import { useIntl } from '@atlassian/jira-intl';
import {
	StickyHeaderTrackerContainer,
	useStickyHeader,
} from '@atlassian/jira-issue-header-tracker-service';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { GROUP_COLLAPSIBLE_UI_EVENT } from '../common/constants';
import type { BaseGroupWithIconProps, CollapsibleGroupProps, GeneralProps } from '../common/types';
import CollapsibleGroupFactory from '../common/ui/collapsible-group-factory';
import {
	ConfigButton,
	configButtonSelectorName,
	defaultComponents,
	IconWrapper,
} from '../common/ui/styled';
import { useGroupLocalStorage } from '../services/local-storage';
import messages from './messages';
import { Status } from './status';
import { Subtitle } from './subtitle';

const TESTID = 'issue-view-layout-group.ui.button';

/**
 * Default Grouping component used for Issue View
 * @param {*} param0
 */
export const CollapsibleGroup = ({
	children,
	title,
	subTitle,
	onChange,
	components,
	projectKey,
	id,
	onConfigButtonClick,
	actionButton,
	initialOpened,
	additionalEventAttributes,
}: CollapsibleGroupProps) => {
	const startTime = useRef(performance.now());
	const CollapsibleGroupRef = useRef<unknown>(null);
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useGroupLocalStorage(projectKey, id, !!initialOpened);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [stickyHeaderPosition, stickyHeaderEnabled] = useStickyHeader();
	const forgeExtensionId = useMemo(
		() =>
			id.includes('ari:cloud:ecosystem::extension')
				? id.replace(/(.*)(ari:cloud:ecosystem::extension)/, '$2')
				: null,
		[id],
	);

	// Ideally this code should be run inside the Forge component, but the `isOpen` is being initialized here,
	// after the children are already rendered
	useEffect(
		() => {
			if (isOpen && forgeExtensionId) {
				forgeModulesConcurrentLoadTime(forgeExtensionId).start({ startTime: startTime.current });
			}
		},
		// We want to run this code only once on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	if (stickyHeaderEnabled === true) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const previousValue = usePrevious(isOpen);
		const hasClosed = !isUndefined(previousValue) && !isOpen && !isEqual(isOpen, previousValue);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (hasClosed) {
				// Wait until group has collapsed
				setTimeout(() => {
					const refElement = CollapsibleGroupRef.current;

					// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null | undefined'.
					smoothScrollIntoViewIfNeeded(refElement, {
						scrollMode: 'if-needed',
						behavior: (actions) =>
							actions.forEach(({ el, top }) => {
								// eslint-disable-next-line no-param-reassign
								el.scrollTop = top - (stickyHeaderPosition || 0);
							}),
						block: 'start',
					});
				});
			}
		}, [hasClosed, stickyHeaderPosition]);
	}

	const onHeaderClick = useCallback(() => {
		setIsOpen(!isOpen);

		const openedOrClosed = isOpen ? 'closed' : 'opened';
		const analyticsEvent = createAnalyticsEvent({
			eventName: `bentoCollapsibleGroup ${openedOrClosed}`,
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'bentoCollapsibleGroup',
		});
		const uiAnalytics: Attributes = {
			groupTitle: title,
			groupState: openedOrClosed,
			...additionalEventAttributes,
		};
		fireUIAnalytics(analyticsEvent, GROUP_COLLAPSIBLE_UI_EVENT, uiAnalytics);

		if (forgeExtensionId && !isOpen) {
			forgeModulesConcurrentLoadTime(forgeExtensionId).start();
		}

		if (typeof onChange === 'function') onChange(!isOpen);
	}, [
		additionalEventAttributes,
		createAnalyticsEvent,
		forgeExtensionId,
		isOpen,
		onChange,
		setIsOpen,
		title,
	]);

	const onConfigButtonClickCallback = useCallback(
		(event: Event) => {
			event.preventDefault();
			event.stopPropagation();
			onConfigButtonClick?.(event);
		},
		[onConfigButtonClick],
	);

	const renderHeaderActions = useCallback(
		() => (
			<>
				<Tooltip content={formatMessage(messages.configure)}>
					{typeof onConfigButtonClick === 'function' && (
						<ConfigButton
							testId={TESTID}
							spacing="compact"
							appearance="subtle"
							// @ts-expect-error - TS2322 - Type '(event: Event) => void' is not assignable to type '(e: MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => void'.
							onClick={onConfigButtonClickCallback}
							data-component-selector={configButtonSelectorName}
						>
							<SettingsIcon
								label={formatMessage(messages.configure)}
								size="small"
								primaryColor={token('color.icon', colors.N90)}
							/>
						</ConfigButton>
					)}
				</Tooltip>
				{actionButton}
				<IconWrapper>
					{isOpen ? (
						<ChevronUpIcon label="" primaryColor={token('color.icon.subtle', colors.N500)} />
					) : (
						<ChevronDownIcon label="" primaryColor={token('color.icon.subtle', colors.N500)} />
					)}
				</IconWrapper>
			</>
		),
		[actionButton, formatMessage, isOpen, onConfigButtonClick, onConfigButtonClickCallback],
	);

	return (
		<StickyHeaderTrackerContainer scope={`group-${id}`}>
			<CollapsibleGroupFactory
				id={id}
				title={title}
				subTitle={subTitle}
				isOpen={isOpen}
				onHeaderClick={onHeaderClick}
				components={components}
				renderHeaderActions={renderHeaderActions}
				stickyHeaderPosition={stickyHeaderPosition}
				// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown> | null' is not assignable to type 'Ref<Props> | undefined'.
				ref={stickyHeaderEnabled === true ? CollapsibleGroupRef : null}
			>
				{children}
			</CollapsibleGroupFactory>
		</StickyHeaderTrackerContainer>
	);
};

/**
 * Group with header Icon and a Badge
 */
export const CollapsibleGroupWithHeaderIcon: FC<BaseGroupWithIconProps> = ({
	icon,
	title,
	subtitle,
	status,
	extension,
	...rest
}) => {
	const components = {
		HeaderRightAligned: useCallback<FC<GeneralProps>>(
			({ children, isOpen }) => (
				<defaultComponents.HeaderRightAligned>
					{!isOpen && <Status status={status} extension={extension} />}
					{children}
				</defaultComponents.HeaderRightAligned>
			),
			[status, extension],
		),
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const name = title as string;
	return (
		<CollapsibleGroup
			{...rest}
			title={title}
			components={components}
			subTitle={<Subtitle icon={icon} label={subtitle} name={name} />}
		/>
	);
};
