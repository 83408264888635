import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';
import {
	type SelectPublicGetValue,
	type SelectPublicSetValue,
	selectScopes,
} from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/fields/select';
import type { PublicToInternalPropertyTransformerContext } from '@atlassian/jira-ui-modifications-fields-configuration/src/common/utils/common/types';
import { commonIssueViewGranularScopes } from './fields-scopes';

// Remove `{ id: string }` on ui-modifications-use-ari-for-single-select-in-issue-view_z50d cleanup
type SelectInternalIdShapeValue = null | { id: string } | { ari: string };

type SelectInternalFullShapeValue = null | {
	id: string;
	value: string;
	self?: string;
};

export const selectSetValueTransformer = (
	value: SelectPublicSetValue,
	_lookupValues: undefined,
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
): SelectInternalIdShapeValue => {
	if (value === null) {
		return null;
	}

	const ari = JiraIssueFieldOptionAri.create({
		siteId: tenantContext.cloudId,
		activationId: tenantContext.activationId,
		fieldId: fieldContext.fieldId,
		optionId: value,
	}).toString();

	return { ari };
};

export const selectGetValueTransformer = (
	value: SelectInternalFullShapeValue,
): SelectPublicGetValue => {
	if (!value) {
		return null;
	}

	return { id: value.id, value: value.value };
};

export const selectIssueViewScopes = {
	...selectScopes,
	granular: [...selectScopes.granular, ...commonIssueViewGranularScopes],
};
