import React, { memo, useState, useMemo, useEffect, Component } from 'react';
import { jpdProjectPageLoad } from '@atlassian/jira-polaris-common/src/common/utils/metrics/project';
import { useFieldCount } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import {
	useActiveIssueCount,
	useArchivedIssueCount,
	useRankedIssueCount,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/issue-ids-hooks';
import {
	useCurrentViewId,
	useCurrentViewKind,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { useViewCount } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view/view-sets/hooks';
import { useProjectIdUnsafe } from '@atlassian/jira-polaris-component-environment-container';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';

// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
class SubmitApdexOnce extends Component<{ extra: Record<string, any> }> {
	shouldComponentUpdate() {
		return false;
	}

	render() {
		return (
			<SubmitApdex
				extra={this.props.extra}
				appName="jpd.project"
				// @ts-expect-error - TS2322 - Type 'PageLoadMetric' is not assignable to type 'BM3Metric'.
				metric={jpdProjectPageLoad}
			/>
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SubmitApdexAfterPaint = (props: { extra: Record<string, any> }) => {
	const [submit, setSubmit] = useState(false);
	useEffect(() => {
		setSubmit(true);
	}, []);
	return submit ? <SubmitApdexOnce {...props} /> : null;
};

export const SubmitJpdProjectLoadedEvent = memo(() => {
	const viewKind = useCurrentViewKind();
	const viewId = useCurrentViewId();
	const viewCount = useViewCount();

	const issueCount = useRankedIssueCount();
	const archivedIssueCount = useArchivedIssueCount();
	const activeIssueCount = useActiveIssueCount();

	const fieldCount = useFieldCount();

	const projectId = useProjectIdUnsafe();

	const finishJpdProjectPageLoadExtra = useMemo(
		() => ({
			issueCount,
			projectId,
			viewId,
			viewKind,
			archivedIssueCount,
			activeIssueCount,
			viewCount,
			fieldCount,
		}),
		[
			issueCount,
			projectId,
			viewId,
			viewKind,
			archivedIssueCount,
			activeIssueCount,
			viewCount,
			fieldCount,
		],
	);
	return <SubmitApdexAfterPaint extra={finishJpdProjectPageLoadExtra} />;
});
