import { createSelector } from 'reselect';
import mapKeys from 'lodash/mapKeys';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { getUserProperties } from '../index';

export const createGetUsersByAccountIdSelector = (fieldKey: FieldKey) =>
	createSelector(getUserProperties, (userProperties) =>
		userProperties[fieldKey]
			? mapKeys(userProperties[fieldKey], (user) => user?.accountId)
			: undefined,
	);
