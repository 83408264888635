import {
	type IntervalFieldFilterOperator,
	type NumericFieldFilterValue,
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
} from './types';

export const createStartAfterNowValueOperator = (
	numericValue: number,
): IntervalFieldFilterOperator => ({
	operator: START_AFTER_NOW,
	numericValue,
});

export const createStartBeforeNowValueOperator = (
	numericValue: number,
): IntervalFieldFilterOperator => ({
	operator: START_BEFORE_NOW,
	numericValue,
});

export const createEndAfterNowValueOperator = (
	numericValue: number,
): IntervalFieldFilterOperator => ({
	operator: END_AFTER_NOW,
	numericValue,
});

export const createEndBeforeNowValueOperator = (
	numericValue: number,
): IntervalFieldFilterOperator => ({
	operator: END_BEFORE_NOW,
	numericValue,
});

export const ltFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'LT',
	numericValue,
});

export const lteFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'LTE',
	numericValue,
});

export const gtFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'GT',
	numericValue,
});

export const gteFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'GTE',
	numericValue,
});

export const eqFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'EQ',
	numericValue,
});

export const neqFilter = (numericValue: number): NumericFieldFilterValue => ({
	operator: 'NEQ',
	numericValue,
});

export const evaluateNumericFilterValues = (
	filterValues: NumericFieldFilterValue[],
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	value: number | void,
): boolean =>
	filterValues.find(({ operator, numericValue }) => {
		switch (operator) {
			case 'LTE':
				return (
					!(numericValue === value) &&
					(numericValue === undefined || value === undefined || !(value < numericValue))
				);
			case 'GTE':
				return (
					!(numericValue === value) &&
					(numericValue === undefined || value === undefined || !(value > numericValue))
				);
			case 'LT':
				return numericValue === undefined || value === undefined || !(value < numericValue);
			case 'GT':
				return numericValue === undefined || value === undefined || !(value > numericValue);
			case 'EQ':
				return numericValue !== value;
			case 'NEQ':
				return numericValue === value;
			default:
				return false;
		}
	}) === undefined;
