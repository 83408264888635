import has from 'lodash/has';
import { getAriConfig } from '@atlassian/jira-platform-ari';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdsByJiraIssueId } from '../../../selectors/issue-ids';
import type { Props, State } from '../../../types';

export const createInsight =
	(insight: Insight) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		if (!insight.container) {
			return;
		}

		const { resourceId } = getAriConfig(insight.container);

		if (!has(localIdMap, resourceId)) {
			return;
		}

		setState({
			properties: {
				...state.properties,
				insights: {
					...state.properties.insights,
					[localIdMap[resourceId]]: [
						...(state.properties.insights[localIdMap[resourceId]] || []),
						insight,
					],
				},
			},
		});
	};
