import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	PRINCIPAL_TYPES,
	VIEW_ACCESS_LEVELS,
	ACCESS_ROLE_ERRORS,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRoleAri,
	Account,
	Group,
	AccessRole,
	ViewAccessLevel,
	PrincipalType,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isAccessForbiddenError,
	isNotFoundError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils';
import { isSetViewAccessPrincipalRolesErrorResponse } from '@atlassian/jira-polaris-remote-view/src/services/jpd-views-service/view-access/utils';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types';
import { transformRoleToRoleAri } from '../utils';

let controller: AbortController | undefined;

export const loadViewAccess =
	(viewUUID: string, viewId?: string): Action<State, Props> =>
	async ({ setState, getState }, { viewRemote, createAnalyticsEvent }) => {
		setState({
			config: {
				...getState().config,
				error: undefined,
			},
		});

		experience.viewPermissions.loadViewAccess.start();

		try {
			// cancel previous ongoing requests if any
			controller?.abort();
			controller = new AbortController();
			const viewAccess = await viewRemote.fetchViewAccess(viewUUID, controller.signal);

			setState({
				...viewAccess,
				config: {
					...getState().config,
					error: undefined,
				},
			});

			// https://data-portal.internal.atlassian.com/analytics/registry/69637
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData loaded', {
				viewUuid: viewUUID,
				viewId,
				accessLevel: viewAccess.accessLevel,
				userCount: viewAccess.additionalAccess.profiles.length,
				groupCount: viewAccess.additionalAccess.groups.length,
				hidden:
					viewAccess.accessLevel === VIEW_ACCESS_LEVELS.RESTRICTED &&
					viewAccess.additionalAccess.profiles.length === 1 &&
					viewAccess.additionalAccess.groups.length === 0,
			});

			experience.viewPermissions.loadViewAccess.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (error instanceof DOMException && error.name === 'AbortError') {
				experience.viewPermissions.loadViewAccess.abort(error);
				return;
			}

			if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
				experience.viewPermissions.loadViewAccess.abort(error);
			} else {
				experience.viewPermissions.loadViewAccess.failure(error);
			}

			fireErrorAnalytics(createErrorAnalytics('polaris.view-access.load-view-access', error));

			setState({
				config: {
					...getState().config,
					error,
				},
			});
		}
	};

export const updateViewAccessLevel =
	({
		viewUUID,
		accessLevel,
		viewId,
	}: {
		viewUUID: string;
		accessLevel: ViewAccessLevel;
		viewId?: string;
	}): Action<State, Props> =>
	async (
		{ getState, setState, dispatch },
		{ viewRemote, onActionFailed, createAnalyticsEvent },
	) => {
		const oldAccessLevel = getState().accessLevel;

		setState({
			accessLevel,
		});

		try {
			experience.viewPermissions.updateViewAccess.start();

			await viewRemote.updateViewAccessLevel({ viewUUID, accessLevel });
			await dispatch(loadViewAccess(viewUUID, viewId));

			// https://data-portal.internal.atlassian.com/analytics/registry/69638
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData accessLevelChanged', {
				viewUuid: viewUUID,
				viewId,
				from: oldAccessLevel,
				to: accessLevel,
			});

			experience.viewPermissions.updateViewAccess.success();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
				experience.viewPermissions.updateViewAccess.abort(error);
			} else {
				experience.viewPermissions.updateViewAccess.failure(error);
			}

			fireErrorAnalytics(createErrorAnalytics('polaris.view-access.update-access-level', error));

			setState({
				accessLevel: oldAccessLevel,
			});

			onActionFailed(error);
		}
	};

export const addViewAccessPrincipals =
	(
		viewUUID: string,
		principals: {
			id: string;
			type: PrincipalType;
			name: string;
			avatarUrl?: string;
		}[],
		role: AccessRole,
		viewId?: string,
	): Action<State, Props> =>
	async (
		{ setState, getState, dispatch },
		{ viewRemote, onActionFailed, createAnalyticsEvent },
	) => {
		const accessLevel = getState().accessLevel;

		setState({
			config: {
				...getState().config,
				isAddingPrincipals: true,
				failures: undefined,
			},
		});

		const roleAri: AccessRoleAri = transformRoleToRoleAri(role);

		const accounts = principals
			.filter((e) => e.type === PRINCIPAL_TYPES.PROFILE)
			.map((item) => ({
				id: item.id,
				role: roleAri,
			}));

		const groups = principals
			.filter((e) => e.type === PRINCIPAL_TYPES.GROUP)
			.map((item) => ({
				id: item.id,
				role: roleAri,
			}));

		try {
			experience.viewPermissions.addPrincipals.start();

			await viewRemote.addViewAccessPrincipals(viewUUID, accounts, groups);
			await dispatch(loadViewAccess(viewUUID, viewId));

			const currentState = getState();
			setState({
				config: {
					...currentState.config,
					isAddingPrincipals: false,
					skippedAccountFailures: currentState.config.skippedAccountFailures.filter(
						(skippedAccount) =>
							!principals.some((principal) => principal.id === skippedAccount.accountId),
					),
				},
			});

			// https://data-portal.internal.atlassian.com/analytics/registry/69639
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData principalAdded', {
				accessLevel,
				viewUuid: viewUUID,
				viewId,
				role,
				newUserCount: accounts.length,
				newGroupCount: groups.length,
			});

			experience.viewPermissions.addPrincipals.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			await dispatch(loadViewAccess(viewUUID, viewId));

			let errorResponse: unknown = error.originalResponse;

			try {
				if (typeof error.originalResponse?.json === 'function') {
					errorResponse = await error.originalResponse.json();
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (subError: any) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.view-access.add-view-access-principals.parse-error',
						subError,
					),
				);
			}

			if (isSetViewAccessPrincipalRolesErrorResponse(errorResponse)) {
				let hasSkippedAccounts = false;
				let hasFailures = false;

				if (
					errorResponse.details.skippedAccounts &&
					errorResponse.details.skippedAccounts.length > 0
				) {
					hasSkippedAccounts = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69691
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'viewAccessData setRoleFailureSkippedAccounts',
						{
							viewUuid: viewUUID,
							viewId,
							missingManageViewsPermissionCount: errorResponse.details.skippedAccounts.filter(
								(item) =>
									item.errorCode === ACCESS_ROLE_ERRORS.MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,
							).length,
							missingProjectAccessCount: errorResponse.details.skippedAccounts.filter(
								(item) => item.errorCode === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION,
							).length,
						},
					);

					const skippedAccounts = errorResponse.details.skippedAccounts.map((item) => {
						const foundPrincipal = principals.find((principal) => principal.id === item.accountId);

						return {
							...item,
							requestedRole: role,
							name: foundPrincipal?.name ?? '',
							avatarUrl: foundPrincipal?.avatarUrl,
						};
					});

					const currentState = getState();

					setState({
						config: {
							...currentState.config,
							skippedAccountFailures: [
								...currentState.config.skippedAccountFailures.filter(
									(item) =>
										!skippedAccounts.some(
											(skippedAccount) => skippedAccount.accountId === item.accountId,
										),
								),
								...skippedAccounts,
							],
						},
					});
				}

				if (errorResponse.details.failures) {
					hasFailures = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69692
					fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData setRoleFailure', {
						viewUuid: viewUUID,
						viewId,
						failuresCount:
							errorResponse.details.failures.accountIds.length +
							errorResponse.details.failures.groupIds.length,
					});

					setState({
						config: {
							...getState().config,
							failures: {
								role,
								principals: [
									...errorResponse.details.failures.accountIds.map((id) => ({
										id,
										name: principals.find((principal) => principal.id === id)?.name ?? '',
										type: PRINCIPAL_TYPES.PROFILE,
									})),
									...errorResponse.details.failures.groupIds.map((id) => ({
										id,
										name: principals.find((principal) => principal.id === id)?.name ?? '',
										type: PRINCIPAL_TYPES.GROUP,
									})),
								],
							},
						},
					});
				}

				if (hasSkippedAccounts || hasFailures) {
					experience.viewPermissions.addPrincipals.successWithReason(error);
				} else {
					experience.viewPermissions.addPrincipals.failure(error);
				}

				setState({
					config: {
						...getState().config,
						isAddingPrincipals: false,
					},
				});
			} else {
				if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
					experience.viewPermissions.addPrincipals.abort(error);
				} else {
					experience.viewPermissions.addPrincipals.failure(error);
				}

				fireErrorAnalytics(
					createErrorAnalytics('polaris.view-access.add-view-access-principals', error),
				);

				setState({
					config: {
						...getState().config,
						isAddingPrincipals: false,
					},
				});

				onActionFailed(error);
			}
		}
	};

export const changeViewAccessPrincipalRole =
	({
		viewUUID,
		viewId,
		name,
		id,
		role,
		type,
		avatarUrl,
	}: {
		viewUUID: string;
		viewId?: string;
		name: string;
		id: string;
		role: AccessRole;
		type: PrincipalType;
		avatarUrl?: string;
	}): Action<State, Props> =>
	async (
		{ setState, getState, dispatch },
		{ viewRemote, onActionFailed, createAnalyticsEvent },
	) => {
		const { additionalAccess, config, accessLevel } = getState();

		const roleAri = transformRoleToRoleAri(role);
		const oldRoleAri =
			type === PRINCIPAL_TYPES.PROFILE
				? additionalAccess.profiles.find((item) => item.accountId === id)?.role
				: additionalAccess.groups.find((item) => item.groupId === id)?.role;

		if (!oldRoleAri) {
			await dispatch(
				addViewAccessPrincipals(viewUUID, [{ id, name, type, avatarUrl }], role, viewId),
			);
			return;
		}

		try {
			experience.viewPermissions.changePrincipalRole.start();

			setState({
				additionalAccess: {
					profiles:
						type === PRINCIPAL_TYPES.PROFILE
							? additionalAccess.profiles.map((item) =>
									item.accountId === id ? { ...item, role: roleAri } : item,
								)
							: additionalAccess.profiles,
					groups:
						type === PRINCIPAL_TYPES.GROUP
							? additionalAccess.groups.map((item) =>
									item.groupId === id ? { ...item, role: roleAri } : item,
								)
							: additionalAccess.groups,
				},
				config: {
					...config,
					failures: undefined,
					skippedAccountFailures: config.skippedAccountFailures.filter(
						(skippedAccount) => skippedAccount.accountId !== id,
					),
				},
			});

			await viewRemote.addViewAccessPrincipals(
				viewUUID,
				type === PRINCIPAL_TYPES.PROFILE ? [{ id, role: transformRoleToRoleAri(role) }] : [],
				type === PRINCIPAL_TYPES.GROUP ? [{ id, role: transformRoleToRoleAri(role) }] : [],
			);
			await dispatch(loadViewAccess(viewUUID, viewId));

			// https://data-portal.internal.atlassian.com/analytics/registry/69640
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData roleChanged', {
				viewUuid: viewUUID,
				viewId,
				accessLevel,
				principalType: type,
				role,
			});

			experience.viewPermissions.changePrincipalRole.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			let errorResponse: unknown = error.originalResponse;

			try {
				if (typeof error.originalResponse?.json === 'function') {
					errorResponse = await error.originalResponse.json();
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (subError: any) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.view-access.change-view-access-principals-role.parse-error',
						subError,
					),
				);
			}

			const currentState = getState();

			if (isSetViewAccessPrincipalRolesErrorResponse(errorResponse)) {
				let hasSkippedAccounts = false;
				let hasFailures = false;

				if (errorResponse.details.skippedAccounts?.[0]) {
					hasSkippedAccounts = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69691
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'viewAccessData setRoleFailureSkippedAccounts',
						{
							viewUuid: viewUUID,
							viewId,
							missingManageViewsPermissionCount: errorResponse.details.skippedAccounts.filter(
								(item) =>
									item.errorCode === ACCESS_ROLE_ERRORS.MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,
							).length,
							missingProjectAccessCount: errorResponse.details.skippedAccounts.filter(
								(item) => item.errorCode === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION,
							).length,
						},
					);

					setState({
						config: {
							...currentState.config,
							skippedAccountFailures: [
								...currentState.config.skippedAccountFailures.filter(
									(item) =>
										item.accountId !== errorResponse.details.skippedAccounts?.[0]?.accountId,
								),
								{
									accountId: errorResponse.details.skippedAccounts[0].accountId,
									errorCode: errorResponse.details.skippedAccounts[0].errorCode,
									name,
									requestedRole: role,
									avatarUrl,
								},
							],
						},
						additionalAccess: {
							profiles:
								type === PRINCIPAL_TYPES.PROFILE
									? currentState.additionalAccess.profiles.map((item) =>
											item.accountId === id ? { ...item, role: oldRoleAri } : item,
										)
									: currentState.additionalAccess.profiles,
							groups: currentState.additionalAccess.groups,
						},
					});
				}

				if (
					errorResponse.details.failures &&
					(errorResponse.details.failures.accountIds.length ||
						errorResponse.details.failures.groupIds.length)
				) {
					hasFailures = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69692
					fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData setRoleFailure', {
						viewUuid: viewUUID,
						viewId,
						failuresCount: 1,
					});

					setState({
						config: {
							...currentState.config,
							failures: {
								role,
								principals: [
									{
										id,
										name,
										type,
									},
								],
							},
						},
						additionalAccess: {
							profiles:
								type === PRINCIPAL_TYPES.PROFILE
									? currentState.additionalAccess.profiles.map((item) =>
											item.accountId === id ? { ...item, role: oldRoleAri } : item,
										)
									: currentState.additionalAccess.profiles,
							groups:
								type === PRINCIPAL_TYPES.GROUP
									? currentState.additionalAccess.groups.map((item) =>
											item.groupId === id ? { ...item, role: oldRoleAri } : item,
										)
									: currentState.additionalAccess.groups,
						},
					});
				}

				if (hasSkippedAccounts || hasFailures) {
					experience.viewPermissions.changePrincipalRole.successWithReason(error);
				} else {
					experience.viewPermissions.changePrincipalRole.failure(error);
				}
			} else {
				if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
					experience.viewPermissions.changePrincipalRole.abort(error);
				} else {
					experience.viewPermissions.changePrincipalRole.failure(error);
				}

				fireErrorAnalytics(
					createErrorAnalytics('polaris.view-access.change-view-access-principals-role', error),
				);

				onActionFailed(error);
			}
		}
	};

export const removeViewAccessPrincipals =
	(viewUUID: string, accounts: string[], groups: string[], viewId?: string): Action<State, Props> =>
	async ({ setState, getState }, { viewRemote, onActionFailed, createAnalyticsEvent }) => {
		const state = getState();
		const accessLevel = state.accessLevel;

		const filterOutDeletedAccounts = (item: Account) => !accounts.includes(item.accountId);
		const filterOutDeletedGroups = (item: Group) => !groups.includes(item.groupId);

		const newAdditionalAccess = {
			profiles: state.additionalAccess.profiles.filter(filterOutDeletedAccounts),
			groups: state.additionalAccess.groups.filter(filterOutDeletedGroups),
		};

		setState({
			additionalAccess: newAdditionalAccess,
			config: {
				...state.config,
				skippedAccountFailures: state.config.skippedAccountFailures.filter(
					({ accountId }) => !accounts.includes(accountId),
				),
			},
		});

		try {
			experience.viewPermissions.removePrincipal.start();

			await viewRemote.removeViewAccessPrincipals(viewUUID, accounts, groups);

			// https://data-portal.internal.atlassian.com/analytics/registry/69641
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData principalRemoved', {
				viewUuid: viewUUID,
				viewId,
				accessLevel,
				principalType: accounts.length > 0 ? PRINCIPAL_TYPES.PROFILE : PRINCIPAL_TYPES.GROUP,
			});

			experience.viewPermissions.removePrincipal.success();

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
				experience.viewPermissions.removePrincipal.abort(error);
			} else {
				experience.viewPermissions.removePrincipal.failure(error);
			}

			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-access.remove-view-access-principals', error),
			);

			const filterOutNonDeletedAccounts = (item: Account) => accounts.includes(item.accountId);
			const filterOutNonDeletedGroups = (item: Group) => groups.includes(item.groupId);

			const currentState = getState();
			setState({
				additionalAccess: {
					profiles: [
						...currentState.additionalAccess.profiles.filter(filterOutDeletedAccounts),
						...state.additionalAccess.profiles.filter(filterOutNonDeletedAccounts),
					],
					groups: [
						...currentState.additionalAccess.groups.filter(filterOutDeletedGroups),
						...state.additionalAccess.groups.filter(filterOutNonDeletedGroups),
					],
				},
			});

			onActionFailed(error);
		}
	};

export const clearFailures =
	(): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			config: {
				...getState().config,
				failures: undefined,
			},
		});
	};

export const clearSkippedAccountFailures =
	(): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			config: {
				...getState().config,
				skippedAccountFailures: [],
			},
		});
	};
