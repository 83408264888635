import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import {
	FIELD_TYPES,
	FIELD_TYPES_CATEGORIES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { PropertyMap } from '../types';
import { getFields } from './fields';
import { getRankedIssueIds } from './issue-ids';
import { getPeopleProperties, getUserProperties } from './properties';

export type UserInformation = {
	[key: string]: UserFieldValue;
};

const getAllUserFields = createSelector(getFields, (fields: Field[]): Field[] =>
	filter(fields, (field) => FIELD_TYPES_CATEGORIES.USER.some((t) => t === field.type)),
);

const getAllPeopleFields = createSelector(getFields, (fields: Field[]): Field[] =>
	filter(
		fields,
		(field) => field.type === FIELD_TYPES.PEOPLE || field.type === FIELD_TYPES.JSW_PEOPLE,
	),
);

const getUserInformationForUserFields = createSelector(
	getRankedIssueIds,
	getAllUserFields,
	getUserProperties,
	(
		allIssues: LocalIssueId[],
		fields: Field[],
		userProperties: PropertyMap<UserFieldValue>,
	): UserInformation => {
		const fieldKeys = fields.map(({ key }) => key);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const retVal: Record<string, any> = {};
		fieldKeys.forEach((fieldKey) => {
			allIssues.forEach((issueId) => {
				const user = userProperties[fieldKey]?.[issueId];
				if (user !== undefined) {
					retVal[user.accountId] = user;
				}
			});
		});
		return retVal;
	},
);

const getUserInformationForPeopleFields = createSelector(
	getRankedIssueIds,
	getAllPeopleFields,
	getPeopleProperties,
	(
		allIssues: LocalIssueId[],
		fields: Field[],
		peopleProperties: PropertyMap<UserFieldValue[]>,
	): UserInformation => {
		const fieldKeys = fields.map(({ key }) => key);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const retVal: Record<string, any> = {};
		fieldKeys.forEach((fieldKey) => {
			allIssues.forEach((issueId) => {
				const users = peopleProperties[fieldKey]?.[issueId];

				if (users !== undefined) {
					users.forEach((user) => {
						retVal[user.accountId] = user;
					});
				}
			});
		});
		return retVal;
	},
);

export const getAllUserInformationOnIssues = createSelector(
	getUserInformationForUserFields,
	getUserInformationForPeopleFields,
	(users: UserInformation, people: UserInformation): UserInformation => ({
		...users,
		...people,
	}),
);
