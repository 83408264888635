import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import set from 'lodash/set';
import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { jiraProjectMapping } from '../../../../field/mapping/project';
import type { PropertyMaps, State } from '../../../types';
import { nullSafeComparator, stringComparator } from '../../comparators';
import { createStringValueContainsFilter } from '../common/filter-utils';
import { removePropertyValue } from '../common/remove-utils';
import type { FieldMapping } from '../types';

export const projectMapping = (field: Field): FieldMapping<ProjectFieldValue> => {
	const valueAccessor: FieldMapping<ProjectFieldValue>['valueAccessor'] = (
		state,
		props,
		issueId,
	) =>
		state.properties.projects[field.key] !== undefined
			? state.properties.projects[field.key][issueId]
			: undefined;

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const project = valueAccessor(state, props, issueId);
		if (!project) {
			return '';
		}
		return project.key;
	};

	return {
		...jiraProjectMapping(field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: ProjectFieldValue) =>
			set(maps.projects, [field.key, issueId], value),
		setImmutable: (
			maps: PropertyMaps,
			issueId: LocalIssueId,
			value?: ProjectFieldValue,
		): PropertyMaps => {
			if (maps.projects[field.key] && isEqual(maps.projects[field.key][issueId], value)) {
				return maps;
			}
			if (value === undefined) {
				return {
					...maps,
					projects: {
						...maps.projects,
						[field.key]: {
							...omit(maps.projects[field.key], [issueId]),
						},
					},
				};
			}
			return {
				...maps,
				projects: {
					...maps.projects,
					[field.key]: {
						...maps.projects[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'projects'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator: nullSafeComparator<ProjectFieldValue>((a, b, direction) =>
			stringComparator(a.name, b.name, direction),
		),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) =>
			state.properties.projects[field.key] ? state.properties.projects[field.key] : {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.projects[fieldKey],
				(ids, projects) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									projects !== undefined && projects[issueId] !== undefined
										? [
												{
													groupIdentity: projects[issueId].id,
													value: projects[issueId],
												},
											]
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) =>
			state.properties.projects[field.key] !== undefined &&
			state.properties.projects[field.key][issueId] !== undefined
				? [
						{
							groupIdentity: state.properties.projects[field.key][issueId].id,
							value: state.properties.projects[field.key][issueId],
						},
					]
				: [],
		allowEmptyGroup: false,
		getLabel: (groupIdentity, value) => value?.name,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueContainsFilter = createStringValueContainsFilter(filter);
				if (stringValueContainsFilter === undefined) {
					return undefined;
				}
				return (value: undefined | ProjectFieldValue, state, props, localIssueId) =>
					stringValueContainsFilter(value?.id, state, props, localIssueId);
			}
			return undefined;
		},
	};
};
