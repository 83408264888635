import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	authenticateButton: {
		id: 'polaris-common.controllers.unfurl.utils.authentication.authenticate-button',
		defaultMessage: 'Authenticate',
		description: 'Authenticate button placeholder',
	},
	restrictedLink: {
		id: 'polaris-common.controllers.unfurl.utils.authentication.restricted-link',
		defaultMessage: 'Restricted link, try another account',
		description: 'Restricted link button placeholder',
	},
});
