import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleInsights: {
		id: 'polaris-common.apps.apps.pendo.title-insights',
		defaultMessage: 'Pendo Insights',
		description: 'Title of the Pendo zendesk app',
	},
	descriptionInsights: {
		id: 'polaris-common.apps.apps.pendo.description-insights',
		defaultMessage: 'Add Pendo features to ideas to track their real-life usage',
		description: 'Description of the Pendo zendesk app',
	},
	titleFeedback: {
		id: 'polaris-common.apps.apps.pendo.title-feedback',
		defaultMessage: 'Pendo Feedback',
		description: 'Title of the polaris zendesk app',
	},
	descriptionFeedback: {
		id: 'polaris-common.apps.apps.pendo.description-feedback',
		defaultMessage: 'Add user requests to ideas to keep track of their direct user impact',
		description: 'Description of the Pendo zendesk app',
	},
	unfurlDialogTitle: {
		id: 'polaris-common.apps.apps.pendo.unfurl-dialog-title',
		defaultMessage: 'Connect to Pendo',
		description: 'Title of the Pendo try-it-now unfurl dialog',
	},
	unfurlDialogPlaceholder: {
		id: 'polaris-common.apps.apps.pendo.unfurl-dialog-placeholder',
		defaultMessage: 'Paste a link to Pendo here',
		description: 'Placeholder of the Pendo try-it-now unfurl dialog input text field',
	},
	unfurlDialogSuccess: {
		id: 'polaris-common.apps.apps.pendo.unfurl-dialog-success',
		defaultMessage: 'This is what it looks like when you add a Pendo link to an idea.',
		description: 'Success message of Pendo the try-it-now unfurl dialog',
	},
});
