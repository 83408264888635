import {
	DONE,
	IN_PROGRESS,
	NO_CATEGORY,
	TODO,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import { issueKeyComparator } from '@atlassian/jira-polaris-domain-field/src/sort/index.tsx';
import type { DeliveryIssue } from '../types';

const hierarchyLevelComparator = (issue1: DeliveryIssue, issue2: DeliveryIssue) =>
	issue2.issueType.hierarchyLevel - issue1.issueType.hierarchyLevel;

const sortedStatusCategories = [TODO, IN_PROGRESS, DONE, NO_CATEGORY];

const statusCategoryComparator = (issue1: DeliveryIssue, issue2: DeliveryIssue) =>
	sortedStatusCategories.findIndex((category) => category === issue1.status.statusCategory.key) -
	sortedStatusCategories.findIndex((category) => category === issue2.status.statusCategory.key);

export const deliveryIssueComparator = (issue1: DeliveryIssue, issue2: DeliveryIssue): number =>
	hierarchyLevelComparator(issue1, issue2) ||
	statusCategoryComparator(issue1, issue2) ||
	issueKeyComparator(issue1.issueKey, issue2.issueKey);
