import React from 'react';
import JpdListBlankIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/jpd-list-blank';
import { useIntl } from '@atlassian/jira-intl';
import { Container, Wrapper } from '../../common/ui/container/styled';
import { ViewEmptyState } from '../../common/ui/view-empty-state';
import { ListPlaceholder, RowPlaceholder } from '../../common/ui/view-empty-state/styled';
import messages from './messages';

export type NoViewsEmptyStateProps = {
	primaryAction?: React.ReactNode;
};

export const NoViewsEmptyState = ({ primaryAction }: NoViewsEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			<Wrapper>
				<ViewEmptyState
					renderImage={JpdListBlankIllustrationWrapper}
					header={formatMessage(messages.noViewsToShowMessage)}
					description={formatMessage(messages.noViewsToShowMessageDescription)}
					primaryAction={primaryAction}
				/>
			</Wrapper>
			<ListPlaceholder>
				{[...Array.from({ length: 15 })].map((_, i) => (
					<RowPlaceholder key={i} isHeader={i === 0} />
				))}
			</ListPlaceholder>
		</Container>
	);
};

const JpdListBlankIllustrationWrapper = () => <JpdListBlankIllustration alt="" width={306} />;
