import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import type { DocNode } from '@atlaskit/adf-schema';
import { doc } from '@atlaskit/adf-utils/builders';
import { traverse } from '@atlaskit/adf-utils/traverse';
import { fireOperationalAnalyticsWithoutContext } from '@atlassian/jira-ui-modifications-analytics';
import type { ViewType } from '@atlassian/jira-ui-modifications-types';

const getADFValidator = async () => {
	const { validator } = await import(
		/* webpackChunkName: "async-adf-validator" */ '@atlaskit/adf-utils/validator'
	);

	return validator;
};

const validateAdfExternalUrls = (adf: DocNode) => {
	try {
		traverse(adf, {
			media: (node) => {
				if (node.attrs?.type === 'external' && node.attrs?.url !== undefined) {
					throw new Error('Cannot contain external images');
				}
			},
			blockCard: () => {
				throw new Error('Cannot contain blockCard');
			},
			embedCard: () => {
				throw new Error('Cannot contain embedCard');
			},
		});

		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		return false;
	}
};

export const validateAdfProhibitedNodes = (newValue: DocNode, currentValue?: DocNode) => {
	// If there is no user input or defaultValue set, we just validate the `externalValue` directly
	if (!currentValue || !currentValue.content.length) {
		return validateAdfExternalUrls(newValue);
	}

	// Find the diff nodes between the new value and the current value
	const foundDiffNodes = differenceWith(newValue.content, currentValue.content, isEqual);

	// Format `foundDiffNodes` into a valid ADF doc
	const formattedDiffAdf = doc(...foundDiffNodes);

	return validateAdfExternalUrls(formattedDiffAdf);
};

type IsValidAdfArgs = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	newValue: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentValue?: any;
	viewType: ViewType;
};

export const isValidADF = async ({
	newValue,
	currentValue,
	viewType,
}: IsValidAdfArgs): Promise<boolean> => {
	const validator = await getADFValidator();
	const validate = validator();
	const validationStartTime = performance.now();

	try {
		const isValidAdfNewValue =
			// Additionaly to ADF validator it chekcs version property since ADF validator checks only existance of it.
			Object.hasOwnProperty.call(newValue, 'version') &&
			typeof newValue.version === 'number' &&
			!Number.isNaN(newValue.version) &&
			validate(newValue).valid;

		const isValidAdf = isValidAdfNewValue && validateAdfProhibitedNodes(newValue, currentValue);

		fireOperationalAnalyticsWithoutContext('adfValidation', 'completed', viewType, {
			validatedInMs: Math.floor(performance.now() - validationStartTime),
		});

		return isValidAdf;
	} catch (e) {
		return false;
	}
};
