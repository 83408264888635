import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import type { Props, State } from './types';

const actions = {};

type Actions = typeof actions;

export const RightSidebarStore = createStore<State, Actions, Props>({
	initialState: {
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onClose: () => {},
	},
	actions,
	name: 'PolarisLibRightSidebarStore',
});

export const RightSidebarContainer = createContainer<State, Actions, Props>(RightSidebarStore, {
	onInit:
		() =>
		({ setState }, { onClose }) => {
			setState({
				onClose,
			});
		},
});

export const useRightSidebar = createHook<State, Actions, Props>(RightSidebarStore);
