import React, { type ReactElement, useEffect } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useAvailableProductsActions } from '../../controllers/available-products/main.tsx';
import {
	useIsProductAvailable,
	useIsAvailableProductsLoading,
	useAvailableProductsError,
} from '../../controllers/available-products/selectors/available-products-hooks';
import type { ProductType } from '../../services/jira/available-products/types';
import messages from './messages';

type WithAvailableProductProps = {
	productType: ProductType;
	cloudId: string;
	children: (isAvailable: boolean) => ReactElement;
	testId?: string;
};

export const WithAvailableProduct = ({
	productType,
	cloudId,
	children,
	testId,
}: WithAvailableProductProps) => {
	const [, { loadAvailableProducts: load }] = useAvailableProductsActions();
	const { formatMessage } = useIntl();
	const [isAvailable] = useIsProductAvailable({
		productType,
		cloudId,
	});
	const [isLoading] = useIsAvailableProductsLoading();
	const [error] = useAvailableProductsError();

	useEffect(() => {
		load();
	}, [load]);

	if (isLoading) {
		return <Spinner size="small" testId={testId} />;
	}

	if (error) {
		return (
			<Container>
				<Button onClick={load} appearance="subtle-link" spacing="compact">
					{formatMessage(messages.refresh)}
				</Button>
			</Container>
		);
	}

	return children(isAvailable);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: colors.N100,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:first-child': {
		marginRight: token('space.075', '6px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > *:last-child': {
		minWidth: 0,
	},
});
