import React, { useCallback, useState } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldComponentProps } from '../types';
import { LabelsField } from './labels-field';

type LabelsFieldComponentProps = FieldComponentProps & {
	fieldKey: FieldKey;
};

type SelectFieldValue = {
	id: string;
};

export const LabelsFieldComponent = ({
	fieldKey,
	onUpdate,
	isEditable,
	predefinedValue,
}: LabelsFieldComponentProps) => {
	const [value, setValue] = useState<SelectFieldValue[] | undefined>(predefinedValue);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const commit = useCallback(
		(newValue: SelectFieldValue[] | undefined) => {
			if (newValue !== undefined) {
				const updateAsArray = newValue.map(({ id }) => ({ id }));
				onUpdate(
					flattenDeep(updateAsArray.map(({ id }) => id.split(' ').map((part) => part.trim()))),
				);
			}
		},
		[onUpdate],
	);

	const closeAndCommit = useCallback(
		(newValue: SelectFieldValue[] | undefined) => {
			commit(newValue);
			setIsMenuOpen(false);
		},
		[commit],
	);

	const onCloseRequested = useCallback(() => {
		closeAndCommit(value);
	}, [closeAndCommit, value]);

	const updateInternal = useCallback(
		(newValue: SelectFieldValue[], forceStateUpdate: boolean) => {
			setValue(newValue);
			if (forceStateUpdate) {
				commit(newValue);
			}
		},
		[commit, setValue],
	);

	return (
		<LabelsField
			isEditable={isEditable}
			fieldKey={fieldKey}
			value={value}
			onUpdate={updateInternal}
			onCloseRequested={() => onCloseRequested()}
			isMenuOpen={isMenuOpen}
			onOpenMenu={() => setIsMenuOpen(true)}
		/>
	);
};
