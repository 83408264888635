import isEqual from 'lodash/isEqual';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { ColumnId } from '../../../common/types';
import { MIN_COLUMN_WIDTH } from '../../../common/ui/constants';
import type { ColumnSizes } from '../../../types';
import { getColumnMinSizes } from '../../selectors/columns';
import { SUMMARY_COLUMN_KEY } from '../../selectors/items';
import type { State, Props } from '../../types';

const EMPTY = Object.freeze({});

export const updateColumnSizesState =
	(columnSizes?: ColumnSizes, columnMinSizes?: ColumnSizes) =>
	({ getState, setState }: StoreActionApi<State>) => {
		if (columnSizes === undefined) {
			setState({
				columnSizes: EMPTY,
			});
		} else if (!isEqual(columnSizes, getState().columnSizes)) {
			setState({ columnSizes });
		}
		if (columnMinSizes === undefined) {
			setState({
				columnMinSizes: EMPTY,
			});
		} else if (!isEqual(columnMinSizes, getState().columnMinSizes)) {
			setState({ columnMinSizes });
		}
	};

export const resizeColumn =
	(columnId: ColumnId, width: number) =>
	({ getState, setState }: StoreActionApi<State>, { onColumnResize }: Props) => {
		const minSizes = getColumnMinSizes(getState());

		const minSize = minSizes[columnId] || MIN_COLUMN_WIDTH;
		const safeNewSize = Math.max(minSize, width);
		const newSizeRounded = Math.floor(safeNewSize);

		onColumnResize && onColumnResize(columnId, newSizeRounded);

		setState({
			columnSizes: {
				...getState().columnSizes,
				[columnId]: newSizeRounded,
			},
		});
	};

export const setResizingColumn =
	(resizingCol?: ColumnSizes) =>
	({ setState }: StoreActionApi<State>) => {
		setState({ resizingCol });
	};

export const setHoveredColumn =
	(hoveredColumn?: ColumnId) =>
	({ setState, getState }: StoreActionApi<State>, { onHoverActiveCellColumn }: Props) => {
		const { selectedRows, hoveredRow } = getState();
		if (
			selectedRows &&
			hoveredRow &&
			selectedRows.includes(hoveredRow) &&
			hoveredColumn !== SUMMARY_COLUMN_KEY
		) {
			onHoverActiveCellColumn && onHoverActiveCellColumn(hoveredColumn);
		} else {
			// reset hovered column when hover on cell that is not in selected row
			onHoverActiveCellColumn && onHoverActiveCellColumn(undefined);
		}
		setState({ hoveredColumn });
	};

type RankColumnsProps = {
	sourceId: ColumnId;
	targetId: ColumnId;
	dropEdge: Edge;
};

export const rankColumns =
	(props: RankColumnsProps) =>
	({ getState }: StoreActionApi<State>, { onColumnRankEnd }: Props) => {
		const { columns } = getState();
		const { sourceId, targetId, dropEdge } = props;

		const srcIdx = columns.indexOf(sourceId);
		const dstIdx = columns.indexOf(targetId);

		let dstAdj = srcIdx < dstIdx && dropEdge === 'left' ? -1 : 0;
		dstAdj = srcIdx > dstIdx && dropEdge === 'right' ? 1 : dstAdj;

		const targetIdAdjusted = columns[dstIdx + dstAdj];

		onColumnRankEnd &&
			onColumnRankEnd({
				oldKey: sourceId,
				newKey: targetIdAdjusted,
			});
	};
