import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createField: {
		id: 'polaris-common.common.create-field.create-field',
		defaultMessage: 'Create a new field',
		description: 'Label on button that opens view to create field',
	},
	noPermissions: {
		id: 'polaris-common.common.create-field.no-permissions',
		defaultMessage: 'Only project admins can create fields',
		description: 'Information message for missing field creation permissions.',
	},
});
