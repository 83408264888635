import React from 'react';
import { styled } from '@compiled/react';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { TransitionProps } from './types';
import { TransitionWithLabel } from './with-label';
import { TransitionWithoutLabel } from './without-label';

export const Transition = ({
	hasLabel,
	transition,
	addTransitionArrowSpacing,
}: TransitionProps) => {
	if (transition.isDisabled === true) {
		return (
			<DisabledTransition>
				<TransitionWithoutLabel transition={transition} isDisabled />
				{transition.unavailableReasonText != null && transition.unavailableReasonText !== '' && (
					<Tooltip content={transition.unavailableReasonText}>
						<IconWrapper>
							<EditorPanelIcon label="" primaryColor={token('color.text.disabled', colors.N200)} />
						</IconWrapper>
					</Tooltip>
				)}
			</DisabledTransition>
		);
	}

	return hasLabel ? (
		<TransitionWithLabel
			transition={transition}
			addTransitionArrowSpacing={addTransitionArrowSpacing}
		/>
	) : (
		<TransitionWithoutLabel transition={transition} />
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledTransition = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	height: '15px',
	overflow: 'visible',
	marginTop: token('space.negative.050', '-4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	IconWrapper,
};
