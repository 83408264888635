import 'rxjs/add/operator/map';
import log from '@atlassian/jira-common-util-logging/src/log';
import { fetchJson$ } from '@atlassian/jira-fetch';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types';
import { getGraphQlData } from '@atlassian/jira-providers-issue/src/source/issue-express/graphql-util';
import type { BaseUrl } from '@atlassian/jira-shared-types';

type GiraResponseData = {
	setActivitySortOrder: {
		success: boolean;
		errors: {
			message: string;
		}[];
	};
};

const saveActivitySortQuery = (sortOrder: ActivitySortOrderType) => `
    mutation jira_activity_setActivitySortOrder {
        setActivitySortOrder(orderBy: ${sortOrder}) {
            success
            errors {
                message
            }
        }
    }
`;

const LOG_LOCATION = 'issue.activity-feed.sort.save.gira';

const fieldDataOptionsGira = (sortOrder: ActivitySortOrderType) => ({
	method: 'POST',
	body: JSON.stringify({
		query: saveActivitySortQuery(sortOrder),
	}),
});

const transform = (data: GiraResponseData) => {
	const { errors } = data.setActivitySortOrder;
	if (errors && errors.length > 0) {
		const error = new Error(errors[0].message);
		log.safeErrorWithoutCustomerData(
			LOG_LOCATION,
			'Error present in gira response when calling to update setActivitySortOrder',
			error,
		);
	}
	return data.setActivitySortOrder;
};

export const updateActivitySortOrder = (baseUrl: BaseUrl, sortOrder: ActivitySortOrderType) =>
	// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>' is not assignable to method's 'this' of type 'Observable<GiraResponseData>'.
	fetchJson$(`${baseUrl}/rest/gira/1/`, fieldDataOptionsGira(sortOrder))
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GraphqlResponse<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>'.
		.map((response) => getGraphQlData(LOG_LOCATION, response))
		.map(transform);
