import React, {
	type SyntheticEvent,
	type RefCallback,
	useMemo,
	useRef,
	useState,
	useEffect,
	memo,
	useCallback,
	type RefObject,
} from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START,
	PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END,
	PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START,
	PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END,
} from '@atlassian/jira-polaris-common/src/common/utils/metrics/project';
import { useIsGlobalCustomField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/field-hooks';
import { useOpenRightSidebarGroupByOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/actions/hooks.tsx';
import { useRightSidebarShowing } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/selectors/hooks.tsx';
import { RightSidebarShowingGroupByOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { useCurrentViewAnalyticsAttributes } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/analytics';
import {
	useCurrentViewGroupBy,
	useCurrentViewVerticalGroupBy,
	useCurrentViewId,
	useCurrentViewLayoutType,
	useCurrentViewConfigured,
	useIsExporting,
	useHasSharedViewConfigError,
	useCanManageCurrentView,
	useCurrentViewAccessLevel,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { canModifyOptionName } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/utils/fields';
import { IdeaCardContextProvider } from '@atlassian/jira-polaris-common/src/ui/idea-card-v2/utils';
import {
	useCanEditFields,
	useCanManageViews,
	useHasNoProjectPermissions,
	useIsLoadedPermissions,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import {
	fireCompoundAnalyticsEvent,
	useViewOpenedAnalytics,
} from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import ExperienceFailErrorBoundary from '@atlassian/jira-polaris-lib-analytics/src/ui/index.tsx';
import { useRunOnce } from '@atlassian/jira-polaris-lib-run-once';
import { BoardViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/board-view/index.tsx';
import { PublishedViewEmptyState } from '@atlassian/jira-polaris-lib-view-empty-state/src/ui/views/publish-view/index.tsx';
import {
	useAnalyticsEvents,
	SCREEN,
	ContextualAnalyticsData,
	type Attributes,
	type AnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { getGroupIdentityPairForDroppableId } from '../../../common/utils/board';
import { useCurrentViewSuccessExperience } from '../common/utils/experience';
import { useGroupOptions } from '../common/utils/group-options';
import { useExtendedVerticalGroupOptions } from '../common/utils/vertical-group-options';
import { AddColumn } from './add-column';
import { BoardDimensionsProvider } from './board-dimensions-provider';
import { Column } from './column';
import { SwimlanesBody } from './swimlanes/body/index.tsx';
import { Header } from './swimlanes/header/index.tsx';
import { useBoardViewWithGroups, useBoardViewWithoutGroups } from './use-board-view-groups';
import { useBoardVirtualization } from './use-board-virtualization/main.tsx';
import { useBoardDND } from './utils/draggable';
import type { DropResult } from './utils/draggable.types';
import { useCardDropDisabled, useIsMoveBetweenGroupsDisabled } from './utils/drop-disabled';
import { useDropCallback, useSwimlanesDropCallback } from './utils/drop-handler';
import { PerformanceObserver } from './utils/performance';
import { useBoardExport } from './utils/use-board-export';

type BoardWithoutGroupsProps = {
	scrollContainerRef: RefObject<HTMLDivElement>;
	isReadOnly: boolean;
	isFooterVisible: boolean;
	groupByField: Field;
};

const BoardWithoutGroups = ({
	scrollContainerRef,
	isReadOnly,
	isFooterVisible,
	groupByField,
}: BoardWithoutGroupsProps) => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START);
	});

	const canEditFields = useCanEditFields();
	const [newlyCreatedOptionId, setNewlyCreatedOptionId] = useState<unknown>(undefined);
	const addColumnRef = useRef<HTMLDivElement | null>(null);
	const isMoveBetweenColumnsDisabled = useIsMoveBetweenGroupsDisabled(groupByField);
	const baseAnalyticsAttributes = useCurrentViewAnalyticsAttributes();
	const currentViewId = useCurrentViewId();
	const extendedOptions = useGroupOptions(groupByField);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isExporting = useIsExporting();
	const isGlobalCustomField = useIsGlobalCustomField(groupByField.key);
	const viewAccessLevel = useCurrentViewAccessLevel();

	const canAddColumns = useMemo(
		() => !isReadOnly && canEditFields && canModifyOptionName(groupByField.type),
		[canEditFields, groupByField.type, isReadOnly],
	);

	const analyticsAttributes: Attributes = useMemo(
		() => ({
			...baseAnalyticsAttributes,
			viewColumnCount: extendedOptions.length,
			viewFieldValues: extendedOptions.map((i) => i.groupIdentity),
		}),
		[baseAnalyticsAttributes, extendedOptions],
	);

	useViewOpenedAnalytics({
		viewId: currentViewId,
		viewAccessLevel,
		analyticsCallback: (accessLevel: string | undefined) => {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END);

			fireCompoundAnalyticsEvent.BoardView.opened(createAnalyticsEvent({}), accessLevel);
			sendPendoTrackEvent({
				actionSubjectAndAction: 'ideasBoardViewScreen viewed',
				attributes: { accessLevel: accessLevel || '' },
			});
		},
	});

	useEffect(() => {
		if (!fg('polaris-view-access-level-analytics')) {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END);
			fireCompoundAnalyticsEvent.BoardView.opened(createAnalyticsEvent({}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentViewId]); // run when the view changes

	const handleDrop = useDropCallback(groupByField, extendedOptions);

	useEffect(() => {
		if (newlyCreatedOptionId !== undefined && addColumnRef.current) {
			addColumnRef.current.scrollIntoView();
			setNewlyCreatedOptionId(undefined);
		}
	}, [newlyCreatedOptionId]);

	const shouldInterruptDnd = useCallback(
		({ source, destination }: DropResult) => {
			const sourceColumnId = source?.droppableId;
			const destinationColumnId = destination?.droppableId;

			return isMoveBetweenColumnsDisabled && sourceColumnId !== destinationColumnId;
		},
		[isMoveBetweenColumnsDisabled],
	);

	const draggedCard = useBoardDND({
		boardContainerRef: scrollContainerRef,
		onDrop: handleDrop,
		shouldInterruptDnd,
	});

	const cardDropDisabledByColumn = useCardDropDisabled({
		field: groupByField,
		extendedOptions,
		draggedCard,
	});

	return (
		<ContextualAnalyticsData
			attributes={analyticsAttributes}
			sourceName="boardView"
			sourceType={SCREEN}
		>
			<BoardWrapper
				ref={scrollContainerRef}
				data-testid="polaris-ideas.ui.view-content.idea-board.board-wrapper"
				isExporting={isExporting}
			>
				<BoardContainer>
					{extendedOptions.map((option, index) => (
						<Column
							key={`${index}-${option.groupIdentity || ''}`}
							droppableId={option.droppableId}
							groupIdentity={option.groupIdentity}
							index={index}
							fieldKey={groupByField.key}
							fieldValue={option.value}
							isCardDropDisabled={
								isReadOnly ||
								(option.groupIdentity !== undefined &&
									cardDropDisabledByColumn[option.groupIdentity])
							}
							isReadOnly={isReadOnly}
							isFooterVisible={isFooterVisible}
							isLastColumn={index === extendedOptions.length - 1}
						/>
					))}
					{canAddColumns && !isGlobalCustomField && (
						<div
							ref={addColumnRef}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="hide-from-export"
						>
							<AddColumn fieldKey={groupByField.key} onAddColumn={setNewlyCreatedOptionId} />
						</div>
					)}
				</BoardContainer>
			</BoardWrapper>
		</ContextualAnalyticsData>
	);
};

BoardWithoutGroups.displayName = 'BoardWithoutGroups';

type BoardWithoutGroupsWrapperProps = Omit<BoardWithoutGroupsProps, 'scrollContainerRef'>;

const BoardWithoutGroupsWrapper = (props: BoardWithoutGroupsWrapperProps) => {
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);
	const { isReadOnly, isFooterVisible, groupByField } = props;

	const groups = useBoardViewWithoutGroups(props.groupByField);

	const { groupColumnContentHeights, subscribeToColumnChanges, unsubscribeToColumnChanges } =
		useBoardVirtualization({
			groups,
			containerRef: scrollContainerRef,
			isReadOnly,
			isFooterVisible,
			hasColumnWithHeaders: true,
		});

	const children = useMemo(
		() => (
			<BoardWithoutGroups
				scrollContainerRef={scrollContainerRef}
				isReadOnly={isReadOnly}
				isFooterVisible={isFooterVisible}
				groupByField={groupByField}
			/>
		),
		[isReadOnly, isFooterVisible, groupByField],
	);

	return (
		<>
			<BoardDimensionsProvider
				groupColumnContentHeights={groupColumnContentHeights}
				subscribeToColumnChanges={subscribeToColumnChanges}
				unsubscribeToColumnChanges={unsubscribeToColumnChanges}
			>
				{children}
			</BoardDimensionsProvider>
			<PerformanceObserver scrollingContainer={scrollContainerRef.current} isGrouped={false} />
		</>
	);
};

BoardWithoutGroupsWrapper.displayName = 'BoardWithoutGroupsWrapper';

type BoardWithGroupsProps = {
	scrollContainerRef: RefCallback<HTMLDivElement>;
	isReadOnly: boolean;
	isFooterVisible: boolean;
	groupByField: Field;
	verticalGroupByField: Field;
};

const BoardWithGroups = ({
	scrollContainerRef,
	isReadOnly,
	isFooterVisible,
	groupByField,
	verticalGroupByField,
}: BoardWithGroupsProps) => {
	useRunOnce(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START);
	});
	const isMoveBetweenColumnsDisabled = useIsMoveBetweenGroupsDisabled(groupByField);
	const isMoveBetweenGroupsDisabled = useIsMoveBetweenGroupsDisabled(verticalGroupByField);
	const extendedOptions = useGroupOptions(groupByField);
	const extendedVerticalOptions = useExtendedVerticalGroupOptions(verticalGroupByField);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const currentViewId = useCurrentViewId();
	const layoutType = useCurrentViewLayoutType();
	const isCompact = layoutType === ViewLayoutType.COMPACT;
	const isExporting = useIsExporting();
	const boardRef = useRef<HTMLDivElement | null>(null);

	const resetScrollPosition = () => {
		if (!boardRef.current) {
			return;
		}

		boardRef.current.scrollTop = 0;
	};

	useEffect(() => {
		resetScrollPosition();
	}, [isCompact]);

	useEffect(() => {
		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END);
		fireCompoundAnalyticsEvent.BoardView.opened(createAnalyticsEvent({}));
		resetScrollPosition();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentViewId]); // run when the view changes

	const handleDrop = useSwimlanesDropCallback(
		groupByField,
		verticalGroupByField,
		extendedOptions,
		extendedVerticalOptions,
	);

	const shouldInterruptDnd = useCallback(
		({ source, destination }: DropResult) => {
			const [sourceGroupId, sourceColumnId] = getGroupIdentityPairForDroppableId(
				source?.droppableId,
			);
			const [destinationGroupId, destinationColumnId] = getGroupIdentityPairForDroppableId(
				destination?.droppableId,
			);

			return (
				(isMoveBetweenColumnsDisabled && sourceColumnId !== destinationColumnId) ||
				(isMoveBetweenGroupsDisabled && sourceGroupId !== destinationGroupId)
			);
		},
		[isMoveBetweenColumnsDisabled, isMoveBetweenGroupsDisabled],
	);

	const draggedCard = useBoardDND({
		boardContainerRef: boardRef,
		onDrop: handleDrop,
		shouldInterruptDnd,
	});

	const onSwimlanesContainerRefChange = useCallback(
		(element: HTMLDivElement | null) => {
			boardRef.current = element;
			scrollContainerRef(element);
		},
		[scrollContainerRef],
	);

	return (
		<SwimlanesContainer
			ref={onSwimlanesContainerRefChange}
			data-testid="polaris-ideas.ui.view-content.idea-board.swimlanes-container"
			id="swimlanes-container"
			isExporting={isExporting}
		>
			<SwimlanesBody
				groupByField={groupByField}
				verticalGroupByField={verticalGroupByField}
				isReadOnly={isReadOnly}
				draggedCard={draggedCard}
				isFooterVisible={isFooterVisible}
			>
				<Header groupByField={groupByField} isReadOnly={isReadOnly} />
			</SwimlanesBody>
		</SwimlanesContainer>
	);
};

BoardWithGroups.displayName = 'BoardWithGroups';

const BoardWithGroupsWrapper = (props: Omit<BoardWithGroupsProps, 'scrollContainerRef'>) => {
	const { isReadOnly, groupByField, verticalGroupByField, isFooterVisible } = props;
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);
	const groups = useBoardViewWithGroups(props.groupByField, props.verticalGroupByField);

	const { groupColumnContentHeights, subscribeToColumnChanges, unsubscribeToColumnChanges } =
		useBoardVirtualization({
			groups,
			containerRef: scrollContainerRef,
			isReadOnly,
			isFooterVisible,
		});

	const onScrollContainerRefChange = useCallback(
		(element: HTMLDivElement | null) => {
			scrollContainerRef.current = element;
		},
		[scrollContainerRef],
	);

	const children = useMemo(
		() => (
			<BoardWithGroups
				scrollContainerRef={onScrollContainerRefChange}
				isReadOnly={isReadOnly}
				groupByField={groupByField}
				verticalGroupByField={verticalGroupByField}
				isFooterVisible={isFooterVisible}
			/>
		),
		[onScrollContainerRefChange, isReadOnly, groupByField, verticalGroupByField, isFooterVisible],
	);

	return (
		<>
			<BoardDimensionsProvider
				groupColumnContentHeights={groupColumnContentHeights}
				subscribeToColumnChanges={subscribeToColumnChanges}
				unsubscribeToColumnChanges={unsubscribeToColumnChanges}
			>
				{children}
			</BoardDimensionsProvider>
			<PerformanceObserver scrollingContainer={scrollContainerRef.current} isGrouped />
		</>
	);
};

BoardWithGroupsWrapper.displayName = 'BoardWithGroupsWrapper';

export type IdeaBoardProps = {
	isReadOnly: boolean;
	isFooterVisible: boolean;
	isSharedView?: boolean;
};

const IdeaBoardInternal = (props: IdeaBoardProps) => {
	const { isReadOnly, isFooterVisible, isSharedView } = props;
	const groupByField = useCurrentViewGroupBy();
	const verticalGroupByField = useCurrentViewVerticalGroupBy();
	const isCurrentViewConfigured = useCurrentViewConfigured();

	useCurrentViewSuccessExperience();
	useBoardExport();

	const canManageCurrentView = useCanManageCurrentView();
	const canManageViews = useCanManageViews();
	const hasSharedViewConfigError = useHasSharedViewConfigError();
	const openGroupByOptions = useOpenRightSidebarGroupByOptions();
	const [sidebarShowing] = useRightSidebarShowing();
	const handleOpenColumnsOptions = useCallback(
		(_event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireCompoundAnalyticsEvent.ViewControls.openColumnsSidebarButtonClicked(analyticsEvent, {
				isEmptyView: true,
			});
			openGroupByOptions();
		},
		[openGroupByOptions],
	);
	const [permissionsLoaded] = useIsLoadedPermissions();
	const [hasNoProjectPermissions] = useHasNoProjectPermissions();

	if (isSharedView && hasSharedViewConfigError) {
		return (
			<PublishedViewEmptyState
				permissionsLoaded={permissionsLoaded}
				hasProjectPermissions={!hasNoProjectPermissions}
			/>
		);
	}

	if (!isCurrentViewConfigured) {
		return (
			<BoardViewEmptyState
				onButtonClick={
					(ff('polaris.view-permissions_plaoi') ? canManageCurrentView : canManageViews)
						? handleOpenColumnsOptions
						: undefined
				}
				isHintHidden={sidebarShowing.mode === RightSidebarShowingGroupByOptions}
			/>
		);
	}

	if (groupByField && verticalGroupByField) {
		return (
			<IdeaCardContextProvider>
				<BoardWithGroupsWrapper
					isReadOnly={isReadOnly}
					isFooterVisible={isFooterVisible}
					groupByField={groupByField}
					verticalGroupByField={verticalGroupByField}
				/>
			</IdeaCardContextProvider>
		);
	}

	return (
		<IdeaCardContextProvider>
			<BoardWithoutGroupsWrapper
				isReadOnly={isReadOnly}
				isFooterVisible={isFooterVisible}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				groupByField={groupByField!}
			/>
		</IdeaCardContextProvider>
	);
};

export const IdeaBoard = memo<IdeaBoardProps>((props) => (
	<ExperienceFailErrorBoundary
		experience={experience.boardView.pageSegmentLoad}
		metadata={{ isSharedView: props.isSharedView }}
	>
		<IdeaBoardInternal {...props} />
	</ExperienceFailErrorBoundary>
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-start',
	width: '100%',
	height: 'calc(100% - 16px)',
	paddingBottom: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlanesContainer = styled.div<{ isExporting: boolean }>({
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ isExporting }) => (isExporting ? 'visible' : 'auto'),
	display: 'flex',
	width: '100%',
	minHeight: '100%',
	justifyContent: 'flex-start',
	boxSizing: 'border-box',
	background: token('elevation.surface', 'white'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardWrapper = styled.div<{ isExporting: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	overflow: ({ isExporting }) => (isExporting ? 'visible' : 'auto'),
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
});
