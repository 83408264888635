import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useDecorationsForField } from '@atlassian/jira-polaris-common/src/controllers/field/selectors/decoration/hooks';
import { useIssueActions } from '@atlassian/jira-polaris-common/src/controllers/issue/main.tsx';
import { useField } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/fields-hooks';
import { useNumberValue } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/properties/hooks';
import { NumberField } from '@atlassian/jira-polaris-common/src/ui/fields/number/index.tsx';
import { useCanEditIssues } from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import messages from './messages';

type Props = {
	fieldKey: FieldKey;
	localIssueId: LocalIssueId;
};

export const Number = ({ fieldKey, localIssueId }: Props) => {
	const field = useField(fieldKey);
	const { formatMessage } = useIntl();
	const { value } = useNumberValue(fieldKey, localIssueId);
	const fieldValueDecorations = useDecorationsForField(fieldKey);
	const [canEditIssues] = useCanEditIssues();
	const { updateFieldValue } = useIssueActions();

	if (field === undefined) {
		return null;
	}

	return (
		<NumberField
			value={value}
			decorations={fieldValueDecorations}
			isEditable={canEditIssues && field.editable}
			fieldType={field.type}
			onUpdate={(newValue?: number) => {
				updateFieldValue({
					fieldKey,
					localIssueIds: [localIssueId],
					newValue,
				});
			}}
			placeholder={formatMessage(messages.noValue)}
		/>
	);
};
