import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AvatarUrls } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { ENTITY_LIMIT_TYPE } from './constants';

type ValueOf<T> = T[keyof T];

export const ProjectOnboardingStatusEnum = {
	UNKNOWN: 'UNKNOWN',
	TASK_CREATION_FAILED: 'TASK_CREATION_FAILED',
	QUEUEING_FAILED: 'QUEUEING_FAILED',
	QUEUED: 'QUEUED',
	IN_PROGRESS: 'IN_PROGRESS',
	DONE: 'DONE',
	FAILED: 'FAILED',
} as const;

export const ProjectTemplateEnum = {
	DISCOVERY: 'jira.polaris:discovery',
	BLANK: 'jira.polaris:blank-project',
	ROADMAP: 'jira.polaris:roadmap',
	PRIORITIZATION: 'jira.polaris:prioritization',
} as const;

export type PolarisProjectTemplate = {
	onboardingStatus: ValueOf<typeof ProjectOnboardingStatusEnum>;
	initialProjectTemplate: ValueOf<typeof ProjectTemplateEnum>;
};

export type EntityLimitType = (typeof ENTITY_LIMIT_TYPE)[keyof typeof ENTITY_LIMIT_TYPE];

export type EntityLimits = {
	[K in EntityLimitType]?: number;
};

export type ProjectAvatarUrls = AvatarUrls;

export type ProjectMetadata = {
	id: ProjectId;
	key: ProjectKey;
	name: string;
	avatarUrls: ProjectAvatarUrls;
};
