import React, { type ComponentType } from 'react';
import { components } from '@atlaskit/select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SelectSingleValueComponentProps = any;

export type SingleValueComponentProps = {
	DecoratedTagComponent: ComponentType<{
		id: string;
		compact?: boolean;
	}>;
};

export const createSingleValueComponent =
	({ DecoratedTagComponent }: SingleValueComponentProps) =>
	({ innerProps, ...rest }: SelectSingleValueComponentProps) => (
		<components.SingleValue
			innerProps={{
				...innerProps,
				style: {
					minHeight: '16px',
				},
			}}
			{...rest}
		>
			<DecoratedTagComponent id={rest.data.id} />
		</components.SingleValue>
	);
