import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { callWhenIdle } from '@atlassian/jira-polaris-lib-idle-callback';
import { getCacheValue } from '@atlassian/jira-polaris-lib-selector-creator-cache';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props, DynamicPropertyMaps } from '../../types';
import {
	createFullPropertyValuesSelectorInternal,
	resolveDynamicFields,
} from '../../utils/dynamic';

const createDynamicPropertyMaps = (
	fields: undefined | Array<Field>,
	dynamicProperties: DynamicPropertyMaps<State>,
) => {
	// only concern ourselves with dynamic fields. Currently defined as fields that have a formula.
	const dynamicFields = (fields || []).filter((field) => field.formula !== undefined);

	// use the dynamic fields utils to create the dynamic properties state structure
	return resolveDynamicFields(dynamicFields, dynamicProperties);
};

/**
 * Initialisation action that populates the dynamic properties selector factories based on the available
 * fields
 */
export const createDynamicFieldSelectors =
	(): Action<State, Props> =>
	({ getState, setState, dispatch }, { fields }: Props) => {
		const dynamicProperties = createDynamicPropertyMaps(fields, getState().dynamicProperties);

		// write to state
		setState({
			dynamicProperties,
		});
		dispatch(scheduleDynamicFieldCalculation());
	};

export const scheduleDynamicFieldCalculation =
	(): Action<State, Props> =>
	({ getState }, props: Props) => {
		if (!getState().meta.initialized) {
			return;
		}

		if (props.onDynamicFieldStoreInitialized() !== undefined) {
			// added/removed field
			if (props.fields?.length !== getState().prevContainerProps?.fields?.length) {
				props.fields?.forEach((field) => {
					props.onDynamicFieldInitialized(field.key);
				});
			}
			return;
		}

		const cacheNode = getCacheValue(createFullPropertyValuesSelectorInternal);

		cacheNode &&
			[...cacheNode.args.values()].forEach((node) => {
				callWhenIdle(() => {
					experience.formulaField.precalculateFormulaFieldAllValuesSelector.start();
					node.selector?.(getState(), props);
					experience.formulaField.precalculateFormulaFieldAllValuesSelector.success();
				});
			});

		Object.keys(getState().dynamicProperties.numberValues).forEach((fieldKey) => {
			const selector = getState().dynamicProperties.numberValues[fieldKey];
			if (fieldKey === undefined) {
				return;
			}
			callWhenIdle(() => {
				experience.formulaField.precalculateFormulaFieldAllValuesSelector.start();
				selector?.(getState(), props);
				experience.formulaField.precalculateFormulaFieldAllValuesSelector.success();
				props.onDynamicFieldInitialized(fieldKey);
			});
		});

		Object.keys(getState().dynamicProperties.dateValues).forEach((fieldKey) => {
			const selector = getState().dynamicProperties.dateValues[fieldKey];
			if (fieldKey === undefined) {
				return;
			}
			callWhenIdle(() => {
				experience.formulaField.precalculateFormulaFieldAllValuesSelector.start();
				selector?.(getState(), props);
				experience.formulaField.precalculateFormulaFieldAllValuesSelector.success();
				props.onDynamicFieldInitialized(fieldKey);
			});
		});
	};

/**
 * Updates dynamic properties for a single field. This is called when the field formula is updated.
 */
export const updateDynamicFieldSelector =
	(field: Field): Action<State, Props> =>
	({ getState, setState }) => {
		const dynamicProperties = createDynamicPropertyMaps([field], getState().dynamicProperties);

		if (Object.keys(dynamicProperties.numberValue).length) {
			setState({
				dynamicProperties: {
					...getState().dynamicProperties,
					numberValue: {
						...getState().dynamicProperties.numberValue,
						...dynamicProperties.numberValue,
					},
				},
			});
		}

		if (Object.keys(dynamicProperties.dateValue).length) {
			setState({
				dynamicProperties: {
					...getState().dynamicProperties,
					dateValue: {
						...getState().dynamicProperties.dateValue,
						...dynamicProperties.dateValue,
					},
				},
			});
		}

		if (Object.keys(dynamicProperties.numberValues).length) {
			setState({
				dynamicProperties: {
					...getState().dynamicProperties,
					numberValues: {
						...getState().dynamicProperties.numberValues,
						...dynamicProperties.numberValues,
					},
				},
			});
		}

		if (Object.keys(dynamicProperties.dateValues).length) {
			setState({
				dynamicProperties: {
					...getState().dynamicProperties,
					dateValues: {
						...getState().dynamicProperties.dateValues,
						...dynamicProperties.dateValues,
					},
				},
			});
		}
	};
