import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { showingZAxisOptions } from '@atlassian/jira-polaris-common/src/controllers/right-sidebar/types';
import { useViewActions } from '@atlassian/jira-polaris-common/src/controllers/views/main.tsx';
import { useFieldsForViewControls } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/fields-hooks';
import { useCurrentViewZAxis } from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import { BaseFieldConfig } from '../base';
import { messages } from './messages';

export const ConfigZAxis = () => {
	const { formatMessage } = useIntl();
	const zAxisField = useCurrentViewZAxis();
	const { setMatrixZAxis } = useViewActions();
	const fields = useFieldsForViewControls();

	const onChange = useCallback(
		(fieldKey: string | undefined) => {
			if (fieldKey === zAxisField) {
				return;
			}
			setMatrixZAxis(fieldKey);
		},
		[setMatrixZAxis, zAxisField],
	);

	return (
		<BaseFieldConfig
			helpText={formatMessage(messages.axisHelpText)}
			fields={fields}
			selectedField={zAxisField}
			backTo={showingZAxisOptions}
			onChange={onChange}
			dimension="z"
			isClearable
		/>
	);
};
