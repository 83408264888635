import { ff } from '@atlassian/jira-feature-flagging';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { normalizeAggregatedDeliveryProgressResponse } from '../../common/normalize-aggregated-delivery-progress-response';
import type {
	AggregatedDeliveryProgressResponse,
	FetchAggregatedDeliveryProgressRequest,
	DateFieldConfiguration,
} from '../types';

// @deprecated - use getAggregatedDeliveryProgress
export const getAggregatedDeliveryProgressBasedOnProjectId = async (
	jpdProjectId: string,
	calculationStrategy: string,
	calculationMode?: string,
	dateFieldConfigurations?: DateFieldConfiguration[],
): Promise<AggregatedDeliveryProgressResponse> => {
	const mode = calculationMode || 'issue_count';

	return fetchJson('/rest/polaris/delivery-data/data', {
		method: 'POST',
		body: JSON.stringify({
			projectId: jpdProjectId,
			type: calculationStrategy,
			calculationMode: mode,
			dateFieldConfigurations,
		}),
	});
};

const getAggregatedDeliveryProgressOld = async (request: FetchAggregatedDeliveryProgressRequest) =>
	fetchJson('/rest/polaris/delivery-data/data', {
		method: 'POST',
		body: JSON.stringify({
			projectId: request.projectId,
			issueIds: request.issueIds,
			type: request.calculationStrategy,
			calculationMode: request.calculationMode,
			dateFieldConfigurations: request.dateFieldConfigurations,
			archived: request.archivedFilter,
		}),
	});

const getAggregatedDeliveryProgressNew = async (request: FetchAggregatedDeliveryProgressRequest) =>
	fetchJson('/rest/polaris/delivery-data/data', {
		method: 'POST',
		body: JSON.stringify({
			projectId: request.projectId,
			issueIds: request.issueIds,
			type: request.calculationStrategy,
			calculationMode: request.calculationMode,
			dateFieldConfigurations: request.dateFieldConfigurations,
			archived: request.archivedFilter,
		}),
	}).then(normalizeAggregatedDeliveryProgressResponse);

export const getAggregatedDeliveryProgress = functionWithCondition(
	() => ff('polaris.publish.fix-error-dialog-on-published-views-after-reload'),
	getAggregatedDeliveryProgressNew,
	getAggregatedDeliveryProgressOld,
);
