import React from 'react';
import { Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { DecoratedTag } from '@atlassian/jira-polaris-common/src/ui/common/decoration/decoration/tag/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import messages from './messages';

type FieldValueComponentProps = {
	fieldKey: FieldKey;
	groupIdentity: string;
};

const LabelFieldValue = ({ fieldKey, groupIdentity }: FieldValueComponentProps) => (
	<DecoratedTag fieldKey={fieldKey} id={groupIdentity} value={groupIdentity} />
);

const EmptyLabelFieldValue = () => {
	const { formatMessage } = useIntl();

	if (fg('polaris_groupby-filter-sort-fields-panels-refresh')) {
		return <Box paddingInlineStart="space.050">{formatMessage(messages.emptyFieldValue)}</Box>;
	}

	return <Box paddingBlockStart="space.050">{formatMessage(messages.emptyFieldValue)}</Box>;
};

export const labelsFieldValueConfig = {
	isHideable: true,
	isDecoratable: true,
	Component: LabelFieldValue,
	EmptyComponent: EmptyLabelFieldValue,
} as const;
