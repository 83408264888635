import React, { type ReactNode, useMemo } from 'react';
import pick from 'lodash/pick';
import { PolarisRouteContainer } from '@atlassian/jira-polaris-common/src/controllers/route';
import {
	type PolarisRoute,
	POLARIS_QUERY_VALID_VALUES,
} from '@atlassian/jira-polaris-common/src/controllers/route/types';
import { ViewSectionTypeViews } from '@atlassian/jira-polaris-common/src/controllers/views/types';

const DEFAULT_ALLOWED_SECTIONS = [ViewSectionTypeViews];

const getAppUrl = ({ resource }: PolarisRoute) => `/jira/discovery/share/views/${resource}`;

export const SharingRouteContainer = ({ children }: { children: ReactNode }) => {
	const queryValidValues = useMemo(
		() =>
			pick(POLARIS_QUERY_VALID_VALUES, ['selectedIssue', 'issueViewLayout', 'issueViewSection']),
		[],
	);
	return (
		<PolarisRouteContainer
			storageKey={undefined}
			getAppUrl={getAppUrl}
			allowedSections={DEFAULT_ALLOWED_SECTIONS}
			defaultSection="view"
			queryValidValues={queryValidValues}
		>
			{children}
		</PolarisRouteContainer>
	);
};
