import React, { forwardRef } from 'react';
import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import DisabledButtonWithTooltip from '@atlassian/jira-disabled-button-with-tooltip';
import type { ButtonWithTooltipProps } from './types';

export type { ButtonWithTooltipProps };

/**
 * Wraps a button within a tooltip and ensures the tooltip is also visible when the button is disabled.
 * The tooltip props can be overridden with the tooltipProps prop.
 * The tooltip will not be displayed when its content is undefined.
 */
export const ButtonWithTooltip = forwardRef(
	(props: ButtonWithTooltipProps, ref: React.Ref<HTMLElement>) => {
		const { tooltipProps, ...buttonProps } = props;

		return (
			<Tooltip {...tooltipProps}>
				<DisabledButtonWithTooltip
					isDisabled={!!buttonProps.isDisabled}
					// @ts-expect-error Property 'css' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLSpanElement> & HTMLAttributes<HTMLSpanElement> & { ...; }, any>, any, any>> & Readonly<...>'.ts(2322)
					// eslint-disable-next-line @compiled/no-css-prop-without-css-function
					css={{
						width: buttonProps.shouldFitContainer === true ? '100%' : 'auto',
					}}
				>
					<Button {...buttonProps} ref={ref} />
				</DisabledButtonWithTooltip>
			</Tooltip>
		);
	},
);
