import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const customCss = {
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	'& > div:nth-child(2)': {
		flex: '0 1 auto',
		a: {
			wordBreak: 'break-all',
		},
	},
	'& > div:nth-child(2) ~ div': {
		flex: 'none',
	},
	'& > div:last-child': {
		minWidth: 0,
	},
	'& > div': {
		alignSelf: 'center',
	},
	'& a, a:active, a:visited, a:hover, a:focus': {
		color: token('color.text.subtle', colors.N600),
		textDecoration: 'none',
	},
	// Style from Hyperlink field
	'& > span + div:nth-of-type(2) span': {
		textAlign: 'left',
	},
};
