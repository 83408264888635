import { useMemo } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIdeasInCreationCount } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/created-issues';
import { useIsSorted } from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/sort-hooks';
import {
	useCanManageCurrentView,
	useCurrentViewContainsArchived,
} from '@atlassian/jira-polaris-common/src/controllers/views/selectors/view-hooks';
import {
	useCanEditIssues,
	useCanManageViews,
	useCanRankIdeas,
} from '@atlassian/jira-polaris-component-permissions-store/src/controllers/permissions/selectors/permissions-hooks.tsx';
import { useIsRankingAllowedDuringCreation } from '../../../utils';

type RankingDisallowedReason = 'not_allowed' | 'in_creation' | 'sorted';

const getRankingDisallowedReason = (props: {
	isReadOnly: boolean;
	canRankIdeas: boolean;
	sorted: boolean;
	issuesInCreation: boolean;
}): RankingDisallowedReason | undefined => {
	const { isReadOnly, canRankIdeas, sorted, issuesInCreation } = props;
	if (isReadOnly || !canRankIdeas) {
		return 'not_allowed';
	}
	if (issuesInCreation) {
		return 'in_creation';
	}
	if (sorted) {
		return 'sorted';
	}
	return undefined;
};

export const useRowOps = () => {
	const canManageCurrentView = useCanManageCurrentView();
	const [canEditIssues] = useCanEditIssues();
	const canManageViews = useCanManageViews();
	const canRankIdeas = useCanRankIdeas();

	const sorted = useIsSorted();
	const containsArchived = useCurrentViewContainsArchived();
	const createdIssueCount = useIdeasInCreationCount();
	const isRankingAllowedDuringCreation = useIsRankingAllowedDuringCreation();

	return useMemo(() => {
		const issuesInCreation = createdIssueCount > 0;

		return {
			selectionAllowed: canEditIssues && !issuesInCreation,
			rankingAllowed: isRankingAllowedDuringCreation && !issuesInCreation,
			rankingDisallowedReason: getRankingDisallowedReason({
				isReadOnly: !canEditIssues || containsArchived,
				canRankIdeas,
				sorted,
				issuesInCreation,
			}),
			groupRankingAllowed: canEditIssues && !sorted && !issuesInCreation,
			columnResizeAllowed: ff('polaris.view-permissions_plaoi')
				? canManageCurrentView
				: canManageViews,
		};
	}, [
		createdIssueCount,
		canEditIssues,
		isRankingAllowedDuringCreation,
		containsArchived,
		canRankIdeas,
		sorted,
		canManageCurrentView,
		canManageViews,
	]);
};

export const useColOps = () => {
	const canManageViews = useCanManageViews();

	return useMemo(
		() => ({
			columnReorderingAllowed: canManageViews,
		}),
		[canManageViews],
	);
};
