import type { ReactElement } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { BaseUrl, IssueKey, IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useProjectViewWorkflowService } from './use-project-view-workflow-service/main';

type WorkflowServiceData = {
	hasPermission?: boolean;
	issueTypeId?: IssueTypeId;
	projectId?: string | undefined;
	projectType?: ProjectType | undefined;
	isSimplified?: boolean | undefined;
};

export type ServiceApi = {
	data: WorkflowServiceData | undefined;
	loading: boolean;
	error: FetchError | undefined;
	fetch: () => Promise<void>;
};

export type Props = {
	issueKey: IssueKey;
	baseUrl: BaseUrl;
	children: (arg1: ServiceApi) => ReactElement;
};

export const emptyResult: ServiceApi = {
	data: undefined,
	loading: false,
	error: undefined,
	fetch: () => Promise.resolve(),
};

/**
 * Gets projectData from ContainerContextSubscriber or from service
 */
const ViewWorkflowContextService = (props: Props) => {
	const { baseUrl, issueKey, children } = props;

	return children(useProjectViewWorkflowService(baseUrl, issueKey));
};

export default ViewWorkflowContextService;
