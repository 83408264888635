import React from 'react';
import { styled } from '@compiled/react';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import { useIsCollectionView } from '@atlassian/jira-polaris-common/src/controllers/environment';
import {
	useIsInitialized,
	useIsIssuesLoading,
} from '@atlassian/jira-polaris-common/src/controllers/issue/selectors/meta-hooks';
import { ShowDescriptionButton } from './show-description-button';
import { ViewIdeaCount } from './view-idea-count';
import { ViewProjectsCount } from './view-projects-count';

type Props = {
	showViewDescriptionButton: boolean;
};

export const Subtitle = ({ showViewDescriptionButton }: Props) => {
	const isIssuesLoading = useIsIssuesLoading();
	const isInitialized = useIsInitialized();
	const isCollectionView = useIsCollectionView();

	if (isIssuesLoading || !isInitialized) {
		return <Box xcss={skeletonStyles} />;
	}

	return (
		<Flex gap="space.050" alignItems="center" xcss={subtitleStyles}>
			{showViewDescriptionButton && (
				<>
					<ShowDescriptionButton />
					{' • '}
				</>
			)}
			<IdeaCountContainer>
				<ViewIdeaCount />
			</IdeaCountContainer>
			{isCollectionView && (
				<>
					{' • '}
					<ViewProjectsCount />
				</>
			)}
		</Flex>
	);
};

const subtitleStyles = xcss({
	color: 'color.text.subtlest',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '11px',
	lineHeight: '14px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdeaCountContainer = styled.span({
	flexShrink: 0,
});

// TODO: use an actual skeleton component from the design system
const skeletonStyles = xcss({
	height: 'space.200',
});
