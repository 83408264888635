import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { useElementSize } from '../../../common/utils/react/elements';
import {
	useSummary,
	useIssueCreatedProperty,
	useLinkedDeliveryIssuesExist,
} from '../../../controllers/issue/selectors/properties/hooks';
import { IssueCreateStatusInTransition } from '../../../controllers/issue/types';
import { useCurrentViewSummaryCardField } from '../../../controllers/views/selectors/view-hooks';
import { LinkedIssuesProgressField } from '../body/linked-issues-progress/index.tsx';
import { DISPLAYING_FIELDS_MIN_CARD_WIDTH } from '../constants';
import { Highlighter } from '../highlighter';
import { SummaryCardField } from './summary-card-field';
import { calculateSummaryFieldSpace } from './utils';

type SummaryCardProps = {
	id: LocalIssueId;
	isResizing?: boolean;
	onHideSummaryTooltip: () => void;
	onShowSummaryTooltip: () => void;
};

export const SummaryCard = (props: SummaryCardProps) => {
	const { id, isResizing, onHideSummaryTooltip, onShowSummaryTooltip } = props;
	const [setSummaryContainerRef, summaryContainerSize] = useElementSize();
	const summaryRef = useRef<HTMLDivElement>(null);
	const summary = useSummary(id);
	const issueCreatedProperty = useIssueCreatedProperty(id);
	const summaryCardField = useCurrentViewSummaryCardField();
	const deliveryIssuesExist = useLinkedDeliveryIssuesExist(id);

	const fieldSpace = calculateSummaryFieldSpace(
		summaryContainerSize.width,
		summaryRef?.current?.scrollWidth,
	);

	return (
		<>
			<SummaryContainer ref={setSummaryContainerRef}>
				<Flex
					justifyContent="space-between"
					alignItems="center"
					gap="space.050"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({ width: '100%' })}
				>
					<SummaryContent ref={summaryRef}>{isEmpty(summary) ? '-' : summary}</SummaryContent>

					{summaryCardField && (
						<SummaryCardField
							issueId={id}
							field={summaryCardField}
							containerWidth={summaryContainerSize.width}
							reservedSpace={fieldSpace.reserved}
							maxWidth={fieldSpace.maxWidth}
							isResizing={isResizing}
							onHideSummaryTooltip={onHideSummaryTooltip}
							onShowSummaryTooltip={onShowSummaryTooltip}
						/>
					)}
				</Flex>
				{issueCreatedProperty && issueCreatedProperty.status === IssueCreateStatusInTransition && (
					<SpinnerWrapper>
						<Spinner />
					</SpinnerWrapper>
				)}
				{summaryCardField?.type === FIELD_TYPES.DELIVERY_PROGRESS && deliveryIssuesExist && (
					<DeliveryProgressWrapper>
						<LinkedIssuesProgressField
							issueId={id}
							fieldKey={summaryCardField?.key}
							hideEmpty
							appearance="ultra-compact"
						/>
					</DeliveryProgressWrapper>
				)}
			</SummaryContainer>
			<Highlighter id={id} isResizing={isResizing} />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	gap: token('space.025', '2px'),
	fontWeight: 600,
	width: '100%',
	height: '38px',
	lineHeight: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryContent = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
	maxWidth: 'fit-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${DISPLAYING_FIELDS_MIN_CARD_WIDTH}px)`]: {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	position: 'absolute',
	top: token('space.050', '4px'),
	right: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const DeliveryProgressWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '5px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '5px',
});
