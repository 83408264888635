import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hiddenFormulaFieldsWarning: {
		id: 'polaris-ideas.idea-view.fields.field.list.fields-that-require-all-ideas-warning.hidden-formula-fields-warning',
		defaultMessage: 'Some formula fields require extra data to be calculated.',
		description:
			'Information message displayed when formula fields are not displayed in the idea view',
	},
	nonEditableLabelFieldsWarning: {
		id: 'polaris-ideas.idea-view.fields.field.list.fields-that-require-all-ideas-warning.non-editable-label-fields-warning',
		defaultMessage: 'Some fields require extra data to be edited.',
		description:
			'Information message displayed when label fields require extra data to be edited in the idea view',
	},
	loadData: {
		id: 'polaris-ideas.idea-view.fields.field.list.fields-that-require-all-ideas-warning.load-data',
		defaultMessage: 'Load data',
		description: 'Clickable link displayed to load necessary data',
	},
});
