import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types';

export const setSorting =
	(column: State['sort']['column'], direction: State['sort']['direction']): Action<State> =>
	({ setState }) => {
		setState({
			sort: {
				column,
				direction,
			},
		});
	};
