import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';
import { createAri } from '@atlassian/jira-platform-ari';
import type { PublicToInternalPropertyTransformerContext } from '../../types';
import type {
	AllowedValuesOverrides,
	FieldOptionsFilter,
	OptionsVisibility,
} from '../../types/options-visibility';
import { validateSetOptionsVisibilityRule } from '../get-common-field-validators';
import { isAriResourceIdValid } from '../is-ari-resource-id-valid';

export const transformSelectSetOptionsVisibility = ({
	isVisible,
	options,
}: OptionsVisibility): AllowedValuesOverrides => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	ids: options,
});

/**
 * @deprecated
 * This function is deprecated and will be removed in the future.
 * It is only used in issue view. However, we should not use it for any new fields
 */
const transformOptionIdsToAriIssueView = (
	optionIds: string[],
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
) =>
	optionIds.map((optionId) => {
		const resourceId = `${fieldContext.fieldId}/${optionId}`;
		return createAri({
			resourceOwner: 'jira',
			cloudId: tenantContext.cloudId,
			resourceType: 'issue-field-option',
			activationId: tenantContext.activationId,
			resourceId,
		});
	});

/**
 * @deprecated
 * This function is deprecated and will be removed in the future.
 * It is only used in issue view. However, we should not use it for any new fields
 */
export const transformSetOptionsVisibilityAriIssueView = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: transformOptionIdsToAriIssueView(options, context),
});

export const transformOptionIdsToARI = (
	optionIds: string[],
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
) =>
	optionIds.map((optionId) =>
		JiraIssueFieldOptionAri.create({
			siteId: tenantContext.cloudId,
			activationId: tenantContext.activationId,
			fieldId: fieldContext.fieldId,
			optionId,
		}).toString(),
	);

export const transformSetOptionsVisibilityAri = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: transformOptionIdsToARI(options, context),
});

// transformer is called on FieldPublicShape type
export const transformSelectGetOptionsVisibility = ({
	isVisible,
	options,
}: OptionsVisibility): OptionsVisibility => ({
	isVisible,
	options,
});

export const optionsVisibilityContainNoMoreThan100Items = (optionsVisibility: OptionsVisibility) =>
	optionsVisibility.options.length <= 100;

export const validateSetOptionsVisibility = (optionsVisibility: OptionsVisibility): boolean =>
	validateSetOptionsVisibilityRule(optionsVisibility) &&
	optionsVisibilityContainNoMoreThan100Items(optionsVisibility);

export const validateSetOptionsVisibilityAri = (optionsVisibility: OptionsVisibility): boolean =>
	validateSetOptionsVisibility(optionsVisibility) &&
	optionsVisibility.options.every((optionId) => isAriResourceIdValid(optionId));
