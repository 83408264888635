import uuid from 'uuid/v4';
import { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';

/**
 * Returns the current polaris-api hostname to use, or undefined to use staging
 * Use this to centrally override the polaris-api hostname.
 * Example: 'https://ssert-be.public.atlastunnel.com'
 *
 * https://app.launchdarkly.com/jira/staging/features/polaris.polaris-api-service-host/targeting
 */

const getPolarisApiServiceHost = () =>
	getFeatureFlagValue<string>('polaris.polaris-api-service-host', 'default');

type FieldsMappingUrl = {
	url: string;
	traceId: string;
};

export const getFieldsMappingUrl = (
	projectIdOrKey: number | string,
	appendTraceId = false,
): FieldsMappingUrl => {
	let ffHost = getPolarisApiServiceHost();
	if (!ffHost || ffHost === 'default') {
		ffHost = '/gateway/api/polaris';
	}

	const requestId = uuid();

	const postfix = appendTraceId ? `?requestId=${requestId}` : '';

	return {
		url: `${ffHost}/rest/projects/${projectIdOrKey}/field-id-mapping${postfix}`,
		traceId: requestId,
	};
};
