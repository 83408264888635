import React, { type ReactElement, createContext, useContext } from 'react';
import { injectable } from 'react-magnetic-di';
import type { Card } from '../use-board-virtualization/types';

type BoardDimensionsState = {
	groupColumnContentHeights: Map<string, number>;
	subscribeToColumnChanges: (columnId: string, callback: (updatedCards: Card[]) => void) => void;
	unsubscribeToColumnChanges: (columnId: string, callback: (updatedCards: Card[]) => void) => void;
};

const BoardDimensionsContext = createContext<BoardDimensionsState | undefined>(undefined);

type BoardDimensionsProviderProps = {
	children: ReactElement;
} & BoardDimensionsState;

const BoardDimensionsProvider = (props: BoardDimensionsProviderProps) => {
	const {
		children,
		groupColumnContentHeights,
		subscribeToColumnChanges,
		unsubscribeToColumnChanges,
	} = props;

	return (
		<BoardDimensionsContext.Provider
			value={{
				groupColumnContentHeights,
				subscribeToColumnChanges,
				unsubscribeToColumnChanges,
			}}
		>
			{children}
		</BoardDimensionsContext.Provider>
	);
};

const useVirtualBoard = () => {
	const context = useContext(BoardDimensionsContext);

	if (context === undefined) {
		throw new Error('useVirtualBoard must be used within a BoardDimensionsProvider');
	}

	return context;
};

const makeVirtualBoardDi = (mockIssues: string[]) => {
	let cardsRendered = false;

	return injectable(useVirtualBoard, () => ({
		subscribeToColumnChanges: (columnId: string, callback: (updatedCards: Card[]) => void) => {
			if (!cardsRendered) {
				callback(
					mockIssues.map((issueId, index) => ({
						height: 267,
						index,
						offset: index * (267 + 8),
						uid: issueId,
					})),
				);
				cardsRendered = true;
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		unsubscribeToColumnChanges: () => {},
		groupColumnContentHeights: new Map().set('MOCKED_GROUP', 500),
	}));
};

export { BoardDimensionsProvider, makeVirtualBoardDi, useVirtualBoard };
