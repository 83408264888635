import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from './actions';
import type { State } from './types';

export type Actions = typeof actions;

/*
 * This is a local react-sweet-state store that will reset if we navigate away from the issue-view
 * */
export const store = createStore<State, Actions>({
	name: 'issue-forms',
	initialState: {
		issueViewAddFormButtonClickedCount: 0,
	},
	actions,
});

export const useFormsInIssueStatus = createHook(store, {
	selector: (state) => state.issueViewAddFormButtonClickedCount,
});

export const FormInIssueStatusContainer = createContainer(store);
